import React from 'react'
import { Avatar, Tooltip } from '@mui/material'
import { avatarStyles, StyledBadge } from  '../../styles/makesStyles'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import PersonIcon from '@mui/icons-material/Person'
import BusinessIcon from '@mui/icons-material/Business'
import clsx from 'clsx'
import { getContactName } from '../helpers'

export const getIconOrLabel = (contact) => {
	if (contact.avatar) return (
		<img
			src={contact.imgUrl}
			alt={contact ? getContactName(contact) : "Thumbnail Image"}
		/>
	)

	if (!contact.imgUrl && getContactName(contact))
		return getContactName(contact)?.split(' ').map((n) => n[0])

	return contact.is_company ? <BusinessIcon /> : <PersonIcon />
}

export default function ContactAvatar (props) {

	const classes = avatarStyles()
	const { contact } = props



	const hidden = contact.is_private && !contact.first_name
	const isPrivate = contact.is_private
	const hasAccess = !hidden

	if (contact.is_deceased) {
		return <Tooltip
			key={contact.id}
			title={`${contact.first_name} ${contact.last_name} - Deceased`}
			arrow
		>
			<div className="user-avatar" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
				<StyledBadge
					className={clsx({
						'qv-sp-badge': true,
						'sp-badge-red': true,
					})}
					overlap="circular"
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					variant="dot"
				>
					<Avatar
						alt={`${contact.first_name} ${contact.last_name} - Deceased`}
						src={contact.imgUrl}
						className={classes.bigger}
						style={{
							marginLeft: -6
						}}
					>
						{isPrivate ? (
							<>
								{!hasAccess ?
									<LockIcon /> :
									<LockOpenIcon />
								}
							</>
						) :
							<>
								{getIconOrLabel(contact)}
							</>
						}
					</Avatar>
				</StyledBadge>
			</div>
		</Tooltip>
	}
	return <Tooltip key={contact.id}
		title={`${contact.first_name} ${contact.last_name}`}
		arrow
	>
		<div className="user-avatar"
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center'
			}}
		>
			<Avatar alt={`${contact.first_name} ${contact.last_name}`}
				src={contact.imgUrl}
				style={{
					marginLeft: -6
				}}
			>
				{isPrivate ? (
					<>
						{!hasAccess ?
							<LockIcon /> :
							<LockOpenIcon />
						}
					</>
				) :
					<>
						{getIconOrLabel(contact)}
					</>
				}
			</Avatar>
		</div>
	</Tooltip>
}
