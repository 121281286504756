import React, { useContext, useCallback } from 'react'
import { DispatchContext } from '../../../store'
import { Paper, FormControl, Menu, MenuItem } from '@mui/material'
import Label from '../../../input/Label'
import TextBox from '../../../input/Text/TextBoxThin'
import { severity } from '../../../Snackbar/CustomizedSnackbar'
import { CancelButton, SaveCardButton } from '../../../input/Buttons'
import { FindModeInput } from '../../../navigation/Tabs/TabbedPage'
import { Skelly } from '../../../common/components/Skelly'

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false,
}

export default function ArtistDetail(props) {
	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	const [state, setState] = React.useState(initialState)
	const [artist, setArtist] = React.useState({
		id: '',
		first_name: '',
		last_name: '',
		dates: '',
		birthplace: ''
	})
	const [attempt, setAttempt] = React.useState(false)

	const resetArtistInput = (artist) => {
		setArtist({
			id: artist.id,
			first_name: artist.first_name,
			last_name: artist.last_name,
			dates: artist.dates,
			birthplace: artist.birthplace
		})
	}

	React.useEffect(() => {
		resetArtistInput(props.artist)
	}, [props.artist])

	const handleClick = (event) => {
		if (state.mouseX || state.editable || props.findMode || !props.artist?.id) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const handleClose = (option) => {
		if (option === 'edit') {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	return (
		<Paper
			className="qv-margin"
			onContextMenu={handleClick}
			id="contact-details"
			data-testid="card-details"
		>
			<h1 className="card-title">
				<span>Details</span>
				{state.editable && !props.findMode && (
					<>
						<div className="spacer"></div>
						<CancelButton
							variant="contained"
							size="small"
							onClick={() => {
								resetArtistInput(props.artist)
								handleClose()
								setAttempt(false)
							}}
						>
							Cancel
						</CancelButton>
						<SaveCardButton
							variant="contained"
							color="secondary"
							size="small"
							disabled={props.disabled}
							onClick={() => {
								setAttempt(true)

								// validation
								if (attempt && (!artist.first_name || !artist.last_name) ) {
									openSnackbar(
										severity.WARNING,
										'Please complete required fields.'
									)
								} else if (artist.first_name && artist.last_name) {

									props.onSave(artist)
									handleClose()
								}

							}}
						>
							Save
						</SaveCardButton>
					</>
				)}
			</h1>
			<div className="column-body">
				<div className="column">
					<FormControl>
						<Label
							disableAnimation
							shrink
							htmlFor="artist-first-name"
							error={attempt && !artist.first_name}
						>
							First Name{!props.findMode && '*'}
						</Label>
						{ props.findMode ? <FindModeInput field="first_name" /> :
							(props.loading ? <Skelly error={props.error} /> :
								<TextBox
									error={ attempt && !artist.first_name }
									id="artist-first-name"
									value={artist.first_name || ''}
									readOnly={!state.editable}
									placeholder={'-'}
									onChange={(e) => {
										setArtist({
											...artist,
											first_name: e.target.value,
										})
									}}
									data-testid="artist-first_name"
								/>
							)}
					</FormControl>

					<FormControl>
						<Label
							disableAnimation
							shrink
							htmlFor="artist-last-name"
							error={ attempt && !artist.last_name }
						>
							Last Name{!props.findMode && '*'}
						</Label>
						{ props.findMode ? <FindModeInput field="last_name" /> :
							(props.loading ? <Skelly error={props.error} /> :
								<TextBox
									id="artist-last-name"
									value={artist.last_name || ''}
									error={ attempt && !artist.last_name }
									readOnly={!state.editable}
									placeholder={'-'}
									onChange={(e) => {
										setArtist({
											...artist,
											last_name: e.target.value,
										})
									}}
									data-testid="artist-last_name"
								/>
							)}
					</FormControl>
				</div>
				<div className="column">
					<FormControl>
						<Label
							disableAnimation
							shrink
							htmlFor="artist-dates"
						>
							Dates
						</Label>
						{ props.findMode ? <FindModeInput field="dates" /> :
							(props.loading ? <Skelly error={props.error} /> :
								<TextBox
									required
									id="artist-dates"
									value={artist.dates || ''}
									readOnly={!state.editable}
									placeholder={'-'}
									onChange={(e) => {
										setArtist({
											...artist,
											dates: e.target.value,
										})
									}}
									data-testid="artist-dates"
								/>
							)}
					</FormControl>

					<FormControl>
						<Label
							disableAnimation
							shrink
							htmlFor="artist-birthplace"
						>
							Birthplace
						</Label>
						{ props.findMode ? <FindModeInput field="birthplace" /> :
							(props.loading ? <Skelly error={props.error} /> :
								<TextBox
									required
									id="artist-birthplace"
									value={artist.birthplace || ''}
									placeholder="-"
									readOnly={!state.editable}
									onChange={(e) => {
										setArtist({
											...artist,
											birthplace: e.target.value,
										})
									}}
									data-testid="artist-birthplace"
								/>
							)}
					</FormControl>
				</div>
			</div>

			<Menu
				keepMounted
				open={state.mouseY !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					state.mouseY !== null && state.mouseX !== null
						? { top: state.mouseY, left: state.mouseX }
						: undefined
				}
			>
				<MenuItem onClick={() => handleClose('edit')}>Edit</MenuItem>
			</Menu>
		</Paper>
	)
}
