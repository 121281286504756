/* eslint-disable eqeqeq */
import { withApollo } from '@apollo/client/react/hoc'
import { useNavigate } from 'react-router-dom'
import { SearchButton, SubmitButton } from '../input/Buttons'


function printNumericValue(val) {
	return val == 0 || val == null ? '-' : val
}

const general_map = {
	"inserted_row": "Total Count",
	"inserted_note": "Notes",
	"inserted_tag": "Tags",
	"inserted_tag_link": "Works Tagged",
	"inserted_image_id": "Images Expected",
	"inserted_image": "Images Uploaded",
}

const art_map = {
	"art_inserted_crnumber": "CR Numbers",
	"art_inserted_research": "Research Records",
	"art_inserted_edition_copy": "Edition Numbers",
	"art_inserted_edition_copy_total": "Edition Totals",
	"art_inserted_edition_ap": "Edition AP",
	"art_inserted_edition_ap_total": "Edition AP Totals",
	"art_inserted_other_inventory": "Other Inventory Numbers",
	"art_inserted_owner_contact_id": "Sources",
	"art_inserted_listing": "Works Added to Listing",
}

const contact_map = {
	"contact_inserted_phone": "Phone Numbers",
	"contact_inserted_email": "Emails",
	"contact_inserted_address": "Addresses",
	"contact_inserted_website": "Websites",
	"contact_inserted_type": "Contact Types",
	"contact_inserted_listing": "Contacts Added to Listing",
}

function ImportQuickViewData(props) {
	const navigate = useNavigate()

	/* Import Completed - Show Data Cards */
	if (props.import.status.value == "Completed") {
		return <>

			{/* General Info */}
			<table className="quickview-main">
				<tbody>
					<tr>
						<td><h3 className="paragraph-2" style={{ paddingBottom: '0' }}>General Totals</h3></td>
					</tr>
					{Object.keys(general_map).map((key) => {
						return <><tr>
							<th style={{ whiteSpace: 'nowrap' }}>{general_map[key]}: </th>
							<td>{printNumericValue(props.import[key])}</td>
						</tr></>
					})}

					{props.import.type.value == "Art" ?
						<>
							<tr>
								<td><h3 className="paragraph-2" style={{ paddingTop: '1em' }}>Art Totals</h3></td>
							</tr>
							{Object.keys(art_map).map((key) => {
								return <><tr>
									<th style={{ whiteSpace: 'nowrap' }}>{art_map[key]}: </th>
									<td>{printNumericValue(props.import[key])}</td>
								</tr></>
							})}
						</>
						:
						<>
							<tr>
								<td><h3 className="paragraph-2" style={{ paddingTop: '1em' }}>Contact Totals</h3></td>
							</tr>
							{Object.keys(contact_map).map((key) => {
								return <><tr>
									<th style={{ whiteSpace: 'nowrap' }}>{contact_map[key]}: </th>
									<td>{printNumericValue(props.import[key])}</td>
								</tr></>
							})}
						</>
					}
				</tbody>
			</table>

			<div
				style={{
					width: '100%',
					paddingBottom: '1em',
					marginTop: '1em',
					textAlign: 'center',
				}}
			>
				{/* Search Object Buttons */}
				{props.import.type.value === "Art" ?
					<>
						<SearchButton variant="contained" style={{ backgroundColor: '#4465D1', float: 'left'}} onClick={() => {
							navigate('/art/', { state: {
								fields: { import_id: props.import.id }
							}})
						}}>See art</SearchButton>
						<SubmitButton 
							variant="contained" 
							style={{ "float": "right" }} 
							onClick={() => {
								props.setUploadImages(true)
							}}
							disabled={props.import.inserted_image >= props.import.inserted_image_id}
						>Upload Images</SubmitButton>
					</>
					:
					<SearchButton variant="contained" style={{ backgroundColor: '#4465D1'}} onClick={() => {
						navigate('/contacts/', { state: {
							fields: { import_id: props.import.id }
						}})
					}}>See contacts</SearchButton>
				}
			</div>
		</>
	}
	// Import Fails or Invalid
	else if (props.import.status.value === "Invalid"
		|| props.import.status.value === "Failed") {
		return <>
			<p style={{ textAlign: 'center' }}>There was a problem with this import.</p>
		</>
	}
	// Import is uploading or still processing
	else if (props.import.status.value == "Uploading" || props.import.status.value == "Processing") {
		return <>
			<p style={{ textAlign: 'center' }}>This import is still {props.import.status.value.toLowerCase()}.</p>
		</>
	}
	else {
		return <>
			<p style={{ textAlign: 'center' }}>Unknown import status.</p>
		</>
	}
}

export default withApollo(ImportQuickViewData)
