import { gql } from '@apollo/client'
import { userDataCompressed } from '../../common/fragments'

export const ContactArtFragment = gql`
fragment ContactArtFragment on DealEntry {
	id
	deal_id
	is_private
	art_id
	modified_at
	created_at
	art {
	  id
	  code_name
	  is_private
	  created_at
	  imgUrl (thumbnailResolution: $thumbnailResolution)
	  title
	  formatted_title 
	  inventory_number
	  inventory_number_prefix
	  inventory_number_suffix
	  year
	  status_id
	  artist {
		id
		first_name
		last_name
	  }
	  current_sources {
		id
		code_name
		first_name
		last_name
		is_company
		company_name
		is_deleted
		is_private
		imgUrl (thumbnailResolution: $thumbnailResolution)
		canNavigate
		isAllowedContactType
		dynamic_generated_codename
		created_at
		deal_entry_status_category
		deal_entry_status
		contact_role_id
	  }
	}
	status_note
	start_at
	offer_amount
	offer_currency {
	  id
	  label
	}
	has_reserve
	reserve_position
	reserve_created_at
	reserve_begin_at
	reserve_end_at
	counter_offer_amount
	counter_offer_currency {
	  id
	  label
	}
	end_at
	is_interested
	sale_currency {
	  id
	  label
	}
	sale_amount
	deal_entry_status {
	  id
	  value
	  deal_type {
		id
	  }
	  workflow_step
	  workflow_end
	  workflow_graph
	  deal_entry_status_category {
		id
		value
	  }
	}
	deal {
	  id
	  is_gallery_private
	  is_private
	  publicAccess
	  code_name
	  created_at
	  type_id
	  start_at
	  end_at
	  contacts (primaryOnly: true) {
		id
		code_name
		is_private
		preferred_name
		last_name
		is_company
		first_name
		company_name
		imgUrl (thumbnailResolution: $thumbnailResolution)
		is_deceased
		contactRole {
		  id
		  value
		}
		dynamic_generated_codename
		created_at
		ownership_percentage
	  }
	  restrictedToUsers {
		id
		first_name
		last_name
		gallery
		title
		department
		imgUrl
		assistants {
		  id
		  first_name
		  last_name
		  gallery
		  imgUrl
		  permissions {
			permission_id
			permission_value_id
		  }
		}
	  }
	  
	  salesperson {
		...userDataCompressed
		is_lead
		is_deleted
	  }
	}
	reserveQueue {
	  hasReserve {
		id
		first_name
		last_name
		imgUrl
	  }
	  reserve_begin_at
	  reserve_end_at
	  queue_length
	}
  }
  ${userDataCompressed}
`

export const GET_CONTACT_ART_COLLECTION = gql`
	query getContactArtCollection(
		$CommonRequest: CommonRequest!
		$thumbnailResolution: String = "128x128"
		$dealEntryStatusIds: [ID]
	) {
		getContactArtCollection(input: $CommonRequest, dealEntryStatusIds: $dealEntryStatusIds) {
			cursor
			totalItems
			items {
				...ContactArtFragment
			}
		}
	}
	${ContactArtFragment}
`

export const GET_CONTACT_ART_COLLECTION_NAV = gql`
	query getContactArtCollection(
		$CommonRequest: CommonRequest!
	) {
		getContactArtCollection(input: $CommonRequest) {
			cursor
			totalItems
			items {
				id
				deal_id
				deal {
					id
					is_gallery_private
					is_private
					created_at
				}
			}
		}
	}
`

export const GET_CONTACT_CONSIGNMENTS = gql`
	query getContactConsignments(
		$dealEntryRequest: GetDealEntriesMultipleCategoriesRequest!
		$thumbnailResolution: String = "128x128"
	) {
		getDealEntriesMultipleCat(input: $dealEntryRequest) {
			cursor
			totalItems
			items {
				...ContactArtFragment
			}
		}
	}
	${ContactArtFragment}
`

export const GET_CONTACT_CONSIGNMENTS_NAV = gql`
	query getContactConsignments(
		$dealEntryRequest: GetDealEntriesMultipleCategoriesRequest!
	) {
		getDealEntriesMultipleCat(input: $dealEntryRequest) {
			cursor
			totalItems
			items {
				id
				deal_id
				deal {
					id
					is_gallery_private
					is_private
					created_at
				}
			}
		}
	}
`

export const GET_CONTACT_KNOWN_WORKS = gql`
	query getContactKnownWorks(
		$commonRequest: CommonRequest!
		$thumbnailResolution: String = "128x128"
	) {
		getContactKnownWorks(input: $commonRequest) {
			cursor
			totalItems
			items {
				...ContactArtFragment
			}
		}
	}
	${ContactArtFragment}
`

export const GET_CONTACT_KNOWN_WORKS_NAV = gql`
	query getContactKnownWorks(
		$commonRequest: CommonRequest!
	) {
		getContactKnownWorks(input: $commonRequest) {
			cursor
			totalItems
			items {
				id
				deal_id
				deal {
					id
					is_gallery_private
					is_private
					created_at
				}
			}
		}
	}
`
