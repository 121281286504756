import { TextField } from "@mui/material"
import { styled } from "@mui/system"

const TextBoxThinForAutocomplete = styled(TextField)(({ theme }) => ({
	"& .MuiOutlinedInput-root": {
		paddingTop: 3.5,
		paddingBottom: 3.5,
	},
}))

export default TextBoxThinForAutocomplete
