/* eslint-disable eqeqeq */
import React, { useContext, useState } from 'react'
import DealQuickView from '../../Deals/DealQuickView'
import { LookupContext } from '../../store'
import { withApollo } from '@apollo/client/react/hoc'
import ArtQuickView from '../../Art/ArtPiece/ArtQuickView'
import UserAccessQuickView from '../../QuickView/UserAccessQuickView'
import ArtCollectionSearch from '../../Search/ArtCollectionSearch'
import { deal_entry_status_categories } from '../../DealEntry/constants'
import ContactDealEntrySearch from '../Contact/ContactDealEntrySearch'
import Purchases from './Purchases'
import ContactDealList from './ContactDealList'
import KnownWorks from './KnownWorks'


const ArtCollectionAndKnownWorks = (props) => {

	let { loading } = props

	const lookup = useContext(LookupContext)

	const dealCategory = {
		consignmentIn: "4",
		consignmentOut: "5",
		galleryPurchase: "6",
		loanIn: "7",
		loanOut: "8"
	}

	const dealEntryStatus = {
		galleryPurchaseReceived: "10",
		loanInSold: "14",
		consignmentInSold: "22",
		galleryPurchaseLegacy: "34"
	}

	const [selectedDeal, setSelectedDeal] = useState(null)

	const [selectedArt, setSelectedArt] = React.useState(null)
	// eslint-disable-next-line no-unused-vars

	const dealEntryStatusCategories = lookup.data?.getDealEntryStatusCategories
	
	React.useEffect(() => {
		if (selectedDeal?.is_private && !Object.keys(selectedDeal?.reserveQueue || {}).length) {
			props.setQVOverride(() => <UserAccessQuickView 
				entity={selectedDeal} 
				idOverride="deal_id"
				onClose={() => setSelectedDeal(null)}
			/>) 
			setSelectedArt(null)
			return
		}

		else if (selectedDeal?.deal_id) {
			props.setQVOverride(() => <DealQuickView 
				id={selectedDeal.deal_id}
				onClose={() => setSelectedDeal(null)}
			/>) 
			setSelectedArt(null)
			return
		}

		else if (!selectedDeal?.deal_id && !selectedArt) {
			props.setQVOverride(null) 
			return
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDeal])

	React.useEffect(() => {
		if (selectedArt) {
			props.setQVOverride(() => <ArtQuickView 
				id={selectedArt.id}
				onClose={() => setSelectedArt(null)}
			/>)
			setSelectedDeal(null)
			return
		}

		else if (!selectedArt && !selectedDeal) {
			props.setQVOverride(null) 
			return
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedArt])

	return props.findMode ? (
		<>
			<ContactDealEntrySearch category={dealEntryStatusCategories?.find((e) => e.id == deal_entry_status_categories.purchases)}/>
			<ContactDealEntrySearch category={dealEntryStatusCategories?.find((e) => e.id == deal_entry_status_categories.consignment_in)}/>
			<ArtCollectionSearch />
		</>
	) : (
		<>
			
			<Purchases
				contact={props.state?.getContact}
				setQVOverride={props.setQVOverride}
				loading={loading}
				findMode={props.findMode}
				style={{
					marginRight: '1em'
				}}
			/>

			<ContactDealList
				contact={props.state?.getContact}
				setQVOverride={props.setQVOverride}
				loading={loading}
				findMode={props.findMode}
				style={{
					marginRight: '1em'
				}}
				title={"Consignments"}
				dealEntryStatusCategories={[dealCategory.consignmentIn, dealCategory.consignmentOut]}
			/>

			<ContactDealList
				contact={props.state?.getContact}
				setQVOverride={props.setQVOverride}
				loading={loading}
				findMode={props.findMode}
				style={{
					marginRight: '1em'
				}}
				title={"Loans"}
				dealEntryStatusCategories={[dealCategory.loanIn, dealCategory.loanOut]}
			/>

			<ContactDealList
				contact={props.state?.getContact}
				setQVOverride={props.setQVOverride}
				loading={loading}
				findMode={props.findMode}
				style={{
					marginRight: '1em'
				}}
				title={"Sold"}
				dealEntryStatusCategories={[dealCategory.consignmentIn, dealCategory.galleryPurchase, dealCategory.loanIn]}
				dealEntryStatusIds={[dealEntryStatus.galleryPurchaseReceived, dealEntryStatus.loanInSold, dealEntryStatus.consignmentInSold, dealEntryStatus.galleryPurchaseLegacy]}
			/>

			<KnownWorks
				contact={props.state?.getContact}
				setQVOverride={props.setQVOverride}
				loading={loading}
				findMode={props.findMode}
				style={{
					marginRight: '1em'
				}}
			/>
		</>
	)
}

export default withApollo(ArtCollectionAndKnownWorks)
