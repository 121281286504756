/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Undo } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Avatar, Button, IconButton, Menu, MenuItem, Paper, Tooltip } from '@mui/material'
import {
	DataGridPro,
	GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector
} from '@mui/x-data-grid-pro'
import clsx from 'clsx'
import { useContext, useEffect, useState } from 'react'
import CustomAvatarGroup from '../common/components/CustomAvatarGroup'
import DataGridFlagMenu from '../common/components/DataGridFlagMenu'
import FavoriteAndFlag from '../common/FavoriteAndFlag'
import { elementReady, formatDate, getDealName, getListHeader, restoreColumnSettings, getArtistThumbnailDetail } from '../common/helpers'
import { filteredPages } from '../navigation/routes'
import { AuthStateContext } from '../store'
import ContactThumbnail from '../Thumbnail/ContactThumbnail'
import ImageIcon from '@mui/icons-material/Image'
import GalleryContactList from '../common/components/GalleryContactList'
import DealThumbnail from './DealThumbnail'
import { OWNER_CONTACT_ROLE, PRIMARY_CONTACT_ROLE } from '../DealEntry/DealEntryList'
import { dealTypes } from '../constants/values'
import Source from '../Art/ArtPiece/Source'

function arraymove(arr, fromIndex, toIndex) {
	const element = arr[fromIndex]
	arr.splice(fromIndex, 1)
	arr.splice(toIndex, 0, element)
}

function getCols({
	loading,
	favoriteAndFlag,
	actionMenu,
	flagMenu,
	density
}) {
	/** @type GridColDef[] */
	const columns = [
		{
			field: 'Actions',
			headerName: '',
			minWidth: 50,
			width: 50,
			resizable: false,
			sortable: false,
			renderCell: actionMenu
	  	},
		{
			field: 'isFlagged',
			headerName: '',
			renderHeader: flagMenu,
			width: 50,
			renderCell: favoriteAndFlag,
			sortable: false,
			resizable: false,
			headerClassName: 'flag-header-data-grid'
		},
		{
			field: 'sortable_name',
			headerName: 'Name',
			width: 150,
			renderCell: params => {

				const { is_private, is_gallery_private, created_at } = params.row

				return <DealThumbnail 
					is_private={is_private}
					is_gallery_private={is_gallery_private}
					created_at={created_at}
					deal_name={getDealName(params.row)}
				/>
			}
		},
		{
			field: 'searchable_deal_type',
			width: 200,
			headerName: 'Type',
			renderCell: params => {

				return <>

					{params.row.is_private || params.row.is_gallery_private ? (
						<span>-</span>
					) : (
						<span>{params.row?.deal_type?.value || '-'}</span>
					)}
				</>

			}
		},
		{
			field: 'contact_name',
			width: 350,
			headerName: 'Contacts',
			sortable: false,
			renderCell: params => {

				const { row } = params
					
				if (params.row.is_private || params.row.is_gallery_private) return <span>-</span>

				if (row.type_id == dealTypes.known_ownership) {

					const owners = row?.contacts?.filter(e => e.contactRole?.id == OWNER_CONTACT_ROLE).map(e => ({
						...e,
						deal_entry_status_category: 'Known Source'
					}))

					if (!owners?.length) return <span>-</span>

					return (
						<Source
							loading={false} 
							canViewOwnerContact={true} 
							sources={owners} 
							compact={params?.api?.state?.density?.value === 'compact'}
							avatarGroupStyles={{
								marginLeft: '0.75em',
								marginRight: '0.75em'
							}}
							thumbnailStyle={{
								marginLeft: '0.5em',
								marginRight: '0.5em'
							}}
						 />
					)
				}

				const primaryContact = params.row.contacts?.find(e => e.contactRole?.id == PRIMARY_CONTACT_ROLE)

				return primaryContact ? (
					<div
						style={{
							backgroundColor: primaryContact.is_private ? '#2E3134' : null,
							textAlign: 'left',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
							padding: '0 .5em',
							borderRadius: 8,
						}}
					>
						<ContactThumbnail contact={primaryContact} darkText={!primaryContact.is_private} />
					</div>
				) : (
					<span>-</span>
				)
			}
		},
		{
			field: 'art_title',
			width: 150,
			headerName: 'Artworks',
			renderCell: params => {


				const row = params.row
				const isPrivate = params.row.is_private || params.row.is_gallery_private

				return <>
					{isPrivate ? (
						<span>-</span>
					) : (
						<>
							<div id="square-avatar-group">
								<CustomAvatarGroup
									max={2}
									extraavatarstooltiptitle={
										row.art?.map((ar, index) =>
											index !== 0 ? (
												<p key={index} style={{ margin: 0, padding: 0 }}>
													{ar.code_name}
												</p>
											) : null
										) || null
									}
								>
									{row.art &&
										row.art
											.sort((a, b) => {
												if (a.imgUrl && !b.imgUrl) return -1 // Return art with images first
												return 0
											})
											.map((art, index) => {
												
												const title = art.code_name || `${getArtistThumbnailDetail(art)}, ${art.year}`

												if (index == 0) {
													return (
														<Tooltip key={index} title={title} arrow>
															<Avatar
																style={{ marginRight: '10px' }}
																alt={title}
																src={art.imgUrl}
																variant="square"
															>
																{!art.imgUrl ? <ImageIcon /> : null}
															</Avatar>
														</Tooltip>
													)
												}
												return (
													<Tooltip key={index} title={title} arrow>
														<Avatar alt={title} src={art.imgUrl} variant="square">
															{!art.imgUrl ? <ImageIcon /> : null}
														</Avatar>
													</Tooltip>
												)
											})}
								</CustomAvatarGroup>
							</div>
							{row.art?.length == 0 ? <span>-</span> : null}
						</>
					)}
				</>
			}
		},
		{
			field: 'salesperson_first_name',
			width: 150,
			headerName: 'Gallery Contact',
			sortable: false,
			renderCell: params => {
				return <GalleryContactList
					salespersons={params.row?.salesperson}
					findMode={false}
					max={2}
				/>
			}
		},
		{
			field: "modified_at",
			headerName: "Modified At",
			sortable: true,
			width: 130,
			renderCell: params => {
				const fDate = params.row?.modified_at ? formatDate( params.row?.modified_at, "MMM' 'dd', 'yyyy" ) : "-"
				return <span>{fDate}</span>
			}
		},
		{
			field: "created_at",
			headerName: "Created At",
			sortable: true,
			width: 130,
			renderCell: params => {
				const fDate = params.row?.created_at ? formatDate( params.row?.created_at, "MMM' 'dd', 'yyyy" ) : "-"
				return <span>{fDate}</span>
			}
		},

	]
	return columns
}

/**
 * @typedef DealsDataGrid
 * @property {Object[]} rows All rows of {Deals} to be displayed
 * @property {function} setRows
 * @property {boolean} loading If a loading is in progress
 * @property {function} saveAndGo Function called on row doubleClick
 * @property {string} mainSearchPage
 * @property {filterComparator} userId
 * @property {Object} selectedRow
 * @property {callback} onSelect
 * @property {filterComparator} totalItems
 * @property {number} limit
 * @property {Object} variables
 * @property {function} setLimit
 * @property {number} activeStep
 * @property {function} setActiveStep
 * @property {Object} sortModel
 * @property {function} onSortModelChange
 * @property {boolean} noScrollToTop do not scroll to top of page on pagination
 */

/**
 * @param {DealsDataGrid} props
 */
export function DealsDataGrid (props) {

	const columnSettingsKey = 'DealsDataGrid'

	const authState = useContext(AuthStateContext)

	// await these three, make the function async, and wrap it in a suspense?
	const userPermissions  = authState?.user?.permissions

	// handle null response.
	const rows = props.rows || []

	const [density, setDensity] = useState(
		localStorage.getItem(`${columnSettingsKey}.density`) || 'comfortable')
	useEffect(() => localStorage.setItem(`${columnSettingsKey}.density`, density),
		[density])

	const [columnVisibilityModel, setColumnVisibilityModel] = useState(
		JSON.parse(localStorage.getItem(`${columnSettingsKey}.visibility`) || null)
	)
	useEffect(() => localStorage.setItem(`${columnSettingsKey}.visibility`, JSON.stringify(columnVisibilityModel)),
		[columnVisibilityModel])

	const favoriteAndFlag = params => {

		if ((params.row.is_private || params.row.is_gallery_private) && !params.row.created_at)
			return <></>

		if (params.row.publicAccess == null)
			return <></>

		return (
			<FavoriteAndFlag
				entityName="deal"
				row={params.row}
				mainSearchPage={props.mainSearchPage}
				userId={props?.userId}
				setRows={props.setRows}
				setEntity={props.setDeals}
				listName={props.listName}
				setFlaggedCount={props.setFlaggedCount}
				compact={density === 'compact'}
				hidden={(params.row.is_private || params.row.is_gallery_private) && !params.row.created_at}
			/>
		)
	}

	const flagMenu = params => {

		if (props.disableFlagMenu) return null

		return (
			<DataGridFlagMenu
				currentFilters
				filterTo={props.filterTo}
				variables={props.variables}
				setFlaggedCount={props.setFlaggedCount}
				setRows={props.setRows}
				rows={props.rows}
				totalItems={props.totalItems}
				type={props.type}
			/>
		)
	}
	const openContextMenu = (field) => (e) => {
		e.preventDefault()
		e.stopPropagation()
		setAnchorEl(e[field])
	}


	const actionMenu = params => {

		if ((params.row.is_private || params.row.is_gallery_private) && !params.row.created_at)
			return <></>

		if (params.row.publicAccess == null)
			return <></>

		return (
			<IconButton
				aria-label="More"
				style={{
					padding: '6px',
					marginRight: '-9px',
				}}
				onClick={e => {
					if (params.row.disabled || params.row.hidden) return
					setMenuRowId(params.id)
					openContextMenu('currentTarget')(e)
				}}
			>
				<MoreVertIcon
					style={ (params.row.is_private) ? { color: 'white' } : null }
				/>
			</IconButton>
		)
	}

	const [menuRowId, setMenuRowId] = useState(null)
	const [anchorEl, setAnchorEl] = useState(null)
	const [columns, setColumns] = useState([])

	// Set up grid with loaded dependencies, and saved data.
	const updateColumns = () => {

		const cols = getCols({
			loading: props.loading,
			favoriteAndFlag,
			actionMenu,
			flagMenu,
			density
		})

		// restore on load.
		const columnSettings = JSON.parse(localStorage.getItem(columnSettingsKey))
		if (columnSettings?.length) restoreColumnSettings(cols, columnSettings)

		if (!columnVisibilityModel) {
			const obj = cols.filter(c => c.hide)
				.reduce((acc, el) => {
					acc[el.field] = false
					return acc
				}, {})
			setColumnVisibilityModel(obj)
		}

		setColumns([...cols])
	}

	// on Load, load column settings, sort, merge in (once everything is loaded.)
	useEffect(() => {
		if (props.userId) updateColumns()
	}, [props.userId, density])

	// update the flag menu/toggle any time the props change
	// to prevent "stale closures"
	useEffect(() => {
		setColumns(cols => {
			const flagCol = cols.find(c => c.field === 'isFlagged')
			if (flagCol) {
				flagCol.renderHeader = flagMenu
				flagCol.renderCell = favoriteAndFlag
				flagCol.width = (density === 'compact') ? 80 : 50
			}
			return [...cols]
		})
	}, [props.variables, props.totalItems, props.userId, density])

	// on unmount, stash columns back in storage. (eventually in DB)
	useEffect(() => {

		// stash on nav. (`columns` needs to be in the deps in order to be accessed.)
		return () => {
			if (columns.length) {
				const columnSettings = columns.map(col => ({
					field: col.field,
					width: col.width,
					minWidth: col.minWidth,
					hide: col.hide
				}))
				localStorage.setItem(columnSettingsKey, JSON.stringify(columnSettings))
			}
		}
	}, [columns])

	const handleClose = () => setAnchorEl(null)

	const resetGrid = () => {
		localStorage.removeItem(columnSettingsKey)
		localStorage.removeItem(`${columnSettingsKey}.visibility`)
		setColumnVisibilityModel(null)
		updateColumns()
	}

	const CustomToolBar =  () => <GridToolbarContainer>
		<Button
			size="small"
			startIcon={<Undo />}
			onClick={resetGrid}
		>
			Reset
		</Button>
		<GridToolbarColumnsButton />
		<GridToolbarDensitySelector />
	</GridToolbarContainer>


	// Scroll to the top of current data grid component when paginating
	useEffect(() => {

		if (props.loading === false && !props.scrollIntoView) {

			elementReady(`.${columnSettingsKey}`)
				.then(searchList =>
					searchList.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					})
				)
		}

	}, [props.activeStep])


	return <>
		<Paper
			className={clsx({
				'data-grid-padding': true,
				[columnSettingsKey]: true
			})}
			data-testid={`card-${props.listHeader ? props.listHeader : "Results "}`}
			style={{
				...props.style,
				flexGrow: 1
			}}
		>
			<h1 className="card-title" style={{
				paddingTop: '1em',
				paddingLeft: '2em'
			}}>
				{getListHeader(props.listHeader, props.totalItems, props.canSee)}
			</h1>

			<div style={{ height: 'calc(100% - 4em)', width: '100%' }}>
				<DataGridPro

					getRowClassName={(params) => params.row.is_private  ? 'Private-DataGrid-Row' : null}

					components={{ Toolbar: CustomToolBar }}
					rowCount={props.totalItems || 0}
					rows={rows}
					columns={columns}
					onRowDoubleClick={({row}) => {
						if ((row.is_private || row.is_gallery_private) && !row.created_at) return
						if (row.publicAccess == null) return
						props.saveAndGo(`/deals/${row.id}`, row.id)
					}}
					autoHeight={props.alwaysAutoHeight ?? false}
					density={density}
					disableColumnMenu
					disableMultipleSelection
					pagination
					paginationMode="server"
					sortingMode="server"
					pageSize={props.limit}
					onPageSizeChange={props.setLimit}
					rowsPerPageOptions={[10, 25, 50]}
					onRowClick={(params, event) => {
						let row = params.row
						if (event.metaKey) {
							if ((row.is_private || row.is_gallery_private) && !row.created_at) return
							if (row.publicAccess == null) return
							window.open(`/deals/${row.id}`, "_blank")
						} else {
							props.onSelect(row)
						}
					}}
					selectionModel={props.selectedRow?.id ? [props.selectedRow?.id] : []}
					page={props.activeStep}
					onPageChange={props.setActiveStep}
					onSortModelChange={props.onSortModelChange}
					loading={props.loading}
					onColumnVisibilityModelChange={setColumnVisibilityModel}
					columnVisibilityModel={columnVisibilityModel || {}}
					onColumnOrderChange={({ oldIndex, targetIndex }) => {
						arraymove(columns, oldIndex, targetIndex)
						setColumns([...columns])
					}}
					onColumnWidthChange={({ colDef, width}) => {
						columns.find(col => col.field === colDef.field).width = width
						setColumns([...columns])
					}}
					onStateChange={(args) => setDensity(args.density?.value ?? 'comfortable')}
				/>
			</div>

			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={!!anchorEl}
				onClose={handleClose}
			>
				{
					filteredPages('dealPages', userPermissions)
						.map(page =>
							<MenuItem
								key={ page.name }
								onClick={() => {
									props.saveAndGo(`/deals/${menuRowId}/${page.route}`, menuRowId)
									handleClose()
								}}
							>
								{ page.name }
							</MenuItem>
						)
				}
			</Menu>
		</Paper>
	</>

}
