import React from 'react'
import withStyles from '@mui/styles/withStyles'
import Switch from '@mui/material/Switch'
import * as Colors from '../../styles/colors/Colors'

const Toggle = withStyles(theme => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1),
	},
	switchBase: {
		'& + $track': {
			backgroundColor: Colors.toggleBackground
		},
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: Colors.nav,
			'& + $track': {
				backgroundColor: '#52d869',
				opacity: 1,
				border: 'none',
			},
		},
		'&$focusVisible $thumb': {
			color: 'white',
			border: '6px solid #fff',
		},
	},
	thumb: {
		width: 24,
		height: 24,
		color: 'white',
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid #E4E4E5`,
		backgroundColor: '#52d869',
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border']),
		height: "auto"
	},
	checked: {},
	focusVisible: {},
}))(({ classes, ...props }) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked,
			}}
			{...props}
		/>
	)
})

export default Toggle
