/* eslint-disable eqeqeq */
import React, { useContext, useCallback, useEffect } from 'react'
import ArtList from './../../../Art/ArtList'
import ArtQuickView from './../../../Art/ArtPiece/ArtQuickView'
import { DispatchContext, AuthStateContext } from '../../../store'
import { severity } from '../../../Snackbar/CustomizedSnackbar'
import { DYNAMIC_ART_SEARCH } from '../../../Search/Queries'
import { withApollo } from '@apollo/client/react/hoc'
import useNavigation from '../../../navigation/useNavigation'
import UserAccessQuickView from '../../../QuickView/UserAccessQuickView'
import { useParams, useNavigate, useLocation } from 'react-router-dom'

const pageSize = 10

const ArtistArtPage = (props) => {

	const navigate = useNavigate()
	const location = useLocation()
	const params = useParams()
	const { push: pushNav } = useNavigation()
	const prevSearch = location.state

	const [availableArts, setAvailableArts] = React.useState(prevSearch?.availableArts ?? [])
	const [otherArts, setOtherArts] = React.useState(prevSearch?.otherArts ?? [])
	const [selectedRow, setSelectedRow] = React.useState(null)

	const [availableOrder, setAvailableOrder] = React.useState(prevSearch?.availableOrder ?? "asc")
	const [availableOrderBy, setAvailableOrderBy] = React.useState(prevSearch?.availableOrderBy ?? "title")
	const [availableSteps, setAvailableSteps] = React.useState(prevSearch?.availableSteps ?? 1)
	const [availableActiveStep, setAvailableActiveStep] = React.useState(prevSearch?.availableActiveStep ?? 0)
	const [availableTotalItems, setAvailableTotalItems] = React.useState(prevSearch?.availableTotalItems ?? null)

	const [nonAvailableOrder, setNonAvailableOrder] = React.useState(prevSearch?.nonAvailableOrder ?? "asc")
	const [nonAvailableOrderBy, setNonAvailableOrderBy] = React.useState(prevSearch?.nonAvailableOrderBy ?? "title")
	const [nonAvailableSteps, setNonAvailableSteps] = React.useState(prevSearch?.nonAvailableActiveStep ?? 1)
	const [nonAvailableActiveStep, setNonAvailableActiveStep] = React.useState(prevSearch?.nonAvailableActiveStep ?? 0)
	const [nonAvailableTotalItems, setNonAvailableTotalItems] = React.useState(prevSearch?.nonAvailableTotalItems ?? null)

	const [availableLoading, setAvailableLoading] = React.useState(false)
	const [otherLoading, setOtherLoading] = React.useState(false)


	// User
	const userAuthentication = useContext(AuthStateContext)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	let { loading, error } = props

	const onSelect = (row) => {
		setSelectedRow(row)
	}


	// Save Search
	function updateHistory (available, row) {
		const savedSearch = {
			available,
			selectedRow,
			otherArts, availableArts,
			availableOrderBy, nonAvailableOrderBy,
			availableOrder, nonAvailableOrder,
			availableSteps, nonAvailableSteps,
			availableActiveStep, nonAvailableActiveStep, 
			availableTotalItems, nonAvailableTotalItems,
			searchPage: location.pathname
		}
		const offset = Math.max(
			availableArts.findIndex(a => a.id == row),
			otherArts.findIndex(a => a.id == row)
		)
		const search = {
			activeStep: available ? availableActiveStep : nonAvailableActiveStep, 
			orderBy: available ? availableOrderBy : nonAvailableOrderBy,
			order: available ? availableOrder : nonAvailableOrder,
		}
		const cursor = search.activeStep * pageSize + offset
		const variables =  {
			cursor,
			limit: pageSize,
			field: search.orderBy,
			direction: search.order.toUpperCase(),
			filters: [
				{field: "artist_id", type: "eq", value: props.state.getArtist.id},
				{
					field: "art_status_value", 
					type: available ? "eq" : "neq", 
					value: "Available"
				}
			]
		}
		variables.limit = 3
		savedSearch.variables = variables
		navigate(location.pathname, { state: savedSearch })
		return savedSearch
	}

	// Deselect Row on change of Main Entity
	useEffect(() => {
		setSelectedRow(null)
	}, [props.id])

	const searchAvailableArt = ({cursor = 0} = {}) => {

		if (!props.state.getArtist) return

		setAvailableLoading(true)
		props.client
			.query({ query: DYNAMIC_ART_SEARCH, variables: {
				cursor,
				limit: pageSize,
				field: availableOrderBy,
				direction: availableOrder.toUpperCase(),
				filters: [
					{field: "artist_id", type: "eq", value: props.state.getArtist.id},
					{field: "art_status_value", type: "eq", value: "Available"}
				]
			}})
			.then(({data}) => {

				if (data.searchDynamicArt?.items) {
					setAvailableArts(data.searchDynamicArt.items)
		
					if (data.searchDynamicArt.items < 1) setAvailableSteps(1)
					else setAvailableSteps((Math.ceil(data.searchDynamicArt.totalItems / pageSize)))
			
					setAvailableTotalItems(data.searchDynamicArt.totalItems || 0)
				} else {
					openSnackbar(severity.ERROR, "There was an error loading available art.")
				}

				setAvailableLoading(false)
			})
			.catch((error) => {
				setAvailableLoading(false)
				console.error(error)
				openSnackbar(severity.ERROR, "Could not load available art.")
			})
	}

	const searchOtherArt = ({cursor = 0} = {}) => {

		if (!props.state.getArtist) return

		setOtherLoading(true)
		props.client
			.query({ query: DYNAMIC_ART_SEARCH, variables: {
				cursor,
				limit: pageSize,
				field: nonAvailableOrderBy,
				direction: nonAvailableOrder.toUpperCase(),
				filters: [
					{field: "artist_id", type: "eq", value: props.state.getArtist.id},
					{field: "art_status_value", type: "neq", value: "Available"}
				]
			}})
			.then(({data}) => {

				if (data.searchDynamicArt?.items) {
					setOtherArts(data.searchDynamicArt.items)
		
					if (data.searchDynamicArt.items < 1) setNonAvailableSteps(1)
					else setNonAvailableSteps((Math.ceil(data.searchDynamicArt.totalItems / pageSize)))
			
					setNonAvailableTotalItems(data.searchDynamicArt.totalItems || 0)
				} else {
					openSnackbar(severity.ERROR, "There was an error loading un-available art.")
				}
				setOtherLoading(false)
			})
			.catch((error) => {
				console.error(error)
				openSnackbar(severity.ERROR, "Could not load other art.")
				setOtherLoading(false)

			})
	}

	// Search on page load, after state load
	useEffect(() => {

		if (Object.keys(props.state?.getArtist || {}).length) {
			searchOtherArt(0)
			searchAvailableArt(0)
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.state])


	// Quick nav
	useEffect(() => {
		
		setAvailableArts([])
		setOtherArts([])

		setSelectedRow(null)

		setAvailableOrder("asc")
		setAvailableOrderBy("title")
		setAvailableSteps(1)
		setAvailableActiveStep(0)
		setAvailableTotalItems(null)

		setNonAvailableOrder("asc")
		setNonAvailableOrderBy("title")
		setNonAvailableSteps(1)
		setNonAvailableActiveStep(0)
		setNonAvailableTotalItems(null)

	}, [params?.id])
	

	// Search on ordering/ pagination
	useEffect(() => {
		searchAvailableArt()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [availableOrder, availableOrderBy])

	// Search on ordering/ pagination
	useEffect(() => {
		searchOtherArt()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nonAvailableOrder, nonAvailableOrderBy])

	React.useEffect(() => {
		if (selectedRow) {
			if (selectedRow.is_private && !selectedRow.created_at)
				props.setQVOverride(() => <UserAccessQuickView
					entity={selectedRow}
					onClose={() => setSelectedRow(null)}
				></UserAccessQuickView>)
			else 
				props.setQVOverride(() => <ArtQuickView
					id={selectedRow.id}
					onClose={() => setSelectedRow(null)}
				/>)
		} else if (selectedRow === null) 
			props.setQVOverride(() => null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRow])

	return !props.findMode && (
		<section className="main-page">
			<div>
				<ArtList
					loading={loading || availableLoading}
					error={error}
					rows={availableArts || []}
					listHeader="AVAILABLE"
					scrollTopOverride="available-works-list"
					setSelectedRow={setSelectedRow}
					selectedRow={selectedRow}
					onSelect={onSelect}
					setRows={setAvailableArts}
					userId={userAuthentication.user?.id}
					setOrder={setAvailableOrder}
					setOrderBy={setAvailableOrderBy}
					order={availableOrder}
					orderBy={availableOrderBy}
					limit={pageSize}
					steps={availableSteps}
					activeStep={availableActiveStep}
					setActiveStep={setAvailableActiveStep}
					handleSubmit={searchAvailableArt}
					totalItems={availableTotalItems}
					saveAndGo={(path, row) => {
						const state = updateHistory(true, row)
						pushNav({ 
							url: location.pathname, 
							state,
							query: DYNAMIC_ART_SEARCH
						}, state.variables.cursor)
						navigate(path, { state })
					}}
				></ArtList>

				<ArtList
					loading={loading || otherLoading}
					error={error}
					rows={otherArts || []}
					listHeader="OTHER WORKS"
					scrollTopOverride="other-works-list"
					setSelectedRow={setSelectedRow}
					selectedRow={selectedRow}
					onSelect={onSelect}
					setRows={setOtherArts}
					userId={userAuthentication.user?.id}
					setOrder={setNonAvailableOrder}
					setOrderBy={setNonAvailableOrderBy}
					order={nonAvailableOrder}
					orderBy={nonAvailableOrderBy}
					limit={pageSize}
					steps={nonAvailableSteps}
					activeStep={nonAvailableActiveStep}
					setActiveStep={setNonAvailableActiveStep}
					handleSubmit={searchOtherArt}
					totalItems={nonAvailableTotalItems}
					saveAndGo={(path, row) => {
						const state = updateHistory(false, row)
						pushNav({ 
							url: location.pathname, 
							state,
							query: DYNAMIC_ART_SEARCH
						}, state.variables.cursor)
						navigate(path, { state })
					}}
				></ArtList>
			</div>
		</section>
	)
}

export default withApollo(ArtistArtPage)
