/* eslint-disable eqeqeq */
import { autoCompleteErrorStyles } from '../styles/makesStyles'
import { MobileDateTimePicker } from '@mui/x-date-pickers-pro'
import { DispatchContext } from './../store'
import { LookupContext } from './../store'
import makeStyles from '@mui/styles/makeStyles'
import { MenuItem, FormControl, Select } from '@mui/material'
import { SearchButton, CancelButton } from './../input/Buttons'
import { severity } from './../Snackbar/CustomizedSnackbar'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import Dropdown from './../input/Dropdown/Dropdown'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import Label from './../input/Label'
import React, { useContext, useEffect } from 'react'
import TextBox from './../input/Text/TextBox'
import TextField from '@mui/material/TextField'
import {
	UPDATE_ENTITY_TASK,
	UPDATE_ART_TASK,
	UPDATE_ARTIST_TASK,
	UPDATE_CONTACT_TASK,
	UPDATE_DEAL_TASK,
	UPDATE_LISTING_TASK
} from './Queries'
import { GET_USERS } from '../User/Queries'
import { useMutation } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { returnDate } from '../common/helpers'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
}))

const EditTask = (props) => {

	const [attempt, setAttempt] = React.useState(false)

	const lookup = useContext(LookupContext)
	const taskTypes = lookup.data?.getTaskTypes
	const departments = lookup.data?.getDepartmentTypes

	const taskStatusTypes = lookup.data?.getTaskStatusTypes

	let {
		currentTask,
		editTaskModal,
		setEditTaskModal,
		updateTask,
		entityId,
		linkField,
		tasks,
		mutationResponse,
		setTasks,
	} = props

	const [updateEntityTask] = useMutation(UPDATE_ENTITY_TASK)
	const [updateArtTask] = useMutation(UPDATE_ART_TASK)
	const [updateArtistTask] = useMutation(UPDATE_ARTIST_TASK)
	const [updateContactTask] = useMutation(UPDATE_CONTACT_TASK)
	const [updateDealTask] = useMutation(UPDATE_DEAL_TASK)
	const [updateListingTask] = useMutation(UPDATE_LISTING_TASK)

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = React.useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const closeSnackbar = () => {
		dispatch({ type: 'closeSnackBar'})
	}

	const typeStyle = {
		fontWeight: 500,
	}

	const classes = useStyles()
	const autoCompleteClasses = autoCompleteErrorStyles()

	const handleResponse = (response) => {

		if (response?.data[mutationResponse]?.success === false) {
			console.error(response?.data[mutationResponse])
			openSnackbar(
				severity.ERROR,
				response?.data[mutationResponse]?.message ||
					'There was an error updating this task.'
			)
		} else if (response?.data[mutationResponse]?.success == true) {
			openSnackbar(
				severity.SUCCESS,
				'Successfully updated task.'
			)
			setEditTaskModal({
				...editTaskModal,
				open: false,
			})

			if (!props.dynamic) {
				setTasks(
					tasks.map((task) => {

						return task.id ==
							response.data[
								mutationResponse
							].task.id
							? response.data[
								mutationResponse
							].task
							: task
					})
				)
			} else {
				setTasks(
					tasks.map((entry) => {
						const {
							approval_at,
							description,
							approved_by_user_id,
							assigned_by_user_id,
							assigned_to_department,
							assigned_to_type,
							assigned_to_users,
							created_at,
							created_by,
							due_at,
							is_completed,
							task_status_id,
							type_id,
						} = response.data[mutationResponse].task

						return entry.id == response.data[mutationResponse].task.id ? {
							...entry,
							approval_at,
							description,
							approved_by_user_id,
							assigned_by_user_id,
							assigned_to_department,
							assigned_to_type,
							assigned_to_users,
							created_at,
							created_by,
							due_at,
							is_completed,
							task_status_id,
							type_id,
						} : entry
					})
				)
			}
		} else {
			console.error(response?.data[mutationResponse])
			openSnackbar(
				severity.ERROR,
				response?.data[mutationResponse]?.message ||
					'Could not update task.'
			)
		}
	}

	const handleError = (error) => {
		console.error(error)
		openSnackbar(
			severity.ERROR,
			'There was an error updating this task.'
		)
	}

	// Async Autocomplete
	const [open, setOpen] = React.useState(false)
	const [options, setOptions] = React.useState(currentTask?.assigned_to_users)
	const [selection, setSelection] = React.useState([])
	const loading =
		open && options?.length === currentTask?.assigned_to_users?.length

	// Set initial selection and options to current entity values
	useEffect(() => {
		setSelection(currentTask?.assigned_to_users || [])
		setOptions(currentTask?.assigned_to_users || [])

		if (props.editTaskModal.open && currentTask?.assigned_to_users?.find(user => user.is_deleted))
			openSnackbar(severity.WARNING, "This task is assigned to one or more deactivated users.")
	}, [currentTask, openSnackbar, props.editTaskModal.open])


	React.useEffect(() => {
		let active = true

		if (!loading) {
			return undefined
		}


		props.client
			.query({
				query: GET_USERS,
			})
			.then((result) => {
				if (active){
					setOptions(result.data.getUsers.items)
				}
			})

		return () => {
			active = false
		}
	}, [loading, props.client])

	return (
		<form
			style={{ minWidth: '70em' }}
			onSubmit={(e) => {
				e.preventDefault()
				setAttempt(true)

				if (
					attempt &&
					((selection.length === 0 &&
						editTaskModal.task.assigned_to_department === 'None') ||
						(selection.length === 0 &&
							!editTaskModal.task.assigned_to_department))
				) {
					openSnackbar(
						severity.WARNING,
						'Please choose either a department or assignees.'
					)
				} else if (
					attempt &&
					(!editTaskModal.task.due_at ||
						!editTaskModal.task.description ||
						editTaskModal.task.description?.length === 0 ||
						!editTaskModal.task.description.replace(/\s/g, '')
							.length)
				) {
					openSnackbar(
						severity.WARNING,
						'Please complete required fields.'
					)
				} else if (
					!(
						(selection.length === 0 &&
							editTaskModal.task.assigned_to_department ===
								'None') ||
						(selection.length === 0 &&
							!editTaskModal.task.assigned_to_department)
					) &&
					!(
						!editTaskModal.task.due_at ||
						!editTaskModal.task.description ||
						editTaskModal.task.description?.length === 0 ||
						!editTaskModal.task.description.replace(/\s/g, '')
							.length
					)
				) {
					// Copy variables into task
					let variables = { ...editTaskModal.task }
					const updateVariables = {
						id: variables.id,
						due_at: returnDate(variables.due_at),
						assigned_to_users: variables.assigned_to_users,
						assigned_by_user_id: variables.assigned_by_user_id,
						assigned_to_type: variables.assigned_to_type,
						assigned_to_department:
							variables.assigned_to_department,
						description: variables.description,
						type_id: variables.type_id,
						task_status_id: variables.task_status_id,
					}

					// Selection of ids
					if (selection.length)
						updateVariables.assigned_to_users = selection.map(
							(item) => item.id
						)

					// Case were no department is selected
					if (updateVariables.assigned_to_department === 'None')
						delete updateVariables.assigned_to_department

					// Case where department is selected
					if (updateVariables.assigned_to_department)
						delete updateVariables.assigned_to_users_id

					// Delete extra nulls
					Object.keys(updateVariables).forEach(
						(key) =>
							updateVariables[key] == null &&
							delete updateVariables[key]
					)

					// Purposefully declare required nulls
					if (updateVariables.assigned_to_department)
						updateVariables.assigned_to_users = null
					if (updateVariables.assigned_to_users)
						updateVariables.assigned_to_department = null

					updateVariables.entity_id = entityId
					updateVariables.entity_name = linkField.split('_')[0]

					const updateParams = {
						variables: {
							UpdateTaskInput: updateVariables,
						},
					}

					if (!props.dynamic)
						updateTask(updateParams)
							.then(handleResponse)
							.catch(handleError)

					else {
						switch (props.type) {
						case "Art":
							updateArtTask(updateParams)
								.then(handleResponse)
								.catch(handleError)
							break
						case "Artist":
							updateArtistTask(updateParams)
								.then(handleResponse)
								.catch(handleError)
							break
						case "Contact":
							updateContactTask(updateParams)
								.then(handleResponse)
								.catch(handleError)
							break
						case "Deal":
							updateDealTask(updateParams)
								.then(handleResponse)
								.catch(handleError)
							break
						case "Listing":
							updateListingTask(updateParams)
								.then(handleResponse)
								.catch(handleError)
							break
						case "Entity":
							updateEntityTask(updateParams)
								.then(handleResponse)
								.catch(handleError)
							break
						default:
							updateEntityTask(updateParams)
								.then(handleResponse)
								.catch(handleError)
						}
					}
				}
			}}
		>
			<div className="row" style={{ justifyContent: 'flex-start' }}>
				<FormControl
					className={classes.searchSelection}
					style={{ maxWidth: '14em', paddingRight: '1em' }}
				>
					<Label
						id="type-label"
						style={typeStyle}
						disableAnimation
						shrink
					>
						Type
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						className="padded-select"
						name="type"

						labelId="type-label"
						input={<Dropdown />}
						value={editTaskModal.task?.type_id || ''}
						onChange={(e) => {
							setEditTaskModal({
								...editTaskModal,
								task: {
									...editTaskModal.task,
									type_id: e.target.value,
								},
							})
						}}
					>
						{taskTypes.map((type) => (
							<MenuItem key={type.id} value={type.id}>
								{type.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				{/* TODO: Phase II
				<FormControl
					className={classes.searchSelection}
					style={{ maxWidth: '14em', paddingRight: '1em' }}
				>
					<Label
						id="department-label"
						style={typeStyle}
						disableAnimation
						shrink
					>
						Department
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						disabled
						className="medium-selection"
						name="department"

						labelId="department-label"
						input={<Dropdown />}
						value={
							editTaskModal.task?.assigned_to_department || 'None'
						}
						onChange={(e) => {
							if (e.target.value === 'None') {
								setEditTaskModal({
									...editTaskModal,
									task: {
										...editTaskModal.task,
										assigned_to_department: e.target.value,
										assigned_to_users: currentTask.assigned_to_users.map(
											(user) =>
												options.find(
													(item) => item.id == user.id
												)
										),
										assigned_to_type: 'User',
									},
								})
							} else {
								setSelection([])
								setEditTaskModal({
									...editTaskModal,
									task: {
										...editTaskModal.task,
										assigned_to_department: e.target.value,
										assigned_to_users: null,
										assigned_to_type: 'Department',
									},
								})
							}
						}}
					>
						<MenuItem key={'None'} value={'None'}>
							None
						</MenuItem>
						{departments.map((type) => (
							<MenuItem key={type.id} value={type.id}>
								{type.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				*/}

				<FormControl
					className={classes.searchSelection}
					style={{ maxWidth: '14em', paddingRight: '1em' }}
				>
					<Label
						id="status-label"
						style={typeStyle}
						disableAnimation
						shrink
					>
						Status
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						className="padded-select"
						name="status"

						labelId="status-label"
						input={<Dropdown />}
						value={editTaskModal.task?.task_status_id || ''}
						onChange={(e) => {
							setEditTaskModal({
								...editTaskModal,
								task: {
									...editTaskModal.task,
									task_status_id: e.target.value,
								},
							})
						}}
					>
						{taskStatusTypes?.map((type) => (
							<MenuItem key={type.id} value={type.id}>
								{type.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<div
					className={
						attempt && !editTaskModal.task.due_at ? 'error' : null
					}
				>
					<FormControl
						className={classes.searchDate}
						style={{ minWidth: '23em' }}
					>
						<Label
							id="date-label"
							style={typeStyle}
							disableAnimation
							shrink
						>
							Due Date
						</Label>

						<MobileDateTimePicker
							inputFormat="MMMM do, yyyy 'at' h:mm aaa"
							disableMaskedInput={true}
							cancelText={null}
							error={attempt && !editTaskModal.task.due_at}
							name="date"
							disablePast
							showTodayButton
							todayLabel="Now"
							inputVariant="outlined"
							style={{ marginTop: '17px' }}
							className="MUIDatePicker"
							variant="dialog"
							value={Number(editTaskModal.task?.due_at)}
							renderInput={({ inputRef, inputProps, InputProps }) => {

								const newProps = { ...inputProps}

								newProps.readOnly = false

								return (
									<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
								)}}
							onChange={(date) => {
								setEditTaskModal({
									...editTaskModal,
									task: {
										...editTaskModal.task,
										due_at: date,
									},
								})
							}}
						/>
					</FormControl>
				</div>
			</div>

			<div
				className={
					attempt &&
					((selection.length === 0 &&
						editTaskModal.task?.assigned_to_department ===
							'None') ||
						(selection.length === 0 &&
							!editTaskModal.task?.assigned_to_department))
						? 'error'
						: null
				}
			>
				<FormControl
					className={classes.searchSelection}
					style={{ width: '100%', paddingRight: '0px' }}
				>
					<Label
						id="user-label"
						style={typeStyle}
						disableAnimation
						shrink
					>
						Assignees
					</Label>
					<Autocomplete
						open={open}
						onOpen={() => {
							setOpen(true)
						}}
						onClose={() => {
							setOpen(false)
						}}

						multiple
						isOptionEqualToValue={(a, b) => a.id == b.id}
						popupIcon={<ExpandMoreRoundedIcon />}
						disabled={
							!!editTaskModal.task?.assigned_to_department &&
							editTaskModal.task?.assigned_to_department !==
								'None'
						}
						style={{ marginTop: '1.75em' }}
						options={options}
						loading={loading}
						getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
						value={
							!!editTaskModal.task?.assigned_to_department &&
							editTaskModal.task?.assigned_to_department !==
								'None'
								? []
								: selection
						}
						filterSelectedOptions
						classes={autoCompleteClasses}
						size="small"
						renderInput={(params) => (
							<TextField
								placeholder={
									!!editTaskModal.task
										?.assigned_to_department &&
									editTaskModal.task
										?.assigned_to_department !== 'None'
										? departments.find(
											(item) =>
												item.id ==
													editTaskModal.task
														?.assigned_to_department
										  )?.value
										: null
								}
								error={
									attempt &&
									((selection.length === 0 &&
										editTaskModal.task
											?.assigned_to_department ===
											'None') ||
										(selection.length === 0 &&
											!editTaskModal.task
												?.assigned_to_department))
								}
								{...params}
								variant="outlined"
								fullWidth
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? (
												<CircularProgress
													color="inherit"
													size={20}
												/>
											) : null}
											{
												params.InputProps
													.endAdornment
											}
										</React.Fragment>
									),
								}}
							/>
						)}
						onChange={(event, value) => {
							setSelection(value)
							setEditTaskModal({
								...editTaskModal,
								task: {
									...editTaskModal.task,
									assigned_to_users: value,
								},
							})
						}}
					/>
				</FormControl>
			</div>

			<div
				className={
					attempt &&
					(!editTaskModal.task.description ||
						editTaskModal.task.description?.length === 0 ||
						!editTaskModal.task.description.replace(/\s/g, '')
							.length)
						? 'error'
						: null
				}
			>
				<FormControl
					className={classes.searchBar}
					style={{ width: '100%' }}
				>
					<Label style={typeStyle} disableAnimation shrink>
						Task
					</Label>
					<TextBox
						name="task"
						multiline
						rows="15"
						value={editTaskModal.task?.description}
						onChange={(e) => {
							setEditTaskModal({
								...editTaskModal,
								task: {
									...editTaskModal.task,
									description: e.target.value,
								},
							})
						}}
					/>
				</FormControl>
			</div>

			<div style={{ paddingTop: '1em' }}>
				<SearchButton
					variant="contained"
					size="small"
					type="submit"
					style={{ float: 'right' }}
				>
					Update
				</SearchButton>
				<CancelButton
					variant="contained"
					size="small"
					type="reset"
					style={{ float: 'right', marginRight: '1em' }}
					onClick={() => {
						setAttempt(false)
						setEditTaskModal({
							...editTaskModal,
							open: false,
						})
						closeSnackbar()
					}}
				>
					Cancel
				</CancelButton>
			</div>
		</form>
	)
}

export default withApollo(EditTask)
