import { useMutation } from '@apollo/client'
import { FormControlLabel } from '@mui/material'
import React, { useCallback, useContext } from 'react'
import { CancelButton, SubmitButton } from '../input/Buttons'
import Toggle from '../input/Toggle/UserToggle'
import { UPDATE_LISTING } from '../Listings/Queries'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { DispatchContext } from '../store'

const ShowcaseModal = (props) => {

	const [showcaseInput, setShowcaseInput] = React.useState({})	

	React.useEffect(() => {

		!props.globalAction && setShowcaseInput({
			id: props.entity?.showcase?.id,
			ready: props.entity?.showcase?.ready || props.entity?.showcase_ready,
		})

	}, [props.entity, props.globalAction, props.open])

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const [updateListing, { loading: updateListingLoading }] = useMutation(UPDATE_LISTING, {

		onError: (error) => {
			console.log(error)
			openSnackbar(severity.ERROR, "Could not update showcase.")
		},

		onCompleted: (response) => {

			if (response.updateListing.success === true) {

				openSnackbar(severity.SUCCESS, response.updateListing.message)

				props.setState({
					...props.state,
					getListing: {
						...props.state.getListing,
						showcase_ready: response.updateListing.listing.showcase_ready
					}
				})
				props.requery?.()
				props.close()
			} else {
				openSnackbar(severity.ERROR, response.updateListing.message)
			}
		}
	})
	
	const mutationLoading = updateListingLoading 

	return (
		<TransitionsModal open={props.open} close={props.close}>
			<div style={{ width: '31em' }} className={props.private ? 'private-object' : null}>
				<h1 className="card-title">
					<span style={{ marginTop: 4 }}>
						Showcase
					</span>
					<div className="spacer"></div>

					<FormControlLabel
						control={
							<Toggle
								checked={showcaseInput.ready || false}
								onChange={() => {

									setShowcaseInput({
										...showcaseInput,
										ready: !showcaseInput.ready,
									})
								}}
							/>
						}
						label={showcaseInput.ready  ? 'enabled' : 'disabled'}
						labelPlacement="start"
					/>
				</h1>

				<p style={{color: props.private ? 'white' : null}}>
				Enabling showcase mode will display this {props.entityName || "listing"} in the client facing portion of the iOS application. 
				</p>
				
				<div
					style={{
						width: '100%',
						paddingBottom: '1em',
						marginTop: 'auto',
					}}
				>
					<CancelButton
						variant="contained"
						style={{ float: 'left', width: 78 }}
						onClick={() => {
							setShowcaseInput({})
							props.close()
						}}
					>
						Cancel
					</CancelButton>

					<SubmitButton
						variant="contained"
						style={{ float: 'right', width: 78 }}
						disabled={mutationLoading}
						onClick={() => {

							if (props.listingMode) {
								updateListing({
									variables: { 
										UpdateListingInput: {
											id: props.state.getListing.id,
											showcase_ready: showcaseInput.ready
										} 
									}
								})
								return
							}
						
						}}
					>
						Save
					</SubmitButton>
				</div>
			</div>
		</TransitionsModal>
	)
}

export default ShowcaseModal
