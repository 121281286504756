/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useEffect } from 'react'
import {
	FormControl,
	Select,
	MenuItem,
	FormControlLabel,
	IconButton,
	TextField,
} from '@mui/material'
import Label from '../input/Label'
import Dropdown from '../input/Dropdown/Dropdown'
import TextBox from '../input/Text/TextBox'
import { SaveButton, SearchButton, ResetButton, ChooseImageButton, AdvancedToggleButton } from '../input/Buttons'
import StarIcon from '@mui/icons-material/Star'
import { AuthStateContext, DispatchContext, LookupContext } from '../store'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { searchStyles, autoCompleteStyles } from '../styles/makesStyles'
import sortBy from 'lodash/sortBy'
import Autocomplete from '@mui/material/Autocomplete'
import { GET_USERS } from '../User/Queries'
import { withApollo } from '@apollo/client/react/hoc'
import SearchInfo from "../Search/SearchInfo"
import ControlPoint from '@mui/icons-material/ControlPoint'
import AdvancedFilterCount from '../Search/AdvancedFilterCount'
import { FindModeButton } from '../Search/GlobalSearchFilters'
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro'
import StartTextBox from '../input/Text/StartTextBox'
import EndTextBox from '../input/Text/EndTextBox'
import { SavedSearch } from '../common/SavedSearch'
import { ART_SEARCH_OBJECT } from '../constants/values'
import { handleLastModifiedChange, handleSearchChange, saveMap, saveSearch, searchMap } from '../Search/unifiedSearchHelpers'
import { severity } from '../Snackbar/CustomizedSnackbar'
import ConfirmationInput from '../navigation/ConfirmationDialog/ConfirmationInput'
import { getGalleryContactLabel, randomHSL } from "../common/helpers"
import { useMutation } from '@apollo/client'
import { CREATE_SEARCH } from '../Search/Queries'

const SearchArt = (props) => {

	const lookup = useContext(LookupContext)
	const categories = lookup.data?.getArtCategories
	const sortedCategories = sortBy(categories, [function(o) { return o.value }])
	const artStatus = lookup.data?.getArtStatuses
	const sortedArtStatus = sortBy(artStatus, [function(o) { return o.value }])

	const [options, setOptions] = React.useState([])

	const autoCompleteStyle = autoCompleteStyles()
	const userAuthentication = useContext(AuthStateContext)
	const [currentSearch, setCurrentSearch] = React.useState(props.prevSearch ?? new SavedSearch({
		object_id: ART_SEARCH_OBJECT,
		search_terms: [],
		is_global: true,
		user_id: userAuthentication.user?.id
	}))
	const [savedSearchDialog, setSavedSearchDialog] = React.useState({
		open: false,
		search: null
	})

	// Mutations
	const [createSearch, { loading }] = useMutation(CREATE_SEARCH)

	useEffect(() => {
		if (props.currentSearch) setCurrentSearch(props.currentSearch)
	}, [props.currentSearch])

	const getSearchValue = useCallback((fieldName) =>
		currentSearch.search_terms
			?.find(el => el.field == fieldName)?.value,
	[currentSearch.search_terms])

	const handleArtSavedSearchChange = (event, type = 'eq') => {


		const search_terms = handleSearchChange(
			event,
			currentSearch,
			setCurrentSearch,
			type,
			true
		)

		if (props.currentSearch.id) {
			props.setCurrentSearch({
				...currentSearch,
				search_terms,
				id: null
			})
		}
	}

	const handleArtLastModifiedChange = (value) => {

		const search_terms = handleLastModifiedChange(
			value,
			currentSearch,
			setCurrentSearch
		)

		if (props.currentSearch.id) {
			props.setCurrentSearch({
				...currentSearch,
				search_terms,
				id: null
			})
		}
	}

	React.useEffect(() => {
		let active = true

		if (active && !options.length) {
			props.client
				.query({
					query: GET_USERS,
					variables: {
						includeDeleted: true
					}
				})
				.then(result => {
					if (active)
						setOptions(result.data.getUsers.items)
				})
		}

		return () => active = false
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// This populate the gallery contacts autocomplete from saved searches
	React.useEffect(() => {

		const names = getSearchValue('gallery_contacts')?.split(' AND ')

		if (options.length && getSearchValue('gallery_contacts')) {
			props.setSelection(options.filter(user =>
				 names.includes([user.first_name, user.last_name].join(" "))
			))
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getSearchValue('gallery_contacts')])


	const [firstLoad, setFirstLoad] = React.useState(true)
	React.useEffect(() => setFirstLoad(false), [])

	const classes = searchStyles()

	const typeStyle = {
		fontWeight: 500,
	}

	const dispatch = useContext(DispatchContext)
	const openQuickView = useCallback((severity, text) => {
		dispatch({ type: 'openQuickView' })
	}, [dispatch])

	// Snackbar
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	return <>
		<h1 className="card-title">Search Art

			<ChooseImageButton
				data-testid="new-art-button"
				variant="contained"
				size="small"
				style={{backgroundColor: !props.loading ? '#4465D1' : null}}
				onClick={() => {
					openQuickView()
					props.setCreateArt(true)
				}}
				endIcon={<ControlPoint />}
			>
                Create
			</ChooseImageButton>

			<AdvancedFilterCount
				advancedFilters={props.advancedFilters}
				setAdvancedSearch={props.setAdvancedSearch}
			/>

			<SearchInfo
				advancedFilters={!!props.advancedFilters?.length}
				fields={[
					"Title",
					"Inventory Number",
					"Artist Name (First, Last, and Full Names)",
					"Date",
					"Source",
					"Other Inventory Number",
					"ID"
				]}
				recordName="artwork"
				unified
			/>
		</h1>
		<form
			onSubmit={(e) => {
				e.preventDefault()
				props.setActiveStep(0)
				props.handleSubmit(
					{ cursor: 0 },
					currentSearch.search_terms,
					currentSearch.id
				)
			}}
		>
			<div className="row">
				<FormControl className={classes.searchSelection}>
					<Label
						id="activity-label"
						style={typeStyle}
						disableAnimation
						shrink
					>
                        Active
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						name="isActive" // String
						className="padded-select"

						data-testid="search-activity"
						labelId="activity-label"
						input={<Dropdown />}
						value={ getSearchValue('isActive') || '' }
						onChange={(event) =>
							handleArtSavedSearchChange(event)
						}
					>
						<MenuItem value='' data-testid="search-activity-item">All</MenuItem>
						<MenuItem value='true'  data-testid="search-activity-item">Active</MenuItem>
						<MenuItem value='false' data-testid="search-activity-item">Inactive</MenuItem>
					</Select>
				</FormControl>

				<FormControl className={classes.searchSelection}>
					<Label
						id="grade-label"
						style={typeStyle}
						disableAnimation
						shrink
					>
                        Status
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						name="art_status_value" // String
						className="padded-select"

						data-testid="search-status"
						labelId="status-label"
						input={<Dropdown />}
						value={
							getSearchValue('art_status_value') || ''
						}
						onChange={(event) =>
							handleArtSavedSearchChange(event)
						}
					>
						<MenuItem value='' data-testid="search-status-item">All</MenuItem>
						{sortedArtStatus &&
                            sortedArtStatus.map((type) => (
                            	<MenuItem
                            		key={type.id}
                            		value={type.value}
                            		data-testid="search-status-item"
                            	>
                            		{type.value}
                            	</MenuItem>
                            ))}
					</Select>
				</FormControl>

				<FormControl className={classes.searchSelection}>
					<Label
						id="category-label"
						style={typeStyle}
						disableAnimation
						shrink
					>
                        Category
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						name="art_categories" // String
						className="padded-select"

						data-testid="search-category"
						labelId="category-label"
						input={<Dropdown />}
						value={
							getSearchValue('art_categories') || ''
						}
						onChange={(event) =>
							handleArtSavedSearchChange(event, 'contains')
						}
					>
						<MenuItem value='' data-testid="search-category-item">All</MenuItem>
						{sortedCategories?.map((type) => (
							<MenuItem
								key={type.id}
								value={type.value}
								data-testid="search-category-item"
							>
								{type.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<MobileDateRangePicker
					inputFormat="MMM do, yyyy"
					disableMaskedInput={true}
					componentsProps={{
						actionBar: {
							actions: ['clear', 'accept']
						}
					}}
					value={[
						currentSearch.search_terms
							.find(el => el.field == 'modified_at' && el.type == 'gte')?.value || null,
						currentSearch.search_terms
							.find(el => el.field == 'modified_at' && el.type == 'lte')?.value || null
					]}
					onChange={handleArtLastModifiedChange}
					renderInput={(startProps, endProps) => {

						const newStartProps = { ...startProps}
						const newEndProps = { ...endProps}

						newStartProps.inputProps.placeholder = "Start"
						newEndProps.inputProps.placeholder = "End"

						return (
							<div style={{
								width: '-webkit-fill-available'
							}}>
								<Label
									id="date-label"
									style={{
										...typeStyle,
										fontSize: 18,
										paddingTop: '0.45em',
										marginLeft: '0'
									}}
									disableAnimation
									shrink
								>
								Last Modified
								</Label>
								<div style={{
									marginTop: '-0.6em',
									display: 'flex',
									width: '-webkit-fill-available'
								}}>

									<StartTextBox sx={{width: '100%'}} {...startProps}/>
									<EndTextBox sx={{width: '100%'}} {...endProps} />
								</div>
							</div>
						)}}
				/>
			</div>
			<div className="flex-row">
				<div className="flex-row search-booleans">
					<FormControlLabel
						value="top"
						control={
							<>
								{getSearchValue('isFavorite') == null ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleArtSavedSearchChange({
												target: {
													name: "isFavorite",
													value: 'false'
												}
											})
										}}
										title="All"
										size="large">
										<StarBorderIcon style={{ color: 'grey' }} />
									</IconButton>
									: null}

								{getSearchValue('isFavorite') == 'false' ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleArtSavedSearchChange({
												target: {
													name: "isFavorite",
													value: 'true'
												}
											})
										}}
										title="Non-Favorite"
										size="large">
										<StarBorderIcon style={{color: 'hsl(50, 93%, 59%)'}} />
									</IconButton>
									: null}

								{getSearchValue('isFavorite') == 'true' ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleArtSavedSearchChange({
												target: {
													name: "isFavorite",
													value: null
												}
											})
										}}
										title="Favorite"
										size="large">
										<StarIcon style={{color: 'hsl(50, 93%, 59%)'}} />
									</IconButton>
									: null}
							</>
						}
						label="Favorite"
						labelPlacement="top"
					/>

					<FormControlLabel
						value="top"
						control={
							<>
								{getSearchValue('isVerified') == null ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleArtSavedSearchChange({
												target: {
													name: "isVerified",
													value: false
												}
											})
										}}
										title="All Art"
										size="large">
										<img
											alt="art"
											style={{
												width: '24px',
												height: '24px',
											}}
											src="/images/icons/VerifiedIcons/UnverifiedGreyShield.svg"
										/>
									</IconButton>
									: null}

								{getSearchValue('isVerified') == 'false' ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleArtSavedSearchChange({
												target: {
													name: "isVerified",
													value: true
												}
											})
										}}
										title="Un-Verified Art"
										size="large">
										<img
											alt="unverified-art"
											style={{
												width: '24px',
												height: '24px',
											}}
											src="/images/icons/VerifiedIcons/UnverifiedBlueX.svg"
										/>
									</IconButton>
									: null}

								{getSearchValue('isVerified') == 'true' ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleArtSavedSearchChange({
												target: {
													name: "isVerified",
													value: null
												}
											})
										}}
										title="Verified Art"
										size="large">
										<img
											alt="verified-art"
											style={{
												width: '24px',
												height: '24px',
											}}
											src="/images/icons/VerifiedIcons/VerifiedBlueCheck.svg"
										/>
									</IconButton>
									: null}
							</>
						}
						label="Verified"
						labelPlacement="top"
					/>
				</div>

				<FormControl
					className={classes.searchBar}
					style={{ width: '100%', marginRight: '1em' }}
				>
					<Label style={typeStyle} disableAnimation shrink>
                        Search
					</Label>
					<TextBox
						name="query"
						value={getSearchValue('query') || ''}
						data-testid="query-bar"
						inputRef={input => input && firstLoad && input.focus()}
						onChange={(event) => handleArtSavedSearchChange(event)}
					/>
				</FormControl>

				<FormControl className={classes.selection} style={{width: "100%"}}>
					<Label
						id="user-label"
						style={typeStyle}
						disableAnimation
						shrink
					>
                        Gallery Contact
					</Label>

					<Autocomplete
						multiple
						classes={autoCompleteStyle}
						style={{marginTop: "1.5em"}}
						isOptionEqualToValue={(option, value) => {
							return option.id == value.id
						}}
						forcePopupIcon
						filterSelectedOptions
						popupIcon={<ExpandMoreRoundedIcon />}
						size="small"
						value={props.selection ?? []}
						getOptionLabel={(option) =>getGalleryContactLabel(option)}
						options={options || []}
						onChange={(_, value) => {
							props.setSelection(value)
							const names = value.map(x=> [x.first_name, x.last_name].join(' ')).join(" AND ")
							handleArtSavedSearchChange({
								target: {
									name: "gallery_contacts",
									value: names
								}
							})
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								fullWidth
								InputProps={{
									...params.InputProps,
									style: {'padding': '9px'},
								}}
							/>
						)}
						data-testid="search-gallery_contact"
					/>
				</FormControl>
			</div>

			<div className="row">
				<div>
					<SaveButton
						data-testid="save-button"
						variant="contained"
						size="small"
						onClick={() => {
							saveSearch(
								currentSearch,
								openSnackbar,
								severity,
								setSavedSearchDialog
							)
						}}
					>
                        Save Search
					</SaveButton>
				</div>
				<FindModeButton onFindMode={props.onFindMode} />
				<AdvancedToggleButton
					data-testid="advanced-toggle-button"
					size="small"
					onClick={() => {
						props.setCurrentSearch(currentSearch)
						props.setAdvancedSearch(true)
					}}
					style={{ marginRight: '1em' }}
				>
                    Advanced
				</AdvancedToggleButton>
				<ResetButton
					data-testid="reset-button"
					variant="contained"
					size="small"
					onClick={() => {
						props.setTotalItems(null)
						props.setSelection([])
						props.resetSearch('simple')
						setCurrentSearch(new SavedSearch({
							object_id: ART_SEARCH_OBJECT,
							search_terms: [],
							is_global: true,
							user_id: userAuthentication.user?.id
						}))
					}}
					style={{ marginRight: '1em' }}
				>
                    Reset
				</ResetButton>
				<SearchButton
					data-testid="search-button"
					variant="contained"
					size="small"
					type="submit"
					style={{backgroundColor: !props.loading ? '#4465D1' : null}}
					disabled={props.loading}
				>
                    Search
				</SearchButton>
			</div>
		</form>

		<ConfirmationInput
			open={savedSearchDialog.open}
			handleClose={() => setSavedSearchDialog({
				open: false,
				savedSearch: null
			})}
			title={'Save search?'}
			disabled={loading}
			acceptText={'Save'}
			buttonColor="#33BF5C"
			inputLabel="Search Label"
			placeholder={'Saved Search ' + (props.savedSearches?.length + 1)}
			onYes={(text) => {
				if (!loading) {
					createSearch({
						variables: {
							CreateSearchInput: {
								label: text ? text : 'Saved Search ' + (props.savedSearches?.length + 1),
								user_id: userAuthentication.user.id,
								object_id: ART_SEARCH_OBJECT,
								color: randomHSL(),
								search_terms: searchMap(currentSearch.search_terms),
								result_count: null,
								is_global: true,
							},
						},
					}).then((response) => {

						setCurrentSearch({
							...response.data.createSavedSearch.search,
							search_terms: saveMap(response.data.createSavedSearch.search.search_terms)
						})

						props.setSavedSearches(props.savedSearches.concat(
							new SavedSearch(response.data.createSavedSearch.search))
						)
					})
				}
			}}
		/>
	</>
}

export default withApollo(SearchArt)
