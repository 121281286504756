import { gql } from "@apollo/client"

export const CLEAR_FLAGS = gql`
	mutation clearFlags($user_id: ID!, $object: String!) {
		clearFlags(user_id: $user_id, object: $object) {
			code
			success
			message
		}
	}
`

export const TOGGLE_FLAGGED_ENTITIES = gql`
	mutation toggleFlaggedEntities($art: [Int], $artists: [Int], $contacts: [Int], $listings: [Int], $deals: [Int], $tasks: [Int], $setFlag: Boolean) {
		toggleFlaggedEntities(input: { art: $art, artists: $artists, contacts: $contacts, listings: $listings, deals: $deals, tasks: $tasks, setFlag: $setFlag }) {
			message
			code
			success
			count
		}
	}
`
