import React, { useEffect, useContext } from 'react'
import { Select, MenuItem, FormControl, Divider } from '@mui/material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

import Label from '../input/Label'
import TextBox from '../input/Text/TextBox'
import Dropdown from '../input/Dropdown/Dropdown'

import { withApollo } from '@apollo/client/react/hoc'
import { GET_CONTACT_DETAILS } from '../Contacts/Queries'

import { getFormattedAddress } from '../Contacts/Contact/ContactInformation'
import { LookupContext, AuthStateContext } from '../store'

import { isEmpty } from 'lodash'
import { StyledBadge } from '../styles/makesStyles'
import { permissions, permissionValues } from '../constants/permissions'
import SingleContact from '../input/SingleContact'

export const galleries = {
	"New York": "19 East 64th Street\nNew York, NY 10065",
	"London": "22 Old Bond Street\nLondon W1S 4PY",
	"Paris": "4 Passage Saomte-Avoye\nEntry Via 8 Rue Ramuteau\n75003 Paris",
	"Hong Kong": "Ground Floor\n2 Ice House Street\nCentral, Hong Kong",
}
export const galleryNames = {
	"New York": "Lévy Gorvy Dayan, LLC",
	"London": "Lévy Gorvy Limited",
	"Paris": "Lévy Gorvy SAS",
	"Hong Kong": "Gorvy Holding Asia Limited",
}

const ShippingAddressInput = props => {
	const [useAddress, setUseAddress] = React.useState("contact")

	const [contact, setContact] = React.useState({})
	const [contactAddresses, setContactAddresses] = React.useState([])
	const [contactAddress, setContactAddress] = React.useState("")

	const [galleryName, setGalleryName] = React.useState("")
	const [galleryAddress, setGalleryAddress] = React.useState("")

	const {
		client, setName, setAddress, shipName, address
	} = props

	const lookup = useContext(LookupContext)
	const authState = useContext(AuthStateContext)
	const countries = lookup.data?.getCountryTypes

	const userPermissions  = authState?.user?.permissions
	const canViewContacts = userPermissions
		// eslint-disable-next-line eqeqeq
		.find(element => element.permission_id == permissions.CONTACTS &&
		element.permission_value_id >= permissionValues.VIEW_ONLY)

	const searchTypes = [
		canViewContacts ? "Contact" : "",
		"Gallery",
		"Free Entry"
	].filter(x=>x)

	useEffect(() => {
		if (!contact?.id) {
			return undefined
		}

		client.query({
			query: GET_CONTACT_DETAILS,
			variables: { id: contact.id }
		}).then(result => {
			const contact = result.data.getContact
			const addr = result.data.getContact.address
			if (addr.length > 0) {
				setContactAddresses(addr)
				setContactAddress(addr.find(a => a.is_primary))
			}
			if (contact?.first_name || contact?.last_name) {
				setName([contact?.prefix?.value, contact?.first_name, contact?.last_name].filter(x=>x).join(" "))
			}
		}).catch(e => {
			console.error(e)
		})
	}, [client, contact, setName])

	useEffect(() => {
		if (contactAddress && useAddress === "contact") {
			setAddress(contactAddress==="free" ? '' : getFormattedAddress(countries, contactAddress))
		} else if (galleryAddress && useAddress === "gallery") {
			setAddress(galleryAddress)
		} else {
			setAddress("")
		}
	}, [contactAddress, countries, galleryAddress, setAddress, useAddress])

	return (
		<div>
			<div style={{
				display: "flex",
				flexDirection: "column"
			}}>
				<h3>{props.addressTitle} Address</h3>

				<FormControl className="fullWidth padded-select">
					<Label disableAnimation shrink id="search-Type">
						Address Type
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						
						input={<Dropdown/>}
						name="address-lookup-select"
						value={useAddress}
						onChange={e => {
							setUseAddress(e.target.value)
							if (e.target.value !== useAddress) {
								setName('')
								setAddress('')
								setGalleryAddress('')
								setGalleryName('')
							}
							if (e.target.value === 'gallery' && !galleryName) {
								let gallery = "New York"
								setGalleryName(gallery)
								setName(galleryNames[gallery])
								setGalleryAddress(galleries[gallery])
								setAddress(galleries[gallery])
							}
						}}
					>
						{searchTypes.map((st, i) => (
							<MenuItem key={i} value={st.toLowerCase()}>{st}</MenuItem>
						))}
					</Select>
				</FormControl>

				{ (useAddress==="contact" && canViewContacts) ? <div
					className="fullWidth"
					style={{
						display: "flex",
						flexDirection: "column"
					}}
				>
					<div
						className="fullWidth"
						style={{
							display: 'flex',
							alignItems: 'flex-end',
							paddingBottom: '0.5em',
						}}
					>

						<FormControl style={{ flexGrow: 2 }}>
							<SingleContact
								elasticSearch="searchContacts"
								value={isEmpty(contact) ? null : contact}
								onChange={(event, value) => {
									if (!(value?.is_private && !value?.first_name)) {
										setContact(value)
									}
								}}
								private={props.private}
							></SingleContact>

						</FormControl>
					</div>

					{ !isEmpty(contact) ?
						<FormControl
							className="padded-select fullWidth"
							style={{ display: 'flex' }}
						>
							<Label id="contact-address-label" disableAnimation shrink>
								Select Address
							</Label>
							<Select
								IconComponent={ExpandMoreRoundedIcon}
								name="contact-address"
								
								labelId="contact-address-label"
								input={<Dropdown />}
								value={contactAddress}
								onChange={(e) => {
									setContactAddress(e.target.value)
								}}
							>
								{contactAddresses.map((addr) => (
									<MenuItem key={addr.id} value={addr}>
										{`${ addr.label||'' } (${addr.type})`}
										{addr?.is_primary ?
											<StyledBadge
												style={{marginLeft: '.5em'}}
												overlap="circular"
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'right',
												}}
												variant="dot"
											></StyledBadge>
											: null
										}
									</MenuItem>
								))}
								<MenuItem key="fe" value="free">Free Entry</MenuItem>
							</Select>
						</FormControl> : null}
				</div> : null}


				{ useAddress==="gallery" ? <FormControl className="padded-select fullWidth" style={{
					// paddingRight: '1em',
					display: 'flex'
				}}>
					<Label id="gallery-label" disableAnimation shrink>
						Gallery Address
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						name="gallery"
						
						labelId="gallery-label"
						input={<Dropdown />}
						value={galleryName}
						onChange={(e) => {
							setGalleryName(e.target.value)
							setGalleryAddress(galleries[e.target.value] || "")
							setName(galleryNames[e.target.value] || '')
						}}
					>
						{Object.keys(galleries).map(g => (
							<MenuItem key={g} value={g}>{ g }</MenuItem>
						))}
					</Select>
				</FormControl> : null}
			</div>
			<Divider
				component="div"
				style={{
					marginTop: '1.8em',
					marginBottom: '1.5em'
				}}
			/>
			<FormControl className="fullWidth">
				<Label disableAnimation shrink id="address-name">
					{props.addressTitle} Name
				</Label>
				<TextBox
					name="address-name"
					value={shipName}
					onChange={(e) => { setName(e.target.value) }}
					error={props.attempt && !shipName }
				/>
			</FormControl>

			<FormControl className="fullWidth">
				<Label disableAnimation shrink id="address">
					{props.addressTitle} Address
				</Label>
				<TextBox
					error={props.attempt && !address }
					name="address"
					multiline
					value={address}
					onChange={(e) => { setAddress(e.target.value) }}
				/>
			</FormControl>
		</div>
	)
}

export default withApollo(ShippingAddressInput)
