/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useRef } from 'react'
import Activity from './Activity'
import { GET_USER_ACTIVITY } from '../Queries.js'
import HomePageCard from '../HomePageCard'
import { withApollo } from '@apollo/client/react/hoc'
import { ITEMS_PER_HOME_PAGE_WIDGET } from '../../constants/values'

const ActivityParent = (props) => {

	// like the "active" - but we're using it inside a callback instead of a effect...
	const mounted = useRef(false)
	useEffect(() => {
		mounted.current = true 
		return () => mounted.current = false
	}, [])

	// Order of table
	const [cursor, setCursor] = React.useState(null)
	const [steps, setSteps] = React.useState(1)
	const [activeStep, setActiveStep] = React.useState(0)
	const [searchLoading, setSearchLoading] = React.useState(false)
	const [totalItems, setTotalItems] = React.useState(0)

	const [activity, setActivity] = React.useState([])

	const handleSubmit = useCallback((cursor = 0) => {

		setSearchLoading(true)

		// If the user id falsy, don't execute the query yet
		if (!props.authState?.user?.id) return 

		const variables = {
			id: props.authState.user.id,
			cursor,
			limit: ITEMS_PER_HOME_PAGE_WIDGET,
			field: 'modified_by',
			direction: 'ASC',
		}

		props.client
			.query({
				query: GET_USER_ACTIVITY,
				variables
			})
			.then(result => {
				if (!mounted.current) return
				setActivity(result.data.getActivityLog?.items)
				setCursor(result.data.getActivityLog?.cursor)
				setTotalItems(result.data.getActivityLog?.totalItems)

				if (result.data.getActivityLog.items.length < 1) setSteps(1)
				else setSteps(Math.ceil(result.data.getActivityLog.totalItems / ITEMS_PER_HOME_PAGE_WIDGET))

				setSearchLoading(false)

			})
			.catch(error => {
				console.log(error)
			})
	}, [props.authState, props.client])


	// Search on page load
	useEffect(() => {
		handleSubmit(cursor)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<HomePageCard
			title="Recent Activity"
			paginate
			handleSubmit={handleSubmit}
			cursor={cursor}
			setCursor={setCursor}
			steps={steps}
			setSteps={setSteps}
			activeStep={activeStep}
			setActiveStep={setActiveStep}
			totalItems={totalItems}
		>
			<Activity
				setCurrentActivities={setActivity}
				currentActivities={activity}
				loading={searchLoading || props.userLoading || !props.authState?.user?.id}
			/>
		</HomePageCard>
	)
}

export default withApollo(ActivityParent)
