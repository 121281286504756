import { withApollo } from '@apollo/client/react/hoc'
import React, { useCallback, useContext } from 'react'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { DispatchContext } from '../store'
import { GET_COMPLETE_PERMISSIONS } from './Queries'
import Permissions from './UserPermissions'

const AdminPermissions = (props) => {
	const user = props.state?.adminGetUser || {}
	const loading = props.loading
	const onChange = (update) => {
		const newState = {
			...props.state,
		}
		newState.adminGetUser = {
			...newState.adminGetUser,
			...update,
		}
		props.setState(newState)
	}

	const [findModePermissions, setFindModePermissions] = React.useState([])

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])



	if (props.findMode && !findModePermissions.length) {
		props.client.query({ query: GET_COMPLETE_PERMISSIONS })
			.then(({ data }) => {
				setFindModePermissions(data.getCompletePermissions)
			})
			.catch(error => {
				console.error(error)
				openSnackbar(
					severity.ERROR,
					'There was a error loading permissions to find.'
				)	
			})
	}

	return (
		<section className="main-page dark-theme">
			<Permissions
				loading={loading}
				user={user}
				completePermissions={!props.findMode ? props.state.getCompletePermissions : findModePermissions}
				onChange={onChange}
				findMode={props.findMode}
			></Permissions>
		</section>
	)
}

export default withApollo(AdminPermissions)
