import { gql } from '@apollo/client'
import { dealData, fileData, userDataCompressed } from '../common/fragments'

export const CREATE_DEAL = gql`
	mutation CreateDeal($CreateDealInput: CreateDealInput!) {
		createDeal(input: $CreateDealInput) {
			code
			success
			message
			deal {
				id
			}
		}
	}
`

export const SEARCH_DEALS = gql`
	query searchDeals(
		$field: String
		$direction: SortDirection
		$type_id: String
		$is_favorite: Boolean
		$created_at: String
		$salesperson_ids: [ID]
		$deal_search: String
		$cursor: Int
		$specialFilter: String
		$isFavorite: Boolean
		$tagId: Int
		$thumbnailResolution: String = "128x128"
		$limit: Int
	) {
		searchDeals(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: {
					tagId: $tagId
					is_favorite: $is_favorite
					type_id: $type_id
					created_at: $created_at
					salesperson_ids: $salesperson_ids
					deal_search: $deal_search
					specialFilter: $specialFilter
					isFavorite: $isFavorite
				}
				thumbnailResolution: $thumbnailResolution
			}
		) {
			cursor
			totalItems
			items {
				id
				created_at
				modified_at
				deal_type {
					id
					value
				}
				isFavorite
				isFlagged
				is_private
				code_name
				art {
					id
					title
					year
					is_private
					code_name
					created_at
					is_active
					imgUrl (thumbnailResolution: $thumbnailResolution)
					formatted_title 
					artist {
						id
						first_name
						last_name
					}
				}
				contacts {
					id
					first_name
					last_name
					is_private
					is_company
					company_name
					code_name
					imgUrl (thumbnailResolution: $thumbnailResolution)
					is_deceased
					contactRole {
						id
						value
					}
					dynamic_generated_codename
					created_at
				}
				salesperson {
					...userDataCompressed
					display_name
					is_lead
				}
			}
		}
	}
	${userDataCompressed}
`

export const GET_DEAL_ART = gql`
	query GetDealArt(
		$id: ID!
		$limit: Int
	) {
		getDeal(id: $id) {
			deal_entry(limit: $limit) {
				art {
					id
					title
					profile_link
					artist {
						id
						first_name
						last_name
					}
				}
			}
		}
	}
`


export const GET_DEAL_WITH_ENTRIES = gql`
	query GetDealWithEntries(
		$id: ID!
		$limit: Int
	) {
		getDeal(id: $id) {
			contacts(primaryOnly: true) {
				id
				code_name
				is_private
				preferred_name
				last_name
				is_company
				first_name
				company_name
				dynamic_generated_codename
				created_at
				address (primaryOnly: true) {
					id
					street_1
					street_2
					street_3
					town
					region
					postcode
					country
					is_primary
					is_deleted
					label
					type_id
				}
			}
			deal_entry(limit: $limit) {
				art {
					id
					title
					profile_link
					artist {
						id
						first_name
						last_name
					}
				}
			}
		}
	}
`

export const GET_DEAL_DETAILS = gql`
	query getDeal($id: ID! $thumbnailResolution: String = "128x128") {
		getDeal(id: $id) {
			...dealData
			is_deleted
		}
		getCriticalDealNotes(input: { deal_id: $id }) {
			id
			type_id
			applied_at
			applied_user_id
			note_text
			is_critical
			is_private
			is_deleted
			user {
				...userDataCompressed
				department
			}
		}
		getDealCount
	}
	${dealData}
	${userDataCompressed}
`

export const GET_DEAL_QV_DETAILS = gql`
	query GetDealDetails(
		$id: ID! 
		$thumbnailResolution: String = "128x128"
	) {
		getDeal(id: $id) {
			...dealData
		}
		getCriticalDealNotes(input: { deal_id: $id }) {
			id
			type_id
			applied_at
			applied_user_id
			note_text
			is_critical
			is_private
			is_deleted
			user {
				...userDataCompressed
				department
			}
		}
	}
	${dealData}
	${userDataCompressed}
`

export const UPDATE_DEAL = gql`
	mutation updateDeal($DealInput: UpdateDealInput!  $thumbnailResolution: String = "128x128") {
		updateDeal(input: $DealInput) {
			code
			success
			message
			severity
			deal {
				...dealData
			}
		}
	}
	${dealData}
`
export const GET_DEAL_DATALOG = gql`
	query getDealDataLog(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$id: ID!
		$date: String
		$items: [String]
		$item_ids: [ID]
		$actions: [Item]
		$user_ids: [ID]
		$thumbnailResolution: String = "128x128"
	) {
		getDealDataLog(
			input: {
				field: $field
				direction: $direction
				limit: 10
				cursor: $cursor
				filters: {
					id: $id
					date: $date
					items: $items
					item_ids: $item_ids
					actions: $actions
					user_ids: $user_ids
				}
			}
		) {
			cursor
			totalItems

			items {
				id
				deal_id
				item
				item_id
				action
				diff
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				user {
					...userDataCompressed
					title
				}
			}
		}
	}
	${userDataCompressed}
`

export const UPDATE_DEAL_FILES = gql`
	mutation updateDealFiles(
		$UpdateFileInput: UpdateFileInput!  
		$thumbnailResolution: String = "128x128"
	) {
		updateDealFiles(input: $UpdateFileInput) {
			code
			success
			message
			files {
				...fileData
			}
		}
	}
	${fileData}
`


export const GET_POSSIBLE_DEAL_CONTACTS = gql`
	query getContacts(
		$deal_id: ID
		$query: String
		$thumbnailResolution: String = "128x128"
	) {
		getPossibleDealContacts(deal_id: $deal_id, query: $query) {
			id
			preferred_name
			first_name
			last_name
			is_company
			company_name
			is_private
			created_at
			code_name
			imgUrl (thumbnailResolution: $thumbnailResolution)
			disabled
			is_deceased
			dynamic_generated_codename
		}
	}
`

export const UPSERT_DEAL_CONTACT = gql`
	mutation upsertDealContact(
		$contact_id: ID
		$deal_id: ID
		$contact_role_id: ID
		$delete: Boolean
		$thumbnailResolution: String = "128x128"
		$ownership_percentage: Float
	) {
		upsertDealContact(
			input: {
				contact_id: $contact_id
				deal_id: $deal_id
				contact_role_id: $contact_role_id
				delete: $delete
				ownership_percentage: $ownership_percentage
			}
		) {
			id
			code_name
			preferred_name
			company_name
			imgUrl (thumbnailResolution: $thumbnailResolution)
			phone {
				id
				number
				is_primary
				extension
			}
			address {
				id
				town
				is_primary
			}
			regions {
				id
				value
			}
			type {
				id
				contact_type_id
				contact_type
				ordinal
			}
			contactRole {
				id
				value
			}
		}
	}
`

export const GET_POSSIBLE_DEAL_ART = gql`
	query getArt(
		$deal_id: ID, 
		$query: String 
		$thumbnailResolution: String = "128x128"
	) {
		getPossibleDealArt(deal_id: $deal_id, query: $query) {
			id
			title
			formatted_title
			code_name
			is_private
			created_at
			year
			imgUrl (thumbnailResolution: $thumbnailResolution)
			artist {
				id
				first_name
				last_name
			}
			primaryRetailPrice
			primary_currency_id
			inventory_number
			inventory_number_prefix
			inventory_number_suffix
			edition_copy_number
			disabled
		}
	}
`

export const SIMPLE_SEARCH_DEALS = gql`
	query simpleSearchDeals($query: String) {
		simpleSearchDeals(query: $query) {
			id
			is_private
			code_name
		}
	}
`

export const ADD_CONTACT_ART = gql`
	mutation addContactsAndArt($DealAddContactsArt: DealAddContactsArt!) {
		addContactsAndArt(input: $DealAddContactsArt) {
			code
			success
			message
			severity
			deal {
				id
			}
		}
	}
`

export const DELETE_DEAL = gql`
	mutation deleteDeal($id: ID!) {
		deleteDeal(id: $id) {
			code
			success
			message
		}
	}
`

export const GET_ART_BY_DEAL_ID = gql`
	query getArtworksByDealId($id: ID! $thumbnailResolution: String = "128x128") {
		getArtworksByDealId(id: $id) {
			id
			title
			formatted_title
			code_name
			is_private
			created_at
			year
			imgUrl (thumbnailResolution: $thumbnailResolution)
			artist {
				id
				first_name
				last_name
			}
			primaryRetailPrice
			primary_currency_id
			inventory_number
			inventory_number_prefix
			inventory_number_suffix
			edition_copy_number
			disabled
			deal_entry_status_id
			deal_entry_status {
				id
				value
			}
			is_gallery_private
		}
	}
`

export const GET_CONTACTS_BY_IDS = gql`
	query GetContactsByIds(
		$ids: [ID],
		$cursor: Int,
		$limit: Int,
		$modified_at: String
		$thumbnailResolution: String = "128x128"
	) {
		getContactsByIds(ids: $ids, cursor: $cursor, limit: $limit, modified_at: $modified_at) {
			cursor
			totalItems
			items {
				id
				preferred_name
				first_name
				last_name
				is_company
				company_name
				is_private
				created_at
				code_name
				imgUrl (thumbnailResolution: $thumbnailResolution)
				disabled
				dynamic_generated_codename
			}
		}
	}
`

export const GET_ART_BY_IDS = gql`
	query GetArtByIds(
		$ids: [ID],
		$cursor: Int,
		$limit: Int,
		$modified_at: String
		$thumbnailResolution: String = "128x128"
	) {
		getArtByIds(ids: $ids, cursor: $cursor, limit: $limit, modified_at: $modified_at) {
			cursor
			totalItems
			items {
				id
				title
				year
				is_private
				code_name
				created_at
				is_active
				imgUrl (thumbnailResolution: $thumbnailResolution)
				formatted_title 
				primary_currency_id
				primaryRetailPrice 
				inventory_number
				inventory_number_prefix
				inventory_number_suffix 
				artist {
					id
					first_name
					last_name
				}
			}
		}
	}
`

export const GET_DEAL_CONTACTS = gql`
	query getDealContacts(
		$id: ID!
		$thumbnailResolution: String = "128x128"
	) {
		getDealContacts(id: $id) {
			id
			publicAccess
			isPermittedContactType
			code_name
			is_private
			preferred_name
			last_name
			is_company
			first_name
			company_name
			is_deceased
			ownership_percentage
			imgUrl (thumbnailResolution: $thumbnailResolution)
			phone {
				id
				number
				is_primary
				extension
			}
			address {
				id
				town
				is_primary
			}
			regions {
				id
				value
			}
			type {
				id
				contact_type_id
				contact_type
				ordinal
			}
			contactRole {
				id
				value
			}
			created_at
			dynamic_generated_codename
		}
	}
`
