import { Autocomplete } from '@mui/material'
import React, { useCallback, useState } from 'react'
import debounce from 'lodash/debounce'
import SearchIcon from '@mui/icons-material/Search'
import { limitedAutocompleteStyles } from '../styles/makesStyles'

const LimitedAutocomplete = (props) => {

	const {
		query,
		setQuery,
		filterOptions: _,
		minimum = 3,
		...newProps
	} = props

	const [curr, setCurr] = useState(0)
	const [inputValue, setInputValue] = useState('')

	const updateQuery = useCallback(debounce((value) => {
		// I don't know what's calling this with the value '-'
		if (value === '-') return

		setCurr(value.length)

		if (value.length >= minimum) {
			setQuery(value)
		} else {
			setQuery(false)
		}
	}, 750), [])

	return (
		<Autocomplete 
			{...newProps}
			filterOptions={options => options}
			popupIcon={<SearchIcon />}
			open={props.open && (props.hideinitialoptionslabel ? curr >= minimum : true)}
			classes={limitedAutocompleteStyles()}
			noOptionsText={curr < minimum ? `${minimum} Character Minimum` : 'No options'}
			inputValue={inputValue}
			onInputChange={(e, val) => {
				setInputValue(val)
				updateQuery(val)
			}}
		/>
	)
}

export default LimitedAutocomplete
