/* eslint-disable eqeqeq */
import { autoCompleteStyles, chipStyles, LightTooltip, tagChipShadow } from '../styles/makesStyles'
import { Paper, Chip, MenuItem, Menu, FormControl } from '@mui/material'
import { SaveCardButton, CancelButton, ChooseImageButton } from '../input/Buttons'
import { Skeleton } from '@mui/material'
import { withApollo } from '@apollo/client/react/hoc'
import React, { useEffect, useCallback, useContext } from 'react'
import TextField from '@mui/material/TextField'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import CircularProgress from '@mui/material/CircularProgress'
import { stripMeta } from '../common/helpers'
import { DispatchContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { useMutation } from '@apollo/client'
import { useLocation, useNavigate } from "react-router-dom"
import {
	UPDATE_ARTIST_TAGS,
	UPDATE_ART_TAGS,
	UPDATE_CONTACT_TAGS,
	UPDATE_DEAL_TAGS,
	UPDATE_LISTING_TAGS,
	SEARCH_TAGS_INCL_SAME_ARTIST
} from './Queries'
import { TagModal } from './TagModal'
import LimitedAutocomplete from '../common/LimitedAutocomplete'
import useNavigation from '../navigation/useNavigation'
import HasCardPermissionIcon from '../common/HasCardPermissionIcon'
import { FindModeInput } from '../navigation/Tabs/TabbedPage'
import { computeTextColorFromBGColor } from '../common/helpers'

/**
 * @typedef TagsProps
 */

/**
 * @param {TagsProps} props
 */
function ObjectTags(props) {

	const tagType = {
		'Art': 'art_tag',
		'Contact': 'contact_tag',
		'Deal': 'deal_tag',
	}[props.entityType]

	const navigate = useNavigate()
	const location = useLocation()
	const { push: pushNav } = useNavigation()
	
	// Edit state
	const initialState = {
		mouseX: null,
		mouseY: null,
		editable: false,
	}

	const handleClose = (option) => {
		if (option === 'edit') {
			props.setTagState(Object.assign({}, initialState, { editable: true }))
		} else {
			props.setTagState(initialState)
		}
	}

	const handleClick = (event) => {
		if (props.tagState?.mouseX || props.tagState?.editable || 
			props.findMode || !props.entity?.id) return
		event.preventDefault()
		props.setTagState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const handleTagResponse = (response) => {
		if (response.errors) {
			openSnackbar(severity.ERROR, `There was an error updating tags.`)
		} else {
			openSnackbar(severity.SUCCESS, response.data[`update${props.entityType}Tags`].message)
			props.setEntity({
				...props.entity,
				tags: response.data[`update${props.entityType}Tags`].tags
			})
		}
	}

	const handleTagError = (error) => {
		openSnackbar(severity.ERROR, `There was an error updating tags.`)
	}

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	// Input state
	const [entityInput, setEntityInput] = React.useState({
		id: props?.entity?.id,
		tags: props?.entity?.tags,
	})

	const resetEntityInput = useCallback(
		(entity) => {
			setEntityInput({
				id: props?.entity?.id,
				tags: props?.entity?.tags,
			})
		},
		[props]
	)

	useEffect(() => {
		resetEntityInput(props.entity)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.entity])

	// Style state
	const editableClasses = autoCompleteStyles()
	
	// Tooltips
	const [ttOpen, setTTOpen] = React.useState({})
	const handleTTClose = (row) => {
		setTTOpen({
			[row.id]: false,
		}) 
	}
	const handleTTOpen = (row) => {
		setTTOpen({
			[row.id]: true,
		}) 
	}

	// Autocomplete State
	const [open, setOpen] = React.useState(false)
	const [options, setOptions] = React.useState([])
	const [selection, setSelection] = React.useState([])
	const [loadTags, setLoadTags] = React.useState(false)
	const loading = !!open && !!loadTags

	// Tag Modal states
	const [openTagModal, setOpenTagModal] = React.useState(false)
	
	// Handle the creation of a new tag
	const handleNewTag = (tag) => {
		setOptions(options.concat(tag))
		setSelection(selection.concat(tag))
	}

	const [updateArtTags, { loading: loadingArt }] = useMutation(UPDATE_ART_TAGS)
	const [updateArtistTags, { loading: loadingArtists }] = useMutation(UPDATE_ARTIST_TAGS)
	const [updateContactTags, { loading: loadingContacts }] = useMutation(UPDATE_CONTACT_TAGS)
	const [updateDealTags, { loading: loadingDeals }] = useMutation(UPDATE_DEAL_TAGS)
	const [updateListingTags, { loading: loadingListings }] = useMutation(UPDATE_LISTING_TAGS)

	const updateLoading = loadingArt || loadingArtists || loadingContacts || 
		loadingDeals || loadingListings
	
	// Set initial selection and options to current entity values
	useEffect(() => {
		setSelection(entityInput?.tags || [])
		setOptions(entityInput?.tags || [])
	}, [entityInput])

	useEffect(() => {
		let active = true

		if (!loading) {
			return undefined
		}

		props.client
			.query({
				query: SEARCH_TAGS_INCL_SAME_ARTIST,
				variables: { query: loadTags, id: props.entityType === 'Art' ? props?.entity?.artist?.id : null },
			})
			.then((result) => {
				if (active) {
					setLoadTags(false)
					if (props.entityType === 'Art' && props?.entity?.artist?.id) {
						const suggestions = result.data.simpleSearchTags.concat(result.data.searchDynamicArt.items.flatMap((el) => {
							return el.tags?.map((tagFields) => { return { ...tagFields, isSuggestion: true }}) || []
						})).filter((value, index, self) =>
							index === self.findIndex((t) => (
								t.id === value.id
							)))
						setOptions(suggestions)
					} else {
						setOptions(result.data.simpleSearchTags)
					}
				}
			})

		return () => {
			active = false
		}
	}, [loadTags, loading, props.client, props.entityType, props.entity?.artist?.id])


	return (
		<Paper
			className="qv-margin"
			id={`${props.entityName}-tags`}
			onContextMenu={handleClick}
			data-testid="card-tags"
		>
			<h1 className="card-title">
				<span>Tags

					<HasCardPermissionIcon permission={props.tagPermission} />

					{props.tagState.editable && (
						<ChooseImageButton
							variant="contained"
							size="small"
							onClick={() => {
								setOpenTagModal(true)
							}}
						>
							Create New
						</ChooseImageButton>)}
				</span>
				{props.tagState.editable && (
					<>
						<div className="spacer"></div>
						<CancelButton
							variant="contained"
							size="small"
							onClick={() => {
								resetEntityInput()
								handleClose()
							}}
						>
							Cancel
						</CancelButton>
						<SaveCardButton
							variant="contained"
							color="secondary"
							size="small"
							disabled={updateLoading}
							onClick={() => {
								let tempEntityInput = {
									id: props.entity.id,
									tags: stripMeta(selection)
								}

								switch (props.entityType) {
								case "Art":
									updateArtTags({
										variables: {
											EntityTagInput: tempEntityInput
										}
									})
										.then(handleTagResponse)
										.catch(handleTagError)
									break

								case "Artist":
									updateArtistTags({
										variables: {
											EntityTagInput: tempEntityInput
										}
									})
										.then(handleTagResponse)
										.catch(handleTagError)
									break

								case "Contact":
									updateContactTags({
										variables: {
											EntityTagInput: tempEntityInput
										}
									})
										.then(handleTagResponse)
										.catch(handleTagError)
									break

								case "Deal":
									updateDealTags({
										variables: {
											EntityTagInput: tempEntityInput
										}
									})
										.then(handleTagResponse)
										.catch(handleTagError)
									break

								case "Listing":
									updateListingTags({
										variables: {
											EntityTagInput: tempEntityInput
										}
									})
										.then(handleTagResponse)
										.catch(handleTagError)
									break

								default:
									break
								}

								handleClose()
							}}
						>
							Save
						</SaveCardButton>
					</>
				)}

			</h1>
			<div>
				{props.loading ? (
					<Skeleton
						variant="rectangular"
						width={120}
						height={25}
						animation="wave"
						style={{
							paddingLeft: '0.5em',
							borderRadius: '10em',
							marginTop: '0.5em',
						}}
					/>
				) : ( props.findMode ? 
					<FindModeInput field={tagType} 
						readOnly={props.readOnly} 
						style={{ width: '100%' }}
					/> :
					(!props.tagState.editable ? 
						<>
							{entityInput.tags?.length > 0 ? (
								entityInput.tags.map((tag, index) => {
									return (<LightTooltip
										title={tag.description || ''}
										key={tag.id}
										label={tag.label} 
										arrow
										open={ttOpen[tag.id] || false}
										disableFocusListener
										disableTouchListener
										disableHoverListener
										onClose={() => handleTTClose(tag)}
										onOpen={() => handleTTOpen(tag)}
										placement="bottom"
										enterDelay={1000}
									>
										<Chip
											className={"tag-chip"}
											style={{ 
												...chipStyles,
												...tagChipShadow,
												backgroundColor: '#' + tag.color_hex,
												color: computeTextColorFromBGColor('#' + tag.color_hex)
											}}
											size="small"
											key={tag.id}
											label={tag.label}
											onMouseEnter={() => handleTTOpen(tag)}
											onMouseLeave={() => handleTTClose(tag)}						
											onClick = {(e)=>{
												e.preventDefault()
												const ids = entityInput.tags.map(i => i.id)
												const state = { variables: { cursor: index } }

												if (e.metaKey) {
													window.open(`/tags/${tag.id}/details`, '_blank')
													return
												}

												pushNav({
													url: location.pathname,
													state,
													query: ids,
												}, state.variables.cursor)
												navigate(`/tags/${tag.id}/details`, { state })
											}}
										/>
									</LightTooltip>)
								})
							) : (
								<div style={{ fontSize: '14px' }}>
									No Tags
								</div>
							)}
						</> : 
						<FormControl
							fullWidth={true}
							style={{ paddingBottom: '0.5em' }}
						>
							<LimitedAutocomplete
								query={loadTags}
								setQuery={setLoadTags}
								minimum={1}
								hideinitialoptionslabel="true"

								multiple
								size="small"
								forcePopupIcon
								filterSelectedOptions
								popupIcon={<ExpandMoreRoundedIcon />}
								classes={editableClasses}
								open={open}
								onOpen={() => {
									setOpen(true)
									props.setTagState({
										...props.tagState,
										focus: false
									})
								}}
								onClose={() => {
									setOpen(false)
								}}
								getOptionLabel={(option) =>
									option.label
								}
								groupBy={(option) => (option.isSuggestion || false) ? `Based on other pieces by ${props.entity?.artist?.first_name} ${props.entity?.artist?.last_name}:` : ""}
								options={options}
								loading={loading}
								disabled={!props.tagState.editable}
								value={selection}
								isOptionEqualToValue={(option, value) =>
									option.id == value?.id
								}
								onChange={(event, value) => {
									setSelection(value.map((v) => { return {
										id: v.id,
										label: v.label,
										description: v.description,
										color_hex: v.color_hex
									}}))
								}}
								renderOption={(params, option) =>
									<LightTooltip
										title={option.description || ''}
										key={option.id}
										label={option.label} 
										arrow
										open={ttOpen[option.id] || false}
										disableFocusListener
										disableTouchListener
										disableHoverListener
										onClose={() => handleTTClose(option)}
										onOpen={() => handleTTOpen(option)}
										placement="bottom"
										enterDelay={1000}
									>
										<Chip
											{...params}
											onMouseEnter={() => handleTTOpen(option)}
											onMouseLeave={() => handleTTClose(option)}
											style={{
												...tagChipShadow,
												width: "min-content",
												backgroundColor: '#' + option.color_hex,
												color: computeTextColorFromBGColor('#' + option.color_hex) 
											}}
											size="small"
											key={option.id}
											label={option.label} />
									</LightTooltip>
								}
								renderTags={(value, getTagProps) =>
									value.map((option, index) => (
										<LightTooltip
											title={option.description || ''}
											key={option.id}
											label={option.label}
											open={ttOpen[option.id] || false}>
											<Chip
												{...getTagProps({index})}
												onMouseEnter={() => handleTTOpen(option)}
												onMouseLeave={() => handleTTClose(option)}						
												style={{ 
													...chipStyles,
													...tagChipShadow,
													backgroundColor: '#' + option.color_hex,
													color: computeTextColorFromBGColor('#' + option.color_hex) 
												}}
												size="small"
												key={option.id}
												label={option.label}/>
										</LightTooltip>
									))
								}
								renderInput={(params) => (
									<TextField
										{...params}
										id="owner-autocomplete"
										variant="outlined"
										fullWidth
										autoFocus
										focused={props.tagState.focus}
										style={{ paddingTop: '0.5' }}
										classes={{ notchedOutline: null }}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{loading ? (
														<CircularProgress
															color="inherit"
															size={20}
														/>
													) : null}
													{
														params.InputProps
															.endAdornment
													}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</FormControl>
					))
				}		
			</div>

			<TagModal
				editMode={false}
				loading={loading}
				open={openTagModal}
				setOpen={setOpenTagModal}
				isPrivate={props.entity?.is_private}
				handleResult={handleNewTag}
			/>

			<Menu
				keepMounted
				open={props.tagState.mouseY !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					props.tagState.mouseY !== null && props.tagState.mouseX !== null
						? { top: props.tagState.mouseY, left: props.tagState.mouseX }
						: undefined
				}
			>
				<MenuItem
					onClick={() => {
						handleClose('edit')
					}}
				>
					Edit
				</MenuItem>
			</Menu>
		</Paper>
	)
}

export default withApollo(ObjectTags)
