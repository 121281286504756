/* eslint-disable eqeqeq */
import { useMutation } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import ImageIcon from '@mui/icons-material/Image'
import PanToolIcon from '@mui/icons-material/PanTool'
import { Button, CircularProgress, Divider, Grid, IconButton, Tooltip } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import React, { useCallback, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { GET_IMPORT_IMAGE_LINKS, SAVE_IMPORT_IMAGES } from '../AdminConsole/Queries'
import { CancelButton, ChooseImageButton, SubmitButton } from '../input/Buttons'
import AlertDialog from '../navigation/ConfirmationDialog/ConfirmationDialog'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { DispatchContext } from '../store'



export const MAX_IMAGES = 500


const ImportImageModal = (props) => {
	const navigate = useNavigate()

	const [dragging, setDragging] = React.useState(false)
	const [enterTarget, setEnterTarget] = React.useState(null)

	// State for tracking images and modal state
	const [images, setImages] = React.useState([])
	const [hasFile, setHasFile] = React.useState(false)
	const [confirmExit, setConfirmExit] = React.useState(false)
	const [isUploading, setIsUploading] = React.useState(false)
	const [isSaving, setIsSaving] = React.useState(false)
	const [isFull, setIsFull] = React.useState(false)
	// State for tracking image count
	const [countUploading, setCountUploading] = React.useState(0)
	const [countReady, setCountReady] = React.useState(0)
	const [countRejected, setCountRejected] = React.useState(0)
	const [countFailed, setCountFailed] = React.useState(0)

	const [saveImportImages, { error }] = useMutation(SAVE_IMPORT_IMAGES)

	/* =================================================================
	Snackbar and Auth
	================================================================= */

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	useEffect(() => {
		if (error?.graphQLErrors) {
			const gqlErrors = error.graphQLErrors
			gqlErrors.forEach((e) => {
				if (e.message === "autherror-admin") {
					openSnackbar(severity.ERROR, "Error: saving images to an import requires admin login")
					navigate("/admin/login")
				}
			})
		}
		else if (error) {
			console.log(error)
			openSnackbar(severity.ERROR, "Error saving the images.")
		}
	}, [navigate, error, openSnackbar])

	/* =================================================================
	Modal Control handlers
	================================================================= */

	// Handle actually closing the modal
	const closeModal = () => {
		props.setOpen(false)
		setImages([])
		setIsUploading(false)
	}

	// Handle modal close confirmation
	const confirmCloseModal = () => {
		if (hasFile) {
			setConfirmExit(true)
		} else closeModal()
	}

	/* =================================================================
	Upload Logic Handlers
	================================================================= */

	const updateImage = (filename, status, message, s3Key = null) => {
		// Copy the state
		let items = [...images]

		// Find the image to edit
		let i = 0
		let found = false
		for (; i < items.length; i++) {
			if (items[i].name == filename) {
				found = true
				break
			}
		}
		if (!found) return false

		// Update the image
		let item = items[i]
		item.status = status
		item.message = message
		item.s3_key = s3Key
		items[i] = item
		setImages(items)
		return true
	}

	// Uploading state listener
	useEffect(() => {
		// No images in the list
		if (images.length == 0) {
			setHasFile(false)
			setIsUploading(false)
			setIsFull(false)
			setCountUploading(0)
			setCountReady(0)
			setCountFailed(0)
			setCountRejected(0)
		} else {
			setHasFile(true)

			// Update isFull
			if (images.length >= MAX_IMAGES) setIsFull(true)

			// Get the counts of image statuses
			let ready = 0
			let failed = 0
			let rejected = 0
			const working = images.filter((value) => {
				if (value.status == 4) ready += 1
				else if (value.status == 1) failed += 1
				else if (value.status == 5) rejected += 1
				return (value.status == 2 || value.status == 3)
			}).length
			setCountUploading(working)
			setCountReady(ready)
			setCountFailed(failed)
			setCountRejected(rejected)

			// Update the isUploading state
			if (working > 0) {
				setIsUploading(true)
			} else setIsUploading(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [images])

	// Upload listener
	useEffect(() => {
		if (images.length && isUploading) {
			// Grab images that are awaiting upload
			const toUpload = images.filter((value) => {
				return value.status == 2
			})

			// Batch update state to from 'awaiting upload' => 'uploading'
			if (toUpload.length) {
				let items = [...images]
				for (let i = 0; i < items.length; i++) {
					for (let j = 0; j < toUpload.length; j++) {
						if (items[i].name == toUpload[j].name) {
							items[i].status = 3
						}
					}
				}
				setImages(items)

				// Grab a batch of URLs for the images
				const uploadLinks = props.client.query({
					query: GET_IMPORT_IMAGE_LINKS,
					variables: {
						getImportImageUploadLinksId: props.import?.id,
						input: [...toUpload].map(f => ({
							contentType: f.type,
							metadata: { 'filename': encodeURI(f.name) }
						}))
					},
				})

				// Loop through the URLs and upload the images
				uploadLinks.then(({ data: { getImportImageUploadLinks } }) => {
					console.log("links", getImportImageUploadLinks)
					Promise.all(getImportImageUploadLinks.map((link, i) => {
						let file = toUpload[i]

						if (!link.valid) {
							updateImage(file.name, 5, `This image "${file.name}" was not expected.`)
							return 1
						} else {
							return fetch(link.presignedurl?.url, {
								method: 'PUT',
								body: file,
								headers: {
									'Content-Type': file.type,
									'x-amz-meta-filename': encodeURI(file.name)
								}
							}).then((event) => {
								// Handle the upload result
								if (event?.ok) {
									updateImage(file.name, 4, "", link.presignedurl?.key)
								} else {
									updateImage(file.name, 1, "An error occurred during image upload.")
								}
							}).catch(() => {
								updateImage(file.name, 1, "An error occurred during image upload.")
							})
						}
					}))
				})
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [images])

	// Handle files added
	const handleFileDrop = (event) => {
		const filelist = event.target.files || event.dataTransfer.files
		let files = []

		for (let i = 0; i < filelist.length; i++) {
			let exists = false
			// Check if the file key already exists in state
			for (let j = 0; j < images.length; j++) {
				if (filelist[i].name.replace(/\.[^/.]+$/, '') == images[j].key) exists = true
			}
			// Check if the file key already exists in the upload batch
			for (let j = i + 1; j < filelist.length; j++) {
				if (filelist[i].name.replace(/\.[^/.]+$/, '') == filelist[j].name.replace(/\.[^/.]+$/, '')) exists = true
			}
			if (exists) continue

			// Check if there are too many images
			if (files.length + images.length >= MAX_IMAGES) {
				openSnackbar(severity.WARNING, `You can only upload ${MAX_IMAGES} images at a time.`)
				break
			}

			// Add required fields to the files
			let file = filelist[i]
			file.id = i + images.length + 1
			file.status = 2
			file.message = ""
			file.key = file.name.replace(/\.[^/.]+$/, '')
			files.push(file)
		}

		// Add the images to the state
		// useEffect will listen and start uploading
		setHasFile(true)
		setIsUploading(true)
		setImages([
			...files,
			...images,
		])
	}

	// Delete an image from the upload list
	const deleteImage = (id, name) => {
		setImages(
			images.filter((value) => {
				return value.id != id
			}).map((value, index) => {
				value.id = index + 1
				return value
			})
		)
		openSnackbar(severity.SUCCESS, `Removed ${name}`)
	}


	/* =================================================================
	File Drop Box Handler
	================================================================= */

	const onDragOver = (e) => {
		e.preventDefault()
		e.stopPropagation()
	}

	const onDragEnter = (e) => {
		e.preventDefault()
		e.stopPropagation()

		setEnterTarget(e.target)

		if (e.dataTransfer?.items && e.dataTransfer?.items?.length > 0) {
			setDragging(true)
		}
	}

	const onDragLeave = (e) => {
		e.preventDefault()
		e.stopPropagation()
		if (enterTarget == e.target)
			setDragging(false)
	}

	const onDrop = (e) => {
		e.preventDefault()
		e.stopPropagation()

		setDragging(false)
		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			handleFileDrop(e)
			e.dataTransfer.clearData()
		}
	}


	/* =================================================================
	Styling
	================================================================= */

	const darkTheme = props.onAdminConsole || props.onPrivateObject

	const getBackgroundColor = () => {
		if (darkTheme) return dragging ? '#3d3d3d' : 'inherit'

		return dragging ? '#e4e4e4' : 'inherit'
	}

	const dropStyle = {
		textAlign: 'center',
		width: '100%',
		height: '200px',
		border: dragging ? '4px dashed #4465D1' : '4px dashed #707070',
		backgroundColor: getBackgroundColor(),
		borderRadius: 8,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	}

	/* =================================================================
	DataGrid
	================================================================= */

	const deleteButton = (params) => {
		return <Tooltip arrow placement="right" title="Remove">
			<IconButton
				aria-label="Delete"
				onClick={(_) => {
					deleteImage(params['id'], params['row']['name'])
				}}
				// Disable delete if item is uploading or awaiting upload
				disabled={params?.row?.status == 2 ? true : false}
			>
				<CloseIcon />
			</IconButton>
		</Tooltip>
	}

	const statusIcon = (params) => {
		if (params.row?.status && params.row.status == 4)
			return <>
				<Tooltip arrow placement="right" title="Ready">
					<DoneIcon color="success" />
				</Tooltip>
			</>
		else if (params.row?.status && params.row.status == 1)
			return <>
				<Tooltip arrow placement="right" title="Failed">
					<CloseIcon color="error" />
				</Tooltip>
			</>
		else if (params.row?.status && params.row.status == 2)
			return <>
				<Tooltip arrow placement="right" title="Awaiting Upload">
					<HourglassEmptyIcon color="primary" />
				</Tooltip>
			</>
		else if (params.row?.status && params.row.status == 3)
			return <>
				<Tooltip arrow placement="right" title="Uploading">
					<FileUploadOutlinedIcon color="primary" />
				</Tooltip>
			</>
		else if (params.row?.status && params.row.status == 5)
			return <>
				<Tooltip arrow placement="right" title="Image not expected">
					<PanToolIcon color="warning" />
				</Tooltip>
			</>
		else return <p>Unknown Status</p>
	}

	const errorButton = (params) => {
		return params.row?.status && params.row.status == 1 ?
			<Button
				onClick={(_) => {
					openSnackbar(severity.INFO, params.row.message)
				}}
			>
				See error
			</Button>
			:
			<></>
	}

	const columns = [
		{
			field: 'status',
			headerName: 'Status',
			minWidth: 30,
			sortable: true,
			resizable: false,
			renderCell: statusIcon,
		},
		{
			field: 'name',
			headerName: 'Filename',
			sortable: true,
			type: 'String',
			resizable: false,
			flex: 1,
		},
		{
			field: 'error',
			type: 'actions',
			sortable: false,
			resizable: false,
			renderCell: errorButton,
		},
		{
			field: 'delete',
			type: 'actions',
			minWidth: 50,
			width: 50,
			resizable: false,
			renderCell: deleteButton,
		},
	]


	return (<>
		<TransitionsModal
			open={props.open}
			close={() => { confirmCloseModal() }}
			scroll={false}
		>
			<div
				id="upload-modal"
				className={'dark-theme'}
				style={{ width: '700px' }}
			>
				{/* Header */}
				<Grid container>
					<Grid item xs={10}>
						<h1 className="card-title" style={{
							color: 'white',
							marginBottom: "0px"
						}}>Upload Images to import {props.import?.id}</h1>
						<p className="h4" style={{ color: "white", marginTop: "0px" }}>{props.import?.name}</p>
					</Grid>
					<Grid item xs={2}>
						<a href="/admin/imports/help#uploading-images" target="_blank" style={{ float: 'right' }}>
							<Tooltip arrow placement="bottom" title="Import Help">
								<HelpOutlineIcon fontSize="large" color="primary" />
							</Tooltip>
						</a>
					</Grid>
				</Grid>

				<Grid container spacing={2} style={{ marginBottom: '1em' }}>
					{/* Stats and loading indicator */}
					<Grid item xs={6}>
						<div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
							<div style={{ width: '100%' }}>
								<div>
									<ImageIcon color="primary" fontSize='20px' />
									<p className="paragraph-1" style={{ color: 'white', display: 'inline' }}> Images expected: </p>
									<p className="paragraph-1" style={{ color: 'white', display: 'inline', float: 'right' }}>{props.import?.inserted_image_id - props.import?.inserted_image}</p>
								</div>
								<Divider />
								<div style={{ display: "block" }}>
									<CloseIcon color="error" fontSize='20px' />
									<p className="paragraph-1" style={{ color: 'white', display: 'inline' }}> Images failed to upload: </p>
									<p className="paragraph-1" style={{ color: 'white', display: 'inline', float: 'right' }}>{countFailed}</p>
								</div>
								<div style={{ display: "block" }}>
									<PanToolIcon color="warning" fontSize='20px' />
									<p className="paragraph-1" style={{ color: 'white', display: 'inline' }}> Images not expected: </p>
									<p className="paragraph-1" style={{ color: 'white', display: 'inline', float: 'right' }}>{countRejected}</p>
								</div>
								<div style={{ display: "block" }}>
									<DoneIcon color="success" fontSize='20px' />
									<p className="paragraph-1" style={{ color: 'white', display: 'inline' }}> Images ready to save: </p>
									<p className="paragraph-1" style={{ color: 'white', display: 'inline', float: 'right' }}>{countReady}</p>
								</div>
							</div>
							<div style={{}}>
								<p
									className="paragraph-1"
									style={{
										color: 'white',
									}}
								>
									{isUploading ?
										<>
											<CircularProgress
												style={{ height: "1em", width: "1em", marginRight: ".5em" }}
											/>
											Uploading {countUploading} images
										</> : null
									}
									{isSaving ?
										<>
											<CircularProgress
												style={{ height: "1em", width: "1em", marginRight: ".5em" }}
											/>
											Saving {countReady} images
										</> : null
									}
								</p>

							</div>
						</div>
					</Grid>
					{/* File Drop Div */}
					<Grid item xs={6} style={{ position: "relative" }}>
						<div
							onDragEnter={(e) => !isUploading && !isSaving && !isFull && onDragEnter(e)}
							onDragLeave={(e) => !isUploading && !isSaving && !isFull && onDragLeave(e)}
							onDragOver={(e) => !isUploading && !isSaving && !isFull && onDragOver(e)}
							onDrop={(e) => !isUploading && !isSaving && !isFull && onDrop(e)}
							style={dropStyle}
						>
							<div style={{ padding: '2em' }}>

								<img
									alt="Share Icon"
									src={`/images/icons/Grey/Upload.svg`}
									style={{
										height: '2em',
									}}
								/>
								<hr
									style={{
										visibility: 'hidden',
										marginBlockStart: '0em',
										marginBlockEnd: '0em',
									}}
								/>
								<span
									style={{ color: 'white', marginBottom: '1em' }}
								>Drag files Here
								</span> <br />
								<span style={{ marginBottom: '1em', color: 'white' }}>– or –</span>
								<hr
									style={{
										visibility: 'hidden',
										marginBlockStart: '0em',
										marginBlockEnd: '0.5em',
									}}
								/>
								<input
									accept={props.parentFile && navigator.platform.toUpperCase().indexOf('MAC') === -1 ? props.parentFile.filetype : '*'}
									style={{ display: 'none' }}
									id="icon-button-file"
									type="file"
									onChange={(e) => {
										handleFileDrop(e)
									}}
									multiple={true}
									disabled={isUploading || isSaving || isFull}
								/>
								<label htmlFor="icon-button-file">
									<ChooseImageButton
										color="primary"
										variant="contained"
										aria-label="upload picture"
										component="span"
										disabled={isUploading || isSaving || isFull}
									>
										Choose files from your Computer
									</ChooseImageButton>
								</label>
							</div>
						</div>
					</Grid>
				</Grid>

				{/* Data Grid */}
				<div style={{ height: "452px" }}>
					<DataGridPro
						rows={images}
						columns={columns}
						density='compact'
						disableColumnMenu
						pagination
						pageSize={10}
						paginationMode="client"
						sortingMode="client"
						onSelectionModelChange={(_) => { }}
						selectionModel={props.selectedRow?.id ? [props.selectedRow?.id] : []}
						loading={props.loading}
						hideFooterRowCount
					/>
				</div>

				{/* Buttons */}
				<div style={{ width: '100%' }}>
					<SubmitButton
						variant="contained"
						style={{ float: "right", display: 'inline' }}
						disabled={(hasFile && isUploading) || isSaving}
						onClick={async () => {
							if (!isUploading) {
								setIsSaving(true)

								// Get the ready images
								const toSave = images.filter((item) => {
									return item.status == 4
								})

								// Check if there is anything to save
								if (toSave.length == 0){
									openSnackbar(severity.WARNING, "There are no image ready to save.")
									setIsSaving(false)
									return
								}

								// Convert the array of files into json
								const toSaveJson = toSave.map((item) => {
									return {
										name: item.name,
										type: item.type,
										size: item.size,
										s3_key: item.s3_key
									}
								})

								// Make the API call
								await saveImportImages({
									variables: {
										importId: props.import?.id,
										input: toSaveJson
									}
								}).then(async (response) => {
									console.log(response)
									// Failed
									if (!response.data.saveImportImages.success) {
										openSnackbar(severity.ERROR, response.data.saveImportImages.message || 'There was an error saving the images.')
										// Success
									} else {
										const anyFailed = response.data.saveImportImages.results.filter((item) => {
											return item.success == false
										})

										console.log(anyFailed)

										if (anyFailed.length > 0) {
											openSnackbar(severity.WARNING, "Only some of the images were saved.")
										} else {
											const count = response.data.saveImportImages.results.length
											openSnackbar(severity.SUCCESS, `${count} image${count == 1 ? '' : 's'} saved`)
										}

										setIsSaving(false)
										closeModal()
									}
								}).catch((error) => {
									console.error(error)
									openSnackbar(severity.ERROR, error.message || 'There was an error saving the images.')
									setIsSaving(false)
								})
							}
						}}
					>
						Save
					</SubmitButton>

					<CancelButton
						variant="contained"
						size="medium"
						type="reset"
						style={{ float: 'right', marginRight: '1em', display: 'inline' }}
						onClick={() => { confirmCloseModal() }}
					>
						Cancel
					</CancelButton>
				</div>
			</div>
		</TransitionsModal>

		<AlertDialog
			open={confirmExit}
			handleClose={() => setConfirmExit(false)}
			onYes={() => { closeModal() }}
			title="Are you sure?"
			text={`${isUploading ? "Images are still uploading" : "You have unsaved changes"}, are you sure you want to exit?`}
			acceptText="Exit"
		/>
	</>)
}

export default withApollo(ImportImageModal)
