import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import './Fonts.css'
import App from './App'
import { BrowserRouter as Router } from "react-router-dom"
import { ApolloLink, Observable } from 'apollo-link'
import { RecoilRoot } from 'recoil'
import { StateProvider } from './store'
import * as serviceWorker from './serviceWorker'

// Material UI Theme
import theme from './styles/muiThemes/mainTheme'
import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error'
import { renewAccessToken } from './common/helpers'

import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

// For Images Card Slideshow
import "react-image-gallery/styles/css/image-gallery.css"

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(error => {
			if (error.extensions?.code === "UNAUTHENTICATED") {
				console.info('Not authenticated.')
				window.location.pathname="/"
			}
		})
	if (networkError) {
		// handle authentication errors smoothly
		const msg = networkError.result?.message
		if(msg?.includes("TokenError") || msg?.includes('RefreshError') ){
			console.info('Not authenticated.')
			window.location.pathname="/"
			console.error(`[Login error]: ${msg}`)

		} else {
			console.error(`[Network error]: ${networkError}`)
		}
	}
})


const addAuthHeader = (op) => {
	const token = window.token

	op.setContext(({headers}) => {
		return {
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : 'Bearer none',
				'X-Client-Version': window.SERVER_DATA?.VERSION?.Client
			}
		}
	})
}

const authLink = new ApolloLink( (operation, forward) => {
	const token = window.token
	// token undefined -> try to renew auth token
	const fiveMinutes = 60000 * 5
	if(!token || !window.token_exp || Date.now() > window.token_exp - fiveMinutes){
		return new Observable(async observer => {
			try{
				await renewAccessToken()
				// set the headers in apollo request
				addAuthHeader(operation)

				const subscriber = {
					next: observer.next.bind(observer),
					error: observer.error.bind(observer),
					complete: observer.complete.bind(observer)
				}
				// Retry request with the token in the headers
				forward(operation).subscribe(subscriber)			
			} catch (e) {
				observer.error(e)
				window.location.pathname = '/'
			}
		})
	} else {
		// else just add the header and :sendit:!
		addAuthHeader(operation)
		return forward(operation)
	}

} )

const link = ApolloLink.from([
	errorLink,
	authLink,
	createUploadLink({
		credentials: 'include',
		uri: '/api/graphql'
	}),
])

export const client = new ApolloClient({
	cache: new InMemoryCache(),
	link,
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'all'
		},
		query: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'all'
		},
		mutate: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'all'
		}
	}
})

const root = createRoot(document.getElementById('root'))

root.render(
	<RecoilRoot>
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<StateProvider client={client}>
					<ApolloProvider client={client}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<Router>
								<App />
							</Router>
						</LocalizationProvider>
					</ApolloProvider>
				</StateProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	</RecoilRoot>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
