import React, { useCallback, useContext, useEffect, useState } from 'react'
import { gql } from '@apollo/client'
import { useLazyQuery } from '@apollo/client'
import { stripMeta } from '../../../common/helpers'
import FileGroups from '../../../Files/FileGroups'
import { DispatchContext } from '../../../store'
import { severity } from '../../../Snackbar/CustomizedSnackbar'
import { UPDATE_ART } from '../../Queries'
import { useMutation } from '@apollo/client'
import { fileData } from '../../../common/fragments'
import { useParams } from 'react-router-dom'

const fileQuery = gql`
	query getArtPiece(
		$id: ID!
		$thumbnailResolution: String = "128x128"
	) {
		getArtPiece(id: $id) {
			code
			success
			message
			severity
			art {
				id
				files {
					...fileData
				}
			}
		}
	}
	${fileData}
`

export default function ArtFilesPage(props) {

	const [files, setFiles] = useState([])
	
	const params = useParams()

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	let art = props.state?.getArtPiece || {}
	let setArt = (art) => props.setState({ getArt: art })

	const [refetch, { loading, error }] = useLazyQuery(fileQuery, {
		onCompleted: (data) => {
			setFiles(data?.getArtPiece?.art?.files || [])
		},
		onError: error => {
			console.log(error)
			openSnackbar(severity.ERROR, "Error retrieving files.")
		}
	})


	const [
		updateArt, { error: updateError }
	] = useMutation(UPDATE_ART, {
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, "Could not update.")
		},
		onCompleted: (response) => {
			if (response.updateArtPiece.success === true) {
				refetch({ variables: { id: props.id } })
				openSnackbar(severity.SUCCESS, response.updateArtPiece.message)
				setArt(response.updateArtPiece.art)
			} else {
				// On failure, reset listingInput state, don't touch listing
				// state and show error
				openSnackbar(severity.ERROR, response.updateArtPiece.message)
			}
		}
	})

	// update function
	const update = (fields) => {
		let variables = stripMeta(fields)
		variables.id = art.id

		updateArt({
			variables: {
				ArtInput: variables
			}
		})
	}

	useEffect(() => {
		setFiles([])
		if (params.id && !props.findMode)
			refetch({ variables: { id: params.id } })
	}, [params.id, refetch, props.tabbedPageFileRefetch, props.findMode])
	

	return (!props.findMode && props.id > 0) && (
		<FileGroups
			objectId={art?.id}
			loading={props.loading || loading}
			error={props.error || error || updateError}
			files={files}
			setFiles={setFiles}
			onSave={update}
			fileObjectId={props.fileObjectId}
			options={props.options}
			atPrivateObject={props.atPrivateObject}

			tabbedPageFileRefetch={props.tabbedPageFileRefetch}
			setTabbedPageFileRefetch={props.setTabbedPageFileRefetch}
		/>
	)
}
