import { Checkbox, FormControl, FormControlLabel } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Label from '../input/Label'
import SingleUser from '../input/SingleUser'
import SingleContact from '../input/SingleContact'
import TextBox from '../input/Text/TextBox'
import { GET_USERS } from '../AdminConsole/Queries'
import { ChooseImageButton } from '../input/Buttons'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'


const defaultAgreement = `Lévy Gorvy Dayan, LLC (“Lévy Gorvy Dayan”), was asked to prepare an appraisal of the above-described work(s) of art (the “Artwork”) as of %DATE% (the “Appraisal Date”) which are owned by %DISPLAY_NAME% (“Client”) for the purpose of Client obtaining insurance for the Artwork (the “Insurance Appraisal”). The Insurance Appraisal represents the opinion of Lévy Gorvy Dayan about the probable cost to replace the Artwork with similar artwork based upon comparable market data. Lévy Gorvy Dayan is not providing an appraisal of the fair market value of the Artwork, as such terms are used in the Internal Revenue Code of 1986, as amended, and this Appraisal may not be used for tax purposes.
Client represents and warrants that the information provided to Lévy Gorvy Dayan for the Appraisal is true and correct and that no material information has been withheld. In the preparation of the Appraisal, Lévy Gorvy Dayan has relied upon certain information supplied by Client with respect to the Artwork and Lévy Gorvy Dayan has made no independent investigation thereon. Lévy Gorvy Dayan is providing its opinions only, and makes no representations or warranties of any kind, including regarding value. Lévy Gorvy Dayan offers no opinion with respect to whether the Artwork is authentic, genuine, or that the provenance information or condition information provided to Lévy Gorvy Dayan by Client for the purpose of the Appraisal is true or accurate.
Client and Client’s insurance company may rely upon the Insurance Appraisal, once it has been signed by Client, solely for the purpose of providing a replacement value estimate for the Artwork as of the Appraisal Date. Any other user or use of the Appraisal, or the opinions contained in the Appraisal, shall be deemed an unintended and unauthorized use. Client releases Lévy Gorvy Dayan from and against any and all liability arising out of or relating to the Appraisal, and Client shall indemnify, defend, and hold harmless Lévy Gorvy Dayan and its directors, officers, agents, and employees from and against any and all claims, actions, demands, damages, losses, liabilities and expenses (including, without limitation, reasonable attorneys’ fees and other reasonable professional fees) arising out of, or based upon the Appraisal.
This Appraisal shall not be effective until it has been signed by Client.`


const ArtAppraisal = (props) => {

	const [appraisalState, setAppraisalState] = useState({
		contact: { id: null, name: 'X' },
		title: 'Property of X',
		subtitle: 'INSURANCE APPRAISAL FOR RETAIL REPLACEMENT',
		appraiserLabel: 'Appraised by photograph by:',
		users: [{}],
		prices: {},
		fields: {},
		agreement: defaultAgreement

	})

	useEffect(() => {
		const stateCopy = {
			...appraisalState,
			users: appraisalState.users
				.filter(u => Object.keys(u)?.length > 0)
		}
		props.onChange(stateCopy)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appraisalState])

	return <div style={{display: 'flex', flexDirection: 'column'}}>
		<FormControl>
			<Label disableAnimation shrink htmlFor="appraisal-contact">
				Contact
			</Label>
			<SingleContact
				id="appraisal-contact"
				elasticSearch="searchContacts"
				onChange={(_, value) => {
					const name = [
						value?.first_name,
						value?.last_name
					].filter(a => a).join(' ') ?? 'X'
					setAppraisalState({
						...appraisalState,
						contact: {
							id: value?.id,
							name
						},
						title: `Property of ${name}`,
					})
				}}
			/>
		</FormControl>
		<FormControl>
			<Label disableAnimation shrink htmlFor="appraisal-contact-name">
				Display Name
			</Label>
			<TextBox
				id="appraisal-contact-name"
				value={appraisalState.contact.name}
				onChange={(e) => {
					setAppraisalState({
						...appraisalState,
						contact: {
							...appraisalState.contact,
							name: e.target.value
						},
						title: `Property of ${e.target.value}`,
					})
				}}
			/>
		</FormControl>
		<FormControl>
			<Label disableAnimation shrink htmlFor="appraisal-title">
				Title
			</Label>
			<TextBox
				id="appraisal-title"
				value={appraisalState.title}
				onChange={(e) => {
					setAppraisalState({
						...appraisalState,
						title: e.target.value
					})
				}}
			/>
		</FormControl>

		<FormControl>
			<Label disableAnimation shrink htmlFor="appraisal-subtitle">
				Subtitle
			</Label>
			<TextBox
				id="appraisal-subtitle"
				value={appraisalState.subtitle}
				onChange={(e) => {
					setAppraisalState({
						...appraisalState,
						subtitle: e.target.value
					})
				}}
			/>
		</FormControl>

		<FormControl>
			<Label disableAnimation shrink htmlFor="appraisal-appraiser">
				Appraiser
			</Label>
			<TextBox
				// className="user-input"
				id="appraisal-appraiser"
				value={appraisalState.appraiserLabel}
				onChange={(e) => {
					setAppraisalState({
						...appraisalState,
						appraiserLabel: e.target.value
					})
				}}
			/>
		</FormControl>
		{
			appraisalState.users.map((user, i) =>
				<div key={`gallery-contact-${i}`} style={{display: 'flex', alignItems: 'flex-end', width: '100%'}}>
					<div style={{ flex: '1', marginRight: '1em' }}>
						<Label disableAnimation shrink htmlFor={`gallery-contact-${i}`} style={{
							marginLeft: 0,
							marginBottom: -10
						}}>
							Gallery Contact
						</Label>
						<SingleUser
							id={`gallery-contact-${i}`}
							query={GET_USERS}
							onChange={(event, value) => {
								Object.assign(appraisalState.users[i], {
									name: [
										value?.first_name,
										value?.last_name
									].filter(a => a).join(' '),
									title: value?.title ?? ''
								})
								setAppraisalState({ ...appraisalState })
							}}
						/>
					</div>
					<FormControl style={{ flex: '1' }}>
						<Label disableAnimation shrink htmlFor={`gallery-contact-title-${i}`}>
							Title
						</Label>
						<TextBox
						// className="user-input"
							id={`gallery-contact-title-${i}`}
							value={appraisalState?.users[i]?.title ?? ''}
							onChange={e => {
								appraisalState.users[i].title = e.target.value
								setAppraisalState({
									...appraisalState,
								})
							}}
						/>
					</FormControl>
				</div>
			)
		}
		<ChooseImageButton
			style={{marginTop: '1em'}}
			onClick={() => {
				appraisalState.users.push({})
				setAppraisalState({...appraisalState})
			}}
		>
			<AddCircleOutlineIcon style={{color: "white"}}/>&nbsp;<span className="ci-span">Add Gallery Contact</span>
		</ChooseImageButton>

		<FormControl style={{
			flexDirection: 'row',
			width: 'fit-content',
			paddingTop: '1.6em',
			marginRight: '1.3em',
			paddingLeft: '0.2em'
		}}>
			<Label disableAnimation shrink>
				Prices
			</Label>
			{['Insured', 'Cost', 'Retail', 'Net'].map(label =>
				<FormControlLabel
					key={label}
					control={<Checkbox
						checked={!!appraisalState.prices[label]}
						name={label}
						onChange={(e) => {
							setAppraisalState({
								...appraisalState,
								prices: {
									...appraisalState.prices,
									[e.target.name]: e.target.checked
								}
							})
						}}
					/>}
					label={label}
				/>
			)}
		</FormControl>
		<FormControl style={{
			flexDirection: 'row',
			width: 'fit-content',
			paddingTop: '1.6em',
			marginRight: '1.3em',
			paddingLeft: '0.2em'
		}}>
			<Label disableAnimation shrink>
				Fields
			</Label>
			{['Provenance', 'Exhibition', 'Literature', 'Text', 'Certificate_of_Authenticity'].map(label =>
				<FormControlLabel
					key={label}
					control={<Checkbox
						checked={!!appraisalState.fields[label]}
						name={label}
						onChange={(e) => {
							setAppraisalState({
								...appraisalState,
								fields: {
									...appraisalState.fields,
									[e.target.name]: e.target.checked
								}
							})
						}}
					/>}
					label={label.replaceAll('_', ' ')}
				/>
			)}
		</FormControl>
		<FormControl >
			<Label disableAnimation shrink>
				Agreement
			</Label>
			<TextBox
				name="agreement"
				multiline
				value={appraisalState.agreement}
				// rows="6"
				onChange={(e) => {
					setAppraisalState({
						...appraisalState,
						agreement: e.target.value,
					})
				}}
			/>
		</FormControl>
	</div>
}

export default ArtAppraisal
