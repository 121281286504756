import React from 'react'
import { GET_ART_DATALOG } from '../Queries'
import DataLog from '../../DataLog/DataLog'

export default function ArtDataLog (props) {

	return (!props.findMode && props.id > 0) && (
		<DataLog 
			query={GET_ART_DATALOG}
			id_field={"art_id"} 
			queryName={"getArtDataLog"}
			parentQueryName={"getArtPiece"}
			{...props}
		/>
	)
}
