import { gql } from '@apollo/client'
import { userData } from '../../common/fragments'

export const GET_LISTING_DATALOG = gql`
	query getListingDataLog(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$id: ID!
		$date: String
		$items: [String]
		$item_ids: [ID]
		$actions: [Item]
		$user_ids: [ID]
	) {
		getListingDataLog(
			input: {
				field: $field
				direction: $direction
				limit: 10
				cursor: $cursor
				filters: {
					id: $id
					date: $date
					items: $items
					item_ids: $item_ids
					actions: $actions
					user_ids: $user_ids
				}
			}
		) {
			cursor
			totalItems
			
			items {
				id
				listing_id
				item
				item_id
				action
				diff
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				user {
					...userData
					title
				}
			}
		}
	}
	${userData}
`

export const GET_LISTING_ART = gql`
	query GetListingArt(
		$field: String
		$id: ID
		$direction: SortDirection
		$cursor: Int
		$limit: Int
		$search: String
	) {
		getListingArt(
			input: { 
				field: $field
				direction: $direction
				limit: $limit 
				cursor: $cursor
				filters: { 
					id: $id 
					search: $search
				} 
			}
		) {
			items {
				id
				status_id
				note
				art {
					id
					title
					formatted_title
					is_verified
					is_private
					code_name
					created_at
					artist {
						id
						first_name
						last_name
						imgUrl
					}
					year
					imgUrl (thumbnailResolution: "128x128")
					inventory_number
					inventory_number_prefix
					inventory_number_suffix
					current_sources {
						id
						code_name
						first_name
						last_name
						is_company
						company_name
						is_deleted
						is_private
						imgUrl (thumbnailResolution: "128x128")
						is_deceased
						dynamic_generated_codename
						created_at
						deal_entry_status_category
						deal_entry_status
						contact_role_id
					}
					is_active
					status_id
					isFavorite
					isFlagged
				}
			}
			cursor
			totalItems
		}
	}
`

export const GET_ALL_LISTING_ART = gql`
	query GetListingArt($id: ID) {
		getListingArt(
			input: { field: "id", direction: ASC, limit: 0, cursor: 0, filters: { id: $id } }
		) {
			items {
				art { id }
			}
			cursor
			totalItems
		}
	}
`

// The extra fields beyond ID are used in <BulkActions> for Deal Creation.
export const GET_ALL_LISTING_ART_FOR_DEAL_CREATION = gql`
	query GetListingArt($id: ID) {
		getListingArt(
			input: { field: "id", direction: ASC, limit: 0, cursor: 0, filters: { id: $id } }
		) {
			items {
				art { 
					id
					is_private
					created_at
					formatted_title
					code_name
					year
					imgUrl
					title
					inventory_number_prefix
					inventory_number
					inventory_number_suffix
				}
			}
			cursor
			totalItems
		}
	}
`

export const CREATE_LISTING_ART = gql`
	mutation createListingArt($art_id: ID, $listing_id: ID) {
		createListingArt(input: { art_id: $art_id, listing_id: $listing_id}) {
			id
			status_id
			note
			art {
				id
				title
				formatted_title
				is_verified
				artist {
					id
					first_name
					last_name
					imgUrl
				}
				year
				imgUrl
				inventory_number
				inventory_number_prefix
				inventory_number_suffix
				current_sources {
					id
					code_name
					first_name
					last_name
					is_company
					company_name
					is_deleted
					is_private
					imgUrl (thumbnailResolution: "128x128")
					is_deceased
					dynamic_generated_codename
					created_at
					deal_entry_status_category
					deal_entry_status
					contact_role_id
				}
				is_active
				status_id
			}
		}
	}
`

export const UPDATE_LISTING_ART = gql`
	mutation updateListingArt($id: ID, $status_id: ID, $note: JSONB) {
		updateListingArt(input: { id: $id, status_id: $status_id, note: $note }) {
			id
			status_id
			note
			art {
				id
				title
				formatted_title
				is_verified
				artist {
					id
					first_name
					last_name
					imgUrl
				}
				year
				imgUrl
				inventory_number
				current_sources {
					id
					code_name
					first_name
					last_name
					is_company
					company_name
					is_deleted
					is_private
					imgUrl (thumbnailResolution: "128x128")
					is_deceased
					dynamic_generated_codename
					created_at
					deal_entry_status_category
					deal_entry_status
					contact_role_id
				}
				is_active
				status_id
			}
		}
	}
`

export const DELETE_LISTING_ART = gql`
	mutation deleteListingArt($id: ID) {
		deleteListingArt(id: $id) {
			code
			success
			message
		}
	}
`

export const GET_POSSIBLE_LISTING_ART = gql`
	query getPossibleListingArt($listing_id: ID, $query: String) {
		getPossibleListingArt(listing_id: $listing_id, query: $query) {
			id
			code_name
			is_private
			created_at
			formatted_title
			year
			imgUrl
			artist {
				id
				first_name
				last_name
			}
			title
			inventory_number
			inventory_number_prefix
			inventory_number_suffix
			disabled
		}
	}
`

export const GET_LISTING_STATUSES = gql`
	query getListingParticipantStatuses($id: ID!) {
		getListingParticipantStatuses(id: $id) {
			statuses {
				artTypes
				contactTypes
			}
			code
			success
			message
			severity
		}
	}
`

export const UPDATE_LISTING_TYPE = gql`
	mutation UpdateListingType($updateListingType: UpdateListingType!) {
		updateListingType(input: $updateListingType) {
			code
			success
			message
			severity
		}
	}
`

export const GET_LISTING_ART_IDS = gql`
	query getListingArtIds($id: ID!) {
		getListingArtIds(id: $id) 
	}
`
