/* eslint-disable eqeqeq */
import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Dropdown from '../../input/Dropdown/ThinDropdown'
import Label from '../../input/Label'
import React, { useContext, useEffect } from "react"
import TextBox from '../../input/Text/TextBoxThin'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import {SubmitButton, CancelButton} from '../../input/Buttons'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { LookupContext } from './../../store'
import { autoCompleteErrorStyles } from '../../styles/makesStyles'
import TextBoxThinForAutocomplete from '../../input/Text/TextBoxThinForAutocomplete'
import { SIMPLE_SEARCH_REGIONS } from '../Queries'
import { withApollo } from '@apollo/client/react/hoc'
import { USA_COUNTRY_CODE } from '../../constants/values'

const EditAddressModal = (props) => {

	const lookup = useContext(LookupContext)
	const countries = lookup.data?.getCountryTypes || []

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}

	const [regions, setRegions] = React.useState([])

	const autoCompleteStyle = autoCompleteErrorStyles()

	useEffect(() => {

		let active = true

		if (props.addressModal?.address?.country) {

			props.client
				.query({
					query: SIMPLE_SEARCH_REGIONS,
					variables: {
						countryName: props.addressModal?.address?.country,
					},
				})
				.then((result) => {

					if(active)
						setRegions(result.data?.simpleSearchRegions || [])
				})
				.catch((e) => {
					if(active)
						console.error("Couldn't find regions for current country.")
				})
		}

		return () => {
			active=false
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.addressModal?.address?.country])

	const isUSA = props.addressModal?.address?.country_id == USA_COUNTRY_CODE

	const unitedStatesRegionError = isUSA &&
		(props.addressModal?.address?.region?.length ? (
			props.addressModal?.address?.region?.length != 2 || 
			!!(/[a-z]/.test(props.addressModal?.address?.region))
		) : true)

	return (
		<TransitionsModal
			className="address-modal"
			open={props.addressModal.open}
			close={() =>
				props.setAddressModal({ ...props.addressModal, open: false })
			}
		>
			<form
				className={props.isPrivate ? "private-object modal-large-content" : "modal-large-content"}
				onSubmit={e => {
					e.preventDefault()
					e.stopPropagation()
					
					if (
						props.addressModal?.address?.country?.length === 0 ||
						props.addressModal?.address?.town?.length === 0 ||
						props.addressModal?.address?.country_id === undefined ||
						props.addressModal?.address?.town === undefined || 
						unitedStatesRegionError
					) {
						openSnackbar(severity.WARNING, "Please correct the fields in red.")
					} else {
						let temp = props.contactInput.address

						if (Array.isArray(temp)) {

							temp[props.addressModal.count - 1] = props.addressModal.address
							props.setContactInput({
								...props.contactInput,
								address: temp
							})
							props.setAddressModal({
								...props.addressModal,
								open: false
							})
						} else {


							props.setContactInput({
								...props.contactInput,
								address: {
									...props.addressModal.address,
								}
							})
							props.setAddressModal({
								...props.addressModal,
								open: false
							})
						}
					}
				}}
			>
				<FormControl style={{ width: '14.5em', marginRight: '1em' }}>
					<Label disableAnimation shrink>
						Label
					</Label>
					<TextBox
						autoFocus
						placeholder="-"
						value={props.addressModal?.address?.label || ''}
						onChange={e => {
							props.setAddressModal({
								...props.addressModal,
								address: {
									...props.addressModal.address,
									label: e.target.value
								}
							})
						}}
					/>
				</FormControl>

				<FormControl style={{ width: '14.5em' }} id="address-type-formcontrol">
					<Label disableAnimation shrink>
						Type
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						
						input={<Dropdown />}
						value={props.addressModal?.address?.type_id || ''}
						onChange={e => {
							props.setAddressModal({
								...props.addressModal,
								address: {
									...props.addressModal.address,
									type_id: e.target.value,
									type:
										props.addressTypes[e.target.value - 1]
											.type
								}
							})
						}}
					>
						{props.addressTypes && props.addressTypes.map(x => (
							<MenuItem key={x.id} value={x.id}>
								{x.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Address Line 1
					</Label>
					<TextBox
						defaultValue={props.addressModal?.address?.street_1}
						onChange={e => {
							props.setAddressModal({
								...props.addressModal,
								address: {
									...props.addressModal.address,
									street_1: e.target.value
								}
							})
						}}
					/>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Address Line 2
					</Label>
					<TextBox
						defaultValue={props.addressModal?.address?.street_2}
						onChange={e => {
							props.setAddressModal({
								...props.addressModal,
								address: {
									...props.addressModal.address,
									street_2: e.target.value
								}
							})
						}}
					/>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Address Line 3
					</Label>
					<TextBox
						defaultValue={props.addressModal?.address?.street_3}
						onChange={e => {
							props.setAddressModal({
								...props.addressModal,
								address: {
									...props.addressModal.address,
									street_3: e.target.value
								}
							})
						}}
					/>
				</FormControl>


				<FormControl>
					<Label
						shrink
						id="country-label"
						htmlFor="country-autocomplete"
						error={!props.addressModal?.address?.country_id}>
					Country*
					</Label>
					<Autocomplete
						options={countries}
						autoHighlight
						getOptionLabel={option => {
							return option.name || option.country || ''
						}}
						isOptionEqualToValue={(option, value) => {
							return option.id == value?.country_id || 
							option.id == value.id
						}}
						disableClearable
						value={countries && countries.find(item => item.id === props.addressModal?.address?.country_id)}
						onChange={(event, value) => {
							props.setAddressModal({
								...props.addressModal,
								address: {
									...props.addressModal.address,
									country: value?.name,
									country_id: value?.id
								}
							})

							props.client
								.query({
									query: SIMPLE_SEARCH_REGIONS,
									variables: {
										countryName: value?.name,
									},
								})
								.then((result) => {
									setRegions(result.data?.simpleSearchRegions || [])
								})
								.catch((e) => {
									console.error("Couldn't find regions for current country.")
								})
						}}
						classes={autoCompleteStyle}
						popupIcon={<ExpandMoreRoundedIcon />}
						renderInput={params => (
							<TextBoxThinForAutocomplete
								{...params}
								id="country-autocomplete"
								variant="outlined"
								size="small"
								error={!props.addressModal?.address?.country_id}
								fullWidth
								style={{width: "30em", paddingTop: "1.5em"}}
								classes={{ notchedOutline: null }} 
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
					/>
				</FormControl>

				<FormControl style={{ width: '100%' }} error={unitedStatesRegionError}>
					<Label 
						disableAnimation 
						shrink
						error={unitedStatesRegionError}
					>
						State/Province/Region{isUSA && '*'}
					</Label>

					<Autocomplete
						freeSolo={!isUSA}
						value={props.addressModal?.address?.region}
						onChange={(event, value) => {
							props.setAddressModal({
								...props.addressModal,
								address: {
									...props.addressModal.address,
									region: props.addressModal.address?.country_id == USA_COUNTRY_CODE 
										? value?.shortCode || '' : value?.name || ''
								}
							})
						}}
						options={[...regions, { name: '' }]}
						classes={autoCompleteStyle}
						popupIcon={<ExpandMoreRoundedIcon />}
						size="small"
						isOptionEqualToValue={(option, value) => {
							return option?.shortCode == value || 
							option?.name == value ||
							option?.name == value?.name
						}}

						getOptionLabel={option => {	
							if (props.addressModal.address?.country_id != USA_COUNTRY_CODE) {

								if (typeof option === "string") return option
								else return option?.name
							}

							if (typeof option === "string") return option
							else return option?.shortCode
						}}
						forcePopupIcon={props.addressModal.address?.country_id == USA_COUNTRY_CODE ? true : 'auto'}
						renderInput={(params) => (
							<TextBoxThinForAutocomplete
								{...params}
								error={unitedStatesRegionError}
								id="region-autocomplete"
								size="small"
								variant="outlined"
								fullWidth
								style={{width: "30em", paddingTop: "1.5em"}}
								classes={{ notchedOutline: null }} 
								InputLabelProps={{
									shrink: true
								}}
							/>
						)}
						data-testid="search-gallery_contact"
					/>
					<FormHelperText>{unitedStatesRegionError ? <span>Invalid Region</span> : null}</FormHelperText>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink error={props.addressModal?.address?.town?.length === 0 ||
							props.addressModal?.address?.town === undefined}>
						City/Town*
					</Label>
					<TextBox
						error={props.addressModal?.address?.town?.length === 0 ||
							props.addressModal?.address?.town === undefined}
						defaultValue={props.addressModal?.address?.town}
						onChange={e => {
							props.setAddressModal({
								...props.addressModal,
								address: {
									...props.addressModal.address,
									town: e.target.value
								}
							})
						}}
					/>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Zip/Postal Code
					</Label>
					<TextBox
						defaultValue={props.addressModal?.address?.postcode}
						inputProps={{ maxLength: 12 }}
						onChange={e => {
							props.setAddressModal({
								...props.addressModal,
								address: {
									...props.addressModal.address,
									postcode: e.target.value
								}
							})
						}}
					/>
				</FormControl>

				<CancelButton
					variant="contained"
					style={{ float: 'left', marginTop: '1em' }}
					onClick={e => {
						e.preventDefault()

						if (Array.isArray(props.contactInput.address)) {
							props.setAddressModal({
								...props.addressModal,
								open: false
							})
						} else {
							props.setAddressModal({
								...props.addressModal,
								address: props.contactInput.address,
								open: false
							})
						}
					}}
				>
				Cancel
				</CancelButton>

				<SubmitButton
					style={{ float: 'right', marginTop: '1em' }}
					type="submit"
					variant="contained"
				>
					Submit
				</SubmitButton>

			</form>
		</TransitionsModal>
	)
}

export default withApollo(EditAddressModal)
