import React, {useState, useEffect, useMemo} from 'react'
import {Checkbox,  FormControl, FormControlLabel} from '@mui/material'
import Label from '../input/Label'
import chunk from 'lodash/chunk'

const FramingReportOptions = (props) => {

	const [fields, setFields] = useState(
		JSON.parse(localStorage.getItem('FramingReportOptions.fields')) || {})

	const fullFields = useMemo(() => ({
		inventoryNumber: true,
		artist: true,
		title: true,
		year: true,
		medium: true,
		dimensions: true,
		frame: true,
		crate: true,
		has_coa: true,
	}), [])

	// update stored values if a new one is added.
	useEffect(() => {

		if (!fields || Object.keys(fullFields).length !== Object.keys(fields).length) {
			
			const tempFields = {...fields}

			Object.keys(fullFields).forEach((key) => {
				if (tempFields[key] === undefined) tempFields[key] = true
			})

			setFields(tempFields)
		}
		
	}, [fields, fullFields])

	const labels = {
		inventoryNumber: "Inventory Number",
		artist: "Artist Name",
		title: "Title",
		year: "Date",
		medium: "Medium",
		dimensions: "Dimensions",
		frame: "Frame Description",
		crate: "Crate Description",
		has_coa: "Certificate of Authenticity",
	}

	useEffect(() => {
		localStorage.setItem('FramingReportOptions.fields', JSON.stringify(fields))
		props.onChange(fields)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.onChange, fields] )

	const entries = Object.entries(fields)
	const checkGrid = (chunk(entries, Math.ceil(entries.length / 3)))

	return (
		<div>

			<FormControl
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",

					paddingTop: '1.2em',
					marginRight: '1.3em',
					paddingLeft: '0.2em'
				}}
			>
				<Label disableAnimation shrink>
					Fields
				</Label>
				{
					checkGrid?.map( (row, i) => (
						<div
							style={{
								display: "flex",
								flexDirection: "column"
							}}
							key={`row-${i}`}
						>
							{row.map( ([key, value]) => {
								return (
									<FormControlLabel
										key={key}
										control={
											<Checkbox
												checked={value}
												name={key}
												onChange={(e)=>{
													setFields({
														...fields,
														[e.target.name]:  e.target.checked
													})
												}}
											/>
										}
										label={labels[key]}
									>
									</FormControlLabel>
								)
							})}
						</div>
					))
				}
			</FormControl>

		</div>
	)
}

export default FramingReportOptions
