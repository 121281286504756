import { Skeleton } from "@mui/material"
import { chunk } from "lodash"
import { FindModeInput } from "../../navigation/Tabs/TabbedPage"
import CustomAvatarGroup from './CustomAvatarGroup'
import UserAvatar from "./UserAvatar"

const CHUNK_SIZE = 100, MAX_SIZE = 100

const lastNameSort = (salepersons: any) => {
	return salepersons?.sort((a: any, b: any) => {
		// Sort by last name
		if (a.last_name < b.last_name) return - 1
		if (a.last_name > b.last_name) return 1
		return 0
	}) || []
}

export const sortSalespersons = (salespersons: any, chunk_size: number = CHUNK_SIZE) => {
	return chunk([
		// Leads, normal, deleted
		...lastNameSort(salespersons?.filter((item: any) => { return  item?.is_lead && !item?.is_deleted })),
		...lastNameSort(salespersons?.filter((item: any) => { return !item?.is_lead && !item?.is_deleted })),
		...lastNameSort(salespersons?.filter((item: any) => { return                    item?.is_deleted })),
	], chunk_size)
}


/**
 * @typedef GalleryContactListProps
 * @property {*} [salespersons]
 * @property {boolean} loading
 * @property {int} chunk the number of avatars to display per line
 * @property {int} max the number of avatars to show before compressing
 * @property {boolean || undefined} findMode
 */

/**
 * A component to display an ordered list of gallery contact avatars.
 * @param {GalleryContactListProps} props 
 */
export const GalleryContactList = (props: any) => {
	const salespersons = props.salespersons
	return (
		<div className="flex">
			{props.findMode ? <FindModeInput field="gallery_contacts" readOnly={props.readOnly} style={undefined} openTo={undefined} views={undefined} disableFuture={undefined} largeInput={undefined} perms={undefined} permission={undefined} /> :

				!salespersons || salespersons?.length === 0 ?
					<>
						{props.loading ? <Skeleton variant="circular" animation={props.loading ? "wave" : false} width={40} height={40} /> : <span>-</span>}
					</>
					:
					null}

			{!props.findMode && salespersons &&
				sortSalespersons(salespersons, props.chunk).map((chunk: any, index: any) =>
					<CustomAvatarGroup
						max={props.max || MAX_SIZE}
						key={`AvatarGroup-${index}`}
						sx={{
							marginTop: index ? '1em' : 0
						}}
						extraavatarstooltiptitle={
							chunk.map((sp: any, index: any) =>
								index !== 0 ? (
									<p key={index} style={{ margin: 0, padding: 0 }}>
										{`${sp.first_name} ${sp.last_name}`}
									</p>
								) : null
							) || null
						}
					>
						{chunk.map((sp: any, index: any) =>
							<UserAvatar key={sp.id}
								sp={sp}
								index={index}
								length={chunk?.length} />
						)}
					</CustomAvatarGroup>
				)
			}
		</div>
	)
}

export default GalleryContactList
