import React, { useCallback, useContext, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { DispatchContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import PrivateAccessDetails from '../PrivateAccess/PrivateAccessDetails'
import PrivateAccessUsers from '../PrivateAccess/PrivateAccessUsers'
import { withApollo } from '@apollo/client/react/hoc'
import { GET_USERS_WITH_ACCESS } from '../QuickView/Queries'
import { UPDATE_ART } from './Queries'

function ArtAccess(props) {
	let { loading, error } = props
	const [users, setUsers] = React.useState([])

	const [artInput, setArtInput] = React.useState({})

	const resetArtInput = () => {
		setArtInput({
			id: props.state?.getArtPiece?.id,
			code_name: props.state?.getArtPiece?.code_name,
			is_private: props.state?.getArtPiece?.is_private,
			gallery_access: props.state?.getArtPiece?.gallery_access,
			restrictedToUsersReplace: props.state?.getArtPiece?.restrictedToUsers,
		})
	}

	useEffect(() => {
		if (props.state?.getArtPiece) {
			resetArtInput()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.state])

	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	// eslint-disable-next-line no-unused-vars
	const [updateArtPiece, { loading: updateLoading, error: mutationError }] = useMutation(
		UPDATE_ART,
		{
			onError: (error) => {
				console.error(error)
				openSnackbar(severity.ERROR, 'An unknown error occurred.')
				// Reset State
				resetArtInput()
			},
			onCompleted: (response) => {
				if (response?.updateArtPiece?.success === true) {
					openSnackbar(
						severity[response.updateArtPiece.severity],
						response.updateArtPiece.message
					)
					props.setAtPrivateObject(
						response.updateArtPiece.art.is_private
					)
					props.setState({ getArtPiece: {
						...props.state.getArtPiece,
						...response.updateArtPiece.art
					} })
					getUsers(props.state.getArtPiece.id, 1)
				}

				if (response?.updateArtPiece?.success === false) {
					openSnackbar(
						severity.ERROR,
						"Could not update private details card."
					)
				}
			},
		}
	)

	if (mutationError) {
		console.error(mutationError)
		openSnackbar(
			severity.ERROR,
			mutationError.message
		)
	}

	const getUsers = (entity_id, objectTypeId) => {
		props.client
			.query({
				query: GET_USERS_WITH_ACCESS,
				variables: {
					objectId: entity_id,
					objectTypeId,
				},
			})
			.then((result) => {
				setUsers(result?.data?.getUsersWithAccess?.users || [])
			})
			.catch((error) => {
				console.error(error)
				openSnackbar(severity.ERROR, 'Error retrieving users with access.')
			})
	}

	const update = (variables) =>
		updateArtPiece({ variables: { UpdateArtInput: variables } })

	return (
		<>
			<PrivateAccessDetails
				loading={loading || updateLoading}
				error={error}
				input={artInput}
				setInput={setArtInput}
				update={update}
				object="art"
				disableCodename
				findMode={props.findMode}
				publicAccess={props.state?.getArtPiece?.publicAccess}
			></PrivateAccessDetails>

			{ !props.findMode && <PrivateAccessUsers
				loading={loading || updateLoading}
				error={error}
				input={artInput}
				setInput={setArtInput}
				entityType="art"
				update={update}
				users={users}
				getUsers={getUsers}
				publicAccess={props.state?.getArtPiece?.publicAccess}
			></PrivateAccessUsers>}
		</>
	)
}

export default withApollo(ArtAccess)
