import { Avatar, ButtonBase } from '@mui/material'
import React, { useCallback, useContext } from 'react'
import CustomAvatarGroup from '../../common/components/CustomAvatarGroup'
import { LightTooltip } from '../../styles/makesStyles'
import ContactThumbnail from '../../Thumbnail/ContactThumbnail'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { useLocation, useNavigate } from 'react-router-dom'
import useNavigation from '../../navigation/useNavigation'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { getContactName, getInitialism } from '../../common/helpers'
import { getIconOrLabel } from '../../Thumbnail/Thumbnail'

interface SourceProps {
	sources: any[]
	thumbnailStyles?: any
	avatarGroupStyles?: any
	buttonStyles?: any
	thumbnailStyle?: any
	compact?: boolean
	loading?: boolean
	enableNav?: boolean
	editTooltip?: boolean
	ownershipHistoryCard?: boolean
	maxOverride?: number
}

const Source = (props: SourceProps) => {

	const firstSource = props.sources?.length ? 
		props.sources?.[0] 
		: null

	const owners = props.sources?.length ? 
		[...props.sources]?.sort((a, b) => b?.id - a?.id)?.filter((e: any) => e.deal_entry_status_category === 'Known Source') 
		: []

	const navigate = useNavigate()
	const location = useLocation()
	const { push: pushNav } = useNavigation()

	const goToSource = () => {
		const id = firstSource?.id

		if (!id) return

		const state = { variables: { cursor: 0 } }
		pushNav({
			url: location.pathname,
			state,
			query: [id],
		}, state.variables.cursor)
		navigate(`/contacts/${id}/details`, { state })
	}

	const goToSourceAvatarGroup = (currentContact: any) => {
		const ids = owners?.map((e: any) => e.id)

		const cursor = ids.findIndex((e: any) => e === currentContact.id)
		const state = { variables: { cursor } }
		pushNav({
			url: location.pathname,
			state,
			query: ids,
		}, cursor)
		navigate(`/contacts/${currentContact.id}/details`, { state })
	}

	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity: string, text: string) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	// Show avatar group
	if (firstSource?.deal_entry_status_category === 'Known Source' && owners?.length > 1) {


		if (props.compact) {
			return <>{owners.map((own: any) => getContactName(own)).join(", ")}</>
		}

		return (

			<>
				<CustomAvatarGroup
					max={props.maxOverride ?? 4}
					extraavatarstooltiptitle={
						owners.slice((props.maxOverride ?? 4) - 1, owners.length)?.map((owner: any, index: number) => {

							const currentName = getContactName(owner)

							return (
								<p key={index} style={{ margin: 0, padding: 0 }}>
									{currentName}
								</p>
							)
						}
							
						) || null
					}
					style={{
						...props.avatarGroupStyles
					}}
				>
					{owners.map((owner: any, index: number) => {

						const contactName = getContactName(owner)

						return (
							<LightTooltip arrow title={contactName}>
								<Avatar
									alt={contactName}
									src={owner.imgUrl}
									style={{
										marginLeft: -6,
										cursor: props.enableNav ? 'pointer' : 'default'
									}}
									onClick={(event) => {
										if (props.enableNav) {

											if (owner?.canNavigate === null) {
												openSnackbar(severity.WARNING, "You do not have access to this contact.")
												return
											}
					
											if (firstSource?.id === null || !firstSource.created_at) {
												return
											}

											if (event.metaKey) {
												window.open(`/contacts/${owner.id}/details` , '_blank')
												return
											}

											goToSourceAvatarGroup(owner)
										}
									}}
								>
									{owner.is_private ? (
										<>
											{!owner.created_at ?
												<LockIcon /> :
												<LockOpenIcon />
											}
										</>
									) :
										<>
											{props.ownershipHistoryCard ? getIconOrLabel({
												avatar: owner.imgUrl,
												name: contactName,
												type: 'contact',
												heightOverride: '1.3em'
											}) : getInitialism(contactName)}
										</>
									}
								</Avatar>
							</LightTooltip>
		
						)
					})}
				</CustomAvatarGroup>

				{props.ownershipHistoryCard ? <div style={{
					padding: '1em'
				}}>{owners.map((own: any) => getContactName(own)).join(", ")}</div> : null}

			</>
		)
	}

	else if (props.compact) return <>{getContactName(firstSource)}</>

	else if (props.enableNav) {


		return (
			<ButtonBase
				style={{
					display: 'block',
					width: '-webkit-fill-available',
					marginLeft: '0px',
					marginRight: '0px',
					borderRadius: 8,
					cursor: 'pointer',
					textAlign: 'left',
					justifyContent: 'start',
					backgroundColor: firstSource?.is_private ? '#2E3134' : undefined,
					...props.buttonStyles
				}}
				onClick={(event) => {

					if (firstSource?.canNavigate === null) {
						openSnackbar(severity.WARNING, "You do not have access to this contact.")
						return
					}

					if (firstSource?.id === null || !firstSource.created_at) {
						return
					}

					if (event.metaKey) {
						window.open(`/contacts/${firstSource?.id}/details` , '_blank')
						return
					}

					goToSource()
				}}
				data-testid="art-source"
			>

				<ContactThumbnail
					contact={firstSource}
					darkText={!firstSource?.is_private}
					loading={props.loading}
					style={props.thumbnailStyle}
				/>							
			</ButtonBase>
		)
	}

	else if (props.editTooltip) {
		return (
			<LightTooltip arrow title={
				<>
					<div style={{fontSize: 'large'}}>To edit the current source: </div>
					<p>{'Add a new deal or remove the latest deal. Check the ownership history card on the financials & transactions page. '}</p>
				</>
			}>
				<div>
					<ContactThumbnail
						contact={firstSource}
						loading={props.loading}
						style={props.thumbnailStyle}
					/>
				</div>
			</LightTooltip>
		)
	}

	// Show thumbnail
	else {
		return (
			<ContactThumbnail
				contact={firstSource}
				loading={props.loading}
				style={props.thumbnailStyle}
			/>
		)
	}
}

export default Source
