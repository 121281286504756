import ConsignmentInformation from "./Contracts/ConsignmentInformation"
import InvoiceInformation from "./Contracts/InvoiceInformation"
import LoanInformation from "./Contracts/LoanInformation"

export default function DealContract(props: any) {

	const deal = props?.state?.getDeal
	const dealType = deal?.deal_type?.value

	let ProperContract = (props: any) => <></>

	if (['Loan In', 'Loan Out'].includes (dealType)) {
		ProperContract = LoanInformation
	} else if (['Offer', 'Gallery Purchase'].includes (dealType)) {
		ProperContract = InvoiceInformation
	} else if (['Consignment In', 'Consignment Out'].includes (dealType)) {
		ProperContract = ConsignmentInformation
	}
	
	return <>
		<ProperContract 
			deal={deal}
		/>
	</>
}
