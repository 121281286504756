import { gql } from '@apollo/client'

export const userData = gql`
	fragment userData on IUser {
		id
		first_name
		last_name
		imgUrl
		user_handle
	}
`

export const userDataCompressed = gql`
	fragment userDataCompressed on IUser {
		id
		first_name
		last_name
		imgUrl (thumbnailResolution: $thumbnailResolution)
		user_handle
	}
`

export const imageData = gql`
	fragment imageData on Image {
		id
		type_id
		filename
		filetype
		ordinal
		credit
		caption
		photographer
		alt_text
		usage_right_id
		key
		imgUrl (thumbnailResolution: "1024x1024")
		filesize
		dimension
	}
`

export const dealData = gql`
	fragment dealData on Deal {

		totalSale
		publicAccess
		created_by
		created_at
		modified_by
		modified_at
		id
		ownership_note
		gallery_access {
			id
			value
			created_by
			created_at
			modified_by
			modified_at
			is_deleted
		}
		type_id
		start_at
		end_at
		deal_type {
			id
			value
		}
		tags {
			id
			label
			description
			color_hex
		}
		is_legacy
		is_historical
		historical_to
		historical_from
		code_name
		is_private
		is_gallery_private
		is_deleted
		created_at
		created_by
		created_user {
			first_name
			last_name
		}
		modified_user {
			first_name
			last_name
		}
		isFavorite
		isFlagged
		restrictedToUsers {
			id
			first_name
			last_name
			gallery
			title
			department
			imgUrl
			assistants {
				id
				first_name
				last_name
				gallery
				imgUrl
				permissions {
					permission_id
					permission_value_id
				}
			}
		}
		created_user {
			...userDataCompressed
			department
			gallery
		}
		salesperson {
			...userDataCompressed
			is_lead
			is_deleted
		}
		contacts {
			id
			publicAccess
			isPermittedContactType
			code_name
			is_private
			preferred_name
			last_name
			is_company
			first_name
			company_name
			is_deceased
			imgUrl (thumbnailResolution: $thumbnailResolution)
			contactRole {
				id
				value
			}
			created_at
			dynamic_generated_codename
		}
	}
	${userDataCompressed}
`

export const fileData = gql`
	fragment fileData on File {
		id
		description
		file_category_id
		version
		parent_file_id
		filename
		filetype
		filesize
		created_by
		created_at
		modified_by
		modified_at
		key
		user {
			...userDataCompressed
			gallery
			profile_link
		}
		fileCategory {
			id
			value
			fileGroup {
				id
				value
				fileObject {
					id
					value
				}
			}
		}
	}
	${userDataCompressed}
`

export const taskData = gql`
	fragment taskData on Task {
		id
		type_id
		description
		assigned_by_user_id
		approval_required
		approved_by_user_id
		approval_at
		due_at
		is_completed
		created_by
		created_at
		modified_by
		modified_at
		is_deleted
		task_status_id
		# task_x_assigned_to
		assigned_to_type
		assigned_to_users {
			...userDataCompressed
			department
			is_deleted
		}
		assigned_to_department {
			id
			value
		}
	}
	${userDataCompressed}
`


