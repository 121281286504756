import { gql } from '@apollo/client'

export const SELECT_ALL_ART = gql`
	query searchDynamicArt(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$filters: [SearchFilter]
		$limit: Int
	) {
		searchDynamicArt(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: $filters
			}
		) {
			cursor
			totalItems
			items {
				id
			}
		}
	}
`

export const SELECT_ALL_TASKS = gql`
	query searchDynamicTasks(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$filters: [SearchFilter]
		$limit: Int
	) {
		searchDynamicTasks(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: $filters
			}
		) {
			cursor
			totalItems
			items {
				id
			}
		}
	}
`


export const SELECT_ALL_ARTISTS = gql`
	query searchDynamicArtists(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$filters: [SearchFilter]
		$limit: Int
	) {
		searchDynamicArtists(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: $filters
			}
		) {
			cursor
			totalItems
			items {
				id
			}
		}
	}
`

export const SELECT_ALL_CONTACTS = gql`
	query searchDynamicContacts(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$filters: [SearchFilter]
		$limit: Int
	) {
		searchDynamicContacts(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: $filters
			}
		) {
			cursor
			totalItems
			items {
				id
			}
		}
	}
`

export const SELECT_ALL_DEALS = gql`
	query searchDynamicDeals(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$filters: [SearchFilter]
		$limit: Int
	) {
		searchDynamicDeals(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: $filters
			}
		) {
			cursor
			totalItems
			items {
				id
			}
		}
	}
`

export const SELECT_ALL_LISTINGS = gql`
	query searchDynamicListings(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$filters: [SearchFilter]
		$limit: Int
	) {
		searchDynamicListings(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: $filters
			}
		) {
			cursor
			totalItems
			items {
				id
			}
		}
	}
`
