import { gql } from '@apollo/client'

export const GET_CONTACT_LISTINGS = gql`
	query GetContactListings(
		$field: String
		$id: ID
		$direction: SortDirection
		$searchString: String
		$listingTypeId: String
		$cursor: Int
		$limit: Int
	) {
		getContactListings(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: {
					id: $id
					searchString: $searchString
					listingTypeId: $listingTypeId
				}
			}
		) {
			items {
				id
				status_id
				listing {
					id
					title
					subtitle
					type_id
					start_time
					location
					start_at
					listing_type {
						value
					}
					imgUrl (thumbnailResolution: "128x128")
				}
			}
			cursor
			totalItems
		}
	}
`

export const UPDATE_LISTING_CONTACT = gql`
	mutation updateListingContact(
		$id: ID
		$contact_id: ID
		$listing_id: ID
		$status_id: ID
	) {
		updateListingContact(
			input: {
				id: $id
				contact_id: $contact_id
				listing_id: $listing_id
				status_id: $status_id
			}
		) {
			id
			status_id
			listing {
				id
				title
				subtitle
				type_id
				location
				start_at
				listing_type {
					value
				}
				imgUrl (thumbnailResolution: "128x128")
			}
		}
	}
`

export const DELETE_LISTING_CONTACT = gql`
	mutation deleteListingContact($id: ID) {
		deleteListingContact(id: $id) {
			code
			success
			message
		}
	}
`

export const GET_LISTING_PREFERENCES = gql`
	query {
		getListingPreferences {
			id
			listing_preference
			gallery_id
		}
	}
`

export const GET_CONTACT_LISTING_PREFERENCES = gql`
	query getContactListingPreferences($contact_id: ID) {
		getContactListingPreferences(contact_id: $contact_id) {
			id
			listing_preference_id
		}
	}
`

export const CREATE_CONTACT_LISTING_PREFERENCE = gql`
	mutation createContactListingPreference(
		$contact_id: ID
		$listing_preference_id: ID
	) {
		createContactListingPreference(
			contact_id: $contact_id
			listing_preference_id: $listing_preference_id
		) {
			code
			success
			message
			ContactListingPreference {
				id
				listing_preference_id
			}
		}
	}
`

export const DELETE_CONTACT_LISTING_PREFERENCE = gql`
	mutation deleteContactListingPreference($ids: [ID]) {
		deleteContactListingPreference(ids: $ids) {
			code
			success
			message
		}
	}
`
