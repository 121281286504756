/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@apollo/client'
import { Undo } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { AvatarGroup, Button, IconButton, Menu, MenuItem, Paper, Popover, Typography } from '@mui/material'
import { styled } from '@mui/system'
import {
	DataGridPro, GridToolbarColumnsButton,
	GridToolbarContainer, GridToolbarDensitySelector
} from '@mui/x-data-grid-pro'
import clsx from 'clsx'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import DataGridFlagMenu from '../common/components/DataGridFlagMenu'
import UserAvatar from '../common/components/UserAvatar'
import FavoriteAndFlag from '../common/FavoriteAndFlag'
import { elementReady, formatDate, getDealName, getListHeader, getObjectDetails, restoreColumnSettings, getArtistThumbnailDetail } from '../common/helpers'
import { getIgnoredAttributes } from '../Home/Tasks/HomeTasks'
import InfoCard from '../InfoCard/InfoCard'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { DispatchContext } from '../store'
import Thumbnail from '../Thumbnail/Thumbnail'
import EditTask from './EditTask'
import {
	DELETE_ARTIST_TASK, DELETE_ART_TASK, DELETE_CONTACT_TASK,
	DELETE_DEAL_TASK, DELETE_ENTITY_TASK, DELETE_LISTING_TASK
} from './Queries'
import { renderSelectEditCell } from './SelectStatus'

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import DealThumbnail from '../Deals/DealThumbnail'


function arraymove(arr, fromIndex, toIndex) {
	const element = arr[fromIndex]
	arr.splice(fromIndex, 1)
	arr.splice(toIndex, 0, element)
}

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
	  backgroundColor: theme.palette.common.white,
	  color: 'rgba(0, 0, 0, 0.87)',
	  boxShadow: theme.shadows[1],
	  fontSize: 14,
	  fontFamily: 'Graphik',
	  fontWeight: 400
	},
}))

function getCols({
	loading,
	favoriteAndFlag, 
	actionMenu, 
	flagMenu, 
	density
}) {

	/** @type GridColDef[] */
	const columns = [
		{
			field: 'Actions',
			headerName: '',
			minWidth: 50,
			width: 50,
			resizable: false,
			sortable: false,
			renderCell: actionMenu
	  	},
		{
			field: 'isFlagged',
			headerName: 'Flag',
			renderHeader: flagMenu,
			width: 50,
			renderCell: favoriteAndFlag,
			sortable: false,
			resizable: false,
			headerClassName: 'flag-header-data-grid'
		}, 
		{
			field: 'task_status',
			headerName: 'Status',
			width: 200,
			editable: true,
			renderEditCell: renderSelectEditCell,
			valueGetter: (params) => params.row.is_deleted ? 'Deleted' : params.row.task_status || '-'
		},
		{
			field: 'link_entity_name',
			headerName: 'Linked Entity',
			width: 350,
			renderCell: (params) => {

				let { type, title, subTitle, isPrivate, hidden, imgUrl } = getObjectDetails(params.row)

				if (!params.row?.created_at) return <span>You do not have permission.</span>
				if (!title) return <span>None</span>


				if (params.row?.deal?.id)
					return (
						<DealThumbnail
							is_private={params.row.deal.is_private}
							is_gallery_private={params.row.deal.is_gallery_private}
							created_at={params.row.deal.created_at}
							deal_name={getDealName(params.row.deal)}
						/>
					)

				if (params.row?.art?.id) 
					return (
						<Thumbnail
							name={title}
							formatted_name={params.row.art?.formatted_title}
							artist={getArtistThumbnailDetail(
								params.row.art
							)}
							isCompany={params.row.contact?.is_company}
							date={params.row.art?.year}
							detail={!isPrivate ? ( !loading && !params.row.art_id ? subTitle || '-' : null) : null}
							avatar={params.row?.art?.imgUrl}
							type={type}
							animation={loading ? 'wave' : false}
							largeText
							hasAccess={!hidden}
						></Thumbnail>
					)

				return (
					<Thumbnail
						name={title}
						isCompany={params.row.contact?.is_company}
						detail={!isPrivate ? ( !loading && !params.row.art_id ? subTitle || '-' : null) : null}
						avatar={imgUrl}
						type={type}
						animation={loading ? 'wave' : false}
						largeText
						darkText
						isPrivate={isPrivate}
						hasAccess={!hidden}
						isDeceased={params.row.contact?.is_deceased}
					></Thumbnail>
				)
			}
		},
		{
			field: 'assignees',
			headerName: 'Assignees',
			width: 175,
			renderCell: ({row}) => {

				if (row.is_deleted) return <span>-</span>
			
				return (
					<>
						{row.assigned_to_type === "User" ? 
							<AvatarGroup max={3}>
								{row.assigned_to_users.map((user, index) =>
									<UserAvatar key={user.id} sp={user} index={index} length={row.assigned_to_users?.length}/>
								)}
							</AvatarGroup>
							: null}

						{row.assigned_to_type === "Department" ? 
							<div style={{float: 'left'}}>
								{row.assigned_to_department.value}
							</div>

							: null}

						{!row.assigned_to_type ?
							<span>-</span>
							: null}
					</>)
			}
		},
		{
			field: 'description',
			headerName: 'Description',
			width: 600,
			renderCell: ({row}) => {

				if (row.is_deleted) return <span>-</span>

				if (row.description) return (
					<LightTooltip title={row.description} placement="bottom" enterDelay={500}>
						<span>{row.description}</span>
					</LightTooltip>
				)

				else return <span>-</span>
			}
		},
		{
			field: 'due_at',
			width: 200,
			headerName: 'Due At',
			renderCell: ({row}) => {

				if (row.is_deleted) return <span>-</span>
				
				return (
					<span>{formatDate(row.due_at, "MMMM do 'at' h':'mma")}</span>
				)
			}
		},
		{
			field: "modified_at",
			headerName: "Modified At",
			sortable: true,
			width: 130,
			renderCell: params => {
				const fDate = params.row?.modified_at ? formatDate( params.row?.modified_at, "MMM' 'dd', 'yyyy" ) : "-"
				return <span>{fDate}</span>
			}
		},
		{
			field: "created_at",
			headerName: "Created At",
			sortable: true,
			width: 130,
			renderCell: params => {
				const fDate = params.row?.created_at ? formatDate( params.row?.created_at, "MMM' 'dd', 'yyyy" ) : "-"
				return <span>{fDate}</span>
			}
		},
	]
	return columns
}

/**
 * @typedef TasksDataGridProps
 * @property {Object[]} rows All rows of {Tasks} to be displayed
 * @property {function} setRows
 * @property {boolean} loading If a loading is in progress
 * @property {function} saveAndGo Function called on row doubleClick
 * @property {string} mainSearchPage
 * @property {filterComparator} userId
 * @property {Object} selectedRow
 * @property {callback} onSelect
 * @property {filterComparator} totalItems
 * @property {number} limit
 * @property {Object} variables
 * @property {function} setLimit
 * @property {number} activeStep
 * @property {function} setActiveStep
 * @property {Object} sortModel
 * @property {function} onSortModelChange
 * @property {boolean} noScrollToTop do not scroll to top of page on pagination
 */

/**
 * @param {TasksDataGridProps} props
 */
const TasksDataGrid = (props) => {

	const columnSettingsKey = 'TasksDataGrid'

	// handle null response.
	const rows = props.rows || []

	const [density, setDensity] = useState(
		localStorage.getItem(`${columnSettingsKey}.density`) || 'comfortable')
	useEffect(() => localStorage.setItem(`${columnSettingsKey}.density`, density),
		[density])

	const [columnVisibilityModel, setColumnVisibilityModel] = useState(
		JSON.parse(localStorage.getItem(`${columnSettingsKey}.visibility`) || null)
	)
	useEffect(() => localStorage.setItem(`${columnSettingsKey}.visibility`, JSON.stringify(columnVisibilityModel)),
		[columnVisibilityModel])

	const [currentTask, setCurrentTask] = React.useState(null)
	const [type, setType] = React.useState(null)

	// Modal
	const [editTaskModal, setEditTaskModal] = React.useState({open: false, task: null})
	const [infoModal, setInfoModal] = React.useState({open: false})

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])


	const [cellAnchorEl, setCellAnchorEl] = React.useState(null)
  
	const handlePopoverOpen = (event) => {

		const id = event.currentTarget.parentElement.dataset.id
		const row = rows.find((r) => r.id === id)
		if (row.is_deleted) setCellAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setCellAnchorEl(null)
	}

	const open = Boolean(cellAnchorEl)

	const handleDeleteTaskResponse = (response) => {

		const data = response && response.data

		if (!data || data.errors) {
			openSnackbar(severity.ERROR, 'There was an error deleting this task.')
		}

		else if (data.deleteContactTask?.success) {
			openSnackbar(severity.SUCCESS, "Successfully deleted this contact's task.")
			props.handleSubmit({cursor: 0}, props.currentSearch.search_terms, props.currentSearch.id)
		}
		else if (data.deleteEntityTask?.success) {
			openSnackbar(severity.SUCCESS, "Successfully deleted this task.")
			props.handleSubmit({cursor: 0}, props.currentSearch.search_terms, props.currentSearch.id)
		}
		else if (data.deleteArtistTask?.success) {
			openSnackbar(severity.SUCCESS, "Successfully deleted artist's contact's task.")
			props.handleSubmit({cursor: 0}, props.currentSearch.search_terms, props.currentSearch.id)
		}
		else if (data.deleteArtTask?.success) {
			openSnackbar(severity.SUCCESS, "Successfully deleted this artwork's task.")
			props.handleSubmit({cursor: 0}, props.currentSearch.search_terms, props.currentSearch.id)
		}
		else if (data.deleteDealTask?.success) {
			openSnackbar(severity.SUCCESS, "Successfully deleted this deal's task.")
			props.handleSubmit({cursor: 0}, props.currentSearch.search_terms, props.currentSearch.id)
		}
		else if (data.deleteListingTask?.success) {
			openSnackbar(severity.SUCCESS, "Successfully deleted this listings' task.")
			props.handleSubmit({cursor: 0}, props.currentSearch.search_terms, props.currentSearch.id)
		}

		else openSnackbar(severity.ERROR, 'Could not delete this task.')
		
	}

	const handleDeleteTaskError = (error) => {
		openSnackbar(severity.ERROR, 'There was an error deleting this task.')
	}

	// Mutations
	const [deleteEntityTask] = useMutation(DELETE_ENTITY_TASK)
	const [deleteArtTask] = useMutation(DELETE_ART_TASK)
	const [deleteArtistTask] = useMutation(DELETE_ARTIST_TASK)
	const [deleteContactTask] = useMutation(DELETE_CONTACT_TASK)
	const [deleteDealTask] = useMutation(DELETE_DEAL_TASK)
	const [deleteListingTask] = useMutation(DELETE_LISTING_TASK)


	React.useEffect(() => {
		if (currentTask?.art_id) {
			setType("Art")

		} else if (currentTask?.artist_id) {
			setType("Artist")

		} else if (currentTask?.contact_id) {
			setType("Contact")

		} else if (currentTask?.deal_id) {
			setType("Deal")

		} else if (currentTask?.listing_id) {
			setType("Listing")

		} else {
			setType("Entity")

		}
	}, [currentTask])

	const deleteTask = (row) => {
		const variables = { id: currentTask.id }

		if (row.art_id) {
			deleteArtTask({
				variables,
			})
				.then(handleDeleteTaskResponse)
				.catch(handleDeleteTaskError)
		} else if (row.artist_id) {
			deleteArtistTask({
				variables,
			})
				.then(handleDeleteTaskResponse)
				.catch(handleDeleteTaskError)
		} else if (row.contact_id) {
			deleteContactTask({
				variables,
			})
				.then(handleDeleteTaskResponse)
				.catch(handleDeleteTaskError)
		} else if (row.deal_id) {
			deleteDealTask({
				variables,
			})
				.then(handleDeleteTaskResponse)
				.catch(handleDeleteTaskError)
		} else if (row.listing_id) {
			deleteListingTask({
				variables,
			})
				.then(handleDeleteTaskResponse)
				.catch(handleDeleteTaskError)
		} else {
			deleteEntityTask({
				variables,
			})
				.then(handleDeleteTaskResponse)
				.catch(handleDeleteTaskError)
		}
	}
	
	const favoriteAndFlag = params => {
		if (!params.row?.created_at) {
			return <span></span>
		}
		return <FavoriteAndFlag
			entityName="task"
			row={params.row}
			mainSearchPage={props.mainSearchPage}
			userId={props?.userId}
			setRows={props.setRows}
			setEntity={props.setTasks}
			listName={props.listName}
			setFlaggedCount={props.setFlaggedCount}
			compact={density === 'compact'}
			hidden={params.row.is_private && !params.row.created_at}
		/>
	}

	const flagMenu = params => <DataGridFlagMenu
		currentFilters
		filterTo={props.filterTo}
		variables={props.variables}
		setFlaggedCount={props.setFlaggedCount}
		setRows={props.setRows}
		rows={props.rows}
		totalItems={props.totalItems}
		type={props.type}
	/>

	const openContextMenu = (field) => (e) => {
		e.preventDefault()
		e.stopPropagation()
		setAnchorEl(e[field])
	}

	const goNewTabWrapper = (task) => {
		if (task.contact_id) {

			if (task.contact.is_private && !task.contact.created_at) return

			window.open(
					`/contacts/${task.contact_id}/tasks`,
					'_blank'
			)
		} else if (task.listing_id) {
			window.open(
					`/listings/${task.listing_id}/tasks`,
					'_blank'
			)
		} else if (task.deal_id) {

			if ((task.deal.is_private || task.deal.is_gallery_private) && !task.deal.created_at) return

			window.open(
					`/deals/${task.deal_id}/tasks`,
					'_blank'
			)
		} else if (task.art_id) {

			if (task.art.is_private && !task.art.created_at) return

			window.open(
					`/art/${task.art_id}/tasks`,
					'_blank'
			)
		} else if (task.artist_id) {
			window.open(
					`/artists/${task.artist_id}/tasks`,
					'_blank'
			)
		} else {
			openSnackbar(severity.WARNING, 'This task is not linked to an object.')
		}
		handleClose()
	}

	const saveAndGoWrapper = (task, type) => {

		if (task.contact_id) {

			if (task.contact.is_private && !task.contact.created_at) return

			props.saveAndGo(
					`/contacts/${task.contact_id}/tasks`,
					task.id
			)
		} else if (task.listing_id) {
			props.saveAndGo(
					`/listings/${task.listing_id}/tasks`,
					task.id
			)
		} else if (task.deal_id) {

			if ((task.deal.is_private || task.deal.is_gallery_private) && !task.deal.created_at) return

			props.saveAndGo(
					`/deals/${task.deal_id}/tasks`,
					task.id
			)
		} else if (task.art_id) {

			if (task.art.is_private && !task.art.created_at) return

			props.saveAndGo(
					`/art/${task.art_id}/tasks`,
					task.id
			)
		} else if (task.artist_id) {
			props.saveAndGo(
					`/artists/${task.artist_id}/tasks`,
					task.id
			)
		} else {
			openSnackbar(severity.WARNING, 'This task is not linked to an object.')
		}
		handleClose()
	}


	const actionMenu = params => {
		if (!params.row?.created_at) {
			return <span></span>
		}

		return <IconButton
			aria-label="More"
			style={{
				padding: '6px',
				marginRight: '-9px',
			}}
			onClick={e => {
				if (params.row.disabled || params.row.hidden) return
				setCurrentTask(params.row)
				openContextMenu('currentTarget')(e)
			}}
		>
			<MoreVertIcon
				style={ params.row.isPrivate ? { color: 'white' } : null }
			/>
		</IconButton>
	}

	const [anchorEl, setAnchorEl] = useState(null)
	const [columns, setColumns] = useState([])

	// Set up grid with loaded dependencies, and saved data.
	const updateColumns = () => {

		const cols = getCols({
			loading: props.loading,
			favoriteAndFlag, 
			actionMenu, 
			flagMenu, 
			density
		})

		// restore on load.
		const columnSettings = JSON.parse(localStorage.getItem(columnSettingsKey))
		if (columnSettings?.length) restoreColumnSettings(cols, columnSettings)

		if (!columnVisibilityModel) {
			const obj = cols.filter(c => c.hide)
				.reduce((acc, el) => {
					acc[el.field] = false
					return acc
				}, {})
			setColumnVisibilityModel(obj)
		}

		setColumns([...cols])
	}

	// on Load, load column settings, sort, merge in (once everything is loaded.)
	useEffect(() => {
		if (props.userId) updateColumns()
	}, [props.userId, density])

	// update the flag menu/toggle any time the props change
	// to prevent "stale closures"
	useEffect(() => {
		setColumns(cols => {
			const flagCol = cols.find(c => c.field === 'isFlagged')
			if (flagCol) {
				flagCol.renderHeader = flagMenu
				flagCol.renderCell = favoriteAndFlag
				flagCol.width = (density === 'compact') ? 80 : 50
			}
			return [...cols]
		})
	}, [props.variables, props.totalItems, props.userId, density])

	// on unmount, stash columns back in storage. (eventually in DB)
	useEffect(() => {

		// stash on nav. (`columns` needs to be in the deps in order to be accessed.)
		return () => {
			if (columns.length) {
				const columnSettings = columns.map(col => ({
					field: col.field,
					width: col.width,
					minWidth: col.minWidth,
					hide: col.hide
				}))
				localStorage.setItem(columnSettingsKey, JSON.stringify(columnSettings))
			}
		}
	}, [columns])

	const handleClose = () => setAnchorEl(null)

	const resetGrid = () => {
		localStorage.removeItem(columnSettingsKey)
		localStorage.removeItem(`${columnSettingsKey}.visibility`)
		setColumnVisibilityModel(null)
		updateColumns()
	}

	const CustomToolBar =  () => <GridToolbarContainer>
		<Button
			size="small"
			startIcon={<Undo />}
			onClick={resetGrid}
		>
			Reset
		</Button>
		<GridToolbarColumnsButton />
		<GridToolbarDensitySelector />
	</GridToolbarContainer>

	// Scroll to the top of current data grid component when paginating
	useEffect(() => {

		if (props.loading === false && !props.scrollIntoView) {

			elementReady(`.${columnSettingsKey}`)
				.then(searchList => 
					searchList.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					})
				)
		}

	}, [props.activeStep])

	return <>
		<Paper
			className={clsx({
				'data-grid-padding': true,
				[columnSettingsKey]: true
			})}
			data-testid={`card-${props.listHeader ? props.listHeader : "Results "}`}
			style={{
				...props.style,
				flexGrow: 1 
			}}
		>
			<h1 className="card-title" style={{
				paddingTop: '1em',
				paddingLeft: '2em'
			}}>
				{getListHeader(props.listHeader, props.totalItems, props.canSee)}
			</h1>

			<div style={{ height: 'calc(100% - 4em)', width: '100%' }}>	
				<DataGridPro
					componentsProps={{
						cell: {
							onMouseEnter: handlePopoverOpen,
							onMouseLeave: handlePopoverClose,
						},
					}}
					components={{ Toolbar: CustomToolBar }}
					rowCount={props.totalItems || 0}
					rows={rows} 
					columns={columns}
					isCellEditable={(params) => !params.row.is_deleted}
					onCellDoubleClick={({row, isEditable}) => {
						if (!isEditable && !row.is_deleted) saveAndGoWrapper(row, 'doubleClick')
					}}
					density={density}
					disableColumnMenu
					disableMultipleSelection
					pagination
					paginationMode="server"
					sortingMode="server"
					pageSize={props.limit}
					onPageSizeChange={props.setLimit}
					rowsPerPageOptions={[10, 25, 50]}
					onRowClick={(params, event) => {
						let row = params.row
						if (event.metaKey) {
							goNewTabWrapper(row)
						} else {
							props.onSelect(row)
						}
					}}
					selectionModel={props.selectedRow?.id ? [props.selectedRow?.id] : []}
					page={props.activeStep}
					onPageChange={props.setActiveStep}
					onSortModelChange={props.onSortModelChange}
					loading={props.loading}
					onColumnVisibilityModelChange={setColumnVisibilityModel}
					columnVisibilityModel={columnVisibilityModel || {}}
					onColumnOrderChange={({ oldIndex, targetIndex }) => {
						arraymove(columns, oldIndex, targetIndex)
						setColumns([...columns])
					}}
					onColumnWidthChange={({ colDef, width}) => {
						columns.find(col => col.field === colDef.field).width = width
						setColumns([...columns])
					}}
					onStateChange={(args) => setDensity(args.density?.value ?? 'comfortable')}
				/>
			</div>

			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={!!anchorEl}
				onClose={handleClose}
			>
				<MenuItem
					onClick={(e) => saveAndGoWrapper(currentTask, 'menuItem')}
					data-testid="task-result-button-item"
				>
                        Go to
				</MenuItem>
				<MenuItem
					onClick={(e) => {
						setEditTaskModal({
							open: true,
							task: {
								...currentTask,
								// TODO: Phase II assigned_to_department: currentTask.assigned_to_department?.id || null,
							},
						})
						handleClose()
					}}
					data-testid="task-result-button-item"
				>
                        Edit
				</MenuItem>
				<MenuItem onClick={e => {
					setInfoModal({open: true})
					handleClose()
				}}
				data-testid="task-result-button-item"
				>
                    Info
				</MenuItem>
				<MenuItem onClick={(e) => {
                
					deleteTask(currentTask)
					handleClose()

				}}
				data-testid="task-result-button-item"
				>Delete</MenuItem>
				
			</Menu>
		</Paper>

		<TransitionsModal
			className="edit-task-modal"
			open={editTaskModal.open}
			close={() => setEditTaskModal({...editTaskModal, open: false})}
		>	
			<EditTask
				setEditTaskModal={setEditTaskModal}
				editTaskModal={editTaskModal}
				dynamic={true}
				currentTask={currentTask}
				setTasks={props.setRows}
				tasks={props.rows}
				linkField="task_id"
				entityId='7'
				mutationResponse={`update${type}Task`}
				type={type}
			/>
		</TransitionsModal>
            
		<InfoCard
			open={infoModal.open}
			object={currentTask}
			setInfoModal={setInfoModal}
			ignoredAttributes={getIgnoredAttributes(currentTask)}
			allAttributes
		/>

		<Popover
			sx={{
				pointerEvents: 'none',
			}}
			open={open}
			anchorEl={cellAnchorEl}
			transitionDuration={500}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			onClose={handlePopoverClose}
			disableRestoreFocus
		>
			<Typography sx={{ p: 1 }}>This task will be removed from search results momentarily.</Typography>
		</Popover>
	</>
	
}

export default TasksDataGrid
