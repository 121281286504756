/* eslint-disable eqeqeq */
import { Paper, MenuItem, Menu, Select, Skeleton } from '@mui/material'
import { DispatchContext } from '../../store'
import makeStyles from '@mui/styles/makeStyles'
import { severity } from '../../Snackbar/CustomizedSnackbar'

import { useMutation } from "@apollo/client"
import IconButton from "@mui/material/IconButton"
import InfoCard from '../../InfoCard/InfoCard'

import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import React, { useContext, useCallback, useEffect } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"

import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import EnhancedTableHead, {getComparator, stableSort} from '../../table/EnhancedTableHead'
import EditTask from '../EditTask'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

import CustomAvatarGroup from '../../common/components/CustomAvatarGroup'

import { LookupContext } from '../../store'

import { 
	DELETE_CONTACT_TASK,
	DELETE_ARTIST_TASK,
	DELETE_ART_TASK,
	DELETE_ENTITY_TASK,
	DELETE_DEAL_TASK,
	DELETE_LISTING_TASK,
	UPDATE_ENTITY_TASK,
	UPDATE_ART_TASK,
	UPDATE_ARTIST_TASK,
	UPDATE_CONTACT_TASK,
	UPDATE_DEAL_TASK,
	UPDATE_LISTING_TASK,
} from '../Queries'
import { formatDate } from '../../common/helpers'
import UserAvatar from '../../common/components/UserAvatar'
import Dropdown from '../../input/Dropdown/Dropdown'

const headCells = [
	{
		id: "task_status",
		numeric: false,
		disablePadding: true,
		label: "Status"
	},
	{
		id: "type_id",
		numeric: false,
		disablePadding: true,
		label: "Type"
	},
	{
		id: "description",
		numeric: false,
		disablePadding: true,
		label: "Description"
	},
	{ id: "assigned_to_type", numeric: false, disablePadding: false, label: "Assignees" },
	{ id: "due_at", numeric: true, disablePadding: false, label: "Due Date & Time" },
	{ id: "actions", numeric: false, disablePadding: true, label: "Actions" }
]

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%"
	},
	paper: {
		width: "100%",
		marginBottom: theme.spacing(2)
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1
	}
}))


export default function TasksList(props) {

	const lookup = useContext(LookupContext)
	const taskTypes= lookup.data?.getTaskTypes
	const taskStatusTypes = lookup.data?.getTaskStatusTypes

	// State
	const classes = useStyles()
	const [order, setOrder] = React.useState("asc")
	const [orderBy, setOrderBy] = React.useState(null)
	const [infoModal, setInfoModal] = React.useState({open: false})
	const [tasks, setTasks] = React.useState([])

	useEffect(() => {
		setTasks(props.tasks.filter(e => e.task_status_id == props.status))
	}, [props.tasks, props.status])

	// Sorting
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	// Menu
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [currentTask, setCurrentTask] = React.useState(null)

	const handleClick = (event, task)=> {
		setAnchorEl(event.currentTarget)
		setCurrentTask(task)
	}
	
	const handleClose = () => {
		setAnchorEl(null)
	}

	
	const handleResponse = (response, name) => {

		if (response && response.data[name].success === false) {
			console.error(response.data[name])
			openSnackbar(severity.ERROR, 'There was an error updating this task.')
			
		} else if (response && response.data[name].success === true) {
			openSnackbar(severity.SUCCESS, 'Successfully updated task.')
			props.refetch()

		} else {
			console.error(response.data[name])
			openSnackbar(severity.ERROR, 'Could not update task.')

		}
	}

	const handleError = (error) => {
		console.error(error)
		openSnackbar(severity.ERROR, 'There was an error updating this task.')
	}


	const handleDeleteTask = (response) => {

		let mutationResponse

		switch (props.linkField) {
		case "art_id":
			mutationResponse = 'deleteArtTask'
			break

		case "artist_id":
			mutationResponse = 'deleteArtistTask'
			break

		case "contact_id":
			mutationResponse = 'deleteContactTask'
			break

		case "deal_id":
			mutationResponse = 'deleteDealTask'
			break

		case "listing_id":
			mutationResponse = 'deleteListingTask'
			break
				
		default:
			mutationResponse = 'deleteEntityTask'
			break
		}

		if (response?.data[mutationResponse]?.success === false) {

			console.error(response?.data[mutationResponse])
			openSnackbar(
				severity.ERROR,
				response?.data[mutationResponse]?.message
					|| 'There was an error deleting this task.'
			)
		} else if (response?.data[mutationResponse]?.success == true) {
									
			let newTasks = tasks?.filter(task => task.id != currentTask.id)
			openSnackbar(severity.SUCCESS, "Successfully deleted task.")
			setTasks(newTasks)
		} else {
									
			console.error(response?.data[mutationResponse])
			openSnackbar(
				severity.ERROR,
				response?.data[mutationResponse]?.message
					|| 'Could not delete task.'
			)
		}
	}

	const handleDeleteError = (error) => {
		console.error(error)
		openSnackbar(severity.ERROR, "There was an error deleting this task.")
	}

	// Mutations
	const [deleteContactTask] = useMutation(DELETE_CONTACT_TASK)
	const [deleteArtistTask] = useMutation(DELETE_ARTIST_TASK)
	const [deleteArtTask] = useMutation(DELETE_ART_TASK)
	const [deleteDealTask] = useMutation(DELETE_DEAL_TASK)
	const [deleteEntityTask] = useMutation(DELETE_ENTITY_TASK)
	const [deleteListingTask] = useMutation(DELETE_LISTING_TASK)

	const [updateEntityTask] = useMutation(UPDATE_ENTITY_TASK)
	const [updateArtTask] = useMutation(UPDATE_ART_TASK)
	const [updateArtistTask] = useMutation(UPDATE_ARTIST_TASK)
	const [updateContactTask] = useMutation(UPDATE_CONTACT_TASK)
	const [updateDealTask] = useMutation(UPDATE_DEAL_TASK)
	const [updateListingTask] = useMutation(UPDATE_LISTING_TASK)

	const [updateTask] = useMutation(props.mutation)

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	// Modal
	const [editTaskModal, setEditTaskModal] = React.useState({open: false, task: null})
	
	return <>
		<Paper 
			className="qv-margin" 
			style={{paddingRight: "0em"}}
			data-testid={`card-${props.title.toLowerCase().replace(" ", "-")}`}
		>
			<h1 className="card-title no-bottom-margin">{props.title}</h1>

			<TableContainer className="task-list">
				<Table
					className={classes.table}
					aria-labelledby="tableTitle"
					size={"medium"}
					aria-label="enhanced table"
				>
					<EnhancedTableHead
						headCells={headCells}
						classes={classes}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={tasks?.length}
					/>
					<TableBody>
						{(props.tasksLoading) ?
							<TableRow > 
								<TableCell style={{width: "15%"}}><Skeleton animation='wave'/></TableCell>
								<TableCell style={{width: "10%"}}><Skeleton animation='wave'/></TableCell>
								<TableCell style={{width: "45%"}}><Skeleton animation='wave'/></TableCell>
								<TableCell style={{width: "15%"}}><Skeleton animation='wave' variant='circular' height={40} width={40}/></TableCell>
								<TableCell style={{width: "20%"}}><Skeleton animation='wave'/></TableCell>
								<TableCell style={{width: "5%"}}></TableCell>
							</TableRow>
							:
							null}

						{(tasks?.length === 0 && !props.tasksLoading)?
							<TableRow > 
								<TableCell style={{width: "15%", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px"}} />
								<TableCell style={{width: "10%", paddingRight: "1em"}}></TableCell>
								<TableCell style={{width: "45%", whiteSpace: "break-spaces", paddingTop: "1em", paddingBottom: "1em"}} >
									<div style={{
										height: 'calc(73px - 2em)',
										display: 'flex',
										alignItems: 'center'
									}}>
										No Tasks Found
									</div> 
								</TableCell>
								<TableCell style={{width: "15%", borderTopRightRadius: "10px", borderBottomRightRadius: "10px"}} />
								<TableCell style={{width: "20%"}}/>
								<TableCell style={{width: "5%"}}/>
							</TableRow>
							:
							null}
						{stableSort(tasks, getComparator(order, orderBy)).map(
							(row, index) => {


								return (
									<TableRow
										hover
										tabIndex={-1}
										key={row.id}
									>

										<TableCell style={{
											width: "15%",
											paddingLeft: '0.5em',
											paddingRight: '0.5em',
										}}>

											<Select
												IconComponent={ExpandMoreRoundedIcon}
												name="status"
												className="hideborder"
												input={<Dropdown />}
												value={row?.task_status_id || ''}
												onChange={(e) => {

													const updateVariables = {
														variables: {
															UpdateTaskInput: {
																id: row.id,
																task_status_id: e.target.value,
															},
														},
													}

													if (row.art_id)
														updateArtTask(updateVariables)
															.then((response) => handleResponse(response, 'updateArtTask'))
															.catch(handleError)
													else if (row.artist_id)
														updateArtistTask(updateVariables)
															.then((response) => handleResponse(response, 'updateArtistTask'))
															.catch(handleError)
													else if (row.contact_id)
														updateContactTask(updateVariables)
															.then((response) => handleResponse(response, 'updateContactTask'))
															.catch(handleError)
													else if (row.deal_id)
														updateDealTask(updateVariables)
															.then((response) => handleResponse(response, 'updateDealTask'))
															.catch(handleError)
													else if (row.listing_id)
														updateListingTask(updateVariables)
															.then((response) => handleResponse(response, 'updateListingTask'))
															.catch(handleError)
													else
														updateEntityTask(updateVariables)
															.then((response) => handleResponse(response, 'updateEntityTask'))
															.catch(handleError)
												}}
											>
												{taskStatusTypes?.map((type) => (
													<MenuItem key={type.id} value={type.id}>
														{type.value}
													</MenuItem>
												))}
											</Select> 
										</TableCell>
										<TableCell
											component="th"
											scope="row"
											padding="none"
											style={{width: "10%", paddingRight: "1em"}}
										>
											{taskTypes && taskTypes.find(item => item.id == row.type_id)?.value}
										</TableCell>
										<TableCell
											component="th"
											scope="row"
											padding="none"
											style={{width: "45%", whiteSpace: "break-spaces", paddingTop: "1em", paddingBottom: "1em"}}
										>
											<div style={{maxHeight: "11em", overflow: "auto"}}>
												{row.description.trim()}
											</div>
										</TableCell>
										<TableCell align="right" style={{width: '15%'}}>

											{/* Display Users */}
											{row.assigned_to_type === 'User' ? 
												<CustomAvatarGroup
													max={3}
													key={'AvatarGroup-' + index}
													extraavatarstooltiptitle={
														row.assigned_to_users.map((user, index) => {
															if(index > 1){
																return(
																	<p key={user.id} style={{ margin: 0, padding: 0 }}>
																		{user.first_name + " " + user.last_name}
																	</p> )
															} else return null
														})
                                                                
													}
												>
													{row.assigned_to_users.map((user, index) => 
														<UserAvatar key={user.id} sp={user} index={index} length={row?.assigned_to_users?.length}/>
													)}
												</CustomAvatarGroup>	
												: null}
                                            
											{/* Display Department */}
											{row.assigned_to_type === "Department" ? 
                                            
												<div>{row.assigned_to_department.value}</div>
												: null}

										</TableCell>

										<TableCell style={{width: "20%"}} align="right">{formatDate(row.due_at, "iiii, MMMM do, yyyy 'at' h':'mma")}</TableCell>
										<TableCell align="right" style={{width: "5%", borderTopRightRadius: "10px", borderBottomRightRadius: "10px"}}>
											<>
												<IconButton
													aria-label="More"
													style={{ padding: "6px", marginRight: "-9px" }}
													onClick={e => {
														e.preventDefault()
														e.stopPropagation()
														handleClick(e, row)
													}}
													size="large">
													<MoreHorizIcon />
												</IconButton>
											</>
										</TableCell>
									</TableRow>
								) 
							}
						)}
					</TableBody>
				</Table>
			</TableContainer>

			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={(e) => {
					setInfoModal({open: true})
					handleClose()
				}}>Info</MenuItem>
				<MenuItem onClick={(e) => {
					setEditTaskModal({open: true, task: {
						...currentTask,
						assigned_to_department: currentTask.assigned_to_department?.id || null
					}})
					handleClose()
				}}>Edit</MenuItem>
				<MenuItem onClick={(e) => {

					const variables = { 
						id: currentTask.id 
					}

					switch (props.linkField) {
					case "art_id":
						deleteArtTask({
							variables
						})
							.then(handleDeleteTask)
							.catch(handleDeleteError)
						break

					case "artist_id":
						deleteArtistTask({
							variables
						})
							.then(handleDeleteTask)
							.catch(handleDeleteError)
						break

					case "contact_id":
						deleteContactTask({
							variables
						})
							.then(handleDeleteTask)
							.catch(handleDeleteError)
						break

					case "deal_id":
						deleteDealTask({
							variables
						})
							.then(handleDeleteTask)
							.catch(handleDeleteError)
						break

					case "listing_id":
						deleteListingTask({
							variables
						})
							.then(handleDeleteTask)
							.catch(handleDeleteError)
						break
                        
					default:
						deleteEntityTask({
							variables
						})
							.then(handleDeleteTask)
							.catch(handleDeleteError)
						break
					}
                        
					handleClose()

				}}>Delete</MenuItem>
			</Menu>
		</Paper>

		<TransitionsModal
			className="edit-task-modal"
			open={editTaskModal.open}
			close={() => setEditTaskModal({...editTaskModal, open: false})}
		>
			<EditTask 
				setEditTaskModal={setEditTaskModal}
				editTaskModal={editTaskModal}
				updateTask={updateTask}
				currentTask={currentTask}
				setTasks={props.setTasks}
				tasks={tasks}
				linkField={props.linkField}
				entityId={props.entityId}
				mutationResponse={props.mutationResponse}
			/>
		</TransitionsModal>

		<InfoCard
			open={infoModal.open}
			object={currentTask}
			setInfoModal={setInfoModal}
			ignoredAttributes={[
				'type_id',
				'task_status_id',
				'assigned_to_type',
				'assigned_by_user_id',
				'due_at',
				'created_by',
				'modified_by',
				'description'
			]}
			allAttributes
		/>
	</>
}
