import { gql } from '@apollo/client'
import { userData } from '../common/fragments'

export const GET_SEARCHES = gql`
	query GetSearches($userId: ID, $objectId: ID, $global: Boolean) {
		getSearches(input: { userId: $userId, objectId: $objectId, global: $global }) {
			id
			label
			user_id
			object_id
			color
			search_terms {
				field
				comparator
				value
				ordinal
			}
			result_count
			last_run_at
			created_at
			created_by
			modified_at
			modified_by
			is_global
		}
	}
`

export const CREATE_LISTING_AND_RELATIONS = gql`
	mutation createListingAndInsertRelation($CreateListingInput: CreateListingInput!) {
		createListingAndInsertRelation(input: $CreateListingInput) {
			code
			success
			message
			listing {
				id
				title
				start_at
				end_at
				start_time
				end_time
				timezone_id
				location
				listing_type {
					value
				}
			}
		}
	}
`

export const CREATE_SEARCH = gql`
	mutation createSavedSearch($CreateSearchInput: CreateSearchInput!) {
		createSavedSearch(input: $CreateSearchInput) {
			code
			success
			message
			search {
				id
				label
				user_id
				object_id
				color
				search_terms {
					field
					comparator
					value
					ordinal
				}
				result_count
				last_run_at
				is_global
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
			}
		}
	}
`

export const UPDATE_SEARCH = gql`
	mutation updateSavedSearch($UpdateSearchInput: UpdateSearchInput!) {
		updateSavedSearch(input: $UpdateSearchInput) {
			code
			success
			message
		}
	}
`

export const DYNAMIC_CONTACT_SEARCH = gql`
	query searchDynamicContacts($field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int) {
		searchDynamicContacts(input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters}) {
			cursor
			totalItems
			items {
				id
				first_name
				last_name
				is_company
				company_name
				title
				grade {
					value
				}
				activity {
					value
				}
				prefix {
					value
				}
				status {
					value
				}
				type {
					id
					contact_type
				}
				is_deceased
				code_name
				is_private
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				salesperson {
					id
					first_name
					last_name
					user_handle
					imgUrl
					is_lead
					is_deleted
				}
				phone {
					id
					number
					is_primary
				}
				email {
					id
					email
					is_primary
				}
				address {
					id
					town
					region
					country
					is_primary
				}
				imgUrl (thumbnailResolution: "128x128")
				isFavorite
				isFavorite
				isFlagged
				publicAccess
				isPermittedContactType
				dynamic_generated_codename
				is_compliant
			}
		}
	}
`

export const DYNAMIC_TAG_SEARCH = gql`
	query searchDynamicTags($field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int) {
		searchDynamicTags(input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters}) {
			cursor
			totalItems
			items {
				id
				label
				description
				color_hex
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				created_user_entity {
					id
					first_name
					last_name
					imgUrl (thumbnailResolution: "128x128")
					gallery
				}
				record_count
			}
		}
	}
`

export const DYNAMIC_USER_SEARCH = gql`
	query searchDynamicUsers($field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int) {
		searchDynamicUsers(input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters}) {
			cursor
			totalItems
			items {
				id
				first_name
				last_name
				imgUrl (thumbnailResolution: "128x128")
				user_handle
				full_name
				full_name_searchable
				gallery
				department
				has_profile_image
				prefix_id
				email
				department_id
				gallery_id
				phone_label1
				phone_number1
				phone_label2
				phone_number2
				title
				suffix
				profile_link
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
			}
		}
	}
`

export const DYNAMIC_IMPORT_SEARCH = gql`
	query searchDynamicImport($field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int) {
		searchDynamicImport(input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters}) {
			cursor
			totalItems
			items {
				id
				type_id
				type {
					id
					value
				}
				status_id
				status {
					id
					value
				}
				name
				
				error_count
				execution_time_ms

				s3_error_file
				s3_runtime_error_file

				inserted_row
				inserted_note
				inserted_tag
				inserted_tag_link
				inserted_image
				inserted_image_id
				inserted_image_link
				inserted_image_id

				art_max_inv_number
				art_inserted_crnumber
				art_inserted_research
				art_inserted_listing
				art_inserted_edition_copy
				art_inserted_edition_copy_total
				art_inserted_edition_ap
				art_inserted_edition_ap_total
				art_inserted_other_inventory
				art_inserted_owner_contact_id

				contact_inserted_phone
				contact_inserted_email
				contact_inserted_address
				contact_inserted_website
				contact_inserted_type
				contact_inserted_listing

				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				is_private
			}
		}
	}
`

export const DYNAMIC_ART_SEARCH = gql`
	query searchDynamicArt($field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int) {
		searchDynamicArt(input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters}) {
			cursor
			totalItems
			items {
				id
				is_verified
				ready
				catalogue_raisonne
				artist {
					id
					first_name
					last_name
				}
				customs_status
				art_location {
					is_current
					location_change_at
					sub_location {
						name
					}
					location {
						name
					}
				}
				title
				formatted_title
				year
				status_id
				is_active
				inventory_number
				inventory_number_prefix
				inventory_number_suffix
				medium
				edition_copy_number
				edition_copy_total
				edition_ap
				edition_ap_total
				current_sources {
					id
					first_name
					last_name
					is_company
					company_name
					is_private
					code_name
					imgUrl (thumbnailResolution: "128x128")
					is_deceased
					canNavigate
					dynamic_generated_codename
					created_at
					deal_entry_status_category
					deal_entry_status
					contact_role_id
				}
				all_dimensions
				code_name
				is_private
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				isFavorite
				isFlagged
				imgUrl (thumbnailResolution: "128x128")
				salesperson {
					id
					first_name
					last_name
					user_handle
				}
				primary_currency_id
				primaryRetailPrice
				is_gallery_private

			}
		}
	}
`

export const DYNAMIC_DEAL_SEARCH = gql`
	query searchDynamicDeals($field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int) {
		searchDynamicDeals(input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters}) {
			cursor
			totalItems
			items {
				id
				isFavorite
				isFlagged
				created_at
				modified_at
				type_id
				code_name
				deal_type {
					id
					value
				}
				is_private
				is_gallery_private
				created_at
				art {
					id
					title
					year
					is_private
					code_name
					created_at
					imgUrl (thumbnailResolution: "128x128")
					artist {
						id
						first_name
						last_name
					}
				}
				contacts (primaryOnly: true) {
					id
					first_name
					last_name
					imgUrl (thumbnailResolution: "128x128")
					company_name
					is_company
					code_name
					is_private
					is_deceased
					contactRole {
						id
						value
					}
					dynamic_generated_codename
					created_at
				}
				salesperson {
					...userData
					display_name
					is_lead
				}
				publicAccess
			}
		}
	}
	${userData}
`

export const INSERT_LISTING_IDS = gql`
	mutation insertListingIds($InsertListingInput: InsertListingInput!) {
		insertListingIds(input: $InsertListingInput) {
			code
			success
			message
			listing {
				id
				title
			}
		}
	}
`

export const DELETE_LISTING_IDS = gql`
	mutation deleteListingIds($DeleteListingContacts: DeleteListingContacts!) {
		deleteListingIds(input: $DeleteListingContacts) {
			code
			success
			message
			listing {
				id
				title
			}
		}
	}
`

export const GET_EXPORT = gql`
	query GetExport($args: JSON) {
		getExport(args: $args)
	}
`

export const GET_DEAL_INFO_EXPORT = gql`
	query GetDealInfoExport($args: JSON) {
		getDealInfoExport(args: $args)
	}
`

export const GET_EXPORTABLE_FIELDS = gql`
	query getExportableFields {
		getExportableFields
	}
`

export const GET_CONTACT_FILTER_FIELDS = gql`
	query searchDynamicContactsFilterFields {
		searchDynamicContactsFilterFields {
			name
			type
			label
		}
	}
`

export const GET_TAG_FILTER_FIELDS = gql`
	query searchDynamicTagsFilterFields {
		searchDynamicTagsFilterFields {
			name
			type
			label
		}
	}
`

export const GET_USER_FILTER_FIELDS = gql`
	query searchDynamicUsersFilterFields {
		searchDynamicUsersFilterFields {
			name
			type
			label
			permission_id
		}
	}
`

export const GET_IMPORT_FILTER_FIELDS = gql`
	query searchDynamicImportFilterFields {
		searchDynamicImportFilterFields {
			name
			type
			label
		}
	}
`

export const GET_ART_FILTER_FIELDS = gql`
	query searchDynamicArtFilterFields {
		searchDynamicArtFilterFields {
			name
			type
			label
			nestedQueryType
		}
	}
`

export const GET_LISTING_FILTER_FIELDS = gql`
	query searchDynamicListingsFilterFields {
		searchDynamicListingsFilterFields {
			name
			type
			label
		}
	}
`

export const GET_TASK_FILTER_FIELDS = gql`
	query searchDynamicTasksFilterFields {
		searchDynamicTasksFilterFields {
			name
			type
			label
		}
	}
`

export const GET_ARTIST_FILTER_FIELDS = gql`
	query searchDynamicArtistsFilterFields {
		searchDynamicArtistsFilterFields {
			name
			type
			label
		}
	}
`

export const GET_DEAL_FILTER_FIELDS = gql`
	query searchDynamicDealsFilterFields {
		searchDynamicDealsFilterFields {
			name
			type
			label
		}
	}
`

export const UPDATE_GALLERY_CONTACTS = gql`
	mutation updateGalleryContacts($GalleryContactInput: GalleryContactInput!) {
		updateGalleryContacts(input: $GalleryContactInput) {
			code
			success
			message
		}
	}
`

export const UPDATE_LOCATION_HISTORIES = gql`
	mutation updateLocationHistories($LocationHistoriesInput: LocationHistoriesInput!) {
		updateLocationHistories(input: $LocationHistoriesInput) {
			code
			success
			message
		}
	}
`
export const UPDATE_ART_PRICES = gql`
	mutation updateArtPrices($ArtPricesInput: ArtPricesInput!) {
		updateArtPrices(input: $ArtPricesInput) {
			code
			success
			message
		}
	}
`
export const UPDATE_CONTACT_INTERESTS = gql`
	mutation updateContactInterests($ContactInterestsInput: ContactInterestsInput!) {
		updateContactInterests(input: $ContactInterestsInput) {
			code
			success
			message
		}
	}
`

export const UPDATE_TASK_STATUSES = gql`
	mutation updateTaskStatuses($task_status_id: ID!, $taskIds: [ID]!) {
		updateTaskStatuses(task_status_id: $task_status_id, taskIds: $taskIds) {
			code
			success
			message
		}
	}
`

export const UPDATE_TASK_ASSIGNEES = gql`
	mutation updateTaskAssignees($assigneeIds: [ID]!, $taskIds: [ID]!) {
		updateTaskAssignees(assigneeIds: $assigneeIds, taskIds: $taskIds) {
			code
			success
			message
		}
	}
`

export const GET_TOTAL_ART_FINANCIALS = gql`
	query getTotalArtFinancials($ids: [ID!]!, $art_financial_type_id: ID!, $currency_id: ID!) {
		getTotalArtFinancials(ids: $ids, art_financial_type_id: $art_financial_type_id, currency_id: $currency_id) {
			retailResult
			costResult
			netResult
			insuredResult
			emptyFinancials
		}
	}
`

export const DYNAMIC_ARTISTS_SEARCH = gql`
	query searchDynamicArtists($field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int) {
		searchDynamicArtists (input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters}) {
			cursor
			totalItems
			items {
				id
				first_name
				last_name
				dates
				nationality
				created_at
				modified_at
				imgUrl (thumbnailResolution: "128x128")
				isFavorite
				isFlagged
			}
		}
	}
`

export const DYNAMIC_LISTING_SEARCH = gql`
	query searchDynamicListings(
			$field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int
		) {
		searchDynamicListings (
			input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters}
		) {
			cursor
			totalItems
			items {
				id
				listing_type {
					id
					value
				}
				title
				subtitle
				location
				start_at
				end_at
				created_by
				isFavorite
				isFlagged
				created_at
				modified_by
				modified_at
				is_deleted
				imgUrl (thumbnailResolution: "128x128")
				artCount
				contactCount
				created_user {
					first_name
					last_name
					id
				}
				modified_user {
					first_name
					last_name
					id
				}
			}
		}
	}
`


export const DYNAMIC_TASK_SEARCH = gql`
	query searchDynamicTasks($field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int) {
		searchDynamicTasks (input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters}) {
			cursor
			totalItems
			items {

				id
				type_id
				description
				due_at
				task_status
				task_status_id

				created_by
				created_at
				modified_by
				modified_at

				is_deleted
				assigned_to_type
				assigned_to_users {
					id
					first_name
					last_name
					imgUrl (thumbnailResolution: "128x128")
					user_handle
					department
					is_deleted
				}

				art_id
				art {
					id
					title
					imgUrl (thumbnailResolution: "128x128")
					formatted_title 
					year
					is_private
					code_name
					created_at
					artist {
						id
						first_name
						last_name
						genre {
							id
							artist_genre_id
							artist_genre
							ordinal
						}
					}
				}

				deal_id
				deal {
					id
					is_private
					is_gallery_private
					created_at
					code_name
				}

				contact_id
				contact {
					id
					is_private
					created_at
					code_name
					first_name
					last_name
					company_name
					is_company
					is_deleted
					is_deceased
					dynamic_generated_codename
				}

				listing_id
				listing {
					id
					title
					subtitle
					type_id
					created_at
					imgUrl (thumbnailResolution: "128x128")
				}

				artist_id
				artist {
					id
					first_name
					last_name
					type_id
					created_at
					imgUrl (thumbnailResolution: "128x128")
					genre {
						id
						artist_genre_id
						artist_genre
						ordinal
					}
				}

				isFavorite
				isFlagged
			}
		}
	}
`

export const CONTACT_FLAG_QUERY = gql`
	query searchDynamicContacts($field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int) {
		searchDynamicContacts(input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters}) {
			totalItems
		}
	}
`


export const ART_FLAG_QUERY = gql`
	query searchDynamicArt($field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int) {
		searchDynamicArt(input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters}) {
			totalItems
		}
	}
`

export const DEAL_FLAG_QUERY = gql`
	query searchDynamicDeals($field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int) {
		searchDynamicDeals(input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters}) {
			totalItems
		}
	}
`


export const LISTING_FLAG_QUERY = gql`
	query searchDynamicListings($field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int) {
		searchDynamicListings (input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters}) {
			totalItems
		}
	}
`

export const ARTIST_FLAG_QUERY = gql`
	query searchDynamicArtists($field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int) {
		searchDynamicArtists (input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters}) {
			totalItems
		}
	}
`

export const TASK_FLAG_QUERY = gql`
	query searchDynamicTasks($field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int) {
		searchDynamicTasks (input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters}) {
			totalItems
		}
	}
`
