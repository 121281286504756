import React, {useState, useEffect} from 'react'
import {Checkbox,  FormControl, FormControlLabel} from '@mui/material'
import Label from '../input/Label'
import chunk from 'lodash/chunk'
import TextBox from '../input/Text/TextBox'

const ART_DETAIL_OPTIONS_KEY = "ArtDetailOptions.fields"
const LISTING_ART_DETAIL_OPTIONS_KEY = "ListingArtDetailOptions.fields"

const ArtDetailOptions = (props) => {

	const common = {
		exportTitle: '',
		artist: true,
		title: true,
		year: true,
		medium: true,
		dimensions: true,
		inventory: true,
		catalogueRaisonne: true,
		currentLocation: true,
		consignor: true,
		buyer: true,
		currentOwner: true,
		galleryContacts: true,
		retail: true,
		net: true,
		insured: true,
		edition: true,
		signature: true,
		provenance: true,
		exhibition: true,
		literature: true,
		other: true,
		copyright: true,
		photographer: true,
		additional_credit_lines: true,
		notes: true,
		critical_notes: true,
		anonymize: true,
		has_coa: true,
	}

	const optionsMappings = {
		[ART_DETAIL_OPTIONS_KEY]: {
			...common,
			additional_credit_lines: true
		},

		[LISTING_ART_DETAIL_OPTIONS_KEY]: {
			...common,
			note: true
		},
	}



	const [fields, setFields] = useState({})
	if (!Object.keys(fields).length) {
		const savedFields = JSON.parse(localStorage.getItem(props.keyName || ART_DETAIL_OPTIONS_KEY))
		const newFields = Object.entries(optionsMappings[props.keyName || ART_DETAIL_OPTIONS_KEY])
			.reduce((acc, [key, val]) => {
				acc[key] = savedFields?.[key] ?? val
				return acc
			}, {})
		setFields(newFields)
	}

	const labels = {
		artist: "Artist Name",
		retail: "Retail Price",
		net: "Net Price",
		insured: 'Insured Price',
		title: "Title",
		year: "Date",
		medium: "Medium",
		dimensions: "Dimensions",
		inventory: "Inventory Number",
		catalogueRaisonne: 'Catalogue Raisonné',
		currentLocation: "Current Location",
		lead: "Lead",
		edition: "Edition",
		signature: "Signature",
		consignor: "Consignor",
		buyer: "Purchaser",
		currentOwner: "Known Sources",
		galleryContacts: "Gallery Contacts",
		provenance: "Provenance",
		exhibition: "Exhibition",
		literature: "Literature",
		other: "Text",
		copyright: "Copyright",
		photographer: "Photographer",
		additional_credit_lines: "Additional Credit Lines",
		note: "Listing Art Note",
		notes: "Notes",
		critical_notes: "Critical Notes",
		anonymize: 'Anonymize',
		includePageNumber: 'Include Page Number',
		has_coa: 'Certificate of Authenticity',
	}

	useEffect(() => {
		props.onChange(fields)
		// Only save to localstorage when the component closes.
		return () => {
			localStorage.setItem(props.keyName || ART_DETAIL_OPTIONS_KEY, JSON.stringify({
				...fields,
				exportTitle: ''
			}))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.onChange, fields] )

	const entries = Object.entries(fields)
		.filter(([k]) => !['exportTitle', 'anonymize', 'includePageNumber'].includes(k))
	const checkGrid = (chunk(entries, Math.ceil(entries.length / 3)))


	return (
		<div>
			<FormControl fullWidth>
				<Label disableAnimation shrink>
					Title
				</Label>

				<TextBox
					className='user-input'
					id='userQV-first-name'
					value={fields.exportTitle}
					onChange={(e) => {
						setFields({
							...fields,
							exportTitle: e.target.value,
						})
					}}
				/>
			</FormControl>
			<FormControl
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",

					paddingTop: "1.2em",
					marginRight: "1.3em",
					paddingLeft: "0.2em",
				}}
			>
				<Label disableAnimation shrink>
					Fields
				</Label>
				{checkGrid.map((row, i) => (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
						}}
						key={`row-${i}`}
					>
						{row.map(([key, value]) => (
							<FormControlLabel
								key={key}
								label={labels[key] || 'Removed'}
								control={
									<Checkbox
										checked={value}
										name={key}
										onChange={(e) => {
											setFields({
												...fields,
												[e.target.name]: e.target.checked,
											})
										}}
									/>
								}
							></FormControlLabel>
						))}
					</div>
				))}
			</FormControl>
			<FormControl
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",

					paddingTop: '1.2em',
					marginRight: '1.3em',
					paddingLeft: '0.2em'
				}}
			>
				<Label disableAnimation shrink>
					Advanced
				</Label>
				<FormControlLabel
					control={
						<Checkbox
							checked={fields.anonymize}
							name="anonymize"
							onChange={(e)=>{
								setFields({
									...fields,
									anonymize:  e.target.checked
								})
							}}
						/>
					}
					label="Anonymize"
				>
				</FormControlLabel>
				<FormControlLabel
					control={
						<Checkbox
							checked={fields.includePageNumber}
							name="includePageNumber"
							onChange={(e)=>{
								setFields({
									...fields,
									includePageNumber:  e.target.checked
								})
							}}
							disabled={props.footer === 'None'}
						/>
					}
					label="Include Page Number"
				>
				</FormControlLabel>
			</FormControl>
		</div>
	)
}

export default ArtDetailOptions

export { ART_DETAIL_OPTIONS_KEY, LISTING_ART_DETAIL_OPTIONS_KEY }
