/* eslint-disable eqeqeq */
import { Paper, Skeleton } from '@mui/material'
import React, { useContext } from 'react'
import Label from '../../input/Label'
import { LookupContext } from '../../store'
import { skeletonStyle } from '../../styles/makesStyles'

const UserGroups = (props) => {
	const lookup = useContext(LookupContext)

	const galleries = lookup.data?.getGalleryTypes

	const getUserGroupsByGallery = (galleryId) =>
		props.user?.user_group?.filter((userGroup) => userGroup.gallery_id == galleryId).map((team) => team.is_lead ? `${team.type.value} (Lead)` : team.type.value).join(', ')

	return (
		<Paper className="qv-margin-no-top">
			<h1 className="card-title">
				<span>User Group(s)</span>
			</h1>

			<div>
				{galleries?.map((gallery) => {
					return (
						<div key={gallery.id} style={{
							padding: '0.5em 0'
						}}> 
							{(props.loading || props.error) ? <Skeleton
								animation={props.error ? false : "wave"}
								variant="text"
								width="100%"
								style={skeletonStyle}
							/> :
								<>
									<Label disableAnimation shrink style={{
										marginLeft: 0,
										paddingTop: 0
									}}>
										{gallery.value}
									</Label>
									<div>{getUserGroupsByGallery(gallery.id) || '-'}</div>
								</>
							}
						</div>
					)
				})}
			</div>
		</Paper>
	)
}

export default UserGroups
