import { Fade } from '@mui/material'
import React from 'react'

const HelperText = ({value, open}) => {
	return (
		<Fade in={open}>
			<span style={{
				color: 'red',
			}}>
				{value}
			</span>
		</Fade>
	)
}

export default HelperText
