/* eslint semi: 0 */
/* eslint-disable eqeqeq */
import { useMutation, useQuery } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import { Paper, StyledEngineProvider, ThemeProvider } from '@mui/material';
import React, { useCallback, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { fetchWithAuth } from '../../common/helpers';
import { SavedSearch } from "../../common/SavedSearch";
import { permissions } from '../../constants/permissions';
import { DEFAULT_SEARCH_LIMIT, IMPORT_SEARCH_OBJECT } from "../../constants/values";
import ImportQuickView from '../../Import/ImportQuickView';
import CircularLoading from '../../navigation/CircularLoading';
import { DYNAMIC_IMPORT_NAV } from "../../navigation/Queries";
import useNavigation from "../../navigation/useNavigation";
import GlobalSearchFilters, { getOperatorValue, initialFilterState } from "../../Search/GlobalSearchFilters";
import { DYNAMIC_IMPORT_SEARCH, GET_IMPORT_FILTER_FIELDS, GET_SEARCHES, UPDATE_SEARCH } from "../../Search/Queries";
import SearchQuickView from "../../Search/SearchQuickView";
import { filterSearchTerms, handleDeleteSearch, updateLabel } from "../../Search/unifiedSearchHelpers";
import { severity } from '../../Snackbar/CustomizedSnackbar';
import { AuthStateContext, DispatchContext } from '../../store';
import theme from '../../styles/muiThemes/adminConsoleTheme';
import CreateImportQuickView from '../CreateImportQuickView';
import '../DarkTheme.css';
import { ImportDataGrid } from './ImportDataGrid';
import SearchImports from './SearchImports';

// Chris Pence - Import Tool

const AdminImports = (props) => {

	const { push: pushNav } = useNavigation()
	const navigate = useNavigate()
	const location = useLocation()
	const userAuthentication = useContext(AuthStateContext)

	const prevSearch = location.state

	/* =================================================================
	Snackbar
	================================================================= */
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	const closeSnackbar = () => {
		dispatch({ type: 'closeSnackBar' })
	}

	/* =================================================================
	SEARCH CONFIG
	================================================================= */

	const [updateSearch] = useMutation(UPDATE_SEARCH)

	const importFiltersMap = (filters) => filters.filter(e => e.field != "").map(e => {
		return {
			field: e.field,
			type: e.type,
			value: e.value?.toString()
		}
	})

	// State that comes from prevSearch
	const [value, setValue] = React.useState(0)
	const [order, setOrder] = React.useState(prevSearch?.order || "desc")
	const [orderBy, setOrderBy] = React.useState(prevSearch?.orderBy || 'id')
	const [imports, setImports] = React.useState(prevSearch?.imports ?? [])
	const [limit, setLimit] = React.useState(prevSearch?.limit ?? DEFAULT_SEARCH_LIMIT)
	const [activeStep, setActiveStep] = React.useState(prevSearch?.activeStep ?? 0)
	const [totalItems, setTotalItems] = React.useState(prevSearch?.totalItems ?? null)
	const [steps, setSteps] = React.useState(prevSearch?.steps ?? 1)
	const [selectedImport, setSelectedImport] = React.useState(null)
	const [advancedSearch, setAdvancedSearch] = React.useState(prevSearch?.advancedSearch ?? false)
	

	// Current search
	const [currentSearch, setCurrentSearch] = React.useState(prevSearch?.currentSearch ?? new SavedSearch({
		object_id: IMPORT_SEARCH_OBJECT,
		search_terms: [{
			field: 'id',
			type: 'eq',
			value: ''
		}],
		is_global: true,
		user_id: userAuthentication.user?.id
	}))

	// Normal State
	const [flaggedCount, setFlaggedCount] = React.useState(0)
	const [firstRender, setFirstRender] = React.useState(true)
	const [selection, setSelection] = React.useState(null)
	const [savedSearches, setSavedSearches] = React.useState([])
	const [searchLoading, setSearchLoading] = React.useState(null)
	const [createImport, setCreateImport] = React.useState(false)

	const setFilters = (filters) => {
		setCurrentSearch({
			...currentSearch,
			search_terms: filters
		})
	}

	// Set the filters to be shown in the simple search
	const currentFilters = [
		'query',
		'import_type',
		'import_status',
	]

	const advancedFilters = filterSearchTerms(currentSearch.search_terms)?.filter(term => {
		if (currentFilters.includes(term.field)) return false
		return true
	})

	React.useEffect(() => {

		if (currentSearch.search_terms.length == 0 && advancedSearch) {
			setCurrentSearch(new SavedSearch({
				object_id: IMPORT_SEARCH_OBJECT,
				search_terms: [initialFilterState()],
				is_global: true,
				user_id: userAuthentication.user?.id
			}))
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSearch.search_terms, userAuthentication.user?.id, advancedSearch])

	// Search on change of order
	React.useEffect(() => {
		setFirstRender(false)
		if (imports)
			if (imports.length !== 0 && imports[0].disabled !== true && !firstRender) {
				handleSubmit()
			}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order, orderBy, limit, activeStep])

	// Get saved searches
	const { loading, error } = useQuery(GET_SEARCHES, {
		skip: !userAuthentication.user?.id,
		variables: {
			userId: userAuthentication.user?.id,
			objectId: IMPORT_SEARCH_OBJECT,
			global: true
		},
		onCompleted: (data) => {
			if (data && data.getSearches && data.getSearches?.length !== 0) {
				setSavedSearches(data.getSearches
					.map(search => new SavedSearch(search)))
			} else {
				// console.log("No saved searches were found.")
			}
		}
	})

	if (error) {
		console.log(error)
		openSnackbar(severity.ERROR, "Error retrieving your saved searches.")
	}

	/**
	 * Save current page state for if one returns to it through
	 * back arrow.
	 *
	 * @returns current page state
	 */
	function updateHistory(index) {
		const currentPageState = {
			currentSearch,
			orderBy,
			order,
			imports: imports,
			limit,
			totalItems,
			steps,
			activeStep,
			advancedSearch,
			searchPage: location.pathname
		}
		const offset = index ? imports.findIndex(a => a.id == index) : imports.indexOf(selectedImport)
		const cursor = activeStep * limit + offset
		const variables = makeVariables(cursor, currentSearch, orderBy, order, currentSearch?.search_terms ?? [])
		variables.limit = 3
		currentPageState.variables = variables
		navigate(location.pathname, { state: currentPageState })
		return currentPageState
	}

	function saveAndGo(path, row) {
		// persist current page state in history
		const state = updateHistory(row)
		pushNav({
			url: location.pathname,
			state,
			query: DYNAMIC_IMPORT_NAV
		}, state.variables?.cursor)
		// navigate to new page (with this page's query and `variables`)
		navigate(path, state)
	}

	// Save search to state on selecting a User
	React.useEffect(() => {
		if (selectedImport) updateHistory()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedImport])


	const makeVariables = (cursor, currentSearch, orderBy, order, filters, limitOverride) => {
		return ({
			cursor,
			limit: limitOverride ?? limit,
			field: orderBy,
			direction: order.toUpperCase(),
			filters: filters ? importFiltersMap(filters) : importFiltersMap(currentSearch.search_terms),
			thumbnailResolution: "128x128"
		})
	}

	const handleSubmit = ({ cursor } = {}, filters = null, searchId) => {
		setSearchLoading(true)
		let submitCursor
		if (cursor == undefined) {
			submitCursor = activeStep * limit
		} else {
			submitCursor = cursor
		}
		openSnackbar(severity.INFO, "Loading search results...")

		const variables = makeVariables(
			submitCursor, currentSearch, orderBy, order, filters
		)
		setValue(1)

		// Set filters when handle submit is not coming from saved search list
		if (filters?.length && !searchId) setFilters(filters)

		props.client
			.query({ query: DYNAMIC_IMPORT_SEARCH, variables })
			.then((result) => {

				const { data } = result

				if (data.searchDynamicImport?.items) {
					setImports(data.searchDynamicImport.items)

					if (data.searchDynamicImport.items < 1) setSteps(1)
					else setSteps((Math.ceil(data.searchDynamicImport.totalItems / limit)))

					setTotalItems(data.searchDynamicImport.totalItems || 0)
					setSearchLoading(false)
					if (!data.searchDynamicImport.totalItems) {
						openSnackbar(severity.WARNING, "There were no results.")
					} else {
						closeSnackbar()
					}

				} else {
					console.error(data)
					setSearchLoading(false)
					openSnackbar(severity.ERROR, "There was an error searching imports.")
				}
			})
			.catch((error) => {
				console.error(error)
				setSearchLoading(false)
				openSnackbar(severity.ERROR, "Could not search imports.")
			})

	}

	const handleReset = (page) => {

		setCurrentSearch(new SavedSearch({
			object_id: IMPORT_SEARCH_OBJECT,
			search_terms: [{
				field: 'id',
				type: 'eq',
				value: ''
			}],
			is_global: true,
			user_id: userAuthentication.user?.id
		}))
		setImports([])
		setSteps(1)
		setCreateImport(false)
		setActiveStep(0)
		setTotalItems(null)
		setOrder("desc")
		setOrderBy('id')
		setSelectedImport(null)
		setSearchLoading(false)
		setValue(0)
	}

	const updateImportSavedSearchLabel = (search, label) =>
		updateLabel(
			search,
			label,
			updateSearch,
			savedSearches,
			setSavedSearches,
			openSnackbar
		)

	const handleDeleteImportSavedSearch = (search) =>
		handleDeleteSearch(
			search,
			updateSearch,
			savedSearches,
			setSavedSearches,
			openSnackbar
		)

	const { data: importFilterFields, error: importFilterError } = useQuery(GET_IMPORT_FILTER_FIELDS, {
		fetchPolicy: 'cache-first',
		onCompleted: ({ searchDynamicImportFilterFields }) => {
			const storedFields = location?.state?.fields
			if (storedFields) {
				setAdvancedSearch(true)
				const fieldFilters = Object.entries(storedFields)
					.map(([field, value], i) => {
						const filterField = searchDynamicImportFilterFields
							.find(f => f.name == field)
						let type = getOperatorValue(filterField?.type)
						return {
							field,
							value,
							type,
							id: new Date().getTime() + i
						}
					})
				setFilters(fieldFilters)
				handleSubmit({}, fieldFilters)
			}
		}
	})

	if (importFilterError) {
		openSnackbar(severity.ERROR, 'There was an error retrieving filterable fields for this search.')
		console.error(importFilterError)
	}

	/* =================================================================
	Render quick view
	================================================================= */

	const renderQuickView = () => {

		if (createImport) {
			return <CreateImportQuickView 
				setCreateImport={setCreateImport} 
				setImport={setSelectedImport}/>
		}

		else if (selectedImport) {
			return (
				<ImportQuickView
					import={selectedImport}
					importEndpoint={props.client}
					setImport={setSelectedImport}
					onClose={() => setSelectedImport(null)}
				></ImportQuickView>
			)
		}

		else {
			return (
				<SearchQuickView

					disableBulkActions
					disableFlagCount
					disableFavoriteSavedSearch

					savedSearches={savedSearches}
					setSavedSearches={setSavedSearches}
					setCurrentSearch={setCurrentSearch}
					setFilters={setFilters}
					handleSubmit={handleSubmit}
					currentSearch={currentSearch}
					savedSearchesLoading={loading}
					updateLabel={updateImportSavedSearchLabel}
					handleDeleteSearch={handleDeleteImportSavedSearch}
					setSelection={setSelection}
					selection={selection}
					totalItems={totalItems}
					flaggedCount={flaggedCount}
					setFlaggedCount={setFlaggedCount}
					makeVariables={makeVariables}
					orderBy={orderBy}
					order={order}
					searchQuery={DYNAMIC_IMPORT_SEARCH}
					entity="import"
					setValue={setValue}
					value={value}
					filterFields={importFilterFields?.searchDynamicImportFilterFields}
					requery={handleSubmit}
				/>
			)
		}
	}

	const variables = makeVariables(0, currentSearch, orderBy, order)


	/* =================================================================
	Authorization handling - Admin Auth Logic
	================================================================= */

	useEffect(() => {
		// redirect if the user is not logged in as admin. 
		const url = new URL("/api/admin-auth-status", window.location.origin)
		fetchWithAuth(url)
			.then(resp => resp.json())
			.then(data => {
				if (!data.isAdminAuthenticated) {
					navigate('/admin/login')
				}
			})

	}, [navigate])


	if (userAuthentication && userAuthentication.user) {
		if (userAuthentication.user.permissions.length === 0 || userAuthentication.user.permissions.find(e => e.permission_id == permissions.ADMIN_CONSOLE).permission_value_id !== "1") {
			openSnackbar(severity.ERROR, "Non-admin privileges.")
			navigate('/home')
		}

		return (
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<section className="main-page dark-theme">
						{/* Import tool quick view */}
						{renderQuickView()}

						<div style={{
							display: 'flex',
							flexDirection: 'column'
						}}>
							{/* Import Tool Search Card */}
							<Paper className="padding-margin-scrollbar search-card">
								{advancedSearch ?
									<GlobalSearchFilters
										adminConsole
										loading={searchLoading}
										currentSearch={currentSearch}
										setCurrentSearch={setCurrentSearch}
										onReset={handleReset}
										onSearch={handleSubmit}
										object_id={IMPORT_SEARCH_OBJECT}
										advancedSearch={advancedSearch}
										setAdvancedSearch={setAdvancedSearch}
										setCreateEntity={setCreateImport}
										filterFields={importFilterFields?.searchDynamicImportFilterFields}
										typeName="Import"
										onFindMode={() => openSnackbar(severity.WARNING, 'There is no Find Mode for imports.')}
										savedSearches={savedSearches}
										setSavedSearches={setSavedSearches}
										prevSearch={prevSearch?.currentSearch}
									/> :
									<SearchImports
										loading={searchLoading}
										currentFilters={currentSearch.search_terms}
										resetSearch={handleReset}
										currentSearch={currentSearch}
										setCurrentSearch={setCurrentSearch}
										handleSubmit={handleSubmit}
										setActiveStep={setActiveStep}
										setTotalItems={setTotalItems}
										advancedSearch={advancedSearch}
										setAdvancedSearch={setAdvancedSearch}
										advancedFilters={advancedFilters}
										selection={selection}
										setSelection={setSelection}
										onFindMode={() => openSnackbar(severity.WARNING, 'There is no Find Mode for imports.')}
										prevSearch={prevSearch?.currentSearch}
										savedSearches={savedSearches}
										setSavedSearches={setSavedSearches}
										setCreateEntity={setCreateImport}
									></SearchImports>}
							</Paper>


							{/* Import Tool datagrid */}
							<ImportDataGrid
								rows={imports}
								loading={searchLoading}
								saveAndGo={saveAndGo}
								setRows={setImports}

								userId={userAuthentication.user?.id}
								mainSearchPage={true}
								totalItems={totalItems}
								onSelect={setSelectedImport}
								selectedRow={selectedImport}
								limit={limit}
								setLimit={setLimit}
								activeStep={activeStep}
								setActiveStep={setActiveStep}

								sortModel={orderBy ? [{ field: orderBy, sort: order }] : []}
								onSortModelChange={newSort => {
									if (newSort.length) {
										setOrderBy(newSort[0].field)
										setOrder(newSort[0].sort)
									} else {
										setOrderBy('id')
										setOrder('desc')
									}
								}}
								variables={variables}
							/>

						</div>
					</section>

					
				</ThemeProvider>
			</StyledEngineProvider>
		)
	} return (
		<CircularLoading />
	)
}

export default withApollo(AdminImports)
