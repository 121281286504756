/* eslint-disable eqeqeq */
import { FormControl, MenuItem, Select } from '@mui/material'
import Dropdown from '../../input/Dropdown/Dropdown'
import Label from '../../input/Label'
import React, { useContext } from "react"
import TextBox from '../../input/Text/TextBoxThin'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import {SubmitButton, CancelButton} from '../../input/Buttons'
import { handleWebsitePaste, isValidWebsite } from '../../common/helpers'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

export default function EditWebsiteModal(props) {

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}

	const currentWebsiteType = props.websiteTypes?.find(e => 
		e.id == props?.websiteModal.website?.type_id
	)

	return (
		<TransitionsModal
			className="website-modal"
			open={props.websiteModal.open}
			close={() =>
				props.setWebsiteModal({ ...props.websiteModal, open: false })
			}
		>
			<form
				className={props.isPrivate ? "private-object modal-small-content" : "modal-small-content"}
				onSubmit={e => {
					e.preventDefault()
					e.stopPropagation()

					if (!isValidWebsite(props.websiteModal?.website?.website, currentWebsiteType) || props.websiteModal?.website?.website?.length === 0 || props.websiteModal?.website?.website === undefined) {
						openSnackbar(severity.WARNING, "Please correct the fields in red.")
					} else {
						let temp = props.contactInput.website
						
						if (Array.isArray(temp)) {
							temp[props.websiteModal.count - 1] =
								props.websiteModal.website
							props.setContactInput({
								...props.contactInput,
								website: temp
							})
							props.setWebsiteModal({
								...props.websiteModal,
								open: false
							})
						} else {
							props.setContactInput({
								...props.contactInput,
								website: props.websiteModal.website
							})
							props.setWebsiteModal({
								...props.websiteModal,
								open: false
							})
						}
					}
				}}
			>
				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Label
					</Label>
					<TextBox
						autoFocus
						placeholder="-"
						defaultValue={props.websiteModal?.website?.label || ''}
						onChange={e => {
							props.setWebsiteModal({
								...props.websiteModal,
								website: {
									...props.websiteModal.website,
									label: e.target.value
								}
							})
						}}
					/>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Type*
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}			
						
						input={<Dropdown />}
						className="medium-selection padded-select"
						value={props.websiteModal?.website?.type_id || ''}
						onChange={e => {
							props.setWebsiteModal({
								...props.websiteModal,
								website: {
									...props.websiteModal.website,
									type_id: e.target.value,
									type:
										props.websiteTypes[e.target.value - 1]
											.type
								}
							})
						}}
					>
						{props.websiteTypes && props.websiteTypes.map(x => (
							<MenuItem key={x.id} value={x.id}>
								{x.value.charAt(0).toUpperCase() + x.value.substring(1)}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink error={!isValidWebsite(props.websiteModal?.website?.website, currentWebsiteType) ||
						props.websiteModal?.website?.website === undefined
					}>
						{currentWebsiteType?.is_social ? 'Social' : 'Website'}*
					</Label>
					<TextBox
						error={!isValidWebsite(props.websiteModal?.website?.website, currentWebsiteType) ||
							props.websiteModal?.website?.website === undefined
						}
						defaultValue={props.websiteModal?.website?.website}
						multiline
						onPaste={e => handleWebsitePaste(e, currentWebsiteType, props.setWebsiteModal, props.websiteModal, openSnackbar)}
						onChange={e => {
							props.setWebsiteModal({
								...props.websiteModal,
								website: {
									...props.websiteModal.website,
									website: e.target.value
								}
							})
						}}
						inputProps={{ maxLength: 255 }}
					/>
				</FormControl>

				<CancelButton
					variant="contained"
					style={{ float: 'left', marginTop: '1em' }}
					onClick={e => {
						e.preventDefault()

						if (Array.isArray(props.contactInput.website)) {
							props.setWebsiteModal({
								...props.websiteModal,
								open: false
							})
						} else {
							props.setWebsiteModal({
								...props.websiteModal,
								website: props.contactInput.website,
								open: false
							})
						}
					}}
				>
				Cancel
				</CancelButton>

				<SubmitButton
					style={{ float: 'right', marginTop: '1em' }}
					type="submit"
					variant="contained"
				>
					Submit
				</SubmitButton>

			</form>
		</TransitionsModal>
	)
}
