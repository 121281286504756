/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useCallback } from "react"
import { DispatchContext } from '../../store'
import { Paper, FormControl, MenuItem, Menu } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from "@mui/material/TextField"
import { withApollo } from '@apollo/client/react/hoc'
import { useMutation } from '@apollo/client'
import { UPDATE_CONTACT } from "../Queries.js"
import Chip from '@mui/material/Chip'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { SaveCardButton, CancelButton } from '../../input/Buttons'
import { Skeleton } from '@mui/material'
import { LookupContext } from './../../store'
import CircularProgress from '@mui/material/CircularProgress'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { autoCompleteStyles, chipStyles } from '../../styles/makesStyles'
import { getNonNullArray } from "../../common/helpers"
import { SIMPLE_SEARCH_ARTISTS } from "../../Art/Queries"
import LimitedAutocomplete from "../../common/LimitedAutocomplete"
import isEqual from 'lodash/isEqual'
import { useLocation, useNavigate } from "react-router-dom"
import useNavigation from "../../navigation/useNavigation"
import { FindModeInput } from "../../navigation/Tabs/TabbedPage"

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false
}

function Interests (props) {

	const { push: pushNav } = useNavigation()

	const navigate = useNavigate()
	const location = useLocation()
	const [state, setState] = React.useState(initialState)
	// Style state
	const editableClasses = autoCompleteStyles()
	const classes = autoCompleteStyles()

	const lookup = useContext(LookupContext)
	const categories = lookup.data?.getArtCategories
	const artistGenre = lookup.data?.getArtistGenres


	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const artist_interests = props.contact?.artist_interests
	

	// Autocomplete State
	const [open, setOpen] = React.useState(false)
	const [loadArtist, setLoadArtist] = React.useState(false)
	const [options, setOptions] = React.useState([])
	const [selection, setSelection] = React.useState([])
	const artistLoading = open && !!loadArtist

	const [categorySelection, setCategorySelection] = React.useState([])
	const [genreSelection, setGenreSelection] = React.useState([])


	const [contactInput, setContactInput] = React.useState({
		id: props?.contact?.id,
		artist_interests: artist_interests,
		art_category_interests: props.contact?.art_category_interests,
		genre_interests: props.contact?.genre_interests,
	})

	React.useEffect(() => {
		let active = true

		if (!artistLoading) {
			return undefined
		}

		props.client
			.query({
				query: SIMPLE_SEARCH_ARTISTS,
				variables: { query: loadArtist },
			})
			.then((result) => {
				if (active) {
					setLoadArtist(false)
					setOptions(result.data.simpleSearchArtists)
				}
			})


		return () => {
			active = false
		}
	}, [artistLoading, loadArtist, props.client])

	const [updateContact, { loading }] = useMutation(UPDATE_CONTACT, {
		onError: (error) => {

			// On failure, reset contactInput state, don't touch contact state and show error
			resetContactInput(props.contact)
			openSnackbar(severity.ERROR, "Could not update interests card.")

			handleClose()
		},

		onCompleted: (response) => {

			if (response.updateContact.success === true) {

				// Success
				openSnackbar(severity[response.updateContact.severity], response.updateContact.message)

				// On success change contact state
				props.setContact({
					...props.contact,
					artist_interests: response.updateContact.contact.artist_interests,
					art_category_interests: response.updateContact.contact.art_category_interests,
					genre_interests: response.updateContact.contact.genre_interests
				})

			} else {

				// On failure, reset contactInput state, don't touch contact state and show error
				resetContactInput(props.contact)
				openSnackbar(severity.ERROR, response.updateContact.message)

			}

		}
	})


	const resetContactInput = useCallback(() => {

		setContactInput({
			id: props?.contact?.id,
			artist_interests: getNonNullArray(props.contact?.artist_interests),
			art_category_interests: props.contact?.art_category_interests,
			genre_interests: props.contact?.genre_interests
		})
	}, [props])

	useEffect(()=> {
		resetContactInput()
	}, [props.contact, resetContactInput])


	// Set initial selection and options to current entity values
	useEffect(() => {
		setSelection(contactInput.artist_interests || [])
		setOptions(contactInput.artist_interests || [])
	}, [contactInput])

	useEffect(() => {
		setCategorySelection(contactInput.art_category_interests || [])
		setGenreSelection(contactInput.genre_interests || [])
	}, [contactInput])

	const handleClick = event => {
		if (state.mouseX || state.editable || props.findMode || !props.contact?.id || props.disableEdits) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const handleClose = (option) => {
		if (option === 'edit') {
			setState(Object.assign({}, initialState, {editable: true}))

		} else {
			setState(initialState)
		}
	}

	const interestLabel = {
		fontSize: "12px"
	}

	return (
		<Paper className="qv-margin" id="contact-interests"
			style={{marginTop: "0px"}}
			onContextMenu={handleClick}
			data-testid="card-interests"
		>
			<h1 className="card-title">
				<span>Interests</span>
				{state.editable && (<>
					<div className="spacer"></div>
					<CancelButton variant="contained" size="small"
						onClick={() => {
							resetContactInput()
							handleClose()
						}}>Cancel</CancelButton>
					<SaveCardButton variant="contained" 
						color="secondary"
						size="small"
						disable={loading}
						onClick={() => {
							setContactInput({
								id: contactInput.id,
								artist_interests: selection,
								art_category_interests: categorySelection,
								genre_interests: genreSelection
							})

							const updateData = {
								id: contactInput.id,
								artists_ids: selection.map(artist => artist.id),
								art_category_ids:categorySelection.map(art_category => art_category.id),
								genre_ids: genreSelection.map(genre => genre.id)
							}

							updateContact({ variables: { ContactInput: updateData } })
							handleClose()
						}}
					>
							Save
					</SaveCardButton>
				</>)}
			</h1>
			<div>
				<FormControl fullWidth={ true } style={{paddingBottom: "0.5em"}} className="autocomplete-multiple">
					<div style={interestLabel}>
						Artists
					</div>
					{props.loading ?
						<Skeleton variant="rectangular" width={120} height={25} animation={props.loading ? "wave" : false} style={{
							paddingLeft: '0.5em',
							borderRadius: '10em',
							marginTop: '0.5em'
						}}/>
						:
						<>{(props.findMode ? <FindModeInput field="interest_artists" /> :
							(state.editable ?
								<LimitedAutocomplete
									query={loadArtist}
									setQuery={setLoadArtist}
									multiple
									size="small"
									forcePopupIcon
									filterSelectedOptions
									popupIcon={<ExpandMoreRoundedIcon />}
									classes={editableClasses}
									open={open && !isEqual(options, contactInput.artist_interests)}
									onOpen={() => {
										setOpen(true)
									}}
									onClose={() => {
										setOpen(false)
										setOptions(contactInput.artist_interests || [])
									}}
									getOptionLabel={option => {
										if (option?.first_name && option?.last_name) {
											return option?.first_name+" "+option?.last_name
										}
									}}
									options={options}
									loading={artistLoading}
									value={selection}
									isOptionEqualToValue={(option, value) =>
										option.id == value?.id
									}
									onChange={(event, value) => {
										setSelection(value)
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											id="owner-autocomplete"
											variant="outlined"
											fullWidth
											autoFocus
											style={{ paddingTop: '0.5' }}
											classes={{ notchedOutline: null }}
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{artistLoading ? (
															<CircularProgress
																color="inherit"
																size={20}
															/>
														) : null}
														{
															params.InputProps
																.endAdornment
														}
													</React.Fragment>
												),
											}}
										/>
									)}
								/>
								:
								<div style={{paddingTop: "0.5em"}}>
									{
										(contactInput.artist_interests && contactInput.artist_interests[0]) ? contactInput.artist_interests?.map((interest, i) => {
											const currentValue = [interest?.first_name, interest?.last_name].filter(e => e).join(" ")

											return (<Chip
												style={chipStyles}
												size="small"
												key={currentValue}
												label={currentValue}
												onClick = {(e)=>{

													if (props.disableNav) return

													if (e.metaKey) {
														window.open(`/artists/${interest.id}/art`, '_blank')
														return
													}

													const ids = contactInput.artist_interests.map(i => i.id)
													const state = { variables: { cursor: i } }
													pushNav({
														url: location.pathname,
														state,
														query: ids,
													}, i)
													navigate(`/artists/${interest.id}/art`, { state })
												}}
											/> )
										})
									 : <span style={{fontSize: "0.8em"}}>-</span>
									}
								</div>
							))}
						</>
					}
				</FormControl>

				<FormControl fullWidth={true} style={{ paddingBottom: '0.5em' }}>
					<div style={interestLabel}>Categories</div>
					{props.loading ? (
						<Skeleton
							variant='rectangular'
							width={120}
							height={25}
							animation={props.loading ? 'wave' : false}
							style={{
								paddingLeft: '0.5em',
								borderRadius: '10em',
								marginTop: '0.5em',
							}}
						/>
					) : (
						<>
							{props.findMode ? (
								<FindModeInput field='interest_categories' />
							) : state.editable ? (
								<Autocomplete
									multiple
									isOptionEqualToValue={(a, b) => a?.id === b?.id}
									options={categories || null}
									value={categorySelection}
									getOptionLabel={(option) => option?.value || 'Loading...'}
									filterSelectedOptions
									popupIcon={<ExpandMoreRoundedIcon />}
									classes={classes}
									size='small'
									renderInput={(params) => <TextField {...params} variant='outlined' fullWidth />}
									onChange={(event, value) => {
										setCategorySelection(value)
									}}
								/>
							) : (
								<div style={{ paddingTop: '0.5em' }}>
									{contactInput.art_category_interests && contactInput.art_category_interests[0] ? (
										contactInput.art_category_interests?.map((interest, i) => {
											const currentValue = interest?.value
											return (
												<Chip
													key={currentValue}
													style={chipStyles}
													size='small'
													label={currentValue}
													onClick={(e) => {
														if (props.disableNav) return

														if (e.metaKey) {
															window.open('/art/', '_blank')
															return
														}

														const fields = { art_categories: currentValue }
														navigate('/art/', { state: { fields }})
													}}
												/>
											)
										})
									) : (
										<span style={{ fontSize: '0.8em' }}>-</span>
									)}
								</div>
							)}
						</>
					)}
				</FormControl>

				<FormControl fullWidth={ true } style={{paddingBottom: "0.5em"}}>
					<div style={interestLabel}>
                        Genres
					</div>
					{props.loading ?
						<Skeleton variant="rectangular" width={120} height={25} animation={props.loading ? "wave" : false} style={{
							paddingLeft: '0.5em',
							borderRadius: '10em',
							marginTop: '0.5em'
						}}/>
						:
						<>
							{(props.findMode ? <FindModeInput field="genre_interests" /> :
								(state.editable ?
									<Autocomplete
										multiple
										isOptionEqualToValue={(a,b) => a?.id === b?.id}
										options={artistGenre || null}
										value={genreSelection}
										getOptionLabel={option => option?.value || "Loading..."}
										filterSelectedOptions
										popupIcon={<ExpandMoreRoundedIcon />}
										classes={classes}
										size="small"
										renderInput={params => (
											<TextField
												{...params}
												variant="outlined"
												fullWidth
											/>
										)}
										onChange={(event, value) => {
											setGenreSelection(value)
										}}
									/>
									:
									<div style={{paddingTop: "0.5em"}}>
										{(contactInput.genre_interests && contactInput.genre_interests[0]) ? contactInput.genre_interests?.map((interest, i) => {

											const currentValue = interest?.value
											return (
												<Chip 
													key={currentValue} 
													style={chipStyles} 
													size="small"
													label={currentValue} 
													onClick = {(e)=>{

														if (props.disableNav) return

														if (e.metaKey) {
															window.open('/art/', '_blank')
															return
														}
														
														const fields = { artist_genre: currentValue }
														navigate('/art/', { state: { fields }})
													}}
												/>
											)
										}) : <span style={{fontSize: "0.8em"}}>-</span>
										}
									</div>
								))}
						</>
					}
				</FormControl>
			</div>
			<Menu
				keepMounted
				open={state.mouseY !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					state.mouseY !== null && state.mouseX !== null
						? { top: state.mouseY, left: state.mouseX }
						: undefined
				}
			>
				<MenuItem onClick={()=>{
					handleClose('edit')
				}}>Edit</MenuItem>
			</Menu>
		</Paper>
	)
}

export default withApollo(Interests)
