import React, {useState, useEffect, useContext} from 'react'

import { ADMIN_GET_USER, GET_USERS } from "./Queries"
import { withApollo } from '@apollo/client/react/hoc'
import { CancelButton, SubmitButton } from '../input/Buttons'

import {
	FormControl, CircularProgress
} from '@mui/material'

import {
	autoCompleteStyles,
} from '../styles/makesStyles'

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import Label from '../input/Label'
import LimitedAutocomplete from '../common/LimitedAutocomplete'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { DispatchContext } from '../store'
import TextBoxThinForAutocomplete from '../input/Text/TextBoxThinForAutocomplete'
import { getGalleryContactLabel } from '../common/helpers'


function PermissionCopy(props){
	
	const [loadUser, setLoadUser] = useState(false)
	const [userOptions, setUserOptions] = useState([]) 
	const [userOpen, setUserOpen] = useState(false)

	const [userInput, setUserInput] = useState({
		user: null
	})

	const userLoading  = userOpen && !! loadUser

	useEffect( ()=>{
		let active = true

		if(!userLoading){
			return undefined
		}

		props.client
			.query({
				query: GET_USERS,
				variables: { 
					query: loadUser, 
					includeDeleted: true
				}
			})
			.then( (result) => {
				if(active){
					setLoadUser(false)
					setUserOptions(result.data.getUsers.items)
				}
			})

		return () => {
			active=false
		}
	}, [loadUser, userLoading, props.client] )

	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({type: 'openSnackBar', payload: {severity, text}})
	}

	const submit = async () => {
		props.client.query( {
			query: ADMIN_GET_USER,
			variables: {
				id: userInput?.user?.id
			}
		} ).then( result => {
			const permissions = result.data.adminGetUser.permissions.reduce( (acc, perm) => {
				return ({...acc, [perm.permission_id]: perm.permission_value_id})
			}, {} )

			props.close(permissions)
		} ).catch( e => {
			console.log(e)
			props.close()
		}  )
	}

	return (
		<div className="dark-theme">
			<h1 className="card-title"> Copy User Permissions </h1>
			<div style={{display: "flex"}}>
				<FormControl style={{flexGrow:2}}>
					<LimitedAutocomplete
						query={loadUser}
						setQuery={setLoadUser}
						minimum={2}
						autoHighlight
						options={userOptions}
						loading={userLoading}
						value = {userInput.user}
						isOptionEqualToValue={(option, value) => {return option === value}}
						open={userOpen && !!userOptions?.length}
						onOpen={() => setUserOpen(true)}
						onClose={() => setUserOpen(false)}
						classes={autoCompleteStyles()}
						forcePopupIcon
						filterSelectedOptions
						popupIcon={<ExpandMoreRoundedIcon />}
						getOptionLabel={option => getGalleryContactLabel(option) }
						onChange={(event, value) => {
							setUserInput({
								user: value
							})
						}}
						renderInput={(params)=>(
							<FormControl style={{display:"flex" }}>
								<Label
									shrink
									htmlFor="user-autocomplete"
									style={{display: "block"}}
								>
									Source Profile
								</Label>
								<TextBoxThinForAutocomplete
									{...params}
									id="user-autocomplete"
									variant="outlined"
									fullWidth
									style={{ paddingTop: '1.8em' }}
									InputLabelProps={{
										shrink:true
									}}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{userLoading ? (
													<CircularProgress
														color="inherit"
														size={20}
													/>
												) : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										)
									}}
									data-testid="permissioncopy-user"
								>	</TextBoxThinForAutocomplete>
							</FormControl>
						)}
					>

					</LimitedAutocomplete>
				</FormControl>
			</div>

			<SubmitButton 
				type="submit"
				variant="contained"
				style={{
					float: 'right',
					marginTop: '1em'
				}}
				onClick={ (e) => {
					e.preventDefault()
					if(userInput.user){
						submit()
					} else{
						openSnackbar(severity.ERROR, "Invalid user")
					}
				} }
			>
					Submit
			</SubmitButton>

			<CancelButton 
				variant="contained"
				style={{
					float: 'right',
					marginTop: '1em',
					marginRight: '1em'
				}}
				onClick={ e => {
					e.preventDefault()
					props.close()
				}
				}
			>
				Cancel
			</CancelButton>
		</div>
	)
}

export default withApollo(PermissionCopy)
