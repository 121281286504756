/* eslint-disable eqeqeq */
import { AuthStateContext, DispatchContext, LookupContext } from '../store'
import makeStyles from '@mui/styles/makeStyles'
import { FormControl, Select, MenuItem, FormControlLabel, IconButton, TextField } from '@mui/material'
import { SaveButton, SearchButton, ResetButton, ChooseImageButton, AdvancedToggleButton } from '../input/Buttons'
import Dropdown from '../input/Dropdown/Dropdown'
import Label from '../input/Label'
import React, { useCallback, useContext, useEffect } from "react"
import TextBox from '../input/Text/TextBox'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { withApollo } from '@apollo/client/react/hoc'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import { Autocomplete } from '@mui/material'
import { autoCompleteStyles } from '../styles/makesStyles'
import { GET_USERS } from '../User/Queries'
import SearchInfo from "../Search/SearchInfo"
import { ControlPoint } from '@mui/icons-material'
import AdvancedFilterCount from '../Search/AdvancedFilterCount'
import { FindModeButton } from '../Search/GlobalSearchFilters'
import { ARTIST_SEARCH_OBJECT } from '../constants/values'
import ConfirmationInput from '../navigation/ConfirmationDialog/ConfirmationInput'
import { getGalleryContactLabel, randomHSL } from '../common/helpers'
import { SavedSearch } from '../common/SavedSearch'
import { handleSearchChange, saveMap, saveSearch, searchMap } from '../Search/unifiedSearchHelpers'
import { useMutation } from '@apollo/client'
import { CREATE_SEARCH } from '../Search/Queries'
import { severity } from '../Snackbar/CustomizedSnackbar'

const useStyles = makeStyles(() => ({
	searchSelection: {
		width: "100%",
		paddingRight: "1em",
		"&:last-child": {
			paddingRight: "0em",
		}
	},
	searchBar: {
		paddingBottom: "1em"
	},
	searchDatePadding: {
		marginTop: "0.5em",
		paddingRight: "1em",
	},
	searchDate: {
		marginTop: "0.5em",
	}
}))

const SearchArtists =  (props) => {

	const [firstLoad, setFirstLoad] = React.useState(true)
	React.useEffect(() => setFirstLoad(false), [])

	const classes = useStyles()
	const autoCompleteStyle = autoCompleteStyles()

	const typeStyle = {
		fontWeight: 500,
	}
	
	const lookup = React.useContext(LookupContext)
	const artistGenre = lookup.data?.getArtistGenres

	const [options, setOptions] = React.useState([])
	
	const userAuthentication = useContext(AuthStateContext)
	const [currentSearch, setCurrentSearch] = React.useState(props.prevSearch ?? new SavedSearch({
		object_id: ARTIST_SEARCH_OBJECT,
		search_terms: [],
		is_global: true,
		user_id: userAuthentication.user?.id
	}))
	const [savedSearchDialog, setSavedSearchDialog] = React.useState({
		open: false,
		search: null
	})

	// Mutations
	const [createSearch, {loading}] = useMutation(CREATE_SEARCH)

	useEffect(() => {
		if (props.currentSearch) setCurrentSearch(props.currentSearch)
	}, [props.currentSearch])

	const getSearchValue = useCallback((fieldName) =>
		currentSearch.search_terms
			?.find(el => el.field == fieldName)?.value,
	[currentSearch.search_terms])

	const handleArtistSavedSearchChange = (event, type = 'eq') => {

		const search_terms = handleSearchChange(
			event,
			currentSearch,
			setCurrentSearch,
			type,
			true
		)

		if (props.currentSearch.id) { 
			props.setCurrentSearch({ 
				...currentSearch,
				search_terms,
				id: null 
			})
		}
	}

	const dispatch = useContext(DispatchContext)
	const openQuickView = useCallback((severity, text) => {
		dispatch({ type: 'openQuickView' })
	}, [dispatch])

	// Snackbar
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])
	
	React.useEffect(() => {
		let active = true

		if (active && !options.length) {
			props.client
				.query({
					query: GET_USERS,
					variables: {
						includeDeleted: true
					}
				})
				.then(result => {
					if (active)
						setOptions(result.data.getUsers.items)
				})
		}

		return () => {
			active = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// This populate the gallery contacts autocomplete from saved searches 
	React.useEffect(() => {

		const names = getSearchValue('gallery_contacts')?.split(' AND ')

		if (options.length && getSearchValue('gallery_contacts')) {
			props.setSelection(options.filter(user => 
				 names.includes([user.first_name, user.last_name].join(" "))
			))
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getSearchValue('gallery_contacts')])

	return <>
		<h1 className="card-title">
            
            Search Artists
        
			<ChooseImageButton
				data-testid="new-artist-button"
				variant="contained"
				size="small"
				style={{backgroundColor: !props.loading ? '#4465D1' : null}}
				onClick={() => {
					openQuickView()
					props.setCreateArtist(true)
				}}
				endIcon={<ControlPoint />}
			>
                Create
			</ChooseImageButton>

			<AdvancedFilterCount
				advancedFilters={props.advancedFilters}
				setAdvancedSearch={props.setAdvancedSearch}
			/>
        
			<SearchInfo 
				advancedFilters={!!props.advancedFilters?.length}
				fields={["Name (First, Last, or Full)", "About Description", "ID"]} style={{marginRight: 0}}
				recordName="artist"
			/> 
		</h1>

		<form onSubmit={(e) => {
			e.preventDefault() 
			props.setActiveStep(0)
			props.handleSubmit(
				{ cursor: 0 }, 
				currentSearch.search_terms, 
				currentSearch.id
			)
		}}>
			<div className="row">
				<FormControl className={classes.searchSelection} style={{ flexGrow: '1'}}>
					<Label id="genre-label" style={typeStyle}
						disableAnimation shrink>
                        Genre
					</Label>
					<Select
						data-testid="search-genre"
						IconComponent={ExpandMoreRoundedIcon}
						className="padded-select"
						name="artist_genre"
						
						labelId="genre-id-label"
						input={<Dropdown />}
						value={getSearchValue('artist_genre') || ''}
						onChange={(event) => handleArtistSavedSearchChange(event)}
					>
						<MenuItem value="" data-testid="search-genre-item">All</MenuItem>
						{artistGenre && artistGenre.map(genre => (
							<MenuItem key={genre.id} value={genre.value} data-testid="search-genre-item">{genre.value}</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl className={classes.searchSelection} style={{ flexGrow: '1'}}>
					<Label id="nationality-label"
						disableAnimation shrink>
                        Nationality
					</Label>
					<TextBox
						name="nationality"
						data-testid="search-nationality"
						labelid="nationality-label"
						value={getSearchValue('nationality') || ''}
						onChange={(event) => handleArtistSavedSearchChange(event)}
					>
					</TextBox>
				</FormControl>

				<FormControl className={classes.selection} style={{width: "100%"}}>
					<Label
						id="user-label"
						style={typeStyle}
						disableAnimation
						shrink
					>
                        Gallery Contact
					</Label>

					<Autocomplete
						multiple
						classes={autoCompleteStyle}
						style={{marginTop: "1.5em"}}
						isOptionEqualToValue={(option, value) => {
							return option.id == value.id
						}}
						forcePopupIcon
						filterSelectedOptions
						popupIcon={<ExpandMoreRoundedIcon />}
						size="small"
						value={props.selection ?? []}
						getOptionLabel={(option) =>getGalleryContactLabel(option)}
						options={options || []}
						onChange={(event, value) => {
							props.setSelection(value)
							const names = value.map(x=> [x.first_name, x.last_name].join(' ')).join(" AND ")
							handleArtistSavedSearchChange({
								target: {
									name: "gallery_contacts",
									value: names
								}
							})
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								fullWidth
								InputProps={{
									...params.InputProps,
									style: {'padding': '9px'},
								}}
							/>
						)}
						data-testid="search-gallery_contact"
					/>
				</FormControl>
			</div>

			<div className="row">
				<div className="flex-row search-booleans" style={{flexGrow: '0'}}>
					<FormControlLabel
						value="top"
						control={
							<>
								{getSearchValue('isFavorite') == null ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleArtistSavedSearchChange({
												target: {
													name: "isFavorite",
													value: 'false'
												}
											})
										}}
										title="All"
										size="large">
										<StarBorderIcon style={{ color: 'grey' }} />
									</IconButton>
									: null}

								{getSearchValue('isFavorite') == 'false' ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleArtistSavedSearchChange({
												target: {
													name: "isFavorite",
													value: true
												}
											})
										}}
										title="Non-Favorite"
										size="large">
										<StarBorderIcon style={{color: 'hsl(50, 93%, 59%)'}} />
									</IconButton>
									: null}

								{getSearchValue('isFavorite') == 'true' ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleArtistSavedSearchChange({
												target: {
													name: "isFavorite",
													value: null
												}
											})
										}}
										title="Favorite"
										size="large">
										<StarIcon style={{color: 'hsl(50, 93%, 59%)'}} />
									</IconButton>
									: null}
							</>
						}
						label="Favorite"
						labelPlacement="top"
					/>
				</div>
				<FormControl className={classes.searchBar} style={{ flexGrow: '2' }}>
					<Label style={typeStyle} disableAnimation shrink>
                        Search
					</Label>
					<TextBox 
						name="query" 
						data-testid="searchbar"
						inputRef={input => input && firstLoad && input.focus()}
						value={getSearchValue('query') || ''}
						onChange={(event) => handleArtistSavedSearchChange(event)} 
					/>
				</FormControl>
			</div>

			<div className="row">
				<div>
					<SaveButton
						data-testid="save-button"
						variant="contained"
						size="small"
						onClick={() => {
							saveSearch(
								currentSearch,
								openSnackbar,
								severity,
								setSavedSearchDialog
							)
						}}					
					>
                        Save Search
					</SaveButton>
				</div>
				<FindModeButton onFindMode={props.onFindMode} />
				<AdvancedToggleButton
					data-testid="advanced-toggle-button"
					size="small"
					onClick={() => {
						props.setCurrentSearch(currentSearch)
						props.setAdvancedSearch(true)
					}}
					style={{ marginRight: '1em' }}
				>
                    Advanced
				</AdvancedToggleButton>
				<ResetButton
					data-testid="reset-button"
					variant="contained"
					size="small"
					onClick={() => {
						props.setTotalItems(null)
						props.setSelection([])
						props.resetSearch('simple')
						setCurrentSearch(new SavedSearch({
							object_id: ARTIST_SEARCH_OBJECT,
							search_terms: [],
							is_global: true,
							user_id: userAuthentication.user?.id
						}))
					}}
					style={{ marginRight: '1em' }}
				>
                    Reset
				</ResetButton>
				<SearchButton
					data-testid="search-button"
					variant="contained"
					size="small"
					type="submit"
					style={{backgroundColor: !props.loading ? '#4465D1' : null}}
					disabled={props.loading}
				>
                    Search
				</SearchButton>
			</div>
		</form>

		<ConfirmationInput
			open={savedSearchDialog.open}
			handleClose={() => setSavedSearchDialog({
				open: false,
				savedSearch: null
			})}
			title={'Save search?'}
			acceptText={'Save'}
			buttonColor="#33BF5C"
			inputLabel="Search Label"
			placeholder={'Saved Search ' + (props.savedSearches?.length + 1)}
			onYes={(text) => {
				if (!loading) {
					createSearch({
						variables: {
							CreateSearchInput: {
								label: text ? text : 'Saved Search ' + (props.savedSearches?.length + 1),
								user_id: userAuthentication.user.id,
								object_id: ARTIST_SEARCH_OBJECT,
								color: randomHSL(),
								search_terms: searchMap(currentSearch.search_terms),
								result_count: null,
								is_global: true,
							},
						},
					}).then((response) => {

						setCurrentSearch({
							...response.data.createSavedSearch.search,
							search_terms: saveMap(response.data.createSavedSearch.search.search_terms)
						})

						props.setSavedSearches(props.savedSearches.concat(
							new SavedSearch(response.data.createSavedSearch.search))
						)
					})
				}
			}}
		/>
	</>
}

export default withApollo(SearchArtists)
