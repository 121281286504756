import React, { useCallback, useContext, useEffect, useState } from 'react'
import { gql } from '@apollo/client'
import { useLazyQuery } from '@apollo/client'
import { stripMeta } from '../../../common/helpers'
import FileGroups from '../../../Files/FileGroups'
import { DispatchContext } from '../../../store'
import { severity } from '../../../Snackbar/CustomizedSnackbar'
import { UPDATE_LISTING } from '../../Queries'
import { useMutation } from '@apollo/client'
import { fileData } from '../../../common/fragments'
import { useParams } from 'react-router-dom'

export const fileQuery = gql`
	query GetListing(
		$id: ID!
		$thumbnailResolution: String = "128x128"
	) {
		getListing(id: $id) {
			id
			files {
				...fileData
			}
		}
	}
	${fileData}
`

export default function FilesPage(props) {

	const [files, setFiles] = useState([])

	const params = useParams()


	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	let listing = props.state?.getListing || {}
	let setListing = (listing) => props.setState({ getListing: listing })

	const [refetch, { loading, error }] = useLazyQuery(fileQuery, {
		onCompleted: (data) => {
			setFiles(data?.getListing?.files || [])
		},
		onError: error => {
			console.log(error)
			openSnackbar(severity.ERROR, "Error retrieving files.")
		}
	})


	const [
		updateListing, { error: updateError }
	] = useMutation(UPDATE_LISTING, {
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, "Could not update.")
		},
		onCompleted: (response) => {
			if (response.updateListing.success === true) {
				refetch({ variables: { id: props.id } })
				openSnackbar(severity.SUCCESS, response.updateListing.message)
				setListing(response.updateListing.listing)
			} else {
				// On failure, reset listingInput state, don't touch listing
				// state and show error
				openSnackbar(severity.ERROR, response.updateListing.message)
			}
		}
	})

	// update function
	const update = (fields) => {
		let variables = stripMeta(fields)
		variables.id = listing.id

		updateListing({
			variables: {
				UpdateListingInput: variables
			}
		})
	}

	useEffect(() => {
		setFiles([])
		if (params.id && !props.findMode)
			refetch({ variables: { id: params.id } })
	}, [params.id, refetch, props.tabbedPageFileRefetch, props.findMode])

	return (!props.findMode && props.id > 0) && (
		<FileGroups
			objectId={listing?.id}
			loading={props.loading || loading}
			error={props.error || error || updateError}
			files={files}
			setFiles={setFiles}
			onSave={update}
			fileObjectId={props.fileObjectId}
			options={props.options}

			tabbedPageFileRefetch={props.tabbedPageFileRefetch}
			setTabbedPageFileRefetch={props.setTabbedPageFileRefetch}
		/>
	)
}
