import makeStyles from '@mui/styles/makeStyles'

/** styles for these components */
export const useStyles = makeStyles(theme => ({
	paperTab: {
		borderRadius: "0",
		position: "relative",
	},
	tab: {
		minWidth: "4em",
		[theme.breakpoints.up('sm')]: {
			minWidth: "5em",
		},
		[theme.breakpoints.up('md')]: {
			minWidth: "6em",
		},
		[theme.breakpoints.up('lg')]: {
			minWidth: "7em",
		},
	},
	button: {
		transition: "all 300ms",
		transformOrigin: "50% 50%",
		"&:hover": {
			backgroundColor: "transparent",
			transform: "scale(1.1)",
		},
		"&:active": {
			transform: "scale(1)",
		},
		fontWeight: 500
	},
	img: {
		paddingTop: "0.05em",
	},
}))
