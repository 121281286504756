/* eslint-disable eqeqeq */
import { withApollo } from '@apollo/client/react/hoc'
import { format } from 'date-fns'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import CollapsedContent from './CollapsedContent'
import { AuthStateContext, DispatchContext } from '../store'
import { UPSERT_RELEASE_NOTE, GET_RELEASE_NOTES } from './Queries'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { Paper } from '@mui/material'
import ReactQuill from 'react-quill'
import { SubmitButton, CancelButton, ChooseImageButton, ResetButton, NewButton } from '../input/Buttons'
import { useMutation } from '@apollo/client'

const pageSize = 10

function ReleaseNotes(props) {
	const userAuthentication = useContext(AuthStateContext)

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	},[dispatch])

	const [notes, setNotes] = useState([])
	const [editNote, setEditNote] = useState(false)
	const [quillText, setQuillText] = React.useState(null)

	useEffect(() => {
		if (quillText && quillText.editor) {
			const editor = quillText.getEditor()
			editor.focus()
			editor.setSelection(editor.getLength(), 0)
		}
	}, [quillText])	


	const [upsertReleaseNote, { loading }] = useMutation(UPSERT_RELEASE_NOTE)

	useEffect(() => {
		let active = true

		props.client
			.query({
				query: GET_RELEASE_NOTES,
				variables: {
					cursor: 0,
					limit: pageSize,
				},
			})
			.then(({ data }) => {
				if (active) {
					const releaseNotes = data.getReleaseNotes

					setNotes(releaseNotes?.items)
				}
			})
			.catch((error) => {
				console.error(error)
				openSnackbar(error.severity || severity.ERROR, error.message || 'There was an error retrieving the release notes.')
			})

		return () => (active = false)
	}, [openSnackbar, props.client, props.id, userAuthentication.last_release_note_date_seen])

	const handleSubmit = (input) => {
		const { __typename, is_new, modified_at, created_at, ...cleanInput } = input
		const { __typename1, is_new1, modified_at1, created_at1, ...cleanNote } = editNote
		upsertReleaseNote({
			variables: { UpsertReleaseNote: cleanInput || { content: cleanNote } },
		})
			.then((res) => {
				if (res.data.upsertReleaseNote.success) {
					openSnackbar(severity.SUCCESS, 'Release Note upserted successfully.')
					setEditNote(false)
				} else {
					const errMsg = res.data?.upsertReleaseNote?.message
					let message = 'Could not upsert Release Note.'
					if (errMsg) message += ` ${errMsg}`
					openSnackbar(severity.ERROR, message)
				}
			})
			.catch((error) => {
				console.log(error)
				openSnackbar(error.severity || severity.ERROR, error.message || 'There was an error upserting the Release Note.')
			})
	}

	return (
		<div className="manual-page" style={{ margin: '1em' }}>
			<h2>{userAuthentication?.user?.id == 1 ? <ChooseImageButton onClick={() => setEditNote('')}>Add Note</ChooseImageButton> : null}</h2>

			{editNote !== false ? (
				<Paper>
					<div
						style={{
							padding: '1em 2em',
							marginBottom: '1em',
						}}
					>
						<ReactQuill
							inputStyle
							editableStyle={true}
							name="release-note-add"
							fontSize="16px"
							data-testid="release-note-add"
							theme="snow"
							ref={(el) => el && setQuillText(el)}
							value={editNote?.content || { ops: [] }}
							onChange={(content, delta, source, editor) => {
								setEditNote({
									...editNote,
									content: editor.getContents(),
								})
							}}
						/>

						<div
							style={{
								marginTop: '1em',
							}}
						>
							<CancelButton
								disabled={loading}
								variant="contained"
								onClick={(e) => {
									setEditNote(false)
									setQuillText(null)
								}}
							>
								Cancel
							</CancelButton>

							<SubmitButton
								disabled={loading}
								variant="contained"
								onClick={() => handleSubmit(editNote)}
								style={{
									marginLeft: '1em',
								}}
							>
								Submit
							</SubmitButton>
						</div>
					</div>
				</Paper>
			) : null}
			<div className="flex release-notes" style={{ flexDirection: 'column' }}>
				{notes.map((note, index) => (
					<CollapsedContent
						key={note.id}
						open={note.is_new || index == 0}
						title={
							<>
								<span
									style={{
										color: 'red',
										visibility: note.is_new ? 'inherit' : 'hidden',
									}}
								>
									•&nbsp;
								</span>
								{format(note.created_at, 'PPP')}
								{userAuthentication?.user?.id == 1 ? (
									<span>
										<NewButton size="small" onClick={() => setEditNote(note)}>
											Edit
										</NewButton>
										<ResetButton
											size="small"
											onClick={() =>
												handleSubmit({
													delete: true,
													id: note.id,
												})
											}
										>
											Delete
										</ResetButton>
									</span>
								) : null}
							</>
						}
						text={
							<ReactQuill
								style={{ border: '0' }}
								readOnly
								name={`release-note-${note.id}`}
								fontSize="16px"
								data-testid={`release-note-${note.id}`}
								theme="snow"
								modules={{ toolbar: false }}
								value={note.content || { ops: [] }}
							/>
						}
					/>
				))}
			</div>
		</div>
	)
}

export default withApollo(ReleaseNotes)
