import { gql } from '@apollo/client'
import { userDataCompressed } from '../common/fragments'

export const GET_USER = gql`
	query user($id: ID, $thumbnailResolution: String = "128x128") {
		getUser(id: $id) {
			...userDataCompressed
			email
			department_id
			title
			prefix_id
			suffix
			gallery_id
			created_at

			phone_label1
			phone_number1
			phone_label2
			phone_number2

			is_deleted
			gallery
			department
			unreadReleaseNotes
			last_release_note_date_seen
			last_login
			web_app_showcase_mode
			permissions {
				permission_id
				permission_value_id
			}
			bosses {
				id
			}
			assistants {
				id
				first_name
				last_name
			}
			access_to {
				id
				first_name
				last_name
			}
			goals {
				id
				goal_type_id
				user_id
				start_at
				end_at
				target
				progress
				type
			}
			user_group {
				id
				gallery_id
				gallery {
					id
					value
				}
				user_group_type_id
				type {
					id
					value
				}
				is_lead
			}
		}
		getCompletePermissions {
			permission_id
			path
			parent_id
			level
			permission_relationship_type_id
			permission_name
			permission_type
			relationship_type
			ordinal
		}
	}
	${userDataCompressed}
`


export const POLL_FOR_USER = gql`
	query user($id: ID, $thumbnailResolution: String = "128x128") {
		getUser(id: $id) {
			id
			unreadReleaseNotes
			last_release_note_date_seen
			unreadNotificationCount
			userNotifications {
				id
				read
				body
				created_at
				user {
					id
					first_name
					last_name
					imgUrl (thumbnailResolution: $thumbnailResolution)
				}
			}
		}
	}
`

export const UPDATE_USER = gql`
	mutation updateUser($UserInput: UserInput!, $thumbnailResolution: String = "128x128") {
		updateUser(input: $UserInput) {
			code
			success
			message
			user {
				...userDataCompressed
				email
				department_id
				title
				prefix_id
				suffix
				gallery_id

				phone_label1
				phone_number1
				phone_label2
				phone_number2

				is_deleted
				gallery
				department
				web_app_showcase_mode
				permissions {
					permission_id
					permission_value_id
				}
				goals {
					id
					goal_type_id
					user_id
					start_at
					end_at
					target
					progress
					type
				}
			}
		}
	}
	${userDataCompressed}
`

// Separate mutation for fields a user can update for himself.
export const UPDATE_SELF = gql`
	mutation updateSelf($SelfInput: SelfInput!, $thumbnailResolution: String = "128x128") {
		updateSelf(input: $SelfInput) {
			code
			success
			message
			user {
				...userDataCompressed

				email
				department_id
				title
				prefix_id
				suffix
				gallery_id

				phone_label1
				phone_number1
				phone_label2
				phone_number2

				is_deleted
				gallery
				department
				profile_link
				
				permissions {
					permission_id
					permission_value_id
				}
				goals {
					id
					goal_type_id
					user_id
					start_at
					end_at
					target
					progress
					type
				}
			}
		}
	}
	${userDataCompressed}
`

export const GET_USER_DATALOG = gql`
	query getUserDataLog(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$id: ID!
		$date: String
		$items: [String]
		$item_ids: [ID]
		$actions: [Item]
		$user_ids: [ID]
		$thumbnailResolution: String = "128x128"
	) {
		getUserDataLog(
			input: {
				field: $field
				direction: $direction
				limit: 10
				cursor: $cursor
				filters: {
					id: $id
					date: $date
					items: $items
					item_ids: $item_ids
					actions: $actions
					user_ids: $user_ids
				}
			}
		) {

			cursor
			totalItems

			items {
				id
				user_id
				item
				item_id
				action
				diff
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				user {
					...userDataCompressed
					title
				}
			}
		}
	}
	${userDataCompressed}
`

export const GET_HP_WIDGET_BY_USER = gql`
	query getHomePageXUser {
		getHomePageWidgetsXUser {
			id
			home_page_widget_id
			user_id
			ordinal
			is_deleted
		}
		getHomePageWidgets {
			id
			name
		}
	}
`

export const GET_USERS = gql`
	query getUsers($field: String, $direction: SortDirection, $cursor: Int, $includeDeleted: Boolean) {
		getUsers(input: { field: $field, direction: $direction, cursor: $cursor, includeDeleted: $includeDeleted }) {
			items {
				id
				first_name
				last_name
				is_deleted
			}
			cursor
			totalItems
		}
	}
`

export const GET_SEARCH_CARD_USERS = gql`
	query getSearchCardUsers {
		getSearchCardUsers {
			id
			first_name
			last_name
			is_deleted
		}
	}
`

export const GET_NON_DELETED_USERS_WITH_IMAGE = gql`
	query getUsers($field: String, $direction: SortDirection, $cursor: Int) {
		getUsers(input: { field: $field, direction: $direction, cursor: $cursor, active: true }) {
			items {
				id
				first_name
				last_name
				department
				imgUrl (thumbnailResolution: "128x128")
			}
			cursor
			totalItems
		}
	}
`

export const GET_USER_QV = gql`
	query user($id: ID) {
		getUser( id: $id ) {
			id
			first_name
			last_name
			email
				
			phone_label1
			phone_number1
			phone_label2
			phone_number2

			title
			department_id
			gallery
			is_deleted
			imgUrl (thumbnailResolution: "1024x1024")
		}
	}
`

export const TOGGLE_USER = gql`
	mutation toggleUserActivation($UserInput: UserInput!) {
		toggleUserActivation(input: $UserInput) {
			code
			success
			message
		}
	}
`
