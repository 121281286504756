/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useCallback, useState, useMemo } from 'react'
import { Autocomplete, Button, CircularProgress, FormControl, Menu, MenuItem, Paper, Select, TableSortLabel, TextField } from '@mui/material'
import {
	CREATE_LISTING_CONTACT,
	DELETE_LISTING_CONTACT,
	GET_LISTING_CONTACTS,
	GET_ALL_LISTING_CONTACTS,
	UPDATE_LISTING_CONTACT
} from './Queries'
import { arraymove, contactSorts, getContactName, getGalleryContactLabel, restoreColumnSettings, toTitleCase } from '../../../common/helpers'
import PhoneInput from 'react-phone-input-2'
import Dropdown from "../../../input/Dropdown/Dropdown"
import { AuthStateContext, LookupContext } from '../../../store'
import { useLocation, useNavigate } from 'react-router-dom'
import { ChooseImageButton, SubmitButton, CancelButton, ResetButton } from '../../../input/Buttons'
import TransitionsModal from '../../../navigation/TransitionsModal/TransitionsModal'
import { withApollo } from '@apollo/client/react/hoc'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { DispatchContext } from '../../../store'
import { severity } from '../../../Snackbar/CustomizedSnackbar'
import TextBox from '../../../input/Text/TextBox'
import Label from '../../../input/Label'
import { SearchButton } from '../../../input/Buttons'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { autoCompleteStyles, LightTooltip, typeStyle } from '../../../styles/makesStyles'
import { getListingStatusColor } from '../../../common/helpers'
import InfoCard from '../../../InfoCard/InfoCard'
import ListingRemoveModal from '../../../common/components/ListingRemoveModal'
import { LISTING_CONTACT_PAGE_COUNT } from '../../../constants/values'
import clsx from 'clsx'
import useNavigation from '../../../navigation/useNavigation'
import SingleContact from '../../../input/SingleContact'
import ContactThumbnail from '../../../Thumbnail/ContactThumbnail'
import GalleryContactList from '../../../common/components/GalleryContactList'
import { GET_LISTING_CONTACTS_NAV } from '../../../navigation/Queries'
import QuillText from '../../../input/QuillText'
import ListingRelationNotes from '../../../Notes/ListingRelationNotes'
import BulkActions from '../../../Search/BulkActions'
import { useSetRecoilState } from 'recoil'
import { listingQuickViewAtom } from '../ListingQuickView'
import { DataGridPro, GridActionsCellItem, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector } from '@mui/x-data-grid-pro'
import DataGridFlagMenu from '../../../common/components/DataGridFlagMenu'
import FavoriteAndFlag from '../../../common/FavoriteAndFlag'
import { ExpandCircleDown, KeyboardArrowDown, PersonRemove, StickyNote2Outlined, Undo } from '@mui/icons-material'
import { CONTACT_FLAG_QUERY } from '../../../Search/Queries'
import ContactQuickView from '../../../Contacts/ContactQuickView'
import { bulkActionSelectionAtom } from '../../../Search/BulkActionQuickView'
import { GET_USERS } from '../../../User/Queries'


const ContactPhoneGridCell = params => {
	const primaryPhone = params.row?.contact?.phone?.find(e => e.is_primary)
	const isPrivate = params.row?.contact?.is_private
	const hidden = isPrivate && !params.row?.contact?.first_name

	if (primaryPhone && !hidden) {
		return <div className="plain-text">
			<PhoneInput style={{width: "10em", display: "inline-block"}}
				inputStyle={{
					color: isPrivate ? 'white' : "rgba(0, 0, 0, 0.87)",
					fontFamily: "graphik",
					cursor: "pointer"
				}}
				placeholder=""
				value={primaryPhone.number}
				disabled
				onChange={() => {}}
			/>
			<div style={{width: "min-content", display: "inline-block"}}>
				{(primaryPhone.extension) ? "x" + primaryPhone.extension : null}
			</div>
		</div>
	}
	return <span>-</span>
}

const ContactStatusGridCell = params => {
	const hidden = params.row?.contact?.is_private && !params.row?.contact?.first_name

	if (params.row?.contact?.status && !hidden) {
		return toTitleCase(params.row?.contact.status.value)
	}
	return <span>-</span>
}

const GalleryContactsGridCell = params => {
	const galleryContacts = params.row?.interaction_salespersons

	return <GalleryContactList
		salespersons={galleryContacts}
		findMode={false}
		max={2}
	/>
}


const ListingContacts = (props) => {

	const setEntities = useSetRecoilState(bulkActionSelectionAtom)
	const setListingQuickView = useSetRecoilState(listingQuickViewAtom)
	const userAuthentication = useContext(AuthStateContext)

	const autoCompleteStyle = autoCompleteStyles()

	const columnSettingsKey = 'ListingContactDataGrid'
	const columnVisibilityKey = `${columnSettingsKey}-visible`

	const { push: pushNav } = useNavigation()
	const navigate = useNavigate()
	const location = useLocation()

	let prevSearch = location.state
	if (prevSearch?.card != "ListingContacts") {
		prevSearch = null
	}

	const classes = autoCompleteStyles()

	// Actions
	const [selectedRow, setSelectedRow] = useState(null)
	const [infoModal, setInfoModal] = useState({open: false})
	const [warningModal, setWarningModal] = useState({
		firstClick: true,
		open: false
	})

	// Pagination
	const [steps, setSteps] = useState(prevSearch?.steps ?? 1)
	const [activeStep, setActiveStep] = useState(prevSearch?.activeStep ?? 0)
	const [cursor, setCursor] = useState(prevSearch?.cursor ?? 0)
	const [totalItems, setTotalItems] = useState(prevSearch?.totalItems ?? 0)

	// Filters
	const [searchString, setSearchString] = useState(prevSearch?.searchString ?? "")
	const [listingContactStatus, setListingContactStatus] = useState(prevSearch?.listingContactStatus ?? "")
	const [galleryContacts, setGalleryContacts] = useState(prevSearch?.galleryContacts ?? [])

	const [editNoteModal, setEditNoteModal] = useState(false)

	const [contactResultsLoading, setContactsResultsLoading] = useState(false)

	// Sorting
	const DEFAULT_ORDER_BY = 'preferred_name'
	const [order, setOrder] = useState(prevSearch?.order || 'asc')
  	const [orderBy, setOrderBy] = useState(prevSearch?.orderBy || DEFAULT_ORDER_BY)

	// Tooltips
	const [ttOpen, setTTOpen] = useState({})
	const handleTTClose = (row) => setTTOpen({ [row.id]: false })
	const handleTTOpen = (row) => setTTOpen({ [row.id]: true })

	const lookup = useContext(LookupContext)

	const [removeListingModal, setRemoveListingModal] = useState(false)

	const [statusTypes, setStatusTypes] = useState([])
	const [modal, setModal] = useState({open: false, contact_id: null})
	const [attempt, setAttempt] = useState(false)

	// Data
	const [rows, setRows] = useState(prevSearch?.rows ?? [])

	// GridSettings
	const [columnSettings, setColumnSettings] = useState( JSON.parse(localStorage.getItem(columnSettingsKey) || null ) )
	const [columnVisibilityModel, setColumnVisibilityModel] = useState(JSON.parse(localStorage.getItem(`${columnSettingsKey}.visibility`) || '{}' ))
	const [limit, setLimit] = useState(Number(localStorage.getItem(`${columnSettingsKey}.limit`) || LISTING_CONTACT_PAGE_COUNT))
	const [density, setDensity] = useState(localStorage.getItem(`${columnSettingsKey}.density`) || 'comfortable')

	// Gallery Contacts options
	const { data: userData } = useQuery(GET_USERS, {
		variables: { includeDeleted: true }
	})
	const options = userData?.getUsers?.items || []

	// Save Search
	const updateHistory = useCallback((contactId) => {
		const savedSearch = {
			cursor, selectedRow,
			searchString, listingContactStatus, rows,
			orderBy, order,
			steps, activeStep, totalItems,
			searchPage: location.pathname,
			card: "ListingContacts"
		}
		const offset = rows.findIndex(a => a.contact.id == contactId)
		const nextcursor = activeStep * limit + offset
		const variables = {
			id: props.id,
			cursor: nextcursor,
			limit: 3,
			searchString,
			listingContactStatus,
			field: orderBy || 'preferred_name',
			direction: order.toUpperCase(),
		}
		savedSearch.variables = variables
		navigate(location.pathname, { state: savedSearch })
		return savedSearch
	}, [activeStep, cursor, limit, listingContactStatus, order, orderBy,
		navigate, location, props.id, rows, searchString, selectedRow, steps, totalItems])

	const saveAndGo = useCallback((path, row) => {
		const state = updateHistory(row)
		pushNav({
			url: location.pathname,
			state,
			query: GET_LISTING_CONTACTS_NAV,
			getId: (r => r?.contact?.id)
		}, state.variables.cursor)
		navigate(path, { state })
	}, [updateHistory, pushNav, navigate, location])



	// Get initial flag count
	const { data: flaggedData } = useQuery(CONTACT_FLAG_QUERY, {
		variables: {
			filters: [{
				field: "isFlagged",
				type: "eq",
				value: "true"
			}]
		}
	})
	const initialFlaggedCount = flaggedData?.searchDynamicContacts?.totalItems

	const warnThenGo = useCallback(() => {
		if (initialFlaggedCount > 0 && warningModal.firstClick) {
			return new Promise((resolve, reject) => {
				setWarningModal(wm => ({
					...wm,
					open: true,
					firstClick: false,
					resolve,
					reject
				}))
			})
		} else {
			return Promise.reject()
		}
	}, [initialFlaggedCount, warningModal.firstClick])

	const resetGrid = () => {
		localStorage.removeItem(columnSettingsKey)
		localStorage.removeItem(columnVisibilityKey)
		localStorage.removeItem(`${columnSettingsKey}.density`)
		localStorage.removeItem(`${columnSettingsKey}.limit`)

		setColumnVisibilityModel({})
		setColumnSettings(null)
		setLimit(LISTING_CONTACT_PAGE_COUNT)
		setDensity('comfortable')
	}

	const updateColumnSettings = () => {
		if (gridCols.length) {
			const colSettings = gridCols.map(col => {
				const setting = { field: col.field }
				if (col.width) setting.width = col.width
				if (col.minWidth) setting.minWidth = col.minWidth
				return setting
			})
			setColumnSettings(colSettings)
		}
	}

	const CustomToolBar =  () => <div>
		<GridToolbarContainer>
			<Button
				size="small"
				startIcon={<Undo />}
				onClick={resetGrid}
			>
				Reset
			</Button>
			<GridToolbarColumnsButton />
			<GridToolbarDensitySelector />
		</GridToolbarContainer>
	</div>

	// Get Status options
	useEffect(() => {
		if (lookup.data?.getListingContactStatus && props.state?.getListing)
			setStatusTypes(lookup.data.getListingContactStatus.filter((type) => {
				return type.listing_type_id == props.state?.getListing.type_id
			}))
	}, [lookup.data?.getListingContactStatus, props.state?.getListing])


	const handleResponse = (data) => {

		setContactsResultsLoading(false)
		setRows(data.getListingContacts.items)

		if (data.getListingContacts.items.length < 1) setSteps(1)
		else setSteps((Math.ceil(data.getListingContacts.totalItems / limit)))

		!called && loadContactIds()
	}

	const handleError = (data) => {

		setContactsResultsLoading(false)
		if (data.getListingContacts?.items) {
			setRows(data.getListingContacts.items)

			if (data.getListingContacts.items.length < 1) setSteps(1)
			else setSteps((Math.ceil(data.getListingContacts.totalItems / limit)))

			setTotalItems(data.getListingContacts.totalItems || 0)
		}
	}

	useEffect(() => {
		let active = true

		setContactsResultsLoading(true)
		if (!props.findMode && props.id > 0) {
			searchListingContacts()
				.then((data) => {
					if (active) {
						handleResponse(data.data)
					}
				})
				.catch(handleError)
		}
		return () => active = false
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.id, orderBy, order, props?.state?.getListing?.type_id, limit])

	const searchListingContacts = (cursorOverride = null) => {
		setContactsResultsLoading(true)
		return props.client.query({
			query: GET_LISTING_CONTACTS,
			variables: {
				id: props.id,
				cursor: cursorOverride ?? cursor,
				limit: limit,
				searchString,
				listingContactStatus,
				galleryContacts: galleryContacts.map(gc => gc.id),
				field: orderBy || DEFAULT_ORDER_BY,
				direction: order.toUpperCase(),
			}
		})
	}

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	// load all ids if needed
	const [ loadContactIds, { data, called } ] = useLazyQuery(GET_ALL_LISTING_CONTACTS, {
		variables: { id: props.id },
		onCompleted: () => {},
		onError: error => openSnackbar(severity.ERROR, error.message)
	})

	// Create relation
	const [createListingContact] = useMutation(CREATE_LISTING_CONTACT, {

		onError: (error) => {
			openSnackbar(severity.ERROR, "Could not add contact.")
		},

		onCompleted: (response) => {
			if (response.createListingContact.id) {
				// Success
				openSnackbar(severity.SUCCESS, "Successfully added contact.")

				// Add the contact to state
				setRows(rows.concat(response.createListingContact))
				setContactsResultsLoading(true)
				searchListingContacts()
					.then(response => handleResponse(response.data))
					.catch(error => handleError(error))

				// update the count in the QV
				setListingQuickView((oldVal) => ({
					...oldVal,
					contactCount: oldVal.contactCount + 1
				}))


				// update the bulk actions button
				loadContactIds()

				setAttempt(false)
				setModal({...modal, open: false})

			} else {
				// On failure, reset state and show error
				const msg = response?.createListingContact?.message
				openSnackbar(severity.ERROR, msg || "Could not add contact.")
			}
		}
	})

	// Delete
	const [deleteRelation, { loading }] = useMutation(DELETE_LISTING_CONTACT)

	// Update
	const [updateRelation] = useMutation(UPDATE_LISTING_CONTACT)

	const handleDeleteListing = () => {
		deleteRelation({
			variables: {
				id: removeListingModal.id
			}
		}).then(response => {

			if (response && !response.data?.deleteListingContact?.success === true) {

				openSnackbar(severity.ERROR, "There was an error deleting this relation.")
			} else {

				openSnackbar(severity.SUCCESS, "Successfully deleted relation.")

				// update the count in the QV
				setListingQuickView((oldVal) => ({
					...oldVal,
					contactCount: oldVal.contactCount - 1
				}))
				searchListingContacts()
					.then(response => handleResponse(response.data))
					.catch(error => handleError(error))

				// update the bulk actions button
				loadContactIds()
			}
		}).catch(error => {
			console.log(error)
			openSnackbar(severity.ERROR, "There was an error deleting this relation.")
		})
	}



	// Ids to be updated
	const [contacts, setContacts] = useState([])
	useEffect(() => {
		if (selectedRow)
			setContacts([selectedRow?.contact])
		else {
			const contactList = data?.getListingContacts?.items?.map(a => a.contact) ?? []
			setContacts(contactList)
			setEntities(contactList)
			setTotalItems(contactList.length)
		}
	}, [selectedRow, data, setEntities])


	// Deselect Row on change of Main Entity
	useEffect(() => {
		setSelectedRow(null)
	}, [props.id])


	useEffect(() => {
		return () => {
			localStorage.setItem(columnSettingsKey, JSON.stringify(columnSettings))
			localStorage.setItem(`${columnSettingsKey}.density`, density)
			localStorage.setItem(`${columnSettingsKey}.visibility`, JSON.stringify(columnVisibilityModel))
			localStorage.setItem(`${columnSettingsKey}.limit`, limit)
		}
	}, [columnSettings, density, columnVisibilityModel, limit])



	// This prevents stale closures; anything that might change (contextually)
	// that needs to stay up to date must be listed in the useMemo dependencies.

	/** @type import('@mui/x-data-grid-pro').GridColDef[] */
	const gridCols = useMemo(() => {

		const cols = ([
			{
				field: 'Actions',
				type: 'actions',
				headerName: '',
				getActions: (params) => [
					<GridActionsCellItem
						icon={<Undo />}
						onClick={() => {
							saveAndGo(
								`/contacts/${params.row?.contact?.id}/details`,
								params.row?.contact?.id
							)
						}}
						label="Go to Contact"
						showInMenu
					/>,
					<GridActionsCellItem
						icon={<StickyNote2Outlined />}
						onClick={() => setEditNoteModal(params.row)}
						label="Edit Note"
						showInMenu
					/>,
					<GridActionsCellItem
						icon={<PersonRemove />}
						onClick={() => setRemoveListingModal(params.row)}
						label="Remove from Listing"
						showInMenu
					/>,
					<GridActionsCellItem
						icon={<ExpandCircleDown />}
						onClick={() => setInfoModal({open: true, row: params.row})}
						label="Information"
						showInMenu
					/>
				  ],

				resizable: false,
				minWidth: 50,
				width: 50,
				sortable: false,
			},
			{
				field: 'isFlagged',
				headerName: 'Flag',
				renderHeader: params => <DataGridFlagMenu
					variables={{
						filters: [
							{field: "listing_number", type: "eq", value: props.id, isOptional: false}
						]
					}}
					setRows={(callback) => {
						const updatedRows = callback(rows.map(r => r.contact))
						setRows(rows => {
							updatedRows.forEach(ur => {
								const theRow = rows.find(r => r?.contact?.id == ur.id)
								theRow && (theRow.contact = ur)
							})
							return [...rows] // change the reference
						})
					}}
					rows={rows.map(r => r.contact)}
					totalItems={totalItems}
					type="Contact"
					hideFindFlagged={true}
					onClick={warnThenGo}
				/>,
				width: density === 'compact' ? 80 : 50,
				renderCell: params => {
					if (params.row.is_private && !params.row.created_at)
						return <></>
					return (
						<FavoriteAndFlag
							entityName="contact"
							row={params.row.contact}
							userId={userAuthentication?.user?.id}
							setRows={(callback) => {
								const updatedRows = callback(rows.map(r => r.contact))
								setRows(rows => {
									updatedRows.forEach(ur => {
										const theRow = rows.find(r => r?.contact?.id == ur.id)
										theRow && (theRow.contact = ur)
									})
									return [...rows] // change the reference
								})
							}}
							compact={params.api.state.density.value == 'compact'}
							hidden={params.row.is_private && !params.row.created_at}
							onClick={warnThenGo}
						/>
					)},
				sortable: false,
				resizable: false,
				headerClassName: 'flag-header-data-grid'
			},
			{
				headerName: 'Contact',
				field: 'Name',
				width: 300,
				sortable: false,
				renderHeader: function ContactHeader (args) {
					const [ctcAnchorEl, setCtcAnchorEl] = useState(null)

					const openContactSortMenu = (e) => {
						e.preventDefault()
						e.stopPropagation()
						setCtcAnchorEl(e.target)
					}
					const handleCtcAnchorClose = () => setCtcAnchorEl(null)

					return <>
						<Menu
							id="simple-menu"
							anchorEl={ctcAnchorEl}
							keepMounted
							open={!!ctcAnchorEl}
							onClose={handleCtcAnchorClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						> { contactSorts.map(x =>
								<MenuItem key={`inner-menu-${x.id}`} value={x.id}
									onClick={() => {
										if (!x.noSort) {
											const field = x.id
											const oldSort = order ?? 'desc'
											const sort = oldSort === 'asc' ? 'desc' : 'asc'
											setOrder(sort)
											setOrderBy(field)
										} else {
											setOrderBy(null)
										}
										handleCtcAnchorClose()
									}}
								>
									<TableSortLabel
										active={!!orderBy === x.id}
										direction={order ?? "asc"}
										hideSortIcon={x.noSort}
										style={x.noSort ? {cursor: "default", fontWeight: 400} : {fontWeight: 400}}
									>
										{ x.label }
									</TableSortLabel>
								</MenuItem>
							)}
						</Menu>
						<Button
							size="small"
							endIcon={<KeyboardArrowDown />}
							onClick={openContactSortMenu}
							sx={{
								fontWeight: 500,
								color: '#808080',
								fontSize: 14
							}}
						>
							{ args.colDef.headerName }
						</Button>
					</>
				},
				renderCell: params => params?.api?.state?.density?.value === 'compact' ?
					<span>{getContactName(params.row?.contact)}</span> :
					 <ContactThumbnail
						contact={params?.row?.contact}
						darkText={!params?.row?.contact?.is_private}
						style={{ lineHeight: 'initial' }}
					/>
			},
			{
				field: 'contact:phone',
				headerName: 'Primary Contact',
				width: 200,
				renderCell: ContactPhoneGridCell,
			}, {
				field: 'contact:status',
				headerName: "Contact Status",
				renderCell: ContactStatusGridCell,
			}, {
				field: 'interaction_salespersons',
				headerName: "Gallery Contacts",
				sortable: false,
				renderCell: GalleryContactsGridCell,
			}, {
				field: 'status',
				headerName: "Listing Status",
				renderCell: params => <LightTooltip
					open={ttOpen[params?.row?.id] || false}
					disableFocusListener
					disableTouchListener
					disableHoverListener
					onClose={() => handleTTClose(params.row)}
					onOpen={() => handleTTOpen(params.row)}
					placement="bottom"
					enterDelay={1000}

					title={
						<QuillText noWrap>
							{typeof params.row.note === 'object'
								? params.row.note || { ops: [{insert: "No Note"}]}
								: JSON.parse(params.row.note)}
						</QuillText>
					}>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						id="listing-contacts-status"

						input={<Dropdown />}
						onOpen={() => handleTTClose(params.row)}
						onMouseEnter={() => handleTTOpen(params.row)}
						onMouseLeave={() => handleTTClose(params.row)}
						className={clsx({
							'hideborder': true,
							'white-svg': params.row.contact?.is_private,
						})}
						value={statusTypes.length ? params.row.status_id : ''}
						onClick={e => {
							e.preventDefault()
							e.stopPropagation()
						}}
						style={{color: getListingStatusColor(params.row.status_id, statusTypes), borderRadius: '4px'}}
						onChange={(event) => {
							const variables = {
								id: params.row.id,
								status_id: event.target.value,
							}

							// Send to server
							updateRelation({
								variables
							}).then(response => {

								if (response && !response?.data?.updateListingContact?.id) {

									openSnackbar(severity.ERROR, "There was an error updating this status.")
								} else {

									openSnackbar(severity.SUCCESS, "Successfully updated status.")
									setRows(rows.map(row => {
										if (row.id == response.data.updateListingContact.id)
											return response.data.updateListingContact
										else return row
									}))
								}

							}).catch(error => {

								console.log(error)
								openSnackbar(severity.ERROR, "There was an error updating this status.")
							})

						}}
					>
						{statusTypes && statusTypes.map(type => (
							<MenuItem style={{color: getListingStatusColor(type.id, statusTypes)}} key={type.id} value={type.id}>{type.value}</MenuItem>
						))}
					</Select>
				</LightTooltip>
			},
		])
		if (columnSettings?.length) restoreColumnSettings(cols, columnSettings)
		return cols
	}, [totalItems, rows, density, statusTypes, columnSettings, ttOpen,
		order, orderBy, warnThenGo, saveAndGo,
		openSnackbar, props.id, updateRelation, userAuthentication?.user?.id
	])

	return (!props.findMode && props.id > 0) && (
		<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
			<Paper
				className='search-list padding-margin'
				data-testid="card-contacts"
				sx={{ display: 'flex', flexDirection: 'column', flexGrow: 0 }}
			>
				<h1 className='card-title' style={{justifyContent: 'space-between'}}>
					<div>
						<span>Search</span>
						<BulkActions
							selectedEntities={contacts}
							entityType='contact'
							onOpen={() => !contacts?.length && !called && loadContactIds()}
							requery={() => searchListingContacts()
								.then(response => handleResponse(response.data))
								.catch(error => handleError(error))
							}
							hangingMode={true}
							disabled={rows?.length <= 0}
							smallButton
							options={{listingId: props.id}}
						/>
					</div>

					<div>
						<ChooseImageButton
							variant="contained"
							color="secondary"
							size="small"
							onClick={() => {
								setModal({open:true, contact_id: null})
							}}
						>
							Add Contact
						</ChooseImageButton>
					</div>
				</h1>

				<div style={{ display: 'flex', flexWrap: 'wrap' }}>
					<FormControl style={{width: '20em', paddingRight: '1em'}}>
						<Label style={{
							fontWeight: 500
						}} disableAnimation shrink>
							Search
						</Label>
						<TextBox
							name="search"
							value={searchString}
							onKeyPress={ev => {
								if (ev.key === 'Enter') {
									searchListingContacts()
										.then(response => handleResponse(response.data))
										.catch(error => handleError(error))
								}
							}}
							onChange={(e) => {
								setSearchString(e.target.value)
							}}/>
					</FormControl>
					<FormControl style={{width: '10em', paddingRight: '1em'}}>
						<Label id="type-label" style={{
							fontWeight: 500
						}} disableAnimation shrink>
							Status
						</Label>
						<Select
							IconComponent={ExpandMoreRoundedIcon}
							className="padded-select"
							name="status"

							labelId="status-label"
							input={<Dropdown />}
							value={statusTypes.length ? listingContactStatus : ''}
							style={{color: getListingStatusColor(listingContactStatus, statusTypes)}}
							onChange={(event) => {
								setListingContactStatus(event.target.value)
							}}
						>
							<MenuItem value="">All</MenuItem>
							{statusTypes && statusTypes.map(type => (
								<MenuItem style={{color: getListingStatusColor(type.id, statusTypes)}} key={type.id} value={type.id}>{type.value}</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl className={classes.selection}
						style={{ marginRight: '1em', width: '15em' }}
					>
						<Label
							id="user-label"
							style={typeStyle}
							disableAnimation
							shrink
						>
							Gallery Contact
						</Label>

						<Autocomplete
							multiple
							classes={autoCompleteStyle}
							style={{marginTop: "1.5em"}}
							isOptionEqualToValue={(option, value) => {
								return option.id == value.id
							}}
							forcePopupIcon
							filterSelectedOptions
							popupIcon={<ExpandMoreRoundedIcon />}
							size="small"
							value={galleryContacts}
							getOptionLabel={(option) =>getGalleryContactLabel(option)}
							options={options || []}
							onChange={(_, value) => {
								setGalleryContacts(value)
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									fullWidth
									InputProps={{
										...params.InputProps,
										style: {'padding': '9px'},
									}}
								/>
							)}
							data-testid="search-gallery_contact"
						/>
					</FormControl>
					<div style={{ flexGrow: 2 }}></div>
					<FormControl style={{paddingRight: '1em'}}>
						<CancelButton variant="contained" size="medium" type="submit" style={{marginTop: '1.9em'}}
							onClick={() => {
								setSearchString("")
								setListingContactStatus("")
								setGalleryContacts([])
							}}
						>
							Reset
						</CancelButton>
					</FormControl>
					<FormControl>
						<SearchButton variant="contained" size="medium" type="submit" style={{marginTop: '1.9em'}}
							onClick={() => {
								searchListingContacts()
									.then(response => handleResponse(response.data))
									.catch(error => handleError(error))
							}}
						>
							Search
						</SearchButton>
					</FormControl>
				</div>
			</Paper>
			<Paper

				sx={{ flexGrow: 1, margin: '0 1em 1em 1em' }}
			>
				<h1 className='card-title' style={{
					paddingTop: '1em',
					paddingLeft: '2em'
				}}>
					<div>
						<span>Contacts {(!called || rows.length > totalItems) ?
							<CircularProgress  size={16} /> :
							`(${totalItems})`}
						</span>
					</div>
				</h1>
				<div style={{ height: 'calc(100% - 4em)', width: '100%' }}>
					<DataGridPro
						disableColumnMenu
						disableMultipleSelection
						columns={gridCols}
						rows={rows}
						onRowDoubleClick={({row}) => {
							const contact = row.contact
							if (contact.is_private && !contact.created_at) return
							saveAndGo(`/contacts/${contact.id}`, contact.id)
						}}
						rowCount={totalItems}
						loading={props.loading || contactResultsLoading}
						components={{ Toolbar: CustomToolBar }}
						pagination
						paginationMode="server"
						sortingMode="server"
						pageSize={limit}
						onPageSizeChange={setLimit}
						rowsPerPageOptions={[10, 25, 50]}
						page={activeStep}
						onPageChange={(page) => {
							const newCursor = page * limit
							setActiveStep(page)
							setCursor(newCursor)
							searchListingContacts(newCursor)
								.then(response => handleResponse(response.data))
								.catch(error => handleError(error))

							// Scroll to top of page
							var elmnt = document.querySelector('.search-list')
							elmnt.scrollIntoView({behavior: "smooth", block: "start"})
						}}
						// sortModel seems to break on our "custom" contact sort
						onSortModelChange={newSort => {
							if (newSort.length) {
								setOrderBy(newSort[0].field)
								setOrder(newSort[0].sort)
							} else {
								setOrderBy(null)
								setOrder('asc')
							}
						}}
						onStateChange={({density}) => setDensity(density?.value ?? 'comfortable')}
						onRowClick={(params, event) => {
							let row = params.row
							if (event.metaKey) {
								if (row.contact?.is_private && !row.contact?.created_at) return

								window.open(`/contacts/${row.contact?.id}`, '_blank')
								return
							}

							setSelectedRow(row)
							props.setQVOverride(() => <ContactQuickView
								id={row?.contact?.id}
								onClose={() => {
									setSelectedRow(null)
									props.setQVOverride(null)
								}}
							/>)
						}} 
						selectionModel={selectedRow?.id ? [selectedRow?.id] : []}

						getRowClassName={({row}) => row.contact?.is_private ? 'Private-DataGrid-Row' : null}
						columnVisibilityModel={columnVisibilityModel}
						onColumnVisibilityModelChange={setColumnVisibilityModel}
						onColumnOrderChange={({ oldIndex, targetIndex }) => {
							arraymove(gridCols, oldIndex, targetIndex)
							updateColumnSettings()
						}}
						onColumnWidthChange={({ colDef, width}) => {
							gridCols.find(col => col.field === colDef.field).width = width
							updateColumnSettings()
						}}
					/>
				</div>


				<ListingRemoveModal
					disabled={loading}
					removeListingModal={removeListingModal}
					setRemoveListingModal={setRemoveListingModal}
					itemToRemove={removeListingModal?.preferred_name || removeListingModal?.company_name}
					handleDeleteListing={handleDeleteListing}
					isPrivate={removeListingModal?.is_private}
				/>

			</Paper>

			<TransitionsModal
				className="listing-contact-modal"
				open={modal.open}
				close={() => setModal({open: false, contact_id: null})}
			>
				<h2 style={{fontWeight: 500}}>Add a Contact</h2>
				<div className={(attempt && !modal.contact_id) ? "error" : null}>
					<FormControl className={classes.searchSelection} style={{width: "100%", paddingRight: "0px"}}>

						<SingleContact
							query="dynamicContactSearch"
							getVariables={(input) => ({
								cursor: 0,
								direction: 'ASC',
								field: null,
								limit: 10,
								filters: [
									{ field: 'query', type: 'eq', value: input},
								]
							})}
							style={{width: "20em"}}
							error={attempt && !modal.contact_id}
							onChange={(event, value) => {
								setModal({
									...modal,
									contact_id: value ? value.id : null
								})
							}}
						/>
					</FormControl>

				</div>

				<>
					<CancelButton
						variant="contained"
						style={{ float: 'left', marginTop: '1em' }}
						onClick={e => {
							e.preventDefault()
							setModal({open: false, contact_id: null})
							setAttempt(false)
						}}
					>
					Cancel
					</CancelButton>

					<SubmitButton
						style={{ float: 'right', marginTop: '1em' }}
						variant="contained"
						onClick={(e) => {
							setAttempt(true)

							if (attempt && !modal.contact_id) {
								openSnackbar(severity.WARNING, "Please complete the fields in red.")

							} else if (modal.contact_id) {
								const variables = {
									contact_id: modal.contact_id,
									listing_id: props.id,
								}

								// Send to server
								createListingContact({ variables })
							}
						}}
					>
						Submit
					</SubmitButton>
				</>
			</TransitionsModal>

			<TransitionsModal
				className="listing-contact-modal"
				open={warningModal.open}
				close={() => setWarningModal(wm => ({ ...wm, open: false, firstClick: false }))}
			>
				<div>
					You currently have { initialFlaggedCount } Contacts already
					flagged.
				</div>

				<div>
					<CancelButton
						variant="contained"
						style={{ float: 'left', marginTop: '1em' }}
						onClick={e => {
							e.preventDefault()
							warningModal.reject()
							setWarningModal(wm => ({
								...wm,
								open: false,
								firstClick: false
							}))
						}}
					>
						Cancel
					</CancelButton>

					<ResetButton
						style={{ float: 'right', marginTop: '1em' }}
						variant="contained"
						onClick={e => {
							e.preventDefault()
							warningModal.resolve()
							setWarningModal(wm => ({
								...wm,
								open: false,
								firstClick: false
							}))
						}}
					>
						Clear Flags
					</ResetButton>
				</div>
			</TransitionsModal>

			<InfoCard
				open={infoModal.open}
				object={infoModal.row}
				setInfoModal={setInfoModal}
				allAttributes
			/>

			{editNoteModal ?
				<ListingRelationNotes
					open={editNoteModal}
					close={() => setEditNoteModal(false)}
					row={editNoteModal}
					updateRelation={updateRelation}
					isPrivate={props.contact?.is_private}
					setRows={setRows}
					rows={rows}
					responseName='updateListingContact'
					entity='Contact'
				/> : null
			}


		</div>
	)
}


export default withApollo(ListingContacts)
