/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import makeStyles from '@mui/styles/makeStyles'
import * as Colors from '../../styles/colors/Colors'
import { useNavigate, useLocation } from 'react-router-dom'
import { Badge, IconButton, Tooltip } from '@mui/material'
import QuickSearchNav from './QuickSearchNav'
import useNavigation from '../useNavigation'
import RedeemIcon from '@mui/icons-material/Redeem'
import { useQuery } from '@apollo/client'
import { POLL_FOR_USER } from '../../User/Queries'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { useRecoilValue } from 'recoil'
import { findModeAtom } from '../Tabs/TabbedPage'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationPane from './NotificationPane'

/** styles for this component */
const useStyles = makeStyles((theme) => ({
	appBar: {
		zIndex: (theme.zIndex?.drawer || 0) + 1,
		backgroundColor: Colors.nav,
		height: '4em',
		fontSize: 14,
	},
	title: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
}))

/** the top navbar as a functional component for reusability */
export default function TopNav(props) {
	const classes = useStyles()

	const findMode = useRecoilValue(findModeAtom)

	const navigation = useNavigation()
	const navigate = useNavigate()
	const location = useLocation()
	const searchState = location?.state
	const [releaseNotes, setReleaseNotes] = React.useState(null)
	const [showUnread, setShowUnread] = React.useState(JSON.parse(localStorage.getItem('LGDRshowOnlyUnreadNotifications')) || false)

	useEffect(() => localStorage.setItem('LGDRshowOnlyUnreadNotifications', showUnread), [showUnread])

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text, loading: true } })
		},
		[dispatch]
	)

	let subheading = ''

	const localPath = location.pathname
	const onAdminConsole = localPath.includes('admin')

	if (onAdminConsole) {
		subheading += ' - Admin Console'
	}

	if (localPath.includes('showcase')) {
		subheading += ' - Showcase'
	}

	let version = window.SERVER_DATA?.VERSION
	version = Object.entries(version || []).map(([key, version]) => (
		<div key={key}>
			{key}: {version}
		</div>
	))

	const [anchorEl, setAnchorEl] = React.useState(null)
	const [open, setOpen] = React.useState(false)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
		setOpen((prev) => !prev)
	}

	const handleClickAway = () => {
		setOpen(false)
	}

	const [unreadNotificationCount, setUnreadNotificationCount] = React.useState(0)
	const [userNotifications, setUserNotification] = React.useState([])
	
	// Function to check if your are on quicksearch pages
	const isOnEntityPage = (searchState) => {
		if (!searchState || !searchState.variables) return false
		else {
			const { pathname } = location

			try {
				let temp
				
				// On admin pages we have an id at index 3
				if (searchState.searchPage == '/admin/users') temp = parseInt(pathname.split('/')[3])
				// On non admin pages we have an id at index 2
				else temp = parseInt(pathname.split('/')[2])

				if (isNaN(temp)) return false
				return true
			} catch (e) {
				return false
			}
		}
	}

	// Get latest release notes, and hard refresh if needed
	useQuery(POLL_FOR_USER, {
		skip: !props.authState?.user?.id,
		variables: {
			id: props.authState?.user?.id,
		},
		pollInterval: 300000, // 5 Minutes
		notifyOnNetworkStatusChange: true,
		onCompleted: async (data) => {
			const result = data?.getUser?.unreadReleaseNotes || 0
			setReleaseNotes(result)

			setUserNotification(data?.getUser?.userNotifications)
			setUnreadNotificationCount(data?.getUser?.unreadNotificationCount)

			//check for out of date server:
			const res = await fetch('/api/config')
			const serverVersion = await res.json()
			const incomingServerVersion = serverVersion.VERSION?.Server
			const currentServerVersion = window.SERVER_DATA.VERSION?.Server
			if (currentServerVersion) console.info(`Local Server version: ${currentServerVersion}`)
			if (incomingServerVersion) console.info(`Remote Server version: ${incomingServerVersion}`)
			if (currentServerVersion != incomingServerVersion) {
				let keyCombo
				if (navigator.platform.includes('Mac')) {
					keyCombo = '⇧⌘R'
				} else {
					keyCombo = 'Ctrl + F5'
				}

				openSnackbar(
					severity.WARNING,
					`The Platform has been updated. Please refresh your page with ${keyCombo}.`
				)
			}
		},
		onError: console.error,
	})

	return (
		localPath !== '/' &&
		localPath !== '/admin/login' && (
			<AppBar position="static" className={classes.appBar}>
				<Toolbar style={{ paddingRight: '0.2em' }}>
					<div id="nav-title" className={classes.title}>
						<Tooltip title={version} placement="bottom-start" arrow>
							<span>The Platform</span>
						</Tooltip>
						{subheading}
					</div>
					<div style={{ margin: 'auto' }}>
						{!findMode && (
							<QuickSearchNav
								totalResultCount={props.totalResultCount}
								setTotalResultCount={props.setTotalResultCount}
								fadeIn={isOnEntityPage(searchState) && !findMode && navigation.length > 0}
							/>
						)}
					</div>
					{releaseNotes ? (
						<div>
							<IconButton
								color="inherit"
								aria-label="Release Notes"
								onClick={(e) => {
									setReleaseNotes(0)
									navigate('/support/releasenotes')
								}}
							>
								<Badge badgeContent={releaseNotes} color="secondary">
									<RedeemIcon />
								</Badge>
							</IconButton>
						</div>
					) : null}
					<IconButton
						color="inherit"
						aria-label="Notifications"
						style={{
							margin: '0.25em',
						}}
						onClick={handleClick}
					>
						<Badge
							badgeContent={unreadNotificationCount != 0 ? unreadNotificationCount : null}
							color="error"
						>
							<NotificationsIcon />
						</Badge>
					</IconButton>

					
					<NotificationPane 
						open={open}
						anchorEl={anchorEl}
						handleClickAway={handleClickAway}
						showUnread={showUnread}
						setShowUnread={setShowUnread}
						userNotifications={userNotifications}
						authState={props?.authState}
						setUserNotification={setUserNotification}
						setUnreadNotificationCount={setUnreadNotificationCount}
						onAdminConsole={onAdminConsole}
					/>
				</Toolbar>
			</AppBar>
		)
	)
}
