import { gql } from '@apollo/client'
import { userData } from '../common/fragments'

export const GET_USER_ACTIVITY = gql`
	query getActivityLog($id: ID, $field: String, $direction: SortDirection, $cursor: Int, $limit: Int) {
		getActivityLog(input: { id: $id, field: $field, direction: $direction, cursor: $cursor, limit: $limit }) {
			items {
				id
				item
				item_id
				action
				diff
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				user {
					...userData
					title
				}
				entity_title
				entity_subtitle
				entity_id
				entity_name
				entity_img (thumbnailResolution: "128x128")

				contact {
					id
					preferred_name
					first_name
					last_name
					is_company
					company_name
					is_private
					created_at
					code_name
					dynamic_generated_codename
				}

				deal {
					id
					is_private
					is_gallery_private
					created_at
					code_name
				}

				art {
					id
					imgUrl (thumbnailResolution: "128x128")
					title
					formatted_title 
					year
					is_private
					code_name
					created_at
					artist {
						id
						first_name
						last_name
					}
				}
			}

			cursor
			totalItems
		}
	}
	${userData}
`

export const GET_HOME_PAGE_DETAILS = gql`
	query user($id: ID) {
		getUser(id: $id) {
			id
			goals {
				id
				goal_type_id
				user_id
				start_at
				end_at
				target
				progress
				type
			}
		}
		getHomePageWidgetsXUser {
			id
			home_page_widget_id
			user_id
			ordinal
			is_deleted
		}
	}
`
export const GET_GLOBAL_RESERVES = gql`
	query getGlobalReserves($cursor: Int, $limit: Int) {
		getGlobalReserves(cursor: $cursor, limit: $limit) {
			items {
				id
				art_id
				deal_id
				is_private
				reserveQueue {
					reserve_begin_at
					reserve_end_at
				}
				art {
					id
					imgUrl (thumbnailResolution: "128x128")
					title
					formatted_title 
					year
					is_private
					code_name
					created_at
					artist {
						id
						first_name
						last_name
					}
				}
				deal {
					id
					is_private
					is_gallery_private
					created_at
					code_name
					salesperson {
						...userData
						is_lead
						is_deleted
					}
				}
				has_reserve
				reserve_end_at
			}
			cursor
			totalItems
		}
	}
	${userData}
`

export const GET_GLOBAL_CONSIGNMENTS = gql`
	query getGlobalConsignments($cursor: Int, $limit: Int, $isSalesperson: Boolean) {
		getGlobalConsignments(cursor: $cursor, limit: $limit, isSalesperson: $isSalesperson) {
			items {
				id
				art_id
				deal_id
				is_private
				reserveQueue {
					reserve_begin_at
					reserve_end_at
				}
				art {
					id
					is_private
					code_name
					created_at
					title
					imgUrl (thumbnailResolution: "128x128")
					is_verified
					is_active
					formatted_title 	
					year
					artist {
						id
						first_name
						last_name
					}
				}
				deal {
					id
					is_private
					is_gallery_private
					created_at
					code_name
					salesperson {
						...userData
						is_lead
						is_deleted
					}
				}
				end_at
			}
			cursor
			totalItems
		}
	}
	${userData}
`

export const GET_GLOBAL_LOANS = gql`
	query getGlobalLoans($cursor: Int, $limit: Int) {
		getGlobalLoans(cursor: $cursor, limit: $limit) {
			items {
				id
				art_id
				deal_id
				art {
					title
					imgUrl (thumbnailResolution: "128x128")
					is_verified
					artist {
						first_name
						last_name
					}
					is_active
					formatted_title 	
					year
					artist {
						id
						first_name
						last_name
					}
				}
				deal {
					id
					is_private
					is_gallery_private
					created_at
					code_name
					salesperson {
						...userData
						is_lead
						is_deleted
					}
				}
				end_at
			}
			cursor
			totalItems
		}
	}
	${userData}
`

export const GET_ACTIVE_DEALS = gql`
	query getMyActiveDeals($cursor: Int) {
		getMyActiveDeals(cursor: $cursor) {
			items {
				start_at
				offer_amount
				deal_entry_status_id
				status_note
				offer_amount
				art {
					id
					imgUrl (thumbnailResolution: "128x128")
					title
					formatted_title 
					year
					is_private
					code_name
					created_at
					artist {
						id
						first_name
						last_name
					}
				}
				deal {
					id
					type_id
					is_private
					is_gallery_private
					created_at
				}
				offer_currency {
					symbol
				}
				deal_entry_status {
					value
					workflow_end
					id
				}
			}
			cursor
			totalItems
		}
	}
`
