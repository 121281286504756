import { Checkbox, FormControl, FormControlLabel } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { CancelButton, SubmitButton } from '../input/Buttons'
import { MobileDatePicker } from '@mui/x-date-pickers-pro'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { DispatchContext } from '../store'
import { useLazyQuery } from '@apollo/client'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { GET_DEAL_INFO_EXPORT } from './Queries'
import Label from '../input/Label'
import TextBox from '../input/Text/TextBox'


const fields = [
	'Art Status',
	'Artist',
	'Title',
	'Date',
	'Medium',
	'Dimensions',
	'Signed',
	'Edition',
	'Retail Value',
	'Gallery Contacts (Artwork)',
	'Inventory No',
	'Primary Contact Status',
	'Primary Contact Grade',
	'Primary Contact Types',
	'Primary Contact First Name',
	'Primary Contact Last Name',
	'Primary Contact Title',
	'Primary Contact Company',
	'Gallery Contacts (Contact)',
	'Primary Contact City',
	'Primary Contact State',
	'Primary Contact Country',
	'Primary Contact Interests',
	'Phones',
	'Emails',
	'Other Contacts',
	'Gallery Contacts (Deal)',
	'Deal Status',
	'Deal Status Note',
	'Start at',
	'Reserve Expiration',
	'Reserve Queue Position',
	'End at',
	'Offer Price',
	'Client Interested',
	'Counter Offer Price',
	'Sale Price',
	'Notes'
]

export default function ExportDealInfoModal (props) {

	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({type: 'openSnackBar', payload: {severity, text}})
	}

	const dealTypes = //lookup.data?.getDealTypes ?? []
		[
			'Offer',
			'Reserves',
			'Sold',
			'Loan In',
			'Loan Out',
			'Consignment In',
			'Consignment Out'
		]

	const [input, setInput] = useState({
		from: null,
		to: null,
		types: {
			Offer: true,
			Reserves: true,
			Sold: true,
		}
	})

	const [columns, setColumns] = useState(JSON.parse(localStorage.getItem('ExportDealInfo.columns')) ||
		fields.reduce((acc, el) => {
			acc[el] = true
			return acc
		},{}))

	// first load - update localstorage if need be.
	useEffect(() => {
		setColumns(columns => {
			Object.keys(columns).forEach(entry => {
				if (!fields.includes(entry)) {
					delete columns[entry]
				}			})
			return columns
		})
	}, [])

	useEffect(() =>
		localStorage.setItem('ExportDealInfo.columns', JSON.stringify(columns)),
	[columns])
	
	
	const [getExport, {loading}] = useLazyQuery(GET_DEAL_INFO_EXPORT, {
		onCompleted: data => {
			if (data?.getDealInfoExport?.length) {
				const a = document.createElement("a")
    		document.body.appendChild(a)
				a.style = "display: none"
				const buf = new ArrayBuffer(data.getDealInfoExport.length) //convert to arrayBuffer
				const view = new Uint8Array(buf)  //create uint8array as viewer
				for (let i = 0; i < data.getDealInfoExport.length; i++)
					view[i] = data.getDealInfoExport.charCodeAt(i) & 0xFF //convert to octet
				const blob = new Blob([buf], { type: "application/octet-stream" })
				const url = window.URL.createObjectURL(blob)
				a.href = url
				a.download = 'dealinfoexport.xlsx'
				a.click()
				window.URL.revokeObjectURL(url)
				openSnackbar(severity.SUCCESS, `Download complete.`)
				props.close()
			} else {
				console.error(data)
				const message = data?.getDealInfoExport?.message ?? 
					'There was an error generating the Export.'
				openSnackbar(severity.ERROR, message)
				props.close()
			}
		}
	})
	

	const getExportClicked = () => {
		let art = props.rows
			.filter(row => row.__typename === "Art")
			.map(art => art.id)

		const args = {
			...input,
			exportType: 'exportDealInfo',
			art,
			types: Object.entries(input.types)
				.filter(([key, val]) => val)
				.map(([key, val]) => key),
			columns: Object.entries(columns)
				.filter(([key, val]) => val)
				.map(([key, val]) => key)
		}

		openSnackbar(severity.INFO, `Downloading. Please wait.`)
		getExport({ variables: { args } })
	}


	return <TransitionsModal
		className="export-deal-info-modal"
		open={props.open}
		close={props.close}
	>
		<div>
			<div style={{display: 'flex'}}>
				<FormControl sx={{marginRight: '.5em', flexGrow: 1}}>
					<Label
						id="date-label"
						style={{ fontWeight: 500 }}
						disableAnimation
						shrink
					>
					From
					</Label>

					<MobileDatePicker
						inputFormat="MMM do, yyyy"
						disableMaskedInput={true}
						cancelText={null}
						name="from-date"
						inputVariant="outlined"
						style={{ marginTop: '17px' }}
						className="MUIDatePicker"
						variant="dialog"
						showTodayButton
						todayLabel="Today"
						renderInput={({ inputRef, inputProps, InputProps }) => {
								
							const newProps = { ...inputProps}

							newProps.readOnly = false

							return (
								<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
							)}}
						value={input.from}
						onChange={(date) => {
							setInput({
								...input,
								from: date
							})
						}}
					/>
				</FormControl>
			
				<FormControl sx={{marginLeft: '.5em', flexGrow: 1}}>
					<Label
						id="date-label-to"
						style={{ fontWeight: 500 }}
						disableAnimation
						shrink
					>
					To
					</Label>

					<MobileDatePicker
						inputFormat="MMM do, yyyy"
						disableMaskedInput={true}
						cancelText={null}
						name="from-date"
						inputVariant="outlined"
						style={{ marginTop: '17px' }}
						className="MUIDatePicker"
						variant="dialog"
						showTodayButton
						todayLabel="Today"
						renderInput={({ inputRef, inputProps, InputProps }) => {
								
							const newProps = { ...inputProps}

							newProps.readOnly = false

							return (
								<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
							)}}
						value={input.to}
						onChange={(date) => {
							setInput({
								...input,
								to: date
							})
						}}
					/>
				</FormControl>
			</div>
			<h3>Deal Types</h3>
			<div style={{
				display: 'flex',
				flexFlow: 'column wrap',
				height: '6em',
				alignContent: 'space-between'
			}}>
				{
					dealTypes.map(label =>
						<FormControlLabel
							key={label}
							control={<Checkbox
								checked={input.types[label] ?? false}
								name={label}
								onChange={(e) => {
									setInput({
										...input,
										types: {
											...input.types,
											[e.target.name]: e.target.checked
										}
									})
								}}
							/>}
							label={label}
						/>
					)}
			</div>

			<h3>Columns</h3>
			<div style={{
				display: 'flex',
				flexFlow: 'column wrap',
				height: '17em',
				width: '50vw',
				overflowX: 'auto'
			}}>
				{fields.map(column => <FormControlLabel
					key={column}
					control={<Checkbox
						checked={columns[column] || false}
						name={column}
						value={column}
						onClick={() => {
							const checked = columns[column]
							setColumns({
								...columns,
								[column]: !checked
							})
						}}
					/>}
					label={column}
				/>)}
			</div>
			<div style={{
				width: '100%',
				display: 'flex',
				justifyContent: 'space-between',
				paddingTop: '1em'
			}}>
				<CancelButton
					variant="contained"
					onClick={props.close}
				>
					Cancel
				</CancelButton>
				<SubmitButton
					style={{ marginLeft: '1em' }}
					variant="contained"
					onClick={getExportClicked}
					disabled={loading}
				>
					Download
				</SubmitButton>
			</div>
		</div>
	</TransitionsModal>
}
