/* eslint-disable react-hooks/exhaustive-deps */
import { Undo } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Button, IconButton, Menu, MenuItem, Paper, CircularProgress } from '@mui/material'
import {
	DataGridPro, GridToolbarColumnsButton,
	GridToolbarContainer, GridToolbarDensitySelector
} from '@mui/x-data-grid-pro'
import clsx from 'clsx'
import { useEffect, useState, useCallback, useContext } from 'react'
import { elementReady, getListHeader, restoreColumnSettings } from '../../common/helpers'
import DoneIcon from '@mui/icons-material/Done'
import Thumbnail from '../../Thumbnail/Thumbnail'
import { ChooseImageButton } from '../../input/Buttons'
import { fetchWithAuth } from '../../common/helpers'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'

function arraymove(arr, fromIndex, toIndex) {
	const element = arr[fromIndex]
	arr.splice(fromIndex, 1)
	arr.splice(toIndex, 0, element)
}

function getCols({
	loading,
	actionMenu, 
	density
}) {
	/** @type import('@mui/x-data-grid-pro').GridColDef[] */
	const columns = [
		{
			field: 'Actions',
			headerName: '',
			minWidth: 50,
			width: 50,
			resizable: false,
			sortable: false,
			renderCell: actionMenu
	  	},
		{
			field: 'full_name',
			headerName: 'Name',
			width: 250,
			renderCell: (params) => {

				const row = params.row

				return (
					<Thumbnail
						name={(!row.disabled) ? `${row.full_name}` : null}
						detail=" "
						avatar={row.imgUrl}
						type='contact'
						animation={loading ? 'wave' : false}
					></Thumbnail>
				)
			}
		},
		{
			field: 'is_active',
			headerName: 'Active',
			renderCell: (params) => {
				if(params.row.is_deleted)
					return '-'
				else return <DoneIcon/>
			}
		},
		{
			field: 'title',
			headerName: 'Title',
			type: 'string',
			width: 250
		},
		{
			field: 'department',
			headerName: 'Department',
			type: 'string',
			width: 200
		},
		{
			field: 'gallery',
			headerName: 'Gallery',
			type: 'string',
			width: 150
		},

	]
	return columns
}

/**
 * @typedef UsersDataGridProps
 * @property {Object[]} rows All rows of {Useres} to be displayed
 * @property {function} setRows
 * @property {boolean} loading If a loading is in progress
 * @property {function} saveAndGo Function called on row doubleClick
 * @property {string} mainSearchPage
 * @property {filterComparator} userId
 * @property {Object} selectedRow
 * @property {callback} onSelect
 * @property {filterComparator} totalItems
 * @property {number} limit
 * @property {Object} variables
 * @property {function} setLimit
 * @property {number} activeStep
 * @property {function} setActiveStep
 * @property {Object} sortModel
 * @property {function} onSortModelChange
 * @property {boolean} noScrollToTop do not scroll to top of page on pagination
 */

/**
 * @param {UsersDataGridProps} props
 */
export function UsersDataGrid (props) {

	const columnSettingsKey = 'UsersDataGrid'

	// handle null response.
	const rows = props.rows || []

	const [density, setDensity] = useState(
		localStorage.getItem(`${columnSettingsKey}.density`) || 'comfortable')
	const [googleLoading, setGoogleLoading] = useState(false)
	useEffect(() => localStorage.setItem(`${columnSettingsKey}.density`, density),
		[density])

	const [columnVisibilityModel, setColumnVisibilityModel] = useState(
		JSON.parse(localStorage.getItem(`${columnSettingsKey}.visibility`) || null)
	)
	useEffect(() => localStorage.setItem(`${columnSettingsKey}.visibility`, JSON.stringify(columnVisibilityModel)),
		[columnVisibilityModel])

	const [currentUser, setCurrentUser] = useState(null)

	const openContextMenu = (field) => (e) => {
		e.preventDefault()
		e.stopPropagation()
		setAnchorEl(e[field])
	}

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const actionMenu = params => {
		if (params.row.is_private && !params.row.created_at) {
			return null
		}

		return <IconButton
			aria-label="More"
			style={{
				padding: '6px',
				marginRight: '-9px',
			}}
			onClick={e => {
				if (params.row.disabled || params.row.hidden) return
				setCurrentUser(params.row)
				openContextMenu('currentTarget')(e)
			}}
		>
			<MoreVertIcon
				style={ params.row.isPrivate ? { color: 'white' } : null }
			/>
		</IconButton>
	}

	const [anchorEl, setAnchorEl] = useState(null)
	const [columns, setColumns] = useState([])

	// Set up grid with loaded dependencies, and saved data.
	const updateColumns = () => {

		const cols = getCols({
			loading: props.loading,
			actionMenu, 
			density
		})

		// restore on load.
		const columnSettings = JSON.parse(localStorage.getItem(columnSettingsKey))
		if (columnSettings?.length) restoreColumnSettings(cols, columnSettings)

		if (!columnVisibilityModel) {
			const obj = cols.filter(c => c.hide)
				.reduce((acc, el) => {
					acc[el.field] = false
					return acc
				}, {})
			setColumnVisibilityModel(obj)
		}

		setColumns([...cols])
	}

	// on Load, load column settings, sort, merge in (once everything is loaded.)
	useEffect(() => {
		if (props.userId) updateColumns()
	}, [props.userId, density])

	// update the flag menu/toggle any time the props change
	// to prevent "stale closures"
	useEffect(() => {
		setColumns(cols => {
			return [...cols]
		})
	}, [props.variables, props.totalItems, props.userId, density])

	// on unmount, stash columns back in storage. (eventually in DB)
	useEffect(() => {

		// stash on nav. (`columns` needs to be in the deps in order to be accessed.)
		return () => {
			if (columns.length) {
				const columnSettings = columns.map(col => ({
					field: col.field,
					width: col.width,
					minWidth: col.minWidth,
					hide: col.hide
				}))
				localStorage.setItem(columnSettingsKey, JSON.stringify(columnSettings))
			}
		}
	}, [columns])

	const handleClose = () => setAnchorEl(null)
		
	const resetGrid = () => {
		localStorage.removeItem(columnSettingsKey)
		localStorage.removeItem(`${columnSettingsKey}.visibility`)
		setColumnVisibilityModel(null)
		updateColumns()
	}

	const CustomToolBar =  () => <GridToolbarContainer>
		<Button
			size="small"
			startIcon={<Undo />}
			onClick={resetGrid}
		>
			Reset
		</Button>
		<GridToolbarColumnsButton />
		<GridToolbarDensitySelector />
	</GridToolbarContainer>

	// Scroll to the top of current data grid component when paginating
	useEffect(() => {

		if (props.loading === false && !props.scrollIntoView) {

			elementReady(`.${columnSettingsKey}`)
				.then(searchList => 
					searchList.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					})
				)
		}

	}, [props.activeStep])

	return <>
		<Paper
			className={clsx({
				'data-grid-padding': true,
				[columnSettingsKey]: true
			})}
			data-testid={`card-${props.listHeader ? props.listHeader : "Results "}`}
			style={{
				...props.style,
				flexGrow: 1 
			}}
		>
			<h1 className="card-title" style={{
				paddingTop: '1em',
				paddingLeft: '2em',
				display: "flex",
			}}>
				{getListHeader(props.listHeader, props.totalItems, props.canSee)}
				<ChooseImageButton
					data-testid="sync-button"
					variant="contained"
					size="small"
					style={{backgroundColor: !props.loading ? '#4465D1' : null, zIndex:100}}
					onClick={() => {
						setGoogleLoading(true)
						fetchWithAuth( "/api/googleSync" )
							.then(resp => resp.json())
							.then(data => {
								setGoogleLoading(false)
								openSnackbar(severity.SUCCESS, `Success! ${data.created} users created.`)
							})
							.catch(err => {
								setGoogleLoading(false)
								openSnackbar(severity.ERROR, `An error occured during user sync.`)
								console.error(err)
							})
					}}
					disabled={googleLoading}
				>
					Google Sync
				</ChooseImageButton>
				{googleLoading ? <CircularProgress size={30} style={{marginLeft: "1em"}}/> : null}
			</h1>

			<div style={{ height: 'calc(100% - 4em)', width: '100%' }}>	
				<DataGridPro
					components={{ Toolbar: CustomToolBar }}
					rowCount={props.totalItems || 0}
					rows={rows} 
					columns={columns}
					onRowDoubleClick={({row}) => {
						props.saveAndGo(`/admin/users/${row.id}/profile`, row.id)
					}}
					density={density}
					disableColumnMenu
					pagination
					paginationMode="server"
					sortingMode="server"
					pageSize={props.limit}
					onPageSizeChange={props.setLimit}
					rowsPerPageOptions={[10, 25, 50]}
					onSelectionModelChange={(newSelectionModel) => {
						// eslint-disable-next-line eqeqeq
						props.onSelect(rows.find(row => row.id == newSelectionModel[0]))
					}}
					selectionModel={props.selectedRow?.id ? [props.selectedRow?.id] : []}
					page={props.activeStep}
					onPageChange={props.setActiveStep}
					onSortModelChange={props.onSortModelChange}
					loading={props.loading}
					onColumnVisibilityModelChange={setColumnVisibilityModel}
					columnVisibilityModel={columnVisibilityModel || {}}
					onColumnOrderChange={({ oldIndex, targetIndex }) => {
						arraymove(columns, oldIndex, targetIndex)
						setColumns([...columns])
					}}
					onColumnWidthChange={({ colDef, width}) => {
						columns.find(col => col.field === colDef.field).width = width
						setColumns([...columns])
					}}
					onStateChange={(args) => setDensity(args.density?.value ?? 'comfortable')}
				/>
			</div>

			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={!!anchorEl}
				onClose={handleClose}
			>
				<MenuItem onClick={(e) => {
					props.saveAndGo(`/admin/users/${currentUser.id}/profile`, currentUser.id)
				}}>Profile</MenuItem>
				<MenuItem onClick={(e) => {
					props.saveAndGo(`/admin/users/${currentUser.id}/permissions`, currentUser.id)
				}}>Permissions</MenuItem>
				<MenuItem onClick={(e) => {
					props.saveAndGo(`/admin/users/${currentUser.id}/datalog`, currentUser.id)
				}}>Data Log</MenuItem>
			</Menu>
		</Paper>

	</>
}
