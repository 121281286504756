import React from 'react'
import { GET_ARTIST_DATALOG } from '../Queries'
import DataLog from '../../DataLog/DataLog'

export default function ArtistDataLog(props) {

	return (!props.findMode && props.id > 0) && (
		<DataLog
			query={GET_ARTIST_DATALOG}
			id_field={"artist_id"}
			queryName={"getArtistDataLog"}
			parentQueryName={"getArtist"}
			{...props}
		/>
	)
}
