import { gql } from '@apollo/client'
import { imageData, userDataCompressed } from '../../../common/fragments'

export const GET_LISTING_NOTES = gql`
	query getListingNotes(
		$listing_id: ID
		$thumbnailResolution: String = "128x128"
	) {
		getListingNotes(input: { listing_id: $listing_id }) {
			id
			type_id
			applied_at
			applied_user_id
			note_text
			is_critical
			is_private
			is_deleted
			created_by
			created_at
			modified_by
			modified_at
			user {
				...userDataCompressed
				is_deleted
			}
			images {
				...imageData
			}
		}
	}
	${imageData}
	${userDataCompressed}
`

export const CREATE_NOTE = gql`
	mutation createListingNote(
		$CreateNoteInput: CreateNoteInput!
		$thumbnailResolution: String = "128x128"
	) {
		createListingNote(input: $CreateNoteInput) {
			code
			success
			message
			note {
				id
				type_id
				applied_at
				applied_user_id
				note_text
				created_by
				created_at
				modified_by
				modified_at
				is_critical
				is_private
				is_deleted
				user {
					...userDataCompressed
					is_deleted
				}
				images {
					...imageData
				}
			}
		}
	}
	${imageData}
	${userDataCompressed}
`

export const LISTING_UPDATE_NOTE = gql`
	mutation updateListingNote(
		$UpdateNoteInput: UpdateNoteInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateListingNote(input: $UpdateNoteInput) {
			code
			success
			message
			note {
				id
				type_id
				applied_at
				applied_user_id
				note_text
				is_critical
				is_private
				is_deleted
				created_by
				created_at
				modified_by
				modified_at
				user {
					...userDataCompressed
					is_deleted
				}
				images {
					...imageData
				}
			}
		}
	}
	${imageData}
	${userDataCompressed}
`
