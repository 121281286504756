import withStyles from '@mui/styles/withStyles'
import InputBase from '@mui/material/InputBase'
import * as Colors from '../../styles/colors/Colors'
import React from "react"

const TextBoxStyled = withStyles(theme => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(3),
		},
	},
	input: {
		borderRadius: '0px 4px 4px 0px',
		position: 'relative',
		border: '1px solid #ced4da',
		borderLeft: 'none',
		fontSize: 16,
		padding: '10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: 'Graphik',
		'&:focus': {
			borderRadius: '0px 4px 4px 0px',
			borderColor: Colors.editAdd,
		},
	},
	error: {
		border: '1px solid red',
		borderRadius: '0px 4px 4px 0px',
	}
}))(InputBase)

const EndTextBox = React.forwardRef((props, ref) => {

	const shouldFocus = props.focus

	return (
		<TextBoxStyled ref={ref} {...props} focused={shouldFocus ? true : undefined} disabled={props.disabled} sx={props.sx}/>
	)}
)


export default EndTextBox
