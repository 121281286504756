/* eslint-disable eqeqeq */
import { AuthStateContext, DispatchContext } from '../store'
import { UPDATE_SELF, UPDATE_USER } from './Queries'
import { LookupContext } from '../store'
import makeStyles from '@mui/styles/makeStyles'
import { ResetButton } from '../input/Buttons'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { IconButton, Skeleton } from '@mui/material'
import { TOGGLE_USER } from './Queries.js'
import { useMutation } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import classNames from 'classnames'
import ConfirmationDialog from '../navigation/ConfirmationDialog/ConfirmationDialog'
import PhoneInput from 'react-phone-input-2'
import QuickView from '../QuickView/QuickView'
import React, { useContext, useEffect, useCallback } from 'react'
import AvatarInput from '../common/AvatarInput'
import { findModeFieldsAtom, FindModeInput } from "../navigation/Tabs/TabbedPage"
import { useRecoilCallback } from 'recoil'
import { useNavigate, useLocation } from "react-router-dom"
import { Close } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
	large: {
		width: 210,
		height: 210,
	},
}))

/**
 * @typedef UserQuickView
 * @property {import('../User').User} user
 */

/**
 * @param {UserQuickView} props
 */
function UserQuickView(props) {


	const lookup = useContext(LookupContext)
	const departments = lookup.data?.getDepartmentTypes

	const authState = useContext(AuthStateContext)
	const location = useLocation()

	const onAdminConsole = props.atAdminConsole || location.pathname.includes('admin')

	const borderColor = onAdminConsole ? '1px solid #3b3b3b' : '1px solid transparent'
	const textColor = onAdminConsole ? '#adc7e6' : 'rgb(0, 0, 238)'

	const classes = useStyles()

	const [user, setUser] = React.useState(
		props.user || props.state?.getUser || props.state?.adminGetUser || null)
	const [toggleUserDialog, setToggleUserDialog] = React.useState(false)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	// Loading Logic
	const [loading, setLoading] = React.useState(true)
	useEffect(() => {
		if (Object.keys(props?.state?.getUser || 
				props?.state?.adminGetUser || props.user || {}).length) {
			setLoading(false)
		} 
	}, [props])

	const [toggleUserActivation] = useMutation(TOGGLE_USER, {
		onError: (error) => {
			console.log(error)
			// On failure, reset userInput state, don't touch user state and show error
			openSnackbar(severity.ERROR, 'Could not toggle User.')
		},
		onCompleted: (response) => {
			if (response.toggleUserActivation?.success === true) {
				// Success
				openSnackbar(severity.SUCCESS, response.toggleUserActivation.message)
				// On success change user state
				setUser({
					...user,
					is_deleted: !user.is_deleted,
				})

				// Only passed up for the main page, not for the tabbed pages.
				props.setUser && props.setUser({
					...props.user,
					is_deleted: !user.is_deleted
				})

			} else {
				// On failure, reset userInput state, don't touch user state and show error
				openSnackbar(severity.ERROR, response.toggleUserActivation.message)
			}
		},
	})

	useEffect(() => {
		if (props.user) setUser(props.user)
		if (props.state?.getUser) setUser(props.state?.getUser)
		if (props.state?.adminGetUser) setUser(props.state?.adminGetUser)
	}, [props.state, props.user])

	// Class names for phone flag label
	var trClasses = classNames({
		'plain-text': true,
		'quickview-flag': true,
	})

	const [updateUserAdmin] = useMutation(UPDATE_USER)
	const [updateUser] = useMutation(UPDATE_SELF)

	useEffect(() => {
		if (location.pathname === ('/admin') && user === null) {
			setUser(authState.user)
			setLoading(false)
		}
	}, [authState.user, location.pathname, user])

	const getUserQVStyle = (user) => {
		if (user?.is_deleted) return { boxShadow: '0 0 0 2pt var(--decline)' }
		return null
	}

	const navigate = useNavigate()

	// handle FindMode search
	const handleKeyDown = useRecoilCallback(({ snapshot }) => async (e) => {
		if (!props.findMode || e.key !== 'Enter') return
		e.stopPropagation()
		let fields = await snapshot.getPromise(findModeFieldsAtom)
		fields = Object.fromEntries(Object.entries(fields).filter(([_, v]) => v != null && v !== ''))
		navigate('/admin/users', { state: { fields }})
	})

	const avatarUser = !props.findMode ? user : ({ created_at: true })

	return (
		<>
			<QuickView className="quick-view-main" style={getUserQVStyle(user)}>

				<div className="quick-view-inner" onKeyDown={handleKeyDown}>

					{!props.findMode ?
						<header>
							<h2 className="quick-view-title" 
								style={{ display: 'flex', justifyContent: 'space-between' }} 
								data-testid="search-qv-header"
							>
								{user?.first_name && user.last_name ? user?.first_name + ' ' + user?.last_name : <Skeleton animation={!user ? 'wave' : false} variant="text" width="8em" />}
								{props.onClose && !loading && <span>
									<IconButton size="large" 
										sx={{ height: '35px', width: '35px' }}
										onClick={props.onClose}
									>
										<Close sx={{ color: 'white' }} />
									</IconButton>
								</span>}
							</h2>

							<h3 className="paragraph-1" style={{ fontWeight: 400 }}>
								{user?.title || <>{loading ? <Skeleton animation={!user ? 'wave' : false} variant="text" width="12em" /> : <span>-</span>}</>}
							</h3>
						</header>
						: 
						<FindModeInput 
							field="full_name" 
							placeholder="Full Name" 
							style={{ width: '100%', marginBottom: '1em' }}
							largeInput
						/> 
					}

					<AvatarInput
						title={avatarUser?.first_name + ' ' + avatarUser?.last_name}
						className={classes.large}
						alt={avatarUser?.first_name + ' ' + avatarUser?.last_name}
						src={avatarUser?.imgUrl}
						editable={!props.findMode}
						oldProfileLink={[avatarUser?.profile_link]}
						inputName={onAdminConsole ? "UserInput" : "SelfInput"}
						entityId={avatarUser?.id}
						mutation={onAdminConsole ? updateUserAdmin : updateUser}
						mutationName={onAdminConsole ? "updateUser" : "updateSelf"}
						setEntity={setUser}
						entity={avatarUser}
						entityName="user"
						onAdminConsole={onAdminConsole}
					/>
					<table className="quickview-main">
						<tbody>
							<tr>
								<th>Gallery</th>
								{!props.findMode ?
									<td>{user?.gallery || <>{loading ? <Skeleton animation={!user ? 'wave' : false} variant="text" width="1em" /> : <span>-</span>}</>}</td>
									:
									<td>
										<FindModeInput 
											field="gallery" 
											style={{ width: '100%', marginTop: '1em' }}
											largeInput
										/>
									</td>
								}
							</tr>
							<tr>
								<th>Department</th>
								{!props.findMode ?
									<td>
								
										{departments?.find((e) => e.id == user?.department_id)?.value || (
											<>{loading ? <Skeleton animation={!user ? 'wave' : false} variant="text" width="1em" /> : <span>-</span>}</>
										)}
									</td>
									:
									<td>
										<FindModeInput 
											field="department" 
											style={{ width: '100%', marginTop: '1em' }}
											largeInput
										/>
									</td>
								}
							</tr>
						</tbody>
					</table>

					{!props.findMode ?
						<>
							<h3 className="paragraph-2">Contact Information</h3>
							<table id="contact-table" className="shadow-group contact-information">
								<tbody>
									<tr className={trClasses}>
										<th style={{ width: '6em' }}>{user?.phone_label1 || 'Phone 1'}</th>
										<td>
											{user && user?.phone_number1 ? (
												<a href={user?.phone_number1 ? 'tel:' + user.phone_number1 : null}>
													<PhoneInput
														disabled
														placeholder=""
														inputStyle={{
															height: '1.6em',
															color: textColor,
															textDecoration: 'underline',
															cursor: 'pointer',
															fontFamily: 'graphik',
														}}
														value={user.phone_number1 || ''}
													/>
												</a>
											) : (
												<>{loading ? <Skeleton animation={!user ? 'wave' : false} variant="text" width="9em" /> : <span>-</span>}</>
											)}
										</td>
									
									</tr>
									<tr className={trClasses}>
										<th style={{ width: '6em' }}>{user?.phone_label2 || 'Phone 2'}</th>
										<td>
											{user && user?.phone_number2 ? (
												<a href={user.phone_number2 ? 'tel:' + user.phone_number1 : null}>
													<PhoneInput
														disabled
														placeholder=""
														inputStyle={{
															height: '1.6em',
															color: textColor,
															textDecoration: 'underline',
															cursor: 'pointer',
															fontFamily: 'graphik',
														}}
														value={user.phone_number2 || ''}
													/>
												</a>
											) : (
												<>{loading ? <Skeleton animation={!user ? 'wave' : false} variant="text" width="9em" /> : <span>-</span>}</>
											)}
										</td>
									
									</tr>
									<tr>
										<th style={{ borderTop: borderColor }}>Email</th>
										<td style={{ borderTop: borderColor }}>
											{user && user?.email ? (
												<a href={'mailto:' + user.email} style={{ color: textColor }}>
													{user.email}
												</a>
											) : (
												<>{loading ? <Skeleton animation={!user ? 'wave' : false} variant="text" width="10em" /> : <span>-</span>}</>
											)}
										</td>
									
									</tr>
								</tbody>
							</table>

							{ /* We do not allow users to deactivate themselves */ }
							{onAdminConsole && user?.id !== authState?.user?.id ? (
								<div style={{ position: 'absolute', bottom: '2em' }}>
									<ResetButton
										style={{
											width: '21em',
											backgroundColor: user?.is_deleted ? 'var(--accept-changes)' : null,
										}}
										onClick={(e) => {
											setToggleUserDialog(true)
										}}
									>
										{user?.is_deleted ? 'ACTIVATE USER' : 'DEACTIVATE USER'}
									</ResetButton>
								</div>
							) : null}

						</>
						: null}

				</div>
			</QuickView>

			<ConfirmationDialog
				open={toggleUserDialog && onAdminConsole}
				handleClose={() => setToggleUserDialog(false)}
				title={'Are you sure?'}
				acceptText={user?.is_deleted ? 'ACTIVATE' : 'DEACTIVATE'}
				text={(user?.is_deleted ? 'Activate ' : 'Deactivate ') + user?.first_name + ' ' + user?.last_name}
				onYes={() => {
					toggleUserActivation({
						variables: {
							UserInput: {
								id: user?.id,
								is_deleted: !user?.is_deleted,
							},
						},
					})
				}}
			/>
		</>
	)
}

export default withApollo(UserQuickView)
