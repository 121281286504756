/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { AuthStateContext, DispatchContext } from '../../store'
import { UPDATE_DEAL } from '../Queries'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import PrivateAccessDetails from '../../PrivateAccess/PrivateAccessDetails'
import PrivateAccessUsers from '../../PrivateAccess/PrivateAccessUsers'
import { withApollo } from '@apollo/client/react/hoc'
import { GET_USERS_WITH_ACCESS } from '../../QuickView/Queries'
import { permissions, permissionValues } from '../../constants/permissions'

function DealAccess(props) {
	let { loading, error } = props
	const [users, setUsers] = React.useState([])

	const [dealInput, setDealInput] = React.useState({})

	const authState = useContext(AuthStateContext)
	const userPermissions  = authState?.user?.permissions

	const resetDealInput = () => {
		setDealInput({
			id: props.state?.getDeal?.id,
			code_name: props.state?.getDeal?.code_name,
			is_private: props.state?.getDeal?.is_private,
			restrictedToUsersReplace: props.state?.getDeal?.restrictedToUsers,
			gallery_access: props.state?.getDeal?.gallery_access
		})
	}

	useEffect(() => {
		if (props.state?.getDeal) {
			resetDealInput()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.state])

	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	// eslint-disable-next-line no-unused-vars
	const [updateDeal, { loading: updateLoading, error: mutationError }] = useMutation(UPDATE_DEAL, {
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, 'An unknown error occurred.')
			// Reset State
			resetDealInput()
		},
		onCompleted: (response) => {
			if (response?.updateDeal?.success === true) {
				openSnackbar(severity[response.updateDeal.severity], response.updateDeal.message)
				props.setAtPrivateObject(response.updateDeal.deal.is_private)
				props.setState({ getDeal: {
					...props.state.getDeal,
					...response.updateDeal.deal
				} })
				getUsers(props.state.getDeal.id, 3)
			}
			if (response?.updateDeal?.success === false) {
				openSnackbar(
					severity.ERROR,
					"Could not update private details card."
				)
			}
		},
	})

	if (mutationError) {
		console.error(mutationError)
		openSnackbar(
			severity.ERROR,
			mutationError.message
		)
	}

	const getUsers = (entity_id, objectTypeId) => {
		props.client
			.query({
				query: GET_USERS_WITH_ACCESS,
				variables: {
					objectId: entity_id,
					objectTypeId,
				},
			})
			.then((result) => {
				setUsers(result?.data?.getUsersWithAccess?.users || [])
			})
			.catch((error) => {
				console.error(error)
				openSnackbar(severity.ERROR, 'Error retrieving users with access.')
			})
	}

	const update = (variables) => {

		// Check for admin console access, delete deal galleries if lacking
		const canViewAdminConsole = userPermissions
			?.find(e => e.permission_id == permissions.ADMIN_CONSOLE)
			?.permission_value_id == permissionValues.YES

		if (!canViewAdminConsole) {
			delete variables.gallery_access
		}

		updateDeal({ variables: { DealInput: variables } })
	}
		
	return (
		<>
			<PrivateAccessDetails
				loading={loading || updateLoading}
				error={error}
				input={dealInput}
				setInput={setDealInput}
				update={update}
				object="deal"
				disableCodename
				findMode={props.findMode}
				publicAccess={props.state?.getDeal?.publicAccess}
			></PrivateAccessDetails>

			<PrivateAccessUsers
				loading={loading || updateLoading}
				error={error}
				input={dealInput}
				setInput={setDealInput}
				entityType="deal"
				update={update}
				users={users}
				getUsers={getUsers}
				publicAccess={props.state?.getDeal?.publicAccess}
			></PrivateAccessUsers>
		</>
	)
}

export default withApollo(DealAccess)
