/* eslint-disable eqeqeq */
import { useMutation, useQuery } from "@apollo/client"
import { withApollo } from "@apollo/client/react/hoc"
import React, { useCallback, useContext, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import CollapsibleSearchCard from "../common/components/CollapsibleSearchCard"
import { SavedSearch } from "../common/SavedSearch"
import {
	DEFAULT_SEARCH_LIMIT, LISTING_SEARCH_OBJECT
} from "../constants/values"
import { DYNAMIC_LISTING_NAV } from "../navigation/Queries"
import useNavigation from "../navigation/useNavigation"
import { SELECT_ALL_LISTINGS } from "../Search/ActionButtons/Queries"
import GlobalSearchFilters, {
	getOperatorValue, initialFilterState
} from "../Search/GlobalSearchFilters"
import {
	DYNAMIC_LISTING_SEARCH, GET_LISTING_FILTER_FIELDS,
	GET_SEARCHES, LISTING_FLAG_QUERY, UPDATE_SEARCH
} from "../Search/Queries"
import SearchQuickView from "../Search/SearchQuickView"
import {
	filterMap, filterSearchTerms, handleDeleteSearch, updateLabel
} from "../Search/unifiedSearchHelpers"
import { severity } from "../Snackbar/CustomizedSnackbar"
import { AuthStateContext, DispatchContext } from "../store"
import CreateListingQuickView from "./CreateListingQuickView"
import ListingQuickView from "./Listing/ListingQuickView"
import { ListingsDataGrid } from "./ListingDataGrid"
import SearchListings from './SearchListings'

const Listings = (props) => {


	const { push: pushNav } = useNavigation()
	const navigate = useNavigate()
	const location = useLocation()
	const userAuthentication = useContext(AuthStateContext)

	const prevSearch = location.state

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const closeSnackbar = () => {
		dispatch({ type: 'closeSnackBar'})
	}

	// Mutations
	const [updateSearch] = useMutation(UPDATE_SEARCH)

	// State that comes from prevSearch
	const [order, setOrder] = React.useState(prevSearch?.order || "asc")
	const [orderBy, setOrderBy] = React.useState(prevSearch?.orderBy || null)
	const [listings, setListings] = React.useState(prevSearch?.listings ?? [])
	const [limit, setLimit] = React.useState(prevSearch?.limit ?? DEFAULT_SEARCH_LIMIT)
	const [activeStep, setActiveStep] = React.useState(prevSearch?.activeStep ?? 0)
	const [totalItems, setTotalItems] = React.useState(prevSearch?.totalItems ?? null)
	const [steps, setSteps] = React.useState(prevSearch?.steps ?? 1)
	const [selectedListing, setSelectedListing] = React.useState(null)
	const [advancedSearch, setAdvancedSearch] = React.useState(prevSearch?.advancedSearch ?? false)
	const [currentSearch, setCurrentSearch] = React.useState(prevSearch?.currentSearch ?? new SavedSearch({
		object_id: LISTING_SEARCH_OBJECT,
		search_terms: [],
		is_global: true,
		user_id: userAuthentication.user?.id
	}))

	// Normal State
	const [value, setValue] = React.useState(0)
	const [flaggedCount, setFlaggedCount] = React.useState(null)
	const [firstRender, setFirstRender] = React.useState(true)
	const [selection, setSelection] = React.useState([])
	const [savedSearches, setSavedSearches] = React.useState([])
	const [searchLoading, setSearchLoading] = React.useState(null)
	const [createListing, setCreateListing] = React.useState(null)


	const setFilters = (filters) => {
		setCurrentSearch({
			...currentSearch,
			search_terms: filters
		})
	}

	const currentFilters = [
		'modified_at',
		'isFavorite',
		'query',
		'start_at',
		'end_at',
		'showcase_ready',
		'gallery_contacts',
		'listing_type'
	]

	const advancedFilters = filterSearchTerms(currentSearch.search_terms)?.filter(term => {
		if (currentFilters.includes(term.field)) return false
		return true
	})


	useEffect(() => {

		if (currentSearch.search_terms.length == 0 && advancedSearch) {
			setCurrentSearch(new SavedSearch({
				object_id: LISTING_SEARCH_OBJECT,
				search_terms: [initialFilterState()],
				is_global: true,
				user_id: userAuthentication.user?.id
			}))
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSearch.search_terms, userAuthentication.user?.id, advancedSearch])

	// Search on change of order
	React.useEffect(() => {
		setFirstRender(false)
		if (listings)
			if (listings.length !== 0 && listings[0].disabled !== true && !firstRender) {
				handleSubmit()
			}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order, orderBy, limit, activeStep])

	/**
	 * Save current page state for if one returns to it through
	 * back arrow.
	 *
	 * @returns current page state
	 */
	function updateHistory (index) {
		const currentPageState = {
			currentSearch,
			orderBy,
			order,
			listings,
			limit,
			totalItems,
			steps,
			activeStep,
			advancedSearch,
			searchPage: location.pathname
		}
		const offset = index ? listings.findIndex(a => a.id == index) : listings.indexOf(selectedListing)
		const cursor = activeStep * limit + offset
		const variables = makeVariables(cursor, currentSearch, orderBy, order, currentSearch?.search_terms ?? [])
		variables.limit = 3
		currentPageState.variables = variables
		navigate(location.pathname, { state: currentPageState })
		return currentPageState
	}

	function saveAndGo(path, row) {
		// persist current page state in history
		const state = updateHistory(row)
		pushNav({
			url: location.pathname,
			state,
			query: DYNAMIC_LISTING_NAV
		}, state.variables?.cursor)
		// navigate to new page (with this page's query and `variables`)
		navigate(path, { state })
	}

	// Save search to state on selecting a Listing
	React.useEffect(() => {
		if (selectedListing) updateHistory()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedListing])


	// Get saved searches
	const {loading, error } = useQuery(GET_SEARCHES, {
		skip:  !userAuthentication.user?.id,
		variables: {
			userId: userAuthentication.user?.id,
			objectId: LISTING_SEARCH_OBJECT,
			global: true
		},
		onCompleted: (data) => {
			if (data && data.getSearches && data.getSearches?.length !== 0) {
				setSavedSearches(data.getSearches
					.map(search => new SavedSearch(search)))
			} else {
				// console.log("No saved searches were found.")
			}
		}
	})

	if (error) {
		console.log(error)
		openSnackbar(severity.ERROR, "Error retrieving your saved searches.")
	}


	const makeVariables = (cursor, currentSearch, orderBy, order, filters, limitOverride) => {
		return ({
			cursor,
			limit: limitOverride ?? limit,
			field: orderBy,
			direction: order.toUpperCase(),
			filters: filters ? filterMap(filters) : filterMap(currentSearch.search_terms)
		})
	}

	const handleSubmit = ({ cursor } = {}, filters = null, searchId) => {
		let submitCursor
		if (cursor == undefined) {
			submitCursor = activeStep * limit
		} else {
			submitCursor = cursor
		}
		setSearchLoading(true)
		openSnackbar(severity.INFO, "Loading search results...")

		const variables = makeVariables(
			submitCursor, currentSearch, orderBy, order, filters
		)
		setValue(1)

		// Set filters when handle submit is not coming from saved search list
		if (filters?.length && !searchId) setFilters(filters)

		props.client
			.query({ query: DYNAMIC_LISTING_SEARCH, variables })
			.then((result) => {

				const {data} = result

				if (data.searchDynamicListings?.items) {
					setListings(data.searchDynamicListings.items)

					if (data.searchDynamicListings.items < 1) setSteps(1)
					else setSteps((Math.ceil(data.searchDynamicListings.totalItems / limit)))

					setTotalItems(data.searchDynamicListings.totalItems || 0)
					setSearchLoading(false)
					if (!data.searchDynamicListings.totalItems) {
						openSnackbar(severity.WARNING, "There were no results.")
					} else {
						closeSnackbar()
					}

				} else {
					console.error(data)
					setSearchLoading(false)
					openSnackbar(severity.ERROR, "There was an error searching listings.")
				}
			})
			.catch((error) => {
				console.error(error)
				setSearchLoading(false)
				openSnackbar(severity.ERROR, "Could not search listings.")
			})

	}

	const handleReset = (page) => {

		setCurrentSearch(new SavedSearch({
			object_id: LISTING_SEARCH_OBJECT,
			search_terms: [],
			is_global: true,
			user_id: userAuthentication.user?.id
		}))
		setListings([])
		setCreateListing(false)
		setSteps(1)
		setActiveStep(0)
		setTotalItems(null)
		setOrder("asc")
		setOrderBy(null)
		setSelectedListing(null)
		setSearchLoading(false)
		setValue(0)
	}

	const updateListingSavedSearchLabel = (search, label) =>
		updateLabel(
			search,
			label,
			updateSearch,
			savedSearches,
			setSavedSearches,
			openSnackbar
		)

	const handleDeleteListingSavedSearch = (search) =>
		handleDeleteSearch(
			search,
			updateSearch,
			savedSearches,
			setSavedSearches,
			openSnackbar
		)

	// Get dynamic listing filter fields
	const { data: listingFilterFields, error: listingFilterError } = useQuery(GET_LISTING_FILTER_FIELDS, {
		fetchPolicy: 'cache-first',
		onCompleted: ({searchDynamicListingsFilterFields}) => {
			const storedFields = location?.state?.fields
			if (storedFields) {
				setAdvancedSearch(true)
				const fieldFilters = Object.entries(storedFields)
					.map(([field, value], i) => {
						const filterField = searchDynamicListingsFilterFields
							.find(f => f.name == field)
						let type = getOperatorValue(filterField?.type)
						return {
							field,
							value,
							type,
							id: new Date().getTime() + i
						}
					})

				setFilters(fieldFilters)
				handleSubmit({}, fieldFilters)
			}
		}
	})


	if (listingFilterError) {
		openSnackbar(severity.ERROR, 'There was an error retrieving filterable fields for this search.')
		console.error(listingFilterError)
	}

	const renderQuickView = () => {
		if (createListing) {
			return <CreateListingQuickView setCreateListing={setCreateListing} />
		}

		else if (selectedListing) {
			return <ListingQuickView
				id={selectedListing.id} 
				onClose={() => setSelectedListing(null)}
			/>
		}

		else {
			return (
				<SearchQuickView
					savedSearches={savedSearches}
					setSavedSearches={setSavedSearches}
					setCurrentSearch={setCurrentSearch}
					setFilters={setFilters}
					handleSubmit={handleSubmit}
					currentSearch={currentSearch}
					setAdvancedSearch={setAdvancedSearch}
					savedSearchesLoading={loading}
					updateLabel={updateListingSavedSearchLabel}
					handleDeleteSearch={handleDeleteListingSavedSearch}
					setSelection={setSelection}
					selection={selection}
					totalItems={totalItems}
					flaggedCount={flaggedCount}
					setFlaggedCount={setFlaggedCount}
					makeVariables={makeVariables}
					orderBy={orderBy}
					order={order}
					searchQuery={DYNAMIC_LISTING_SEARCH}
					entity="listing"
					bulkActionQuery={SELECT_ALL_LISTINGS}
					flagQuery={LISTING_FLAG_QUERY}
					setValue={setValue}
					value={value}
					filterFields={listingFilterFields?.searchDynamicListingsFilterFields}
					requery={handleSubmit}
				/>
			)
		}
	}

	const variables = makeVariables(0, currentSearch, orderBy, order)

	return (
		<>
			<section className="main-page">

				{ renderQuickView() }

				<div style={{
					display: 'flex',
					flexDirection: 'column'
				}}>
					<CollapsibleSearchCard>
						{advancedSearch ?
							<GlobalSearchFilters
								loading={searchLoading}
								currentSearch={currentSearch}
								setCurrentSearch={setCurrentSearch}
								onReset={handleReset}
								onSearch={handleSubmit}
								object_id={LISTING_SEARCH_OBJECT}
								advancedSearch={advancedSearch}
								setAdvancedSearch={setAdvancedSearch}
								setCreateEntity={setCreateListing}
								filterFields={listingFilterFields?.searchDynamicListingsFilterFields}
								typeName="Listing"
								onFindMode={() => navigate('/listings/findmode/details')}
								savedSearches={savedSearches}
								setSavedSearches={setSavedSearches}
								prevSearch={prevSearch?.currentSearch}
								
							/> :
							<SearchListings
								loading={searchLoading}
								currentFilters={currentSearch.search_terms}
								resetSearch={handleReset}
								currentSearch={currentSearch}
								setCurrentSearch={setCurrentSearch}
								handleSubmit={handleSubmit}
								setActiveStep={setActiveStep}
								setTotalItems={setTotalItems}
								advancedSearch={advancedSearch}
								setAdvancedSearch={setAdvancedSearch}
								setCreateListing={setCreateListing}
								advancedFilters={advancedFilters}
								selection={selection}
								setSelection={setSelection}
								onFindMode={() => navigate('/listings/findmode/details')}
								prevSearch={prevSearch?.currentSearch}
								savedSearches={savedSearches}
								setSavedSearches={setSavedSearches}
							></SearchListings>}
					</CollapsibleSearchCard>

					<ListingsDataGrid
						rows={listings}
						loading={searchLoading}
						saveAndGo={saveAndGo}
						setRows={setListings}

						setFlaggedCount={setFlaggedCount}
						userId={userAuthentication.user?.id}
						mainSearchPage={true}
						totalItems={totalItems}
						onSelect={setSelectedListing}
						selectedRow={selectedListing}
						limit={limit}
						setLimit={setLimit}
						activeStep={activeStep}
						setActiveStep={setActiveStep}

						sortModel={ orderBy ? [{ field: orderBy, sort: order }] : []}
						onSortModelChange={newSort => {
							if (newSort.length) {
								setOrderBy(newSort[0].field)
								setOrder(newSort[0].sort)
							} else {
								setOrderBy(null)
								setOrder('asc')
							}
						}}
						// flagmenu
						filterTo={newFilter => {
							setSelection([])
							setFilters(newFilter)
							handleSubmit({cursor: 0}, newFilter)
							setValue(1)
							setSelectedListing(null)
						}}
						type="Listing"
						variables={variables}
					/>
				</div>
			</section>
		</>
	)
}

export default withApollo(Listings)
