import { Box, FormControlLabel, Grid, Paper } from '@mui/material'
import React from 'react'
import NotificationToggle from '../../input/Toggle/NotificationToggle'
import { LightTooltip } from '../../styles/makesStyles'

interface UserNotificationsInnerProps {
	notifications: any[]
	allNotificationsToggle: boolean
	notificationsObject: any
	onAdminConsole: boolean
	callUpdateMutation: any
	userAuthentication: any
	category: string
}

const UserNotificationsInner = (props: UserNotificationsInnerProps) => {
	const { notifications, allNotificationsToggle, notificationsObject, onAdminConsole, callUpdateMutation, userAuthentication, category } = props

	return (
		<Grid item xs={2} sm={4} md={4}>
			<Paper
				style={{
					padding: '1em 2em',
					height: '100%',
				}}
			>
				<h1 className="card-title">{category}</h1>

				<Box
					paddingTop={1}
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
					}}
				>
					{notifications
						?.sort((a, b) => a.order - b.order)
						?.map((notification: any) => {
							const { value, label, description, key } = notification

							return (
								<LightTooltip
									key={key}
									placement="left"
									arrow
									title={
										allNotificationsToggle ? (
											<div style={{ fontSize: 14 }}>{description}</div>
										) : (
											<div style={{ fontSize: 14 }}>
												<b>Notifications are currently disabled.</b>
												<br />
												<div>{description}</div>
											</div>
										)
									}
								>
									<FormControlLabel
										disabled={!allNotificationsToggle || onAdminConsole}
										control={
											<NotificationToggle
												checked={value && allNotificationsToggle}
												onChange={(e: any) => {
													const newValue = e.target.checked

													callUpdateMutation({
														user_id: userAuthentication.user?.id,
														notifications_on: allNotificationsToggle,
														notifications: {
															...notificationsObject,
															[key]: {
																value: newValue,
															},
														},
													})
												}}
											/>
										}
										label={label}
										labelPlacement="start"
										style={{
											width: '-webkit-fill-available',
											display: 'flex',
											justifyContent: 'space-between',
											marginBottom: '1em',
											whiteSpace: 'pre-wrap',
										}}
									/>
								</LightTooltip>
							)
						})
					}
				</Box>
			</Paper>
		</Grid>
	)
}

export default UserNotificationsInner
