import React from 'react'
import "./SearchQuickView.css"
import { Paper, FormControl, Fade, Button} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Label from './../input/Label'
import TextBox from './../input/Text/TextBoxThin'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import ListItemIcon from '@mui/material/ListItemIcon'
import SaveIcon from '@mui/icons-material/Save'
import Tooltip from '@mui/material/Tooltip'

const useStyles = makeStyles(theme => ({
	item: {
		marginBottom: "1em",
		maxHeight: "5em",
		borderRadius: '6px'
	},
	newContactContainer: {
		marginLeft: "1em"
	},
}))

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false
}

/**
 * A representation of a QuickViewItem
 * @param {string=} title - The title of the item
 * @param {string=} subtitle - The sub-title of the item
 * @param {string=} color - The color of the item
 * @param {string=} count - The count of the item
 * @returns {JSX.Element}
 */
export default function QuickViewItem(props) {

	const classes = useStyles()
	const [state, setState] = React.useState(initialState)
	const [label, setLabel] = React.useState(props.title)

	const handleRightClick = event => {
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const handleClose = option => {
		if (option === "edit") {
			setState(Object.assign({}, initialState, { editable: true }))
		} else if (option === "delete") {
			handleDelete()
		} else {
			setState(initialState)
		}
	}

	const handleDelete = () => {
		handleClose()
		props.handleDeleteSearch(props.search)
	}

	const getQuickViewItemStyles = () => {
		let styles = {
			overflow: 'hidden'
		}

		if (!props.editable) 
			styles.cursor = 'pointer'

		if (props.selected) {
			styles.boxShadow = 'none'
			styles.border = "1.5px solid hsl(220, 100%, 77%)"
		}

		return styles
	}

	return <>
		<Paper
			className={classes.item}
			style={getQuickViewItemStyles()}
			aria-controls={props.title + '-menu'}
			aria-haspopup="true"
			onClick={!state.editable ? props.handleLeftClick : null}
			onContextMenu={!state.editable ? handleRightClick : null}
		>
			<ListItem style={{
				borderLeft: '1em solid ' + props.color
			}}>
				{state?.editable ? (
					<Fade in={state?.editable}>
						<FormControl>
							<TextBox
								id="saved-search-input"
								defaultValue={label}
								style={{ minWidth: '15.5em' }}
								onChange={e => {
									setLabel(e.target.value)
								}}
								onKeyPress={ev => {
									if (ev.key === 'Enter') {
										setState(initialState)
										props.updateLabel(
											props.search,
											label
										)
									}
								}}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											type="button"
											aria-label="cancel"
											style={{ padding: '0px' }}
											onClick={() => {
												setLabel(props.title)
												handleClose()
											}}
											size="large">
											<CloseIcon />
										</IconButton>
									</InputAdornment>
								}
							/>
							<Label
								disableAnimation
								shrink
								htmlFor="contact-first-name"
								style={{ paddingTop: '3.5em' }}
							>
                                Edit Label
							</Label>

							<Button
								style={{ visibility: 'hidden' }}
								type="submit"
							>
                                Submit
							</Button>
						</FormControl>
					</Fade>
				) : (
					<>
						<ListItemIcon>
							<SaveIcon style={{fill: props.color, fontSize: 30 }}/>
						</ListItemIcon>
						<Tooltip title={props.tooltip} enterDelay={500} arrow> 
							<ListItemText
								className="qv-card-ellipse"
								primary={label}
								secondary={props.subtitle}
							/>
						</Tooltip>
					</>
				)}
			</ListItem>
		</Paper>

		<Menu
			id={props.title + '-menu'}
			anchorReference="anchorPosition"
			open={state.mouseY !== null}
			onClose={handleClose}
			anchorPosition={
				state.mouseY !== null && state.mouseX !== null
					? { top: state.mouseY, left: state.mouseX }
					: undefined
			}
		>
			<MenuItem onClick={() => handleClose('edit')}>Rename</MenuItem>

			<MenuItem onClick={() => handleClose('delete')}>
                Delete
			</MenuItem>
		</Menu>
	</>
}
