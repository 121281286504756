import React, { useContext } from 'react'
import QuickView from '../../QuickView/QuickView'
import TextBox from '../../input/Text/TextBoxThin'
import Label from '../../input/Label'
import Dropdown from '../../input/Dropdown/Dropdown'
import { FormControl, Select, MenuItem } from '@mui/material'
import { SubmitButton, CancelButton } from '../../input/Buttons'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { DispatchContext } from '../../store'
import { useMutation } from '@apollo/client'
import makeStyles from '@mui/styles/makeStyles'
import { LookupContext } from '../../store'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { CREATE_ARTIST } from '../Queries'
import { useNavigate } from 'react-router-dom'
import { withApollo } from '@apollo/client/react/hoc'
import AvatarInput from '../../common/AvatarInput'

const useStyles = makeStyles(() => ({
	large: {
		height: 210,
		width: 210
	}
}))

const CreateArtistQuickView = (props) => {

	const lookup = useContext(LookupContext)
	const artistGenres = lookup.data?.getArtistGenres

	const classes = useStyles()
	const navigate = useNavigate()

	// Other useState hooks
	const [attempt, setAttempt] = React.useState(false)

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}
	const [ createArtist, {loading} ] = useMutation(CREATE_ARTIST)
	
	// Artist
	const [artist, setArtist] = React.useState({})

	return (
		<QuickView className="quick-view-main">
			<form
				className="create-artist-form"
				style={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%' }}
				onSubmit={(e) => {
					e.preventDefault()
					setAttempt(true)

					if ( attempt && (!artist.first_name || !artist.last_name )) {
						openSnackbar(severity.WARNING,'Please fill fields in red.')

					} else if (!(!artist.first_name || !artist.last_name )) {
						let variables = {
							first_name: artist.first_name,
							last_name: artist.last_name,
							genre_id: artist.genre_id,
							nationality: artist.nationality,
							birthplace: artist.birthplace,
							dates: artist.dates,
							profile_link: artist.profile_link
						}
			
						createArtist({ variables: { CreateArtistInput: variables } })
							.then((res) => {
								setAttempt(false)

								if (res.data.createArtist.success) {
									openSnackbar( severity.SUCCESS, 'Artist created successfully.')
									props.setCreateArtist(null)

									navigate(`/artists/${res.data.createArtist.artist.id}/details`)

								} else {
									openSnackbar( severity.ERROR, 'Could not create artist.')
								}							
							})
							.catch((error) => {
								console.log(error)
								openSnackbar( severity.ERROR, 'There was an error creating the artist.')
							})
					}
				}}
			>
				<div className="quick-view-inner">
				
					<h2 className="card-title" style={{
						alignSelf: 'flex-start',
					}}>Create New Artist</h2>

					<FormControl fullWidth>
						<Label disableAnimation shrink htmlFor="artist-first-name" error={attempt && !artist?.first_name}>
							First Name*
						</Label>

						<TextBox
							error={attempt && !artist?.first_name}
						
							id="artist-first-name"
							onChange={(e) => {
								setArtist({
									...artist,
									first_name: e.target.value
								})
							}}
							data-testid="artist-first_name"
						/>
				
					</FormControl>

					<FormControl fullWidth>
						<Label disableAnimation shrink htmlFor="artist-last-name" error={attempt && !artist?.last_name}>
							Last Name*
						</Label>
						
						<TextBox
							error={attempt && !artist?.last_name}
						
							id="artist-last-name"
							onChange={(e) => {
								setArtist({
									...artist,
									last_name: e.target.value
								})
							}}
							data-testid="artist-last_name"
						/>
				
					</FormControl>

					<AvatarInput
						style={{
							textAlign: 'center',
							marginTop: '1em',
						}}
						newEntity={true}
						className={classes.large}
						alt={artist?.first_name ? `${artist?.first_name} ${artist?.last_name}` : "New Artist"}
						src={artist?.imgUrl}
						editable={true}
						oldProfileLink={[]}
						inputName="CreateArtistInput"
						entityId={null}
						setEntity={setArtist}
						entity={artist}
						entityName="artist"
						creation={true}
					/>

					<FormControl fullWidth>
						<Label id="genre-id-label" disableAnimation shrink >
							Genre
						</Label>

						<Select
							IconComponent={ExpandMoreRoundedIcon}
							className="medium-selection padded-select"
							name="genre_id"
							
							labelId="genre-id-label"
							input={<Dropdown />}
							value={artist.genre_id}
							onChange={(e) => {
								setArtist({
									...artist,
									genre_id: e.target.value,
								})
							}}
							data-testid="artist-genre"
						>
							{artistGenres && artistGenres.map((genre) => (
								<MenuItem 
									key={genre.id} 
									value={genre.id}
									data-testid="artist-genre-item"
								> 
									{genre.value} 
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl fullWidth>
						<Label disableAnimation shrink htmlFor="artist-nationality" >
							Nationality
						</Label>

						<TextBox
							id="artist-nationality"
							onChange={(e) => {
								setArtist({
									...artist,
									nationality: e.target.value
								})
							}}
							data-testid="artist-nationality"
						/>

					</FormControl>

					<FormControl fullWidth>
						<Label disableAnimation shrink htmlFor="artist-dates" >
							Dates
						</Label>

						<TextBox
							id="artist-dates"
							onChange={(e) => {
								setArtist({
									...artist,
									dates: e.target.value
								})
							}}
							data-testid="artist-dates"
						/>

					</FormControl>

					<FormControl fullWidth>
						<Label disableAnimation shrink htmlFor="artist-birth" >
							Birthplace
						</Label>
 
						<TextBox			
							id="artist-birth"
							onChange={(e) => {
								setArtist({
									...artist,
									birthplace: e.target.value
								})
							}}
							data-testid="artist-birthplace"
						/>
				
					</FormControl>

				</div>
				<div
					style={{
						paddingTop: '1em',
						marginBottom: '1em',
						marginLeft: '1em',
						marginRight: '1em',
						marginTop: 'auto',
					}}
				>
					<CancelButton
						variant="contained"
						style={{ float: 'left' }}
						onClick={(e) => {
							props.setCreateArtist(null)
							setAttempt(false)
						}}
						data-testid="artist-cancel"
					>
								Cancel
					</CancelButton>

					<SubmitButton
						variant="contained"
						style={{ float: 'right' }}
						type="submit"
						data-testid="artist-submit"
						disabled={loading}
					>
						Create
					</SubmitButton>
				</div>
			</form>
		</QuickView>
	)
}

export default withApollo(CreateArtistQuickView)
