import { CircularProgress, FormControl } from '@mui/material'
import React, { useCallback, useContext } from 'react'
import Label from '../../input/Label'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import { typeStyle } from '../../styles/makesStyles'
import { CancelButton, SaveCardButton } from './../../input/Buttons'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { SEARCH_POSSIBLE_CONTACT_LISTINGS, SEARCH_POSSIBLE_ART_LISTINGS } from './Queries'
import LimitedAutocomplete from '../LimitedAutocomplete'
import { withApollo } from '@apollo/client/react/hoc'
import Thumbnail from '../../Thumbnail/Thumbnail'
import { useMutation } from '@apollo/client'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { CREATE_LISTING_ART } from '../../Listings/Listing/Queries'
import { CREATE_LISTING_CONTACT } from '../../Listings/Listing/Contacts/Queries'
import TextBoxThinForAutocomplete from '../../input/Text/TextBoxThinForAutocomplete'

const ListingAddModal = (props) => {
	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const { isPrivate, setAddListingModal, addListingModal, type, requery, id} = props

	// Autocomplete
	const [open, setOpen] = React.useState(false)
	const [options, setOptions] = React.useState([])
	const [loadListings, setLoadListings] = React.useState(false)
	const loading = open && !!loadListings

	const [listing, setListing] = React.useState(null)
	const [attempt, setAttempt] = React.useState(false)

	const query = type === 'art' ? SEARCH_POSSIBLE_ART_LISTINGS : SEARCH_POSSIBLE_CONTACT_LISTINGS
	const queryResponse = type === 'art' ? 'searchPossibleArtListings' : 'searchPossibleContactListings'

	const [handleAddListing, { loading: loadingAdd }] = useMutation(type === 'art' ? CREATE_LISTING_ART : CREATE_LISTING_CONTACT, {
		onError: (error) => {
			openSnackbar(severity, 'There was an error adding to the listing.')
			console.error(error)
		},
		onCompleted: (response) => {
			let responseName
			if (type === 'art') responseName = 'createListingArt'
			else responseName = 'createListingContact'

			if (response[responseName].id) {
				openSnackbar(severity.SUCCESS, 'Successfully added listing.')
				requery()
			} else {
				openSnackbar(severity.ERROR, 'Could not add listing.')
			}
		},
	})

	React.useEffect(() => {
		let active = true

		if (!loading) {
			return undefined
		}

		props.client
			.query({
				query,
				variables: { [`${type}_id`]: id, query: loadListings },
			})
			.then((result) => {
				if (active) {
					setLoadListings(false)
					setOptions(result.data[queryResponse])
				}
			})

		return () => (active = false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.id, loading])

	return (
		<TransitionsModal className="new-listing-modal" open={addListingModal} close={() => setAddListingModal(false)}>
			<div className={isPrivate ? 'dark-theme' : null} style={{ minWidth: '20em' }}>
				<h1 className="card-title">Add Listing</h1>

				<FormControl className="fullWidth" error={attempt && !listing?.id}>
					<Label id="user-label" style={typeStyle} disableAnimation shrink>
						Listing
					</Label>

					<LimitedAutocomplete
						query={loadListings}
						setQuery={setLoadListings}
						isOptionEqualToValue={(a, b) => a?.id === b?.id}
						style={{ marginTop: '1.75em' }}
						open={open && !!options?.length}
						forcePopupIcon
						popupIcon={<ExpandMoreRoundedIcon />}
						onOpen={() => setOpen(true)}
						onClose={() => setOpen(false)}
						loading={loadingAdd}
						options={options}
						getOptionDisabled={option=> option.disabled}
						getOptionLabel={(row) => row.title}
						value={listing}
						renderOption={(optionProps, option) => {

							const thumbnail = <Thumbnail 
								name={option.title} 
								detail={option.subtitle || '-'}
								avatar={option.imgUrl} 
								type="listing"
								darkText={!props.isPrivate}
								largeText 
							/>

							if (option.disabled) 
								return (
									<li {...optionProps} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
										{thumbnail}
										<i>Already Included</i>
									</li>
								)

							return <li {...optionProps}>{thumbnail}</li>
						}}
						renderInput={(params) => (
							<TextBoxThinForAutocomplete
								autoFocus
								{...params}
								variant="outlined"
								error={attempt && !listing?.id}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? <CircularProgress color="inherit" size={20} /> : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
						onChange={(event, value) => {
							setListing(value)
						}}
					/>
				</FormControl>

				<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1.5em' }}>
					<CancelButton
						variant="contained"
						onClick={() => {
							setListing(null)
							setAddListingModal(false)
							setAttempt(false)
						}}
					>
						Cancel
					</CancelButton>
					<SaveCardButton
						style={{ marginLeft: '1em' }}
						variant="contained"
						color="secondary"
						disabled={loading}
						onClick={() => {

							setAttempt(true)

							if (attempt && ( !listing?.id)) {
								openSnackbar(severity.WARNING, "Please complete required field.")

							} else if (!(!listing?.id)) {

								handleAddListing({
									variables: {
										listing_id: listing.id,
										[`${type}_id`]: props.id,
									},
								})
								
								setListing(null)
								setAddListingModal(false)
								setAttempt(false)
								setOptions([])
							}

						}}
					>
						Save
					</SaveCardButton>
				</div>
			</div>
		</TransitionsModal>
	)
}

export default withApollo(ListingAddModal)
