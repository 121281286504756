/* eslint-disable eqeqeq */
import { withApollo } from '@apollo/client/react/hoc'
import { Close } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import TimelapseOutlinedIcon from '@mui/icons-material/TimelapseOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { CircularProgress, IconButton, Tooltip, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { GET_IMPORT } from "../AdminConsole/Queries"
import QuickView from '../QuickView/QuickView'
import { AuthStateContext } from '../store'
import ImportQuickViewData from './ImportQuickViewData'
import ImportQuickViewError from './ImportQuickViewError'
import ImportImageModal from '../Import/ImportImageModal'



class Poller extends React.Component {
	state = {
		isPolling: false,
		isHanging: false,
		delay: 5000
	}

	shouldPoll = (import_obj) => {
		return ["Uploading", "Processing"].includes(import_obj.status.value)
	}

	poll = () => {
		// request an update on the status of the import
		console.log(`Polling for update on import ${this.props.import.id}`)
		this.props.importEndpoint.query({ query: GET_IMPORT, variables: { importId: this.props.import.id } })
			.then((response) => {
				const new_status = response.data.getImport.import.status.value
				const time = response.data.getImport.time

				// Check if the import isHanging
				if (time - this.props.import.created_at > 600000) {
					this.setState({ isHanging: true })
				}

				// Check if the status has changed
				if (new_status && new_status !== this.props.import.status.value) {
					// Status has changed, check if polling still needs to occur
					this.setState({ isPolling: false })
					this.props.setImport(response.data.getImport.import)
					clearTimeout(this.current)
					this.current = null
				}
				else {
					// If the status hasn't changed, keep polling
					if (this.shouldPoll(this.props.import)) {
						this.current = setTimeout(() => this.poll(), this.state.delay)
					}
				}
			})
			.catch((error) => {
				this.setState({ isPolling: false })
				console.error(error)
			})
	}

	// start the timeout
	componentDidMount() {
		if (this.shouldPoll(this.props.import)) {
			this.setState({ isHanging: false })
			this.setState({ isPolling: true })
			this.current = setTimeout(() => this.poll(), this.state.delay)
		}
	}

	// check if we need to update the timeout
	componentDidUpdate(prevProps, prevState) {
		// Check for polling if the new import is different
		// or if the import is the same but the status has changed
		if (prevProps.import.id !== this.props.import.id ||
			(prevProps.import.id === this.props.import.id && prevProps.import.status.id !== this.props.import.status.id)) {
			clearTimeout(this.current)
			if (this.shouldPoll(this.props.import)) {
				this.setState({ isHanging: false })
				this.setState({ isPolling: true })
				this.current = setTimeout(() => this.poll(), 0)
			}
			else {
				this.setState({ isPolling: false })
				this.setState({ isHanging: false })
			}
		}
	}

	// Close the timeout when unmounted
	componentWillUnmount() {
		this.setState({ isPolling: false })
		this.setState({ isHanging: false })
		clearTimeout(this.current)
	}

	render() {
		return (this.state.isPolling ?
			<>
				<CircularProgress />
				<Typography variant="caption">Checking for updates...</Typography>
				{this.state.isHanging ?
					<div className={'shadow-group-light'}>
						<div style={{ padding: "1em" }}>
							<div style={{
								whiteSpace: "pre-line",
								maxHeight: "15em",
								overflow: "auto",
							}}>
								This import has been running for more than 10 minutes, an error likely occured. <br /><br /><b>Don't re-upload this import</b> until a system administrator has investigated.
							</div>
						</div>
					</div>
					:
					<></>
				}
			</>
			:
			<></>
		)
	}
}


/**
 * @typedef ImportQuickView
 * @property {import('../Import').Import} import
 */

/**
 * @param {ImportQuickView} props
 */
function ImportQuickView(props) {

	const authState = useContext(AuthStateContext)
	const location = useLocation()

	const [import_obj, setImport] = React.useState(
		props.import || props.state?.getImport || null)
	const [uploadImages, setUploadImages] = React.useState(false)

	useEffect(() => {
		if (Object.keys(props?.state?.getImport || props.import || {}).length) {
			// Loading logic if required
		}
	}, [props])

	useEffect(() => {
		if (props.import) setImport(props.import)
		if (props.state?.getImport) setImport(props.state?.getImport)
	}, [props.state, props.import])


	useEffect(() => {
		if (location.pathname === ('/admin') && import_obj === null) {
			setImport(authState.user)
		}
	}, [authState.user, location.pathname, import_obj])



	/* =================================================================
	Return the quick view
	================================================================= */

	return <>
		<QuickView className="quick-view-main">
			<div className="quick-view-inner" /* onKeyDown={handleKeyDown} */>
				<header>
					<div
						style={{
							display: 'flex',
							paddingBottom: '0px',
							width: '100%',
						}}
					>
						{/* Import ID */}
						<h2
							className='quick-view-title'
							style={{
								overflowWrap: 'anywhere',
								paddingBottom: '0.5em'
							}}
						>
							Import {import_obj.id}
						</h2>


						{/* Import Status */}
						<Tooltip title={import_obj.status.value} placement="top-end" arrow>
							<span style={{ marginLeft: 'auto', marginRight: '.5em' }}>
								{
									import_obj.status?.value == 'Completed' ?
										<DoneIcon color="success" style={{ height: '35px', width: '35px' }} />
										: import_obj.status?.value == 'Failed' ?
											<CloseIcon color="error" style={{ height: '35px', width: '35px' }} />
											: import_obj.status?.value == 'Invalid' ?
												<WarningAmberOutlinedIcon color="warning" style={{ height: '35px', width: '35px' }} />
												: import_obj.status?.value == 'Processing' ?
													<TimelapseOutlinedIcon color="primary" style={{ height: '35px', width: '35px' }} />
													: import_obj.status?.value == 'Uploading' ?
														<FileUploadOutlinedIcon color="primary" style={{ height: '35px', width: '35px' }} />
														: <p>Unknown Status</p>
								}
							</span>
						</Tooltip>

						{/* Import Type */}
						<Tooltip title={import_obj.type.value} placement="top-start" arrow>
							<span >
								{import_obj.type.value == "Art" ?
									<img alt='art' style={{ height: '30px', width: '30px' }}
										src={`/images/icons/White/Art.svg`} />
									:
									<img alt='contact' style={{ height: '30px', width: '30px' }}
										src={`/images/icons/White/Contacts.svg`} />
								}
							</span>
						</Tooltip>

						{props.onClose && <span>
							<IconButton size="large"
								sx={{ height: '35px', width: '35px' }}
								onClick={props.onClose}
							>
								<Close sx={{ color: 'white' }} />
							</IconButton>
						</span>}
					</div>

					<div
						style={{
							display: 'flex',
							paddingBottom: '0px',
							width: '100%',
						}}
					>
						{/* Import Name */}
						<h3 className="paragraph-1">{import_obj.name}</h3>
					</div>
				</header>
				<ImportQuickViewData
					import={import_obj}
					setUploadImages={setUploadImages}
				></ImportQuickViewData>

				<ImportQuickViewError
					import={import_obj}
					importEndpoint={props.importEndpoint}
				></ImportQuickViewError>

				<Poller
					import={import_obj}
					importEndpoint={props.importEndpoint}
					setImport={props.setImport}
				></Poller>

				<ImportImageModal
					open={uploadImages}
					setOpen={setUploadImages}
					import={import_obj}
				></ImportImageModal>

			</div>
		</QuickView>


	</>
}

export default withApollo(ImportQuickView)
