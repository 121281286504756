import React from 'react'
import { GET_CONTACT_DATALOG } from './Queries'
import DataLog from '../../DataLog/DataLog'

export default function ContactDataLog (props) {

	return !props.findMode && props.id > 0 && (
		<DataLog 
			query={GET_CONTACT_DATALOG} 
			id_field={"contact_id"} 
			queryName={"getContactDataLog"}
			parentQueryName={"getContact"}
			{...props}
		/>
	)
}
