import React, { useCallback, useContext, useEffect, useState } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Checkbox, CircularProgress, FormControlLabel, Paper } from '@mui/material'
import { DYNAMIC_LISTING_SEARCH } from '../../Search/Queries'
import { SearchTerm } from '../../common/SavedSearch'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { useApolloClient } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import TextBoxForAutocomplete from '../../input/Text/TextBoxForAutocomplete'
import LimitedAutocomplete from '../../common/LimitedAutocomplete'
import { autoCompleteErrorStyles } from '../../styles/makesStyles'
import { ExpandMoreRounded } from '@mui/icons-material'
import { showcaseColumns } from '../CuratedLists/ShowcaseCuratedListSearch'
import { formatForFuzzySearch } from '../../Search/unifiedSearchHelpers'
import ShowcaseSortOrder from '../ShowcaseSortOrder'
import { ESOrder } from '../../common/typescriptVars'
import { showcaseSortOptions } from '../../constants/values'

export default function ShowcaseArtFairSearch (props: any) {
	type ListingT = any
	interface ListingData {
		data: {
			searchDynamicListings: {
				items: ListingT[]
				totalItems: number
				cursor: number
			}
		}
	}

	const DEFAULT_LIMIT = 50

	// Searching
	const [cursor, setCursor] = useState(0)
	const [listings, setListings] = useState<ListingT>([])
	const [loadedRows, setLoadedRows] = useState<any>([])
	const limit = DEFAULT_LIMIT
	const [order, setOrder] = useState<ESOrder>('DESC')
	const [orderBy, setOrderBy] = useState('start_at')
	const [isCurrent, setIsCurrent] = useState(true)
	const [activeStep, setActiveStep] = useState(0)
	const [totalItems, setTotalItems] = useState(0)
	const [loading, setLoading] = useState(true)

	const autoCompleteStyles = autoCompleteErrorStyles()

	// Autocomplete
	const [listingOpen, setListingOpen] = useState(false)
	const [listingOptions, setListingOptions] = useState([])
	const [loadListing, setLoadListing] = useState<string | false>(false)
	const listingLoading = listingOpen && !!loadListing
	const [listingAutocomplete, setListingAutocomplete] = useState<ListingT | null>(null)


	const client = useApolloClient()
	const navigate = useNavigate()

	const listingType = 'Art Fair'

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity: severity, text: string) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, 	[dispatch])

	const closeSnackbar = () => dispatch({ type: 'closeSnackBar' })

	let filters = [
		{
			"field": "showcase_ready",
			"type": "eq",
			"value": "true",
			"isOptional": false
		},
		{
			"field": "listing_type",
			"type": "contains",
			"value": listingType,
			"isOptional": false
		}
	]

	if (isCurrent) {
		const today = (new Date()).toDateString()

		filters = filters.concat({
			"field": "start_at",
			"type": "lte",
			"value": today,
			"isOptional": false
		}, {
			"field": "end_at",
			"type": "gte",
			"value": today,
			"isOptional": false
		})
	}

	React.useEffect(() => {
		let active = true

		if (client && listingLoading) {
			client
				.query({
					query: DYNAMIC_LISTING_SEARCH,
					variables: {
						filters: [
							{
								field: 'title',
								type: 'contains',
								value: formatForFuzzySearch(loadListing),
								isOptional: false },
							...filters
						],
						cursor: 0,
						limit: 10,
						field: 'title',
						direction: 'ASC'
					},
				})
				.then((result: any) => {
					if (active) {
						setLoadListing(false)
						setListingOptions(result.data?.searchDynamicListings?.items || [])
					}
				})
		}

		return () => {
			active = false
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadListing])

	const handleSubmit = (cursorOverride?: number, newFilters?: SearchTerm[], firstLoad = false) => {

		setLoadedRows([])
		if (firstLoad) setLoading(firstLoad)

		client
			.query({
				query: DYNAMIC_LISTING_SEARCH,
				variables: {
					cursor: cursorOverride ?? cursor,
					limit,
					field: orderBy,
					direction: order,
					thumbnailResolution: '128x128',
					filters: [
						...filters,
						...(newFilters || [])
					],
				},
			})
			.then((result: ListingData) => {
				const { data } = result

				setLoading(false)
				if (data.searchDynamicListings?.items) {
					setListings(data.searchDynamicListings.items)
					setTotalItems(data.searchDynamicListings.totalItems || 0)
					closeSnackbar()
				} else {
					console.error(data)
					openSnackbar(severity.ERROR, 'There was an error searching listings.')
				}
			})
			.catch((error: Error) => {
				console.error(error)
				openSnackbar(severity.ERROR, 'There was an error retrieving Listings.')
			})
	}

	useEffect(() => {
		handleSubmit(0, [], true)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order, orderBy, isCurrent, listingType])

	const handleOnRowsScrollEnd = (params: any) => {

		if (!listings.length) return

		setActiveStep((step: number) => step + 1)
		setCursor((step: number)=> (step + 1) * DEFAULT_LIMIT)

		const fullFilters = [ ...filters ]
		if (listingAutocomplete) {
			fullFilters.push({
				field: 'title',
				type: 'contains',
				value: formatForFuzzySearch(listingAutocomplete),
				isOptional: false
			})
		}

		client
			.query({
				query: DYNAMIC_LISTING_SEARCH,
				variables: {
					cursor: (activeStep + 1) * DEFAULT_LIMIT,
					limit,
					field: orderBy,
					direction: order,
					thumbnailResolution: '128x128',
					filters: fullFilters,
				},
			})
			.then((result: ListingData) => {
				const { data } = result

				if (data.searchDynamicListings?.items) {
					setLoadedRows((rows: any[]) => rows.concat(data.searchDynamicListings.items))
					setTotalItems(data.searchDynamicListings.totalItems || 0)
					closeSnackbar()
				} else {
					console.error(data)
					openSnackbar(severity.ERROR, 'There was an error searching showcase listings.')
				}
			})
			.catch((error: Error) => {
				console.error(error)
				openSnackbar(severity.ERROR, 'There was an error retrieving showcase listings.')
			})
	}

	return (
		<div style={{ height: '100%', }}	>
			<Paper className='qv-margin' sx={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(100% - 2em)', height: 'fit-content' }}>

				<div style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'end'
				}}>

					<div style={{ flexGrow: '2', marginRight: '16px' }}>
						<h1 className="card-title">Art Fairs</h1>
					</div>

					<FormControlLabel
						sx={{ marginBottom: '4px' }}
						control={<Checkbox
							checked={isCurrent}
							name="Current"
							onChange={() => setIsCurrent(c => !c)}
						/>}
						label="Current"
					/>

					<ShowcaseSortOrder
						order={order}
						orderBy={orderBy}
						onOrderChange={setOrder}
						onOrderByChange={setOrderBy}
						width="18em"
						sortOptions={showcaseSortOptions}
						largeSize
					/>
					<LimitedAutocomplete
						freeSolo
						query={loadListing}
						setQuery={setLoadListing}
						filterSelectedOptions
						forcePopupIcon
						popupIcon={<ExpandMoreRounded />}
						classes={autoCompleteStyles}
						open={listingOpen && !!listingOptions?.length}
						onOpen={() => setListingOpen(true)}
						onClose={() => setListingOpen(false)}
						getOptionLabel={(listing: any) => {
							// Free solo change
							if (typeof listing == 'string') return listing

							// User selected listing
							return listing.title
						}}
						options={listingOptions}
						loading={listingLoading}
						isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
						value={listingAutocomplete}
						onChange={(event: any, value: any) => {
							setListingAutocomplete(value)

							if (value === null) {
								handleSubmit(0, [])
								setListingOptions([])
							} else if (typeof value == 'string') {
								handleSubmit(0, [{
									field: 'title',
									type: 'contains',
									value: formatForFuzzySearch(value),
									isOptional: false
								}])
							} else {
								navigate(`/showcase/artfairs/${value.id}`)
							}
						}}
						renderInput={(params: any) => (
							<TextBoxForAutocomplete
								{...params}
								variant='outlined'
								fullWidth
								sx={{ backgroundColor: 'white' }}
								classes={{ notchedOutline: null }}
								InputLabelProps={{ shrink: true }}
								InputProps={{
									...params.InputProps,
									endAdornment: <>
										{listingLoading ? <CircularProgress color='inherit' size={20} /> : null}
										{params.InputProps.endAdornment}
									</>,
									style: { width: '30em' },
								}}
							/>
						)}
					/>
				</div>

				<DataGridPro
					sx={{ '& .MuiDataGrid-cell': { borderBottom: '0' } }}
					rowCount={totalItems || 0}
					rows={listings.concat(loadedRows)}
					columns={showcaseColumns}
					density={'comfortable'}
					disableColumnMenu
					disableSelectionOnClick
					disableColumnFilter
					disableColumnPinning
					disableColumnReorder
					disableColumnResize
					disableColumnSelector
					disableDensitySelector

					scrollEndThreshold={300}

					onRowClick={({ row }) => {
						navigate(`/showcase/artfairs/${row.id}`)
					}}
					page={activeStep}
					onPageChange={setActiveStep}
					loading={loading}

					// Infinite Scrolling
					hideFooterPagination
					onRowsScrollEnd={handleOnRowsScrollEnd}
				/>
			</Paper>
		</div>
	)
}
