/* eslint-disable eqeqeq */
import { FormControl, MenuItem, Select } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Dropdown from '../../input/Dropdown/ThinDropdown'
import Label from '../../input/Label'
import React, { useContext } from "react"
import { withApollo } from '@apollo/client/react/hoc'
import TextBox from '../../input/Text/TextBoxThin'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import {SubmitButton, CancelButton} from '../../input/Buttons'
import { isPrimary } from '../../common/helpers'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

import { LookupContext } from './../../store'
import { autoCompleteErrorStyles } from '../../styles/makesStyles'
import { CREATE_ADDRESS } from '../Contact/Queries'
import { useMutation } from '@apollo/client'
import TextBoxThinForAutocomplete from '../../input/Text/TextBoxThinForAutocomplete'
import { SIMPLE_SEARCH_REGIONS } from '../Queries'
import { USA_COUNTRY_CODE } from '../../constants/values'

const NewAddressModal = (props) => {

	const lookup = useContext(LookupContext)
	const countries = lookup.data?.getCountryTypes || []
	
	const [attempt, setAttempt] = React.useState(false)
	const [regions, setRegions] = React.useState([])


	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}

	const [createAddress] = useMutation(CREATE_ADDRESS, {
		onError: (error) => {
			openSnackbar(severity.ERROR, error.createAddress.message)
		},
		onCompleted: (data) => {
			if (data?.createAddress?.success) {
				openSnackbar(severity.SUCCESS, data.createAddress.message)
				props.setContact({
					...props.contact,
					addressCount: props.contact.addressCount + 1
				})
			} else {
				openSnackbar(severity.ERROR, "Could not create address.")
			}
		}
	})

	const isUSA = props.newAddressModal?.address?.country_id == USA_COUNTRY_CODE

	return (
		<TransitionsModal
			className="new-address-modal"
			open={props.newAddressModal?.open}
			close={() =>
				props.setNewAddressModal({
					...props.newAddressModal,
					open: false
				})
			}
		>
			<form
				className={props.isPrivate ? "private-object modal-large-content" : "modal-large-content"}
				onSubmit={e => {
					e.preventDefault()
					e.stopPropagation()

					setAttempt(true)

					if (attempt && (
						props.newAddressModal?.address?.country?.length === 0 ||
						props.newAddressModal?.address?.town?.length === 0 ||
						(isUSA && props.newAddressModal?.address?.region?.length === 0)
					)) {
						openSnackbar(severity.WARNING, "Please fill out the fields in red.")
					} else if (! (
						props.newAddressModal?.address?.country?.length === 0 ||
						props.newAddressModal?.address?.town?.length === 0 ||
						(isUSA && props.newAddressModal?.address?.region?.length === 0))) {


						let newAddress = {
							label: props.newAddressModal.address.label,
							type_id: props.newAddressModal.address.type_id,
							type: props.addressTypes[parseInt(props.newAddressModal.address.type_id) - 1].value,
							street_1: props.newAddressModal.address.street_1,
							street_2: props.newAddressModal.address.street_2,
							street_3: props.newAddressModal.address.street_3,
							country: props.newAddressModal.address.country,
							country_id: props.newAddressModal.address.country_id,
							region: props.newAddressModal.address.region,
							town: props.newAddressModal.address.town,
							postcode: props.newAddressModal.address.postcode,
							contact_id: props.contactInput.id,
							is_primary: isPrimary(props, "address")
						}

						delete newAddress.country

						if (!props.hasContactInfoPermission && props.onContactDetailsPage) {

							delete newAddress.type

							createAddress({
								variables: {
									AddressInput: newAddress
								}
							})
			
						} else {
							props.addToContactInput('address', newAddress)
						}

						props.setNewAddressModal({
							...props.newAddressModal,
							open: false
						})

						setAttempt(false)
					}
				}}
			>
				<FormControl style={{ width: '14.5em', marginRight: '1em' }}>
					<Label disableAnimation shrink>
						Label
					</Label>
					<TextBox
						autoFocus
						placeholder="-"
						value={props.newAddressModal?.address?.label}
						onChange={e => {
							props.setNewAddressModal({
								...props.newAddressModal,
								address: {
									...props.newAddressModal.address,
									label: e.target.value
								}
							})
						}}
						data-testid="address-title"
					/>
				</FormControl>

				<FormControl style={{ width: '14.5em' }} id="address-type-formcontrol">
					<Label disableAnimation shrink>
						Type
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						
						input={<Dropdown />}
						value={props.newAddressModal?.address?.type_id}
						onChange={e => {
							props.setNewAddressModal({
								...props.newAddressModal,
								address: {
									...props.newAddressModal.address,
									type_id: e.target.value
								}
							})
						}}
						data-testid="address-type"
					>
						{props.addressTypes && props.addressTypes.map(x => (
							<MenuItem key={x.id} value={x.id} data-testid="address-type-item">
								{x.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Address Line 1
					</Label>
					<TextBox
						value={props.newAddressModal?.address?.street_1}
						onChange={e => {
							props.setNewAddressModal({
								...props.newAddressModal,
								address: {
									...props.newAddressModal.address,
									street_1: e.target.value
								}
							})
							
						}}
						data-testid="address-line1"
					/>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Address Line 2
					</Label>
					<TextBox
						value={props.newAddressModal?.address?.street_2}
						onChange={e => {
							props.setNewAddressModal({
								...props.newAddressModal,
								address: {
									...props.newAddressModal.address,
									street_2: e.target.value
								}
							})
							
						}}
						data-testid="address-line2"
					/>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Address Line 3
					</Label>
					<TextBox
						value={props.newAddressModal?.address?.street_3}
						onChange={e => {
							props.setNewAddressModal({
								...props.newAddressModal,
								address: {
									...props.newAddressModal.address,
									street_3: e.target.value
								}
							})
							
						}}
						data-testid="address-"
					/>
				</FormControl>

				<FormControl>
					<Label shrink id="country-label" htmlFor="country-autocomplete" error={ attempt && (
						props.newAddressModal?.address?.country?.length === 0 ||
						props.newAddressModal?.address?.country === undefined)
					}>
						Country*
					</Label>
					<Autocomplete
						autoHighlight
						options={countries}
						getOptionLabel={option => {
							return option.name || option.country || ''
						}}
						isOptionEqualToValue={(option, value) => {
							return option.id == value?.country_id
						}}
						value={props.newAddressModal?.address}
						disableClearable
						onChange={(event, value) => {
							
							props.setNewAddressModal({
								...props.newAddressModal,
								address: {
									...props.newAddressModal.address,
									country: value?.name,
									country_id: value?.id
								}
							})

							
							props.client
								.query({
									query: SIMPLE_SEARCH_REGIONS,
									variables: {
										countryName: value?.name,
									},
								})
								.then((result) => {
									setRegions(result.data?.simpleSearchRegions || [])
								})
								.catch((e) => {
									console.error("Couldn't find regions for current country.")
								})

						}}
						classes={autoCompleteErrorStyles()}
						popupIcon={<ExpandMoreRoundedIcon />}
						renderInput={params => (
							<TextBoxThinForAutocomplete
								{...params}
								id="country-autocomplete"
								size="small"
								variant="outlined"
								error={ attempt && (
									props.newAddressModal?.address?.country?.length === 0 ||
									props.newAddressModal?.address?.country === undefined)
								}
								fullWidth
								style={{width: "30em", paddingTop: "1.5em"}}
								classes={{ notchedOutline: null }} 
								InputLabelProps={{
									shrink: true
								}}
							/>
						)}
						data-testid="address-country"
					/>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label 
						disableAnimation 
						shrink
						error={ attempt && isUSA && 							
							(!props.newAddressModal?.address?.region || 
							!props.newAddressModal?.address?.region?.length)
						}
					>
						State/Province/Region{isUSA && '*'}
					</Label>

					<Autocomplete
						freeSolo={!isUSA}
						value={props.newAddressModal?.address?.region}
						classes={autoCompleteErrorStyles()}

						onChange={(event, value) => {

							props.setNewAddressModal({
								...props.newAddressModal,
								address: {
									...props.newAddressModal.address,
									region: props.newAddressModal.address?.country_id == USA_COUNTRY_CODE 
										? value?.shortCode || '' : value?.name || ''
								}
							})
							
						}}
						options={[...regions, { name: '' }]}
						popupIcon={<ExpandMoreRoundedIcon />}
						size="small"
						isOptionEqualToValue={(option, value) => 
							option?.name == (value?.name || '')
						}
						getOptionLabel={option => {	
							if (props.newAddressModal.address?.country_id != USA_COUNTRY_CODE) {

								if (typeof option === "string") return option
								else return option?.name 
							}

							if (typeof option === "string") return option
							else return option?.shortCode 
						}}
						forcePopupIcon={props.newAddressModal.address?.country_id == USA_COUNTRY_CODE ? true : 'auto'}
						renderInput={(params) => (
							<TextBoxThinForAutocomplete
								{...params}
								id="region-autocomplete"
								size="small"
								variant="outlined"
								fullWidth
								style={{width: "30em", paddingTop: "1.5em"}}
								classes={{ notchedOutline: null }} 
								InputLabelProps={{
									shrink: true
								}}
								error={ attempt && isUSA && 							
									(!props.newAddressModal?.address?.region || 
									!props.newAddressModal?.address?.length)
								}
							/>
						)}
						data-testid="search-gallery_contact"
					/>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink error={ attempt && (
						props.newAddressModal?.address?.town?.length === 0 ||
						props.newAddressModal?.address?.town === undefined)
					}>
						City/Town*
					</Label>
					<TextBox
						error={ attempt && (
							props.newAddressModal?.address?.town?.length === 0 ||
							props.newAddressModal?.address?.town === undefined)
						}
						value={props.newAddressModal?.address?.town}
						onChange={e => {
							props.setNewAddressModal({
								...props.newAddressModal,
								address: {
									...props.newAddressModal.address,
									town: e.target.value
								}
							})
							
						}}
						data-testid="address-city"
					/>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Zip/Postal Code
					</Label>
					<TextBox
						value={props.newAddressModal?.address?.postcode}
						inputProps={{ maxLength: 12 }}
						onChange={e => {
							props.setNewAddressModal({
								...props.newAddressModal,
								address: {
									...props.newAddressModal.address,
									postcode: e.target.value
								}
							})
							
						}}
						data-testid="address-zip"
					/>
				</FormControl>

				{props.contactInput ? 
					<>
						<CancelButton
							variant="contained"
							style={{ float: 'left', marginTop: '1em' }}
							onClick={e => {
								e.preventDefault()
								props.setNewAddressModal({
									...props.newAddressModal,
									open: false
								})
								setAttempt(false)
							}}
							data-testid="address-cancel"
						>
						Cancel
						</CancelButton>

						<SubmitButton
							style={{ float: 'right', marginTop: '1em' }}
							type="submit"
							variant="contained"
							data-testid="address-submit"
						>
							Submit
						</SubmitButton>
					</>
					:
					null
				}
			</form>
		</TransitionsModal>
	)
}

export default withApollo(NewAddressModal)
