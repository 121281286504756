import { gql } from "@apollo/client"

export const LISTING_ART_SEARCH = gql`
	query searchDynamicArt($field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int) {
		searchDynamicArt(input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters }) {
			cursor
			totalItems
			items {
				id
				title
				artist {
					id
					first_name
					last_name
				}
				code_name
				imgUrl
				year
				reserves {
					id
					reserve_end_at
					reserve_position
					deal {
						id
						salesperson {
							id
							first_name
							last_name
						}
					}
				}
				status {
					id
					value
				}
				primary_currency_id
				primaryRetailPrice

				medium
				all_dimensions
			}
		}
	}
`
export const GET_LISTING = gql`
	query getListing( $id: ID! ) {
		getListing(id: $id) {
			id
			title
			subtitle
		}
	}
`
