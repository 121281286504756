import React from 'react'

import UserPermissions from '../AdminConsole/UserPermissions'

const UserPermissionsPage = (props) => {

	const user = props.state?.getUser
	const getCompletePermissions = props.state?.getCompletePermissions
	
	return (
		<section className="main-page">
			<UserPermissions
				loading={!user}
				user={user}
				completePermissions={getCompletePermissions}
				readOnly={true}
			></UserPermissions>
		</section>
	)
}

export default UserPermissionsPage
