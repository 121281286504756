import React from 'react'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import Typography from '@mui/material/Typography'
import { CancelButton, SaveCardButton } from './../../input/Buttons'

export default function ListingRemoveModal(props) {
	const { 
		isPrivate, itemToRemove, handleDeleteListing, 
		setRemoveListingModal, removeListingModal 
	} = props

	return (
		<TransitionsModal className="new-listing-modal" open={removeListingModal} close={() => setRemoveListingModal(false)}>
			<div className={isPrivate ? 'dark-theme' : null} style={{ maxWidth: '25em' }}>
				<h1 className="card-title">Remove From Listing?</h1>
				<div style={{ padding: '1em 0 1.5em 0' }}>
					<Typography className="paragraph-1">Are you sure you want to remove {itemToRemove} from the listing?</Typography>
				</div>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<CancelButton variant="contained" onClick={() => setRemoveListingModal(false)}>
						Cancel
					</CancelButton>
					<SaveCardButton
						style={{ marginLeft: '1em', background: 'rgb(204, 51, 51)' }}
						variant="contained"
						color="secondary"
						disabled={props.disabled}
						onClick={() => {
							handleDeleteListing()
							setRemoveListingModal(false)
						}}
					>
						Remove
					</SaveCardButton>
				</div>
			</div>
		</TransitionsModal>
	)
}
