/* eslint-disable eqeqeq */
import { Tooltip } from "@mui/material"
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { dealContactRoles } from "../../constants/values"


interface SourceLabelProps {
	sources: any[]
	iconStyles?: any
	loading?: boolean
}

const SourceLabel = (props: SourceLabelProps) => {
	const firstSource = props.sources?.[0]

	let label = 'Source'

	if (firstSource) {
		switch (firstSource.deal_entry_status_category) {
		case 'Consignment In':
			label = 'Consignor'
			break
		case 'Consignment Out':
			label = 'Consignee'
			break
		case 'Gallery Purchase':
			label = 'Vendor'
			break
		case 'Loan In':
			label = 'Loaner'
			break
		case 'Loan Out':
			label = 'Loanee'
			break
		case 'Purchases':
			label = 'Purchaser'
			break
		case 'Known Source': {
			
			if (props.sources?.filter((e: any) => e.deal_entry_status_category === 'Known Source' 
				&& (e.contactRole?.id == dealContactRoles.owner || e.contact_role_id == dealContactRoles.owner))?.length > 1) {
				label = 'Known Sources'
				break
			}

			label = 'Known Source'
			break
		}
			
		default:
			label = 'Source'
			break
		}
	}
	
	return (<>
		{label}
		{(!props.loading && props.sources?.length && !firstSource.created_at)  ?
			<Tooltip
				enterDelay={500}
				title={'You do not have permission to view this Source.'} arrow placement="bottom">
				<VisibilityOffIcon style={{
					color: 'grey',
					marginLeft: '0.4em',
					...props.iconStyles
				}}/>
			</Tooltip>
			: null}
	</>
	)
}

export default SourceLabel
