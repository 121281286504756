/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useCallback } from 'react'
import { DispatchContext } from '../../store'
import {
	Paper,
	Menu,
	Button,
	TableContainer,
	Table,
	TableCell,
	TableBody,
	TableRow,
	IconButton,
	TableHead,
	FormControl,
	Select,
	MenuItem,
	CircularProgress
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import Thumbnail from '../../Thumbnail/Thumbnail'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import Autocomplete from '@mui/material/Autocomplete'
import Label from '../../input/Label'
import { withApollo } from '@apollo/client/react/hoc'
import { useMutation } from '@apollo/client'
import { UPDATE_CONTACT } from '../Queries.js'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { CancelButton, SaveCardButton, NewButton } from '../../input/Buttons'
import TextBox from '../../input/Text/TextBox'
import { LookupContext } from '../../store'
import Dropdown from '../../input/Dropdown/Dropdown'
import { Skeleton } from '@mui/material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { autoCompleteErrorStyles } from '../../styles/makesStyles'
import { getContactName,formatDate, getContactLabel } from '../../common/helpers'
import { SIMPLE_SEARCH_CONTACTS } from '../../Art/Queries'
import LimitedAutocomplete from '../../common/LimitedAutocomplete'
import sortBy from 'lodash/sortBy'
import useNavigation from '../../navigation/useNavigation'
import ContactThumbnail from '../../Thumbnail/ContactThumbnail'
import { FindModeInput } from '../../navigation/Tabs/TabbedPage'
import TextBoxThinForAutocomplete from '../../input/Text/TextBoxThinForAutocomplete'
import TextBoxForAutocomplete from '../../input/Text/TextBoxForAutocomplete'
import { useLocation, useNavigate } from 'react-router-dom'



const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false,
}

function Relationships(props) {

	const { push: pushNav } = useNavigation()

	// Use the lookup to find relationship types
	const lookup = useContext(LookupContext)
	const relationshipTypes = lookup.data?.getRelationshipTypes
	const relationshipTypesSorted = sortBy(relationshipTypes, ['value'])
	const classes = autoCompleteErrorStyles()

	const navigate = useNavigate()
	const location = useLocation()

	// Other State
	const [state, setState] = React.useState(initialState)
	const [attempt, setAttempt] = React.useState(false)
	const [relationshipModal, setRelationshipModal] = React.useState({
		open: false,
		relationship_1: null,
		relationship_2: null,
		description: null
	})
	const [contactInput, setContactInput] = React.useState({
		id: props?.contact?.id,
		relationships: props?.contact?.relationships,
	})

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const [contactOpen, setContactOpen] = React.useState(false)
	const [contactOptions, setContactOptions] = React.useState([])
	const [loadContacts, setLoadContacts] = React.useState(false)
	const contactLoading = contactOpen && !!loadContacts


	useEffect(() => {
		let active = true

		if (!contactLoading) {
			return undefined
		}


		props.client.query({
			query: SIMPLE_SEARCH_CONTACTS,
			variables: { query: loadContacts },
		}).then(result => {
			if (active) {
				setLoadContacts(false)
				setContactOptions(result.data.simpleSearchContacts)
			}
		})

		return () => active = false
	}, [contactLoading, loadContacts, props.client])

	const [updateContact, { loading, error }] = useMutation(UPDATE_CONTACT, {
		onError: (error) => {
			// On failure, reset contactInput state, don't touch contact state and show error
			resetContactInput(props.contact)
			openSnackbar(severity.ERROR, 'Could not update relationships card.')
			handleClose()
		},

		onCompleted: (response) => {
			if (response.updateContact.success === true) {
				// Success
				openSnackbar(severity.SUCCESS, response.updateContact.message)

				// On success change contact state
				props.setContact({
					...props.contact,
					relationships: response.updateContact.contact.relationships,
				})
			} else {
				// On failure, reset contactInput state, don't touch contact state and show error
				resetContactInput(props.contact)
				openSnackbar(severity.ERROR, response.updateContact.message)
			}
		},
	})

	// Loading, Error
	useEffect(() => {
		if (loading) openSnackbar(severity.INFO, 'Saving relationships card...')
		if (error)
			openSnackbar(severity.ERROR, 'Error saving relationships card.')
	}, [error, loading, openSnackbar])

	const resetContactInput = useCallback(

		(contact) => {

			setContactInput({
				id: contact?.id,
				relationships: props?.contact?.relationships,
			})
		},
		[props]
	)

	React.useEffect(() => {
		resetContactInput(props.contact)
	}, [props.contact, resetContactInput])

	const handleClick = (event) => {
		if (state.mouseX || state.editable || props.findMode || !props.contact?.id || props.disableEdits) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const handleClose = (option) => {
		if (option === 'edit') {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	const relationshipsLength = contactInput?.relationships
		?.filter(e => e.contact_2.id === props.contact.id)
		?.length


	// If a user doesn't have public access and the contact is not a excluded contact type
	if (props.contact?.publicAccess == null && props.contact?.isPermittedContactType == null) return <> </>

	return (
		<React.Fragment>
			<Paper
				className="qv-margin"
				id="contact-relationship"
				style={{ marginTop: '0px' }}
				onContextMenu={handleClick}
				data-testid="card-relationships"
			>
				<h1 className="card-title">
					<div>
						<span style={{marginRight: '10px'}}>Relationships {
							(!props.findMode && relationshipsLength) ? 
							`(${relationshipsLength})` : ''
						}</span>
					</div>
					{(state.editable && !props.findMode) && (
						<>
							<div className="spacer"></div>
							<CancelButton
								variant="contained"
								size="small"
								onClick={() => {
									resetContactInput(props.contact)
									handleClose()
								}}
							>
								Cancel
							</CancelButton>
							<SaveCardButton
								variant="contained"
								color="secondary"
								size="small"
								disabled={loading}
								onClick={() => {
									const updateInput = {
										id: contactInput.id,
										relationships: contactInput.relationships.map(relationship => ({
											id: relationship?.id,
											contact_1_id: relationship.contact_1.id,
											contact_2_id: relationship.contact_2.id,
											relationship_type_id: relationship.relationship_type_id,
											description_id: relationship.description_id,
											description: relationship.description
										}))
									}

									updateContact({
										variables: {
											ContactInput: updateInput,
										},
									})

									setState({
										...state,
										editable: false
									})
								}}
							>
								Save
							</SaveCardButton>
						</>
					)}
				</h1>

				<TableContainer
					className={props.contact?.is_private ? "search-list dark-theme" : "search-list"}
					style={{ maxHeight: '20em' }}
				>
					<Table aria-label="relationships table" size="small">
						<TableHead>

							<TableRow>
								{state.editable ? (
									<TableCell></TableCell>
								) : null}
								<TableCell style={{width: '20%'}}>
									Contact
								</TableCell>
								{!props.findMode && <TableCell style={{width: '10%'}}>Relation</TableCell>}
								<TableCell style={{width: '60%'}}>Description</TableCell>
								{!props.findMode && !props.removeCreatedCol ? <TableCell style={{width: '10%'}}>Created At</TableCell> : null}
							</TableRow>
						</TableHead>
						<TableBody>
							{props.loading ? (
								<>
									<TableRow>
										<TableCell>
											<Thumbnail largeText animation='wave'/>
										</TableCell>
										<TableCell>
											<Skeleton
												animation={'wave'}
												variant="text"
												width="75%"
											/>
										</TableCell>
										<TableCell>
											<Skeleton
												animation={'wave'}
												variant="text"
												width="75%"
											/>
										</TableCell>
									</TableRow>
								</>
							) : (
								<>
									{ props.findMode ? 
										<TableRow>
											<TableCell>
												<FindModeInput field="related_contact" />
											</TableCell>
											<TableCell>
												<FindModeInput 
													field="relationship_description" 
													style={{width: '100%'}} />
											</TableCell>
										</TableRow>
										: contactInput?.relationships &&
											contactInput?.relationships?.length !==
											0 ? (
												contactInput?.relationships.map(
													(row, index) => {
													// Check for duplication of relationships
														if (
															row.contact_1 ===
															contactInput
																?.relationships[
																	index - 1
																]?.contact_2 &&
															row.contact_2 ===
															contactInput
																?.relationships[
																	index - 1
																]?.contact_1
														) {
															return false
														}
														// Assume contact_1
														let currentRelationContact =
															row.contact_1


														const hidden = currentRelationContact.is_private && !currentRelationContact.first_name
														const isPrivate = currentRelationContact.is_private

														return (
															<TableRow
																className={isPrivate ? 'private private-relationship' : null}
																key={`relationship-${index}`}
																selected={row === props.selectedRow || row === props.privateAccess}
																style={{cursor: !props.disableEdits ? 'pointer' : null}}
																hover={!hidden && !isPrivate && !props.disableEdits}
																onClick={(e) => {

																	if (props.disableEdits) return

																	e.preventDefault()
																	e.stopPropagation()

																	if (state.editable) return
																	if (row.contact_1?.is_deleted) {
																		openSnackbar(severity.WARNING, `${getContactName(row.contact_1)} was deleted.`)
																		return
																	}

																	if (hidden) {
																		props.onSelect(null)
																		if (props.setPrivateAccess) props.setPrivateAccess(row)
																		return
																	}

																	if (e.metaKey) {
																		window.open(`/contacts/${currentRelationContact.id}`, '_blank')
																		return
																	}

																	if (row === props.selectedRow && !hidden) {
																		const ids = contactInput.relationships
																			.map(r => r.contact_1.id)
																		const state = { variables: { cursor: index } }
																		pushNav({
																			url: location.pathname,
																			state,
																			query: ids,
																		}, index)
																		navigate(`/contacts/${currentRelationContact.id}`, { state })
																	}

																	props.onSelect(row)
																	if (props.setPrivateAccess) props.setPrivateAccess(null)
																}}
															>
																{state.editable && (
																	<TableCell
																		style={{
																			padding:'5px'
																		}}
																	>
																		{!hidden && <IconButton
																			aria-label="delete"
																			onClick={(e) => {
																				e.preventDefault()
																				e.stopPropagation()

																				let temp = [...contactInput.relationships]
																					.map((o) => ({...o}))

																				if (temp[index].id) {
																					temp.splice(index, 1)
																				} else {
																					temp = temp.filter(item => {
																						if ((item.contact_1.id == row.contact_1.id && item.contact_2.id == row.contact_2.id) ||
																							(item.contact_1.id == row.contact_2.id && item.contact_2.id == row.contact_1.id))
																							return false
																						return true
																					})
																				}

																				setContactInput({
																					...contactInput,
																					relationships: temp,
																				})

																			}}
																			size="large">
																			<ClearIcon style={isPrivate ? { color: 'white' } : null } />
																		</IconButton>
																		}
																	</TableCell>
																)}
																<TableCell
																	component="th"
																	scope="row"
																>
																	<ContactThumbnail contact={currentRelationContact}/>
																</TableCell>
																<TableCell>
																	{ (state.editable && !hidden) ?
																		<Select
																			IconComponent={ExpandMoreRoundedIcon}
																			name="type"
																			
																			input={<Dropdown />}
																			value={row.relationship_type_id}
																			style={ isPrivate ? { backgroundColor: 'transparent', color: 'white' } : {
																				backgroundColor: 'transparent'
																			}}
																			onClick={e => {
																				e.preventDefault()
																				e.stopPropagation()
																			}}
																			onChange={e => {

																				let temp = [...contactInput.relationships]
																					?.map((o) => ({...o}))

																				const index = temp.findIndex(item => item.id == row.id)
																				temp[index].relationship_type_id = e.target.value
																				temp[index].type = relationshipTypesSorted.find(type => type.id == e.target.value).value

																				setContactInput({
																					...contactInput,
																					relationships: temp,
																				})
																			}}
																		>
																			{relationshipTypesSorted?.map((type) => (
																				<MenuItem key={type.id} value={type.id}>
																					{type.value}
																				</MenuItem>
																			))}
																		</Select>
																		:
																		<span>
																			{hidden ? '-' : row.type}
																		</span>}
																</TableCell>

																<TableCell>
																	{ (state.editable && !hidden) ?
																		<TextBox multiline value={row.description}
																			style={ isPrivate ? { color: 'white', width: '-webkit-fill-available'
																			} : {width: '-webkit-fill-available'}} onClick={e => {
																				e.preventDefault()
																				e.stopPropagation()
																			}} onChange={e => {

																				let temp = [...contactInput.relationships]
																					.map((o) => ({...o}))

																				const index = temp.findIndex(item => item.id == row.id)

																				temp[index].description = e.target.value

																				setContactInput({
																					...contactInput,
																					relationships: temp,
																				})
																			}}/>
																		:
																		<div style={{overflow: "auto", maxHeight: "10em", whiteSpace: "pre-wrap"}}>
																			{hidden ? '-' : row.description}
																		</div>
																	}
																</TableCell>

																{!props.removeCreatedCol ? 
																	<TableCell>
																		{hidden ? '-' : <p style={ isPrivate ?
																			{ color: 'white', minWidth: '8em'} :
																			{minWidth: '8em'}}>
																			{ !row?.created_at ?
																				formatDate(new Date(), 'iii, MMMM do, yyyy') :
																				formatDate(row?.created_at, 'iii, MMMM do, yyyy') || "-"
																			}
																		</p>}
																	</TableCell> : null }
															</TableRow>
														)
													}
												)
											) : (
												<TableRow>
													<TableCell>
														{state.editable ? null : 'No relationships.' }
													</TableCell>
													<TableCell>
														{state.editable ? 'No relationships.' : null}
													</TableCell>
													<TableCell></TableCell>
													{state.editable ?
														<TableCell></TableCell>
														:null}
												</TableRow>
											)}
								</>
							)}
						</TableBody>
					</Table>
				</TableContainer>

				{state.editable ? (
					<div style={{ paddingLeft: '5em', paddingTop: '1em' }}>
						<Button
							onClick={() => {
								setRelationshipModal({
									open: true,
									relationship_1: null,
									relationship_2: null,
								})
							}}
						>
							<AddCircleOutlineIcon style={{ color: 'grey' }} />
							&nbsp; Add a new Relationship
						</Button>
					</div>
				) : null}
			</Paper>

			<TransitionsModal
				className="relationship-modal"
				open={relationshipModal.open}
				close={() =>
					setRelationshipModal({ ...relationshipModal, open: false })
				}
			>
				<form
					style={{ minWidth: '40em' }}
					onSubmit={(e) => {
						e.preventDefault()
						setAttempt(true)


						if (attempt && !(relationshipModal.relationship_1 !== null &&
							relationshipModal.relationship_2 !== null &&
							relationshipModal.relationship_2.contact_1 !==
							null &&
							relationshipModal.relationship_1
								.relationship_type_id !== null &&
							relationshipModal.relationship_2
								.relationship_type_id !== null &&
							relationshipModal.relationship_1
								.relationship_type_id &&
							relationshipModal.relationship_2
								.relationship_type_id
						)){
							openSnackbar(
								severity.WARNING,
								'Please fill fields in red.'
							)
						}

						if (
							relationshipModal.relationship_1 !== null &&
							relationshipModal.relationship_2 !== null &&
							relationshipModal.relationship_2.contact_1 !==
							null &&
							relationshipModal.relationship_1
								.relationship_type_id !== null &&
							relationshipModal.relationship_2
								.relationship_type_id !== null &&
							relationshipModal.relationship_1
								.relationship_type_id &&
							relationshipModal.relationship_2
								.relationship_type_id
						) {
							setContactInput({
								...contactInput,
								relationships: contactInput.relationships
									.concat(relationshipModal.relationship_2)
									.concat(relationshipModal.relationship_1),
							})

							setRelationshipModal({
								...relationshipModal,
								open: false,
							})

							setAttempt(false)
						}
					}}
				>
					<div
						className={
							attempt &&
								!relationshipModal.relationship_1?.contact_1
								? 'error'
								: null
						}
					>
						<LimitedAutocomplete

							query={loadContacts}
							setQuery={setLoadContacts}

							open={contactOpen && !!contactOptions?.length}

							onOpen={() => setContactOpen(true)}
							onClose={() => setContactOpen(false)}

							loading={contactLoading}
							isOptionEqualToValue={(a,b) => a?.id === b?.id}

							popupIcon={<ExpandMoreRoundedIcon />}
							options={contactOptions.filter(option => {
								return !contactInput.relationships.find(item => {
									return item.contact_1.id == option.id || item.contact_2.id == option.id
								}) && option.id != props?.contact?.id
							}) || []}

							renderOption={(props, option) => (
								<li {...props}>
									<ContactThumbnail contact={option} darkText={!props.contact?.is_private && !option.is_private}/>
								</li>
							)}

							getOptionLabel={getContactLabel}
							classes={classes}
							renderInput={(params) => (
								<>
									<h1
										style={props.contact?.is_private ? { marginBottom: '.5em', color: '#FFFFFF'} : { marginBottom: '.5em'}}
										className="card-title">
										Add Relationship
									</h1>
									<FormControl style={{ width: '100%' }}>
										<Label
											shrink
											id="relation-label"
											htmlFor="relation-autocomplete"
										>
										Related Contact*
										</Label>
										<TextBoxThinForAutocomplete
											autoFocus
											{...params}
											id="relation-autocomplete"
											variant="outlined"
											error={
												attempt &&
											!relationshipModal.relationship_1
												?.contact_1
											}
											fullWidth
											style={{ paddingTop: '1.5em' }}
											classes={{ notchedOutline: null }}
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{contactLoading ? (
															<CircularProgress
																color="inherit"
																size={20}
															/>
														) : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
											}}
										/>
									</FormControl>
								</>
							)}
							onChange={(event, value) => {
								setRelationshipModal({
									...relationshipModal,
									relationship_1: {
										...relationshipModal.relationship_1,
										contact_1: props.contact,
										contact_2: value,
									},
									relationship_2: {
										...relationshipModal.relationship_2,
										contact_1: value,
										contact_2: props.contact,
									},
								})
							}}
						/>
					</div>

					<div style={{ display: 'flex' }}>
						<div
							className={
								attempt &&
									!relationshipModal.relationship_1
										?.relationship_type_id
									? 'error'
									: null
							}
							style={{ flexGrow: '1', paddingRight: '1em' }}
						>
							<Autocomplete
								options={relationshipTypesSorted || null}
								getOptionLabel={(option) =>
									option?.value || option
								}
								filterSelectedOptions
								classes={classes}
								popupIcon={<ExpandMoreRoundedIcon />}
								renderInput={(params) => (
									<FormControl style={{ width: '100%' }}>
										<Label
											shrink
											id="relation-label"
											htmlFor="relation-autocomplete"
										>
											{props.contact.preferred_name} is {' '}
											{relationshipModal?.relationship_1
												?.contact_2?.preferred_name !==
												undefined
												? getContactName(relationshipModal
													?.relationship_1
													?.contact_2) + "'s"
												: null}
											...*
										</Label>
										<TextBoxForAutocomplete
											{...params}
											id="relation-autocomplete"
											variant="outlined"
											fullWidth
											error={
												attempt &&
												!relationshipModal
													.relationship_1
													?.relationship_type_id
											}
											style={{ paddingTop: '1.5em' }}
											classes={{ notchedOutline: null }}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</FormControl>
								)}
								onChange={(event, value) => {
									setRelationshipModal({
										...relationshipModal,
										relationship_1: {
											...relationshipModal.relationship_1,
											relationship_type_id: value?.id,
											type: value?.value,
											is_deleted: false,
										},
									})
								}}
							/>
						</div>

						<div
							className={
								attempt &&
									!relationshipModal.relationship_2
										?.relationship_type_id
									? 'error'
									: null
							}
							style={{ flexGrow: '1' }}
						>
							<Autocomplete
								options={relationshipTypesSorted || null}
								getOptionLabel={(option) =>
									option?.value || option
								}
								filterSelectedOptions
								classes={classes}
								popupIcon={<ExpandMoreRoundedIcon />}
								renderInput={(params) => (
									<FormControl style={{ width: '100%' }}>
										<Label
											shrink
											id="relation-label"
											htmlFor="relation-autocomplete"
										>
											{getContactName(relationshipModal?.relationship_1
												?.contact_2) ||
												'...'} {' '}
											is {props.contact.preferred_name}'s
											...*
										</Label>
										<TextBoxForAutocomplete
											{...params}
											id="relation-autocomplete"
											variant="outlined"
											fullWidth
											error={
												attempt &&
												!relationshipModal
													.relationship_2
													?.relationship_type_id
											}
											style={{ paddingTop: '1.5em' }}
											classes={{ notchedOutline: null }}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</FormControl>
								)}
								onChange={(event, value) => {
									setRelationshipModal({
										...relationshipModal,
										relationship_2: {
											...relationshipModal.relationship_2,
											relationship_type_id: value?.id,
											type: value?.value,
											is_deleted: false,
										},
									})
								}}
							/>
						</div>
					</div>

					<FormControl style={{ width: '100%' }}>
						<Label disableAnimation shrink>
							Description
						</Label>
						<TextBox multiline rows="4" style={{backgroundColor: 'transparent'}} value={relationshipModal.relationship_1?.description || ''} onChange={(e) => {
							setRelationshipModal({
								...relationshipModal,
								relationship_1: {
									...relationshipModal.relationship_1,
									description: e.target.value,
								},
								relationship_2: {
									...relationshipModal.relationship_2,
									description: e.target.value,
								}
							})
						}}/>
					</FormControl>

					<CancelButton
						variant="contained"
						style={{ float: 'left', marginTop: '1em' }}
						onClick={(e) => {
							e.preventDefault()
							setRelationshipModal({
								...relationshipModal,
								open: false,
							})
							setAttempt(false)
						}}
					>
						Cancel
					</CancelButton>

					<NewButton
						style={{ float: 'right', marginTop: '1em' }}
						type="submit"
						variant="contained"
					>
						Add
					</NewButton>
				</form>
			</TransitionsModal>

			<Menu
				keepMounted
				open={state.mouseY !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					state.mouseY !== null && state.mouseX !== null
						? { top: state.mouseY, left: state.mouseX }
						: undefined
				}
			>
				<MenuItem
					onClick={() => {
						props.onSelect(null)
						props.setQVOverride(null)
						handleClose('edit')
					}}
				>
					Edit
				</MenuItem>
			</Menu>
		</React.Fragment>
	)
}

export default withApollo(Relationships)
