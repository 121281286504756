import { gql } from '@apollo/client'
import { imageData, fileData, userDataCompressed, userData } from '../common/fragments'

export const SEARCH_ART = gql`
	query SearchArt(
		$field: String
		$direction: SortDirection
		$isActive: Boolean
		$statusId: String
		$categoryId: String
		$isFavorite: Boolean
		$isVerified: Boolean
		$modifiedStartDate: String
		$modifiedEndDate: String
		$specialFilter: String
		$cursor: Int
		$searchString: String
		$limit: Int
		$tagId: Int
		$salesperson_ids: [ID]
		$ready: Boolean
		$thumbnailResolution: String = "128x128"
	) {
		searchArt(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: {
					tagId: $tagId
					searchString: $searchString
					isActive: $isActive
					statusId: $statusId
					categoryId: $categoryId
					modifiedStartDate: $modifiedStartDate
					modifiedEndDate: $modifiedEndDate
					isFavorite: $isFavorite
					isVerified: $isVerified
					specialFilter: $specialFilter
					salesperson_ids: $salesperson_ids
					ready: $ready
				}
				thumbnailResolution: $thumbnailResolution
			}
		) {
			items {
				id
				title
				formatted_title
				isFavorite
				isFlagged
				is_private
				is_verified
				ready
				status_id
				categories {
					id
					value
					ordinal
				}

				catalogue_raisonne
				art_location {
					is_current
					location_change_at
					sub_location {
						name
					}
					location {
						name
					}
				}
				restrictedToUsers {
					id
					first_name
				}
				artist {
					id
					first_name
					last_name
				}
				medium
				year
				dimension {
					id
					description
				}
				inventory_number
				inventory_number_prefix
				inventory_number_suffix
				edition_copy_number
				edition_copy_total
				edition_ap
				edition_ap_total
				all_dimensions
				is_active
				status {
					id
					value
				}
				current_sources {
					id
					code_name
					first_name
					last_name
					is_company
					company_name
					is_deleted
					is_private
					imgUrl (thumbnailResolution: $thumbnailResolution)
					canNavigate
					isAllowedContactType
					dynamic_generated_codename
					created_at
					deal_entry_status_category
					deal_entry_status
					contact_role_id
				}
				profile_link
				created_at
				imgUrl (thumbnailResolution: $thumbnailResolution)
				primary_currency_id
				primaryRetailPrice
			}
			cursor
			totalItems
		}
	}
`

export const GET_ART_QV_DETAILS = gql`
	query GetArtDetails(
		$id: ID!, 
		$thumbnailResolution: String = "128x128", 
		$qvResolution: String = "1024x1024"
	) {
		getArtPiece(id: $id) {
			code
			success
			message
			severity
			art {
				id
				artist {
					id
					first_name
					last_name
				}
				title
				formatted_title
				year
				status_id
				is_active
				entity_id
				primary_currency_id
				primaryRetailPrice

				is_verified
				verified_at
				verified_by
				verified_text
				verified_user {
					...userDataCompressed
					gallery
				}
				inventory_number
				inventory_number_prefix
				inventory_number_suffix
				canViewOwnerContact
				current_sources {
					id
					code_name
					first_name
					last_name
					is_company
					company_name
					is_deleted
					is_private
					imgUrl (thumbnailResolution: $thumbnailResolution)
					is_deceased
					canNavigate
					isAllowedContactType
					dynamic_generated_codename
					created_at
					deal_entry_status_category
					deal_entry_status
					contact_role_id
				}
				is_private
				profile_link
				imgUrl (thumbnailResolution: $qvResolution)
				salesperson {
					...userDataCompressed
					is_lead
					is_deleted
				}
				created_at
				gallery_access {
					id
					value
					created_by
					created_at
					modified_by
					modified_at
					is_deleted
				}
			}
		}
		getCriticalArtNotes(input: { art_id: $id }) {
			id
			type_id
			applied_at
			applied_user_id
			note_text
			is_critical
			is_private
			is_deleted
			user {
				...userDataCompressed
				department
			}
		}
	}
	${userDataCompressed}
`

export const SIMPLE_SEARCH_ARTISTS = gql`
	query simpleSearchArtists($query: String) {
		simpleSearchArtists(query: $query) {
			id
			first_name
			last_name
			imgUrl (thumbnailResolution: "128x128")
			type_id
		}
	}
`

export const SIMPLE_SEARCH_ART = gql`
	query simpleSearchArt($query: String) {
		simpleSearchArt(query: $query) {
			id
			title
		}
	}
`

export const SIMPLE_SEARCH_CONTACTS = gql`
	query simpleSearchContacts($query: String) {
		simpleSearchContacts(query: $query) {
			id
			first_name
			last_name
			company_name
			is_company
			is_private
			created_at
			code_name
			imgUrl (thumbnailResolution: "128x128")
			is_deceased
			dynamic_generated_codename
			created_at
		}
	}
`

export const GET_ART_DETAILS = gql`
	query getArtPiece(
		$id: ID!
		$thumbnailResolution: String = "128x128"
	) {
		getArtPiece(id: $id) {
			code
			success
			message
			severity
			art {

				created_by
				created_at
				modified_by
				modified_at
				id
				
				is_deleted
				code_name
				is_private
				artist_id
				artist {
					id
					first_name
					last_name
					copyright
				}
				art_location {
					is_current
					location_change_at
					sub_location {
						name
					}
					location {
						name
					}
				}
				entity_id
				title
				formatted_title
				year
				signature
				status_id
				is_active
				is_verified
				verified_at
				verified_by
				verified_text
				customs_status
				isFavorite
				isFlagged
				additional_credit_lines
				photographer
				restrictedToUsers {
					id
					first_name
					last_name
					gallery
					title
					department
					imgUrl (thumbnailResolution: "128x128")
					assistants {
						id
						first_name
						last_name
						gallery
						imgUrl (thumbnailResolution: "128x128")
						permissions {
							permission_id
							permission_value_id
						}
					}
				}
				verified_user {
					...userDataCompressed
					gallery
				}
				inventory_number_prefix
				inventory_number
				inventory_number_suffix
				canViewOwnerContact
				current_sources {
					id
					code_name
					first_name
					last_name
					is_company
					company_name
					is_deleted
					is_private
					imgUrl (thumbnailResolution: "128x128")
					is_deceased
					canNavigate
					isAllowedContactType
					dynamic_generated_codename
					created_at
					deal_entry_status_category
					deal_entry_status
					contact_role_id
				}
				salesperson {
					...userDataCompressed
					is_lead
					is_deleted
				}
				medium
				all_dimensions
				dimension {
					id
					ordinal
					description
					depth_metric
					width_metric
					height_metric
				}

				edition_copy_number
				edition_copy_total
				edition_ap
				edition_ap_total

				insurance_id
				other_inventory_number
				categories {
					id
					value
					ordinal
				}

				primary_currency_id
				cost_split

				images {
					...imageData
				}

				operation_images {
					...imageData
				}

				profile_link
				imgUrl (thumbnailResolution: "1024x1024")

				tags {
					id
					label
					description
					color_hex
				}
				provenance {
					id
					art_id
					art_research_type_id
					art_research_type {
						id
						value
					}
					content
					formatted_content
					created_by
					created_at
					modified_by
					modified_at
					is_deleted
				}
				exhibition {
					id
					art_id
					art_research_type_id
					art_research_type {
						id
						value
					}
					content
					formatted_content
					created_by
					created_at
					modified_by
					modified_at
					is_deleted
				}
				literature {
					id
					art_id
					art_research_type_id
					art_research_type {
						id
						value
					}
					content
					formatted_content
					created_by
					created_at
					modified_by
					modified_at
					is_deleted
				}
				other {
					id
					art_id
					art_research_type_id
					art_research_type {
						id
						value
					}
					content
					formatted_content
					created_by
					created_at
					modified_by
					modified_at
					is_deleted
				}
				crate_description
				frame_description
				mount_description
				installation_description
				condition_description
				has_coa
				catalogue_raisonne

				primaryRetailPrice
				primaryCost
				primaryNet
				primaryInsured
				primaryProduction
				created_user {
					first_name
					last_name
				}
				modified_user {
					first_name
					last_name
				}
				import_id
				weight
				gallery_access {
					id
					value
					created_by
					created_at
					modified_by
					modified_at
					is_deleted
				}
				project_code
			}
		}
		getCriticalArtNotes(input: { art_id: $id }) {
			id
			type_id
			applied_at
			applied_user_id
			note_text
			is_critical
			is_private
			is_deleted
			user {
				...userDataCompressed
				department
			}
		}
		getArtCount
	}
	${imageData}
	${userDataCompressed}
`



export const UPDATE_ART = gql`
	mutation updateArtPiece (
		$UpdateArtInput: UpdateArtInput!, 
		$thumbnailResolution: String = "128x128",
		$notify: NotificationLevel
	) {
		updateArtPiece(input: $UpdateArtInput, notify: $notify) {
			code
			success
			message
			severity
			art {
				id
				code_name
				is_private
				additional_credit_lines
				photographer
				artist {
					id
					first_name
					last_name
					copyright
				}
				art_location {
					is_current
					location_change_at
					sub_location {
						id
						name
					}
					location {
						id
						name
					}
				}
				title
				entity_id
				formatted_title
				year
				status_id
				is_active
				inventory_number
				inventory_number_prefix
				inventory_number_suffix
				canViewOwnerContact
				current_sources {
					id
					code_name
					first_name
					last_name
					is_company
					company_name
					is_deleted
					is_private
					imgUrl (thumbnailResolution: "128x128")
					is_deceased
					canNavigate
					isAllowedContactType
					dynamic_generated_codename
					created_at
					deal_entry_status_category
					deal_entry_status
					contact_role_id
				}
				restrictedToUsers {
					id
					first_name
					last_name
					gallery
					title
					department
					imgUrl (thumbnailResolution: "128x128")
					assistants {
						id
						first_name
						last_name
						gallery
						imgUrl
						permissions {
							permission_id
							permission_value_id
						}
					}
				}
				salesperson {
					...userDataCompressed
					is_lead
					is_deleted
				}
				medium
				all_dimensions
				dimension {
					id
					ordinal
					description
					depth_metric
					width_metric
					height_metric
				}

				edition_copy_number
				edition_copy_total
				edition_ap
				edition_ap_total
				customs_status
				signature

				insurance_id
				other_inventory_number
				categories {
					id
					value
					ordinal
				}

				is_verified
				verified_at
				verified_by
				verified_text
				verified_user {
					...userDataCompressed
					gallery
				}

				images {
					...imageData
				}

				operation_images {
					...imageData
				}

				profile_link
				imgUrl (thumbnailResolution: "1024x1024")

				tags {
					id
					label
					description
					color_hex
				}

				crate_description
				frame_description
				mount_description
				installation_description
				condition_description
				has_coa
				catalogue_raisonne
				provenance {
					id
					art_id
					art_research_type_id
					art_research_type {
						id
						value
					}
					content
					formatted_content
					created_by
					created_at
					modified_by
					modified_at
					is_deleted
				}
				exhibition {
					id
					art_id
					art_research_type_id
					art_research_type {
						id
						value
					}
					content
					formatted_content
					created_by
					created_at
					modified_by
					modified_at
					is_deleted
				}
				literature {
					id
					art_id
					art_research_type_id
					art_research_type {
						id
						value
					}
					content
					formatted_content
					created_by
					created_at
					modified_by
					modified_at
					is_deleted
				}
				other {
					id
					art_id
					art_research_type_id
					art_research_type {
						id
						value
					}
					content
					formatted_content
					created_by
					created_at
					modified_by
					modified_at
					is_deleted
				}
				created_at
				weight
				gallery_access { id }
			}
		}
	}
	${imageData}
	${userDataCompressed}
`
export const BULK_UPDATE_ART = gql`
	mutation updateArtBulk (
		$input: BulkUpdateArtInput!
	) {
		updateArtBulk( input: $input) {
			code
			success
			message
			severity
		}
	}
`

export const BULK_UPDATE_CONTACTS = gql`
	mutation updateContactsBulk (
		$input: BulkUpdateContactsInput!
	) {
		updateContactsBulk( input: $input) {
			code
			success
			message
			severity
		}
	}
`


export const CREATE_ART_PIECE = gql`
	mutation CreateArtPiece($CreateArtPieceInput: CreateArtPieceInput!) {
		createArtPiece(input: $CreateArtPieceInput) {
			code
			success
			message
			art {
				id
			}
		}
	}
`

export const GET_ART_DATALOG = gql`
	query getArtDataLog(
		$field: String, 
		$direction: SortDirection, 
		$cursor: Int, 
		$id: ID!, 
		$date: String, 
		$items: [String], 
		$item_ids: [ID], 
		$actions: [Item], 
		$user_ids: [ID],
		$thumbnailResolution: String = "128x128"
	) {
		getArtDataLog(
			input: {
				field: $field
				direction: $direction
				limit: 10
				cursor: $cursor
				filters: { id: $id, date: $date, items: $items, item_ids: $item_ids, actions: $actions, user_ids: $user_ids }
			}
		) {
			cursor
			totalItems

			items {
				id
				art_id
				item
				item_id
				action
				diff
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				user {
					...userDataCompressed
					title
				}
			}
		}
	}
	${userDataCompressed}
`

export const UPDATE_ART_FILES = gql`
	mutation updateArtFiles(
		$UpdateFileInput: UpdateFileInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateArtFiles(input: $UpdateFileInput) {
			code
			success
			message
			files {
				...fileData
			}
		}
	}
	${fileData}
`

export const GET_LATEST_INVENTORY_NO = gql`
	query GetLatestInventoryNo($query: String) {
		getLatestInventoryNo(query: $query)
	}
`

export const GET_ART_PROJECT_CODE_HISTORY = gql`
	query GetArtProjectCodeHistory($input: ArtProjectCodeRequest) {
		getArtProjectCodeHistory(input: $input) {
			items {
				id
				project_code {
					id
					project_code
				}
				modified_by
				modified_at
				modified_user {
					...userData
				}
			}
			cursor
			totalItems
		}
	}
	${userData}
`

export const DELETE_ART = gql`
	mutation deleteArt($id: ID!) {
		deleteArt(id: $id) {
			code
			success
			message
		}
	}
`
