/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useRef } from 'react'
import HomePageCard from '../HomePageCard'
import Tasks from './HomeTasks'
import { permissionValues, permissions } from '../../constants/permissions'
import { withApollo } from '@apollo/client/react/hoc'
import { ITEMS_PER_HOME_PAGE_WIDGET } from '../../constants/values'
import { DYNAMIC_TASK_SEARCH } from '../../Search/Queries'
import { filterMap } from '../../Search/unifiedSearchHelpers'

const TasksParent = (props) => {

	// like the "active" - but we're using it inside a callback instead of a effect...
	const mounted = useRef(false)
	useEffect(() => {
		mounted.current = true 
		return () => mounted.current = false
	}, [])

	// Order of table
	const [cursor, setCursor] = React.useState(null)
	const [steps, setSteps] = React.useState(1)
	const [activeStep, setActiveStep] = React.useState(0)
	const [searchLoading, setSearchLoading] = React.useState(false)
	const [totalItems, setTotalItems] = React.useState(0)

	const [tasks, setTasks] = React.useState([])
	const [defaultSelection, setDefaultSelection] = React.useState([])

	React.useEffect(() => {
		if (!props.authState) return null

		let temp = ['unlinked']

		const userPermissions = props.authState?.user?.permissions

		if (userPermissions) {
			const artPermission = userPermissions
				.find(e => e.permission_id == permissions.ART_TASKS)
				?.permission_value_id
			if (artPermission >= permissionValues.VIEW_ONLY) 
				temp.push('art')

			const artistPermission = userPermissions
				.find(e => e.permission_id == permissions.ARTISTS_TASKS)
				?.permission_value_id
			if (artistPermission >= permissionValues.VIEW_ONLY)
				temp.push('artist')

			const contactPermission = userPermissions
				.find(e => e.permission_id == permissions.CONTACTS_TASKS)
				?.permission_value_id
			if (contactPermission >= permissionValues.VIEW_ONLY)
				temp.push('contact')

			const dealPermission = userPermissions
				.find(e => e.permission_id == permissions.DEALS_TASKS)
				?.permission_value_id
			if (dealPermission >= permissionValues.VIEW_ONLY) 
				temp.push('deal')

			const listingPermission = userPermissions
				.find(e => e.permission_id == permissions.LISTINGS_TASKS)
				?.permission_value_id
			if (listingPermission >= permissionValues.VIEW_ONLY)
				temp.push('listing')
		}
		setDefaultSelection(temp)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.authState])

	const handleSubmit = useCallback((cursor = 0) => {

		// If the user id falsy, don't execute the query yet
		if (!props.authState?.user?.id) return 

		setSearchLoading(true)

		const statusList = [
			'1', // Ready To Start
			'2', // In Progress
		]

		const variables = {
			cursor,
			limit: ITEMS_PER_HOME_PAGE_WIDGET,
			field: 'due_at',
			direction: 'ASC',
			filters: filterMap([
				{
				  "field": "linkEntity",
				  "type": "eq",
				  "value": defaultSelection.join(" OR ")
				},
				{
				  "field": "assigneeIds",
				  "type": "eq",
				  "value": props.authState.user.id.toString()
				},
				{
				  "field": "taskStatusId",
				  "type": "contains",
				  "value": statusList.join(" OR ")
				}
			])
		}

		props.client
			.query({
				query: DYNAMIC_TASK_SEARCH,
				variables
			})
			.then(result => {
				if (!mounted.current) return
				if (result.data.searchDynamicTasks != null) {

					setTasks(result.data.searchDynamicTasks?.items)
					setCursor(result.data.searchDynamicTasks?.cursor)
					setTotalItems(result.data.searchDynamicTasks?.totalItems)
		
					if (result.data.searchDynamicTasks.items.length < 1) setSteps(1)
					else setSteps(Math.ceil(result.data.searchDynamicTasks.totalItems / ITEMS_PER_HOME_PAGE_WIDGET))
				} 				

				setSearchLoading(false)

			})
			.catch(error => {
				console.log(error)
				if (mounted.current) { setSearchLoading(false) }
			})
	}, [defaultSelection, props.authState, props.client])

	// Search on page load
	useEffect(() => {
		handleSubmit(cursor)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultSelection])

	return (
		<HomePageCard
			title='My Tasks'
			paginate
			handleSubmit={handleSubmit}
			cursor={cursor}
			setCursor={setCursor}
			steps={steps}
			setSteps={setSteps}
			activeStep={activeStep}
			setActiveStep={setActiveStep}
			totalItems={totalItems}
		>
			<Tasks
				setCurrentTasks={setTasks}
				tasks={tasks}
				loading={
					searchLoading || props.userLoading || !props.authState?.user?.id
				}
				user={props.authState?.user}
			/>
		</HomePageCard>
	)
}

export default withApollo(TasksParent)
