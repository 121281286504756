/* eslint-disable eqeqeq */
import { CancelButton, SaveCardButton } from '../../input/Buttons'
import { Paper, Menu, MenuItem, IconButton, Tooltip, Fade } from '@mui/material'
import React, { useState, useEffect, useCallback, useContext } from 'react'
import { Skeleton } from '@mui/material'
import ReactQuill from 'react-quill'
import { addQuillTitles } from '../helpers'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { quillToolbar } from '../../constants/values'
import { FindModeInput } from '../../navigation/Tabs/TabbedPage'

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false,
}

export default function ResearchCard(props) {

	const [state, setState] = useState(initialState)
	const [entityInput, setEntityInput] = useState([])
	const [showCopy, setShowCopy] = useState(false)

	const [open, setOpen] = useState(false)
	const [tooltipTitle, setTooltipTitle] = useState(`Copy formatted ${props.title}`)

	const handleTooltipClose = () => setOpen(false)
	const handleTooltipOpen = () => setOpen(true)

	useEffect(() => {
		if (tooltipTitle == "Copied!") {
			setTimeout(() => {
				setTooltipTitle(`Copy formatted ${props.title}`)
			}, 2000)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tooltipTitle])
	
	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])


	const resetEntityInput = useCallback(() => {
		setEntityInput({
			[props.entityKey]: props.entity[props.entityKey]
		})
	}, [props.entity, props.entityKey])

	useEffect(() => {
		resetEntityInput()
	}, [props.entity, resetEntityInput])

	const handleClick = (event) => {
		if (state.mouseX || state.editable || props.findMode || !props.entity?.id) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const handleClose = (option) => {
		if (option === 'edit') {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	useEffect(() => {
		addQuillTitles()
	}, [state])

	const [quillText, setQuillText] = React.useState(null)
	useEffect(() => {
		if (quillText && quillText.editor) {
			const editor = quillText.getEditor()
			editor.focus()
			editor.setSelection(editor.getLength(), 0)
		}
	
	}, [quillText, state])


	return (
		<>
			<Paper
				style={{
					...props.style,
					padding: '1em 2em',
					margin: '0 1em 1em 0',
					overflow: 'auto'
				}}
				onContextMenu={(e) => !state.mouseX ? handleClick(e) : null}
				id='research'
				className={state.editable ? 'editable research' : 'research'}
				onMouseEnter={() => setShowCopy(true)}
				onMouseLeave={() => setShowCopy(false)}
				data-testid={`card-${props.title.toLowerCase().replace(" ", "-")}`}
			>
				<header
					style={{
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<h1 className="card-title fullWidth">
						<span>{props.title}</span>
												
						{!props.findMode && 
						<Tooltip open={open} 
							onClose={handleTooltipClose} 
							onOpen={handleTooltipOpen} 
							title={tooltipTitle} arrow placement="bottom"
						>
						
							<Fade in={showCopy || state.editable} {...{timeout: {
								enter: 500,
								exit: 100
							} }} >
								<IconButton
									onClick={() => {
										try {
											if (!quillText) {
												openSnackbar(severity.WARNING, "No content to copy.")
												return
											}

											const editor = quillText.getEditor()
											editor.focus()
											editor.setSelection(0, editor.getLength())
											document.execCommand("copy")
											editor.setSelection(null)

											setTooltipTitle("Copied!")
											openSnackbar(severity.SUCCESS, `${props.title} was copied to your clipboard.`)

										} catch (e) {
											console.error(e)
											openSnackbar(severity.ERROR, 'There was an error copying to your clipboard.')
										}

									}}
									size="small"
								>
									<FileCopyIcon style={{ color: props.private ? 'white' : 'grey', height: '20px' }}> </FileCopyIcon>
								</IconButton>
							</Fade>
						</Tooltip>
						}

						{state.editable && (
							<>
								<div className="spacer"></div>
								<CancelButton
									variant="contained"
									size="small"
									onClick={() => {
										handleClose()
										resetEntityInput()
									}}
								>
									Cancel
								</CancelButton>
								<SaveCardButton
									variant="contained"
									color="secondary"
									size="small"
									disabled={props.disabled}
									onClick={() => {
										const tempInput = {
											[props.entityKey]:{
												id: entityInput[props.entityKey]?.id,
												[props.linkField]: props.entity.id,
												[props.researchTypeField]: props.researchTypeId,
												content: entityInput[props.entityKey]?.content,
												formatted_content: entityInput[props.entityKey]?.formatted_content,
											}
										}

										props.update(tempInput)
										handleClose()
									}}
								>
									Save
								</SaveCardButton>
							</>
						)}
					</h1>
				</header>

				{props.loading ? (
					<>
						<Skeleton
							type="text"
							animation="wave"
							className="fullWidth"
						/>
						<Skeleton
							type="text"
							animation="wave"
							className="fullWidth"
						/>
					</>
				) : (
					<>
						{!props.findMode ? (
							<ReactQuill
								modules={{
									toolbar: state.editable
										? quillToolbar.toolbar
										: false,
								}}
								ref={(el) => {
									if (el) setQuillText(el)
								}}
								placeholder={!state.editable ? "-" : null}
								className={props.hangingIndent ? "hanging-indent" : null}
								theme="snow"
								value={entityInput[props.entityKey]?.formatted_content || { ops: [] }}
								readOnly={!state.editable}
								onChange={(content, delta, source, editor) => {
									setEntityInput({
										...entityInput,
										[props.entityKey]: {
											...entityInput[props.entityKey],
											formatted_content: editor.getContents(),
											content: editor.getText().trim(),
											[props.researchTypeField]: props.researchTypeId,
										},
									})
								}}
							/>
						) : (
							<FindModeInput
								style={{ width: '100%' }}
								field={props.entityKey}
							/>
						)}
					</>
				)}

				<Menu
					keepMounted
					open={state.mouseY !== null}
					onClose={handleClose}
					anchorReference="anchorPosition"
					anchorPosition={
						state.mouseY !== null && state.mouseX !== null
							? { top: state.mouseY, left: state.mouseX }
							: undefined
					}
				>
					<MenuItem onClick={() => handleClose('edit')}>
						Edit
					</MenuItem>
				</Menu>
			</Paper>
		</>
	)
}
