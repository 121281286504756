import React from 'react'

import UserGoalsPerYear from './UserGoalsPerYear'
import UserDetails from './UserDetails'
import UserAdvanced from './UserAdvanced'
import UserGroups from '../User/Details/UserGroups'

export default function AdminUser (props) {

	const user = props.state?.adminGetUser || {}
	const loading = props.loading
	const onChange = (update) => {
		const newState = {
			...props.state
		}
		newState.adminGetUser = {
			...newState.adminGetUser,
			...update
		}
		props.setState(newState)
	}

	
	return (
		<section className="main-page dark-theme">
			<div>
				<div className="row">
					<UserDetails
						loading={loading || !Object.keys(user).length} 
						user={user}
						setUser={onChange}
						findMode={props.findMode}
					/>
					<UserAdvanced
						loading={loading || !Object.keys(user).length} 
						contact={user}
						user={user}
						setUser={onChange}
						findMode={props.findMode}
					/>
				</div>
				<div className="row">
					<UserGoalsPerYear 
						loading={loading} 
						user={user}
						onChange={onChange}
						findMode={props.findMode}
					/>
					<UserGroups
						user={user} 
						loading={loading || !Object.keys(user).length} 
					/>
				</div>
			</div>
		</section>
	)
}
