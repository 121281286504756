import React, { useState, useEffect } from "react"
import { withApollo } from '@apollo/client/react/hoc'

import { FormControl, FormControlLabel, Checkbox } from '@mui/material'
import { Autocomplete } from '@mui/material'

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import Label from '../input/Label'
import { format } from 'date-fns'
import TextField from '@mui/material/TextField'
import TextBox from '../input/Text/TextBox'
import { GET_USERS } from '../Contacts/Contact/Queries'
import chunk from 'lodash/chunk'

const FaceSheetModal = (props) => {

	const listingContact = props?.options?.listingId
 
	const [date, setDate] = useState(new Date())
	const [galleryContacts, setGalleryContacts] = useState([])
	const [users, setUsers] = React.useState([])
	const [fields, setFields] = React.useState({
		showGalleryContacts: true,
		companyName: true,
		contactTypes: true,
		contactStatus: true,
		notes: true,
		bio: false,
		extraSpace: false,
	})

	const Labels = {
		showGalleryContacts: "Gallery Contacts",
		companyName: "Company Name",
		contactTypes: "Contact Type",
		contactStatus: "Contact Status",
		notes: "Critical Notes",
		bio: "Bio",
		listingStatus: "Listing Status",
		extraSpace: "Extra Space"
	}

	useEffect(() => {
		if (listingContact) {
			setFields(fields => ({
				...fields,
				listingStatus: false
			}))
		} else {
			setFields(fields => {
				const { listingStatus, ...newFields } = fields
				return newFields
			})
		}
	}, [listingContact])

	const selectorRows = chunk(Object.keys(fields).map( key => ({
		label: Labels[key],
		key: key,
		value: fields[key]
	}) ), Math.ceil( Object.keys(fields).length / 2 ))

	useEffect(() => {
		let active = true

		if (users.length) return

		props.client.query({
			query: GET_USERS,
			variables: { includeDeleted: true },
		}).then((result) => {
			if (active) {
				setUsers(result.data.getUsers.items)
			}
		}).catch(console.error)

		return () => {
			active = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		props.onChange({
			date,
			galleryContacts,
			...fields
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ date, fields, galleryContacts])

	return (
		<div>
			<div
				style = {{
					display: "flex",
					flexDirection: "column"
				}}
			>
				<FormControl fullWidth>
					<Label
						id="start-at-label"
						disableAnimation
						shrink
					>
					Start Date
					</Label>

					<MobileDatePicker
						inputFormat="MMM do, yyyy"
						disableMaskedInput={true}
						componentsProps={{
							actionBar: {
								actions: [ 'clear', 'accept']
							}
						}}
						name="date"
						value={date}
						inputVariant="outlined"
						fullWidth
						className="MUIDatePicker"
						variant="dialog"
						style={{ marginTop: '25px' }}
						renderInput={({ inputRef, inputProps, InputProps }) => {
								
							const newProps = { ...inputProps}

							newProps.readOnly = false

							return (
								<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
							)}}
						labelFunc={(date) => {
							if (date)
								return format(
									new Date(date),
									'iiii, MMMM do, yyyy'
								)
							else return ''
						}}
						onChange={(date) => {
							setDate(date)
						}}
					/>
				</FormControl>
				<FormControl style={{ width: '100%' }}>
					<Label
						id="gallery-contacts"
						disableAnimation shrink
					>
						Gallery Contacts
					</Label>

					<Autocomplete
						multiple
						filterSelectedOptions

						style={{ marginTop: '1.75em', maxWidth: '35em' }}
						// }}
						isOptionEqualToValue={(option, value) => {
							return option.id === value.id
						}}
						forcePopupIcon
						popupIcon={<ExpandMoreRoundedIcon />}
						size="small"
						getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
						options={users}
						value={galleryContacts}
						onChange={(_, value) => { setGalleryContacts(value) }}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								fullWidth
								classes={{ notchedOutline: null }}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
					/>
				</FormControl>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",

						paddingTop: '1.2em',
						marginRight: '1.3em',
						paddingLeft: '0.2em'
					}}
				>
					{selectorRows.map( (row, i) => (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
							}}
							key={`row-${i}`}
						>
							{
								row.map( (selector, j) => (
									<FormControlLabel
										label={selector.label}
										key={`checklabel-${j}`}
										control={
											<Checkbox
												checked={selector.value}
												name={`check-${j}`}
												onChange={(e)=>{
													setFields({
														...fields,
														[selector.key]: e.target.checked
													})
												}}
											/>
										}
									/>
								))
							}
						</div>
				 	))}
				</div>
			</div>
		</div>
	)
}

export default withApollo(FaceSheetModal)
