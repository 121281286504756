import routes from '../../navigation/routes'
import TabbedPage from '../../navigation/Tabs/TabbedPage'
import ContactQuickView from '../ContactQuickView'
import { GET_CONTACT_DETAILS } from './../Queries'
import { useParams } from 'react-router-dom'
import { DYNAMIC_CONTACT_SEARCH } from '../../Search/Queries'

export default function Contact(props) {
	const params = useParams()

	return TabbedPage({
		...props,
		routes: routes.contactPages.pages,
		Quickview: ContactQuickView,
		query: GET_CONTACT_DETAILS,
		searchGlobalQuery: DYNAMIC_CONTACT_SEARCH,
		options: routes.contactPages.options,
		params,
	})
}
