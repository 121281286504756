/* eslint-disable eqeqeq */
import { useMutation, useQuery } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import BusinessIcon from '@mui/icons-material/Business'
import PersonIcon from '@mui/icons-material/Person'
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import React, { useCallback, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import CollapsibleSearchCard from '../common/components/CollapsibleSearchCard'
import { SavedSearch } from "../common/SavedSearch"
import { CONTACT_SEARCH_OBJECT, DEFAULT_SEARCH_LIMIT } from "../constants/values"
import { DYNAMIC_CONTACT_NAV } from "../navigation/Queries"
import useNavigation from "../navigation/useNavigation"
import UserAccessQuickView from "../QuickView/UserAccessQuickView"
import { SELECT_ALL_CONTACTS } from "../Search/ActionButtons/Queries"
import GlobalSearchFilters, { getOperatorValue, initialFilterState } from "../Search/GlobalSearchFilters"
import { DYNAMIC_CONTACT_SEARCH, GET_CONTACT_FILTER_FIELDS, GET_SEARCHES, UPDATE_SEARCH, CONTACT_FLAG_QUERY } from "../Search/Queries"
import SearchQuickView from "../Search/SearchQuickView"
import { filterMap, filterSearchTerms, handleDeleteSearch, updateLabel } from "../Search/unifiedSearchHelpers"
import { severity } from '../Snackbar/CustomizedSnackbar'
import { AuthStateContext, DispatchContext } from '../store'
import { ContactDataGrid } from "./ContactDataGrid"
import ContactQuickView from "./ContactQuickView"
import CreateContactQuickView from "./CreateContactQuickView"
import SearchContacts from "./SearchContacts"

const Contacts = (props) => {

	const { push: pushNav } = useNavigation()
	const navigate = useNavigate()
	const location = useLocation()
	const userAuthentication = useContext(AuthStateContext)

	const prevSearch = location.state

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const closeSnackbar = () => {
		dispatch({ type: 'closeSnackBar'})
	}

	// Mutations
	const [updateSearch] = useMutation(UPDATE_SEARCH)

	// State that comes from prevSearch
	const [value, setValue] = React.useState(0)
	const [order, setOrder] = React.useState(prevSearch?.order || "asc")
	const [orderBy, setOrderBy] = React.useState(prevSearch?.orderBy || null)
	const [contacts, setContacts] = React.useState(prevSearch?.contacts ?? [])
	const [limit, setLimit] = React.useState(prevSearch?.limit ?? DEFAULT_SEARCH_LIMIT)
	const [activeStep, setActiveStep] = React.useState(prevSearch?.activeStep ?? 0)
	const [totalItems, setTotalItems] = React.useState(prevSearch?.totalItems ?? null)
	const [steps, setSteps] = React.useState(prevSearch?.steps ?? 1)
	const [selectedContact, setSelectedContact] = React.useState(null)
	const [advancedSearch, setAdvancedSearch] = React.useState(prevSearch?.advancedSearch ?? false)
	const [currentSearch, setCurrentSearch] = React.useState(prevSearch?.currentSearch ?? new SavedSearch({
		object_id: CONTACT_SEARCH_OBJECT,
		search_terms: [],
		is_global: true,
		user_id: userAuthentication.user?.id
	}))

	// Normal State
	const [flaggedCount, setFlaggedCount] = React.useState(null)
	const [firstRender, setFirstRender] = React.useState(true)
	const [selection, setSelection] = React.useState([])
	const [savedSearches, setSavedSearches] = React.useState([])
	const [searchLoading, setSearchLoading] = React.useState(null)
	const [createContact, setCreateContact] = React.useState(null)

	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)

	const openQuickView = () => dispatch({ type: 'openQuickView' })

	const handleActionClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleActionClose = () => {
		setAnchorEl(null)
	}

	const listingPrefs = [
		"-Mailing", "-Catalogue", "-Emailing",
		"-Do Not Email", "-Do Not Mail", "-Do Not Contact",
		"New York, US-Dinner", "New York, US-Event",
		"London, UK-Dinner", "London, UK-Event",
		"Paris, Europe-Dinner", "Paris, Europe-Event",
		"Hong Kong, Asia-Dinner", "Hong Kong, Asia-Event",
		"Zürich, Europe-Dinner", "Zürich, Europe-Event"
	].reduce((acc, el) => {
		const [section, option] = el.split('-')
		const label = `Preference - ${section || 'Global'} - ${option}`
		acc[el] = ({ type: 'Boolean', label, name: el })
		return acc
	}, {})


	const CreateContactMenu = () => (
		<Menu
			className="new-contact-actions"
			anchorEl={anchorEl}
			keepMounted
			open={open}
			onClose={handleActionClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<MenuItem
				onClick={() => {
					setCreateContact("company")
					openQuickView()
					handleActionClose()
				}}
			>
				<ListItemIcon style={{minWidth: 47}}>
					<BusinessIcon />
				</ListItemIcon>
				<ListItemText primary="Company" />
			</MenuItem>
			<MenuItem
				onClick={() => {
					setCreateContact("person")
					openQuickView()
					handleActionClose()
				}}
			>
				<ListItemIcon style={{minWidth: 47}}>
					<PersonIcon />
				</ListItemIcon>
				<ListItemText primary="Person" />
			</MenuItem>
		</Menu>
	)

	const setFilters = (filters) => {
		setCurrentSearch({
			...currentSearch,
			search_terms: filters
		})
	}

	const currentFilters = [
		'contact_status',
		'contactGrade',
		'contactActivity',
		'modified_at',
		'isFavorite',
		'gallery_contacts',
		'query'
	]

	const advancedFilters = filterSearchTerms(currentSearch.search_terms)?.filter(term => {
		if (currentFilters.includes(term.field)) return false
		return true
	})


	useEffect(() => {

		if (currentSearch.search_terms.length == 0 && advancedSearch) {
			setCurrentSearch(new SavedSearch({
				object_id: CONTACT_SEARCH_OBJECT,
				search_terms: [initialFilterState()],
				is_global: true,
				user_id: userAuthentication.user?.id
			}))
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSearch.search_terms, userAuthentication.user?.id, advancedSearch])

	// Search on change of order
	React.useEffect(() => {
		setFirstRender(false)
		if (contacts)
			if (contacts.length !== 0 && contacts[0].disabled !== true && !firstRender) {
				handleSubmit()
			}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order, orderBy, limit, activeStep])

	/**
	 * Save current page state for if one returns to it through
	 * back arrow.
	 *
	 * @returns current page state
	 */
	function updateHistory (index) {
		const currentPageState = {
			currentSearch,
			orderBy,
			order,
			contacts,
			limit,
			totalItems,
			steps,
			activeStep,
			advancedSearch,
			searchPage: location.pathname
		}
		const offset = index ? contacts.findIndex(a => a.id == index) : contacts.indexOf(selectedContact)
		const cursor = activeStep * limit + offset
		const variables = makeVariables(cursor, currentSearch, orderBy, order, currentSearch?.search_terms ?? [])
		variables.limit = 3
		currentPageState.variables = variables
		navigate(location.pathname, { state: currentPageState })
		return currentPageState
	}

	function saveAndGo(path, row) {
		// persist current page state in history
		const state = updateHistory(row)
		pushNav({
			url: location.pathname,
			state,
			query: DYNAMIC_CONTACT_NAV
		}, state.variables?.cursor)
		// navigate to new page (with this page's query and `variables`)
		navigate(path, { state })
	}

	// Save search to state on selecting a Contact
	React.useEffect(() => {
		if (selectedContact) updateHistory()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedContact])


	// Get saved searches
	const {loading, error } = useQuery(GET_SEARCHES, {
		skip:  !userAuthentication.user?.id,
		variables: {
			userId: userAuthentication.user?.id,
			objectId: CONTACT_SEARCH_OBJECT,
			global: true
		},
		onCompleted: (data) => {
			if (data && data.getSearches && data.getSearches?.length !== 0) {
				setSavedSearches(data.getSearches
					.map(search => new SavedSearch(search)))
			} else {
				// console.log("No saved searches were found.")
			}
		}
	})

	if (error) {
		console.log(error)
		openSnackbar(severity.ERROR, "Error retrieving your saved searches.")
	}


	const makeVariables = (cursor, currentSearch, orderBy, order, filters, limitOverride) => {
		return ({
			cursor,
			limit: limitOverride ?? limit,
			field: orderBy,
			direction: order.toUpperCase(),
			filters: filters ? filterMap(filters) : filterMap(currentSearch.search_terms),
			thumbnailResolution: "128x128"
		})
	}

	const handleSubmit = ({cursor} = {}, filters = null, searchId) => {
		setSearchLoading(true)
		let submitCursor
		if (cursor == undefined) {
			submitCursor = activeStep * limit
		} else {
			submitCursor = cursor
		}
		openSnackbar(severity.INFO, "Loading search results...")

		// translate the "preference" filters
		let filteredFilters
		if (filters) {
			const preferenceFilters = filters.filter(f => listingPrefs[f.field])
			const dontInclude = preferenceFilters
				.filter(f => f.value === 'false' || f.value === false)
				.map(f => `"${f.field}"`).join(" OR ")
			const doInclude = preferenceFilters
				.filter(f => f.value === 'true' || f.value === true)
				.map(f => `"${f.field}"`).join(" AND ")
			filteredFilters = filters.filter(f => !listingPrefs[f.field])
			if (dontInclude.length) {
				filteredFilters.push({
					field: 'listing_preferences',
					id: new Date(),
					isOptional: false,
					type: 'ncontains',
					value: dontInclude
				})
			}
			if (doInclude.length) {
				filteredFilters.push({
					field: 'listing_preferences',
					id: new Date(),
					isOptional: false,
					type: 'contains',
					value: doInclude
				})
			}
		}

		// replace any `.`s with ` `s - per our ES substition :
		// theplatform-server/docker-logstash/config/template.json line 39
		// https://jahnelgroup.atlassian.net/browse/LGWEBAPP-4441
		const searchTerms = filters?.map(f => ({
			...f,
			value: typeof f.value === 'string' ? f.value.replace(/\./g, ' ') : f.value
		}))

		const variables = makeVariables(
			submitCursor, currentSearch, orderBy, order, searchTerms
		)
		setValue(1)

		// Set filters when handle submit is not coming from saved search list
		if (filters?.length && !searchId) setFilters(filters)

		props.client
			.query({ query: DYNAMIC_CONTACT_SEARCH, variables })
			.then((result) => {

				const {data} = result

				if (data.searchDynamicContacts?.items) {
					setContacts(data.searchDynamicContacts.items)

					if (data.searchDynamicContacts.items < 1) setSteps(1)
					else setSteps((Math.ceil(data.searchDynamicContacts.totalItems / limit)))

					setTotalItems(data.searchDynamicContacts.totalItems || 0)
					setSearchLoading(false)
					if (!data.searchDynamicContacts.totalItems) {
						openSnackbar(severity.WARNING, "There were no results.")
					} else {
						closeSnackbar()
					}

				} else {
					console.error(data)
					setSearchLoading(false)
					openSnackbar(severity.ERROR, "There was an error searching contacts.")
				}
			})
			.catch((error) => {
				console.error(error)
				setSearchLoading(false)
				openSnackbar(severity.ERROR, "Could not search contacts.")
			})

	}

	const handleReset = (page) => {

		setCurrentSearch(new SavedSearch({
			object_id: CONTACT_SEARCH_OBJECT,
			search_terms: [],
			is_global: true,
			user_id: userAuthentication.user?.id
		}))
		setContacts([])
		setCreateContact(false)
		setSteps(1)
		setActiveStep(0)
		setTotalItems(null)
		setOrder("asc")
		setOrderBy(null)
		setSelectedContact(null)
		setSearchLoading(false)
		setValue(0)
	}

	const updateContactSavedSearchLabel = (search, label) =>
		updateLabel(
			search,
			label,
			updateSearch,
			savedSearches,
			setSavedSearches,
			openSnackbar
		)

	const handleDeleteContactSavedSearch = (search) =>
		handleDeleteSearch(
			search,
			updateSearch,
			savedSearches,
			setSavedSearches,
			openSnackbar
		)

	const hidden = selectedContact?.is_private && !selectedContact?.created_at
	const privateAccess = hidden ? selectedContact : false


	// Get the dynamic contact filter fields
	const { data: contactFilterFields, error: contactFilterError } = useQuery(GET_CONTACT_FILTER_FIELDS, {
		fetchPolicy: 'cache-first',
		onCompleted: ({searchDynamicContactsFilterFields}) => {
			const storedFields = location?.state?.fields
			if (storedFields) {
				setAdvancedSearch(true)
				const fieldFilters = Object.entries(storedFields)
					.map(([field, value], i) => {
						const filterField = searchDynamicContactsFilterFields
							.find(f => f.name == field)
						let type = getOperatorValue(filterField?.type) || 'eq'

						return {
							field,
							value,
							type,
							id: new Date().getTime() + i
						}
					})
				setFilters(fieldFilters)
				handleSubmit({}, fieldFilters)
			}
		}
	})


	if (contactFilterError) {
		openSnackbar(severity.ERROR, 'There was an error retrieving filterable fields for this search.')
		console.error(contactFilterError)
	}

	const renderQuickView = () => {
		if (createContact) {
			return (
				<CreateContactQuickView
					setCreateContact={setCreateContact}
					isCompany={createContact === 'company'}
				></CreateContactQuickView>
			)
		}

		else if (privateAccess) {
			return (
				<UserAccessQuickView
					entity={privateAccess}
					onClose={() => setSelectedContact(null)}
				></UserAccessQuickView>
			)
		}

		else if (selectedContact) {
			return (
				<ContactQuickView
					id={selectedContact.id}
					onClose={() => setSelectedContact(null)}
				></ContactQuickView>
			)
		}

		else {
			return (
				<SearchQuickView
					savedSearches={savedSearches}
					setSavedSearches={setSavedSearches}
					setCurrentSearch={setCurrentSearch}
					setFilters={setFilters}
					handleSubmit={handleSubmit}
					currentSearch={currentSearch}
					setAdvancedSearch={setAdvancedSearch}
					savedSearchesLoading={loading}
					updateLabel={updateContactSavedSearchLabel}
					handleDeleteSearch={handleDeleteContactSavedSearch}
					setSelection={setSelection}
					selection={selection}
					totalItems={totalItems}
					flaggedCount={flaggedCount}
					setFlaggedCount={setFlaggedCount}
					makeVariables={makeVariables}
					orderBy={orderBy}
					order={order}
					searchQuery={DYNAMIC_CONTACT_SEARCH}
					flagQuery={CONTACT_FLAG_QUERY}
					entity="contact"
					bulkActionQuery={SELECT_ALL_CONTACTS}
					setValue={setValue}
					value={value}
					filterFields={contactFilterFields?.searchDynamicContactsFilterFields}
					requery={handleSubmit}
				/>
			)
		}
	}

	const variables = makeVariables(0, currentSearch, orderBy, order)

	return (
		<>

			<section className="main-page">

				{ renderQuickView() }

				<div style={{
					display: 'flex',
					flexDirection: 'column'
				}}>
					<CollapsibleSearchCard>
						{advancedSearch ?
							<GlobalSearchFilters
								loading={searchLoading}
								currentSearch={currentSearch}
								setCurrentSearch={setCurrentSearch}
								onReset={handleReset}
								onSearch={handleSubmit}
								object_id={CONTACT_SEARCH_OBJECT}
								advancedSearch={advancedSearch}
								setAdvancedSearch={setAdvancedSearch}
								setCreateEntity={setCreateContact}
								filterFields={[...(contactFilterFields?.searchDynamicContactsFilterFields || []), ...Object.values(listingPrefs)]}
								typeName="Contact"
								handleActionClick={handleActionClick}
								createMenu={CreateContactMenu}
								onFindMode={() => navigate('/contacts/findmode/details')}
								savedSearches={savedSearches}
								setSavedSearches={setSavedSearches}
								prevSearch={prevSearch?.currentSearch}
							/> :
							<SearchContacts
								loading={searchLoading}
								currentFilters={currentSearch.search_terms}
								resetSearch={handleReset}
								currentSearch={currentSearch}
								setCurrentSearch={setCurrentSearch}
								handleSubmit={handleSubmit}
								setActiveStep={setActiveStep}
								setTotalItems={setTotalItems}
								advancedSearch={advancedSearch}
								setAdvancedSearch={setAdvancedSearch}
								setCreateContact={setCreateContact}
								advancedFilters={advancedFilters}
								selection={selection}
								setSelection={setSelection}
								handleActionClick={handleActionClick}
								createMenu={CreateContactMenu}
								onFindMode={() => navigate('/contacts/findmode/details')}
								prevSearch={prevSearch?.currentSearch}
								savedSearches={savedSearches}
								setSavedSearches={setSavedSearches}
							></SearchContacts>}
					</CollapsibleSearchCard>

					<ContactDataGrid
						rows={contacts}
						loading={searchLoading}
						saveAndGo={saveAndGo}
						setRows={setContacts}

						setFlaggedCount={setFlaggedCount}
						userId={userAuthentication.user?.id}
						mainSearchPage={true}
						totalItems={totalItems}
						onSelect={setSelectedContact}
						selectedRow={selectedContact}
						limit={limit}
						setLimit={setLimit}
						activeStep={activeStep}
						setActiveStep={setActiveStep}

						sortModel={ orderBy ? [{ field: orderBy, sort: order }] : []}
						onSortModelChange={newSort => {
							if (newSort.length) {
								setOrderBy(newSort[0].field)
								setOrder(newSort[0].sort)
							} else {
								setOrderBy(null)
								setOrder('asc')
							}
						}}
						// flagmenu
						filterTo={newFilter => {
							setSelection([])
							setFilters(newFilter)
							handleSubmit({cursor: 0}, newFilter)
							setValue(1)
							setSelectedContact(null)
						}}
						type="Contact"
						variables={variables}
					/>
				</div>
			</section>
		</>
	)
}

export default withApollo(Contacts)
