/* eslint-disable eqeqeq */
import { AuthStateContext, DispatchContext, LookupContext } from '../store'
import makeStyles from '@mui/styles/makeStyles'
import { FormControl, Select, MenuItem } from '@mui/material'
import { SaveButton, SearchButton, ResetButton, ChooseImageButton, AdvancedToggleButton } from '../input/Buttons'
import { MobileDatePicker } from '@mui/x-date-pickers-pro'
import Autocomplete from '@mui/material/Autocomplete'
import Dropdown from '../input/Dropdown/Dropdown'
import React, { useCallback, useContext, useEffect, useMemo } from "react"
import TextBox from '../input/Text/TextBox'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import CircularProgress from '@mui/material/CircularProgress'
import { withApollo } from '@apollo/client/react/hoc'
import { autoCompleteStyles, typeStyle } from '../styles/makesStyles'
import startCase from 'lodash/startCase'
import SearchInfo from "../Search/SearchInfo"
import { ControlPoint } from '@mui/icons-material'
import Label from '../input/Label'
import { TASK_SEARCH_OBJECT } from '../constants/values'
import { SavedSearch } from '../common/SavedSearch'
import { useMutation } from '@apollo/client'
import { CREATE_SEARCH } from '../Search/Queries'
import { handleSearchChange, saveMap, saveSearch, searchMap } from '../Search/unifiedSearchHelpers'
import TextBoxThinForAutocomplete from '../input/Text/TextBoxThinForAutocomplete'
import { GET_SEARCH_CARD_USERS } from '../User/Queries'
import ConfirmationInput from '../navigation/ConfirmationDialog/ConfirmationInput'
import { getGalleryContactLabel, randomHSL } from '../common/helpers'
import { severity } from '../Snackbar/CustomizedSnackbar'
import AdvancedFilterCount from '../Search/AdvancedFilterCount'
import { permissions } from '../constants/permissions'
import { getPermissionCanSee } from '../Tags/TagQuickView'

const useStyles = makeStyles(() => ({
	searchSelection: {
		width: "100%",
		paddingRight: "1em",
		"&:last-child": {
			paddingRight: "0em",
		}
	},
	searchBar: {
		paddingBottom: "1em"
	},
	searchDatePadding: {
		paddingRight: "1em",
	},
}))

function SearchTasks(props) {

	const classes = useStyles()
	const classes2 = autoCompleteStyles()

	const userAuthentication = useContext(AuthStateContext)

	// User options
	const [options, setOptions] = React.useState([])
	const [optionsDropdown, setOptionsDropdown] = React.useState(false)
	const optionsListLoading = optionsDropdown && options?.length === 1
	
	const [firstLoad, setFirstLoad] = React.useState(true)
	React.useEffect(() => setFirstLoad(false), [])

	const lookup = React.useContext(LookupContext)
	const taskTypes= lookup.data?.getTaskTypes
	const taskStatusTypes = lookup.data?.getTaskStatusTypes

	const dispatch = useContext(DispatchContext)
	const openQuickView = useCallback((severity, text) => {
		dispatch({ type: 'openQuickView' })
	}, [dispatch])

	const [currentSearch, setCurrentSearch] = React.useState(props.prevSearch ?? new SavedSearch({
		object_id: TASK_SEARCH_OBJECT,
		search_terms: [],
		is_global: true,
		user_id: userAuthentication.user?.id
	}))

	const [savedSearchDialog, setSavedSearchDialog] = React.useState({
		open: false,
		search: null
	})

	const getSearchValue = useCallback((fieldName) =>
		currentSearch.search_terms
			?.find(el => el.field == fieldName)?.value,
	[currentSearch.search_terms])

	
	// Snackbar
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const objectOptions = useMemo(() => {
		const permittedObjects = ['unlinked']

		const userPermissions = userAuthentication?.user?.permissions
		if (userPermissions) {
			if (getPermissionCanSee(userPermissions, permissions.ART_TASKS)) {
				permittedObjects.push('art')
			}
			if (getPermissionCanSee(userPermissions, permissions.ARTISTS_TASKS)) {
				permittedObjects.push('artist')
			}
			if (getPermissionCanSee(userPermissions, permissions.CONTACTS_TASKS)) {
				permittedObjects.push('contact')
			}
			if (getPermissionCanSee(userPermissions, permissions.DEALS_TASKS)) {
				permittedObjects.push('deal')
			}
			if (getPermissionCanSee(userPermissions, permissions.LISTINGS_TASKS)) {
				permittedObjects.push('listing')
			}
		}

		return permittedObjects
	}, [userAuthentication])

	// This populate the assignees autocomplete from saved searches 
	React.useEffect(() => {

		let active = true

		const names = getSearchValue('assignees')?.split(' AND ')

		if (active && options.length && getSearchValue('assignees')) {
			props.setSelection(options.filter(user => {
				if (names.includes([user.first_name, user.last_name].join(" "))) return true
				return false
			}))
		}

		return () => {
			active = false
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getSearchValue('assignees')])

	// This populate the linkEntity autocomplete from saved searches 
	React.useEffect(() => {

		let active = true

		const objects = getSearchValue('linkEntity')?.split(' OR ')

		if (active && objectOptions.length && getSearchValue('linkEntity')) {
			props.setEntitySelection(objectOptions.filter(object => {
				if (objects.includes(object)) return true
				return false
			}))
		}

		return () => {
			active = false
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getSearchValue('linkEntity')])

	React.useEffect(() => {
		let active = true

		if (active && !options.length) {
			props.client
				.query({
					query: GET_SEARCH_CARD_USERS,
				})
				.then(result => {
					if (active)
						setOptions(result.data.getSearchCardUsers)
				})
		}

		return () => {
			active = false
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleTaskSavedSearchChange = (event, type = 'eq') => {

		const search_terms = handleSearchChange(
			event,
			currentSearch,
			setCurrentSearch,
			type,
			true
		)

		if (props.currentSearch.id) { 
			props.setCurrentSearch({ 
				...currentSearch,
				search_terms,
				id: null 
			})
		}
	}

	// Mutations
	const [createSearch, { loading }] = useMutation(CREATE_SEARCH)

	useEffect(() => {
		if (props.currentSearch) setCurrentSearch(props.currentSearch)
	}, [props.currentSearch])


	return (
		<>
			<h1 className="card-title">Search Tasks

				<ChooseImageButton
					data-testid="new-task-button"
					variant="contained"
					size="small"
					style={{backgroundColor: !props.loading ? '#4465D1' : null}}
					onClick={() => {
						props.setCreateTask(true)
						openQuickView()
					}}
					endIcon={<ControlPoint />}
				>
					Create
				</ChooseImageButton>

				<AdvancedFilterCount
					advancedFilters={props.advancedFilters}
					setAdvancedSearch={props.setAdvancedSearch}
				/>
			
				<SearchInfo 
					advancedFilters={!!props.advancedFilters?.length}
					fields={["Linked Entity", "Task Description", "ID"]} style={{marginRight: 0}}
					recordName="task"
				/> 
			</h1>
			<form onSubmit={(e) => {
				e.preventDefault() 
				props.setActiveStep(0)
				props.handleSubmit(
					{ cursor: 0 }, 
					currentSearch.search_terms, 
					currentSearch.id
				)
			}}>
				<div className="row">
					<FormControl className={classes.searchSelection}>
						<Label id="status-label" style={typeStyle}
							disableAnimation shrink>
							Status
						</Label>
						<Select
							className="padded-select"
							IconComponent={ExpandMoreRoundedIcon}
							name="taskStatus"
							
							data-testid="search-status"
							labelId="status-label"
							input={<Dropdown />}
							value={getSearchValue('taskStatus') || ""}
							onChange={(event) => handleTaskSavedSearchChange(event)}
						>
							<MenuItem value="">All</MenuItem>
							{taskStatusTypes && taskStatusTypes.map(status => (
								<MenuItem 
									key={status.id}
									value={status.value.toString()}
									data-testid="search-status-item"
								>
									{status.value}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl className={classes.searchSelection}>
						<Label id="type-label" style={typeStyle}
							disableAnimation shrink>
							Type
						</Label>
						<Select
							className="padded-select"
							IconComponent={ExpandMoreRoundedIcon}
							name="taskType"
							
							data-testid="search-type"
							labelId="type-label"
							input={<Dropdown />}
							value={getSearchValue('taskType') || ""}
							onChange={(event) => handleTaskSavedSearchChange(event)}
						>
							<MenuItem value="">All</MenuItem>
							{taskTypes && taskTypes.map(type => (
								<MenuItem 
									key={type.id} 
									value={type.value.toString()}
									data-testid="search-status-item"
								>
									{type.value}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl className={classes.searchDatePadding}>
						<Label id="start-date-label" style={{
							...typeStyle,
							fontSize: 18
						}} disableAnimation shrink>
							Creation Date
						</Label>
						
						<MobileDatePicker
							inputFormat="MMM do, yyyy"
							disableMaskedInput={true}
							componentsProps={{
								actionBar: {
									actions: ['clear', 'accept']
								}
							}}
							name="created_at"
							value={getSearchValue('created_at') || null}
							inputVariant="outlined"
							className="MUIDatePicker"
							variant="dialog" 
							renderInput={({ inputRef, inputProps, InputProps }) => {
								
								const newProps = { ...inputProps}
	
								newProps.readOnly = false
	
								return (
									<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
								)}}
							onChange={(date) => handleTaskSavedSearchChange({
								target: {
									name: 'created_at',
									value: date ? date : ''
								}
							})}
							data-testid="search-created_date"
						/> 
					</FormControl>

					<FormControl>
						<Label id="start-date-label" style={typeStyle} disableAnimation shrink>
							Due Date
						</Label>
						
						<MobileDatePicker
							inputFormat="MMM do, yyyy"
							disableMaskedInput={true}
							componentsProps={{
								actionBar: {
									actions: ['clear', 'accept']
								}
							}}
							name="due_at"
							value={getSearchValue('due_at') || null}
							inputVariant="outlined"
							className="MUIDatePicker"
							variant="dialog" 
							renderInput={({ inputRef, inputProps, InputProps }) => {
								
								const newProps = { ...inputProps}
	
								newProps.readOnly = false
	
								return (
									<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
								)}}
							onChange={(date) => handleTaskSavedSearchChange({
								target: {
									name: 'due_at',
									value: date ? date : ''
								}
							})}
							data-testid="search-due_date"
						/> 
					</FormControl>
				</div>

				<div className="row">
					<FormControl style={{flexGrow: "1", marginRight: '1em'}}>
						<Label
							id="user-label"
							style={typeStyle}
							disableAnimation
							shrink
						>
						Assignees
						</Label>

						<Autocomplete
							multiple
							classes={classes2}
							style={{marginTop: "1.6em"}}
							open={optionsDropdown}
							isOptionEqualToValue={(option, value) => {
								return option.id == value.id
							}}
							forcePopupIcon
							filterSelectedOptions
							popupIcon={<ExpandMoreRoundedIcon />}
							value={props.selection ?? []}
							onOpen={() => { setOptionsDropdown(true) }}
							onClose={() => { setOptionsDropdown(false)}}
							getOptionLabel={(option) =>getGalleryContactLabel(option)}
							options={options || []}
							loading={optionsListLoading}
							onChange={(_, value) => {
								props.setSelection(value)
								const names = value.map(x=> [x.first_name, x.last_name].join(' ')).join(" AND ")
								handleTaskSavedSearchChange({
									target: {
										name: "assignees",
										value: names
									}
								})
							}}
							renderInput={(params) => (
								<TextBoxThinForAutocomplete
									{...params}
									variant="outlined"
									fullWidth
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{optionsListLoading ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>
							)}
							data-testid="search-assignees"
						/>

					</FormControl>

					<FormControl style={{flexGrow: "1"}}>
						<Label
							id="linkEntity-label"
							style={typeStyle}
							disableAnimation
							shrink
						>
						Objects
						</Label>

						<Autocomplete
							multiple
							classes={classes2}
							style={{marginTop: "1.6em"}}
							forcePopupIcon
							filterSelectedOptions
							popupIcon={<ExpandMoreRoundedIcon />}
							options={objectOptions}
							value={props.entitySelection}
							getOptionLabel={(option) => {
								return startCase(option)
							}}
							onChange={(_, value) => {
								props.setEntitySelection(value)
								const newValue = value.join(" OR ")
								handleTaskSavedSearchChange({
									target: {
										name: 'linkEntity',
										value: newValue
									}
								})
							}}
							renderInput={(params) => (
								<TextBoxThinForAutocomplete {...params} variant="outlined" />
							)}
							data-testid="search-objects"
						/>

					</FormControl>

				</div>

				<div className="row">
					<FormControl className={classes.searchBar} style={{ flexGrow: "1"}}>
						<Label style={typeStyle} disableAnimation shrink>
						Search
						</Label>
						<TextBox 
							name="query" 
							inputRef={input => input && firstLoad && input.focus()}
							value={getSearchValue('query') || ""} 
							onChange={(event) => handleTaskSavedSearchChange(event)} 
							data-testid="searchbar"
						/>
					</FormControl>
				</div>

				<div className="row">
					<div>
						<SaveButton
							data-testid="save-button"
							variant="contained"
							size="small"
							onClick={() => {
								saveSearch(
									currentSearch,
									openSnackbar,
									severity,
									setSavedSearchDialog
								)
							}}					
						>
                        Save Search
						</SaveButton>
					</div>
					<AdvancedToggleButton
						data-testid="advanced-toggle-button"
						size="small"
						onClick={() => {
							props.setCurrentSearch(currentSearch)
							props.setAdvancedSearch(true)
						}}
						style={{ marginRight: '1em' }}
					>
                    Advanced
					</AdvancedToggleButton>
					<ResetButton
						data-testid="reset-button"
						variant="contained"
						size="small"
						onClick={() => {
							props.setTotalItems(null)
							props.setSelection([])
							props.setEntitySelection([])
							props.resetSearch('simple')
							setCurrentSearch(new SavedSearch({
								object_id: TASK_SEARCH_OBJECT,
								search_terms: [],
								is_global: true,
								user_id: userAuthentication.user?.id
							}))
						}}
						style={{ marginRight: '1em' }}
					>
                    Reset
					</ResetButton>
					<SearchButton
						data-testid="search-button"
						variant="contained"
						size="small"
						type="submit"
						style={{backgroundColor: !props.loading ? '#4465D1' : null}}
						disabled={props.loading}
					>
                    Search
					</SearchButton>
				</div>
			</form>

			<ConfirmationInput
				open={savedSearchDialog.open}
				handleClose={() => setSavedSearchDialog({
					open: false,
					savedSearch: null
				})}
				title={'Save search?'}
				acceptText={'Save'}
				buttonColor="#33BF5C"
				inputLabel="Search Label"
				disabled={loading}
				placeholder={'Saved Search ' + (props.savedSearches?.length + 1)}
				onYes={(text) => {
					if (!loading) {
						createSearch({
							variables: {
								CreateSearchInput: {
									label: text ? text : 'Saved Search ' + (props.savedSearches?.length + 1),
									user_id: userAuthentication.user.id,
									object_id: TASK_SEARCH_OBJECT,
									color: randomHSL(),
									search_terms: searchMap(currentSearch.search_terms),
									result_count: null,
									is_global: true,
								},
							},
						}).then((response) => {
							setCurrentSearch({
								...response.data.createSavedSearch.search,
								search_terms: saveMap(response.data.createSavedSearch.search.search_terms)
							})

							props.setSavedSearches(props.savedSearches.concat(
								new SavedSearch(response.data.createSavedSearch.search))
							)
						})
					}
				}}
			/>
		</>
	)
}

export default withApollo(SearchTasks)
