// Common Deal Entry Status Categories
export const deal_entry_status_categories = {
	offers: 1,
	reserves: 2,
	purchases: 3,
	consignment_in: 4,
	consignment_out: 5,
	gallery_purchase: 6,
	loan_in: 7,
	loan_out: 8,
	known_ownership: 9
}
