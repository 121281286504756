/* eslint-disable eqeqeq */
import { Avatar, ButtonBase, CircularProgress, Fade, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useCallback, useContext, useEffect } from 'react'
import CreateIcon from '@mui/icons-material/Create'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { CancelButton, ChooseImageButton, SubmitButton } from '../input/Buttons'
import { uploadImages } from './upload'
import { withApollo } from '@apollo/client/react/hoc'
import { DispatchContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import AssignmentIcon from '@mui/icons-material/Assignment'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import BusinessIcon from '@mui/icons-material/Business'
import { Skeleton } from '@mui/material'
import Zoom from 'react-medium-image-zoom'
import { ReactComponent as ContactFilledSvg } from '../images/icons/White/ContactFilled.svg'

const AvatarInput = (props) => {
	const [editButton, setEditButton] = React.useState(false)
	const [modalOpen, setModalOpen] = React.useState(false)
	const [hideSource, setHideSource] = React.useState(false)

	const [dragging, setDragging] = React.useState(false)
	const [currentImage, setCurrentImage] = React.useState(null)
	const [loading, setLoading] = React.useState(false)
	const [entityInput, setEntityInput] = React.useState({
		[props.inputName]: {}
	})

	const [enterTarget, setEnterTarget] = React.useState(null)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	// Set image on object creation
	useEffect(() => {
		if (props.newEntity && props.entity?.profile_link && currentImage) {
			const image = document.getElementById('avatar-inner')
			image.src = URL.createObjectURL(currentImage)
		}
	}, [currentImage, props])

	const resetEntityInput = () => {
		setEntityInput({
			[props.inputName]: {}
		})
	}

	const close = () => {
		setModalOpen(false)
		setDragging(false)
		setCurrentImage(null)
		resetEntityInput()
		setLoading(false)
		setHideSource(false)
	}

	useEffect(() => {

		if (entityInput[props.inputName]?.profile_link) {
			setLoading(false)
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entityInput[props.inputName]])

	// Upload image.
	let handleUpload = useCallback(
		async (event) => {

			setLoading(true)
			setHideSource(false)
			
			if (event.target?.files) setCurrentImage(event.target.files[0])
			else setCurrentImage(event.dataTransfer.files[0])

			return uploadImages(props.client.query, event, null, props.oldProfileLink)
				.then((images) => {
					if (!!props.includeImages) {

						setEntityInput({
							[props.inputName]: {
								id: props.entityId,
								profile_link: images[0].key,
								images: images.map(image => ({
									...image,
									...props.extraFields
								})),
							},
						})
					} else {
						setEntityInput({
							[props.inputName]: {
								id: props.entityId,
								profile_link: images[0].key,
							},
						})
					}
					
				})
				.catch((error) => {
					console.error(error)
					openSnackbar(severity.ERROR, 'Error during upload.')
				})
		},
		[openSnackbar, props.client.query, props.entityId, props.extraFields, props.includeImages, props.inputName, props.oldProfileLink]
	)

	const onDragOver = (e) => {
		e.preventDefault()
		e.stopPropagation()
	}

	const onDragEnter = (e) => {
		e.preventDefault()
		e.stopPropagation()

		setEnterTarget(e.target)

		if (e.dataTransfer?.items && e.dataTransfer?.items?.length > 0) {
			setDragging(true)
		}
	}

	const onDragLeave = (e) => {
		e.preventDefault()
		e.stopPropagation()
		if (enterTarget == e.target) 
			setDragging(false)
	}

	// The profile image should be deletable in certain cases
	const shouldBeDeletable = (hideSource && !props.creation && !props.newEntity) 
	
	const onDrop = (e) => {
		e.preventDefault()
		e.stopPropagation()

		setDragging(false)
		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {

			if (e.dataTransfer.files.length > 1 && props.creation) {
				openSnackbar(severity.ERROR, "You cannot upload multiple images upon creation.")
				return
			}

			resetEntityInput()
			handleUpload(e)
			e.dataTransfer.clearData()
		}
	}

	const radius = () => {

		switch (props.entityName) {
		case "ArtPiece":
			return '0%'
		case "Listing":
			return "5%"
		default:
			return "50%"
		}
	}

	const buttonLocation = () => {
		switch (props.entityName) {
		case "ArtPiece":
		case "Listing":
			return {
				marginLeft: -17,
				marginRight: -17,
				top: '3.85em',
				right: '0.5em',
			}
		default:
			return {
				marginLeft: -17,
				marginRight: -17,
				top: '2.5em',
				right: '1em',
			}
		}
	}
	

	const useStyles = makeStyles(() => ({
		buttonBase: {
			borderRadius: radius(),
		},
		button: {
			backgroundColor: 'white',
			boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
			position: 'relative',
			height: 34,
			width: 34,
			'&:hover': {
				backgroundColor: 'white',
			},
			'&:focus': {
				backgroundColor: 'white',
			},
			padding: 8,
			...buttonLocation()
		},
		icon: {
			color: 'grey',
			height: '1.1em',
			width: '1.1em',
		},
		large: {
			width: 210,
			height: 210
		},
	}))

	const classes = useStyles()

	const darkTheme = props.onAdminConsole || props.onPrivateObject

	const getBackgroundColor = () => {
		if (darkTheme) return dragging ? '#3d3d3d' : 'inherit'

		return dragging ? '#e4e4e4' : 'inherit'
	}

	
	const variant = (type = 'avatar') => {

		if (type == 'avatar') {
			switch (props.entityName) {
			case "ArtPiece":
				return "square"
			case "Listing":
				return "rounded"
			default:
				return "circular"
			}
		}

		if (type == 'skeleton') {
			switch (props.entityName) {
			case "ArtPiece":
				return "rectangular"
			case "Listing":
				return "text"
			default:
				return "circular"
			}
		}	
	}

	const getIcon = () => {

		const style={
			height: '8em',
		}

		switch (props.entityName) {
		case "ArtPiece":
			return <img alt={'art-icon'} style={style}
				src={`/images/icons/White/ArtImage.svg`} />

		case "artist":
			return <img alt={'artist-icon'} style={style}
				src={`/images/icons/White/ArtistFilled.svg`} />

		case "Listing":
			return <AssignmentIcon style={{
				fontSize: 140
			}}></AssignmentIcon>

		default: {

			if (props.entity?.is_company || props.isCompany) {
				return <BusinessIcon style={{
					fontSize: 140
				}}/>
			}

			return <ContactFilledSvg alt={'contact-icon'} style={style} />
			// <img alt={'contact-icon'} style={style}
			// 	src={contactFilledSvg} />
		}}
	}

	const dropStyle = {
		height: '20em',
		width: '40em',
		border: dragging ? '4px dashed #4465D1' : '4px dashed #707070',
		backgroundColor: getBackgroundColor(),
		borderRadius: 8,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	}

	// Main avatar
	const avatar = 
	(
		<Avatar variant={variant()} className={props.className || classes.large} alt={props.alt} src={props.src}>
			{props.newEntity && props.entity?.profile_link ? <img style={{ height: 'inherit' }} alt={props.alt} id="avatar-inner" /> : null}
			{!props.entity?.profile_link && !props.src ? getIcon() : null  }
		</Avatar> 
	)

	const loadingAvatar = !props.entity?.created_at && !props.creation

	return (
		<>
			<div style={props.style} onMouseLeave={() => setEditButton(false)}>			

				{/* loading skeleton*/}
				{loadingAvatar ?
					<Skeleton animation="wave" 
						className={props.className || classes.large}
						variant={variant('skeleton')}
					/> : null
				}

				{/* creation */}
				{props.creation && !loadingAvatar ? <>
				
					<ButtonBase className={classes.buttonBase} onMouseEnter={() => setEditButton(true)} onClick={() => !props.src && props.editable && setModalOpen(true)}>
						{avatar}
					</ButtonBase>
			
				</> : null}


				{/* images exists */}
				{props.src && !props.creation && !loadingAvatar ? (
					<ButtonBase className={classes.buttonBase} component="div" onMouseEnter={() => setEditButton(true)} onClick={() => !props.src && props.editable && setModalOpen(true)}>
						<Zoom
							overlayBgColorEnd="rgba(0, 0, 0, 0.5)"
							overlayBgColorStart="rgba(255, 255, 255, 0)"
						>
							{avatar}
						</Zoom>
					</ButtonBase>

				) : null}

				{/* no image exists */}
				{!props.src && !props.creation && !loadingAvatar ? (
					<ButtonBase className={classes.buttonBase} onMouseEnter={() => setEditButton(true)} onClick={() => !props.src && props.editable && setModalOpen(true)}>
						{avatar}
					</ButtonBase>

				) : null}

				{/* edit button */}
				{(editButton && props.editable) || props.newEntity ? (
					<Fade in={true}>
						<IconButton
							variant="contained"
							color="primary"
							onClick={() => setModalOpen(true)}
							size="large"
							className={classes.button}
						>
							<CreateIcon className={classes.icon} style={{color: darkTheme ? 'grey' : null}}/>
						</IconButton>
					</Fade>
				) : null}

			</div>

			{/* edit modal */}
			<TransitionsModal open={modalOpen} close={() => close()}>
				<div className={darkTheme ? 'dark-theme' : null}>
					<h1 className="card-title">{props.newEntity ? <span>Add {props.entityName} profile image</span> : <span>Update {props.title}'s Profile Image</span>}</h1>

					<div style={dropStyle} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDragOver={onDragOver} onDrop={onDrop}>

						{ loading ? (
							<CircularProgress size={50} style={{ color: '#919191' }} />
						) : (
							<>
								{(currentImage || props.src) && !hideSource ? ( 
									<>
										{currentImage ? 
											<img alt="avatar-preview" id="avatar-preview" className={variant()} src={URL.createObjectURL(currentImage)}/> 
											: <img alt="old-profile-link" id="avatar-image" className={variant()} src={props.src}/>  }

										<IconButton
											variant="contained"
											color="primary"
											className={classes.button}
											style={{
												position: 'relative',
												top: '-7em',
												marginBottom: '-1em',
												left: '2.3em',
											}}
											onClick={() => {
												setHideSource(true)
												setEntityInput({
													[props.inputName]: {
														id: props.entityId,
														profile_link: null,
													},
												})
											}}
											size="large">
											<DeleteOutlineIcon className={classes.icon}  style={{color: darkTheme ? 'grey' : '#cc3333'}}/>
										</IconButton>
									</>
									
								) : (
									<>
										<span style={{ marginBottom: '0.5em', color: darkTheme ? 'white' : null }}>Drag an Image Here</span>
										<span style={{ marginBottom: '1em', color: darkTheme ? 'white' : null }}>– or –</span>
									</>
								)}

								<input
									accept="image/*"
									style={{ display: 'none' }}
									id="file-upload-modal"
									type="file"
									onChange={(e) => {
										resetEntityInput()
										handleUpload(e)
									}}
									multiple={false}
								/>
								<label htmlFor="file-upload-modal">
									<ChooseImageButton color="primary" variant="contained" aria-label="upload picture" component="span">
										Select an{currentImage ? 'other' : null} Image from your Computer
									</ChooseImageButton>
								</label>
							</>
						)}
					</div>

					<div
						style={{
							width: '100%',
							marginTop: '1em',
						}}
					>
						<CancelButton
							variant="contained"
							style={{ float: 'left' }}
							onClick={() => close()}
						>
							Cancel
						</CancelButton>

						<SubmitButton
							variant="contained"
							style={{ float: 'right' }}
							type="submit"
							disabled={(loading || (!entityInput[props.inputName]?.profile_link)) && !shouldBeDeletable}
							onClick={() => {
								if (!props.newEntity) {
									props
										.mutation({
											variables: entityInput,
										})
										.then(({ data }) => {
											const success = data?.updateSelf?.success ||
												data?.updateUser?.success || data[props.mutationName].success

											if (!success) throw data
											openSnackbar(severity.SUCCESS, 'Successfully updated avatar.')
											props.setEntity({
												...props.entity,
												...data[props.mutationName][props.entityName],
											})
										})
										.catch((error) => {
											const message = error?.updateSelf?.message || 'Error during avatar update.'
											console.error(error)
											openSnackbar(severity.ERROR, message)
										})
										.finally(() => {
											setModalOpen(false)
											setDragging(false)
											setCurrentImage(null)
											setEntityInput({})
										})
								} else {

									if (props.includeImages) {

										props.setEntity({
											...props.entity,
											profile_link: entityInput[props.inputName].profile_link,
											images: entityInput[props.inputName].images
										})
										
									} else {
										props.setEntity({
											...props.entity,
											profile_link: entityInput[props.inputName].profile_link,
										})
									}

									setModalOpen(false)
									setDragging(false)
									setEntityInput({})
								}
							}}
						>
							Submit
						</SubmitButton>
					</div>
				</div>
			</TransitionsModal>
		</>
	)
}

export default withApollo(AvatarInput)
