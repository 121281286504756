/* eslint-disable eqeqeq */
import React, { useContext, useState } from 'react'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { GLOBAL_SEARCH_MAX_SELECTION } from '../constants/values'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { DispatchContext } from '../store'
import { useLazyQuery } from '@apollo/client'
import BulkActions from './BulkActions'
import { atom, useRecoilState } from 'recoil'


export const bulkActionSelectionAtom = atom({
	key: 'bulkActionSelection',
	default: []
})

/**
 * @typedef {Object} BulkActionQuickViewProps
 * @property {number} flaggedCount number of flagged items of the containing entity type
 * @property {number} totalItems total number of items within the search rsults.
 * @property {string} entityType type of entity (art, artist, etc)
 * @property {function=} requery
 * @property {object[]} entities items selected to update
 * @property {function} setEntities function to update selected entities
 *
 */

/**
 * BulkActionQuickView
 * @param {BulkActionQuickViewProps} props
 * @returns
 */
export default function BulkActionQuickView (props) {

	const [entities, setEntities] = useRecoilState(bulkActionSelectionAtom)

	const [bulkActionType, setBulkActionType] = useState('flagged')
	const handleRadioChange = (e) => setBulkActionType(e.target.value)

	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({type: 'openSnackBar', payload: {severity, text}})
	}

	const [query, {loading}] = useLazyQuery(props.bulkActionQuery, {
		variables: props.makeVariables(
			0, props.currentSearch, null, 'asc', bulkActionType == 'flagged' ? [{
				field: "isFlagged",
				type: "eq",
				value: "true"
			}] : props.currentSearch?.search_terms, GLOBAL_SEARCH_MAX_SELECTION),
		onCompleted: data => {
			if (data.searchDynamicArt?.items ||
				data.searchDynamicContacts?.items ||
				data.searchDynamicDeals?.items ||
				data.searchDynamicArtists?.items ||
				data.searchDynamicListings?.items ||
				data.searchDynamicTasks?.items) {

				setEntities(data.searchDynamicArt?.items ||
						data.searchDynamicContacts?.items ||
						data.searchDynamicDeals?.items ||
						data.searchDynamicArtists?.items ||
						data.searchDynamicListings?.items||
						data.searchDynamicTasks?.items)
			}
		},
		onError: error => {
			console.error(error)
			openSnackbar(severity.ERROR, "There was an error searching.")
		}
	})

	const handleClick = (event) => {


		const { flaggedCount, totalItems } = props
		let actionType = props.bulkActionType || bulkActionType

		if (actionType == 'flagged' && flaggedCount == 0) {
			openSnackbar(severity.WARNING, "No flagged items.")
			return
		}

		if (actionType == 'results' && totalItems == 0) {
			openSnackbar(severity.WARNING, "No current results.")
			return
		}

		if (actionType == 'flagged' && flaggedCount > GLOBAL_SEARCH_MAX_SELECTION) {
			openSnackbar(severity.WARNING, `Cannot apply action on more than ${GLOBAL_SEARCH_MAX_SELECTION} entities.`)
			return
		}

		if (actionType == 'results' && totalItems > GLOBAL_SEARCH_MAX_SELECTION) {
			openSnackbar(severity.WARNING, `Cannot apply action on more than ${GLOBAL_SEARCH_MAX_SELECTION} entities.`)
			return
		}

		query()
	}

	let noneSelected
	if (bulkActionType === 'flagged') {
		noneSelected = props.flaggedCount <= 0
	} else {
		noneSelected = props.totalItems <= 0
	}

	return <>
		<div style={{
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			height: '100%',
			marginLeft: '1em',
			marginRight: '1em'
		}}>
			<div>
				<FormControl component='fieldset' focused={false}>
					<FormLabel
						component='legend'
						style={{
							padding: "1em",
							lineHeight: "normal",
						}}
					>
                        Would you like to apply bulk actions to all flagged entities
                        or all search results?
					</FormLabel>
					<RadioGroup
						style={{ padding: "0 4em" }}
						aria-label='bulk action type'
						name='bulkActionType'
						value={bulkActionType}
						onChange={handleRadioChange}
					>
						<FormControlLabel
							value='flagged'
							control={<Radio />}
							label={"Flagged (" + (props.flaggedCount || 0) + ")"}
						/>
						<FormControlLabel
							value='results'
							control={<Radio />}
							label={"Search Results (" + (props.totalItems || 0) + ")"}
						/>
					</RadioGroup>
				</FormControl>
			</div>
			<BulkActions
				selectedEntities={entities}
				entityType={props.entityType}
				onOpen={handleClick}
				requery={() => props.requery?.()}
				disabled={noneSelected}
				loading={loading}
			/>
		</div>
	</>

}
