import React, { useCallback, useContext, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { DispatchContext } from '../../../store'
import { UPDATE_CONTACT } from '../../Queries'
import { severity } from '../../../Snackbar/CustomizedSnackbar'
import PrivateAccessDetails from '../../../PrivateAccess/PrivateAccessDetails'
import PrivateAccessUsers from '../../../PrivateAccess/PrivateAccessUsers'
import { withApollo } from '@apollo/client/react/hoc'
import { GET_USERS_WITH_ACCESS } from '../../../QuickView/Queries'

function ContactAccess(props) {
	let { loading, error } = props
	const [users, setUsers] = React.useState([])

	const [contactInput, setContactInput] = React.useState({})

	const resetContactInput = () => {
		setContactInput({
			id: props.state?.getContact?.id,
			code_name: props.state?.getContact?.code_name,
			is_private: props.state?.getContact?.is_private,
			restrictedToUsersReplace: props.state?.getContact?.restrictedToUsers,
		})
	}

	useEffect(() => {
		if (props.state?.getContact) {
			resetContactInput()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.state])

	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	// eslint-disable-next-line no-unused-vars
	const [updateContact, { loading: updateLoading, error: mutationError }] = useMutation(
		UPDATE_CONTACT,
		{
			onError: (error) => {
				console.error(error)
				openSnackbar(severity.ERROR, 'An unknown error occurred.')
				// Reset State
				resetContactInput()
			},
			onCompleted: (response) => {
				if (response?.updateContact?.success === true) {
					openSnackbar(
						severity[response.updateContact.severity],
						response.updateContact.message
					)
					props.setAtPrivateObject(
						response.updateContact.contact.is_private
					)
					props.setState({
						getContact: {
							...props.state.getContact,
							...response.updateContact.contact
						},
					})
					getUsers(props.state.getContact.id, 2)
				}
				if (response?.updateContact?.success === false) {
					openSnackbar(
						severity.ERROR,
						"Could not update private details card."
					)
				}
			},
		}
	)

	if (mutationError) {
		console.error(mutationError)
		openSnackbar(
			severity.ERROR,
			mutationError.message
		)
	}

	const getUsers = (entity_id, objectTypeId) => {
		props.client
			.query({
				query: GET_USERS_WITH_ACCESS,
				variables: {
					objectId: entity_id,
					objectTypeId,
				},
			})
			.then((result) => {
				setUsers(result?.data?.getUsersWithAccess?.users || [])
			})
			.catch((error) => {
				console.error(error)
				openSnackbar(severity.ERROR, 'Error retrieving users with access.')
			})
	}

	const update = (variables) =>
		updateContact({ variables: { ContactInput: variables } })

	return (
		<>
			<PrivateAccessDetails
				loading={loading || updateLoading}
				error={error}
				input={contactInput}
				setInput={setContactInput}
				update={update}
				object="contact"
				findMode={props.findMode}
				placeholder={props.state?.getContact?.dynamic_generated_codename}
				publicAccess={props.state?.getContact?.publicAccess}
			></PrivateAccessDetails>

			<PrivateAccessUsers
				loading={loading || updateLoading}
				error={error}
				input={contactInput}
				setInput={setContactInput}
				entityType={'contact'}
				update={update}
				users={users}
				getUsers={getUsers}
				publicAccess={props.state?.getContact?.publicAccess}
			></PrivateAccessUsers>
		</>
	)
}

export default withApollo(ContactAccess)
