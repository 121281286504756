/* eslint-disable eqeqeq */
import { useEffect, useState } from "react"
import LimitedAutocomplete from "./LimitedAutocomplete"
import { autoCompleteErrorStyles } from "../styles/makesStyles"
import { ExpandMoreRounded } from "@mui/icons-material"
import { CircularProgress, TextField } from "@mui/material"
import { SIMPLE_SEARCH_PROJECT_CODES } from "../Listings/Queries"
import { useApolloClient } from "@apollo/client"

export default function ProjectCodeInput (props) {

	const client = useApolloClient()

	// Project Code
	const [loadProjectCodes, setLoadProjectCodes] = useState(false)
	const [projectCodesAutocompleteOpen, setProjectCodesAutocompleteOpen] = useState(false)
	const [projectCodesOptions, setProjectCodesOptions] = useState([])
	const projectCodesLoading = projectCodesAutocompleteOpen && !!loadProjectCodes

	const classes = autoCompleteErrorStyles()

	useEffect(() => {
		let active = true

		if (!projectCodesLoading) return undefined

		client.query({
			query: SIMPLE_SEARCH_PROJECT_CODES,
			variables: { query: loadProjectCodes },
		}).then((result) => {
			if (active) {
				setLoadProjectCodes(false)
				setProjectCodesOptions(result.data.simpleSearchProjectCodes)
			}
		})

		return () => active = false
	}, [projectCodesLoading, loadProjectCodes, client])

	return <LimitedAutocomplete
		query={loadProjectCodes}
		setQuery={setLoadProjectCodes}
		size="small"
		forcePopupIcon
		classes={classes}
		filterSelectedOptions
		popupIcon={<ExpandMoreRounded />}
		open={projectCodesAutocompleteOpen && !!projectCodesOptions?.length}
		onOpen={() => setProjectCodesAutocompleteOpen(true)}
		onClose={() => setProjectCodesAutocompleteOpen(false)}
		getOptionLabel={option => option?.project_code}
		options={projectCodesOptions}
		loading={projectCodesLoading}
		value={props.value || null}
		isOptionEqualToValue={(option, value) => option.id == value.id}
		onChange={props.onChange}
		renderInput={(params) => (
			<TextField
				{...params}
				id="project-code"
				variant="outlined"
				fullWidth
				error={props.attempt && !props.value}
				size="small"
				style={{ paddingTop: '1.8em' }}
				classes={{ notchedOutline: null }}
				InputLabelProps={{ shrink: true }}
				InputProps={{
					...params.InputProps,
					endAdornment: <>
						{projectCodesLoading ? (
							<CircularProgress
								color="inherit"
								size={20}
							/>
						) : null}
						{ params.InputProps.endAdornment }
					</>,
				}}
			/>
		)}
		data-testid="project-code"
	/>
}
