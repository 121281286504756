import React, {useContext, useCallback} from "react"
import NewNote from '../../../Notes/ObjectNotes/NewObjectNote'
import NoteList from '../../../Notes/ObjectNotes/ObjectNotesList'
import { GET_ART_NOTES, CREATE_NOTE, ART_UPDATE_NOTE } from './Queries'
import { isNullArray } from "../../../common/helpers"
import { DispatchContext } from "../../../store"
import { severity } from "../../../Snackbar/CustomizedSnackbar"
import { withApollo } from "@apollo/client/react/hoc"
import NoteSearch from "../../../Search/NoteSearch"
import { ART_NOTE_TYPE } from "../../../Notes/ObjectNotes/constants"

function ArtNotes(props) {

	const [notes, setNotes] = React.useState([])
	const [notesLoading, setNotesLoading] = React.useState(true)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	// Modal
	const [editNoteModal, setEditNoteModal] = React.useState({open: false, note: null})

	React.useEffect(() => {

		let active = true

		if (props.id && !props.findMode) {
			setNotesLoading(true)
			setNotes([])

			props.client
				.query({ query: GET_ART_NOTES, variables: { art_id: props.id}})
				.then(notesData => {
					if (active) {
						setNotesLoading(false)
						if (notesData?.data && !isNullArray(notesData?.data?.getArtNotes)) {
							setNotes(notesData?.data.getArtNotes || [])
						} else if (isNullArray(notesData?.data?.getArtNotes)) {
							setNotes([])
							openSnackbar(severity.ERROR, "Error - Unauthorized")
						}
					}
				})
		}
		return () => active = false
	}, [openSnackbar, props.client, props.findMode, props.id])

	return (
		<section className="main-page">
			{props.findMode ? 
				<NoteSearch type="art" />
				: <div>
					<NewNote
						entityId={props.id}
						notes={notes}
						setNotes={setNotes}
						notesLoading={notesLoading}
						mutation={CREATE_NOTE}
						mutationName="createArtNote"
						linkField="art_id"
						state={props.state}
						setState={props.setState}
						criticalNotes="getCriticalArtNotes"
						privateEntity={props.state?.getArtPiece?.is_private || false}
						noteType={ART_NOTE_TYPE}
					/>
					<NoteList
						editNoteModal={editNoteModal}
						setEditNoteModal={setEditNoteModal}
						state={props.state}
						setState={props.setState}
						notes={notes}
						setNotes={setNotes}
						notesLoading={notesLoading}
						mutationResponse="updateArtNote"
						updateMutationName={ART_UPDATE_NOTE}
						criticalNotes="getCriticalArtNotes"
						linkField="art_id"
						privateEntity={props.state?.getArtPiece?.is_private || false}
					/>
				</div>}
		</section>
	)
}

export default withApollo(ArtNotes)
