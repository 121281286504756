import routes from '../../navigation/routes'
import TabbedPage from '../../navigation/Tabs/TabbedPage'
import ListingQuickView from './ListingQuickView'
import { GET_LISTING_DETAILS } from '../Queries'
import { useParams } from 'react-router-dom'
import { DYNAMIC_LISTING_SEARCH } from '../../Search/Queries'

export default function Listing(props) {
	const params = useParams()

	return TabbedPage({
		...props,
		routes: routes.listingPages.pages,
		Quickview: ListingQuickView,
		query: GET_LISTING_DETAILS,
		searchGlobalQuery: DYNAMIC_LISTING_SEARCH,
		options: routes.listingPages.options,
		params,
	})
}
