/* eslint-disable eqeqeq */
import { CircularProgress } from '@mui/material'
import React from 'react'
import { ChooseImageButton } from '../Buttons'

export default function ImageInput(props) {

	const [dragging, setDragging] = React.useState(false)
	const [enterTarget, setEnterTarget] = React.useState(null)


	const onDragOver = (e) => {
		e.preventDefault()
		e.stopPropagation()
	}

	const onDragEnter = (e) => {
		e.preventDefault()
		e.stopPropagation()

		setEnterTarget(e.target)

		if (e.dataTransfer?.items && e.dataTransfer?.items?.length > 0) {
			setDragging(true)
		}
	}

	const onDragLeave = (e) => {
		e.preventDefault()
		e.stopPropagation()
		if (enterTarget == e.target) 
			setDragging(false)
	}

	const onDrop = (e) => {
		e.preventDefault()
		e.stopPropagation()

		setDragging(false)
		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			props.onChange(e)
			e.dataTransfer.clearData()
		}
	}


	const dropStyle = {

		textAlign: 'center',
		height: '15em',
		margin: '0',
		border: dragging ? '4px dashed #4465D1' : '4px dashed #707070',
		backgroundColor: dragging ? '#e4e4e4' : 'inherit',
		borderRadius: 8,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	}

	return (
		<div style={dropStyle} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDragOver={onDragOver} onDrop={onDrop}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: 'inherit',
					padding: '2em',
					width: 300,
					alignItems: 'center'
				}}
			>
				{props.loading ? <CircularProgress variant='indeterminate' size={50} style={{ color: '#919191' }} /> : 
				 <>
				 	<img alt="Cameras Icon" src={`/images/icons/Black/Cameras.svg`} />
						<hr
							style={{
								visibility: 'hidden',
								marginBlockStart: '0em',
								marginBlockEnd: '0em',
							}}
						/>
							Drag Images Here
						<hr
							style={{
								visibility: 'hidden',
								marginBlockStart: '0em',
								marginBlockEnd: '0.5em',
							}}
						/>
							– or –
						<hr
							style={{
								visibility: 'hidden',
								marginBlockStart: '0em',
								marginBlockEnd: '0.5em',
							}}
						/>
						<input accept="image/*" style={{ display: 'none' }} id="icon-button-file" type="file" onChange={props.onChange} multiple={true} />
						<label htmlFor="icon-button-file">
							<ChooseImageButton color="primary" variant="contained" aria-label="upload picture" component="span">
								Select Images from your Computer
							</ChooseImageButton>
						</label>
				 </>}
			</div>
		</div>
	)
}
