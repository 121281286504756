import React from "react"

const AdvancedFilterCount = ({ advancedFilters, setAdvancedSearch }) => {
	if (advancedFilters?.length)
		return (
			<span onClick={() => {
				setAdvancedSearch(true)
			}} className='advanced-filter-count-link'>
				{advancedFilters?.length} Advanced Filter
				{advancedFilters?.length > 1 ? "s" : ""}
			</span>
		)

	return null
}

export default AdvancedFilterCount
