import { useContext } from 'react'
import routes from '../navigation/routes'
import TabbedPage from '../navigation/Tabs/TabbedPage'
import UserQuickView from './UserQuickView'
import { GET_USER } from './Queries'
import { AuthStateContext } from '../store'

export default function User(props) {
	const authState = useContext(AuthStateContext)

	let params = { id: authState.user?.id }

	return TabbedPage({
		...props,
		routes: routes.userPages.pages,
		Quickview: UserQuickView,
		params,
		hideTabBar: false,
		query: GET_USER,
	})
}
