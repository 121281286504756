import routes from '../../navigation/routes'
import TabbedPage from '../../navigation/Tabs/TabbedPage'
import TagQuickView from '../TagQuickView'
import { GET_TAG_DETAILS } from './../Queries'
import { useParams } from 'react-router-dom'
import { DYNAMIC_TAG_SEARCH } from '../../Search/Queries'

export default function Tag(props) {
	const params = useParams()

	return TabbedPage({
		...props,
		routes: routes.tagPages.pages,
		Quickview: TagQuickView,
		query: GET_TAG_DETAILS,
		searchGlobalQuery: DYNAMIC_TAG_SEARCH,
		params,
		hideTabBar: true
	})
}
