/* eslint-disable eqeqeq */
import React, { useContext, useCallback, useState, useEffect } from 'react'
import { DispatchContext } from '../store'
import {
	IconButton,
	Radio,
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableRow,
	TableBody,
} from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { CancelButton, SaveButton } from '../input/Buttons'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { formatDate, formatter } from '../common/helpers'
import { useMutation, useQuery } from '@apollo/client'
import { severity } from '../Snackbar/CustomizedSnackbar'
import ContactThumbnail from '../Thumbnail/ContactThumbnail'
import { withApollo } from '@apollo/client/react/hoc'
import { GET_DEAL_ENTRY_QUEUE, UPSERT_DEAL_ENTRY } from './Queries'
import { PRIMARY_CONTACT_ROLE, REJECTED_STATUS } from './DealEntryList'
import GalleryContactList from '../common/components/GalleryContactList'

const formatPrice = (currency, amt) => (amt ? formatter(currency).format(amt) : null)

const DealEntryQueue = (props) => {

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	const [currentDealEntry, setCurrentDealEntry] = useState(null)

	const [dealEntryResult, setDealEntryResult] = useState([])
	const [saving, setSaving] = useState(false)

	const { loading } = useQuery(GET_DEAL_ENTRY_QUEUE, {
		variables: { 
			dealEntryStatusCategoryId:  props.dealEntryStatusCategoryId,
			entityId: props.entityId
		},
		skip: !props?.entityId || !props.dealEntryStatusCategoryId || !props.open,
		onCompleted: (response) => {
			setDealEntryResult(response.getDealEntryQueue)
		},
		onError: (error) => {
			console.error(error)
		}
	})

	const [orderedDealEntries, setOrderedDealEntries] = useState([])
	useEffect(() => {
		const originalDealEntries = dealEntryResult
			?.sort((a,b) => Number(a.reserve_position) - Number(b.reserve_position))
			?.map(de => de.id)
			?.filter(de => de)

		setOrderedDealEntries(originalDealEntries ?? [])
	}, [dealEntryResult])

	// Mutations
	const [updateRelation] = useMutation(UPSERT_DEAL_ENTRY)
	const handleSave = () => {
		setSaving(true)
		const rows = orderedDealEntries.map((id, index) => ({
			id,
			reserve_position: index + 1,
			has_reserve: index === 0,
			is_reserve_active: true
		}))
		openSnackbar(severity.INFO, 'Saving...')
		Promise.all(
			rows.map((variables, index) =>
				updateRelation({
					variables: {
						DealEntryRequest: variables,
						reserveQueueIndex: index
					},
				})
			)
		)
			.then((response) => {
				const success = response.every((r) => r.data?.upsertDealEntryV2?.success)
				if (success) {
					openSnackbar(severity.SUCCESS, 'Successfully updated entries.')
					props.handleSubmit(props.activeStep * props.limit)
					props.close()
					setSaving(false)
				} else throw response.map((r) => r.errors)
			})
			.catch((errors) => {
				console.error(errors)
				const message = errors.flat()[0]?.message
				openSnackbar(severity.ERROR, message ?? 'There was an error updating this entry.')
				setSaving(false)
			})
	}

	const handleUpClick = () => {
		let b = currentDealEntry
		let a = Math.max(b - 1, 0)
		;[orderedDealEntries[b], orderedDealEntries[a]] =
			 [orderedDealEntries[a], orderedDealEntries[b]]
		setOrderedDealEntries(orderedDealEntries)
		setCurrentDealEntry(a)
	}

	const handleDownClick = () => {
		let a = currentDealEntry
		let b = Math.min(a + 1, orderedDealEntries.length - 1)
		;[orderedDealEntries[b], orderedDealEntries[a]] =
			 [orderedDealEntries[a], orderedDealEntries[b]]
		setOrderedDealEntries(orderedDealEntries)
		setCurrentDealEntry(b)
	}

	return <TransitionsModal
		open={props.open}
		close={props.close}
		style={{ width: '70em' }}
	>
		<h1 className="card-title">Change Queue Order
			<div>
				<IconButton
					size="small"
					disabled={ currentDealEntry == 0 }
					onClick={ handleUpClick }
					data-testid="dimension-move-up"
				><ArrowUpwardIcon /></IconButton>
				<IconButton
					size="small"
					disabled={ currentDealEntry >= orderedDealEntries.length - 1 }
					onClick={ handleDownClick }
					data-testid="dimension-move-down"
				><ArrowDownwardIcon /></IconButton>
			</div>

			<div className="spacer"></div>

			<CancelButton
				variant="contained"
				onClick={props.close}
				style={{ display: 'flex', marginLeft: '1em', padding: '.2em'}}
			>Cancel</CancelButton>
			<SaveButton
				disabled={saving}
				onClick={handleSave}
			>Save</SaveButton>
		</h1>

		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell></TableCell>
						<TableCell>Reserve Position</TableCell>
						<TableCell>Primary Contact</TableCell>
						<TableCell>Gallery Contacts</TableCell>
						<TableCell>Reserve Expiration Date</TableCell>
						<TableCell>Counter Offer</TableCell>
						<TableCell>Offer Price</TableCell>
						<TableCell>Deal Status</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{dealEntryResult.length === 0 && !loading ? <TableRow>
						<TableCell></TableCell>
						<TableCell>Nothing in the reserve queue.</TableCell>
						<TableCell></TableCell>
						<TableCell></TableCell>
						<TableCell></TableCell>
						<TableCell></TableCell>
						<TableCell></TableCell>
						<TableCell></TableCell>
					</TableRow> : null }
					{ orderedDealEntries.map((entryIndex, index) => {
						const entry = dealEntryResult
							.find(de => de?.id == entryIndex)
							// This is in case re-renders happen before
							// the useEffect is called.
						if (!entry) return null
						const primaryContact = entry?.deal?.contacts
							?.find((e) => e.contactRole?.id == PRIMARY_CONTACT_ROLE)

						return <TableRow key={`dealEntry-${entry?.id}`}>
							<TableCell>
								<Radio
									checked={currentDealEntry == index}
									onChange={() => setCurrentDealEntry(index)}
									name="radio-button-dimension"
									inputProps={{ 'aria-label': 'current-dealEntry' }}
									data-testid={`dealEntry-select-${entry.id}`}
								/>
							</TableCell>
							<TableCell>
								{ index + 1 }
							</TableCell>
							<TableCell>
								<ContactThumbnail contact={primaryContact}/>
							</TableCell>
							<TableCell>
								<GalleryContactList
									salespersons={entry.deal?.salesperson}
									max={2}
								/>
							</TableCell>
							<TableCell>
								{ formatDate(entry?.reserve_end_at, 'MMM d, yyyy') }
							</TableCell>
							<TableCell>
								{ formatPrice(entry.counter_offer_currency?.label, entry.counter_offer_amount) || '-' }
							</TableCell>
							<TableCell>
								{ formatPrice(entry.offer_currency?.label, entry.offer_amount) || '-' }
							</TableCell>
							<TableCell>
								{ entry.deal_entry_status.id == REJECTED_STATUS && !entry.has_reserve ?
										`In Queue - ${entry.reserve_position}` :
									entry.deal_entry_status.value
								}
							</TableCell>
						</TableRow>
					})}
				</TableBody>
			</Table>
		</TableContainer>
	
	</TransitionsModal>
}

export default withApollo(DealEntryQueue)
