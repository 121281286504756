/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useCallback } from "react"
import { DispatchContext } from '../store'
import {
	CircularProgress,
	FormControl,
	Menu,
	MenuItem,
	Paper,
	Select
} from "@mui/material"
import Dropdown from '../input/Dropdown/Dropdown'
import Label from '../input/Label'
import TextBox from '../input/Text/TextBoxDark'
import { useMutation } from '@apollo/client'
import { UPDATE_USER } from "./Queries.js"
import { Autocomplete, Skeleton } from '@mui/material'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { CancelButton, SaveCardButton } from '../input/Buttons'
import PhoneInput from 'react-phone-input-2'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { LookupContext } from '../store'
import { GET_USERS } from '../Contacts/Contact/Queries'
import { withApollo } from '@apollo/client/react/hoc'
import { useNavigate } from 'react-router'
import { autoCompleteStyles, typeStyle } from '../styles/makesStyles'
import PhoneModal from "./PhoneModal"
import TextBoxThinForAutocomplete from "../input/Text/TextBoxThinForAutocomplete"
import { FindModeInput } from "../navigation/Tabs/TabbedPage"

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false
}

const initialUserInput = {
	id: undefined,
	phone_label1: undefined,
	phone_number1: undefined,
	phone_label2: undefined,
	phone_number2: undefined,
	email: undefined,
	identity_provider: undefined
}


const UserAdvanced = (props) => {

	const lookup = useContext(LookupContext)

	const classes2 = autoCompleteStyles()

	const galleries = lookup.data?.getGalleryTypes
	const identityProviders =  lookup.data?.getIdentityProviders

	const navigate = useNavigate()

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])


	const [state, setState] = React.useState(initialState)
	const [userInput, setUserInput] = React.useState(initialUserInput)
	const [attempt, setAttempt] = React.useState(false)

	const [open, setOpen] = React.useState(false)
	const [options, setOptions] = React.useState([])
	const [selection, setSelection] = React.useState([])
	const optionsListLoading = open && options?.length === props.user?.assistants?.length

	const [phoneOne, setPhoneOne] = React.useState(false)
	const [phoneTwo, setPhoneTwo] = React.useState(false)

	React.useEffect(() => {
		setOptions(props.user?.assistants || [])
		setSelection(props.user?.assistants || [])
	}, [props.user])

	React.useEffect(() => {
		let active = true
		if (!optionsListLoading) {
			return undefined
		}
		
		props.client
			.query({ query: GET_USERS})
			.then(result => {
				if (active) {
					setOptions(result.data.getUsers.items)	
				}
			})
		
		return () => {
			active = false
		}
	}, [optionsListLoading, props.client])


	const [updateUser, { loading, error }] = useMutation(UPDATE_USER, {
		onError: (error) => {
			// On failure, reset userInput state, don't touch user state and show error
			resetUserInput(props.user)
			handleClose()
		},
		onCompleted: (response) => {
			if (response.updateUser?.success === true) {
				// Success
				openSnackbar(severity.SUCCESS, response.updateUser.message)

				// On success change user state
				props.setUser({
					...props.user,
					...response.updateUser.user
				})
			} else {
				// On failure, reset userInput state, don't touch user state and show error
				resetUserInput(props.user)
				openSnackbar(severity.ERROR, response.updateUser.message)
			}
		}
	})
	// Loading, Error
	useEffect( () => {
		if(error?.graphQLErrors){
			const gqlErrors = error.graphQLErrors
			gqlErrors.forEach( (e) => {
				if(e.message === "autherror-admin"){
					openSnackbar(severity.ERROR, "Error: Editing user details requires admin login")
					navigate("/admin/login")
				}
			} )
		}
	}, [navigate, error, openSnackbar])

	useEffect(() => {
		if (loading) openSnackbar(severity.INFO, "Saving details card...")
	}, [error, loading, openSnackbar])
	
	const resetUserInput = useCallback((user) => {

		if (!user) return

		setUserInput({
			id: user.id,

			phone_label1: user.phone_label1,
			phone_number1: user.phone_number1,
			phone_label2: user.phone_label2,
			phone_number2: user.phone_number2,

			email: user.email,
			gallery_id: user.gallery_id,
			identity_provider: user.identity_provider,
			assistant_ids: selection.map(e => e.id)
		})
		
	}, [selection])

	React.useEffect(() => {
		resetUserInput(props.user)
	}, [props.user, resetUserInput])

	const handleClick = event => {
		if (props.findMode) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
			editable: false
		})
	}

	const handleClose = option => {
		if (option === "edit") {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	const skeletonStyle = {
		height: "4.5em", 
		marginTop: "0.8em", 
		marginBottom: "-0.6em",
	}

	return (
		<Paper
			className="qv-margin"
			onContextMenu={e => (!state.mouseX && !props.readOnly) ? handleClick(e) : null}
			id="user-details"
			data-testid="card-advanced"
		>
			<h1 className="card-title">
				<span>Advanced</span>
				{state.editable && (
					<>
						<div className="spacer"></div>
						<CancelButton
							variant="contained"
							size="small"
							onClick={() => {
								resetUserInput(props.user)
								handleClose()
								setAttempt(false)
							}}
						>
							Cancel
						</CancelButton>
						<SaveCardButton
							variant="contained"
							color="primary"
							size="small"
							disabled={loading}
							onClick={() => {

								setAttempt(true)

								if (userInput.email){
									delete userInput.gallery
									updateUser({ variables: { UserInput: userInput } })
									handleClose()
								}			

							}}
						>
							Save
						</SaveCardButton>
					</>
				)}
			</h1>

			<div className="user-details-body">
				<div className="user-details-column">

					<div className={!state.editable ? "plain-text" : null}>
						<div>
							<Label disableAnimation shrink style={{
								paddingTop: '0.4em',
								marginLeft: 0,
								marginBottom: '-0.4em',
							}}>
								Direct
							</Label>

							{!props.findMode ? <> 

								{(props.loading || props.error) ? <Skeleton
									animation={props.error ? false : "wave"}
									variant="text"
									width="auto"
									style={{
										height: "4.5em",
										marginTop: "-1.2em", 
										marginBottom: "-0.6em",
									}}
								/> : 
									<PhoneInput
										enableSearch
										disableDropdown
										placeholder=""
										disabled={!state.editable}
										value={userInput.phone_number1 || ''}
										dropdownStyle={{borderRadius: "4px"}}
										inputStyle={{ width: "100%", height: "41px", fontFamily: "graphik", fontSize: 'inherit'}}
										onChange={(value, data, event) => {
											setUserInput({
												...userInput,
												phone_number1: value
											})
										}}
									/>
								}
							</> : <FindModeInput field="phone_number1" largeInput style={{width: '100%', marginTop: 0}}/>}

						</div>
					</div>

					<div className={!state.editable ? "plain-text" : null}>
						<div>
							<Label disableAnimation shrink style={{
								paddingTop: '0.4em',
								marginLeft: 0,
								marginBottom: '-0.4em',
							}}>
								Mobile
							</Label>

							{!props.findMode ? <> 

								{(props.loading || props.error) ? <Skeleton
									animation={props.error ? false : "wave"}
									variant="text"
									width="auto"
									style={{
										height: "4.5em",
										marginTop: "-1.2em", 
										marginBottom: "-0.6em",
									}}
								/> : 
									<PhoneInput
										disableSearchIcon
										enableSearch
										disableDropdown
										placeholder=""
										disabled={!state.editable}
										value={userInput.phone_number2 || ''}
										dropdownStyle={{borderRadius: "4px"}}
										inputStyle={{width: "100%", height: "41px", fontFamily: "graphik",color: 'currentColor', fontSize: 'inherit'}}
										onChange={(value, event, data) => {
											setUserInput({
												...userInput, 
												phone_number2: value
											})
										}}
									/>
								}
							</> : <FindModeInput field="phone_number1" largeInput style={{width: '100%', marginTop: 0}}/>}

						</div>
					</div>

					<FormControl style={{width: '100%'}}>
						<Label disableAnimation shrink htmlFor="user-email" error={attempt && state.editable && !userInput?.email}>
							Email{!props.findMode && <span>*</span>}
						</Label>

						{!props.findMode ? <> 
							{(props.loading || props.error) ? <Skeleton
								animation={props.error ? false : "wave"}
								variant="text"
								width="auto"
								style={skeletonStyle}
							/> : 
								<TextBox
									required
									error={attempt && state.editable && !userInput?.email}
									className="user-input"
									id="user-email"
									value={userInput.email || ''}
									readOnly={!state.editable}
									placeholder={'-'}
									onChange={(e) => {
										setUserInput({
											...userInput,
											email: e.target.value
										})
									}}
								/>
							}
						</> : <FindModeInput field="email" largeInput/>}

					</FormControl>
				</div>

				<div className="user-details-column">

					<FormControl className="padded-select">
						<Label id="gallery-label" disableAnimation shrink error={attempt && state.editable && !userInput?.gallery_id}>
							Gallery{!props.findMode && <span>*</span>}
						</Label>
						
						{!props.findMode ? <> 
							{(props.loading || props.error) ? <Skeleton
								animation={props.error ? false : "wave"}
								variant="text"
								width="auto"
								style={skeletonStyle}
							/> : ( state.editable ?
								  <Select
									className="medium-selection"
									IconComponent={ExpandMoreRoundedIcon}
									name="field"
									
									labelId="department-label"
									input={<Dropdown />}
									value={userInput.gallery_id}
									onChange={(e) => {
										setUserInput({
											...userInput,
											gallery_id: e.target.value,
										})
									}}
								>
									{galleries.map((gallery) => (
										<MenuItem key={gallery.id} value={gallery.id}>
											{gallery.value}
										</MenuItem>
									))}
								</Select> : <TextBox
									className="user-input"
									id="user-gallery"
									value={galleries?.find(item => item.id === userInput.gallery_id)?.value || ''}
									readOnly={true}
									placeholder={'-'}
									onChange={(e) => {  }}
								/>
								
							)
							}
						</> : <FindModeInput field="gallery" largeInput/>}
					</FormControl>

					<FormControl style={{width: "100%"}}>
						<Label
							id="user-assistant"
							style={typeStyle}
							disableAnimation
							shrink
						>
							Assistants
						</Label>

						{!props.findMode ? <> 

							{(props.loading || props.error) ? <Skeleton
								animation={props.error ? false : "wave"}
								variant="text"
								width="auto"
								style={skeletonStyle}
							/> : 
								<>
									{state.editable ?
										<Autocomplete
											multiple
											classes={classes2}
											style={{marginTop: "1.75em"}}
											open={open}
											isOptionEqualToValue={(option, value) => {
												return option.id == value.id
											}}
											forcePopupIcon
											filterSelectedOptions
											popupIcon={<ExpandMoreRoundedIcon />}
											value={selection}
											disabled={!state.editable}
											onOpen={() => { setOpen(true) }}
											onClose={() => { setOpen(false)}}
											getOptionLabel={option => {
												try {
													return option.first_name + ' ' + option.last_name
												} catch {
													return "Loading..."
												}
											}}
											options={options?.filter(option => option.id != props.user?.id) || []}
											loading={optionsListLoading}
											onChange={(event, value) => {
												setSelection(value)
												setUserInput({
													...userInput,
													assistant_ids: value.map(e => e.id)
												})
											}}
							
											renderInput={(params) => (
												<TextBoxThinForAutocomplete
													{...params}
													variant="outlined"
													fullWidth
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<React.Fragment>
																{optionsListLoading ? <CircularProgress color="inherit" size={20} /> : null}
																{params.InputProps.endAdornment}
															</React.Fragment>
														),
													}}
												/>
											)}
										/>
										: 
										<TextBox
											multiline
											id="user-assistants"
											value={props.user?.assistants?.map(user => `${user.first_name} ${user.last_name}`).join(", ")}
											readOnly={true}
											placeholder={props.user?.assistants?.length ? '' : '-'}	
											style={{marginTop: '1em'}}
										/>
									}
								</>
							}
						</> : <FindModeInput field="assistants" largeInput/>}


					</FormControl>
					<FormControl style={{width: "100%"}}>
						<Label
							id="user-assistant-to"
							style={typeStyle}
							disableAnimation
							shrink
						>
							Access To:
						</Label>

						{!props.findMode ? <> 
							{(props.loading || props.error) ? <Skeleton
								animation={props.error ? false : "wave"}
								variant="text"
								width="auto"
								style={skeletonStyle}
							/> :
								<TextBox
									multiline
									id="user-assistant-to"
									value={props.user?.access_to?.map(user => `${user.first_name} ${user.last_name}`).join(", ") || ''}
									readOnly={true}
									placeholder={props.user?.access_to?.length ? '' : '-'}	
									style={{marginTop: '1em'}}
								/>}
						</> : <FindModeInput field="bosses" largeInput/>}

					</FormControl>

					<FormControl style={{width: "100%"}}>
						<Label
							id="idp-label"
							style={typeStyle}
							disableAnimation
							shrink
						>
							Identity Provider
						</Label>

						<Select
							IconComponent={ExpandMoreRoundedIcon}
							name="idp"
							labelId="idp-label"
							input={<Dropdown/>}

							value={userInput.identity_provider || ""}
							className={(!state.editable) ? "readonly padded-select" : null}

							readOnly={!state.editable}
							onChange={(e) => {
								setUserInput({
									...userInput,
									identity_provider: e.target.value,
								})
							}}
						>
							{identityProviders?.map(idp => (
								<MenuItem key={idp.id} value={idp.id}>{idp.display_name}</MenuItem>
							))}
						</Select>

					</FormControl>
				</div>
			</div>

			<Menu
				keepMounted
				open={state.mouseY !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					state.mouseY !== null && state.mouseX !== null
						? { top: state.mouseY, left: state.mouseX }
						: undefined
				}
			>
				<MenuItem onClick={() => handleClose("edit")}>Edit</MenuItem>

			</Menu>

			<PhoneModal 
				isOpen={phoneOne || phoneTwo}
				close={() => {
					setPhoneOne(false)
					setPhoneTwo(false)
				}}
				userInput={userInput}
				setUserInput={setUserInput}
				phoneOne={phoneOne}
				phoneTwo={phoneTwo}
			/>
		</Paper>
	)
}

export default withApollo(UserAdvanced)
