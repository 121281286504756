/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useEffect } from 'react'
import { Order } from '../common/typescriptVars'
import { Box, CircularProgress, IconButton, Paper, Skeleton, Tab, Tabs, Typography } from '@mui/material'
import { gql } from '@apollo/client'
import LimitedAutocomplete from '../common/LimitedAutocomplete'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { autoCompleteErrorStyles } from '../styles/makesStyles'
import TextBoxForAutocomplete from '../input/Text/TextBoxForAutocomplete'
import { SearchTerm } from '../common/SavedSearch'
import { DispatchContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { useApolloClient } from '@apollo/client'
import ShowcaseDataGrid from './ShowcaseDatagrid'
import { useNavigate } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import { styled } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'
import { elementReady } from '../common/helpers'
import { DYNAMIC_ARTISTS_SEARCH } from '../Search/Queries'
import { formatForFuzzySearch } from '../Search/unifiedSearchHelpers'

export const SHOWCASE_ARTISTS_SEARCH = gql`
	query searchDynamicArtists($field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int) {
		searchDynamicArtists(input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters }) {
			cursor
			totalItems
			items {
				id
				first_name
				last_name
			}
		}
	}
`

export const ShowcaseArtists = (props: any) => {
	type ArtistT = any
	interface ArtistData {
		data: {
			searchDynamicArtists: {
				items: ArtistT[]
				totalItems: number
				cursor: number
			}
		}
	}

	const DEFAULT_LIMIT = 50

	// Searching
	const [cursor, setCursor] = React.useState(0)
	const [artists, setArtists] = React.useState<ArtistT>([])
	const [loadedRows, setLoadedRows] = React.useState<any>([])
	const [limit, setLimit] = React.useState(DEFAULT_LIMIT)
	const [order, setOrder] = React.useState<Order>('asc')
	const [orderBy, setOrderBy] = React.useState('last_name')
	const [activeStep, setActiveStep] = React.useState(0)
	const [totalItems, setTotalItems] = React.useState(0)
	const [steps, setSteps] = React.useState(1)
	const [loading, setLoading] = React.useState(true)

	// Autocomplete
	const [artistOpen, setArtistOpen] = React.useState(false)
	const [artistOptions, setArtistOptions] = React.useState([])
	const [loadArtist, setLoadArtist] = React.useState(false)
	const artistLoading = artistOpen && !!loadArtist
	const [artistAutocomplete, setArtistAutocomplete] = React.useState<ArtistT | null>(null)

	const client = useApolloClient()
	const navigate = useNavigate()

	const [showSearch, setShowSearch] = React.useState<boolean>(false)
	const [tab, setTab] = React.useState<number>(0)

	const loadingSkeletons = Array.from({ length: 27 }, (_, i: number) => (
		<Typography variant='h4' key={i}>
			<Skeleton animation='wave' width={Math.random() * 5 + 5 + 'em'} />
		</Typography>
	))

	const StyledTabs = styled((props: any) => <Tabs {...props} TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }} indicatorColor='secondary' />)({
		'& .MuiTabs-indicator': {
			display: 'flex',
			justifyContent: 'center',
			backgroundColor: 'transparent',
		},
		'& .MuiTabs-indicatorSpan': {
			maxWidth: 40,
			width: '100%',
			backgroundColor: 'white',
		},
	})

	const StyledTab = styled((props: any) => <Tab disableRipple {...props} />)(({ theme }) => ({
		'&.Mui-selected': {
			color: 'black',
			fontWeight: 500,
		},
	}))

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity: string, text: string) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const closeSnackbar = () => {
		dispatch({ type: 'closeSnackBar' })
	}

	React.useEffect(() => {
		let active = true

		if (client && artistLoading) {
			client
				.query({
					query: DYNAMIC_ARTISTS_SEARCH,
					variables: { 
						limit: 10,
						cursor: 0,
						field: 'last_name',
						direction: 'ASC',
						filters: {
							field: 'full_name',
							type: 'like',
							value: loadArtist,
							isOptional: false
						}
					},
				})
				.then((result: any) => {
					if (active) {
						setLoadArtist(false)
						setArtistOptions(result.data.searchDynamicArtists?.items)
					}
				})
		}

		return () => {
			active = false
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadArtist])

	const handleSubmit = (cursorOverride: number, filters: SearchTerm[]) => {
		setLoadedRows([])

		setLoading(true)
		client
			.query({
				query: SHOWCASE_ARTISTS_SEARCH,
				variables: {
					cursor: cursorOverride ?? cursor,
					limit,
					field: orderBy,
					direction: order == 'asc' ? 'ASC' : 'DESC',
					thumbnailResolution: '128x128',
					filters,
				},
			})
			.then((result: ArtistData) => {
				const { data } = result

				setLoading(false)
				if (data.searchDynamicArtists?.items) {
					setTotalItems(data.searchDynamicArtists.totalItems || 0)
					setArtists(data.searchDynamicArtists.items)

					if (data.searchDynamicArtists.items?.length < 1) setSteps(1)
					else setSteps(Math.ceil(data.searchDynamicArtists.totalItems / limit))

					if (data.searchDynamicArtists.totalItems) closeSnackbar()
				} else {
					console.error(data)
					openSnackbar(severity.ERROR, 'There was an error searching showcase artists.')
				}
			})
			.catch((error: Error) => {
				setLoading(false)
				console.error(error)
				openSnackbar(severity.ERROR, 'There was an error retrieving showcase artists.')
			})
	}

	const currentFilters = (currentTab: number) => {

		// Affilliated With
		if (currentTab == 0) {
			return [
				{ field: 'is_affiliated', type: 'eq', value: 'true', isOptional: false }
			]
		}
		
		// All 
		else {
			return []
		}
	}

	// Initial page load query
	useEffect(() => {
		handleSubmit(0, currentFilters(0))

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleOnRowsScrollEnd = (params: any) => {


		if (!artists.length || loading) return
		if (totalItems <= DEFAULT_LIMIT) return

		setActiveStep(activeStep + 1)
		setCursor((activeStep + 1) * DEFAULT_LIMIT)

		let filters: any[] = []

		if (artistAutocomplete === null) {

			filters = [{ field: 'query', type: 'eq', value: '', isOptional: false }]
		} else if (typeof artistAutocomplete == 'string') {
			
			filters = [{ field: 'query', type: 'eq', value: formatForFuzzySearch(artistAutocomplete), isOptional: false }]
		} 

		client
			.query({
				query: SHOWCASE_ARTISTS_SEARCH,
				variables: {
					cursor: (activeStep + 1) * DEFAULT_LIMIT,
					limit,
					field: orderBy,
					direction: order == 'asc' ? 'ASC' : 'DESC',
					thumbnailResolution: '128x128',
					filters,
				},
			})
			.then((result: ArtistData) => {
				const { data } = result

				if (data.searchDynamicArtists?.items) {
					setLoadedRows(loadedRows.concat(data.searchDynamicArtists.items))

					if (data.searchDynamicArtists.items?.length < 1) setSteps(1)
					else setSteps(Math.ceil(data.searchDynamicArtists.totalItems / limit))

					setTotalItems(data.searchDynamicArtists.totalItems || 0)
					if (!data.searchDynamicArtists.totalItems) {
						openSnackbar(severity.WARNING, 'There were no more results.')
					} else {
						closeSnackbar()
					}
				} else {
					console.error(data)
					openSnackbar(severity.ERROR, 'There was an error searching showcase artists.')
				}
			})
			.catch((error: Error) => {
				console.error(error)
				openSnackbar(severity.ERROR, 'There was an error retrieving showcase artists.')
			})
	}

	return (
		<div
			style={{
				height: '100%',
			}}
		>
			<Paper className='qv-margin' sx={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(100% - 2em)', maxHeight: 'calc(100% - 2em)', overflow: 'hidden', height: 'fit-content' }}>
				{/* Autocomplete Card */}
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<div
						style={{
							flex: '1 0 0',
							display: 'flex',
						}}
					>
						{/* <IconButton color='primary' aria-label='menu button' component='span'>
							<MenuIcon />
						</IconButton> */}
					</div>

					<div
						style={{
							flex: '1 0 0',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '3em',
						}}
					>
						<LimitedAutocomplete
							style={{
								display: !showSearch ? 'none' : null,
							}}
							freeSolo
							query={loadArtist}
							setQuery={setLoadArtist}
							filterSelectedOptions
							forcePopupIcon
							popupIcon={<ExpandMoreRoundedIcon />}
							classes={autoCompleteErrorStyles()}
							open={artistOpen && !!artistOptions?.length}
							onOpen={() => setArtistOpen(true)}
							onClose={() => setArtistOpen(false)}
							getOptionLabel={(artist: any) => {
								// Free solo change
								if (typeof artist == 'string') return artist

								// User selected artist
								return `${[artist.first_name, artist.last_name].filter((e) => e).join(' ')}`
							}}
							options={artistOptions}
							loading={artistLoading}
							isOptionEqualToValue={(option: any, value: any) => option.id == value.id}
							value={artistAutocomplete}
							onChange={(event: any, value: any) => {
								setArtistAutocomplete(value)
								setLoading(true)

								if (value === null) {

									handleSubmit(0, currentFilters(tab))
									setArtistOptions([])
								} else if (typeof value == 'string') {
	
									handleSubmit(0, [
										...currentFilters(tab),
										{ field: 'query', type: 'eq', value: formatForFuzzySearch(value), isOptional: false }
									])
								} else {
	
									navigate(`/showcase/artists/${value.id}`)
								}				
							}}
							renderInput={(params: any) => (
								<TextBoxForAutocomplete
									{...params}
									id='artist-autocomplete'
									variant='outlined'
									fullWidth
									sx={{
										backgroundColor: 'white',
									}}
									classes={{ notchedOutline: null }}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{artistLoading ? <CircularProgress color='inherit' size={20} /> : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
										style: {
											width: '30em',
										},
									}}
								/>
							)}
							data-testid='art-artist'
						/>

						{!showSearch ?
							<img
								alt='Lévy Gorvy Dayan WordMark'
								src={`/images/LG_WORDMARK_LGDR.png`}
								style={{
									width: '346px',
									objectFit: 'contain',
									height: '3em',
								}}
							/> : null}
					</div>
					<div
						style={{
							flex: '1 0 0',
							display: 'flex',
							justifyContent: 'end',
						}}
					>
						<IconButton color='primary' aria-label='menu button' component='span' onClick={() => {
							setShowSearch(!showSearch)
							if (!showSearch) {
								setTab(1)
								
								setTotalItems(0)
								setActiveStep(0)
								setCursor(0)
								setArtists([])
								setSteps(1)
								setLoading(true)
								setLoadedRows([])	
								
								handleSubmit(0, [])
							
								elementReady('input.MuiAutocomplete-input')
									.then(autoComplete => 
										autoComplete.focus()
									)
							}
						}}>
							{!showSearch ? <SearchIcon /> : <CloseIcon /> }
						</IconButton>
					</div>
				</Box>

				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						padding: '2em 0',
					}}
				>
					<Typography
						variant='h4'
						style={{
							paddingLeft: 10,
						}}
					>
						ARTISTS
					</Typography>

					<StyledTabs value={tab} onChange={(_: any, value: any) => {

						setTab(value)

						setTotalItems(0)
						setActiveStep(0)
						setCursor(0)
						setArtists([])
						setSteps(1)
						setLoading(true)
						setLoadedRows([])

						// Affilliated With
						if (value == 0) {
							setShowSearch(false)
							setArtistAutocomplete(null)
							
							handleSubmit(0, [
								...currentFilters(value),
							])
						}
				
						// All Artists
						if (value == 1) {
							
							if (artistAutocomplete === null) {
				
								handleSubmit(0, currentFilters(value))
					
							} else if (typeof artistAutocomplete == 'string') {
					
								handleSubmit(0, [
									...currentFilters(value),
									{ field: 'query', type: 'eq', value: formatForFuzzySearch(artistAutocomplete), isOptional: false }
								])
							} 
						}
		
					}} aria-label='showcase artist tabs'>
						<StyledTab label='AFFILIATED WITH' />
						<StyledTab label='ALL ARTISTS' />
					</StyledTabs>
				</div>

				{!loading ? <ShowcaseDataGrid 
					route="artists" 
					rows={artists}
					loadedRows={loadedRows} 
					totalItems={totalItems} 
					handleOnRowsScrollEnd={handleOnRowsScrollEnd} 
					loading={loading}
				/> : loadingSkeletons}
			</Paper>
		</div>
	)
}

export default ShowcaseArtists
