/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useEffect } from "react"
import { FormControl, Select, MenuItem, IconButton, FormControlLabel, TextField } from '@mui/material'
import Label from '../input/Label'
import Dropdown from '../input/Dropdown/Dropdown'
import TextBox from '../input/Text/TextBox'
import { SaveButton, SearchButton, ResetButton, ChooseImageButton, AdvancedToggleButton } from '../input/Buttons'
import { AuthStateContext, DispatchContext, LookupContext } from '../store'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import { searchStyles, autoCompleteStyles } from "../styles/makesStyles"
import Autocomplete from '@mui/material/Autocomplete'
import { GET_USERS } from "../User/Queries"
import { withApollo } from "@apollo/client/react/hoc"
import SearchInfo from "../Search/SearchInfo"
import { getGalleryContactLabel, randomHSL, toTitleCase } from "../common/helpers"
import ControlPoint from '@mui/icons-material/ControlPoint'
import AdvancedFilterCount from '../Search/AdvancedFilterCount'
import { FindModeButton } from '../Search/GlobalSearchFilters'
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro"
import StartTextBox from "../input/Text/StartTextBox"
import EndTextBox from "../input/Text/EndTextBox"
import ConfirmationInput from "../navigation/ConfirmationDialog/ConfirmationInput"
import { CONTACT_SEARCH_OBJECT } from "../constants/values"
import { useMutation } from "@apollo/client"
import { CREATE_SEARCH } from "../Search/Queries"
import { handleLastModifiedChange, handleSearchChange, saveMap, saveSearch, searchMap } from "../Search/unifiedSearchHelpers"
import { SavedSearch } from "../common/SavedSearch"
import { severity } from "../Snackbar/CustomizedSnackbar"

const SearchContacts = (props) => {

	const classes = searchStyles()
	
	const typeStyle = {
		fontWeight: 500,
	}

	const [firstLoad, setFirstLoad] = React.useState(true)
	React.useEffect(() => setFirstLoad(false), [])

	const lookup = useContext(LookupContext)
	const contactStatus = lookup.data?.getContactStatus

	const [options, setOptions] = React.useState([])

	const autoCompleteStyle = autoCompleteStyles()

	const userAuthentication = useContext(AuthStateContext)
	const [currentSearch, setCurrentSearch] = React.useState(props.prevSearch ?? new SavedSearch({
		object_id: CONTACT_SEARCH_OBJECT,
		search_terms: [],
		is_global: true,
		user_id: userAuthentication.user?.id
	}))
	const [savedSearchDialog, setSavedSearchDialog] = React.useState({
		open: false,
		search: null
	})

	const handleContactSavedSearchChange = (event, type = 'eq') => {

		const search_terms = handleSearchChange(
			event,
			currentSearch,
			setCurrentSearch,
			type,
			true
		)

		if (props.currentSearch.id) {
			props.setCurrentSearch({
				...props.currentSearch,
				search_terms,
				id: null
			})
		}
	}

	const handleContactLastModifiedChange = (value) => {

		const search_terms = handleLastModifiedChange(
			value,
			currentSearch,
			setCurrentSearch
		)

		if (props.currentSearch.id) {
			props.setCurrentSearch({ 
				...props.currentSearch,
				search_terms,
				id: null
			})
		}

	}

	// Mutations
	const [createSearch, { loading }] = useMutation(CREATE_SEARCH)

	const dispatch = useContext(DispatchContext)
	
	// Snackbar
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	useEffect(() => {
		if (props.currentSearch) setCurrentSearch(props.currentSearch)
	}, [props.currentSearch])

	const getSearchValue = useCallback((fieldName) =>
		currentSearch.search_terms
			?.find(el => el.field == fieldName)?.value,
	[currentSearch.search_terms])

	React.useEffect(() => {
		let active = true

		if (active && !options.length) {
			props.client
				.query({
					query: GET_USERS,
					variables: {
						includeDeleted: true
					}
				})
				.then(result => {
					if (active)	setOptions(result.data.getUsers.items)
				})
				.catch(result => {
					if (active) console.error(result)
				})
		}

		return () => active = false
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// This populate the gallery contacts autocomplete from saved searches 
	React.useEffect(() => {

		const names = getSearchValue('gallery_contacts')?.split(' AND ')

		if (options.length && getSearchValue('gallery_contacts')) {
			props.setSelection(options.filter(user => 
				 names.includes([user.first_name, user.last_name].join(" "))
			))
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getSearchValue('gallery_contacts')])

	const CreateContactMenu = props.createMenu

	return <>
		<h1 className="card-title">
            
            Search Contacts
        
			<ChooseImageButton
				data-testid="new-contact-button"
				variant="contained"
				size="small"
				style={{backgroundColor: !props.loading ? '#4465D1' : null}}
				onClick={(e) => props.handleActionClick(e)}
				endIcon={<ControlPoint />}
			>
                Create
			</ChooseImageButton>

			<CreateContactMenu />

			<AdvancedFilterCount 
				advancedFilters={props.advancedFilters}
				setAdvancedSearch={props.setAdvancedSearch}
			/>
        
			<SearchInfo 
				advancedFilters={!!props.advancedFilters?.length}
				fields={["Contact Name (First, Last, Full)", "Company Name", "Code Name", "Preferred Name", "Foreign Preferred Name", "ID"]}
				recordName="contact"
				unified
			/> 
                
		</h1>
		<form onSubmit={(e) => {
			e.preventDefault() 
			props.setActiveStep(0)
			props.handleSubmit(
				{ cursor: 0 }, 
				currentSearch.search_terms, 
				currentSearch.id
			)
		}}>
			<div className="row">
				<FormControl className={classes.searchSelection}>
					<Label id="type-label" style={typeStyle} disableAnimation shrink>
                        Status
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						name="contact_status"
						
						className="padded-select"
						data-testid="search-status"
						labelId="type-label"
						input={<Dropdown />}
						value={ getSearchValue('contact_status') || '' }
						onChange={(event) =>
							handleContactSavedSearchChange(event)
						}
					>
						<MenuItem value="" data-testid="search-status-item">All</MenuItem>
						{contactStatus && contactStatus.map(type => (
							<MenuItem 
								key={type.id} 
								value={type.value}
								data-testid="search-status-item"
							>
								{toTitleCase(type.value)}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl className={classes.searchSelection}>
					<Label id="grade-label" style={typeStyle} disableAnimation shrink>
                        Grade
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						name="contactGrade"
						
						className="padded-select"
						data-testid="search-grade"
						labelId="grade-label"
						input={<Dropdown />}
						value={ getSearchValue('contactGrade') || '' }
						onChange={(event) =>
							handleContactSavedSearchChange(event)
						}
					>
						{[
							<MenuItem value="" data-testid="search-grade-item">All</MenuItem>,
							<MenuItem value="1">1</MenuItem>,
							<MenuItem value="2">2</MenuItem>,
							<MenuItem value="3">3</MenuItem>,
							<MenuItem value="4">4</MenuItem>,
							<MenuItem value="5">5</MenuItem>
						].map( (item, i) => (  <MenuItem {...item.props} data-testid="search-grade-item" key={i} ></MenuItem> ))  }

					</Select>
				</FormControl>

				<FormControl className={classes.searchSelection}>
					<Label id="activity-label" style={typeStyle} disableAnimation shrink>
                        Activity
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						name="contactActivity"
						
						className="padded-select"
						data-testid="search-activity"
						labelId="activity-label"
						input={<Dropdown />}
						value={ getSearchValue('contactActivity') || '' }
						onChange={(event) => {

							const value = event.target.value

							if (value === "$isNull") {
								handleContactSavedSearchChange(event, value)
							}
                                
							else handleContactSavedSearchChange(event)
						}}
					>
						{[
							<MenuItem value="">All</MenuItem>,
							<MenuItem value="$isNull">None</MenuItem>,
							<MenuItem value="A">A</MenuItem>,
							<MenuItem value="B">B</MenuItem>,
							<MenuItem value="C">C</MenuItem>,
							<MenuItem value="D">D</MenuItem>,
							<MenuItem value="F">F</MenuItem>,
							<MenuItem value="U">U</MenuItem>
						].map( (item, i) => <MenuItem {...item.props} data-testid="search-activity-item" key={i}></MenuItem> )}
					</Select>
				</FormControl>

				<MobileDateRangePicker
					inputFormat="MMM do, yyyy"
					disableMaskedInput={true}
					componentsProps={{
						actionBar: {
							actions: ['clear', 'accept']
						}
					}}
					value={[
						currentSearch.search_terms
							.find(el => el.field == 'modified_at' && el.type == 'gte')?.value || null,
						currentSearch.search_terms
							.find(el => el.field == 'modified_at' && el.type == 'lte')?.value || null
					]}
					onChange={handleContactLastModifiedChange}
					renderInput={(startProps, endProps) => {
						

						const newStartProps = { ...startProps}
						const newEndProps = { ...endProps}

						newStartProps.inputProps.placeholder = "Start"
						newEndProps.inputProps.placeholder = "End"
						
						return (
							<div style={{
								width: '-webkit-fill-available'
							}}>
								<Label
									id="date-label"
									style={{
										...typeStyle,
										fontSize: 18,
										paddingTop: '0.45em',
										marginLeft: '0'
									}}
									disableAnimation
									shrink
								>
								Last Modified
								</Label>
								<div style={{
									marginTop: '-0.6em',
									display: 'flex',
									width: '-webkit-fill-available'
								}}>
							
									<StartTextBox sx={{width: '100%'}} {...startProps}/>	
									<EndTextBox sx={{width: '100%'}} {...endProps} />
								</div>
							</div>
						)}}
				/>
			</div>

			<div className="flex-row">
				<div className="flex-row search-booleans">
					<FormControlLabel
						value="top"
						control={
							<>
								{getSearchValue('isFavorite') == null ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleContactSavedSearchChange({
												target: {
													name: "isFavorite",
													value: 'false'
												}
											})
										}}
										title="All"
										size="large">
										<StarBorderIcon style={{ color: 'grey' }} />
									</IconButton>
									: null}

								{getSearchValue('isFavorite') == 'false' ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleContactSavedSearchChange({
												target: {
													name: "isFavorite",
													value: 'true'
												}
											})
										}}
										title="Non-Favorite"
										size="large">
										<StarBorderIcon style={{color: 'hsl(50, 93%, 59%)'}} />
									</IconButton>
									: null}

								{getSearchValue('isFavorite') == 'true' ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleContactSavedSearchChange({
												target: {
													name: "isFavorite",
													value: null
												}
											})
										}}
										title="Favorite"
										size="large">
										<StarIcon style={{color: 'hsl(50, 93%, 59%)'}} />
									</IconButton>
									: null}
							</>
						}
						label="Favorite"
						labelPlacement="top"
					/>
				</div>
				<FormControl className={classes.searchBar} style={{ width: "100%", marginRight: '1em' }}>
					<Label style={typeStyle} disableAnimation shrink>
                        Search
					</Label>
					<TextBox 
						name="query"
						value={getSearchValue('query') || ''}
						data-testid="query-bar"
						inputRef={input => input && firstLoad && input.focus()} 
						onChange={(event) => handleContactSavedSearchChange(event)} 
					/>
				</FormControl>

				<FormControl className={classes.selection} style={{width: "100%"}}>
					<Label
						id="user-label"
						style={typeStyle}
						disableAnimation
						shrink
					>
                        Gallery Contact
					</Label>

					<Autocomplete
						multiple
						classes={autoCompleteStyle}
						style={{marginTop: "1.5em"}}
						isOptionEqualToValue={(option, value) => {
							return option.id == value.id
						}}
						forcePopupIcon
						filterSelectedOptions
						popupIcon={<ExpandMoreRoundedIcon />}
						size="small"
						value={props.selection ?? []}
						getOptionLabel={(option) =>getGalleryContactLabel(option)}

						options={options || []}
						onChange={(_, value) => {
							props.setSelection(value)
							const names = value.map(x=> [x.first_name, x.last_name].join(' ')).join(" AND ")
							handleContactSavedSearchChange({
								target: {
									name: "gallery_contacts",
									value: names
								}
							})
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								fullWidth
								InputProps={{
									...params.InputProps,
									style: {'padding': '9px'},
								}}
							/>
						)}
						data-testid="search-gallery_contact"
					/>
				</FormControl>
			</div>


			<div className="row">
				<div>
					<SaveButton
						data-testid="save-button"
						variant="contained"
						size="small"
						onClick={() => {
							saveSearch(
								currentSearch,
								openSnackbar,
								severity,
								setSavedSearchDialog
							)
						}}
					>
                        Save Search
					</SaveButton>
				</div>
				<FindModeButton onFindMode={props.onFindMode} />
				<AdvancedToggleButton
					data-testid="advanced-toggle-button"
					size="small"
					onClick={() => {		
						props.setCurrentSearch(currentSearch)
						props.setAdvancedSearch(true)
					}}
					style={{ marginRight: '1em' }}
				>
                    Advanced
				</AdvancedToggleButton>
				<ResetButton
					data-testid="reset-button"
					variant="contained"
					size="small"
					onClick={() => {
						props.setTotalItems(null)
						props.setSelection([])
						props.resetSearch('simple')
						setCurrentSearch(new SavedSearch({
							object_id: CONTACT_SEARCH_OBJECT,
							search_terms: [],
							is_global: true,
							user_id: userAuthentication.user?.id
						}))
					}}
					style={{ marginRight: '1em' }}
				>
                    Reset
				</ResetButton>
				<SearchButton
					data-testid="search-button"
					variant="contained"
					size="small"
					type="submit"
					style={{backgroundColor: !props.loading ? '#4465D1' : null}}
					disabled={props.loading}
				>
                    Search
				</SearchButton>
			</div>
		</form>

		<ConfirmationInput
			open={savedSearchDialog.open}
			handleClose={() => setSavedSearchDialog({
				open: false,
				savedSearch: null
			})}
			title={'Save search?'}
			disabled={loading}
			acceptText={'Save'}
			buttonColor="#33BF5C"
			inputLabel="Search Label"
			placeholder={'Saved Search ' + (props.savedSearches?.length + 1)}
			onYes={(text) => {
				if (!loading) {
					createSearch({
						variables: {
							CreateSearchInput: {
								label: text ? text : 'Saved Search ' + (props.savedSearches?.length + 1),
								user_id: userAuthentication.user.id,
								object_id: CONTACT_SEARCH_OBJECT,
								color: randomHSL(),
								search_terms: searchMap(currentSearch.search_terms),
								result_count: null,
								is_global: true,
							},
						},
					}).then((response) => {

						setCurrentSearch({
							...response.data.createSavedSearch.search,
							search_terms: saveMap(response.data.createSavedSearch.search.search_terms)
						})

						props.setSavedSearches(props.savedSearches.concat(
							new SavedSearch(response.data.createSavedSearch.search))
						)
					})
				}
			}}
		/>
	</>
}

export default withApollo(SearchContacts)
