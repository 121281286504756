import React, {useState, useEffect, useMemo} from 'react'
import {Checkbox,  FormControl, FormControlLabel} from '@mui/material'
import Label from '../input/Label'
import chunk from 'lodash/chunk'
import TextBox from '../input/Text/TextBox'

const InventoryReportOptions = (props) => {

	const [fields, setFields] = useState(
		JSON.parse(localStorage.getItem('InventoryReportOptions.fields')) || {})

	const fullFields = useMemo(() => ({
		exportTitle: '',
		inventory: true,
		artist: true,
		title: true,
		year: true,
		medium: true,
		dimensions: true,
		edition: true,
		status: true,
		retail: true,
		cost: true,
		net: true,
		insured: true,
		production: true,
		currentLocation: true,
		consignor: true,
		buyer: true,
		currentOwner: true,
		galleryContacts: true,
		ownerContacts: true,
		soldTo: true,
		soldFor: true,
		anonymize: true,
		includePageNumber: false,
		has_coa: true,
		sourceType: false,
		sourceCityAndCountry: false
	}), [])

	// update stored values if a new one is added.
	useEffect(() => {

		if (!fields || Object.keys(fullFields).length !== Object.keys(fields).length) {
			const fullKeys = Object.keys(fullFields)

			const tempFields = fullKeys.reduce((acc, f) => {
				acc[f] = fields[f]
				return acc
			}, {})

			fullKeys.forEach((key) => {
				if (tempFields[key] === undefined) tempFields[key] = true
			})

			setFields(tempFields)
		}

	}, [fields, fullFields])

	const labels = {
		inventory: "Inventory Number",
		artist: "Artist Name",
		title: "Title",
		year: "Date",
		medium: "Medium",
		dimensions: "Dimensions",
		edition: "Edition",
		status: "Status",
		retail: "Retail Price",
		cost: "Cost",
		net: "Net",
		insured: "Insured",
		production: "Production Cost",
		currentLocation: "Current Location",
		consignor: "Consignor",
		buyer: "Purchaser",
		currentOwner: "Known Sources",
		galleryContacts: "Gallery Contacts - Artwork",
		ownerContacts: "Gallery Contacts - Known Source",
		soldTo: 'Sold to/Date Sold',
		soldFor: 'Sold For',
		anonymize: 'Anonymize',
		includePageNumber: 'Include Page Number',
		has_coa: 'Certificate of Authenticity',
		sourceType: 'Known Source Type',
		sourceCityAndCountry: 'Known Source City/Country'
	}

	useEffect(() => {
		localStorage.setItem('InventoryReportOptions.fields', JSON.stringify({
			...fields,
			exportTitle: ''
		}))
		props.onChange(fields)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.onChange, fields] )

	const entries = Object.entries(fields)
		.filter(([k]) => !['exportTitle', 'anonymize', 'includePageNumber'].includes(k))
	const checkGrid = (chunk(entries, Math.ceil(entries.length / 3)))

	return (
		<div>
			<FormControl fullWidth>
				<Label disableAnimation shrink>
					Title
				</Label>

				<TextBox
					className='title-input'
					id='export-modal-inventoryreport-title'
					value={fields.exportTitle}
					onChange={(e) => {
						setFields({
							...fields,
							exportTitle: e.target.value,
						})
					}}
				/>
			</FormControl>

			<FormControl
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",

					paddingTop: '1.2em',
					marginRight: '1.3em',
					paddingLeft: '0.2em'
				}}
			>
				<Label disableAnimation shrink>
					Fields
				</Label>
				{
					checkGrid?.map( (row, i) => (
						<div
							style={{
								display: "flex",
								flexDirection: "column"
							}}
							key={`row-${i}`}
						>
							{row.map( ([key, value]) => {
								return (
									<FormControlLabel
										key={key}
										control={
											<Checkbox
												checked={value}
												name={key}
												onChange={(e)=>{
													setFields({
														...fields,
														[e.target.name]:  e.target.checked
													})
												}}
											/>
										}
										label={labels[key] || 'Removed'}
									>
									</FormControlLabel>
								)
							})}
						</div>
					))
				}
			</FormControl>
			<FormControl
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",

					paddingTop: '1.2em',
					marginRight: '1.3em',
					paddingLeft: '0.2em'
				}}
			>
				<Label disableAnimation shrink>
					Advanced
				</Label>
				<FormControlLabel
					control={
						<Checkbox
							checked={fields.anonymize}
							name="anonymize"
							onChange={(e)=>{
								setFields({
									...fields,
									anonymize:  e.target.checked
								})
							}}
						/>
					}
					label="Anonymize"
				>
				</FormControlLabel>
				<FormControlLabel
					control={
						<Checkbox
							checked={fields.includePageNumber}
							name="includePageNumber"
							onChange={(e)=>{
								setFields(fields => ({
									...fields,
									includePageNumber:  e.target.checked
								}))
							}}
						/>
					}
					label="Include Page Number"
				>
				</FormControlLabel>
			</FormControl>
		</div>
	)
}

export default InventoryReportOptions
