import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'
import AddLocationIcon from '@mui/icons-material/AddLocation'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import MoneyIcon from '@mui/icons-material/Money'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import makeStyles from '@mui/styles/makeStyles'
import TaskIcon from '@mui/icons-material/Task'
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material'
import { PersonAdd, PersonRemove } from '@mui/icons-material'


export type ButtonName = 'existing_listing' | 'remove_from_existing_listing' |
	'new_listing' | 'existing_deal' | 
	'new_deal' | 'location' | 'addNote' | 'price' | 'total_price' | 'showcase' |
	'tags' | 'favorite' | 'unfavorite' | 'interests' | 'user' | 'bulk_edit' |
	'exportDealInfo' | 'export' | 'pdf' | 'task_assignees' | 'task_status' | 'word'


type Button = {
	name: ButtonName
	src: string | OverridableComponent<SvgIconTypeMap<{}, "svg">>
	extraIcon?: any
	title: string
	creation?: boolean
	pages?: string[]
}

export const buttons: Button[] = [
	// Buttons for bulk actions throughout the application
	{
		name: 'existing_listing',
		src: '/images/icons/Black/Listings.svg',
		title: 'Add to existing Listing',
		extraIcon: {
			contact: PersonAdd
		},
		pages: ['art', 'contact']
	},
	{
		name: 'remove_from_existing_listing',
		src: '/images/icons/Black/Listings.svg',
		title: 'Remove from existing Listing',
		extraIcon: {
			contact: PersonRemove
		},
		pages: ['contact']
	},
	{
		name: 'new_listing',
		src: '/images/icons/Black/Listings.svg',
		title: 'Create new Listing',
		creation: true,
		pages: ['art', 'contact']
	},
	{
		name: 'existing_deal',
		src: '/images/icons/Black/Deals.svg',
		title: 'Add to existing Deal',
		pages: ['art', 'contact']
	},
	{
		name: 'new_deal',
		src: '/images/icons/Black/Deals.svg',
		title: 'Create new Deal',
		creation: true,
		pages: ['art', 'contact']
	},
	{
		name: 'location',
		src: AddLocationIcon,
		title: 'Add Location',
		pages: ['art']
	},
	{
		name: 'addNote',
		src: NoteAddOutlinedIcon,
		title: 'Add Note',
		pages: ['art', 'artist', 'contact', 'listing', 'deal']
	},
	{
		name: 'price',
		src: AttachMoneyIcon,
		title: 'Adjust Price',
		pages: ['art']
	},
	{
		name: 'total_price',
		src: MoneyIcon,
		title: 'Totals',
		pages: ['art']
	},
	{
		name: 'tags',
		src: '/images/icons/Black/Tags.svg',
		title: 'Tag',
		pages: ['art', 'artist', 'contact', 'listing', 'deal']
	},
	{
		name: 'favorite',
		src: StarIcon,
		title: 'Favorite',
	},
	{
		name: 'unfavorite',
		src: StarBorderIcon,
		title: 'Unfavorite',
	},
	{
		name: 'interests',
		src: EmojiPeopleIcon,
		title: 'Update Contact Interests',
		pages: ['contact']
	},
	{
		name: 'user',
		src: PeopleOutlineIcon,
		title: 'Edit Gallery Contacts',
		pages: ['art', 'artist', 'contact', 'listing', 'deal']
	},
	{
		name: 'bulk_edit',
		src: AutoAwesomeMotionIcon,
		title: 'Bulk Edit',
		pages: ['art', 'contact'],
	},
	{
		name: 'exportDealInfo',
		src: '/images/icons/Black/Export.svg',
		title: 'Export Deal Information',
		pages: ['art']
	},
	{
		name: 'export',
		src: '/images/icons/Black/Export.svg',
		title: 'Export to Excel',
		pages: ['art', 'contact', 'deal']
	},
	{
		name: 'pdf',
		src: '/images/icons/Black/PDF.svg',
		title: 'Export to PDF/Word',
		pages: ['art', 'contact']
	},
	{
		name: 'task_assignees',
		src: PeopleOutlineIcon,
		title: 'Update Assignees',
		pages: ['task'],
	},
	{
		name: 'task_status',
		src: TaskIcon,
		title: 'Update Statuses',
		pages: ['task'],
	}
]

const bulkActionButtonStyles = makeStyles(theme => ({
	drawerIcon: {
		maxHeight: "30px",
		maxWidth: "25px",
		padding: '2px'
	},
	buttonSize: {
		minWidth: '54px!important',
		height: '30px',
		margin: '3px',
	},
	deleteIcon: {
		color: 'grey',
		cursor: 'pointer',
		marginLeft: '10px',
	},
}))
export { bulkActionButtonStyles }

