import { CancelButton, SaveCardButton } from '../../input/Buttons'
import { Paper, FormControlLabel, Menu, MenuItem } from '@mui/material'
import { Skeleton } from '@mui/material'
import { UPDATE_HOME_CARDS } from './Queries'
import { useMutation } from '@apollo/client'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import React from 'react'
import Toggle from '../../input/Toggle/Toggle'
import { moveUp, moveDown } from '../../common/helpers'

export default function HomeToggles(props) {
	const [updateHomeCards, { loading }] = useMutation(UPDATE_HOME_CARDS)

	const initialState = {
		mouseX: null,
		mouseY: null,
		editable: false,
	}

	const widgets = props?.widgets
	const [widgetInput, setWidgetInput] = React.useState(
		Array.from({ length: 10 }, () => ({ loading: true }))
	)
	const [selectedIndex, setSelectedIndex] = React.useState(null)
	const [state, setState] = React.useState(initialState)

	const handleClick = (event) => {
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
			editable: false,
		})
	}

	const handleClose = (option) => {
		if (option === 'edit') {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	React.useEffect(() => {
		if (props.values) setWidgetInput(props.values)
	}, [props.values])

	return (
		<Paper
			className="qv-margin-no-top"
			id="user-permissions"
			onContextMenu={handleClick}
			style={{
    			overflow: 'auto',
			}}
		>
			<h1 className="card-title">
				<span>Home Page</span>
				{state.editable && (
					<>
						{state.editable ? 
							<>
								<IconButton
									size="small"
									disabled={selectedIndex === null}
									onClick={() => {
										const sortedWidgets = [...widgetInput]
											.sort( (a, b) => {
												if (a.loading || b.loading) return 1
												else return Number(a.ordinal) - Number(b.ordinal)
											} )
										moveUp(
											selectedIndex,
											setSelectedIndex,
											sortedWidgets,
											setWidgetInput
										)
									}}
								>
									<ArrowUpwardIcon />
								</IconButton>

								<IconButton
									size="small"
									disabled={selectedIndex === null}
									onClick={() =>{
										const sortedWidgets = [...widgetInput]
											.sort( (a, b) => {
												if (a.loading || b.loading) return 1
												else return Number(a.ordinal) - Number(b.ordinal)
											} )

										moveDown(
											selectedIndex,
											setSelectedIndex,
											sortedWidgets,
											setWidgetInput
										)
									}}
								>
									<ArrowDownwardIcon />
								</IconButton>

							</>
							: null}	
						<div className="spacer"></div>
						<CancelButton
							variant="contained"
							size="small"
							onClick={() => {
								setSelectedIndex(null)
								setWidgetInput(props.values)
								handleClose()
							}}
						>
							Cancel
						</CancelButton>
						<SaveCardButton
							variant="contained"
							color="primary"
							size="small"
							disabled={loading}
							onClick={() => {

								let variables = {
									widgets: widgetInput.map(widget => ({
										id: widget.id,
										ordinal: widget.ordinal,
										is_deleted: widget.is_deleted
									}))
								}

								updateHomeCards({ variables: { HomePageWidgetRequest: variables } })
								handleClose()
								setSelectedIndex(null)
							}}
						>
							Save
						</SaveCardButton>
					</>
				)}
			</h1>

			<div>
				<List
					className="homepage-toggles"
					style={{
						height: '330px',
						display: 'flex',
						flexDirection: 'column',
						flexWrap: 'wrap',
						width: '50%'
					}}
				>	
					{[...widgetInput]
						?.sort((a, b) => {
							if (a.loading || b.loading) return 1
							else return Number(a.ordinal) - Number(b.ordinal)
						})
						?.map((widget, index) => {
							const currentWidget = widgets?.find(
								(value) =>
									widget.home_page_widget_id === value.id
							)
							const loading = widgetInput[index].loading

							return (
								<ListItem
									button
									selected={selectedIndex === index}
									onClick={(event) => {
										event.preventDefault()
										event.stopPropagation()

										if (state.editable)
											setSelectedIndex(index)
									}}
									key={
										!loading
											? widgetInput[index].ordinal
											: index
									}
									style={{
										borderRadius: '8px',
									}}
								>
									<FormControlLabel
										control={
											<Toggle
												checked={!loading ? !widget?.is_deleted : false}
												onClick={(event) => {
													event.stopPropagation()
													event.preventDefault()
													
													if (state.editable) {

														let tempArray = [...widgetInput]
															.map((o) => ({...o}))

														const currentIndex = tempArray.findIndex(el => widget.id === el.id)

														tempArray[currentIndex].is_deleted = !tempArray[currentIndex].is_deleted

														setWidgetInput(tempArray)
													}
												}}
											/>
										}
										label={
											!loading ? (
												`${widget?.ordinal}. ${currentWidget.name}`
											) : (
												<Skeleton
													type="text"
													width="9em"
													height="3em"
													style={{
														marginTop: '-0.5em',
														marginBottom: '-0.5em',
													}}
												/>
											)
										}
										labelPlacement="start"
									/>
								</ListItem>
							)
						})}

					{/* { [...widgetInput].length % 2 === 1 ? <ListItem
						button
						key={10}
						style={{
							borderRadius: '8px',
							display: 'inline-table'
						}}
					></ListItem> : null } */}
					
				</List>
			</div>

			<Menu
				keepMounted
				open={state.mouseY !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					state.mouseY !== null && state.mouseX !== null
						? { top: state.mouseY, left: state.mouseX }
						: undefined
				}
			>
				<MenuItem onClick={() => handleClose('edit')}>Edit</MenuItem>
			</Menu>
		</Paper>
	)
}
