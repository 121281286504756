import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { useMutation } from '@apollo/client'
import { CREATE_MULTIPLE_TAGS, TOGGLE_FAVORITE_ENTITIES } from '../common/queries'
import DealDropdown from '../Deals/DealDropdown'
import { ADD_CONTACT_ART } from '../Deals/Queries'
import ExcelExport from '../Exports/ExcelExport'
import groupBy from 'lodash/groupBy'
import { AuthStateContext, DispatchContext } from '../store'
import BulkActionMenu from './BulkActionMenu'
import { BULK_EDIT, EXCEL, PDF, validatePermissions } from '../Exports/ExportMenu'
import PdfExport from '../Exports/PdfExport'
import EditGalleryContactsModal from './EditGalleryContactsModal'
import EditContactInterests from './EditContactInterests'
import { severity } from '../Snackbar/CustomizedSnackbar'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import GlobalSearchTags from '../Tags/GlobalSearchTags'
import { CREATE_LISTING_AND_RELATIONS, DELETE_LISTING_IDS, INSERT_LISTING_IDS } from './Queries'
import { permissions, permissionValues } from '../constants/permissions'
import LocationModal from './LocationModal'
import AdjustPriceModal from './AdjustPriceModal'
import TotalPriceModal from './TotalPriceModal'
import ExportDealInfoModal from './ExportDealInfoModal'
import { quickViewAtom } from '../navigation/Tabs/TabbedPage'
import CreateListingQuickView from '../Listings/CreateListingQuickView'
import CreateDealQuickView from '../Deals/CreateDealQuickView'
import ListingDropdown from '../Listings/ListingDropdown'
import TaskStatusModal from './TaskStatusModal'
import TaskAssigneesModal from './TaskAssigneesModal'
import BulkObjectNote from '../Notes/ObjectNotes/BulkObjectNote'
import BulkEdit from './BulkEdit'
import { useNavigate } from 'react-router-dom'

/**
 * @typedef {Object} Entity
 * @property {number} id
 * @property {string} value
 */

/**
 * @param {Entity} entity
 * @returns {number}
 */
const getId = entity => Number(entity.id)

/**
 *
 * @param {Object[]} idlist
 * @returns {Object}
 */
function getIds (idlist) {
	let ids = groupBy(idlist, '__typename')
	Object.entries(ids).forEach(([key, val]) => {
		ids[key] = val.map(getId)
	})
	return ids
}

/**
 * @typedef {Object} BulkActionProps
 * @property {Entity[]} selectedEntities
 * @property {"contact" | "art"} entityType
 * @property {function=} onOpen
 * @property {function=} requery function to call if needed to relead a grid
 * @property {boolean} disabled
 * @property {Object} options
 */

/**
 * BulkActions
 * @param {BulkActionProps} props
 * @returns
 */
export default function BulkActions (props) {

	const navigate = useNavigate()
	const authState = useContext(AuthStateContext)
	const userPermissions = authState?.user?.permissions

	const setQVOverride = useSetRecoilState(quickViewAtom)

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])


	// Modal states
	const [openListingDropdownModal, setOpenListingDropdownModal] = useState(false)
	const [createListing, setCreateListing] = useState(false)
	const [openDealDropdownModal, setOpenDealDropdownModal] = useState(false)
	const [bulkEditModal, setBulkEditModal] = useState(false)
	const [createDeal, setCreateDeal] = useState(false)
	const [contactInterests, setContactInterests] = useState(false)
	const [openGalleryContactsModal, setOpenGalleryContactsModal] = useState(false)
	const [openExcelExportModal, setOpenExcelExportModal] = useState(false)
	const [openPdfExportModal, setOpenPdfExportModal] = useState(false)
	const [tagModal, setTagModal] = useState(false)
	const [openLocationModal, setOpenLocationModal] = useState(false)
	const [openPriceModal, setOpenPriceModal] = useState(false)
	const [totalPriceModal, setTotalPriceModal] = useState(false)
	const [openExportDealInfoModal, setOpenExportDealInfoModal] = useState(false)
	const [openTaskAssignees, setOpenTaskAssignees] = useState(false)
	const [openTaskStatus, setOpenTaskStatus] = useState(false)
	const [addNoteModal, setAddNoteModal] = useState(false)

	// Mutations
	const [toggleFavoriteEntities, { loading: loadingFavorites }] = useMutation(TOGGLE_FAVORITE_ENTITIES)
	const [createMultipleTags, {loading: loadingTags }] = useMutation(CREATE_MULTIPLE_TAGS)
	const [addContactsAndArt, { loading: loadingContactArt }] = useMutation(ADD_CONTACT_ART)
	const [insertListingIds, { loading: loadingInsertListings }] = useMutation(INSERT_LISTING_IDS)
	const [removeListingIds, { loading: loadingRemoveListings }] = useMutation(DELETE_LISTING_IDS)

	const loadingListings = loadingInsertListings || loadingRemoveListings
	const entities = props.selectedEntities

	// common handlers
	const handleUpdateResponse = (field) => response => {
		if (response.data[field]?.success === true) {
			openSnackbar(severity.SUCCESS, response.data[field].message)
		} else if (response.errors[0].message) {
			openSnackbar(severity.ERROR, response.errors[0].message)
		} else {
			openSnackbar(severity.ERROR, "Update encountered a problem.")
		}
	}

	const handleError = error => {
		console.error(error)
		openSnackbar(severity.ERROR, 'An unexpected error occured')
	}

	const addToDeal = (dealId) => {
		const contactIds = entities
			.filter(r => r.__typename === 'Contact')
			.map(getId)
		const artIds = entities
			.filter(r => r.__typename === 'Art')
			.map(r => ({
				art_id: r.id,
				offer_currency_id: r.primary_currency_id || '1',
				offer_amount: r.primaryRetailPrice || 0
			}))

		addContactsAndArt({
			variables: {
				DealAddContactsArt: {
					id: dealId,
					contactIds,
					artIds
				}
			},
		})
			.then(handleUpdateResponse('addContactsAndArt'))
			.then(() => navigate(`/deals/${dealId}/details`))
			.catch(handleError)
	}


	useEffect(() => {
		if (createDeal) {
			const contacts = entities
				.filter(row => row.__typename === 'Contact')
			let art = entities
				.filter(row => row.__typename === 'Art')

			setQVOverride(<CreateDealQuickView
				contacts={contacts}
				art={art}
				setCreateDeal={setCreateDeal}
			/>)

		} else if (createListing) {
			setQVOverride(<CreateListingQuickView
				setCreateListing={setCreateListing}
				entities={entities}
				createListingMutation={CREATE_LISTING_AND_RELATIONS}
			/>)
		} else {
			setQVOverride(null)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createDeal, createListing])


	function setFavorites (setFavorite) {

		const ids = getIds(entities)
		toggleFavoriteEntities({
			variables: {
				contacts: ids?.Contact,
				deals: ids?.Deal,
				listings: ids?.Listing,
				artists: ids?.Artist,
				art: ids?.Art,
				tasks: ids?.SearchTaskItem,
				setFavorite
			}
		})
			.then(handleUpdateResponse('toggleFavoriteEntities'))
			.then(() => { props.requery?.() })
			.catch(handleError)
	}


	const updateTags = (selectedTags) => {
		let ids = getIds(entities)
		let tagIds = selectedTags.tags.map(getId)

		createMultipleTags({
			variables: {
				contacts: ids.Contact,
				deals: ids.Deal,
				listings: ids.Listing,
				artists: ids.Artist,
				art: ids.Art,
				tagIds
			},
		})
			.then(handleUpdateResponse('createMultipleTags'))
			.then(() => setTagModal(false))
			.catch(handleError)
	}


	const handleAddListings = (listingIds) => {

		let ids = getIds(entities)

		insertListingIds({
			variables: {
				InsertListingInput:{
					listingIds: listingIds.object.value,
					contacts: ids.Contact,
					art: ids.Art,
				}
			},
		})
			.then(handleUpdateResponse('insertListingIds'))
			.catch(handleError)
	}
	const handleRemoveListings = (listingIds) => {

		let ids = getIds(entities)

		removeListingIds({
			variables: {
				DeleteListingContacts:{
					listingIds: listingIds.object.value,
					contacts: ids.Contact,
				}
			},
		})
			.then(handleUpdateResponse('deleteListingIds'))
			.catch(handleError)
	}


	const validate4ValPermissions = (perm, val) => {
		if (!userPermissions) return false

		// eslint-disable-next-line eqeqeq
		const permissionSought = userPermissions.find((element) => element.permission_id == perm)
		if (Number(permissionSought?.permission_value_id) < val) {
			openSnackbar(severity.WARNING, 'Insufficient Permissions')
			return false
		}
		return true
	}

	/**
	 * 
	 * @param {import('./BulkActionButtons').ButtonName} menuName 
	 */
	const handleAction = menuName => {

		switch (menuName) {
		case 'existing_listing':
			if (validate4ValPermissions(permissions.LISTINGS, permissionValues.CREATE_AND_EDIT_OWN)) {
				setOpenListingDropdownModal("Add to")
			}
			break
		case 'remove_from_existing_listing':
			if (validate4ValPermissions(permissions.LISTINGS, permissionValues.CREATE_AND_EDIT_OWN)) {
				setOpenListingDropdownModal("Remove from")
			}
			break
		case 'new_listing':
			if (validate4ValPermissions(permissions.LISTINGS, permissionValues.CREATE_AND_EDIT_OWN)) {
				setCreateListing(true)
			}
			break
		case 'existing_deal':
			if (validate4ValPermissions(permissions.DEALS, permissionValues.CREATE_AND_EDIT_OWN)) {
				setOpenDealDropdownModal(true)
			}
			break
		case 'new_deal':
			if (validate4ValPermissions(permissions.DEALS, permissionValues.CREATE_AND_EDIT_OWN)) {
				setCreateDeal(true)
			}
			break
		case 'location':
			setOpenLocationModal(true)
			break
		case 'price':
			setOpenPriceModal(true)
			break
		case 'total_price':
			setTotalPriceModal(true)
			break
		case 'tags':
			setTagModal(true)
			break
		case 'favorite':
			setFavorites(true)
			break
		case 'unfavorite':
			setFavorites(false)
			break
		case 'interests':
			setContactInterests(true)
			break
		case 'user':
			setOpenGalleryContactsModal(true)
			break
		case 'exportDealInfo':
			if (validatePermissions(EXCEL, userPermissions, entities, openSnackbar) &&
				validate4ValPermissions(permissions.DEALS, permissionValues.VIEW_ONLY) &&
				validate4ValPermissions(permissions.ART, permissionValues.VIEW_ONLY) &&
				validate4ValPermissions(permissions.CONTACTS, permissionValues.VIEW_ONLY)
			) {
				setOpenExportDealInfoModal(true)
			}
			break
		case 'export':
			if (validatePermissions(EXCEL, userPermissions, entities, openSnackbar)) {
				setOpenExcelExportModal(true)
			}
			break
		case 'pdf':
			if (validatePermissions(PDF, userPermissions, entities, openSnackbar)) {
				setOpenPdfExportModal(true)
			}
			break
		case 'task_assignees': 
			setOpenTaskAssignees(true)
			break
		case 'task_status':
			setOpenTaskStatus(true)
			break
		case 'addNote':
			setAddNoteModal(true)
			break
		case 'bulk_edit':
			if (validatePermissions(BULK_EDIT, userPermissions, entities, openSnackbar)) {
				setBulkEditModal(true)
			}
			break
		default:
			console.error(`Unknown menu option '${menuName}'`)
		}
	}

	return <>
		<BulkActionMenu
			onOpen={() => props.onOpen?.()}
			entity={props.entityType}
			handleAction={handleAction}
			hangingMode={props.hangingMode}
			disabled={props.disabled || loadingFavorites || loadingTags || 
				loadingContactArt || loadingListings }
			smallButton={props.smallButton}
			loading={props.loading}
		/>
		<EditContactInterests
			open={contactInterests}
			onClose={() => setContactInterests(false)}
			selectedRows={entities}
		/>
		<EditGalleryContactsModal
			open={openGalleryContactsModal}
			onClose={() => setOpenGalleryContactsModal(false)}
			selectedRows={entities}
		/>
		<PdfExport
			open={openPdfExportModal}
			close={() => setOpenPdfExportModal(false)}
			selectedRows={entities}
			keyName={props.keyName}
			options={props.options}
		/>
		<ExcelExport
			open={openExcelExportModal}
			onClose={() => setOpenExcelExportModal(false)}
			selectedRows={entities}
			options={props.options}
		/>
		<TransitionsModal
			className="new-tag-modal"
			open={tagModal}
			close={() => setTagModal(false)}
		>
			<div style={{ minWidth: '30em' }}>
				<GlobalSearchTags
					loading={loadingTags}
					onSave={updateTags}
					setTagModal={setTagModal}
				></GlobalSearchTags>
			</div>
		</TransitionsModal>

		<TransitionsModal
			className="existing-deal-modal"
			open={openDealDropdownModal}
			close={() => setOpenDealDropdownModal(false)}
		>
			<div style={{ minWidth: '25em' }}>
				<DealDropdown
					onSave={addToDeal}
					loading={loadingContactArt}
					close={() => setOpenDealDropdownModal(false)}
				/>
			</div>
		</TransitionsModal>

		<TransitionsModal
			noPadding
			open={bulkEditModal}
			close={() => setBulkEditModal(false)}
		>
			{
				<BulkEdit
					entityType={props.entityType}
					toggleEditMode={() => setBulkEditModal(false)}
					requery={props.requery}
				/>

			}
		</TransitionsModal>

		<TransitionsModal
			className="existing-listing-modal"
			open={openListingDropdownModal}
			close={() => setOpenListingDropdownModal(false)}
		>
			<div style={{ minWidth: '25em' }}>
				<ListingDropdown
					name={openListingDropdownModal}
					description={`Remove ${props.options?.listingId ? 'these' : 'the'} ${entities.length} ${props.options?.listingId ? '' : 'flagged'} Contact(s) from the Listing(s) selected below:`}
					loading={loadingListings}
					onSave={(listingIds) => {
						if (openListingDropdownModal === 'Add to') {
							handleAddListings(listingIds)
						} else if (openListingDropdownModal === 'Remove from') {
							handleRemoveListings(listingIds)
						} else {
							console.error('Unexpected state.')
						}
					}}
					setOpenListingDropdownModal={setOpenListingDropdownModal}
				/>
			</div>
		</TransitionsModal>

		<LocationModal
			open={openLocationModal}
			close={() => setOpenLocationModal(false)}
			rows={entities}
		/>

		<AdjustPriceModal
			open={openPriceModal}
			close={() => {
				setOpenPriceModal(false)
				props.requery?.()
			}}
			rows={entities}
		/>

		<TotalPriceModal
			open={totalPriceModal}
			close={() => setTotalPriceModal(false)}
			rows={entities}
		/>

		<ExportDealInfoModal
			open={openExportDealInfoModal}
			close={() => setOpenExportDealInfoModal(false)}
			rows={entities}
		/>

		<TaskAssigneesModal
			open={openTaskAssignees}
			close={() => setOpenTaskAssignees(false)}
			rows={entities}
		/>

		<TaskStatusModal
			open={openTaskStatus}
			close={() => setOpenTaskStatus(false)}
			rows={entities}
		/>

		<BulkObjectNote
			open={addNoteModal}
			close={() => setAddNoteModal(false)}
			rows={entities}
		/>
	</>
}
