import { gql } from '@apollo/client'
import { fileData, imageData, userDataCompressed } from '../common/fragments'

export const SIMPLE_SEARCH_LISTINGS = gql`
	query simpleSearchListings($query: String) {
		simpleSearchListings(query: $query) {
			id
			title
		}
	}
`

export const GET_LISTING_DETAILS = gql`
	query getListing(
		$id: ID! 
		$thumbnailResolution: String = "128x128"
		$qvResolution: String = "1024x1024"
	) {
		getListing(id: $id) {

			created_by
			created_at
			modified_by
			modified_at
			id

			is_active
			is_deleted
			isFavorite
			isFlagged
			title
			subtitle
			about
			start_at
			end_at
			start_time
			end_time
			timezone_id
			type_id
			created_at
			created_by
			created_user {
				first_name
				last_name
			}
			modified_user {
				first_name
				last_name
			}
			listing_type {
				value
			}
			images {
				...imageData
			}
			salesperson {
				...userDataCompressed
				is_lead
				is_deleted
			}
			imgUrl (thumbnailResolution: $qvResolution)
			profile_link
			exhibited_pieces
			location
			tags {
				id
				label
				description
				color_hex
			}
			showcase_ready
			artCount
			contactCount
			project_code {
				id
				project_code
			}
		}
		getCriticalListingNotes(input: { listing_id: $id }) {
			id
			type_id
			applied_at
			applied_user_id
			note_text
			is_critical
			is_private
			is_deleted
			user {
				...userDataCompressed
				department
			}
		}
		getListingCount
	}
	${imageData}
	${userDataCompressed}
`

export const GET_LISTING_QV_DETAILS = gql`
	query GetListing(
		$id: ID!
		$thumbnailResolution: String = "128x128"
		$qvResolution: String = "1024x1024"
	) {
		getListing(id: $id) {
			id
			is_deleted
			title
			subtitle
			about
			start_at
			end_at
			start_time
			end_time
			timezone_id
			type_id
			listing_type {
				value
			}
			salesperson {
				...userDataCompressed
				is_lead
				is_deleted
			}
			imgUrl (thumbnailResolution: $qvResolution)
			exhibited_pieces
			location
			showcase_ready
			created_at
			artCount
			contactCount
		}
		getCriticalListingNotes(input: { listing_id: $id }) {
			id
			type_id
			applied_at
			applied_user_id
			note_text
			is_critical
			is_private
			is_deleted
			user {
				...userDataCompressed
				department
			}
		}
	}
	${userDataCompressed}
`

export const SEARCH_LISTINGS = gql`
	query searchListings(
		$field: String
		$direction: SortDirection
		$searchString: String
		$typeId: String
		$isFavorite: Boolean
		$listingStartDate: String
		$listingEndDate: String
		$modifiedStartDate: String
		$modifiedEndDate: String
		$cursor: Int
		$specialFilter: String
		$tagId: Int
		$limit: Int
		$salesperson_ids: [ID]
		$showcase_ready: Boolean
		$thumbnailResolution: String = "128x128"
	) {
		searchListings(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: {
					tagId: $tagId
					searchString: $searchString
					isFavorite: $isFavorite
					typeId: $typeId
					listingStartDate: $listingStartDate
					listingEndDate: $listingEndDate
					modifiedStartDate: $modifiedStartDate
					modifiedEndDate: $modifiedEndDate
					specialFilter: $specialFilter
					salesperson_ids: $salesperson_ids
					showcase_ready: $showcase_ready
				}
				thumbnailResolution: $thumbnailResolution
			}
		) {
			cursor
			totalItems
			items {
				id
				type_id
				listing_type {
					id
					value
				}
				title
				subtitle
				location
				start_at
				end_at
				is_active
				created_by
				isFavorite
				isFlagged
				created_at
				modified_by
				modified_at
				is_deleted
				imgUrl (thumbnailResolution: $thumbnailResolution)
			}
		}
	}
`

export const UPLOAD_FILE = gql`
	mutation uploadFile($file: Upload!) {
		singleUpload(file: $file) {
			filename
			Location
			mimetype
			encoding
		}
	}
`

export const UPDATE_LISTING = gql`
	mutation updateListing(
		$UpdateListingInput: UpdateListingInput!
		$thumbnailResolution: String = "128x128"
		$qvResolution: String = "1024x1024"
	) {
		updateListing(input: $UpdateListingInput) {
			code
			success
			message
			severity
			listing {
				id
				title
				subtitle
				location
				about
				start_at
				type_id
				end_at
				start_time
				end_time
				images {
					...imageData
				}
				imgUrl (thumbnailResolution: $qvResolution)
				profile_link
				timezone_id
				listing_type {
					value
				}
				tags {
					id
					label
					description
					color_hex
				}
				salesperson {
					...userDataCompressed
					is_lead
					is_deleted
				}
				showcase_ready
				created_at
			}
		}
	}
	${imageData}
	${userDataCompressed}
`

export const CREATE_LISTING = gql`
	mutation createListing($CreateListingInput: CreateListingInput!) {
		createListing(input: $CreateListingInput) {
			code
			success
			message
			listing {
				id
				title
				start_at
				end_at
				start_time
				end_time
				timezone_id
				location
				listing_type {
					value
				}
			}
		}
	}
`

export const UPDATE_LISTING_FILES = gql`
	mutation updateListingFiles(
		$UpdateFileInput: UpdateFileInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateListingFiles(input: $UpdateFileInput) {
			code
			success
			message
			files {
				...fileData
			}
		}
	}
	${fileData}
`

export const DELETE_LISTING = gql`
	mutation deleteListing($id: ID!) {
		deleteListing(id: $id) {
			code
			success
			message
		}
	}
`

export const SIMPLE_SEARCH_PROJECT_CODES = gql`
	query simpleSearchProjectCodes($query: String) {
		simpleSearchProjectCodes(query: $query) {
			id
			project_code
		}
	}
`
