import React from 'react'
import { Paper, FormControl } from '@mui/material'
import Label from '../input/Label'
import { FindModeInput } from '../navigation/Tabs/TabbedPage'

const DealEntrySearch = (props) => {

	return (
		<Paper className="qv-margin" id="location-search">
			<h1 className="card-title">
				<span>Offers, Reserves, &amp; Sales</span>
			</h1>
			<div className="column-body">
				<FormControl style={{
					flexGrow: 1,
					marginRight: '1em'
				}}>
					<Label disableAnimation shrink htmlFor="deal_entry_status_category">
						Status Category
					</Label>
					<FindModeInput field="deal_entry_status_category" />
				</FormControl>
				<FormControl style={{
					flexGrow: 1,
					marginRight: '1em'
				}}>
					<Label disableAnimation shrink htmlFor="deal_entry_status">
						Status
					</Label>
					<FindModeInput field="deal_entry_status" />
				</FormControl>
				<FormControl style={{ flexGrow: 1 }}>
					<Label disableAnimation shrink htmlFor="deal_entry_status_id">
						Status ID
					</Label>
					<FindModeInput field="deal_entry_status_id" />
				</FormControl>
			</div>
			<div className="column-body">
				<FormControl style={{
					flexGrow: 1,
					marginRight: '1em'
				}}>
					<Label disableAnimation shrink htmlFor="art_title">
						Art Title
					</Label>
					<FindModeInput field="art_title" />
				</FormControl>
				<FormControl style={{
					flexGrow: 1,
					marginRight: '1em'
				}}>
					<Label disableAnimation shrink htmlFor="artist">
						Artist
					</Label>
					<FindModeInput field="artist" />
				</FormControl>
				<FormControl style={{ flexGrow: 1 }}>
					<Label disableAnimation shrink htmlFor="searchable_inventory_number">
						Inventory Number
					</Label>
					<FindModeInput field="searchable_inventory_number" />
				</FormControl>
			</div>
		</Paper>
	)
}

export default DealEntrySearch
