/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { AuthStateContext, LookupContext, DispatchContext } from '../../store'
import { useLazyQuery, useQuery, useMutation } from '@apollo/client'
import clsx from 'clsx'
import {
	CREATE_LISTING_ART,
	DELETE_LISTING_ART,
	GET_ALL_LISTING_ART,
	GET_LISTING_ART,
	GET_POSSIBLE_LISTING_ART,
	UPDATE_LISTING_ART,
} from './Queries'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import {
	autoCompleteStyles,
	autoCompleteErrorStyles,
	LightTooltip
} from '../../styles/makesStyles'
import {
	Button, CircularProgress, FormControl, Menu, MenuItem, Paper,
	Select, Skeleton, TableSortLabel
} from '@mui/material'
import { ChooseImageButton, CancelButton, SubmitButton, ResetButton } from '../../input/Buttons'
import Thumbnail from '../../Thumbnail/Thumbnail'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import {
	ExpandCircleDown, KeyboardArrowDown, PersonRemove, StickyNote2Outlined, TableRows, Undo 
} from '@mui/icons-material'
import ArtQuickView from '../../Art/ArtPiece/ArtQuickView'
import { getArtActivity } from '../../Art/ArtList'
import {
	arraymove, artSorts, getArtInventoryNumber, getArtistThumbnailDetail, getArtStatusChip,
	getListingStatusColor, getNonNullArray, restoreColumnSettings
} from '../../common/helpers'
import Dropdown from '../../input/Dropdown/Dropdown'
import { useLocation, useNavigate } from 'react-router-dom'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import { withApollo } from '@apollo/client/react/hoc'
import LimitedAutocomplete from '../../common/LimitedAutocomplete'
import ListingRemoveModal from '../../common/components/ListingRemoveModal'
import useNavigation from '../../navigation/useNavigation'
import QuillText from '../../input/QuillText'
import ListingRelationNotes from '../../Notes/ListingRelationNotes'
import BulkActions from '../../Search/BulkActions'
import { LISTING_ART_DETAIL_OPTIONS_KEY } from '../../Exports/ArtDetailOptions'
import UserAccessQuickView from '../../QuickView/UserAccessQuickView'
import TextBoxThinForAutocomplete from '../../input/Text/TextBoxThinForAutocomplete'
import { useSetRecoilState } from 'recoil'
import { listingQuickViewAtom } from './ListingQuickView'
import {
	DataGridPro, GridActionsCellItem, GridToolbarColumnsButton,
	GridToolbarContainer, GridToolbarDensitySelector
} from '@mui/x-data-grid-pro'
import DataGridFlagMenu from '../../common/components/DataGridFlagMenu'
import FavoriteAndFlag from '../../common/FavoriteAndFlag'
import { ART_FLAG_QUERY } from '../../Search/Queries'
import InfoCard from '../../InfoCard/InfoCard'
import { bulkActionSelectionAtom } from '../../Search/BulkActionQuickView'
import Source from '../../Art/ArtPiece/Source'
import ListingGridView from './ListingGridView'


const ListingArt = (props) => {

	const columnSettingsKey = 'ListingArtDataGrid'
	const columnVisibilityKey = `${columnSettingsKey}-visible`

	const setEntities = useSetRecoilState(bulkActionSelectionAtom)
	const setListingQuickView = useSetRecoilState(listingQuickViewAtom)
	const userAuthentication = useContext(AuthStateContext)

	const { push: pushNav } = useNavigation()
	const navigate = useNavigate()
	const location = useLocation()
	const [isGrid, setIsGrid] = useState(false)

	let prevSearch = location.state
	if (prevSearch?.card != 'ListingArt') {
		prevSearch = null
	}

	const pageSize = 10

	const classes = autoCompleteStyles()
	const classes2 = autoCompleteErrorStyles()

	// Actions
	const [selectedRow, setSelectedRow] = React.useState(null)
	const [infoModal, setInfoModal] = React.useState({open: false})
	const [warningModal, setWarningModal] = React.useState({
		firstClick: true,
		open: false
	})

	// Pagination
	const [steps, setSteps] = React.useState(prevSearch?.steps ?? 1)
	const [activeStep, setActiveStep] = React.useState(prevSearch?.activeStep ?? 0)
	const [cursor, setCursor] = React.useState(prevSearch?.cursor ?? 0)
	const [totalItems, setTotalItems] = React.useState(prevSearch?.totalItems ?? 0)

	// Sorting
	const DEFAULT_ORDER_BY = 'art.title'
	const [order, setOrder] = React.useState(prevSearch?.order || 'asc')
	const [orderBy, setOrderBy] = React.useState(prevSearch?.orderBy || DEFAULT_ORDER_BY)


	const [removeListingModal, setRemoveListingModal] = React.useState(false)
	const lookup = React.useContext(LookupContext)
	const [attempt, setAttempt] = React.useState(false)
	const [modal, setModal] = React.useState({ open: false, art_id: null })
	const artStatus = lookup.data?.getArtStatuses

	const listingTypeId = props?.state?.getListing?.type_id
	const listingArtStatuses = lookup.data?.getListingArtStatus
	const listingArtStatus = useMemo(() => {
		return listingArtStatuses
			?.filter(status => status.listing_type_id == listingTypeId )
		|| []
	}, [listingTypeId, listingArtStatuses])


	const [editNoteModal, setEditNoteModal] = React.useState(false)

	const [artLoading, setArtLoading] = React.useState(false)

	// Data
	const [rows, setRows] = React.useState(prevSearch?.rows ?? [])


	// Get initial flag count
	const { data: flaggedData } = useQuery(ART_FLAG_QUERY, {
		variables: {
			filters: [{
				field: "isFlagged",
				type: "eq",
				value: "true"
			}]
		}
	})
	const initialFlaggedCount = flaggedData?.searchDynamicArt?.totalItems

	const warnThenGo = useCallback(() => {
		if (initialFlaggedCount > 0 && warningModal.firstClick) {
			return new Promise((resolve, reject) => {
				setWarningModal(wm => ({
					...wm,
					open: true,
					firstClick: false,
					resolve,
					reject
				}))
			})
		} else {
			return Promise.reject()
		}
	}, [initialFlaggedCount, warningModal.firstClick])

	const resetGrid = () => {
		localStorage.removeItem(columnSettingsKey)
		localStorage.removeItem(columnVisibilityKey)
		localStorage.removeItem(`${columnSettingsKey}.density`)
		localStorage.removeItem(`${columnSettingsKey}.limit`)

		setColumnVisibilityModel({})
		setColumnSettings(null)
		setLimit(50)
		setDensity('comfortable')
	}

	const updateColumnSettings = () => {
		if (gridCols.length) {
			const colSettings = gridCols.map(col => {
				const setting = { field: col.field }
				if (col.width) setting.width = col.width
				if (col.minWidth) setting.minWidth = col.minWidth
				return setting
			})
			setColumnSettings(colSettings)
		}
	}

	const CustomToolBar =  () => 
		<div className='flex justify-end'>
			<Button
				size="small"
				startIcon={<Undo />}
				onClick={resetGrid}
			>
				Reset
			</Button>
			<GridToolbarColumnsButton />
			<GridToolbarDensitySelector />
			<Button
				size="small"
				startIcon={<TableRows />}
				onClick={() => setIsGrid(!isGrid)}
			>
				Display
			</Button>
		</div>

	// Save Search
	const updateHistory = useCallback((artId) => {
		const savedSearch = {
			cursor, selectedRow, rows,
			orderBy, order,
			steps, activeStep, totalItems,
			searchPage: location.pathname,
			card: 'ListingArt'
		}
		const offset = rows.findIndex(a => a.art.id == artId)
		const nextcursor = activeStep * pageSize + offset
		const variables = {
			id: props.id,
			cursor: nextcursor,
			limit: 3,
			field: orderBy || DEFAULT_ORDER_BY,
			direction: order.toUpperCase(),
		}
		savedSearch.variables = variables
		navigate(location.pathname, { state: savedSearch })
		return savedSearch
	}, [activeStep, cursor, navigate, order, orderBy, props.id,
		rows, selectedRow, steps, totalItems, location])



	const saveAndGo = useCallback((path, artId) => {
		const state = updateHistory(artId)
		pushNav({
			url: location.pathname,
			state,
			query: GET_LISTING_ART,
			getId: r => r?.art?.id
		}, state.variables.cursor)
		navigate(path, { state })
	}, [navigate, location, pushNav, updateHistory])

	// Art Autocomplete
	const [open, setOpen] = React.useState(false)
	const [options, setOptions] = React.useState([])
	const [loadArt, setLoadArt] = React.useState(false)
	const loading = open && !!loadArt

	React.useEffect(() => {
		let active = true

		if (!loading) {
		  return undefined
		}

		props.client
			.query({
				query: GET_POSSIBLE_LISTING_ART,
				variables: { listing_id: props.id, query: loadArt },
			})
			.then(result => {
				if (active ) {
					setLoadArt(false)
					setOptions(result.data.getPossibleListingArt)
				}
			})

		return () => active = false
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [props.id, loading])

	// GridSettings
	const [columnSettings, setColumnSettings] = useState( JSON.parse(localStorage.getItem(columnSettingsKey) || null ) )
	const [columnVisibilityModel, setColumnVisibilityModel] = useState(JSON.parse(localStorage.getItem(`${columnSettingsKey}.visibility`) || '{}' ))
	const [limit, setLimit] = React.useState(Number(localStorage.getItem(`${columnSettingsKey}.limit`) || 50))
	const [density, setDensity] = useState(localStorage.getItem(`${columnSettingsKey}.density`) || 'comfortable')


	const handleResponse = (data) => {

		setArtLoading(false)
		setRows(data.getListingArt.items)

		if (data.getListingArt.items.length < 1) setSteps(1)
		else setSteps(Math.ceil(data.getListingArt.totalItems / pageSize))

		setTotalItems(data.getListingArt.totalItems || 0)
	}

	const handleError = (data) => {

		setArtLoading(false)
		if (getNonNullArray(data.getListingArt.items).length === 0) {
			openSnackbar(severity.ERROR, "Error - Unauthorized")
			return
		}

		if (data.getListingArt?.items) {
			setRows(data.getListingArt.items)

			if (data.getListingArt.items.length < 1) setSteps(1)
			else setSteps(Math.ceil(data.getListingArt.totalItems / pageSize))

			setTotalItems(data.getListingArt.totalItems || 0)
		}
	}

	useEffect(() => {
		let active = true

		setArtLoading(true)
		if (!props.findMode && props.id > 0) {
			searchListingArt()
				.then((data) => {
					if (active) {
						handleResponse(data.data)
					}
				})
				.catch(handleError)
		}
		return () => active = false
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.id, orderBy, order, limit])

	// Deselect Row on change of Main Entity
	useEffect(() => {
		setSelectedRow(null)
	}, [props.id])

	const searchListingArt = (cursorOverride = null, { search } = {}) => {

		return props.client.query({
			query: GET_LISTING_ART,
			variables: {
				id: props.id,
				cursor: cursorOverride ?? cursor,
				field: orderBy || DEFAULT_ORDER_BY,
				...({ search }),
				direction: order.toUpperCase(),
				limit
			}
		})
	}

	//Snackbar
	const dispatch = React.useContext(DispatchContext)
	const openSnackbar = React.useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	// Create relation
	const [createListingArt, { loading: loadingCreateListingArt }] = useMutation(CREATE_LISTING_ART, {
		onError: (error) => {
			openSnackbar(severity.ERROR, 'Could not add art.')
		},

		onCompleted: (response) => {
			if (response.createListingArt.id) {
				// Success
				openSnackbar(severity.SUCCESS, 'Successfully added art.')

				// Add the art to state
				setRows(rows.concat(response.createListingArt))
				searchListingArt().then(response => handleResponse(response.data)).catch(handleError)

				// update the count in the QV
				setListingQuickView((oldVal) => ({
					...oldVal,
					artCount: oldVal.artCount + 1
				}))

				setAttempt(false)
				setModal({ ...modal, open: false })
			} else {
				// On failure, reset state and show error
				openSnackbar(severity.ERROR, 'Could not add art.')
			}
		},
	})

	// Delete
	const [deleteRelation, { loading: loadingDelete }] = useMutation(DELETE_LISTING_ART)

	// Update
	const [updateRelation] = useMutation(UPDATE_LISTING_ART)

	const handleDeleteListing =  () =>{
		deleteRelation({
			variables: {
				id: removeListingModal.id
			}
		}).then(response => {

			if (response && response.data.deleteListingArt.success === false) {
				openSnackbar(severity.ERROR, "There was an error deleting this relation.")
			} else {
				openSnackbar(severity.SUCCESS, "Successfully deleted relation.")
				searchListingArt()
					.then(response => handleResponse(response.data))
					.catch(error => handleError(error))
				// update the count in the QV
				setListingQuickView((oldVal) => ({
					...oldVal,
					artCount: oldVal.artCount - 1
				}))
			}

		}).catch(error => {
			console.error(error)
			openSnackbar(severity.ERROR, "There was an error deleting this relation.")
		})
	}

	// Tooltips
	const [ttOpen, setTTOpen] = React.useState({})
	const handleTTClose = (row) => setTTOpen({ [row.id]: false })
	const handleTTOpen = (row) => setTTOpen({ [row.id]: true })


	const [artList, setArtList] = useState([])

	// load all ids if needed
	const [ loadArtIds, { data, called } ] = useLazyQuery(GET_ALL_LISTING_ART, {
		variables: { id: props.id },
		onCompleted: () => {},
		onError: error => openSnackbar(severity.ERROR, error.message)
	})


	// Ids to be updated
	useEffect(() => {
		if (selectedRow) {
			setArtList([selectedRow.art])
		} else {
			const loadedArt = data?.getListingArt?.items?.map(a => a.art) ?? []
			setArtList(loadedArt)
			setEntities(loadedArt)
		}
	}, [selectedRow, data, setEntities])

	useEffect(() => {
		return () => {
			localStorage.setItem(columnSettingsKey, JSON.stringify(columnSettings))
			localStorage.setItem(`${columnSettingsKey}.density`, density)
			localStorage.setItem(`${columnSettingsKey}.visibility`, JSON.stringify(columnVisibilityModel))
			localStorage.setItem(`${columnSettingsKey}.limit`, limit)
		}
	}, [columnSettings, density, columnVisibilityModel, limit])



	// This prevents stale closures; anything that might change (contextually)
	// that needs to stay up to date must be listed in the useMemo dependencies.

	/** @type import('@mui/x-data-grid-pro').GridColDef[] */
	const gridCols = useMemo(() => {

		const cols = ([
			{
				field: "art",
				headerName: 'Artwork',
				renderHeader: function ArtHeader (args) {
					const [ctcAnchorEl, setCtcAnchorEl] = useState(null)

					const openArtSortMenu = (e) => {
						e.preventDefault()
						e.stopPropagation()
						setCtcAnchorEl(e.target)
					}
					const handleCtcAnchorClose = () => setCtcAnchorEl(null)

					return <>
						<Menu
							id="simple-menu"
							anchorEl={ctcAnchorEl}
							keepMounted
							open={!!ctcAnchorEl}
							onClose={handleCtcAnchorClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						> { artSorts.map(x =>
								<MenuItem key={`inner-menu-${x.id}`} value={x.id}
									onClick={() => {
										if (!x.noSort) {
											const field = x.id
											const oldSort = order ?? 'desc'
											const sort = oldSort === 'asc' ? 'desc' : 'asc'
											setOrder(sort)
											setOrderBy(field)
										} else {
											setOrderBy(null)
										}
										handleCtcAnchorClose()
									}}
								>
									<TableSortLabel
										active={!!orderBy === x.id}
										direction={order ?? "asc"}
										hideSortIcon={x.noSort}
										style={x.noSort ? {cursor: "default", fontWeight: 400} : {fontWeight: 400}}
									>
										{ x.label }
									</TableSortLabel>
								</MenuItem>
							)}
						</Menu>
						<Button
							size="small"
							endIcon={<KeyboardArrowDown />}
							onClick={openArtSortMenu}
							sx={{
								fontWeight: 500,
								color: '#808080',
								fontSize: 14
							}}
						>
							{ args.colDef.headerName }
						</Button>
					</>
				},
				minWidth: 415,
				sortable: false,
				renderCell: params => {
					const art = params.row?.art
					if (params?.api?.state?.density?.value === 'compact') {
						return art?.formatted_title ?
							<QuillText name="qv-title">
								{art?.formatted_title}
							</QuillText> :
							<span>{art?.title}</span>
					}
					return <Thumbnail
						formatted_name={art?.formatted_title}
						name={art.code_name}
						artist={getArtistThumbnailDetail( art )}
						date={art.year}
						avatar={art.imgUrl}
						type="art"
						animation={ false }
						largeText
						darkText={!art?.is_private}
						hasAccess={!art?.is_private && art.created_at}
					/>
				}
			}, {
				field: 'Verified',
				headerName: '',
				resizable: false,
				sortable: false,
				renderCell: params => {
					const Art = () => {
						const art = params.row?.art
						if (art?.is_private) return
						if (art?.is_verified && !art?.disabled) {
							return <img
								alt="verified-art"
								style={{
									width: '18px',
									height: '18px',
								}}
								src="/images/icons/VerifiedIcons/VerifiedBlueCheck.svg"
							/>
						}
						return <img
							alt="unverified-art"
							style={{
								width: '18px',
								height: '18px',
							}}
							src="/images/icons/VerifiedIcons/UnverifiedGreyX.svg"
						/>
					}
					return (
						<div className='flex w-full justify-center'>
							<Art />
						</div>
					)
				}
			}, {
				headerName: 'Inventory No.',
				field: 'art.inventory_number_prefix',
				width: 110,
				renderCell: params => params.row?.art?.inventory_number ?
					getArtInventoryNumber(params.row?.art): '-'
			}, {
				field: 'current_source',
				headerName: 'Source',
				minWidth: 415,
				sortable: false,
				renderCell: params => {

					return (!params.row?.art?.isPrivate && params.row?.art?.current_sources?.length) ?
						<Source
							loading={props.loading} 
							canViewOwnerContact={params.row?.art?.canViewOwnerContact} 
							sources={params.row?.art?.current_sources} 
							thumbnailStyle={{
								margin: '8px auto 8px 8px',
							}}
							avatarGroupStyles={{
								marginLeft: '1em'
							}}
							compact={params?.api?.state?.density?.value === 'compact'}
						/> : '-'
				}
					
			}, {
				field: 'art.is_active',
				headerName: "Activity",
				renderCell: params => !params.row?.art.is_private ?
					getArtActivity(params.row?.art) : '-'
			}, {
				headerName: 'Art Status',
				field: 'art.status_id',
				renderCell: params => !params.row?.art.is_private ?
					getArtStatusChip(params.row?.art, false,  artStatus) : '-',
			}, {
				field: 'listing_x_art.status_id',
				headerName: "Listing Status",
				width: 150,
				renderCell: params => {
					const statusIds = listingArtStatus.map(las => las.id)
					const hasStatus = params.row?.status_id
					const isIncluded = statusIds.includes(hasStatus)

					return hasStatus ?
						<LightTooltip
							open={ttOpen[params?.row?.id] || false}
							disableFocusListener
							disableTouchListener
							disableHoverListener
							onClose={() => handleTTClose(params.row)}
							onOpen={() => handleTTOpen(params.row)}
							placement="bottom"
							enterDelay={1000}
							title={
								<QuillText noWrap>
									{typeof params.row.note === 'object'
										? params.row.note || { ops: [{insert: "No Note"}]}
										: JSON.parse(params.row.note)}
								</QuillText>
							}>
							{isIncluded ? <Select
								IconComponent={ExpandMoreRoundedIcon}
								id="listing-art-status"

								input={<Dropdown />}
								onOpen={() => handleTTClose(params.row)}
								onMouseEnter={() => handleTTOpen(params.row)}
								onMouseLeave={() => handleTTClose(params.row)}
								className={clsx({
									'hideborder': true,
									'white-svg': params.row.art?.is_private,
								})}
								value={statusIds.includes(params.row.status_id) ? params.row.status_id : ''}
								onClick={e => {
									e.preventDefault()
									e.stopPropagation()
								}}
								style={{
									fontSize: '14px',
									color: getListingStatusColor(params.row.status_id, listingArtStatus),
									borderRadius: '4px'
								}}
								onChange={(event) => {
									const variables = {
										id: params.row.id,
										status_id: event.target.value,
									}

									// Send to server
									updateRelation({
										variables
									}).then(response => {

										if (response && !response?.data?.updateListingArt?.id) {
											openSnackbar(severity.ERROR, "There was an error updating this status.")
										} else {
											openSnackbar(severity.SUCCESS, "Successfully updated status.")
											setRows(rows.map(row => {
												if (row.id == response.data.updateListingArt.id)
													return response.data.updateListingArt
												else return row
											}))
										}
									}).catch(error => {
										console.error(error)
										openSnackbar(severity.ERROR, "There was an error updating this status.")
									})

								}}
							>
								{ listingArtStatus.map(type => (
									<MenuItem
										style={{ color: getListingStatusColor(type.id, listingArtStatus) }}
										key={ type.id }
										value={ type.id }
									>
										{ type.value }
									</MenuItem>
								))}
							</Select> : <Skeleton variant="text" animation="wave" width="100%" />
							}
						</LightTooltip> : <span>-</span>
				}
			}, {
				field: 'isFlagged',
				headerName: 'Flag',
				renderHeader: params => <DataGridFlagMenu
					variables={{
						filters: [
							{field: "listing_number", type: "eq", value: props.id, isOptional: false}
						]
					}}
					setRows={(callback) => {
						const updatedRows = callback(rows.map(r => r.art))
						setRows(rows => {
							updatedRows.forEach(ur => {
								const theRow = rows.find(r => r?.art?.id == ur.id)
								theRow && (theRow.art = ur)
							})
							return [...rows] // change the reference
						})
					}}
					rows={rows.map(r => r.art)}
					totalItems={totalItems}
					type="Art"
					hideFindFlagged={true}
					onClick={warnThenGo}
				/>,
				width: density === 'compact' ? 80 : 50,
				renderCell: params => {
					if (params.row.is_private && !params.row.created_at)
						return <></>
					return (
						<FavoriteAndFlag
							entityName="art"
							row={params.row.art}
							userId={userAuthentication?.user?.id}
							setRows={(callback) => {
								const updatedRows = callback(rows.map(r => r.art))
								setRows(rows => {
									updatedRows.forEach(ur => {
										const theRow = rows.find(r => r?.art?.id == ur.id)
										theRow && (theRow.art = ur)
									})
									return [...rows] // change the reference
								})
							}}
							compact={params.api.state.density.value == 'compact'}
							hidden={params.row.is_private && !params.row.created_at}
							onClick={warnThenGo}
						/>
					)},
				sortable: false,
				resizable: false,
				headerClassName: 'flag-header-data-grid'
			},
			{
				field: 'Actions',
				type: 'actions',
				headerName: '',
				getActions: (params) => [
					<GridActionsCellItem
						icon={<Undo />}
						onClick={() => {
							saveAndGo(
								`/art/${params.row?.art?.id}/details`,
								params.row?.art?.id
							)
						}}
						label="Go to Art"
						showInMenu
					/>,
					<GridActionsCellItem
						icon={<StickyNote2Outlined />}
						onClick={() => setEditNoteModal(params.row)}
						label="Edit Note"
						showInMenu
					/>,
					<GridActionsCellItem
						icon={<PersonRemove />}
						onClick={() => setRemoveListingModal(params.row)}
						label="Remove from Listing"
						showInMenu
					/>,
					<GridActionsCellItem
						icon={<ExpandCircleDown />}
						onClick={() => setInfoModal({open: true, row: params.row})}
						label="Information"
						showInMenu
					/>
				  ],
				resizable: false,
				width: 50,
				sortable: false,
			}
		])
		if (columnSettings?.length) restoreColumnSettings(cols, columnSettings)
		return cols
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totalItems, rows, density, listingArtStatus, columnSettings, ttOpen,
		order, orderBy, warnThenGo, saveAndGo, artStatus,
		openSnackbar, props.id, updateRelation, userAuthentication?.user?.id
	])

	const { getListing: listing } = props?.state || {}

	const onPageChange = (page) => {
		const newCursor = page * limit
		setActiveStep(page)
		setCursor(newCursor)
		searchListingArt(newCursor)
			.then(response => handleResponse(response.data))
			.catch(error => handleError(error))

		// Scroll to top of page
		var elmnt = document.querySelector('.search-list')
		elmnt.scrollIntoView({behavior: "smooth", block: "start"})
	}

	const listingGridViewProps = {
		handleSort: ({ orderBy, order = 'asc' }) => {
			setOrder(order)
			setOrderBy(orderBy)
		},
		isGrid,
		pagination: {
			count: totalItems,
			onPageChange: (event, page) => onPageChange(page),
			onRowsPerPageChange: (event) => setLimit(parseInt(event.target.value, 10)),
			page: activeStep,
			rowsPerPage: limit,
			rowsPerPageOptions: [10, 25, 50],
		},
		order,
		orderBy,
		rows,
		setIsGrid,
		searchListingArt: (search) => searchListingArt(null, { search }).then(response => handleResponse(response.data)).catch(handleError)
	}

	return (!props.findMode && props.id > 0) && (
		<>
			<Paper
				className="search-list padding-margin"
				data-testid="card-art"
				sx={{
					height: 'calc(100% - 2em)',
				}}
			>
				<div className="flex justify-between text-2xl">
					<div className="capitalize flex flex-col">
						<div className='h-[33px]'>{listing?.title || ' '}</div>
						<div className="text-grey">Art {`(${totalItems})`} </div>
					</div>
					<div className="flex gap-4 h-fit">
						<BulkActions
							selectedEntities={artList}
							entityType='art'
							onOpen={() => !artList?.length && !called && loadArtIds()}
							requery={() => searchListingArt()
								.then(response => handleResponse(response.data))
								.catch(error => handleError(error))
							}
							hangingMode={true}
							disabled={rows?.length <= 0}
							keyName={LISTING_ART_DETAIL_OPTIONS_KEY}
							smallButton
						/>
						<ChooseImageButton
							variant="contained"
							color="secondary"
							size="small"
							onClick={() => {
								setModal({ open: true, art_id: null })
							}}
						>
							Add Art
						</ChooseImageButton>
					</div>
				</div>

				<div style={{ height: 'calc(100% - 3em)', width: '100%' }}>
				{
					isGrid ? 
					<ListingGridView {...listingGridViewProps} />
					:
					<DataGridPro
						disableColumnMenu
						disableMultipleSelection
						columns={gridCols}
						onRowDoubleClick={({row}) => {
							const art = row.art
							if (art.is_private && !art.created_at) return
							saveAndGo(`/art/${art.id}`, art.id)
						}}
						rows={rows}
						rowCount={totalItems}
						loading={props.loading || artLoading}
						components={{ Toolbar: CustomToolBar }}
						pagination
						paginationMode="server"
						sortingMode="server"
						pageSize={limit}
						onPageSizeChange={setLimit}
						rowsPerPageOptions={[10, 25, 50]}
						page={activeStep}
						onPageChange={onPageChange}
						// sortModel seems to break on our "custom" contact sort
						onSortModelChange={newSort => {
							if (newSort.length) {
								setOrderBy(newSort[0].field)
								setOrder(newSort[0].sort)
							} else {
								setOrderBy(null)
								setOrder('asc')
							}
						}}
						onStateChange={({density}) => setDensity(density?.value ?? 'comfortable')}
						onRowClick={(params, event) => {
							let row = params.row
							const hidden = row?.art?.is_private &&
								!row?.art?.created_at
							if (event.metaKey) {
								if (hidden) return
			
								window.open(`/art/${row.art?.id}`, '_blank')
								return
							}

							setSelectedRow(row)
							props.setQVOverride(() => {
								if (hidden) return <UserAccessQuickView
									entity={row.art}
									onClose={() => {
										setSelectedRow(null)
										props.setQVOverride(null)
									}}
								/>
								return <ArtQuickView
									id={row?.art?.id}
									onClose={() => {
										setSelectedRow(null)
										props.setQVOverride(null)
									}}
								/>
							})
						}}
						selectionModel={selectedRow?.id ? [selectedRow?.id] : []}
						getRowClassName={({row}) => row.art?.is_private ? 'Private-DataGrid-Row' : null}
						columnVisibilityModel={columnVisibilityModel}
						onColumnVisibilityModelChange={setColumnVisibilityModel}
						onColumnOrderChange={({ oldIndex, targetIndex }) => {
							arraymove(gridCols, oldIndex, targetIndex)
							updateColumnSettings()
						}}
						onColumnWidthChange={({ colDef, width}) => {
							gridCols.find(col => col.field === colDef.field).width = width
							updateColumnSettings()
						}}
					/>
				}
				</div>

				<ListingRemoveModal
					disabled={loadingDelete}
					removeListingModal={removeListingModal}
					setRemoveListingModal={setRemoveListingModal}
					itemToRemove={removeListingModal?.title || ''}
					handleDeleteListing={handleDeleteListing}
				 />

			</Paper>

			<TransitionsModal
				className="listing-art-modal"
				open={modal.open}
				close={() => setModal({open: false, art_id: null})}
			>
				<h2 style={{fontWeight: 500}}>Add an Artwork</h2>
				<div className={(attempt && !modal.art_id) ? "error" : null}>
					<FormControl className={classes.searchSelection} style={{width: "100%", paddingRight: "0px", marginBottom: '1em'}}>
						<LimitedAutocomplete
							query={loadArt}
							setQuery={setLoadArt}

							isOptionEqualToValue={(a,b) => a?.id === b?.id}
							style={{width: "20em"}}
							open={open && !!options?.length}
							forcePopupIcon
							popupIcon={<ExpandMoreRoundedIcon />}
							onOpen={() => setOpen(true)}
							onClose={() => setOpen(false)}
							loading={loading}
							options={options}
							classes={classes2}

							getOptionDisabled={(option) => option.disabled}
							getOptionLabel={(row) => {

								const artWork = options?.find(e => e.id == row.id)

								if (!artWork) return ''

								const inventoryNumber = getArtInventoryNumber(artWork, false)

								if (artWork.title) {
									return `${artWork.title}${inventoryNumber ? ` (${inventoryNumber})` : ''}`
								} else if (artWork.artist) {
									return `${artWork.artist}${inventoryNumber ? ` (${inventoryNumber})` : ''}`
								} else {
									return inventoryNumber
								}
							}}

							renderOption={(optionProps, option) => {

								const disabled = !!rows.map(e => e.art.id)
									?.find(entry => entry === option.id)

								const artWork = options?.find(e => e.id == option.id) || {}
								const hidden = artWork.is_private && !artWork.created_at
								const isPrivate = artWork.is_private

								const thumbnail = (
									<Thumbnail
										formatted_name={artWork.formatted_title}
										name={artWork.code_name}
										artist={getArtistThumbnailDetail(
											artWork
										)}
										date={artWork.year}
										avatar={artWork.imgUrl}
										type="art"
										animation={false}
										largeText
										darkText={!isPrivate}
										hasAccess={!hidden}
									/>
								)

								if (disabled)
									return (
										<li {...optionProps} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', backgroundColor: isPrivate ? '#2E3134' : null}}>
											{thumbnail}
											<i style={{ color: isPrivate ? 'white' : null }} >Already Included</i>
										</li>
									)

								return <li {...optionProps} style={{ backgroundColor: isPrivate ? '#2E3134' : null }}>{thumbnail}</li>
							}}
							renderInput={(params) => (
								<TextBoxThinForAutocomplete
									autoFocus
									{...params}
									error={attempt && !modal.art_id}
									variant="outlined"
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{loading ? (
													<CircularProgress
														color="inherit"
														size={20}
													/>
												) : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>
							)}
							onChange={(event, value) => {
								setModal({
									...modal,
									art_id: value ? value.id : null
								})
							}}
						/>
					</FormControl>
				</div>

				<>
					<CancelButton
						variant="contained"
						style={{ float: 'left', marginTop: '1em' }}
						onClick={e => {
							e.preventDefault()
							setModal({open: false, art_id: null})
							setAttempt(false)
						}}
					>
						Cancel
					</CancelButton>

					<SubmitButton
						style={{ float: 'right', marginTop: '1em' }}
						variant="contained"
						disabled={loadingCreateListingArt}
						onClick={(e) => {
							setAttempt(true)

							if (attempt && !modal.art_id) {
								openSnackbar(severity.WARNING, "Please complete the fields in red.")
							} else if (modal.art_id) {
								const variables = {
									art_id: modal.art_id,
									listing_id: props.id,
								}

								// Send to server
								createListingArt({ variables })
							}
						}}
					>
						Submit
					</SubmitButton>
				</>
			</TransitionsModal>

			<TransitionsModal
				className="listing-contact-modal"
				open={warningModal.open}
				close={() => setWarningModal(wm => ({ ...wm, open: false, firstClick: false }))}
			>
				<div>
					You currently have { initialFlaggedCount } Art already flagged.
				</div>

				<div>
					<CancelButton
						variant="contained"
						style={{ float: 'left', marginTop: '1em' }}
						onClick={e => {
							e.preventDefault()
							warningModal.reject()
							setWarningModal(wm => ({
								...wm,
								open: false,
								firstClick: false
							}))
						}}
					>
						Cancel
					</CancelButton>

					<ResetButton
						style={{ float: 'right', marginTop: '1em' }}
						variant="contained"
						onClick={e => {
							e.preventDefault()
							warningModal.resolve()
							setWarningModal(wm => ({
								...wm,
								open: false,
								firstClick: false
							}))
						}}
					>
						Clear Flags
					</ResetButton>
				</div>
			</TransitionsModal>

			<InfoCard
				open={infoModal.open}
				object={infoModal.row}
				setInfoModal={setInfoModal}
				allAttributes
			/>

			{editNoteModal ?
				<ListingRelationNotes
					open={editNoteModal}
					close={() => setEditNoteModal(false)}
					row={editNoteModal}
					updateRelation={updateRelation}
					isPrivate={props.art?.is_private}
					setRows={setRows}
					rows={rows}
					responseName='updateListingArt'
					entity='Art'
				/> : null}

		</>
	)
}

export default withApollo(ListingArt)
