import Select from './components/Select'
import Search from './components/Search'

const RenderInput = (props) => {
  const { formik, name, type } = props || {}

  const inputProps = {
    ...props,
    onChange: formik?.handleChange || props?.onChange,
    value: formik?.values?.[name] || props?.value,
  }

  switch(type) {
    case 'search':
      return <Search {...inputProps} />
    case 'select':
      return <Select {...inputProps} />
  }
}

export default RenderInput
