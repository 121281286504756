/* eslint-disable eqeqeq */
import React, { useContext, useCallback, useState, useEffect } from 'react'
import {
	Paper,
	FormControl,
	Menu,
	MenuItem,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Radio,
} from '@mui/material'
import Label from '../../../input/Label'
import { Skeleton } from '@mui/material'
import TextBox from '../../../input/Text/TextBoxThin'
import { DispatchContext, LookupContext, AuthStateContext } from '../../../store'
import TransitionsModal from '../../../navigation/TransitionsModal/TransitionsModal'
import { CancelButton, SubmitButton } from '../../../input/Buttons'
import NumberFormat from 'react-number-format'
import { GET_ART_FINANCIALS, UPDATE_ART_FINANCIALS } from './Queries'
import { useMutation } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { severity } from '../../../Snackbar/CustomizedSnackbar'
import { permissions, permissionValues } from '../../../constants/permissions'
import { getNonNullArray } from '../../../common/helpers'
import { FindModeInput } from '../../../navigation/Tabs/TabbedPage'
import { skeletonStyle } from '../../../styles/makesStyles'
import { Skelly } from '../../../common/components/Skelly'
import SourceLabel from '../SourceLabel'
import Source from '../Source'


const financialTypes = {
	INSURED: 1,
	COST: 2,
	RETAIL: 3,
	NET: 4,
	PRODUCTION: 5
}

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false,
}

const ArtFinancials = (props) => {

	const [updateFinancials] = useMutation(UPDATE_ART_FINANCIALS)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	let disableContextMenu = false

	const lookup = useContext(LookupContext)
	const authState = useContext(AuthStateContext)
	const userPermissions  = authState?.user?.permissions

	const insuredPermission = userPermissions?.find((e) => e.permission_id == permissions.INSURED)
		.permission_value_id
	
	const costPermission = userPermissions?.find((e) => e.permission_id == permissions.COST)
		.permission_value_id

	const retailPermission = userPermissions?.find((e) => e.permission_id == permissions.RETAIL)
		.permission_value_id

	const netPermission = userPermissions?.find((e) => e.permission_id == permissions.NET)
		.permission_value_id

	const productionPermission = userPermissions?.find((e) => e.permission_id == permissions.PRODUCTION)
		.permission_value_id

	const currencies = lookup.data?.getCurrencies

	const [state, setState] = useState(initialState)
	const [artInput, setArtInput] = useState(null)

	if (insuredPermission < permissionValues.VIEW_ONLY 
		&& costPermission < permissionValues.VIEW_ONLY
		&& retailPermission < permissionValues.VIEW_ONLY
		&& netPermission < permissionValues.VIEW_ONLY
		&& productionPermission < permissionValues.VIEW_ONLY) {
		disableContextMenu = true
	}
	if (!props.artPiece || props.findMode) {
		disableContextMenu = true
	}

	const resetArtInput = (artPiece) => {
		setArtInput({
			id: artPiece.id,
			primary_currency_id: artPiece.primary_currency_id,
			financials: [],
		})
	}

	useEffect(() => {
		if (props.artPiece) resetArtInput(props.artPiece)
	}, [props.artPiece])

	const handleClick = (event) => {
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
			editable: false,
		})
	}
	
	const handleClose = (option) => {
		if (option === 'edit') {

			if (artInput.financials.length == 0)
				props.client
					.query({
						query: GET_ART_FINANCIALS,
						variables: {
							id: props.artPiece.id,
						},
					})
					.then((result) => {
						setArtInput({
							...artInput,
							financials: getNonNullArray(result.data?.getArtFinancials)  || [],
						})
					})

			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	return (
		<Paper className="qv-margin" 
			onContextMenu={(e) => !disableContextMenu && !state.mouseX ? handleClick(e) : null}
			id="art-caption"
			data-testid="card-financials"
		>
			<header
				style={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<h1 className="card-title" style={{ width: '100%' }}>
					<span>Financials</span>
				</h1>
			</header>
			<div className="column-body">
				<div className="column">
					{!props.findMode ?

						<FormControl>
							<Label disableAnimation shrink htmlFor="art-owner"
								style={{ display: 'flex' }}
							>
								<SourceLabel
									loading={props.loading} 
									canViewOwnerContact={props.artPiece?.canViewOwnerContact} 
									sources={props.artPiece?.current_sources} 
								/>
							</Label>

							<Source
								loading={props.loading} 
								canViewOwnerContact={props.artPiece?.canViewOwnerContact} 
								sources={props.artPiece?.current_sources} 
								enableNav
								thumbnailStyle={{
									margin: '8px auto 8px 8px',
								}}
								buttonStyles={{
									marginTop: '1.7em'
								}}
								avatarGroupStyles={{
									marginTop: '2em',
									marginLeft: '0.5em'
								}}
							/>

						</FormControl>

						: null}

					{ !props.findMode && <FormControl>
						<Label disableAnimation shrink htmlFor="art-currency">
							Currency
						</Label>
						{(props.loading && !props.findMode) ? (
							<Skeleton
								animation={props.error ? false : 'wave'}
								variant="text"
								width="auto"
								style={skeletonStyle}
							/>
						) : (
							<TextBox
								id="art-currency"
								value={
									currencies?.find((e) => e.id == props.artPiece?.primary_currency_id)?.symbol || ''
								}
								disabled={props.findMode}
								readOnly={true}
								placeholder={'-'}
							/>
						)}
					</FormControl>}

					<FormControl>
						<Label disableAnimation shrink htmlFor="art-retail">
							Retail
						</Label>
						{props.findMode ? 
							<FindModeInput field="retail"
								readOnly={retailPermission <= permissionValues.VIEW_ONLY}
								type="number"
							/> :
							(!props.artPiece && props.loading) ? <Skelly error={props.error} /> : (
								<NumberFormat
									id="art-retail"
									customInput={TextBox}
									value={props.artPiece?.primaryRetailPrice|| ''}
									placeholder={'-'}
									thousandSeparator
									allowNegative={false}
									readOnly={true}
									allowLeadingZeros={false}
								/>
							)
						}

					</FormControl>
				</div>
				<div className="column">
					<FormControl>
						<Label disableAnimation shrink htmlFor="art-cost">
							Cost
						</Label>
						{props.findMode ? 
							<FindModeInput field="cost"
								type="number"
								readOnly={costPermission <= permissionValues.VIEW_ONLY}
							/> :
							(!props.artPiece && props.loading) ?  <Skelly error={props.error} /> : 
								<NumberFormat
									id="art-cost"
									customInput={TextBox}
									value={props.artPiece?.primaryCost || ''}
									placeholder={'-'}
									thousandSeparator
									allowNegative={false}
									readOnly={true}
									allowLeadingZeros={false}
								/>
						}
					</FormControl>

					<FormControl>
						<Label disableAnimation shrink htmlFor="art-net">
							Net
						</Label>
						{props.findMode ? 
							<FindModeInput field="net"
								type="number"
								readOnly={netPermission <= permissionValues.VIEW_ONLY}
							/> :
							(!props.artPiece && props.loading) ? <Skelly error={props.error} /> : (
								<NumberFormat
									id="art-net"
									customInput={TextBox}
									value={props.artPiece?.primaryNet || ''}
									placeholder={'-'}
									thousandSeparator
									allowNegative={false}
									readOnly={true}
									allowLeadingZeros={false}
								/>
							)}
					</FormControl>

					<FormControl>
						<Label disableAnimation shrink htmlFor="art-insured">
							Insured
						</Label>
						{props.findMode ? <FindModeInput
							field="insured"
							type="number"
							readOnly={insuredPermission <= permissionValues.VIEW_ONLY}
						/> :
							(!props.artPiece && props.loading) ? <Skelly error={props.error} /> : (
								<NumberFormat
									id="art-insured"
									customInput={TextBox}
									value={props.artPiece?.primaryInsured || ''}
									placeholder={'-'}
									thousandSeparator
									allowNegative={false}
									readOnly={true}
									allowLeadingZeros={false}
								/>
							)}
					</FormControl>

					<FormControl>
						<Label disableAnimation shrink htmlFor="art-production">
							Production
						</Label>
						{props.findMode ? <FindModeInput
							field="production"
							type="number"
							readOnly={productionPermission <= permissionValues.VIEW_ONLY}
						/> :
							(!props.artPiece && props.loading) ? <Skelly error={props.error} /> : (
								<NumberFormat
									id="art-production"
									customInput={TextBox}
									value={props.artPiece?.primaryProduction || ''}
									placeholder={'-'}
									thousandSeparator
									allowNegative={false}
									readOnly={true}
									allowLeadingZeros={false}
								/>
							)}
					</FormControl>
				</div>
			</div>

			<Menu
				keepMounted
				open={state.mouseY !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					state.mouseY !== null && state.mouseX !== null
						? { top: state.mouseY, left: state.mouseX }
						: undefined
				}
			>
				<MenuItem onClick={() => handleClose('edit')}>Edit</MenuItem>
			</Menu>

			<TransitionsModal
				className="financials-modal"
				open={state.editable}
				close={() => {
					setState({
						...state,
						editable: false,
					})
					resetArtInput(props.artPiece)
				}}
			>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Primary</TableCell>
								<TableCell align="left" style={{ paddingLeft: '1.2em' }}>
									Retail
								</TableCell>
								<TableCell align="left" style={{ paddingLeft: '1.2em' }}>
									Cost
								</TableCell>
								<TableCell align="left" style={{ paddingLeft: '1.2em' }}>
									Net
								</TableCell>
								<TableCell align="left" style={{ paddingLeft: '1.2em' }}>
									Insured
								</TableCell>
								<TableCell align="left" style={{ paddingLeft: '1.2em' }}>
									Production
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<FinancialRow
								artInput={artInput}
								setArtInput={setArtInput}
								currency={currencies?.find((e) => e.id == 1)}
								artPiece={props.artPiece}
								insuredPermission={insuredPermission}
								retailPermission={retailPermission}
								costPermission={costPermission}
								netPermission={netPermission}
								productionPermission={productionPermission}
							/>

							<FinancialRow
								artInput={artInput}
								setArtInput={setArtInput}
								currency={currencies?.find((e) => e.id == 2)}
								artPiece={props.artPiece}
								insuredPermission={insuredPermission}
								retailPermission={retailPermission}
								costPermission={costPermission}
								netPermission={netPermission}
								productionPermission={productionPermission}
							/>

							<FinancialRow
								artInput={artInput}
								setArtInput={setArtInput}
								currency={currencies?.find((e) => e.id == 3)}
								artPiece={props.artPiece}
								insuredPermission={insuredPermission}
								retailPermission={retailPermission}
								costPermission={costPermission}
								netPermission={netPermission}
								productionPermission={productionPermission}
							/>

							<FinancialRow
								artInput={artInput}
								setArtInput={setArtInput}
								currency={currencies?.find((e) => e.id == 4)}
								artPiece={props.artPiece}
								insuredPermission={insuredPermission}
								retailPermission={retailPermission}
								costPermission={costPermission}
								netPermission={netPermission}
								productionPermission={productionPermission}
							/>

							<FinancialRow
								artInput={artInput}
								setArtInput={setArtInput}
								currency={currencies?.find((e) => e.id == 5)}
								artPiece={props.artPiece}
								insuredPermission={insuredPermission}
								retailPermission={retailPermission}
								costPermission={costPermission}
								netPermission={netPermission}
								productionPermission={productionPermission}
							/>

							<FinancialRow
								artInput={artInput}
								setArtInput={setArtInput}
								currency={currencies?.find((e) => e.id == 6)}
								artPiece={props.artPiece}
								insuredPermission={insuredPermission}
								retailPermission={retailPermission}
								costPermission={costPermission}
								netPermission={netPermission}
								productionPermission={productionPermission}
							/>
						</TableBody>
					</Table>
				</TableContainer>

				<div
					style={{
						width: '100%',
						paddingBottom: '1em',
						marginTop: '1em',
					}}
				>
					<SubmitButton
						variant="contained"
						style={{ float: 'right' }}
						type="submit"
						onClick={(e) => {
							const artFinancialInput = {
								id: artInput.id,
								primary_currency_id: artInput.primary_currency_id,
								financials: artInput.financials.map((financial) => {
									return financial.id
										? {
											id: financial.id,
											value: Number.parseFloat(financial.value),
										  }
										: {
											art_financial_type_id: financial.art_financial_type_id,
											art_id: financial.art_id,
											currency_id: financial.currency_id,
											value: Number.parseFloat(financial.value),
										  }
								}),
							}

							updateFinancials({ variables: { ArtFinancialUpdate: artFinancialInput } })
								.then((response) => {
									if (response.data.updateArtFinancials.success) {
										const financials = response.data.updateArtFinancials.financials

										// Update to new primary currency id
										props.setState({
											...props.state,
											getArtPiece: {
												...props.state.getArtPiece,
												primary_currency_id: artInput.primary_currency_id,

												primaryRetailPrice:
													financials.find(
														(e) =>
															e.currency_id == artInput.primary_currency_id &&
															e.art_financial_type_id == financialTypes.RETAIL
													)?.value || '',
												primaryCost:
													financials.find(
														(e) =>
															e.currency_id == artInput.primary_currency_id &&
															e.art_financial_type_id == financialTypes.COST
													)?.value || '',
												primaryNet:
													financials.find(
														(e) =>
															e.currency_id == artInput.primary_currency_id &&
															e.art_financial_type_id == financialTypes.NET
													)?.value || '',
												primaryInsured:
													financials.find(
														(e) =>
															e.currency_id == artInput.primary_currency_id &&
															e.art_financial_type_id == financialTypes.INSURED
													)?.value || '',
												primaryProduction:
													financials.find(
														(e) =>
															e.currency_id == artInput.primary_currency_id &&
															e.art_financial_type_id == financialTypes.PRODUCTION
													)?.value || '',
											},
										})

										openSnackbar(severity[response.data.updateArtFinancials.severity], 
											response.data.updateArtFinancials.message)

										props.refetch({
											variables: {
												id: artInput.id,
												cursor: null
											}
										})

									} else {
										
										openSnackbar(severity[response.data.updateArtFinancials.severity], 
											response.data.updateArtFinancials.message)
									}
								})
								.catch((error) => {
									console.log(error)
									openSnackbar(severity.ERROR, "There was an error updating art financials.")
								})

							setState({
								...state,
								editable: false,
							})
						}}
					>
						Submit
					</SubmitButton>
					<CancelButton
						variant="contained"
						style={{ float: 'right', marginRight: '1em' }}
						onClick={(e) => {
							setState({
								...state,
								editable: false,
							})
							resetArtInput(props.artPiece)
						}}
					>
						Cancel
					</CancelButton>
				</div>
			</TransitionsModal>
		</Paper>
	)
}

export default withApollo(ArtFinancials)

const FinancialRow = (props) => {
	const { artInput, setArtInput, currency } = props

	const handleChange = (e) => {
		let value = e.target.value?.replace(/,/g, '')
		if(value && value != "0" ){
			value = Number(value).toString()
		}

		let financialType
		switch (e.target.name) {
		case 'retail':
			financialType = financialTypes.RETAIL
			break

		case 'cost':
			financialType = financialTypes.COST
			break

		case 'net':
			financialType = financialTypes.NET
			break

		case 'insured':
			financialType = financialTypes.INSURED
			break

		case 'production':
			financialType = financialTypes.PRODUCTION
			break
		default:
			break
		}

		// Find existing entry
		const index = artInput.financials.findIndex((e) => {
			return e.currency_id == currency.id && e.art_financial_type_id == financialType
		})

		if (index === -1) {
			setArtInput({
				...artInput,
				financials: artInput.financials.concat([
					{
						art_id: props.artPiece.id,
						currency_id: currency.id,
						art_financial_type_id: financialType,
						value,
					},
				]),
			})
		} else {
			let tempItems = [...artInput.financials]
			let tempItem = { ...tempItems[index] }

			tempItem.value = value

			tempItems[index] = tempItem

			setArtInput({
				...artInput,
				financials: tempItems,
			})
		}
	}

	return (
		<TableRow id={currency?.id}>
			<TableCell
				style={{
					width: '8em',
				}}
				component="th"
				scope="row"
			>
				<div style={{display: 'flex', alignItems: 'center'}}>
					<Radio
						checked={artInput?.primary_currency_id == currency?.id || false}
						onChange={() => {
							setArtInput({
								...artInput,
								primary_currency_id: currency.id,
							})
						}}
					/>
					<div>{currency?.symbol}</div>
				</div>
			</TableCell>
			<TableCell align="right">
				<NumberFormat
					customInput={TextBox}
					placeholder={'-'}
					thousandSeparator
					allowNegative={false}
					readOnly={props.retailPermission <= permissionValues.VIEW_ONLY}
					name="retail"
					value={
						artInput.financials?.find((e) => {
							return e.currency_id == currency.id && e.art_financial_type_id == financialTypes.RETAIL
						})?.value || ''
					}
					onChange={handleChange}
					allowLeadingZeros={false}
				/>
			</TableCell>
			<TableCell align="right">
				<NumberFormat
					customInput={TextBox}
					placeholder={'-'}
					thousandSeparator
					allowNegative={false}
					name="cost"
					readOnly={props.costPermission <= permissionValues.VIEW_ONLY}
					value={
						artInput.financials?.find((e) => {
							return e.currency_id == currency.id && e.art_financial_type_id == financialTypes.COST
						})?.value || ''
					}
					onChange={handleChange}
					allowLeadingZeros={false}
				/>
			</TableCell>
			<TableCell align="right">
				<NumberFormat
					customInput={TextBox}
					placeholder={'-'}
					thousandSeparator
					allowNegative={false}
					name="net"
					readOnly={props.netPermission <= permissionValues.VIEW_ONLY}
					value={
						artInput.financials?.find((e) => {
							return e.currency_id == currency.id && e.art_financial_type_id == financialTypes.NET
						})?.value || ''
					}
					onChange={handleChange}
					allowLeadingZeros={false}
				/>
			</TableCell>
			<TableCell align="right">
				<NumberFormat
					customInput={TextBox}
					placeholder={'-'}
					thousandSeparator
					allowNegative={false}
					name="insured"
					readOnly={props.insuredPermission <= permissionValues.VIEW_ONLY}
					value={
						artInput.financials?.find((e) => {
							return e.currency_id == currency.id && e.art_financial_type_id == financialTypes.INSURED
						})?.value || ''
					}
					onChange={handleChange}
					allowLeadingZeros={false}
				/>
			</TableCell>
			<TableCell align="right">
				<NumberFormat
					customInput={TextBox}
					placeholder={'-'}
					thousandSeparator
					allowNegative={false}
					name="production"
					readOnly={props.productionPermission <= permissionValues.VIEW_ONLY}
					value={
						artInput.financials?.find((e) => {
							return e.currency_id == currency.id && e.art_financial_type_id == financialTypes.PRODUCTION
						})?.value || ''
					}
					onChange={handleChange}
					allowLeadingZeros={false}
				/>
			</TableCell>
		</TableRow>
	)
}
