/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useCallback, useState } from "react"
import { DispatchContext } from '../../store'
import {
	Paper,
	FormControl,
	Menu,
	MenuItem,
	Select,
} from "@mui/material"
import Dropdown from '../../input/Dropdown/ThinDropdown'
import Label from '../../input/Label'

import { Skeleton } from '@mui/material'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { CancelButton, SaveCardButton } from '../../input/Buttons'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { formatDate, returnDate } from '../../common/helpers'
import TextBox from '../../input/Text/TextBox'
import { useRecoilState } from "recoil"
import { findModeFieldSelector } from "../../navigation/Tabs/TabbedPage"
import { Skelly } from "../../common/components/Skelly"
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false
}


const IsLegacySelect = (props) => {

	const [value, setValue] = useRecoilState(findModeFieldSelector('is_legacy'))

	return <Select
		displayEmpty
		input={<Dropdown />}
		IconComponent={ExpandMoreRoundedIcon}
		value={value || ''}
		renderValue={val => {
			if (val === 'true') return 'Yes'
			if (val === 'false') return 'No'
			if (val === '') return <i>Ignored</i>
		}}
		onChange={(event) => setValue(event.target.value)}
	>
		<MenuItem key='active-null' value=""><i>Ignored</i></MenuItem>
		<MenuItem key='active-false' value="false">No</MenuItem>
		<MenuItem key='active-true' value="true">Yes</MenuItem>
	</Select>
}

export default function DealAdvancedCard(props) {
	
	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const [state, setState] = useState(initialState)
	const [dealInput, setDealInput] = useState({})
	const [attempt, setAttempt] = useState(false)

	const validDealTypes = [3, 4, 5, 6]
	const dateInteraction = !!validDealTypes.find(el => el == props.deal?.deal_type?.id)



	const resetDealInput = (deal) => setDealInput({
		id: deal.id,
		is_legacy: deal.is_legacy,
		start_at: deal.start_at,
		end_at: deal.end_at,
		ownership_note: deal.ownership_note
	})

	useEffect(() => {
		resetDealInput(props.deal)
	}, [props.deal])

	const handleClick = event => {
		if (state.mouseX || state.editable || props.findMode) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
			editable: false,
		})
	}

	const handleClose = option => {
		if (option === "edit") {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	return (
		<Paper
			className="qv-margin"
			onContextMenu={(e) => (!state.mouseX && !props.findMode) ? handleClick(e) : null}
			id="contact-details"
			data-testid="card-advanced"
			style={{
				maxHeight: '20em',
				overflow: 'scroll'
			}}
		>
			<h1 className="card-title">
				<span>Advanced</span>
				{(state.editable && !props.findMode)  && (
					<>
						<div className="spacer"></div>
						<CancelButton
							variant="contained"
							size="small"
							onClick={() => {
								resetDealInput(props.deal)
								handleClose()
								setAttempt(false)
							}}
						>
							Cancel
						</CancelButton>
						<SaveCardButton
							variant="contained"
							color="secondary"
							size="small"
							disabled={props.disabled}
							onClick={() => {

								setAttempt(true)

								if (attempt && dealInput?.is_legacy === undefined) {
									openSnackbar(severity.WARNING, "Please complete required fields.")
								} else {		
									
									const edits = {}

									if (dealInput.start_at) edits.start_at = returnDate(dealInput.start_at)
									if (dealInput.end_at) edits.end_at = returnDate(dealInput.end_at)
									if (dealInput.ownership_note) edits.ownership_note = dealInput.ownership_note

									props.onSave({
										id: dealInput.id,
										...edits
									})
									setState({
										...state,
										editable: false
									})
								}
							}}
						>
							Save
						</SaveCardButton>
					</>
				)}
			</h1>
			<div className="column-body">
				<div className="column">

					<FormControl>
						<Label id="legacy-label" disableAnimation shrink 
							error={attempt && state.editable && dealInput?.is_legacy === undefined}>
							Legacy Deal
						</Label>
						{props.findMode ? <IsLegacySelect /> :
							(props.loading || props.error) ? <Skelly error={props.error} /> : 
								<Select
									IconComponent={ExpandMoreRoundedIcon}
									name="prefix"
									
									labelId="legacy-label"
									input={<Dropdown />}
									value={!!dealInput?.is_legacy}
									className={"readonly"}
									readOnly={true}
									onChange={(e) => {
										setDealInput({
											...dealInput,
											is_legacy: e.target.value
										})
									}}
									data-testid="deal-legacy"
								>
									<MenuItem value={true} data-testid="deal-legacy-item">Yes</MenuItem>
									<MenuItem value={false}  data-testid="deal-legacy-item">No</MenuItem>
								</Select>
						}
						
					</FormControl>

					{dateInteraction ?

						<>
							<FormControl>
								<Label id="start-date-label" style={{fontSize: "18px"}} disableAnimation shrink>
			Start Date
								</Label>

								{(props.loading || props.error) ? <Skeleton
									animation={props.error ? false : "wave"}
									variant="text"
									width="auto"
									style={{
										height: "3.88em", 
										marginTop: "0.4em", 
										marginBottom: "-0.6em",
									}}
								/> : 

									<MobileDatePicker
										inputFormat="MMM do, yyyy"
										disableMaskedInput={true}
										cancelText={null}
										name="date"
										value={dealInput.start_at}
										inputVariant="outlined"
										style={{marginTop: "17px"}}
										className="MUIDatePicker"
										variant="dialog" 
										readOnly={!state.editable}
										renderInput={({ inputRef, inputProps, InputProps }) => {
		
											const newProps = { ...inputProps}

											newProps.readOnly = !state.editable
											if (!state.editable) newProps.onClick = () => {}

											return (
												<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
											)}}
										onChange={(date) => {
											setDealInput({
												...dealInput,
												start_at: date,
											})
										}}
										data-testid="start-date"
									/>
								}
							</FormControl> 

							<FormControl>
								<Label id="end-date-label" style={{fontSize: "18px"}} disableAnimation shrink>
			End Date
								</Label>

								{(props.loading || props.error) ? <Skeleton
									animation={props.error ? false : "wave"}
									variant="text"
									width="auto"
									style={{
										height: "3.88em", 
										marginTop: "0.4em", 
										marginBottom: "-0.6em",
									}}
								/> : 

									<MobileDatePicker
										inputFormat="MMM do, yyyy"
										disableMaskedInput={true}
										cancelText={null}
										name="date"
										value={dealInput.end_at}
										inputVariant="outlined"
										style={{marginTop: "17px"}}
										className="MUIDatePicker"
										variant="dialog" 
										readOnly={!state.editable}
										renderInput={({ inputRef, inputProps, InputProps }) => {
		
											const newProps = { ...inputProps}

											newProps.readOnly = !state.editable
											if (!state.editable) newProps.onClick = () => {}

											return (
												<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
											)}}
										labelFunc={(date) => {
											if (date) return formatDate(date, "MMMM do, yyyy")
											else return "-"
										}}
										onChange={(date) => {
											setDealInput({
												...dealInput,
												end_at: date
											})
										}}
										data-testid="end-date"
									/>
								}
							</FormControl>

						</>

						: null}

				</div>
				<div className="column" >

					<FormControl>
						<Label id="legacy-label" disableAnimation shrink>
							Ownership Note
						</Label>
						{(props.loading || props.error) ? <Skelly error={props.error} /> : 
							<TextBox
								multiline
								name="ownership_note"
								value={dealInput.ownership_note}
								readOnly={!state.editable}
								style={{marginTop: '1.7em'}}
								onChange={(e) => {
									setDealInput({
										...dealInput,
										ownership_note: e.target.value
									})
								}}
							/>}
					</FormControl>
				</div>
			</div>

			<Menu
				keepMounted
				open={state.mouseY !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					state.mouseY !== null && state.mouseX !== null
						? { top: state.mouseY, left: state.mouseX }
						: undefined
				}
			>
				<MenuItem onClick={() => handleClose("edit")}>Edit</MenuItem>

			</Menu>
		</Paper>
	)
}
