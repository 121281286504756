/* eslint-disable eqeqeq */
import React, { useState, useContext, useEffect } from 'react'
import QuickView from '../QuickView/QuickView'
import {
	FormControl,
	Select,
	MenuItem,
	CircularProgress,
	IconButton,
	ThemeProvider,
	StyledEngineProvider,
} from '@mui/material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import Label from '../input/Label'
import { CancelButton, SubmitButton, ChooseImageButton } from '../input/Buttons'
import Dropdown from '../input/Dropdown/Dropdown'
import { LookupContext, AuthStateContext, DispatchContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { useMutation } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { useNavigate } from 'react-router-dom'
import { GET_USERS } from '../User/Queries'
import { CREATE_DEAL, GET_ART_BY_IDS, GET_CONTACTS_BY_IDS, GET_POSSIBLE_DEAL_ART } from './Queries'
import { Autocomplete } from '@mui/material'
import { typeStyle, autoCompleteErrorStyles } from '../styles/makesStyles'
import { OfferPrice } from './Deal/OfferPrice'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { getArtInventoryNumber, getArtistThumbnailDetail } from '../common/helpers'
import LimitedAutocomplete from '../common/LimitedAutocomplete'
import privateObjectTheme from '../styles/muiThemes/privateObjectTheme'
import Thumbnail from '../Thumbnail/Thumbnail'
import SingleContact from '../input/SingleContact'
import TextBoxForAutocomplete from '../input/Text/TextBoxThinForAutocomplete'
import { dealTypes as dealTypeConstants } from '../constants/values'
import ProjectCodeInput from '../common/ProjectCodeInput'

function CreateDealQuickView (props) {

	const classes2 = autoCompleteErrorStyles()

	const authState = useContext(AuthStateContext)
	const lookup = useContext(LookupContext)
	const navigate = useNavigate()

	const [users, setUsers] = React.useState([])
	const [optionsDropdown, setOptionsDropdown] = React.useState(false)
	const optionsListLoading = optionsDropdown && users?.length === 0

	const [typeId, setTypeId] = React.useState(null)

	// Contact Entries
	const contacts = props.contacts?.length ?
		props.contacts?.map((row, i) => ({
			...row,
			key: i + 1,
			contact_role_id: i ? '' : typeId == dealTypeConstants.known_ownership ? '8' : '1',
		})) : [{
			key: 1,
			id: null,
			contact_role_id: typeId == dealTypeConstants.known_ownership ? '8' : '1'
		}]


	React.useEffect(() => {
		let active = true
		if (!optionsListLoading) {
			return undefined
		}
		props.client
			.query({ query: GET_USERS })
			.then(result => {
				if (active)
					setUsers(result.data.getUsers.items)
			})
		return () => active = false
	}, [optionsListLoading, props.client])

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}

	const dealTypes = lookup.data?.getDealTypes
	const contactRoles = lookup.data?.getContactRoles

	// Deal Entries
	let dealEntries = props.art?.map((row, i) => ({
		art: row,
		key: i + 1,
		offer_currency: row.primary_currency_id || '1',
		offer_amount: row.primaryRetailPrice || 0,
		art_id: row.id,
	}))

	if (!dealEntries?.length) {
		dealEntries = [{
			key: 1, offer_currency: '1',
			offer_amount: 0, art_id: null
		}]
	}


	const [dealInput, setDealInput] = useState({
		salespersons: [authState.user],
		type_id: props.typeId || 1,
		deal_entries: dealEntries,
		contacts: contacts
	})

	// On change of type id, reset deal input
	useEffect(() => {
		setDealInput(oldDealInput => ({
			salespersons: [authState.user],
			type_id: typeId || 1,
			deal_entries: oldDealInput.deal_entries,
			contacts: oldDealInput.contacts
		}))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [typeId])

	useEffect(() => {
		let active = true
		let ids = contacts.map(c => c.id).filter(c => c)
		const variables = {
			ids,
			cursor: 0,
			limit: ids.length
		}

		props.client.query({ query: GET_CONTACTS_BY_IDS, variables })
			.then(result => {
				let items = result.data.getContactsByIds?.items
				if (active) {
					const newContacts = contacts.map(c => ({
						...c,
						...items.find(c1 => c1.id == c.id)
					}))
					setDealInput({
						...dealInput,
						contacts: newContacts
					})
				}
			})
		return () => active = false
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// load full art
	useEffect(() => {
		let active = true
		let ids = dealEntries.map(c => c.art_id).filter(c => c)
		const variables = {
			ids,
			cursor: 0,
			limit: ids.length
		}

		props.client.query({ query: GET_ART_BY_IDS, variables })
			.then(result => {
				let items = result.data.getArtByIds?.items
				if (active) {
					const newArt = dealEntries.map(c => ({
						...c,
						art: {
							...c.art,
							...items.find(c1 => c1.id == c.art_id)
						}
					}))
					setDealInput({
						...dealInput,
						deal_entries: newArt
					})
				}
			})
		return () => active = false
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const [attempt, setAttempt] = useState(false)

	const [createDeal, {loading}] = useMutation(CREATE_DEAL)


	const [artOptions, setArtOptions] = React.useState(props.art || [])
	const [artOpen, setArtOpen] = useState(null)
	const [loadArt, setLoadArt] = React.useState(false)
	const artLoading = !!artOpen && !!loadArt

	React.useEffect(() => {
		let active = true

		if (!artLoading) {
			return undefined
		}

		props.client
			.query({
				query: GET_POSSIBLE_DEAL_ART,
				variables: { deal_id: null, query: loadArt },
			})
			.then((result) => {
				if (active) {
					setLoadArt(false)
					setArtOptions(result.data.getPossibleDealArt)
				}
			})

		return () => (active = false)
	}, [artLoading, loadArt, props.client])

	useEffect(() => {
		if (props.art) setArtOptions(props.art)
	}, [props.art])

	const showProjectCode = dealInput.type_id == 5 || // Consignment In
		dealInput.type_id == 2 // Gallery Purchce

	const QuickViewContent = (
		<QuickView className={props.darkTheme ? "quick-view-main dark-theme" : "quick-view-main"}>

			<form
				style={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
				}}
				onSubmit={event => {
					event.preventDefault()
					event.stopPropagation()
					setAttempt(true)

					if (!dealInput.salespersons.length ||
							dealInput.deal_entries
								.filter(de => !de.art_id).length ||
							dealInput.contacts.filter(c => !c.id).length ||
							dealInput.contacts.filter(c => !c.contact_role_id).length
							// || (showProjectCode && ( ! dealInput.deal_entries.every(de => de.project_code)))
					) {
						openSnackbar(
							severity.WARNING,
							'Please fill fields in red.'
						)
						return
					} else {

						const isKnownPurchase = dealInput.type_id == dealTypeConstants.known_ownership

						const CreateDealInput = {
							type_id: dealInput.type_id,
							salespersons_ids: dealInput
								.salespersons.map(s => s.id),
							contact_ids: dealInput.contacts
								.map(c => ({
									contact_id: c.id,
									contact_role_id: c.contact_role_id
								})),
							primary_contact_ids: !isKnownPurchase ? [dealInput.contacts[0]]
								.map(c => c.id) : null,
							deal_entries: dealInput.deal_entries.map(de => ({
								art_id: de.art_id,
								offer_amount: !isKnownPurchase ? Number(de.offer_amount) : null,
								offer_currency_id: !isKnownPurchase ? de.offer_currency : null,
								sale_amount: isKnownPurchase ? Number(de.offer_amount) : null,
								sale_currency_id: isKnownPurchase ? de.offer_currency : null,
								project_code_id: showProjectCode ? de.project_code?.id : null,
							}))
						}

						createDeal({ variables: { CreateDealInput } })
							.then((res) => {

								// update any arts.
								setAttempt(false)
								if (res.data.createDeal.success) {
									setAttempt(false)
									openSnackbar(severity.SUCCESS, 'Deal created successfully.')
									props.setCreateDeal(false)

									navigate(`/deals/${res.data.createDeal.deal.id}/details`)

								} else {
									setAttempt(false)
									openSnackbar( severity.ERROR, 'Could not create deal.')
								}
							})
							.catch((error) => {
								console.log(error)
								openSnackbar( severity.ERROR, 'There was an error creating the deal.')
							})
					}
				}}
			>
				<div className="quick-view-inner">
					<h2 className="card-title"  style={{
						alignSelf: 'flex-start',
					}}>Create New Deal</h2>

					<FormControl className="fullWidth padded-select">
						<Label id="type-label" disableAnimation shrink>
							Type
						</Label>

						<Select
							autoFocus
							IconComponent={ExpandMoreRoundedIcon}
							name="type_id"

							labelId="type-label"
							input={<Dropdown />}
							value={dealInput?.type_id || ''}
							onChange={event => {
								setTypeId(event.target.value)
								setDealInput({
									...dealInput,
									type_id: event.target.value
								})
							}}
							data-testid="deal-type"
						>
							{dealTypes?.map(type =>
								<MenuItem
									key={type.id}
									value={type.id}
									data-testid="deal-type-item"
								>
									{type.value}
								</MenuItem>
							)}
						</Select>
					</FormControl>

					<FormControl style={{paddingBottom: '2em', width: '100%'}}>
						<Label
							id="user-label"
							style={typeStyle}
							disableAnimation
							shrink
							htmlFor="art-salesperson"
							error={attempt && !dealInput.salespersons.length}
						>
							Gallery Contacts
						</Label>

						<Autocomplete
							multiple
							classes={classes2}
							style={{marginTop: "1.75em"}}
							open={optionsDropdown}
							isOptionEqualToValue={(option, value) => {
								return option.id == value.id
							}}
							forcePopupIcon
							filterSelectedOptions
							popupIcon={<ExpandMoreRoundedIcon />}
							size="medium"
							value={dealInput.salespersons ?? []}
							onOpen={() => { setOptionsDropdown(true) }}
							onClose={() => { setOptionsDropdown(false)}}
							getOptionLabel={option => {
								try {
									return option.first_name + ' ' + option.last_name
								} catch {
									return "Loading..."
								}
							}}
							options={users || []}
							loading={optionsListLoading}
							onChange={(event, value) => {
								setDealInput({
									...dealInput,
									salespersons: value
								})
							}}
							renderInput={(params) => (
								<TextBoxForAutocomplete
									{...params}
									error={attempt && !dealInput.salespersons.length}
									variant="outlined"
									fullWidth
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{optionsListLoading ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>
							)}
							data-testid="deal-salesperson"
						/>
					</FormControl>


					{dealInput.contacts.map((contact, i) => {

						return (
							<div key={contact.key} style={{width: '100%'}}>

								<FormControl style={{ width: '100%' }} >
									<Label
										disableAnimation
										shrink
										htmlFor="art-contact"
										error={attempt && !contact.id}
									>
										{ contact.key === 1 ? 'Primary Contact' : 'Contact' }
									</Label>

									<SingleContact
										elasticSearch="searchContacts"
										disabled={i != dealInput.contacts?.length - 1}
										private={props.darkTheme}
										disableClearable={true}
										error={attempt && !contact.id}
										onChange={(event, value) => {
											const e = dealInput.contacts
												.find(c => c === contact)
											Object.assign(e, value)
											setDealInput({ ...dealInput })
										}}
										value={contact.created_at ? contact : ''}
										data-testid={`deal-contact-${i}`}
										exclude={dealInput.contacts.map(c => c.id)}
									></SingleContact>
								</FormControl>

								{contact.key !== 1 ?
									<div style={{display: 'flex',
										flexDirection: 'row',
										alignItems: 'flex-end'
									}}>
										<IconButton
											aria-label="cancel"
											color="primary"
											style={{
												padding: '10px',
												marginRight: '0.5em'
											}}
											onClick={() => {
												dealInput.contacts = dealInput.contacts
													.filter(c => c !== contact)
												setDealInput({
													...dealInput,
												})
											}}
											size="large">
											<CancelOutlinedIcon style={{color: "grey"}}/>
										</IconButton>
										<FormControl className="fullWidth padded-select">
											<Label id="type-label" disableAnimation
												shrink
												error={attempt && !contact.contact_role_id}
											>
                                        		Role
											</Label>

											<Select
												IconComponent={ExpandMoreRoundedIcon}
												name="type_id"

												labelId="type-label"
												input={<Dropdown />}
												value={contact?.contact_role_id || ''}
												onChange={event => {
													const e = dealInput.contacts
														.find(c => c === contact)
													e.contact_role_id = event.target.value
													setDealInput({ ...dealInput })
												}}
												data-testid={`deal-contact_role-${i}`}
											>
												{contactRoles
													?.filter(cr => cr.id !== '1') // Primary
													?.map(role =>
														<MenuItem
															key={role.id}
															value={role.id}
															data-testid={`deal-contact_role-${i}-item`}
														>
															{role.value}
														</MenuItem>
													)}
											</Select>
										</FormControl>
									</div> : null}
							</div>
						)}
					)}

					<ChooseImageButton data-testid="deal-contact-add"
						variant="contained"
						disabled={
							!dealInput.contacts[dealInput.contacts?.length - 1]?.id ||
							!dealInput.contacts[dealInput.contacts?.length - 1]?.contact_role_id
						}
						style={{
							marginTop: '1em',
							marginBottom: '2em',
							width: '100%'
						}} onClick={() => {
							dealInput.contacts.push({
								key: Date.now(),
								id: null,
								contact_role_id: ''
							})
							setDealInput({
								...dealInput,
							})
						}}>
						<AddCircleOutlineIcon style={{color: "white"}}/>&nbsp;<span className="ci-span">Add Contact</span>
					</ChooseImageButton>

					{dealInput.deal_entries.map((entry, i) => <>
						<FormControl style={{ width: '100%'}} key={entry.key} >
							<Label
								disableAnimation
								shrink
								htmlFor="deal-art"
								error={attempt && !entry.art_id}
							>
								Artwork
							</Label>
							<div style={{display: 'flex', alignItems: 'flex-end', paddingBottom: '0.5em' }}>
								<LimitedAutocomplete
									isOptionEqualToValue={(a, b) => a?.id === b?.id}
									query={loadArt}
									setQuery={setLoadArt}

									open={artOpen === entry.key && !!artOptions?.length}
									onOpen={() => setArtOpen(entry.key)}
									onClose={() => setArtOpen(null)}

									loading={artLoading}
									disabled={i != dealInput.deal_entries?.length - 1}

									autoHighlight
									style={{marginTop: "1.75em", flexGrow: 2}}
									forcePopupIcon
									disableClearable={true}
									popupIcon={<ExpandMoreRoundedIcon />}
									options={artOptions}
									size="medium"
									getOptionDisabled={option => !!dealInput
										?.deal_entries
										?.find(entry => entry.art_id === option.id)
									}
									getOptionLabel={(row) => {
										const artWork = entry.art
										if (!artWork) return ''

										const inventoryNumber = getArtInventoryNumber(artWork, false)

										if (artWork.title) {
											return `${artWork.title}${inventoryNumber ? ` (${inventoryNumber})` : ''}`
										} else {
											return inventoryNumber
										}
									}}

									renderOption={(optionProps, option) => {
										const disabled = !!dealInput
											?.deal_entries
											?.find(entry => entry.art_id === option.id)
										const artWork = artOptions?.find(e => e.id == option.id) || {}
										const hidden = artWork.is_private && !artWork.created_at
										const isPrivate = artWork.is_private
										return (
											<li {...optionProps}
												key={option?.id}
												style={{
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'flex-start',
													backgroundColor: isPrivate ? '#2E3134' : null
												}}
											>
												<Thumbnail
													formatted_name={artWork.formatted_title}
													name={artWork.code_name}
													artist={getArtistThumbnailDetail(
														artWork
													)}
													date={artWork.year}
													avatar={artWork.imgUrl}
													type="art"
													animation={false}
													largeText
													darkText={!isPrivate}
													hasAccess={!hidden}
												></Thumbnail>
												{disabled && <i style={{ color: isPrivate ? 'white' : null }}>Already Included</i>}
											</li>
										)
									}}
									classes={classes2}
									value={entry.art || null}
									renderInput={(params) => (
										<TextBoxForAutocomplete
											{...params}
											error={attempt && !entry.art_id}
											variant="outlined"
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{artLoading && entry.key === artOpen? (
															<CircularProgress
																color="inherit"
																size={20}
															/>
														) : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
												style: {
													paddingTop: '6px',
													paddingBottom: '6px',
												},
											}}
										/>
									)}

									onChange={(event, value) => {
										const theArt = value
										const e = dealInput.deal_entries
											.find(de => de === entry)
										e.art_id = value ? theArt.id : null
										e.offer_amount = value ? theArt.primaryRetailPrice : 0
										e.offer_currency = value ? theArt.primary_currency_id : '1'
										e.art = theArt
										setDealInput({ ...dealInput })
									}}
									data-testid={`deal-artwork-${i}`}
								/>
							</div>
							<div className="flex" style={{
								alignItems: 'flex-end'
							}}>
								<OfferPrice
									labelOverride={dealInput.type_id == dealTypeConstants.known_ownership ? 'Sale Price' : null}
									currency={entry.offer_currency}
									price={entry.offer_amount || 0}
									onChange={(field, value) => {
										const e = dealInput.deal_entries
											.find(de => de === entry)
										e[field] = value
										setDealInput({ ...dealInput })
									}}
									currencies={lookup.data?.getCurrencies || []}
									testidOrdinal={i}
									testidPrefix="deal-artwork"
									style={{ alignItems: 'flex-end' }}
								>
								</OfferPrice>
							</div>
						</FormControl>
						<div className="flex" style={{
							alignItems: 'flex-end',
							width: '100%'
						}}>
							{ entry.key != 1 ?
								<IconButton
									aria-label="cancel"
									color="primary"
									style={{
										padding: '10px',
										height: 'min-content'
									}}
									onClick={() => {
										dealInput.deal_entries =
									dealInput.deal_entries
										.filter(de => de !== entry)
										setDealInput({
											...dealInput,
										})
									}}
									size="large">
									<CancelOutlinedIcon style={{color: "grey"}}/>
								</IconButton> : null
							}
							{showProjectCode && <FormControl fullWidth>
								<Label
									id="type-id-label"
									disableAnimation
									shrink
									// error={attempt && !entry.project_code} // todo && !listingInput?.project_code}
								>
									Project Code
								</Label>
								<ProjectCodeInput
									value={entry.project_code}
									// attempt={attempt}
									onChange={(event, value) => {
										const e = dealInput.deal_entries
											.find(de => de === entry)
										e.project_code = value
										setDealInput({ ...dealInput })
									}}
								/>
							</FormControl>}
						</div>
					</>)}

					<ChooseImageButton data-testid="deal-artwork-add" variant="contained" disabled={!dealInput.deal_entries[dealInput.deal_entries?.length - 1].art_id} style={{
						marginTop: '1em',
						width: '100%'
					}} onClick={() => {
						dealInput.deal_entries.push({
							key: Date.now(),
							offer_currency: '1',
							offer_amount: 0,
							art_id: null,
						})
						setDealInput({
							...dealInput,
						})

						setLoadArt(false)
						setArtOptions([])
					}}>
						<AddCircleOutlineIcon style={{color: "white"}}/>&nbsp;<span className="ci-span">Add Artwork</span>
					</ChooseImageButton>

				</div>
				<div
					style={{
						paddingTop: '1em',
						marginBottom: '1em',
						marginLeft: '1em',
						marginRight: '1em',
						marginTop: 'auto'
					}}
				>
					<CancelButton
						variant="contained"
						style={{ float: 'left' }}
						onClick={() => {
							props.setCreateDeal(false)
							setAttempt(false)
						}}
						data-testid="deal-cancel"
					>
						Cancel
					</CancelButton>

					<SubmitButton
						variant="contained"
						style={{ float: 'right' }}
						type="submit"
						data-testid="deal-submit"
						disabled={loading}
					>
						Submit
					</SubmitButton>
				</div>
			</form>
		</QuickView>
	)

	if (props.darkTheme) {
		return (
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={props.darkTheme ? privateObjectTheme : null}>
					{QuickViewContent}
				</ThemeProvider>
			</StyledEngineProvider>
		)
	} return QuickViewContent

}

export default withApollo(CreateDealQuickView)
