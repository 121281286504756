import React from 'react'
import { Paper, FormControl } from '@mui/material'
import Label from '../input/Label'
import { FindModeInput } from '../navigation/Tabs/TabbedPage'

const ListingSearch = (props) => {

	return (
		<Paper className="qv-margin" id="location-search">
			<h1 className="card-title">
				<span>Listing</span>
			</h1>

			<div className="column-body">
				<FormControl style={{
					flexGrow: 1,
					marginRight: '1em'
				}}>
					<Label disableAnimation shrink htmlFor="listing_number">
						Listing Number
					</Label>
					<FindModeInput field="listing_number" />
				</FormControl>
				<FormControl style={{
					flexGrow: 1,
					marginRight: '1em'
				}}>
					<Label disableAnimation shrink htmlFor="listing_title">
						Title
					</Label>
					<FindModeInput field="listing_title" />
				</FormControl>
				<FormControl style={{ flexGrow: 1 }}>
					<Label disableAnimation shrink htmlFor="listing_subtitle">
						Subtitle
					</Label>
					<FindModeInput field="listing_subtitle" />
				</FormControl>
			</div>
		</Paper>
	)
}

export default ListingSearch
