import React, { useCallback, useContext, useEffect } from 'react'
import LimitedAutocomplete from '../common/LimitedAutocomplete'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { autoCompleteErrorStyles, typeStyle } from '../styles/makesStyles'
import { CircularProgress, FormControl, TextField } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { SIMPLE_SEARCH_ARTISTS } from '../Art/Queries'
import { DispatchContext, LookupContext } from '../store'
import { withApollo } from '@apollo/client/react/hoc'
import Label from '../input/Label'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { CancelButton, SaveButton } from '../input/Buttons'
import { getArtistThumbnail } from '../common/helpers'
import { useMutation } from '@apollo/client'
import { UPDATE_CONTACT_INTERESTS } from './Queries'

const EditContactInterests = (props) => {
	const { open, onClose, selectedRows } = props

	const editableClasses = autoCompleteErrorStyles()

	const lookup = useContext(LookupContext)
	const categories = lookup.data?.getArtCategories || []

	// Autocomplete State
	const [autoOpen, setAutoOpen] = React.useState(false)
	const [autoOpenTwo, setAutoOpenTwo] = React.useState(false)
	const [loadArtist, setLoadArtist] = React.useState(false)
	const [options, setOptions] = React.useState([])
	const [addArtists, setAddArtists] = React.useState([])
	const [removeArtists, setRemoveArtists] = React.useState([])

	const artistLoading = (autoOpen || autoOpenTwo) && !!loadArtist

	const [addCategories, setAddCategories] = React.useState([])
	const [removeCategories, setRemoveCategories] = React.useState([])

	const [attempt, setAttempt] = React.useState(false)

	const [loading, setLoading] = React.useState(false)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	useEffect(() => {
		if (loading) openSnackbar(severity.INFO, "Loading")
	}, [loading, openSnackbar])

	React.useEffect(() => {
		let active = true

		if (!artistLoading) {
			return undefined
		}

		props.client
			.query({
				query: SIMPLE_SEARCH_ARTISTS,
				variables: { query: loadArtist },
			})
			.then((result) => {
				if (active) {
					setLoadArtist(false)
					setOptions(result.data.simpleSearchArtists)
				}
			})

		return () => {
			active = false
		}
	}, [artistLoading, loadArtist, props.client])

	const error = addArtists.length === 0 && removeArtists.length === 0
	&& addCategories.length === 0 && removeCategories.length === 0

	const handleClose = () => {
		setAutoOpen(false)
		setAutoOpenTwo(false)
		setLoadArtist(false)
		setOptions([])
		setAddArtists([])
		setRemoveArtists([])
		setAddCategories([])
		setRemoveCategories([])
		onClose()
	}

	const [updateContactInterests, { updateLoading }] = useMutation(UPDATE_CONTACT_INTERESTS)

	const handleSubmit = () => {

		setAttempt(true)

		const mapIds = (array) => (array || []).map((e) => e.id)

		if (error && attempt) {
			openSnackbar(severity.WARNING, 'Please supply at least one field.')
		}

		if (!error) {
			
			setLoading(true)
			const inputVariables = {
				contactIDs: mapIds(selectedRows),
				addArtistIds: mapIds(addArtists),
				removeArtistIds: mapIds(removeArtists),
				addCategoryIds: mapIds(addCategories),
				removeCategoryIds: mapIds(removeCategories),
			}
	
			updateContactInterests({
				variables: {
					ContactInterestsInput: inputVariables,
				},
			}).then((response) => {
				
				if (response.data?.updateContactInterests?.success) {
					openSnackbar(severity.SUCCESS, response.data.updateContactInterests.message)
				}

				else if (response.errors) {
					openSnackbar(severity.ERROR, response.errors?.[0]?.message)
				}
	
				else {
					openSnackbar(severity.ERROR, response.data.updateContactInterests.message)
				}
	
				setLoading(false)
				handleClose()
				
			}).catch( (error) => {
	
				console.log(error)
				openSnackbar(
					severity.ERROR,
					error
				)
	
				setLoading(false)
			} )
		}

	}

	return (
		<TransitionsModal className="new-tag-modal"
			open={open}	
			close={() => handleClose()}
		>
			<div style={{ width: '30em' }}>
				<h2
					className="card-title"
					style={{
						paddingBottom: '0.25',
						margin: 0,
					}}
				>
					Add
				</h2>

				<FormControl style={{ width: '100%' }} error={attempt && error}>
					<Label style={typeStyle} disableAnimation shrink>
						Artists
					</Label>

					<LimitedAutocomplete
						query={loadArtist}
						setQuery={setLoadArtist}
						multiple
						size="small"
						style={{ marginTop: '1.75em' }}
						forcePopupIcon
						filterSelectedOptions
						popupIcon={<ExpandMoreRoundedIcon />}
						classes={editableClasses}
						open={autoOpen}
						onOpen={() => {
							setAutoOpen(true)
						}}
						onClose={() => setAutoOpen(false)}
						getOptionLabel={(option) => {
							if (option?.first_name && option?.last_name) {
								return option?.first_name + ' ' + option?.last_name
							}
						}}
						getOptionDisabled={(option) => {
							return !!removeArtists?.find((el) => el.id === option.id)
						}}
						options={options}
						loading={artistLoading}
						renderOption={(props, option) => (
							<li {...props}>
								{getArtistThumbnail(option)}
							</li>
						)}
						value={addArtists}
						isOptionEqualToValue={(option, value) => option.id === value?.id}
						onChange={(event, value) => {
							setAddArtists(value)
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								id="artist-autocomplete"
								variant="outlined"
								fullWidth
								style={{ paddingTop: '0.5' }}
								classes={{ notchedOutline: null }}
								InputLabelProps={{
									shrink: true,
								}}
								error={attempt && error}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{artistLoading ? <CircularProgress color="inherit" size={20} /> : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
				</FormControl>

				<FormControl style={{ width: '100%' }} error={attempt && error}>
					<Label style={typeStyle} disableAnimation shrink>
						Categories
					</Label>

					<Autocomplete
						multiple
						style={{ marginTop: '1.75em' }}
						isOptionEqualToValue={(a, b) => a?.id === b?.id}
						options={categories || null}
						value={addCategories}
						getOptionLabel={(option) => option?.value || 'Loading...'}
						filterSelectedOptions
						getOptionDisabled={(option) => {
							return !!removeCategories?.find((el) => el.id === option.id)
						}}
						popupIcon={<ExpandMoreRoundedIcon />}
						classes={editableClasses}
						size="small"
						renderInput={(params) => <TextField {...params} variant="outlined" error={attempt && error} fullWidth />}
						onChange={(event, value) => {
							setAddCategories(value)
						}}
					/>
				</FormControl>

				<h2
					className="card-title"
					style={{
						paddingTop: '0.5em',
						paddingBottom: '0.25',
						margin: 0,
					}}
				>
					Remove
				</h2>

				<FormControl style={{ width: '100%' }} error={attempt && error}>
					<Label style={typeStyle} disableAnimation shrink>
						Artists
					</Label>

					<LimitedAutocomplete
						style={{ marginTop: '1.75em' }}
						query={loadArtist}
						setQuery={setLoadArtist}
						multiple
						size="small"
						forcePopupIcon
						filterSelectedOptions
						popupIcon={<ExpandMoreRoundedIcon />}
						classes={editableClasses}
						open={autoOpenTwo}
						onOpen={() => {
							setAutoOpenTwo(true)
						}}
						onClose={() => setAutoOpenTwo(false)}
						getOptionLabel={(option) => {
							if (option?.first_name && option?.last_name) {
								return option?.first_name + ' ' + option?.last_name
							}
						}}
						getOptionDisabled={(option) => {
							return !!addArtists?.find((el) => el.id === option.id)
						}}
						options={options}
						loading={artistLoading}
						value={removeArtists}
						isOptionEqualToValue={(option, value) => option.id === value?.id}
						onChange={(event, value) => {
							setRemoveArtists(value)
						}}
						renderOption={(props, option) => (
							<li {...props}>
								{getArtistThumbnail(option)}
							</li>
						)}
						renderInput={(params) => (
							<TextField
								{...params}
								id="owner-autocomplete"
								variant="outlined"
								fullWidth
								style={{ paddingTop: '0.5' }}
								classes={{ notchedOutline: null }}
								InputLabelProps={{
									shrink: true,
								}}
								error={attempt && error}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{artistLoading ? <CircularProgress color="inherit" size={20} /> : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
				</FormControl>

				<FormControl style={{ width: '100%' }} error={attempt && error}>
					<Label style={typeStyle} disableAnimation shrink>
						Categories
					</Label>

					<Autocomplete
						multiple
						style={{ marginTop: '1.75em' }}
						isOptionEqualToValue={(a, b) => a?.id === b?.id}
						options={categories || null}
						value={removeCategories}
						getOptionLabel={(option) => option?.value || 'Loading...'}
						filterSelectedOptions
						getOptionDisabled={(option) => {
							return !!addCategories?.find((el) => el.id === option.id)
						}}
						popupIcon={<ExpandMoreRoundedIcon />}
						classes={editableClasses}
						size="small"
						renderInput={(params) => <TextField {...params} error={attempt && error} variant="outlined" fullWidth />}
						onChange={(event, value) => {
							setRemoveCategories(value)
						}}
					/>
				</FormControl>

				<div style={{ 
					display:'flex', 
					justifyContent: 'space-between',
					marginTop: '1.5em'
				}}>
					<CancelButton variant="contained" size="medium"
						onClick={() => handleClose()}
					>
						Cancel
					</CancelButton>
					<SaveButton variant="contained" color="secondary" 
						size="medium"
						disabled={loading || updateLoading}
						onClick={() => handleSubmit()}
					>
               			 Save
					</SaveButton>
				</div>
			</div>
		</TransitionsModal>
	)
}

export default withApollo(EditContactInterests)
