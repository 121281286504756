/* eslint-disable eqeqeq */
import React, { useContext, useCallback, useEffect, useState } from 'react'
import { AuthStateContext, DispatchContext } from '../../store'
import { Paper, FormControl, Menu, MenuItem, IconButton, Fade, Tooltip } from '@mui/material'
import Label from '../../input/Label'
import TextBox from '../../input/Text/TextBoxThin'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { CancelButton, SaveCardButton } from '../../input/Buttons'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import DimensionSelection, { roundTo10th } from './DimensionSelection'
import { addQuillTitles, getArtInventoryNumber, getArtEditionText, stripMeta } from '../../common/helpers'
import ReactQuill from 'react-quill'
import Quill from 'quill'
import Delta from 'quill-delta'

import { autoCompleteErrorStyles } from '../../styles/makesStyles'
import FileCopyIcon from '@mui/icons-material/FileCopy'

import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css'

import '../../input/quill.css'
import QuillText from '../../input/QuillText'
import { permissions, permissionValues } from '../../constants/permissions'
import { SIMPLE_SEARCH_ARTISTS } from '../Queries'

import LimitedAutocomplete from '../../common/LimitedAutocomplete'
import { useLocation, useNavigate } from 'react-router-dom'
import { quillNoNewlines, quillToolbar } from '../../constants/values'
import useNavigation from '../../navigation/useNavigation'
import { findModeFieldsAtom, FindModeInput } from '../../navigation/Tabs/TabbedPage'
import { Skelly } from '../../common/components/Skelly'
import { useSetRecoilState } from 'recoil'
import { useApolloClient } from '@apollo/client'
import NumberFormat from 'react-number-format'


const toLbs = (x) => roundTo10th(x * 2.205)
const toKg = (x) => roundTo10th(Number(x / 2.205))

// lets us use exact font sizes https://stackoverflow.com/questions/38623716/how-to-add-custom-font-sizes-to-quilljs-editor
const Size = Quill.import('attributors/style/size')
Size.whitelist = ['11px']
Quill.register(Size, true)

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false,
}

export const getDimensionError = (artInput) => {
	let error = false
	artInput.dimensions &&
		artInput.dimensions.forEach((dimension) => {
			if (!dimension.description) error = true
		})
	return error
}

const ArtCaption = (props) => {

	const { push: pushNav } = useNavigation()
	const navigate = useNavigate()
	const location = useLocation()
	const client = useApolloClient()

	const setFindMode = useSetRecoilState(findModeFieldsAtom)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const authState = useContext(AuthStateContext)
	const userPermissions  = authState?.user?.permissions
	const [canViewArtists, setCanViewArtists] = useState(true)
	const [ttOpen, setTTOpen] = useState(false)
	const [showCopy, setShowCopy] = useState(false)

	const handleTooltipClose = () => setTTOpen(false)
	const handleTooltipOpen = () => setTTOpen(true)

	const [copyText, setCopyText] = useState(null)
	const [copyElement, setCopyElement] = useState(null)

	useEffect(() => {
		if (userPermissions) {
			const canViewArtists = userPermissions.find(e => e.permission_id == permissions.ARTISTS).permission_value_id
			if (canViewArtists < permissionValues.VIEW_ONLY) setCanViewArtists(false)
		}
	}, [userPermissions])

	const [dimension, setDimension] = useState({dimensions: [{}]})
	const [weightLbs, setWeightLbs] = useState()
	const [titleModal, setTitleModal] = useState(false)
	const [signModal, setSignModal] = useState(false)
	const [dimensionModal, setDimensionModal] = useState(false)
	const [attempt, setAttempt] = useState(false)
	const [state, setState] = useState(initialState)
	const [artInput, setArtInput] = useState({})
	const [artistAutocompleteOpen, setArtistAutocompleteOpen] = useState(false)
	const [artistOptions, setArtistOptions] = useState(
		artInput.artist ? [artInput.artist] : []
	)
	const [loadArtist, setLoadArtist] = useState(false)
	const artistLoading = artistAutocompleteOpen && !!loadArtist
	const artistValue = artistOptions.find(
		(item) => item?.id == artInput.artist?.id
	)


	const [quillText, setQuillText] = useState(null)
	useEffect(() => {
		if (quillText && quillText.editor) {
			const editor = quillText.getEditor()
			editor.focus()
			editor.setSelection(editor.getLength(), 0)
		}
	}, [quillText])

	const classes = autoCompleteErrorStyles()

	const quillDefault = {"ops": [{"insert":"-"}]}

	useEffect(() => {
		addQuillTitles()
	}, [titleModal, signModal])

	React.useEffect(() => {
		let active = true

		if (!artistLoading) {
			return undefined
		}

		client.query({
			query: SIMPLE_SEARCH_ARTISTS,
			variables: { query: loadArtist },
		}).then((result) => {
			if (active) {
				setLoadArtist(false)
				setArtistOptions(result.data.simpleSearchArtists)
			}
		})
		
		return () => active = false
	}, [artistLoading, loadArtist, client])

	React.useEffect(() => {
		if (!artistAutocompleteOpen && artistOptions.length <= 1) {
			setArtistOptions(artInput.artist ? [artInput.artist] : [])
		}
	}, [artInput.artist, artistAutocompleteOpen, artistOptions.length])

	// when closing the dimension modal in find mode.
	React.useEffect(() => {
		if (!dimensionModal && props.findMode) {
			setFindMode(old => ({
				...old,
				...(dimension.dimensions[0])
			}))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dimensionModal, props.findMode])
	
	React.useEffect(() => {
		const inventoryNumber = getArtInventoryNumber(props.art, false)
		const copySize = "11px"

		let signature = props.art?.signature?.ops?.reduce( (delta, op) => {
			return delta.insert( op.insert, {...op.attributes, size: copySize} )
		}, new Delta())
		// deal with missing or extra newlines at the end of a signature.
		if (signature?.ops?.length) {
			let lastOp = signature.ops[signature.ops.length - 1]
			lastOp.insert = lastOp.insert.replace(/\n*$/, '\n')
		}

		const resizedTitle = props.art?.formatted_title?.ops?.reduce( (delta, op) => {
			return delta.insert( op.insert, {...op.attributes, size: copySize} )
		}, new Delta())

		const other = new Delta()
			.insert([
				props.art?.year ? `\n${props.art?.year?.toString()}`: undefined,
				props.art?.medium ? `${props.art?.medium}` : undefined,
				props.art?.all_dimensions ? `${props.art?.all_dimensions}` : undefined,
				getArtEditionText(props.art) ? `${getArtEditionText(props.art)}` : undefined,
			].filter(x=>x).join("\n"), {size: copySize})
			.insert('\n', {size: copySize})

		const artCopyDelta = new Delta()
			.insert( [props.art?.artist?.first_name, props.art?.artist?.last_name, '\n'].join(' '),  {bold: true, size: copySize})
			.concat( resizedTitle || quillDefault )
			.concat(other)
			.concat(signature || new Delta() )
			.insert(`${inventoryNumber}`, {size: copySize} )

		setCopyText(artCopyDelta)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.art])

	const resetArtInput = (art) => {
		setArtInput({
			id: art.id,
			artist: {
				id: art.artist?.id || 0,
				first_name: art.artist?.first_name || '',
				last_name: art.artist?.last_name || '',
			},
			title: art.title,
			formatted_title: art.formatted_title,
			year: art.year,
			medium: art.medium,
			all_dimensions: art.all_dimensions,
			dimensions: art.dimension,
			signature: art.signature,
			edition_copy_number: art.edition_copy_number,
			edition_copy_total: art.edition_copy_total,
			edition_ap: art.edition_ap,
			edition_ap_total: art.edition_ap_total,
			additional_credit_lines: art.additional_credit_lines,
			photographer: art.photographer,
			copyright: art?.artist?.copyright,
			weight: art?.weight
		})
		setWeightLbs(toLbs(art?.weight) || '')
	}

	useEffect(() => {
		resetArtInput(props.art)
	}, [props.art])

	const handleClick = (event) => {
		if (state.mouseX || state.editable || props.findMode || !props.art?.id) return
		if (!titleModal && !dimensionModal && !signModal) {
			event.preventDefault()
			setState({
				mouseX: event.clientX - 2,
				mouseY: event.clientY - 4,
			})
		}
	}

	const handleClose = (option) => {
		if (option === 'edit') {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	const handleChange = (e) => updateField(e.target.name, e.target.value)

	const updateField = (field, value) => {
		setArtInput({
			...artInput,
			[field]: value
		})
	}
	const editable = state.editable || props.findMode

	const currentObject = artInput


	/**
	 * RENDER
	 */
	return (
		<Paper
			className="qv-margin"
			onContextMenu={(e) => !state.mouseX ? handleClick(e) : null}
			id="art-caption"
			onMouseEnter={() => setShowCopy(true)}
			onMouseLeave={() => setShowCopy(false)}
			data-testid="card-caption"
		>
			<header
				style={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<h1 className="card-title" style={{ width: '100%' }}>
					<span>Caption</span>
					{ !props.findMode &&
					<Tooltip open={ttOpen} onClose={handleTooltipClose}
						onOpen={handleTooltipOpen} title={"Copy formatted Caption"}
						arrow placement="bottom"
					>
						<Fade in={showCopy || state.editable} {...{timeout: {
							enter: 500,
							exit: 100
						} }}>
							<IconButton
								size="small"
								onClick={async ()=>{
									try{
										const editor = copyElement.getEditor()

										if(editor.getLength() === 0){
											openSnackbar(severity.WARNING,
												'No content to copy.'
											)
											return
										}

										editor.focus()
										editor.setSelection(0, editor.getLength())
										await navigator.clipboard.writeText(editor.getText())
										editor.setSelection(null)

										openSnackbar(
											severity.SUCCESS,
											'Art Caption copied to clipboard!'
										)
									} catch(e){
										openSnackbar(
											severity.ERROR,
											'Error copying Art Caption'
										)
									}
								}}
							>
								<FileCopyIcon style={{ color: props.private ? 'white' : 'grey', height: '20px' }}> </FileCopyIcon>
							</IconButton>
						</Fade>
					</Tooltip>
					}
					{state.editable && (
						<>
							<div className="spacer"></div>
							<CancelButton
								variant="contained"
								size="small"
								onClick={() => {
									resetArtInput(props.art)
									handleClose()
									setAttempt(false)
								}}
							>
								Cancel
							</CancelButton>
							<SaveCardButton
								variant="contained"
								color="secondary"
								size="small"
								disabled={props.disabled}
								onClick={() => {
									setAttempt(true)
									if (attempt &&
										(getDimensionError(artInput) || !artInput.title || !artInput.artist)
									) {
										openSnackbar(severity.WARNING,
											'Please correct fields in red.'
										)
									} else if (!(getDimensionError(artInput) || !artInput.title || !artInput.artist || !artInput.artist?.id)) {
										const variables = {
											id: artInput.id,
											artist_id: artInput.artist
												? artInput.artist.id
												: null,
											title: artInput.title,
											formatted_title:
												artInput.formatted_title,
											signature: artInput.signature,
											year: artInput.year?.toString(),
											medium: artInput.medium,
											dimension: stripMeta(
												artInput.dimensions
											),
											edition_copy_number: artInput.edition_copy_number,
											edition_copy_total: artInput.edition_copy_total,
											edition_ap: artInput.edition_ap,
											edition_ap_total: artInput.edition_ap_total,
											additional_credit_lines: artInput.additional_credit_lines,
											photographer: artInput.photographer,
											weight: artInput.weight == "0" ? null : artInput.weight
										}

										if (!canViewArtists)
											delete variables.artist_id

										props.onSave(variables)
										setState({
											...state,
											editable: false,
										})
										setAttempt(false)
									}
								}}
							>
								Save
							</SaveCardButton>
						</>
					)}
				</h1>
			</header>
			<div className="column-body">
				<div className="column">
					{state.editable && canViewArtists ? (
						<FormControl className="artist-formcontrol">
							<Label disableAnimation shrink htmlFor="art-artist" error={attempt && (!artInput.artist || !artInput.artist?.id)}>
								Artist{!props.findMode && '*'}
							</Label>
							<LimitedAutocomplete
								query={loadArtist}
								setQuery={setLoadArtist}

								size="small"
								forcePopupIcon
								classes={classes}
								filterSelectedOptions
								popupIcon={<ExpandMoreRoundedIcon />}
								open={artistAutocompleteOpen && artistOptions?.length}
								readOnly={!state.editable}
								onOpen={() => setArtistAutocompleteOpen(true)}
								onClose={() => setArtistAutocompleteOpen(false)}
								getOptionLabel={(option) => {
									if (!option.id) return '-' // Cannot view artist
									return `${option.first_name} ${option.last_name}`
								}}
								options={artistOptions}
								loading={artistLoading}
								value={artistValue || null}
								isOptionEqualToValue={(option, value) =>
									option.id == value.id
								}
								onChange={(event, value) => {
									let artist = null
									if (value !== null) {
										artist = {
											id: value.id,
											first_name: value.first_name,
											last_name: value.last_name,
										}
									}
									updateField('artist', artist)
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										id="artist-autocomplete"
										variant="outlined"
										fullWidth
										readOnly={!state.editable}
										error={attempt && (!artInput.artist || !artInput.artist?.id)}
										size="small"
										style={
											!state.editable
												? {
													paddingTop: '1.8em',
													paddingBottom: '0.4em',
												  }
												: { paddingTop: '1.8em' }
										}
										classes={{ notchedOutline: null }}
										InputLabelProps={{ shrink: true }}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{artistLoading ? (
														<CircularProgress
															color="inherit"
															size={20}
														/>
													) : null}
													{
														params.InputProps
															.endAdornment
													}
												</React.Fragment>
											),
										}}
									/>
								)}
								data-testid="caption-artist"
							/>
						</FormControl>
					) : (
						<FormControl>
							<Label disableAnimation shrink htmlFor="art-artist">
								Artist{!props.findMode && '*'}
							</Label>
							{props.loading ? <Skelly error={props.error} /> : (
								props.findMode ? <FindModeInput field="artist_full_name" /> :
									<TextBox
										className={!props.findMode ? "underline-hover pointer" : ''}
										id="art-artist"
										name="artist_full_name"
										value={currentObject.artist ? 
											`${currentObject.artist.first_name} ${currentObject.artist.last_name}` : (
												currentObject.artist_full_name || '')}
										readOnly={true}
										placeholder={currentObject.artist ? '' : '-'}
										style={{ fontWeight: 500 }}
										onClick={(event) => {
											if (!props.art.artist?.id) return

											if (event.metaKey) {
												window.open(`/artists/${props.art.artist.id}/details`, '_blank')
												return
											}

											const id = props.art.artist.id
											const state = { variables: { cursor: 0 } }
											pushNav({
												url: location.pathname,
												state,
												query: [id],
											})
											navigate(`/artists/${props.art.artist.id}/details`, { state })
										}}
										onChange={handleChange}
										data-testid="caption-artist"
									/>
							)}
						</FormControl>
					)}

					<FormControl
						style={props.loading ? { marginTop: '-.4em' } : null}
					>
						<Label disableAnimation shrink htmlFor="art-artist" error={attempt && !artInput.title}>
							Title{!props.findMode && '*'}
						</Label>
						{props.loading ? <Skelly error={props.error} /> :
							(!props.findMode ?
								<QuillText
									error={attempt && !artInput.title}
									inputStyle
									editableStyle={state.editable}
									onClick={() => {
										if (state.editable) setTitleModal(true)
									}}
									name="caption-title"
									fontSize="16px"
									data-testid="caption-title"
								>
									{ artInput?.formatted_title }
								</QuillText> : <FindModeInput field="title" />
							)
						}
					</FormControl>

					<FormControl>
						<Label disableAnimation shrink htmlFor="art-subtitle">
							Date
						</Label>
						{props.loading ? <Skelly error={props.error} /> :
							(props.findMode ? <FindModeInput field="year" /> :
								<TextBox
									id="art-year"
									value={currentObject.year?.toString() || ''}
									readOnly={!editable}
									placeholder={'-'}
									onChange={e => {
										updateField('year', e.target.value)
									}}
									data-testid="caption-year"
								/>)
						}
					</FormControl>

					<FormControl>
						<Label disableAnimation shrink htmlFor="art-medium">
							Medium
						</Label>
						{props.loading ? <Skelly error={props.error} /> :
							(props.findMode ? <FindModeInput field="medium" /> :
								<TextBox
									multiline
									id="art-medium"
									value={currentObject.medium || ''}
									readOnly={!editable}
									placeholder={'-'}
									onChange={(e) => {
										updateField('medium', e.target.value)
									}}
									data-testid="caption-medium"
								/>
							)}
					</FormControl>
					<FormControl>
						<Label disableAnimation shrink htmlFor="art-copyright">
							Copyright
						</Label>
						{props.loading ?  <Skelly error={props.error} /> :
							(props.findMode ? <FindModeInput field="copyright" /> :
								<TextBox
									id="art-copyright"
									value={currentObject.copyright || ''}
									readOnly={!props.findMode || state.editable}
									placeholder={'-'}
									onChange={(e) => {
										updateField('copyright', e.target.value)
									}}
									data-testid="caption-copyright"
								/>)
						}
					</FormControl>
					{!editable ? (
						<FormControl>
							<Label
								disableAnimation
								shrink
								htmlFor="art-weight"								
							>
								Weight
							</Label>
							{props.loading ? <Skelly error={props.error} /> :
								<TextBox
									id="art-weight"
									value={currentObject.weight ? `${currentObject.weight}`.concat(' kilograms') : '-'}
									readOnly={true}
									data-testid="caption-weight"
								/>
							}
						</FormControl>
					) :
						<>
							{props.loading ? <Skelly error={props.error} /> : <>
								<div style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'baseline'
								}}>
									<FormControl >
										<Label disableAnimation
											shrink
											htmlFor="art-weight">
											Weight
										</Label>
										{props.findMode ? 
											<FindModeInput field="weight" /> : 
											<NumberFormat
												name="weight_metric"
												step="any"
												decimalScale={1}
												onChange={(e) => {
													const x = Number(e.target.value)
													updateField('weight', roundTo10th(x))
													setWeightLbs(toLbs(x))
												}}
												value={roundTo10th(currentObject.weight) || ''}
												data-testid={'caption-weight'}
												customInput={TextBox}
												allowLeadingZeros={false}
											/>
										}
									</FormControl>
									<span style={{ padding: '5px' }}>
										kg.
									</span>
									{!props.findMode ? 
										<FormControl>
											<NumberFormat
												name="weight_imperial"
												step="any"
												decimalScale={1}
												onChange={(e) => {
													if (e.target.value) {
														const x = Number(e.target.value)
														updateField('weight', toKg(x))
														setWeightLbs(roundTo10th(x))
													} else {
														setWeightLbs()
														updateField('weight')
													}
												}}
												value={weightLbs}
												data-testid="caption-weight-lbs"
												customInput={TextBox}
												allowLeadingZeros={false}
											/>
										</FormControl>
										: <></>
									}
									{!props.findMode ? 
										<span style={{ padding: '5px' }}>
											lbs.
										</span> : <></>
									}
								</div>
							</>}
						</>
					}
				</div>
				<div className="column">
					<FormControl>
						<Label
							disableAnimation
							shrink
							htmlFor="art-dimension"
							error={attempt && getDimensionError(artInput)}
						>
							Dimensions { !props.findMode &&
								(artInput.dimensions?.length ?
									`(${artInput.dimensions?.length})` : '')
							}
						</Label>
						{props.loading ? <Skelly error={props.error} /> :
							<TextBox
								multiline
								id="art-dimension"
								error={attempt && getDimensionError(artInput)}
								value={ props?.findMode ?
									Object.values(dimension?.dimensions?.[0] ?? {})
										.map(v => `${v}cm`).join(' × ')
									: (artInput?.all_dimensions || '')}
								readOnly={!editable}
								placeholder={'-'}
								onClick={(e) => {
									if (state.editable || props.findMode) {
										setDimensionModal(true)
									}
								}}
								data-testid="caption-dimensions"
							/>
						}
					</FormControl>

					<FormControl>
						<Label disableAnimation shrink htmlFor="art-signed">
							Signed
						</Label>

						{props.loading ? <Skelly error={props.error} /> :
							(!props.findMode ? <QuillText
								inputStyle
								editableStyle={state.editable}
								onClick={() => {
									if (state.editable) setSignModal(true)
								}}
								name="caption-signed"
								fontSize="16px"
								data-testid="caption-signed"
							>
								{artInput?.signature || quillDefault }
							</QuillText> : <FindModeInput field="signature" />)
						}
					</FormControl>

					{!editable ? (
						<FormControl>
							<Label
								disableAnimation
								shrink
								htmlFor="art-edition"
							>
								Edition
							</Label>
							{props.loading ? <Skelly error={props.error} /> :
								<TextBox
									id="art-edition"
									value={getArtEditionText(artInput) || "-"}
									readOnly={true}
									data-testid="caption-edition"
								/>
							}
						</FormControl>
					) :
						<>
							{props.loading ? <Skelly error={props.error} /> : <>
								<div style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'baseline'
								}}>
									<FormControl >
										<Label disableAnimation
											shrink
											htmlFor="art-edition"
										>Edition</Label>{props.findMode ? 
											<FindModeInput field="editionCopyNumber" /> : 
											<TextBox
												name="edition_copy_number"
												value={currentObject.edition_copy_number || ''}
												placeholder="#"
												onChange={handleChange}
												data-testid="caption-edition-copy"
											/>}
									</FormControl>
									<span style={{ padding: '5px' }}>
											of
									</span>
									<FormControl>{props.findMode ? 
										<FindModeInput field="editionCopyTotal" /> : 
										<TextBox
											name="edition_copy_total"
											value={currentObject.edition_copy_total || ''}
											placeholder="#"
											onChange={handleChange}
											data-testid="caption-edition-copy-total"
										/>}
									</FormControl>
								</div>
								<div style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'baseline'
								}}>
									<FormControl>
										<Label disableAnimation
											shrink htmlFor="art-ap"
										>AP</Label>{props.findMode ? 
											<FindModeInput field="edition_ap" /> : 
											<TextBox
												name="edition_ap"
												value={currentObject.edition_ap || ''}
												onChange={handleChange}
												placeholder="AP #"
												data-testid="caption-edition-ap"
											/>}
									</FormControl>
									<span style={{ padding: '5px' }}>
										of
									</span>
									<FormControl>{props.findMode ? 
										<FindModeInput field="edition_ap_total" /> : 
										<TextBox
											name="edition_ap_total"
											value={currentObject.edition_ap_total || ''}
											onChange={handleChange}
											placeholder="Total AP"
											data-testid="caption-edition-ap-total"
										/>}
									</FormControl>
								</div>
							</>}
						</>
					}
					<FormControl>
						<Label disableAnimation shrink htmlFor="art-photographer">
							Photographer
						</Label>
						{props.loading ? <Skelly error={props.error} /> :
							(props.findMode ? <FindModeInput field="photographer" />: 
								<TextBox
									id="art-photographer"
									value={currentObject.photographer || ''}
									readOnly={!editable}
									placeholder={'-'}
									onChange={(e) => {
										updateField('photographer', e.target.value)
									}}
									data-testid="caption-photographer"
								/>)
						}
					</FormControl>
					<FormControl>
						<Label disableAnimation shrink htmlFor="art-additional_credit_lines">
							Additional Credit Lines
						</Label>
						{props.loading ? <Skelly error={props.error} /> :
							(props.findMode ? <FindModeInput field="additional_credit_lines" /> : 
								<TextBox
									id="art-additional_credit_lines"
									value={currentObject.additional_credit_lines || ''}
									readOnly={!editable}
									placeholder={'-'}
									onChange={e => updateField(
										'additional_credit_lines', e.target.value
									)}
									data-testid="caption-additional_credit_lines"
								/>)
						}
					</FormControl>
				</div>
			</div>

			<Menu
				keepMounted
				open={state.mouseY !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					state.mouseY !== null && state.mouseX !== null
						? { top: state.mouseY, left: state.mouseX }
						: undefined
				}
			>
				<MenuItem onClick={() => handleClose('edit')}>Edit</MenuItem>
			</Menu>

			<TransitionsModal
				className="dimension-modal"
				open={dimensionModal}
				close={() => {
					setDimensionModal(false)
					setArtInput({
						...artInput,
						all_dimensions: artInput.dimensions
							?.filter((e) => e.description)
							?.map((e) => e.description)
							?.sort((a, b) => Number(a.ordinal) - Number(b.ordinal))
							.join('\n'),
					})
				}}
			>
				<DimensionSelection
					showOldLabel
					setDimensionModal={setDimensionModal}
					art={!props.findMode ? props.art : dimension}
					artInput={props.findMode ? dimension : artInput }
					setArtInput={props.findMode ? setDimension : setArtInput}
					attempt={attempt}
					findMode={props.findMode}
				/>
			</TransitionsModal>

			<TransitionsModal
				noPadding
				className="title-modal"
				open={titleModal}
				close={() => {
					setTitleModal(false)
				}}
			>
				<div className={props.art?.is_private ? 'private-object' : null}>
					<ReactQuill
						className="modal"
						theme="snow"
						ref={(el) => {
							if (el) setQuillText(el)
						}}
						value={artInput.formatted_title || { ops: [] }}
						style={{ minWidth: '30em', color: props.art?.is_private ? 'white' : null }}
						modules={quillNoNewlines}
						onKeyUp={(event) => {
							// Close the modal if they hit enter
							if (event.keyCode == 13) setTitleModal(false)
						}}
						onChange={(content, delta, source, editor) => {
							setArtInput({
								...artInput,
								formatted_title: editor.getContents(),
								title: editor.getText().trim(),
							})
						}}
					/>
				</div>
			</TransitionsModal>

			<TransitionsModal
				noPadding
				className="sign-modal"
				open={signModal}
				close={() => setSignModal(false)}
			>
				<div className={props.art?.is_private ? 'private-object' : null}>
					<ReactQuill
						className="modal"
						theme="snow"
						ref={(el) => {
							if (el) setQuillText(el)
						}}
						value={artInput.signature || { ops: [] }}
						style={{ minWidth: '30em', color: props.art?.is_private ? 'white' : null  }}
						modules={quillToolbar}
						onChange={(content, delta, source, editor) => {
							setArtInput({
								...artInput,
								signature: editor.getContents(),
							})
						}}
					/>
				</div>
			</TransitionsModal>

			<div style={{position: 'absolute', opacity: 0, zIndex: -1}}>
				<ReactQuill
					value={copyText || { ops: [] }}
					ref={(el) => {
						if (el) setCopyElement(el)
					}}
				>
				</ReactQuill>
			</div>
		</Paper>
	)
}

export default ArtCaption
