/* eslint-disable eqeqeq */
import React from 'react'
import FormControl from '@mui/material/FormControl'
import {autoCompleteStyles, searchStyles} from '../styles/makesStyles'
import { withApollo } from '@apollo/client/react/hoc'	
import { SIMPLE_SEARCH_LISTINGS } from './Queries'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { SaveCardButton, CancelButton } from '../input/Buttons'
import { getNonNullArray } from '../common/helpers'
import LimitedAutocomplete from '../common/LimitedAutocomplete'
import TextBoxThinForAutocomplete from '../input/Text/TextBoxThinForAutocomplete'

function ListingDropdown(props) {
	const classes = searchStyles()
	const autoCompleteStyle = autoCompleteStyles()
	
	// User
	const [optionsDropdown, setOptionsDropdown] = React.useState(false)
	const [options, setOptions] = React.useState([])
	const [loadListing, setLoadListing] = React.useState(false)
	const listingLoading = optionsDropdown && !!loadListing
	
	const [selection, setSelection] = React.useState([])
	const [listingIds, setListingIds] = React.useState([])

	React.useEffect(() => {
		let active = true

		if (!listingLoading) {
			return undefined
		}

		props.client
			.query({
				query: SIMPLE_SEARCH_LISTINGS,
				variables: { query: loadListing },
			})
			.then((result) => {
				if (active) {
					setLoadListing(false)
					setOptions(getNonNullArray(result.data.simpleSearchListings))
				}
			})

		return () => {
			active = false
		}
	}, [listingLoading, loadListing, props.client])
	

	return (
		<div>
			<FormControl className={classes.selection} style={{ width: "100%"}}>
				
				<div style={{ display: 'flex', justifyContent:'space-between', alignItems: 'center'}}>
					<h1 className="card-title" >{props.name} Listings</h1>
				</div>
				<div>{ props.description }</div>
				<LimitedAutocomplete

					query={loadListing}
					setQuery={setLoadListing}
					minimum={2}

					multiple
					classes={autoCompleteStyle}
					open={optionsDropdown && !!options?.length}
					isOptionEqualToValue={(option, value) => { return option.id == value.id }}
					forcePopupIcon
					filterSelectedOptions
					popupIcon={<ExpandMoreRoundedIcon />}
					value={selection ?? []}
					onOpen={() => { setOptionsDropdown(true) }}
					onClose={() => { setOptionsDropdown(false)}}
					getOptionLabel={option => {
						try {
							return option.title
						} catch {
							return "Loading..."
						}
					}}
					options={options || []}
					onChange={(event, value) => {
						setSelection(value)
						const ids = value.map(x=> Number(x.id))
						setListingIds({
							object: {
								name: "listing_ids",
								value: ids
							}
						})
					}}
					renderInput={(params) => (
						<TextBoxThinForAutocomplete
							{...params}
							variant="outlined"
							fullWidth
							placeholder={!selection.length ? "-" : ""}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<React.Fragment>
										{params.InputProps.endAdornment}
									</React.Fragment>
								),
							}}
						/>
					)}
				/>
			</FormControl>

			<div style={{
				display: 'flex',
				justifyContent: 'space-between',
				marginTop: '1em',
			}}>
				<CancelButton
					variant="contained"
					size="medium"
					onClick={() => {
						props.setOpenListingDropdownModal(false)
					}}
				>
					Cancel
				</CancelButton>

				<SaveCardButton
					variant="contained"
					size="medium"
					disabled={!listingIds.object || !listingIds.object.value.length || props.loading}
					onClick={() =>{
						props.setOpenListingDropdownModal(false)
						props.onSave(listingIds)
					}}
				>
					Save
				</SaveCardButton>
			</div>
		</div>
	)
}


export default withApollo(ListingDropdown)
