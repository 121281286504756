/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react'
import LimitedAutocomplete from '../common/LimitedAutocomplete'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { getContactLabel } from '../common/helpers'
import { CircularProgress } from '@mui/material'
import { autoCompleteErrorStyles } from '../styles/makesStyles'
import { useLazyQuery } from '@apollo/client'
import ContactThumbnail from '../Thumbnail/ContactThumbnail'
import { SEARCH_CONTACTS_FAST } from '../Contacts/Contact/Queries'
import { LookupContext } from '../store'
import { reservedCharactersRegex } from '../common/searchHelpers'
import TextBoxForAutocomplete from './Text/TextBoxForAutocomplete'
import TextBoxThinForAutocomplete from './Text/TextBoxThinForAutocomplete'
import { uniqBy } from 'lodash'
import { DYNAMIC_CONTACT_SEARCH } from '../Search/Queries'

/**
 * @typedef SingleContactProps
 * @property {boolean=} error - if the value isn't valie
 * @property {DocumentNode=} query - the query to call for the autocomplete
 * @property {function} onChange - a callback when the contact value changes
 * @property {boolean=} private - if the ... context? is private
 * @property {*} [value] - currently selected 'Contact' object
 * @property {boolean=} smallTextField - determines whether to use small textfield or not
 * @property {string} elasticSearch - ?
 * @property {number[]=} exclude - list of ids to exclude as possibilities
 * @property {boolean=} disabled
 * @property {boolean=} disableClearable
 */

/**
 * @param {SingleContactProps} props
 */
export default function SingleContact (props) {

	// pass down styling/id props that are passed in
	const passedProps = {}
	if (props.className) passedProps.className = props.className
	if (props.style) passedProps.style = props.style
	if (props['data-testid']) passedProps['data-testid'] = props['data-testid']
	if (props.onChange) passedProps.onChange = props.onChange

	const [contactOpen, setContactOpen] = useState(false)
	const [loadContacts, setLoadContacts] = useState(false)

	let query = SEARCH_CONTACTS_FAST
	if (!props.elasticSearch) query = props.query
	if (props.query == 'dynamicContactSearch') query = DYNAMIC_CONTACT_SEARCH
	const [lazyLoadContacts, { loading, data }] = useLazyQuery(query)

	const [contactOptions, setContactOptions] = React.useState([])
	const lookup = useContext(LookupContext)

	const reservedCharacters = lookup?.data?.getReservedCharacterArray

	useEffect(() => {
		let items
		if (props?.elasticSearch && data?.[props?.elasticSearch]) {
			items = data[props.elasticSearch].items
		} else if (props.query == 'dynamicContactSearch') {
			items = data?.searchDynamicContacts?.items || []
		} else {
			items = Object.values(data ?? {})?.[0]
		}
		items = items || []
		items.forEach(i => {
			if (props.exclude?.includes(i.id)) {
				i.disabled = true
			}
		})
		const newOptions = uniqBy([...items, props.value].filter(a => a), 'id')
		setContactOptions(newOptions)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, props?.elasticSearch, props?.value])

	
	useEffect(() => {
		if (typeof loadContacts === 'string') {

			if (props.query == 'dynamicContactSearch') {
				lazyLoadContacts({ variables: props.getVariables?.(loadContacts)})
			}

			else if (!props?.elasticSearch) {
				lazyLoadContacts({ variables: {
					...props.variables,
					query: loadContacts
				}})
			}

			else {

				// searchString contains a reserved character
				if (reservedCharactersRegex(reservedCharacters, 'search').test(loadContacts)) {
					lazyLoadContacts({ variables: {
						cursor: 0,
						direction: 'ASC',
						field: null,
						searchString: loadContacts,
						limit: 30
					}})
				}

				// searchString doesn't contain a reserved character, so we surround with
				// *'s to improve the search
				else {
					lazyLoadContacts({ variables: {
						cursor: 0,
						direction: 'ASC',
						field: null,
						searchString: `*${loadContacts}*`,
						limit: 30
					}})
				}
			}

			setLoadContacts(false)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lazyLoadContacts, loadContacts, props.variables])

	return <LimitedAutocomplete
		setQuery={setLoadContacts}
		style={{marginTop: "1.75em"}}
		isOptionEqualToValue={(a, b) => a?.id == b?.id}
		className="fullWidth"
		open={contactOpen}
		forcePopupIcon
		popupIcon={<ExpandMoreRoundedIcon />}
		onOpen={() => setContactOpen(true)}
		onClose={() => setContactOpen(false)}
		loading={loading}
		options={contactOptions}
		value={props.value}
		disableClearable={props.disableClearable}
		getOptionLabel={val => val.id ? getContactLabel(contactOptions?.length ? contactOptions.find(el => el.id == val.id) : val) : '' }
		classes={autoCompleteErrorStyles()}
		getOptionDisabled={option => option?.disabled}
		renderOption={(optionProps, option) => {

			const contact = contactOptions?.find(e => e?.id == option || e?.id == option?.id) || {}

			const thumbnail = <ContactThumbnail contact={contact} darkText={!props.private}/>

			// Fix duplicate key errors
			optionProps.key = `${optionProps.key}-${option.id}`

			if (option?.disabled) {
				return (
					<li {...optionProps}  style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
						{thumbnail}
						<i>Already Included</i>
					</li>
				)
			}
			return <li {...optionProps}>{thumbnail}</li>
		}}

		renderInput={(params) => {

			// Large TextField
			if (!props.smallTextField) {
				return (
					<TextBoxForAutocomplete
						autoFocus
						{...params}
						error={props.error}
						variant="outlined"
						InputProps={{
							...params.InputProps,
							endAdornment: <>
								{loading ? (
									<CircularProgress
										color="inherit"
										size={20}
									/>
								) : null}
								{params.InputProps.endAdornment}
							</>,
						}}
					/>
				)
			}

			// Small TextField
			return (
				<TextBoxThinForAutocomplete
					{...params}
					variant="outlined"
					fullWidth
					style={{ paddingTop: '1.8em' }}
					classes={{ notchedOutline: null }}
					error={props.error}
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{
						...params.InputProps,
						endAdornment: <>
							{ loading ? <CircularProgress
								color="inherit"
								size={20}
							/> : null }
							{ params.InputProps.endAdornment }
						</>,
						style: {
							paddingTop: '3px',
							paddingBottom: '3px',
						},
					}}
				/>
			)
		}}
		{...passedProps}
	/>
}
