/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react'
import {
	Paper,
	Menu,
	FormControl,
	MenuItem,
	Select,
	FormControlLabel, 
	FormGroup,
	Tooltip
} from '@mui/material'
import { CancelButton, SaveCardButton } from '../input/Buttons'
import TextBox from '../input/Text/TextBox'
import { autoCompleteErrorStyles } from '../styles/makesStyles'
import { AuthStateContext, DispatchContext } from '../store'
import { permissions, permissionValues } from '../constants/permissions'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { useRecoilState } from 'recoil'
import { findModeFieldsAtom } from '../navigation/Tabs/TabbedPage'
import Dropdown from '../input/Dropdown/Dropdown'
import Label from '../input/Label'
import AdminToggleSmall from '../input/Toggle/AdminToggleSmall'
import { FindModeInput } from '../navigation/Tabs/TabbedPage'
import { GET_GALLERY_ACCESS } from './Queries'
import { withApollo } from '@apollo/client/react/hoc'
import EditOffIcon from '@mui/icons-material/EditOff'

const galleryAccessTypes = {
	levyGorvy: 1,
	salon94: 2,
	dayanRohatyn: 3,
	luxembourgDayan: 4,
	lgdr: 5,
	levyGorvyDayan: 6
}

const typeToPermission = {
	[galleryAccessTypes.lgdr]: permissions.LGDR_ART,
	[galleryAccessTypes.levyGorvy]: permissions.LEVY_GORVY_ART,
	[galleryAccessTypes.salon94]: permissions.SALON_94_ART,
	[galleryAccessTypes.dayanRohatyn]: permissions.DAYAN_ROHATYN_ART,
	[galleryAccessTypes.luxembourgDayan]: permissions.LUXEMBOURG_AND_DAYAN_ART,
	[galleryAccessTypes.levyGorvyDayan]: permissions.LEVY_GORVY_DAYAN_ART,
}

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false,
}

/**
 * Validates the passed codeName
 * 
 * @param {*} codeName
 * @returns 
 */
const validateCodeName = (codeName) => {

	if (!codeName) return {
		valid: false,
		message: 'The Code Name is a required field.'
	}	

	if (codeName.length < 2) return {
		valid: false,
		message: 'The Code Name must be at least two characters long.'
	}

	if (codeName.length > 255) return {
		valid: false,
		message: 'The Code Name cannot be longer than 255 characters.'
	}	

	if (/^\s*$/.test(codeName)) return {
		valid: false,
		message: 'The Code Name cannot be only whitespace.'
	}
	
	return {
		valid: true,
		message: 'The passed Code Name is valid.'
	}
}

function PrivateAccessDetails(props) {

	// eslint-disable-next-line no-unused-vars
	const classes = autoCompleteErrorStyles()

	const showGalleries = (['deal', 'art'].includes(props.object))

	// Other State
	const [state, setState] = useState(initialState)
	const [attempt, setAttempt] = useState(false)
	const [galleryAccess, setGalleryAccess] = useState([])

	const [findModeEntity, setFindModeEntity] = useRecoilState(findModeFieldsAtom)

	const getDefaultState = () => {

		const state = {
			id: props?.input?.id,
			is_private: props?.input?.is_private,
		}

		if (!props.disableCodename)
			state.code_name = props?.input?.code_name

		if (showGalleries)
			state.gallery_access = props?.input?.gallery_access

		return state
	}

	const [entityInput, setEntityInput] = useState(getDefaultState())

	const resetEntityInput = (entity) => {
		entity &&
			setEntityInput(getDefaultState())
	}

	useEffect(() => {
		resetEntityInput(props.input)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.input])

	
	useEffect(() => {
		if (showGalleries) {

			props.client.query({ 
				query: GET_GALLERY_ACCESS
			}).then(result => {
				setGalleryAccess(result.data?.getGalleryAccess)
			}).catch(error => {
				console.error(error)
				openSnackbar(severity.ERROR, "Could not retrieve deal galleries.")
			})
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.object])

	const authState = useContext(AuthStateContext)
	const userPermissions  = authState?.user?.permissions

	const permissionMap = userPermissions?.reduce((acc, el) => {
		acc[el.permission_id] = el.permission_value_id
		return acc
	}, {}) || {}

	const adminConsoleAccess = permissionMap[permissions.ADMIN_CONSOLE] == permissionValues.YES
	const privateObjectAccess = permissionMap[permissions.PRIVATE_OBJECT_ACCESS] == permissionValues.YES
	const privateTogglePermission = permissionMap[permissions.PRIVATE_OBJECT_TOGGLE] == permissionValues.YES

	let noPermissions = false
	// Users without private toggle permission and admin console access cannot limit deal access
	if (props.object == "deal" && (!privateObjectAccess || (!privateTogglePermission && !adminConsoleAccess))) {
		noPermissions = true
	}

	// Users without private toggle permission and admin console access cannot limit deal access
	if (props.object == "art" && (!privateObjectAccess || !privateTogglePermission)) {
		noPermissions = true
	}

	// Users without private toggle permission and admin console access cannot limit deal access
	if (props.object == "contact" && (!privateObjectAccess || (!privateTogglePermission && !props.publicAccess))) {
		noPermissions = true
	}

	const handleClick = (event) => {
		if (state.mouseX || state.editable || props.findMode || !props.input?.id) return

		// Users without private toggle permission and admin console access cannot limit deal access
		if (noPermissions) {
			openSnackbar(severity.INFO, `You do not have permission to adjust ${props.object} access.`)
			event.preventDefault()
			event.stopPropagation()
			return
		}

		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const handleClose = (option) => {
		if (option === 'edit') {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({type: 'openSnackBar', payload: {severity, text}})
	}


	const updateField = (field, value) => {
		// 	"state" object normally;
		// 	"filterObject" up a level in find mode.
		if (props.findMode) {
			// make this into a selector family, eventually.
			setFindModeEntity(old => ({
				...old,
				[field]: value
			}))
		}
	}
	const editable = state.editable || props.findMode


	let cardHeader = `Private ${props.object || ''} Details`
	if (props.object == 'deal') {
		cardHeader = 'Limit deal access'
	} else if (props.object == 'art') {
		cardHeader = 'Limit artwork access'
	}

	return (
		<React.Fragment>
			<Paper
				className="padding-margin"
				onContextMenu={handleClick}
				data-testid="card-private-contact-details"
			>
				<h1 className="card-title">
					<span>
						{ cardHeader }

						{!props.findMode && noPermissions ? 
							<Tooltip
								enterDelay={500}
								title={'You do not have the private object toggle permission or admin console access.'} arrow placement="bottom">
								<EditOffIcon style={{
									color: 'grey',
									marginLeft: '0.3em',
									marginBottom: '-0.1em'
								}}/>
							</Tooltip>		
							: null}

					</span>
					{state.editable && !props.findMode && (
						<>
							<div className="spacer"></div>
							<CancelButton
								variant="contained"
								size="small"
								onClick={() => {
									resetEntityInput(props.input)
									setAttempt(false)
									handleClose()
								}}
							>
								Cancel
							</CancelButton>
							<SaveCardButton
								variant="contained"
								color="secondary"
								size="small"
								onClick={() => {

									if (showGalleries) {


										if (entityInput.gallery_access?.length == 0) {
											openSnackbar(
												severity.ERROR,
												'Error: You need to select at least one gallery.'
											)

											return
										}

										props.update({
											...entityInput,
											gallery_access: entityInput.gallery_access.map(e => ({id: e.id}))
										})
										handleClose()

									}

									else {
										// Does user have private object access permissions?
										if (!privateObjectAccess ) {
											openSnackbar(
												severity.ERROR,
												'Error: Non private object access permission.'
											)
										}

										setAttempt(true)

										if (attempt && !validateCodeName(entityInput.code_name).valid && !props.disableCodename && !props.placeholder) {
											openSnackbar(severity.WARNING, validateCodeName(entityInput.code_name).message)
										}

										if (validateCodeName(entityInput.code_name).valid || props.disableCodename || props.placeholder) {
											props.update(entityInput)
											handleClose()
											setAttempt(false)
										}
									}
								}}
							>
								Save
							</SaveCardButton>
						</>
					)}
				</h1>
				<div className="flex" style={{flexDirection: 'column', alignItems: 'start'}}>
					{(!props.disableCodename && !props.findMode) ? <FormControl>
						<Label
							disableAnimation
							shrink
							htmlFor="entity-code-name"
							error={attempt && !validateCodeName(entityInput.code_name).valid && !props.placholder}
						>
							Code Name
						</Label>

						<TextBox
							id="entity-code-name"
							readOnly={!editable}
							placeholder={props.placeholder}
							error={attempt && !validateCodeName(entityInput.code_name).valid && !props.placholder}
							value={!editable && props.placeholder && !entityInput.code_name ? props.placeholder : entityInput.code_name || '' }
							onChange={(e) => {
								setEntityInput({
									...entityInput,
									code_name: e.target.value,
								})
							}}
						/>
					</FormControl> : null }


					<FormControl component='fieldset' style={{ marginLeft: '0.5em', marginTop: '0.5em', width: '100%' }}>
						<div style={{ display: 'flex' }}>
							<FormGroup
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<FormControlLabel
									style={{ margin: props.findMode ? 0 : null, alignItems: props.findMode ? 'flex-start' : null }}
									control={
										!props.findMode ? (
											<AdminToggleSmall
												checked={entityInput.is_private || false}
												onChange={() =>
													setEntityInput({
														...entityInput,
														is_private: !entityInput.is_private,
													})
												}
												disabled={!state.editable || !privateTogglePermission}
											/>
										) : (
											<Select
												displayEmpty
												input={<Dropdown />}
												value={findModeEntity.isPrivate || ''}
												renderValue={(val) => {
													if (val == 'true') return 'Private'
													if (val == 'false') return 'Not Private'
													if (val == '') return <i>Ignored</i>
												}}
												onChange={(event) => {
													updateField('isPrivate', event.target.value)
												}}
											>
												<MenuItem key='private-null' value=''>
													<i>Ignored</i>
												</MenuItem>
												<MenuItem key='private-false' value='false'>
												Not Private
												</MenuItem>
												<MenuItem key='private-true' value='true'>
												Private
												</MenuItem>
											</Select>
										)
									}
									label='Private'
									labelPlacement={!props.findMode ? 'end' : 'top'}
								/>

								{!props.findMode && props.object == 'contact' && !privateTogglePermission ? (
									<Tooltip enterDelay={500} title={'You do not have the private object toggle permission.'} arrow placement='bottom'>
										<EditOffIcon
											style={{
												color: 'grey',
												margin: '0.3em',
											}}
										/>
									</Tooltip>
								) : null}
							</FormGroup>
							
						</div>

						{ showGalleries && !props.findMode ? <>

							{galleryAccess.map((galleryAccess) => {
								return (
									<FormGroup key={galleryAccess.id}>
										<FormControlLabel
											control={!props.findMode ? 
												<AdminToggleSmall
													checked={!!entityInput.gallery_access?.find(e => e.id === galleryAccess.id)}
													onChange={() => {
														if (!!entityInput.gallery_access?.find(e => e.id === galleryAccess.id)) {
															setEntityInput({
																...entityInput,
																gallery_access: entityInput.gallery_access.filter(e => e.id != galleryAccess.id)
															})
														}

														else {
															setEntityInput({
																...entityInput,
																gallery_access: entityInput.gallery_access.concat({
																	id: galleryAccess.id
																})
															})
														}
													}}
													disabled={!state.editable || !adminConsoleAccess ||
														(props.object == 'art' && 
														permissionMap[typeToPermission[galleryAccess.id]] == permissionValues.NO
														)
													}
												/> : 
												<FindModeInput type="admin-toggle-small" field={galleryAccess.value}/>}
											label={galleryAccess.value}
											labelPlacement="end"
										/>
									</FormGroup>
								)
							})}
						</> : null}
						
					</FormControl>
				</div>
			</Paper>

			<Menu
				keepMounted
				open={state.mouseY !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					state.mouseY !== null && state.mouseX !== null
						? { top: state.mouseY, left: state.mouseX }
						: undefined
				}
			>
				<MenuItem onClick={() => handleClose('edit')}>Edit</MenuItem>
			</Menu>
		</React.Fragment>
	)
}

export default withApollo(PrivateAccessDetails)
