import { useMutation, useQuery } from '@apollo/client'
import { Grid, Paper } from '@mui/material'
import React, { useCallback, useContext } from 'react'

import { severity } from '../../Snackbar/CustomizedSnackbar'
import { AuthStateContext, DispatchContext } from '../../store'
import { GET_USER_NOTIFICATIONS_SETTINGS, UPDATE_USER_NOTIFICATIONS } from './Queries'
import { LoadingButton } from '@mui/lab'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff'
import { useLocation } from 'react-router-dom'
import UserNotificationsInner from './UserNotificationsInner'
import groupBy from 'lodash/groupBy'

const UserNotificationsPage = (props) => {

	const location = useLocation()

	const onAdminConsole = location.pathname.includes('admin')

	const user = onAdminConsole ? props.state?.adminGetUser : props.state?.getUser

	const userAuthentication = useContext(AuthStateContext)

	const [allNotificationsToggle, setAllNotificationsToggle] = React.useState(false)
	const [notificationsObject, setNotificationsObject] = React.useState({})

	const [toggleAllNotificationsLoading, setToggleAllNotificationsLoading] = React.useState(false)

	/* =================================================================
	Snackbar
	================================================================= */
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])


	const { error } = useQuery(GET_USER_NOTIFICATIONS_SETTINGS, {
		skip: !userAuthentication.user?.id,
		variables: {
			user_id: user?.id,
		},
		onCompleted: (data) => {

			const { getUserNotificationSettings } = data

			try {
				setAllNotificationsToggle(getUserNotificationSettings.notifications_on)
				setNotificationsObject(getUserNotificationSettings.notifications)
			} catch (error) {
				console.error(error)
				openSnackbar(severity.ERROR, "Error retrieving your notifications.")

			}
		}
	})

	const [updateUserNotifications] = useMutation(UPDATE_USER_NOTIFICATIONS)

	function callUpdateMutation(input) {
		updateUserNotifications({
			variables: {
				UserNotificationsInput: input,
			}
		}).then(async ({data}) => {

			// @ts-ignore
			if (data.updateUserNotifications.success) {
				// @ts-ignore
				setAllNotificationsToggle(data.updateUserNotifications.user_notifications.notifications_on)
				setNotificationsObject(data.updateUserNotifications.user_notifications.notifications)
				openSnackbar(
					severity.SUCCESS,
					'Successfully updated user notifications.'
				)
			} else {
				console.error(data)
				openSnackbar(
					severity.ERROR,
					'There was an error while updating your notifications.'
				)
			}
			setToggleAllNotificationsLoading(false)
		}).catch((error) => {
			// Handle error creating DB record
			console.error(error)
			setToggleAllNotificationsLoading(false)
			openSnackbar(
				severity.ERROR,
				'There was an error while updating your notifications.'
			)
		})
	}


	if (error) {
		console.error(error)
		openSnackbar(severity.ERROR, "Error retrieving your notifications.")
	}

	return (
		<>
			<Paper
				className="qv-margin"
				id="user-notifications"
				style={{ marginBottom: 0 }}
			>
				<h1 className="card-title">
					User Notifications
					{!onAdminConsole ? (
						<LoadingButton
							style={{
								marginLeft: 'auto',
							}}
							size="large"
							color="info"
							onClick={() => {
								setToggleAllNotificationsLoading(true)
								callUpdateMutation({
									user_id: userAuthentication.user?.id,
									notifications_on: !allNotificationsToggle,
									notifications: notificationsObject,
								})
							}}
							loading={toggleAllNotificationsLoading}
							loadingPosition="end"
							endIcon={allNotificationsToggle ? <NotificationsOffIcon /> : <NotificationsIcon />}
							variant="contained"
						>
							{allNotificationsToggle ? 'Disable All Notifications' : 'Enable Notifications'}
						</LoadingButton>
					) : (
						<>{allNotificationsToggle ? <NotificationsIcon style={{
							marginLeft: 'auto',
						}}/> : <NotificationsOffIcon style={{
							marginLeft: 'auto',
						}}/>}</>
					)}
				</h1>

				<i>Note: These settings are only for email notifications. All notifications will be sent to the notifications pane regardless of settings.</i>
			</Paper>
			<Grid direction="row" style={{ padding: '0 1em 1em 1em' }} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 8, md: 12 }}>
				{Object.entries(groupBy(notificationsObject, 'category'))?.sort((a, b) => a[0].localeCompare(b[0]))
					?.map((entry) => {

						const [category, notifications] = entry

						return (
							<UserNotificationsInner
								key={`notification-content-${category}`}
								category={category}
								notificationsObject={notificationsObject}
								notifications={notifications}
								allNotificationsToggle={allNotificationsToggle}
								onAdminConsole={onAdminConsole}
								callUpdateMutation={callUpdateMutation}
								userAuthentication={userAuthentication}
							/>
						)
					})
				}
			</Grid>
		</>
	)
}

export default UserNotificationsPage
