import React from 'react'
import { Avatar, Tooltip } from '@mui/material'
import { avatarStyles, StyledBadge } from  '../../styles/makesStyles'
import clsx from 'clsx'

const labelStyle = {
	position: 'absolute',
	bottom: 0,
	transform: 'translate(0%, 1.1em)',
	fontWeight: 400,
	fontSize: '14px'
}

/**
 * @typedef {Object} User
 * @property {number=} id
 * @property {boolean} is_deleted
 * @property {boolean} is_lead
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} imgUrl - Avatar image url
 * @property {string} user_handle - 2-4 letters
 */

/**
 * @typedef {Object} UserProps
 * @property {User=} sp
 * @property {number} index
 * @property {number} length
 */


/**
 * A representation of a UserAvatar
 * @param {UserProps} props
 * @returns {JSX.Element}
 */
export default function UserAvatar (props) {

	const classes = avatarStyles()
	const { sp } = props

	let zIndex = 1
	let diff = (props?.length - props?.index)
	if (!isNaN(diff)) { zIndex = diff }

	const avatarStyle = {
		position: 'relative',
		display: 'inline-flex',
		flexDirection: 'column',
		alignItems: 'center', zIndex,
	}

	if (sp.is_lead || sp.is_deleted) {
		return <Tooltip key={sp.id} title={`${sp.first_name} ${sp.last_name}`} arrow>
			<span className="user-avatar" style={avatarStyle}>
				<StyledBadge
					className={clsx({
						'qv-sp-badge': true,
						'sp-badge-red': sp.is_deleted,
					})}
					overlap="circular"
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					variant="dot"
				>
					<Avatar alt={`${sp.first_name} ${sp.last_name}`}
						src={sp.imgUrl} className={classes.bigger}
					>
						{ !sp.imgUrl ? sp.user_handle : null }
					</Avatar>
				</StyledBadge>
				<span style={labelStyle}>
					{ sp.imgUrl ? sp.user_handle : null }
				</span>
			</span>
		</Tooltip>
	}
	return <Tooltip key={sp.id} title={`${sp.first_name} ${sp.last_name}`} arrow>
		<span className="user-avatar" style={avatarStyle}>
			<Avatar
				alt={`${sp.first_name} ${sp.last_name}`}
				src={sp.imgUrl}
			>
				{ !sp.imgUrl ? sp.user_handle : null}
			</Avatar>
			<span style={labelStyle} >
				{sp.imgUrl ? sp.user_handle : null}
			</span>
		</span>
	</Tooltip>
}
