import { useMutation } from '@apollo/client'
import { WarningAmber } from '@mui/icons-material'
import { FormControl, Skeleton } from '@mui/material'
import { useCallback, useContext, useEffect, useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import { CancelButton, ChooseImageButton } from '../input/Buttons'
import Label from '../input/Label'
import TextBox from '../input/Text/TextBox'
import TransitionModal from '../navigation/TransitionsModal/TransitionsModal'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { DispatchContext } from '../store'
import { tagBackgroundWithoutHex } from '../styles/colors/Colors'
import { CREATE_TAG, UPDATE_TAG } from './Queries'

const LABEL_LENGTH = 100
const DESCRIPTION_LENGTH = 255

const emptyTag = { id: null, label: '', description: '', color_hex: tagBackgroundWithoutHex }

/**
 * Returns true if a field is empty (including strings that are just whitespace)
 * @param field: string - value to check
 * @returns true if field param is empty
 */
const isFieldEmpty = (field: string) => {
	return field?.length === 0 || field?.replace(/\s/g, '').length === 0
}

/**
 * Returns true if string is greater than the length
 * @param field: string - value to check
 * @param length: number - max length to compare
 * @returns true if field.length is greater than length
 */
const isFieldTooLong = (field: string, length: number) => {
	return field?.length === length
}

export const TagModal = (props: any) => {

	const {
		editMode,
		open,
		setOpen,
		handleResult,
	} = props

	const [attempt, setAttempt] = useState(false)
	const [tag, setTag] = useState(emptyTag)

	// Set the tag to the passed in tag when opened
	useEffect(() => {
		setTag(props.tag || emptyTag)
	}, [props.tag, open])

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity: any, text: any) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	// Function to close the modal
	const closeModal = () => {
		setTag(emptyTag)
		setAttempt(false)
		setOpen(false)
	}

	// Edits an existing tag
	const [editTag] = useMutation(UPDATE_TAG, {
		onError: (error) => {
			console.log(error)
			openSnackbar(severity.ERROR, 'There was an error updating this tag.')
		},

		onCompleted: (response) => {
			if (response.updateEntityTag?.success === true) {
				openSnackbar(severity.SUCCESS, 'Successfully updated tag.')
				handleResult(response.updateEntityTag?.tag)
				closeModal()
			} else if (response.updateEntityTag?.message) {
				openSnackbar(severity.ERROR, response.updateEntityTag?.message)
			} else if (response.errors[0].message) {
				openSnackbar(severity.ERROR, response.errors[0].message)
			} else {
				openSnackbar(severity.ERROR, "Could not update tag.")
			}
		},
	})

	// Creates a new tag
	const [createTag] = useMutation(CREATE_TAG)

	// Save handler
	const save = () => {
		setAttempt(true)

		// Validate fields
		if (isFieldEmpty(tag.label)) {
			openSnackbar(severity.ERROR, "Tag label must be specified.")
		} else if (isFieldTooLong(tag.label, LABEL_LENGTH)) {
			openSnackbar(severity.ERROR, `Tag label must be less than ${LABEL_LENGTH} characters.`)
		} else if (isFieldTooLong(tag.description, DESCRIPTION_LENGTH)) {
			openSnackbar(severity.ERROR, `Tag description must be less than ${LABEL_LENGTH} characters.`)
		} else {
			// Save the tags
			if (editMode) {
				editTag({
					variables: {
						UpdateTagInput: {
							id: tag.id,
							label: tag.label,
							description: tag.description,
							color_hex: tag.color_hex
						}
					}
				})
			} else {
				createTag({
					variables: {
						TagInput: {
							label: tag.label,
							description: tag.description,
							color_hex: tag.color_hex
						}
					}
				}).then(({ data }: any) => {
					if (data.createTag?.success === true) {
						openSnackbar(severity.SUCCESS, data.createTag.message)
						handleResult(data.createTag.tag)
						closeModal()
					} else {
						openSnackbar(severity.ERROR, data.createTag?.message)
					}
				}).catch((error) => {
					console.error(error)
					openSnackbar(severity.ERROR, 'Could not create tag.')
				})
			}
		}
	}

	return (
		<TransitionModal
			className="tag-modal"
			open={open}
			close={() => {
				closeModal()
			}}
		>
			<div className={props.isPrivate ? "private-object" : undefined}
				style={{ minWidth: '20em' }}>
				<h1 className="card-title">
					<span>{editMode ? "Edit" : "New"} Tag</span>
				</h1>

				{editMode ?
					<div style={{
						display: 'flex',
						alignItems: 'center',
						flexWrap: 'wrap',
						marginBottom: '.5em',
					}}>
						<WarningAmber color="warning" />
						<span>WARNING: This will change the tag for everyone in The Platform.</span>
					</div>
					: null
				}

				<FormControl className="fullWidth">
					{props.loading ? (
						<Skeleton
							variant="text"
							width="auto"
							height="5em"
							style={{
								marginTop: '0.4em',
								marginBottom: '-0.6em'
							}}
						/>
					) : (
						<>
							<Label
								disableAnimation
								shrink
								htmlFor="tag-label"
								error={ attempt && ( isFieldEmpty(tag.label)
									|| isFieldTooLong(tag.label, LABEL_LENGTH)
								)}
							>
								Label
							</Label>
							<TextBox
								id="tag-label"
								value={tag.label}
								name="tag-label"
								error={ attempt && ( isFieldEmpty(tag.label)
									|| isFieldTooLong(tag.label, LABEL_LENGTH)
								)}
								onChange={(e) => {
									if (!isFieldTooLong(tag.label, LABEL_LENGTH))
										setTag({
											...tag,
											label: e.target.value
										})
								}}
								onKeyPress={e => {
									if (e.key === 'Enter') {
										e.preventDefault()
										save()
									}
								}}
							/>
						</>
					)}
				</FormControl>

				<FormControl className="fullWidth">
					{props.loading ? (
						<Skeleton
							variant="text"
							width="auto"
							height="5em"
							style={{
								marginTop: '0.4em',
								marginBottom: '-0.6em'
							}}
						/>
					) : (
						<>
							<Label
								disableAnimation
								shrink
								htmlFor="tag-description"
								error={ attempt &&
									isFieldTooLong(tag.description, DESCRIPTION_LENGTH)
								}
							>
								Description
							</Label>
							<TextBox
								id="tag-description"
								value={tag.description}
								name="tag-description"
								error={ attempt &&
									isFieldTooLong(tag.description, DESCRIPTION_LENGTH)
								}
								onChange={(e) => {
									if (!isFieldTooLong(tag.description, DESCRIPTION_LENGTH))
										setTag({
											...tag,
											description: e.target.value
										})
								}}
								onKeyPress={e => {
									if (e.key === 'Enter') {
										e.preventDefault()
										save()
									}
								}}
							/>
						</>
					)}
				</FormControl>

				<div
					style={{ color: "var(--white)" }}
				>
					<Label
						style={{ paddingTop: "0.5em", paddingLeft: "1.1em" }}
						disableAnimation
						shrink
						htmlFor="tag-color">
						Tag Color
					</Label>

					<div className="value" style={{
						borderLeft: `24px solid ${'#' + tag.color_hex}`,
						paddingLeft: '10px',
						marginBottom: '.5em',
						color: 'black'
					}}>
						{'#' + tag.color_hex}
					</div>

					<HexColorPicker
						color={tag.color_hex}
						onChange={(e) => {
							setTag({
								...tag,
								color_hex: e.replace('#', ''),
							})
						}}
					/>
				</div>

				<div style={{ paddingTop: '1em', display: 'flex' }}>
					<CancelButton
						variant="contained"
						onClick={() => {
							closeModal()
						}}
					>
						Cancel
					</CancelButton>
					<ChooseImageButton
						style={{ marginLeft: 'auto' }}
						variant="contained"
						onClick={save}
					>
						{editMode ? "Update" : "Add"}
					</ChooseImageButton>
				</div>
			</div>
		</TransitionModal>
	)
}
