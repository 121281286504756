import { gql } from '@apollo/client'

export const GET_POSSIBLE_ACCESS_USERS = gql`
	query GetPossibleAccessUsers(
        $id: ID
        $entity: String
		$galleries: [ID]
	){
        getPossibleUserAccess(
            id: $id
            entity: $entity
			galleries: $galleries
        ) {
			id
			first_name
			last_name
		}
	}
`

export const GET_GALLERY_ACCESS = gql`
	query getGalleryAccess{
        getGalleryAccess{
			id
			value
			created_by
			created_at
			modified_by
			modified_at
			is_deleted
		}
	}
`

