/* eslint-disable eqeqeq */
import Autocomplete from '@mui/material/Autocomplete'
import { autoCompleteErrorStyles } from '../../styles/makesStyles'
import CircularProgress from '@mui/material/CircularProgress'
import { MobileDateTimePicker  } from "@mui/x-date-pickers-pro"
import { DispatchContext, AuthStateContext, LookupContext } from '../../store'
import Dropdown from '../../input/Dropdown/Dropdown'
import makeStyles from '@mui/styles/makeStyles'
import { Paper, FormControl } from '@mui/material'
import { SearchButton, CancelButton } from '../../input/Buttons'
import { Select, MenuItem } from '@mui/material'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { useMutation } from '@apollo/client'
import Label from '../../input/Label'
import React, { useEffect, useContext, useCallback } from 'react'
import TextBox from '../../input/Text/TextBox'
import TextField from '@mui/material/TextField'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { getDefaultDueDate } from '../../common/helpers'
import { GET_USERS } from '../../User/Queries'
import { withApollo } from '@apollo/client/react/hoc'

const useStyles = makeStyles(() => ({
	selection: {
		paddingRight: "1em",
		"&:last-child": {
			paddingRight: "0em",
		}
	},
	bar: {
		paddingBottom: "1em"
	},
}))


function NewEntityTask(props) {

	const lookup = useContext(LookupContext)
	const taskTypes= lookup.data?.getTaskTypes

	const taskStatusTypes = lookup.data?.getTaskStatusTypes
	
	const classes = useStyles()
	const classes2 = autoCompleteErrorStyles()
	
	const typeStyle = {
		fontWeight: 500,
	}
	
	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const closeSnackbar = () => {
		dispatch({ type: 'closeSnackBar'})
	}

	// User
	const userAuthentication = useContext(AuthStateContext)
	const [attempt, setAttempt] = React.useState(false)

	// Task
	const [task, setTask] = React.useState({
		assignees: [userAuthentication.user],
		due_at: null,
		type: 2,
		department: "None",
		status: 1,
		approval: false
	})
		
	// Use effect to set default assignee to current user
	useEffect(() => {
		if (task.assignees[0] === undefined) {
			setTask({
				...task,
				assignees: [userAuthentication.user]
			})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userAuthentication])

	// Create Task
	const [createTask, { loading, error }] = useMutation(props.mutation, {
		
		onError: (error) => {
			openSnackbar(severity.ERROR, "Could not create task.")
		},

		onCompleted: (response) => {
			if (response[props.mutationResponse]?.success === true) {
				// Success
				openSnackbar(severity.SUCCESS, response[props.mutationResponse].message)
				setSelection([userAuthentication.user])
				setTask({
					assignees: [userAuthentication.user],
					due_at: null,
					type: 2,
					department: "None",
					status: 1,
					approval: false,
					text: ""
				})

				setAttempt(false)
				props.setTasks(props.tasks.concat(response[props.mutationResponse].task))
				
			} else {
				// On failure, reset state and show error
				openSnackbar(severity.ERROR, response[props.mutationResponse]?.message)
			}
		}
	})

	// Loading, Error
	useEffect(() => {
		if (loading) openSnackbar(severity.INFO, "Creating task...")
		if (error) {
			console.error(error)
			openSnackbar(severity.ERROR, "Error creating task.")
		}
	}, [error, loading, openSnackbar])


	// Async Autocomplete
	// User options
	const [options, setOptions] = React.useState(userAuthentication.user ? [userAuthentication.user] : [])
	const [selection, setSelection] = React.useState(userAuthentication.user ? [userAuthentication.user] : [])
	const [optionsDropdown, setOptionsDropdown] = React.useState(false)
	const optionsListLoading = optionsDropdown && options?.length === 1

	useEffect(() => {
		if (!selection) {
			setSelection(options?.find(item => item.id == userAuthentication.user?.id))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options])

	useEffect(() => {
		if (options.length <= 1) {
			setOptions([userAuthentication.user])
			setSelection(userAuthentication.user ? [userAuthentication.user] : [])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userAuthentication.user])
	
	React.useEffect(() => {
		let active = true
		if (!optionsListLoading) {
			return undefined
		}
		props.client
			.query({ query: GET_USERS})
			.then(result => {
				if (active) {
					setOptions(result.data.getUsers.items)	
				}
			})
		return () => {
			active = false
		}
	}, [optionsListLoading, props.client])

	React.useEffect(() => {
		if (!optionsDropdown && options.length <= 1) {
			setOptions(userAuthentication.user ? [userAuthentication.user] : [])
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.user, optionsDropdown])



	return (
		<Paper 
			className="qv-margin"
			data-testid="card-new-task"
		>
			<h1 className="card-title">New Task</h1>
			<form
				onSubmit={e => {
					e.preventDefault()
					setAttempt(true)

					if (attempt & ((task.assignees.length === 0 && task.department === "None") || (task.assignees.length === 0 && !task.department))) {
						
						openSnackbar(severity.WARNING, "Please choose either a department or assignees.")
					} else if (attempt && (!task.due_at || (!task.text || task.text?.length === 0 || !task.text.replace(/\s/g, '').length))) {

						openSnackbar(severity.WARNING, "Please complete required fields.")
					} else if (!(((task.assignees.length === 0 && task.department === "None") || (task.assignees.length === 0 && !task.department))||(!task.due_at || (!task.text || task.text?.length === 0 || !task.text.replace(/\s/g, '').length)))) {

						let variables = {
							type_id: task.type,
							description: task.text,
							assigned_by_user_id: userAuthentication.user.id,
							approval_required: false,
							due_at: task.due_at,
							is_deleted: false,
							task_status_id: task.status,
							is_completed: false,
							assigned_to_type: task.department === "None" ? "User" : "Department",
							assigned_to_user_ids: (task.department === "None") ? task.assignees.map(assignee => assignee.id) : null,
							assigned_to_department_id: (task.department !== "None") ? task.department : null,
							
							entity_id: props.entityId,
							entity_name: props.linkField
						}
						
						Object.keys(variables).forEach((key) => (variables[key] == null) && delete variables[key])

						// Submit to server
						createTask({
							variables: { 
								CreateTaskInput: variables 
							}
						})
					}
				}}
			>
				<div className="row" style={{justifyContent: "flex-start"}}>
					<FormControl className={classes.selection} style={{maxWidth: "14em"}}>
						<Label id="type-label" style={typeStyle} disableAnimation shrink>
							Type
						</Label>
						<Select
							IconComponent={ExpandMoreRoundedIcon}
							className="padded-select"
							name="type"
							
							labelId="type-label"
							input={<Dropdown />}
							value={taskTypes ? task.type : ''}
							onChange={(e) => {

								if (e.target.value === 3) {
									// Immediate
									setTask({
										...task,
										type: e.target.value,
										due_at: getDefaultDueDate()
									})
								} else {
									setTask({
										...task,
										type: e.target.value
									})
								}	
							}}
						>
							{taskTypes && taskTypes.map(type => (
								<MenuItem key={type.id} value={type.id}>{type.value}</MenuItem>
							))}
						</Select>
					</FormControl>

					{/* TODO: Phase II
					<FormControl className={classes.selection} style={{maxWidth: "14em"}}>
						<Label id="department-label" style={typeStyle} disableAnimation shrink>
							Department
						</Label>
						<Select
							IconComponent={ExpandMoreRoundedIcon}
							disabled
							className="padded-select"
							name="department"
							
							labelId="department-label"
							input={<Dropdown />}
							value={task.department}
							onChange={(e) => {

								setTask({
									...task,
									department: e.target.value,
									assignees: []
								})
							
							}}
						>
							<MenuItem key={"None"} value={"None"}>None</MenuItem>
							{departments && departments.map(type => (
								<MenuItem key={type.id} value={type.id}>{type.value}</MenuItem>
							))}
						</Select>
					</FormControl>
					*/}

					<FormControl className={classes.selection} style={{maxWidth: "14em"}}>
						<Label id="status-label" style={typeStyle} disableAnimation shrink>
							Status
						</Label>
						<Select
							IconComponent={ExpandMoreRoundedIcon}
							className="padded-select"
							name="status"
							
							labelId="status-label"
							input={<Dropdown />}
							value={taskStatusTypes ? task.status : ''}
							onChange={(e) => {
								setTask({
									...task,
									status: e.target.value
								})
							}}
						>

							{taskStatusTypes && taskStatusTypes.map(type => (
								<MenuItem key={type.id} value={type.id}>{type.value}</MenuItem>
							))}
						</Select>
					</FormControl>

					<div className={(attempt && !task.due_at) ? "error" : null}>
						<FormControl className={classes.searchDate} style={{minWidth: "25em"}}>
							<Label
								id="date-label"
								style={typeStyle}
								disableAnimation
								shrink
							>
								Due Date
							</Label>

							<MobileDateTimePicker
								inputFormat="MMMM do, yyyy 'at' h:mm aaa"
								disableMaskedInput={true}
								cancelText={null}
								disablePast
								componentsProps={{
									actionBar: {
										actions: ['today', 'clear', 'accept']
									}
								}}
								todayLabel="Now"
								error={attempt && !task.due_at}
								name="date"
								inputVariant="outlined"
								style={{ marginTop: '15px' }}
								className="MUIDatePicker"
								variant="dialog"
								value={task.due_at}
								renderInput={({ inputRef, inputProps, InputProps }) => {
								
									const newProps = { ...inputProps}
		
									newProps.readOnly = false
		
									return (
										<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
									)}}
								onChange={date => {
									setTask({
										...task,
										due_at: date
									})
								}}
							/>
						</FormControl>
					</div>
				</div>

				<div className={(attempt && task.assignees.length === 0 && task.department === "None") ? "error" : null}>

					<FormControl className={classes.selection} style={{width: "100%", paddingRight: "0px"}}>
						<Label
							id="user-label"
							style={typeStyle}
							disableAnimation
							shrink
						>
							Assignees
						</Label>

						<Autocomplete
							multiple
							classes={classes2}
							style={{marginTop: "1.75em"}}
							open={optionsDropdown}
							isOptionEqualToValue={(option, value) => {
								return option.id == value.id
							}}
							forcePopupIcon
							filterSelectedOptions
							popupIcon={<ExpandMoreRoundedIcon />}
							size="small"
							value={selection ?? []}
							onOpen={() => { setOptionsDropdown(true) }}
							onClose={() => { setOptionsDropdown(false)}}
							getOptionLabel={option => {
								try {
									return option.first_name + ' ' + option.last_name
								} catch {
									return "Loading..."
								}
							}}
							options={options || []}
							loading={optionsListLoading}
							onChange={(event, value) => {
								setSelection(value)
								setTask({
									...task,
									assignees: value
								})
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									fullWidth
									error={attempt && task.assignees.length === 0 && task.department === "None"}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{optionsListLoading ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>
							)}
						/>
					</FormControl>
				</div>

				<div className={(attempt && (!task.text || task.text?.length === 0 || !task.text.replace(/\s/g, '').length)) ? "error" : null}>
					<FormControl className={classes.bar} style={{ width: "100%" }} >
						<Label style={typeStyle} disableAnimation shrink >
							Task
						</Label>
						<TextBox name="search" multiline 
							rows="4"
							value={task.text}
							onChange={(e) => {
								setTask({
									...task, 
									text: e.target.value
								})
							}}
						/>
					</FormControl>
				</div>

				<div style={{
					display: 'inline-flex',
					width: '-webkit-fill-available',
					justifyContent: 'right',
				}}>
					<CancelButton variant="contained" size="small" type="reset" 
						style={{float: "right", marginRight: "1em", color: '#FFFFFF', backgroundColor: '#cc3333'}}
						onClick={() => {
							setAttempt(false)
							closeSnackbar()
							setSelection([userAuthentication.user])
							setTask({
								assignees: [userAuthentication.user],
								due_at: null,
								type: 2,
								department: "None",
								status: 1,
								approval: false,
								text: "",
							})
						}}>
						Reset
					</CancelButton>
					<SearchButton variant="contained" size="small" type="submit" style={{float: "right", backgroundColor: '#4465D1'}}>
						Save
					</SearchButton>
					
				</div>
			</form>
		</Paper>
	)
}

export default withApollo(NewEntityTask)
