/* eslint-disable eqeqeq */
import EnhancedTableHead, { stableSort, getComparator } from '../table/EnhancedTableHead'
import { formatDate, readableBytes, shorten } from '../common/helpers'
import {
	Menu,
	MenuItem,
	Paper,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	IconButton,
	ListItem,
	ListItemText,
	Skeleton,
} from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import React, { useState, useEffect, useContext, useCallback } from 'react'
import Thumbnail from '../Thumbnail/Thumbnail'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import CloudIcon from '@mui/icons-material/Cloud'
import UploadModal from './UploadModal'
import InfoCard from '../InfoCard/InfoCard'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { useMutation } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { UPDATE_CONTACT_FILES } from '../Contacts/Queries'
import { DispatchContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { CancelButton, ResetButton, ChooseImageButton } from '../input/Buttons'
import { GET_DOWNLOAD_LINK, GET_PREVIEW_LINK } from './Queries'
import {  } from 'react-router-dom'
import clsx from 'clsx'

export const getFileType = (type) => {
	const val = type?.toLowerCase()

	if (val.includes('pdf'))
		return (
			<img
				alt="pdf"
				style={{
					color: 'red',
					width: '24px',
					height: '32px',
				}}
				src="/images/icons/Red/PDF.svg"
			/>
		)
	else if (val.includes('doc') 
			|| val.includes('docx') 
			|| val.includes('word') 
			|| val.includes('application/vnd.ms-excel')) return <InsertDriveFileIcon style={{ color: 'grey' }} /> 
	else return <CloudIcon style={{ color: 'grey' }} />
}

/**
 * @typedef {Object} FileListProps
 * @property {number} objectId
 * @property {Array.<Object>} files
 * @property {function(Array.<Object>): void} setFiles
 * @property {number} fileObjectId
 * @property {boolean} atPrivateObject
 * @property {string} heading
 * @property {Object} style
 */

/**
 * @param {FileListProps} props 
 * @returns {JSX.Element}
 */
const FileList = (props) => {

	const [anchorEl, setAnchorEl] = useState(null)
	const [currentRow, setCurrentRow] = useState(null)

	const handleClick = (event) => setAnchorEl(event.currentTarget)
	const handleClose = () => setAnchorEl(null)

	const [addMode, setAddMode] = useState(false)
	const [editMode, setEditMode] = useState(false)
	const [addVersionMode, setAddVersionMode] = useState(false)
	const [infoCard, setInfoCard] = useState({ open: false })
	const [versionHistory, setVersionHistory] = useState(false)
	const [deleteConfirmation, setDeleteConfirmation] = useState(false)

	const [files, setFiles] = useState([])

	const headCells = [
		{
			id: 'filetype',
			numeric: false,
			disablePadding: false,
			label: '',
			noSort: true,
		},
		{ id: 'type', numeric: false, disablePadding: false, label: 'Type', noSort: true },
		{
			id: 'filename',
			numeric: false,
			disablePadding: false,
			label: 'Filename & Description',
			noSort: false,
		},
		{ id: 'created_at', numeric: false, disablePadding: false, label: 'Uploaded Date', noSort: false },
		{ id: 'filesize', numeric: false, disablePadding: false, label: 'Size', noSort: false },
		{ id: 'userName', numeric: false, disablePadding: false, label: 'User', noSort: true },
		{ id: 'actions', numeric: false, disablePadding: false, label: 'Actions', noSort: true },
	]

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const [updateEntity] = useMutation(props.options?.updateQuery || UPDATE_CONTACT_FILES, {
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, `Could not update ${props.options?.object}`)
		},
		onCompleted: (response) => {
			if (response[props.options?.updateQuery.definitions[0].name.value].success) {
				openSnackbar(severity.SUCCESS, 'Successfully updated.')
				props.setTabbedPageFileRefetch(!props.tabbedPageFileRefetch)
				if (versionHistory) setVersionHistory(false)
				if (deleteConfirmation) setDeleteConfirmation(false)
			} else openSnackbar(severity.ERROR, 'There was an error during the delete.')
		},
	})

	useEffect(() => {
		if (props.versionList) {
			setFiles(props.files)
		} else {
			setFiles(
				props.files.filter(
					(file) =>
						!props.files.find(
							(e) =>
								e.parent_file_id == file.id ||
								(e.parent_file_id == file.parent_file_id && e.version > file.version)
						)
				) || []
			)
		}
	}, [props.files, props.versionList])

	// Sorting
	const [orderMap, setOrderMap] = useState({})

	// Sorting
	const handleRequestSort = (event, property, heading) => {
		const orderBy = property
		const oldOrder = orderMap[heading]?.order
		const isAsc = orderBy === property && oldOrder === 'asc'
		const order = isAsc ? 'desc' : 'asc'
		setOrderMap({
			...orderMap,
			[heading]: {
				order,
				orderBy,
			},
		})
	}

	const openFile = (row) => {
		props.client.query({ 
			query: GET_PREVIEW_LINK, 
			variables: {
				id: row.id
			}
		}).then(result => {

			if (result.data.getPreviewLink) 
				window.open(result.data.getPreviewLink, '_blank')
			else 
				openSnackbar(severity.ERROR, "Error - Unauthorized")


		}).catch(error => {
			console.error(error)
			openSnackbar(severity.ERROR, "Could not preview file")
		})

		handleClose()
	}

	return <>
		<Paper
			className={clsx({"qv-margin": true, "private-object": props.atPrivateObject})}
			id="contact-details"
			key={props.heading}
			style={props.style}
			data-testid={`card-${props.heading.toLowerCase().replace(" ", "-")}`}
		>
			<h1 className="card-title">

				<span>
					{props.heading} {files.length && !props.hideCount ? `(${files.length})` : ''}
				</span>

				<div style={{
					marginLeft: 'auto'
				}}>
					{!props.hideAddButton ? <ChooseImageButton
						variant="contained"
						color="secondary"
						size="small"

						onClick={() => {

							if (props.versionList) {
                                
								setCurrentRow(files[0])
								setAddMode(false)
								setEditMode(false)
								setAddVersionMode(true)

							} else {
                                
								setCurrentRow(null)
								setEditMode(false)
								setAddVersionMode(false)
								setAddMode(true)
							}
						}}
					>
                        Add {props.versionList ? 'Version' : ''}
					</ChooseImageButton> : null}

					{props.versionList ?
                    
						<CancelButton
							variant="contained"
							size="small"

							onClick={() => {
								props.setVersionHistory(false)
							}}
						>
                            Close
						</CancelButton>
						: null}
				</div>
			</h1>
			<TableContainer style={{ maxHeight: '50em' }}>
				<Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                    
					<EnhancedTableHead
						headCells={headCells}
						order={orderMap[props.heading]?.order || 'desc'}
						orderBy={orderMap[props.heading]?.orderBy || 'created_at'}
						onRequestSort={(e, property) => handleRequestSort(e, property, props.heading)}
						rowCount={files.length}
					/>

					{!props.loading && files?.length == 0 ? (
						<TableBody>
							<TableRow key={props.heading + '-empty'}>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell>
									<div style={{
										height: 76,
										display: 'flex',
										alignItems: 'center'
									}}>
										No Files Found
									</div>
								</TableCell>
								<TableCell style={{ minWidth: '10em' }}></TableCell>
								<TableCell style={{ minWidth: '15em' }}></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableBody>
					) : null}

					{props.loading && files?.length == 0 ? 
						<TableBody>
							<TableRow>
								<TableCell><Skeleton 
									variant="rectangular"
									width={40}
									height={40}
									animation="wave"
								/></TableCell>
								<TableCell><Skeleton animation="wave"/></TableCell>
								<TableCell><Skeleton animation="wave"/></TableCell>
								<TableCell style={{ minWidth: '10em' }}><Skeleton animation="wave"/></TableCell>
								<TableCell style={{ minWidth: '15em' }}><Skeleton animation="wave"/></TableCell>
								<TableCell><Thumbnail animation="wave" /></TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableBody>
						: null}

					<TableBody>
						{stableSort(
							files.map((file) => ({
								...file,
								type: file?.fileCategory,
								userName: `${file?.user?.first_name} ${file?.user?.last_name}`,
							})),
							getComparator(orderMap[props.heading]?.order, orderMap[props.heading]?.orderBy)
						).map((row, index) => {
							return (
								<TableRow key={row.id} onClick={(event) => {
									if (event.metaKey) {
										openFile(row)
									}
								}}>
									<TableCell>{getFileType(row.filetype)}</TableCell>
									<TableCell>{typeof row.fileCategory === "string" ? row.fileCategory : row.fileCategory?.value}</TableCell>
									<TableCell style={{maxWidth: '50em', overflowX: 'scroll'}}>
										<ListItem style={{ padding: '0' }}>
											<ListItemText
												primary={row.filename}
												secondary={shorten(row.description || '-', 240)}
											/>
										</ListItem>
									</TableCell>
									<TableCell style={{ minWidth: '10em' }}>
										{formatDate(row.created_at, 'MMM d, yyyy')}
									</TableCell>
									<TableCell>{readableBytes(row.filesize)}</TableCell>
									<TableCell style={{ minWidth: '15em' }}>
										<Thumbnail
											name={row.user?.first_name + ' ' + row.user?.last_name}
											detail={row.user?.gallery}
											avatar={row.user?.imgUrl}
											type="contact"
										></Thumbnail>
									</TableCell>
									{!props.deleteConfirmation ? (
										<TableCell>
											<IconButton
												aria-label="More"
												style={{
													padding: '6px',
													marginRight: '-9px',
												}}
												onClick={(e) => {
													e.preventDefault()
													e.stopPropagation()
													setCurrentRow(row)
													handleClick(e)
												}}
												size="large">
												<MoreHorizIcon />
											</IconButton>
										</TableCell>
									) : null}
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>

			{props.deleteConfirmation ? (
				<div
					style={{
						paddingTop: '1em',
						display: 'flex',
						justifyContent: 'flex-end',
					}}
				>
					<CancelButton
						variant="contained"
						autoFocus
						onClick={() => {
							props.setDeleteConfirmation(false)
						}}
					>
                        Cancel
					</CancelButton>
					<ResetButton
						onClick={() => {
							const variables = {
								id: props.objectId,
								files: props.deleteConfirmation.map((file) => {
									const {
										file_group_id,
										user,
										fileCategory,
										__typename,
										modified_at,
										created_at,
										modified_by,
										created_by,
										fileGroup,
										versionHistory,
										type,
										userName,
										fileObject,
										...rest
									} = file

									return {
										...rest,
										delete: true,
									}
								}),
							}

							updateEntity({
								variables: {
									UpdateFileInput: variables,
								},
							})

							props.setDeleteConfirmation(false)
						}}
						style={{ marginLeft: '1em' }}
						variant="contained"
					>
                        Delete
					</ResetButton>
				</div>
			) : null}
		</Paper>

		<Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>		
			<MenuItem
				onClick={() => openFile(currentRow)}
			>
                Open
			</MenuItem>

			<MenuItem
				onClick={(e) => {

					props.client.query({ 
						query: GET_DOWNLOAD_LINK, 
						variables: {
							id: currentRow.id
						}
					}).then(result => {

						if (result.data.getDownloadLink)
							window.location.href = result.data.getDownloadLink
						else 
							openSnackbar(severity.ERROR, "Error - Unauthorized")


					}).catch(error => {
						console.error(error)
						openSnackbar(severity.ERROR, "Could not download file.")
					})

					handleClose()
				}}
			>
                Download
			</MenuItem>

			{!props.versionList && !props.hideEditButtons ? (
				<MenuItem
					onClick={(e) => {
						setAddMode(false)
						setEditMode(false)
						setAddVersionMode(true)
						handleClose()
					}}
				>
                    Add Version
				</MenuItem>
			) : null}

			{!props.versionList && currentRow?.versionHistory?.length > 1 && !props.hideEditButtons ? (
				<MenuItem
					onClick={(e) => {
						setEditMode(false)
						setAddMode(false)
						setVersionHistory(true)
						handleClose()
					}}
				>
                    Version History
				</MenuItem>
			) : null}

			{(!props.versionList && !props.hideEditButtons) ||
            (props.versionList && files && files[0]?.id == currentRow?.id && !props.hideEditButtons) ? (
					<MenuItem
						onClick={(e) => {
							setAddVersionMode(false)
							setAddMode(false)
							setEditMode(true)
							handleClose()
						}}
					>
                        Edit File
					</MenuItem>
				) : null}

            
			<MenuItem
				onClick={(e) => {
					setInfoCard({ open: true })
					handleClose()
				}}
			>
                Information
			</MenuItem>

			{(!props.versionList && currentRow?.versionHistory?.length === 1 && !props.hideEditButtons) ||
            (props.versionList && files && files[0]?.id == currentRow?.id && !props.hideEditButtons) ? (
					<MenuItem
						onClick={(e) => {

							if (props.versionList && files && files[0]?.id == currentRow?.id) {


								const variables = {
									id: props.objectId,
									files: [currentRow].map((file) => {
										const {
											file_group_id,
											user,
											fileCategory,
											__typename,
											modified_at,
											created_at,
											modified_by,
											created_by,
											fileGroup,
											versionHistory,
											type,
											userName,
											fileObject,
											...rest
										} = file

										return {
											...rest,
											delete: true,
										}
									}),
								}

								updateEntity({
									variables: {
										UpdateFileInput: variables,
									},
								})

								props.setVersionHistory(false)

							} else {
								setDeleteConfirmation([currentRow])
							}

							handleClose()
						}}
					>
                    Delete File
					</MenuItem>
				) : null}

			{!props.versionList && currentRow?.versionHistory?.length > 1 && !props.hideEditButtons ? (
				<MenuItem
					onClick={(e) => {
						setDeleteConfirmation(currentRow.versionHistory)
						handleClose()
					}}
				>
                    Delete All
				</MenuItem>
			) : null}
		</Menu>

		<UploadModal
			heading={props.heading}
			open={addMode || editMode || addVersionMode}
			setOpen={() => {
				setEditMode(false)
				setAddVersionMode(false)
				setAddMode(false)
			}}
			object={props.options?.object}
			objectId={props.objectId}
			fileId={currentRow?.id}
			atPrivateObject={props.atPrivateObject}
			fileObjectId={props.fileObjectId}
			editMode={editMode}
			addVersionMode={addVersionMode}
			fileInput={editMode ? currentRow : null}
			parentFile={addVersionMode ? currentRow : null}
			setFiles={props.setFiles ? props.setFiles : setFiles}
			setLocalFiles={setFiles}
			files={files}
			updateQuery={props.options?.updateQuery}
			updateFileQuery={props.options?.updateFileQuery}
			tabbedPageFileRefetch={props.tabbedPageFileRefetch}
			setTabbedPageFileRefetch={props.setTabbedPageFileRefetch}
			fileGroup={addMode ? props.heading : null}
			versionList={props.versionList}
		/>

		<InfoCard
			open={infoCard.open}
			object={currentRow}
			setInfoModal={setInfoCard}
			allAttributes
			ignoredAttributes={[
				'created_at',
				'created_by',
				'modified_at',
				'modified_by',
				'key',
				'fileObject',
				'file_group_id',
				'file_category_id',
				'userName',
				'user',
				'versionHistory',
			]}
		/>

		<TransitionsModal
			className="file-version-history"
			open={(versionHistory || deleteConfirmation) && !props.versionList}
			close={() => {
				setVersionHistory(false)
				setDeleteConfirmation(false)
			}}
			noPadding
		>
			<FileList
				versionList={true}
				client={props.client}
				atPrivateObject={props.atPrivateObject}
				setVersionHistory={setVersionHistory}
				hideCount={deleteConfirmation.length === 1}
				heading={
					!deleteConfirmation
						? `${currentRow?.filename} version history`
						: `Delete ${currentRow?.filename}${deleteConfirmation.length > 1 ? ' versions' : ''}?`
				}
				deleteConfirmation={deleteConfirmation}
				setDeleteConfirmation={setDeleteConfirmation}
				files={currentRow?.versionHistory?.sort((a,b) => b?.created_at - a?.created_at) || []}
				setFiles={(files) => {
					props.setFiles(files)
					setVersionHistory(false)
				}}
				fileObjectId={props.fileObjectId}
				objectId={props.objectId}
				options={props.options}
				refetch={props.refetch}
				tabbedPageFileRefetch={props.tabbedPageFileRefetch}
				setTabbedPageFileRefetch={props.setTabbedPageFileRefetch}
				style={{ margin: '0' }}
			></FileList>
		</TransitionsModal>
	</>
}

export default withApollo(FileList)
