import React, { useContext } from 'react'
import './QuickView.css'
import Paper from '@mui/material/Paper'
import { IconButton } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { DispatchContext, OpenContext } from '../store'

export default function QuickView(props) {
	const quickView = 'quick-view ' + (props.className || '')

	const openContext = useContext(OpenContext)

	const quickViewOpen = openContext?.quickView || false
	const drawerOpen = openContext?.drawer || false

	const dispatch = useContext(DispatchContext)
	const closeQuickView = () => dispatch({ type: 'closeQuickView' })
	const openQuickView = () => dispatch({ type: 'openQuickView' })

	const getLeftOffset = () => {

		let offset = 0

		if (quickViewOpen) offset += 14.75
		else offset += 2.55

		if (drawerOpen && quickViewOpen) offset += 3.5
		if (drawerOpen && !quickViewOpen) offset += 4
		if (!drawerOpen && !quickViewOpen) offset += 0.5

		return `${offset}em`
	}

	const button = (
		<IconButton
			style={{
				position: 'absolute',
				left: getLeftOffset(),
				top: 'calc(100% - 2.5em)',
				backgroundColor: 'hsl(0deg 0% 50% / 32%)',
				borderTopLeftRadius: 0,
				borderBottomLeftRadius: 0,
				padding: 6,
				zIndex: 3,
				transition: '225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
			}}
			onClick={() => {
				if (quickViewOpen) {
					closeQuickView()
				} else openQuickView()
			}}
			size="large">
			{!quickViewOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
		</IconButton>
	)

	return (
		<>
			<Paper
				onClickCapture={() => {
					if (!quickViewOpen) {
						openQuickView()
					}
				}}
				className={quickView}
				style={{
					...props.style,
					...{
						marginLeft: !quickViewOpen ? '-19.5em' : null,
						cursor: !quickViewOpen ? 'pointer' : null,
						transition: '225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
					},
				}}
				onContextMenu={props.onContextMenu}
			>
				{props.children}
			</Paper>
			{button}
		</>
	)
}
