import { ClickAwayListener, FormControl, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Select as MUISelect } from "@mui/material"
import ExpandMoreRounded from "@mui/icons-material/ExpandMoreRounded"
import { useRef, useState } from "react"


const BorderlessSelect = (props) => {
  const { disabled, label, onChange, options, value } = props || {}
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return
    }
    setOpen(false)
  }

  const handleMenuItemClick = (event, option) => {
    if (option?.value !== value) {
      !disabled && onChange?.(option?.value)
    }
    setOpen(false)
  }

  const selected = options?.find((option) => option?.value === value)

  return (
    <div className="flex gap-4">
      <div className="text-sm text-grey">
        { label }
      </div>
      <div className="flex gap-2" ref={anchorRef}>
        <div className={`flex text-sm ${selected?.className}`}>{selected?.label}</div>
        <IconButton className="p-0" onClick={!disabled && handleToggle}>
          <ExpandMoreRounded />
        </IconButton>
        <Popper
          className="z-50 fixed"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options?.map((option, index) => (
                      <MenuItem
                        className="text-sm"
                        key={index}
                        onClick={(event) => handleMenuItemClick(event, option)}
                        selected={index === selected?.index}
                      >
                        {option?.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  )
}

const Select = (props) => {
  const { className, disabled, label, name, onChange, options = [], value, style = 'default' } = props || {}

  const styles = {
    default: {
      select: {
        container: "border-inputBorder",
        outlined: "py-[13px] pl-[13px] pr-[26px]",
      }
    },
  }

  const classes = styles?.[style] || styles['default']

  return (
    <FormControl className={`${className} ${classes?.container}`}>
      <div className="text-sm">
        {label}
      </div>
      <MUISelect
        name={name}
        value={value}
        IconComponent={ExpandMoreRounded}
        className={`${classes?.select?.container}`}
        classes={{ outlined: `${classes?.select?.outlined}` }}
        onChange={!disabled && onChange}
      >
        {
          options?.map((option, i) => {
            return (
              <MenuItem value={option?.value} key={i}>
                {option?.label}
              </MenuItem>
            )
          })
        }
      </MUISelect>
    </FormControl>
  )
}

const SelectTypes = (props) => {
  const { variant = 'default' } = props || {}

  switch(variant) {
    case 'borderless':
      return <BorderlessSelect {...props} />
    case 'default':
    default: 
      return <Select {...props} />
  }
}


export default SelectTypes
