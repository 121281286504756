import { useQuery } from "@apollo/client"
import { KeyboardArrowLeft } from "@mui/icons-material"
import { Box, Paper } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import AvatarInput from "../../common/AvatarInput"
import { Skelly } from "../../common/components/Skelly"
import ContactInformation from "../../Contacts/Contact/ContactInformation"
import { CONTACT_UPDATE_NOTE, GET_CONTACT_NOTES } from '../../Contacts/Contact/Notes/Queries'
import { SearchButton } from "../../input/Buttons"
import ContactThumbnail from "../../Thumbnail/ContactThumbnail"
import React, { useEffect, useState } from "react"
import Interests from "../../Contacts/Contact/Interests"
import Relationships from "../../Contacts/Contact/Relationships"
import ObjectNotesList from "../../Notes/ObjectNotes/ObjectNotesList"
import TransitionsModal from "../../navigation/TransitionsModal/TransitionsModal"
import NewObjectNote from '../../Notes/ObjectNotes/NewObjectNote'
import { CREATE_NOTE } from '../../Contacts/Contact/Notes/Queries'

const BIO = '28'

export default function ShowcaseContact (props: any) {

	const navigate = useNavigate()
	const params = useParams<any>()
	const id = params?.id

	const [contact, setContact] = useState<any>({})
	const loading = props.loading
	const error = props.error

	useEffect(() => {
		setContact(props?.state?.getContact || {})
	}, [props?.state?.getContact])

	// Notes
	const { data: notesData, loading: notesLoading } = useQuery(GET_CONTACT_NOTES, { variables: { contact_id: id }})

	const [newNoteModal, setNewNoteModal] = React.useState(false)
	const [notes, setNotes] = React.useState([])

	useEffect(() => {

		setNotes(notesData?.getContactNotes?.filter((note: any) => note.type_id === BIO || note.is_critical))

	}, [notesData])
	

	const contactInformationProps = {
		loading,
		error,
		contact,
		setContact,
		findMode: false,
		isPrivate: contact.is_private,
		disableEdits: true,
		noScroll: true,
		editButton: true
	}

	const relationshipProps = {
		loading,
		error,
		contact,
		setContact,
		findMode: false,
		isPrivate: contact.is_private,
		disableEdits: true,
		removeCreatedCol: true
	}

	const interestProps = {
		loading,
		error,
		contact,
		setContact,
		findMode: false,
		isPrivate: contact.is_private,
		disableEdits: true,
		disableNav: true
	}

	const noteProps = {
		notes,
		privateEntity: contact.is_private,
		linkField: 'contact_id',
		updateMutationName: CONTACT_UPDATE_NOTE,
		state: props.state,
		setState: props.setState,
		criticalNotes: "getCriticalContactNotes",
		alias: "Bio and Critical Notes",
		disableTypeFilter: true,
		disableEdits: true,
		addButton: true,
		showcaseMode: true,
		setNewNoteModal: setNewNoteModal
	}

	return <>
		<main style={{ overflow: 'auto' }}>
			<Paper className="qv-margin" style={{
				display: 'flex'
			}}>
				<AvatarInput
					style={{ margin: '1em' }}
					title={contact?.preferred_name}
					alt={contact?.preferred_name}
					src={contact?.imgUrl}
					editable={false}
					oldProfileLink={[contact?.profile_link]}
					inputName="ContactInput"
					entityId={contact?.id}
					entity={contact}
					entityName="contact"
					onPrivateObject={contact?.is_private}
				/>
				<div style={{
					display: 'flex',
					flexDirection: "column"
				}}>
					<h1>  {
						(loading || error) ?
							<Skelly error={error} /> :
							[contact.first_name, contact.last_name].filter(a => a).join(' ')
					} </h1>
					<div> {
						(loading || error) ?
							<Skelly error={error} /> :
							contact.company_name
					} </div>
					<div> {
						(loading || error) ?
							<Skelly error={error} /> :
							contact.type?.map((c: any) => c.contact_type).join(', ')
					} </div>
					
					<div> {
						(loading || error) ?
							<Skelly error={error} /> :
							<span>Foreign preferred name: { contact.foreign_preferred_name }</span>
					} </div>
					

				</div>
			</Paper>

			<ContactInformation
				{...contactInformationProps}
			></ContactInformation>

			{props.showcaseInformation && !loading && <Box>
				
				<Paper className="qv-margin">
					<h1 className="card-sub-title" >Gallery Contacts</h1>
					{ contact.salesperson?.map((s: any) => <ContactThumbnail
						key={s.id}
						loading={false}
						contact={s}
						darkText={!s.is_private}
						style={{ lineHeight: 'initial' }} />)
					}

					{contact?.salesperson?.length === 0 ? <>No gallery contacts.</> : null}
				</Paper>

				<Interests
					{...interestProps}
				></Interests>

				<Relationships
					{...relationshipProps}
				></Relationships>

				<ObjectNotesList
					{...noteProps}
				/>

			</Box>}
		</main>
		<footer style={{
			marginLeft: '1em',
			marginBottom: '1em'
		}}>
			<SearchButton
				startIcon={<KeyboardArrowLeft />}
				style={{ backgroundColor: '#4465D1' }}
				onClick={() => {
					if (props.onClose) props.onClose()
					else navigate('/showcase/contacts')
				}}
			>
				Return to Search
			</SearchButton>
		</footer>

		<TransitionsModal
			className="new-note-modal"
			open={newNoteModal}
			close={() => setNewNoteModal(false)}
			noPadding
			noBackground
		>
			<NewObjectNote
				entityId={props.id}
				notes={notes}
				setNotes={(notes: any) => {

					const filteredNotes = notes.filter((note: any) => note.type_id === BIO || note.is_critical)

					setNotes(filteredNotes)
				}}
				notesLoading={notesLoading}
				mutation={CREATE_NOTE}
				mutationName="createContactNote"
				linkField="contact_id"
				state={props.state}
				setState={props.setState}
				criticalNotes="getCriticalContactNotes"
				privateEntity={contact.is_private || false}
			/>
			
		</TransitionsModal>
	</>
}
