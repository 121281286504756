/* eslint-disable eqeqeq */
import './ContactListings.css'
import {
	GET_LISTING_PREFERENCES,
	GET_CONTACT_LISTING_PREFERENCES,
	CREATE_CONTACT_LISTING_PREFERENCE,
	DELETE_CONTACT_LISTING_PREFERENCE,
} from './Queries'
import groupBy from 'lodash/groupBy'
import { LookupContext, DispatchContext } from '../../../store'
import { severity } from '../../../Snackbar/CustomizedSnackbar'
import { CircularProgress } from '@mui/material'
import { useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import Paper from '@mui/material/Paper'
import React, { useState, useContext, useCallback, useMemo } from 'react'
import Toggle from '../../../input/Toggle/Toggle'
import { CancelButton, SaveCardButton } from '../../../input/Buttons'
import { withApollo } from '@apollo/client/react/hoc'
import {Menu,MenuItem} from "@mui/material"
import { FindModeInput } from '../../../navigation/Tabs/TabbedPage'

const Preferences = (props) => {
	const lookup = React.useContext(LookupContext)
	const galleryTypes = lookup.data?.getGalleryTypes

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	const initialState = {
		mouseX: null,
		mouseY: null,
		editable: false
	}

	const [state, setState] = React.useState(initialState)
	const [contactPreferences, setContactPreferences] = useState({})

	const [createPreference] = useMutation(CREATE_CONTACT_LISTING_PREFERENCE)
	const [deletePreference] = useMutation(DELETE_CONTACT_LISTING_PREFERENCE)

	const [initialContactPreferences, setInitialContactPreferences] = useState({})

	const [saving, setSaving] = useState(false)

	const { data: getLP, loading } = useQuery(GET_LISTING_PREFERENCES)

	const handleClick = event => {
		if (state.mouseX || state.editable || props.findMode || !props?.id) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4
		})
	}

	const handleClose = option => {
		if (option === "edit") {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
			setContactPreferences(initialContactPreferences)
		}
	}

	React.useEffect(() => {

		if (props.findMode) return
		let active = true
		props.client
			.query({ query: GET_CONTACT_LISTING_PREFERENCES, variables: { contact_id: props.id}})
			.then(listingData => {
				if (active && listingData.data.getContactListingPreferences) {
					const listingObj = listingData.data.getContactListingPreferences.reduce((acc, el) => {
						acc[el.listing_preference_id] = { isChecked: true, id: el.id }
						return acc
					}, {})
					setInitialContactPreferences(listingObj)
					setContactPreferences(listingObj)
				}
			})

		return () => {
			active = false
		}

	}, [openSnackbar, props.client, props.id, props.findMode])

	const handleSave = () => {

		let deleteToggleIds = Object.entries(contactPreferences).filter(([_, val]) => !val.isChecked && val.id)
			.map(([_, val]) => val.id)
		let createToggleIds = Object.entries(contactPreferences)
			.filter(([key, val]) => val.isChecked && !initialContactPreferences[key])
			.map(([key, val]) => key )
		const updates = []

		if (deleteToggleIds.length) {
			setSaving(true)
			updates.push(
				deletePreference({ variables: { ids: deleteToggleIds } })
					.then(response => {
						if (!response?.data?.deleteContactListingPreference?.success) {
							setContactPreferences(initialContactPreferences)
							throw(response?.errors[0]?.message)
						} else {
							setContactPreferences(cp => {
								Object.entries(cp)
									.filter(([ _, val ]) => deleteToggleIds.includes(val.id))
									.forEach(([ key ]) => delete cp[key])
								return cp
							})
						}
					})
			)
		}

		if (createToggleIds.length) {
			setSaving(true)
			updates.concat(createToggleIds.map(x =>
				createPreference({
					variables: {
						contact_id: props.id,
						listing_preference_id: x,
					},
				}).then(response => {
					const resp = response?.data?.createContactListingPreference
					if (!resp.success) {
						setContactPreferences(initialContactPreferences)
						throw(response?.errors[0]?.message)
					} else {
						const updatedCLP = resp.ContactListingPreference
						setContactPreferences(cp => ({
							...cp,
							[updatedCLP.listing_preference_id]:  { isChecked: true, id: updatedCLP.id }
						}))
					}
				})
			))
		}
		Promise.all(updates).then(() => {
			openSnackbar(severity.SUCCESS, 'Successfully Updated Contact Listing Preferences.')
			setSaving(false)
		}).catch(err => {
			openSnackbar(severity.ERROR, 'One or more errors occured in saving. Please check the console for more details.')
			console.error(err)
			setSaving(false)
		})
	}



	let groupedToggles = useMemo(() => {
		const toggles = getLP?.getListingPreferences ?? []

		if (!toggles || !galleryTypes) return []
		const galleries = groupBy(galleryTypes, 'id')
		const groups = groupBy(toggles, (toggle) => galleries[toggle.gallery_id]?.[0]?.value || 'Global')
		const {Global, ...rest} = groups
		return [{ Global }, {...rest}]
	}, [getLP, galleryTypes])


	return (
		<Paper className="padding-margin"
			onContextMenu={handleClick}
			data-testid="card-preferences"
		>
			<h1 className="card-title">
				<span>Preferences</span>

				{state.editable && (
					<>
						<div className="spacer"></div>
						<CancelButton
							variant="contained"
							size="small"
							onClick={() => {
								handleClose()
							}}
						>
							Cancel
						</CancelButton>
						<SaveCardButton
							variant="contained"
							color="secondary"
							size="small"
							disabled={saving}
							onClick={() => {
								handleSave()
								setState({...state, editable: false})
							}}
						>
								Save
						</SaveCardButton>
					</>
				)}
			</h1>

			{/* outer */}
			<div className="flex-container preference-container" style={{
				minHeight: '10em',
				display: 'flex',
				justifyContent: loading || !groupedToggles ? 'center' : 'left',
				alignItems: loading || !groupedToggles ? 'center' : 'flex-start',
			}}>
				{loading || !groupedToggles ? <CircularProgress /> : groupedToggles
					.map(outerGroup => <div
						style={{
							marginRight: '2em',
							display: 'flex',
							flexWrap: 'wrap'
						}}
					> {/* inner group */}
						{ Object.keys(outerGroup).sort((a, b) => a - b).map((key) => {
							return (
								<div key={key} className="flex-item preference-item">
									<div className="preference-header">
										{key}
									</div>

									{outerGroup[key]?.map((toggle, index) => {
										const preference = contactPreferences[toggle.id]?.isChecked
										const isChecked = !!preference
										const field = [
											key == 'Global' ? '' : key,
											toggle.listing_preference
										].join('-')
										return (
											<div key={index} className="toggle-container" >
												{ toggle.listing_preference }
												{ props.findMode ?
													<FindModeInput
														type="listing-pref"
														field={field}
													/> :

													<Toggle
														checked={isChecked || false}
														onClick={() => {

															if (!state.editable) return

															setContactPreferences(pref => ({
																...pref,
																[toggle.id]: {
																	id: pref[toggle.id]?.id,
																	isChecked: !isChecked
																}
															}))

														}}
													/>
												}
											</div>
										)
									})}
								</div>
							)
						})}
					</div>)
				}
			</div>
			<Menu
				keepMounted
				open={state.mouseY !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					state.mouseY !== null && state.mouseX !== null
						? { top: state.mouseY, left: state.mouseX }
						: undefined
				}
			>
				<MenuItem onClick={() => handleClose("edit")}>
						Edit
				</MenuItem>
			</Menu>
		</Paper>
	)
}

export default withApollo(Preferences)
