import React from 'react'
import { Skeleton } from '@mui/material'
import { skeletonStyle } from '../../styles/makesStyles'

export const Skelly = (props) => <Skeleton
	animation={props.error ? false : 'wave'}
	variant="text"
	width="auto"
	style={skeletonStyle}
/>
