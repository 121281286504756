import { createTheme } from "@mui/material/styles"

const theme = createTheme({
	typography: {
		fontFamily: ["Graphik"].join(","),
		fontSize: 14,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		button: {
			textTransform: "none",
		},
	},
	palette: {
		mode: "dark",
	},
	components: {
		MuiDataGrid: {
			styleOverrides: {
				root: {
					border: 'none'
				},
				main: {
					overflow: 'initial',
				},
				columnHeaders: {
					position: 'sticky',
					zIndex: 1,
					backgroundColor: '#1e1e1e',
				},
				toolbarContainer: {
					justifyContent: 'end',
					marginRight: '1em',
					marginTop: '-3em',
				},
				virtualScroller: {
					marginTop: '0px !important',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				rounded: {
					borderRadius: "8px",
				},
			},
		},
		MuiBadge: {
			styleOverrides: {
				root: {
					borderRadius: "100%",
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					"&$focused": {
						color: "white",
					},
				},
			},
		},
		MuiMenu: {
			styleOverrides: {
				paper: {
					maxHeight: "20em",
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					backgroundColor: "#6A6A72",
				},
				indicator: {
					backgroundColor: "white",
				},
				scroller: {
					height: "100%",
				},
				flexContainer: {
					justifyContent: "center",
					minWidth: "max-content",
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					"&.Mui-selected": {
						color: "white",
					},
				},
			},
		},
		MuiPopover: {
			styleOverrides: {
				root: {
					maxHeight: "35em",
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					fontFamily: "Graphik",
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: {
					borderColor: "white",
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					"&.Mui-selected": {
						backgroundColor: "#272729 !important",
					},
				},
			},
		},
		MuiButtonBase: {
			styleOverrides: {
				root: {
					fontFamily: "Graphik",
				},
			},
		},
		MuiAvatarGroup: {
			styleOverrides: {
				avatar: {
					border: "none",
				},
				root: {
					justifyContent: 'flex-end'
				}
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					border: "none !important",
				},
			}
		}
	},
})
export default theme
