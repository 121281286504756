import { gql } from '@apollo/client'
import { fileData, userData, userDataCompressed } from '../../../common/fragments'

export const GET_ART_COA_LOCATION_HISTORY = gql`
	query getArtCoaLocationHistory(
		$id: ID
		$cursor: Int
		$limit: Int
		$thumbnailResolution: String = "128x128"
	) {
		getArtCoaLocationHistory(id: $id, cursor: $cursor, limit: $limit) {
			items {
				id
				art_id
				status_id
				location_id
				sub_location_id
				via_id
				is_current
				tracking_number
				estimated_shipping_cost
				final_shipping_cost
				location_change_at
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				status {
					id
					value
				}
				location {
					id
					name
				}
				sub_location {
					id
					name
				}
				via {
					id
					name
				}
				user {
					...userData
					gallery
				}
				modified_user {
					...userData
					gallery
				}
				
				currency_id
				currency {
					id
					symbol
					label
					name
				}

				files {
					...fileData
				}

			}
			cursor
			totalItems
		}
	}
	${fileData}
	${userData}
`

export const GET_ART_LOCATION_HISTORY = gql`
	query getArtLocationHistory(
		$id: ID
		$cursor: Int
		$limit: Int
		$thumbnailResolution: String = "128x128"
	) {
		getArtLocationHistory(id: $id, cursor: $cursor, limit: $limit) {
			items {
				id
				art_id
				location_id
				sub_location_id
				via_id
				is_current
				estimated_shipping_cost
				final_shipping_cost
				location_change_at
				created_by
				created_at
				modified_by
				modified_at
				is_deleted

				location {
					id
					name
				}
				sub_location {
					id
					name
				}
				via {
					id
					name
				}
				user {
					...userData
					gallery
				}

				currency_id
				currency {
					id
					symbol
					label
					name
				}

				files {
					...fileData
				}

			}
			cursor
			totalItems
		}
	}
	${fileData}
	${userData}
`

export const GET_LOCATION = gql`
	query {
		getLocation {
			id
			name
			is_archived
			created_by
			created_at
			modified_by
			modified_at
			is_deleted
		}
	}
`

export const GET_SUB_LOCATION = gql`
	query {
		getSubLocation {
			id
			name
			is_archived
			created_by
			created_at
			modified_by
			modified_at
			is_deleted
		}
	}
`

export const SIMPLE_SEARCH_LOCATION = gql`
	query simpleSearchLocation($query: String) {
		simpleSearchLocation(query: $query) {
			id
			name
			is_archived
			created_by
			created_at
			modified_by
			modified_at
			is_deleted
		}
	}
`

export const SIMPLE_SEARCH_SUB_LOCATION = gql`
	query simpleSearchSubLocation($query: String) {
		simpleSearchSubLocation(query: $query) {
			id
			name
			is_archived
			created_by
			created_at
			modified_by
			modified_at
			is_deleted
		}
	}
`

export const SIMPLE_SEARCH_VIA = gql`
	query simpleSearchVia($query: String) {
		simpleSearchVia(query: $query) {
			id
			name
			is_archived
			created_by
			created_at
			modified_by
			modified_at
			is_deleted
		}
	}
`

export const DELETE_ART_LOCATION = gql`
	mutation deleteArtLocation($id: ID!) {
		deleteArtLocation(id: $id) {
			code
			success
			message
		}
	}
`

export const GET_VIA = gql`
	query {
		getVia {
			id
			name
			is_archived
			created_by
			created_at
			modified_by
			modified_at
			is_deleted
		}
	}
`

export const CREATE_LOCATION_HISTORY = gql`
	mutation createArtLocation(
		$CreateArtLocationInput: CreateArtLocationInput!
		$thumbnailResolution: String = "128x128"
	) {
		createArtLocation(input: $CreateArtLocationInput) {
			code
			success
			message
			ArtLocation {
				id
				art_id
				location_id
				sub_location_id
				via_id
				is_current
				estimated_shipping_cost
				final_shipping_cost
				location_change_at
				created_by
				created_at
				modified_by
				modified_at
				is_deleted

				location {
					id
					name
				}
				sub_location {
					id
					name
				}
				via {
					id
					name
				}
				user {
					...userData
					gallery
				}

				currency_id
				currency {
					id
					symbol
					label
					name
				}

				files {
					...fileData
				}
			}
		}
	}
	${fileData}
	${userData}
`

export const UPDATE_ART_LOCATION = gql`
	mutation updateArtLocation(
		$UpdateArtLocationInput: UpdateArtLocationInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateArtLocation(input: $UpdateArtLocationInput) {
			code
			success
			message
			ArtLocation {
				id
				art_id
				location_id
				sub_location_id
				via_id
				is_current
				estimated_shipping_cost
				final_shipping_cost
				location_change_at
				created_by
				created_at
				modified_by
				modified_at
				is_deleted

				location {
					id
					name
				}
				sub_location {
					id
					name
				}
				via {
					id
					name
				}
				user {
					...userDataCompressed
					gallery
				}

				currency_id
				currency {
					id
					symbol
					label
					name
				}

				files {
					...fileData
				}
			}
		}
	}
	${fileData}
	${userDataCompressed}
`


export const CREATE_COA_LOCATION_HISTORY = gql`
	mutation createArtCoaLocation(
		$CreateArtCoaLocationInput: CreateArtCoaLocationInput!
		$thumbnailResolution: String = "128x128"
	) {
		createArtCoaLocation(input: $CreateArtCoaLocationInput) {
			code
			success
			message
			ArtCoaLocation {
				id
				art_id
				status_id
				location_id
				sub_location_id
				via_id
				is_current
				tracking_number
				estimated_shipping_cost
				final_shipping_cost
				location_change_at
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				status {
					id
					value
				}
				location {
					id
					name
				}
				sub_location {
					id
					name
				}
				via {
					id
					name
				}
				user {
					...userData
					gallery
				}
				currency_id
				currency {
					id
					symbol
					label
					name
				}
				files {
					...fileData
				}
			}
		}
	}
	${fileData}
	${userData}
`


export const UPDATE_ART_COA_LOCATION = gql`
	mutation updateArtCoaLocation(
		$UpdateArtCoaLocationInput: UpdateArtCoaLocationInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateArtCoaLocation(input: $UpdateArtCoaLocationInput) {
			code
			success
			message
			ArtCoaLocation {
				id
				art_id
				status_id
				location_id
				sub_location_id
				via_id
				is_current
				tracking_number
				estimated_shipping_cost
				final_shipping_cost
				location_change_at
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				status {
					id
					value
				}
				location {
					id
					name
				}
				sub_location {
					id
					name
				}
				via {
					id
					name
				}
				user {
					...userDataCompressed
					gallery
				}
				currency_id
				currency {
					id
					symbol
					label
					name
				}
				files {
					...fileData
				}
			}
		}
	}
	${fileData}
	${userDataCompressed}
`

export const DELETE_ART_COA_LOCATION = gql`
	mutation deleteArtCoaLocation($id: ID!) {
		deleteArtCoaLocation(id: $id) {
			code
			success
			message
		}
	}
`
