import { gql } from '@apollo/client'
import ShowcaseGridView from '../ShowcaseGridView'

export const PAST_SALES_ART_SEARCH = gql`
	query searchDynamicArt($field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int) {
		searchDynamicArt(input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters }) {
			cursor
			totalItems
			items {
				id
				title
				artist {
					id
					first_name
					last_name
				}
				code_name
				imgUrl
				year
				status {
					id
					value
				}
				primary_currency_id
				primaryRetailPrice

				medium
				all_dimensions
			}
		}
	}
`

export const PastSales = (props: any) => {

	const filters =  [
		{ field: 'artist_id', type: 'eq', value: props.id, isOptional: false },
		{ field: 'art_status_value', type: 'contains', value: 'SOLD', isOptional: false },
	]

	return (
		<ShowcaseGridView
			filters={filters}
			query={PAST_SALES_ART_SEARCH}
			showReserve={false}
			state={props.state}
			page="PastSales"
			hideCreation
			showcaseInformation={props.showcaseInformation}
			setShowcaseInformation={props.setShowcaseInformation}

			showcaseSelectMode={props.showcaseSelectMode}
			setShowcaseSelectMode={props.setShowcaseSelectMode}
		/>
	)
}

export default PastSales
