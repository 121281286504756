import React from 'react'
import HomeToggles from './HomeToggles'
import { useQuery } from '@apollo/client'
import UserGoalsPerYear from '../../AdminConsole/UserGoalsPerYear'
import { GET_HP_WIDGET_BY_USER } from '../Queries'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import UserDetails from '../../AdminConsole/UserDetails'
import UserAdvanced from '../../AdminConsole/UserAdvanced'
import UserGroups from './UserGroups'
import { Paper } from '@mui/material'

export default function Details(props) {
	const user = props.state?.getUser
	
	const { loading, error, data: response, } = useQuery(GET_HP_WIDGET_BY_USER, {
		variables: { id: user?.id },
		skip: !user?.id,
	})

	//Snackbar
	const dispatch = React.useContext(DispatchContext)
	const openSnackbar = React.useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const values = response?.getHomePageWidgetsXUser
	const widgets = response?.getHomePageWidgets

	if (error) openSnackbar(severity.ERROR, "Could not get user home widget details.")

	return (
		<section className="main-page">
			<div>
				<div className="page-row">
					<UserDetails
						readOnly
						user={user}
						loading={props?.loading || loading || !response}
					></UserDetails>
					<UserAdvanced
						readOnly
						user={user}
						loading={props?.loading || loading || !response}
					></UserAdvanced>
				</div>
				<div className="page-row">
					<UserGoalsPerYear
						readOnly
						user={user}
						loading={props?.loading || loading || !response}
					></UserGoalsPerYear>
					<HomeToggles 
						user={user} 
						values={values}
						widgets={widgets}
						loading={props?.loading || loading || !response}
					></HomeToggles>
				</div>
				<div className="page-row">
					<UserGroups
						user={user} 
						loading={props?.loading || loading || !response}
					/>
					<Paper className="qv-margin-no-top" style={{ width: '50%', visibility: 'hidden' }}/>
				</div>
			</div>
		</section>
	)
}
