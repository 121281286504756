import { gql } from '@apollo/client'

export const GET_RELEASE_NOTES = gql`
	query GetReleaseNotes(
        $cursor: Int
        $limit: Int
    ) {
		getReleaseNotes(
			input: {
				limit: $limit
				cursor: $cursor	
			}
		) {
            cursor
			totalItems
            items {
                id
                content
                modified_at
				created_at
				is_new
            }
		}
	}
`

export const UPSERT_RELEASE_NOTE = gql`
	mutation UpsertReleaseNote($UpsertReleaseNote: UpsertReleaseNote!) {
		upsertReleaseNote(input: $UpsertReleaseNote) {
			code
			success
			message
			severity
		}
	}
`
