import React, { useEffect, useState } from 'react'
import LimitedAutocomplete from '../common/LimitedAutocomplete'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import Thumbnail from '../Thumbnail/Thumbnail'
import { CircularProgress } from '@mui/material'
import { autoCompleteErrorStyles } from '../styles/makesStyles'
import { gql, useLazyQuery } from '@apollo/client'
import TextBoxThinForAutocomplete from './Text/TextBoxThinForAutocomplete'
import TextBoxForAutocomplete from './Text/TextBoxForAutocomplete'

export const GET_SINGLE_USER = gql`
	query user($id: ID) {
		getUser(id: $id) {
			id
			first_name
			last_name
		}
	}
`


/**
 * @typedef SingleUserProps
 * @property {boolean=} error - if the value isn't valie
 * @property {DocumentNode} query - the query to call for the autocomplete
 * @property {function} onChange - a callback when the contact value changes
 * @property {boolean=} private - if the ... context? is private
 * @property {*} value - currently selected 'User' object
 * @property {boolean=} smallTextField - determines whether to use small textfield or not
 */

/**
 * @param {SingleUserProps} props
 */
export default function SingleUser (props) {

	// pass down styling/id props that are passed in
	const passedProps = {}
	if (props.className) passedProps.className = props.className
	if (props.style) passedProps.style = props.style
	if (props['data-testid']) passedProps['data-testid'] = props['data-testid']
	if (props.onChange) passedProps.onChange = props.onChange

	const [userOpen, setUserOpen] = useState(false)
	const [loadUsers, setLoadUsers] = useState(false)

	const [lazyLoadUsers, { loading, data }] = useLazyQuery(props.query)
	const [loadSingleUser] = useLazyQuery(GET_SINGLE_USER)

	// extract whatever the query returns, regardless of query name
	const userOptions = Object.values(data ?? {})?.[0]?.items ?? []

	useEffect(() => {
		if (typeof loadUsers === 'string') {
			lazyLoadUsers({ variables: {
				...props.variables,
				query: loadUsers
			}})
			setLoadUsers(false)
		}
	}, [lazyLoadUsers, loadUsers, props.variables])

	const searchVal = props.value

	useEffect(() => {
		if (typeof searchVal === 'string') {
			loadSingleUser({
				variables: { id: searchVal }
			}).then(({data}) => {
				const user ={...data?.getUser}
				props.onChange(null, user)
			})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadSingleUser, searchVal])

	return <LimitedAutocomplete
		setQuery={setLoadUsers}
		// eslint-disable-next-line eqeqeq
		isOptionEqualToValue={(a, b) => a?.id === b?.id || a?.id == b || a == b?.id}
		className="fullWidth"
		open={userOpen && !!userOptions?.length}
		forcePopupIcon
		style={{...props.style}}
		popupIcon={<ExpandMoreRoundedIcon />}
		onOpen={() => setUserOpen(true)}
		onClose={() => setUserOpen(false)}
		loading={loading}
		options={userOptions}
		value={props.value}
		getOptionLabel={val => {
			// eslint-disable-next-line eqeqeq
			let user = val.id ? val : userOptions.find(u => u.id == val)
			return [
				user?.first_name,
				user?.last_name
			].filter(a => a).join(' ') ?? '' }}
		classes={autoCompleteErrorStyles()}
		getOptionDisabled={option => option.disabled}
		filterSelectedOptions
		renderOption={(optionProps, option) => {
			// eslint-disable-next-line eqeqeq
			const user = userOptions?.find(e => e.id == option || e.id == option.id) || {}

			const name = [user.first_name, user.last_name].filter(a => a).join(' ')

			const thumbnail = <Thumbnail
				name={name}
				detail={user?.department ?? "-"}
				avatar={user?.imgUrl}
				type="contact"
				animation={loading ? 'wave' : false}
				darkText={!props.private}
				largeText
			/>

			if (option.disabled) {
				return (
					<li {...optionProps} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
						{thumbnail}
						<i>Already Included</i>
					</li>
				)
			}
			return <li {...optionProps}>{thumbnail}</li>
		}}

		renderInput={(params) => {

			// Large TextField
			if (!props.smallTextField) {
				return (
					<TextBoxForAutocomplete
						autoFocus
						{...params}
						error={props.error}
						variant="outlined"
						InputProps={{
							...params.InputProps,
							endAdornment: <>
								{loading ? (
									<CircularProgress
										color="inherit"
										size={20}
									/>
								) : null}
								{params.InputProps.endAdornment}
							</>,
							style: {
								paddingTop: '3px',
								paddingBottom: '3px',
							},
						}}
					/>
				)
			}

			// Small TextField
			return (
				<TextBoxThinForAutocomplete
					{...params}
					variant="outlined"
					fullWidth
					style={{ paddingTop: '1.8em' }}
					classes={{ notchedOutline: null }}
					error={props.error}
					InputLabelProps={{
						shrink: true,
					}}
					InputProps={{
						...params.InputProps,
						endAdornment: <>
							{ loading ? <CircularProgress
								color="inherit"
								size={20}
							/> : null }
							{ params.InputProps.endAdornment }
						</>,
						style: {
							paddingTop: '3px',
							paddingBottom: '3px',
						},
					}}
				/>
			)
		}}
		{...passedProps}
	/>
}
