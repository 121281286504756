/* eslint-disable eqeqeq */
import { useMutation } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { MobileDatePicker } from '@mui/x-date-pickers-pro'
import { CircularProgress, FormControl } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import React, { useContext, useEffect } from 'react'
import { randomHSL } from '../common/helpers'
import { SavedSearch } from '../common/SavedSearch'
import { TAG_SEARCH_OBJECT } from '../constants/values'
import { AdvancedToggleButton, ResetButton, SaveButton, SearchButton } from '../input/Buttons'
import Label from '../input/Label'
import TextBox from '../input/Text/TextBox'
import TextBoxThinForAutocomplete from '../input/Text/TextBoxThinForAutocomplete'
import ConfirmationInput from '../navigation/ConfirmationDialog/ConfirmationInput'
import AdvancedFilterCount from '../Search/AdvancedFilterCount'
import { CREATE_SEARCH } from '../Search/Queries'
import { handleSearchChange, saveMap, searchMap } from '../Search/unifiedSearchHelpers'
import { AuthStateContext } from '../store'
import { autoCompleteStyles, typeStyle } from '../styles/makesStyles.js'
import { GET_SEARCH_CARD_USERS } from '../User/Queries'

const SearchTags = (props) => {


	const userAuthentication = useContext(AuthStateContext)
	const [currentSearch, setCurrentSearch] = React.useState(props.prevSearch ?? new SavedSearch({
		object_id: TAG_SEARCH_OBJECT,
		search_terms: [],
		is_global: true,
		user_id: userAuthentication.user?.id
	}))
	const [savedSearchDialog, setSavedSearchDialog] = React.useState({
		open: false,
		search: null
	})

	const handleTagSavedSearchChange = (event, type = 'eq') => {

		const search_terms = handleSearchChange(
			event,
			currentSearch,
			setCurrentSearch,
			type,
			true
		)

		if (props.currentSearch.id) { 
			props.setCurrentSearch({ 
				...currentSearch,
				search_terms,
				id: null 
			})
		}
	}
	
	// Mutations
	const [createSearch, { loading: saving }] = useMutation(CREATE_SEARCH)

	const classes = autoCompleteStyles()

	const [open, setOpen] = React.useState(false)
	const [options, setOptions] = React.useState([])
	const loading = open && options.length === 0

	const [firstLoad, setFirstLoad] = React.useState(true)
	React.useEffect(() => setFirstLoad(false), [])

	React.useEffect(() => {
		let active = true
		if (!loading) {
			return undefined
		}
		props.client.query({ query: GET_SEARCH_CARD_USERS }).then((result) => {
			if (active) {
				setOptions(result.data.getSearchCardUsers)
			}
		})

		return () => {
			active = false
		}
	}, [loading, props.client])

	useEffect(() => {
		if (props.currentSearch) setCurrentSearch(props.currentSearch)
	}, [props.currentSearch])

	const getSearchValue = (fieldName) =>
		currentSearch.search_terms
			?.find(el => el.field == fieldName)?.value



	return (
		<>
			<h1 className="card-title">
				
				Search Tags

				<AdvancedFilterCount
					advancedFilters={props.advancedFilters}
					setAdvancedSearch={props.setAdvancedSearch}
				/>
			
				{/* 
				<!-- Tag search has been intentionally altered to auto-* 
				  all search terms: https://jahnelgroup.atlassian.net/browse/LGWEBAPP-4873
				-->
				<SearchInfo 
					advancedFilters={!!props.advancedFilters?.length}
					fields={["Label", "Description", "Creator name", "ID"]} style={{marginRight: 0}}
					recordName="tag"
					unified
				/>  */}
			</h1>
			<form
				onSubmit={(e) => {
					e.preventDefault()
					props.setActiveStep(0)
					props.handleSubmit(
						{ cursor: 0 },
						currentSearch.search_terms,
						currentSearch.id
					)
				}}
			>
				<div className="row">
					<FormControl style={{ flexGrow: '1', paddingRight: '1em' }}>
						<Label id="user-label" style={typeStyle} disableAnimation shrink>
							Created User
						</Label>

						<Autocomplete
							style={{ marginTop: '1.55em' }}
							options={options ? options : []}
							popupIcon={<ExpandMoreRoundedIcon />}
							getOptionLabel={(option) => {
								return option ? [option.first_name, option.last_name].filter(e => e).join(" ") : ''
							}}
							open={open}
							onOpen={() => {
								setOpen(true)
							}}
							onClose={() => {
								setOpen(false)
							}}
							loading={loading}
							value={props.selection}
							filterSelectedOptions
							classes={classes}
							renderInput={(params) => (
								<TextBoxThinForAutocomplete
									{...params}
									fullWidth
									InputProps={{
										...params.InputProps,
										style: {
											paddingTop: '3px',
											paddingBottom: '3px'
										},
										endAdornment: (
											<React.Fragment>
												{loading ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
									data-testid="search-created_user"
								/>
							)}
							onChange={(event, value) => {

								props.setSelection(value)

								handleTagSavedSearchChange({
									target: {
										name: 'created_user',
										value: value ? [value.first_name, value.last_name].filter(e => e).join(" ") : null
									},
								})
							}}
						/>
					</FormControl>

					<FormControl style={{ flexGrow: '1', paddingRight: '1em' }}>
						<Label id="start-date-label" style={typeStyle} disableAnimation shrink>
							Date Created
						</Label>

						<MobileDatePicker
							inputFormat="MMM do, yyyy"
							disableMaskedInput={true}
							componentsProps={{
								actionBar: {
									actions: ['clear', 'accept']
								}
							}}
							name="created_at"
							value={getSearchValue('created_at') || null}
							inputVariant="outlined"
							style={{ marginTop: '17px' }}
							className="MUIDatePicker"
							variant="dialog"
							renderInput={({ inputRef, inputProps, InputProps }) => {
								
								const newProps = { ...inputProps}
	
								newProps.readOnly = false
	
								return (
									<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
								)}}
							onChange={(date) =>
								handleTagSavedSearchChange({
									target: {
										name: 'created_at',
										value: date ? date : null,
									},
								})
							}
							data-testid="search-created_date"
						/>
					</FormControl>

					<FormControl className={classes.searchBar} style={{ flexGrow: '2' }}>
						<Label style={typeStyle} disableAnimation shrink>
							Search
						</Label>
						<TextBox 
							name="query" 
							inputRef={input => input && firstLoad && input.focus()}
							value={getSearchValue('query') || ''} 
							onChange={(event) => handleTagSavedSearchChange(event)} 
							data-testid="searchbar"
						/>
					</FormControl>
				</div>

				<div className="row" style={{ paddingTop: '1em' }}>
					<div>
						<SaveButton data-testid="save-button" 
							variant="contained" size="small" 
							onClick={() => props.saveSearch()}
						>
							Save Search
						</SaveButton>
					</div>
					<AdvancedToggleButton
						data-testid="advanced-toggle-button"
						size="small"
						onClick={() => {
							props.setCurrentSearch(currentSearch)
							props.setAdvancedSearch(true)
						}}
						style={{ marginRight: '1em' }}
					>
                    Advanced
					</AdvancedToggleButton>
					<ResetButton
						data-testid="reset-button"
						variant="contained"
						size="small"
						onClick={() => {
							props.setTotalItems(null)
							props.setSelection([])
							props.resetSearch('simple')
							setCurrentSearch(new SavedSearch({
								object_id: TAG_SEARCH_OBJECT,
								search_terms: [],
								is_global: true,
								user_id: userAuthentication.user?.id
							}))
						}}
						style={{ marginRight: '1em' }}
					>
						Reset
					</ResetButton>
					<SearchButton data-testid="search-button" variant="contained" size="small" type="submit" style={{backgroundColor: !props.loading ? '#4465D1' : null}}
						disabled={props.loading}>
						Search
					</SearchButton>
				</div>
			</form>

			<ConfirmationInput
				open={savedSearchDialog.open}
				handleClose={() => setSavedSearchDialog({
					open: false,
					savedSearch: null
				})}
				disabled={saving}
				title={'Save search?'}
				acceptText={'Save'}
				buttonColor="#33BF5C"
				inputLabel="Search Label"
				placeholder={'Saved Search ' + (props.savedSearches?.length + 1)}
				onYes={(text) => {
					if (!saving) {
						createSearch({
							variables: {
								CreateSearchInput: {
									label: text ? text : 'Saved Search ' + (props.savedSearches?.length + 1),
									user_id: userAuthentication.user.id,
									object_id: TAG_SEARCH_OBJECT,
									color: randomHSL(),
									search_terms: searchMap(currentSearch.search_terms),
									result_count: null,
									is_global: true,
								},
							},
						}).then((response) => {

							setCurrentSearch({
								...response.data.createSavedSearch.search,
								search_terms: saveMap(response.data.createSavedSearch.search.search_terms)
							})

							props.setSavedSearches(props.savedSearches.concat(
								new SavedSearch(response.data.createSavedSearch.search))
							)
						})
					}
				}}
			/>
		</>
	)
}

export default withApollo(SearchTags)
