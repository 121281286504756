import * as Colors from '../styles/colors/Colors'
import { Button } from '@mui/material'
import withStyles from '@mui/styles/withStyles'

export const AdvancedToggleButton = withStyles(() => ({
	root: {
		fontWeight: 400,
		color: Colors.editAdd,
	}
}))(Button)

export const SubmitButton = withStyles(() => ({
	root: {
		fontWeight: 400,
		color: Colors.white,
		backgroundColor: Colors.acceptChanges,
		'&:hover': {
			backgroundColor: Colors.acceptChanges
		},
		'&:active': {
			boxShadow: "none"
		},
		'&:disabled': {
			color: Colors.white,
		},
	}
}))(Button)

export const ChooseImageButton = withStyles(theme => ({
	root: {
		fontWeight: 400,
		color: Colors.white,
		backgroundColor: Colors.editAdd,
		'&:hover': {
			backgroundColor: Colors.editAdd,
		},
		'&:active': {
			boxShadow: "none"
		}
	},
}))(Button)

export const SaveButton = withStyles(theme => ({
	root: {
		fontWeight: 400,
		color: Colors.white,
		backgroundColor: Colors.acceptChanges,
		'&:hover': {
			backgroundColor: Colors.acceptChanges,
		},
		'&:active': {
			boxShadow: "none"
		}
	},
}))(Button)


export const ResetButton =  withStyles(theme => ({
	root: {
		fontWeight: 400,
		color: Colors.white,
		backgroundColor: Colors.resetButton,
		'&:hover': {
			backgroundColor: Colors.resetButton,
		},
		'&:active': {
			boxShadow: "none"
		}
	},
}))(Button)

export const SearchButton = withStyles(theme => ({
	root: {
		fontWeight: 400,
		color: Colors.white,
		backgroundColor: Colors.acceptChanges,
		'&:hover': {
			backgroundColor: Colors.acceptChanges,
		},
		'&:active': {
			boxShadow: "none"
		}
	},
}))(Button)

export const CancelButton = withStyles(theme => ({
	root: {
		fontWeight: 400,
		backgroundColor: "#BEBEBE",
		'&:hover': {
			backgroundColor: "#BEBEBE",
		},
		'&:active': {
			boxShadow: "none"
		}
	},
}))(Button)

export const SaveCardButton = withStyles(theme => ({
	root: {
		fontWeight: 400,
		color: '#FFFFFF',
		backgroundColor: Colors.acceptChanges,
		'&:hover': {
			backgroundColor: Colors.acceptChanges
		},
		'&:active': {
			boxShadow: "none"
		}
	},
}))(Button)


export const NavigationButton = withStyles(theme => ({
	root: {
		fontWeight: 400,
		color: Colors.white,
		'&:active': {
			boxShadow: "none"
		}
	}
}))(Button)

export const NewButton = withStyles(theme => ({
	root: {
		fontWeight: 400,
		color: Colors.white,
		backgroundColor: Colors.editAdd,
		'&:hover': {
			backgroundColor: Colors.editAdd,
		},
		'&:active': {
			boxShadow: "none"
		}
	},
}))(Button)
