import { useState, useEffect, useMemo  } from "react"
import { FormControl, FormControlLabel, Checkbox, Select, MenuItem } from '@mui/material'
import Label from '../input/Label'

import chunk from 'lodash/chunk'
import { ExpandMoreRounded } from "@mui/icons-material"
import Dropdown from "../input/Dropdown/Dropdown"

const ArtLabelModal = props => {
	const labels = {
		includeArtist: "Artist",
		includeTitle: "Title",
		includeDate: "Date",
		includeMedium: "Medium",
		includeDimensions: "Dimensions",
		includeInventory: "Inventory number",
		includeEdition: "Edition",
		includeCoa: "Certificate of Authenticity",
	}

	const initialFields = useMemo(() => ({
		includeArtist: true,
		includeDate: true,
		includeTitle: true,
		includeMedium: true,
		includeDimensions: true,
		includeInventory: true,
		includeEdition: true,
		includeCoa: false,
	}), [])

	const storedFields = JSON.parse( localStorage.getItem('labelSheetOptions.fields') )
	const [fields, setFields] = useState( storedFields || initialFields)
	const [paperSize, setPaperSize] = useState('A4')

	// update stored values if a new one is added.
	useEffect(() => {
		if (!fields || Object.keys(initialFields).length !== Object.keys(fields).length) {
			const tempFields = {...fields}
			Object.keys(initialFields).forEach((key) => {
				if (tempFields[key] === undefined) tempFields[key] = true
			})
			setFields(tempFields)
		}
	}, [fields, initialFields])
		
	useEffect( () => {
		localStorage.setItem('labelSheetOptions.fields', JSON.stringify(fields))
		props.onChange({
			...fields,
			paperSize
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	} , [fields, paperSize])

	const selectorRows = chunk(Object.keys(fields)?.map( key => ({
		label: labels[key],
		key: key,
		value: props.fields[key] || false
	})), Math.ceil(Object.keys(props.fields).length / 3))


	return <div>
		<FormControl style={{ width: '20em' }} label="paper-size">
			<Label id="paper-size" disableAnimation shrink>
				Paper Size
			</Label>

			<Select
				className="padded-select"
				IconComponent={ExpandMoreRounded}
				name="paper-size"
				labelId="paper-size"
				input={<Dropdown />}
				value={paperSize}
				onChange={(e) => setPaperSize(e.target.value)}
			>
				<MenuItem key="A4" value="A4">A4</MenuItem>
				<MenuItem key="LETTER" value="LETTER">Letter</MenuItem>
			</Select>
		</FormControl>

		<FormControl
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				paddingTop: '1.2em',
				marginRight: '1.3em',
				paddingLeft: '0.2em'
			}}
		>
			<Label disableAnimation shrink>
				Fields
			</Label>
			{selectorRows.map( (row, i) => (
				<div
					key={`row-${i}`}
					style={{
						display: "flex",
						flexDirection: "column"
					}}
				>
					{row.map( (selector, j) => (
						<FormControlLabel
							label={selector.label}
							key={`checklabel-${i}${j}`}
							control={
								<Checkbox
									checked={selector.value}
									name={`check-${i}${j}`}
									key={`check-${i}${j}`}
									onChange={(e)=>{
										setFields({...fields, [selector.key]: e.target.checked})
									}}
								/>
							}
						/>
					))}
				</div>
			))}
		</FormControl>
	</div>

}

export default ArtLabelModal
