import React, {useCallback, useEffect, useContext} from 'react'
import { GET_USER } from '../User/Queries'
import { DispatchContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { CircularProgress } from '@mui/material'
import MockLogin from './MockLogin'
import { fetchWithAuth } from '../common/helpers'
import googleIcon from './g-logo.png'
import Login from './Login'
import { useNavigate } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'

export default function AzureRedirect (props) {
    
	const params = new window.URL(window.location.toString()).searchParams
	const dispatch = useContext(DispatchContext)
	const navigate = useNavigate()
	const client = useApolloClient()

	const runAuth = useCallback(
		async (prom) => {
			return prom
				.then( resp => resp.json() )
				.then( ({access_token}) => {
					window.token = access_token
					window.token_exp = Date.now() + 3600000
				} )
				.then(() => client.query({ query: GET_USER }))
				.then(({ data: { getUser } }) => {
					console.info('User located.')
					dispatch({
						type: 'login',
						payload: getUser
					})
					return getUser
				})
				.then((user) => {
					console.info('User state stored locally.')
					if (user.web_app_showcase_mode) {
						navigate('/showcase/artists')
					} else {
						navigate('/home')
					}
				})
				.catch(error => {
					console.error(error)
					dispatch({ type: 'openSnackBar', payload: {
						severity: severity.ERROR,
						text: 'Authentication failed.'
					}})
					dispatch({
						type: 'logout'
					})
					window.location.href = window.SERVER_DATA.AD_LOGOUT_URL
				})
		}, [dispatch, client, navigate]
	)

	const runAdminAuth = useCallback( 
		async (prom) => {
			return prom
				.then( () => client.query({ query: GET_USER }) )
				.then(( { data: {getUser} } ) => {
					console.info('user located')
					return dispatch({
						type: 'login',
						payload: getUser
					})
				})
				.then( () => {
					console.info('User state stored')
					navigate('/admin')
				} )
				.catch(error => {
					console.error(error)
					dispatch({type: 'openSnackBar', payload: {
						severity: severity.ERROR,
						text: 'Authentication failed.'
					}})
					dispatch({ type: 'logout' })
				})
		}, 
		[dispatch, client, navigate])
	
	const login = (code) => {
		if (!code) { return null }
		if (!window.localStorage.idp){
			console.error('identity provider not found in localstorage.')
		}

		const url = new URL('/api/login', window.location.origin)
		url.searchParams.append('code', code)
		url.searchParams.append('idp', window.localStorage.getItem('idp'))

		window.SERVER_DATA.MOCKED = false
		window.token = null

		return runAuth(fetch(url))
	}

	const adminLogin = (code) => {
		if(!code) return null

		const url = new URL('/api/admin/login', window.location.origin)
		url.searchParams.append('code', code)
		url.searchParams.append('grant_type', 'authorization_code')
		url.searchParams.append('callbackUrl', `${window.location.origin}/admin/login`)
		url.searchParams.append('idp', window.localStorage.getItem('idp'))
		window.SERVER_DATA.MOCKED = false

		return runAdminAuth(fetchWithAuth(url)
			.then(()=>console.info("Authenticated")))
	}

	const logout = () => {
		const url = new URL('/api/logout', window.location.origin)
		return fetchWithAuth(url)
			.then( resp => resp.json())
			.then( data => {
				dispatch({type: 'logout'})
				const logoutUrl = window.SERVER_DATA.AUTH_URLS[data.idp].logout
				if(!window.SERVER_DATA?.MOCKED) {
					window.location.href = logoutUrl
				} else {
					window.location.href = ''
				}
			})
	}

	useEffect(() => {
		// route URL paths to handler functions
		const routes = {
			"/": () => login(params.get('code')),
			"/admin/login": () => adminLogin(params.get('code')),
			"/logout": () => logout()
		}
		routes[window.location.pathname]()

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [runAuth, runAdminAuth])

	const handleClick = (id, type) => {
		const pathnames = {
			"/": new URL('/api/login/mock', window.location.origin),
			"/admin/login": new URL('/api/login/adm-mock', window.location.origin)
		}
		const url = pathnames[window.location.pathname]
		url.searchParams.append('user_id', id)
		
		window.SERVER_DATA.MOCKED = true
		if (window.location.pathname === "/"){
			runAuth(fetch(url))
		} else {
			// user should be logged in to access the admin console.
			runAdminAuth(fetchWithAuth(url))
		}
	}

	const logins = [
		{
			name: "google",
			imgSrc: googleIcon,
			imgAltText: "Sign in With Google",
			loginUrls: window.SERVER_DATA?.AUTH_URLS?.google
		}
	]

	const loading =  params.has("code") || window.location.pathname === "/logout"
	if (window.SERVER_DATA.MOCK_LOGIN_ENABLED===true || window.SERVER_DATA.MOCK_LOGIN_ENABLED==="true") {
		if (loading) {
			return <div style={{
				display: 'flex',
				height: '90vh',
				padding: '40vh',
				justifyContent: 'center'
			}}>
				<CircularProgress />
			</div>
		} else {
			return <MockLogin logins={logins} handleClick={handleClick} />
		}
	} else {
		return <Login logins={logins} loading={loading}/>
	}
}
