import React from 'react'
import { gql } from '@apollo/client'
import ShowcaseGridView from '../ShowcaseGridView'

export const AVAILABLE_ART_SEARCH = gql`
	query searchDynamicArt($field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int) {
		searchDynamicArt(input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters }) {
			cursor
			totalItems
			items {
				id
				title
				artist {
					id
					first_name
					last_name
				}
				code_name
				imgUrl
				year
				reserves {
					id
					reserve_end_at
					reserve_position
					deal {
						id
						salesperson {
							id
							first_name
							last_name
						}
					}
				}
				status {
					id
					value
				}
				primary_currency_id
				primaryRetailPrice

				medium
				all_dimensions
			}
		}
	}
`

export const Available = (props: any) => {

	const filters =  [
		{ field: 'artist_id', type: 'eq', value: props.id, isOptional: false },
		{ field: 'art_status_value', type: 'eq', value: 'Available OR Reserved', isOptional: false },
	]

	return (
		<ShowcaseGridView
			filters={filters}
			query={AVAILABLE_ART_SEARCH}
			showReserve={true}
			state={props.state}
			page="Available"
			showcaseInformation={props.showcaseInformation}
			setShowcaseInformation={props.setShowcaseInformation}

			showcaseSelectMode={props.showcaseSelectMode}
			setShowcaseSelectMode={props.setShowcaseSelectMode}
		/>
	)
}

export default Available
