import React from 'react'
import { getContactName } from '../common/helpers'
import Thumbnail from './Thumbnail'

/**
 * @typedef ContactThumbnailProps
 * @property {React.CSSProperties | undefined} [style] 
 * @property {Object} contact
 * @property {boolean} [loading] If a loading is in progress
 * @property {boolean} [darkText]
 */

/**
 * @param {ContactThumbnailProps} props
 */
const ContactThumbnail = ({ contact, loading, darkText, style }) => {
	if (!contact) return <Thumbnail
		style={style}
		type="contact"
		animation={loading ? 'wave' : false}
		largeText
	/>

	const hidden = contact.is_private && !contact.first_name
	const getContactDetail = (contact) => {
		if (hidden) return null
		if (contact.is_company) return null
		return contact.company_name || null
	}

	const contactDetails = getContactDetail(contact)
	
	return (
		<Thumbnail
			style={style}
			name={getContactName(contact)}
			detail={contactDetails}
			avatar={!hidden ? contact.imgUrl : null}
			type="contact"
			isPrivate={hidden}
			hasAccess={!hidden}
			animation={loading ? 'wave' : false}
			darkText={darkText}
			largeText
			isCompany={contact.is_company}
			isDeceased={contact.is_deceased}
			hideDetail={!contactDetails}
		/>	
	)
}

export default ContactThumbnail
