/* eslint-disable eqeqeq */
import { Paper, Typography } from "@mui/material"
import { deal_entry_status_categories } from "../../DealEntry/constants"
import { GET_DEAL_ENTRIES_SHOWCASE_SIDE } from "../../DealEntry/Queries"
import { DYNAMIC_ART_SEARCH } from "../../Search/Queries"
import ContactThumbnail from "../../Thumbnail/ContactThumbnail"
import ShowcaseGridView from "../ShowcaseGridView"
import ShowcaseSelectChips from "../ShowcaseSelectChips"

function ContactArtHeading (props: any) {
	return <Typography variant='h5' sx={{
		marginTop: '1em',
		marginBottom: '1em',
		color: props.private ? 'white' : 'rgba(0, 0, 0, 0.6)'
	}}>{ props.children }</Typography>
}

export default function ShowcaseContactArt (props: any) {

	const input = (categoryId: number) => ({
		field: 'end_at',
		direction: 'ASC',
		cursor: 0,
		limit: 5,
		filters: {
			entity: 'contact',
			entityId: props.state.getContact?.id,
			dealEntryStatusCategoryId: categoryId,
		}
	})

	const { showcaseSelectMode, setShowcaseSelectMode } = props

	return <Paper
		className='qv-margin' 
		sx={{ 
			display: 'flex',
			flexDirection: 'column', 
			minHeight: 'calc(100% - 2em)', 
			height: 'fit-content' 
		}}
	>

		<div style={{
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		}}>

			{/* @ts-ignore */}
			<ContactThumbnail
				contact={props.state.getContact}
				darkText={!props.state?.getContact?.is_private}
			/>

			<ShowcaseSelectChips showcaseSelectMode={showcaseSelectMode} setShowcaseSelectMode={setShowcaseSelectMode}/>

		</div>
		<ContactArtHeading private={props.atPrivateObject}>
			Known Works
		</ContactArtHeading>
		<ShowcaseGridView
			limit={5}
			variables={{ 
				filters: [{
					field: "source_id.current_sources",
					isOptional: false,
					nestedQueryType: "Source",
					type: "eq",
					value: props.state.getContact?.id
				}]
			}}
			query={DYNAMIC_ART_SEARCH}
			showReserve={true}
			page="ShowcaseContact"
			getArt={(data) => data.searchDynamicArt}
			removeFrame
			height="inherit"
			showcaseInformation={props.showcaseInformation}
			setShowcaseInformation={props.setShowcaseInformation}
			hideCreation
			showcaseSelectMode={showcaseSelectMode} 
			setShowcaseSelectMode={setShowcaseSelectMode}
			loading={props.state.getContact?.id == null}
		/>

		<ContactArtHeading private={props.atPrivateObject}>
			Purchases
		</ContactArtHeading>
		<ShowcaseGridView
			limit={5}
			query={GET_DEAL_ENTRIES_SHOWCASE_SIDE}
			showReserve={true}
			page="ShowcaseContact"
			getArt={(results) => ({
				...(results?.getDealEntries),
				items: results?.getDealEntries?.items?.map((de: any)=> de.art)
			})}
			removeFrame
			height="inherit"
			variables={{ 
				GetDealEntriesRequest: input(deal_entry_status_categories.purchases)
			}}
			showcaseInformation={props.showcaseInformation}
			setShowcaseInformation={props.setShowcaseInformation}
			hideCreation
			showcaseSelectMode={showcaseSelectMode} 
			setShowcaseSelectMode={setShowcaseSelectMode}
			loading={props.state.getContact?.id == null}
		/>

		<ContactArtHeading private={props.atPrivateObject}>
			Consigned In
		</ContactArtHeading>
		<ShowcaseGridView
			limit={5}
			query={GET_DEAL_ENTRIES_SHOWCASE_SIDE}
			showReserve={true}
			page="ShowcaseContact"
			getArt={(results) => ({
				...(results?.getDealEntries),
				items: results?.getDealEntries?.items?.map((de: any)=> de.art)
			})}
			removeFrame
			height="inherit"
			variables={{ 
				GetDealEntriesRequest: input(deal_entry_status_categories.consignment_in)
			}}
			showcaseInformation={props.showcaseInformation}
			setShowcaseInformation={props.setShowcaseInformation}
			hideCreation
			showcaseSelectMode={showcaseSelectMode} 
			setShowcaseSelectMode={setShowcaseSelectMode}
			loading={props.state.getContact?.id == null}
		/>

		<ContactArtHeading private={props.atPrivateObject}>
			Loaned In
		</ContactArtHeading>
		<ShowcaseGridView
			limit={5}
			query={GET_DEAL_ENTRIES_SHOWCASE_SIDE}
			showReserve={true}
			page="ShowcaseContact"
			getArt={(results) => ({
				...(results?.getDealEntries),
				items: results?.getDealEntries?.items?.map((de: any)=> de.art)
			})}
			removeFrame
			height="inherit"
			variables={{ 
				GetDealEntriesRequest: input(deal_entry_status_categories.loan_in)
			}}
			showcaseInformation={props.showcaseInformation}
			setShowcaseInformation={props.setShowcaseInformation}
			hideCreation
			showcaseSelectMode={showcaseSelectMode} 
			setShowcaseSelectMode={setShowcaseSelectMode}
			loading={props.state.getContact?.id == null}
		/>
	</Paper>
}
