import { FormControl, MenuItem, Select } from '@mui/material'
import Dropdown from '../../input/Dropdown/ThinDropdown'
import Label from '../../input/Label'
import React, { useContext } from "react"
import TextBox from '../../input/Text/TextBoxThin'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import {SubmitButton, CancelButton} from '../../input/Buttons'
import { isPrimary, isValidPhone } from '../../common/helpers'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useEffect } from 'react'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { useMutation } from '@apollo/client'
import { CREATE_PHONE } from '../Contact/Queries'

export default function NewPhoneModal(props) {

	const [validPhone, setValidPhone] = React.useState(false)
	const [attempt, setAttempt] = React.useState(false)

	useEffect(() => {
		setValidPhone(isValidPhone(props.newPhoneModal?.phone?.number))
	}, [props.newPhoneModal])

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}

	const [createPhone] = useMutation(CREATE_PHONE, {
		onError: (error) => {
			openSnackbar(severity.ERROR, error.createPhone.message)
		},
		onCompleted: (data) => {
			if (data?.createPhone?.success) {
				openSnackbar(severity.SUCCESS, data.createPhone.message)
				props.setContact({
					...props.contact,
					phoneCount: props.contact.phoneCount + 1
				})
			} else {
				openSnackbar(severity.ERROR, "Could not create phone.")
			}
		}
	})

	return (
		<TransitionsModal
			className="new-phone-modal"
			open={props.newPhoneModal?.open}
			close={() =>
				props.setNewPhoneModal({ ...props.newPhoneModal, open: false })
			}
		>
			<form
				className={props.isPrivate ? "private-object modal-small-content" : "modal-small-content"}
				onSubmit={e => {
					e.preventDefault()
					e.stopPropagation()
					setAttempt(true)

					if (attempt && !validPhone) {
						openSnackbar(severity.WARNING, "Please fill out the fields in red.")
					} else if (validPhone) {

						let newPhone = {
							label: props.newPhoneModal.phone.label,
							type_id: parseInt(
								props.newPhoneModal.phone.type_id
							),
							number: props.newPhoneModal.phone.number,
							type:
								props.phoneTypes[
									parseInt(
										props.newPhoneModal.phone.type_id
									) - 1
								].type,
							contact_id: props.contactInput.id,
							is_primary: isPrimary(props, "phone"),
							extension: props.newPhoneModal.phone.extension,
						}

						if (!props.hasContactInfoPermission && props.onContactDetailsPage) {

							createPhone({
								variables: {
									PhoneInput: newPhone
								}
							})

						} else {
							props.addToContactInput('phone', newPhone)
						}

						props.setNewPhoneModal({
							...props.newPhoneModal,
							open: false
						})

						setAttempt(false)
					}
				}}
			>
				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Label
					</Label>
					<TextBox
						autoFocus
						placeholder="-"
						onChange={e => {
							props.setNewPhoneModal({
								...props.newPhoneModal,
								phone: {
									...props.newPhoneModal.phone,
									label: e.target.value
								}
							})
						}}
						data-testid="phone-title"
					/>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Type*
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						
						input={<Dropdown />}
						className="padded-select"
						value={props.newPhoneModal?.phone?.type_id}
						onChange={e => {
							props.setNewPhoneModal({
								...props.newPhoneModal,
								phone: {
									...props.newPhoneModal.phone,
									type_id: e.target.value
								}
							})
						}}
						data-testid="phone-type"
					>
						{props.phoneTypes && props.phoneTypes.map(x => (
							<MenuItem key={x.id} value={x.id} data-testid="phone-type-item">
								{x.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<div style={{display: "flex"}}>
					
					<div className={attempt && !validPhone ? "error" : null} data-testid="phone-input" style={{
						marginRight: '1em',
						marginTop: '-12px'
					}}>

						<Label disableAnimation shrink style={{marginBottom: '-12px', marginTop: 5, marginLeft: 2}}>
							New Phone*
						</Label>
						<PhoneInput
							disableSearchIcon
							enableSearch
							dropdownStyle={{borderRadius: "4px"}}
							inputStyle={{width: "100%", fontFamily: "graphik", height: '37px'}}
							value={props.newPhoneModal?.phone?.number}
							placeholder=""
							onChange={(value, data, event) => {
								props.setNewPhoneModal({
									...props.newPhoneModal,
									phone: {
										...props.newPhoneModal.phone,
										number: value.replace(/[^0-9]+/g,'')

									}
								})
							}}
							
						/>

					</div>

					<FormControl style={{ width: '30%' }}>
						<Label disableAnimation shrink>
							Extension
						</Label>
						<TextBox
							value={props.newPhoneModal?.phone?.extension || ''}
							onChange={e => {
								props.setNewPhoneModal({
									...props.newPhoneModal,
									phone: {
										...props.newPhoneModal.phone,
										extension: e.target.value
									}
								})
							}}
							data-testid="phone-extension"
						/>
					</FormControl>

				</div>
				
				{props.contactInput ? 
					<>
						<CancelButton
							variant="contained"
							style={{ float: 'left', marginTop: '1em' }}
							onClick={e => {
								e.preventDefault()
								props.setNewPhoneModal({
									...props.newPhoneModal,
									open: false
								})
								setAttempt(false)
							}}
							data-testid="phone-cancel"
						>
						Cancel
						</CancelButton>

						<SubmitButton
							style={{ float: 'right', marginTop: '1em' }}
							type="submit"
							variant="contained"
							data-testid="phone-submit"
						>
							Submit
						</SubmitButton>
					</>
					:
					null
				}

			</form>
		</TransitionsModal>
	)
}
