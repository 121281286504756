import { useQuery } from "@apollo/client"
import { Paper } from "@mui/material"
import { GET_ART_PROJECT_CODE_HISTORY } from "../../Queries"
import { DataGridPro } from "@mui/x-data-grid-pro"
import { formatDate } from "../../../common/helpers"
import UserAvatar from "../../../common/components/UserAvatar"
import { useCallback, useContext, useState } from "react"
import { DispatchContext } from "../../../store"
import { severity } from "../../../Snackbar/CustomizedSnackbar"

const columns = [
	{ 
		field: 'project_code:project_code',
		headerName: 'Project Code',
		valueGetter: r => r?.row?.project_code?.project_code,
		flex: 1,
	}, {
		field: 'modified_at',
		headerName: 'Date',
		renderCell: params => {
			const fDate = params.row?.modified_at ? 
				formatDate(params.row.modified_at, "MMMM do, yyyy  h':'mma O") : '-'
			return <span>{ fDate }</span>
		},
		flex: 1,
	}, {
		field: 'modified_by',
		headerName: 'User',
		renderCell: params => <UserAvatar sp={params?.row?.modified_user} />,
		width: 75
	}
]

export default function ProjectCodeHistory(props) {

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	
	const limit = 3
	const [sortModel, setSortModel] = useState([])
	const [page, setPage] = useState(0)

	const { data, previousData, loading, error } = useQuery(GET_ART_PROJECT_CODE_HISTORY, {
		skip:  !props.art?.id,
		variables: {
			input: {
				field: sortModel?.[0]?.field || 'modified_at',
				direction: sortModel?.[0]?.sort?.toUpperCase(),
				cursor: page * limit,
				limit,
				artId: props?.art.id
			}
		}
	})
	if (error) {
		openSnackbar(severity.ERROR, error.message || 'An error occured loading Project Code history.')
		console.error(error)
	}

	const rowCount = data?.getArtProjectCodeHistory?.totalItems ||
		previousData?.getArtProjectCodeHistory?.totalItems || 0

	const rows = data?.getArtProjectCodeHistory?.items ||
		previousData?.getArtProjectCodeHistory?.items || []

	return <Paper
		className="qv-margin"
		sx={{
			display: 'flex',
			flexDirection: 'column'
		}}
		id="operation-details"
		data-testid="card-details"
	>
		<h1 className="card-title">
			<span>Project Code History</span>
		</h1>

		<DataGridPro
			columns={columns}
			rows={rows}
			loading={!props.art?.id || loading}
			rowCount={rowCount}
			pagination
			paginationMode="server"
			sortingMode="server"
			pageSize={limit}
			page={page}
			onPageChange={setPage}
			sortModel={sortModel}
			onSortModelChange={setSortModel}
			disableColumnFilter={true}
		/>
	</Paper>
}
