import { AppBar, Box, Tab, Tabs } from '@mui/material'
import React, { useEffect } from 'react'
import QuickView from '../QuickView/QuickView'
import PropTypes from 'prop-types'
import SavedSearchList from './SavedSearchList'
import { withApollo } from '@apollo/client/react/hoc'
import BulkActionQuickView from './BulkActionQuickView'
import { useRecoilValue } from 'recoil'
import { quickViewAtom } from '../navigation/Tabs/TabbedPage'

export const SAVED_SEARCHES = 0
export const SELECTED = 1
function TabPanel(props) {

	const { children, value, index, ...other } = props
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box m={1} sx={{margin: 0}}>
					{children}
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

const SearchQuickView = (props) => {

	const QVOverride = useRecoilValue(quickViewAtom)

	// Get initial flag count
	useEffect(() => {
		let isActive = true

		if (!props.disableFlagCount && props.flaggedCount === null)
			props.client
				.query({
					query: props.flagQuery,
					variables: props.makeVariables(0, props.currentSearch, props.orderBy, props.order, [{
						field: "isFlagged",
						type: "eq",
						value: "true"
					}])
				})
				.then(({data}) => {

					if(isActive){

						props.setFlaggedCount(
							data.searchDynamicArt?.totalItems ||
							data.searchDynamicArtists?.totalItems ||
							data.searchDynamicContacts?.totalItems ||
							data.searchDynamicDeals?.totalItems ||
							data.searchDynamicListings?.totalItems ||
							data.searchDynamicTasks?.totalItems || 0
						)
					}
				})

		return () => {
			isActive = false
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.client])


	const { value, setValue } = props
	// Tab Value
	const handleChange = (_, newValue) => {
		setValue(newValue)
	}

	const InnerSavedSearchList = (newProps) => (
		<SavedSearchList
			setFilters={props.setFilters}
			savedSearches={props.savedSearches}
			setSavedSearches={props.setSavedSearches}
			setCurrentSearch={props.setCurrentSearch}
			currentSearch={props.currentSearch}
			handleSubmit={props.handleSubmit}
			setAdvancedSearch={props.setAdvancedSearch}
			updateLabel={props.updateLabel}
			handleDeleteSearch={props.handleDeleteSearch}
			setSelection={props.setSelection}
			setDealAutoValue={props.setDealAutoValue}
			entity={props.entity}
			setSelectedSearch={props.setSelectedSearch}
			filterFields={props.filterFields}
			disableFavoriteSavedSearch={props.disableFavoriteSavedSearch}
			{...newProps}
		/>
	)

	return (
		<>
			{QVOverride}
			<QuickView
				style={{
					overflowY: "auto",
					display: QVOverride ? "none" : "block",
				}}
			>
				{props.disableBulkActions ? (
					<>
						<AppBar position='static' elevation={1} sx={{
							height: 48,
							display: 'flex',
							justifyContent: 'center',
							textAlign: 'center',
							fontWeight: 500
						}}>
							Saved Searches
						</AppBar>

						<InnerSavedSearchList />
					</>
				) : (
					<>
						<AppBar position='static' elevation={1}>
							<Tabs variant='fullWidth' id='search-tabs' value={value} onChange={handleChange}>
								<Tab label='Saved Searches' />
								<Tab label='Selected' />
							</Tabs>
						</AppBar>

						<TabPanel value={value} index={0} className='full-height-tabpanel'>
							<InnerSavedSearchList />
						</TabPanel>

						<TabPanel value={value} index={1} className='full-height-tabpanel'>
							<BulkActionQuickView
								totalItems={props.totalItems}
								flaggedCount={props.flaggedCount}
								entityType={props.entity}
								requery={() => props.requery?.()}
								bulkActionQuery={props.bulkActionQuery}
								currentSearch={props.currentSearch}
								makeVariables={props.makeVariables}
							/>
						</TabPanel>
					</>
				)}
			</QuickView>
		</>
	)
}

export default withApollo(SearchQuickView)

