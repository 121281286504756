/* eslint semi: 0 */
/* eslint-disable eqeqeq */
import { withApollo } from '@apollo/client/react/hoc';
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';
import { useCallback, useContext } from 'react';
import { GET_IMPORT_ERROR_LINK } from "../AdminConsole/Queries"
import { severity } from '../Snackbar/CustomizedSnackbar';
import { DispatchContext } from '../store';

function ErrorHeader(props) {
	return <><h3 className="paragraph-2" style={{ paddingBottom: '0' }}>Errors</h3></>
}

function ImportQuickViewError(props) {

	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	// Success but the QA may have failed
	if (props.import.status.value == 'Completed' &&
		(props.import.s3_runtime_error_file != null
			|| props.import.inserted_row == 0)
	) {
		return <>
			<ErrorHeader></ErrorHeader>
			<div className={'shadow-group-light'}>
				<div style={{ padding: "1em" }}>
					<div style={{
						whiteSpace: "pre-line",
						maxHeight: "15em",
						overflow: "auto",
					}}>
						There was an error collecting the data above, it may be inaccurate.<br /><br /><b>Don't re-upload this import.</b>
					</div>
				</div>
			</div>
		</>
	}
	// Invalid import
	else if (props.import.status.value == 'Invalid') {
		return <>
			<ErrorHeader></ErrorHeader>

			<Button
				className={'shadow-group-light'}
				endIcon={<DownloadIcon />}
				onClick={(e) => {
					console.log("DOWNLOAD")

					props.importEndpoint.query({
						query: GET_IMPORT_ERROR_LINK,
						variables: {
							importId: props.import.id
						}
					}).then((response) => {
						// check if null, no error file
						const link = response.data.getImportErrorLink

						if(link) {
							window.open(link, '_blank');
						}
						else{
							openSnackbar(severity.ERROR, "No error file found.")
						}

					}).catch((error) => {
						console.error(error)
						openSnackbar(severity.ERROR, "Could not download file.")
					})
				}}
			>
				Download Error File
			</Button>
		</>
	}
	// Failed import
	else if (props.import.status.value == "Failed") {
		return <>
			<ErrorHeader></ErrorHeader>
			<div className={'shadow-group-light'}>
				<div style={{ padding: "1em" }}>
					<div style={{
						whiteSpace: "pre-line",
						maxHeight: "15em",
						overflow: "auto",
					}}>
						A fatal error occured.<br /><br /><b>Don't re-upload this import</b> until a system administrator has solved the problem.
					</div>
				</div>
			</div>

		</>
	}
	// Return no error card for all other situations
	else {
		return <></>
	}
}

export default withApollo(ImportQuickViewError)
