/* eslint-disable eqeqeq */
import { Box } from '@mui/system'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import { Close } from '@mui/icons-material'
import {
	Button, Divider, FormControl, FormGroup, IconButton, Skeleton, Typography 
} from '@mui/material'
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported'
import QuillText from '../../input/QuillText'
import { CancelButton, ChooseImageButton, NewButton, SubmitButton } from '../../input/Buttons'
import React, { useCallback, useContext, useEffect } from 'react'
import { LookupContext } from '../../store'
import { formatDate, formatter, getArtStatusChip, getContactName, isValidEmail } from '../../common/helpers'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import { artStatusesConstants, dealTypes } from '../../constants/values'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { GET_DOWNLOAD_LINK, GET_PREVIEW_LINK } from '../../Files/Queries'
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import ReactImageGallery from 'react-image-gallery'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import DownloadIcon from '@mui/icons-material/Download'
import { GET_IMAGE_DOWNLOAD_LINK } from '../../input/Image/Queries'
import SingleContact from '../../input/SingleContact'
import Label from '../../input/Label'
import { OfferPrice } from '../../Deals/Deal/OfferPrice'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { MobileDateTimePicker } from '@mui/x-date-pickers-pro'
import TextBox from '../../input/Text/TextBox'
import { addDays } from 'date-fns'
import { fileData, imageData, userDataCompressed } from '../../common/fragments'
import { deal_entry_status_categories } from '../../DealEntry/constants'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import Source from '../../Art/ArtPiece/Source'
import SourceLabel from '../../Art/ArtPiece/SourceLabel'

const ART_FAIR_MODE = false

export const RETURNED_ART_STATUS = 5

export const GET_CURRENT_ARTWORK = gql`
	query getArtPiece($id: ID!, $thumbnailResolution: String = "128x128", $dealEntryStatusCategoryId: ID) {
		getArtPiece(id: $id) {
			code
			success
			message
			severity
			art {
				id
				status_id
				imgUrl(thumbnailResolution: "1024x1024")
				images {
					...imageData
				}
				artist {
					id
					first_name
					last_name
				}
				title
				formatted_title
				year
				medium
				all_dimensions
				signature
				primary_currency_id
				primaryRetailPrice
				allRetailPrices {
					id
					currency_id
					value
				}
				canViewOwnerContact
				current_sources {
					id
					code_name
					first_name
					last_name
					is_company
					company_name
					is_deleted
					is_private
					imgUrl (thumbnailResolution: $thumbnailResolution)
					canNavigate
					isAllowedContactType
					dynamic_generated_codename
					created_at
					deal_entry_status_category
					deal_entry_status
					contact_role_id
				}
				one_email_files {
					...fileData
				}
				reserves {
					id
					reserve_end_at
					reserve_position
					deal {
						id
						salesperson {
							id
							first_name
							last_name
						}
					}
				}
				latest_deal_entry (dealEntryStatusCategoryId: $dealEntryStatusCategoryId) {
					id
					start_at
					end_at
					offer_amount
					offer_currency {
						id
						label
					}
					deal_id
					deal {
						id
						salesperson {
							...userDataCompressed
							is_lead
							is_deleted
						}
						contacts(primaryOnly: true) {
							id
							code_name
							first_name
							last_name
							is_company
							company_name
							is_deleted
							is_private
							imgUrl (thumbnailResolution: $thumbnailResolution)
							is_deceased
							dynamic_generated_codename
						}
					}
				}

				medium
				all_dimensions
			}
		}
	}
	${imageData}
	${fileData}
	${userDataCompressed}
`

/**
 * Checks for validity of contacts to be inserted alongside of a newly created offer or reserve.
 */
const isContactInvalid = (entity: any) => {

	if (!entity.new_contact) return !entity.id || !entity.contact_role_id

	else return (!entity.first_name?.length || !entity.last_name?.length || !isValidEmail(entity.email))
}


export default function ShowcaseArtModal(props: any) {
	const { artModal, setArtModal, type, artList, setArtList } = props

	const lookup: any = useContext(LookupContext)
	const artStatus: any = lookup.data?.getArtStatuses
	const currencies = lookup.data?.getCurrencies

	const [activeStep, setActiveStep] = React.useState(0)
	const [modalType, setModalType] = React.useState('Offer')

	const [currentPdf, setCurrentPdf] = React.useState<any>()

	const client = useApolloClient()

	const [imageGalleryRef, setImageGalleryRef] = React.useState(null)

	const [attempt, setAttempt] = React.useState(false)

	const [art, setArt] = React.useState<any>(null)

	const getDealEntryStatusCategory = (type: string) => {

		try {

			if (type === "Available") {
				return deal_entry_status_categories.consignment_in
			}
	
			if (type === "PastSales") {
				return deal_entry_status_categories.purchases
			}
	
			if (type === "LoanedReturned") {
	
				if (artList.find((e: any) => e.id === artModal)?.status?.id == RETURNED_ART_STATUS)
					return deal_entry_status_categories.purchases
	
				return deal_entry_status_categories.loan_in
			}
	
			if (type === "ShowcaseArtFair") {
				return deal_entry_status_categories.purchases
			}

		} catch (error) {
			console.error("Art doesn't have a status: ", error)
			return null
		}
	}

	const { loading, data, error } = useQuery(GET_CURRENT_ARTWORK, {
		skip: (artModal == null) ,
		variables: {
			id: artModal,
			dealEntryStatusCategoryId: getDealEntryStatusCategory(type)
		},
	})

	const isLoading = loading || !Object.keys(art || {}).length

	useEffect(() => {
		if (data) {
			if (data.getArtPiece?.success) {
				setArt(data.getArtPiece.art)
			} else openSnackbar(severity.ERROR, data.getArtPiece?.message || 'Could not retrieve art piece.')
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity: severity, text: string) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const CREATE_OFFERED_DEAL = gql`
		mutation createOfferedDeal($ShowcaseDealInput: ShowcaseDealInput!) {
			createOfferedDeal(input: $ShowcaseDealInput) {
				code
				success
				message
				deal {
					id
					deal_entry {
						art {
							id
							title
							artist {
								id
								first_name
								last_name
							}
							code_name
							imgUrl
							year
							reserves {
								id
								reserve_end_at
								reserve_position
								deal {
									id
									salesperson {
										id
										first_name
										last_name
									}
								}
							}
							status {
								id
								value
							}
							primary_currency_id
							primaryRetailPrice
							allRetailPrices {
								id
								currency_id
								value
							}

							current_sources {
								id
								code_name
								first_name
								last_name
								is_company
								company_name
								is_deleted
								is_private
								imgUrl (thumbnailResolution: "128x128")
								canNavigate
								isAllowedContactType
								dynamic_generated_codename
								created_at
								deal_entry_status_category
								deal_entry_status
								contact_role_id
							}

							medium
							all_dimensions
						}
					}
				}
			}
		}
	`

	const [createOfferedDeal, { loading: offerMutationCalled }] = useMutation(CREATE_OFFERED_DEAL)

	const CREATE_RESERVED_DEAL = gql`
		mutation createReservedDeal($ShowcaseDealInput: ShowcaseDealInput!) {
			createReservedDeal(input: $ShowcaseDealInput) {
				code
				success
				message
				deal {
					id
					deal_entry {
						art {
							id
							title
							artist {
								id
								first_name
								last_name
							}
							code_name
							imgUrl
							year
							reserves {
								id
								reserve_end_at
								reserve_position
								deal {
									id
									salesperson {
										id
										first_name
										last_name
									}
								}
							}
							status {
								id
								value
							}
							primary_currency_id
							primaryRetailPrice
							allRetailPrices {
								id
								currency_id
								value
							}

							current_sources {
								id
								code_name
								first_name
								last_name
								is_company
								company_name
								is_deleted
								is_private
								imgUrl (thumbnailResolution: "128x128")
								canNavigate
								isAllowedContactType
								dynamic_generated_codename
								created_at
								deal_entry_status_category
								deal_entry_status
								contact_role_id
							}

							medium
							all_dimensions
						}
					}
				}
			}
		}
	`

	const [createReservedDeal, {loading: reserveMutationCalled }] = useMutation(CREATE_RESERVED_DEAL)

	const defaultShowcaseInput = () => ({
		deal: {
			type_id: dealTypes.offer,
		},

		deal_entry: [{
			art_id: art?.id,
			start_at: null,
			offer_currency: null,
			offer_amount: null,
			reserve_end_at: null, // reserve
			reserve_begin_at: null, // reserve
		}],

		contacts: [
			{
				id: 0,
				contact_role_id: 1,
				new_contact: false
			},
		],
	})

	const [showcaseInput, setShowcaseInput] = React.useState<any>(defaultShowcaseInput())
	const resetShowcaseInput = () => setShowcaseInput(defaultShowcaseInput())

	const closeModal = () => {
		setArtModal(null)
		setArt(null)
		setActiveStep(0)
		resetShowcaseInput()
		setAttempt(false)
	}

	useEffect(() => {
		const handleEsc = (event: any) => {
			if (event.keyCode === 27 && activeStep == 1) {
				setActiveStep(0)
				resetShowcaseInput()
				setAttempt(false)
			}

			if (event.keyCode === 27 && activeStep == 0) {
				closeModal()
			}
		}
		window.addEventListener('keydown', handleEsc)

		return () => {
			window.removeEventListener('keydown', handleEsc)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeStep])

	useEffect(() => {
		if (art?.id) {
			setShowcaseInput((oldShowcaseInput: any) => ({
				...oldShowcaseInput,
				deal_entry: [{
					...oldShowcaseInput.deal_entry[0],
					art_id: art.id,
					offer_currency: art?.primary_currency_id || null,
					offer_amount: art?.primaryRetailPrice || 0,
				}],
			}))
		}
	}, [art])

	const mobileDatePickerProps = {
		name: 'date',
		cancelText: null,
		todayLabel: 'Now',
		inputVariant: 'outlined',
		style: {
			marginTop: '1em',
		},
		variant: 'dialog',
	}

	const hasReserve = !!art?.reserves?.length

	// Only some statuses show the create buttons
	const isValidStatus = (status: any) => {
		if (status == artStatusesConstants.sold) return false
		if (status == artStatusesConstants.soldTBS) return false
		if (status == artStatusesConstants.nfs) return false
		if (status == artStatusesConstants.nfsTBS) return false

		return true
	}

	const latestOwner = art?.latest_deal_entry?.deal?.contacts?.[0]
	/**
	 * 
	 * @param type - the type/page the art modal is on
	 */
	const getExtraInformation = (type: string) => {

		const startAndEndAt = () => {

			if (Object.keys(art?.latest_deal_entry || {}).length && art?.latest_deal_entry) {
				return (
					<>
						<div>Start at: {formatDate(art.latest_deal_entry.start_at, "MMM do yyyy")}</div>
						<div>Sale Date: {formatDate(art.latest_deal_entry.end_at, "MMM do yyyy")}</div>
						<div>Gallery Contacts: {art.latest_deal_entry.deal?.salesperson?.map((e: any) => `${e.first_name} ${e.last_name}`).join(", ")}</div>
					</>
				)
				
			}

			return <span>None Found</span>
		}


		if (type === "Available") {
			return (
				<div
					style={{
						paddingLeft: '0.75em',
						marginTop: '1em',
					}}
				>
					<p style={{ marginBottom: '0.5em'}}>Latest Consignment Detail:</p>

					{startAndEndAt()}
				</div>
			)
		}

		if (type === "PastSales") {
			return (
				<div
					style={{
						paddingLeft: '0.75em',
						marginTop: '2em',
					}}
				>
					<p style={{ marginBottom: '0.5em'}}>Latest Purchases:</p>
					
					{Object.keys(art?.latest_deal_entry || {}).length && art?.latest_deal_entry ? 
						<>
							<div>Sold to: { getContactName(latestOwner) }</div>
							<div>Price: {art.latest_deal_entry?.offer_amount ? formatter(currencies?.find((e: any) => e.id == art.latest_deal_entry?.offer_currency?.id)?.label || 'USD').format(art.latest_deal_entry?.offer_amount) : '-'}</div>
							<div>Sale Date: {formatDate(art.latest_deal_entry.end_at, "MMM do yyyy")}</div>
							<div>Gallery Contacts: {art.latest_deal_entry.deal?.salesperson?.map((e: any) => `${e.first_name} ${e.last_name}`).join(", ")}</div>
						</> : <span>None Found</span>}
				</div>
			)
		}

		if (type === "LoanedReturned") {

			if (art?.status_id == artStatusesConstants.returned)
				return (
					<div
						style={{
							paddingLeft: '0.75em',
							marginTop: '1em',
						}}
					>
						<p style={{ marginBottom: '0.5em'}}>Latest Consignment Deal Details:</p>

						{startAndEndAt()}
					</div>
				)

			return (
				<div
					style={{
						paddingLeft: '0.75em',
						marginTop: '1em',
					}}
				>
					<p style={{ marginBottom: '0.5em'}}>Latest Loan Deal Details:</p>

					{startAndEndAt()}
				</div>
			)
		}

		if (type === "ShowcaseArtFair") {

			return (
				<div
					style={{
						paddingLeft: '0.75em',
						marginTop: '1em',
					}}
				>
					<p style={{ marginBottom: '0.5em'}}>Latest consignment Detail:</p>

					{startAndEndAt()}
				</div>
			)

		}
		return null
	}

	const inner = <>

		<OfferPrice
			selectStyles={{
				width: '6em',
			}}
			currency={showcaseInput.deal_entry[0]?.offer_currency}
			price={showcaseInput.deal_entry[0]?.offer_amount}
			onChange={(field: any, value: any) => {
				setShowcaseInput({
					...showcaseInput,
					deal_entry: [{
						...showcaseInput.deal_entry[0],
						[field]: value,
					}],
				})
			}}
			currencies={lookup.data?.getCurrencies}
		/>

		{showcaseInput.contacts?.map((contact: any, i: any) => {
			return (
				<div
					key={contact.new_contact ? `new-contact-${i}` : contact.id}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'end',
						}}
					>

						{contact.new_contact == false ? 														
							<FormControl
								style={{
									width: '100%',
								}}	
							>
								<Label disableAnimation shrink error={attempt && !contact.id}>
									{i === 0 ? 'Primary Contact' : 'Advisor'}
								</Label>

								<SingleContact
									error={attempt && !contact.id}
									elasticSearch='searchContacts'
									onChange={(event: any, value: any) => {
										const e = showcaseInput.contacts.find((c: any) => c === contact)
										Object.assign(e, value)
										setShowcaseInput({ ...showcaseInput })
									}}
									disabled={i != showcaseInput.contacts?.length - 1}
									disableClearable
									value={contact.created_at ? contact : ''}
									exclude={showcaseInput.contacts.map((c: any) => c.id)}
								/>

							</FormControl> 

							:

							<FormGroup
								style={{
									width: '100%',
								}}	
							>
								<div style={{
									display: 'flex',
									alignItems: 'flex-end',
								}}>
									<FormControl>
										<Label disableAnimation shrink error={attempt && !contact.first_name && !contact.last_name}>
											{i === 0 ? 'Primary Contact' : 'Advisor'}
										</Label>
																
										<TextBox
											required
											sx={{
												marginRight: '1em',
												marginTop: '2em !important'
											}}
											placeholder='First Name'
											error={attempt && !contact.first_name}
											onChange={(e) => {
												const { value } = e.target

												const contact = showcaseInput.contacts[i]
												Object.assign(contact, {
													...contact,
													first_name: value
												})
												setShowcaseInput({ ...showcaseInput })
											}}
										/>
									</FormControl>

									<TextBox
										required
										placeholder='Last Name'
										error={attempt && !contact.last_name}
										onChange={(e) => {

											const { value } = e.target

											const contact = showcaseInput.contacts[i]
											Object.assign(contact, {
												...contact,
												last_name: value
											})
											setShowcaseInput({ ...showcaseInput })
										}}
									/>

								</div>

							</FormGroup> 
						}

						{ ART_FAIR_MODE ? <IconButton
							onClick={(event) => {
								const e = showcaseInput.contacts.find((c: any) => c === contact)
								Object.assign(e, {
									...e,
									new_contact: !contact.new_contact,
								})
								setShowcaseInput({ ...showcaseInput })
							}}
							title='Create a new Contact'
							sx={{
								padding: '0.5em',
								marginLeft: '0.125em'
							}}
						>
							<PersonAddIcon color={showcaseInput.contacts.find((c: any) => c === contact).new_contact ? "info" : "secondary"}/>
						</IconButton> : null }

													

						{i !== 0 ? (
							<IconButton
								aria-label='cancel'
								color='primary'
								style={{
									padding: '12px'
								}}
								onClick={() => {
									showcaseInput.contacts = showcaseInput.contacts.filter((c: any) => c !== contact)
									setShowcaseInput({
										...showcaseInput,
									})
								}}
								size='large'
							>
								<CancelOutlinedIcon style={{ color: 'grey' }} />
							</IconButton>
						) : null}
					</div>

					{contact.new_contact === true ? 																	
						<FormControl className='fullWidth'>
							<Label disableAnimation shrink error={attempt && !isValidEmail(contact.email)}>
															Email
							</Label>
													
							<TextBox
								required
								error={attempt && !isValidEmail(contact.email)}
								sx={{
									marginTop: '2em !important'
								}}
								placeholder='Email'
								onChange={(e) => {
									const { value } = e.target

									const contact = showcaseInput.contacts[i]
									Object.assign(contact, {
										...contact,
										email: value
									})
									setShowcaseInput({ ...showcaseInput })
								}}
							/>
						</FormControl> : null}

				</div>
												
			)
		})}


		<ChooseImageButton
			variant='contained'
			style={{
				marginTop: '1em',
			}}
			disabled={!!isContactInvalid(showcaseInput.contacts[showcaseInput.contacts?.length - 1])}
			startIcon={<AddCircleOutlineIcon style={{ color: 'white' }} />}
			onClick={() => {
				showcaseInput.contacts.push({
					id: 0,
					contact_role_id: 2,
					new_contact: false
				})
				setShowcaseInput({
					...showcaseInput,
				})
			}}
		>
			<span className='ci-span'>Add Advisor</span>
		</ChooseImageButton>
	</>

	return (
		<TransitionsModal
			className='art-modal'
			open={!!artModal}
			close={() => {
				closeModal()
			}}
			noBackground
			disableEscapeKeyDown={true}
		>
			<div
				style={{
					maxWidth: '45em',
					minWidth: '45em',
				}}
				className='campton'
			>
				<Box
					style={{
						backgroundColor: 'white',
						padding: '1em 2em',
						borderRadius: 8,
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: props.hasViewDealPermission ? 'space-between' : 'end',
						}}
					>
						{props.hasViewDealPermission && activeStep == 0 && !props.hideCreation && isValidStatus(art?.status_id) && !isLoading ? (
							<div>
								<NewButton
									sx={{
										marginRight: '1em',
										width: '7em',
									}}
									onClick={() => {
										setActiveStep(1)
										setModalType('Offer')
									}}
									startIcon={<AddCircleOutlineIcon style={{ color: 'white' }} />}
								>
									Offer
								</NewButton>

								<NewButton
									sx={{
										width: '7em',
									}}
									onClick={() => {
										setActiveStep(1)
										setModalType('Reserve')
									}}
									startIcon={<AddCircleOutlineIcon style={{ color: 'white' }} />}
								>
									Reserve
								</NewButton>
							</div>
						) : null}

						{activeStep == 1 ? <h2 className='card-title'>Create {modalType}</h2> : null}

						{loading ? <div className="spacer"></div> : null}

						<IconButton
							size='large'
							onClick={() => {
								closeModal()
							}}
						>
							<Close />
						</IconButton>
					</Box>

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						{activeStep == 1 ? (
							<IconButton
								size='large'
								onClick={() => {
									setActiveStep(0)
									resetShowcaseInput()
									setAttempt(false)
								}}
							>
								<KeyboardArrowLeft />
							</IconButton>
						) : null}

						{/* Inner Scrollable Content */}
						<div
							style={{
								minHeight: '35em',
								maxHeight: '75vh',
								overflowY: 'auto',
								overflowX: 'visible',
								flexGrow: 2,
							}}
						>
							{activeStep == 0 ? (
								<>
									<Box sx={{ maxWidth: '45em', padding: '0.5em 0' }}>
										<Box sx={{ display: 'flex', overflow: 'auto', minHeight: '20em' }}>
											{art?.images?.length ? (
												<Box
													sx={{
														width: '100%',
													}}
													className='showcase-image-gallery'
												>
													{ /* @ts-ignore */ }
													<ReactImageGallery
														ref={(el) => {
															// @ts-ignore
															if (el) setImageGalleryRef(el)
														}}
														items={
															art?.images?.map((img: any) => {
																return {
																	original: img.imgUrl,
																	thumbnail: img.imgUrl,
																	originalAlt: img.filename,
																	thumbnailAlt: `${img.filename}-thumbnail`,
																}
															}) || []
														}
														infinite={false}
														showPlayButton={false}
														useTranslate3D={false}
														renderItem={(item) => {
															return (
																<div className='image-gallery-slide center'>
																	<img
																		className='image-gallery-image'
																		alt={item.originalAlt}
																		src={item.original}
																		style={{
																			cursor: 'pointer',
																		}}
																		onClick={() => {
																			// @ts-ignore
																			imageGalleryRef?.fullScreen()
																		}}
																	/>
																</div>
															)
														}}
														renderRightNav={(onClick, disabled) => (
															<IconButton
																onClick={onClick}
																disabled={disabled}
																style={{
																	right: 0,
																	top: '50%',
																	position: 'absolute',
																	zIndex: 1,
																	backgroundColor: 'white',
																	marginRight: '0.1em',
																}}
															>
																<ChevronRightIcon
																	sx={{
																		color: 'grey',
																	}}
																/>
															</IconButton>
														)}
														renderLeftNav={(onClick, disabled) => (
															<IconButton
																onClick={onClick}
																disabled={disabled}
																style={{
																	left: 0,
																	top: '50%',
																	position: 'absolute',
																	zIndex: 1,
																	backgroundColor: 'white',
																	marginLeft: '0.1em',
																}}
															>
																<ChevronLeftIcon
																	sx={{
																		color: 'grey',
																	}}
																/>
															</IconButton>
														)}
														renderFullscreenButton={(onClick, isFullscreen) => (
															<>
																<IconButton
																	title='Download Current Image'
																	onClick={() => {
																		try {
																			client
																				.query({
																					query: GET_IMAGE_DOWNLOAD_LINK,
																					variables: {
																						// @ts-ignore
																						id: art.images.find((_, i) => i === imageGalleryRef.getCurrentIndex()).id,
																					},
																				})
																				.then((result: any) => {
																					if (result.data.getImageDownloadLink) window.location.href = result.data.getImageDownloadLink
																					else openSnackbar(severity.ERROR, 'Error - Unauthorized')
																				})
																				.catch((error: any) => {
																					console.error(error)
																					openSnackbar(severity.ERROR, 'Could not download file.')
																				})
																		} catch (error) {
																			console.error(error)
																			openSnackbar(severity.ERROR, 'Could not download file.')
																		}
																	}}
																	style={{
																		left: 0,
																		bottom: 0,
																		position: 'absolute',
																		zIndex: 1,
																		backgroundColor: 'white',
																		marginLeft: '0.1em',
																	}}
																>
																	<DownloadIcon />
																</IconButton>

																<IconButton
																	title='Fullscreen View'
																	onClick={onClick}
																	style={{
																		right: 0,
																		bottom: 0,
																		position: 'absolute',
																		zIndex: 1,
																		backgroundColor: 'white',
																		marginRight: '0.1em',
																	}}
																>
																	{isFullscreen ? (
																		<FullscreenExitIcon
																			sx={{
																				color: 'grey',
																			}}
																		/>
																	) : (
																		<FullscreenIcon
																			sx={{
																				color: 'grey',
																			}}
																		/>
																	)}
																</IconButton>
															</>
														)}
													/>
												</Box>
											) : (
												<>
													{isLoading ? (
														<Skeleton variant='rectangular' width='100%' height='19em' animation='wave' />
													) : (
														<div
															style={{
																width: '100%',
																display: 'flex',
																flexDirection: 'column',
																alignItems: 'center',
																justifyContent: 'center',
															}}
														>
															<ImageNotSupportedIcon fontSize='large' style={{ color: 'grey', marginBottom: '0.25em' }} />
															No Images Found.
														</div>
													)}
												</>
											)}
										</Box>
									</Box>

									<div
										style={{
											marginLeft: '0.75em',
										}}
									>
										<Typography variant='h5' sx={{ paddingBottom: '0.5em', fontWeight: 600 }}>
											{isLoading ? <Skeleton animation={!error ? 'wave' : false} width='12em' /> : <span>{[art.artist?.first_name, art.artist?.last_name].filter((e) => e).join(' ')}</span>}
										</Typography>

										<Typography variant='h6' sx={{ fontWeight: '400' }}>
											{isLoading ? (
												<Skeleton animation={!error ? 'wave' : false} width='20em' />
											) : (
												<QuillText noWrap ellipsis name={art.id}>
													{typeof art?.formatted_title === 'object' ? art?.formatted_title || {} : JSON.parse(art?.formatted_title || {})}
												</QuillText>
											)}
										</Typography>

										<Typography variant='h6' sx={{ fontWeight: '400' }}>
											{isLoading ? <Skeleton animation={!error ? 'wave' : false} width='5em' /> : <span>{art.year}</span>}
										</Typography>

										<Typography variant='h6' sx={{ fontWeight: '400' }}>
											{isLoading ? <Skeleton animation={!error ? 'wave' : false} width='15em' /> : <span>{art.medium}</span>}
										</Typography>

										<Typography variant='h6' sx={{ fontWeight: '400' }}>
											{isLoading ? <Skeleton animation={!error ? 'wave' : false} width='20em' /> : <span>{art.all_dimensions}</span>}
										</Typography>

										<Typography variant='h6' sx={{ fontWeight: '400' }}>
											{isLoading ? (
												<Skeleton animation={!error ? 'wave' : false} width='15em' />
											) : (
												<QuillText noWrap ellipsis name={art.id}>
													{typeof art.signature === 'object' ? art.signature || {} : JSON.parse(art.signature || {})}
												</QuillText>
											)}
										</Typography>

										{props.showcaseInformation && art?.primaryRetailPrice ? (
											<Typography variant='h6' sx={{ fontWeight: '400' }}>
												{isLoading ? (
													<Skeleton animation={!error ? 'wave' : false} width='10em' />
												) : (
													<span>{formatter(currencies?.find((e: any) => e.id == art.primary_currency_id)?.label || 'USD').format(art.primaryRetailPrice)}</span>
												)}
											</Typography>
										) : null}

										{props.showcaseInformation ? (
											<Typography variant='h6' sx={{ fontWeight: '400' }}>

												{art?.allRetailPrices?.filter((e: any) => e.currency_id != art.primary_currency_id)?.map((retailPrice: any) => {

													return <span key={`currency-${retailPrice.id}`} style={{color: 'grey'}}>{formatter(currencies?.find((e: any) => e.id == retailPrice.currency_id)?.label || 'USD').format(retailPrice.value)}</span>
												})}
											</Typography>
										) : null}

										<Typography variant='h6' sx={{ fontWeight: '400' }}>
											{isLoading ? <Skeleton animation={!error ? 'wave' : false} width='5em' /> : <>{getArtStatusChip(art, false, artStatus)}</>}
										</Typography>

									</div>

									{art?.one_email_files?.length ? (
										<div style={{ marginTop: '1em' }}>
											{art?.one_email_files?.map((file: any) => {
												return (
													<div
														key={`file-${file.id}`}
														style={{
															display: 'flex',
															alignItems: 'center',
														}}
													>
														<IconButton
															title={`Download ${file.filetype}`}
															onClick={(e) => {
																client
																	.query({
																		query: GET_DOWNLOAD_LINK,
																		variables: {
																			// @ts-ignore
																			id: file.id,
																		},
																	})
																	.then((result: any) => {
																		if (result.data.getDownloadLink) window.location.href = result.data.getDownloadLink
																		else openSnackbar(severity.ERROR, 'Error - Unauthorized')
																	})
																	.catch((error: any) => {
																		console.error(error)
																		openSnackbar(severity.ERROR, 'Could not download file.')
																	})
															}}
														>
															<DownloadIcon />
														</IconButton>

														<Button
															title={`Preview ${file.filetype}`}
															variant='text'
															startIcon={<OpenInNewIcon />}
															onClick={() => {
																if (file.filetype === 'application/pdf') {
																	client
																		.query({
																			query: GET_PREVIEW_LINK,
																			variables: {
																				id: file.id,
																			},
																		})
																		.then((result) => {
																			if (result.data.getPreviewLink) setCurrentPdf({ ...file, src: result.data.getPreviewLink })
																			else openSnackbar(severity.ERROR, 'Error - Unauthorized')
																		})
																		.catch((error) => {
																			console.error(error)
																			openSnackbar(severity.ERROR, 'Could not preview file')
																		})
																} else {
																	client
																		.query({
																			query: GET_PREVIEW_LINK,
																			variables: {
																				id: file.id,
																			},
																		})
																		.then((result) => {
																			if (result.data.getPreviewLink) window.open(result.data.getPreviewLink, '_blank')
																			else openSnackbar(severity.ERROR, 'Error - Unauthorized')
																		})
																		.catch((error) => {
																			console.error(error)
																			openSnackbar(severity.ERROR, 'Could not preview file')
																		})
																}
															}}
														>
															{file.fileCategory.value} - {formatDate(file.modified_at, 'MMM do yyyy')}
														</Button>
													</div>
												)
											})}
										</div>
									) : null}

									{props.showcaseInformation && !loading ? (
										<>
											{art?.current_sources?.length ? <> 
											
												<div
													style={{
														paddingLeft: '0.75em',
														marginTop: '1em',
													}}
												>
													<SourceLabel 
														loading={false} 
														sources={art?.current_sources} 
													/>:
												</div>

												<div
													style={{
														paddingLeft: 'calc(0.75em - 8px)',
													}}
												>
													<Source
														loading={false} 
														sources={art?.current_sources} 
														thumbnailStyle={{
															margin: '8px auto 8px 8px',
														}}
														avatarGroupStyles={{
															marginLeft: '1em'
														}}
													/>
												</div>
											
											 </> : null}

											 {getExtraInformation(type)}

										</>
									) : null}

								</>
							) : (
								<>
									{modalType === 'Offer' ? (
										<>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													maxWidth: '30em',
													maxHeight: '75vh',
													margin: 'auto',
													paddingRight: 48,
													paddingTop: '1em',
												}}
											>
												{inner}

											</div>
										</>
									) : (
										<>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													maxWidth: '30em',
													maxHeight: '75vh',
													margin: 'auto',
													paddingRight: 48,
												}}
											>
												<div>
													{hasReserve ? (
														<>
															<p>Currently Reserved By:</p>

															<p style={{ width: '25em', wordWrap: 'break-word' }}>
																{art?.reserves?.[0].deal?.salesperson?.map((sp: any) => [sp.first_name, sp.last_name].filter((e) => e).join(' ')).join(', ')}
															</p>

															<p>Expires: {formatDate(art?.reserves?.[0]?.reserve_end_at, "MMMM do, yyyy  h':'mma O")}</p>
														</>
													) : null}

													<p>
														Your Reserve Queue Position: <br /> {art?.reserves?.length + 1}
													</p>
												</div>

												<Divider
													style={{
														margin: '1em 0',
													}}
												/>

												{inner}

												{!hasReserve ? (
													<FormControl style={{ marginTop: '1em' }}>
														<Label id='reserve-date-label' disableAnimation shrink>
															Reserve Expiration Date
														</Label>

														<MobileDateTimePicker
															{...mobileDatePickerProps}
															disablePast // Date Input on reserve Modal
															showToolbar
															componentsProps={{
																actionBar: {
																	actions: ['today']
																}
															}}
															inputFormat="MMMM do, yyyy  h':'mma O"
															disableMaskedInput={true}
															className='MUIDatePicker'
															value={showcaseInput.deal_entry[0].reserve_end_at ?? addDays(new Date(), 2)}
															renderInput={({ inputRef, inputProps, InputProps }) => {
																const newProps = { ...inputProps }

																newProps.readOnly = false

																// @ts-ignore
																return <TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
															}}
															onChange={(date) => {
																setShowcaseInput({
																	...showcaseInput,
																	deal_entry: [{
																		...showcaseInput.deal_entry[0],
																		reserve_begin_at: new Date(),
																		reserve_end_at: date,
																	}],
																})
															}}
														/>
													</FormControl>
												) : null}
											</div>
										</>
									)}
								</>
							)}
						</div>
					</div>

					{activeStep == 1 ? (
						<div
							style={{
								width: '100%',
								justifyContent: 'space-between',
								display: 'flex',
							}}
						>
							<CancelButton
								variant='contained'
								style={{ float: 'left' }}
								onClick={() => {
									closeModal()
								}}
							>
								Cancel
							</CancelButton>


							<SubmitButton
								variant='contained'
								style={{ float: 'right' }}
								type='submit'
								disabled={ offerMutationCalled || reserveMutationCalled }
								onClick={() => {
									setAttempt(true)

									if (!showcaseInput.contacts.filter((c: any) => !isContactInvalid(c)).length) {

										openSnackbar(severity.WARNING, 'Please fill fields in red.')

									} else {

									
										const ShowcaseDealInput = { ...showcaseInput }

										// Cleanup input

										// Parse Float
										if (ShowcaseDealInput.deal_entry[0]?.offer_amount) {
											ShowcaseDealInput.deal_entry[0].offer_amount = Number.parseFloat(ShowcaseDealInput.deal_entry[0].offer_amount)
										}

										// OfferPrice uses a different form of the id field
										if (ShowcaseDealInput.deal_entry[0]?.offer_currency) {
											ShowcaseDealInput.deal_entry[0].offer_currency_id = ShowcaseDealInput.deal_entry[0].offer_currency
										}

										delete ShowcaseDealInput.deal_entry[0]?.offer_currency

										// Map over contacts to remove some fields
										ShowcaseDealInput.contacts = showcaseInput.contacts.map((e: any) => {

											if (e.id == 0) return ({ 
												first_name: e.first_name,
												last_name: e.last_name,
												contact_role_id: e.contact_role_id,
												email: e.email
											})

											else return ({ id: e.id, contact_role_id: e.contact_role_id })

										})

										if (modalType == 'Offer')
											createOfferedDeal({ variables: { ShowcaseDealInput } })
												.then((res) => {
													setAttempt(false)
													if (res.data.createOfferedDeal?.success) {
														setAttempt(false)
														openSnackbar(severity.SUCCESS, 'Deal created successfully.')

														setArtList(
															artList.map((art: any) => {
															// There should only be one of each
																if (art.id === res.data.createOfferedDeal?.deal?.deal_entry?.[0]?.art?.id) {
																	return {
																		...res.data.createOfferedDeal.deal.deal_entry[0].art,
																	}
																} else return art
															})
														)
														closeModal()
													} else {
														setAttempt(false)
														openSnackbar(severity.ERROR, res.data.createOfferedDeal?.errors?.[0]?.message || 'Could not create deal.')
													}
												})
												.catch((error) => {
													console.error(error)
													openSnackbar(severity.ERROR, 'There was an error creating the deal.')
												})
										else {

											createReservedDeal({ variables: { ShowcaseDealInput } })
												.then((res) => {
													setAttempt(false)
													if (res.data.createReservedDeal?.success) {
														setAttempt(false)
														openSnackbar(severity.SUCCESS, 'Deal created successfully.')

														setArtList(
															artList.map((art: any) => {
															// There should only be one of each
																if (art.id === res.data.createReservedDeal?.deal?.deal_entry?.[0]?.art?.id) {
																	return {
																		...res.data.createReservedDeal.deal.deal_entry[0].art,
																	}
																} else return art
															})
														)
														closeModal()
													} else {
														setAttempt(false)
														openSnackbar(severity.ERROR, res.data.createReservedDeal?.errors?.[0]?.message || 'Could not create deal.')
													}
												})
												.catch((error) => {
													console.error(error)
													openSnackbar(severity.ERROR, 'There was an error creating the deal.')
												})
										}
									}
									
								}}
							>
								Submit
							</SubmitButton>
						</div>
					) : null}

				</Box>
			</div>

			<TransitionsModal
				open={!!Object.keys(currentPdf || {})?.length}
				close={() => {
					setCurrentPdf({})
				}}
				noPadding
			>
				<Box
					style={{
						height: '100vh',
						width: '70vw',
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'end',
						}}
					>
						<IconButton
							size='large'
							onClick={() => {
								setCurrentPdf({})
							}}
						>
							<Close />
						</IconButton>
					</div>
					<embed
						style={{
							pointerEvents: 'auto',
						}}
						src={currentPdf?.src}
						type='application/pdf'
						height='100%'
						width='100%'
						onDragEnter={() => {}}
					></embed>
				</Box>
			</TransitionsModal>
		</TransitionsModal>
	)
}
