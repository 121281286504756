/* eslint-disable eqeqeq */
import React, { useEffect, useContext, useCallback, useState } from "react"
import { ButtonBase, IconButton, Menu } from "@mui/material"
import makeStyles from '@mui/styles/makeStyles'
import QuickView from "../QuickView/QuickView"
import QuickViewNotes from "../QuickView/QuickViewNotes"
import "../QuickView/QuickView.css"
import { Skeleton } from '@mui/material'
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { withApollo } from '@apollo/client/react/hoc'
import { MenuItem } from "@mui/material"
import { GET_DEAL_QV_DETAILS, GET_ART_BY_DEAL_ID } from "./Queries.js"
import Thumbnail from '../Thumbnail/Thumbnail'
import { DispatchContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import chunk from 'lodash/chunk'
import { AvatarGroup } from '@mui/material'
import privateObjectTheme from "../styles/muiThemes/privateObjectTheme"
import lightTheme from "../styles/muiThemes/mainTheme"
import clsx from 'clsx'
import { formatDate, getDealName, getArtistThumbnailDetail } from "../common/helpers"
import useNavigation from "../navigation/useNavigation"
import ContactThumbnail from "../Thumbnail/ContactThumbnail"
import GalleryContactList from "../common/components/GalleryContactList"
import ContactAvatar from "../common/components/ContactAvatar"
import { findModeFieldsAtom, FindModeInput } from "../navigation/Tabs/TabbedPage"
import { useRecoilCallback } from "recoil"
import { StyledEngineProvider } from "@mui/styled-engine"
import { ThemeProvider } from "@mui/material"
import { UserTable } from "../QuickView/UserAccessQuickView"
import { Close } from "@mui/icons-material"
import { OWNER_CONTACT_ROLE, PRIMARY_CONTACT_ROLE } from "../DealEntry/DealEntryList"
import { dealTypes } from "../constants/values"

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false
}

const CONTACTS_PER_ROW = 8


/**
 * @typedef DealQuickViewProps
 * @property {import('./Deal').Deal} deal
 */

/**
 * @param {DealQuickViewProps} props
 */
function DealQuickView(props) {

	const navigate = useNavigate()
	const location = useLocation()

	const useStyles = makeStyles({
		dealQuickViewRoot: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
		  	'& div': {
				marginBottom: '.5em',
				width: '100%'
		  	}
		}
	})
	const classes = useStyles()

	const { push: pushNav } = useNavigation()

	// TODO: disable edit image and status upon lack of permissions
	const canEdit = Object.keys(props?.state?.getContact || {}).length && true

	const [deal, setDeal] = React.useState({})
	const [notes, setNotes] = React.useState([])
	const [artWorks, setArtWorks] = React.useState(null)
	const [users, setUsers] = React.useState([])

	const params = useParams()

	const salespersons = deal?.salesperson

	const [state, setState] = useState(initialState)

	const handleClick = event => {
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
			editable: false
		})
	}
	const handleClose = option => {
		if (option === "edit") {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	// Enacts on the details page
	useEffect(() => {
		if (params.id !== undefined && !props.id && props.state) {

			setDeal(props.state.getDeal || {})
			setNotes(props.state.getCriticalDealNotes)
		}
	}, [params.id, props.id, props.state])

	useEffect(() => {

		let active = true

		if (props.id && !props.findMode) {

			setDeal(null)
			setArtWorks(null)
			setNotes([])
			setUsers([])

			props.client
				.query({query: GET_DEAL_QV_DETAILS, variables: {id: props.id}})
				.then(result => {

					if (active) {
						// Snackbar responses
						if (!result.data.getDeal && result.errors) {
							// Error
							openSnackbar(severity.ERROR, result.errors[0].message)
							return
						} else {
							// Success
							setDeal(result.data.getDeal)
							setNotes(result.data.getCriticalDealNotes)
						}
					}

				})
				.catch(e => {
					// Error
					openSnackbar(severity.ERROR, "Could not retrieve deal Quick View.")
				})
		}
		return () => active = false

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.id])

	const handleArtSubmit = () => {

		if (!deal?.id) return
		props.client
			.query({query: GET_ART_BY_DEAL_ID, variables: {id: deal.id}})
			.then(result => {
				setArtWorks(result.data.getArtworksByDealId)
			})
			.catch(e => {
				console.error(e)
			})
	}

	useEffect(() => {
		handleArtSubmit()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deal])

	const privateDeal = deal && deal.is_private

	// handle FindMode search
	const handleKeyDown = useRecoilCallback(({ snapshot }) => async (e) => {
		if (!props.findMode || e.key !== 'Enter') return
		e.stopPropagation()
		let fields = await snapshot.getPromise(findModeFieldsAtom)
		fields = Object.fromEntries(Object.entries(fields).filter(([_, v]) => v != null && v !== ''))
		navigate('/deals', { state: { fields }})
	})

	const loading = !props.findMode && (props.loading || !deal?.created_at)

	const primaryOrOwners = deal?.contacts && deal.contacts.filter(e => e.contactRole?.id == PRIMARY_CONTACT_ROLE || e.contactRole?.id == OWNER_CONTACT_ROLE)

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={privateDeal ? privateObjectTheme : lightTheme}>
				<QuickView className={privateDeal ? "quick-view-main private-object" : "quick-view-main" }
					onContextMenu={canEdit ? handleClick : undefined} >

					{privateDeal ? <div className="private-qv-label">Private Deal</div> : null}

					<div className="quick-view-inner" onKeyDown={handleKeyDown}>

						<header>
							<h2
								className={privateDeal ? "quick-view-title-light" : "quick-view-title" }
								style={{ textAlign:"left", display: 'flex', justifyContent: 'space-between' }}
								data-testid="search-qv-header"
							>
								{props.findMode ? 'Deal Search' :
									deal?.id ? `${getDealName(deal)}` : (
										<Skeleton
											animation="wave"
											variant="text"
											width="4em"
										/>
									)}
								{props.onClose && deal?.id && <span>
									<IconButton size="large"
										sx={{ height: '35px', width: '35px' }}
										onClick={props.onClose}
									>
										<Close />
									</IconButton>
								</span>}
							</h2>

							<h3
								className={privateDeal ? "paragraph-1-light" : "paragraph-1"}
								style={{ fontWeight: 400 }}
							>
								{props.findMode ?
									<FindModeInput field="created_at" placeholder="Created Date"/> :
									deal?.created_at ? formatDate(deal?.created_at, 'MMM d, yyyy') :
										<Skeleton
											animation="wave"
											variant="text"
											width="6em"
										/>
								}
							</h3>
						</header>

						{props.findMode ? <>
							<h3 className="paragraph-2">Contacts</h3>
							<FindModeInput field="contact_name" style={{ width: '100%' }} />
						</> : <>
							<h3 className={privateDeal ? "paragraph-2-light" : "paragraph-2"}>{deal?.type_id == dealTypes.known_ownership ? `Owner${primaryOrOwners?.length > 1 ? 's' : ''}` : 'Primary Contact'}</h3>

							{loading ?
								<div
									className="shadow-group"
									style={{
										textAlign: 'left',
										display:"flex",
										alignItems:"center",
										justifyContent: 'flex-start',
										padding: "0 .5em",
										borderRadius: 8,
										marginBottom: '0.5em',
									}}
								>

									<Thumbnail
										animation="wave"
										type="contact"
									/>
								</div>
							 : null}

							{primaryOrOwners?.sort((a,b) => b?.created_at - a?.created_at)?.map((contact, i) => {

								const hidden = contact.is_private && !contact.first_name

								return (
									<ButtonBase key={"contact-"+contact.id+"critical"}
										className={clsx({
											'shadow-group': !privateDeal,
											'shadow-group-light': privateDeal
										})}
										onClick={(event) => {
											if (hidden) return
											const id = contact?.id

											if (event.metaKey) {
												window.open(`/contacts/${id}`, '_blank')
												return
											}

											const state = { variables: { cursor: 0 } }
											pushNav({
												url: location.pathname,
												state,
												query: [id],
											})
											navigate(`/contacts/${id}`, { state })
										}}
										style={{
											backgroundColor: contact.is_private ? '#2E3134' : null,
											textAlign: 'left',
											display:"flex",
											alignItems:"center",
											justifyContent: 'flex-start',
											padding: "0 .5em",
											borderRadius: 8,
											marginBottom: '0.5em',
										}}>
										<ContactThumbnail
											contact={contact}
											darkText={!contact.is_private}
										/>
									</ButtonBase>
								)
							})}

							<h3 className={privateDeal ? "paragraph-2-light" : "paragraph-2"}>Other Contacts</h3>

							{loading ? <div style={{
								padding: '1em',
								display: 'flex',
								alignItems: 'flex-start',
								width: '100%'
							}}>
								<Skeleton variant='circular' animation='wave' height={40} width={40}/>
							</div> : null}

							{deal?.contacts && deal.contacts.filter(e => e.contactRole?.id != PRIMARY_CONTACT_ROLE && e.contactRole?.id != OWNER_CONTACT_ROLE ).length === 0 ? <div style={{
								padding: '1em',
								width: '100%'
							}}>
								<span>-</span>
							</div> : null}

							{chunk(deal?.contacts && deal.contacts.filter(e => e.contactRole?.id != PRIMARY_CONTACT_ROLE && e.contactRole?.id != OWNER_CONTACT_ROLE), CONTACTS_PER_ROW).map((chunk, index) => {
								return (
									<AvatarGroup key={"AvatarGroup-"+index}
										max={CONTACTS_PER_ROW}
										sx={{
											width: '100%',
											padding: '0.5em',
											marginTop: '1em'
										}}
									>
										{chunk.map(contact => <ContactAvatar
											key={contact.id}
											contact={contact}
										/>)}
									</AvatarGroup>
								)
							})}
						</>
						}
						{props.findMode ? <>
							<h3 className="paragraph-2">Gallery Contacts</h3>
							<FindModeInput field="gallery_contacts" style={{ width: '100%' }} />
						</> : <table className="quickview-main">
							<tbody>
								<tr>
									<th style={{
										marginTop: '1em',
										display: 'block',
										verticalAlign:' top',
										marginRight: '6em',
									}}>Gallery Contacts</th>
									<td>
										<GalleryContactList
											salespersons={salespersons}
											findMode={props.findMode}
											loading={props.loading}
											chunk={5}
										/>
									</td>
								</tr>
								<tr>
									<th>Total Sale</th>
									<td>{loading ? <Skeleton animation="wave" variant="text"/> : <>{ deal?.totalSale || "-" }</>}</td>
								</tr>
							</tbody>
						</table>
						}

						<h3 className={privateDeal ? "paragraph-2-light" : "paragraph-2"}>
							Artworks
						</h3>
						{props.findMode ? <div className={classes.dealQuickViewRoot}>
							<FindModeInput field="artist" placeholder="Artist" />
							<FindModeInput field="art_title" placeholder="Title" />
							<FindModeInput field="deal_entry_status" placeholder="Status" />
						</div> :
							Object.keys(deal || {}).length && !loading ? artWorks?.map?.((art, i) => {

								const hidden = art.is_private && !art.created_at
								const isPrivate = art.is_private || art.is_gallery_private

								return (
									<ButtonBase key={`art-${art?.id}-critical-${i}`}
										className={clsx({
											'shadow-group': !privateDeal,
											'shadow-group-light': privateDeal
										})}
										onClick={(event) => {
											if (hidden || art.is_gallery_private) return

											if (event.metaKey) {
												window.open(`/art/${art?.id}`, '_blank')
												return
											}

											const ids = artWorks?.map(el => el.id)
											const index = ids.indexOf(art.id)
											const state = { variables: { cursor: index } }
											pushNav({
												url: location.pathname,
												state,
												query: ids,
											}, index)
											navigate(`/art/${art?.id}`, { state })
										}}
										style={{
											display:"flex",
											alignItems:"center",
											justifyContent: 'flex-start',
											padding: "0 .5em",
											textAlign: 'left',
											marginTop: '0.5em',
											borderRadius: 8,
											backgroundColor: isPrivate ? '#2E3134' : null,
											cursor: 'pointer',
										}}>
										<Thumbnail
											formatted_name={art.formatted_title}
											name={art?.code_name}
											artist={getArtistThumbnailDetail(
												art
											)}
											date={art.year}
											avatar={art.imgUrl}
											type="art"
											animation={
												props.loading
													? 'wave'
													: false
											}
											largeText
											darkText={!isPrivate}
											hasAccess={!hidden}
										></Thumbnail>

										<div style={{fontSize: "smaller", marginLeft: 'auto', color: isPrivate ? 'white' : null}}>
											{ art?.deal_entry_status?.value}
										</div>
									</ButtonBase>
								)
							}) :
								<>
									{loading ?
										<div
											className="shadow-group"
											style={{
												display:"flex",
												alignItems:"center",
												justifyContent: 'flex-start',
												padding: "0 .5em",
												textAlign: 'left',
												marginTop: '0.5em',
												borderRadius: 8,
											}}>
											<Thumbnail type="art" animation="wave"/>
										</div>
									 : null}
								</>
						}

						{(deal?.publicAccess === null && !props.findMode) ?
							<UserTable
								rows={users}
								heading='Users With Public Access'
								lazyLoad={true}
								entity={deal}
								objectTypeId={3}
								setRows={setUsers}
								hideAssistants={true}
							/> :
							<>
								{!props.findMode && <QuickViewNotes
									notes={notes}
									entity={deal}
									privateObject={deal?.is_private}
									style={{marginTop: '0.5em'}}
									loading={loading}
								/>}

							</>
						}

						<Menu
							keepMounted
							open={state.mouseY !== null}
							onClose={handleClose}
							anchorReference="anchorPosition"
							anchorPosition={
								state.mouseY !== null && state.mouseX !== null
									? { top: state.mouseY, left: state.mouseX }
									: undefined
							}
						>
							<MenuItem onClick={() => handleClose("edit")}>Edit Profile Picture</MenuItem>
						</Menu>

					</div>
				</QuickView>
			</ThemeProvider>
		</StyledEngineProvider>
	)
}

export default withApollo(DealQuickView)
