import { gql } from "@apollo/client"

export const GET_IMAGE_DOWNLOAD_LINK = gql`
	query getImageDownloadLink($id: ID!) {
		getImageDownloadLink(id: $id)
	}
`

export const GET_IMAGE_PREVIEW_LINK = gql`
	query getImagePreviewLink($id: ID!) {
		getImagePreviewLink(id: $id)
	}
`

export const DOWNLOAD_ALL_IMAGES = gql`
	query getMultiImageDownloads($ids: [ID!]!) {
		getMultiImageDownloads(ids: $ids)
	}
`

export const PREVIEW_ALL_IMAGES = gql`
	query getMultiImagePreviews($ids: [ID!]!) {
		getMultiImagePreviews(ids: $ids)
	}
`
