import { gql } from '@apollo/client'

export const GET_DATALOG_ITEMS = gql`
	query getDataLogItems($DataLogItemRequest: DataLogItemRequest!) {
		getDataLogItems(input: $DataLogItemRequest)
	}
`

export const GET_DATALOG_ITEM_IDS = gql`
	query getDataLogIds($DataLogItemRequest: DataLogItemRequest!) {
		getDataLogIds(input: $DataLogItemRequest)
	}
`
