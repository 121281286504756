/* eslint-disable eqeqeq */
import { Avatar, Tooltip, Select, MenuItem, IconButton, List, ListItemIcon, Typography, ListItemButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DispatchContext, AuthStateContext } from '../../store'
import { GET_ART_QV_DETAILS, UPDATE_ART } from '../Queries.js'
import { LookupContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { Skeleton } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { formatter, getArtInventoryNumber, getArtStatusChip } from '../../common/helpers'
import UserToggleSmall from '../../input/Toggle/UserToggleSmall'
import ImageIcon from '@mui/icons-material/Image'
import QuickView from '../../QuickView/QuickView'
import React, { useEffect, useContext, useCallback } from 'react'
import Thumbnail from '../../Thumbnail/Thumbnail'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { useApolloClient, useMutation } from '@apollo/client'
import QuillText from '../../input/QuillText'
import Dropdown from '../../input/Dropdown/Dropdown'
import TransitionModal from '../../navigation/TransitionsModal/TransitionsModal'
import {
	CancelButton,
	ResetButton,
	ChooseImageButton,
	SubmitButton,
} from '../../input/Buttons'
import { formatDate } from '../../common/helpers'
import ConfirmationDialog from '../../navigation/ConfirmationDialog/ConfirmationDialog'
import { ThemeProvider, StyledEngineProvider, FormControl } from '@mui/material'
import lightTheme from '../../styles/muiThemes/mainTheme'
import Label from '../../input/Label'
import TextBox from '../../input/Text/TextBox'
import QuickViewNotes from '../../QuickView/QuickViewNotes'
import sortBy from 'lodash/sortBy'
import QuickViewContacts from '../../QuickView/QuickViewContacts'
import privateObjectTheme from '../../styles/muiThemes/privateObjectTheme'
import clsx from 'clsx'
import { findModeFieldsAtom, findModeFieldSelector, FindModeInput } from '../../navigation/Tabs/TabbedPage'
import { useRecoilCallback, useRecoilState, useSetRecoilState } from 'recoil'
import Zoom from 'react-medium-image-zoom'
import { Close } from '@mui/icons-material'
import SourceLabel from './SourceLabel'
import Source from './Source'
import { artStatusesConstants, dealTypes } from '../../constants/values'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import NotificationsIcon from '@mui/icons-material/Notifications'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff'
import { permissions, permissionValues } from '../../constants/permissions'
import CreateDealQuickView from '../../Deals/CreateDealQuickView'
import { quickViewAtom } from '../../navigation/Tabs/TabbedPage'
import RenderInput from '../../components/Input'

const findModeInputStyle = {
	marginBottom: '.5em',
	width: '100%'
}

export const getVerifiedIcon = (art, loading = false) => {
	if (!art) return null

	const verified = (
		<img
			alt="verified-art"
			style={{
				width: '18px',
				height: '18px',
				alignItems: 'center',
				justifyContent: 'center',
				maxWidth: '18px',
			}}
			src="/images/icons/VerifiedIcons/VerifiedBlueCheck.svg"
		/>
	)

	const unVerified = (
		<img
			alt="unverified-art"
			style={{
				width: '18px',
				height: '18px',
				alignItems: 'center',
				justifyContent: 'center',
				maxWidth: '18px',
			}}
			src="/images/icons/VerifiedIcons/UnverifiedGreyX.svg"
		/>
	)

	const nullVerified = <img
		alt="art"
		style={{
			width: '18px',
			height: '18px',
			alignItems: 'center',
			justifyContent: 'center',
			maxWidth: '18px',
		}}
		src="/images/icons/VerifiedIcons/UnverifiedGreyShield.svg"
	/>

	if ((art.isVerified || art.is_verified) && !loading) return verified
	if (art.isVerified == null) return nullVerified
	return unVerified
}


/**
 * @typedef ArtQuickViewProps
 * @property {import('./ArtPiece').ArtPiece} [artPiece]
 * @property {number} id
 */

/**
 * @type import('react').ComponentClass<ArtQuickViewProps, any>
 */
function ArtQuickView(props) {

	const [findModeActive, setFindModeActive] = useRecoilState(findModeFieldSelector('isActive'))
	const [findModeVerified, setFindModeVerified] = useRecoilState(findModeFieldSelector('isVerified'))
	const [findModeArtStatusValue, setFindModeArtStatusValue] = useRecoilState(findModeFieldSelector('art_status_value'))
	const [notificationModal, setNotificationModal] = React.useState(false)
	const [notification, setNotification] = React.useState("ALL")

	const setQVOverride = useSetRecoilState(quickViewAtom)

	const canEdit = props?.id === undefined
	// can only edit the top values if on an art-detail page with the
	// "natural" quickview, rather than as a view from a grid.
	const editable = props.state != undefined

	const authState = useContext(AuthStateContext)
	const userPermissions  = authState?.user?.permissions

	const canViewAdminConsole = userPermissions
		?.find(e => e.permission_id == permissions.ADMIN_CONSOLE)
		?.permission_value_id == permissionValues.YES

	const silentTransactionPermission = userPermissions
		?.find(e => e.permission_id == permissions.SILENT_TRANSACTIONS)
		?.permission_value_id == permissionValues.YES


	const navigate = useNavigate()
	const lookup = useContext(LookupContext)
	const client = useApolloClient()

	const artStatus = lookup.data?.getArtStatuses
	const currencies = lookup.data?.getCurrencies

	const sortedArtStatus = sortBy(artStatus, 'value')
	const [art, setArt] = React.useState(props.state?.getArtPiece)
	const [notes, setNotes] = React.useState([])
	const [verifiedModal, setVerifiedModal] = React.useState(false)
	const [activeDialog, setActiveDialog] = React.useState(false)
	const [verifiedText, setVerifiedText] = React.useState('')

	const params = useParams()

	const salespersons = art?.salesperson

	const useStyles = makeStyles(() => ({
		large: {
			width: 285,
			height: 285
		},
	}))

	const classes = useStyles()

	const [updateArt] = useMutation(UPDATE_ART, {
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, 'Could not update.')
		},
		onCompleted: (response) => {
			if (response.updateArtPiece.success === true) {
				openSnackbar(severity.SUCCESS, response.updateArtPiece.message)
				setArt({
					...art,
					is_verified: response.updateArtPiece.art.is_verified,
					verified_at: response.updateArtPiece.art.verified_at,
					verified_by: response.updateArtPiece.art.verified_by,
					verified_user: response.updateArtPiece.art.verified_user,
					verified_text: response.updateArtPiece.art.verified_text,
					status_id: response.updateArtPiece.art.status_id,
					is_active: response.updateArtPiece.art.is_active,
				})

				// Update the tabbed page art state if the returned status was available and art was active
				if (response.updateArtPiece.art.status_id == 6 && response.updateArtPiece.art.is_active) {
					props.setState({
						...props.state,
						getArtPiece: {
							...props.state.getArtPiece,
							...response.updateArtPiece.art
						}
					})
				}

				// Update the tabbed page art state if the returned status was available or art was active
				if (response.updateArtPiece.art.status_id != 6 || !response.updateArtPiece.art.is_active) {
					props.setState({
						...props.state,
						getArtPiece: {
							...props.state.getArtPiece,
							...response.updateArtPiece.art
						}
					})
				}

				setVerifiedText(response.updateArtPiece.art.verified_text || '')

				setVerifiedModal(false)
				setActiveDialog(false)
			} else {
				// On failure, reset artInput state, don't touch art
				// state and show error
				openSnackbar(severity.ERROR, response.updateArtPiece.message)
			}
		},
	})

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const getActiveStatus = (art) => {
		if (!art) return null

		return (
			<div style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				paddingTop: '.2em'
			}}>
				{art.is_active ? (
					<span
						style={{ color: 'var(--available)', fontWeight: '500', paddingTop: '0.3em' }}
					>
						ACTIVE
					</span>
				) : (
					<span style={{ color: 'var(--sold)', fontWeight: '500', paddingTop: '0.3em' }}>
						INACTIVE
					</span>
				)}
				<UserToggleSmall
					disabled={!props.findMode && !editable}
					checked={props.findMode ?
						!!findModeActive :
						!!art.is_active}
					onClick={() => {
						if (props.findMode) {
							setFindModeActive(!findModeActive)
							return
						}
						if (canEdit) setActiveDialog(true)
					}}
				/>
			</div>
		)
	}

	const getArtQVStyle = (art) => {
		const statusLabel = artStatus?.find( as => art?.status_id === as.id )

		if (statusLabel?.value === "Reserved")
			return { boxShadow: '0 0 0 2pt rgba(245, 149, 17, 0.6)', overflowX: 'hidden' }
		return { overflowX: 'hidden'}
	}

	useEffect(() => {
		if (params.id !== undefined && !props.id && props.state) {
			setArt(props.state?.getArtPiece || {})
			setNotes(props.state?.getCriticalArtNotes)
			setVerifiedText(props.state?.getArtPiece?.verified_text || '')
		}
		if (art && props.status) {
			setArt({
				...art,
				status_id: props.status
			})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id, props.id, props.status, props.state])

	const handleUpdateArtStatus = (event) => {
		if (props.findMode) {
			setFindModeArtStatusValue(event.target.value)
			return
		}
		let artInput = {
			id: art?.id,
			status_id: event.target.value,
		}

		// If moving to Reserved or Sold, one needs to be redirected through
		// the deal workflow
		if ([
			26, // Reserved
			1, // SOLD,
			14, // SOLD/TBS
		 ].includes(Number(event.target.value))) {
			setQVOverride(() => (
				<CreateDealQuickView
					typeId={dealTypes.offer}
					contacts={[]}
					art={art ? [{ ...art }] : []}
					setCreateDeal={() => setQVOverride(null)}
					darkTheme={privateArt || false}
				></CreateDealQuickView>
			))

			openSnackbar(
				severity.WARNING,
				"Please create a Deal to set the Status to ‘Reserved’ or ‘Sold’.")
			return
		 }

		if (!props.findMode &&
			(canViewAdminConsole || silentTransactionPermission) &&
			event.target.value == artStatusesConstants.available &&
			art.status_id != artStatusesConstants.available
		) {
			setNotificationModal(true)
		} else {
			updateArt({
				variables: { UpdateArtInput: artInput },
			})
		}
	}

	useEffect(() => {
		let active = true

		if (props.id && !props.findMode) {

			setArt(null)
			setNotes([])
			client
				.query({
					query: GET_ART_QV_DETAILS,
					variables: { id: props.id },
				})
				.then((result) => {
					if (active) {
					// Snackbar responses
						if (!result.data.getArtPiece.art || !result.data.getArtPiece.success) {
							openSnackbar(
								severity[result.data.getArtPiece.severity],
								result.data.getArtPiece.message)
						} else {
							setArt(result.data.getArtPiece.art)
							setNotes(result.data.getCriticalArtNotes)
						}
					}
				})
				.catch((e) => {
					if (active) {
						openSnackbar(
							severity.ERROR,
							'Could not retrieve art Quick View.'
						)
					}
				})
		}
		return () => active = false
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.id])

	const privateArt = props.atPrivateObject || art?.is_private

	let verifiedTooltip = ''
	if (!props.findMode && !art?.is_verified) {
		verifiedTooltip = 'Not Verified'
	} else if (!props.findMode) {
		const name = [
			art?.verified_user?.first_name,
			art?.verified_user?.last_name
		].filter(a => a).join(' ')
		const date = formatDate(
			art?.verified_at,
			"MMMM do, yyyy 'at' h':'mma"
		)
		const text = art?.verified_text && `\n${art.verified_text}`

		verifiedTooltip = [name, date, text]
			.filter(a => a).join('\n')
	}

	const currentObject = art

	// handle FindMode search
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const handleKeyDown = useRecoilCallback(({ snapshot }) => async (e) => {
		if (!props.findMode || e.key !== 'Enter') return
		e.stopPropagation()
		let fields = await snapshot.getPromise(findModeFieldsAtom)
		fields = Object.fromEntries(Object.entries(fields).filter(([_, v]) => v != null && v !== ''))
		navigate('/art', { state: { fields }})
	})

	const loading = !props.findMode && (props.loading || (!art?.created_at && !art?.title))

	const getAvatar = () => (
		<Avatar
			alt={!props.findMode ? currentObject?.title + ' Image' : ''}
			src={!props.findMode ? currentObject?.imgUrl : null}
			variant="square"
			style={{
				height: (props.findMode || !currentObject?.imgUrl) ? '285px' : 'auto',
				width: '285px',
				marginBottom: '0.5em',
				marginTop: '5px',
				objectFit: 'scale-down'
			}}
		>
			<ImageIcon style={{ fontSize: '150' }} />
		</Avatar>
	)

	const activeInputProps = {
		disabled: !props.findMode,
		label: 'Activity Status:',
		onChange: (value) => {
			setFindModeActive(value)
		},
		options: [
			{ className: 'text-green', label: 'Active', value: 1 },
			{ className: 'text-red', label: 'Inactive', value: 0 },
		],
		type: 'select',
		value: findModeActive,
		variant: 'borderless',
	}

	const artStatusColorMap = {
		'Available': 'text-green',
		'SOLD': 'text-red',
		'Reserved': 'text-info',
	}

	const artStatusOptions =  [
		...(sortedArtStatus?.map((type) => ({ label: type.value, value: props.findMode ? type.value : parseInt(type.id) })) || []),
		...(props.findMode ? [{ label: "Ignored", value: "" }] : [])
	]

	// apply className to artStatusOptions with label map
	artStatusOptions.forEach((option) => {
		option.className = artStatusColorMap[option.label] || null
	})

	const artStatusInputProps = {
		disabled: !props?.findMode && !editable,
		label: 'Art Status:',
		onChange: (value) => {
			handleUpdateArtStatus({ target: { value }})
		},
		options: artStatusOptions,
		type: 'select',
		value: props?.findMode ? (findModeArtStatusValue || '') : (artStatus && art?.status_id) ? parseInt(art?.status_id) : '',
		variant: 'borderless',
	}

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={privateArt ? privateObjectTheme : lightTheme}>
				<QuickView
					className={clsx({
						'quick-view-main': true,
						'private-object': privateArt
					})}
					style={getArtQVStyle(art)}
				>

					{ (privateArt && !props.findMode) ?
						<div className="private-qv-label">Private Art Piece</div> :
						null
					}

					<div className="flex gap-3 quick-view-inner" onKeyDown={handleKeyDown}>
						{(loading) ? (
							<Skeleton
								animation={loading ? 'wave' : false}
								variant="text"
								width="100%"
								height="4em"
								style={{ marginTop: '-1em'}}
							/>
						) : null}

						<div className='flex justify-between w-full'>
							<div className='flex items-center text-sm text-grey'>Artwork Detail</div>
							<div className='flex justify-end'>
								<Tooltip
									title={verifiedTooltip}
									arrow
									placement="bottom"
									enterDelay={1000}
								>
									<span style={{ marginLeft: 'auto' }}>
										<IconButton
											sx={{ height: '24px', width: '24px' }}
											onClick={() => {
												if (props.findMode) {
													let next = null
													if (findModeVerified) next = false
													if (findModeVerified == null) next = true
													setFindModeVerified(next)
													return
												}
												if (params.id !== undefined)
													setVerifiedModal(true)
											}}
											disabled={!props.findMode && !editable}
											size="large">
											{props.findMode ?
												getVerifiedIcon({
													isVerified: findModeVerified
												}) :
												getVerifiedIcon(art)}
										</IconButton>
									</span>
								</Tooltip>
								{props.onClose && !loading && <span>
									<IconButton size="large"
										sx={{ height: '24px', width: '24px' }}
										onClick={props.onClose}
									>
										<Close />
									</IconButton>
								</span>}
							</div>
						</div>

						{art ? (
							<div className='flex flex-row w-full'>
								{props.findMode ?
									<Select
										displayEmpty
										input={<Dropdown />}
										IconComponent={ExpandMoreRoundedIcon}
										value={findModeActive || ''}
										renderValue={val => {
											if (val == 'true') return 'Active'
											if (val == 'false') return 'Inactive'
											if (val == '') return <i>Ignored</i>
										}}
										onChange={(event) => {
											setFindModeActive(event.target.value)
										}}
									>
										<MenuItem key='active-null' value="">
											<i>Ignored</i>
										</MenuItem>
										<MenuItem key='active-false' value="false">
                                            Inactive
										</MenuItem>
										<MenuItem key='active-true' value="true">
                                            Active
										</MenuItem>
									</Select>
									: getActiveStatus(art)
								}
								<Select
									displayEmpty
									native={false}
									// readOnly={!canEdit}
									// disabled={!props.findMode && !editable}

									input={<Dropdown />}
									IconComponent={ExpandMoreRoundedIcon}
									className="hideborder small-selection"
									style={{ marginLeft: 'auto' }}
									value={
										props.findMode ? (findModeArtStatusValue || '') :
											(artStatus && art?.status_id) ?
												parseInt(art?.status_id) : ''
									}
									renderValue={(id) => props.findMode ?
										(id == '' ? <i>Ignored</i> : id) :
										getArtStatusChip({status_id: id, is_private: art.is_private}, loading, artStatus, true)
									}
									onChange={handleUpdateArtStatus}
									data-testid="art-status"
								>
									{sortedArtStatus &&
                                sortedArtStatus.map((type) => (
									
									<MenuItem
										key={`${type.id}-${type.value}`}
										value={props.findMode ? type.value : type.id}
										data-testid="art-status-item"
									>
										<Tooltip
											arrow
											placement="right"
											enterDelay={1000}
											title={type?.value} 
										>
										{getArtStatusChip({status_id: type.id, is_private: art.is_private}, loading, artStatus, true)}
										</Tooltip>
									</MenuItem>
                                ))}
									{props.findMode && <MenuItem
										key="typeId-Ignored"
										value=""
									>
                                        Ignored
									</MenuItem>}
								</Select>
							</div>
						) : null}

						<div className='flex w-full mt-3'>
						{(getArtInventoryNumber(art, false) !== '-' || props.findMode ) && (
							<div className='flex text-xl' data-testid="search-qv-header">
								{
									props.findMode ? <FindModeInput field="searchable_inventory_number" placeholder="Inventory No."/> 
									: getArtInventoryNumber(art, false)
								}
							</div>
						)}
						</div>

						<div className='flex flex-col w-full gap-1'>
							{loading ? (
								<>
									<Skeleton
										animation={!art?.created_at ? 'wave' : false}
										variant="text"
										width="60%"
										height="3em"
									/>
									<Skeleton
										animation={!art?.created_at ? 'wave' : false}
										variant="text"
										width="50%"
										height="2em"
									/>
								</>
							) : null}

							<div className='text-sm'>
								{ props.findMode ? <FindModeInput
									field="artist_full_name"
									style={findModeInputStyle}
									placeholder="Artist"
								/> :
                                `${art?.artist?.first_name || ''} ${
                                    art?.artist?.last_name || ''}`
								}
							</div>
							<div className='text-sm text-grey italic'>
								{ props.findMode ? <FindModeInput field="title"
									style={findModeInputStyle}
									placeholder="Title"
								/> : art?.formatted_title ? (
									<QuillText name="qv-title">
										{art?.formatted_title}
									</QuillText>
								) : <span>{art?.title}</span> }
								{ props.findMode &&
                                    <FindModeInput field="year"
                                    	style={findModeInputStyle}
                                    	placeholder="Date"
                                    />
								}
								{!props.findMode && art?.year && art?.formatted_title ? `, ${art?.year}` : null}
								{!props.findMode && art?.year && !art?.formatted_title ? `${art?.year}` : null}
							</div>
							{!props.findMode ?
								<span className='flex w-full text-sm'>
									{art?.primary_currency_id && art?.primaryRetailPrice && formatter(
										currencies?.find(
											(e) => e.id == art.primary_currency_id
										)?.label || 'USD'
									).format(art.primaryRetailPrice)}
								</span>
								: null
							}
						</div>

						{/*  Primary Retail Price of Art Piece */}

						

						{loading ? 
							<div>
								<Skeleton animation="wave"
									className={classes.large}
									variant="rectangular"
								/>
							</div> :
							<>
								{!props.findMode && currentObject.imgUrl ?
									<Zoom
										overlayBgColorEnd="rgba(0, 0, 0, 0.5)"
										overlayBgColorStart="rgba(255, 255, 255, 0)"
									> { getAvatar() } </Zoom> :
									getAvatar()
								}
							</>
						}

						{/* {
							art && (
								<div className='flex flex-col w-full gap-3'>
									<RenderInput {...activeInputProps} />
									<RenderInput {...artStatusInputProps} />
								</div>
							)
						} */}

						{props.findMode ?
							<FindModeInput field="gallery_contacts"
								style={findModeInputStyle}
								placeholder="Gallery Contacts"
							/> :
							<QuickViewContacts
								loading={loading}
								salespersons={salespersons}
							/>
						}


						{props.findMode ?

							<>
								<FormControl fullWidth>
									<Label disableAnimation shrink>
										Current Source
									</Label>
									<FindModeInput field="source_name.current_sources"/>
								</FormControl>
								<FormControl fullWidth>
									<Label disableAnimation shrink>
										Purchaser
									</Label>
									<FindModeInput field="source_name.purchaser"/>
								</FormControl>
								<FormControl fullWidth>
									<Label disableAnimation shrink>
										Consignor
									</Label>
									<FindModeInput field="source_name.consignor"/>
								</FormControl>
								<FormControl fullWidth>
									<Label disableAnimation shrink>
										Owner
									</Label>
									<FindModeInput field="source_name.owners"/>
								</FormControl>

							</>

							: <div className="w-full text-sm text-grey">
								<SourceLabel
									loading={loading}
									canViewOwnerContact={art?.canViewOwnerContact}
									sources={art?.current_sources}
									iconStyles={{
										marginBottom: '-0.25em'
									}}
								/>
							</div>}

						{!props.findMode ?
							<>
								{!loading && !art.current_sources?.length ? (
									<table id="contact-table" className="shadow-group">
										<tbody>
											<tr>
												<th>No linked source.</th>
											</tr>
										</tbody>
									</table>) :

									<div style={{
										width: '100%'
									}}>
										<Source
											loading={loading}
											canViewOwnerContact={art?.canViewOwnerContact}
											sources={art?.current_sources}
											enableNav
											thumbnailStyle={{
												margin: '8px auto 8px 8px',
											}}
											avatarGroupStyles={{
												margin: '0.5em'
											}}
										/>
									</div>
								}
							</>
							: null}

						{!props.findMode && <QuickViewNotes
							notes={notes}
							entity={art}
							privateObject={privateArt}
							loading={loading}
						/>}

					</div>

					<TransitionModal
						className="verified-modal"
						open={verifiedModal}
						close={() => setVerifiedModal(false)}
					>
						<div style={{ minWidth: '20em' }} className={art?.is_private ? "private-object" : null}>
							<h1 className="card-title">
								<span>Verification</span>
							</h1>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between',
									padding: '1em',
								}}
							>
								<Thumbnail
									name={
										art?.verified_user
											? `${art.verified_user.first_name} ${art.verified_user.last_name}`
											: null
									}
									detail={
										art?.verified_user
											? art.verified_user.gallery
											: null
									}
									avatar={art?.verified_user?.imgUrl}
									largeText
									animation={
										art?.title
											? false : 'wave'
									}
								/>
								<div style={art?.is_private ? { paddingLeft: '1em', maxWidth: '10em', color: 'white' } : { paddingLeft: '1em', maxWidth: '10em' }}>
									{art?.verified_at && formatDate(art?.verified_at, 'MM dd yyyy') !== 'Invalid Date' ? formatDate(art?.verified_at, "iiii, MMMM do, yyyy 'at' h':'mma") : null}
								</div>

							</div>

							<FormControl className="fullWidth" style={{
								paddingBottom: '1em'
							}}>
								<Label
									disableAnimation
									shrink
									htmlFor="art-verified-text"
								>
                                Verified Text
								</Label>

								<TextBox
									multiline
									id="art-verified-text"
									value={verifiedText}
									onChange={(e) => {
										setVerifiedText(e.target.value)
									}}
								/>
							</FormControl>

							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									paddingTop: '1em',
								}}
							>
								<CancelButton
									variant="contained"
									onClick={() => {
										setVerifiedModal(false)
										setVerifiedText(props.state?.getArtPiece?.verified_text || '')
									}}
									style={{ marginRight: 'auto' }}
								>
                                Cancel
								</CancelButton>

								{art?.is_verified ? (
									<ResetButton
										variant="contained"
										onClick={() => {
											let artInput = {
												id: art.id,
												is_verified: false,
												verified_at: null,
												verified_by: null,
												verified_text: verifiedText
											}

											updateArt({
												variables: { UpdateArtInput: artInput },
											})
										}}
									>
                                    Un-Verify
									</ResetButton>
								) : null}

								<ChooseImageButton
									variant="contained"
									onClick={() => {
										let artInput = {
											id: art.id,
											is_verified: true,
											verified_at: new Date(),
											verified_by: authState.user.id,
											verified_text: verifiedText
										}
										updateArt({
											variables: { UpdateArtInput: artInput },
										})
									}}
									style={{
										marginLeft: '1em',
									}}
								>
									{art?.is_verified ? 'Re-Verify' : 'Verify'}
								</ChooseImageButton>
							</div>
						</div>
					</TransitionModal>

					<ConfirmationDialog
						open={activeDialog}
						handleClose={() => setActiveDialog(false)}
						title={'Are you sure?'}
						acceptText={!art?.is_active ? 'Activate' : 'Deactive'}
						text={
							(!art?.is_active ? 'Activate ' : 'Deactivate ') + art?.title
						}
						buttonColor={!art?.is_active ? 'var(--accept-changes)' : null}
						onYes={() => {
							let artInput = {
								id: art.id,
								is_active: !art.is_active,
							}
							updateArt({
								variables: { UpdateArtInput: artInput },
							})
						}}
					/>


				</QuickView>


				<TransitionsModal
					open={notificationModal}
					close={() => {
						setNotificationModal(null)
						setNotification("ALL")
					}}
				>
					<div className={clsx({
						'private-object': privateArt,
					})}>
						<h1 className="card-title">
							<span>Notification Level</span>
						</h1>
						<List>
							<ListItemButton
								style={{
									borderRadius: 4,
									marginBottom: '0.5em'
								}}
								selected={notification === 'ALL'}
								onClick={() => {
									setNotification('ALL')
								}}
							>
								<ListItemIcon>
									<NotificationsIcon color="primary" />
								</ListItemIcon>
								<Typography>Notifications On</Typography>
							</ListItemButton>
							<ListItemButton
								style={{
									borderRadius: 4,
									marginBottom: '0.5em'
								}}
								selected={notification === 'LEAD'}
								onClick={() => {
									setNotification('LEAD')
								}}
							>
								<ListItemIcon>
									<SupervisorAccountIcon color="primary" />
								</ListItemIcon>
								<Typography>Team Leads Only</Typography>
							</ListItemButton>
							<ListItemButton
								style={{
									borderRadius: 4,
									marginBottom: '0.5em'
								}}
								selected={notification === 'OFF'}
								onClick={() => {
									setNotification('OFF')
								}}
							>
								<ListItemIcon>
									<NotificationsOffIcon color="primary" />
								</ListItemIcon>
								<Typography>Notifications Off</Typography>
							</ListItemButton>
						</List>


						<div style={{
							display: 'flex',
							justifyContent: 'space-between',
							flexDirection: 'row-reverse'
						}}>

							<SubmitButton
								variant="contained"
								type="submit"
								onClick={() => {
									let artInput = {
										id: art?.id,
										status_id: artStatusesConstants.available,
									}

									updateArt({
										variables: {
											UpdateArtInput: artInput,
											notify: notification
										},
									})

									setNotificationModal(null)
									setNotification("ALL")
								}}
							>
								Submit
							</SubmitButton>

							<CancelButton
								variant="contained"
								onClick={(e) => {
									setNotificationModal(null)
									setNotification("ALL")
								}}
							>
								Cancel
							</CancelButton>

						</div>
					</div>
				</TransitionsModal>

			</ThemeProvider>
		</StyledEngineProvider>
	)
}

export default ArtQuickView
