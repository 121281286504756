import {
	Paper,
	FormControl,
} from '@mui/material' 
import Label from '../../../input/Label'
import React from 'react'
import { FindModeInput } from '../../../navigation/Tabs/TabbedPage'

const LocationSearch = (props) => {

	return (<Paper 
		className="qv-margin" 
		id="location-search" 
	>
		<h1 className="card-title">
			<span>Location</span>
		</h1>

		<div className="column-body">
			<FormControl style={{
				flexGrow: 1,
				marginRight: '1em'
			}}>
				<Label disableAnimation shrink htmlFor="location_name">
					Location
				</Label>
				<FindModeInput field="location_name" />
			</FormControl>
			<FormControl style={{
				flexGrow: 1,
				marginRight: '1em'
			}}>
				<Label disableAnimation shrink htmlFor="sub_location_name">
					Sub-Location
				</Label>
				<FindModeInput field="sub_location_name" />
			</FormControl>
			<FormControl style={{
				flexGrow: 1,
				marginRight: '1em'
			}}>
				<Label disableAnimation shrink htmlFor="via">
					Via
				</Label>
				<FindModeInput field="via" />
			</FormControl>
			<FormControl style={{ flexGrow: 1 }}>
				<Label disableAnimation shrink htmlFor="location_change_at">
					Date
				</Label>
				<FindModeInput type="date-picker" field="location_change_at"/>
			</FormControl>
		</div>
	</Paper>)
}

export default LocationSearch
