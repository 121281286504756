/* eslint semi: 0 */
/* eslint-disable eqeqeq */
import { withApollo } from '@apollo/client/react/hoc';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import PanToolIcon from '@mui/icons-material/PanTool';
import TimelapseOutlinedIcon from '@mui/icons-material/TimelapseOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { AccordionDetails, Divider, Link, Paper, StyledEngineProvider, ThemeProvider, Typography } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import React, { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { fetchWithAuth } from '../../common/helpers';
import { permissions } from '../../constants/permissions';
import { MAX_IMAGES } from '../../Import/ImportImageModal';
import CircularLoading from '../../navigation/CircularLoading';
import { severity } from '../../Snackbar/CustomizedSnackbar';
import { AuthStateContext, DispatchContext } from '../../store';
import theme from '../../styles/muiThemes/adminConsoleTheme';
import { ALLOWED_SIZE_HUMAN_READABLE } from '../CreateImportQuickView';
import '../DarkTheme.css';


const AdminImportsHelp = (props) => {

	const userAuthentication = useContext(AuthStateContext)
	const navigate = useNavigate()

	/* =================================================================
	Snackbar
	================================================================= */
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	/* =================================================================
	Accordian Logic and styling
	================================================================= */

	const [expanded, setExpanded] = React.useState('item1');

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const Accordion = styled((props) => (
		<MuiAccordion disableGutters elevation={0} square {...props} />
	))(({ theme }) => ({
		border: `1px solid ${theme.palette.divider}`,
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
	}));

	const AccordionSummary = styled((props) => (
		<MuiAccordionSummary
			expandIcon={<ArrowForwardIosIcon sx={{ fontSize: '0.9rem' }} />}
			{...props}
		/>
	))(({ theme }) => ({
		backgroundColor:
			theme.palette.mode === 'dark'
				? 'rgba(255, 255, 255, .05)'
				: 'rgba(0, 0, 0, .03)',
		flexDirection: 'row-reverse',
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(90deg)',
		},
		'& .MuiAccordionSummary-content': {
			marginLeft: theme.spacing(1),
		},
	}));

	/* =================================================================
	Repeated Text
	================================================================= */

	const GeneralRules = () => {
		return (<>
			<ul>
				<li>Character limits factor in new lines, spaces, punctuation, etc.</li>
				<li>Do not rename the columns.</li>
				<li>Do not add any additional columns.</li>
				<li>If additional requirements are needed please reach out to the development team.</li>
			</ul>
		</>)
	}

	const ImportPageLink = (props) => {
		const url = `/admin/imports${props.highlight ? props.highlight : ''}`
		const text = props.text ? props.text : 'import page'
		return (<>
			<Link href={url} target="_blank" underline="none">{text}</Link>
		</>)
	}

	const CharacterLimit = (props) => {
		return (<>
			<p>Character Limit: <b>{props.limit}</b></p>
		</>)
	}

	const TagText = (props) => {
		return (<>
			<CharacterLimit limit={100} />
			<ul>
				<li>Will create the tag if it doesn't already exist.</li>
			</ul>
		</>)
	}

	const NoteText = (props) => {
		return (<>
			<ul>
				<li>Note Type: {props.type}</li>
				<li>Note will <b>{props.critical ? '' : 'not'}</b> be marked as critical{props.critical ? ' and appear in the Quick View' : ''}.</li>
			</ul>
		</>)
	}

	const ListingText = (props) => {
		const type = props.type == 'art' ? 'artwork' : 'contact'
		return (<>
			<ul>
				<li>The unique ID of the Listing you want to add the {type} to. This can be found on the "Listing {'->'} Details Page {'->'} Details Card {'->'} Listing Number".</li>
			</ul>
		</>)
	}

	const PhoneText = (props) => {
		return (<>
			<CharacterLimit limit={15} />
			<ul>
				<li>Phone Type: Mobile</li>
				<li>Must be listed in international format.</li>
				<li>The tool will remove the following characters:
					<ul>
						<li>(</li>
						<li>)</li>
						<li>.</li>
						<li>-</li>
						<li>+</li>
					</ul>
				</li>
				<li>Don't include extensions.</li>
			</ul>
		</>

		)
	}

	const ExtensionText = (props) => {
		return (<>
			<CharacterLimit limit={10} />
			<ul>
				<li>Don't include "x" at the beginning, this is applied automatically in The Platform.</li>
			</ul>
		</>)
	}

	const EmailText = (props) => {
		return (<>
			<CharacterLimit limit={255} />
			<ul>
				<li>Email Type: Personal</li>
			</ul>
		</>)
	}

	const WebsiteText = (props) => {
		return (<>
			<CharacterLimit limit={255} />
			<ul>
				<li>Website Type: Personal</li>
			</ul>
		</>)
	}

	const AddressLines = (props) => {
		return (<>
			<CharacterLimit limit={255} />
			<ul>
				<li>Address Type: Home</li>
				<li>Line breaks will be ignored.</li>
			</ul>
		</>)
	}

	const AddressCountry = (props) => {
		return (<>
			<ul>
				<li>Must match identically to an existing country in The Platform. See Contact {'->'} Details Page {'->'} Contact Information {'->'} Edit Mode {'->'} Add Address for list</li>
				<li>A valid address must have a country and a city/town.</li>
			</ul>
		</>)
	}

	const AddressState = (props) => {
		return (<>
			<CharacterLimit limit={45} />
			<ul>
				<li>If the country is the United States then use the two letter state abbreviation (New York {'->'} NY).</li>
			</ul>
		</>)
	}

	const AddressCity = (props) => {
		return (<>
			<CharacterLimit limit={45} />
			<ul>
				<li>A valid address must have a country and a city/town.</li>
			</ul>
		</>)
	}

	/* =================================================================
	Authorization handling - Admin Auth Logic
	================================================================= */

	useEffect(() => {
		// redirect if the user is not logged in as admin. 
		const url = new URL("/api/admin-auth-status", window.location.origin)
		fetchWithAuth(url)
			.then(resp => resp.json())
			.then(data => {
				if (!data.isAdminAuthenticated) {
					navigate('/admin/login')
				}
			})

	}, [navigate])


	if (userAuthentication && userAuthentication.user) {
		if (userAuthentication.user.permissions.length === 0 || userAuthentication.user.permissions.find(e => e.permission_id == permissions.ADMIN_CONSOLE).permission_value_id !== "1") {
			openSnackbar(severity.ERROR, "Non-admin privileges.")
			navigate('/home')
		}

		return (
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<section className="main-page dark-theme">

						{/* Back to top */}
						{/* <Button
							style={{
								position: 'fixed',
								bottom: '1em',
								right: '1em',
							}}
							onClick={() => {
								console.log('hello')
								window.scrollTo({
									top: 0,
									behavior: 'smooth'
								})
							}}
						>Back to top</Button> */}

						<div style={{
							display: 'flex',
							flexDirection: 'column',
							width: '50%',
							margin: 'auto'
						}}>
							<Paper className="padding-margin-scrollbar">
								<h1>Table of Contents</h1>

								<div>
									<Accordion expanded={expanded === 'item1'} onChange={handleChange('item1')} >
										<AccordionSummary aria-controls="item1d-content" id="item1d-header">
											<Typography>Workflow</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<ul>
												<li><Link underline="none" href="#uploading-data">Uploading Data</Link></li>
												<li><Link underline="none" href="#uploading-images">Uploading Images</Link></li>
											</ul>
										</AccordionDetails>
									</Accordion>
									<Accordion expanded={expanded === 'item2'} onChange={handleChange('item2')}>
										<AccordionSummary aria-controls="item2d-content" id="item2d-header">
											<Typography>General Information</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<ul>
												<li><Link underline="none" href="#status">Import Status</Link></li>
											</ul>
										</AccordionDetails>
									</Accordion>
									<Accordion expanded={expanded === 'item3'} onChange={handleChange('item3')}>
										<AccordionSummary aria-controls="item3d-content" id="item3d-header">
											<Typography>Art</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<ul>
												<li><Link underline="none" href="#Dimension Rules">Dimension Rules</Link></li>
												<li><Link underline="none" href="#Edition Rules">Edition Rules</Link></li>

												<li><Link underline="none" href="#art-template-rules">Art Template Rules</Link></li>
												<ul>
													<li><Link underline="none" href="#Image ID #">Image ID #</Link></li>
													<li><Link underline="none" href="#Art Listing ID">Listing ID</Link></li>
													<li><Link underline="none" href="#Artist First Name">Artist First Name</Link></li>
													<li><Link underline="none" href="#Artist Last Name">Artist Last Name</Link></li>
													<li><Link underline="none" href="#Title">Title</Link></li>
													<li><Link underline="none" href="#Year">Year</Link></li>
													<li><Link underline="none" href="#Medium">Medium</Link></li>
													<li><Link underline="none" href="#Height (cm)">Height (cm)</Link></li>
													<li><Link underline="none" href="#Width (cm)">Width (cm)</Link></li>
													<li><Link underline="none" href="#Depth (cm)">Depth (cm)</Link></li>
													<li><Link underline="none" href="#Height 2 (cm)">Height 2 (cm)</Link></li>
													<li><Link underline="none" href="#Width 2 (cm)">Width 2 (cm)</Link></li>
													<li><Link underline="none" href="#Depth 2 (cm)">Depth 2 (cm)</Link></li>
													<li><Link underline="none" href="#Signed">Signed</Link></li>
													<li><Link underline="none" href="#Edition">Edition</Link></li>
													<li><Link underline="none" href="#Edition Total">Edition Total</Link></li>
													<li><Link underline="none" href="#AP">AP</Link></li>
													<li><Link underline="none" href="#AP Total">AP Total</Link></li>
													<li><Link underline="none" href="#Catalogue Raisonne">Catalogue Raisonne</Link></li>
													<li><Link underline="none" href="#Other Inventory No.">Other Inventory No.</Link></li>
													<li><Link underline="none" href="#Provenance">Provenance</Link></li>
													<li><Link underline="none" href="#Exhibition">Exhibition</Link></li>
													<li><Link underline="none" href="#Literature">Literature</Link></li>
													<li><Link underline="none" href="#Other">Other</Link></li>
													<li><Link underline="none" href="#Art Status">Status</Link></li>
													<li><Link underline="none" href="#Artwork Category">Artwork Category</Link></li>
													<li><Link underline="none" href="#Source Contact ID">Source Contact ID</Link></li>
													<li><Link underline="none" href="#Art Tag 1">Tag 1</Link></li>
													<li><Link underline="none" href="#Art Tag 2">Tag 2</Link></li>
													<li><Link underline="none" href="#Art Tag 3">Tag 3</Link></li>
													<li><Link underline="none" href="#Art Tag 4">Tag 4</Link></li>
													<li><Link underline="none" href="#Art Critical Note">Critical Note</Link></li>
													<li><Link underline="none" href="#Art Critical Note 2">Critical Note 2</Link></li>
													<li><Link underline="none" href="#Art Note">Note</Link></li>
													<li><Link underline="none" href="#Art Is Active">Is Active</Link></li>
												</ul>
											</ul>
										</AccordionDetails>
									</Accordion>
									<Accordion expanded={expanded === 'item4'} onChange={handleChange('item4')}>
										<AccordionSummary aria-controls="item4d-content" id="item4d-header">
											<Typography>Contacts</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<ul>
												<li><Link underline="none" href="#Contact Listing ID">Listing ID</Link></li>
												<li><Link underline="none" href="#Prefix">Prefix</Link></li>
												<li><Link underline="none" href="#First Name">First Name</Link></li>
												<li><Link underline="none" href="#Last Name">Last Name</Link></li>
												<li><Link underline="none" href="#Suffix">Suffix</Link></li>
												<li><Link underline="none" href="#Is Company">Is Company</Link></li>
												<li><Link underline="none" href="#Company Name">Company Name</Link></li>
												<li><Link underline="none" href="#Job Title">Job Title</Link></li>
												<li><Link underline="none" href="#Preferred Name">Preferred Name</Link></li>
												<li><Link underline="none" href="#Foreign Preferred Name">Foreign Preferred Name</Link></li>
												<li><Link underline="none" href="#Salutation">Salutation</Link></li>
												<li><Link underline="none" href="#Type">Type</Link></li>
												<li><Link underline="none" href="#Contact Status">Status</Link></li>
												<li><Link underline="none" href="#Gallery Contact IDs">Gallery Contact IDs</Link></li>
												<li><Link underline="none" href="#Primary Phone">Primary Phone</Link></li>
												<li><Link underline="none" href="#Primary Phone Extension">Primary Phone Extension</Link></li>
												<li><Link underline="none" href="#2nd Phone">2nd Phone</Link></li>
												<li><Link underline="none" href="#2nd Phone Extension">2nd Phone Extension</Link></li>
												<li><Link underline="none" href="#3rd Phone">3rd Phone</Link></li>
												<li><Link underline="none" href="#3rd Phone Extension">3rd Phone Extension</Link></li>
												<li><Link underline="none" href="#Primary Email">Primary Email</Link></li>
												<li><Link underline="none" href="#2nd Email">2nd Email</Link></li>
												<li><Link underline="none" href="#3rd Email">3rd Email</Link></li>
												<li><Link underline="none" href="#Primary Website">Primary Website</Link></li>
												<li><Link underline="none" href="#2nd Website">2nd Website</Link></li>
												<li><Link underline="none" href="#3rd Webite">3rd Webite</Link></li>
												<li><Link underline="none" href="#Primary Address - Line 1">Primary Address - Line 1</Link></li>
												<li><Link underline="none" href="#Primary Address - Line 2">Primary Address - Line 2</Link></li>
												<li><Link underline="none" href="#Primary Address - Line 3">Primary Address - Line 3</Link></li>
												<li><Link underline="none" href="#Primary Address - Country">Primary Address - Country</Link></li>
												<li><Link underline="none" href="#Primary Address - State/Province/Region">Primary Address - State/Province/Region</Link></li>
												<li><Link underline="none" href="#Primary Address - City/Town">Primary Address - City/Town</Link></li>
												<li><Link underline="none" href="#Primary Address - Zip/Postal Code">Primary Address - Zip/Postal Code</Link></li>
												<li><Link underline="none" href="#Secondary Address - Line 1">Secondary Address - Line 1</Link></li>
												<li><Link underline="none" href="#Secondary Address - Line 2">Secondary Address - Line 2</Link></li>
												<li><Link underline="none" href="#Secondary Address - Line 3">Secondary Address - Line 3</Link></li>
												<li><Link underline="none" href="#Secondary Address - Country">Secondary Address - Country</Link></li>
												<li><Link underline="none" href="#Secondary Address - State/Province/Region">Secondary Address - State/Province/Region</Link></li>
												<li><Link underline="none" href="#Secondary Address - City/Town">Secondary Address - City/Town</Link></li>
												<li><Link underline="none" href="#Secondary Address - Zip/Postal Code">Secondary Address - Zip/Postal Code</Link></li>
												<li><Link underline="none" href="#Contact Tag 1">Tag 1</Link></li>
												<li><Link underline="none" href="#Contact Tag 2">Tag 2</Link></li>
												<li><Link underline="none" href="#Contact Tag 3">Tag 3</Link></li>
												<li><Link underline="none" href="#Contact Critical Note">Critical Note</Link></li>
												<li><Link underline="none" href="#Contact Critical Note 2">Critical Note 2</Link></li>
												<li><Link underline="none" href="#Collection Note">Collection Note</Link></li>
												<li><Link underline="none" href="#Bio Note">Bio Note</Link></li>
												<li><Link underline="none" href="#Contact Note">Note</Link></li>
											</ul>
										</AccordionDetails>
									</Accordion>
								</div>





								<Divider />
								<h1 id="workflow">Workflow</h1>

								<h2 id="uploading-data">Uploading Data</h2>
								<ol>
									<li>Download either an Art or Contact template using the links on the <ImportPageLink />.</li>
									<li>Fill out the template with your data.</li>
									<ul>
										<li>The data file cannot be larger than <b>{ALLOWED_SIZE_HUMAN_READABLE}</b>.</li>
									</ul>
									<li>Click the "Create" button on the <ImportPageLink highlight="#:~:text=Create" />.</li>
									<li>Fill out the import type, title and data file.</li>
									<li>Click "Submit".</li>
									<li>The import will run and will progress through five potential <Link underline="none" href="#status">statuses</Link>.</li>
								</ol>
								<p></p>
								<h2 id="uploading-images">Uploading Images</h2>
								<p>You can only upload images to Art imports.</p>
								<ol>
									<li>Click on the completed import you'd like to upload images to.</li>
									<li>Click on "Upload Images" which will open the upload window.</li>
									<li>Click "Choose files from your Computer" or drag the images over the box to upload them.</li>
									<ul>
										<li>You can only upload <b>{MAX_IMAGES}</b> images at a time.</li>
									</ul>
									<li>Once the images are done uploading, they will be in one of three states:</li>
									<ul>
										<li><DoneIcon color="success" fontSize='20px' /> <b>Ready</b> - The image is ready to save.</li>
										<li><PanToolIcon color="warning" fontSize='20px' /> <b>Not Expected</b> - The image isn't a part of this import or it was already uploaded.</li>
										<li><CloseIcon color="error" fontSize='20px' /> <b>Failed</b> - The image wasn't able to upload.</li>
									</ul>
									<li>Click "Save".</li>
								</ol>


								<Divider />
								<h1 id="information">General Information</h1>

								<h2 id="status">Import Status</h2>

								<ul>
									<li><FileUploadOutlinedIcon color="primary" fontSize='20px' /> <b>Uploading</b> - The data file is uploading to the server.</li>
									<li><TimelapseOutlinedIcon color="primary" fontSize='20px' /> <b>Processing</b> - The server is processing the data.</li>
									<li><DoneIcon color="success" fontSize='20px' /> <b>Completed</b> - The import has completed, the data is available for use!</li>
									<li><WarningAmberOutlinedIcon color="warning" fontSize='20px' /> <b>Invalid</b> - The data file was invalid. You can download the error file, fix the issues and re-run the import.</li>
									<li><CloseIcon color="error" fontSize='20px' /> <b>Failed</b> - An unexpected error occured during processing. A system administrator will have to fix the issue, let them know <Link underline="none" target="_blank" href="mailto:tmercadante@jahnelgroup.com">here</Link>.</li>
								</ul>



								<Divider />
								<h1 id="art">Art</h1>
								<GeneralRules />
								<h2 id="Dimension Rules">Dimension Rules</h2>
								<p>All dimensions <b>MUST</b> be in centimeters.</p>
								<ul>
									<li>Limit decimals to a 10th of a cm.</li>
									<li>Units are implied. Don't put "cm" with the number.</li>
									<li>Decimals must be marked with a period, do not use a comma.</li>
									<li>The tool will automatically calculate the imperial dimensions and label.</li>
								</ul>
								<p>The following values will result in the two dimensions labels detailed below:</p>
								<ul>
									<li>Height (cm) = 1.1</li>
									<li>Width (cm) = 5.2</li>
									<li>Depth (cm) = 30.3</li>
									<li>Height 2 (cm) = 2.0</li>
									<li>Width 2 (cm) = 3</li>
									<li>Depth 2 (cm) = 19.0</li>
								</ul>
								<p>Labels:</p>
								<ul>
									<li>⁷⁄₁₆ x 2¹⁄₁₆ x 11¹⁵⁄₁₆ inches<br />(1.1 x 5.2 x 30.3 cm)</li>
									<li>¹³⁄₁₆ x 1³⁄₁₆ x 7½ inches<br />(2 x 3 x 19 cm)</li>
								</ul>
								<h2 id="Edition Rules">Edition Rules</h2>
								<p>The following values will result in the edition label below:</p>
								<ul>
									<li>Edition = 186</li>
									<li>Edition Total = 250</li>
									<li>AP = 26</li>
									<li>AP Total = 26</li>
								</ul>
								<p>Label: 186 of 250, 26 of 26 AP</p>

								<h2 id="art-template-rules">Art Template Rules</h2>
								<h3 id="Image ID #">Image ID #</h3>
								<ul>
									<li>The value should be equivalent to the filename without the extension</li>
									<ul>
										<li>1.jpg should just say 1</li>
									</ul>
									<li>Each Image number ID must be unique according to the point above</li>
								</ul>
								<h3 id="Art Listing ID">Listing ID</h3>
								<ListingText type='art' />
								<h3 id="Artist First Name">Artist First Name</h3>
								<ul>
									<li>Must match identically with the first name (along with <Link underline="none" href="#Artist Last Name">last name</Link>) of an artist in The Platform.</li>
								</ul>
								<h3 id="Artist Last Name">Artist Last Name</h3>
								<ul>
									<li>Must match identically with the last name (along with <Link underline="none" href="#Artist First Name">first name</Link>) of an artist in The Platform.</li>
								</ul>
								<h3 id="Title">Title</h3>
								<CharacterLimit limit={255} />
								<ul>
									<li>Italics will be applied automatically.</li>
								</ul>
								<h3 id="Year">Year</h3>
								<CharacterLimit limit={150} />
								<h3 id="Medium">Medium</h3>
								<CharacterLimit limit={2000} />
								<h3 id="Height (cm)">Height (cm)</h3>
								<p>More details in section <Link underline="none" href="#Dimension Rules">Dimension Rules</Link></p>
								<h3 id="Width (cm)">Width (cm)</h3>
								<p>More details in section <Link underline="none" href="#Dimension Rules">Dimension Rules</Link></p>
								<h3 id="Depth (cm)">Depth (cm)</h3>
								<p>More details in section <Link underline="none" href="#Dimension Rules">Dimension Rules</Link></p>
								<h3 id="Height 2 (cm)">Height 2 (cm)</h3>
								<p>More details in section <Link underline="none" href="#Dimension Rules">Dimension Rules</Link></p>
								<h3 id="Width 2 (cm)">Width 2 (cm)</h3>
								<p>More details in section <Link underline="none" href="#Dimension Rules">Dimension Rules</Link></p>
								<h3 id="Depth 2 (cm)">Depth 2 (cm)</h3>
								<p>More details in section <Link underline="none" href="#Dimension Rules">Dimension Rules</Link></p>
								<h3 id="Signed">Signed</h3>
								<ul>
									<li>No styling can be applied to this text.</li>
								</ul>
								<h3 id="Edition">Edition</h3>
								<CharacterLimit limit={100} />
								<p>More details in section <Link underline="none" href="#Edition Rules">Edition Rules</Link>.</p>
								<h3 id="Edition Total">Edition Total</h3>
								<CharacterLimit limit={100} />
								<p>More details in section <Link underline="none" href="#Edition Rules">Edition Rules</Link>.</p>
								<h3 id="AP">AP</h3>
								<CharacterLimit limit={255} />
								<p>More details in section <Link underline="none" href="#Edition Rules">Edition Rules</Link>.</p>
								<h3 id="AP Total">AP Total</h3>
								<CharacterLimit limit={255} />
								<p>More details in section <Link underline="none" href="#Edition Rules">Edition Rules</Link>.</p>
								<h3 id="Catalogue Raisonne">Catalogue Raisonne</h3>
								<CharacterLimit limit={55} />
								<h3 id="Other Inventory No.">Other Inventory No.</h3>
								<CharacterLimit limit={255} />
								<h3 id="Provenance">Provenance</h3>
								<ul>
									<li>No styling like italics can be applied to this text.</li>
									<li>Indentations and new lines will be respected.</li>
								</ul>
								<h3 id="Exhibition">Exhibition</h3>
								<ul>
									<li>No styling like italics can be applied to this text.</li>
									<li>Indentations and new lines will be respected.</li>
								</ul>
								<h3 id="Literature">Literature</h3>
								<ul>
									<li>No styling like italics can be applied to this text.</li>
									<li>Indentations and new lines will be respected.</li>
								</ul>
								<h3 id="Other">Other</h3>
								<ul>
									<li>No styling like italics can be applied to this text.</li>
									<li>Indentations and new lines will be respected.</li>
								</ul>
								<h3 id="Art Status">Status</h3>
								<ul>
									<li>Must match identically to an existing art status.</li>
								</ul>
								<h3 id="Artwork Category">Artwork Category</h3>
								<ul>
									<li>Must match identically to an existing art category.</li>
									<li>Only one art category can be imported per artwork.</li>
								</ul>
								<h3 id="Source Contact ID">Source Contact ID</h3>
								<ul>
									<li>Must match an existing contact ID in The Platform.</li>
									<li>To get a contact's ID:
										<ol>
											<li>Navigate to the contact.</li>
											<li>Note the contact ID from the URL. If the URL is "platform.levygorvy.com/contact/<b>14267</b>/details" then the contact ID is 14267.</li>
										</ol>
									</li>
								</ul>
								<h3 id="Art Tag 1">Tag 1</h3>
								<TagText />
								<h3 id="Art Tag 2">Tag 2</h3>
								<TagText />
								<h3 id="Art Tag 3">Tag 3</h3>
								<TagText />
								<h3 id="Art Tag 4">Tag 4</h3>
								<TagText />
								<h3 id="Art Critical Note">Critical Note</h3>
								<NoteText critical={true} type={"Note"} />
								<h3 id="Art Critical Note 2">Critical Note 2</h3>
								<NoteText critical={true} type={"Note"} />
								<h3 id="Art Note">Note</h3>
								<NoteText critical={false} type={"Note"} />
								<h3 id="Art Is Active">Is Active</h3>
								<ul>
									<li>Enter "<b>true</b>" in the cell if it's active.</li>
									<li>Leave blank if it's inactive.</li>
								</ul>

								{/* ================= 
								Contacts
								================= */}

								<Divider />
								<h2 id="contact-rules">Contact Rules</h2>
								<h3 id="Contact Listing ID">Listing ID</h3>
								<ListingText type='contact' />
								<h3 id="Prefix">Prefix</h3>
								<ul>
									<li>Must match identically to an existing contact prefix.</li>
								</ul>
								<h3 id="First Name">First Name</h3>
								<CharacterLimit limit={100} />
								<ul>
									<li>Leave blank if company.</li>
								</ul>
								<h3 id="Last Name">Last Name</h3>
								<CharacterLimit limit={100} />
								<ul>
									<li>Leave blank if company.</li>
								</ul>
								<h3 id="Suffix">Suffix</h3>
								<CharacterLimit limit={45} />
								<ul>
									<li>Leave blank if company.</li>
								</ul>
								<h3 id="Is Company">Is Company</h3>
								<ul>
									<li>Enter "<b>true</b>" in the cell if it's a company record.</li>
									<li>Leave blank if it's a person.</li>
								</ul>
								<h3 id="Company Name">Company Name</h3>
								<CharacterLimit limit={255} />
								<h3 id="Job Title">Job Title</h3>
								<CharacterLimit limit={255} />
								<ul>
									<li>Leave blank if company.</li>
								</ul>
								<h3 id="Preferred Name">Preferred Name</h3>
								<CharacterLimit limit={255} />
								<ul>
									<li>Leave blank if company.</li>
								</ul>
								<h3 id="Foreign Preferred Name">Foreign Preferred Name</h3>
								<CharacterLimit limit={255} />
								<ul>
									<li>Leave blank if company.</li>
								</ul>
								<h3 id="Salutation">Salutation</h3>
								<CharacterLimit limit={255} />
								<h3 id="Type">Type</h3>
								<ul>
									<li>Must match identically to an existing contact type.</li>
									<li>Up to three types can be provided in a comma separated list. Examples of valid lists:
										<ul>
											<li>"ATTORNEY,collector"</li>
											<li>"Artist , Social,preSS"</li>
											<li>"Interior DesigneR, Friend"</li>
										</ul>
									</li>
								</ul>
								<h3 id="Contact Status">Status</h3>
								<ul>
									<li>Must match identically to an existing contact status.</li>
								</ul>
								<h3 id="Gallery Contact IDs">Gallery Contact IDs</h3>
								<ul>
									<li>Must match an existing user ID in The Platform.</li>
									<li>To get a user's ID:
										<ol>
											<li>Navigate to the user via the admin console user search.</li>
											<li>Note the user ID from the URL. If the URL is "platform.levygorvy.com/users/<b>2</b>/profile" then the user ID is 2.</li>
										</ol>
									</li>
								</ul>
								<h3 id="Primary Phone">Primary Phone</h3>
								<PhoneText />
								<h3 id="Primary Phone Extension">Primary Phone Extension</h3>
								<ExtensionText />
								<h3 id="2nd Phone">2nd Phone</h3>
								<PhoneText />
								<h3 id="2nd Phone Extension">2nd Phone Extension</h3>
								<ExtensionText />
								<h3 id="3rd Phone">3rd Phone</h3>
								<PhoneText />
								<h3 id="3rd Phone Extension">3rd Phone Extension</h3>
								<ExtensionText />
								<h3 id="Primary Email">Primary Email</h3>
								<EmailText />
								<h3 id="2nd Email">2nd Email</h3>
								<EmailText />
								<h3 id="3rd Email">3rd Email</h3>
								<EmailText />
								<h3 id="Primary Website">Primary Website</h3>
								<WebsiteText />
								<h3 id="2nd Website">2nd Website</h3>
								<WebsiteText />
								<h3 id="3rd Webite">3rd Webite</h3>
								<WebsiteText />
								<h3 id="Primary Address - Line 1">Primary Address - Line 1</h3>
								<AddressLines />
								<h3 id="Primary Address - Line 2">Primary Address - Line 2</h3>
								<AddressLines />
								<h3 id="Primary Address - Line 3">Primary Address - Line 3</h3>
								<AddressLines />
								<h3 id="Primary Address - Country">Primary Address - Country</h3>
								<AddressCountry />
								<h3 id="Primary Address - State/Province/Region">Primary Address - State/Province/Region</h3>
								<AddressState />
								<h3 id="Primary Address - City/Town">Primary Address - City/Town</h3>
								<AddressCity />
								<h3 id="Primary Address - Zip/Postal Code">Primary Address - Zip/Postal Code</h3>
								<CharacterLimit limit={10} />
								<h3 id="Secondary Address - Line 1">Secondary Address - Line 1</h3>
								<AddressLines />
								<h3 id="Secondary Address - Line 2">Secondary Address - Line 2</h3>
								<AddressLines />
								<h3 id="Secondary Address - Line 3">Secondary Address - Line 3</h3>
								<AddressLines />
								<h3 id="Secondary Address - Country">Secondary Address - Country</h3>
								<AddressCountry />
								<h3 id="Secondary Address - State/Province/Region">Secondary Address - State/Province/Region</h3>
								<AddressState />
								<h3 id="Secondary Address - City/Town">Secondary Address - City/Town</h3>
								<AddressCity />
								<h3 id="Secondary Address - Zip/Postal Code">Secondary Address - Zip/Postal Code</h3>
								<CharacterLimit limit={10} />
								<h3 id="Contact Tag 1">Tag 1</h3>
								<TagText />
								<h3 id="Contact Tag 2">Tag 2</h3>
								<TagText />
								<h3 id="Contact Tag 3">Tag 3</h3>
								<TagText />
								<h3 id="Contact Critical Note">Critical Note</h3>
								<NoteText critical={true} type={"Note"} />
								<h3 id="Contact Critical Note 2">Critical Note 2</h3>
								<NoteText critical={true} type={"Note"} />
								<h3 id="Collection Note">Collection Note</h3>
								<NoteText critical={false} type={"Collection"} />
								<h3 id="Bio Note">Bio Note</h3>
								<NoteText critical={false} type={"Bio"} />
								<h3 id="Contact Note">Note</h3>
								<NoteText critical={false} type={"Note"} />
							</Paper>
						</div>
					</section>
				</ThemeProvider >
			</StyledEngineProvider >
		)
	} return (
		<CircularLoading />
	)
}

export default withApollo(AdminImportsHelp)
