import { gql } from '@apollo/client'
import { userDataCompressed } from '../common/fragments'


export const ADMIN_GET_USER = gql`
	query user($id: ID, $thumbnailResolution: String = "1024x1024") {
		adminGetUser(id: $id) {
			...userDataCompressed 
			email
			department_id
			title
			prefix_id
			suffix
			gallery_id

			created_at
				
			phone_label1
			phone_number1
			phone_label2
			phone_number2

			is_deleted
			gallery

			last_login
			permissions {
				permission_id
				permission_value_id
			}
			goals {
				id
				goal_type_id
				user_id
				start_at
				end_at
				target
				progress
				type
			}
			assistants {
				id
				first_name
				last_name
			}
			access_to {
				id
				first_name
				last_name
			}
			identity_provider
			user_group {
				id
				gallery_id
				gallery {
					id
					value
				}
				user_group_type_id
				type {
					id
					value
				}
				is_lead
			}
		}
		getCompletePermissions {
			permission_id
			path
			parent_id
			level
			permission_relationship_type_id
			permission_name
			permission_type
			relationship_type
			ordinal
		}
		getUserCount
	}
	${userDataCompressed}
`

export const GET_USERS = gql`
	query GetUsers(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$query: String
		$active: Boolean
		$thumbnailResolution: String = "128x128"
	) {
		getUsers(
			input: {
				field: $field
				direction: $direction
				limit: 10
				cursor: $cursor
				query: $query
				includeDeleted: true
				active: $active
			}
		) {
			items {
				...userDataCompressed

				department_id
				title
				email
				department
				gallery

				created_at
				
				phone_label1
				phone_number1
				phone_label2
				phone_number2
				
				active
				is_deleted
				identity_provider
			}
			cursor
			totalItems
		}
	}
	${userDataCompressed}
`

export const GET_USER = gql`
	query user($id: ID) {
		getUser( id: $id ) {
			id
			first_name
			last_name
			email
			department_id
			title
			prefix_id
			suffix
			gallery_id
				
			phone_label1
			phone_number1
			phone_label2
			phone_number2

			is_deleted
			gallery
			imgUrl (thumbnailResolution: "128x128")
			permissions {
				permission_id
				permission_value_id
			}
			goals {
				id
				goal_type_id
				user_id
				start_at
				end_at
				target
				progress
				type
			}
			assistants {
				id
				first_name
				last_name
			}
			access_to {
				id
				first_name
				last_name
			}
			identity_provider
			user_group {
				id
				gallery_id
				gallery {
					id
					value
				}
				user_group_type_id
				type {
					id
					value
				}
				is_lead
			}
		}
		getCompletePermissions {
			permission_id
			path
			parent_id
			level
			permission_relationship_type_id
			permission_name
			permission_type
			relationship_type
			ordinal
		}
	}
`

export const UPDATE_USER = gql`
	mutation updateUser(
		$UserInput: UserInput!
		$thumbnailResolution: String = "128x128"
	) {
    updateUser(input: $UserInput) {
			code
			success
			message
			user {
				...userDataCompressed

				email
				department_id
				title
				prefix_id
				suffix
				gallery_id
				
				phone_label1
				phone_number1
				phone_label2
				phone_number2

				is_deleted
				gallery
				profile_link
				identity_provider
				permissions {
					permission_id
					permission_value_id
				}
				goals {
					id
					goal_type_id
					user_id
					start_at
					end_at
					target
					progress
					type
				}
				assistants {
					id
					first_name
					last_name
				}
				user_group {
					id
					gallery_id
					gallery {
						id
						value
					}
					user_group_type_id
					type {
						id
						value
					}
					is_lead
				}
			}
        }
    }
	${userDataCompressed}
`

export const UPDATE_PERMISSIONS = gql`
	 mutation updateUserPermissions($PermissionsInput: [UserPermissionInput]!) {
        updateUserPermissions(input: $PermissionsInput) {
			code
			success
			message
			permissions {
				user_id
				permission_id
				permission_value_id
			}
        }
    }
`

export const GET_USER_DATALOG = gql`
	query getUserDataLog(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$id: ID!
		$date: String
		$items: [String]
		$item_ids: [ID]
		$actions: [Item]
		$user_ids: [ID]
		$thumbnailResolution: String = "128x128"
	) 
	{
		getUserDataLog(
			input: {
				field: $field
				direction: $direction
				limit: 10
				cursor: $cursor
				filters: {
					id: $id
					date: $date
					items: $items
					item_ids: $item_ids
					actions: $actions
					user_ids: $user_ids
				}
			}
		)
		{
			cursor
			totalItems
			
			items {
				id
				user_id
				item
				item_id
				action
				diff
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				user {
					...userDataCompressed
					title
				}
			}
		}
	}
	${userDataCompressed}
`

export const CREATE_USER = gql`
	mutation createUser(
		$UserInput: UserInput!
		$thumbnailResolution: String = "128x128"
	) {
		createUser(input: $UserInput) {
			code
			success
			message
			user {
				...userDataCompressed
				email
				department_id
				title
				prefix_id
				suffix
				gallery_id
				
				phone_label1
				phone_number1
				phone_label2
				phone_number2

				is_deleted
				gallery
				department
				permissions {
					permission_id
					permission_value_id
				}
				goals {
					id
					goal_type_id
					user_id
					start_at
					end_at
					target
					progress
					type
				}
				profile_link
			}
		}
	}
	${userDataCompressed}
`

export const CREATE_IMPORT = gql`
	mutation createImport(
		$ImportInput: ImportInput!
	){
		createImport(input: $ImportInput){
			code
			success
			message
			import{
				id
				type_id
				type {
					id
					value
				}
				status_id
				status {
					id
					value
				}
				name
				
				error_count
				execution_time_ms

				s3_error_file
				s3_runtime_error_file

				inserted_row
				inserted_note
				inserted_tag
				inserted_tag_link
				inserted_image
				inserted_image_id
				inserted_image_link
				inserted_image_id

				art_max_inv_number
				art_inserted_crnumber
				art_inserted_research
				art_inserted_listing
				art_inserted_edition_copy
				art_inserted_edition_copy_total
				art_inserted_edition_ap
				art_inserted_edition_ap_total
				art_inserted_other_inventory
				art_inserted_owner_contact_id

				contact_inserted_phone
				contact_inserted_email
				contact_inserted_address
				contact_inserted_website
				contact_inserted_type
				contact_inserted_listing

				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				is_private
			}
		}
	}
`

export const GET_IMPORT = gql`
	query getImport(
		$importId: ID!
	){
		getImport(id: $importId){
			time
			import {
				id
				type_id
				type {
					id
					value
				}
				status_id
				status {
					id
					value
				}
				name
				
				error_count
				execution_time_ms

				s3_error_file
				s3_runtime_error_file

				inserted_row
				inserted_note
				inserted_tag
				inserted_tag_link
				inserted_image
				inserted_image_id
				inserted_image_link
				inserted_image_id

				art_max_inv_number
				art_inserted_crnumber
				art_inserted_research
				art_inserted_listing
				art_inserted_edition_copy
				art_inserted_edition_copy_total
				art_inserted_edition_ap
				art_inserted_edition_ap_total
				art_inserted_other_inventory
				art_inserted_owner_contact_id

				contact_inserted_phone
				contact_inserted_email
				contact_inserted_address
				contact_inserted_website
				contact_inserted_type
				contact_inserted_listing

				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				is_private
			}
		}
	}
`

export const GET_IMPORT_ERROR_LINK = gql`
	query Query($importId: ID!) {
  		getImportErrorLink(id: $importId)
}
`

export const GET_IMPORT_IMAGE_LINKS = gql`
	query GetImportImageUploadLinks($getImportImageUploadLinksId: ID!, $input: [JSON]) {
		getImportImageUploadLinks(id: $getImportImageUploadLinksId, input: $input) {
			valid
			presignedurl {
				url
				key
			}
		}
	}
`

export const SAVE_IMPORT_IMAGES = gql`
	mutation SaveImportImages($importId: ID!, $input: [JSON]) {
  		saveImportImages(id: $importId, input: $input) {
    		code
    		success
    		message
    		results
    		severity
  	}
}
`

export const GET_ART_TEMPLATE = gql`
	query artTemplate {
		getArtTemplate {
			imgUrl
		}
	}
`

export const GET_CONTACT_TEMPLATE = gql`
	query contactTemplate {
		getContactTemplate {
			imgUrl
		}
	}
`

export const GET_COMPLETE_PERMISSIONS = gql`
	query getCompletePermissions {
		getCompletePermissions {
			permission_id
			path
			parent_id
			level
			permission_relationship_type_id
			permission_name
			permission_type
			relationship_type
			ordinal
		}
	}
`

export const GET_USER_GROUP_BY_GALLERY = gql`
	query getUserGroupsByGallery($id: ID!) {
		getUserGroupsByGallery(id: $id) {
			id
			user_id
			user {
				id
				first_name
				last_name
				imgUrl (thumbnailResolution: "128x128")
				user_handle
				gallery
				department
				prefix_id
				email
				department_id
				gallery_id
				phone_label1
				phone_number1
				phone_label2
				phone_number2
				title
				suffix
				profile_link
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
			}
			gallery_id
			user_group_type_id
			type {
				id
				value
			}
			is_lead
			created_by
			created_at
			modified_by
			modified_at
			is_deleted
		}
	}
`

export const ADD_USER_TO_GROUP= gql`
	mutation addUserToGroup($UserGroupInput: UserGroupInput) {
		addUserToGroup(input: $UserGroupInput) {
			code
			success
			message
			severity
			user_groups {
				id
				user_id
				user {
					id
					first_name
					last_name
					imgUrl (thumbnailResolution: "128x128")
					user_handle
					gallery
					department
					prefix_id
					email
					department_id
					gallery_id
					phone_label1
					phone_number1
					phone_label2
					phone_number2
					title
					suffix
					profile_link
					created_by
					created_at
					modified_by
					modified_at
					is_deleted
				}
				gallery_id
				user_group_type_id
				type {
					id
					value
				}
				is_lead
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
			}
		}
	}
`

export const REMOVE_USER_GROUP = gql`
	mutation removeUserGroup($id: ID!) {
		removeUserGroup(id: $id) {
			code
			success
			message
			severity
		}
	}
`

export const UPDATE_USER_GROUP = gql`
	mutation updateUserGroup($id: ID!, $is_lead: Boolean!) {
		updateUserGroup(id: $id, is_lead: $is_lead) {
			code
			success
			message
			severity
		}
	}
`
