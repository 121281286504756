/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useState } from 'react'
import { useMutation } from '@apollo/client'
import { IconButton, Rating } from '@mui/material'
import { favoriteEntityRow, flagEntityRow } from './helpers'
import { TOGGLE_FAVORITE, TOGGLE_FLAG } from './queries'
import FlagIcon from '@mui/icons-material/Flag'
import { DispatchContext } from '../store'
import { CLEAR_FLAGS } from '../table/Queries'
import { severity } from '../Snackbar/CustomizedSnackbar'

/**
 * @typedef {Object} FavoriteAndFlagParams
 * @property {'listing' | 'art' | 'artist' | 'contact' | 'deal'} entityName
 * @property {SearchNav} searchNav - current search being navigated
 * @pro
 */

/**
 * 
 * @param {FavoriteAndFlagParams} param0 
 * @returns 
 */
const FavoriteAndFlag = ({
	row,
	entityName,
	userId,
	setRows,
	setEntity,
	setFlaggedCount,
	compact,
	hidden,
	onClick
}) => {
	const [toggleFavorite] = useMutation(TOGGLE_FAVORITE)
	const [toggleFlagged] = useMutation(TOGGLE_FLAG)

	const [toggleLoading, setToggleLoading] = useState(null)
	
	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch] )


	// Only to clear flags if user asks to.
	const [clearFlagsMutation] = useMutation(CLEAR_FLAGS)

	const clearFlags = () => {
		let object = entityName === 'Deal' ? 'deal' : entityName.toLowerCase()

		setFlaggedCount?.(0)

		return clearFlagsMutation({
			variables: {
				user_id: userId,
				object
			}
		}).then(({ data }) => {
			if (data.clearFlags?.success === true) {
				openSnackbar(severity.SUCCESS, data.clearFlags.message)
				setRows(rows => rows.map(el => ({
					...el,
					isFlagged: false
				})))
			} else {
				openSnackbar(severity.ERROR, data.clearFlags.message)
			}
		}).catch(error => {
			console.error(error)
			openSnackbar(severity.ERROR, "Could not clear flags.")
		})
	}

	const handleStarClick = (e) => {

		if (toggleLoading == row.id || hidden || row.is_deleted)
			return
		let clear = false
		e?.stopPropagation?.()
		e?.preventDefault?.()
		;(onClick || Promise.reject.bind(Promise))()
			.then(() => {
				clear = true
				return clearFlags()
			})
			.finally(() => {
				flagEntityRow (e, row, toggleLoading, setToggleLoading, toggleFlagged, userId, entityName, openSnackbar, () => {
					
					// Success
					if (row.isFlagged && setFlaggedCount) setFlaggedCount(cur => cur - 1 )
					else if (!row.isFlagged && setFlaggedCount) setFlaggedCount(cur => cur + 1)

					if (setEntity) {
						setEntity(entity => {
							return entity.map(e => {
								if (e.id == row.id) {
									return {
										...e,
										isFlagged: !row.isFlagged,
									}
								} else {
									return e
								}
							})
						})
					} else {
						setRows(rows => {
							let newRows = rows.map(r => {
								if (r.id == row.id) {
									return ({
										...r,
										isFlagged: !row.isFlagged,
									})
								} else {
									return ({
										...r,
										isFlagged: clear ? false : r.isFlagged
									})
								}
							})
							return newRows
						})
					}
				})
			})

	}

	if (row.disabled) return <span>-</span>
	return (
		<div style={{
			display: 'flex',
			flexWrap: 'wrap',
			flexDirection: compact ? 'row' : 'column',
			justifyContent: 'center',
			alignItems: 'center',
			overflow: 'hidden'
		}}>
			<Rating name="customized-1"
				// classes={{ root: 'w-[18px] h-[18px]' }}
				// sx={{ width: 18, height: 18 }}
				size="small"
				// icon={(Icon) => <Icon sx={{ width: 18, height: 18 }}/>}
				value={row.isFavorite ? 1 : -1} max={1}
				onClick={(e) => {

					if (toggleLoading == row.id || hidden || row.is_deleted)
						return

					favoriteEntityRow(
						e, row, toggleLoading, setToggleLoading, toggleFavorite, userId,
						entityName, openSnackbar, () => {

							// Success
							if (setEntity) {
								setEntity(entity => {
									return entity.map(e => {
										if (e.id == row.id) {
											return {
												...e,
												isFavorite: !row.isFavorite,
											}
										} else {
											return e
										}
									})
								})
							} else {
								setRows(rows => {
									return rows.map(r => {
										if (r.id == row.id) {
											return {
												...r,
												isFavorite: !row.isFavorite,
											}
										} else {
											return r
										}
									})
								})
							}
						})
				}}/>

			<IconButton aria-label="flagged" size="small" className="zoom-on-hover"
				style={{
					height: 'fit-content',
					backgroundColor: 'transparent'
				}} 
				onClick={handleStarClick}
				disableRipple
			>
				<FlagIcon style={ row.isFlagged ?
					{ color: '#cc3333', width: 18, height: 18 } : {color: '#919191', width: 18, height: 18 }
				} />
			</IconButton>
		</div>
	)
}

export default FavoriteAndFlag
