/* eslint-disable eqeqeq */
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { FormControl, IconButton } from '@mui/material'
import React, { useEffect } from 'react'
import { formatDate, returnDate } from '../../common/helpers'
import { CancelButton, SubmitButton } from '../../input/Buttons'
import Label from '../../input/Label'
import TextBox from '../../input/Text/TextBox'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import CloseIcon from '@mui/icons-material/Close'

const DatesModal = ({
	open, 
	close,
	contactInput,
	setContactInput,
	isPrivate,
	loading,
	error
}) => {

	const [datesModalInput, setDatesModalInput] = React.useState(contactInput)
	const [newDateLabel, setNewDateLabel] = React.useState('')
	const [newDate, setNewDate] = React.useState(null)

	useEffect(() => {
		setDatesModalInput(contactInput)
	}, [contactInput])

	return (
		<TransitionsModal
			className="type-modal"
			open={open}
			close={() => {
				setNewDateLabel('')
				setNewDate(null)
				close()
			}}
		>
			<div className={isPrivate ? "private-object" : null}>
				<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
					<h1 className="card-title">Contact Dates</h1>
					<div>
						<CancelButton
							variant="contained"
							onClick={() => {
								setDatesModalInput(contactInput)
								setNewDateLabel('')
								setNewDate(null)
								close()
							}}
							style={{ height: '40px', marginRight: '1em'}}
						>Cancel
						</CancelButton>
						<SubmitButton
							variant="contained"
							disabled={datesModalInput.dates?.some(date => !date.label)}
							onClick={() => {
								setContactInput({
									...contactInput,
									dates: datesModalInput.dates
								})
								close()
							}}
							style={{ height: '40px'}}
						>Submit
						</SubmitButton>
					</div>
				</div>

				<div style={{display: "flex", flexDirection: 'column',}}>

					{datesModalInput.dates?.map((date, i) => {
						
						return (
							<div style={{
								display: 'flex',
								alignItems: 'end',
								flexDirection: 'row',
							}} key={`date-modal-input-${i}`}>
								<FormControl sx={{ marginRight: '1em' }}>
									<Label 
										required
										error={!date.label?.length}
										style={{fontSize: "18px"}} 
										disableAnimation 
										shrink
									>
										Label
									</Label>
									<TextBox
										value={date.label}
										error={!date.label?.length}
										onChange={(e) => {
											setDatesModalInput({
												...datesModalInput,
												dates: datesModalInput.dates?.map(el => el.id == date.id ? {
													...el,
													label: e.target.value
												} : el)
											})
										}}
									/>
								</FormControl>
						
								<FormControl>
									<Label
										disableAnimation
										shrink
									>
                      					Date
									</Label>

									<MobileDatePicker
										inputFormat="MMM do, yyyy"
										disableMaskedInput={true}
										cancelText={null}
										showTodayButton
										todayLabel="Now"
										openTo="year"
										views={['year', 'month', 'day']}
										name="date"
										renderInput={({ inputRef, inputProps, InputProps }) => {
								
											const newProps = { ...inputProps}

											newProps.readOnly = false

											return (
												<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
											)}}
										style={{ marginTop: '17px' }}
										className="MUIDatePicker"
										variant="dialog"
										value={returnDate(date.date)}
										onChange={(newDate) => {
											setDatesModalInput({
												...datesModalInput,
												dates: datesModalInput.dates?.map(el => el.id == date.id ? {
													...el,
													date: newDate ? formatDate(newDate, 'yyyy-MM-dd') : null
												} : el)
											})
										}}
									/>
								</FormControl>

								<IconButton
									color="primary" 
									aria-label="remove date" 
									sx={{ marginLeft: '0.5em'}}
									onClick={() => {
										setDatesModalInput({
											...datesModalInput,
											dates: datesModalInput.dates.filter(el => el.id != date.id)
										})
									}}
								>
									<CloseIcon style={{color: 'grey'}}/>
								</IconButton>
							</div>
						)

					})}
				</div>

				<div style={{
					display: 'flex',
					alignItems: 'end',
				}}>
					<FormControl sx={{ marginRight: '1em' }}>
						<Label 
							required
							style={{fontSize: "18px"}} 
							disableAnimation 
							shrink
						>
							Label
						</Label>
						<TextBox
							value={newDateLabel}
							onChange={(e) => {
								setNewDateLabel(e.target.value)
							}}
						/>
					</FormControl>
						
					<FormControl sx={{ marginRight: '1em' }}>
						<Label
							disableAnimation
							shrink
							required
						>
							Date
						</Label>

						<MobileDatePicker
							inputFormat="MMM do, yyyy"
							disableMaskedInput={true}
							cancelText={null}
							showTodayButton
							todayLabel="Now"
							openTo="year"
							views={['year', 'month', 'day']}
							name="date"
							renderInput={({ inputRef, inputProps, InputProps }) => {
								
								const newProps = { ...inputProps}

								newProps.readOnly = false

								return (
									<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
								)}}
							inputVariant="outlined"
							style={{ marginTop: '17px' }}
							className="MUIDatePicker"
							variant="dialog"
							value={returnDate(newDate)}
							onChange={(date) => {
								setNewDate(date ? formatDate(date, 'yyyy-MM-dd') : null)
							}}
						/>
					</FormControl>

					<SubmitButton
						variant="contained"
						disabled={!newDateLabel || !newDate}
						onClick={() => {
							setNewDate(null)
							setNewDateLabel('')
							setDatesModalInput({
								...datesModalInput,
								dates: datesModalInput.dates.concat({
									contact_id: contactInput.id,
									date: newDate,
									label: newDateLabel,
								})
							})
						}}
						style={{ height: '44px'}}
					>Add
					</SubmitButton>
				</div>
			</div>
		</TransitionsModal>
	)
}

export default DatesModal
