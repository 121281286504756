import AuthButton from "./AuthButtons"
import { CircularProgress } from '@mui/material'

const Login = (props) => {

	const buttonStack = props.logins.map( (btn,i) => {
		const url = window.location.pathname === '/admin/login' ? btn.loginUrls.admin : btn.loginUrls.login
		return (
			<AuthButton
				url={url}
				key={`loginButton-${i}`}
				logo={btn.imgSrc}
				name={btn.imgAltText}
				identityProvider={btn.name}
			/>
		)
	} )

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: "space-around",

				width: "100%",
				height: "100vh"
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
					height: "100%",
					alignItems: 'center',
					backgroundColor: "#1C1C1D",
				}}
			>
				<h1
					style={{
						fontWeight: "400",
						fontSize: "144px",
						width: "100%",
						textAlign: "center",
						color: "#fff",
						marginBottom: "0px"
					}}
				>The Platform</h1>
				<h2 
					style = {{ 
						fontSize: "32px", 
						fontWeight: "400",
						color: "#fff",

					}}> 
					Powered by Lévy Gorvy Dayan
				</h2>
				
				{props.loading ? <CircularProgress style={{'color': 'white', height: "4em", width: "4em" }}/> : buttonStack}

			</div>
		</div>
	)
}

export default Login
