/* eslint-disable eqeqeq */
import React from 'react'
import { useQuery } from '@apollo/client'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { GET_USERS } from "../Queries.js"
import Label from '../../../input/Label'
import {FormControl} from "@mui/material"
import { Skeleton } from '@mui/material'
import { autoCompleteStyles } from '../../../styles/makesStyles.js'
import { getGalleryContactLabel } from '../../../common/helpers.js'

/**
 * This react functional component queries the possible salespersons to select for
 * use in details pages. It shows the possibilities in a 
 * autocomplete component. 
 * 
 * @param {*} props 
 */
export default function SalespersonSelection(props) {

	const { loading, error, data } = useQuery(GET_USERS, { variables: {
		includeDeleted: true
	}})
	if (error) {
		props.close()
		return null
	}

	let options = data?.getUsers.items || []
	let spSelection = []
	let pspSelection = []

	/*
		These for loops determine the correct salesperson selections based on 
		the props and supplied query data for the current entity.
	*/
	if (!loading && data && props.salespersons && props.primary_salespersons) {
		for (let i = 0; i < props.salespersons.length; i++) {
			spSelection[i] = options?.find(
				(item) => item.id === props.salespersons[i].id
			)
		}

		for (let i = 0; i < props.primary_salespersons.length; i++) {
			pspSelection[i] = spSelection?.find(
				(item) => item.id === props.primary_salespersons[i].id
			)
		}
	}

	return (
		<>
			<FormControl className="salesperson-formcontrol">
				<Autocomplete
					multiple
					size="small"
					options={options}
					value={spSelection}
					getOptionLabel={option => getGalleryContactLabel(option)}
					onChange={(event, value) => {

						// ids of values passed on change
						const valueIds = value.map(e => e.id)

						// duplicate inputs
						let newSalespersons =  [...props.input.salespersons]
							.map(o => ({...o}))

						let newPrimarySalespersons =  [...props.input.primary_salespersons]
							.map(o => ({...o}))

						// Add to input
						value.forEach(item => {
							if (!newSalespersons.map(e => e.id).includes(item.id)) {
								newSalespersons.push({
									id: item.id,
									first_name: item.first_name,
									last_name: item.last_name,
									is_deleted: item.is_deleted,
									is_lead: false,
								})
							}
						})

						// remove from entities inputs if required
						newSalespersons = newSalespersons.filter(sp => {
							if (!valueIds.includes(sp.id))
								return false
							return true
						})

						newPrimarySalespersons = newPrimarySalespersons.filter(sp => {
							if (!valueIds.includes(sp.id))
								return false
							return true
						})
					
						props.setInput({
							...props.input,
							salespersons: newSalespersons,
							primary_salespersons: newPrimarySalespersons
						})
					}}
					classes={autoCompleteStyles()}
					renderInput={params => (
						<>
							<FormControl>
								<Label shrink id="salesperson-label" htmlFor="salesperson-autocomplete">
									Gallery Contacts
								</Label>

								{(loading || !data) ? <Skeleton
									animation={props.error ? false : "wave"}
									variant="text"
									width="30em"
									height='4.5em'
									style={{marginTop: '0.7em'}}
								/>
									:
									<TextField
										autoFocus
										{...params}
										id="salesperson-autocomplete"
										variant="outlined"
										fullWidth
										style={{width: "30em", paddingTop: "2em"}}
										classes={{ notchedOutline: null }} 
										InputLabelProps={{
											shrink: true
										}}
									/>
								}
							</FormControl>
						</>
					)}
					data-testid="salesperson-gallery-contact"
				/>
			</FormControl> <br/>
			
			<FormControl className="primary-salesperson-formcontrol">
				<Autocomplete
					multiple
					size="small"
					options={spSelection}
					value={pspSelection}
					getOptionLabel={option => {
						return option.first_name + " " + option.last_name
					}}
					onChange={(event, value) => {

						// ids of values passed on change
						const valueIds = value.map(e => e.id)

						let newSalespersons =  [...props.input.salespersons]
							.map(o => ({...o}))

						let newPrimarySalespersons =  [...props.input.primary_salespersons]
							.map(o => ({...o}))

						// Add to input
						value.forEach(item => {
							if (!newPrimarySalespersons.map(e => e.id).includes(item.id)) {
								newPrimarySalespersons.push({
									id: item.id,
									first_name: item.first_name,
									last_name: item.last_name,
									is_deleted: item.is_deleted,
									is_lead: true,
								})

								newSalespersons = newSalespersons.map(sp => {
									if (sp.id == item.id) {
										return {
											id: sp.id,
											first_name: sp.first_name,
											last_name: sp.last_name,
											is_deleted: item.is_deleted,
											is_lead: true,
										}
									}
									return sp
								})
							}
						})

						// remove from inputs if required
						newPrimarySalespersons = newPrimarySalespersons.filter(sp => {
							if (!valueIds.includes(sp.id)) {

								newSalespersons = newSalespersons.map(s => {
									if (s.id == sp.id) {
										return {
											id: s.id,
											first_name: s.first_name,
											last_name: s.last_name,
											is_deleted: s.is_deleted,
											is_lead: false,
										}
									}
									return s
								})

								return false
							}
							return true
						})

						props.setInput({
							...props.input,
							salespersons: newSalespersons,
							primary_salespersons: newPrimarySalespersons
						})
						
					}}
					classes={autoCompleteStyles()}
					renderInput={params => (
						<>
							<FormControl>
								<Label shrink id="salesperson-label" htmlFor="salesperson-autocomplete">
									Primary Gallery Contacts
								</Label>

								{(loading || !data) ? <Skeleton
									animation={props.error ? false : "wave"}
									variant="text"
									width="30em"
									height='4.5em'
									style={{marginTop: '0.7em'}}
								/>
									:
									<TextField
										{...params}
										id="salesperson-autocomplete"
										variant="outlined"
										fullWidth
										style={{width: "30em", paddingTop: "2em"}}
										classes={{ notchedOutline: null }} 
										InputLabelProps={{
											shrink: true
										}}
									/>
								}
							</FormControl>
						</>
					)}
					data-testid="salesperson-primary-gallery-contact"
				/>
			</FormControl>
		</>
	)

}
