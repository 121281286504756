import { CancelButton, SubmitButton } from "../../../input/Buttons"
import TransitionsModal from "../../../navigation/TransitionsModal/TransitionsModal"



export default function VerifyModal (props) {
	
	return <TransitionsModal
		className="verified-modal"
		open={!!props.open}
		close={props.onClose}
	>
		<div style={{ minWidth: '20em' }}>
			<h1 className="card-title">
				<span>Warning</span>
			</h1>

			<div>
				<p>
					Would you like to also update the { props?.name }?
				</p>
			</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					paddingTop: '1em',
				}}
			>
				<CancelButton
					variant="contained"
					onClick={props.onClose}
					style={{ marginRight: 'auto' }}
				>
					No
				</CancelButton>

				<SubmitButton
					variant="contained"
					onClick={() => {
						props.onChange({ target: {
							name: props.field,
							value: props.value
						}})
						props.onClose()
					}}
					style={{ marginLeft: '1em' }}
				>
					Yes
				</SubmitButton>
			</div>
		</div>
	</TransitionsModal>
}
