import ExpandMoreRounded from "@mui/icons-material/ExpandMoreRounded"
import { FormControl, MenuItem, Select } from "@mui/material"
import { ESOrder } from "../common/typescriptVars"
import Dropdown from "../input/Dropdown/Dropdown"
import ThickDropdown from "../input/Dropdown/ThickDropdown"
import Label from "../input/Label"


interface ShowcaseSortOrderProps {
	onOrderChange: (newOrder: ESOrder) => void
	onOrderByChange: (newOrderBy: string) => void
	order: ESOrder | undefined
	orderBy: string | undefined
	largeSize?: boolean
	showRelevance?: boolean
	width?: string 
	sortOptions?: any[]
}

const sortOptionsList = [
	{
		value: '-',
		label: 'Relevance'
	}, {
		value: 'year-ASC',
		label: 'Date – Oldest first'
	}, {
		value: 'year-DESC',
		label: 'Date – Newest first'
	}, {
		value: 'artist_last_name-ASC',
		label: 'Artist Last Name – A first'
	}, {
		value: 'artist_last_name-DESC',
		label: 'Artist Last Name – Z first'
	}, {
		value: 'art_status_value-ASC',
		label: 'Status – A first'
	}, {
		value: 'art_status_value-DESC',
		label: 'Status – Z first'
	}, {
		value: 'height_metric-ASC',
		label: 'Height – Smallest first'
	}, {
		value: 'height_metric-DESC',
		label: 'Height – Largest first'
	}, {
		value: 'retail-ASC',
		label: 'Retail – Lowest first'
	}, {
		value: 'retail-DESC',
		label: 'Retail – Highest first'
	}
]

export default function ShowcaseSortOrder(props: ShowcaseSortOrderProps) {

	let sortOptions = [...sortOptionsList]
	if (!props.showRelevance) {
		sortOptions.shift()
	}

	return <FormControl sx={{
		flexBasis: !props.width ? '15em' : null,
		width: props.width ?? null,
		marginRight: '1em'
	}}>
		<Label disableAnimation shrink >
			Sort results by
		</Label>
		<Select
			IconComponent={ExpandMoreRounded}
			className="padded-select"
			input={props.largeSize ? <ThickDropdown /> : <Dropdown />}
			value={[props.orderBy || '', props.order || ''].join('-')}
			onChange={(event) => {
				const [orderBy, order] = event.target.value.split('-')
				props.onOrderByChange(orderBy)
				props.onOrderChange(order as ESOrder)
			}}
		>
			{(props.sortOptions ?? sortOptions).map((option: any, i: number) =>
				<MenuItem value={ option.value } key={i}>
					{ option.label }
				</MenuItem>
			)}
		</Select>
	</FormControl>

}
