import { FormControl, Paper } from '@mui/material'
import React from 'react'
import Label from '../../input/Label'
import { FindModeInput } from '../../navigation/Tabs/TabbedPage'

const ContactDealEntrySearch = (props) => {

	const { category } = props

	const title = category.value
	const id = category.id

	return (
		<Paper className="qv-margin">
			<h1 className="card-title">
				<span>{title}</span>
			</h1>
			<div className="column-body">
				<FormControl style={{
					flexGrow: 1,
					marginRight: '1em'
				}}>
					<Label disableAnimation shrink htmlFor={`deal_entry_status_category_id:${id}.deal_entry_artist`}>
						Artist
					</Label>
					<FindModeInput field={`deal_entry_status_category_id:${id}.deal_entry_artist`}/>
				</FormControl>
				<FormControl style={{
					flexGrow: 1,
					marginRight: '1em'
				}}>
					<Label disableAnimation shrink htmlFor={`deal_entry_status_category_id:${id}.deal_entry_art_title`}>
						Artwork Title
					</Label>
					<FindModeInput field={`deal_entry_status_category_id:${id}.deal_entry_art_title`}/>
				</FormControl>
				<FormControl style={{ flexGrow: 1 }}>
					<Label disableAnimation shrink htmlFor={`deal_entry_status_category_id:${id}.deal_entry_inventory_number`}>
						Inventory Number
					</Label>
					<FindModeInput field={`deal_entry_status_category_id:${id}.deal_entry_inventory_number`}/>
				</FormControl>
			</div>
			<div style={{
				display: 'flex'
			}}>
				<FormControl sx={{
					flex: '1 0 0',
					marginRight: '1em'
				}}>
					<Label disableAnimation shrink htmlFor={`deal_entry_status_category_id:${id}.deal_entry_gallery_contact`}>
						Gallery Contacts
					</Label>
					<FindModeInput field={`deal_entry_status_category_id:${id}.deal_entry_gallery_contact`}/>
				</FormControl>
				<FormControl sx={{
					flex: '1 0 0',
					marginRight: '1em'
				}}>
					<Label disableAnimation shrink htmlFor={`deal_entry_status_category_id:${id}.deal_entry_start_date`}>
						Start Date
					</Label>
					<FindModeInput field={`deal_entry_status_category_id:${id}.deal_entry_start_date`} type="date-picker"/>
				</FormControl>
				<div style={{
					flex: '1 0 0',
				}}></div>
			</div>
		</Paper>
	)
}

export default ContactDealEntrySearch
