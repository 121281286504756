/* eslint-disable eqeqeq */
import React, { useContext, useCallback, useEffect } from 'react'
import { AuthStateContext, DispatchContext } from '../../store'
import { Paper, FormControl, Menu, MenuItem } from '@mui/material'
import Label from '../../input/Label'
import TextBox from '../../input/Text/TextBoxThin'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { CancelButton, SaveCardButton } from '../../input/Buttons'
import { permissions, permissionValues } from '../../constants/permissions'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import SalespersonSelection from '../../Contacts/Contact/Salesperson/SalespersonSelection'
import GalleryContactEditMode from '../../common/GalleryContactEditMode'
import { FindModeInput } from '../../navigation/Tabs/TabbedPage'
import { Skelly } from '../../common/components/Skelly'

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false,
}

export default function ListingDetail(props) {
	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const [state, setState] = React.useState(initialState)
	const [listingInput, setListingInput] = React.useState({
		id: '',
		title: '',
		subtitle: '',
		location: '',
	})
	const [attempt, setAttempt] = React.useState(false)

	const resetListingInput = (listing) => {

		if (!listing || !Object.entries(listing).length) {
			return
		}

		const salespersons = listing.salesperson?.map((sp) => {
			return {
				id: sp.id,
				first_name: sp.first_name,
				last_name: sp.last_name,
				is_lead: sp.is_lead,
				is_deleted: sp.is_deleted,
				imgUrl: sp.imgUrl,
				user_handle: sp.user_handle
			}
		})

		const primary_salespersons = salespersons?.filter(e => e.is_lead)

		setListingInput({
			id: listing.id,
			title: listing.title,
			subtitle: listing.subtitle,
			location: listing.location,
			salespersons,
			primary_salespersons,
		})
	}

	React.useEffect(() => {
		resetListingInput(props.listing)
	}, [props.listing])

	
	// Salesperson Selection Logic
	const [salespersonModal, setSalespersonModal] = React.useState(false)
	const [canViewSP, setCanViewSP] = React.useState(true)
	const [canEditSP, setCanEditSP] = React.useState(true)

	const authState = useContext(AuthStateContext)
	const userPermissions  = authState?.user?.permissions
	const createdUserId = props.listing?.created_by
	const currentUserId = authState?.user?.id
	useEffect(() => {
		if (userPermissions) {
			const galleryContactPermission = userPermissions.find(e => e.permission_id == permissions.LISTINGS_SALESPEOPLE)?.permission_value_id
			if (galleryContactPermission < permissionValues.VIEW_ONLY) setCanViewSP(false)
			const canUpdateGalleryContacts = (galleryContactPermission == permissionValues.CREATE_AND_EDIT) || 
				(galleryContactPermission == permissionValues.CREATE_AND_EDIT_OWN && 
					createdUserId == currentUserId)
			setCanEditSP(canUpdateGalleryContacts)
		}
	}, [userPermissions, createdUserId, currentUserId])

	const handleClick = (event) => {
		if (state.mouseX || state.editable || props.findMode || !props.listing?.id) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const handleClose = (option) => {
		if (option === 'edit') {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	return (
		<>
			<Paper
				className="qv-margin"
				onContextMenu={handleClick}
				id="contact-details"
				data-testid="card-details"
			>
				<h1 className="card-title">
					<span>Details</span>
					{state.editable && !props.findMode && (
						<>
							<div className="spacer"></div>
							<CancelButton
								variant="contained"
								size="small"
								onClick={() => {
									resetListingInput(props.listing)
									handleClose()
									setAttempt(false)
								}}
							>
							Cancel
							</CancelButton>
							<SaveCardButton
								variant="contained"
								color="secondary"
								size="small"
								disabled={props.disabled}
								onClick={() => {
									setAttempt(true)

									// validation
									if (
										attempt &&
									listingInput.title.length === 0
									) {
										openSnackbar(
											severity.WARNING,
											'Please complete required fields.'
										)
									} else if (!(listingInput.title.length === 0)) {

										let updateData = {
											...listingInput,
											primary_salesperson_ids: listingInput.primary_salespersons?.map(salesperson => salesperson.id),
											salespersons_ids: listingInput.salespersons?.map(salesperson => salesperson.id),
										}
										
										delete updateData.primary_salespersons
										delete updateData.salespersons
										if (!canEditSP) {
											delete updateData.primary_salesperson_ids
											delete updateData.salespersons_ids
										}
										props.onSave(updateData)
										handleClose()
									}
								}}
							>
							Save
							</SaveCardButton>
						</>
					)}
				</h1>
				<div className="column-body">
					<div className="column">
						<FormControl>
							<Label
								disableAnimation
								shrink
								htmlFor="listing-title"
								error={attempt && listingInput.title.length === 0}
							>
							Title{ !props.findMode && '*'}
							</Label>
							{props.findMode ? <FindModeInput field="title" /> :
								(props.loading ? <Skelly error={props.error} /> :
									<TextBox
										error={
											attempt && listingInput.title.length === 0
										}
								
										id="listing-title"
										value={listingInput.title || ''}
										readOnly={!state.editable}
										placeholder={'-'}
										onChange={(e) => {
											setListingInput({
												...listingInput,
												title: e.target.value,
											})
										}}
										data-testid="listing-title"
									/>
								)}
						</FormControl>

						<FormControl>
							<Label
								disableAnimation
								shrink
								htmlFor="listing-subtitle"
							>
							Subtitle
							</Label>
							{props.findMode ? <FindModeInput field="subtitle" /> :
								(props.loading ? <Skelly error={props.error} /> :
									<TextBox
										id="listing-subtitle"
										value={listingInput.subtitle || ''}
										readOnly={!state.editable}
										placeholder={'-'}
										onChange={(e) => {
											setListingInput({
												...listingInput,
												subtitle: e.target.value,
											})
										}}
										data-testid="listing-subtitle"
									/>
								)}
						</FormControl>
					</div>
					<div className="column">
						<FormControl>
							<Label
								disableAnimation
								shrink
								htmlFor="listing-location"
							>
								Location
							</Label>{props.findMode ? <FindModeInput field="location" /> :
								(props.loading ? <Skelly error={props.error} /> :
									<TextBox
										required
										error={
											attempt && state.editable &&
											listingInput?.last_name?.length === 0
										}
								
										id="listing-location"
										value={listingInput.location || ''}
										readOnly={!state.editable}
										placeholder={'-'}
										onChange={(e) => {
											setListingInput({
												...listingInput,
												location: e.target.value,
											})
										}}
										data-testid="listing-location"
									/>
								)}
						</FormControl>

						{!props.findMode && <FormControl>
							<Label
								disableAnimation
								shrink
								htmlFor="listing-location"
							>
							Listing Number
							</Label>
							{props.loading ? <Skelly error={props.error} /> : (
								<TextBox
									required
								
									id="listing-number"
									value={listingInput.id || ''}
									readOnly={true}
									onChange={(e) => {
										openSnackbar(severity.WARNING, 
											"You cannot change the listing number.")
									}}
									data-testid="listing-number"
								/>
							)}
						</FormControl> }

						<FormControl>
							<Label disableAnimation shrink htmlFor="listing-salesperson">
								Gallery Contacts
							</Label>
							{props.findMode ? <FindModeInput field="gallery_contacts" /> :
								(props.loading ? <Skelly error={props.error} /> :
									<GalleryContactEditMode
										setSalespersonModal={setSalespersonModal}
										salespersons={listingInput.salespersons}
										canViewSP={canViewSP}
										editable={state.editable && canEditSP}
									/>
								)}
						</FormControl>
					</div>
				</div>

				<Menu
					keepMounted
					open={state.mouseY !== null}
					onClose={handleClose}
					anchorReference="anchorPosition"
					anchorPosition={
						state.mouseY !== null && state.mouseX !== null
							? { top: state.mouseY, left: state.mouseX }
							: undefined
					}
				>
					<MenuItem onClick={() => handleClose('edit')}>Edit</MenuItem>
				</Menu>
			</Paper>
			<TransitionsModal
				className="salesperson-modal"
				open={salespersonModal}
				close={() => setSalespersonModal(false)}
			>
				<SalespersonSelection
					input={listingInput}
					setInput={setListingInput}
					salespersons={listingInput.salespersons}
					primary_salespersons={listingInput.primary_salespersons}
					close={() => setSalespersonModal(false)}
				/>
			</TransitionsModal>
		</>
	)
}
