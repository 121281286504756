import { Collapse, IconButton, Paper } from '@mui/material'
import React from 'react'
import ReactPlayer from 'react-player'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

const CollapsedContent = (props) => {
	const [open, setOpen] = React.useState(props.open || false)
	const [openSubsections, setOpenSubsections] = React.useState([])
	const [openSubSubsection, setOpenSubSubsection] = React.useState([])

	return (
		<Paper style={{ padding: '1em 2em', marginBottom: '1em' }}>
			<h2 className="card-title" style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
				{props.title}

				<IconButton
					aria-label="dropdown"
					onClick={() => setOpen(!open)}
					style={{ padding: '6px', marginLeft: '0.5em' }}
					size="large">
					{!open ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowUpIcon fontSize="small" />}
				</IconButton>
			</h2>
			<Collapse in={open}>
				{open ? (
					<>
						<p>{props.text}</p>
						{props.url ? (
							<center>
								<ReactPlayer url={props.url} controls={true} />
							</center>
						) : null}

						{props.subSections?.map((subSection, index) => {
							return (
								<div key={`subsection-${index}`} style={{ marginLeft: '5em' }}>
									<h3
										className="card-title"
										style={{ paddingTop: '0.5em', cursor: 'pointer' }}
										onClick={() => {
											if (openSubsections.includes(index)) {
												let tempArray = [...openSubsections]
												tempArray.splice(
													openSubsections.findIndex((e) => e === index),
													1
												)

												setOpenSubsections(tempArray)
											} else {
												setOpenSubsections([...openSubsections].concat([index]))
											}
										}}
									>
										{subSection.title}
										<IconButton
											aria-label="dropdown-subsection"
											onClick={() => {
												if (openSubsections.includes(index)) {
													let tempArray = [...openSubsections]
													tempArray.splice(
														openSubsections.findIndex((e) => e === index),
														1
													)

													setOpenSubsections(tempArray)
												} else {
													setOpenSubsections([...openSubsections].concat([index]))
												}
											}}
											style={{ padding: '6px', marginLeft: '0.5em' }}
											size="large">
											{!openSubsections.includes(index) ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowUpIcon fontSize="small" />}
										</IconButton>
									</h3>
									<Collapse in={openSubsections.includes(index)}>
										{openSubsections.includes(index) ? (
											<>
												{subSection.text}

												{subSection.url ? (
													<center style={{ paddingTop: '0.5em' }}>
														<ReactPlayer url={subSection.url} controls={true} style={{ marginLeft: '-5em' }} />
													</center>
												) : null}

												{subSection.subSections?.map((subSection, subIndex) => {
													return (
														<div key={`sub-subSection-${subIndex}`} style={{ marginLeft: '5em' }}>
															<h3
																className="card-title"
																style={{ paddingTop: '0.5em', cursor: 'pointer' }}
																onClick={() => {
																	if (openSubSubsection.includes(`${index}-${subIndex}`)) {
																		let tempArray = [...openSubSubsection]
																		tempArray.splice(
																			openSubSubsection.findIndex((e) => e === `${index}-${subIndex}`),
																			1
																		)

																		setOpenSubSubsection(tempArray)
																	} else {
																		setOpenSubSubsection([...openSubSubsection].concat([`${index}-${subIndex}`]))
																	}
																}}
															>
																{subSection.title}
																<IconButton
																	aria-label="dropdown-subsection"
																	onClick={() => {
																		if (openSubSubsection.includes(`${index}-${subIndex}`)) {
																			let tempArray = [...openSubSubsection]
																			tempArray.splice(
																				openSubSubsection.findIndex((e) => e === `${index}-${subIndex}`),
																				1
																			)

																			setOpenSubSubsection(tempArray)
																		} else {
																			setOpenSubSubsection([...openSubSubsection].concat([`${index}-${subIndex}`]))
																		}
																	}}
																	style={{ padding: '6px', marginLeft: '0.5em' }}
																	size="large">
																	{!openSubSubsection.includes(`${index}-${subIndex}`) ? (
																		<KeyboardArrowDownIcon fontSize="small" />
																	) : (
																		<KeyboardArrowUpIcon fontSize="small" />
																	)}
																</IconButton>
															</h3>
															<Collapse in={openSubSubsection.includes(`${index}-${subIndex}`)}>
																{openSubSubsection.includes(`${index}-${subIndex}`) ? (
																	<>
																		{subSection.text}
																		{subSection.url ? (
																			<center style={{ paddingTop: '0.5em' }}>
																				<ReactPlayer url={subSection.url} controls={true} style={{ marginLeft: '-10em' }} />
																			</center>
																		) : null}
																	</>
																) : null}
															</Collapse>
														</div>
													)
												})}
											</>
										) : null}
									</Collapse>
								</div>
							)
						})}
					</>
				) : null}
			</Collapse>
		</Paper>
	)
}

export default CollapsedContent
