/* eslint-disable eqeqeq */
import { Avatar, Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, ListItemIcon, Menu, MenuItem, Select, Tooltip, Typography } from '@mui/material'
import { AvatarGroup } from '@mui/material'
import { MobileDateTimePicker } from '@mui/x-date-pickers-pro'
import { addDays } from 'date-fns'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { formatDate } from '../common/helpers'
import { OfferPrice } from '../Deals/Deal/OfferPrice'
import Dropdown from '../input/Dropdown/DropdownNoBorder'
import TextBox from '../input/Text/TextBox'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { LightTooltip, StyledBadge } from '../styles/makesStyles'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { CancelButton, SaveButton } from '../input/Buttons'
import { AuthStateContext, DispatchContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { withApollo } from '@apollo/client/react/hoc'
import { initialStatusModalState } from './DealEntryList'
import { GET_DEAL_ENTRY, UDPATE_DEAL_ENTRY_ART_STATUS } from './Queries'
import { useApolloClient, useMutation } from '@apollo/client'
import Label from '../input/Label'
import { deal_entry_status_categories } from './constants'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { permissions, permissionValues } from '../constants/permissions'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff'
import ProjectCodeInput from '../common/ProjectCodeInput'

const DealEntryStatusModal = (props) => {

	const {
		statusModal,
		setStatusModal,
		hasNoReserve,
		lookup,
		updateRow,
		setCurrentRow
	} = props

	const client = useApolloClient()
	const [attempt, setAttempt] = useState(false)
	const [artStatusModal, setArtStatusModal] = useState({
		open: false
	})
	const [loadingDealEntry, setLoadingDealEntry] = useState(false)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const dealEntryId = statusModal.id
	// set default project code
	useEffect(() => {

		if (!dealEntryId) return

		setLoadingDealEntry(true)
		client
			.query({
				query: GET_DEAL_ENTRY,
				variables: { dealEntryId },
			})
			.then((result) => {
				let project_code = result.data.getDealEntry.art?.project_code ||
					result.data.getDealEntry.art?.currentListings?.[0]?.project_code
					
				setStatusModal(sm => ({
					...sm,
					project_code
				}))
				setLoadingDealEntry(false)
			})
			.catch((error) => {
				console.error(error)
			})

	}, [dealEntryId, client, setStatusModal])

	// Functions
	const getCurrentTitle = () => {
		const title = lookup.data?.getDealEntryStatuses.find((des) => des.id === statusModal.statusId)?.value?.split([' - '])?.[0]

		switch (title) {
		case 'Reserved': {
			if (hasNoReserve) {
				return 'Reserve Artwork'
			}
			return 'Reserve Queue'
		}

		default:
			return title
		}
	}

	const [updateDealEntryArtStatus] = useMutation(UDPATE_DEAL_ENTRY_ART_STATUS, {
		onError: (error) => {
			console.log(error)
			openSnackbar(severity.ERROR, 'Could not update Art Status.')
		},
		onCompleted: (response) => {
			const theSeverity = response.updateDealEntryArtStatus?.success ?
				severity.SUCCESS : severity[response.updateDealEntryArtStatus?.severity]

			openSnackbar(theSeverity, 	response.updateDealEntryArtStatus?.message)
		},
	})

	const authState = useContext(AuthStateContext)
	const userPermissions  = authState?.user?.permissions

	const canViewAdminConsole = userPermissions
		?.find(e => e.permission_id == permissions.ADMIN_CONSOLE)
		?.permission_value_id == permissionValues.YES

	const silentTransactionPermission = userPermissions
		?.find(e => e.permission_id == permissions.SILENT_TRANSACTIONS)
		?.permission_value_id == permissionValues.YES


	const [anchorEl, setAnchorEl] = React.useState(null)
	const handleClose = () => setAnchorEl(null)


	const [notification, setNotification] = React.useState("ALL")
	const handleChange = (event) => {

		if (!canViewAdminConsole && !silentTransactionPermission) {
			openSnackbar(severity.WARNING, "Only Administrators and users with silent transaction permission can disable notifications.")
			return
		}

		else {
			setAnchorEl(event.currentTarget)
		}
	}

	const getNotificationIcon = (notification) => {

		if (notification === "ALL") return <NotificationsIcon color="primary"/>

		if (notification === "LEAD") return <SupervisorAccountIcon color="primary"/>

		return <NotificationsOffIcon color="primary"/>

	}

	const getNotificationTitle = (notification) => {

		if (notification === "ALL") return "All relevent users with be notified."

		if (notification === "LEAD") return "All lead users will be notified."

		return "No users will be notified."

	}

	return <>
		<TransitionsModal
			open={statusModal.open}
			close={() => {
				setStatusModal(initialStatusModalState)
				setNotification("ALL")
				setAttempt(false)
			}}
		>
			<div className={props.privateEntity ? 'dark-theme' : null}>
				<h2 className="card-title" style={{ fontWeight: 500 }}>
					{getCurrentTitle()}

					{statusModal.statusId == 4 ?
						<>
							<LightTooltip arrow title={getNotificationTitle(notification)}>
								<IconButton
									style={{marginLeft: 'auto'}}
									onClick={handleChange}
									color="primary"
								>
									{getNotificationIcon(notification)}
								</IconButton>
							</LightTooltip>

							<Menu
								id="notification-menu"
								anchorEl={anchorEl}
								keepMounted
								open={!!anchorEl}
								onClose={handleClose}
								value
							>
								 <MenuItem selected={notification === "ALL"} onClick={() => {
									setAnchorEl(null)
									setNotification("ALL")}
								 }>
									<ListItemIcon>
										<NotificationsIcon color="primary"/>
									</ListItemIcon>
									<Typography>
										Notifications On
									</Typography>
								</MenuItem>
								<MenuItem selected={notification === "LEAD"} onClick={() => {
									setAnchorEl(null)
									setNotification("LEAD")
								}}>
									<ListItemIcon>
										<SupervisorAccountIcon color="primary"/>
									</ListItemIcon>
									<Typography>
										Team Leads Only
									</Typography>
								</MenuItem>
								<MenuItem selected={notification === "OFF"} onClick={() => {
									setAnchorEl(null)
									setNotification("OFF")
								}}>
									<ListItemIcon>
										<NotificationsOffIcon color="primary"/>
									</ListItemIcon>
									<Typography>
									Notifications Off
									</Typography>
								</MenuItem>
							</Menu>
						</>
					 : null}
				</h2>

				{statusModal.statusId == 3 ? (
					<FormControlLabel
						control={
							<Checkbox
								checked={statusModal.is_interested}
								onChange={() =>
									setStatusModal({
										...statusModal,
										is_interested: !statusModal.is_interested,
									})
								}
								name="interest"
							/>
						}
						label="Client Interest"
					/>
				) : null}
				{statusModal.statusId == 4 ? (
					<div>
						{props.currentRow?.reserveQueue?.hasReserve ? (
							<>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<FormControl>
										<Label disableAnimation shrink>
											Current Reserve
										</Label>
										<AvatarGroup
											style={{
												marginTop: '2em',
											}}
										>
											{props.currentRow?.reserveQueue?.hasReserve.map((sp) => {
												if (sp.is_lead) {
													return (
														<Tooltip key={sp.id} title={`${sp.first_name} ${sp.last_name}`} arrow>
															<StyledBadge
																className="qv-sp-badge"
																overlap="circular"
																anchorOrigin={{
																	vertical: 'bottom',
																	horizontal: 'right',
																}}
																variant="dot"
															>
																<Avatar alt={`${sp.first_name} ${sp.last_name}`} src={sp.imgUrl}>
																	{!sp.image && sp.first_name && sp.last_name ? sp.first_name[0] + sp.last_name[0] : null}
																</Avatar>
															</StyledBadge>
														</Tooltip>
													)
												}

												return (
													<Tooltip key={sp.id ?? 0} title={`${sp.first_name} ${sp.last_name}`} arrow>
														<Avatar alt={`${sp.first_name} ${sp.last_name}`} src={sp.imgUrl}>
															{!sp.image && sp.first_name && sp.last_name ? sp.first_name[0] + sp.last_name[0] : null}
														</Avatar>
													</Tooltip>
												)
											})}
										</AvatarGroup>
									</FormControl>

									<span
										style={{
											paddingTop: '1.1em',
											color: props.privateEntity ? 'white' : null,
										}}
									>
										{`Expires ${formatDate(props.currentRow.reserveQueue?.reserve_end_at, "MMMM do 'at' h':'mma")}`}
									</span>
								</div>

								<FormControl className="fullWidth" style={{ marginTop: '2em' }}>
									<Label id="reserve-queue-position-label" disableAnimation shrink>
										Your Reserve Queue Position
									</Label>
									<TextBox
										style={{
											marginTop: '1.5em',
											color: !props.privateEntity ? 'black' : 'white',
										}}
										disabled
										className="hideborder"
										id="reserve-queue-position"
										value={props.currentRow?.reserveQueue?.queue_length + 1}
									/>
								</FormControl>
							</>
						) : null}
						<OfferPrice
							currency={statusModal.offer_currency}
							price={statusModal.offer_amount || 0}
							onChange={(field, value) =>
								setStatusModal({
									...statusModal,
									[field]: value,
								})
							}
							currencies={lookup.data.getCurrencies}
							error={attempt && !statusModal.offer_amount}
						/>

						{hasNoReserve ? (
							<FormControl
								style={{
									width: '26em',
								}}
							>
								<Label id="reserve-date-label" disableAnimation shrink>
									Reserve Expiration Date
								</Label>

								<MobileDateTimePicker
									name="date"
									disablePast // Date Input on reserve Modal
									showTodayButton
									inputFormat="MMM do, yyyy"
									disableMaskedInput={true}
									cancelText={null}
									todayLabel="Now"
									inputVariant="outlined"
									className="MUIDatePicker"
									style={{
										marginTop: '1em',
									}}
									variant="dialog"
									value={statusModal.reserve_end_at ?? addDays(new Date(), 2)}
									renderInput={({ inputRef, inputProps, InputProps }) => {

										const newProps = { ...inputProps}

										newProps.readOnly = false

										return (
											<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
										)}}
									onChange={(date) => {
										setStatusModal({
											...statusModal,
											reserve_begin_at: new Date(),
											reserve_end_at: date,
										})
									}}
								/>
							</FormControl>
						) : null}
					</div>
				) : null}
				{(statusModal.statusId == 36 || statusModal.statusId == 7) ? (
					<FormControl className="fullWidth">
						<Label id="sale-price-label" disableAnimation shrink
							error={false && attempt && !statusModal.project_code}
						>
							Project Code
						</Label>
						<ProjectCodeInput
							value={statusModal.project_code}
							attempt={false && attempt}
							disabled={loadingDealEntry}
							onChange={(event, value) => {
								setStatusModal(sm => ({
									...sm,
									project_code: value
								}))
							}}
						/>
					</FormControl>
				) : null}
				{statusModal.statusId == 7 ? (
					<>
						<FormControl className="fullWidth">
							<Label id="sale-price-label" disableAnimation shrink>
								Sale Price
							</Label>
							<TextBox
								style={{ marginTop: '1.5em' }}
								required
								id="sale-price"
								value={statusModal.sale_amount || props.currentRow?.offer_amount}
								placeholder={'-'}
								onChange={(e) =>
									setStatusModal({
										...statusModal,
										sale_amount: e.target.value,
									})
								}
								startAdornment={
									<InputAdornment position="start">
										<Select
											input={<Dropdown />}
											IconComponent={ExpandMoreRoundedIcon}
											aria-label="offer currency"
											value={statusModal.sale_currency || props.currentRow?.offer_currency?.id}
											onChange={(e) => {
												setStatusModal({
													...statusModal,
													sale_currency: e.target.value,
												})
											}}
										>
											{lookup.data.getCurrencies.map((cur) => {
												return (
													<MenuItem key={cur.id} value={cur.id}>
														<Tooltip title={cur.name} arrow placement="right" enterDelay={1000}>
															<span>{cur.symbol}</span>
														</Tooltip>
													</MenuItem>
												)
											})}
										</Select>
									</InputAdornment>
								}
							/>
						</FormControl>
					</>
				) : null}

				<FormControl
					className="fullWidth"
					style={{
						marginTop: '0.5em',
					}}
				>
					<Label disableAnimation shrink>
						Deal Status Note
					</Label>
					<TextBox
						multiline
						value={statusModal.statusNote}
						rows="6"
						style={{
							marginTop: '1.5em',
						}}
						onChange={(e) => {
							setStatusModal({
								...statusModal,
								statusNote: e.target.value,
							})
						}}
					/>
				</FormControl>

				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginTop: '1em',
					}}
				>
					<CancelButton
						variant="contained"
						size="small"
						onClick={() => {
							setStatusModal(initialStatusModalState)
							setNotification("ALL")
							setAttempt(false)
						}}
					>
						Cancel
					</CancelButton>
					<SaveButton
						variant="contained"
						color="secondary"
						size="small"
						disabled={props.disabled}
						onClick={() => {
							let updates

							// We need -> Going into reserve/has reserve;
							// -> has reserve + queue=1/passed, below
							// has_reserved to sold.
							let before = {
								id: props.currentRow?.id,
								dealId: props.currentRow?.deal_id,
								artId: props.currentRow?.art_id,
								hasReserve: props.currentRow?.has_reserve,
								reservePosition: props.currentRow?.reserve_position,
								queueLength: props.currentRow?.reserveQueue?.queue_length,
								hasNoReserve: hasNoReserve,
								previousStatusId: props.currentRow?.deal_entry_status?.id,
								targetStatusId: statusModal.statusId
							}
							// goint to sold-paied
							if (statusModal.statusId == 36 || statusModal.statusId == 7) {
								// setAttempt(true)
								// if (!statusModal.project_code) {
								// 	if (attempt) {
								// 		openSnackbar(severity.WARNING, 'Please fill fields in red.')
								// 	}
								// 	return
								// }
							}
							// going to reserved
							if (statusModal.statusId == 4) {
								setAttempt(true)

								if (attempt && !statusModal.offer_amount) {
									openSnackbar(severity.WARNING, 'Please fill fields in red.')
								} else if (statusModal.offer_amount) {
									if (hasNoReserve) {

										// Reserve Modal
										updates = updateRow(
											{
												...statusModal,
												reserve_begin_at: new Date(),
												reserve_end_at: statusModal.reserve_end_at ?? addDays(new Date(), 2),
											},
											notification
										).then((row) => {
											setCurrentRow(null)
											setStatusModal(initialStatusModalState)
											return row
										})
									} else {
										// Reserve Queue Modal
										updates = updateRow(
											statusModal,
											notification
										)
											.then(row => {
												setCurrentRow(null)
												setStatusModal(initialStatusModalState)
												return row
											})
									}

									setAttempt(false)
								}
							} else {
								updates = updateRow(statusModal)
									.then(row => {
										setCurrentRow(null)
										setStatusModal(initialStatusModalState)
										return row
									})
							}
							setAttempt(false)
							updates && updates.then(result => {
								let recommendedStatus = null

								let after = {
									hasReserve: result?.has_reserve,
									reservePosition: result?.reserve_position,
									queueLength: result?.reserveQueue?.queue_length,
									status: result?.deal_entry_status
								}
								// When a deal entry status is moved to “reserved”
								// and they will be in ANY reserve position
								// the art status should also be moved to “reserved”
								if (before.targetStatusId == 4) {
									recommendedStatus = { id: 26, value: "Reserved" }
								}
								// If a user is the last one in the reserve queue,
								// and marks it as “Passed” or “Below Asking”and
								// removes themselves from the queue, then the art
								// status should suggest to move back to “Available”
								// * for future proofing this, we should probably just
								// check and see if they removes them as the last person in the queue.
								if (before.previousStatusId == 4 && after.queueLength == 0) {
									recommendedStatus = { id: 6, value: "Available" }

								}
								// has_reserved to sold.
								if (before.previousStatusId == 4 && after.status?.deal_entry_status_category?.id == deal_entry_status_categories.purchases) {
									recommendedStatus = { id: 1, value: "SOLD" }
								}
								if (recommendedStatus && (recommendedStatus != result?.art?.status)) {
									setArtStatusModal({
										open: true,
										recommendedStatus,
										previousStatus: result?.art?.status,
										dealEntryId: before.id,
										dealId: before.dealId,
										artId: before.artId
									})
								}
							})
						}}
					>
						{statusModal.statusId == 4 ? 'Enter Queue' : 'Save'}
					</SaveButton>
				</div>
			</div>
		</TransitionsModal>
		<TransitionsModal
			open={artStatusModal.open}
		>
			<div className={props.privateEntity ? 'dark-theme' : null}>
				<p
					style={{
						color: !props.privateEntity ? 'black' : 'white',
					}}
				>
					The Artwork Status will also be updated.
				</p>

				<FormControl
					className="fullWidth"
					style={{
						marginTop: '0.5em',
					}}
				>
					<Label disableAnimation shrink>
						Current Art Status:
					</Label>
					<TextBox
						style={{
							marginTop: '1.5em',
							color: !props.privateEntity ? 'black' : 'white',
						}}
						disabled
						className="hideborder"
						value={ artStatusModal.previousStatus?.value }
					/>
				</FormControl>
				<FormControl
					className="fullWidth"
					style={{
						marginTop: '0.5em',
					}}
				>
					<Label disableAnimation shrink>
						Updated Status:
					</Label>
					<TextBox
						style={{
							marginTop: '1.5em',
							color: !props.privateEntity ? 'black' : 'white',
						}}
						disabled
						className="hideborder"
						value={ artStatusModal.recommendedStatus?.value }
					/>
				</FormControl>

				<div style={{
					display: 'flex',
					justifyContent: 'space-between',
					marginTop: '1em',
				}}>

					<SaveButton
						variant="contained"
						color="secondary"
						size="small"
						onClick={ () => {
							updateDealEntryArtStatus({
								variables: {
									DealEntryRequest: {
										id: artStatusModal.dealEntryId,
										deal_id: artStatusModal.dealId,
										art_id: artStatusModal.artId
									},
									ArtStatusId: artStatusModal.recommendedStatus.id
								}
							})
							props.onUpdateArtStatus &&
								props.onUpdateArtStatus(artStatusModal.recommendedStatus.id)
							setArtStatusModal({ open: false })
						}}
					>Accept</SaveButton>
				</div>
			</div>
		</TransitionsModal>
	</>
}

export default withApollo(DealEntryStatusModal)
