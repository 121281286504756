import { useContext, useEffect } from "react"
import { useLocation, useNavigate, useNavigationType } from "react-router-dom"
import { DispatchContext, NavigationContext } from '../store'

export default function useNavigation () {

	/** @type {import("../store").Navigation} */
	const navigationStack = useContext(NavigationContext)
	const dispatch = useContext(DispatchContext)
	const navigate = useNavigate()
	const navigationType = useNavigationType()

	const location = useLocation()

	// listen for the browser back arrow, and pop the stack
	// if and only if it changes levels.
	// So: It looks like that's automatic: the "POP" action only happens 
	// when it's truly going back.
	useEffect(() => {
		if (navigationType === 'POP') {
			dispatch({ type: 'popLocation' })
		}
		if (location.pathname.match(/\//g).length <= 1) {
			clearLocations()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, location, navigationType])

	/**
	 * Add an entry to the navigation stack. Note that this does not
	 * (? yet?) actually navigate to that page, which must be done 
	 * seperately with a `history.push()`
	 * @param {import("../store").NavStackEntry} stackEntry 
	 */
	const push = (stackEntry, cursor) => {
		cursor = cursor ?? 0
		setSearchNav({...navigationStack.searchNav, current: cursor})
		dispatch({ type: 'pushLocation', payload: {
			...stackEntry, 
			searchNav: navigationStack.searchNav
		}})
	}

	const pop = () => {
		const length = navigationStack.stack.length
		const prev = navigationStack.stack[length-1]
		if (!prev) {
			console.error('Navigation stack is empty.')
			return
		}
		dispatch({ type: 'popLocation' })
		setSearchNav({...prev.searchNav})
		navigate(prev.url, { state: prev.state })
	}
	
	const clearLocations = () => dispatch({ type: 'clearLocations' })

	/**
	 * 
	 * @param {import("../store").SearchNav} searchNav 
	 */
	const setSearchNav = (searchNav) => {
		dispatch({ type: 'setSearchNav', payload: searchNav})
	}

	/**
	 * 
	 * @param {boolean} bool 
	 */
	const setSearchNavLoading = (bool) => {
		dispatch({ type: 'setSearchNavLoading', payload: bool})
	}

	const length = navigationStack.stack.length
	const prevSearch = navigationStack.stack[length-1]
	const searchNavLoading = navigationStack.searchNavLoading

	return {
		length,
		push, 
		pop, 
		prevSearch,
		setSearchNav,
		searchNavLoading,
		setSearchNavLoading,
		searchNav: navigationStack.searchNav
	}
}
