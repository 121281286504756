/* eslint-disable eqeqeq */
import React, { useContext, useCallback } from "react"
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import { SubmitButton } from '../input/Buttons'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { moveUp, moveDown } from '../common/helpers'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { CancelButton } from '../input/Buttons'
import { FormControl, MenuItem, Select } from '@mui/material'
import Label from '../input/Label'
import { Skelly } from './components/Skelly'
import Dropdown from '../input/Dropdown/Dropdown'
import { DispatchContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'

const MultiTypeModal = ({
	typeModal, 
	setTypeModal, 
	attempt, 
	setAttempt, 
	isPrivate, 
	entityInput, 
	setEntityInput, 
	loading, 
	error, 
	sortedTypeDropdownValues,
	type,
	typeId,
	title,
	keyName,
	width
}) => {

	const [selectedIndex, setSelectedIndex] = React.useState(null)
	const [typeToAdd, setTypeToAdd] = React.useState(null)


	const handleListItemClick = (event, index) => {
		setSelectedIndex(index)
	}

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])


	const orderedTypes =  (entityInput[keyName] ? [...entityInput[keyName]] : [])
		.sort((a, b) => a.ordinal - b.ordinal)

	return (
		<TransitionsModal
			className="type-modal"
			open={typeModal}
			close={() => {
				setTypeModal(false)
				setTypeToAdd(null)
				setAttempt(false)
			}}
		>
			<div className={isPrivate ? "private-object" : null}>
				<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
					<h1 className="card-title">{title}</h1>
					<CancelButton
						variant="contained"
						onClick={() => {
							setTypeModal(false)
							setTypeToAdd(null)
							setAttempt(false)
						}}
						style={{ height: '40px'}}
					>Close
					</CancelButton>
				</div>
				<form onSubmit={e => {
					e.preventDefault()
					e.stopPropagation()
				}}>

					{orderedTypes?.length ?
					
						<div style={{display: "flex"}}>

							{orderedTypes?.length >= 2 ? 
							
								<div style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									marginRight: "1em"
								}}>
									<IconButton size="small" disabled={selectedIndex == 0} onClick={() => {

										moveUp(selectedIndex, setSelectedIndex, orderedTypes, (tempArray) => {
											setEntityInput({
												...entityInput,
												[keyName]: tempArray
											})
										})
									}}>
										<ArrowUpwardIcon/>
									</IconButton>

									<IconButton size="small" disabled={selectedIndex == orderedTypes.length - 1} onClick={() => {

										moveDown(selectedIndex, setSelectedIndex, orderedTypes, (tempArray) => {
											setEntityInput({
												...entityInput,
												[keyName]: tempArray
											})
										})
									}} >
										<ArrowDownwardIcon />
									</IconButton>
								</div>
						
								: null}


							<List dense={true} style={orderedTypes.length >= 2 ? {
								width: '-webkit-fill-available',
								overflowY: 'auto',
								overflowX: 'hidden',
								marginTight: '-1em',
								maxHeight: '30em',
								maxWidth: '30em'
							} : {
								width: '-webkit-fill-available',
								maxHeight: '15em',
								overflowX: 'hidden',
								marginTight: '-1em',
								maxWidth: '30em'
							}}>
								{orderedTypes?.sort((a, b) => a.ordinal - b.ordinal).map((item, index) => {
									return (
										<ListItem
											style={
												selectedIndex === index
													? {
														borderRadius: '8px',
														marginTop: '1em',
														marginBottom: '1em',
														backgroundColor: isPrivate ? 'darkgrey' :
															'#DCDCDC',
														color: isPrivate ? "white" : null
													  }
													: {
														borderRadius: '8px',
														marginTop: '1em',
														marginBottom: '1em',
														backgroundColor: isPrivate ? 'grey' :
															'#F5F5F5',
														color: isPrivate ? "white" : null
													  }
											}
											key={
												item[type]+item.ordinal
											}
											onClick={(event) =>
												handleListItemClick(
													event,
													index
												)
											}
											button
											selected={selectedIndex === index}
										>								
											<ListItemText  primary={item.ordinal + ". " + (item[type] || item.value)} />

											<ListItemSecondaryAction>
												<IconButton size="small" edge="end" aria-label="delete" style={{padding: '0px'}} onClick={(e) => {

													let tempTypes = [...orderedTypes]
														.map((o) => ({...o}))
									
													const index = entityInput
														[keyName]
														.findIndex(type => type.ordinal == item.ordinal)

													tempTypes = tempTypes.filter((item, i) => {
														if (index == i) return false
														return true
													})

													tempTypes = tempTypes.map((item, i) => ({
														...item,
														ordinal: i+1
													}))

													setEntityInput({
														...entityInput,
														[keyName]: tempTypes,
													})
												}}>
													<ClearIcon/>
												</IconButton>
											</ListItemSecondaryAction>
							
										</ListItem>
									)
								})}
							</List>

						</div>
						:
						null}

					
					<div style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between"
					}}>

						<div className={
							attempt && ((typeof typeToAdd === "string" && typeToAdd.length < 3) || !typeToAdd)
								? 'error'
								: null
						}>
							<FormControl style={{marginTop: '.5em'}} className="type-formcontrol">

								<Label style={{
									fontWeight: 500,
								}} shrink id="type-label" disableAnimation
							 >
								Add {title}
								</Label>
								<>
									{(loading) ? <Skelly error={error} />
										:
										<Select
											id="type-select"
											input={<Dropdown />}
											onChange={(event, value) => {
												setTypeToAdd(value.props)
											}}
												
											value={typeToAdd?.value || ''}
											fullWidth
											style={{minWidth: width ?? '10em'}}
											IconComponent={ExpandMoreRoundedIcon}
										>
											{sortedTypeDropdownValues?.map(type => 
												<MenuItem key={type.id} value={type.id}>{type.value}</MenuItem>
											)}
										</Select>
									}
								</>
							</FormControl>
						</div>
							
						<SubmitButton
							style={{     
								marginTop: '2.1em',
								height: '40px',
								marginLeft: '1em',
							}}
							type="submit"
							variant="contained"
							onClick={(e) => {
								setAttempt(true)								
								if(attempt && (!typeToAdd)) {
									openSnackbar(
										severity.WARNING,
										'Please correct fields in red.'
									)
								}
								if (!typeToAdd) return

								let tempTypes = [...orderedTypes]
									.map((o) => ({...o}))
									
								tempTypes.push({
									[typeId]: typeToAdd.value,
									[type]: typeToAdd.children,
									ordinal: tempTypes.length + 1
								})
									
								setEntityInput({
									...entityInput,
									[keyName]: tempTypes
								})
	
								setTypeToAdd(null)	
								setAttempt(false)
							}}
						>
						Add
						</SubmitButton>
					</div>
				</form>
			</div>
		</TransitionsModal>
	)
}

export default MultiTypeModal
