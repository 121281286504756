/* eslint-disable eqeqeq */
import React, { useEffect } from 'react'
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import makeStyles from '@mui/styles/makeStyles'
import { useQuery } from '@apollo/client'
import { useParams } from "react-router-dom"
import Box from '@mui/material/Box'
import {
	Paper,
	FormControl,
	TextField,
	CircularProgress,
	Skeleton
} from "@mui/material"
import Label from '../input/Label'
import { CancelButton } from '../input/Buttons'
import { Pagination, Autocomplete } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import IconButton from "@mui/material/IconButton"
import InfoCard from '../InfoCard/InfoCard'
import { toProperCase, getActionColor, formatDate, readableBytes } from '../common/helpers'
import EnhancedTableHead from '../table/EnhancedTableHead'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import QuillText from '../input/QuillText'
import { typeStyle, autoCompleteStyles } from '../styles/makesStyles'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { GET_USERS } from '../User/Queries'
import { withApollo } from '@apollo/client/react/hoc'
import { GET_DATALOG_ITEMS, GET_DATALOG_ITEM_IDS } from './Queries'
import startCase from 'lodash/startCase'
import UserAvatar from '../common/components/UserAvatar'
import TextBoxThinForDatePicker from '../input/Text/TextBoxThinForDatePicker'

import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import transform from 'lodash/transform'

const headCells = [
	{
		id: "data_log.created_at",
		numeric: false,
		disablePadding: true,
		label: "Date"
	},
	{
		id: "users.first_name",
		numeric: false,
		disablePadding: true,
		label: "User"
	},
	{
		id: "action",
		numeric: false,
		disablePadding: true,
		label: "Action"
	},
	{
		id: "item",
		numeric: false,
		disablePadding: true,
		label: "Item"
	},
	{
		id: "item_id",
		numeric: false,
		disablePadding: true,
		label: "Item Id"
	},
	{
		id: "field",
		numeric: false,
		disablePadding: true,
		label: "Field",
		noSort: true,
	},
	{
		id: "old_value",
		numeric: false,
		disablePadding: true,
		label: "Original",
		noSort: true,
	},
	{
		id: "new_value",
		numeric: false,
		disablePadding: true,
		label: "New",
		noSort: true,
	},
	{
		id: "actions",
		numeric: false,
		disablePadding: true,
		label: "Actions",
	},
]

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%"
	},
	paper: {
		width: "100%",
		marginBottom: theme.spacing(2)
	},
	table: {
		minWidth: 750
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1
	},
	
}))

// Logs that are to be separated by convention 
const singledLogs = [
	"Permission",
	"Gallery Contact"
]

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
function difference(object, base) {
	function changes(object, base) {
		return transform(object, function(result, value, key) {
			if (!isEqual(value, base[key])) {
				result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value
			}
		})
	}
	return changes(object, base)
}

const DataLog = (props) => {

	const classes = useStyles()
	const classes2 = autoCompleteStyles()

	// Used for grouping logs
	const halfASecond = 500
	const [logs, setLogs] = React.useState([])

	// Pagination	
	const [steps, setSteps] = React.useState(1)
	const [activeStep, setActiveStep] = React.useState(0)
	const [cursor, setCursor] = React.useState(0)
	const [totalItems, setTotalItems] = React.useState(0)

	// Sorting
	const [order, setOrder] = React.useState('desc')
	const [orderBy, setOrderBy] = React.useState('data_log.created_at')

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}
	
	const [date, setDate] = React.useState(null)
	const [users, setUsers] = React.useState([])
	const [actions] = React.useState([
		'Edited',
		'Deleted',
		'Created'
	])
	const [items, setItems] = React.useState([])
	const [itemIds, setItemIds] = React.useState([])

	const [optionsDropdown, setOptionsDropdown] = React.useState(false)
	const optionsListLoading = optionsDropdown && users?.length === 0

	const [itemsDropdown, setItemsDropdown] = React.useState(false)
	const itemsListLoading = itemsDropdown && items?.length === 0

	const [itemIdsDropdown, setItemIdsDropdown] = React.useState(false)
	const itemIdsListLoading = itemIdsDropdown && itemIds?.length === 0

	const [userSelection, setUserSelection] = React.useState([])
	const [actionSelection, setActionSelection] = React.useState([])
	const [itemSelection, setItemSelection] = React.useState([])
	const [itemIdsSelection, setItemIdsSelection] = React.useState([])
	
	const [infoModal, setInfoModal] = React.useState({open: false})
	const [currentLog, setCurrentLog] = React.useState(null)
	const params = useParams()
	
	const {data, loading} = useQuery(props.query, {
		variables: { 
			field: orderBy,
			direction: order.toUpperCase(),
			cursor,
			id: params.id || props.entity_id,
			date: date ? formatDate(date, "yyyy'-'MM'-'dd") : null,
			items: itemSelection.length ? itemSelection : null,
			item_ids: itemIdsSelection.length ? itemIdsSelection : null,
			actions: actionSelection.length ? actionSelection : null,
			user_ids: userSelection.length ? 
				userSelection.map(e => Number(e.id)) : null,
		},
		skip: !params.id && !props.entity_id,
	})
		
	useEffect(() => {
		if (data) {
			setLogs(data[props.queryName]?.items)

			if (data[props.queryName]?.items < 1) setSteps(1)
			else setSteps((Math.ceil(data[props.queryName]?.totalItems / 10)))
	
			setTotalItems(data[props.queryName]?.totalItems || 0)
			
		}

	}, [data, props.queryName])

	// Clear logs on quick nav
	useEffect(() => {
		if (logs.length) setLogs([])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id])

	React.useEffect(() => {
		let active = true
		if (!optionsListLoading) {
			return undefined
		}
		
		props.client
			.query({ query: GET_USERS})
			.then(result => {
				if (active) {
					setUsers(result.data.getUsers.items)
				}
			})
		return () => {
			active = false
		}
	}, [optionsListLoading, props.client])

	React.useEffect(() => {
		let active = true
		if (!itemsListLoading) {
			return undefined
		}
		props.client
			.query({ query: GET_DATALOG_ITEMS, variables: {
				DataLogItemRequest: {
					id: params.id || props.entity_id,
					field_name: props.id_field
				}
			}})
			.then(result => {
				if (active) {
					setItems(result.data.getDataLogItems || [])	
				}
			})
		return () => {
			active = false
		}
	}, [itemsListLoading, params.id, props.client, props.entity_id, props.id_field])

	React.useEffect(() => {
		let active = true
		if (!itemIdsListLoading) {
			return undefined
		}
		
		props.client
			.query({ query: GET_DATALOG_ITEM_IDS, variables: {
				DataLogItemRequest: {
					id: params.id || props.entity_id,
					field_name: props.id_field
				}
			}})
			.then(result => {
				if (active) {
					setItemIds(result.data.getDataLogIds || [])
				}
			})
		return () => {
			active = false
		}
	}, [itemIdsListLoading, params.id, props.client, props.entity_id, props.id_field])

	// Get formatted field name
	const formattedField = (field) => {

		if (!field) return null
		field = field.toString()
		field = field.replace(/_/g, " ")
		field = toProperCase(field)
		field = field.replace("Id", "")
		return field
	}

	// Get the formatted old and new values
	const getDisplayValue = (value, isDate, field = null, otherVal = null) => {
		if (value === false) return "False"
		else if (value === true) return "True"

		else if (!value) return (
			<span>-</span>
		) 
		
		if (field === 'Start At' || field === 'End At' ) {
			return formatDate(value, "MMMM do, yyyy")
		}

		else if (isDate) {
			if (field === 'Birthdate') {
				return formatDate(value, "MMMM do, yyyy")
			}

			return formatDate(value, "MMMM do, yyyy 'at' h':'mma")
		}

		else if (typeof value === "object") {

			if (value?.ops) {
				return (
					<QuillText name={field}>{value}</QuillText>
				)
			}

			if (otherVal) {
				return Object.entries(difference(value, otherVal)).map((entry, index) => {
					return <span key={`${field}-${index}`}>{`${entry[0]} : ${JSON.stringify(entry[1])}`}</span>
				})
			}

			return JSON.stringify(value, null, "\t")
		}
		
		else if (field && field == "Filesize")
			return readableBytes(value)

		return value
	}
	
	const getFieldOverride = (field, object) => {

		if (object == "art_id" && field.includes("Description")) 
			return field.replace(" Description", "")

		switch (field.trim()) {
		case "Formatted Title":
			return "Title"
		case "Formatted Content":
			return "Content"
		case "Owner Contact":
			return "Source"
		case "Inventory Number":
			return "Inventory Number - Base"
		case "Inventory Number Prefix":
			return "Inventory Number - Prefix"
		case "Inventory Number Suffix":
			return "Inventory Number - Suffix"
		case "Year":
			return "Date"
		default:
			return field
		}
	}

	return <>
		<Paper className="data-list qv-margin" data-testid="card-datalog">
			<h1 className="card-title">Data Log {"("+totalItems+")"}</h1>

			<div id="datalog-filters" style={{paddingBottom: "1em"}}>

				<FormControl style={{width: '15em', marginTop: '0.4em', paddingRight: '1em'}}>
					<Label
						id="date-label"
						style={typeStyle}
						disableAnimation
						shrink
					>
                        Date
					</Label>

					<MobileDatePicker
						inputFormat="MMM do, yyyy"
						disableMaskedInput={true}
						componentsProps={{
							actionBar: {
								actions: ['today', 'clear', 'accept']
							}
						}}
						todayLabel="Now"
						name="date"
						renderInput={({ inputRef, inputProps, InputProps }) => {
								
							const newProps = { ...inputProps}

							newProps.readOnly = false

							return (
								<TextBoxThinForDatePicker ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
							)}}
						inputVariant="outlined"
						style={{ marginTop: '17px' }}
						className="MUIDatePicker"
						variant="dialog"
						value={date}
						onChange={(date) => {
							if (activeStep > 1) {
								setActiveStep(0)
								setCursor(0)
							}
							setDate(date)
						}}
					/>
				</FormControl>

				<FormControl style={{minWidth: '15em', paddingRight: "1em", marginTop: "0.4em"}}>
					<Label
						id="user-label"
						style={typeStyle}
						disableAnimation
						shrink
					>
                        Users
					</Label>

					<Autocomplete
						multiple
						classes={classes2}
						style={{marginTop: "1.5em"}}
						open={optionsDropdown}
						isOptionEqualToValue={(option, value) => {
							return option.id == value.id
						}}
						forcePopupIcon
						filterSelectedOptions
						popupIcon={<ExpandMoreRoundedIcon />}
						size="small"
						value={userSelection ?? []}
						onOpen={() => { setOptionsDropdown(true) }}
						onClose={() => { setOptionsDropdown(false)}}
						getOptionLabel={option => {
							try {
								return option.first_name + ' ' + option.last_name
							} catch {
								return "Loading..."
							}
						}}
						options={users || []}
						loading={optionsListLoading}
						onChange={(event, value) => {
							if (activeStep > 1) {
								setActiveStep(0)
								setCursor(0)
							}
							setUserSelection(value)
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								fullWidth
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{optionsListLoading ? <CircularProgress color="inherit" size={20} /> : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
				</FormControl>

				<FormControl style={{minWidth: '15em', paddingRight: "1em", marginTop: "0.4em"}}>
					<Label
						id="actions-label"
						style={typeStyle}
						disableAnimation
						shrink
					>
                        Actions
					</Label>

					<Autocomplete
						multiple
						classes={classes2}
						style={{marginTop: "1.5em"}}
						forcePopupIcon
						filterSelectedOptions
						popupIcon={<ExpandMoreRoundedIcon />}
						size="small"
						value={actionSelection ?? []}
						options={actions || []}
						onChange={(event, value) => {
							if (activeStep > 1) {
								setActiveStep(0)
								setCursor(0)
							}
							setActionSelection(value)
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								fullWidth
							/>
						)}
					/>
				</FormControl>

				<FormControl style={{minWidth: '15em', paddingRight: "1em", marginTop: "0.4em"}}>
					<Label
						id="items-label"
						style={typeStyle}
						disableAnimation
						shrink
					>
                        Items
					</Label>

					<Autocomplete
						multiple
						classes={classes2}
						style={{marginTop: "1.5em"}}
						open={itemsDropdown}
						forcePopupIcon
						filterSelectedOptions
						popupIcon={<ExpandMoreRoundedIcon />}
						size="small"
						value={itemSelection ?? []}
						onOpen={() => { setItemsDropdown(true) }}
						onClose={() => { setItemsDropdown(false)}}
						options={items || []}
						loading={itemsListLoading}
						getOptionLabel={(option) => startCase(option)}
						onChange={(event, value) => {
							if (activeStep > 1) {
								setActiveStep(0)
								setCursor(0)
							}
							setItemSelection(value)
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								fullWidth
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{itemsListLoading ? <CircularProgress color="inherit" size={20} /> : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
				</FormControl>


				<FormControl style={{minWidth: '15em', paddingRight: "1em", marginTop: "0.4em"}}>
					<Label
						id="items-label"
						style={typeStyle}
						disableAnimation
						shrink
					>
                        Item Ids
					</Label>

					<Autocomplete
						multiple
						classes={classes2}
						style={{marginTop: "1.5em"}}
						open={itemIdsDropdown}
						forcePopupIcon
						filterSelectedOptions
						popupIcon={<ExpandMoreRoundedIcon />}
						size="small"
						value={itemIdsSelection ?? []}
						onOpen={() => { setItemIdsDropdown(true) }}
						onClose={() => { setItemIdsDropdown(false)}}
						options={itemIds || []}
						loading={itemIdsListLoading}
						onChange={(event, value) => {
							if (activeStep > 1) {
								setActiveStep(0)
								setCursor(0)
							}
							setItemIdsSelection(value)
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								fullWidth
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{itemIdsListLoading ? <CircularProgress color="inherit" size={20} /> : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
				</FormControl>

				<CancelButton data-testid="reset-button" variant="contained" onClick={() => {

					setDate(null)
					setUserSelection([])
					setActionSelection([])
					setItemSelection([])
					setItemIdsSelection([])
                        
				}} style={{marginTop: "2em"}}>
                    Reset
				</CancelButton>
			</div>
            
			<TableContainer>
				<Table
					className={classes.table}
					aria-labelledby="tableTitle"
					size={"medium"}
					aria-label="enhanced table"
				>
					<EnhancedTableHead
						headCells={headCells}
						classes={classes}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
					/>
					<TableBody>

						{loading ?
							<TableRow>
								<TableCell><Skeleton animation='wave'/></TableCell>
								<TableCell><Skeleton animation='wave' variant='circular' height={40} width={40}/> </TableCell>
								<TableCell><Skeleton animation='wave'/></TableCell>
								<TableCell><Skeleton animation='wave'/></TableCell>
								<TableCell><Skeleton animation='wave'/></TableCell>
								<TableCell><Skeleton animation='wave'/></TableCell>
								<TableCell><Skeleton animation='wave'/></TableCell>
								<TableCell><Skeleton animation='wave'/></TableCell>
								<TableCell></TableCell>
							</TableRow>
							: null}

						{logs?.length == 0 && !loading ? 
							<TableRow>
								<TableCell></TableCell>
								<TableCell> 
									<div style={{
										height: 40,
										display: 'flex',
										alignItems: 'center'
									}}>
										No Data Log Entries Found
									</div>
								 </TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
							</TableRow>
							: null}

						{logs?.flatMap((log) => {
							let {
								diff, 
								...dataLog
							} = log
							return Object.entries(log.diff).filter(e => e[0] != props.id_field).map((subLog) => {

								return {
									...dataLog, 
									field: subLog[0],
									oldValue: subLog[1][0],
									newValue: subLog[1][1],										
									users_name: `${log.user?.first_name} ${log.user?.last_name}`
								}
							})
						}).map(
							(row, index, array) => {


								const isDate = (!!row.field?.includes("date") || !!row.field?.includes("_at"))
                                    && row.field !== "start_at"
                                    && row.field !== "end_at"
                                    && row.field !== "dates"
                                
								const field = formattedField(row.field)

								let nextEquality = false
								let prevEquality = false

								// Determine whether to hide the top and bottom border of the current row
								const prevDate = array[index - 1] ? array[index - 1].created_at : null
								const currDate = array[index].created_at
								const nextDate = array[index + 1] ? array[index + 1].created_at : null

								if (
									array[index] &&
                                    array[index - 1] &&
                                    (Math.abs(currDate - prevDate) < halfASecond) &&
                                    array[index].created_by === array[index - 1].created_by &&
                                    array[index].action === array[index - 1].action &&
                                    array[index].item === array[index - 1].item &&
                                    array[index].item_id === array[index - 1].item_id &&
                                    !singledLogs.includes(array[index].item)
								) {
									prevEquality = true
								}

								if (
									array[index] &&
                                    array[index + 1] &&
                                    (Math.abs(nextDate - currDate) < halfASecond ) &&
                                    array[index].created_by === array[index + 1].created_by &&
                                    array[index].action === array[index + 1].action &&
                                    array[index].item === array[index + 1].item &&
                                    array[index].item_id === array[index + 1].item_id &&
                                    !singledLogs.includes(array[index].item)
								) {
									nextEquality = true
								}

								return (
									<TableRow key={index} className="datalog-table-row">
										<TableCell
											component="th"
											scope="row"
											padding="none"
											style={nextEquality ? {borderBottom: "transparent"} : null}
										>
											{!row.created_at ?  <span>-</span>: null}
											{!prevEquality && row.created_at ? formatDate(row.created_at, "MMMM do, yyyy  h':'mma O") : null}
										</TableCell>
										<TableCell
											component="th"
											scope="row"
											style={nextEquality ? {borderBottom: "transparent", maxWidth: "8em"} : {maxWidth: "8em"}}
										>
											{!prevEquality ?
												<UserAvatar sp={row.user} />
												: <Box style={{height: "3em"}}></Box>
											}
										</TableCell>
										<TableCell
											component="th"
											scope="row"
											padding="none"
											style={nextEquality ? {borderBottom: "transparent"} : null}
										>	
											{!prevEquality ?
												<span style={{color: getActionColor(row.action)}}>{row.action ? row.action : <span>-</span>}</span> 
												: <Box style={{height: "3em"}}></Box>
											}
										</TableCell>
										<TableCell
											component="th"
											scope="row"
											padding="none"
											style={nextEquality ? {borderBottom: "transparent"} : null}
										>
											{!prevEquality ?
												<span style={{whiteSpace: "pre"}}>{row.item ? row.item.charAt(0).toUpperCase() + row.item.slice(1):  <span>-</span>}</span> 
												: <Box style={{height: "3em"}}></Box>
											}
										</TableCell>
										<TableCell
											component="th"
											scope="row"
											padding="none"
											style={nextEquality ? {borderBottom: "transparent"} : null}
										>
											{!prevEquality ?
												<span style={{whiteSpace: "pre"}}>{row.item_id ? row.item_id: <span>-</span>}</span> 
												: <Box style={{height: "3em"}}></Box>
											}
										</TableCell>
										<TableCell
											component="th"
											scope="row"
											padding="none"
											style={nextEquality ? {borderBottom: "transparent"} : null}
										>
											<span>{field ? getFieldOverride(field, props.id_field) : '-'}</span> 
										</TableCell>
										<TableCell
											component="th"
											scope="row"
											padding="none"
											style={nextEquality ? {borderBottom: "transparent"} : null}
										>
											<div style={{maxHeight: "15em", overflow: "auto"}}>
												{getDisplayValue(row.oldValue, isDate, field, row.newValue)}
											</div>
										</TableCell>
										<TableCell
											component="th"
											scope="row"
											padding="none"
											style={nextEquality ? {borderBottom: "transparent"} : null}
										>
											<div style={{maxHeight: "15em", overflow: "auto"}}>
												{getDisplayValue(row.newValue, isDate, field, row.oldValue)}
											</div>
										</TableCell>
										<TableCell
											component="th"
											scope="row"
											padding="none"
											style={nextEquality ? {borderBottom: "transparent"} : null}> 
											{!prevEquality ?
												<>
													<IconButton
														aria-label="More"
														onClick={() => {
															setCurrentLog(row)
															setInfoModal({open: true})
														}}
														size="large">
														<InfoOutlinedIcon />
													</IconButton>
												</>
												: null}
                                            
										</TableCell>
									</TableRow>
								)
							}
						)}
					</TableBody>

				</Table>
			</TableContainer>

			<div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
				<Pagination style={{
					paddingTop: '2em',
					paddingBottom: '1em',
					margin: 'auto',
				}} count={steps} page={activeStep+1} onChange={(event, page) => {

					setActiveStep(page - 1)
					setCursor((page - 1) * 10)

					// Scroll to top of page
					var elmnt = document.querySelector('.data-list')
					elmnt.scrollIntoView({behavior: "smooth", block: "start"})
				}}/>
			</div>
        
		</Paper>

		<InfoCard open={infoModal.open} object={currentLog} ignoredAttributes={["field", "old_value", "new_value", "modified_by", "modified_at"]} setInfoModal={setInfoModal} allAttributes/>
	</>
}

export default withApollo(DataLog)
