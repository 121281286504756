/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { ESOrder } from '../common/typescriptVars'
import { Avatar, Box, CircularProgress, Paper, TablePagination, Typography } from '@mui/material'
import LimitedAutocomplete from '../common/LimitedAutocomplete'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { autoCompleteErrorStyles, LightTooltip } from '../styles/makesStyles'
import TextBoxForAutocomplete from '../input/Text/TextBoxForAutocomplete'
import { SIMPLE_SEARCH_ART } from '../Art/Queries'
import { SearchTerm } from '../common/SavedSearch'
import { DispatchContext, AuthStateContext} from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { useApolloClient } from '@apollo/client'
import { DocumentNode } from 'graphql'
import Grid from '@mui/material/Grid'
import { Skeleton } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import { formatDate, formatter, getArtStatusChip, getContactName } from '../common/helpers'
import ShowcaseArtModal from './Artist/ShowcaseArtModal'
import { permissions, permissionValues } from '../constants/permissions'
import { AVAILABLE_ART_SEARCH } from './Artist/Available'
import { SearchButton } from '../input/Buttons'
import { useNavigate } from 'react-router-dom'
import { groupBy } from 'lodash'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import ShowcaseSortOrder from './ShowcaseSortOrder'
import { LookupContext } from '../store'
import ShowcaseToggle from './ShowcaseToggle'
import ShowcaseSelectChips from './ShowcaseSelectChips'
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone'
import { dealContactRoles } from '../constants/values'

const GRID_ITEM_WIDTH = 200
const GRID_ITEM_PADDING = 32

export const GridItemInner = (props: any) => {

	const lookup: any = useContext(LookupContext)
	const currencies = lookup.data?.getCurrencies
	const artStatus: any = lookup.data?.getArtStatuses
	
	const { showcaseSelectMode, setShowcaseSelectMode } = props

	const artPiece = props.artPiece

	const artist = [artPiece?.artist?.first_name, artPiece?.artist?.last_name]
		.filter(e => e).join(" ")
	const titleYear = [artPiece?.title, artPiece?.year]
		.filter(e => e).join(', ')
	const retailValue = (artPiece.primaryRetailPrice && props.showcaseInformation && props.page !== "MuseumOwned") ? 
		formatter(currencies?.find((e: any) => e.id == artPiece.primary_currency_id)?.label || 'USD').format(artPiece.primaryRetailPrice) : 
		null
	
	const getMusuemSourceVal = (artPiece: any) => {

		const firstSource = artPiece.current_sources?.[0]

		const owners = props.sources?.filter((e: any) => e.deal_entry_status_category === 'Known Source' 
			&& (e.contactRole?.id == dealContactRoles.owner || e.contact_role_id == dealContactRoles.owner))

		let label

		if (firstSource) {
			switch (firstSource.deal_entry_status_category) {
			case 'Consignment In':
				label = 'Consignor'
				break
			case 'Consignment Out':
				label = 'Consignee'
				break
			case 'Gallery Purchase':
				label = 'Vendor'
				break
			case 'Loan In':
				label = 'Loaner'
				break
			case 'Loan Out':
				label = 'Loanee'
				break
			case 'Purchases':
				label = 'Purchaser'
				break
			case 'Known Source': {
				
				if (owners?.length > 1) {
					label = 'Known Sources'
					break
				}
	
				label = 'Known Source'
				break
			}
				
			default:
				label = 'Source'
				break
			}

			return label == 'Owners' ? `${label}: ${owners.map((c: any) => getContactName(c)).join(", ")}` : `${label}: ${getContactName(firstSource)}`
		}

		return null
	}

	const sourceValue = props.page === "MuseumOwned" ? getMusuemSourceVal(artPiece) : null

	const mediumValue =  artPiece.medium ?? null 
	const dimensions = artPiece.all_dimensions ?? null

	const statusValue = (
		<div
			style={{
				paddingTop: '0.5em',
				paddingRight: '0.5em',
				display: 'flex',
				flexDirection: 'row-reverse',
			}}
		>
			{getArtStatusChip(artPiece, false, artStatus)}
		</div>
	)

	const fullTitle = (
		<div style={{
			minWidth: '20em',
			padding: '0.5em',
			fontSize: 15
		}}>
			{
				[artist, titleYear, retailValue, sourceValue, mediumValue, dimensions, statusValue]
					.filter(e => e)
					.map(e => <div key={e as string}>{e}</div>)
			}
		</div>
	)

	const getMultiTitle = (reserveArray: any) => {
		

		return reserveArray.length && reserveArray.map((reserve: any) => {

			const galleryContacts = reserve.deal?.salesperson?.map((sp: any) => [sp.first_name, sp.last_name].filter((e) => e).join(' ')).join(", ")

			return (
				<div key={`reserve-${reserve.id}`} style={{
					minWidth: '20em',
					padding: '0.5em',
					fontSize: 15
				}}>
					{`Reserved${reserve.deal?.salesperson?.length ? ` for:\n${galleryContacts}` : ''}\nReserve queue position: ${reserve.reserve_position}`}
				</div>
			)
		})

	}

	const showcaseSelectModeOn = !!showcaseSelectMode?.selectMode

	const selectArt = (artPiece: any) => {

		// We will either add the id to the list, or remove it

		// Check if the artPiece id exists in the list
		const existingIndex = showcaseSelectMode.artIds.findIndex((e: number) => e == artPiece.id)
		
		// Add new art id
		if (existingIndex === -1) {	
			setShowcaseSelectMode({
				...showcaseSelectMode,
				artIds: [...showcaseSelectMode.artIds, artPiece.id]
			})
		}

		else {
			setShowcaseSelectMode({
				...showcaseSelectMode,
				artIds: showcaseSelectMode.artIds.filter((_: any, i: number) => i !== existingIndex)
			})
		}	
	}

	const isShowcaseSelected = (artPiece: any) => showcaseSelectMode.artIds.includes(artPiece?.id)

	return (
		<>
			<LightTooltip title={fullTitle} placement='bottom' arrow>
				<Avatar
					alt={artPiece.title || artPiece.code_name}
					src={artPiece.imgUrl}
					sx={{ width: GRID_ITEM_WIDTH, height: GRID_ITEM_WIDTH, boxShadow: 1, cursor: 'pointer' }}
					variant='rounded'
					onClick={(e: any) => showcaseSelectModeOn ? selectArt(artPiece) : props.onClick(e)}
					className={showcaseSelectModeOn && isShowcaseSelected(artPiece) ? "showcase-selected" : undefined}
				>
					{!artPiece.imgUrl ?
						<img alt={artPiece.title || artPiece.code_name}
							style={{ height: '85%' }}
							src={`/images/icons/White/ArtImage.svg`}
						/> : null}
				</Avatar>
			</LightTooltip>

			{isShowcaseSelected(artPiece) ? <CheckCircleTwoToneIcon sx={{ 
				position: 'relative',
				bottom: '1.1em',
				left: '7.3em',
				color: 'white',
			}}/> : null}

			{(props.hasViewDealPermission && props.showReserve && artPiece.reserves?.length)  ? (
				<div style={{ 
					display: 'flex', 
					justifyContent: 'end',
					marginTop: isShowcaseSelected(artPiece) ? '-1.4em' : '0.5em' 
				}}>
					{artPiece.reserves.slice(0, artPiece.reserves.length > 7 ? 7 : artPiece.reserves.length).map((reserve: any, index: number) => {

						const galleryContacts = reserve.deal?.salesperson?.map((sp: any) => [sp.first_name, sp.last_name].filter((e) => e).join(' ')).join(", ")

						const getTitle = () => {
							if (index == 0) {
								
								return (
									<div style={{
										minWidth: '20em',
										padding: '0.5em',
										fontSize: 15
									}}>
										{`Reserved${reserve.deal?.salesperson?.length ? ` for:\n${galleryContacts}` : ''}${reserve.reserve_end_at ? `\nReserved until: \n${formatDate(reserve.reserve_end_at, "MMMM do, yyyy  h':'mma O")}` : ''}`}
									</div>
								)
							}
							return (
								<div style={{
									minWidth: '20em',
									padding: '0.5em',
									fontSize: 15
								}}>
									{`Reserved${reserve.deal?.salesperson?.length ? ` for:\n${galleryContacts}` : ''}\nReserve queue position: ${reserve.reserve_position}`}
								</div>
							)
						}

						return (
							<LightTooltip key={`reserve-${reserve.id}`} title={getTitle()} placement='bottom' arrow>
								<CircleIcon sx={{ color: '#ed7d31' }} />
							</LightTooltip>
						)
					})}
					{artPiece.reserves?.length > 7 ? (
						<LightTooltip title={getMultiTitle(artPiece.reserves.slice(7, artPiece.reserves.length))} placement='bottom' arrow>
							<span>{`+${artPiece.reserves.length - 7}`}</span>
						</LightTooltip>
					) : null}
				</div>
			) : null}
		</>
	)
}

type ArtT = any
interface ArtData {
	items: ArtT[]
	totalItems: number
	cursor: number
}

interface ShowcaseGridViewProps {
	filters?: SearchTerm[]
	query: DocumentNode
	page: string
	groupBy?: (element: any) => string
	comparator?: ((a: string, b: string) => number) | undefined
	onClose?: () => void
	showBackButton?: boolean
	showReserve?: boolean
	showRelevance?: boolean
	showSort?: boolean
	state?: any
	title?: string
	order?: ESOrder
	orderBy?: string
	height?: string
	showShowcaseToggle?: Boolean
	variables?: any
	limit?: number
	getArt?: (results: any) => ArtData
	removeFrame?: boolean
	hideCreation?: Boolean
	showcaseInformation: Boolean
	setShowcaseInformation: () => void
	returnRoute?: String
	showcaseSelectMode?: any
	setShowcaseSelectMode: (newShowcaseMode: any) => void
	loading?: Boolean
}

export const ShowcaseGridView = (props: ShowcaseGridViewProps) => {


	const DEFAULT_LIMIT = 25

	const navigate = useNavigate()
	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity: string, text: string) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const closeSnackbar = () => {
		dispatch({ type: 'closeSnackBar' })
	}

	// Searching
	const [cursor, setCursor] = React.useState(0)
	const [art, setArt] = React.useState<ArtT>([])
	const [limit, setLimit] = React.useState(props.limit || DEFAULT_LIMIT)
	let defaultOrder = props.order || (props.showRelevance ? undefined : 'DESC')
	let defaultOrderBy = props.orderBy || (props.showRelevance ? undefined : 'year')

	const [order, setOrder] = React.useState<ESOrder | undefined>(defaultOrder)
	const [orderBy, setOrderBy] = React.useState(defaultOrderBy)
	const [activeStep, setActiveStep] = React.useState(0)
	const [totalItems, setTotalItems] = React.useState(0)
	const [steps, setSteps] = React.useState(1)
	const [loading, setLoading] = React.useState(false)
	const [rowsPerPageOps, setRowsPerPageOps] = React.useState([
		...(new Set([10, 25, 50, props.limit]))
		// @ts-ignore
	].filter(a => a).sort((a, b) => a - b))
	const widthRef = useRef<HTMLElement>(null)

	// Dynamically change the DEFAULT_LIMIT so the rows are complete
	// Only runs on page load, resizing won't change this
	useEffect(() => {
		// Don't run when limit override provided or when we are grouping by dates
		if (!props.limit && !props.groupBy) {
			// Total width of TablePagination element, 1300 is a size where 25 is perfect
			const totalWidth = widthRef?.current?.clientWidth || 1300
			const gridItemWidth = GRID_ITEM_WIDTH + GRID_ITEM_PADDING
			const cols = Math.floor(totalWidth / gridItemWidth)
			const newLimit = limit - (limit % cols)

			setLimit(newLimit)
			setRowsPerPageOps([
				...(new Set([10, 25, 50, newLimit]))
				// @ts-ignore
			].filter(a => a).sort((a, b) => a - b))
		}
	}, [widthRef])

	const defaultFilters = props.filters ?? []
	const listingId = props.filters?.find(el => el.field == "listing_number")?.value

	const { showcaseSelectMode, setShowcaseSelectMode } = props

	// Autocomplete
	const [artOpen, setArtOpen] = React.useState(false)
	const [artOptions, setArtOptions] = React.useState([])
	const [loadArt, setLoadArt] = React.useState(false)
	const artLoading = artOpen && !!loadArt
	const [artAutocomplete, setArtAutocomplete] = React.useState<ArtT | null>(null)

	const client = useApolloClient()

	const authState = useContext(AuthStateContext)
	// @ts-ignore
	const userPermissions  = authState?.user?.permissions

	const dealPermission = userPermissions?.find((e: any) => e.permission_id == permissions.DEALS)
		.permission_value_id

	const hasViewDealPermission = dealPermission >= permissionValues.VIEW_ONLY

	// Modal
	const [artModal, setArtModal] = React.useState(null)

	React.useEffect(() => {
		let active = true

		if (client && artLoading) {
			client
				.query({
					query: SIMPLE_SEARCH_ART,
					variables: { query: loadArt },
				})
				.then((result: any) => {
					if (active) {
						setLoadArt(false)
						setArtOptions(result.data?.simpleSearchArt || [])
					}
				})
		}

		return () => {
			active = false
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadArt])


	const handleSubmit = (cursorOverride: number, filters: SearchTerm[], orderOverride: any = null) => {
		setLoading(true)
		client
			.query({
				query: props.query ?? AVAILABLE_ART_SEARCH,
				variables: {
					cursor: cursorOverride ?? cursor,
					limit,
					field: orderBy || undefined,
					direction: order || undefined,
					thumbnailResolution: '256x256',
					filters,
					...(props.variables || {})
				},
			})
			.then((result: any) => {
				setLoading(false)
				const { data } = result
				let resultArt: ArtData
				if (props.getArt) {
					resultArt = props.getArt(data)
				} else {
					resultArt = data.searchDynamicArt
				}
				if (resultArt?.items) {
					setArt(resultArt.items)

					if (resultArt.items?.length < 1) setSteps(1)
					else setSteps(Math.ceil(resultArt.totalItems / limit))

					setTotalItems(resultArt.totalItems || 0)
					if (resultArt.totalItems || props.removeFrame) closeSnackbar()
				} else {
					console.error(data)
					openSnackbar(severity.ERROR, 'There was an error searching art.')
				}
			})
			.catch((error: Error) => {
				setLoading(false)
				console.error(error)
				openSnackbar(severity.ERROR, 'There was an error retrieving art.')
			})
	}

	// On change of limit, etc
	// Used for initial page load since limit is changed/set on load
	// Also searches on change of listing id
	useEffect(() => {

		if (props.loading == true) return

		if (artAutocomplete === null) {
			handleSubmit(0, defaultFilters)
		} else if (typeof artAutocomplete == 'string') {
			handleSubmit(0, [...defaultFilters, { field: 'query', type: 'eq', value: artAutocomplete, isOptional: false }])
		} else {
			handleSubmit(0, [...defaultFilters, { field: 'query', type: 'eq', value: artAutocomplete.title, isOptional: false }])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [limit, order, orderBy, listingId])

	let title = props.title
	if (props.state && !props.title) {
		title = undefined
		if (props.state?.getArtist) {
			title = [props.state?.getArtist?.first_name, props.state?.getArtist?.last_name]
				.filter((e) => e).join(' ')
		}
	} 

	let groupedArt: any
	let groups: string[] | undefined

	if (props.groupBy) {
		groupedArt = groupBy(art, props.groupBy)
		groups = Object.keys(groupedArt).sort(props.comparator)
		if (order === 'DESC') { groups.reverse()}
		let finalGroup = groupedArt['']
		groups = groups.filter(g => !!g)
		if (finalGroup) groups = groups.concat('')
	}

	const inner = (
		<>
			{/* Only show inner content if not loading and there are results */}
			{!loading && art?.length ? 
				<>
					<div
						style={{
							height: props.height ?? 'calc(100vh - 22em)',
							padding: '1em',
							overflow: 'auto'
						}}
					>

						{/* Grouped By Year  */}
						{groups
							? groups.map((group, i: number) => (
								<div key={i} style={{ paddingBottom: '1em' }}>
									<Typography
										variant='h5'
										key={group}
										sx={{
											marginTop: '1em',
											marginBottom: group ? '1em' : '1em',
											color: 'rgba(0, 0, 0, 0.6)',
										}}
									>
										{group}
									</Typography>
									<Grid container columnSpacing={4} rowSpacing={6}>
										{groupedArt[group].map((art: any) => {
											return (
												<Grid item key={`art-${art.id}`}>
													<GridItemInner
														page={props.page}
														artPiece={art}
														showReserve={props.showReserve}
														hasViewDealPermission={hasViewDealPermission}
														onClick={() => setArtModal(art.id)}
														showcaseInformation={props.showcaseInformation}
														showcaseSelectMode={showcaseSelectMode} 
														setShowcaseSelectMode={setShowcaseSelectMode}
													/>
												</Grid>
											)
										})}
									</Grid>
									<div
										style={{
											marginTop: '1em',
											borderBottom: group ? '1px solid rgb(206, 212, 218)' : 'none',
											paddingBottom: '1em',
											marginBottom: group ? '1em' : '1em',
										}}
									></div>
								</div>
				  ))
							: null}

						{/* Not Grouped */}
						{!groups ? (
							<Grid container columnSpacing={4} rowSpacing={6}>
								{art.map((art: any) => {
									return (
										<Grid item key={`art-${art.id}`}>
											<GridItemInner 
												page={props.page}
												artPiece={art} 
												showReserve={props.showReserve} 
												hasViewDealPermission={hasViewDealPermission} 
												onClick={() => setArtModal(art.id)} 
												showcaseInformation={props.showcaseInformation} 
												showcaseSelectMode={showcaseSelectMode} 
												setShowcaseSelectMode={setShowcaseSelectMode}
											/>
										</Grid>
									)
								})}
							</Grid>
						) : null}
					</div> </> : null}

			{/* No rows */}
			{!art?.length && !loading && !props.loading ? (
				<Typography variant='body1' style={{ margin: 'auto' }}>
				No Results
				</Typography>
			) : null}

			{/* Loading Spinner */}
			{props.loading || loading ? <CircularProgress size='2rem' sx={{ margin: 'auto' }} /> : null}

			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
				ref={widthRef}
			>
				{props.showBackButton ? (
					<SearchButton
						startIcon={<KeyboardArrowLeft />}
						style={{ backgroundColor: '#4465D1' }}
						onClick={() => {
							if (props.onClose) props.onClose()
							// @ts-ignore
							else navigate(props?.returnRoute)
						}}
					>
					Return to Search
					</SearchButton>
				) : (
					<div></div>
				)}

				{/* Grid view */}
				{art.length ? (
					<TablePagination
						component='div'
						count={totalItems}
						page={activeStep}
						//@ts-ignore
						rowsPerPageOptions={rowsPerPageOps} // TS doesn't like that is a react state and not an int array
						onPageChange={(event, page) => {
							if (artAutocomplete === null) {
								handleSubmit(page * limit, defaultFilters)
							} else if (typeof artAutocomplete == 'string') {
								handleSubmit(page * limit, [...defaultFilters, { field: 'query', type: 'eq', value: artAutocomplete, isOptional: false }])
							} else {
								handleSubmit(page * limit, [...defaultFilters, { field: 'query', type: 'eq', value: artAutocomplete.title, isOptional: false }])
							}

							setActiveStep(page)
						}}
						rowsPerPage={limit}
						labelRowsPerPage={"Items per page:"}
						onRowsPerPageChange={(event) => setLimit(parseInt(event.target.value))}
					/>
				) : null}
			</Box>
			{/* On click of a grid item */}
			<ShowcaseArtModal
				artModal={artModal}
				setArtModal={setArtModal}
				type={props.page}
				artList={art}
				setArtList={setArt}
				showRelevance={true}
				hasViewDealPermission={hasViewDealPermission}
				hideCreation={props.hideCreation}
				showcaseInformation={props.showcaseInformation}
				setShowcaseInformation={props.setShowcaseInformation}
			/>
		</>
	)


	// Sometimes we just return the inner portion of the showcase grid view
	if (props.removeFrame) return inner

	return <>
		<Paper className='qv-margin' sx={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(100% - 2em)', height: 'fit-content' }}>
			<Box
				sx={{
					marginBottom: '1em',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'start'
					}}
				>
					{/* Title (Artist name, ect...) */}
					<Typography variant='h5'>
						{(title === undefined) ?
							<Skeleton animation='wave' width='15em' /> :
							<span>{title}</span>
						}
					</Typography>
				</div>
			

				<div
					style={{
						display: 'flex',
						alignItems: 'end',
					}}
				>

					{props.showShowcaseToggle ?  <div style={{ marginRight: '1em' }}><ShowcaseToggle /></div> : null}

					<ShowcaseSelectChips showcaseSelectMode={showcaseSelectMode} setShowcaseSelectMode={setShowcaseSelectMode}/>

					{ props.showSort !== false ? <ShowcaseSortOrder 
						order={order}
						orderBy={orderBy}
						onOrderByChange={setOrderBy}
						onOrderChange={setOrder}
						largeSize
						showRelevance={props.showRelevance}
					/> : null
					}
					<LimitedAutocomplete
						freeSolo
						query={loadArt}
						setQuery={setLoadArt}
						filterSelectedOptions
						forcePopupIcon
						popupIcon={<ExpandMoreRoundedIcon />}
						classes={autoCompleteErrorStyles()}
						open={artOpen && !!artOptions?.length}
						onOpen={() => setArtOpen(true)}
						onClose={() => setArtOpen(false)}
						getOptionLabel={(art: any) => {
						// Free solo change
							if (typeof art == 'string') return art

							// User selected art title
							return art.title
						}}
						options={artOptions}
						loading={artLoading}
						isOptionEqualToValue={(option: any, value: any) => option.id == value.id}
						value={artAutocomplete}
						onChange={(event: any, value: any) => {
							setArtAutocomplete(value)

							if (value === null) {
								handleSubmit(0, defaultFilters)
								setArtOptions([])
							} else if (typeof value == 'string') {
								handleSubmit(0, [...defaultFilters, { field: 'query', type: 'eq', value, isOptional: false }])
							} else {
								handleSubmit(0, [...defaultFilters, { field: 'query', type: 'eq', value: value.title, isOptional: false }])
							}
						}}
						renderInput={(params: any) => (
							<TextBoxForAutocomplete
								{...params}
								id='art-autocomplete'
								variant='outlined'
								fullWidth
								sx={{
									backgroundColor: 'white',
								}}
								classes={{ notchedOutline: null }}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{artLoading ? <CircularProgress color='inherit' size={20} /> : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
									style: {
										width: '30em',
									},
								}}
							/>
						)}
					/>
				</div>
			</Box>
			
			{/* The inner component here! */}
			{inner}

		</Paper>
	</>
}

export default ShowcaseGridView
