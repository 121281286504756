/* eslint-disable eqeqeq */
import React, { useCallback, useContext } from 'react'
import { TableHead, TableRow, TableCell, IconButton, Menu, MenuItem } from '@mui/material'
import TableSortLabel from "@mui/material/TableSortLabel"
import EnhancedTableHeadCell from './EnhancedTableHeadCell'
import FlagIcon from '@mui/icons-material/Flag'
import { useMutation } from '@apollo/client'
import { CLEAR_FLAGS, TOGGLE_FLAGGED_ENTITIES } from './Queries'
import { DispatchContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { GLOBAL_SEARCH_MAX_SELECTION } from '../constants/values'
import { SELECT_ALL_ART, SELECT_ALL_ARTISTS, SELECT_ALL_CONTACTS, SELECT_ALL_DEALS, SELECT_ALL_LISTINGS } from '../Search/ActionButtons/Queries'
import { withApollo } from '@apollo/client/react/hoc'
import isEqualWith from 'lodash/isEqualWith'


function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

export function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy)
}

export function stableSort(array, comparator) {

	if (!array) return []
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map(el => el[0])
}

/**
 * @typedef EnhancedTableHeadProps
 * @property {Object[]} headCells - The list of each element that create a column in the table.
 */

/**
 * This functional component create a Material-UI TableHead that can control the rows below it
 * via a sorting button/header text
 *
 * The headCells of the table, each has an id, label and two booleans:
 * numeric and disablePadding. Object ID must match the GraphQL query's attributes name.
 *
 * @param {EnhancedTableHeadProps} props - A list of table head elements
 */
const EnhancedTableHead = (props) => {

	const [sortCell, setSortCell] = React.useState(null)
	const { order, orderBy, onRequestSort } = props
	const createSortHandler = property => event => {
		onRequestSort(event, property)
	}

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])


	// Menu
	const [anchorEl, setAnchorEl] = React.useState(null)
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const getQuery = (type) => {
		switch (type) {
		case 'Art':
			return SELECT_ALL_ART

		case 'Contact':
			return SELECT_ALL_CONTACTS
			
		case 'Deal':
			return SELECT_ALL_DEALS

		case 'Artist':
			return SELECT_ALL_ARTISTS

		case 'Listing':
		default:
			return SELECT_ALL_LISTINGS
		}
	}
	
	const query = getQuery(props.type)


	const [toggleFlaggedEntities] = useMutation(TOGGLE_FLAGGED_ENTITIES)


	const flagAll = (items) => {

		let contactIds = []
		let dealIds = []
		let artIds = []
		let artistIds = []
		let listingIds = []

		if (props.type === "Art") {
			artIds = items.map(e => Number(e.id))
		}

		if (props.type === "Contact") {
			contactIds = items.map(e => Number(e.id))
		}

		if (props.type === "Deal") {
			dealIds = items.map(e => Number(e.id))
		}
		
		if (props.type === "Artist") {
			artistIds = items.map(e => Number(e.id))
		}

		if (props.type === "Listing") {
			listingIds = items.map(e => Number(e.id))
		}


		toggleFlaggedEntities({
			variables: {
				contacts: contactIds,
				deals: dealIds,
				listings: listingIds,
				artists: artistIds,
				art: artIds,
				setFlag: true
			},
		}).then((res) => {
			if (res && res.data.toggleFlaggedEntities.errors) {
				openSnackbar( severity.ERROR, 'Could not toggle these flags.')

			} else if (res && res.data.toggleFlaggedEntities.success === false) {
				openSnackbar(severity.ERROR, 'There was an error toggling these flags.')

			} else if (res && res.data.toggleFlaggedEntities.success) {

				openSnackbar(severity.SUCCESS, 'Successfully flagged entities.')

				props.setFlaggedCount(cur => cur + res.data.toggleFlaggedEntities.count)

				props.setRows(
					props.rows.map(el => ({
						...el,
						isFlagged: true
					}))
				)
			}

		})
			.catch((e) => {
				console.error(e)
				openSnackbar( severity.ERROR, 'There was an error toggling these flags.')
			})
	}


	const [clearFlags] = useMutation(CLEAR_FLAGS, {
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, "Could not clear flags.")
		},
		onCompleted: (response) => {

			if (response.clearFlags?.success === true) {
				// Success
				openSnackbar(severity.SUCCESS, response.clearFlags.message)

				props.setRows(
					props.rows.map(el => ({
						...el,
						isFlagged: false
					}))
				)
			} else {
				openSnackbar(severity.ERROR, response.clearFlags.message)
			}
		}
	})

	return (

		<>
			<TableHead style={props.darkMode ? {borderBottom: "0.1em solid #6e6e6e"} : null }>
				<TableRow >
					{props.headCells?.map((headCell, i) => {
						if (headCell.data) {
							return (
								<EnhancedTableHeadCell
									key={`${headCell.label}-${i}`}
									headCell={headCell}
									sortCell={sortCell}
									setSortCell={setSortCell}
									rowCount={props.rowCount}
									order={order}
									orderBy={orderBy}
									createSortHandler={createSortHandler}
								/>
							)
						}

						if (headCell.isFlagged) {
							return (
								<TableCell
									key={`${headCell.label}-${i}`}
									align={headCell.numeric ? "right" : "left"}
									padding={headCell.disablePadding ? "none" : "normal"}
									style={{ padding: 6 }}
								>
									{props.mainSearchPage ? <IconButton onClick={e => handleClick(e)} aria-label="flagged" size="small" style={{
										height: 'fit-content'
									}}>
										<FlagIcon style={{color: '#919191'}} />
									</IconButton> : null }
									
								</TableCell>
							)
						}

						else
						if (headCell.label.toLowerCase() !== "actions" && headCell.label.toLowerCase() !== "disabled") {
							return (
								<TableCell
									key={`${headCell.label}-${i}`}
									align={headCell.numeric ? "right" : "left"}
									padding={headCell.disablePadding ? "none" : "normal"}
									sortDirection={orderBy === headCell.id ? order : false}
									style={{
										...headCell.style
									}}
								>
									<TableSortLabel
										active={orderBy === headCell.id}
										direction={orderBy === headCell.id ? order : "asc"}
										onClick={!headCell.noSort ? createSortHandler(headCell.id) : null}
										hideSortIcon={headCell.noSort}
										style={headCell.noSort ? {cursor: "default", fontWeight: 400} : {fontWeight: 400}}
									>
										{headCell.label}
									</TableSortLabel>
								</TableCell>
							)
						} else {
							return (
								<TableCell key={headCell.label+i} style={{width: "1em"}}></TableCell>
							)
						}
					})}
				</TableRow>
			</TableHead>

			<Menu
				id="flag-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={(e) => {

					const search_terms = [
						{ field: 'isFlagged', type: 'eq', value: 'true' },
					]

					props.setSelection([])
					if (props.setDealAutoValue) props.setDealAutoValue(null)
					props.setFilters(search_terms)

					props.handleSubmit({cursor: 0}, search_terms)
					props.setValue(1)
					props.onSelect(null)

					handleClose()

				}}>Find Flagged</MenuItem>
				<MenuItem disabled={!props.totalItems} onClick={(e) => {

					if (props.totalItems > GLOBAL_SEARCH_MAX_SELECTION) {
						openSnackbar(severity.WARNING, `Cannot flag more than ${GLOBAL_SEARCH_MAX_SELECTION} entities.`)
						return
					}

					openSnackbar(severity.INFO, "Loading...")
					props.client
						.query({
							query,
							variables: {
								...props.makeVariables(0, props.currentSearch, orderBy, order),
								limit: GLOBAL_SEARCH_MAX_SELECTION
							},
						})
						.then((result) => {
							const { data } = result

							if (data[props.searchResponse]) {
								const items = data[props.searchResponse].items

								if (items.length) flagAll(items)
								else openSnackbar(severity.WARNING, 'No items to flag.')
							} else {
								console.error(result)
								openSnackbar(severity.error, 'Unable to select results.')

							}
						})
						.catch((error) => {

							openSnackbar(severity.error, 'There was an error selecting results.')
							console.error(error)
						})

					handleClose()

				}}>Flag All</MenuItem>
				<MenuItem onClick={(e) => {

					let object = props.typeName == 'Deal' ? 'deal' : props.typeName.toLowerCase()

					if (props.setFlaggedCount) props.setFlaggedCount(0)

					// Special Case if user just flagged all
					if (
						isEqualWith(
							props.currentFilters?.[0],
							{
								field: "isFlagged",
								comparator: "eq",
								value: "true",
							},
							(value, other) => {
								return (
									value?.field == other?.field &&
									value?.comparator == other?.comparator &&
									value?.value == other?.value
								)
							}
						)
					) props.setRows([])
					

					clearFlags({
						variables: {
							user_id: props.userId,
							object
						}
					})

					handleClose()

				}}>Clear Flags</MenuItem>
			</Menu>

		</>
	)
}

export default withApollo(EnhancedTableHead)
