import React, { useEffect } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import chunk from 'lodash/chunk'
import { Checkbox, FormControlLabel } from '@mui/material'

const FaceSheetModal = (props) => {

	const listingContact = props?.options?.listingId
	
	const [fields, setFields] = React.useState({
		profilePicture: true,
		type: true,
		grade: true,
		activity: true,
		title: true,
		company: true,
		primaryAddress: true,
		emails: true,
		phoneNumbers: true,
		tags: true,
		criticalNotes: true,
		bioNote: true,
		wishListNote: true,
		collectionNote: true,
		purchases: true,
		knownWorks: true
	})

	const Labels = {
		profilePicture: 'Profile Picture',
		type: 'Type',
		listingStatus: 'Listing Status',
		grade: 'Grade',
		activity: 'Activity',
		title: 'Title',
		company: 'Company',
		primaryAddress: 'Primary Address',
		emails: 'Emails',
		phoneNumbers: 'Phone Numbers',
		tags: 'Tags',
		criticalNotes: 'Critical Notes',
		bioNote: 'Bio Note',
		wishListNote: 'Wish List Note',
		collectionNote: 'Collection Note',
		purchases: 'Purchases',
		knownWorks: 'Known Works'
	}
	
	useEffect(() => {
		if (listingContact) {
			setFields(fields => ({
				...fields,
				listingStatus: false
			}))
		} else {
			setFields(fields => {
				const { listingStatus, ...newFields } = fields
				return newFields
			})
		}
	}, [listingContact])

	const selectorRows = chunk(
		Object.keys(fields).map((key) => ({
			label: Labels[key],
			key: key,
			value: fields[key],
		})),
		Math.ceil(Object.keys(fields).length / 2)
	)

	useEffect(() => {
		props.onChange({
			...fields,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fields])

	return (
		<div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						paddingTop: '1.2em',
						marginRight: '1.3em',
						paddingLeft: '0.2em',
					}}
				>
					{selectorRows.map((row, i) => (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
							key={`row-${i}`}
						>
							{row.map((selector, j) => (
								<FormControlLabel
									label={selector.label}
									key={`checklabel-${j}`}
									control={
										<Checkbox
											checked={selector.value}
											name={`check-${j}`}
											onChange={(e) => {
												setFields({
													...fields,
													[selector.key]: e.target.checked,
												})
											}}
										/>
									}
								/>
							))}
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default withApollo(FaceSheetModal)
