import React, { useEffect, useContext, useCallback } from "react"
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import QuickView from "../QuickView/QuickView"
import { IconButton, Skeleton, Tooltip } from '@mui/material'
import { useNavigate, useParams } from "react-router-dom"
import { withApollo } from '@apollo/client/react/hoc'
import { FormControl, MenuItem, Select } from "@mui/material"
import Dropdown from "../input/Dropdown/Dropdown"
import { useMutation } from "@apollo/client"
import { UPDATE_CONTACT, GET_CONTACT_QV_DETAILS } from "./Queries.js"
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

import { DispatchContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import classNames from 'classnames'
import PhoneInput from 'react-phone-input-2'

import { LookupContext } from '../store'


import { avatarStyles } from '../styles/makesStyles'

import privateObjectTheme from "../styles/muiThemes/privateObjectTheme"
import lightTheme from "../styles/muiThemes/mainTheme"
import clsx from 'clsx'
import QuickViewNotes from "../QuickView/QuickViewNotes"
import AvatarInput from "../common/AvatarInput"
import CompanyUpdate from "./CompanyUpdate"
import { getContactName } from "../common/helpers"
import { findModeFieldsAtom, findModeFieldSelector, FindModeInput } from "../navigation/Tabs/TabbedPage"
import { useRecoilCallback, useRecoilState } from "recoil"
import Label from "../input/Label"
import { Close } from "@mui/icons-material"
import GalleryContactList from "../common/components/GalleryContactList"
import { getVerifiedIcon } from "../Art/ArtPiece/ArtQuickView"

const IsCompanySelect = (props) => {

	const [value, setValue] = useRecoilState(findModeFieldSelector('isCompany'))

	return <Select
		displayEmpty
		input={<Dropdown />}
		IconComponent={ExpandMoreRoundedIcon}
		value={value || ''}
		renderValue={val => {
			if (val === 'true') return 'Company'
			if (val === 'false') return 'Person'
			if (val === '') return <i>Ignored</i>
		}}
		onChange={(event) => setValue(event.target.value)}
	>
		<MenuItem key='active-null' value=""><i>Ignored</i></MenuItem>
		<MenuItem key='active-false' value="false">Person</MenuItem>
		<MenuItem key='active-true' value="true">Company</MenuItem>
	</Select>
}

const IsDeceasedSelect = (props) => {

	const [value, setValue] = useRecoilState(findModeFieldSelector('isDeceased'))

	return <Select
		displayEmpty
		input={<Dropdown />}
		IconComponent={ExpandMoreRoundedIcon}
		value={value || ''}
		renderValue={val => {
			if (val === 'true') return 'Deceased'
			if (val === 'false') return 'Is not Deceased'
			if (val === '') return <i>Ignored</i>
		}}
		onChange={(event) => setValue(event.target.value)}
	>
		<MenuItem key='active-null' value=""><i>Ignored</i></MenuItem>
		<MenuItem key='active-false' value="false">Is not Deceased</MenuItem>
		<MenuItem key='active-true' value="true">Deceased</MenuItem>
	</Select>
}

const KYCSelect = (props) => {

	const [value, setValue] = useRecoilState(findModeFieldSelector('is_compliant'))

	return <Select
		displayEmpty
		input={<Dropdown />}
		IconComponent={ExpandMoreRoundedIcon}
		value={value || ''}
		renderValue={val => {
			if (val === 'true') return 'Yes'
			if (val === 'false') return 'No'
			if (val === '') return <i>Ignored</i>
		}}
		onChange={(event) => setValue(event.target.value)}
	>
		<MenuItem key='active-null' value=""><i>Ignored</i></MenuItem>
		<MenuItem key='active-false' value="false">No</MenuItem>
		<MenuItem key='active-true' value="true">Yes</MenuItem>
	</Select>
}
/**
 * This function looks for the primary address in a list of addresses and returns a
 * array of the lines of the address to be formatted.
 *
 * @param {*} addresses - The passed array of addresses.
 */
export const getPrimaryFormattedAddress = (addresses) => {
	if (!addresses || addresses.length===0) return [""]
	const address = addresses?.find(address => address?.is_primary === true)

	let returnable = ""
	if (address?.street_1) returnable =  address?.street_1 + "\n"
	if (address?.street_2) returnable += (address?.street_2 + "\n")
	if (address?.street_3) returnable += (address?.street_3 + "\n")
	if (address?.town) returnable += (address?.town + ", ")
	if (address?.region) returnable += (address?.region + " ")
	if (address?.postcode) returnable += (address?.postcode)
	returnable += ("\n" + address?.country)

	return returnable.split("\n")
}

/**
 * @typedef ContactQuickViewProps
 * @property {import('./Contacts').Contact} contact
 */

/**
 * @param {ContactQuickViewProps} props
 */
function ContactQuickView(props) {

	// TODO: disable edit image and status upon lack of permissions
	const canEdit = Object.keys(props?.state?.getContact || {}).length && true

	const navigate = useNavigate()
	const lookup = useContext(LookupContext)
	const contactStatus = lookup.data?.getContactStatus

	const [contact, setContact] = React.useState({})
	const [notes, setNotes] = React.useState([])

	const classes = avatarStyles()
	const params = useParams()

	const salespersons = contact?.salesperson

	// Loading Logic
	const [loading, setLoading] = React.useState(true)

	useEffect(() => {

		if (Object.keys(contact || {}).length) {
			setLoading(false)
		} else setLoading(true)
	}, [contact])

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	// Enacts on the details page
	useEffect(() => {
		if (params.id !== undefined && !props.id && props.state) {

			setContact(props.state.getContact || {})
			setNotes(props.state.getCriticalContactNotes)
		}
	}, [params.id, props.id, props.state])

	// Enacts on the contacts list page
	useEffect(() => {

		let active = true

		if (props.id && !props.findMode) {

			setContact(null)
			setNotes([])

			props.client
				.query({query: GET_CONTACT_QV_DETAILS, variables: {id: props.id}})
				.then(result => {
					if (active) {
						setLoading(false)

						// Snackbar responses
						if (!result.data.getContact && result.errors) {
							// Error
							openSnackbar(severity.ERROR, result.errors[0].message)
							return
						} else {
							// Success
							setContact(result.data.getContact)
							setNotes(result.data.getCriticalContactNotes)
						}
					}

				})
				.catch(e => {
					if (active) {
						setLoading(false)
						// Error
						openSnackbar(severity.ERROR, "Could not retrieve contact Quick View.")
					}
				})
		}
		return () => active = false
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.id])

	const getContactQVStyle = (contact) => {
		if (contact?.is_deceased)
			return { boxShadow: '0 0 0 2pt black' }
		return null
	}

	const [updateContact, {data}] = useMutation(UPDATE_CONTACT)

	useEffect(() => {
		if ((data && data.errors) || (data && data.updateContact === null)) {
			openSnackbar(severity.ERROR, "Could not update contact.")
		} else if (data && data.updateContact?.success) {
			openSnackbar(severity.SUCCESS, "Successfully updated contact.")
			setContact({
				...data.updateContact.contact
			})

			// Make details are in sync
			props.setState({
				...props.state,
				getContact: {
					...props.state.getContact,
					...data.updateContact.contact
				}
			})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, updateContact])

	// Class names for phone flag label
	var trClasses = classNames({
		'plain-text': true,
		'quickview-flag': true
	})

	const privateContact = props.atPrivateObject || contact?.is_private

	// handle FindMode search
	const handleKeyDown = useRecoilCallback(({ snapshot }) => async (e) => {
		if (!props.findMode || e.key !== 'Enter') return
		e.stopPropagation()
		let fields = await snapshot.getPromise(findModeFieldsAtom)
		fields = Object.fromEntries(Object.entries(fields).filter(([_, v]) => v != null && v !== ''))
		navigate('/contacts', { state: { fields }})
	})

	const avatarContact = !props.findMode ? contact : ({ created_at: true })

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={privateContact ? privateObjectTheme : lightTheme}>
				<QuickView style={getContactQVStyle(contact)} 
					className={clsx({
						'quick-view-main': true,
						'private-object': privateContact
					})}
				>

					{privateContact ?
						<div className="private-qv-label">Private {contact?.is_company ? "Company" : "Contact"}</div> : null}

					<div className="quick-view-inner" onKeyDown={handleKeyDown}>
						<header>
							{ props.findMode ?
								<div style={{ marginLeft: '.5em' }}>
									<div style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}>
										<FormControl style={{ flex: 1  }}>
											<Label id="company-label" disableAnimation shrink>
                                                Company?
											</Label>
											<IsCompanySelect />
										</FormControl>
										<FormControl style={{ flex: 1  }}>
											<Label id="company-label" disableAnimation shrink>
                                                KYC?
											</Label>
											<KYCSelect />
										</FormControl>
										<FormControl style={{ flex: 1  }}>
											<Label id="company-label" disableAnimation shrink>
                                                Deceased?
											</Label>
											<IsDeceasedSelect />
										</FormControl>
									</div>
									<FindModeInput field="contact_status"
										placeholder="Status"
										style={{
											width: '100%',
											marginTop: '.5em',
											marginBottom: '.5em'
										}}
									/>
								</div>
								: <>

									<div style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}>
										{!loading ? <CompanyUpdate
											contact={contact}
											updateContact={updateContact}
											canEdit={canEdit}
										/> : null}
										{contact?.is_compliant && <span>
											<Tooltip title="KYC & AML Compliant" arrow>
												<IconButton
													style={{ height: '35px', width: '35px' }}
													size="large">
													{ getVerifiedIcon({
														isVerified: true
													}) }
												</IconButton>
											</Tooltip>
										</span>}

										{!loading ?
											<div style={{float: "right", marginLeft: 'auto'}}>
												<Select
													IconComponent={ExpandMoreRoundedIcon}
													id="contact-qv-status"
													readOnly={!canEdit}

													input={<Dropdown />}
													className="hideborder"
													style={{fontWeight: 600}}
													value={(contact?.status?.id && contactStatus) ? parseInt(contact?.status?.id) : ''}
													onChange={(event) => {

														if (event.target.value === "Deceased") return null

														let contactInput = {
															id: contact?.id,
															status_id: event.target.value
														}

														updateContact({ variables: { ContactInput: contactInput } })
													}}
												>
													{contactStatus && contactStatus.map(type => (
														<MenuItem key={type.id} value={type.id}>{type.value.charAt(0).toUpperCase() + type.value.substring(1) + `${contact?.is_deceased ? " - Deceased" : ''}`}</MenuItem>
													))}
													<MenuItem onClick={(e) => {
														e.preventDefault()
														e.stopPropagation()

														let contactInput = {
															id: contact?.id,
															is_deceased: !contact.is_deceased
														}

														updateContact({ variables: { ContactInput: contactInput } })

													}} key={"Mark Deceased"} value={"Deceased"}>Is{contact?.is_deceased ? " Not " : ' '}Deceased</MenuItem>

												</Select>
											</div> : null}
										{props.onClose && !loading && <span>
											<IconButton size="large" 
												sx={{ height: '35px', width: '35px' }}
												onClick={props.onClose}
											>
												<Close />
											</IconButton>
										</span>}
									</div>
								</>}

							<div style={{ paddingLeft: '0.5em' }} >
								{!props.findMode && <h2
									className={privateContact ? "quick-view-title-light" : "quick-view-title" }
									style={{textAlign:"left", float: "left"}}
									data-testid="search-qv-header"
								>
									{loading ? <Skeleton
										animation={loading ? "wave" : false}
										variant="text"
										width="12em"
									/> : <span style={{
										color: contact?.is_deceased ? "#A91D23" : null
									}}>
										{ getContactName(contact) }
									</span> || <span>-</span>}
								</h2>
								}

								{props.findMode ? <FindModeInput
									field="company_name"
									placeholder="Company"
									style={{ width: '100%', marginBottom: '.5em' }}
								/> :
									!contact?.is_company ? <>
										<h3 className={privateContact ? "paragraph-1-light" : "paragraph-1"}
											style={{
												display: 'flex',
												width: '100%',
												alignItems: 'center',
												justifyContent: 'space-between',
												fontSize: 14,
												fontWeight: 400
											}}>
											{contact?.company_name || (
												<>
													{loading ? <Skeleton
														animation={loading ? "wave" : false}
														variant="text"
														width="15em"
													/> : <span>-</span> }
												</>
											)}
										</h3>
										<h3 className={privateContact ? "paragraph-1-light" : "paragraph-1"}
											style={{
												display: 'flex',
												width: '100%',
												alignItems: 'center',
												justifyContent: 'space-between',
												fontSize: 14,
												fontWeight: 400
											}}>
											{contact?.title || null}
										</h3>
									</> : null}
								{props.findMode && <FindModeInput
									field="title"
									placeholder="Title"
									style={{ width: '100%' }}
								/> }
							</div>
						</header>

						<AvatarInput
							title={avatarContact?.preferred_name}
							className={classes.large}
							alt={avatarContact?.preferred_name}
							src={avatarContact?.imgUrl}
							editable={canEdit && !props.findMode}
							oldProfileLink={[avatarContact?.profile_link]}
							inputName="ContactInput"
							entityId={avatarContact?.id}
							mutation={updateContact}
							mutationName="updateContact"
							setEntity={contact => {
								setContact(contact)
								props.setState({getContact: contact })
							}}
							entity={avatarContact}
							entityName="contact"
							onPrivateObject={contact?.is_private}
						/>

						<table className="quickview-main">
							<tbody id="info-table">
								<tr>
									<th className="flex">Type</th>
									<td>
										{props.findMode ? <FindModeInput field="contact_types" /> :
											contact?.type?.map(e => e.contact_type).join(", ") || (
												<>
													{(loading && !props.findMode) ? <Skeleton
														animation={loading ? "wave" : false}
														variant="text"
														width="1em"
													/> : <span>-</span> }

												</>
											)}
									</td>
								</tr>
								<tr>
									<th>Grade</th>
									<td>
										{props.findMode ? <FindModeInput field="contactGrade" /> :
											contact?.grade?.value.toString() || (
												<>
													{loading ? <Skeleton
														animation={loading ? "wave" : false}
														variant="text"
														width="1em"
													/> : <span>-</span> }
												</>
											)}
									</td>
								</tr>
								<tr>
									<th>Activity</th>
									<td>
										{props.findMode ? <FindModeInput field="contactActivity" /> :
											contact?.activity?.value || (
												<>
													{loading ? <Skeleton
														animation={loading ? "wave" : false}
														variant="text"
														width="1em"
													/> : <span>-</span> }
												</>
											)}
									</td>
								</tr>
								<tr>
									<th style={{
										marginTop: '0.3em',
										display: 'block',
										verticalAlign:' top',
										marginRight: '6em',
									}}>Gallery Contacts</th>
									<td>
										<GalleryContactList 
											salespersons={salespersons}
											findMode={props.findMode}
											loading={props.loading}
											chunk={5}
										/>
									</td>
								</tr>
							</tbody>
						</table>
						{(!props.findMode) ?
							<>
								<h3 className={privateContact ? "paragraph-2-light" : "paragraph-2"}>Contact Information</h3>
								<table id="contact-table" className={clsx({
									'shadow-group': !privateContact,
									'contact-information': true,
									'shadow-group-light': privateContact
								})}>
									<tbody>
										<tr className={trClasses}>
											<th>Phone</th>
											<td style={{display: "flex"}}>
												{props.findMode ? <FindModeInput field="primary_phone" /> :
													(contact && contact?.phone?.length !== 0 && !loading) ?
														<>
															<a href={"tel:"+contact.phone?.find(phone => phone.is_primary === true)?.number}>
																<PhoneInput placeholder="" inputStyle={{height: "1.6em", color: privateContact ? "#6F8CED" : "#0000EE", textDecoration: "underline", cursor: "pointer", fontFamily: "graphik"}} value={contact.phone?.find(phone => phone.is_primary === true)?.number} disabled onChange={() => {}}/>
															</a>
															{contact.phone?.find(phone => phone.is_primary === true)?.extension ? "x"+contact.phone?.find(phone => phone.is_primary === true)?.extension : null}
														</>
														:
														<>
															{loading ? <Skeleton
																animation={loading ? "wave" : false}
																variant="text"
																width="9em"
															/> : <span>-</span> }
														</>
												}
											</td>
										</tr>
										<tr>
											<th>Email</th>
											<td>
												{props.findMode ? <FindModeInput field="primary_email" /> :
													(contact && contact?.email?.length !== 0 && !loading) ?
														<a href={"mailto:"+contact.email?.find(email => email.is_primary === true)?.email} style={privateContact ? {color: '#6F8CED'} : null}>
															{contact.email?.find(email => email.is_primary === true)?.email}
														</a>
														:
														<>
															{loading ? <Skeleton
																animation={loading ? "wave" : false}
																variant="text"
																width="10em"
															/> : <span>-</span> }
														</>
												}
											</td>
										</tr>
										<tr>
											<th>Address</th>
											<td>
												{props.findMode ? <FindModeInput field="full_addresses" /> :

													(contact && contact?.address?.length !== 0 && !loading) ?
														getPrimaryFormattedAddress(contact.address).map((line, i) => {
															return (
																<div key={"address-line " + i}>
																	<span>{line}</span><br/>
																</div>
															)
														})
														:
														<>
															{loading ? <>
																<Skeleton
																	animation={loading ? "wave" : false}
																	variant="text"
																	width="10em"
																/>
																<Skeleton
																	animation={loading ? "wave" : false}
																	variant="text"
																	width="9em"
																/>
																<Skeleton
																	animation={loading ? "wave" : false}
																	variant="text"
																	width="8em"
																/>
															</> : <span>-</span> }
														</>

												}
											</td>
										</tr>
									</tbody>
								</table>

							
								<QuickViewNotes
									notes={notes}
									entity={contact}
									privateObject={privateContact}
									loading={loading}
								/>
								
							</> : null}

						
					</div>
				</QuickView>
			</ThemeProvider>
		</StyledEngineProvider>
	)
}

export default withApollo(ContactQuickView)
