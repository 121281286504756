/* eslint-disable eqeqeq */
import groupBy from 'lodash/groupBy'
import FileList from './FileList'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_FILE_GROUPS } from './Queries'
import CircularLoading from '../navigation/CircularLoading'

export default function FilesGroups(props) {
	const [fileGroups, setFileGroups] = useState(props.fileGroups)
	const [files, setFiles] = useState({})

	useQuery(GET_FILE_GROUPS, {
		variables: { id: props.fileObjectId },
		skip: !props.fileObjectId || props.fileGroups,
		onCompleted: (response) => {
			setFileGroups(response.getFileGroups)
		},
	})

	// group up files.
	useEffect(() => {
		// for display purposes
		if (props.files) {

			const files = groupBy(
				props.files.map((file) => {
					
					return {
						...file,
						fileObject: file.fileCategory?.fileGroup?.fileObject?.value,
						fileGroup: file.fileCategory?.fileGroup?.value,
						fileCategory: file.fileCategory?.value,
						file_group_id: file.fileCategory?.fileGroup?.id,
						file_category_id: file.fileCategory?.id,
						versionHistory: props.files
							.filter((e) => {
								if (e.id == file.id) return true
								else if ((file.parent_file_id == e.id || e.parent_file_id == file.parent_file_id) && file.parent_file_id) return true
								else return false
							})
							.sort((a, b) => b.version - a.version)
							.map((x) => ({
								...x,
								fileObject: x.fileCategory?.fileGroup?.fileObject?.value,
								fileGroup: x.fileCategory?.fileGroup?.value,
								fileCategory: x.fileCategory?.value,
								file_group_id: x.fileCategory?.fileGroup?.id,
								file_category_id: x.fileCategory?.id,
							})),
					}
				}),
				'fileGroup'
			)

			setFiles(files)
		}
	}, [props.files, setFileGroups])

	return fileGroups
		? fileGroups.map((fileGroup) => {
			const { id, value, alias, hideAddButton = false, hideEditButtons = false } = fileGroup

			if (value === 'Operations') return null

			return (
				<FileList
					key={id}
					heading={alias || value}
					files={files[value]?.sort((a,b) => b?.created_at - a?.created_at) || []}
					setFiles={setFiles}
					fileObjectId={props.fileObjectId}
					objectId={props.objectId}
					options={props.options}
					refetch={props.refetch}
					tabbedPageFileRefetch={props.tabbedPageFileRefetch}
					setTabbedPageFileRefetch={props.setTabbedPageFileRefetch}
					atPrivateObject={props.atPrivateObject}
					hideAddButton={hideAddButton}
					hideEditButtons={hideEditButtons}
					loading={props.loading}
				/>
			)
		  })
		: <CircularLoading />
}
