import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { stripMeta } from '../../common/helpers'
import { UPDATE_ARTIST } from '../Queries'
import { useMutation } from '@apollo/client'
import React, { useContext, useCallback } from 'react'
import ResearchCard from '../../common/components/ResearchCard'

function ArtistResearch(props) {

	let { loading, error } = props
	let artist = props.state.getArtist || {}
	
	let setArtist = (artist) => props.setState({ getArtist: artist })

	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const [updateArtist, { loading: updateLoading }] = useMutation(UPDATE_ARTIST, {
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, 'Could not update.')
		},
		onCompleted: (response) => {
			if (response.updateArtist.success === true) {
				openSnackbar(severity.SUCCESS, response.updateArtist.message)
				setArtist(response.updateArtist.artist)
			} else {
				// On failure, reset artInput state, don't touch art
				// state and show error
				openSnackbar(severity.ERROR, response.updateArtist.message)
				setArtist(response.updateArtist.artist)
			}
		},
	})

	const update = (fields) => {
		let ArtistInput = stripMeta(fields)

		updateArtist({
			variables: {
				ArtistInput: {
					id: artist.id,
					...ArtistInput
				}
			},
		})
	}

	return !props.findMode && (
		<section className="main-page">
			<div>
				<div className="page-row" style={{marginLeft: '1em', marginTop: '1em'}}>
					<ResearchCard 
						title="Education"
						entityKey="education"
						linkField="artist_id"
						researchTypeField="artist_research_type_id"
						researchTypeId={1}
						loading={loading}
						error={error}
						entity={artist}
						update={update}
						disabled={updateLoading}
						hangingIndent
					/>
					<ResearchCard
						title="Selected Awards"
						entityKey="selected_awards"
						linkField="artist_id"
						researchTypeField="artist_research_type_id"
						researchTypeId={2}
						loading={loading}
						error={error}
						entity={artist}
						update={update}
						disabled={updateLoading}
						hangingIndent
					/>
				</div>
				<div className="page-row" style={{marginLeft: '1em'}}>
					<ResearchCard 
						title="Selected Solo Exhibitions"
						entityKey="selected_solo_exhibitions"
						linkField="artist_id"
						researchTypeField="artist_research_type_id"
						researchTypeId={3}
						loading={loading}
						error={error}
						entity={artist}
						update={update}
						disabled={updateLoading}
						hangingIndent
					/>
					<ResearchCard 
						title="Selected Monographs"
						entityKey="selected_monographs"
						linkField="artist_id"
						researchTypeField="artist_research_type_id"
						researchTypeId={4}
						loading={loading}
						error={error}
						entity={artist}
						update={update}
						disabled={updateLoading}
						hangingIndent
					/>
				</div>
			</div>
		</section>
	)
}

export default ArtistResearch
