import React from 'react'
import Toggle from '../input/Toggle/UserToggle'
import { LightTooltip } from '../styles/makesStyles'

const ShowcaseToggle = (props: any) => {
	return <LightTooltip title='Showcase Information Toggle' arrow>
		<div>
			<Toggle checked={props.showcaseInformation} onChange={() => props.setShowcaseInformation(!props.showcaseInformation)} />
		</div>
	</LightTooltip>
}

export default ShowcaseToggle
