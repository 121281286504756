import './Permissions.css'
import { CancelButton, SaveCardButton, NewButton } from '../input/Buttons'
import { DispatchContext } from '../store'
import { Paper, FormControlLabel, FormGroup, FormControl, FormLabel, Menu, MenuItem } from '@mui/material'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { UPDATE_PERMISSIONS } from './Queries'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import CreateIcon from '@mui/icons-material/Create'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AdminToggleSmall from '../input/Toggle/AdminToggleSmall'
import Divider from '@mui/material/Divider'
import * as Colors from '../styles/colors/Colors'
import UserToggle from '../input/Toggle/UserToggle'
import TransitionsModal from "../navigation/TransitionsModal/TransitionsModal"
import PermissionCopy from "./PermissionCopy"
import { permissions } from '../constants/permissions'
import { findModeAtom, findModeFieldSelector, FindModeInput } from '../navigation/Tabs/TabbedPage'
import { useRecoilState, useRecoilValue } from 'recoil'

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false
}

const options = [
	{ icon: <ClearIcon />, value: "3", label: "Cannot See" },
	{ icon: <VisibilityIcon />, value: "4", label: "View Only" },
	{ icon: <AddIcon />, value: "5", label: "Create & Edit Own" },
	{ icon: <CreateIcon />, value: "6", label: "Create & Edit All" }
]


/**
 * @typedef {Object} FourValueProps
 * @property {number=} value - The value of the Four Value (1-4? 3-6?)
 * @property {function(number): void=} onChange - Callback when the value is changed
 * @property {number} max - The maximum valid value
 * @property {boolean} disabled
 */

/**
 * A representation of a User
 * @param {FourValueProps} props 
 * @returns {JSX.Element}
 */
function FourValue(props) {

	const findMode = useRecoilValue(findModeAtom)

	const [value, setValue] = useRecoilState(findModeFieldSelector(props.field))

	useEffect(() => {
		if (Number(props.value) > Number(props.max)) {
			props.onChange(props.max.toString())
		}
	}, [props])
		
	return (
		<ToggleButtonGroup
			style={{backgroundColor: "transparent"}}
			size="small"
			value={!findMode ? props.value : value}
			exclusive
			onChange={(_, newValue) => {
				if (findMode) setValue(newValue)
				else props.onChange(newValue)
			}}>
			{ options.map((opt, i) => {

				const disabled = !findMode && (props.readOnly ? true : Number(opt.value) > Number(props.max) || props.disabled)

				return (
					<ToggleButton key={i} value={opt.value} aria-label={opt.label}
						className={ disabled ? "disabled-permission" : null }
						style={{
							margin: ".4em",
							borderRadius: "4px",
							backgroundColor: disabled ? "#DCDCDC" : "grey",
							borderColor: disabled ? "#DCDCDC" : "grey",
							padding: "0px",
							height: "fit-content",
							color: disabled ? "grey" : Colors.nav
						}}
						disabled={disabled}>
						{opt.icon}
					</ToggleButton>
				)
			})}
		</ToggleButtonGroup>
	)
}

// Get the padding of the passed permissions label
const getPermissionLabelStyles = (permission) => {

	const financialPermissions = [
		permissions.RETAIL,
		permissions.NET,
		permissions.INSURED,
		permissions.COST,
		permissions.PRODUCTION
	].map(e => e.toString())

	// Extra padding on permissions under financial section
	if (financialPermissions.includes(permission.permission_id)) {
		return  {
			paddingLeft: '2em', 
			paddingRight: '1em'
		} 
	}

	// Default dynamically rendered padding
	return  {
		paddingLeft: (permission.level -1 )* 1 + 'em', 
		paddingRight: '1em'
	} 
}

export function NestedFormControls({permission, userPerms, perms, handleChange, max, disabled, readOnly}) {
	const children = perms
		.filter(p => p.parent_id === permission.permission_id &&
			p.relationship_type === 'SINGLE DEPENDS ON')
		.sort((a, b) => a.ordinal - b.ordinal)
	return (
		<>
			{permission.permission_name === "Retail" ? 
				// Add header "Financials" 
				<FormControl component="fieldset">
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
						className={'permission-header'}>
						<FormLabel component="label" style={{paddingLeft: '1em', paddingRight: '1em', paddingTop: "0.5em", paddingBottom: "0.5em"}} >
							Financials
						</FormLabel>
					</div>
					
				</FormControl> 
				: null}
			<FormControl component="fieldset">
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
					className={children.length ? 'permission-header' : ''}>
					<FormLabel component="label" style={getPermissionLabelStyles(permission)}>
						{permission.permission_name}
					</FormLabel>
					<FourValue
						field={permission.path}
						value={userPerms[permission.permission_id]}
						max={max}
						onChange={!disabled ? handleChange(permission.permission_id) : () => {}}
						readOnly={readOnly}
						disabled={disabled}
					/>
				</div>
				<FormGroup> { 
					children.map(p => 
						<NestedFormControls 
							key={p.permission_id}
							permission={p}
							userPerms={userPerms} 
							perms={perms} 
							handleChange={handleChange}
							max={Math.min(userPerms[permission.permission_id] || 3, max)}
							readOnly={readOnly}
							disabled={disabled}
						/>)
				} </FormGroup>
			</FormControl> 
		</>
	)
}



export default function Permissions (props) {

	/* Map of parent perm to sub-perms and their default value. Additionally, an error message to throw if
	a user actives the parent perm but no sub-perms. 

	eg. 
	`parent`: {
		children: {`sub_perm`: `default_value`},
		validation_message: `message`
	}*/
	const subPerms = {
		7: {
			children: {55: "2", 56: "2"},
			validation_message: "Please provide at least one admin console sub-permission."
		}
	}

	const onChange = props.onChange || (() => {})
	const readOnly = props.readOnly
	
	const navigate = useNavigate()

	const reducedPermissions = (props.user?.permissions || [])
		.reduce((acc, el) => {
			acc[el.permission_id] = el.permission_value_id
			return acc
		}, {})

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])
	
	const [state, setState] = useState(initialState)
	let [userPerms, setUserPerms] = useState(reducedPermissions)
	const [subPermsError, setSubPermsError] = useState({})

	const [permissionCopy, setPermissionCopy] = useState(false)

	const validateSubPerms = () => {
		// Loop through each parent perm
		for (const parent in subPerms) {
			// If no sub-perms are `yes` && the parent is `yes` --> return false
			if (userPerms[parent] === "2") break
			if (
				( 
					// boolean OR reduce the subperms to single boolean `yes`=true `no`=false
					Object.keys(subPerms[parent]["children"]).reduce((prev, key) => {
						return prev || userPerms[key] === "1" ? true : false
					}, false) 
					&&
					// Parent perm is `yes`
					userPerms[parent] === "1"
				) === false
			) {
				// Throw error message, highlight problem category, return false
				console.log("returning", false, parent)
				openSnackbar(severity.ERROR, subPerms[parent]["validation_message"])
				setSubPermsError({
					...subPermsError,
					[parent]: true
				})
				return false
			}
		}
		return true
	}
	
	useEffect(() => {
		setUserPerms(reducedPermissions)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setUserPerms, props.user])

	let completePerms = useRef([])
	let fourValues = useRef([])
	let dependencies = useRef([])
	
	const handleClick = event => {
		if (props.findMode) return
		if(!readOnly){
			event.preventDefault()
			setState({
				mouseX: event.clientX - 2,
				mouseY: event.clientY - 4,
				editable: false
			})
		}
	}
	const handleClose = option => {
		if (option === "edit") {
			setState(Object.assign({}, initialState, { editable: readOnly ? false : true }))
		} else {
			setState(initialState)
		}
	}

	const handleChange = (perm) => (val) => {
		if (val) {
			const newPerms = {
				...userPerms,
				[perm]: val
			}
			setUserPerms(newPerms)
		}
	}
	
	const resetPermissionsInput = (user) => {
		setUserPerms(reducedPermissions)
	}

	const disableSubPermGroup = (parent) => {
		const newPerms = {
			...userPerms,
			[parent]: (!(userPerms[parent] - 1) + 1).toString(),
			...subPerms[parent]['children']
		}
		setUserPerms(newPerms)
	}

	const toggleSwitch = (perm) => () => {
		// If parent of sub perms, handle differently
		if (Object.keys(subPerms).includes(perm.toString())) disableSubPermGroup(perm)
		else handleChange(perm)((!(userPerms[perm] - 1) + 1).toString())
	}

	const { completePermissions } = props
	if (completePerms.current.length === 0 && completePermissions) {
		completePerms.current = (completePermissions || [])
			// remove dupes
			.reduce((acc, el) => {
				if (!acc.find(e => e.permission_id === el.permission_id)) {
					return acc.concat(el)
				}
				return acc
			}, [])
		fourValues.current = completePerms.current
			// remove dupes
			.reduce((acc, el) => {
				if (!acc.find(e => e.permission_id === el.permission_id)) {
					return acc.concat(el)
				}
				return acc
			}, [])
			.filter(p => p.parent_id === null ||
						p.relationship_type !== 'SINGLE DEPENDS ON')
			.filter(p => p.permission_type === '4 Value')

		dependencies.current = completePermissions
			.filter(p => p.relationship_type === 'EITHER DEPENDS ON (OR)' || 
				p.relationship_type === 'MULTIPLE DEPENDS ON (AND)')
	}

	const [updatePermissions, { loading, error }] = useMutation(UPDATE_PERMISSIONS, {
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, "Could not update permissions.")
		},
		onCompleted(response) {
			if (response.updateUserPermissions?.success === true) {
				openSnackbar(severity.SUCCESS, response.updateUserPermissions.message)
				onChange({permissions: response.updateUserPermissions.permissions})
			} else {
				openSnackbar(severity.ERROR, response.updateUserPermissions.message)
			}
		}
	})

	useEffect( () => {
		if(error?.graphQLErrors){
			const gqlErrors = error.graphQLErrors
			gqlErrors.forEach( (e) => {
				if(e.message === "autherror-admin"){
					openSnackbar(severity.ERROR, "Error: Editing user details requires admin login")
					navigate("/admin/login")
				}
			} )
		}
	}, [navigate, error, openSnackbar])

	// Set defaults if no permissions found
	if (Object.keys(userPerms).length === 0 && 
		userPerms.constructor === Object && 
		completePermissions &&
		completePermissions.length)  {

		let temp = {}

		for (let i = 0; i <= completePermissions.length; i += 1) {

			if (!completePermissions[i]) break

			if (completePermissions[i].permission_type === "Yes/No") 
				temp[completePermissions[i].permission_id] = "2"

			else if (completePermissions[i].permission_type === "4 Value") 
				temp[completePermissions[i].permission_id] = "3"
		}

		setUserPerms(temp)
	}

	const exampleStyle = {
		backgroundColor: 'white',
		borderRadius: '4px',
		marginLeft: '0.5em',
		color: 'var(--nav)',
		marginRight: '0.5em'
	}

	const findMode = props?.findMode || false
	
	return (
		<div>
			<Paper 				
				className="qv-margin"
				onContextMenu={(e) => null}
				id="user-permissions" 
				data-testid="card-legend"
			>
				<h1 className="card-title">Legend</h1>
				<div style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'left',
					alignItems: 'center',
					gap: '1em'
				}}>
					<div><UserToggle checked={false} disabled /> No</div>
					<div><UserToggle checked={true} disabled /> Yes</div>  
					<div style={{display: 'flex'}}><ClearIcon style={exampleStyle}/> Cannot See</div>
					<div style={{display: 'flex'}}><VisibilityIcon style={exampleStyle}/> View Only</div>	
					<div style={{display: 'flex'}}><AddIcon style={exampleStyle}/> Create &amp; Edit Own</div>	
					<div style={{display: 'flex'}}><CreateIcon style={exampleStyle}/> Create &amp; Edit All</div>
				</div>
			</Paper>
			<Paper
				className="qv-margin"
				onContextMenu={(e) => !state.mouseX ? handleClick(e) : null}
				id="user-permissions"
				data-testid="card-permissions"
			>
				<h1 className="card-title">
					<span>Permissions</span>
					{state.editable && (
						<>
							<NewButton
								onClick={()=>{
									setPermissionCopy(true)
								}}
							>
								Copy Permissions
							</NewButton>
							<div className="spacer"></div>
							<CancelButton
								variant="contained"
								size="small"
								onClick={() => {
									resetPermissionsInput(props.user)
									setSubPermsError({})
									handleClose()
								}}
							>
								Cancel
							</CancelButton>
							<SaveCardButton
								variant="contained"
								color="secondary"
								size="small"
								disabled={loading}
								onClick={() => {
									let PermissionsInput = Object.entries(userPerms)
										.map(([key, val]) => ({
											user_id: props.user.id,
											permission_id: key,
											permission_value_id: val
										}))
									
									if (validateSubPerms() === false) {
										// Do nothing, function handles all ^ :trolldance:
									} else {
										updatePermissions({ variables: { PermissionsInput } })
										setSubPermsError({})
										handleClose()
									}
								}}
							>
								Save
							</SaveCardButton>
						</>
					)}
				</h1>
				<div className={readOnly ? "userPage user-permissions-body" : "adminPage user-permissions-body"}>
					<div className="yes-no-row">
						<FormControl component="fieldset">
							<FormLabel style={{marginBottom: '1em'}}>Exporting</FormLabel>
							<Divider light />
							<FormGroup>
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
							
									control={!findMode ? <AdminToggleSmall
										checked={userPerms[1] === '1'}
										onChange={toggleSwitch(1)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Art Exporting To Excel"/>}
									label="Art Exporting To Excel"
									labelPlacement="end"
								/> 
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[2] === '1'}
										onChange={toggleSwitch(2)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Art Exporting To PDF"/> }
									label="Art Exporting To PDF"
									labelPlacement="end"
								/>
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall
										checked={userPerms[3] === '1'}
										onChange={toggleSwitch(3)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Contacts Exporting To Excel"/> }
									label="Contacts Exporting To Excel"
									labelPlacement="end"
								/> 
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[4] === '1'}
										onChange={toggleSwitch(4)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Contacts Exporting To PDF"/> }
									label="Contacts Exporting To PDF"
									labelPlacement="end"
								/> 
							</FormGroup>
						</FormControl>
                    
						<FormControl component="fieldset">
							<FormLabel style={{marginBottom: '1em'}}>Advanced</FormLabel>
							<Divider light />
							<FormGroup>
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[5] === '1'}
										onChange={toggleSwitch(5)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="iOS Application"/> }
									label="iOS Application"
									labelPlacement="end"
								/> 
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[42] === '1'}
										onChange={toggleSwitch(42)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Contact Information"/> }
									label="Contact Information"
									labelPlacement="end"
								/>
								<FormControlLabel
									style={{ paddingLeft: '1.2em' }}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[44] === '1'}
										onChange={toggleSwitch(44)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Source"/> }
									label="Source"
									labelPlacement="end"
								/>
								<FormControlLabel
									style={{ paddingLeft: '1.2em' }}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[51] === '1'}
										onChange={toggleSwitch(51)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Bulk Edit"/> }
									label="Bulk Edit"
									labelPlacement="end"
								/>
								<FormControlLabel
									style={{ paddingLeft: '1.2em' }}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[53] === '1'}
										onChange={toggleSwitch(53)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Access Public Deals"/> }
									label="Access Public Deals"
									labelPlacement="end"
								/>
								<FormControlLabel
									style={{ paddingLeft: '1.2em' }}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[54] === '1'}
										onChange={toggleSwitch(54)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Access Public Contacts"/> }
									label="Access Public Contacts"
									labelPlacement="end"
								/>
							</FormGroup>
						</FormControl>


						{/* !!! Adding new admin console permissions? Make sure to add them to the subPerms
						list in the `subPerms` object !!! */}
						<FormControl component="fieldset" error={subPermsError[7]}>
							<FormLabel style={{marginBottom: '1em'}}>Admin Console</FormLabel>
							<Divider light />
							<FormGroup>
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[7] === '1'}
										onChange={toggleSwitch(7)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Admin Console"/> }
									label="Access"
									labelPlacement="end"
								/> 
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[55] === '1'}
										onChange={toggleSwitch(55)}
										disabled={!state.editable || (userPerms[7] === '2')}
									/> : <FindModeInput type="admin-toggle-small" field="Users"/> }
									label="Users"
									labelPlacement="end"
								/> 
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[56] === '1'}
										onChange={toggleSwitch(56)}
										disabled={!state.editable || (userPerms[7] === '2')}
									/> : <FindModeInput type="admin-toggle-small" field="Import Tool"/> }
									label="Import Tool"
									labelPlacement="end"
								/>
							</FormGroup>
						</FormControl>
						{/* See comment above before adding more admin console permissions! */}


						<FormControl component="fieldset">
							<FormLabel style={{marginBottom: '1em'}}>File Access</FormLabel>
							<Divider light />
							<FormGroup>
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[35] === '1'}
										onChange={toggleSwitch(35)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="View Compliance Files"/> }
									label="View Compliance Files"
									labelPlacement="end"
								/> 
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[36] === '1'}
										onChange={toggleSwitch(36)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="View Financial Files"/> }
									label="View Financial Files"
									labelPlacement="end"
								/> 
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[37] === '1'}
										onChange={toggleSwitch(37)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="View Sensitive Images"/> }
									label="View Sensitive Images"
									labelPlacement="end"
								/> 
							</FormGroup>
						</FormControl>
						<FormControl component="fieldset">
							<FormLabel style={{marginBottom: '1em'}}>Private Object</FormLabel>
							<Divider light />
							<FormGroup>
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[6] === '1'}
										onChange={toggleSwitch(6)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Private Object Access"/> }
									label="Access"
									labelPlacement="end"
								/>
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[38] === '1'}
										onChange={toggleSwitch(38)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Private Object Admin"/> }
									label="Admin"
									labelPlacement="end"
								/>
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[57] === '1'}
										onChange={toggleSwitch(57)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Private Object Toggle"/> }
									label="Toggle"
									labelPlacement="end"
								/>
							</FormGroup>
						</FormControl>
						<FormControl component="fieldset">
							<FormLabel style={{marginBottom: '1em'}}>Deal Access</FormLabel>
							<Divider light />
							<FormGroup>
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[65] === '1'}
										onChange={toggleSwitch(65)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Lévy Gorvy Dayan"/> }
									label="Lévy Gorvy Dayan"
									labelPlacement="end"
								/>
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[52] === '1'}
										onChange={toggleSwitch(52)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="LGDR"/> }
									label="LGDR"
									labelPlacement="end"
								/>
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[47] === '1'}
										onChange={toggleSwitch(47)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Lévy Gorvy"/> }
									label="Lévy Gorvy"
									labelPlacement="end"
								/>
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[48] === '1'}
										onChange={toggleSwitch(48)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Salon 94"/> }
									label="Salon 94"
									labelPlacement="end"
								/>
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[49] === '1'}
										onChange={toggleSwitch(49)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Dayan Rohatyn"/> }
									label="Dayan Rohatyn"
									labelPlacement="end"
								/>
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[50] === '1'}
										onChange={toggleSwitch(50)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Luxembourg & Dayan"/> }
									label="Luxembourg & Dayan"
									labelPlacement="end"
								/>
							</FormGroup>
						</FormControl>
						<FormControl component="fieldset">
							<FormLabel style={{marginBottom: '1em'}}>Art Access</FormLabel>
							<Divider light />
							<FormGroup>
								<FormControlLabel
									style={{ paddingLeft: '1.2em' }}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[64] === '1'}
										onChange={toggleSwitch(64)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Lévy Gorvy Dayan"/> }
									label="Lévy Gorvy Dayan"
									labelPlacement="end"
								/>
								<FormControlLabel
									style={{ paddingLeft: '1.2em' }}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[59] === '1'}
										onChange={toggleSwitch(59)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="LGDR"/> }
									label="LGDR"
									labelPlacement="end"
								/>
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[60] === '1'}
										onChange={toggleSwitch(60)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Lévy Gorvy"/> }
									label="Lévy Gorvy"
									labelPlacement="end"
								/>
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[61] === '1'}
										onChange={toggleSwitch(61)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Salon 94"/> }
									label="Salon 94"
									labelPlacement="end"
								/>
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[62] === '1'}
										onChange={toggleSwitch(62)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Dayan Rohatyn"/> }
									label="Dayan Rohatyn"
									labelPlacement="end"
								/>
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[63] === '1'}
										onChange={toggleSwitch(63)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Luxembourg & Dayan"/> }
									label="Luxembourg & Dayan"
									labelPlacement="end"
								/>
							</FormGroup>
						</FormControl>
						<FormControl component="fieldset">
							<FormLabel style={{marginBottom: '1em'}}>Notifications</FormLabel>
							<Divider light />
							<FormGroup>
								<FormControlLabel
									style={{
										paddingLeft: '1.2em'
									}}
									control={!findMode ? <AdminToggleSmall 
										checked={userPerms[58] === '1'}
										onChange={toggleSwitch(58)}
										disabled={!state.editable}
									/> : <FindModeInput type="admin-toggle-small" field="Silent Transactions"/> }
									label="Silent Transactions"
									labelPlacement="end"
								/>
							</FormGroup>
						</FormControl>
					</div>
			
					<div className="four-value-row">
						{ 
							fourValues.current.map(p => {
								let max = 6
								let deps = dependencies.current.filter(p1 => 
									p1.permission_id === p.permission_id)
								if (deps.length) {
									if (p.relationship_type ==='MULTIPLE DEPENDS ON (AND)') {
										max = Math.min(...deps.map(d => userPerms[d.parent_id]))
									} else {
										max = Math.max(...deps.map(d => userPerms[d.parent_id]))
									}
								}

								return <NestedFormControls 
									key={p.permission_id}
									permission={p}
									userPerms={userPerms}
									perms={completePerms.current} 
									handleChange={handleChange}
									max={max}
									disabled={readOnly ? true : !state.editable}
									readOnly={readOnly}
									state={state}
								/>
							})
						}
					</div>
				</div>

				<Menu
					keepMounted
					open={state.mouseY !== null}
					onClose={handleClose}
					anchorReference="anchorPosition"
					anchorPosition={
						state.mouseY !== null && state.mouseX !== null
							? { top: state.mouseY, left: state.mouseX }
							: undefined
					}
				>
					<MenuItem onClick={() => handleClose("edit")}>Edit</MenuItem>
				</Menu>
			</Paper>
			<TransitionsModal
				open={ permissionCopy }
				close={ ()=>{	setPermissionCopy(false) } }
			>
				<PermissionCopy 
					close = {(perms)=>{
						setPermissionCopy(false)
						if (perms) {
							setUserPerms(perms)
						}
					}}
				> </PermissionCopy>
			</TransitionsModal>
		</div>
	)
}
