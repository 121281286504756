import React from 'react'
import { GET_USER_DATALOG } from './Queries'
import DataLog from '../DataLog/DataLog'

export default function AdminDataLog (props) {

	return (!props.findMode && props.id > 0) && (
		<DataLog 
			query={GET_USER_DATALOG} 
			id_field={"user_id"} 
			queryName={"getUserDataLog"}
			parentQueryName={"adminGetUser"}
			{...props}
		/>
	)
}
