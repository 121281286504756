/* eslint-disable eqeqeq */
import { useMutation } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { CircularProgress, FormControl, MenuItem, Select } from '@mui/material'
import React, { useCallback, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { uploadFiles } from '../common/upload'
import { getFileType } from '../Files/FileList'
import { CancelButton, ChooseImageButton, SubmitButton } from '../input/Buttons'
import Dropdown from '../input/Dropdown/Dropdown'
import Label from '../input/Label'
import TextBox from '../input/Text/TextBoxThin'
import QuickView from '../QuickView/QuickView'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { DispatchContext, LookupContext } from '../store'
import { CREATE_IMPORT } from './Queries'

export const ALLOWED_SIZE = 2097152
export const ALLOWED_SIZE_HUMAN_READABLE = "2 MB"

function CreateImportQuickView(props) {

	// Regular submit controls
	const lookup = useContext(LookupContext)
	const navigate = useNavigate()

	const types = lookup.data?.getImportTypes

	const [createImport, { loading, error }] = useMutation(CREATE_IMPORT)

	const [attempt, setAttempt] = React.useState(false)
	const [uploading, setUploading] = React.useState(false)
	const [uploadError, setUploadError] = React.useState(false)
	const [import_obj, setImport] = React.useState({
		name: '',
		type_id: 1,
	})

	/* =================================================================
	Snackbar and Auth
	================================================================= */

	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	useEffect(() => {
		if (error?.graphQLErrors) {
			const gqlErrors = error.graphQLErrors
			gqlErrors.forEach((e) => {
				if (e.message === "autherror-admin") {
					openSnackbar(severity.ERROR, "Error: import creation requires admin login")
					navigate("/admin/login")
				}
			})
		}
		else if (error) {
			console.log(error)
			openSnackbar(severity.ERROR, "Error creating import")
		}
	}, [navigate, error, openSnackbar])

	/* =================================================================
	File Management
	================================================================= */

	useEffect(() => {
		if (props.import_obj) setImport(props.import_obj)
	}, [props.import_obj])

	const [dragging, setDragging] = React.useState(false)
	const [enterTarget, setEnterTarget] = React.useState(null)
	const [hasFile, setHasFile] = React.useState(false)


	let handleFileDrop = (event) => {
		// Verify the filesize
		const files = event.target.files || event.dataTransfer.files
		if (files[0].size > ALLOWED_SIZE) {
			openSnackbar(severity.ERROR, `The file size must be less than ${ALLOWED_SIZE_HUMAN_READABLE}`)
		} else if (files[0].size <= 0) {
			openSnackbar(severity.ERROR, 'The provided file was empty.')
		} else {
			// upload the file
			setUploading(true)
			uploadFiles(props.client.query, event).then((files) => {
				// File uploaded, update the import state
				const file = files[0]
				const fileInfo = {
					filename: file.filename,
					filekey: file.key,
					filesize: file.filesize,
					filetype: file.filetype,
				}
				setImport({
					...import_obj,
					...fileInfo,
					file: file
				})
				setHasFile(true)
				setUploading(false)
			}).catch((error) => {
				// Error uploading the file, allow user to try again
				setUploadError(true)
				setUploading(false)
				openSnackbar(severity.ERROR, 'Error during upload.')
			})
		}
	}

	const onDragOver = (e) => {
		e.preventDefault()
		e.stopPropagation()
	}

	const onDragEnter = (e) => {
		e.preventDefault()
		e.stopPropagation()

		setEnterTarget(e.target)

		if (e.dataTransfer?.items && e.dataTransfer?.items?.length > 0) {
			setDragging(true)
		}
	}

	const onDragLeave = (e) => {
		e.preventDefault()
		e.stopPropagation()

		if (enterTarget == e.target)
			setDragging(false)
	}

	const onDrop = (e) => {
		e.preventDefault()
		e.stopPropagation()
		setDragging(false)

		if (hasFile) {
			openSnackbar(severity.ERROR, "You may only upload one data file.")
		}
		else {
			if (e.dataTransfer.files && e.dataTransfer.files.length == 1) {
				handleFileDrop(e)
				e.dataTransfer.clearData()
			}
			else if (e.dataTransfer.files && e.dataTransfer.files.length > 1) {
				openSnackbar(severity.ERROR, "You may only upload one data file.")
			}
			else {
				openSnackbar(severity.ERROR, "There was an error detecting the file.")
			}
		}
	}

	const disableUpload = false


	/* =================================================================
	Styling
	================================================================= */

	const darkTheme = props.onAdminConsole || props.onPrivateObject

	const getBackgroundColor = () => {
		if (darkTheme) return dragging ? '#3d3d3d' : 'inherit'

		return dragging ? '#e4e4e4' : 'inherit'
	}

	const dropStyle = {
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'center',
		width: '100%',
		height: '200px',
		marginTop: '1.5em',
		marginBottom: '1em',
		border: dragging ? '4px dashed #4465D1' : (attempt && !hasFile) ? '4px dashed red' : '4px dashed #707070',
		backgroundColor: getBackgroundColor(),
		borderRadius: 8,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	}

	/* =================================================================
	Return
	================================================================= */

	// Return object
	return (
		<QuickView className="quick-view-main create-import-quick-view">
			<div className="quick-view-inner">
				<form
					style={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						width: '90%',
					}}
					onSubmit={async (event) => {
						// Prevent re-route and set an attempt state
						event.preventDefault()
						setAttempt(true)

						// Check to make sure fields are valid
						if (
							!import_obj.type_id ||
							!import_obj.name ||
							!import_obj.filename
						) {
							openSnackbar(severity.WARNING, 'Please fill fields in red')
						}
						else if (
							!(
								!import_obj.type_id ||
								!import_obj.name ||
								!import_obj.filename
							)
						) {
							// setUploading(true)
							// Don't pass the file data to create the import
							const { file, ...importToSend } = import_obj
							// Create the import
							await createImport({
								variables: {
									ImportInput: importToSend,
								}
							}).then(async (response) => {
								// Auth or permissions error, most likely
								if (response.data.createImport == null) {
									openSnackbar(
										severity.ERROR,
										'There was an error while creating the import'
									)
								}
								else {
									// Import created
									const createdImport = response.data.createImport.import
									openSnackbar(
										severity.SUCCESS,
										`Import ${createdImport.id} has started!`
									)
									// Switch the quickView content to the import
									// TODO clear the previous import value
									props.setImport(createdImport)
									props.setCreateImport(false)
									setAttempt(false)
								}
							}).catch((error) => {
								// Handle error creating DB record
								console.error(error)
								openSnackbar(
									severity.ERROR,
									'There was an error while creating the import'
								)
							})
						}
					}}
				>
					<div style={{ width: '100%' }}>
						{/* Card Title */}
						<h2 className="card-title">Create New Import</h2>
					</div>

					{/* Import Type */}
					<FormControl fullWidth>
						<Label
							id="type-label"
							disableAnimation
							shrink
							error={attempt && !import_obj?.type_id}
						>
							Type*
						</Label>

						<Select
							IconComponent={ExpandMoreRoundedIcon}
							name="type"
							id="importQV-import-type"
							labelId="type-label"
							className="medium-selection"
							input={<Dropdown />}
							value={import_obj.type_id}
							error={attempt && !import_obj?.type_id}
							onChange={(e) => {
								setImport({
									...import_obj,
									type_id: e.target.value,
								})
							}}
						>
							{types.map((prefix) => (
								<MenuItem key={prefix.id} value={prefix.id}>
									{prefix.value}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					{/* Import Name */}
					<FormControl fullWidth>
						<Label
							disableAnimation
							shrink
							htmlFor="import-name"
							error={attempt && !import_obj?.name}
						>
							Name*
						</Label>

						<TextBox
							className="import-input"
							id="importQV-import-name"
							value={import_obj.name}
							error={attempt && !import_obj?.name}
							onChange={(e) => {
								setImport({
									...import_obj,
									name: e.target.value,
								})
							}}
							inputProps={{ maxLength: 255 }}
						/>
					</FormControl>

					{/* File Upload */}
					<FormControl>
						<Label
							disableAnimation
							shrink
							htmlFor="import-name"
							error={attempt && !import_obj?.filename}
						>
							Data File*
						</Label>
						<div
							onDragEnter={(e) => !disableUpload && onDragEnter(e)}
							onDragLeave={(e) => !disableUpload && onDragLeave(e)}
							onDragOver={(e) => !disableUpload && onDragOver(e)}
							onDrop={(e) => !disableUpload && onDrop(e)}
							style={dropStyle}
						>
							<div style={{ padding: '2em' }}>
								{hasFile ?
									<>{getFileType(import_obj.filetype)}</> :
									<img
										alt="Share Icon"
										src={`/images/icons/Grey/Upload.svg`}
										style={{
											height: '2em',
										}}
									/>
								}

								<hr
									style={{
										visibility: 'hidden',
										marginBlockStart: '0em',
										marginBlockEnd: '0em',
									}}
								/>
								<span
									style={
										props.atPrivateObject
											? { color: 'white', marginBottom: '1em' }
											: { marginBottom: '1em' }
									}
								>

									{!hasFile ? `Drag a File Here` : `${import_obj.filename}`}
								</span> <br />
								{!hasFile ? <span style={{ marginBottom: '1em', color: props.atPrivateObject ? 'white' : null }}>– or –</span> : null}
								<hr
									style={{
										visibility: 'hidden',
										marginBlockStart: '0em',
										marginBlockEnd: '0.5em',
									}}
								/>
								<input
									accept={'.csv'}
									style={{ display: 'none' }}
									id="icon-button-file"
									type="file"
									onChange={(e) => {
										if (hasFile) {
											openSnackbar(severity.ERROR, "You may only upload one data file.")
										}
										else {
											handleFileDrop(e)
										}
									}}
									multiple={false}
									disabled={disableUpload}
								/>
								<label htmlFor="icon-button-file">
									{hasFile ?
										<></>
										:
										<ChooseImageButton
											color="primary"
											variant="contained"
											aria-label="upload picture"
											component="span"
											disabled={disableUpload || hasFile}
										>
											Choose a File
										</ChooseImageButton>
									}
								</label>
							</div>
						</div>
					</FormControl>

					{/* Form buttons */}
					<div
						style={{
							width: '100%',
							paddingBottom: '1em',
							marginTop: '1em',
						}}
					>
						<CancelButton
							variant="contained"
							style={{ float: 'left' }}
							onClick={(e) => {
								props.setCreateImport(false)
								setAttempt(false)
							}}
						>
							Cancel
						</CancelButton>

						<SubmitButton
							variant="contained"
							style={{ float: 'right' }}
							type="submit"
							disabled={loading || uploading || uploadError}
						>
							Submit
						</SubmitButton>
					</div>

					<div
						style={{
							width: '100%',
							textAlign: 'center',
							alignItems: 'center',
							marginTop: '1em',
						}}
					>
						{(uploading || loading) ? <CircularProgress /> : <></>}
					</div>
				</form>
			</div>
		</QuickView>)
}

export default withApollo(CreateImportQuickView)
