/* eslint-disable eqeqeq */
import React, { useContext, useCallback, useEffect, useState, useMemo } from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
import '../../Contacts/Contact/ContactDetails.css'
import ObjectTags from '../../Tags/ObjectTags'
import { GET_DEAL_DETAILS, UPDATE_DEAL } from "../Queries.js"
import { DispatchContext, LookupContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import privateObjectTheme from '../../styles/muiThemes/privateObjectTheme'
import DealDetailsCard from './DealDetailsCard'
import DealAdvancedCard from './DealAdvancedCard'
import DealContactTable from './DealContactTable'
import ContactQuickView from '../../Contacts/ContactQuickView'
import ArtQuickView from '../../Art/ArtPiece/ArtQuickView'
import UserAccessQuickView from '../../QuickView/UserAccessQuickView'
import clsx from 'clsx'
import DealEntryList from '../../DealEntry/DealEntryList'
import { permissions, permissionValues } from '../../constants/permissions'
import DealContactSearch from './DealContactSearch'
import DealEntrySearch from '../../Search/DealEntrySearch'
import { useParams } from 'react-router-dom'

function DealDetails(props) {

	const lookup = useContext(LookupContext)

	let { loading, error } = props
	let deal = useMemo(() => props.state.getDeal || {}, [props.state.getDeal])
	let setDeal = (deal) => props.setState({ getDeal: deal })

	let [selectedRow, setSelectedRow] = useState(null)
	const [requeryDealEntry, setRequeryDealEntry] = useState({})

	const isPrivate = deal && deal.is_private

	const client = useApolloClient()
	const params = useParams()

	const requeryDeal = () => {
		client
			.query({ query: GET_DEAL_DETAILS, variables: { id: params.id } })
			.then(({ data }) => {
				setDeal(data.getDeal)
			})
			.catch((error) => {
				console.error(error)
				openSnackbar(severity.ERROR, "Could not requery deal.")
			})
	}

	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])


	const [updateDeal, { loading: loadingDeal, error: updateError }] = useMutation(UPDATE_DEAL, {
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, "Could not update deal.")
		},
		onCompleted: (response) => {
			if (response.updateDeal.success === true) {
				openSnackbar(
					severity[response.updateDeal.severity] || severity.SUCCESS,
					response.updateDeal.message
				)
				setDeal(response.updateDeal.deal)
			} else {
				openSnackbar(severity.ERROR, response.updateDeal.message)
			}
		}
	})

	const onSave = (variables) => {
		updateDeal({ variables: {
			DealInput: variables
		}})
	}

	useEffect(() => {
		if (deal && props.setAtPrivateObject)
			props.setAtPrivateObject(deal.is_private)
	}, [deal, props])

	React.useEffect(() => {
		if (selectedRow) {
			if (selectedRow.__typename == "DealContact") {
				const hidden = selectedRow.is_private && !selectedRow.first_name

				if (hidden) {
					props.setQVOverride(() => <UserAccessQuickView
						entity={selectedRow}
						onClose={() => setSelectedRow(null)}
					/>)
				} else {
					props.setQVOverride(() => <ContactQuickView 
						id={selectedRow.id}
						onClose={() => setSelectedRow(null)} 
					/>)
				}
			} else if (selectedRow.__typename == "DealEntry") {

				const hidden = selectedRow?.art?.is_private && !selectedRow?.art?.created_at

				if (hidden) {
					props.setQVOverride(() => <UserAccessQuickView
						entity={selectedRow?.art}
						onClose={() => setSelectedRow(null)}
					/>)
				} else {
					props.setQVOverride(() => <ArtQuickView
						id={selectedRow.art.id}
						status={selectedRow.art.status_id}
						onClose={() => setSelectedRow(null)}
					/>)
				}
			}
		} else {
			props.setQVOverride(null)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRow, selectedRow?.art?.status_id])

	// The Deal Entry Status Categories to be displayed on this page
	const dealCategories = lookup.data?.getDealEntryStatusCategories
		?.filter(el => el.deal_type_id == deal.deal_type?.id)

	// A mapping for making deal details dynamic per deal type
	const commonDealMapping = {
		sort: 'end_at',
		creation: true,
		otherPermissionPair: [permissions.ART, permissionValues.CREATE_AND_EDIT_OWN],
		creationButtonName: 'Add Art',
	}

	const dealMapping = {
		Offers: {
			sort: 'start_at',
			creation: true,
			otherPermissionPair: [permissions.ART, permissionValues.CREATE_AND_EDIT_OWN],
			creationButtonName: 'Add Art',
		},
		Reserves: {
			sort: 'reserve_position',
			creation: false,
			otherPermissionPair: null,
			creationButtonName: '',
		},
		'Purchases': {
			sort: 'end_at',
			creation: false,
			otherPermissionPair: null,
			creationButtonName: '',
		},
		'Consignment In': commonDealMapping,
		'Consignment Out': commonDealMapping,
		'Loan In': commonDealMapping,
		'Loan Out': commonDealMapping,
		'Gallery Purchase': commonDealMapping,
		'Known Source': {
			sort: 'end_at',
			creation: false,
			otherPermissionPair: [permissions.ART, permissionValues.CREATE_AND_EDIT_OWN],
			creationButtonName: '',
		}
	}

	const onUpdateArtStatus = (artStatusId) => {
		if (selectedRow) {
			setSelectedRow({
				...selectedRow,
				art: {
					...selectedRow.art,
					status_id: artStatusId
				}
			})
		}
	}

	const content = (
		<section className="main-page">
			<div className={clsx({
				'private-object': isPrivate,
				'tabbed-content': true
			})}>
				<div className="page-row">
					<DealDetailsCard
						loading={loading}
						error={error}
						deal={deal}
						disabled={loadingDeal}
						onSave={onSave}
						setDeal={setDeal}
						findMode={props.findMode}
					></DealDetailsCard>
					<DealAdvancedCard
						loading={loading}
						error={error}
						deal={deal}
						setDeal={setDeal}
						disabled={loadingDeal}
						onSave={onSave}
						isPrivate={isPrivate}
						findMode={props.findMode}
					></DealAdvancedCard>
				</div>


				{props.findMode ? <>
					<DealContactSearch />
					<DealEntrySearch />
				</> :
					<DealContactTable
						dealId={deal?.id}
						deal={deal}
						loading={props.loading}
						selectedRow={selectedRow}
						setSelectedRow={setSelectedRow}
						privateDeal={deal.is_private}
						requeryDeal={requeryDeal}
					/>
				}

				{!props.findMode && dealCategories?.map((category, index) => {

					const initialSort = dealMapping[category.value].sort
					const creation = dealMapping[category.value].creation
					const otherPermissionPair = dealMapping[category.value].otherPermissionPair
					const creationButtonName = dealMapping[category.value].creationButtonName

					return (
						<DealEntryList
							key={`${index}-${category?.value}`}
							page='deal'
							category={category}
							initialSort={initialSort}
							privateEntity={props.state?.getDeal?.is_private || false}
							selectedRow={selectedRow}
							setSelectedRow={setSelectedRow}
							setQVOverride={props.setQVOverride}
							deal={props.state?.getDeal}
							creation={creation}
							otherPermissionPair={otherPermissionPair}
							creationButtonName={creationButtonName}
							requeryDealEntry={requeryDealEntry}
							setRequeryDealEntry={setRequeryDealEntry}
							onUpdateArtStatus={onUpdateArtStatus}
						/>
					)
				})}

				<ObjectTags
					loading={loading}
					error={error || updateError}
					entity={deal}
					setEntity={setDeal}
					tagState={props.tagState}
					setTagState={props.setTagState}
					newTagModal={props.newTagModal}
					setNewTagModal={props.setNewTagModal}
					setQVOverride={ props.setQVOverride }
					entityType="Deal"
					isPrivate={props.entity?.is_private}
					tagPermission={permissions.DEALS_TAGS}
					findMode={props.findMode}
				></ObjectTags>

			</div>
		</section>
	)

	if (isPrivate) return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={privateObjectTheme}>
				{content}
			</ThemeProvider>
		</StyledEngineProvider>
	)

	return content
}

export default DealDetails
