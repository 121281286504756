import ShowcaseGridView from '../ShowcaseGridView'
import { useParams } from 'react-router-dom'
import { GET_LISTING, LISTING_ART_SEARCH } from '../queries'
import { artComparator, groupByArt } from '../../common/helpers'
import { useQuery } from '@apollo/client'

export default function ShowcaseExhibitionArt (props: any) {
	const params = useParams<any>()

	const id = params?.id

	const { data } = useQuery(GET_LISTING,  {
		skip: !id,
		variables: { id }
	})
	const filters =  [
		{
			field: "listing_number",
			isOptional: false,
			type: "eq",
			value: params?.id
		},
		{
			field: "is_deleted",
			isOptional: false,
			type: "eq",
			value: 'false'
		},
	]
	return <>
		{params?.id && <ShowcaseGridView
			filters={filters}
			query={LISTING_ART_SEARCH}
			showReserve={true}
			state={props.state}
			page="ShowcaseExhibition"
			showBackButton
			showSort={false}
			title={data?.getListing?.title}
			groupBy={groupByArt}
			comparator={artComparator}
			height="calc(100vh - 20em)"
			// groupBy={(art: any) => {
			// 	let decade = art.year?.substring(0, 3) || ''
			// 	return decade ? decade + '0' : ''
			// }}
			showcaseInformation={props.showcaseInformation}
			setShowcaseInformation={props.setShowcaseInformation}

			showcaseSelectMode={props.showcaseSelectMode}
			setShowcaseSelectMode={props.setShowcaseSelectMode}
			returnRoute='/showcase/exhibitions'
		/>}
	</>
}
