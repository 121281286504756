import React, { useState } from 'react'
import {
	ListItemIcon, ListItemText, Menu, MenuItem
} from '@mui/material'
import { buttons } from './BulkActionButtons'
import Add from '@mui/icons-material/Add'
import { nav } from '../styles/colors/Colors'
import { LoadingButton } from '@mui/lab'

const localStyles = {
	Button: {
		color: 'white',
		backgroundColor: nav
	},
	MenuPaper: {
		style: {
		  maxHeight: '95vh',
		  height: 'max-content'
		},
	},
	MenuItem: {
		width: '18.3em',
		// this overwrites a mui value to the
		// same one that exists lower - I'm not
		// sure if there's a better way.
		'& .MuiListItemIcon-root': {
			minWidth: '56px'
		}
	},
	ListItemIcon: {
		alignItems: 'center',
		justifyContent: 'flex-end',
		paddingRight: '1em',
	}
}

/**
 * @typedef {"art" | "artist" | "contact" | "deal" | "listing" | "tags" | "tasks"} EntityType
 */

const menuIcon = (menu, entity) => {
	let PreIcon = menu.creation && <Add fontSize="small" />
	if (menu.extraIcon?.[entity]) {
		const TheIcon = menu.extraIcon[entity]
		PreIcon = <TheIcon fontSize="small" />
	}
	return <>
		{ PreIcon }
		{ typeof menu.src === 'string' ?
			<img alt=""
				src={menu.src}
				style={{
					color: 'grey',
					width: '1.1em',
					margin: 1,
				}}
			/> : <menu.src />
		}
	</>
}
	

/**
 * This callback type is called `requestCallback` and is displayed as a global symbol.
 *
 * @callback requestCallback
 * @param {string} MenuName
 */

/**
 * @typedef {Object} BulkActionMenuProps
 * @property {requestCallback} handleAction
 * @property {EntityType} entity
 * @property {function} onOpen function to call when the menu is first opened
 * @property {boolean} hangingMode if menu should drop from anchor.
 * @property {boolean} disabled
 */

/**
 * BulkActionMenu
 * @param {BulkActionMenuProps} props
 * @returns
 */
export default function BulkActionMenu (props) {

	const [menuAnchor, setMenuAnchor] = useState(null)

	const anchorOrigin = {
		vertical: props.hangingMode ? 'bottom' : 'top',
		horizontal: 'left'
	}
	const transformOrigin = {
		vertical: props.hangingMode ? 'top' : 'bottom',
		horizontal: 'left'
	}

	const handleClick = event => {
		props?.onOpen?.()
		setMenuAnchor(event.currentTarget)
	}
	const closeMenu = () => setMenuAnchor(null)

	const menuItems = buttons
		.filter(b => b?.pages?.includes(props.entity) || !b.pages)

	return <>
		<LoadingButton
			className='bg-black'
			loading={props.loading}
			aria-controls="customized-menu"
			aria-haspopup="true"
			variant="contained"
			sx={localStyles.Button}
			onClick={handleClick}
			disabled={props.disabled || props.loading}
			size={props.smallButton ? 'small' : 'medium'}
		>
			Selected Actions
		</LoadingButton>
		<Menu
			id="listing-contact-action-menu"
			anchorEl={menuAnchor}
			anchorOrigin={anchorOrigin}
			transformOrigin={transformOrigin}
			keepMounted
			open={!!menuAnchor && !props.loading}
			onClose={closeMenu}
			PaperProps={localStyles.MenuPaper}
		>
			{ menuItems.map(menuItem =>
				<MenuItem
					key={`action-menuItem-${menuItem.name}`}
					sx={localStyles.MenuItem}
					disabled={props.disabled}
					onClick={() => {
						closeMenu()
						props.handleAction(menuItem.name)
					}}
				>
					<ListItemIcon sx={localStyles.ListItemIcon} >
						{ menuIcon(menuItem, props.entity) }
					</ListItemIcon>
					<ListItemText primary={menuItem.title} />
				</MenuItem>
			)}
		</Menu> 
	</>
}
