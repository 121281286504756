import { Button, Fade, IconButton } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import React from 'react'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import useNavigation from '../useNavigation'

const QuickSearchNav = (props) => {

	const navigate = useNavigate()
	const location = useLocation()
	const { searchNavLoading, searchNav, setSearchNav, pop: popNav } = useNavigation()

	const nextCursor = () => searchNav.current + 1
	const prevCursor = () => searchNav.current - 1

	const spacedItem = {
		paddingRight: '1em',
		paddingLeft: '1em',
	}

	const QSNavButton = withStyles(() => ({
		root: {
			color: 'lightgrey',
			margin: 2,
			'&:hover': {
				backgroundColor: 'var(--nav-highlight)',
			},
			'&:disabled': {
				color: 'grey',
			},
		},
	}))(IconButton)

	const getPathname = (id) => {
		const pathTemplate = (location.pathname)
			.replace(/\d+/, ':id')
		const path = generatePath(pathTemplate, { id })
		return path
	}
	
	const loading = searchNav.current == null || searchNav.total == null
	return (
		<>
			<Fade in={!loading && props.fadeIn} timeout={300}>
				<div style={{
					display: 'flex',
					justifyContent: 'center',
					width: '100%',
					alignItems: 'center',
					visibility: loading ? 'hidden' : 'visible'
				}}>
					<div style={spacedItem}>
						<QSNavButton
							size="small"
							aria-label="previous"
							disabled={!searchNav.prev || searchNavLoading}
							onClick={() => {
								const variables = {
									...location.state.variables,
									cursor: prevCursor(),
								}
								setSearchNav({
									...searchNav,
									current: prevCursor()
								})
								navigate(getPathname(searchNav.prev), { state: {
									...location.state,
									variables,
								}})
							}}
						>
							<div className='align-middle flex-row'>
								<KeyboardArrowLeftIcon />
								<div className='text-sm'>
									Prev
								</div>
							</div>
						</QSNavButton>
						<Button color="info" size="small" aria-label="Back to List" variant="contained" onClick={popNav}>
							Back to List
						</Button>
						<QSNavButton
							size="small"
							aria-label="next"
							disabled={!searchNav.next || searchNavLoading}
							onClick={() => {
								const variables = {
									...location.state.variables,
									cursor: nextCursor(),
								}
								setSearchNav({
									...searchNav,
									current: nextCursor()
								})
								navigate(getPathname(searchNav.next), { state: {
									...location.state,
									variables,
								}})
							}}
						>
							<div className='align-middle flex-row'>
								<div className='text-sm'>Next</div>
								<KeyboardArrowRightIcon />
							</div>
						</QSNavButton>
					</div>

					<div style={spacedItem}>
						{searchNav.current + 1} of {searchNav.total} Found
					</div>

					{props.totalResultCount ? 
						<div style={spacedItem}>
							{props.totalResultCount} Total Records
						</div> : null
					}
				</div>
			</Fade>
		</>
	)
}

export default QuickSearchNav
