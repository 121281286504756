import styled from "@emotion/styled"
import { Link } from "@mui/material"
import { DataGridPro } from "@mui/x-data-grid-pro"

// Custom Data Grid Styles
const DataGridProNoBorders = styled(DataGridPro)(({ theme: any }) => ({
	'& .MuiDataGrid-withBorder': {
		border: 'none',
	},

	'& .MuiDataGrid-row:hover': {
		backgroundColor: 'white',
	},

	'& .MuiDataGrid-footerContainer': {
		marginTop: '-5em'
	}
}))


export default function ShowcaseDataGrid (props: any) {
	return (
		<DataGridProNoBorders
			className='DataGridNoBorders'
			rows={props.rows.concat(props.loadedRows)}
			columns={[
				{
					field: 'last_name',
					headerName: '',
					flex: 1,
					sortable: false,
					renderCell: (params: any) => (
						<Link
							key={`route-link-${params.row.id}`}
							href={`/showcase/${props.route}/${params.row.id}`}
							underline='hover'
							variant='h5'
							sx={{
								width: 'max-content',
							}}
						>
							{`${props?.getRow ? props.getRow(params.row) : [params.row.last_name?.toUpperCase(), params.row.first_name?.toUpperCase()].filter((e) => !!e && e?.trim() !== "").join(', ')}`}
						</Link>
					),
				},
			]}
			density='compact'
			rowCount={props.totalItems || 0}

			// Disable all the things!
			disableChildrenFiltering
			disableChildrenSorting
			disableColumnFilter
			disableColumnMenu
			disableColumnPinning
			disableColumnReorder
			disableColumnResize
			disableColumnSelector
			disableDensitySelector
			disableExtendRowFullWidth
			disableMultipleColumnsFiltering
			disableMultipleColumnsSorting
			disableMultipleSelection
			disableSelectionOnClick
			showColumnRightBorder={false}
			showCellRightBorder={false}

			// Infinite Scrolling
			hideFooterPagination
			onRowsScrollEnd={props.handleOnRowsScrollEnd}
			loading={props.loading}
			scrollEndThreshold={10}
		/>
	)
}
