
// Quick Nav Queries
export const queries = [
	'searchDynamicContacts',
	'searchDynamicArt',
	'searchDynamicDeals',
	'searchDynamicArtists',
	'searchDynamicListings',
	'searchDynamicTags',
	'searchDynamicUsers',
	// non-search:
	'getContactListings',
	'getListingContacts',
	'getArtListings',
	'getListingArt',
	'getGlobalReserves',
	'getMyActiveDeals',
	'getGlobalConsignments',
	// tags
	'searchContacts',
	'searchArtists',
	'searchListings',
	'searchArt',
	'searchDeals'
]


// Search limits
export const DEFAULT_GLOBAL_SEARCH_LIMIT = 50
export const ITEMS_PER_HOME_PAGE_WIDGET = 4
export const GLOBAL_SEARCH_MAX_SELECTION = 5000
export const LISTING_CONTACT_PAGE_COUNT = 50
export const DEFAULT_SEARCH_LIMIT = 50
export const DEFAULT_SHARED_OBJECTS_LIMIT = 10

// Search object types
export const ART_SEARCH_OBJECT = 1
export const CONTACT_SEARCH_OBJECT = 2
export const ARTIST_SEARCH_OBJECT = 3
export const DEAL_SEARCH_OBJECT = 4
export const LISTING_SEARCH_OBJECT = 5
export const TAG_SEARCH_OBJECT = 6
export const TASK_SEARCH_OBJECT = 7
export const FILE_SEARCH_OBJECT = 8
export const USER_SEARCH_OBJECT = 9
export const IMPORT_SEARCH_OBJECT = 10

// File object types
export const ART_FILE_OBJECT = 1
export const CONTACT_FILE_OBJECT = 2
export const LISTING_FILE_OBJECT = 3
export const ARTIST_FILE_OBJECT = 4
export const DEAL_FILE_OBJECT = 5
export const ART_LOCATION_FILE_OBJECT = 6

export const quillToolbar = {
	toolbar: [
		['bold', 'italic', 'underline'],
		[{ color: ['red'] }],
		['clean'],
	],
}

export const quillNoNewlines = {
	...quillToolbar,
	keyboard: {
		bindings: {
			tab: false,
			handleEnter: {
				key: 13,
				handler: function() {
					// Do nothing
				}
			}
		}
	}
}

// Other
export const ES_SEARCH_MAX_RESULT_COUNT = 10000

// ids used for preset saved search comparisons
export const FAVORITES_DEFAULT_SEARCH = "fav"
export const RECENTLY_CREATED_DEFAULT_SEARCH = "rc"
export const VERIFIED_ARTWORK_DEFAULT_SEARCH = "va"
export const COMPLETED_IMPORT_DEFAULT_SEARCH = "ci"
export const INVALID_IMPORT_DEFAULT_SEARCH = "ii"

export const USA_COUNTRY_CODE = 236

export const contactStatusConstants = {
	CLIENT: 1,
	TBD: 5
}

export const contactTypeConstants = {
	UNASSIGNED: 27
}

export const contactGradeConstants = {
	NA: 7
}

export const TASK_STATUSES = {
	readyToStart: 1,
	inProgress: 2,
	completed: 3,
	review: 4
}

export const dealTypes = {
	offer: 1,
	gallery_purchase: 2,
	loan_in: 3,
	loan_out: 4,
	consignment_in: 5,
	consignment_out: 6,
	known_ownership: 7,
}

export const dealContactRoles = {
	primary: 1,
	advisor: 2,
	owner: 8,
}

export const artStatusesConstants = {
	returned: 5,
	sold: 1,
	soldTBS: 14,
	nfs: 3,
	nfsTBS: 31,
	available: 6
}


/*
Showcase 
*/

export const showcaseSortOptions = [
	{
		value: 'location-ASC',
		label: 'Location – A first'
	}, {
		value: 'location-DESC',
		label: 'Location – Z first'
	}, {
		value: 'title-ASC',
		label: 'Title – A first'
	}, {
		value: 'title-DESC',
		label: 'Title – Z first'
	}, {
		value: 'start_at-ASC',
		label: 'Date – Oldest first'
	}, {
		value: 'start_at-DESC',
		label: 'Date – Newest first'
	}, {
		value: 'artCount-ASC',
		label: 'Associated Works – Fewest first'
	}, {
		value: 'artCount-DESC',
		label: 'Associated Works – Most first'
	}

]
