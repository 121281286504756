import React from 'react'
import BusinessIcon from '@mui/icons-material/Business'
import PersonIcon from '@mui/icons-material/Person'
import { IconButton, Tooltip } from '@mui/material'
import ConfirmationDialog from '../navigation/ConfirmationDialog/ConfirmationDialog'
import { getFullName } from '../common/helpers'

const CompanyUpdate = (props) => {

	const {
		contact,
		updateContact,
		canEdit,
	} = props

	const [confirmationModal, setConfirmationModal] = React.useState(false)


	// This function fixes toggles back and forth from private companies and contacts
	const getNewCodeName = () => {
		
		// Toggle to company
		if (!contact?.is_company && contact.code_name === getFullName(contact)) {
			return null
		}

		// Toggle to contact
		else if (contact?.is_company && (contact.code_name === contact.company_name || contact.code_name === `Company ${contact.id}`)) {
			return null
		}

		return contact.code_name
	}

	return <>
		<Tooltip title={contact?.is_company ? "Company" : "Person"} arrow>
			<span>
				<IconButton
					disabled={!canEdit}
					onClick={() => setConfirmationModal(true)}
					style={{
						padding: 8
					}}
					size="large">
					{contact?.is_company ? <BusinessIcon /> : <PersonIcon style={{
						color: contact?.is_deceased ? '#A91D23' : null
					}}/>}
				</IconButton>
			</span>
		</Tooltip>

		<ConfirmationDialog
			open={confirmationModal}
			handleClose={() => setConfirmationModal(false)}
			title='Are you sure?'
			acceptText={'Save'}
			buttonColor={'var(--accept-changes)'}
			text={contact?.is_company ? 'Toggle company to person.' : 'Toggle person to company.'}
			onYes={() => {
				updateContact({ variables: { ContactInput: {
					id: contact.id,
					is_company: !contact?.is_company,
					code_name: getNewCodeName()
				}}})
			}}
		/>
	</>
}

export default CompanyUpdate
