import { useMutation } from '@apollo/client'
import { FormControl } from '@mui/material'
import { trim } from 'lodash'
import React, { useCallback, useContext } from 'react'
import { listingTypes } from '../../common/helpers'
import { CancelButton, SubmitButton } from '../../input/Buttons'
import Label from '../../input/Label'
import TextBox from '../../input/Text/TextBox'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import { CREATE_LISTING_AND_RELATIONS } from '../../Search/Queries'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { DispatchContext } from '../../store'
import { useNavigate } from "react-router-dom"

interface CuratedListModalProps {
	showcaseSelectMode: any
	setShowcaseSelectMode: (newShowcaseSelectMode: any) => void,
	open: boolean,
	setOpen: (arg0: boolean) => void
}


const CuratedListModal = (props: CuratedListModalProps) => {

	const { showcaseSelectMode, setShowcaseSelectMode, open, setOpen } = props

	const [createListingAndInsertRelation] = useMutation(CREATE_LISTING_AND_RELATIONS)
	const [title, setTitle] = React.useState('')
	const [attempt, setAttempt] = React.useState(false)

	const navigate = useNavigate()

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity: string, text: string) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const close = () => {
		setOpen(false)
		setTitle('')
		setAttempt(false)
	}


	return (
		<TransitionsModal open={open} close={close}>
			<form
				className={"modal-small-content"}
				onSubmit={e => {
					e.preventDefault()
					e.stopPropagation()

					setAttempt(true)

					if (attempt && !trim(title).length) {
						openSnackbar(severity.WARNING, "Please complete the fields in red.")
					}

					else {
						createListingAndInsertRelation({
							variables: {
								CreateListingInput: {
									type_id: listingTypes.CLIENT_DEV,
									title,
									start_at: new Date(),
									artIds: showcaseSelectMode.artIds,
									contactIds: []
								}
							}
						})
							.then((res) => {

								setAttempt(false)
								if (res.data.createListingAndInsertRelation?.success) {
									setAttempt(false)

									setShowcaseSelectMode({
										selectMode: false,
										artIds: []
									})

									navigate(`/showcase/curated_lists/${res.data.createListingAndInsertRelation.listing.id}/art`)

								} else {
									setAttempt(false)
									openSnackbar( severity.ERROR, 'Could not create listing.')
								}							
							})
							.catch((error) => {
								console.log(error)
								openSnackbar( severity.ERROR, 'There was an error creating the listing.')
							})
					}

					close()
				}}
			>
				<h1 className="card-title">
					<span>Create Curated List</span>
				</h1>

				<FormControl className="fullWidth" error={attempt && !trim(title).length}>
					<Label disableAnimation shrink>
						Title*
					</Label>
					<TextBox
						autoFocus
						value={title}
						onChange={(e) => {
							setTitle(e.target.value)
						}}
					/>		
				</FormControl>
				
				<CancelButton
					variant="contained"
					style={{ float: 'left', marginTop: '1em' }}
					onClick={close}
				>
				Cancel
				</CancelButton>

				<SubmitButton
					style={{ float: 'right', marginTop: '1em' }}
					type="submit"
					variant="contained"
				>
					Submit
				</SubmitButton>
			</form>

		</TransitionsModal>
	)
}

export default CuratedListModal
