/* eslint-disable eqeqeq */
import React, { useContext, useCallback, useEffect } from 'react'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { ChooseImageButton, SearchButton, CancelButton } from '../input/Buttons'
import { Select, MenuItem, FormControl, CircularProgress } from '@mui/material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

import Dropdown from '../input/Dropdown/Dropdown'
import Label from '../input/Label'
import TextBox from '../input/Text/TextBox'
import { useQuery, useMutation } from '@apollo/client'
import { GET_FILE_GROUPS, GET_FILE_CATEGORIES, UPDATE_CONTACT_FILE } from './Queries'
import { uploadFiles } from '../common/upload'
import { DispatchContext, AuthStateContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { UPDATE_CONTACT_FILES } from '../Contacts/Queries'
import { useLocation, useNavigate } from 'react-router-dom'
import { withApollo } from '@apollo/client/react/hoc'
import { getFileType } from './FileList'

const initialState = {
	file_group_id: '',
	file_category_id: '',
	description: '',
}

const UploadModal = (props) => {

	const [fileInput, setFileInput] = React.useState(initialState)
	const [attempt, setAttempt] = React.useState(false)
	const [saving, setSaving] = React.useState(false)
	const [loading, setLoading] = React.useState(false)
	const [dragging, setDragging] = React.useState(false)
	const [enterTarget, setEnterTarget] = React.useState(null)

	const authState = useContext(AuthStateContext)
	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		if(props.fileInput) setFileInput(props.fileInput)
		if(props.parentFile) setFileInput({
			file_group_id: props.parentFile.file_group_id,
			file_category_id: props.parentFile.file_category_id,
			description: props.parentFile.description
		})
	}, [props.fileInput, props.parentFile])


	const handleChange = (e) => {
		setFileInput({
			...fileInput,
			[e.target.name]: e.target.value,
		})
	}
 
	const [updateEntity] = useMutation(props.updateQuery || UPDATE_CONTACT_FILES, {
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, `Could not update ${props.object}`)
			setSaving(false)
		},
		onCompleted: (response) => {
			
			if (response[props.updateQuery.definitions[0].name.value].success) {
				const message = response[props.updateQuery.definitions[0].name.value]?.message
				openSnackbar(severity.SUCCESS, message || "Successfully uploaded.")

				// Navigate to correct page
				if (props.object === 'art') {


					const files = response[props.updateQuery.definitions[0].name.value].files.sort(
						(a, b) => a.id - b.id
					)

					const pathArray = location.pathname.split("/")
					const currentPath = pathArray[pathArray.length - 1]

					if (files[files.length - 1].fileCategory.fileGroup.value === "Operations") {

						if (currentPath === 'operations') {
							props.setTabbedPageFileRefetch(!props.tabbedPageFileRefetch)
							if (props.versionList && props.setFiles) {
								props.setFiles(response[props.updateQuery.definitions[0].name.value].files)
							}
						}
						else
							navigate('operations')
					}
					
					else {
						if (currentPath === 'files') {
							props.setTabbedPageFileRefetch(!props.tabbedPageFileRefetch)
							if (props.versionList && props.setFiles) {
								props.setFiles(response[props.updateQuery.definitions[0].name.value].files)
							}
						}
						else
							navigate('files')
					}
				}

				else {
					const pathArray = location.pathname.split("/")
					const currentPath = pathArray[pathArray.length - 1]

					if (currentPath === 'files') {
						props.setTabbedPageFileRefetch(!props.tabbedPageFileRefetch)
						if (props.versionList && props.setFiles) {
							props.setFiles(response[props.updateQuery.definitions[0].name.value].files)
						}
					}
					else
						navigate('files')
				}
			}
				

			else openSnackbar(severity.ERROR, "There was an error during upload.")

			setAttempt(false)
			setFileInput(initialState)
			setSaving(false)
			props.setOpen(false)
		}
	})

	const handleUpdateFileError = (error) => {
		console.error(error)
		openSnackbar(severity.ERROR, `Could not update file.`)
		setSaving(false)
	}

	const handleUpdateFileCompleted = (response) => {
		// Check for success regardless of what the "query name"
		// was we have to get through.
		if (Object.values(response)?.[0]?.success) {


			// Check if new file is on a another card, and we need to requery all cards

			if (!props.locationModal && props.files.find(e => e.id == Object.values(response)[0].file.id).fileGroup 
				!== Object.values(response)[0].file.fileCategory.fileGroup.value) {
				
				props.setTabbedPageFileRefetch(!props.tabbedPageFileRefetch)
			}

			// Special case for location modal
			if (props.locationModal) {
				props.setFiles(props.files.map(file => {

					return file.id == Object.values(response)[0].file.id ? {
						...file,
						...Object.values(response)[0].file
					} : file
				}))
			}

			// Update the current file
			else {
				props.setLocalFiles(props.files.map(file => {

					return file.id == Object.values(response)[0].file.id ? {
						...file,
						...Object.values(response)[0].file
					} : file
				}))
			}

			openSnackbar(severity.SUCCESS, "Successfully updated file.")
		} else {
			openSnackbar(severity.ERROR, "There was an error during the update.")
		}

		setAttempt(false)
		setFileInput(initialState)
		setSaving(false)
		props.setOpen(false)
	}

	const [updateFile] = useMutation(props.updateFileQuery || UPDATE_CONTACT_FILE, {
		onError: handleUpdateFileError,
		onCompleted: handleUpdateFileCompleted
	})

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const { data: groups } = useQuery(GET_FILE_GROUPS, {
		variables: { id: props.fileObjectId },
		skip: !props.fileObjectId || !props.open,
	})


	const { data: categories } = useQuery(GET_FILE_CATEGORIES, {
		variables: { id: fileInput.file_group_id },
		skip: !props.fileObjectId || !props.open || !fileInput?.file_group_id,
	})

	useEffect(() => {

		if (props.fileGroup && groups) {
			setFileInput({
				...fileInput,
				file_group_id: groups.getFileGroups?.find(e => {
					return e.value.includes(props.heading.split(' ')[0])
				})?.id || ''
			})
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.fileGroup, groups])

	useEffect(() => {
		if (groups?.getFileGroups.length === 1) {
			setFileInput({
				...fileInput,
				file_group_id: groups?.getFileGroups[0].id
			})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groups])


	let handleUpload = (event) =>
		uploadFiles(props.client.query, event)
			.then((files) => {

				const {
					delete: toDelete,
					description,
					file_category_id,
					parent_file_id,
					version,
					...file
				} = files[0]

				files[0] && setFileInput({
					...fileInput,
					...file,
				})
				setLoading(false)

				if (props.parentFile && props.parentFile.filetype != files[0].filetype) {
					openSnackbar(severity.WARNING, "The file-type does not match between versions.")
				}
			})
			.catch((error) => {
				console.error(error)
				openSnackbar(severity.ERROR, 'Error during upload.')
				setLoading(false)
			})

	const hasFile = fileInput.filename && fileInput.key

	const onDragOver = (e) => {
		e.preventDefault()
		e.stopPropagation()
	}

	const onDragEnter = (e) => {
		e.preventDefault()
		e.stopPropagation()

		setEnterTarget(e.target)

		if (e.dataTransfer?.items && e.dataTransfer?.items?.length > 0) {
			setDragging(true)
		}
	}

	const onDragLeave = (e) => {
		e.preventDefault()
		e.stopPropagation()
		if (enterTarget == e.target) 
			setDragging(false)
	}

	const onDrop = (e) => {
		e.preventDefault()
		e.stopPropagation()

		setDragging(false)
		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			handleUpload(e)
			e.dataTransfer.clearData()
		}
	}

	const darkTheme = props.onAdminConsole || props.onPrivateObject

	const getBackgroundColor = () => {
		if (darkTheme) return dragging ? '#3d3d3d' : 'inherit'

		return dragging ? '#e4e4e4' : 'inherit'
	}

	const dropStyle = {
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'center',
		width: '500px',
		height: '200px',
		marginTop: '1em',
		marginBottom: '1em',
		border: dragging ? '4px dashed #4465D1' : (attempt && !hasFile) ? '4px dashed red' : '4px dashed #707070',
		backgroundColor: getBackgroundColor(),
		borderRadius: 8,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	}

	const disableUpload = !!props.editMode

	return (
		<TransitionsModal open={props.open} close={() => {
			setAttempt(false)
			setFileInput(initialState)
			props.setOpen(false)
		}}>

			<div
				id="upload-modal"
				className={props.atPrivateObject ? 'dark-theme' : null}
				style={{
					width: '500px'
				}}
			>
				<h1 className="card-title">
					{props.parentFile ? <span>{`Update ${props.parentFile.filename} to version ${props.parentFile.version+1}`} </span> : <span>{props.editMode ? `Edit File` : `Upload File to ${props.titleOverride || props.object}` }</span>}
				</h1>

				<div
					onDragEnter={(e) => !disableUpload && onDragEnter(e)} 
					onDragLeave={(e) => !disableUpload && onDragLeave(e)} 
					onDragOver={(e) => !disableUpload && onDragOver(e)} 
					onDrop={(e) => !disableUpload && onDrop(e)}
					style={dropStyle}
				>
					<div style={{ padding: '2em' }}>
						{loading ? <CircularProgress size={32} style={{color: '#919191'}}/> : 
							<>
								{hasFile ? 
									<>{getFileType(fileInput.filetype)}</> :							
									<img
										alt="Share Icon"
										src={`/images/icons/Grey/Upload.svg`}
										style={{
											height: '2em',
										}}
									/> 
								}
							</>

						}

						<hr
							style={{
								visibility: 'hidden',
								marginBlockStart: '0em',
								marginBlockEnd: '0em',
							}}
						/>
						<span
							style={
								props.atPrivateObject
									? { color: 'white', marginBottom: '1em' }
									: { marginBottom: '1em'}
							}
						>

							{!hasFile ? `Drag a File Here` : `${fileInput.filename}`}
						</span> <br/>
						{!hasFile ? <span style={{ marginBottom: '1em', color: props.atPrivateObject ? 'white' : null }}>– or –</span> : null}
						<hr
							style={{
								visibility: 'hidden',
								marginBlockStart: '0em',
								marginBlockEnd: '0.5em',
							}}
						/>
						<input
							accept={props.parentFile && navigator.platform.toUpperCase().indexOf('MAC') === -1 ? props.parentFile.filetype : '*'}
							style={{ display: 'none' }}
							id="icon-button-file"
							type="file"
							onChange={(e) => {
								handleUpload(e)
								setLoading(true)
							}}
							multiple={false}
							disabled={disableUpload}
						/>
						<label htmlFor="icon-button-file">
							<ChooseImageButton
								color="primary"
								variant="contained"
								aria-label="upload picture"
								component="span"
								disabled={disableUpload}
							>
								Choose a{hasFile ? 'nother' : ''} File from your Computer
							</ChooseImageButton>
						</label>
					</div>
				</div>

				<FormControl className="fullWidth">
					<Label disableAnimation shrink id="file-group" error={attempt && !fileInput?.file_group_id}>
						File Group*
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						error={attempt && !fileInput?.file_group_id}
						name="file_group_id"
						
						labelId="file-group"
						input={<Dropdown />}
						disabled={!!props.addVersionMode || !!props.fileGroup || !!props.locationModal}
						onChange={(e) => {
							setFileInput({
								...fileInput,
								file_group_id: e.target.value,
								file_category_id: null,
							})
						}}
						value={groups ? fileInput?.file_group_id || '' : ''}
					>
						{groups?.getFileGroups?.sort((a, b) => a.id - b.id)?.map((group) => (
							<MenuItem key={group.id} value={group.id}>
								{group.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>


				<FormControl className="fullWidth">
					<Label disableAnimation shrink id="file-category" error={attempt && !fileInput?.file_category_id}>
						File Category*
					</Label>
					<Select
						error={attempt && !fileInput?.file_category_id}
						IconComponent={ExpandMoreRoundedIcon}
						disabled={!fileInput?.file_group_id || props.addVersionMode}
						name="file_category_id"
						
						labelId="file-category"
						input={<Dropdown />}
						onChange={handleChange}
						value={
							categories ? fileInput?.file_category_id || '' : ''
						}
					>
						{categories?.getFileCategories.map((category) => (
							<MenuItem key={category.id} value={category.id}>
								{category.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl className="fullWidth">
					<Label disableAnimation shrink id="file-description">
						Description
					</Label>
					<TextBox
						name="description"
						value={fileInput.description || ''}
						multiline
						rows="6"
						onChange={handleChange}
					/>
				</FormControl>

				<div style={{ paddingTop: '1em' }}>
					<SearchButton
						disabled={saving}
						variant="contained"
						size="medium"
						type="submit"
						style={{ float: 'right' }}
						onClick={() => {
							if (saving) return
							setSaving(true)
							setAttempt(true)

							if (!hasFile || !fileInput?.file_group_id || !fileInput?.file_category_id) {
								openSnackbar(severity.WARNING, "Please complete required fields.")
								setSaving(false)

							} else if (!(!hasFile || !fileInput?.file_group_id || !fileInput?.file_category_id )) {

								if (props.locationModal) {

									if (props.editMode) {
										
										// Edit Description
										const variables = {
											id: props.fileId,
											description: fileInput.description,
											file_category_id: fileInput.file_category_id,
										}

										updateFile({variables: {
											FileInput: variables
										}})

									} else {
										props.setLocationModal({
											...props.locationModal,
											input: {
												...props.locationModal.input,
												files: (props.locationModal.input.files || []).concat([fileInput].map(file => {
													const {
														file_group_id,
														...rest
													} = file
		
													return {
														...rest,
														user:  authState?.user,
														created_at: new Date(),
														fileCategory:  categories.getFileCategories.find(e => e.id == fileInput.file_category_id).value
													}
												}))
											}
										})

										setAttempt(false)
										setFileInput(initialState)
										setSaving(false)
										props.setOpen(false)
									}

								} else if (!props.editMode) {

									// New File
									const variables = {
										id: props.objectId,
										files: [fileInput].map(file => {
											const {
												file_group_id,
												...rest
											} = file
	
											return {
												...rest,
												parent_file_id: props.addVersionMode ? props.parentFile.parent_file_id || props.parentFile.id : null,
												version: props.addVersionMode ? props.parentFile.version+1 : 1
											}
										}),
									}
										
									updateEntity({ variables: { 
										UpdateFileInput: variables
									}})
								} else {

									// Edit Description
									const variables = {
										id: props.fileId,
										description: fileInput.description,
										file_category_id: fileInput.file_category_id,
									}

									updateFile({variables: {
										FileInput: variables
									}})
								}
							}
						}}
					>
						Save
					</SearchButton>
					<CancelButton
						variant="contained"
						size="medium"
						type="reset"
						style={{ float: 'right', marginRight: '1em' }}
						onClick={() => {
							setAttempt(false)
							setFileInput(initialState)
							props.setOpen(false)
						}}
					>
						Cancel
					</CancelButton>
				</div>
			</div>
		</TransitionsModal>
	)
}

export default withApollo(UploadModal)
