/* eslint-disable eqeqeq */
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { MenuItem, Select, Tooltip } from "@mui/material"
import Source from "../Art/ArtPiece/Source"
import SourceLabel from "../Art/ArtPiece/SourceLabel"
import { dealTypes } from "../constants/values"
import { deal_entry_status_categories } from "../DealEntry/constants"
import { filterStatuses, OWNER_CONTACT_ROLE, PRIMARY_CONTACT_ROLE } from "../DealEntry/DealEntryList"
import { DealEntryStyle, DealEntryWhiteStyle } from "../input/DealEntry/TextEntry"
import Dropdown from "../input/Dropdown/Dropdown"
import ContactThumbnail from "../Thumbnail/ContactThumbnail"
import { GalleryContactList } from "./components/GalleryContactList"
import { formatDate, formatter, getContactName } from "./helpers"

export const currentSources = (params) => {
	const { row } = params
	
	const deal = row?.deal

	if (deal.type_id == dealTypes.known_ownership) {

		const owners = row?.deal?.contacts
			?.filter(e => e.contactRole?.id == OWNER_CONTACT_ROLE)
			?.map(e => ({
				...e,
				deal_entry_status_category: 'Known Source'
			}))

		if (!owners?.length) return <span>-</span>

		return (
			<Source
				loading={false} 
				canViewOwnerContact={true} 
				sources={owners} 
				compact={params?.api?.state?.density?.value === 'compact'}
				ownershipHistoryCard={true}
				maxOverride={2}
				avatarGroupStyles={{
					marginLeft: '0.5em',
					marginRight: '-0.5em',
				}}
			 />
		)
	}

	else {
		const primaryContact = row?.deal?.contacts
			?.find((e) => e.contactRole?.id == PRIMARY_CONTACT_ROLE) || 
			row?.deal?.contacts
				?.find((e) => e.contactRole?.id == OWNER_CONTACT_ROLE) || {}

		if (params?.api?.state?.density?.value === 'compact') {
			return <div
				style={{
					height: '100%',
					width: '100%',
					padding: '1em',
					color: primaryContact.is_private ? 'white' : 'inherit',
					borderRadius: '8px',
					display: 'flex',
					alignItems: 'center',
					paddingLeft: '1em'
				}}
				className={primaryContact.is_private ? 'private' : null}
			>
				{ getContactName(primaryContact) }
			</div>
		}

		const inner = primaryContact ? (
			<div
				style={{
					borderRadius: 8,
					backgroundColor: primaryContact?.is_private && !row.is_private ? '#2E3134' : null,
				}}
			>
				<ContactThumbnail contact={primaryContact}
					darkText={!primaryContact.is_private}
					style={{
						padding: primaryContact.is_private ? '1em' : null
					}}
				/>
			</div>

		) : <span>-</span>

		return (
			<div style={{ minWidth: '17em'}}>
				{inner}
			</div>
		)
	}	
}

export const sourceType = (params) => {

	const { row } = params

	const sources = row?.deal?.contacts?.map((contact => {

		return ({
			...contact,
			deal_entry_status: row?.deal_entry_status?.value,
			deal_entry_status_category: row?.deal_entry_status?.deal_entry_status_category?.value
		})
	}))
	
	
	return sources?.length ? <SourceLabel
		sources={sources} 
	/> : <span>-</span>
}

export const dealStatus = (params, open, handleTTClose, handleTTOpen, statusTypes, setStatusModal, statusModal, setCurrentRow, privateEntity) => {
	
	const {row} = params
	

	return (
		<div className={row?.is_private ? 'private-object' : null}>
			<Tooltip
				title={row.status_note || ''}
				arrow
				open={open[row.id] || false}
				disableFocusListener
				disableTouchListener
				disableHoverListener
				onClose={() => handleTTClose(row)}
				onOpen={() => handleTTOpen(row)}
				placement="bottom"
				enterDelay={1000}
			>
				<Select
					IconComponent={ExpandMoreRoundedIcon}
					id="listing-contacts-status"

					input={<Dropdown />}
					className="hideborder"
					value={(statusTypes?.length && row.deal_entry_status?.id) || ''}
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
					}}
					onOpen={() => handleTTClose(row)}
					onMouseEnter={() => handleTTOpen(row)}
					onMouseLeave={() => handleTTClose(row)}
					onChange={(event) => {
						// The current row should be set on status change
						setCurrentRow(row)
						let newStatusModalValue = {}
						if (event.target.value == 7) {
							newStatusModalValue = {
								sale_amount: row.offer_amount,
								sale_currency: row.offer_currency?.id || null,
							}
						}
						setStatusModal({
							...statusModal,
							open: true,
							id: row.id,
							statusId: event.target.value,
							...newStatusModalValue
						})
					}}
					style={{
						color: privateEntity || row?.is_private ? 'white' : null,
					}}
					data-testid="deal-entry-status-select"
				>
					{statusTypes &&
						filterStatuses(statusTypes, row.deal_entry_status).map((type) => (
							<MenuItem
								style={{
									color: privateEntity ? 'white' : null,
								}}
								key={type.id}
								value={type.id}
							>
								{type.id == 4 && row.deal_entry_status?.id == 4 && !row.has_reserve ? 'In Queue - ' + row.reserve_position : type.value}
							</MenuItem>
						))}
				</Select>
			</Tooltip>
		</div>
	)
}

export const galleryContacts = (params) => {
	
	const { row } = params



	const inner = <GalleryContactList
		salespersons={row?.deal?.salesperson}
		findMode={false}
		max={2}
	/>

	return <>{inner}</>

}

export const price = (params, setPriceModal, priceModal, updateRow, setCurrentChange) => {

	const formatPrice = (currency, amt) => (amt ? formatter(currency || 'USD').format(amt) : null)

	
	const { row } = params

	const useSale = ([
		deal_entry_status_categories.purchases,
		deal_entry_status_categories.gallery_purchase,
		deal_entry_status_categories.known_ownership
	].findIndex((e) => e == params.row?.deal_entry_status?.deal_entry_status_category?.id)) !== -1

	const currencyField = useSale ? 'sale_currency' : 'offer_currency'
	const amountField = useSale ? 'sale_amount' : 'offer_amount'
	const name =  useSale ? 'Sale Price' : 'Offer Price'

	const hiddenDeal = (row.deal?.is_private || row.deal?.is_gallery_private) && !row.deal?.created_at

	const onClick = () => {
		setPriceModal({
			...priceModal,
			open: true,
			rowId: row.id,
			currency: row[currencyField]?.id,
			amount: row[amountField],
			name,
			save: (values) => {
				updateRow({
					...row,
					[amountField]: values.amount,
					[currencyField]: values.currency,
				})
			},
		})
	}

	if (hiddenDeal)
		return (
			<span>-</span>
		)

	return (
		<div onClick={onClick}>
			{row?.is_private ? (
				<DealEntryWhiteStyle
					onMouseEnter={() => setCurrentChange(true)}
					onMouseLeave={() => setCurrentChange(false)}
					value={formatPrice(row[currencyField]?.label, row[amountField]) || '-'}
				/>
			) : (
				<DealEntryStyle
					onMouseEnter={() => setCurrentChange(true)}
					onMouseLeave={() => setCurrentChange(false)}
					value={formatPrice(row[currencyField]?.label, row[amountField]) || '-'}
				/>
			)}
		</div>
	)
}

export const dateCell = (params) => {

	const { row } = params
	const { start_at, end_at, deal_entry_status, deal } = row
	const { start_at: dealStartAt, end_at: dealEndAt } = deal
	const { deal_entry_status_category } = deal_entry_status
	
	let inner


	if (deal_entry_status_category.id == deal_entry_status_categories.purchases)
		inner = (
			<>
				Sale Date: {formatDate(end_at, 'MMM do, yyyy')}
			</>
		)

	else if (deal_entry_status_category.id == deal_entry_status_categories.consignment_in || deal_entry_status_category.id == deal_entry_status_categories.consignment_out)
		inner = (
			<>
				Consigned: {formatDate(start_at ?? dealStartAt, 'MMM do, yyyy')}{end_at || dealEndAt ? ` - ${formatDate(end_at ?? dealEndAt, 'MMM do, yyyy')}` : null} 
			</>
		)

	else if (deal_entry_status_category.id == deal_entry_status_categories.gallery_purchase)
		inner = (
			<>
				Last Known Date: {formatDate(start_at, 'MMM do, yyyy')}{end_at ? `, Sale Date: ${formatDate(end_at, 'MMM do, yyyy')}` : null} 
			</>
		)

	else if (deal_entry_status_category.id == deal_entry_status_categories.loan_in || deal_entry_status_category.id == deal_entry_status_categories.loan_out)
		inner = (
			<>
				Loaned: {formatDate(start_at ?? dealStartAt, 'MMM do, yyyy')}{end_at || dealEndAt ? ` - ${formatDate(end_at ?? dealEndAt, 'MMM do, yyyy')}` : null} 
			</>
		)

	else if (deal_entry_status_category.id == deal_entry_status_categories.known_ownership)
		inner = (
			<>
				Last Known Date: {formatDate(start_at, 'MMM do, yyyy')}
			</>
		)
		
	else inner = <>-</>

	return <div>{inner}</div>
}
