import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { darken } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'

/**
 * @typedef RevvedProps
 * @property {object=} style
 * @property {object=} textStyle
 * @property {number=} current
 * @property {number=} target
 * @property {number=} size
 * @property {number=} thickness
 * @property {string=} topColor
 * @property {string=} bottomColor
 * @property {number=} darken
 */

/**
 * A revved circular progress bar.
 * @param {RevvedProps} props
 * @returns {JSX.Element}
 */
const RevvedCircularProgress = (props) => {
	const percent = (props.current / props.target) * 100
	const goalMet = props.current / props.target > 1
	const base = Math.floor((percent / 100) % 10) * 100

	const [rev, setRev] = React.useState(0)
	const [perc, setPerc] = React.useState(goalMet ? base : 0)

	React.useEffect(() => {
		const delay = 0
		const timer = setInterval(() => {
			if (perc < percent) {
				setRev((rev) => rev + 1)
				setPerc((perc) => perc + 1)
			} else if (perc === percent) clearInterval(timer)
		}, delay)

		return () => {
			clearInterval(timer)
		}
	}, [perc, rev, percent])

	const common = {
		position: 'absolute',
		left: 0,
	}

	const useStyles = makeStyles(() => {
		return {
			bottom: {
				...common,
				zIndex: 1,
				color: goalMet ? props.topColor : props.bottomColor,
			},
			top: {
				...common,
				zIndex: 2,
				color: goalMet ? darken(props.topColor, props.darken || 0.25) : props.topColor,
			},
			circle: {
				strokeLinecap: 'round',
			},
		}
	})

	const classes = useStyles()

	return (
		<div
			style={{
				...props.style,
				height: props.size,
				width: props.size,
			}}
		>
			<CircularProgress
				variant="determinate"
				value={rev}
				className={classes.top}
				classes={{ circle: classes.circle }}
				size={props.size}
				thickness={props.thickness}
			/>
			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position="absolute"
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				<Typography
					style={{
						...props.textStyle,
					}}
					variant="caption"
					component="div"
					color="textSecondary"
				>{!isNaN(perc) ? `${perc}%` : '-'}</Typography>
			</Box>
			<CircularProgress
				variant="determinate"
				value={100}
				className={classes.bottom}
				classes={{ circle: classes.circle }}
				size={props.size}
				thickness={props.thickness}
			/>
		</div>
	)
}

export default RevvedCircularProgress
