import React from 'react'
import TransitionsModal from './../navigation/TransitionsModal/TransitionsModal'
import { useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { formatDate, readableBytes } from '../common/helpers'
import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'
import { CancelButton } from './../input/Buttons'

export default function InfoCard(props) {

	const [object, setObject] = React.useState()

	useEffect(() => {
		setObject(props.object)
	}, [props.object])

	const dateKeys = [
		"modified_at",
		"created_at",
		"approval_at",
		"due_at",
		"start_at",
		"reserve_created_at",
		"reserve_begin_at",
		"reserve_end_at",
		"end_at",
		'verified_at'
	]
	const userKeys = {
		"verified_by": 'verified_user',
		"created_by": 'created_user',
		"modified_by": 'modified_user'
	}
	// verified_user
	
	// and created_by and modified_by shouldn’t show the user ID, rather the 
	// user’s first + last name


	const close = () => {
		// original tasks
		props.setInfoModal && props.setInfoModal({ open: false })
		// new
		props.close && props.close()
	}
	const display = (open) => { 

		if (props.open && object) {
			return Object.keys(object).map((key, i) => {

				if (props.ignoredAttributes && props.ignoredAttributes.includes(key)) return null
				if (key !== "__typename") {

					let label = key.toString()

					// Label overrides
					switch(label) {
					case 'has_coa':
						label = 'Has Certificate of Authenticity'
						break
					default:
						label = startCase(camelCase(key)).replace("Id", "ID")
						break
					}

					if (object[key] === null || object[key] === '') {
						return (
							<TableRow key={i}>
								<TableCell>{label}</TableCell>
								<TableCell>-</TableCell>
							</TableRow>
						)
					} else if (key.includes("date") || dateKeys.includes(key) ) {
						return (
							<TableRow key={i}>
								<TableCell>{label}</TableCell>
								<TableCell>
									{formatDate(object[key], "iiii, MMMM do, yyyy 'at' h':'mma")}
								</TableCell>
							</TableRow>
						)
					} else if (Object.keys(userKeys).includes(key)) {
						let user = object[userKeys[key]]
						return <TableRow key={i}>
							<TableCell>{label}</TableCell>
							<TableCell>
								{ user?.first_name } { user?.last_name }
							</TableCell>
						</TableRow>
					}
					else if (key === "filesize") {
						return (
							<TableRow key={i}>
								<TableCell>{label}</TableCell>
								<TableCell>
									{readableBytes(object[key])}
								</TableCell>
							</TableRow>
						)
					}
					else return props.allAttributes && typeof object[key] !== "object" ? (
						<TableRow key={i}>
							<TableCell>{label}</TableCell>
							<TableCell style={{ whiteSpace: "break-spaces"}}>
								{ object[key].toString() }
							</TableCell>
						</TableRow>
					) : null
					
				} else return null
			})		
		}
	}
	return (
		<TransitionsModal
			open={props.open}
			close={close}
			scroll={true}
		>
			<CancelButton
				variant="contained"
				onClick={close}
				style={{ display: 'flex', marginLeft: 'auto', padding: '.2em' }}
			>Close
			</CancelButton>

			<Table style={{maxWidth: '40em'}}>
				<TableHead>
					<TableRow>
						<TableCell>Key</TableCell>
						<TableCell>Value</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{display()}
				</TableBody>
			</Table>

		</TransitionsModal>
	)
}
