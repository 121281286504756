import { gql } from '@apollo/client'

export const TOGGLE_FAVORITE = gql`
	mutation toggleFavorite($ToggleFavoriteInput: ToggleFavoriteInput!) {
		toggleFavorite(input: $ToggleFavoriteInput) {
			code
			success
			message
		}
	}
`

export const TOGGLE_FLAG = gql`
	mutation toggleFlagged($ToggleFlagInput: ToggleFlagInput!) {
		toggleFlagged(input: $ToggleFlagInput) {
			code
			success
			message
		}
	}
`

export const GET_UPLOAD_LINKS = gql`
	query uploadLink($headers: [JSON]) {
		getUploadLink(input: $headers) {
			url
			key
		}
	}
`
export const GET_MANUAL_UPLOAD_LINK = gql`
	query uploadLink {
		getManualUploadLink {
			url
			key
		}
	}
`
export const GET_MANUAL_LINK = gql`
	query manualLink {
		getManualLink {
			imgUrl
		}
	}
`
export const TOGGLE_FAVORITE_ENTITIES = gql`
	mutation toggleFavoriteEntities($art: [Int], $artists: [Int], $contacts: [Int], $listings: [Int], $deals: [Int], $tasks: [Int], $setFavorite: Boolean) {
		toggleFavoriteEntities(input: { art: $art, artists: $artists, contacts: $contacts, listings: $listings, deals: $deals, tasks: $tasks, setFavorite: $setFavorite }) {
			message
			code
			success
		}
	}
`

export const CREATE_MULTIPLE_TAGS = gql`
	mutation createMultipleTags($art: [Int], $artists: [Int], $contacts: [Int], $listings: [Int], $deals: [Int], $tagIds: [Int]) {
		createMultipleTags(input: { art: $art, artists: $artists, contacts: $contacts, listings: $listings, deals: $deals, tagIds: $tagIds }) {
			message
			code
			success
		}
	}
`
