/* eslint-disable eqeqeq */
import { FormControl, MenuItem, Select, CircularProgress } from "@mui/material"
import React, { useCallback, useContext } from "react"
import Dropdown from "../input/Dropdown/Dropdown"
import Label from "../input/Label"
import TransitionsModal from "../navigation/TransitionsModal/TransitionsModal"
import { typeStyle } from "../styles/makesStyles"
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded"
import { DispatchContext, LookupContext } from "../store"
import NumberFormat from "react-number-format"
import TextBox from "../input/Text/TextBox"
import { CancelButton, SubmitButton } from "../input/Buttons"
import { withApollo } from "@apollo/client/react/hoc"
import { GET_TOTAL_ART_FINANCIALS } from "./Queries"
import HelperText from "../common/HelperText"
import { severity } from "../Snackbar/CustomizedSnackbar"

const TotalPriceModal = (props) => {
	const { open, close, rows } = props

	const initialInputState = {
		art_financial_type_id: 3,
		currency_id: 1
	}

	const [input, setInput] = React.useState(initialInputState)
	const [result, setResult] = React.useState(null)
	const [loading, setLoading] = React.useState(false)
	const [emptyFinancialCount, setEmptyFinancialCount] = React.useState(null)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const handleClose = () => {
		setLoading(false)
		setInput(initialInputState)
		setResult(null)
		setEmptyFinancialCount(null)
		close()
	}

	const handleSubmit = () => {

		const {
			art_financial_type_id,
			currency_id,
		} = input

		setLoading(true)

		props.client
			.query({
				query: GET_TOTAL_ART_FINANCIALS,
				variables: { 
					art_financial_type_id,
					currency_id,
					ids: rows?.map((e) => e.id),
				}
			})
			.then( (result) => {
				setLoading(false)
				setResult(
					result.data.getTotalArtFinancials.costResult || 
					result.data.getTotalArtFinancials.netResult || 
					result.data.getTotalArtFinancials.insuredResult || 
					result.data.getTotalArtFinancials.retailResult
				)

				setEmptyFinancialCount(result.data.getTotalArtFinancials.emptyFinancials)

				if (result?.errors?.[0]?.message)
					openSnackbar(severity.ERROR, result.errors[0].message)

			})
	}

	const lookup = useContext(LookupContext)

	const types = lookup.data?.getArtFinancialTypes
	const currencies = lookup.data?.getCurrencies

	return (
		<TransitionsModal
			className='total-price-modal'
			open={open}
			close={() => handleClose()}
		>
			<div style={{ width: "35em" }}>
				<h2
					className='card-title'
					style={{
						paddingBottom: "0.5em",
						margin: 0,
					}}
				>
					Totals
				</h2>

				<FormControl style={{ width: "9em" }}>
					<Label
						id='type-label'
						style={typeStyle}
						disableAnimation
						shrink
					>
						Art Financial Type
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						name='type'
						className='padded-select'
						
						labelId='type-label'
						input={<Dropdown />}
						value={input.art_financial_type_id}
						onChange={(event) => {
							setInput({
								...input,
								art_financial_type_id: event.target.value,
							})
						}}
					>
						{types?.map((type) => (
							<MenuItem key={type.id} value={type.id}>
								{type.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl
					className='padded-select'
					style={{
						marginLeft: "1em",
						width: "25em",
					}}
				>
					<Label id='currency-label' disableAnimation shrink>
						Currency
					</Label>

					<Select
						IconComponent={ExpandMoreRoundedIcon}
						name='currency'
						
						labelId='currency-label'
						input={<Dropdown />}
						value={input.currency_id}
						renderValue={(value) => {
							const currency = currencies?.find(
								(e) => e.id == value
							)
							return `${currency.symbol} - ${currency.name}`
						}}
						onChange={(e) => {
							setInput({
								...input,
								currency_id: e.target.value,
							})
							setResult(null)
						}}
					>
						{currencies?.map((currency) => (
							<MenuItem key={currency.id} value={currency.id}>
								{`${currency.symbol} - ${currency.name}`}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl fullWidth>
					<Label id='result-label' disableAnimation shrink>
						Result
					</Label>

					<NumberFormat
						className='force-border'
						customInput={TextBox}
						allowNegative={false}
						decimalScale={2}
						allowLeadingZeros={false}
						value={result}
						thousandSeparator={true}
						readOnly={true}
						placeholder={"0"}
						prefix={
							currencies?.find((el) => el.id == input.currency_id)
								? ` ${
										currencies?.find(
											(el) => el.id == input.currency_id
										)?.symbol
								  } `
								: null
						}
					/>
				</FormControl>

				<HelperText value={`${emptyFinancialCount} un-matched financials.`} open={emptyFinancialCount != null && emptyFinancialCount != 0 && !!result}/>

				<div
					style={{
						display: 'flex',
						marginTop: '1em'
					}}
				>
					<CancelButton
						variant='contained'
						style={{ float: "left" }}
						onClick={() => handleClose()}
					>
						Cancel
					</CancelButton>

					{loading ? (
						<CircularProgress style={{
							marginRight: '1em',
							alignSelf: 'center',
  							marginLeft: 'auto',
						}} color="inherit" size={25} />
					) : null}

					<SubmitButton
						variant='contained'
						style={{ marginLeft: !loading ? 'auto' : 'inherit', }}
						type='submit'
						disabled={loading}
						onClick={() => handleSubmit()}
					>
						Submit
					</SubmitButton>
				</div>
			</div>
		</TransitionsModal>
	)
}

export default withApollo(TotalPriceModal)
