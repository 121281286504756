/* ==========================================================================
	These colors are meant to be used anywhere in the Levy Gorvy CRM
    ========================================================================== */
    
/* eslint-disable no-unused-vars */
export const nav = "#1C1C1D" // off black
export const navHighlight = "#3B3B3B" // dark grey
export const text = "#373A52" // dark purple
export const cancel = "#6A6A72" // grey
export const background = "#F4F4F4" // off white
export const cards = "#FFFFFF" // white
export const link = "#253874" // dark blue
export const editAdd = "#4465D1" // blue
export const acceptChanges = "#33BF5C" // light green
export const decline = "#A91D23" // red
export const white = "#FFFFFF" // white
export const toggleBackground = "#A9A9A9" // light grey
export const resetButton = "#cc3333" // bright red

// Other semantic colors
export const skeletonText = cancel
export const scrollbar = cancel

// Art Status Chips
export const available = acceptChanges
export const known= "#707070"
export const returned = "#A07E36" // tan
export const reserved = "#F59511" // orange
export const loanedIn = editAdd
export const loanedOut= link
export const consignedIn = "#08530B" // dark green
export const consignedOut = "#4E0000" // dark red
export const sold = decline

// Tag Default Background
export const tagBackgroundWithoutHex = "D3D3D3"
export const tagBackground = "#" + tagBackgroundWithoutHex// lighter grey

// Material UI ThemeProvider colors 
export const primary = nav
export const secondary = navHighlight
export const error = decline
export const warning = reserved
export const info = editAdd
export const success = acceptChanges

export const favorite = '#F8D937' // yellow favorite

export const offerSent = available
export const noResponse = decline
export const rejected = decline
export const offerRejected = decline
export const belowAsking = editAdd
