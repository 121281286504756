import React from 'react'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { SubmitButton, CancelButton } from '../input/Buttons'
import { FormControl } from '@mui/material'
import PhoneInput from 'react-phone-input-2'
import Label from '../input/Label'
import TextBox from '../input/Text/TextBoxDark'

const PhoneModal = (props) => {

	const handleChange = (e) => {
		props.setUserInput({
			...props.userInput,
			[e.target.name]: e.target.value,
		})
	}
		
	return (
		<TransitionsModal
			className="phone-modal"
			open={props.isOpen}
			close={props.close}
		>
			<form
				className={"dark-theme modal-small-content"}
				onSubmit={e => {
					e.preventDefault()
					e.stopPropagation()

				}}
			>
				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Label
					</Label>
					<TextBox
						autoFocus
						name={props.phoneOne ? "phone_label1" : "phone_label2"}
						placeholder={props.phoneOne ? "Phone 1" : "Phone 2"}
						value={props.phoneOne ? props.userInput.phone_label1 || '' : props.userInput.phone_label2 || ''}
						onChange={handleChange}
					/>
				</FormControl>

				<div style={{display: "flex", marginTop: "3px"}}>
					
					<div style={{marginTop: "-3px", width: '-webkit-fill-available'}}>

						<Label disableAnimation shrink style={{marginBottom: "-3px"}}>
							Phone*
						</Label>
						<PhoneInput
							disableSearchIcon
							disableDropdown
							placeholder=""
							dropdownStyle={{borderRadius: "4px"}}
							inputStyle={{width: "100%", height: "41px", fontFamily: "graphik"}}
							value={props.phoneOne ? props.userInput.phone_number1 || '' : props.userInput.phone_number2 || ''}
							onChange={(value, data, event) => {
								if (props.phoneOne) {

									props.setUserInput({
										...props.userInput,
										phone_number1: value.replace(/[^0-9]+/g,'')
									})

								} else if (props.phoneTwo) {
									props.setUserInput({
										...props.userInput,
										phone_number2: value.replace(/[^0-9]+/g,'')
									})
								}
							}}
						/>

					</div>

				</div>

				<CancelButton
					variant="contained"
					style={{ float: 'left', marginTop: '1em' }}
					onClick={e => {
						e.preventDefault()
						props.close()
						
					}}
				>
				Cancel
				</CancelButton>

				<SubmitButton
					style={{ float: 'right', marginTop: '1em' }}
					type="submit"
					variant="contained"
					onClick={e => {
						e.preventDefault()
						props.close()
						
					}}
				>
					Submit
				</SubmitButton>
		
			</form>
			
		</TransitionsModal>
	)
}

export default PhoneModal
