import { useApolloClient } from "@apollo/client"
import { CircularProgress } from "@mui/material"
import { CSSProperties, DragEventHandler, EventHandler, ReactPropTypes, useCallback, useContext, useEffect, useState } from "react"
import { GET_MANUAL_LINK, GET_MANUAL_UPLOAD_LINK } from "../common/queries"
import { getFileType } from "../Files/FileList"
import { CancelButton, ChooseImageButton } from "../input/Buttons"
import TransitionsModal from "../navigation/TransitionsModal/TransitionsModal"
import { severity } from "../Snackbar/CustomizedSnackbar"
import { AuthStateContext, DispatchContext } from "../store"

const initialState = {
	filename: null,
	key: null,
	filetype: null
}

export default function PdfManual(props: ReactPropTypes) {

	const userAuthentication = useContext(AuthStateContext)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity: string, text: string) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

		
	const [manualUrl, setManualUrl] = useState()

	const [fileInput, setFileInput] = useState(initialState)
	const [uploadOpen, setUploadOpen] = useState(false)
	const [attempt, setAttempt] = useState(false)
	const [dragging, setDragging] = useState(false)
	const [loading, setLoading] = useState(false)

	const [enterTarget, setEnterTarget] = useState<EventTarget | null>(null)
	const hasFile = fileInput.filename && fileInput.key

	const client = useApolloClient()

	const onDragOver: DragEventHandler<HTMLDivElement> = (e) => {
		e.preventDefault()
		e.stopPropagation()
	}

	const onDragEnter: DragEventHandler<HTMLDivElement> = (e) => {
		e.preventDefault()
		e.stopPropagation()
		setEnterTarget(e.target)
		if (e.dataTransfer?.items && e.dataTransfer?.items?.length > 0) {
			setDragging(true)
		}
	}

	const onDragLeave:DragEventHandler<HTMLDivElement> = (e) => {
		e.preventDefault()
		e.stopPropagation()
		if (enterTarget === e.target) 
			setDragging(false)
	}

	const onDrop:DragEventHandler<HTMLDivElement> = (e) => {
		e.preventDefault()
		e.stopPropagation()

		setDragging(false)
		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			handleUpload(e)
			e.dataTransfer.clearData()
		}
	}

	// Get the signed URL for the pdf to display it on pageload.
	useEffect(() => {
		setManualUrl(undefined)
		client.query({
			query: GET_MANUAL_LINK
		}).then(({ data }) => {
			const url = data?.getManualLink?.imgUrl
			setManualUrl(url)
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	
	let handleUpload:EventHandler<any> = (event) => {
		const files = event.target.files || event.dataTransfer.files
		const file = files[0]
		const suffix = file?.name?.split('.')?.pop()

		if (file.type !== 'application/pdf' || suffix !== 'pdf' ) {
			openSnackbar(severity.WARNING, 'Please upload a PDF.')
			return
		}
		setLoading(true)
		
		// get the upload link
		client.query({
			query: GET_MANUAL_UPLOAD_LINK
		}).then(({ data }) => {
			// Upload the new file
			const url = data?.getManualUploadLink?.url
			return fetch(url, {
				method: 'PUT',
				body: file,
				headers: {
					'Content-Type': file.type,
				}
			})
		}).then(() => {
			// get the new signed link
			openSnackbar(severity.SUCCESS, 'Successfully updated.')
			return client.query({
				query: GET_MANUAL_LINK
			})
		}).then(({ data }) => {
			// replace the old version in the window and close the modal
			const url = data?.getManualLink?.imgUrl
			setManualUrl(url)
			setUploadOpen(false)
		}).catch((error) => {
			console.error(error)
			openSnackbar(severity.ERROR, 'Error during upload.')
		}).finally(() => {
			setLoading(false)
		})
	}

	const dropStyle: CSSProperties = {
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'center',
		width: '500px',
		height: '200px',
		marginTop: '1em',
		marginBottom: '1em',
		border: dragging ? '4px dashed #4465D1' : (attempt && !hasFile) ? '4px dashed red' : '4px dashed #707070',
		backgroundColor: dragging ? '#e4e4e4' : 'inherit',
		borderRadius: 8,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	}
			
	return <>
		<div className="manual-page" style={{ margin: '1em' }}>
			<h2>{
				Number(userAuthentication?.user?.id) === 1 &&
					<ChooseImageButton onClick={() => setUploadOpen(true)}>
						Upload new Manual
					</ChooseImageButton>
			}</h2>

			<embed
				style={{
					pointerEvents: uploadOpen ? 'none' : 'auto'
				}}
				src={manualUrl}
				type="application/pdf"
				height="100%"
				width="100%"
				onDragEnter={() => {}}
			></embed>
		</div>

		<TransitionsModal 
			open={uploadOpen}
			close={() => setUploadOpen(false) }
		>
			<div
				id="upload-modal"
				style={{ width: '500px' }} 
			>
				<h1 className="card-title">
					Upload new Manual
				</h1>

				<div
					onDragEnter={onDragEnter} 
					onDragLeave={onDragLeave} 
					onDragOver={onDragOver} 
					onDrop={onDrop}
					style={dropStyle}
				>
					<div style={{ padding: '2em' }}>
						{loading ? 
							<CircularProgress size={32} style={{color: '#919191'}}/> : 
							<>
								{hasFile ? 
									<>{getFileType(fileInput.filetype)}</> :							
									<img
										alt="Share Icon"
										src={`/images/icons/Grey/Upload.svg`}
										style={{
											height: '2em',
										}}
									/> 
								}
							</>

						}

						<hr
							style={{
								visibility: 'hidden',
								marginBlockStart: '0em',
								marginBlockEnd: '0em',
							}}
						/>
						<span style={{ marginBottom: '1em'}} >

							{!hasFile ? `Drag a File Here` : `${fileInput.filename}`}
						</span> <br/>
						{!hasFile ? <span style={{ marginBottom: '1em' }}>– or –</span> : null}
						<hr
							style={{
								visibility: 'hidden',
								marginBlockStart: '0em',
								marginBlockEnd: '0.5em',
							}}
						/>
						<input
							accept="application/pdf,.pdf"
							style={{ display: 'none' }}
							id="icon-button-file"
							type="file"
							onChange={handleUpload}
							multiple={false}
						/>

						<label htmlFor="icon-button-file">
							<ChooseImageButton
								color="primary"
								variant="contained"
								aria-label="upload picture"
								// @ts-ignore
								component="span"
							>
								Choose a{hasFile ? 'nother' : ''} File from your Computer
							</ChooseImageButton>
						</label>
					</div>
				</div>

				<div style={{ paddingTop: '1em' }}>
					<CancelButton
						variant="contained"
						size="medium"
						type="reset"
						style={{ float: 'right', marginRight: '1em' }}
						onClick={() => {
							setAttempt(false)
							setFileInput(initialState)
							setUploadOpen(false)
						}}
					>
						Close
					</CancelButton>
				</div>
			</div>
		</TransitionsModal>
	</>
}
