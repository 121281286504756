import { BaseSyntheticEvent, FormEventHandler, useCallback, useContext, useRef, useState } from 'react'
import {
	Autocomplete, CircularProgress, FormControl, IconButton, MenuItem, Paper,
	Select, TextField } from "@mui/material"
import { ResetButton, SearchButton } from "../input/Buttons"
import Label from "../input/Label"
import ClearIcon from '@mui/icons-material/Clear'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import TextBoxForAutocomplete from '../input/Text/TextBoxForAutocomplete'
import { autoCompleteStyles, searchStyles } from '../styles/makesStyles'
import { useLazyQuery, useMutation } from '@apollo/client'
import TextBox from '../input/Text/TextBox'
import { useRecoilValue } from 'recoil'
import { bulkActionSelectionAtom } from './BulkActionQuickView'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { DispatchContext, LookupContext } from '../store'
import Dropdown from '../input/Dropdown/Dropdown'
import QuillText from '../input/QuillText'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import ReactQuill from 'react-quill'
import { quillNoNewlines } from '../constants/values'
import SingleContact from '../input/SingleContact'
import SingleUser from '../input/SingleUser'
import MultiplePrivateAccessUsers from '../input/MultiplePrivateAccessUsers'
import { GET_USERS } from '../AdminConsole/Queries'
import { MobileDateTimePicker } from '@mui/x-date-pickers'
import {
	BULK_UPDATE_ART, BULK_UPDATE_CONTACTS, SIMPLE_SEARCH_ARTISTS 
} from '../Art/Queries'
import DimensionSelection from '../Art/ArtPiece/DimensionSelection'
import bulkArtfields from './BulkEditArt'
import bulkContactFields from './BulkEditContacts'
import LimitedAutocomplete from '../common/LimitedAutocomplete'


interface Entity {
	id: string
	value: string
}

type Edit = {
	id: number,
	field: string | null
	value?: string | any | null
	type: string | null
	operation: string | null
}

const initialEditState = () => ({
	id: new Date().getTime(),
	field: null,
	value: null,
	type: null,
	operation: null
})

export type Field = {
	field: string,
	name: string,
	type: 'string' | 'boolean' | 'jsonb' | 'dimensions' | 'ID' | '[ID]' | 
		'contact' | 'ArtResearchInput' | 'restrictedToUsers' | 'galleryContact' |
		'date' | 'artists'
	lookup?: string
}

type BulkEditProps = {
	entityType: 'art' | 'contact'
	toggleEditMode: () => void
	loading: boolean
	requery?: () => void
}

const listToFieldMap = (acc: any, el: Field) => {
	acc[el.field] = el
	return acc
}

const entities = {
	'contact': {
		key: 'contact',
		name: 'Contacts',
		fieldMap: bulkContactFields.reduce(listToFieldMap, {}),
		fields: bulkContactFields,
		query: BULK_UPDATE_CONTACTS,
		dataField: 'updateContactsBulk'
	},
	'art': {
		key: 'art',
		name: 'Art',
		fieldMap: bulkArtfields.reduce(listToFieldMap, {}),
		fields: bulkArtfields,
		query: BULK_UPDATE_ART,
		dataField: 'updateArtBulk'
	}
}

// Filter Inputs
const BulkInput = function (props: {
	edit: Edit, handleChange: any, entity: any
}) {

	const lookup = useContext<any>(LookupContext)?.data

	const autocompleteClasses = autoCompleteStyles()

	const [dimensionsModal, setDimensionsModal] = useState(false)
	const [quillModal, setQuillModal] = useState(false)
	const [artResearchModal, setArtResearchModal] = useState(false)

	const quillRef = useRef<ReactQuill>(null)

	const [tempValue, setTempValue] = useState<any>(props.edit?.value)

	// Autocomplete State
	const [options, setOptions] = useState<any[]>([])
		
	const edit = props.edit
	let { value } = edit

	const [ loadArtists, { loading: artistLoading }] = 
		useLazyQuery(SIMPLE_SEARCH_ARTISTS, {
			onCompleted: (resp) => {
				setOptions([
					...resp.simpleSearchArtists,
					...(tempValue || [])
				])
			}
		})

	if (edit.type === 'boolean') {
		return (
			<FormControl style={{flexGrow: 2}}>
				<Select
					className="active-search-value padded-select"
					IconComponent={ExpandMoreRoundedIcon}
					name={`value-${edit.id}-label`}
					input={<Dropdown />}
					value={(edit.value !== null && edit.value !== undefined) ? edit.value : ''}
					onChange={(e) => props.handleChange({
						target: { value: e.target.value === 'true' }
					})}
				>
					<MenuItem value="true">True</MenuItem>
					<MenuItem value="false">False</MenuItem>
				</Select>
			</FormControl>
		)
	} else if (edit.type === 'jsonb') {
		const onClose = () => {
			props.handleChange({ target: { value: tempValue } })
			setQuillModal(false)
		}
		return <>
			<FormControl style={{flexGrow: 2}} >
				<QuillText
					inputStyle
					large
					editableStyle={true}
					onClick={() => setQuillModal(true)}
					name="caption-title"
					fontSize="16px"
					data-testid="caption-title"
				>
					{ edit.value }
				</QuillText>
			</FormControl>
			<TransitionsModal
				noPadding
				className="title-modal"
				open={quillModal}
				close={onClose}
			>
				<ReactQuill
					className="modal"
					theme="snow"
					ref={quillRef}
					value={tempValue || { ops: [] }}
					style={{ minWidth: '30em' }}
					modules={quillNoNewlines}
					onKeyUp={({ keyCode }) => (keyCode === 13) && onClose()}
					onChange={(content, delta, source, editor) => {
						setTempValue(editor.getContents())
					}}
				/>
			</TransitionsModal>
		</>
	} else if (edit.type === 'dimensions') {
		const onClose = () => {
			props.handleChange({ target: { value: tempValue?.dimensions } })
			setDimensionsModal(false)
		}
		return <>
			<FormControl style={{flexGrow: 2}} >
				<TextBox
					value={ edit.value
						?.filter((e: any) => e.description)
						?.map((e: any) => e.description)
						?.sort((a: any, b: any) => Number(a.ordinal) - Number(b.ordinal))
						.join('; ') || ''}
					placeholder={'-'}
					onClick={() => setDimensionsModal(true)}
					data-testid="caption-dimensions"
				/>
			</FormControl>
			<TransitionsModal
				className="dimension-modal"
				open={dimensionsModal}
				close={onClose}
			>
				<DimensionSelection
					setDimensionModal={onClose}
					artInput={tempValue || {dimensions: []}}
					setArtInput={setTempValue}
				/>
			</TransitionsModal>
		</>
	} else if (edit.type === 'ID') {
		return <FormControl className="padded-select" style={{flexGrow: 2}} >
			<Select
				IconComponent={ExpandMoreRoundedIcon}
				input={<Dropdown />}
				value={edit.value || ''}
				onChange={props.handleChange}
			>
				<MenuItem value="">-</MenuItem>
				{edit.field && lookup?.[props.entity.fieldMap[edit.field].lookup]
					?.sort((a: Entity, b: Entity) => a.value.localeCompare(b.value))
					?.map((option: any) => (
						<MenuItem
							key={option.id}
							value={option.id}
						>
							{ option.value }
						</MenuItem>
					))}
			</Select>
		</FormControl>
	} else if (edit.type === '[ID]') {
		return <FormControl style={{flexGrow: 2}} >
			<Autocomplete
				multiple
				isOptionEqualToValue={(a,b) => a?.id === b?.id}
				options={edit.field && lookup?.[props.entity.fieldMap[edit.field].lookup]}
				value={tempValue || []}
				getOptionLabel={option => option?.value || "Loading..."}
				filterSelectedOptions
				popupIcon={<ExpandMoreRoundedIcon />}
				classes={autocompleteClasses}
				size="small"
				renderInput={params => (
					<TextField
						{...params}
						variant="outlined"
						fullWidth
					/>
				)}
				onChange={(event, value: any[]) => {
					setTempValue(value)
					props.handleChange({target: { value: value?.map(v => v.id) }})				
				}}
			/>
		</FormControl>
	} else if (edit.type === 'contact') {
		return <FormControl style={{flexGrow: 2}} >
			<SingleContact
				elasticSearch="searchContacts"
				value={tempValue}
				onChange={(event: BaseSyntheticEvent, value: any) => {
					setTempValue(value)
					props.handleChange({target: { value: value?.id}})
				}}
			/>
		</FormControl>
	} else if (edit.type === 'ArtResearchInput') {
		const artResearchTypes: { [key: string]: number }  = {
			'provenance': 1,
			'exhibition': 2,
			'literature': 3,
			'other': 4
		}
		const type = edit.field || 'other'
		const onClose = () => {
			props.handleChange({ target: { value: tempValue } })
			setArtResearchModal(false)
		}
		return <>
			<FormControl style={{flexGrow: 2}} >
				<QuillText
					large
					inputStyle
					editableStyle={true}
					onClick={() => setArtResearchModal(true)}
					name="research"
					fontSize="16px"
				>
					{ edit.value?.formatted_content }
				</QuillText>
			</FormControl>
			<TransitionsModal
				noPadding
				className="title-modal"
				open={artResearchModal}
				close={onClose}
			>
				<ReactQuill
					className="modal"
					theme="snow"
					ref={quillRef}
					value={tempValue?.formatted_content || { ops: [] }}
					style={{ minWidth: '30em' }}
					modules={quillNoNewlines}
					onKeyUp={({ keyCode }) => (keyCode === 13) && onClose()}
					onChange={(content, delta, source, editor) => {
						const artResearchTypeId = artResearchTypes[type] || 4
						setTempValue({
							art_research_type_id: artResearchTypeId,
							content: editor.getText().trim(),
							formatted_content: editor.getContents()
						})
					}}
				/>
			</TransitionsModal>
		</>
	} else if (edit.type === 'restrictedToUsers') {
		return <FormControl style={{flexGrow: 2}} >
			<MultiplePrivateAccessUsers
				entityType={props.entity.key}
				query={GET_USERS}
				value={tempValue || []}
				onChange={(event: any, value: any) => {
					setTempValue(value)
					props.handleChange({target: {
						value: value?.map((u: Entity) => u.id)
					}})
				}}
			/>
		</FormControl>
	} else if (edit.type === 'galleryContact') {
		return <FormControl style={{flexGrow: 2}} >
			<SingleUser
				query={GET_USERS}
				value={tempValue}
				onChange={(event: any, value: any) => {
					setTempValue(value)
					props.handleChange({target: { value: value?.id}})
				}}
			/>
		</FormControl>
	} else if (edit.type === 'date') {
		return <FormControl style={{flexGrow: 2}} >
			<MobileDateTimePicker
				inputFormat="MMM do, yyyy"
				disableMaskedInput={true}
				componentsProps={{
					actionBar: {
						actions: ['today', 'clear', 'accept']
					}
				}}
				className="MUIDatePicker"
				value={value}
				renderInput={({ inputRef, inputProps, InputProps }) => {
					const newProps = { ...inputProps}
					newProps.readOnly = false
					if (InputProps?.endAdornment) {
						newProps.endAdornment = InputProps.endAdornment
					}
					
      				// @ts-ignore 
					return <TextBox
						value={null} // included for Typescript; replace by ...newProps
						ref={inputRef}
						{...newProps}
					/>
				}}
				onChange={(date) => {
					props.handleChange({target: { value: date }})
				}}
			/>
		</FormControl>
	} else if (edit.type === 'artists') {
		return <FormControl style={{flexGrow: 2}} >
			<LimitedAutocomplete
				setQuery={(query: string | boolean) => {
					if (query) { loadArtists({ variables: { query } }) }
				}}
				multiple
				size="small"
				forcePopupIcon
				filterSelectedOptions
				popupIcon={ <ExpandMoreRoundedIcon /> }
				classes={autocompleteClasses}
				getOptionLabel={(option: any) => {
					if (option?.first_name && option?.last_name) {
						return option?.first_name+" "+option?.last_name
					}
				}}
				options={options}
				loading={artistLoading}
				value={tempValue || []}
				isOptionEqualToValue={(option: Entity, value: Entity) =>{
					return option.id === value?.id
				}}
				onChange={(_: BaseSyntheticEvent, value: Entity[]) => {
					setTempValue(value)
					setOptions(value || [])
					props.handleChange({target: { value: value.map(v => v.id) }})
				}}
				renderInput={(params: any) => <TextField
					{...params}
					id="owner-autocomplete"
					variant="outlined"
					fullWidth
					autoFocus
					style={{ paddingTop: '0.5' }}
					classes={{ notchedOutline: null }}
					InputLabelProps={{ shrink: true }}
					InputProps={{
						...params.InputProps,
						endAdornment: <>
							{artistLoading ? (
								<CircularProgress
									color="inherit"
									size={20}
								/>
							) : null}
							{ params.InputProps.endAdornment }
						</>,
					}}
				/>}
			/>
		</FormControl>
	}
	return (
		<FormControl style={{flexGrow: 2}} >
			<TextBox
				value={value || ''}
				onChange={props.handleChange}
			/>
		</FormControl>
	)
}

const BulkEdit = (props: BulkEditProps) => {

	const entity = entities[props.entityType]

	const autocompleteClasses = autoCompleteStyles()
	const classes = searchStyles()
	const buttonStyle = {
		padding: '8px',
		marginRight: '0.25em',
		height: 'fit-content',
	}
	const typeStyle = { fontWeight: 500 }

	// @ts-ignore
	const bulkActionSelection = useRecoilValue<Entity[]>(bulkActionSelectionAtom)

	const [edits, setEdits] = useState<Edit[]>([initialEditState()])
	const [buttonActive, setButtonActive] = useState(true)
	const [updateMutation] = useMutation(entity.query)

	const valueSubmit = edits.every(e => (e.field != null) && (e.value != null))

	const dispatch = useContext(DispatchContext)

	// Snackbar
	const openSnackbar = useCallback((severity: string, text: string) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])



	const onSubmit: FormEventHandler<HTMLFormElement> = e => {
		e.preventDefault()

		setButtonActive(false)

		const id_list = bulkActionSelection.map(s => s.id)
		const fields = edits.reduce<any>((acc, el) => {
			if (el.operation && el.field === 'restrictedToUsers') {
				acc[el.operation] = el.value
			} else if (el.field) {
				acc[el.field] = el.value
			}
			return acc
		}, {})

		updateMutation({
			variables: {
				input: {
					id_list,
					...fields
				}
			}
		}).then(({ data }: any) => {
			const response = data[entity.dataField]
			
			// @ts-ignore
			const theSeverity = severity[response?.severity || 'ERROR']

			openSnackbar(theSeverity, response?.message)
			setButtonActive(true)
			props.toggleEditMode()
			props.requery?.()
		}).catch(error => {
			console.error(error)
			openSnackbar(severity.ERROR, error?.[entity.dataField]?.message ||
				"There was an error bulk updating these entities.")
			setButtonActive(true)
			props.toggleEditMode()
		})
	}

	return <Paper
		className="search-card"
		sx={{
			padding: '1em 2em',
			minWidth: '60em'
		}}
	>
		<h1 className="card-title">Bulk Edit {entity.name} ({ bulkActionSelection.length })</h1>
		<form onSubmit={onSubmit} >
			<div style={{
				overflowY: 'auto',
				maxHeight: '20em'
			}}>
				{ edits.map((edit: Edit, i) => {

					const hasValue = edit.field !== '' ||
					edit.field !== null || entity.fields
						.map(f => f.name)
						.includes(edit.field)

					return (
						<div
							className="row"
							style={{
								alignItems: 'flex-end',
								minHeight: '65px',
							}}
							key={edit.id || i}
						>
							<IconButton
								aria-label="delete"
								onClick={e => {
									e.preventDefault()

									if (edits.length === 1) {
										setEdits([initialEditState()])
									} else {
										setEdits(edits.filter(e => e !== edit))
									}
								}}
								style={buttonStyle}
								size="large">
								<ClearIcon />
							</IconButton>

							<FormControl className={classes.searchSelection}>
								<Label
									id={`field-${edit.id}-label`}
									style={typeStyle}
									disableAnimation
									shrink
								>
                                Field
								</Label>
								<Autocomplete
									popupIcon={<ExpandMoreRoundedIcon />}
									classes={autocompleteClasses}
									style={{ marginTop: '1.5em' }}
									onChange={(e, value) => {
									// null check
										const type = value ? 
											entity.fieldMap[value].type : 
											'string'
										edit.field = value
										edit.type = type
										edit.value = null
										edit.operation = (value === 'restrictedToUsers' ? 'restrictedToUsersAdd' : null)
										setEdits([...edits])
									}}
									value={(hasValue && edit.field) || null}
									options={entity.fields.map(f => f.field)}
									getOptionLabel={option => entity.fieldMap[option]?.name}
									renderInput={(params) => (
										<TextBoxForAutocomplete
											{...params}
											variant="outlined"
											InputProps={{
												...params.InputProps,
												style: {
													paddingTop: 3.5,
													paddingBottom: 3.5
												}
											}}
										/>
									)}

								/>
							</FormControl>
							<FormControl className={classes.searchSelection}>
								<Select
									className="active-search-value padded-select"
									IconComponent={ExpandMoreRoundedIcon}
									name={`operator-${edit.id}-label`}
									onChange={(event) => {
										edit.operation = event.target.value
										setEdits([...edits])
									}}
									input={<Dropdown />}
									value={edit.field !== 'restrictedToUsers' ? 'replaceWith' : edit.operation}
									disabled={edit.field !== 'restrictedToUsers'}
								>
									{edit.field !== 'restrictedToUsers' ?
										<MenuItem value="replaceWith">Replace with</MenuItem> : null}
									{edit.field === 'restrictedToUsers' ? 
										<MenuItem value="restrictedToUsersAdd">Add</MenuItem> : null}
									{edit.field === 'restrictedToUsers' ? 
										<MenuItem value="restrictedToUsersRemove">Remove</MenuItem> : null}
								</Select>
							</FormControl>
							<BulkInput
								edit={edit}
								handleChange={(event: BaseSyntheticEvent) => {
									edit.value = event.target.value
									setEdits([...edits])
								}}
								entity={entity}
							/>
						</div>
					)
				})}
			</div>

			<div
				className="row"
				style={{ minHeight: '65px', alignItems: 'center' }}
			>
				<IconButton
					aria-label="add"
					onClick={(e) => {
						e.preventDefault()
						setEdits(edits => edits.concat(initialEditState()))
					}}
					style={buttonStyle}
					disabled={
						edits[edits.length - 1]?.field == null ||
						edits[edits.length - 1]?.value === ''
					}
					size="large"
				>
					<AddCircleOutlineIcon />
				</IconButton>
			</div>

			<div className="row" style={{marginTop: '1em'}}>
				<div />
				<ResetButton
					data-testid="reset-button"
					variant="contained"
					size="small"
					onClick={() => {
						setEdits([initialEditState()])
						props.toggleEditMode()
					}}
					style={{ marginRight: '1em' }}
					disabled={!buttonActive}
				>
					Cancel
				</ResetButton>
				<SearchButton
					data-testid="search-button"
					variant="contained"
					size="small"
					type="submit"
					style={{backgroundColor: !props.loading ? '#4465D1' : undefined}}
					disabled={props.loading || !buttonActive || !valueSubmit}
				>
					Update
				</SearchButton>
			</div>
		</form>

	</Paper>
}

export default BulkEdit
