import routes from '../../navigation/routes'
import TabbedPage from '../../navigation/Tabs/TabbedPage'
import { useParams } from 'react-router-dom'
import { gql } from '@apollo/client'

export const GET_LISTING = gql`
	query getListing($id: ID!) {
		getListing(id: $id) {
			id
		}
	}
`

export default function ShowcaseExhibitionTabbedPage(props: any) {
	const params = useParams()

	// @ts-ignore
	return TabbedPage({
		...props,
		routes: routes.exhibitionPages.pages,
		query: GET_LISTING,
		options: routes.exhibitionPages.options,
		params,
	})
}
