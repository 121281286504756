import { gql } from "@apollo/client"
import { userDataCompressed } from "../common/fragments"

export const GET_DEAL_ENTRY_QUEUE = gql`
	query getDealEntryQueue(
		$dealEntryStatusCategoryId: ID!
		$entityId: ID!
		$thumbnailResolution: String = "128x128"
	) {
		getDealEntryQueue(dealEntryStatusCategoryId: $dealEntryStatusCategoryId, entityId: $entityId) {
			id
			deal_id
			is_private
			art_id
			art {
				id
				code_name
				is_private
				created_at
				imgUrl
				title
				formatted_title 
				inventory_number
				inventory_number_prefix
				inventory_number_suffix
				year
				artist {
					id
					first_name
					last_name
				}
			}
			status_note
			start_at
			offer_amount
			offer_currency {
				id
				label
			}
			has_reserve
			reserve_position
			reserve_created_at
			reserve_begin_at
			reserve_end_at
			counter_offer_amount
			counter_offer_currency {
				id
				label
			}
			end_at
			is_interested
			sale_currency {
				id
				label
			}
			sale_amount
			deal_entry_status {
				id
				value
				deal_type {
					id
				}
				workflow_step
				workflow_end
				workflow_graph
				deal_entry_status_category {
					id
					value
				}
			}
			deal {
				id
				contacts {
					id
					code_name
					is_private
					preferred_name
					last_name
					is_company
					first_name
					company_name
					imgUrl
					is_deceased
					contactRole {
						id
						value
					}
					dynamic_generated_codename
					created_at
				}
				restrictedToUsers {
					id
					first_name
					last_name
					gallery
					title
					department
					imgUrl
					assistants {
						id
						first_name
						last_name
						gallery
						imgUrl
						permissions {
							permission_id
							permission_value_id
						}
					}
				}
				
				salesperson {
					...userDataCompressed
					is_lead
					is_deleted
				}
			}
			reserveQueue {
				hasReserve {
					id
					first_name
					last_name
					imgUrl
				}
				reserve_begin_at
				reserve_end_at
				queue_length
			}
		}
	}
	${userDataCompressed}
`

export const UDPATE_DEAL_ENTRY_ART_STATUS = gql`
	mutation updateDealEntryArtStatus(
		$DealEntryRequest: DealEntryRequest!
		$ArtStatusId: ID
	) {
		updateDealEntryArtStatus(
			input: $DealEntryRequest
			artStatusId: $ArtStatusId
		) {
			code
			success
			message
			severity
		}
	}
`

export const UPSERT_DEAL_ENTRY = gql`
	mutation upsertDealEntry(
		$DealEntryRequest: DealEntryRequest
		$thumbnailResolution: String = "128x128",
		$notify: NotificationLevel,
		$reserveQueueIndex: Int
	) {
		upsertDealEntryV2(input: $DealEntryRequest, notify: $notify, reserveQueueIndex: $reserveQueueIndex) {
			code
			success
			message
			severity
			dealEntry {
				id
				deal_id
				is_private
				art_id
				art {
					id
					code_name
					is_private
					created_at
					imgUrl
					title
					formatted_title 
					inventory_number
					inventory_number_prefix
					inventory_number_suffix
					year
					artist {
						id
						first_name
						last_name
					}
					status {
						id
						value
					}
				}
				status_note
				start_at
				offer_amount
				offer_currency {
					id
					label
				}
				has_reserve
				reserve_position
				reserve_created_at
				reserve_begin_at
				reserve_end_at
				counter_offer_amount
				counter_offer_currency {
					id
					label
				}
				end_at
				is_interested
				sale_currency {
					id
					label
				}
				sale_amount
				deal_entry_status {
					id
					value
					deal_type {
						id
					}
					workflow_step
					workflow_end
					workflow_graph
					deal_entry_status_category {
						id
						value
					}
				}
				deal {
					id
					contacts {
						id
						code_name
						is_private
						preferred_name
						last_name
						is_company
						first_name
						company_name
						imgUrl
						is_deceased
						contactRole {
							id
							value
						}
						dynamic_generated_codename
						created_at
					}
					restrictedToUsers {
						id
						first_name
						last_name
						gallery
						title
						department
						imgUrl
						assistants {
							id
							first_name
							last_name
							gallery
							imgUrl
							permissions {
								permission_id
								permission_value_id
							}
						}
					}
					
					salesperson {
						...userDataCompressed
						is_lead
						is_deleted
					}
				}
				reserveQueue {
					hasReserve {
						id
						first_name
						last_name
						imgUrl
					}
					reserve_begin_at
					reserve_end_at
					queue_length
				}
			}
		}
	}
	${userDataCompressed}
`

export const GET_DEAL_ENTRIES = gql`
	query getDealEntries(
		$GetDealEntriesRequest: GetDealEntriesRequest!
		$thumbnailResolution: String = "128x128"
	) {
		getDealEntries(input: $GetDealEntriesRequest) {
			cursor
			totalItems
			items {
				id
				deal_id
				is_private
				art_id
				art {
					id
					code_name
					is_private
					created_at
					imgUrl (thumbnailResolution: $thumbnailResolution)
					title
					formatted_title 
					inventory_number
					inventory_number_prefix
					inventory_number_suffix
					year
					status_id
					artist {
						id
						first_name
						last_name
					}
				}
				status_note
				start_at
				offer_amount
				offer_currency {
					id
					label
				}
				has_reserve
				reserve_position
				reserve_created_at
				reserve_begin_at
				reserve_end_at
				counter_offer_amount
				counter_offer_currency {
					id
					label
				}
				end_at
				is_interested
				sale_currency {
					id
					label
				}
				sale_amount
				deal_entry_status {
					id
					value
					deal_type {
						id
					}
					workflow_step
					workflow_end
					workflow_graph
					deal_entry_status_category {
						id
						value
					}
				}
				deal {
					id
					is_gallery_private
					is_private
					publicAccess
					code_name
					created_at
					contacts (primaryOnly: true) {
						id
						code_name
						is_private
						preferred_name
						last_name
						is_company
						first_name
						company_name
						imgUrl (thumbnailResolution: $thumbnailResolution)
						is_deceased
						contactRole {
							id
							value
						}
						dynamic_generated_codename
						created_at
					}
					restrictedToUsers {
						id
						first_name
						last_name
						gallery
						title
						department
						imgUrl
						assistants {
							id
							first_name
							last_name
							gallery
							imgUrl
							permissions {
								permission_id
								permission_value_id
							}
						}
					}
					
					salesperson {
						...userDataCompressed
						is_lead
						is_deleted
					}
				}
				reserveQueue {
					hasReserve {
						id
						first_name
						last_name
						imgUrl
					}
					reserve_begin_at
					reserve_end_at
					queue_length
				}
			}
		}
	}
	${userDataCompressed}
`

export const GET_DEAL_ENTRY = gql`
	query getDealEntry(
		$dealEntryId: ID!
	) {
		getDealEntry(dealEntryId: $dealEntryId) {
			
			id
			deal_id
			is_private
			art_id
			art {
				id
				code_name
				is_private
				title
				project_code {
					id
					project_code {
						id
						project_code
					}
				}
				currentListings {
					id
					project_code {
						id
						project_code
					}
				}
			}
			status_note
			start_at
			offer_amount
			offer_currency {
				id
				label
			}
			deal_entry_status {
				id
				value
				deal_type {
					id
				}
				workflow_step
				workflow_end
				workflow_graph
				deal_entry_status_category {
					id
					value
				}
			}
			deal {
				id
				is_gallery_private
				is_private
				publicAccess
				code_name
			}
			
		}
	}
`

export const GET_TRANSACTION_HISTORY = gql`
	query getTransactionHistory(
		$CommonRequest: CommonRequest!
		$thumbnailResolution: String = "128x128"
	) {
		getTransactionHistory(input: $CommonRequest) {
			cursor
			totalItems
			items {
				id
				deal_id
				is_private
				art_id
				modified_at
				created_at
				art {
					id
					code_name
					is_private
					created_at
					imgUrl (thumbnailResolution: $thumbnailResolution)
					title
					formatted_title 
					inventory_number
					inventory_number_prefix
					inventory_number_suffix
					year
					status_id
					artist {
						id
						first_name
						last_name
					}
					current_sources {
						id
						code_name
						first_name
						last_name
						is_company
						company_name
						is_deleted
						is_private
						imgUrl (thumbnailResolution: $thumbnailResolution)
						canNavigate
						isAllowedContactType
						dynamic_generated_codename
						created_at
						deal_entry_status_category
						deal_entry_status
						contact_role_id
					}
				}
				status_note
				start_at
				offer_amount
				offer_currency {
					id
					label
				}
				has_reserve
				reserve_position
				reserve_created_at
				reserve_begin_at
				reserve_end_at
				counter_offer_amount
				counter_offer_currency {
					id
					label
				}
				end_at
				is_interested
				sale_currency {
					id
					label
				}
				sale_amount
				deal_entry_status {
					id
					value
					deal_type {
						id
					}
					workflow_step
					workflow_end
					workflow_graph
					deal_entry_status_category {
						id
						value
					}
				}
				deal {
					id
					is_gallery_private
					is_private
					publicAccess
					code_name
					created_at
					type_id
					start_at
					end_at
					contacts (primaryOnly: true) {
						id
						code_name
						is_private
						preferred_name
						last_name
						is_company
						first_name
						company_name
						imgUrl (thumbnailResolution: $thumbnailResolution)
						is_deceased
						contactRole {
							id
							value
						}
						dynamic_generated_codename
						created_at
					}
					restrictedToUsers {
						id
						first_name
						last_name
						gallery
						title
						department
						imgUrl
						assistants {
							id
							first_name
							last_name
							gallery
							imgUrl
							permissions {
								permission_id
								permission_value_id
							}
						}
					}
					
					salesperson {
						...userDataCompressed
						is_lead
						is_deleted
					}
				}
				reserveQueue {
					hasReserve {
						id
						first_name
						last_name
						imgUrl
					}
					reserve_begin_at
					reserve_end_at
					queue_length
				}
			}
		}
	}
	${userDataCompressed}
`

export const GET_TRANSACTION_HISTORY_NAV = gql`
	query getTransactionHistory(
		$CommonRequest: CommonRequest!
	) {
		getTransactionHistory(input: $CommonRequest) {
			cursor
			totalItems
			items {
				id
				deal_id
				deal {
					id
					is_gallery_private
					is_private
					created_at
				}
			}
		}
	}
`

export const GET_DEAL_ENTRIES_SHOWCASE_SIDE = gql`
	query getDealEntries(
		$GetDealEntriesRequest: GetDealEntriesRequest!
		$thumbnailResolution: String = "1024x1024"
	) {
		getDealEntries(input: $GetDealEntriesRequest) {
			cursor
			totalItems
			items {
				id
				deal_id
				is_private
				art_id
				art {
					id
					code_name
					is_private
					created_at
					imgUrl (thumbnailResolution: $thumbnailResolution)
					title
					medium
					all_dimensions
					formatted_title 
					inventory_number
					inventory_number_prefix
					inventory_number_suffix
					year
					status_id
					artist {
						id
						first_name
						last_name
					}
				}
				status_note
				start_at
				offer_amount
				offer_currency {
					id
					label
				}
				has_reserve
				reserve_position
				reserve_created_at
				reserve_begin_at
				reserve_end_at
				counter_offer_amount
				counter_offer_currency {
					id
					label
				}
				end_at
				is_interested
				sale_currency {
					id
					label
				}
				sale_amount
				deal_entry_status {
					id
					value
					deal_type {
						id
					}
					workflow_step
					workflow_end
					workflow_graph
					deal_entry_status_category {
						id
						value
					}
				}
				deal {
					id
					is_gallery_private
					is_private
					publicAccess
					code_name
					created_at
					contacts (primaryOnly: true) {
						id
						code_name
						is_private
						preferred_name
						last_name
						is_company
						first_name
						company_name
						imgUrl (thumbnailResolution: $thumbnailResolution)
						is_deceased
						contactRole {
							id
							value
						}
						dynamic_generated_codename
						created_at
					}
					restrictedToUsers {
						id
						first_name
						last_name
						gallery
						title
						department
						imgUrl
						assistants {
							id
							first_name
							last_name
							gallery
							imgUrl
							permissions {
								permission_id
								permission_value_id
							}
						}
					}
					
					salesperson {
						...userDataCompressed
						is_lead
						is_deleted
					}
				}
				reserveQueue {
					hasReserve {
						id
						first_name
						last_name
						imgUrl
					}
					reserve_begin_at
					reserve_end_at
					queue_length
				}
			}
		}
	}
	${userDataCompressed}
`

export const GET_DEAL_IDS = gql`
	query getDealIds($GetDealEntriesRequest: GetDealEntriesRequest!) {
		getDealIds(input: $GetDealEntriesRequest) {
			id
			deal_id
			art_id
		}
	}
`
