import React from 'react'
import { ChooseImageButton, SubmitButton } from '../input/Buttons'
import TextBox from '../input/Text/TextBoxThin'
import AuthButton from './AuthButtons'

import { withApollo } from '@apollo/client/react/hoc'
import { Button } from '@mui/material'

const MockLogin = (props) => {
	const { logins, handleClick } = props
	const [userSelection, setUserSelection] = React.useState(null)

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				height: '100vh',
				marginTop: '10%',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<h1>{window.location.pathname === '/admin/login' ? 'Admin Console' : 'Azure Island'}</h1>
				{
					logins.map( login => (
						<AuthButton
							width = "250px"
							url={window.location.pathname === '/admin/login' ? login?.loginUrls?.admin : login?.loginUrls?.login}
							key={`oauthButton-${login.name}`}
							logo={login.imgSrc}
							name={login.imgAltText}
							identityProvider={login.name}
						/>
					))
				}

			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					marginLeft: '1em',
				}}
			>
				<h1>Quick Mock</h1>
				<ChooseImageButton data-testid="cofounder-login" onClick={() => handleClick(2)}>
					Co-Founder
				</ChooseImageButton>

				<ChooseImageButton data-testid="sr-director-login" style={{ marginTop: '1em' }} onClick={() => handleClick(32)}>
					Senior Director
				</ChooseImageButton>

				<ChooseImageButton data-testid="director-login" style={{ marginTop: '1em' }} onClick={() => handleClick(10)}>
					Director
				</ChooseImageButton>

				<ChooseImageButton data-testid="research-head-login" style={{ marginTop: '1em' }} onClick={() => handleClick(20)}>
					Head of Research & Archives
				</ChooseImageButton>

				<ChooseImageButton data-testid="system-user-login" style={{ marginTop: '1em' }} onClick={() => handleClick(1)}>
					System User
				</ChooseImageButton>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					marginLeft: '1em',
				}}
			>
				<h1>By User ID</h1>
				<TextBox
					variant="outlined"
					fullWidth
					size="small"
					type="number"
					value={userSelection || ''}
					onChange={(e) => setUserSelection(parseInt(e.target.value))}
				></TextBox>
				<SubmitButton data-testid="autocomplete-login" style={{ marginTop: '1em' }} onClick={() => handleClick(userSelection)}>
					Submit
				</SubmitButton>

				<Button data-testid="logout-button" style={{ marginTop: '1em', backgroundColor: '#d3d3d3'}} onClick={() => {
					window.location.pathname = '/logout'
				}}>
					Logout
				</Button>
			</div>
		</div>
	)
}

export default withApollo(MockLogin)
