import React from 'react'
import ReactPlayer from 'react-player'
import CollapsedContent from './CollapsedContent'

const Faq = () => {
	return (
		<div
			className="manual-page"
			style={{
				margin: '1em',
			}}
		>
			<div className="flex" style={{ flexDirection: 'column' }}>
				<CollapsedContent
					title="Q: How do I edit fields?"
					text={
						<>
							<li>
								Unlike ArtBase, The Platform’s fields aren’t editable when you click on them. Rather, in The Platform, you must enable the edit-mode of the card you’re attempting to
								edit before doing so. To enable this edit-mode, right click (two finger click on trackpad) and click on the “Edit” option in the pop-up context menu. After you make
								your changes you can revert them by selecting the “Cancel” button, or save them with the “Save” button. See the video below for example:
							</li>{' '}
							<br />
							<center>
								<ReactPlayer url="https://www.youtube.com/watch?v=9UllqhqXGl0" controls={true} />
							</center>
						</>
					}
				/>
			</div>
		</div>
	)
}

export default Faq
