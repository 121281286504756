import React, { useCallback, useContext, useEffect, useState } from 'react'
import { gql } from '@apollo/client'
import { useLazyQuery } from '@apollo/client'
import { stripMeta } from '../../../common/helpers'
import FileGroups from '../../../Files/FileGroups'
import { DispatchContext } from '../../../store'
import { severity } from '../../../Snackbar/CustomizedSnackbar'
import { UPDATE_ARTIST } from '../../Queries'
import { useMutation } from '@apollo/client'
import { fileData } from '../../../common/fragments'
import { useParams } from 'react-router-dom'

const fileQuery = gql`
	query getArtist(
		$id: ID!
		$thumbnailResolution: String = "128x128"
	) {
		getArtist(id: $id) {
			id
			files {
				...fileData
			}
		}
	}
	${fileData}
`

export default function ArtistFilesPage(props) {

	const [files, setFiles] = useState([])

	const params = useParams()

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	let artist = props.state?.getArtist || {}
	let setArtist = (artist) => props.setState({ getArtist: artist })

	const [refetch, { loading, error }] = useLazyQuery(fileQuery, {
		onCompleted: (data) => {
			setFiles(data?.getArtist?.files || [])
		},
		onError: error => {
			console.log(error)
			openSnackbar(severity.ERROR, "Error retrieving files.")
		}
	})


	const [
		updateArtist, { error: updateError }
	] = useMutation(UPDATE_ARTIST, {
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, "Could not update.")
		},
		onCompleted: (response) => {
			if (response.updateArtist.success === true) {
				refetch({ variables: { id: props.id } })
				openSnackbar(severity.SUCCESS, response.updateArtist.message)
				setArtist(response.updateArtist.artist)
			} else {
				// On failure, reset listingInput state, don't touch listing
				// state and show error
				openSnackbar(severity.ERROR, response.updateArtist.message)
			}
		}
	})

	// update function
	const update = (fields) => {
		let variables = stripMeta(fields)
		variables.id = artist.id

		updateArtist({
			variables: {
				ArtistInput: variables
			}
		})
	}

	useEffect(() => {
		setFiles([])
		if (params.id && !props.findMode)
			refetch({ variables: { id: params.id } })
	}, [params.id, refetch, props.tabbedPageFileRefetch, props.findMode])

	return (!props.findMode && props.id > 0) && (
		<FileGroups
			objectId={artist?.id}
			loading={props.loading || loading}
			error={props.error || error || updateError}
			files={files}
			setFiles={setFiles}
			onSave={update}
			fileObjectId={props.fileObjectId}
			options={props.options}

			tabbedPageFileRefetch={props.tabbedPageFileRefetch}
			setTabbedPageFileRefetch={props.setTabbedPageFileRefetch}
		/>
	)
}
