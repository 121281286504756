import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react'
import {
	Box, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem, Paper, Radio, RadioGroup, Select, Tabs
} from "@mui/material"
import Label from '../../input/Label'
import TextBox from '../../input/Text/TextBox'
import { DispatchContext, LookupContext } from '../../store'
import { range, sortBy } from 'lodash'
import { ResetButton, SearchButton } from '../../input/Buttons'
import Dropdown from '../../input/Dropdown/Dropdown'
import { selectionOptions } from '../../Art/ArtPiece/DimensionSelection'
import NumberFormat from 'react-number-format'
import { ExpandMoreRounded } from '@mui/icons-material'
import { ESOrder } from '../../common/typescriptVars'
import { SearchTerm } from '../../common/SavedSearch'
import LimitedAutocomplete from '../../common/LimitedAutocomplete'
import { useApolloClient } from '@apollo/client'
import TextBoxForAutocomplete from '../../input/Text/TextBoxForAutocomplete'
import ShowcaseSortOrder from '../ShowcaseSortOrder'
import { AVAILABLE_ART_SEARCH } from '../Artist/Available'
import ShowcaseGridView from '../ShowcaseGridView'
import { DYNAMIC_ARTISTS_SEARCH } from '../../Search/Queries'
import clsx from 'clsx'
import { useStyles } from '../../navigation/Tabs/StyledTab'
import ShowcaseToggle from '../ShowcaseToggle'
import { GridMoreVertIcon } from '@mui/x-data-grid-pro'
import ViewCompactIcon from '@mui/icons-material/ViewCompact'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import CuratedListModal from '../CuratedLists/CuratedListModal'

const fromToStyles = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center'
}

const cmPerInch = 2.54
const toMetric = (int: string, frac: string) => ((Number(int) + Number(frac)) * cmPerInch) + ''

export default function ShowcaseArtSearch(props: any) {

	const lookup = useContext<any>(LookupContext)
	const artCategories = lookup?.data?.getArtCategories
	const sortedCategories = sortBy(artCategories, ['value'])
	const artStatuses = lookup.data?.getArtStatuses || []

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity: severity, text: string) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	const client = useApolloClient()
	const classes = useStyles()

	const mainStatuses = artStatuses.length ?
		['Consigned In', 'Available', 'Reserved', 'SOLD', 'Returned']
			.map(val => artStatuses.find((s: any) => s.value === val)) :
		[]
	const otherStatuses = artStatuses.filter((s: any) => !mainStatuses.includes(s))
		?.sort((a: any, b: any) => a.value.localeCompare(b.value))

	// Input Values
	const [artists, setArtists] = useState<any[]>([])
	const [title, setTitle] = useState<string>('')
	const [categories, setCategories] = useState<{ [key: string]: boolean }>({})
	const [fromYear, setFromYear] = useState<string>('')
	const [toYear, setToYear] = useState<string>('')
	const [unit, setUnit] = useState<string>('in')
	const [minHeight, setMinHeight] = useState<string>('')
	const [minHeightFrac, setMinHeightFrac] = useState<string>('')
	const [maxHeight, setMaxHeight] = useState<string>('')
	const [maxHeightFrac, setMaxHeightFrac] = useState<string>('')
	const [minWidth, setMinWidth] = useState<string>('')
	const [minWidthFrac, setMinWidthFrac] = useState<string>('')
	const [maxWidth, setMaxWidth] = useState<string>('')
	const [maxWidthFrac, setMaxWidthFrac] = useState<string>('')
	const [minPrice, setMinPrice] = useState<string>('')
	const [maxPrice, setMaxPrice] = useState<string>('')
	const [statuses, setStatuses] = useState<{ [key: string]: boolean }>({})
	const [excludeImageless, setExcludeImageless] = useState(false)

	const [orderBy, setOrderBy] = useState<string | undefined>()
	const [order, setOrder] = useState<ESOrder | undefined>()
	const [filters, setFilters] = useState<SearchTerm[]>([])

	const checkedStatuses = otherStatuses
		.map((s: any) => s.value)
		.filter((s: string) =>
			Object.entries(statuses)
				.filter(([key, val]) => val)
				.map(([key]) => key)
				.includes(s)
		)

	const [artistOpen, setArtistOpen] = React.useState(false)
	const [artistOptions, setArtistOptions] = React.useState([])
	const [loadArtist, setLoadArtist] = React.useState(false)
	const artistLoading = artistOpen && !!loadArtist

	const [curatedListModal, setCuratedListModal] = React.useState(false)

	useEffect(() => {
		let active = true

		if (artistLoading) {
			client
				.query({
					query: DYNAMIC_ARTISTS_SEARCH,
					variables: { 
						limit: 10,
						cursor: 0,
						field: 'last_name',
						direction: 'ASC',
						filters: {
							field: 'full_name',
							type: 'like',
							value: loadArtist,
							isOptional: false
						}
					},
				})
				.then((result) => {
					if (active) {
						setLoadArtist(false)
						setArtistOptions(result.data.searchDynamicArtists?.items)
					}
				})
		}
		return () => { active = false }
	}, [artistLoading, client, loadArtist])

	const [attempt, setAttempt] = useState(false)
	const [showResults, setShowResults] = useState(false)

	const isInvalid = (min: string, max: string) =>
		min !== '' && max !== '' && (Number(min) > Number(max))

	const isInvalidYear = (year: string) => {
		const trimmedYear = year.trim()
		return trimmedYear !== '' && trimmedYear.length !== 4
	}

	const switchToResults = useCallback(() => {

		const invalid = isInvalid(minHeight, maxHeight) ||
			isInvalid(minWidth, maxWidth) ||
			isInvalid(fromYear, toYear) ||
			isInvalid(minPrice, maxPrice) ||
			isInvalidYear(fromYear) ||
			isInvalidYear(toYear)

		setAttempt(true)
		if (invalid) return

		let innerFilters = []
		if (artists && artists.length) {
			const formattedArtists = artists.map(a =>
				typeof a === 'object' ?
					`${a.first_name} ${a.last_name}` :
					`${a}`
			).join(' OR ')

			innerFilters.push({
				field: "artist_full_name",
				type: "contains",
				value: formattedArtists,
				isOptional: false
			})
		}
		if (title && title !== '') {
			innerFilters.push({
				field: "title",
				type: "like",
				value: title,
				isOptional: false
			})
		}
		if (fromYear || toYear) {
			let years = [fromYear, toYear].filter(e => e).map(Number)
			let min = Math.min(...years)
			let max = Math.max(...years) + 1
			let yearRange = range(min, max)
			innerFilters.push({
				field: "year",
				type: "contains",
				value: yearRange.join(' OR '),
				isOptional: false
			})
		}

		if (minHeight && minHeight !== '') {
			let val = minHeight
			if (unit === 'in') {
				val = toMetric(minHeight, minHeightFrac)
			}
			innerFilters.push({
				field: "height_metric",
				type: "gte",
				value: val,
				isOptional: false
			})
		}

		if (maxHeight && maxHeight !== '') {
			let val = maxHeight
			if (unit === 'in') {
				val = toMetric(maxHeight, maxHeightFrac)
			}
			innerFilters.push({
				field: "height_metric",
				type: "lte",
				value: val,
				isOptional: false
			})
		}
		if (minWidth && minWidth !== '') {
			let val = minWidth
			if (unit === 'in') {
				val = toMetric(minWidth, minWidthFrac)
			}
			innerFilters.push({
				field: "width_metric",
				type: "gte",
				value: val,
				isOptional: false
			})
		}
		if (maxWidth && maxWidth !== '') {
			let val = maxWidth
			if (unit === 'in') {
				val = toMetric(maxWidth, maxWidthFrac)
			}
			innerFilters.push({
				field: "width_metric",
				type: "lte",
				value: val,
				isOptional: false
			})
		}


		if (minPrice && minPrice !== '') {
			innerFilters.push({
				field: "retail",
				type: "gte",
				value: minPrice,
				isOptional: false
			})
		}

		if (maxPrice && maxPrice !== '') {
			innerFilters.push({
				field: "retail",
				type: "lte",
				value: maxPrice,
				isOptional: false
			})
		}

		if (categories) {
			const formattedCategories = Object.entries(categories)
				.filter(([key, val]) => val)
				.map(([key, val]) => key)
				.join(' OR ')
			if (formattedCategories.length) {
				innerFilters.push({
					field: "art_categories",
					type: "contains",
					value: formattedCategories,
					isOptional: false
				})
			}
		}

		if (statuses) {
			const formattedStatuses = Object.entries(statuses)
				.filter(([key, val]) => val)
				.map(([key, val]) => key)
				.join(' OR ')
			if (formattedStatuses.length) {
				innerFilters.push({
					field: "art_status_value",
					type: "contains",
					value: formattedStatuses,
					isOptional: false
				})
			}
		}

		if (excludeImageless) {
			innerFilters.push({
				field: 'has_profile_image',
				type: 'eq',
				value: 'true',
				isOptional: false
			})
		}

		setFilters(innerFilters)
		setShowResults(true)
		setAttempt(false)
	}, [artists, categories, excludeImageless, fromYear, maxHeight, 
		maxHeightFrac, maxPrice, maxWidth, maxWidthFrac, minHeight, 
		minHeightFrac, minPrice, minWidth, minWidthFrac, statuses, title, 
		toYear, unit])

	const reset = () => {
		setArtists([])
		setTitle('')
		setCategories({})
		setFromYear('')
		setToYear('')
		setUnit('in')
		setMinHeight('')
		setMinHeightFrac('')
		setMaxHeight('')
		setMaxHeightFrac('')
		setMinWidth('')
		setMinWidthFrac('')
		setMaxWidth('')
		setMaxWidthFrac('')
		setMinPrice('')
		setMaxPrice('')
		setStatuses({})
		setExcludeImageless(false)
		setOrderBy('year')
		setOrder('ASC')
		setFilters([])
		setAttempt(false)
	}

	const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback((e) => {
		// @ts-ignore
		if (e.key !== 'Enter' || e.target.name === 'artist') return
		e.stopPropagation()
		switchToResults()
	}, [switchToResults])

	// Menu
	const [anchorEl, setAnchorEl] = React.useState(null)
	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return showResults ?
		// barebones tab bar similar to TabbedPage and TabBar elements
		<>
			<Paper
				style={{
					justifyContent: 'center',
					display: 'flex',
					zIndex: 2,
					// @ts-ignore
					backgroundColor: props.atPrivateObject ? '#6A6A72' : null
				}}
				className={clsx(classes.paperTab, {
					'private-object': props.atPrivateObject,
				})}
				elevation={3}
			>
				<Tabs
					className={clsx('Top-Tabs', 'fullWidth')}
					variant="standard"
				></Tabs>
				<Box sx={{
					display: 'flex',
					alignItems: 'center',
				}}>
					<ShowcaseToggle showcaseInformation={props.showcaseInformation} setShowcaseInformation={props.setShowcaseInformation}/>

					<IconButton
						onClick={e => handleClick(e)} 
						size="large"
					>
						<GridMoreVertIcon />
					</IconButton>
				</Box>
			</Paper>
			<div
				className={'main-page'}
			>
				<div
					className={'tabbed-content'}
				>
					<ShowcaseGridView
						query={AVAILABLE_ART_SEARCH}
						filters={filters}
						title=""
						order={order}
						orderBy={orderBy}
						showReserve={true}
						showRelevance={true}
						page="ShowcaseArtSearch"
						showBackButton
						onClose={() => setShowResults(false)}
						showcaseInformation={props.showcaseInformation}
						setShowcaseInformation={props.setShowcaseInformation}
						returnRoute='/showcase/art'

						showcaseSelectMode={props.showcaseSelectMode}
						setShowcaseSelectMode={props.setShowcaseSelectMode}
					/>
				</div>
			</div>

			<Menu
				PaperProps={{ style: { maxHeight: '100%' } }}
				id="top-tab-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				
				<MenuItem onClick={()=>{

					if (!props.showcaseSelectMode?.artIds?.length) {
						openSnackbar(severity.WARNING, "There is nothing selected.")
					}

					else setCuratedListModal(true)

					handleClose()
				}}>
					<ListItemIcon>
						<ViewCompactIcon style={{
							maxHeight: '24px',
							maxWidth: '24px',
							color: '#919191'
						}}/>
					</ListItemIcon>
					<ListItemText style={{paddingRight: '1em'}}>
						Create Curated List
					</ListItemText>
				</MenuItem>	
			</Menu>

			<CuratedListModal 
				open={curatedListModal}
				setOpen={setCuratedListModal}
				showcaseSelectMode={props.showcaseSelectMode}
				setShowcaseSelectMode={props.setShowcaseSelectMode}
			/>
		</>
		:
		<>
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: 'calc(100vh - 2em - 56px)',
					width: '50em',
					margin: '1em auto',
					padding: '1em 2em'
				}}
				onKeyDown={handleKeyDown}
			>
				<div style={{
					overflowY: 'scroll'
				}}>
					<h1 className="card-title">Search Art</h1>
					<form
						style={{
							display: 'grid',
							maxWidth: '42em',
							rowGap: '2em',
							gridTemplateColumns: '100%'
						}}
					>
						<div>
							<FormControl fullWidth>
								<FormLabel component="legend">Artist</FormLabel>


								<LimitedAutocomplete
									query={loadArtist}
									setQuery={setLoadArtist}
									freeSolo
									multiple
									filterSelectedOptions
									isOptionEqualToValue={(option: string, value: string) => {
										return option === value
									}}
									open={artistOpen && !!artistOptions?.length}
									onOpen={() => setArtistOpen(true)}
									onClose={() => setArtistOpen(false)}
									forcePopupIcon
									popupIcon={<ExpandMoreRounded />}
									getOptionLabel={(option: any) =>
										typeof option === 'object' ?
											option.first_name + ' ' + option.last_name :
											option
									}
									options={artistOptions}
									value={artists}
									onChange={(event: any, value: any) => {
										setArtists(value)
									}}
									renderInput={(params: any) => (
										<TextBoxForAutocomplete
											{...params}
											name="artist"
											id="artist-autocomplete"
											variant="outlined"
											fullWidth
											// style={{ paddingTop: '1.5em' }}
											classes={{ notchedOutline: null }}
											InputLabelProps={{ shrink: true }}
											InputProps={{
												...params.InputProps,
												endAdornment: <>
													{artistLoading &&
														<CircularProgress
															color="inherit"
															size={20}
														/>}
													{params.InputProps.endAdornment}
												</>
											}}
										/>
									)}
								/>
							</FormControl>
						</div>

						<div>
							<FormControl fullWidth>
								<FormLabel component="legend">Title</FormLabel>
								<TextBox
									name="title"
									value={title}
									// data-testid="query-bar"
									// inputRef={input => input && firstLoad && input.focus()}
									onChange={(event) => setTitle(event.target.value)}
								/>
							</FormControl>
						</div>

						<div>
							<FormLabel component="legend">Date</FormLabel>
							<div style={fromToStyles}>
								<FormControl fullWidth>
									<NumberFormat
										style={{ flexGrow: '2' }}
										error={attempt &&
											(isInvalid(fromYear, toYear) ||
												isInvalidYear(fromYear))
										}
										name="fromYear"
										step="1"
										value={fromYear}
										onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
											setFromYear(event.target.value)
										}
										customInput={TextBox}
										decimalSeparator={''}
										allowLeadingZeros={false}
										placeholder="YYYY"
										format="####"
									/>

								</FormControl>
								<span style={{ margin: '0 1em' }}> to </span>
								<FormControl fullWidth>
									<NumberFormat
										error={attempt &&
											(isInvalid(fromYear, toYear) ||
												isInvalidYear(toYear))
										}
										style={{ flexGrow: '2' }}
										name="toYear"
										step="1"
										value={toYear}
										onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setToYear(event.target.value)}
										customInput={TextBox}
										decimalSeparator={''}
										allowLeadingZeros={false}
										placeholder="YYYY"
										format="####"
									/>
								</FormControl>
							</div>
						</div>

						<div>
							<FormLabel component="legend">Dimensions <RadioGroup

								sx={{
									flexDirection: 'row',
									display: 'inline-block',
									marginLeft: '1em'
								}}
								value={unit}
								onChange={e => {
									setUnit(e.target.value)
									if (e.target.value === 'in') {
										maxHeight !== '' && setMaxHeight(Math.floor(Number(maxHeight)) + '')
										minHeight !== '' && setMinHeight(Math.floor(Number(minHeight)) + '')
										maxWidth !== '' && setMaxWidth(Math.floor(Number(maxWidth)) + '')
										minWidth !== '' && setMinWidth(Math.floor(Number(minWidth)) + '')
									}
								}}
							>
								<FormControlLabel
									value='in'
									control={<Radio />}
									label="in"
								/>
								<FormControlLabel
									value='cm'
									control={<Radio />}
									label="cm"
								/>
							</RadioGroup></FormLabel>
							<div style={{
								display: 'grid',
								gridTemplateColumns: '1fr 1em 1fr',
								columnGap: '1em',
								alignItems: 'baseline'
							}}>
								<div style={{ display: 'flex', alignItems: 'end' }}>
									<FormControl fullWidth>
										<Label
											disableAnimation
											shrink
										>Height</Label>

										<NumberFormat
											error={attempt && isInvalid(minHeight, maxHeight)}
											style={{ flexGrow: '2' }}
											name="minHeight"
											step="any"
											value={minHeight}
											onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setMinHeight(event.target.value)}
											customInput={TextBox}
											decimalSeparator={unit === 'cm' ? undefined : ''}
											allowLeadingZeros={true}
											decimalScale={unit === 'cm' ? 2 : undefined}
										/>
									</FormControl>
									{unit === 'in' && <Select
										style={{ marginLeft: '1em', flexBasis: '5em' }}
										input={<Dropdown />}
										name="minHeightFrac"
										IconComponent={ExpandMoreRounded}
										value={minHeightFrac}
										onChange={(e) => setMinHeightFrac(e.target.value)}
									>
										{selectionOptions.map((fraction) => (
											<MenuItem
												key={fraction.decimal}
												value={fraction.decimal}
											>
												{fraction.fraction}
											</MenuItem>
										))}
									</Select>}
								</div>
								<span> to </span>
								<div style={{ display: 'flex', alignItems: 'end' }}>

									<FormControl fullWidth>
										<NumberFormat
											error={attempt && isInvalid(minHeight, maxHeight)}
											style={{ flexGrow: '2' }}
											name="maxHeight"
											step="any"
											value={maxHeight}
											onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setMaxHeight(event.target.value)}
											customInput={TextBox}
											decimalSeparator={unit === 'cm' ? undefined : ''}
											allowLeadingZeros={true}
											decimalScale={unit === 'cm' ? 2 : undefined}
										/>
									</FormControl>
									{unit === 'in' && <Select
										style={{ marginLeft: '1em', flexBasis: '5em' }}
										input={<Dropdown />}
										name="maxHeightFrac"
										IconComponent={ExpandMoreRounded}
										value={maxHeightFrac}
										onChange={e => setMaxHeightFrac(e.target.value)}
									>
										{selectionOptions.map((fraction) => (
											<MenuItem
												key={fraction.decimal}
												value={fraction.decimal}
											>
												{fraction.fraction}
											</MenuItem>
										))}
									</Select>}
								</div>
								<div style={{ display: 'flex', alignItems: 'end' }}>

									<FormControl fullWidth>
										<Label
											error={attempt && isInvalid(minWidth, maxWidth)}
											disableAnimation
											shrink
										>Width</Label>
										<NumberFormat
											error={attempt && isInvalid(minWidth, maxWidth)}
											style={{ flexGrow: '2' }}
											name="minWidth"
											step="any"
											value={minWidth}
											onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
												setMinWidth(event.target.value)
											}
											customInput={TextBox}
											decimalSeparator={unit === 'cm' ? undefined : ''}
											allowLeadingZeros={true}
											decimalScale={unit === 'cm' ? 2 : undefined}
										/>
									</FormControl>
									{unit === 'in' && <Select
										style={{ marginLeft: '1em', flexBasis: '5em' }}
										input={<Dropdown />}
										name="minWidthFrac"
										IconComponent={ExpandMoreRounded}
										value={minWidthFrac}
										onChange={e => setMinWidthFrac(e.target.value)}
									>
										{selectionOptions.map((fraction) => (
											<MenuItem
												key={fraction.decimal}
												value={fraction.decimal}
											>
												{fraction.fraction}
											</MenuItem>
										))}
									</Select>}
								</div>
								<span> to </span>
								<div style={{ display: 'flex', alignItems: 'end' }}>

									<FormControl fullWidth>
										<NumberFormat
											error={attempt && isInvalid(minWidth, maxWidth)}
											style={{ flexGrow: '2' }}
											name="maxWidth"
											step="any"
											value={maxWidth}
											onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setMaxWidth(event.target.value)}
											customInput={TextBox}
											decimalSeparator={unit === 'cm' ? undefined : ''}
											allowLeadingZeros={true}
											decimalScale={unit === 'cm' ? 2 : undefined}
										/>
									</FormControl>
									{unit === 'in' && <Select
										style={{ marginLeft: '1em', flexBasis: '5em' }}
										input={<Dropdown />}
										name="maxWidthFrac"
										IconComponent={ExpandMoreRounded}
										value={maxWidthFrac}
										onChange={e => setMaxWidthFrac(e.target.value)}
									>
										{selectionOptions.map((fraction) => (
											<MenuItem
												key={fraction.decimal}
												value={fraction.decimal}
											>
												{fraction.fraction}
											</MenuItem>
										))}
									</Select>}
								</div>
							</div>
						</div>


						<div>
							<FormLabel component="legend">Retail Price</FormLabel>
							<div style={fromToStyles} >
								<FormControl fullWidth>
									<NumberFormat
										style={{ flexGrow: '2' }}
										error={attempt && isInvalid(minPrice, maxPrice)}
										name="retailFrom"
										step="any"
										value={minPrice}
										thousandSeparator={true}
										onValueChange={(value) => setMinPrice(value.value)}
										customInput={TextBox}
										allowLeadingZeros={true}
										decimalScale={2}
									/>

								</FormControl>
								<span style={{ margin: '0 1em' }}> to </span>
								<FormControl fullWidth>
									<NumberFormat
										error={attempt && isInvalid(minPrice, maxPrice)}
										style={{ flexGrow: '2' }}
										name="maxPrice"
										step="any"
										value={maxPrice}
										thousandSeparator={true}
										onValueChange={(value) => setMaxPrice(value.value)}
										customInput={TextBox}
										allowLeadingZeros={true}
										decimalScale={2}
									/>
								</FormControl>
							</div>
						</div>

						<div>
							<FormControl component="fieldset" variant="standard"
								sx={{ width: '100%' }}
							>
								<FormLabel component="legend">Category</FormLabel>
								<FormGroup style={{
									display: 'grid',
									gridAutoFlow: 'column',  /* or 'row', 'row dense', 'column dense' */
									gridTemplateRows: '1fr 1fr 1fr 1fr 1fr',
									gridTemplateColumns: 'repeat(3, minmax(0, 1fr))'

								}}>
									{sortedCategories.map(cat => <FormControlLabel
										key={cat.value}
										control={<Checkbox
											checked={categories[cat.value] || false}
											name={cat.value}
											onChange={(e) => {
												setCategories(oldCat => {
													oldCat[cat.value] = !oldCat[cat.value]
													return { ...oldCat }
												})
											}}
										/>}
										label={cat.value}
									/>)
									}
								</FormGroup>
								{/* <FormHelperText>Be careful</FormHelperText> */}
							</FormControl>
						</div>

						<div>
							<FormControl component="fieldset" 
								fullWidth
								variant="standard"
							>
								<FormLabel component="legend">Status</FormLabel>
								<FormGroup style={{
									display: 'grid',
									gridAutoFlow: 'column',  /* or 'row', 'row dense', 'column dense' */
									gridTemplateRows: '1fr'
								}}>
									{mainStatuses.map((s: any) => <FormControlLabel
										key={s.id}
										control={<Checkbox
											checked={statuses[s.value] || false}
											name={s.value}
											onChange={(e) => {
												setStatuses(oldStatuses => {
													oldStatuses[s.value] = !oldStatuses[s.value]
													return { ...oldStatuses }
												})
											}}
										/>}
										label={s.value}
									/>)}
								</FormGroup>
								<FormControl>
									<Label disableAnimation shrink>Additional Statuses</Label>
									<Select
										className="padded-select"
										multiple
										value={checkedStatuses}
										input={<Dropdown />}
										renderValue={(selected) => selected.join(', ')}
									>
										{otherStatuses?.map((status: any) => (
											<MenuItem key={status.value} value={status.value}
												onClick={(e) => {
													e.preventDefault()
													e.stopPropagation()
													setStatuses(oldStatuses => {
														oldStatuses[status.value] = !oldStatuses[status.value]
														return { ...oldStatuses }
													})
												}}
											>
												<FormControlLabel
													key={status.id}
													control={<Checkbox
														checked={statuses[status.value] || false}
														name={status.value}
													/>}
													label={status.value}
												/>
											</MenuItem>
										))}
									</Select>
								</FormControl>
								{/* <FormHelperText>Be careful</FormHelperText> */}
							</FormControl>
						</div>
					</form>
				</div>
				<div>
					<div style={{
						marginTop: '1em',
						borderTop: '1px solid #ced4da',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'baseline'
					}}>
						
						<ShowcaseSortOrder
							order={order}
							orderBy={orderBy}
							onOrderChange={setOrder}
							onOrderByChange={setOrderBy}
							showRelevance={true}
						/>
						<FormControlLabel
							sx={{ alignSelf: 'end' }}
							control={<Checkbox
								checked={excludeImageless}
								name={'hasProfileImage'}
								onChange={(e) => setExcludeImageless(old => !old)}
							/>}
							label={'Exclude results with no image'}
						/>

						<div style={{ flexGrow: '2' }}></div>
						<ResetButton
							style={{ marginRight: '1em' }}
							data-testid="reset-button"
							variant="contained"
							size="small"
							onClick={reset}
						>
							Reset
						</ResetButton>
						<SearchButton
							style={{ backgroundColor: '#4465D1' }}
							variant="contained"
							size="small"
							type="button"
							onClick={switchToResults}
						>
							Search
						</SearchButton>
					</div>
					
				</div>
			</Paper>
		</>
}
