/* eslint-disable eqeqeq */
import React, { useContext } from 'react'
import { TableContainer, Table, TableRow, TableCell, TableBody, Skeleton } from '@mui/material'
import Thumbnail from '../../Thumbnail/Thumbnail'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import EnhancedTableHead from '../../table/EnhancedTableHead'
import { formatDate, getArtistThumbnailDetail } from '../../common/helpers'
import { DispatchContext, LookupContext } from '../../store'
import Tooltip from '@mui/material/Tooltip'
import {getMyActiveDealsStatus} from '../../common/helpers'
import { severity } from '../../Snackbar/CustomizedSnackbar'

function Deals(props) {

	const headCells = [
		{ id: 'artwork', noSort: true, numeric: false, disablePadding: false, label: 'Artwork' },
		{ id: 'dealtype', noSort: true, numeric: false, disablePadding: false, label: 'Deal Type' },
		{ id: 'date', noSort: true, numeric: false, disablePadding: false, label: 'Date' },
		{ id: 'offerPrice', noSort: true, numeric: false, disablePadding: false, label: 'Offer Price' },
		{ id: 'status', noSort: true, numeric: false, disablePadding: false, label: 'Deal Status' },
		{ id: 'action', noSort: true, numeric: false, disablePadding: false, label: 'Actions' },
	]

	// Menu
	const [anchorEl, setAnchorEl] = React.useState(null)
	
	const lookup = useContext(LookupContext)
	const dealTypes = lookup.data?.getDealTypes ?? []
	
	const [currentDeal, setCurrentDeal] = React.useState(null)

	const handleClick = (event, deal) => {
		setAnchorEl(event.currentTarget)
		setCurrentDeal(deal)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({type: 'openSnackBar', payload: {severity, text}})
	}
	
	return <>
		<div>
			<TableContainer style={{paddingBottom: '2em' }}>
				<Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
					<EnhancedTableHead headCells={headCells} rowCount={props?.currentDeals?.length} />
					<TableBody>
						{!props.loading && props.currentDeals.length === 0 ? (
							<TableRow>
								<TableCell></TableCell>
								<TableCell style={{ padding: '1em' }}>No Deals Found.</TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
							</TableRow>
						) : null}

						{(!props.loading ? props.currentDeals : [{ id: 1, disabled: true }]).map((row, index) => {
							if (row && row.hidden === true) return null

							const hidden = row.art?.is_private && !row.art?.created_at

							return (
								<TableRow key={index} >
									<TableCell
										className="home-page-deallist"
										component="th"
										scope="row"
										data-testid="deal-thumbnail"
										style={{ width: '15em' }}
									>
										<Thumbnail
											formatted_name={row.art?.formatted_title}
											name={row.art?.code_name}
											artist={getArtistThumbnailDetail(
												row.art
											)}
											date={row.art?.year}
											style={{ paddingLeft: '1em' }}
											avatar={row.art?.imgUrl}
											type="art"
											animation={
												props.loading
													? 'wave'
													: false
											}
											largeText	
											hasAccess={!hidden}
										></Thumbnail>
									</TableCell>
                                    
									<TableCell>
										<div style={{ maxHeight: '10em', overflow: 'auto' }}>

											{row?.deal?.type_id ? dealTypes.find(el => el.id == row?.deal?.type_id)?.value :
												props.loading ?
													<Skeleton
														animation={!row.listing ? "wave" : false}
														variant="text"
													/> : <span>-</span>
											}

										</div>
									</TableCell>
									<TableCell>
										<div
											style={{
												maxHeight: '10em',
												overflow: 'auto',
											}}
										>

											{row?.start_at ? formatDate(row.start_at, "MMMM do, yyyy") :
												props.loading ?
													<Skeleton
														animation={!row.listing ? "wave" : false}
														variant="text"
													/> : <span>-</span>
											}

										</div>
									</TableCell>

									<TableCell>
										<div style={{ maxHeight: '10em', overflow: 'auto' }}>

											{row?.offer_currency?.symbol ? <span>{`${row?.offer_currency.symbol} ${row?.offer_amount}`}</span> :
												props.loading ?
													<Skeleton
														animation={!row.listing ? "wave" : false}
														variant="text"
													/> : <span>-</span>
											}

										</div>
									</TableCell>
									<Tooltip title={row.status_note || "No status note"} aria-label="note" arrow>
										<TableCell>
											<div style={{ maxHeight: '10em', overflow: 'auto' }}>
												{ getMyActiveDealsStatus(row, props.loading) }
											</div>
										</TableCell>
									</Tooltip>

									<TableCell>
										{!row?.disabled ? (
											<IconButton
												aria-label="More"
												style={{ padding: '6px', marginRight: '-9px' }}
												onClick={(e) => {
													e.preventDefault()
													e.stopPropagation()
													handleClick(e, row)
												}}
												size="large">
												<MoreHorizIcon />
											</IconButton>
										) : null}
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>

			<>
				<Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
					<MenuItem
						onClick={(e) => {
							const id = currentDeal.deal.id || currentDeal.deal_id

							if ((currentDeal.deal?.is_private || currentDeal.deal?.is_gallery_private) && !currentDeal.deal?.created_at) {
								openSnackbar(severity.ERROR, "This deal was made private.")
								return
							}

							props.saveAndGo(`/deals/${id}/details`, currentDeal)
						}}
					>
                        Go to Deal
					</MenuItem>
					<MenuItem
						onClick={(e) => {

							if (currentDeal.art?.is_private && !currentDeal.art?.created_at) {
								openSnackbar(severity.ERROR, "This art was made private.")
								return
							}

							props.saveAndGo(`/art/${currentDeal.art.id}/details`, currentDeal)
						}}
					>
                        Go to Art
					</MenuItem>
				</Menu>
			</>
		</div>
	</>
}

export default Deals
