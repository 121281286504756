import { gql } from '@apollo/client'

export const GET_USERS_WITH_ACCESS = gql`
	query getUsersWithAccess(
		$objectId: ID!, 
		$objectTypeId: ID!
		$thumbnailResolution: String = "128x128"
	) {
		getUsersWithAccess(
			objectId: $objectId, 
			objectTypeId: $objectTypeId
		) {
			code
			success
			message
			users {
				id
				first_name
				last_name
				email
				department_id
				title
				prefix_id
				suffix
				gallery_id
				department
				is_deleted
				gallery
				imgUrl (thumbnailResolution: $thumbnailResolution)
				permissions {
					permission_id
					permission_value_id
				}
				assistants {
					id
					first_name
					last_name
					gallery
					imgUrl (thumbnailResolution: $thumbnailResolution)
					permissions {
						permission_id
						permission_value_id
					}
				}
				
			}
		}
	}
`

export const GET_USERS_WITH_PUBLIC_ACCESS = gql`
	query getUsersWithPublicAccess(
		$objectId: ID!, 
		$objectTypeId: ID!
		$thumbnailResolution: String = "128x128"
	) {
		getUsersWithPublicAccess(
			objectId: $objectId, 
			objectTypeId: $objectTypeId
		) {
			code
			success
			message
			users {
				id
				first_name
				last_name
				email
				department_id
				title
				prefix_id
				suffix
				gallery_id
				department
				is_deleted
				gallery
				imgUrl (thumbnailResolution: $thumbnailResolution)
				permissions {
					permission_id
					permission_value_id
				}
				assistants {
					id
					first_name
					last_name
					gallery
					imgUrl (thumbnailResolution: $thumbnailResolution)
					permissions {
						permission_id
						permission_value_id
					}
				}
				
			}
		}
	}
`

export const GET_USERS_WITH_PUBLIC_ACCESS_PERMISSION = gql`
	query getUsersWithPublicAccessPermission(
		$objectTypeId: ID!
		$thumbnailResolution: String = "128x128"
	) {
		getUsersWithPublicAccessPermission(
			objectTypeId: $objectTypeId
		) {
			code
			success
			message
			users {
				id
				first_name
				last_name
				email
				department_id
				title
				prefix_id
				suffix
				gallery_id
				department
				is_deleted
				gallery
				imgUrl (thumbnailResolution: $thumbnailResolution)
				permissions {
					permission_id
					permission_value_id
				}
				assistants {
					id
					first_name
					last_name
					gallery
					imgUrl (thumbnailResolution: $thumbnailResolution)
					permissions {
						permission_id
						permission_value_id
					}
				}
			}
		}
	}
`

export const GET_DEAL_GALLERY_ACCESS_AND_USERS = gql`
	query getDealGalleriesAndUsers(
		$id: ID!
		$thumbnailResolution: String = "128x128"
	) {
		getDealGalleriesAndUsers(id: $id) {
			code
			success
			message
			galleriesAndUsers {
				gallery {
					id
					value
				}
				users {
					id
					first_name
					last_name
					email
					department_id
					title
					prefix_id
					suffix
					gallery_id
					department
					is_deleted
					gallery
					imgUrl (thumbnailResolution: $thumbnailResolution)
					permissions {
						permission_id
						permission_value_id
					}
					assistants {
						id
						first_name
						last_name
						gallery
						imgUrl (thumbnailResolution: $thumbnailResolution)
						permissions {
							permission_id
							permission_value_id
						}
					}
					
				}
			}
		}
	}
`

export const GET_ART_GALLERY_ACCESS_AND_USERS = gql`
	query getArtGalleriesAndUsers(
		$id: ID!
		$thumbnailResolution: String = "128x128"
	) {
		getArtGalleriesAndUsers(id: $id) {
			code
			success
			message
			galleriesAndUsers {
				gallery {
					id
					value
				}
				users {
					id
					first_name
					last_name
					email
					department_id
					title
					prefix_id
					suffix
					gallery_id
					department
					is_deleted
					gallery
					imgUrl (thumbnailResolution: $thumbnailResolution)
					permissions {
						permission_id
						permission_value_id
					}
					assistants {
						id
						first_name
						last_name
						gallery
						imgUrl (thumbnailResolution: $thumbnailResolution)
						permissions {
							permission_id
							permission_value_id
						}
					}
					
				}
			}
		}
	}
`
