
import { CircularProgress, IconButton, InputBase } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

const Search = (props) => {
  const { className, label, loading, name, onChange, value } = props || {}

  const inputBaseProps = {
    name,
    className,
    classes: { input: 'p-[12px] rounded focus:ring-0', root: 'p-0' },
    onChange,
    value,
  }

  return (
    <div className="flex flex-col justify-end">
      <div className='text-sm'>
        { label }
      </div>
      <div className="flex rounded border border-inputBorder">
        <InputBase {...inputBaseProps} />
        { 
          loading ? <CircularProgress color="inherit" size={20} /> :
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        }
      </div>
      {/* <Autocomplete
        className={className}
        freeSolo
        inputValue={value}
        options={[]}
        onInputChange={(e) => console.log('onInputChange',e)}
        popupIcon={<SearchIcon />}
        renderInput={(params) => (
          
        )}
      /> */}
    </div>
  )
}

export default Search

