/* eslint-disable eqeqeq */
import React, { useEffect } from 'react'
import ReactQuill from 'react-quill'
import { quillToolbar } from '../constants/values'
import { CancelButton, SubmitButton } from '../input/Buttons'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { DispatchContext } from '../store'

const ListingRelationNotes = ({open, close, row, updateRelation, isPrivate, setRows, rows, responseName, entity}) => {


	const [currentNote, setCurrentNote] = React.useState(row.note)

	const [quillText, setQuillText] = React.useState(null)
	useEffect(() => {
		if (quillText && quillText.editor) {
			const editor = quillText.getEditor()
			editor.focus()
			editor.setSelection(editor.getLength(), 0)
		}
	}, [quillText])

	//Snackbar
	const dispatch = React.useContext(DispatchContext)
	const openSnackbar = React.useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

		
	return (
		<TransitionsModal
			className="title-modal"
			open={open}
			close={close}
		>
			<div className={isPrivate ? 'private-object' : null} style={{
				maxWidth: '50vw'
			}}>
				<h1 className="card-title" style={{
					marginBottom: '1em'
				}}> Listing {entity} Note </h1>

				<ReactQuill
					className="modal"
					theme="snow"
					ref={(el) => {
						if (el) setQuillText(el)
					}}
					value={currentNote || { ops: [] }}
					style={{ minWidth: '30em', color: isPrivate ? 'white' : null }}
					modules={quillToolbar}
					onChange={(content, delta, source, editor) => {
						setCurrentNote(editor.getContents())
					}}
				/>

				<>
					<CancelButton
						variant="contained"
						style={{ float: 'left', marginTop: '2em' }}
						onClick={(e) => close()}
					>
							Cancel
					</CancelButton>

					<SubmitButton
						style={{ float: 'right', marginTop: '2em' }}
						variant="contained"
						onClick={(e) => {

							const variables = {
								id: row.id,
								note: currentNote,
							}

							// Send to server
							updateRelation({
								variables
							}).then(response => {

								if (response && !response.data[responseName].id) {
									openSnackbar(severity.ERROR, "There was an error updating this note.")
								} else {

									openSnackbar(severity.SUCCESS, "Successfully updated note.")
									setRows(rows.map(row => {
										if (row.id == response.data[responseName].id)
											return response.data[responseName]
										else return row
									}))
								}

								close()

							}).catch(error => {

								console.log(error)
								openSnackbar(severity.ERROR, "There was an error updating this note.")
							})
						}}
					>
							Submit
					</SubmitButton>

				</>
			</div>
		</TransitionsModal>
	)
}

export default ListingRelationNotes
