import { gql } from '@apollo/client'
import { fileData, userDataCompressed } from '../common/fragments'

export const CREATE_ARTIST = gql`
	mutation createArtist($CreateArtistInput: CreateArtistInput) {
		createArtist(input: $CreateArtistInput) {
			code
			message
			success
			artist {
				id
				first_name
				last_name
				dynasty
				birthplace
				nationality
				type_id
				dates
				profile_link
			}
		}
	}
`

export const GET_ARTIST_QV_DETAILS = gql`
	query getArtist(
		$id: ID!
		$thumbnailResolution: String = "128x128"
		$qvResolution: String = "1024x1024"
		) {
		getArtist(id: $id) {
			id
			first_name
			last_name
			nationality
			dates
			artist_initials
			genre {
				id
				artist_genre_id
				artist_genre
				ordinal
			}
			profile_link
			imgUrl (thumbnailResolution: $qvResolution)
			available_art {
				id
				title
				year
				is_private
				code_name
				created_at
				is_active
				status_id
				imgUrl (thumbnailResolution: $thumbnailResolution)
				formatted_title 
				artist {
					id
					first_name
					last_name
				}
			}
			salesperson {
				...userDataCompressed
				is_lead
			}
			created_at
			is_affiliated
		}
		getCriticalArtistNotes(input: { artist_id: $id }) {
			id
			type_id
			applied_at
			applied_user_id
			note_text
			is_critical
			is_private
			is_deleted
			user {
				...userDataCompressed
				department
			}
		}
	}
	${userDataCompressed}
`

export const GET_ARTIST_DETAILS = gql`
	query getArtist(
		$id: ID! 
		$thumbnailResolution: String = "128x128"
		$qvResolution: String = "1024x1024"
	) {
		getArtist(id: $id) {

			created_by
			created_at
			modified_by
			modified_at
			id

			is_deleted
			first_name
			last_name
			dynasty
			birthplace
			nationality
			copyright
			genre {
				id
				artist_genre_id
				artist_genre
				ordinal
			}
			dates
			profile_link
			imgUrl (thumbnailResolution: $qvResolution)
			profile_link
			artist_initials
			about
			isFavorite
			isFlagged
			created_user {
				first_name
				last_name
			}
			modified_user {
				first_name
				last_name
			}
			tags {
				id
				label
				description
				color_hex
			}
			education {
				id
				artist_id
				artist_research_type_id
				artist_research_type {
					id
					value
				}
				content
				formatted_content
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
			}
			selected_awards {
				id
				artist_id
				artist_research_type_id
				artist_research_type {
					id
					value
				}
				content
				formatted_content
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
			}
			selected_solo_exhibitions {
				id
				artist_id
				artist_research_type_id
				artist_research_type {
					id
					value
				}
				content
				formatted_content
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
			}
			selected_monographs {
				id
				artist_id
				artist_research_type_id
				artist_research_type {
					id
					value
				}
				content
				formatted_content
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
			}
			salesperson {
				...userDataCompressed
				is_lead
				is_deleted
			}
			available_art {
				id
				title
				year
				is_active
				status_id
				imgUrl (thumbnailResolution: $thumbnailResolution)
				is_private
				created_at
				code_name
				formatted_title 
				artist {
					id
					first_name
					last_name
				}
			}
			is_affiliated
		}
		getCriticalArtistNotes(input: { artist_id: $id }) {
			id
			type_id
			applied_at
			applied_user_id
			note_text
			is_critical
			is_private
			is_deleted
			user {
				...userDataCompressed
				department
			}
		}
		getArtistCount
	}
	${userDataCompressed}
`

export const UPDATE_ARTIST = gql`
	mutation updateArtist(
		$ArtistInput: ArtistInput!
		$thumbnailResolution: String = "128x128"
		$qvResolution: String = "1024x1024"
	) {
		updateArtist(input: $ArtistInput) {
			code
			message
			success
			severity
			artist {
				id
				created_at
				is_deleted
				first_name
				last_name
				dynasty
				birthplace
				nationality
				copyright
				genre {
					id
					artist_genre_id
					artist_genre
					ordinal
				}
				dates
				artist_initials
				imgUrl (thumbnailResolution: $qvResolution)
				profile_link
				isFavorite
				tags {
					id
					label
					description
					color_hex
				}
				about
				salesperson {
					...userDataCompressed
					is_lead
					is_deleted
				}
				education {
					id
					artist_id
					artist_research_type_id
					artist_research_type {
						id
						value
					}
					content
					formatted_content
					created_by
					created_at
					modified_by
					modified_at
					is_deleted
				}
				selected_awards {
					id
					artist_id
					artist_research_type_id
					artist_research_type {
						id
						value
					}
					content
					formatted_content
					created_by
					created_at
					modified_by
					modified_at
					is_deleted
				}
				selected_solo_exhibitions {
					id
					artist_id
					artist_research_type_id
					artist_research_type {
						id
						value
					}
					content
					formatted_content
					created_by
					created_at
					modified_by
					modified_at
					is_deleted
				}
				selected_monographs {
					id
					artist_id
					artist_research_type_id
					artist_research_type {
						id
						value
					}
					content
					formatted_content
					created_by
					created_at
					modified_by
					modified_at
					is_deleted
				}
				available_art {
					id
					title
					year
					is_active
					status_id
					imgUrl (thumbnailResolution: $thumbnailResolution)
					is_private
					created_at
					code_name
					formatted_title 
					artist {
						id
						first_name
						last_name
					}
				}
				is_affiliated
			}
		}
	}
	${userDataCompressed}
`

export const SEARCH_ARTISTS = gql`
	query searchArtists(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$nationality: String
		$specialFilter: String
		$searchString: String
		$isFavorite: Boolean
		$tagId: Int
		$limit: Int
		$salesperson_ids: [ID]
		$thumbnailResolution: String = "128x128"
	) {
		searchArtists(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: {
					tagId: $tagId
					searchString: $searchString
					nationality: $nationality
					specialFilter: $specialFilter
					isFavorite: $isFavorite
					salesperson_ids: $salesperson_ids
				}
				thumbnailResolution: $thumbnailResolution
			}
		) {
			items {
				id
				first_name
				last_name
				dynasty
				artist_initials
				dates
				birthplace
				nationality
				artist_initials
				profile_link
				about
				created_at
				modified_at
				genre {
					id
					artist_genre_id
					artist_genre
					ordinal
				}
				imgUrl (thumbnailResolution: $thumbnailResolution)
				tags {
					label
					description
					color_hex
				}
				notes {
					type_id
					applied_at
					applied_user_id
				}
				isFavorite
				isFlagged
				is_affiliated
			}
			cursor
			totalItems
		}
	}
`

export const GET_ARTIST_DATALOG = gql`
	query getArtistDataLog(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$id: ID!
		$date: String
		$items: [String]
		$item_ids: [ID]
		$actions: [Item]
		$user_ids: [ID]
		$thumbnailResolution: String = "128x128"
	) {
		getArtistDataLog(
			input: {
				field: $field
				direction: $direction
				limit: 10
				cursor: $cursor
				filters: {
					id: $id
					date: $date
					items: $items
					item_ids: $item_ids
					actions: $actions
					user_ids: $user_ids
				}
			}
		) {
			cursor
			totalItems
			items {
				id
				artist_id
				item
				item_id
				action
				diff
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				user {
					...userDataCompressed
					title
				}
			}
		}
	}
	${userDataCompressed}
`

export const UPDATE_ARTIST_FILES = gql`
	mutation updateArtistFiles(
		$UpdateFileInput: UpdateFileInput!
		$thumbnailResolution: String = "128x128"
	
	) {
		updateArtistFiles(input: $UpdateFileInput) {
			code
			success
			message
			files {
				...fileData
			}
		}
	}
	${fileData}
`

export const DELETE_ARTIST = gql`
	mutation deleteArtist($id: ID!) {
		deleteArtist(id: $id) {
			code
			success
			message
		}
	}
`
