import React, { useContext } from 'react'
import { Paper, FormControl, Select, MenuItem } from '@mui/material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { useRecoilState } from 'recoil'
import Dropdown from '../input/Dropdown/Dropdown'
import Label from '../input/Label'
import TextBox from '../input/Text/TextBox'
import { findModeFieldSelector, FindModeInput } from '../navigation/Tabs/TabbedPage'
import { LookupContext } from '../store'
import { noteTypeComparator } from '../common/helpers'



/**
 * @typedef {Object} NoteSearchProps
 * @property {'contact' | 'art'} type
 */

/**
 * @param {NoteSearchProps} props 
 * @returns 
 */
const NoteSearch = (props) => {

	const lookup = useContext(LookupContext)
	const noteTypes = lookup.data?.getNoteTypes || []

	const [notes, setNotes] = useRecoilState(findModeFieldSelector('compound_notes'))

	const handleChange = (e) => {
		const { name, value } = e.target
		let newNote = { ...notes }
		if (value === '') {
			delete newNote[name]
		} else {
			newNote[name] = value
		}
		setNotes(newNote)	
	}
	
	return (
		<div className="page-row" style={{ maxHeight: '22em' }}>
			<Paper 
				className="qv-margin" 
				id="location-search" 
			>
				<h1 className="card-title">
					<span>Notes</span>
				</h1>

				<div style={{ display: 'flex', flexDirection: 'column '}}>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<FormControl> 
							<Label
								focused={false}
								disableAnimation
								shrink
							>
								Note Type
							</Label>

							<Select
								style={{
									width: '20em',
									marginBottom: '1em'
								}}
								displayEmpty
								input={<Dropdown />}
								name="type"
								
								className="padded-select"
								value={notes?.type || ''}
								IconComponent={	ExpandMoreRoundedIcon }
								onChange={handleChange}
							>
								<MenuItem key="nt-any" value=''> Any Type </MenuItem>
								{noteTypes?.sort( noteTypeComparator )?.map(
									(noteType, index) => (
										<MenuItem
											key={`nt-${index}`}
											value={noteType.value}
										>
											{noteType.value}
										</MenuItem>
									)
								)}
							</Select>
						</FormControl>
						{props.type === 'contact' && <FormControl> 
							<Label focused={false} disableAnimation shrink >
								Note Dates
							</Label>
							<FindModeInput field="note_dates" type="date-picker"/>
						</FormControl>}
					</div>
					
					<FormControl style={{
						flexGrow: 1,
						alignSelf: 'stretch'
					}}>
						<TextBox
							name="text"
							multiline
							rows="4"
							value={ notes?.text || ''}
							placeholder={'-'}
							onChange={handleChange}
						/>
					</FormControl>
				</div>
			</Paper>
		</div>
	)
}

export default NoteSearch
