/* eslint-disable eqeqeq */
import { CircularProgress, FormControl } from '@mui/material'
import React, { useCallback, useContext, useEffect } from 'react'
import { 
	SIMPLE_SEARCH_LOCATION,
	SIMPLE_SEARCH_SUB_LOCATION,
	SIMPLE_SEARCH_VIA,
} from '../Art/ArtPiece/Operations/Queries'
import { CancelButton, SubmitButton } from '../input/Buttons'
import Label from '../input/Label'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { autoCompleteStyles } from '../styles/makesStyles'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { MobileDatePicker } from '@mui/x-date-pickers-pro'
import { withApollo } from '@apollo/client/react/hoc'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { DispatchContext } from '../store'
import { useMutation } from '@apollo/client'
import { UPDATE_LOCATION_HISTORIES } from './Queries'
import LimitedAutocomplete from '../common/LimitedAutocomplete'
import TextBoxForAutocomplete from '../input/Text/TextBoxForAutocomplete'
import TextBoxForDatePicker from '../input/Text/TextBoxForDatePicker'

const LocationModal = (props) => {
	const { open, close, rows } = props

	const classes = autoCompleteStyles()
	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const [locationOpen, setLocationOpen] = React.useState(false)
	const [locationOptions, setLocationOptions] = React.useState([])
	const [loadLocation, setLoadLocation] = React.useState(false)
	const locationLoading = locationOpen && !!loadLocation
	const [locationValue, setLocationValue] = React.useState(null)

	const [subLocationOpen, setSubLocationOpen] = React.useState(false)
	const [subLocationOptions, setSubLocationOptions] = React.useState([])
	const [loadSubLocation, setLoadSubLocation] = React.useState(false)
	const subLocationLoading = subLocationOpen && !!loadSubLocation
	const [subLocationValue, setSubLocationValue] = React.useState(null)

	const [viaOpen, setViaOpen] = React.useState(false)
	const [viaOptions, setViaOptions] = React.useState([])
	const [loadVia, setLoadVia] = React.useState(false)
	const viaLoading = viaOpen && !!loadVia
	const [viaValue, setViaValue] = React.useState(null)

	const [dateValue, setDateValue] = React.useState(new Date())

	const [attempt, setAttempt] = React.useState(false)

	const [loading, setLoading] = React.useState(false)

	useEffect(() => {
		if (loading) openSnackbar(severity.INFO, "Loading")
	}, [loading, openSnackbar])


	React.useEffect(() => {
		let active = true

		if (!locationLoading) {
			return undefined
		}

		props.client
			.query({
				query: SIMPLE_SEARCH_LOCATION,
				variables: { query: loadLocation },
			})
			.then((result) => {
				if (active) {
					setLoadLocation(false)
					setLocationOptions(result.data?.simpleSearchLocation || [])
				}
			})


		return () => {
			active = false
		}
	}, [loadLocation, locationLoading, props.client])

	React.useEffect(() => {
		let active = true

		if (!subLocationLoading) {
			return undefined
		}

		props.client
			.query({
				query: SIMPLE_SEARCH_SUB_LOCATION,
				variables: { query: loadSubLocation },
			})
			.then((result) => {
				if (active) {
					setLoadSubLocation(false)
					setSubLocationOptions(result.data?.simpleSearchSubLocation || [])
				}
			})


		return () => {
			active = false
		}
	}, [loadSubLocation, subLocationLoading, props.client])


	React.useEffect(() => {
		let active = true

		if (!viaLoading) {
			return undefined
		}


		props.client
			.query({
				query: SIMPLE_SEARCH_VIA,
				variables: { query: loadVia },
			})
			.then((result) => {
				if (active) {
					setLoadVia(false)
					setViaOptions(result.data?.simpleSearchVia || [])
				}
			})


		return () => {
			active = false
		}
	}, [loadVia, viaLoading, props.client])

	const [updateLocationHistories, {loading: mutationLoading}] = useMutation(UPDATE_LOCATION_HISTORIES)

	const handleClose = () => {
		setLocationValue(null)
		setSubLocationValue(null)
		setViaValue(null)
		setDateValue(new Date())
		setLoading(false)
		setAttempt(false)
		close()
	}

	const handleSubmit = (input) => {

		setLoading(true)

		updateLocationHistories({
			variables: {
				LocationHistoriesInput: input,
			},
		}).then((response) => {
			
			if (response.data?.updateLocationHistories?.success) {
				openSnackbar(severity.SUCCESS, response.data.updateLocationHistories.message)
			}

			else if (!response.data?.updateLocationHistories && response.errors?.[0]?.message?.includes("Create and edit")) {
				openSnackbar(severity.ERROR, "Error: Requires Create and Edit Art Privileges.")
			}

			else {
				openSnackbar(severity.ERROR, response.data.updateLocationHistories.message)
			}

			setLoading(false)
			handleClose()
			
		}).catch( (error) => {

			console.log(error)
			openSnackbar(
				severity.ERROR,
				error
			)

			setLoading(false)
		} )
	}

	return (
		<TransitionsModal className="location-modal" open={open} close={() => handleClose()}>
			<div style={{ width: '30em' }}>
				<h2
					className="card-title"
					style={{
						paddingBottom: '0.3',
						margin: 0,
					}}
				>
					Add Location
				</h2>

				<FormControl className="fullWidth" error={attempt && !locationValue}>
					<Label id="location-label" style={{ fontWeight: 500 }} disableAnimation shrink>
						Location*
					</Label>
					<LimitedAutocomplete

						query={loadLocation}
						setQuery={setLoadLocation}

						id="location-autocomplete"
						classes={classes}
						open={locationOpen}
						onOpen={() => {
							setLocationOpen(true)
						}}
						onClose={() => {
							setLocationOpen(false)
						}}
						style={{ marginTop: '1.75em' }}
						isOptionEqualToValue={(option, value) => option.id == value.id}
						getOptionLabel={(option) => option?.name || option || ''}
						options={locationOptions}
						value={locationValue}
						forcePopupIcon
						autoSelect={true}
						autoHighlight={true}
						filterSelectedOptions
						popupIcon={<ExpandMoreRoundedIcon />}
						loading={locationLoading}
						onChange={(event, value) => {
							setLocationValue(value)
						}}
						renderInput={(params) => (
							<TextBoxForAutocomplete
								{...params}
								variant="outlined"
								error={attempt && !locationValue}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{locationLoading ? <CircularProgress color="inherit" size={20} /> : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
				</FormControl>

				<FormControl className="fullWidth">
					<Label id="sub-location-label" style={{ fontWeight: 500 }} disableAnimation shrink>
						Sub-Location
					</Label>
					<LimitedAutocomplete

						query={loadSubLocation}
						setQuery={setLoadSubLocation}
						

						id="sub-location-autocomplete"
						classes={classes}
						open={subLocationOpen}
						onOpen={() => {
							setSubLocationOpen(true)
						}}
						onClose={() => {
							setSubLocationOpen(false)
						}}
						style={{ marginTop: '1.75em' }}
						isOptionEqualToValue={(option, value) => option.id == value.id}
						getOptionLabel={(option) => option.name || option}
						options={subLocationOptions}
						value={subLocationValue}
						forcePopupIcon
						autoSelect={true}
						autoHighlight={true}
						filterSelectedOptions
						popupIcon={<ExpandMoreRoundedIcon />}
						loading={subLocationLoading}
						onChange={(event, value) => {
							setSubLocationValue(value)
						}}
						renderInput={(params) => (
							<TextBoxForAutocomplete
								{...params}
								variant="outlined"
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{subLocationLoading ? <CircularProgress color="inherit" size={20} /> : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
				</FormControl>

				<FormControl className="fullWidth">
					<Label id="via-label" style={{ fontWeight: 500 }} disableAnimation shrink>
						Via
					</Label>
					<LimitedAutocomplete

						query={loadVia}
						setQuery={setLoadVia}

						id="via-autocomplete"
						classes={classes}
						open={viaOpen}
						onOpen={() => {
							setViaOpen(true)
						}}
						onClose={() => {
							setViaOpen(false)
						}}
						style={{ marginTop: '1.75em' }}
						isOptionEqualToValue={(option, value) => option.id == value.id}
						getOptionLabel={(option) => option.name || option}
						options={viaOptions}
						value={viaValue}
						forcePopupIcon
						autoSelect={true}
						autoHighlight={true}
						filterSelectedOptions
						popupIcon={<ExpandMoreRoundedIcon />}
						loading={viaLoading}
						onChange={(event, value) => {
							setViaValue(value)
						}}
						renderInput={(params) => (
							<TextBoxForAutocomplete
								{...params}
								variant="outlined"
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{viaLoading ? <CircularProgress color="inherit" size={20} /> : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
				</FormControl>

				<FormControl className="fullWidth">
					<Label
						id="date-label"
						style={{
							fontWeight: 500,
						}}
						disableAnimation
						shrink
					>
						Date
					</Label>

					<MobileDatePicker
						inputFormat="MMM do, yyyy"
						disableMaskedInput={true}
						cancelText={null}
						name="date"
						inputVariant="outlined"
						style={{ marginTop: '17px' }}
						className="MUIDatePicker"
						variant="dialog"
						showTodayButton
						todayLabel="Today"
						value={dateValue}
						renderInput={({ inputRef, inputProps, InputProps }) => {
								
							const newProps = { ...inputProps}

							newProps.readOnly = false

							return (
								<TextBoxForDatePicker ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps}/>
							)}}
						onChange={(date) => {
							setDateValue(date)
						}}
					/>
				</FormControl>

				<div
					style={{
						width: '100%',
						paddingBottom: '1em',
						marginTop: '1.5em',
					}}
				>
					<CancelButton variant="contained" style={{ float: 'left' }} onClick={() => handleClose()}>
						Cancel
					</CancelButton>

					<SubmitButton disabled={mutationLoading} variant="contained" style={{ float: 'right' }} type="submit" onClick={() => {
						setAttempt(true)
						if (
							attempt &&
							(!locationValue)
						) {
							openSnackbar(
								severity.WARNING,
								'Please fill fields in red.'
							)
						}

						else if (locationValue) {

							const input = {
								location_id: locationValue.id,
								sub_location_id: subLocationValue?.id ?? null,
								via_id: viaValue?.id ?? null,
								date: dateValue,
								art_ids: rows.map(e => e.id)
							}

							handleSubmit(input)
						}
					}}>
						Submit
					</SubmitButton>
				</div>
			</div>
		</TransitionsModal>
	)
}

export default withApollo(LocationModal)
