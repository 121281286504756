import { gql } from '@apollo/client'
import { fileData, userDataCompressed } from '../common/fragments'

export const SEARCH_CONTACTS = gql`
	query SearchContacts(
		$field: String
		$direction: SortDirection
		$searchString: String
		$activityID: String
		$gradeID: String
		$statusID: String
		$regionID: String
		$modifiedStartDate: String
		$modifiedEndDate: String
		$specialFilter: String
		$cursor: Int
		$isFavorite: Boolean
		$tagId: Int
		$limit: Int
		$salesperson_ids: [ID]
		$thumbnailResolution: String = "128x128"
	) {
		searchContacts(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: {
					tagId: $tagId
					searchString: $searchString
					activityId: $activityID
					modifiedStartDate: $modifiedStartDate
					modifiedEndDate: $modifiedEndDate
					statusId: $statusID
					gradeId: $gradeID
					regionId: $regionID
					specialFilter: $specialFilter
					isFavorite: $isFavorite
					salesperson_ids: $salesperson_ids
				}
				thumbnailResolution: $thumbnailResolution
			}
		) {
			items {
				id
				first_name
				last_name
				preferred_name
				is_company
				regions {
					value
				}
				status {
					id
					value
				}
				company_name
				code_name
				is_private
				is_deleted
				is_deceased
				restrictedToUsers {
					id
					first_name
				}
				phone {
					id
					type_id
					number
					is_deleted
					is_primary
					label
					extension
				}
				email {
					id
					contact_id
					type_id
					email
					is_deleted
					is_primary
					label
				}
				address {
					id
					street_1
					street_2
					street_3
					town
					region
					postcode
					country
					is_primary
					is_deleted
					label
					type_id
				}
				imgUrl (thumbnailResolution: $thumbnailResolution)
				isFavorite
				isFlagged
				dynamic_generated_codename
			}
			cursor
			totalItems
		}
	}
`



export const GET_CONTACT_QV_DETAILS = gql`
	query GetContactDetails(
		$id: ID!
		$thumbnailResolution: String = "128x128"
		$qvResolution: String = "1024x1024"
	) {
		getContact(id: $id) {
			id
			first_name
			last_name
			preferred_name
			company_name
			is_company
			salutation
			type {
				id
				contact_type_id
				contact_type
				ordinal
			}
			grade {
				id
				value
			}
			activity {
				id
				value
			}
			status {
				id
				value
			}
			is_deceased
			code_name
			is_private
			created_by
			created_at
			modified_by
			modified_at
			is_deleted
			email {
				id
				type_id
				email
				is_deleted
				type
				is_primary
				label
			}
			title
			website {
				id
				type_id
				website
				is_deleted
				type
				is_primary
				label
			}
			phone {
				id
				type_id
				number
				is_deleted
				type
				is_primary
				label
				extension
			}
			address {
				id
				type_id
				street_1
				street_2
				street_3
				town
				region
				postcode
				country
				country_id
				is_deleted
				type
				is_primary
				label
			}
			salesperson {
				...userDataCompressed
				is_lead
				is_deleted
			}
			imgUrl (thumbnailResolution: $qvResolution)
			publicAccess
			isPermittedContactType

			dynamic_generated_codename
			is_compliant
		}
		getCriticalContactNotes(input: { contact_id: $id }) {
			id
			type_id
			applied_at
			applied_user_id
			note_text
			is_critical
			is_private
			is_deleted
			user {
				...userDataCompressed
				department
			}
		}
	}
	${userDataCompressed}
`

export const GET_CONTACT_ADDRESSES = gql`
	query getContact( $id: ID! ) {
		getContact(id: $id) {
			address {
				id
				type_id
				street_1
				street_2
				street_3
				town
				region
				postcode
				country
				country_id
				is_deleted
				type
				is_primary
				label
			}
		}
	}
`

export const GET_CONTACT_DETAILS = gql`
	query getContact(
		$id: ID!
		$thumbnailResolution: String = "128x128"
	) {
		getContact(id: $id) {

			publicAccess
			isPermittedContactType
			created_by
			created_at
			modified_by
			modified_at
			id

			prefix_id
			prefix {
				id
				value
			}
			is_company
			first_name
			middle_name
			last_name
			isFavorite
			isFlagged
			suffix
			preferred_name
			foreign_preferred_name
			company_name
			salutation
			title
			type {
				id
				contact_type_id
				contact_type
				ordinal
			}
			grade {
				id
				value
			}
			activity {
				id
				value
			}
			status {
				id
				value
			}
			grade_id
			activity_id
			status_id
			
			dates {
				id
				contact_id
				date
				label
				created_at 
				modified_by
				modified_at
				is_deleted
			}

			is_deceased
			code_name
			is_private
			restrictedToUsers {
				id
				first_name
				last_name
				gallery
				title
				department
				imgUrl (thumbnailResolution: $thumbnailResolution)
				assistants {
					id
					first_name
					last_name
					gallery
					imgUrl (thumbnailResolution: $thumbnailResolution)
					permissions {
						permission_id
						permission_value_id
					}
				}
			}
			created_user {
				first_name
				last_name
				id
			}
			modified_user {
				first_name
				last_name
			}
			is_deleted
			email {
				id
				type_id
				email
				is_deleted
				type
				is_primary
				label
			}
			title
			website {
				id
				type_id
				website
				is_deleted
				type
				is_primary
				label
			}
			phone {
				id
				type_id
				number
				is_deleted
				type
				is_primary
				label
				extension
			}
			address {
				id
				type_id
				street_1
				street_2
				street_3
				town
				region
				postcode
				country
				country_id
				is_deleted
				type
				is_primary
				label
			}
			salesperson {
				...userDataCompressed
				is_lead
				is_deleted
			}
			regions {
				id
				value
				relationModified
			}
			relationships {
				id
				is_deleted
				relationship_type_id
				type
				description_id
				description
				contact_1 {
					id
					preferred_name
					first_name
					last_name
					is_company
					company_name
					is_private
					code_name
					is_deleted
					type {
						id
						contact_type_id
						contact_type
						ordinal
					}
					imgUrl (thumbnailResolution: $thumbnailResolution)
					is_deceased
					publicAccess
					dynamic_generated_codename
					created_at
				}
				contact_2 {
					id
					preferred_name
					first_name
					last_name
					is_company
					company_name
					code_name
					is_deleted
					is_private
					type {
						id
						contact_type_id
						contact_type
						ordinal
					}
					is_deceased
					publicAccess
					dynamic_generated_codename
					created_at
				}
				created_at
			}
			tags {
				id
				label
				description
				color_hex
			}
			artist_interests {
				id
				first_name
				last_name
			}
			art_category_interests {
				id
				value
			}
			genre_interests {
				id
				value
			}
			imgUrl (thumbnailResolution: "1024x1024")
			profile_link
			phoneCount
			emailCount
			websiteCount
			addressCount
			hasContactInfoPermission
			import_id

			dynamic_generated_codename
			is_compliant
		}
		getCriticalContactNotes(input: { contact_id: $id }) {
			id
			type_id
			applied_at
			applied_user_id
			note_text
			is_critical
			is_private
			is_deleted
			user {
				...userDataCompressed
				department
			}
		}
		getContactCount
	}
	${userDataCompressed}
`

export const UPDATE_CONTACT = gql`
	mutation updateContact(
		$ContactInput: UpdateContactInput!
		$thumbnailResolution: String = "128x128"
		$qvResolution: String = "1024x1024"
	) {
		updateContact( input: $ContactInput	) {
			code
			success
			message
			severity
			contact {
				id
				prefix {
					id
					value
				}
				is_company
				first_name
				middle_name
				last_name
				suffix
				preferred_name
				foreign_preferred_name
				company_name
				salutation
				title
				type {
					id
					contact_type_id
					contact_type
					ordinal
				}
				grade {
					id
					value
				}
				activity {
					id
					value
				}
				status {
					id
					value
				}
				dates {
					id
					contact_id
					date
					label
					created_at 
					modified_by
					modified_at
					is_deleted
				}
				is_deceased
				code_name
				is_private
				restrictedToUsers {
					id
					first_name
					last_name
					gallery
					title
					department
					imgUrl (thumbnailResolution: $thumbnailResolution)
					assistants {
						id
						first_name
						last_name
						gallery
						imgUrl (thumbnailResolution: $thumbnailResolution)
						permissions {
							permission_id
							permission_value_id
						}
					}
				}
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				email {
					id
					type_id
					email
					is_deleted
					type
					is_primary
					label
				}
				title
				website {
					id
					type_id
					website
					is_deleted
					type
					is_primary
					label
				}
				phone {
					id
					type_id
					number
					is_deleted
					type
					is_primary
					label
					extension
				}
				address {
					id
					type_id
					street_1
					street_2
					street_3
					town
					region
					postcode
					country
					country_id
					is_deleted
					type
					is_primary
					label
				}
				salesperson {
					...userDataCompressed
					is_lead
					is_deleted
				}
				regions {
					id
					value
				}
				relationships {
					id
					is_deleted
					relationship_type_id
					type
					description_id
					description
					created_at
					contact_1 {
						id
						preferred_name
						company_name
						first_name
						last_name
						is_company
						is_private
						code_name
						is_deceased
						type {
							id
							contact_type_id
							contact_type
							ordinal
						}
						imgUrl (thumbnailResolution: $thumbnailResolution)
						publicAccess
						dynamic_generated_codename
						created_at
					}
					contact_2 {
						id
						preferred_name
						first_name
						last_name
						is_company
						company_name
						code_name
						is_private
						is_deceased
						type {
							id
							contact_type_id
							contact_type
							ordinal
						}
						publicAccess
						dynamic_generated_codename
						created_at
					}
				}
				tags {
					id
					label
					description
					color_hex
				}
				artist_interests {
					id
					first_name
					last_name
				}
				art_category_interests {
					id
					value
				}
				genre_interests {
					id
					value
				}
				profile_link
				imgUrl (thumbnailResolution: $qvResolution)
				phoneCount
				emailCount
				websiteCount
				addressCount
				hasContactInfoPermission

				dynamic_generated_codename
			}
		}
	}
	${userDataCompressed}
`

export const CREATE_CONTACT = gql`
	mutation createContact(
		$CreateContactInput: CreateContactInput!
		$thumbnailResolution: String = "128x128"
	) {
		createContact(
			input: $CreateContactInput,
		) {
			code
			success
			message
			contact {
				id
				prefix {
					id
					value
				}
				first_name
				is_company
				middle_name
				salutation
				last_name
				suffix
				preferred_name
				foreign_preferred_name
				company_name
				title
				type {
					id
					contact_type_id
					contact_type
					ordinal
				}
				grade {
					id
					value
				}
				activity {
					id
					value
				}
				status {
					id
					value
				}
				dates {
					id
					contact_id
					date
					label
					created_at 
					modified_by
					modified_at
					is_deleted
				}
				is_deceased
				code_name
				created_by
				created_at
				modified_by
				modified_at

				email {
					id
					type_id
					email
					is_deleted
					type
					is_primary
					label
				}
				title
				website {
					id
					type_id
					website
					is_deleted
					type
					is_primary
					label
				}
				phone {
					id
					type_id
					number
					is_deleted
					type
					is_primary
					label
					extension
				}
				address {
					id
					type_id
					street_1
					street_2
					street_3
					town
					region
					postcode
					country
					country_id
					is_deleted
					type
					is_primary
					label
				}
				salesperson {
					...userDataCompressed
					is_lead
					is_deleted
				}
				primary_town
				regions {
					id
					value
				}
				relationships {
					id
					is_deleted
					relationship_type_id
					type
					contact_1 {
						id
						preferred_name
						first_name
						last_name
						is_company
						company_name
						type {
							id
							contact_type_id
							contact_type
							ordinal
						}
						publicAccess
						dynamic_generated_codename
						created_at
					}
					contact_2 {
						id
						preferred_name
						first_name
						last_name
						is_company
						company_name
						type {
							id
							contact_type_id
							contact_type
							ordinal
						}
						publicAccess
						dynamic_generated_codename
						created_at
					}
				}
				tags {
					id
					label
					description
					color_hex
				}
				artist_interests {
					id
					first_name
					last_name
				}
				art_category_interests {
					id
					value
				}
				dynamic_generated_codename
			}
		}
	}
	${userDataCompressed}
`

export const UPDATE_CONTACT_FILES = gql`
	mutation updateContactFiles(
		$UpdateFileInput: UpdateFileInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateContactFiles(input: $UpdateFileInput) {
			code
			success
			message
			files {
				...fileData
			}
		}
	}
	${fileData}
`

export const DELETE_CONTACT = gql`
	mutation deleteContact($id: ID!) {
		deleteContact(id: $id) {
			code
			success
			message
		}
	}
`

export const SIMPLE_SEARCH_REGIONS = gql`
	query($countryName: String) {
		simpleSearchRegions(countryName: $countryName) {
			name
			shortCode
		} 
	}
`
