/* eslint-disable eqeqeq */
import React, { useContext, useCallback, useState, useEffect } from 'react'
import OperationDetails from './OperationDetails'
import LocationHistory from './LocationHistory'
import { DispatchContext } from '../../../store'
import { useMutation, useLazyQuery } from '@apollo/client'
import { severity } from '../../../Snackbar/CustomizedSnackbar'
import { stripMeta } from '../../../common/helpers'
import { UPDATE_ART } from '../../Queries'
import ImagesCard from '../../../input/Image/ImagesCard'
import FileList from '../../../Files/FileList'
import groupBy from 'lodash/groupBy'
import { gql } from '@apollo/client'
import { fileData } from '../../../common/fragments'
import LocationSearch from './LocationSearch'
import ArtCoaLocation from './ArtCoaLocation'
import { useParams } from 'react-router-dom'
import ProjectCodeHistory from './ProjectCodeHistory'

const fileQuery = gql`
	query getArtPiece($id: ID!, $thumbnailResolution: String = "128x128") {
		getArtPiece(id: $id) {
			code
			success
			message
			severity
			art {
				id
				operation_files {
					...fileData
				}
			}
		}
	}
	${fileData}
`

const ArtOperations = (props) => {
	let { loading, error } = props
	let art = props.state.getArtPiece || {}

	const params = useParams()

	let setArt = (art) => props.setState({ getArtPiece: art })

	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const [updateArt, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_ART, {
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, 'Could not update.')
		},
		onCompleted: (response) => {
			if (response.updateArtPiece.success === true) {
				openSnackbar(severity[response.updateArtPiece?.severity] ?? severity.SUCCESS , response.updateArtPiece.message)
				setArt(response.updateArtPiece.art)
			} else {
				// On failure, reset artistInput state, don't touch artist
				// state and show error
				openSnackbar(severity.ERROR, response.updateArtPiece.message)
			}
		},
	})

	const update = (fields) => {
		let variables = stripMeta(fields)

		if (!variables.id) variables.id = art.id

		updateArt({
			variables: {
				UpdateArtInput: variables,
			},
		})
	}

	const [files, setFiles] = useState([])

	const [refetch, { loading: operationFilesLoading }] = useLazyQuery(fileQuery, {
		onCompleted: (data) => {
			if (data?.getArtPiece?.art?.operation_files) {
				const files = groupBy(
					data?.getArtPiece?.art?.operation_files
						.filter((file) => {
							return file.fileCategory.fileGroup.id == 3
						})
						.sort((a, b) => b.version - a.version)
						.map((file) => ({
							...file,
							fileObject: file.fileCategory?.fileGroup?.fileObject?.value,
							fileGroup: file.fileCategory?.fileGroup?.value,
							fileCategory: file.fileCategory?.value,
							file_group_id: file.fileCategory?.fileGroup?.id,
							file_category_id: file.fileCategory?.id,
						})),
					'fileGroup'
				)

				setFiles(
					files.Operations?.map((file) => ({
						...file,
						versionHistory: files.Operations.filter((e) => {
							if (e.id == file.id) return true
							else if (
								(file.parent_file_id == e.id || e.parent_file_id == file.parent_file_id) &&
								file.parent_file_id
							)
								return true
							else return false
						}),
					}))
				)
			} 
		},
		onError: (error) => {
			console.log(error)
			openSnackbar(severity.ERROR, 'Error retrieving files.')
		},
	})

	useEffect(() => {
		if (params?.id) {
			setFiles([])
			refetch({ variables: { id: params.id } })
		}
	}, [refetch, props.tabbedPageFileRefetch, params?.id])

	return (
		<>
			<div className="page-row">
				<OperationDetails 
					loading={loading} 
					error={error} 
					art={art}
					disabled={updateLoading}
					setArt={setArt} 
					update={update}
					findMode={props.findMode}
				/>
				<ProjectCodeHistory 
					art={art}
				/>
			</div>
			{!art.has_coa ? <></> : 
				<ArtCoaLocation 
					loading={loading}
					error={error}
					art={art}
					setArt={setArt}
					update={update}
					atPrivateObject={props.atPrivateObject}
				/>
			}
			{props.findMode ?  <LocationSearch /> : 
				<LocationHistory
					loading={loading}
					error={error}
					art={art}
					setArt={setArt}
					update={update}
					atPrivateObject={props.atPrivateObject}
				/>
			}
			{!props.findMode && <FileList
				objectId={art?.id}
				heading={'Operation Documents'}
				loading={operationFilesLoading}
				files={files?.sort((a,b) => b?.created_at - a?.created_at) || []}
				setFiles={setFiles}
				fileObjectId={props.options?.fileObjectId}
				options={props.options}
				refetch={refetch}
				tabbedPageFileRefetch={props.tabbedPageFileRefetch}
				setTabbedPageFileRefetch={props.setTabbedPageFileRefetch}
				atPrivateObject={props.state?.getArtPiece?.is_private || false}
			/>}
			{!props.findMode && <ImagesCard
				loading={loading}
				error={error || updateError}
				entity={art}
				setEntity={setArt}
				onSave={update}
				imgName="operation_images"
				noPrimaryUpdate
				titleOverride="Operation Images"
				defaultImageType={1}
				private={art?.is_private || false}
				page="Operations"
			></ImagesCard>
			}
		</>
	)
}

export default ArtOperations
