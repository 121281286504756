/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react'
import { Route, Routes, matchPath, useLocation, Navigate } from 'react-router-dom'
import { LicenseInfo } from '@mui/x-data-grid-pro'

import { AuthStateContext, DispatchContext } from './store'
import { GET_USER } from './User/Queries'

// CSS
import { CircularProgress, CssBaseline } from '@mui/material'
import './App.css'
import './styles/fonts/Fonts.css'
import 'react-medium-image-zoom/dist/styles.css'
import './tailwind.css'

// Navigational Components
import PageNotFound from './PageNotFound/PageNotFound'
import SideDrawer from './navigation/SideDrawer/SideDrawer'
import TopNav from './navigation/TopNav/TopNav'
import routes from './navigation/routes'
import { filteredPages} from './navigation/routes'
import AuthRedirect from './SignIn/AuthRedirect'

// Other components
import CustomizedSnackbar, { severity } from './Snackbar/CustomizedSnackbar'

import clsx from 'clsx'
import { useApolloClient } from '@apollo/client'

const packageJson = require('../package.json')
export const CLIENT_SIDE_VERSION = packageJson.version

/*
	This is their recommended way to install:
	https://mui.com/components/data-grid/getting-started/#license-key-installation

	N.B.: This currently is locking all our MUI upgrades to their last version.
	Expiry date: Thu Oct 06 2022
*/

LicenseInfo.setLicenseKey(
	'980afb2e88486b4e0a680845cd8fa0e5T1JERVI6MzA0MjcsRVhQSVJZPTE2NjUwNjQ3MTIwMDAsS0VZVkVSU0lPTj0x',
)

const DrawerWithRouter = SideDrawer

/** the outer most application component */
function App (props) {
	const [isConfigLoaded, setIsConfigLoaded] = useState(false)
	const [connectionFailure, setConnectionFailure] = useState(false)

	const [subtitle, setSubtitle] = useState(null)
	const [entityTitle, setEntityTitle] = useState(null)

	const authState = useContext(AuthStateContext)
	const dispatch = useContext(DispatchContext)

	const location = useLocation()
	const client = useApolloClient()

	const atAdminConsole = location.pathname.includes('admin')
	const atShowcaseConsole = location.pathname.includes('showcase/')
	const userPermissions  = authState?.user?.permissions

	const [totalResultCount, setTotalResultCount] = React.useState(0)

	const [showcaseInformation, setShowcaseInformation] = React.useState(false)

	const [showcaseSelectMode, setShowcaseSelectMode] = React.useState({
		selectMode: false,
		artIds: []
	})
	
	// Quick view auto hide functionality
	const closeQuickView = () => dispatch({ type: 'closeQuickView' })
	useEffect(() => {
		if (document.body.clientWidth < 1400) {
			closeQuickView()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {

		async function fetchConfig() {
			try {
				const res = await fetch('/api/configV2')
				const data = await res.json()

				window.SERVER_DATA = data
				if (window.SERVER_DATA.VERSION) {
					window.SERVER_DATA.VERSION.Client = CLIENT_SIDE_VERSION
				}

				if (window.location.pathname !== "/" ) {
					client.query({ query: GET_USER })
						.then(({ data }) => {
							if (!data.getUser) {
								dispatch({ type: 'openSnackBar', payload: {
									severity: severity.ERROR,
									text: "Unauthenticated"
								}})
								dispatch({ type: 'logout' })
							} else {
								dispatch({
									type: 'login',
									payload: data.getUser
								})
							}
						})
						.catch(error => {
							dispatch({ type: 'openSnackBar', payload: {
								severity: severity.ERROR,
								text: "Authentication error"
							}})
							dispatch({ type: 'logout' })
						})
				}
			} catch(error) {
				setConnectionFailure(true)
				console.log(error)
				dispatch({ type: 'openSnackBar', payload: {
					severity: severity.ERROR,
					text: `Configuration failed to load. Check connection.`
				}})
				window.SERVER_DATA = {}
			} finally {
				setIsConfigLoaded(true)
			}
		}

		fetchConfig()
	}, [dispatch, client])

	/**
	 * Closes the snackbar
	 * @param {*} event
	 * @param {*} reason
	 */
	const closeSnackbar = (event, reason) => {
		dispatch({ type: 'closeSnackBar' })
	}


	// find the matching route, and pass the name to the subtitle.
	useEffect(() => {
		let pageName = routes.mainPages.pages
			.find(page => matchPath({
				path: page.route
			}, location.pathname))?.name || routes.adminPages.pages
			.find(page => matchPath({
				path: page.route
			}, location.pathname))?.name || routes.showcasePages.pages
			.find(page => matchPath({
				path: page.route
			}, location.pathname))?.name || null
		setSubtitle(pageName)
		setEntityTitle(null)
	}, [location])

	useEffect(() => {
		let tabTitle = "Platform"
		if (subtitle)
			tabTitle += ` – ${subtitle}`
		if (entityTitle)
			tabTitle += ` – ${entityTitle}`
		document.title = tabTitle
	}, [entityTitle, subtitle])


	let innerContent
	if (connectionFailure) {
		innerContent = (
			<div style={{textAlign: 'center'}}>
				<p>Configuration failed to load. Please check your connection and try again. If the problem persists, please contact support.</p>
			</div>
		)
	} else {
		let pageName = 'mainPages'

		if (atAdminConsole) pageName = 'adminPages'
		else if (atShowcaseConsole) pageName = 'showcasePages'

		const mainPages = routes[pageName]?.pages

		const permittedRoutes = filteredPages(pageName, userPermissions)
			.map((page) => {

				const CurrentComponent = page.component

				return <Route key={page.route} path={page.route}
					element={<div
						className={clsx({
							'content': true,
							'fadeIn': !atAdminConsole,
							'campton': atShowcaseConsole
						})}
					>
						<CurrentComponent
							onEntity={setEntityTitle}
							totalResultCount={totalResultCount}
							setTotalResultCount={setTotalResultCount}
							showcaseInformation={showcaseInformation}
							setShowcaseInformation={setShowcaseInformation}
							showcaseSelectMode={showcaseSelectMode}
							setShowcaseSelectMode={setShowcaseSelectMode}
						/>
					</div>
					} 
				/>
			})

		const loading = <div style={{
			display: 'flex',
			height: '90vh',
			padding: '40vh',
			justifyContent: 'center'
		}}>
			<CircularProgress />
		</div>
			
		let notPermitted = userPermissions ? 
			<Route path="*" element={<PageNotFound />} /> : 
			<Route path="*" element={
				<div style={{
					width: '100%',
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}>
					<CircularProgress />
				</div>
			} />
		

		if (pageName === 'showcasePages') {
			notPermitted = <Route path="*" element={<Navigate to="/home" replace />} />
		}

		innerContent = (isConfigLoaded ) ?
			<DrawerWithRouter
				pageName={pageName}
				mainPages={mainPages}
				user={ authState?.user }
				setTotalResultCount={setTotalResultCount}
				setShowcaseSelectMode={setShowcaseSelectMode}
			>
					
				{ // If there is no auth state, disable all routes and redirect to login page.
					(authState?.authenticated !== false) ?
						<Routes>
							<Route path="/" element={<AuthRedirect />} />
							<Route path="/logout" element={<AuthRedirect />} />
							<Route path="/admin/login" element={<AuthRedirect />} />
							{ permittedRoutes }
							{ notPermitted }
						</Routes> :
						<AuthRedirect />
				}
			</DrawerWithRouter>
			: <>{ loading }</>
	}

	return (
		<React.Fragment>
			<CssBaseline />
			<TopNav
				subtitle={subtitle}
				entityTitle={entityTitle}

				totalResultCount={totalResultCount}
				setTotalResultCount={setTotalResultCount}

				unreadReleaseNotes={authState?.user?.unreadReleaseNotes}
				authState={authState}
			/>

			{ innerContent }

			<CustomizedSnackbar
				handleClose={() => closeSnackbar()}
			></CustomizedSnackbar>
		</React.Fragment>
	)
}

export default App
