/* eslint-disable eqeqeq */
import React, { useContext, useEffect } from "react"
import { Paper,FormControl, Menu, MenuItem} from "@mui/material"
import Label from '../../../input/Label'
import TextBox from '../../../input/Text/TextBoxThin'
import { CancelButton, SaveCardButton } from '../../../input/Buttons'
import { AuthStateContext, LookupContext } from '../../../store'
import { permissions, permissionValues } from "../../../constants/permissions"
import TransitionsModal from "../../../navigation/TransitionsModal/TransitionsModal"
import SalespersonSelection from "../../../Contacts/Contact/Salesperson/SalespersonSelection"
import sortBy from 'lodash/sortBy'
import GalleryContactEditMode from "../../../common/GalleryContactEditMode"
import { FindModeInput } from "../../../navigation/Tabs/TabbedPage"
import { Skelly } from "../../../common/components/Skelly"
import MultiTypeModal from "../../../common/MultiTypeModal"

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false
}

export default function ArtistAdvanced(props) {

	const [state, setState] = React.useState(initialState)
	const [artist, setArtist] = React.useState({
		nationality: '',
		type_id: '',
		artist_initials: '',
		next_artist_serial: '',
	})

	const resetArtistInput = (artist) => {

		if (!artist || !Object.entries(artist).length) {
			return
		}

		const salespersons = artist.salesperson?.map((sp) => {
			return {
				id: sp.id,
				first_name: sp.first_name,
				last_name: sp.last_name,
				is_lead: sp.is_lead,
				is_deleted: sp.is_deleted,
				imgUrl: sp.imgUrl,
				user_handle: sp.user_handle
			}
		})

		const primary_salespersons = salespersons?.filter(e => e.is_lead)

		setArtist({
			nationality: artist.nationality,
			artist_initials: artist.artist_initials,
			next_artist_serial: artist.next_artist_serial,
			salespersons,
			primary_salespersons,
			copyright: artist.copyright,
			genres: artist.genre,
		})
	}

	React.useEffect(() => {
		resetArtistInput(props.artist)
	}, [props.artist])

	const handleClick = event => {
		if (state.mouseX || state.editable || props.findMode || !props.artist?.id) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4
		})
	}

	const handleClose = option => {
		if (option === "edit") {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	const [typeModal, setTypeModal] = React.useState(false)
	const [attempt, setAttempt] = React.useState(false)


	// Salesperson Selection Logic
	const [salespersonModal, setSalespersonModal] = React.useState(false)
	const [canViewSP, setCanViewSP] = React.useState(true)
	const [canEditSP, setCanEditSP] = React.useState(true)

	const authState = useContext(AuthStateContext)
	const userPermissions  = authState?.user?.permissions
	const createdUserId = props.artist?.created_by
	const currentUserId = authState?.user?.id
	useEffect(() => {
		if (userPermissions) {
			const galleryContactPermission = userPermissions.find(e => e.permission_id == permissions.ARTISTS_SALESPEOPLE)?.permission_value_id
			if (galleryContactPermission < permissionValues.VIEW_ONLY) setCanViewSP(false)

			const canUpdateGalleryContacts = (galleryContactPermission == permissionValues.CREATE_AND_EDIT) || 
				(galleryContactPermission == permissionValues.CREATE_AND_EDIT_OWN && 
					createdUserId == currentUserId)
			setCanEditSP(canUpdateGalleryContacts)
		}
	}, [userPermissions, createdUserId, currentUserId])


	const lookup = React.useContext(LookupContext)
	const artistGenres = lookup.data?.getArtistGenres

	const genreDropdownValues = artistGenres?.filter(genre =>
		!artist.genres?.find(it => it.artist_genre_id == genre.id)
	)

	const sortedDropdownValues = sortBy(genreDropdownValues, [function(o) { return o.value }])

	return (
		<React.Fragment>
			<Paper className="qv-margin"
				onContextMenu={handleClick}
				id="artist-advanced"
				data-testid="card-advanced"
			>
				<h1 className="card-title">
					<span>Advanced</span>
					{state.editable && !props.findMode && (
						<>
							<div className="spacer"></div>
							<CancelButton
								variant="contained"
								size="small"
								onClick={() => {
									resetArtistInput(props.artist)
									handleClose()
								}}
							>
								Cancel
							</CancelButton>
							<SaveCardButton
								variant="contained"
								color="secondary"
								size="small"
								disabled={props.disabled}
								onClick={() => {
									// Submit update

									let updateData = {
										...artist,
										primary_salesperson_ids: artist.primary_salespersons?.map(salesperson => salesperson.id),
										salespersons_ids: artist.salespersons?.map(salesperson => salesperson.id),
										genres: artist.genres?.map(genre => {
											if(genre.id) {
												return {
													id: genre.id,
													ordinal: parseInt(genre.ordinal),
													artist_genre_id: genre.artist_genre_id
												}
											}

											else if (genre.artist_genre_id) {
												return {
													ordinal: parseInt(genre.ordinal),
													artist_genre_id: genre.artist_genre_id
												}
											}

											return {
												ordinal: parseInt(genre.ordinal),
												artist_genre: genre.artist_genre,
											}
											
										}),
									}
									
									delete updateData.primary_salespersons
									delete updateData.salespersons
									if (!canEditSP) {
										delete updateData.primary_salesperson_ids
										delete updateData.salespersons_ids
									}

									props.onSave(updateData)
									handleClose()
								}}
							>
								Save
							</SaveCardButton>
						</>
					)}
				</h1>
				<div className="column-body">
					<div className="column">
						<FormControl>
							<Label disableAnimation shrink htmlFor="artist-nationality">
								Nationality
							</Label>
							{ props.findMode ? <FindModeInput field="nationality" /> :
								(props.loading ? <Skelly error={props.error} /> :
									<TextBox
										id="artist-nationality"
										value={artist.nationality || ''}
										readOnly={!state.editable}
										placeholder={'-'}
										onChange={(e) => {
											setArtist({
												...artist,
												nationality: e.target.value,
											})
										}}
										data-testid="artist-nationality"
									/>
								)}
						</FormControl>

						<FormControl>
							<Label id="genre-label" disableAnimation shrink htmlFor="artist-genre">
								Genre
							</Label>
							{(props.loading || props.error) ? <Skelly error={props.error} /> : 
								(props.findMode ? <FindModeInput field="artist_genres" /> :
									<TextBox
										inputProps={{ spellCheck: 'false' }}
										id="artist-genres"
										value={
											artist.genres?.map(item => {
												return item.artist_genre
											}).join(", ") || ""
										}
										readOnly={!state.editable}
										placeholder={'-'}
										onClick={(e) => {
											if (state.editable) {
												setTypeModal(true)
											}
										}}
										data-testid="artist-genre"
									/>
								)
							}
						</FormControl>
						<FormControl>
							<Label disableAnimation shrink htmlFor="artist-copyright">
								Copyright
							</Label>
							{ props.findMode ? <FindModeInput field="copyright" /> :
								(props.loading ? <Skelly error={props.error} /> :
									<TextBox
										id="artist-copyright"
										value={artist.copyright || ''}
										readOnly={!state.editable}
										placeholder={'-'}
										multiline
										onChange={(e) => {
											setArtist({
												...artist,
												copyright: e.target.value,
											})
										}}
										data-testid="artist-copyright"
									/>
								)}
						</FormControl>
					</div>
					<div className="column">
						<FormControl>
							<Label disableAnimation shrink htmlFor="artist-initials">
								Initials
							</Label>
							{ props.findMode ? <FindModeInput field="artist_initials" /> :
								(props.loading ? <Skelly error={props.error} /> :
									<TextBox
										id="artist-initials"
										value={artist.artist_initials || ''}
										readOnly={!state.editable}
										placeholder={'-'}
										onChange={(e) => {
											setArtist({
												...artist,
												artist_initials: e.target.value,
											})
										}}
										data-testid="artist-initials"
									/>
								)}
						</FormControl>
						<FormControl>
							<Label disableAnimation shrink htmlFor="artist-salesperson">
								Gallery Contacts
							</Label>
							{ (props.findMode && canViewSP) ? <FindModeInput field="gallery_contacts" /> :
								(props.loading ? <Skelly error={props.error} /> : 
									<GalleryContactEditMode
										setSalespersonModal={setSalespersonModal}
										salespersons={artist.salespersons}
										canViewSP={canViewSP}
										editable={state.editable && canEditSP}
									/>
								)}
						</FormControl>
					</div>
				</div>

				<Menu
					keepMounted
					open={state.mouseY !== null}
					onClose={handleClose}
					anchorReference="anchorPosition"
					anchorPosition={
						state.mouseY !== null && state.mouseX !== null ? { top: state.mouseY, left: state.mouseX } : undefined
					}
				>
					<MenuItem onClick={() => handleClose('edit')}>Edit</MenuItem>
				</Menu>
			</Paper>

			<TransitionsModal
				className="salesperson-modal"
				open={salespersonModal}
				close={() => setSalespersonModal(false)}
			>
				<SalespersonSelection
					input={artist}
					setInput={setArtist}
					salespersons={artist.salespersons}
					primary_salespersons={artist.primary_salespersons}
					close={() => setSalespersonModal(false)}
				/>
			</TransitionsModal>

			<MultiTypeModal
				sortedTypeDropdownValues={sortedDropdownValues}
				attempt={attempt}
				setAttempt={setAttempt}
				typeModal={typeModal}
				setTypeModal={setTypeModal}
				loading={props.loading}
				error={props.error}
				isPrivate={false}
				entityInput={artist}
				setEntityInput={setArtist}
				type="artist_genre"
				typeId="artist_genre_id"
				keyName="genres"
				title="Genre"
				width="15em"
			/>
		</React.Fragment>
	)
}
