/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useCallback } from "react"
import { DispatchContext } from '../../store'
import { Paper, FormControl, Menu, MenuItem, Button, Divider, Fade } from "@mui/material"
import makeStyles from '@mui/styles/makeStyles'
import "./ContactDetails.css"
import Label from "../../input/Label"
import TextBox from "../../input/Text/TextBoxThin"
import { capitalizeFirstLetter } from "../../common/helpers"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import { useMutation } from '@apollo/client'
import { UPDATE_CONTACT } from "../Queries.js"
import { severity } from '../../Snackbar/CustomizedSnackbar'
import Radio from '@mui/material/Radio'
import { CancelButton, SaveCardButton } from '../../input/Buttons'
import { Skeleton } from '@mui/material'

import NewPhoneModal from "../Modals/NewPhoneModal"
import NewEmailModal from "../Modals/NewEmailModal"
import NewWebsiteModal from "../Modals/NewWebsiteModal"
import NewAddressModal from "../Modals/NewAddressModal"

import EditPhoneModal from "../Modals/EditPhoneModal"
import EditEmailModal from "../Modals/EditEmailModal"
import EditWebsiteModal from "../Modals/EditWebsiteModal"
import EditAddressModal from "../Modals/EditAddressModal"

import PhoneInput from 'react-phone-input-2'
import { shorten } from '../../common/helpers'
import { LookupContext } from '../../store'

import {StyledBadge} from '../../styles/makesStyles'

import { Tooltip } from '@mui/material'

import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { FindModeInput } from "../../navigation/Tabs/TabbedPage"
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { parsePhoneNumber } from "libphonenumber-js"
import EditIcon from '@mui/icons-material/Edit'

const formatPhone = phone => {
	if (!phone) return null
	const num = `+${phone.number}`
	let phoneNumber
	try {
		phoneNumber = parsePhoneNumber(num)
	} catch (e) {
		return phone.number
	}
	if (!phoneNumber) return num

	const number = phoneNumber.formatInternational()
		.replace(/(\d{3}) (\d{3}) (\d{4})$/, '($1) $2-$3')
			+ (phone.extension ? `x${phone.extension}` : '')
	return number
}

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false
}


const textboxStyle = {
	width: "100%",
	marginTop: "27px"
}

const columnStyle = {
	width: "24.5%",
	paddingLeft: "1em",
	paddingRight: "1em"
}

// Get other communication object labels
export const getLabel = (obj, name, i, types) => {

	let type = types?.find(type => type.id == obj.type_id).value

	type = type ? `(${capitalizeFirstLetter(type)})` : ''

	if (obj.label)
		return (
			<Tooltip
				title={`${obj.label} ${type}`} arrow placement="top" enterDelay={1000}>
				<span>{`${shorten(obj.label, 20)} ${type}`}</span>
			</Tooltip>
		)
	else
		return (
			<Tooltip
				title={`- ${type}`} arrow placement="top" enterDelay={1000}>
				<span>{`- ${type}`}</span>
			</Tooltip>
		)
}

export const getFormattedAddress = (countries, address={}) => {
	
	const cityLine = [
		address?.town ? `${address.town},` : null,
		address?.region,
		address?.postcode
	].filter(a => a).join(' ')
	const formattedAddress = [
		address?.street_1,
		address?.street_2,
		address?.street_3,
		cityLine,
		countries?.find(e => e.id == address?.country_id)?.name || address.country
	].filter(a => a).join('\n')

	return formattedAddress
}

/**
 * @typedef ContactsInformation
 * @property {string} address
 * @property {mobile} mobile
 * @property {function} onSelect - a callback when a row is selected
 */


export default function ContactInformation(props) {

	const lookup = useContext(LookupContext)

	const useStyles = makeStyles({
		contactInformationRoot: {
			display: 'flex',
			flexDirection: 'column',
		  	'& div': {
				marginBottom: '.5em',
				width: '100%'
		  	}
		}
	})
	const classes = useStyles()


	const emailTypes= lookup.data?.getEmailTypes
	const phoneTypes= lookup.data?.getPhoneTypes
	const websiteTypes= lookup.data?.getWebsiteTypes
	const addressTypes= lookup.data?.getAddressTypes
	const countries= lookup.data?.getCountryTypes

	// Get phone label
	const getPhoneLabel = (phone, i) => {

		let type = phoneTypes?.find(type => type.id == phone.type_id).value || ''

		type = type ? `(${type})` : ''

		return (
			<Tooltip
				title={ phone.label ? `${phone.label} ${type}` : `- ${type}`} arrow placement="top" enterDelay={1000}>
				<span>{ phone.label ? `${shorten(phone.label, 20)} ${type}` : `- ${type}`}</span>
			</Tooltip>
		)
	}

	const [state, setState] = React.useState(initialState)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const [phoneModal, setPhoneModal] = React.useState({open: false, phone: null, count: null,})
	const [emailModal, setEmailModal] = React.useState({open: false, email: null, count: null})
	const [websiteModal, setWebsiteModal] = React.useState({open: false, website: null, count: null})
	const [addressModal, setAddressModal] = React.useState({open: false, address: null, count: null})

	const [newPhoneModal, setNewPhoneModal] = React.useState({open: false, phone: null, count: null})
	const [newEmailModal, setNewEmailModal] = React.useState({open: false, email: null, count: null})
	const [newWebsiteModal, setNewWebsiteModal] = React.useState({open: false, website: null, count: null})
	const [newAddressModal, setNewAddressModal] = React.useState({open: false, address: null, count: null})

	const [copied, setCopied] = React.useState(false)

	const [contactInput, setContactInput] = React.useState({})

	const [updateContact, { loading, error }] = useMutation(UPDATE_CONTACT, {

		onError: (error) => {

			// On failure, reset contactInput state, don't touch contact state and show error
			resetContactInput(props.contact)
			openSnackbar(severity.ERROR, "Could not update information card.")

			handleClose()
		},

		onCompleted: (response) => {
			if (response.updateContact.success === true) {

				// Success
				openSnackbar(severity.SUCCESS, response.updateContact.message)

				// On success change contact state
				props.setContact(response.updateContact.contact)

			} else {

				// On failure, reset contactInput state, don't touch contact state and show error
				resetContactInput(props.contact)
				openSnackbar(severity.ERROR, response.updateContact.message)

			}
		}
	})

	// Loading, Error
	useEffect(() => {
		if (loading) openSnackbar(severity.INFO, "Saving information card...")
		if (error) openSnackbar(severity.ERROR, "Error saving information card.")
	}, [error, loading, openSnackbar])

	const resetContactInput = (contact) => {
		setContactInput({
			id: contact?.id,
			phone: contact?.phone ? [...contact?.phone].map(o => ({...o})) : [],
			email: contact?.email ? [...contact?.email].map(o => ({...o})) : [],
			website: contact?.website ? [...contact?.website].map(o => ({...o})) : [],
			address: contact?.address ? [...contact?.address].map(o => ({...o})) : []
		})
	}

	/**
	 * Toggles the in-state copied is_primary attributes of each communication object.
	 * @param {*} type array of communication objects to look in
	 * @param {*} id  id of object to toggle
	 */
	const togglePrimary = (type, index) => {

		let temp = [...contactInput[type]].map(o => ({...o, is_primary: false}))
		temp[index].is_primary = !temp[index].is_primary
		setContactInput({
			id: contactInput.id,
			phone: contactInput.phone,
			email: contactInput.email,
			website: contactInput.website,
			address: contactInput.address,
			[type]: temp
		})
	}

	const updateAttribute = (type, attribute, index, value) => {

		let temp= [...contactInput[type]].map(o => ({...o}))
		temp[index][attribute] = value

		if (attribute === "is_deleted") {
			props.setContact({
				...props.contact,
				[`${type}Count`]: props.contact[`${type}Count`] - 1
			})
		}

		// Element does not exist in db yet
		if (attribute === "is_deleted" && !temp[index].id) {
		 	delete temp[index]
		}

		// Check if deleted last item
		if (attribute === "is_deleted" && temp.length && temp.filter(item => item.is_primary && !item.is_deleted).length === 0) {

			const index = temp.findIndex(item => item && !item.is_primary && !item.is_deleted)
			if (index !== -1) temp[index].is_primary = true

		}

		setContactInput({
			id: contactInput.id,
			phone: contactInput.phone,
			email: contactInput.email,
			website: contactInput.website,
			address: contactInput.address,
			[type]: temp.filter(e => e != null)
		})
	}


	const addToContactInput = (type, obj) => {

		setContactInput({
			id: contactInput.id,
			phone: contactInput.phone,
			email: contactInput.email,
			website: contactInput.website,
			address: contactInput.address,
			[type]: contactInput[type].concat(obj)
		})

		props.setContact({
			...props.contact,
			[`${type}Count`]: props.contact[`${type}Count`] + 1
		})
	}

	React.useEffect(() => {
		if (!contactInput.id || props.contact?.id !== contactInput.id) resetContactInput(props.contact)
	}, [contactInput.id, props.contact])

	const handleClick = event => {
		if (state.mouseX || state.editable || props.findMode || !props.contact?.id || props.editButton) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4
		})
	}

	const handleClose = option => {
		if (option === "edit") {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	function addhttps(url) {
		if (!/^(?:f|ht)tps?:\/\//.test(url)) {
			url = "https://" + url
		}
		return url
	}


	const getWebsiteDisplay = (website, type = {}) => {

		const { is_social = false, value } = type

		let label
		let href
		let image
		let color

		if (!is_social) {
			label = website.website
			href = addhttps(website.website)
		}

		const isCustomImage = value === "WeChat"

		let ending = website.website
		if (website.website.startsWith("@"))
			ending = website.website.slice(1)

		switch (value) {
		case 'LinkedIn':
			label = website.website
			href = `https://www.linkedin.com/in/${ending}`
			image = LinkedInIcon
			color = '#0D66C2'
			break
		case 'Instagram':
			label = website.website
			href = `https://www.instagram.com/${ending}`
			image = InstagramIcon
			color = '#DA5F37'
			break
		case 'Twitter':
			label = website.website.startsWith("@") ? website.website : `@${website.website}`
			href = `https://twitter.com/${ending}`
			image = TwitterIcon
			color = '#22A1F2'
			break
		case 'Facebook':
			label = website.website
			href = `https://www.facebook.com/${ending}`
			image = FacebookIcon
			color = '#1877F2'
			break
		case 'WeChat':
			label = website.website
			href = `https://web.wechat.com/`
			image = '/images/icons/WeChat.svg'
			break
		default:
			break
		}

		const CurrentIcon = typeof image !== 'string' ? image : () =>
			<Tooltip title={!copied ? "Copy to Clipboard" : "Copied!"} placement="top" arrow><img
				onClick={() => {

					try {
						navigator.clipboard.writeText(label)

						setCopied(true)

						setTimeout(() => {
							setCopied(false)
						}, 5000)

					} catch (error) {
						console.error(error)
					}

				}}
				alt="current-icon"
				style={{
					width: isCustomImage ? 20 : 24,
					height: isCustomImage ? 20 : 24,
					marginTop: isCustomImage ? 27 : null,
					cursor: 'pointer'
				}}
				src={image}
			/></Tooltip>

		const aElement = (<a
			style={
				{
					lineBreak: 'anywhere',
					marginTop: isCustomImage ? -27 : 27,
					fontSize: 16,
					padding: '6px 0px 6px 0px',
					color: props.isPrivate ? '#6F8CED' :'#0000EE',
					fontWeight: 'light',
					marginLeft: is_social ? '1.7em' : null
				}}
			rel="noopener noreferrer"
			target="_blank"
			href={href}
			data-testid="contact-website"
		>
			{label}
		</a> )

		if (!is_social) return aElement

		return (
			<>
				<Tooltip title={!copied ? "Copy to Clipboard" : "Copied!"} placement="top" arrow>
					<CurrentIcon fontSize="small" onClick={() => {

						try {
							navigator.clipboard.writeText(label)

							setCopied(true)

							setTimeout(() => {
								setCopied(false)
							}, 5000)

						} catch (error) {
							console.error(error)
						}

					}} style={{
						position: 'absolute',
						top: '36px',
						color,
						cursor: 'pointer'
					}}/>
				</Tooltip>
				{aElement}
			</>
		)
	}

	function copyToClipboard (text) {
		return (event) => {
			navigator.clipboard
				.writeText(text)
				.then(() => {
					setCopied(true)
					setTimeout(() => {
						setCopied(false)
					}, 5000)
				})
				.catch(console.error)

		}
	}
	const ClipboardToolTip = (props) => {
		return !state.editable ?
			<div className="clipboard-copy">
				<Tooltip
					title={!copied ? "Copy to Clipboard" : "Copied!"}
					placement="top"
					arrow
					onClose={() => setCopied(false)}
				>
					<IconButton
						onClick={props.onClick}
						sx={{
							cursor: "copy",
							margin: "0 1em"
						}}
						size="small"
					>
						<ContentCopyIcon />
					</IconButton>
				</Tooltip>
			</div> : null
	}

	const hasContactInfoPermission = !!props.contact?.hasContactInfoPermission

	const onSaveClicked = useCallback(() => {

		contactInput.email.forEach  (x=> {
			delete x.__typename
			delete x.type})
		contactInput.phone.forEach  (x=> {
			delete x.__typename
			delete x.type})
		contactInput.website.forEach (x=> {
			delete x.__typename
			delete x.type})
		contactInput.address.forEach (x=> {
			delete x.__typename
			delete x.country
			delete x.type
		})

		updateContact({ variables: { ContactInput: contactInput } })

		setState({
			...state,
			editable: false
		})
	}, [contactInput, state, updateContact])

	return (
		<React.Fragment>
			<Paper
				className="qv-margin"
				onContextMenu={handleClick}
				id="contact-information"
				data-testid="card-contact-information"
			>
				<h1 className="card-title">
					<span>Contact Information</span>

					{(!hasContactInfoPermission && !props.loading && !props.findMode) ?
						<Tooltip
							enterDelay={500}
							title={'You do not have permission to view this contact information. However, you may still have the ability to add information by editing the card.\n\nTo gain access to this contact information find an administrator, a gallery contact, or a user with the contact information permission.'} arrow placement="bottom">
							<VisibilityOffIcon style={{
								color: 'grey',
								marginLeft: '0.4em',
								marginTop: '0.20em',
							}}/>
						</Tooltip>
					 : null}

					{props.editButton && !state.editable ? (
						<>
							<div className='spacer'></div>
							<IconButton
								onClick={() => {
									setState(Object.assign({}, initialState, { editable: true }))
								}}
							>
								<EditIcon />
							</IconButton>
						</>
					) : null}

					{(state.editable && !props.findMode) && (
						<>
							<div className="spacer"></div>
							<CancelButton
								variant="contained"
								size="small"
								onClick={() => {
									resetContactInput(props.contact)
									handleClose()
								}}
							>
								{hasContactInfoPermission ? "Cancel" : "Close"}
							</CancelButton>

							{hasContactInfoPermission ? <SaveCardButton
								variant="contained"
								color="secondary"
								size="small"
								disabled={loading}
								onClick={onSaveClicked}
							>
								Save
							</SaveCardButton> : null}
						</>
					)}
				</h1>

				<div className="contact-information-body" style={{
					minHeight: '2em',
					maxHeight: props.noScroll ? '50em' : '20em' 
				}}>
					<div
						className="contact-information-phone"
						style={{
							width: "24.5%",
							paddingRight: "1em"
						}}
					>
						{(props.loading || props.error) ?
							<>
								<Skeleton
									animation={props.error ? false : "wave"}
									variant="text"
									width="auto"
									style={{height: 30}}
								/>
								<Skeleton
									animation={props.error ? false : "wave"}
									variant="text"
									width="auto"
									style={{height: 50}}
								/>
							</>
							: null}

						{ props.findMode ?
							<div>
								<FormControl fullWidth>
									<Label disableAnimation shrink>
										Primary Phone
									</Label>
									<FindModeInput field="primary_phone" />
								</FormControl>
								<FormControl fullWidth>
									<Label disableAnimation shrink>
										Other Phones
									</Label>
									<FindModeInput field="non_primary_phones" />
								</FormControl>
								<FormControl fullWidth>
									<Label disableAnimation shrink>
										All Phones
									</Label>
									<FindModeInput field="phones" />
								</FormControl>
							</div> :
							((contactInput?.phone?.length === 0 && !props.loading && !props.error) || (contactInput?.phone?.every((phone) => phone.is_deleted === true) && !props.loading && !props.error)) ?
								<FormControl fullWidth>
									<Label disableAnimation shrink style={{width: '130%', display: 'flex'}}>
									Phones <span style={{marginLeft: 'auto'}}>({props.contact?.phoneCount})</span>
									</Label>
									{hasContactInfoPermission ? <TextBox
										style={textboxStyle}
										value={"-"}
										readOnly={true}
									/> : null}
								</FormControl>
								:
								null
						}

						{!props.loading && !props.findMode && contactInput?.phone?.sort((x,y)=>{
							if (x.is_primary && !state.editable) return -1
							else if (y.is_primary && !state.editable) return 1
							else return 0
						})?.map((phone, i) => {

							if (phone.is_deleted) return null

							return (

								<FormControl
									key={phone.number + "." + phone.id}
									data-testid={`contact-phone-${phone.label}`}
									fullWidth
								>

									<Label
										disableAnimation
										shrink className="flag-label"
										data-testid="contact-phone-label"
										style={{
											display: 'flex',
											width: '130%',
											alignItems: 'center',
											marginTop: "-0.3em",
											zIndex: 100
										}}
									>
										{ /* This is just the flag of the phone number.  */ }
										<PhoneInput
											disabled
											value={phone.number}
											containerStyle={{width: '10%'}}
										/>
										{getPhoneLabel(phone, i)}

										{phone.is_primary && <StyledBadge
											style={{marginLeft: '.5em', marginTop: 3}}
											overlap="circular"
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right',
											}}
											variant="dot"
										/>}
										<ClipboardToolTip
											onClick={copyToClipboard(formatPhone(phone))}
										/>
										{phone.is_primary && <span
											style={{marginLeft: 'auto'}}
										>({props.contact.phoneCount})
										</span>}
									</Label>

									<div style={
										(state.editable) ?
											{display: "flex", paddingTop: ".4em"} :
											{ paddingTop: ".4em" }
									}>
										{(state.editable) ?
											<IconButton
												type="button"
												aria-label="delete"
												style={{
													padding: "0px",
													height: "24px",
													width: "24px",
													marginTop: "26px",
													marginRight: "6px"
												}}
												title="Delete"
												onClick={(e) => {
													e.preventDefault()
													updateAttribute("phone", "is_deleted", (i), true)
												}}
												size="large">
												<CloseIcon/>
											</IconButton> :
											null
										}

										<div className="information"
											style={{
												display: 'flex',
												alignItems: 'center'
											}}>
											<PhoneInput
												style={{ width: '100%' }}
												placeholder=""
												value={`${phone.number}`}
												disabled={!state.editable}
												onChange={() => { }}
												inputStyle={(!state.editable) ? {
													border: "none",
													color: 'currentColor',
													font:'inherit'
												} : {font:'inherit'}}
												onClick={e => {
													if (state.editable) {
														setPhoneModal({open: true, phone, count: i+1})
													}
												}}
												data-testid="contact-phone"
											/>
											<span
												style={{
													marginLeft: '.5em',
													color: !props.isPrivate ? 'currentColor' : 'white',
													marginBottom: !state.editable ? '3px' : null,
													font:'inherit'
												}}
												data-testid="contact-phone-extension"
											>
												{phone.extension ? `x${phone.extension}` : null }
											</span>
										</div>

										{state.editable ?
											<IconButton
												type="button"
												aria-label="Mark As Primary"
												style={{
													padding: "0px",
													height: "42px",
													width: "42px",
													marginTop: "16px",
													marginLeft: "6px"
												}}
												title="Mark As Primary"
												onClick={(e) => {
													e.stopPropagation()
													togglePrimary("phone", i)
												}}
												size="large">
												<Radio
													name="radio-button-phone"
													checked={phone.is_primary}
												/>

											</IconButton>
											:
											null}

									</div>

								</FormControl>

							)
						})}

						{(state.editable && !props.findMode) ?
							<Fade in={true}>
								<div style={{marginTop: "1em", textAlign: 'center'}}>
									<Button onClick={() => {
										setNewPhoneModal({open: true, phone: {
											label: '',
											type_id: 2,
											number: ''
										}, count: contactInput?.phone?.length+1})
									}}>
										<AddCircleOutlineIcon style={{color: "grey"}}/> &nbsp; <span className="ci-span">Add a new Phone Number</span>
									</Button>
								</div>
							</Fade>
							:
							null
						}
					</div>

					<Divider orientation="vertical" flexItem />

					<div
						className="contact-information-email"
						style={columnStyle}
					>
						{(props.loading || props.error) ?
							<>
								<Skeleton
									animation={props.error ? false : "wave"}
									variant="text"
									width="auto"
									style={{height: 30}}
								/>
								<Skeleton
									animation={props.error ? false : "wave"}
									variant="text"
									width="auto"
									style={{height: 50}}
								/>
							</>
							: null}
						{ props.findMode ?
							<div>
								<FormControl fullWidth>
									<Label disableAnimation shrink>
										Primary Email
									</Label>
									<FindModeInput field="primary_email" />
								</FormControl>
								<FormControl fullWidth>
									<Label disableAnimation shrink>
										Other Emails
									</Label>
									<FindModeInput field="non_primary_emails" />
								</FormControl>
								<FormControl fullWidth>
									<Label disableAnimation shrink>
										All Emails
									</Label>
									<FindModeInput field="emails" />
								</FormControl>
							</div> :
							((contactInput?.email?.length === 0 && !props.loading && !props.error) || (contactInput?.email?.every((email) => email.is_deleted === true) && !props.loading && !props.error)) ?
								<FormControl fullWidth>
									<Label disableAnimation shrink style={{width: '130%', display: 'flex'}}>
									Emails <span style={{marginLeft: 'auto'}}>({props.contact?.emailCount})</span>
									</Label>
									{hasContactInfoPermission ? <TextBox
										style={textboxStyle}
										value={"-"}
										readOnly={true}
									/> : null}
								</FormControl>
								:
								null
						}

						{!props.loading && !props.findMode && contactInput?.email?.sort((x,y)=>{
							if (x.is_primary && !state.editable) return -1
							else if (y.is_primary && !state.editable) return 1
							else return 0
						})?.map((email, i) => {

							if (email.is_deleted) return null
							return (
								<FormControl
									key={email.email + " " + email.id} fullWidth
									data-testid={`contact-email-${email.label}`}
								>
									<Label
										disableAnimation
										shrink
										data-testid="contact-email-label"
										style={{
											display: 'flex',
											width: '130%',
											alignItems: 'center'
										}}
									>
										{getLabel(email, "Email", i, emailTypes)}
										{email.is_primary && <StyledBadge
											style={{marginLeft: '.5em', marginTop: 3}}
											overlap="circular"
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right',
											}}
											variant="dot"
										/>}
										<ClipboardToolTip
											onClick={copyToClipboard(email.email)}
										/>
										{email.is_primary && <span
											style={{marginLeft: 'auto'}}
										>({props.contact.emailCount})
										</span>}
									</Label>
									<TextBox
										style={textboxStyle}
										value={email.email}
										placeholder={"-"}
										readOnly={!state.editable}
										onClick={e => {
											if (e.target.tagName === "TEXTAREA" && state.editable) {
												setEmailModal({open: true, email, count: i+1})
											}
										}}
										multiline
										startAdornment={
											state.editable ? (
												<Fade in={true}>
													<InputAdornment position="start">
														<IconButton
															type="button"
															aria-label="delete"
															style={{
																padding: "0px"
															}}
															title="Delete"
															onClick={(e) => {
																e.preventDefault()
																updateAttribute("email", "is_deleted", (i), true)
															}}
															size="large">
															<CloseIcon/>
														</IconButton>
													</InputAdornment>
												</Fade>
											) : null
										}
										endAdornment={
											state.editable ? (
												<Fade in={true}>
													<InputAdornment position="end">
														<IconButton
															type="button"
															aria-label="Mark As Primary"
															style={{
																padding: "0px"
															}}
															title="Mark As Primary"
															onClick={(e) => {
																e.stopPropagation()
																togglePrimary("email", i)
															}}
															size="large">
															<Radio
																name="radio-button-email"
																checked={email.is_primary}
															/>

														</IconButton>
													</InputAdornment>
												</Fade>
											) : null
										}
										data-testid="contact-email"
									/>
								</FormControl>
							)
						})}

						{ (state.editable && !props.findMode) ? (
							<Fade in={true}>
								<div style={{marginTop: "1em", textAlign: 'center'}}>
									<Button onClick={() => {
										setNewEmailModal({open: true, email: {
											label: "",
											type_id: 1,
											email: ""
										}, count: contactInput?.email?.length+1})
									}}>
										<AddCircleOutlineIcon style={{color: "grey"}}/> &nbsp; <span className="ci-span">Add a new Email</span>
									</Button>
								</div>
							</Fade>
						) : null}
					</div>

					<Divider orientation="vertical" flexItem />

					<div
						className="contact-information-website"
						style={columnStyle}
					>

						{(props.loading || props.error) ?
							<>
								<Skeleton
									animation={props.error ? false : "wave"}
									variant="text"
									width="auto"
									style={{height: 30}}
								/>
								<Skeleton
									animation={props.error ? false : "wave"}
									variant="text"
									width="auto"
									style={{height: 50}}
								/>
							</>
							: null}

						{ props.findMode ?
							<div>
								<FormControl fullWidth>
									<Label disableAnimation shrink>
										Primary Website
									</Label>
									<FindModeInput field="primary_website" />
								</FormControl>
								<FormControl fullWidth>
									<Label disableAnimation shrink>
										Other Websites
									</Label>
									<FindModeInput field="non_primary_websites" />
								</FormControl>
								<FormControl fullWidth>
									<Label disableAnimation shrink>
										All Websites
									</Label>
									<FindModeInput field="websites" />
								</FormControl>
							</div> :
							((contactInput?.website?.length === 0 && !props.loading && !props.error) || (contactInput?.website?.every((website) => website.is_deleted === true) && !props.loading && !props.error)) ?
								<FormControl fullWidth>
									<Label disableAnimation shrink style={{width: '130%', display: 'flex'}}>
									Websites <span style={{marginLeft: 'auto'}}>({props.contact?.websiteCount})</span>
									</Label>
									{hasContactInfoPermission ? <TextBox
										style={textboxStyle}
										value={"-"}
										readOnly={true}
									/> : null}
								</FormControl>
								:
								null
						}

						{!props.loading && !props.findMode && contactInput?.website?.sort((x,y)=>{
							if (x.is_primary && !state.editable) return -1
							else if (y.is_primary && !state.editable) return 1
							else return 0
						})?.map((website, i) => {

							const currentWebsiteType = websiteTypes?.find(e =>
								e.id == website?.type_id
							)

							if (website.is_deleted) return null
							return (
								<FormControl
									key={ website.website + " " + website.id}
									fullWidth
									data-testid={`contact-website-${website.label}`}
								>
									<Label
										disableAnimation
										shrink
										data-testid="contact-website-label"
										style={{
											display: 'flex',
											width: '130%',
											alignItems: 'center'
										}}
									>
										{getLabel(website, "Website", i, websiteTypes)}
										{website.is_primary &&
												<StyledBadge
													style={{marginLeft: '.5em', marginTop: 3}}
													overlap="circular"
													anchorOrigin={{
														vertical: 'bottom',
														horizontal: 'right',
													}}
													variant="dot"
												/>}
										<ClipboardToolTip
											onClick={copyToClipboard(website.website)}
										/>
										{website.is_primary && <span
											style={{marginLeft: 'auto'}}
										>({props.contact.websiteCount})
										</span>}
									</Label>
									{!state.editable ?

										getWebsiteDisplay(website, currentWebsiteType)

										 :
										<TextBox
											style={textboxStyle}
											placeholder={"-"}
											readOnly={!state.editable}
											value={website.website}
											onClick={e => {
												if (e.target.tagName === "TEXTAREA" && state.editable) {
													setWebsiteModal({open: true, website, count: i+1})
												}
											}}
											multiline
											startAdornment={
												state.editable ? (
													<Fade in={true}>
														<InputAdornment position="start">
															<IconButton
																type="button"
																aria-label="delete"
																style={{
																	padding: "0px"
																}}
																title="Delete"
																onClick={(e) => {
																	e.preventDefault()
																	updateAttribute("website", "is_deleted", (i), true)
																}}
																size="large">
																<CloseIcon />
															</IconButton>
														</InputAdornment>
													</Fade>
												) : null

											}
											endAdornment={
												state.editable ? (
													<Fade in={true}>
														<InputAdornment position="end">
															<IconButton
																type="button"
																aria-label="Mark As Primary"
																style={{
																	padding: "0px"
																}}
																title="Mark As Primary"
																onClick={(e) => {
																	e.stopPropagation()
																	togglePrimary("website", i)
																}}
																size="large">
																<Radio
																	name="radio-button-website"
																	checked={website.is_primary}
																/>
															</IconButton>
														</InputAdornment>
													</Fade>
												) : null

											}
											data-testid="contact-website"
										/>
									}
								</FormControl>
							)
						})}

						{(state.editable && !props.findMode) ?
							<Fade in={true}>
								<div style={{marginTop: "1em", textAlign: 'center'}}>
									<Button onClick={() => {
										setNewWebsiteModal({open: true, website: {
											label: "",
											type_id: 1,
											website: ""
										}, count: contactInput?.website?.length+1})
									}}>
										<AddCircleOutlineIcon style={{color: "grey"}}/> &nbsp; <span className="ci-span">Add a new Website</span>
									</Button>
								</div>
							</Fade>
							:
							null
						}
					</div>

					<Divider orientation="vertical" flexItem />

					<div
						className="contact-information-address"
						style={{
							width: "24.5%",
							paddingLeft: "1em"
						}}
					>

						{(props.loading || props.error) ?
							<>
								<Skeleton
									animation={props.error ? false : "wave"}
									variant="text"
									width="auto"
									style={{height: 30}}
								/>
								<Skeleton
									animation={props.error ? false : "wave"}
									variant="text"
									width="auto"
									style={{height: 50}}
								/>
							</>
							: null}

						{ props.findMode ?
							<div className={classes.contactInformationRoot}>
								<FormControl fullWidth>
									<Label disableAnimation shrink>
										All Addresses
									</Label>
									<FindModeInput field="full_addresses" />
								</FormControl>
								<FindModeInput field="towns" placeholder="Town" />
								<FindModeInput field="regions" placeholder="Region" />
								<FindModeInput field="postcodes" placeholder="Postcode" />
								<FindModeInput field="countries" placeholder="Country" />
								<div><Label disableAnimation shrink>
									Primary Address
								</Label></div>
								<FindModeInput field="primary_town" placeholder="Town" />
								<FindModeInput field="primary_region" placeholder="Region" />
								<FindModeInput field="primary_postcode" placeholder="Postcode" />
								<FindModeInput field="primary_country" placeholder="Country" />

								<div><Label disableAnimation shrink>
									Other Addresses
								</Label></div>
								<FindModeInput field="non_primary_towns" placeholder="Town" />
								<FindModeInput field="non_primary_regions" placeholder="Region" />
								<FindModeInput field="non_primary_postcodes" placeholder="Postcode" />
								<FindModeInput field="non_primary_countries" placeholder="Country" />
							</div> :
							((contactInput?.address?.length === 0 && !props.loading && !props.error) || (contactInput?.address?.every((address) => address.is_deleted === true) && !props.loading && !props.error)) ?
								<FormControl fullWidth>
									<Label disableAnimation shrink style={{width: '130%', display: 'flex'}}>
									Addresses <span style={{marginLeft: 'auto'}}>({props.contact?.addressCount})</span>
									</Label>
									{hasContactInfoPermission ? <TextBox
										style={textboxStyle}
										value={"-"}
										readOnly={true}
									/> : null}
								</FormControl>
								:
								null
						}

						{!props.loading && !props.findMode && contactInput?.address?.sort((x,y)=>{
							if (x.is_primary && !state.editable) return -1
							else if (y.is_primary && !state.editable) return 1
							else return 0
						})?.map((address, i) => {
							if (!address) return null
							if (address.is_deleted) return null
							return (
								<FormControl
									key={address.country + "." + address.id}
									fullWidth
									data-testid={`contact-address-${address.label}`}
								>
									<Label
										disableAnimation
										shrink
										data-testid="contact-address-label"
										style={{
											display: 'flex',
											width: '130%',
											alignItems: 'center'
										}}
									>
										{getLabel(address, "Address", i, addressTypes)}
										{address.is_primary && <StyledBadge
											style={{marginLeft: '.5em', marginTop: 3}}
											overlap="circular"
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right',
											}}
											variant="dot"
										/>}
										<ClipboardToolTip
											onClick={copyToClipboard(getFormattedAddress(countries, address))}
										/>
										{address.is_primary && <span
											style={{marginLeft: 'auto'}}
										>({props.contact.addressCount})
										</span>}
									</Label>
									<TextBox
										style={textboxStyle}
										value={getFormattedAddress(countries, address)}
										placeholder={"-"}
										readOnly={!state.editable}
										multiline
										onClick={(e) => {
											if (e.target.tagName === "TEXTAREA" && state.editable) {
												setAddressModal({open: true, address, count: i+1})
											}
										}}
										startAdornment={
											state.editable ? (
												<Fade in={true}>
													<InputAdornment position="start">
														<IconButton
															type="button"
															aria-label="delete"
															style={{
																padding: "0px"
															}}
															title="Delete"
															onClick={(e) => {
																e.preventDefault()
																updateAttribute("address", "is_deleted", (i), true)
															}}
															size="large">
															<CloseIcon/>
														</IconButton>
													</InputAdornment>
												</Fade>
											) : null
										}
										endAdornment={
											state.editable ? (
												<Fade in={true}>
													<InputAdornment position="end">
														<IconButton
															type="button"
															aria-label="Mark As Primary"
															style={{
																padding: "0px"
															}}
															title="Mark As Primary"
															onClick={(e) => {
																e.stopPropagation()
																togglePrimary("address", i)
															}}
															size="large">
															<Radio
																name="radio-button-address"
																checked={address.is_primary}
															/>
														</IconButton>
													</InputAdornment>
												</Fade>
											) : null
										}
										data-testid="contact-address"
									/>
								</FormControl>
							)
						})}

						{(state.editable && !props.findMode) ?
							<Fade in={true}>
								<div style={{marginTop: "1em", textAlign: 'center'}}>
									<Button onClick={() => {
										setNewAddressModal({open: true, address: {
											label: '',
											type_id: 1,
											street_1: '',
											street_2: '',
											street_3: '',
											country_id: null,
											country: '',
											region: '',
											town: '',
											postcode: ''
										}, count: contactInput?.address?.length+1})
									}}>
										<AddCircleOutlineIcon style={{color: "grey"}}/> &nbsp; <span className="ci-span">Add a new Address</span>
									</Button>
								</div>
							</Fade>
							:
							null
						}
					</div>
				</div>

				<Menu
					keepMounted
					open={state.mouseY !== null}
					onClose={handleClose}
					anchorReference="anchorPosition"
					anchorPosition={
						state.mouseY !== null && state.mouseX !== null
							? { top: state.mouseY, left: state.mouseX }
							: undefined
					}
				>
					<MenuItem onClick={() => handleClose("edit")}>
						Edit
					</MenuItem>
				</Menu>
			</Paper>


			<EditPhoneModal
				phoneModal={phoneModal}
				setPhoneModal={setPhoneModal}
				contactInput={contactInput}
				setContactInput={setContactInput}

				phoneTypes={phoneTypes}
				isPrivate={props.isPrivate}
			/>

			<EditEmailModal
				emailModal={emailModal}
				setEmailModal={setEmailModal}
				contactInput={contactInput}
				setContactInput={setContactInput}

				emailTypes={emailTypes}
				isPrivate={props.isPrivate}
			/>

			<EditWebsiteModal
				websiteModal={websiteModal}
				setWebsiteModal={setWebsiteModal}
				contactInput={contactInput}
				setContactInput={setContactInput}

				websiteTypes={websiteTypes}
				isPrivate={props.isPrivate}
			/>

			<EditAddressModal
				addressModal={addressModal}
				setAddressModal={setAddressModal}
				contactInput={contactInput}
				setContactInput={setContactInput}

				addressTypes={addressTypes}
				isPrivate={props.isPrivate}
			/>

			<NewPhoneModal
				newPhoneModal={newPhoneModal}
				setNewPhoneModal={setNewPhoneModal}
				addToContactInput={addToContactInput}
				contactInput={contactInput}
				phoneTypes={phoneTypes}

				isPrivate={props.isPrivate}
				hasContactInfoPermission={hasContactInfoPermission}
				onContactDetailsPage
				contact={props.contact}
				setContact={props.setContact}
			/>

			<NewEmailModal
				newEmailModal={newEmailModal}
				setNewEmailModal={setNewEmailModal}
				addToContactInput={addToContactInput}
				contactInput={contactInput}
				emailTypes={emailTypes}

				isPrivate={props.isPrivate}
				hasContactInfoPermission={hasContactInfoPermission}
				onContactDetailsPage
				contact={props.contact}
				setContact={props.setContact}
			/>

			<NewWebsiteModal
				newWebsiteModal={newWebsiteModal}
				setNewWebsiteModal={setNewWebsiteModal}
				addToContactInput={addToContactInput}
				contactInput={contactInput}
				websiteTypes={websiteTypes}

				isPrivate={props.isPrivate}
				hasContactInfoPermission={hasContactInfoPermission}
				onContactDetailsPage
				contact={props.contact}
				setContact={props.setContact}
			/>

			<NewAddressModal
				newAddressModal={newAddressModal}
				setNewAddressModal={setNewAddressModal}
				addToContactInput={addToContactInput}
				contactInput={contactInput}
				addressTypes={addressTypes}

				isPrivate={props.isPrivate}
				hasContactInfoPermission={hasContactInfoPermission}
				onContactDetailsPage
				contact={props.contact}
				setContact={props.setContact}
			/>

		</React.Fragment>
	)
}
