/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useRef } from 'react'
import Notes from './Notes'
import { GET_HOME_NOTES } from './../../Notes/Queries'
import HomePageCard from '../HomePageCard'
import { withApollo } from '@apollo/client/react/hoc'
import { ITEMS_PER_HOME_PAGE_WIDGET } from '../../constants/values'

const NotesParent = (props) => {

	// like the "active" - but we're using it inside a callback instead of a effect...
	const mounted = useRef(false)
	useEffect(() => {
		mounted.current = true 
		return () => mounted.current = false
	}, [])

	// Order of table
	const [cursor, setCursor] = React.useState(null)
	const [steps, setSteps] = React.useState(1)
	const [activeStep, setActiveStep] = React.useState(0)
	const [searchLoading, setSearchLoading] = React.useState(false)
	const [totalItems, setTotalItems] = React.useState(0)

	const [notes, setNotes] = React.useState([])

	const handleSubmit = useCallback((cursor = 0) => {

		// If the user id falsy, don't execute the query yet
		if (!props.authState?.user?.id) return 

		setSearchLoading(true)

		const variables = {
			id: props.authState.user.id,
			cursor,
			limit: ITEMS_PER_HOME_PAGE_WIDGET,
			field: 'applied_at',
			direction: 'DESC',
		}

		props.client
			.query({
				query: GET_HOME_NOTES,
				variables
			})
			.then(result => {
				if (!mounted.current) return 		
				setNotes(result.data.searchHomeNotes?.items)
				setCursor(result.data.searchHomeNotes?.cursor)
				setTotalItems(result.data.searchHomeNotes?.totalItems)
				
				if (result.data.searchHomeNotes.items.length < 1) setSteps(1)
				else setSteps(Math.ceil(result.data.searchHomeNotes.totalItems / ITEMS_PER_HOME_PAGE_WIDGET))

				setSearchLoading(false)

			})
			.catch(error => {
				console.log(error)
			})
	}, [props.authState, props.client])


	// Search on page load
	useEffect(() => {
		handleSubmit(cursor)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<HomePageCard
			title="Recent Notes"
			paginate
			handleSubmit={handleSubmit}
			cursor={cursor}
			setCursor={setCursor}
			steps={steps}
			setSteps={setSteps}
			activeStep={activeStep}
			setActiveStep={setActiveStep}
			totalItems={totalItems}
		>			
			<Notes
				setCurrentNotes={setNotes}
				currentNotes={notes}
				loading={searchLoading || props.userLoading || !props.authState?.user?.id}
			/>
		</HomePageCard>
	)
}

export default withApollo(NotesParent)
