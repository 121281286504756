/* eslint-disable eqeqeq */
import React, { useContext } from 'react'
import { AuthStateContext } from '../store'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { permissionValues } from '../constants/permissions'
import { Tooltip } from '@mui/material'

const HasCardPermissionIcon = ({ permission }) => {

	const authState = useContext(AuthStateContext)

	const userPermissions = authState?.user?.permissions

	const currentPermission = userPermissions?.find(e => e.permission_id == permission)
		?.permission_value_id

	if (userPermissions && currentPermission != undefined && currentPermission < permissionValues.VIEW_ONLY) return (

		<Tooltip
			enterDelay={500}
			title={'You do not have permission to view these tags.'} arrow placement="top">
			<VisibilityOffIcon style={{
				color: 'grey',
				marginLeft: '0.4em',
				marginBottom: '-.1em'
			}}/>
		</Tooltip>
	)

	return null
}

export default HasCardPermissionIcon
