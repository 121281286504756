/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { GridColDef } from '@mui/x-data-grid-pro'
import { Button, CircularProgress, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Skeleton, Typography } from '@mui/material'
import { SearchTerm } from '../../common/SavedSearch'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { useApolloClient } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import TextBoxForAutocomplete from '../../input/Text/TextBoxForAutocomplete'
import LimitedAutocomplete from '../../common/LimitedAutocomplete'
import { autoCompleteErrorStyles } from '../../styles/makesStyles'
import { ExpandMoreRounded } from '@mui/icons-material'
import ContactThumbnail from '../../Thumbnail/ContactThumbnail'
import { getContactName } from '../../common/helpers'
import { formatForFuzzySearch } from '../../Search/unifiedSearchHelpers'
import ShowcaseDataGrid from '../ShowcaseDatagrid'
import ShowcaseSortOrder from '../ShowcaseSortOrder'
import { ESOrder } from '../../common/typescriptVars'
import { gql } from '@apollo/client'
import ControlPoint from '@mui/icons-material/ControlPoint'
import BusinessIcon from '@mui/icons-material/Business'
import PersonIcon from '@mui/icons-material/Person'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import CreateContactQuickView from '../../Contacts/CreateContactQuickView'

export const SHOWCASE_CONTACTS_SEARCH = gql`
	query searchDynamicContacts($field: String, $direction: SortDirection, $cursor: Int, $filters: [SearchFilter], $limit: Int) {
		searchDynamicContacts(input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: $filters}) {
			cursor
			totalItems
			items {
				id
				first_name
				last_name
				is_company
				company_name
				code_name
				is_private
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
			}
		}
	}
`

export const showcaseContactColumns: GridColDef[] = [
	{
		field: 'contact',
		headerName: '',
		width: 300,
		sortable: false,
		renderCell: (params: any) => <ContactThumbnail
			loading={false}
			contact={params.row}
			darkText={!params.row.is_private}
			style={{ lineHeight: 'initial' }}
		/>
	},
]

type ContactT = any
interface ContactData {
	data: {
		searchDynamicContacts: {
			items: ContactT[]
			totalItems: number
			cursor: number
		}
	}
}

const loadingSkeletons = Array.from({ length: 27 }, (_, i: number) => (
	<Typography variant='h4' key={i} style={{ paddingLeft: 15}}>
		<Skeleton animation='wave' width={Math.random() * 5 + 5 + 'em'} />
	</Typography>
))

const sortOptions = [
	{
		value: 'first_name-ASC',
		label: 'Contact First Name – A first'
	}, {
		value: 'first_name-DESC',
		label: 'Contact First Name – Z first'
	}, {
		value: 'last_name-ASC',
		label: 'Contact Last Name – A first'
	}, {
		value: 'last_name-DESC',
		label: 'Contact Last Name – Z first'
	}
]


export default function ShowcaseContacts (props: any) {


	const DEFAULT_LIMIT = 50

	const autoCompleteStyles = autoCompleteErrorStyles()

	// Autocomplete
	const [contactOpen, setContactOpen] = useState(false)
	const [contactOptions, setContactOptions] = useState([])
	const [loadContact, setLoadContact] = useState<string | false>(false)
	const contactLoading = contactOpen && !!loadContact
	const [contactAutocomplete, setContactAutocomplete] = useState<ContactT | null>(null)

	// Searching
	const [cursor, setCursor] = useState(0)
	const [contacts, setContacts] = useState<ContactT>([])
	const [loadedRows, setLoadedRows] = useState<any>([])
	const limit = DEFAULT_LIMIT
	const [orderBy, setOrderBy] = useState<string>('last_name')
	const [order, setOrder] = useState<ESOrder>('ASC')
	const [activeStep, setActiveStep] = useState(0)
	const [totalItems, setTotalItems] = useState(0)
	const [steps, setSteps] = React.useState(1)
	const [loading, setLoading] = useState(true)

	const client = useApolloClient()
	const navigate = useNavigate()
	const [newContactModal, setNewContactModal] = React.useState<null | string>(null)

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity: string, text: string) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, 	[dispatch])

	const closeSnackbar = () => dispatch({ type: 'closeSnackBar' })

	const filters: any[] = [{
		field: 'has_valid_name',
		type: 'eq',
		value: 'true',
		isOptional: false
	}]

	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)


	const handleActionClick = (event: any) => {
		setAnchorEl(event.currentTarget)
	}

	const handleActionClose = () => {
		setAnchorEl(null)
	}

	const createContactProps = {
		showcaseMode: true,
		isCompany: newContactModal === 'company',
		setCreateContact: (val: any) => setNewContactModal(val)
	}

	React.useEffect(() => {
		let active = true

		if (client && contactLoading) {
			client
				.query({
					query: SHOWCASE_CONTACTS_SEARCH,
					variables: {
						filters: [
							{
								field: 'query',
								type: 'eq',
								value: formatForFuzzySearch(loadContact),
								isOptional: false },
							...filters
						],
						cursor: 0,
						limit: 10,
						field: 'title',
						direction: 'ASC'
					},
				})
				.then((result: any) => {
					if (active) {
						setLoadContact(false)
						setContactOptions(result.data?.searchDynamicContacts?.items || [])
					}
				})
		}

		return () => {
			active = false
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadContact])

	const handleSubmit = (cursorOverride?: number, newFilters?: SearchTerm[]) => {

		setLoadedRows([])
		setLoading(true)
		
		client
			.query({
				query: SHOWCASE_CONTACTS_SEARCH,
				variables: {
					cursor: cursorOverride ?? cursor,
					limit,
					field: orderBy,
					direction: order,
					thumbnailResolution: '128x128',
					filters: [
						...filters,
						...(newFilters || [])
					],
				},
			})
			.then((result: ContactData) => {
				const { data } = result

				setLoading(false)
				if (data.searchDynamicContacts?.items) {
					setTotalItems(data.searchDynamicContacts.totalItems || 0)
					setContacts(data.searchDynamicContacts.items)

					if (data.searchDynamicContacts.items?.length < 1) setSteps(1)
					else setSteps(Math.ceil(data.searchDynamicContacts.totalItems / limit))

					if (data.searchDynamicContacts.totalItems) closeSnackbar()
				} else {
					console.error(data)
					openSnackbar(severity.ERROR, 'There was an error searching Contacts.')
				}
			})
			.catch((error: Error) => {
				console.error(error)
				setLoading(false)
				openSnackbar(severity.ERROR, 'There was an error retrieving Contacts.')
			})
	}
	
	// Search on initial page load, change of order
	useEffect(() => {
		handleSubmit(0, [
			contactAutocomplete ? {
				field: 'query',
				type: 'eq',
				value: formatForFuzzySearch(contactAutocomplete),
				isOptional: false } : null,
			...filters
		].filter(e => e))

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderBy, order])


	const handleOnRowsScrollEnd = (params: any) => {
 
		if (!contacts.length || loading) return
		if (totalItems <= DEFAULT_LIMIT) return

		setActiveStep((step: number) => step + 1)
		setCursor((step: number)=> (step + 1) * DEFAULT_LIMIT)

		const fullFilters = [ ...filters ]
		if (contactAutocomplete) {
			fullFilters.push({
				field: 'query',
				type: 'eq',
				value: formatForFuzzySearch(contactAutocomplete),
				isOptional: false
			})
		}

		client
			.query({
				query: SHOWCASE_CONTACTS_SEARCH,
				variables: {
					cursor: (activeStep + 1) * DEFAULT_LIMIT,
					limit,
					field: orderBy,
					direction: order,
					thumbnailResolution: '128x128',
					filters: fullFilters,
				},
			})
			.then((result: ContactData) => {
				const { data } = result

				if (data.searchDynamicContacts?.items) {
					setLoadedRows((rows: any[]) => rows.concat(data.searchDynamicContacts.items))
					setTotalItems(data.searchDynamicContacts.totalItems || 0)
					closeSnackbar()
				} else {
					console.error(data)
					openSnackbar(severity.ERROR, 'There was an error searching showcase contacts.')
				}
			})
			.catch((error: Error) => {
				console.error(error)
				openSnackbar(severity.ERROR, 'There was an error retrieving showcase contacts.')
			})
	}

	return (
		<>
			<div style={{ height: '100%'}}>
				<Paper className='qv-margin' sx={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(100% - 2em)', height: 'fit-content' }}>

					<div style={{
						display: 'flex',
						justifyContent: 'space-between',
						paddingBottom: '2em',
						alignItems: 'flex-end',
					}}>
						<Typography
							variant='h4'
							style={{
								paddingLeft: 10,
							}}
						>
						CONTACTS
						</Typography>

						<div
							style={{
								display: 'flex',
    						alignItems: 'flex-end',
							}}
						>
							<Button color="info" size="large" variant="contained" endIcon={<ControlPoint />} sx={{
								padding: '0.7em 1.5em',
								marginRight: '1em'
							}}
							onClick={(e) => handleActionClick(e)}
							>Create Contact</Button>

							<ShowcaseSortOrder
								order={order}
								orderBy={orderBy}
								onOrderChange={setOrder}
								onOrderByChange={setOrderBy}
								width="18em"
								sortOptions={sortOptions}
								largeSize
							/>
							<LimitedAutocomplete
								freeSolo
								query={loadContact}
								setQuery={setLoadContact}
								filterSelectedOptions
								forcePopupIcon
								popupIcon={<ExpandMoreRounded />}
								classes={autoCompleteStyles}
								open={contactOpen && !!contactOptions?.length}
								onOpen={() => setContactOpen(true)}
								onClose={() => setContactOpen(false)}
								getOptionLabel={(contact: any) => {
								// Free solo change
									if (typeof contact == 'string') return contact

									// User selected contact
									return getContactName(contact)
								}}
								options={contactOptions}
								loading={contactLoading}
								isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
								value={contactAutocomplete}
								onChange={(event: any, value: any) => {
									setContactAutocomplete(value)

									setLoading(true)
									if (value === null) {
										handleSubmit(0, [])
										setContactOptions([])
									} else if (typeof value == 'string') {
										handleSubmit(0, [{
											field: 'query',
											type: 'eq',
											value: `${value.split(' ').join('~1 ')}~1`,
											isOptional: false
										}])
									} else {
										navigate(`/showcase/contacts/${value.id}`)
									}
								}}
								renderInput={(params: any) => (
									<TextBoxForAutocomplete
										{...params}
										variant='outlined'
										fullWidth
										sx={{ backgroundColor: 'white' }}
										classes={{ notchedOutline: null }}
										InputLabelProps={{ shrink: true }}
										InputProps={{
											...params.InputProps,
											endAdornment: <>
												{contactLoading ? <CircularProgress color='inherit' size={20} /> : null}
												{params.InputProps.endAdornment}
											</>,
											style: { width: '30em' },
										}}
									/>
								)}
							/>
						</div>
					</div>

					{!loading ? <ShowcaseDataGrid 
						route="contacts" 
						rows={contacts} 
						loadedRows={loadedRows} 
						totalItems={totalItems} 
						loading={loading}
						handleOnRowsScrollEnd={handleOnRowsScrollEnd} 
						getRow={(row: any) => getContactName(row, true)?.toUpperCase()}
					/> : loadingSkeletons}

				</Paper>

				<Menu
					className="new-contact-showcase"
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleActionClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<MenuItem
						onClick={() => {
							handleActionClose()
							setNewContactModal('company')
						}}							
					>
						<ListItemIcon>
							<BusinessIcon />
						</ListItemIcon>
						<ListItemText primary="Company" />
					</MenuItem>
					<MenuItem
						onClick={() => {
							handleActionClose()
							setNewContactModal('person')
						}}
					>
						<ListItemIcon>
							<PersonIcon />
						</ListItemIcon>
						<ListItemText primary="Person" />
					</MenuItem>
				</Menu>
			</div>

			<TransitionsModal open={Boolean(newContactModal)} close={() => setNewContactModal(null)}>
				<CreateContactQuickView {...createContactProps}/>						
			</TransitionsModal>
		</>
	)
}
