/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useCallback } from "react"
import { AuthStateContext, DispatchContext } from '../../store'
import {
	Paper,
	FormControl,
	Menu,
	MenuItem,
	Select
} from "@mui/material"

import Dropdown from '../../input/Dropdown/Dropdown'
import "./ContactDetails.css"
import Label from '../../input/Label'
import TextBox from '../../input/Text/TextBoxThin'
import { useMutation } from '@apollo/client'
import { UPDATE_CONTACT } from "../Queries.js"
import makeStyles from '@mui/styles/makeStyles'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import SalespersonSelection from './Salesperson/SalespersonSelection'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { CancelButton, SaveCardButton } from '../../input/Buttons'
import { LookupContext } from '../../store'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { permissions, permissionValues } from "../../constants/permissions"
import sortBy from 'lodash/sortBy'
import GalleryContactEditMode from "../../common/GalleryContactEditMode"
import { Skelly } from "../../common/components/Skelly"
import { FindModeInput } from "../../navigation/Tabs/TabbedPage"
import MultiTypeModal from "../../common/MultiTypeModal"
import DatesModal from "./DatesModal"
import { formatDate } from "../../common/helpers"

const useStyles = makeStyles(theme => ({
	selection: {
		width: "100%",
	},
	input: {
		padding: '6px 12px',
	}
}))

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false
}

export default function Advanced(props) {

	const lookup = useContext(LookupContext)

	const regionsList= lookup.data?.getRegionTypes
	const activities= lookup.data?.getActivityTypes
	const grades= lookup.data?.getGradeTypes
	const types = lookup.data?.getContactTypes

	// Styles for mui components
	const classes = useStyles()

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const [state, setState] = React.useState(initialState)
	const [contactInput, setContactInput] = React.useState({})
	const [salespersonModal, setSalespersonModal] = React.useState(false)
	const [typeModal, setTypeModal] = React.useState(false)
	const [datesModal, setDatesModal] = React.useState(false)
	const [attempt, setAttempt] = React.useState(false)

	const [regionOptions, setRegionOptions] = React.useState([])
	const [regionSelection, setRegionSelection] = React.useState([])

	const authState = useContext(AuthStateContext)
	const userPermissions  = authState?.user?.permissions
	const [canViewSP, setCanViewSP] = React.useState(true)
	const [canEditSP, setCanEditSP] = React.useState(false)

	const createdUserId = props.contact?.created_user?.id
	useEffect(() => {
		if (userPermissions) {
			const permissionGalleryContact = userPermissions.find(e => e.permission_id == permissions.CONTACTS_SALESPEOPLE)?.permission_value_id
			if (permissionGalleryContact < permissionValues.VIEW_ONLY) setCanViewSP(false)
			const canUpdateGalleryContacts = (permissionGalleryContact == permissionValues.CREATE_AND_EDIT) || 
				(permissionGalleryContact == permissionValues.CREATE_AND_EDIT_OWN && 
					createdUserId == authState?.user?.id)
			setCanEditSP(canUpdateGalleryContacts)
		}
	}, [userPermissions, createdUserId])

	useEffect(() => {
		if (regionsList) setRegionOptions(regionsList)

	}, [regionsList])

	useEffect(() => {
		if (regionOptions && regionOptions.length && contactInput && contactInput.regions_ids) {
			setRegionSelection(regionOptions.filter(region => contactInput.regions_ids.includes(String(region.id))))
		}
	}, [contactInput, regionOptions])

	const [updateContact, { loading }] = useMutation(UPDATE_CONTACT, {

		onError: (error) => {

			console.error(error)

			// On failure, reset contactInput state, don't touch contact state and show error
			resetContactInput(props.contact)
			openSnackbar(severity.ERROR, "Could not update advanced card.")

			handleClose()
		},

		onCompleted(response) {
			if (response.updateContact.success === true) {

				// Success
				openSnackbar(severity[response.updateContact.severity], response.updateContact.message)

				// On success change contact state
				props.setContact(response.updateContact.contact)
				setAttempt(false)
				handleClose()

			} else {

				// On failure, reset contactInput state, don't touch contact state and show error
				resetContactInput(props.contact)
				openSnackbar(severity.ERROR, response.updateContact.message)

			}

		},
	})



	const resetContactInput = (contact) => {

		if (!contact || !Object.entries(contact).length) {
			return
		}

		const salespersons = contact.salesperson?.map((sp) => {
			return {
				id: sp.id,
				first_name: sp.first_name,
				last_name: sp.last_name,
				is_lead: sp.is_lead,
				is_deleted: sp.is_deleted,
				imgUrl: sp.imgUrl,
				user_handle: sp.user_handle
			}
		})

		const primary_salespersons = salespersons?.filter(e => e.is_lead)

		setContactInput({
			id: contact.id,
			types: contact.type,
			grade_id: contact.grade?.id,
			activity_id: contact.activity?.id,
			foreign_preferred_name: contact.foreign_preferred_name,
			regions_ids: contact.regions ? contact.regions.sort((a, b) => {
				return a.relationModified - b.relationModified
			}).map(region => region.id) : null,
			dates: contact.dates,
			salespersons,
			primary_salespersons,
		})

	}

	React.useEffect(() => {
		resetContactInput(props.contact)
	}, [props.contact])

	const handleClick = event => {
		if (state.mouseX || state.editable || props.findMode || !props.contact?.id) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4
		})
	}

	const handleClose = option => {
		if (option === "edit") {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	const typeDropdownValues = types?.filter(type =>
		!contactInput.types?.find(it => it.contact_type_id == type.id)
	)
	const sortedTypeDropdownValues = sortBy(typeDropdownValues, ['value'])

	return (
		<React.Fragment>
			<Paper
				className="qv-margin"
				onContextMenu={handleClick}
				id="contact-advanced"
				data-testid="card-advanced"
			>
				<h1 className="card-title">
					<span>Advanced</span>
					{state.editable && (
						<>
							<div className="spacer"></div>
							<CancelButton
								variant="contained"
								size="small"
								onClick={() => {
									setAttempt(false)
									resetContactInput(props.contact)
									handleClose()
								}}
							>
							Cancel
							</CancelButton>
							<SaveCardButton
								variant="contained"
								color="secondary"
								size="small"
								disabled={loading}
								onClick={() => {
									setAttempt(true)

									if (!contactInput.types?.length) {
										if (attempt) {
											openSnackbar(severity.WARNING,
												'Please fill fields in red.')
										}
										return
									}
									let updateData = {
										...contactInput,
										primary_salesperson_ids: contactInput.primary_salespersons?.map(salesperson => salesperson.id),
										salespersons_ids: contactInput.salespersons?.map(salesperson => salesperson.id),
										types: contactInput.types?.map(type => {
											if(type.id) {
												return {
													id: type.id,
													ordinal: parseInt(type.ordinal),
													contact_type_id: type.contact_type_id
												}
											}

											else if (type.contact_type_id) {
												return {
													ordinal: parseInt(type.ordinal),
													contact_type_id: type.contact_type_id
												}
											}

											return {
												ordinal: parseInt(type.ordinal),
												contact_type: type.contact_type,
											}

										}),
										regions_ids: regionSelection.map(region => `${region.id}`),
										dates: contactInput.dates?.map(date => ({
											id: date.id,
											contact_id: date.contact_id || contactInput.id,
											date: date.date,
											label: date.label,
										}))
									}

									delete updateData.primary_salespersons
									delete updateData.salespersons

									if (!canEditSP) {
										delete updateData.primary_salesperson_ids
										delete updateData.salespersons_ids
									}
									// Submit update
									updateContact({ variables: { ContactInput: updateData } })
								}}
							>
							Save
							</SaveCardButton>
						</>
					)}
				</h1>
				<div className="column-body">
					<div className="column">
						<FormControl className={classes.selection}>
							<Label id="type-label"
								disableAnimation shrink
								htmlFor="contact-type"
								error={attempt && state.editable && contactInput?.types?.length === 0}
							>
								Type
							</Label>
							{(props.loading || props.error) ? <Skelly error={props.error} /> :
								(props.findMode ? <FindModeInput field="contact_types" /> :
									<TextBox
										error={attempt && state.editable && contactInput?.types?.length === 0}

										inputProps={{ spellCheck: 'false' }}
										id="contact-types"
										value={
											contactInput.types?.map(item => {
												return item.contact_type
											}).join(", ") || ""
										}
										readOnly={!state.editable}
										placeholder={'-'}
										onClick={(e) => {
											if (state.editable) {
												setTypeModal(true)
											}
										}}
										data-testid="contact-type"
									/>
								)
							}
						</FormControl>

						<FormControl className={classes.selection}>
							<Label id="grade-label" disableAnimation shrink htmlFor="contact-grade">
								Grade{!props.findMode && '*'}
							</Label>
							{(props.loading || props.error) ? <Skelly error={props.error} /> :
								(props.findMode ? <FindModeInput field="contactGrade" /> :
									<Select
										IconComponent={ExpandMoreRoundedIcon}
										name="grade"

										labelId="grade-label"
										input={<Dropdown />}
										value={contactInput.grade_id || 'All'}
										className={(!state.editable) ? "readonly padded-select" : "padded-select"}
										readOnly={!state.editable}
										onChange={(e) => {
											setContactInput({
												...contactInput,
												grade_id:  (e.target.value === 'All') ? null : e.target.value
											})
										}}
										data-testid="contact-grade"
									>
										<MenuItem value={'All'} data-testid="contact-grade-item">Unassigned</MenuItem>
										{grades && grades.map(grade => (
											<MenuItem
												key={grade.id}
												value={grade.id}
												data-testid="contact-grade-item"
											>
												{grade.value}
											</MenuItem>
										))}
									</Select>
								)
							}
						</FormControl>

						<FormControl className={classes.selection}>
							<Label id="activity-label" disableAnimation shrink htmlFor="contact-activity">
							Activity
							</Label>
							{(props.loading || props.error) ? <Skelly error={props.error} /> :
								(props.findMode ? <FindModeInput field="contactActivity" /> :
									<Select
										IconComponent={ExpandMoreRoundedIcon}
										name="activity"

										labelId="activity-label"
										input={<Dropdown />}
										value={activities ? contactInput.activity_id || 'All' : 'All'}
										className={(!state.editable) ? "readonly padded-select" : "padded-select"}
										readOnly={!state.editable}
										onChange={(e) => {
											setContactInput({
												...contactInput,
												activity_id:  (e.target.value === 'All') ? null : e.target.value
											})
										}}
										data-testid="contact-activity"
									>
										<MenuItem value={'All'} data-testid="contact-activity-item">-</MenuItem>
										{activities && activities.map(activity => (
											<MenuItem
												key={activity.id}
												value={activity.id}
												data-testid="contact-activity-item"
											>
												{activity.value}
											</MenuItem>
										))}
									</Select>
								)
							}
						</FormControl>

						<FormControl>
							<Label disableAnimation shrink htmlFor="contact-foreign-name">
							Foreign Preferred Name
							</Label>
							{(props.loading || props.error) ? <Skelly error={props.error} /> :
								(props.findMode ? <FindModeInput field="foreign_preferred_name" /> :
									<TextBox
										id="contact-foreign-name"
										value={contactInput.foreign_preferred_name || ''}
										readOnly={!state.editable}
										placeholder={'-'}
										onChange={(e) => {
											setContactInput({
												...contactInput,
												foreign_preferred_name: e.target.value
											})
										}}
										data-testid="contact-foreign-preferred-name"
									/>
								)
							}
						</FormControl>
					</div>
					<div className="column">
						<FormControl>
							<Label id="dates-label" style={{fontSize: "18px"}} disableAnimation shrink>
								Dates
							</Label>
							{(props.loading || props.error) ? <Skelly error={props.error} /> :
								(props.findMode ? <FindModeInput field="dates" type="date-picker" openTo="year" views={['year', 'month', 'day']} disableFuture/> :
									<TextBox
										inputProps={{ spellCheck: 'false' }}
										id="contact-dates"
										multiline
										value={
											contactInput.dates?.sort((a,b) => {
												return a.created_at - b.created_at
											}).map(item => {
												return `${item.label}: ${formatDate(item.date, "MMMM do, yyyy")}`
											}).join("\n") || ""
										}
										readOnly={!state.editable}
										placeholder={'-'}
										onClick={(e) => {
											if (state.editable) {
												setDatesModal(true)
											}
										}}
										data-testid="contact-dates"
									/>
								)
							}
						</FormControl>

						<FormControl>
							<Label disableAnimation shrink htmlFor="contact-salesperson">
								Gallery Contacts
							</Label>
							{(props.loading || props.error) ? <Skelly error={props.error} /> :
								(props.findMode ? <FindModeInput field="gallery_contacts" /> :
									<GalleryContactEditMode
										setSalespersonModal={setSalespersonModal}
										salespersons={contactInput.salespersons}
										canViewSP={canViewSP}
										editable={state.editable && canEditSP}
									/>
								)
							}
						</FormControl>

						{ props.findMode && <FormControl>
							<Label disableAnimation shrink htmlFor="contact-date-labels">
							Date Labels
							</Label>
							{(props.loading || props.error) ?
								<Skelly error={props.error} /> :
								 <FindModeInput field="date_labels" />
							}
						</FormControl> }
					</div>
				</div>

				<Menu
					keepMounted
					open={state.mouseY !== null}
					onClose={handleClose}
					anchorReference="anchorPosition"
					anchorPosition={
						state.mouseY !== null && state.mouseX !== null
							? { top: state.mouseY, left: state.mouseX }
							: undefined
					}
				>
					<MenuItem onClick={() => handleClose("edit")}>Edit</MenuItem>
				</Menu>
			</Paper>

			<TransitionsModal
				className="salesperson-modal"
				open={salespersonModal}
				close={() => setSalespersonModal(false)}
			>
				<SalespersonSelection
					input={contactInput}
					setInput={setContactInput}
					salespersons={contactInput.salespersons}
					primary_salespersons={contactInput.primary_salespersons}
					close={() => setSalespersonModal(false)}
				/>
			</TransitionsModal>

			<MultiTypeModal
				sortedTypeDropdownValues={sortedTypeDropdownValues}
				attempt={attempt}
				setAttempt={setAttempt}
				typeModal={typeModal}
				setTypeModal={setTypeModal}
				loading={props.loading}
				error={props.error}
				isPrivate={props.contact.is_private}
				entityInput={contactInput}
				setEntityInput={setContactInput}
				type="contact_type"
				typeId="contact_type_id"
				keyName="types"
				title="Type"
			/>

			<DatesModal
				open={datesModal}
				close={() => setDatesModal(false)}
				contactInput={contactInput}
				setContactInput={setContactInput}
				isPrivate={props.contact?.is_private || false}
				loading={props.loading}
				error={props.error}
				attempt={attempt}
				setAttempt={setAttempt}
			/>

		</React.Fragment>
	)
}
