import { gql } from '@apollo/client'

export const GET_ALL_TYPES = gql`
	query {
		getArtCategories {
			id 
			value
		}
		getArtStatuses {
			id
			value
		}
		getDealEntryStatuses {
			id
			value
			deal_type {
				id
				value
			}
			deal_entry_status_category {
				id
				value
			}
			workflow_step
			workflow_end
			workflow_graph
		}
		getDealEntryStatusCategories {
			id
			value
			deal_type_id
		}
		getDealTypes {
			id
			value
		}
		getPrefixTypes {
			id
			value
		}
		getGalleryAccess {
			id
			value
		}
		getGradeTypes {
			id
			value
		}
		getContactRoles {
			id
			value
		}
		getContactTypes {
			id
			value
		}
		getActivityTypes {
			id
			value
		}
		getEmailTypes {
			id
			value
		}
		getPhoneTypes {
			id
			value
		}
		getWebsiteTypes {
			id
			value
			is_social
		}
		getRegionTypes {
			id
			value
		}
		getAddressTypes {
			id
			value
		}
		getCountryTypes {
			id
			name
			code
		}
		getInsurance {
			id
			value
		}
		getRelationshipTypes {
			id
			value
		}
		getListingTypes {
			id
			value
		}
		getSearchObjectTypes {
			id
			value
		}
		getContactStatus {
			id
			value
		}
		getDepartmentTypes {
			id
			value
		}
		getGalleryTypes {
			id
			value
		}
		getTaskStatusTypes {
			id
			value
		}
		getTaskTypes {
			id
			value
		}
		getNoteTypes{
			id
			value
		}
		getListingContactStatus {
			id
			value
			listing_type_id
		}
		getListingArtStatus {
			id
			value
			listing_type_id
		}
		getArtistGenres {
			id
			value
		}
		getArtCoaStatuses{
			id
			value
		}
		getCurrencies{
			id
			symbol
			label
			name
		}
		getUsageRights {
			id
			value
		}
		getImageTypes {
			id
			value
		}
		getArtFinancialTypes {
			id
			value
		}
		getTimezones {
			id
			abbr
			tzoffset
			value
		}
		getReservedCharacterArray {
			char
			search
		}
		getIdentityProviders {
			id
			name
			display_name
		}
		getImportStatuses{
			id
			value
		}
		getImportTypes{
			id
			value
		}
		getContinents{
			name
			code
		}
		getUserGroupTypes{
			id
			value
		}
	}
`
