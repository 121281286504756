/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { GET_GLOBAL_CONSIGNMENTS } from '../Queries'
import React, { useCallback, useEffect } from 'react'
import HomePageCard from '../HomePageCard'
import { withApollo } from '@apollo/client/react/hoc'
import { permissions, permissionValues } from '../../constants/permissions'
import GlobalTable from '../GlobalTable'
import { ITEMS_PER_HOME_PAGE_WIDGET } from '../../constants/values'
import useNavigation from '../../navigation/useNavigation'
import { useLocation, useNavigate } from 'react-router-dom'
import { GET_GLOBAL_CONSIGNMENTS_NAV } from '../../navigation/Queries'

const ConsignmentsParent = (props) => {

	const title = "Expiring Consignments"

	const { push: pushNav } = useNavigation()
	const navigate = useNavigate()
	const location = useLocation()
	let prevSearch = location.state
	if (prevSearch?.card != title) {
		prevSearch = null
	}

	// Order of table
	const [cursor, setCursor] = React.useState(prevSearch?.cursor ?? null)
	const [steps, setSteps] = React.useState(prevSearch?.steps ?? 1)
	const [activeStep, setActiveStep] = React.useState(prevSearch?.activeStep ?? 0)
	const [searchLoading, setSearchLoading] = React.useState(false)
	const [totalItems, setTotalItems] = React.useState(prevSearch?.totalItems ?? 0)

	const [consignments, setConsignments] = React.useState(prevSearch?.consignments ?? [])

	const user = props.authState?.user
	const userPermissions = user?.permissions

	const [checkbox, setCheckbox] = React.useState(false)

	let active = true
	const handleSubmit = useCallback((cursor = 0, checkbox) => {

		setSearchLoading(true)
		
		const variables = {
			cursor,
			limit: ITEMS_PER_HOME_PAGE_WIDGET,
			isSalesperson: checkbox
		}

		const dealPermission = userPermissions && userPermissions.find((element) => element.permission_id == permissions.DEALS)
		const artPermission = userPermissions && userPermissions.find((element) => element.permission_id == permissions.ART)

		// Check if current user has valid permissions for viewing global consignments
		if (dealPermission?.permission_value_id >= permissionValues.VIEW_ONLY &&
			artPermission?.permission_value_id >= permissionValues.VIEW_ONLY) {
			props.client
				.query({
					query: GET_GLOBAL_CONSIGNMENTS,
					variables
				})
				.then(result => {
					if (!active) return
					setConsignments(result.data.getGlobalConsignments?.items)
					setCursor(result.data.getGlobalConsignments?.cursor)
					setTotalItems(result.data.getGlobalConsignments?.totalItems)

					if (result.data.getGlobalConsignments.items.length < 1) setSteps(1)
					else setSteps(Math.ceil(result.data.getGlobalConsignments.totalItems / ITEMS_PER_HOME_PAGE_WIDGET))

					setSearchLoading(false)

				})
				.catch(error => {
					console.log(error)
				})
		} else {
			setConsignments([])
			setCursor(null)
			setSteps(1)
			setSearchLoading(false)
		}
	}, [props, userPermissions])

	function saveAndGo(path, row) {
		const state = updateHistory(row)
		const idField = path.includes('art') ? 'art_id' : 'deal_id'
		pushNav({
			url: location.pathname,
			state,
			query: GET_GLOBAL_CONSIGNMENTS_NAV,
			getId: (r => r?.[idField])
		}, state.variables.cursor)
		navigate(path, { state })
	}
	// Save Search
	function updateHistory (id) {
		const savedSearch = {
			cursor, consignments,
			steps, activeStep, totalItems,
			searchPage: location.pathname,
			card: title
		}
		const offset = consignments.findIndex(a => a.id == id)
		const nextcursor = activeStep * ITEMS_PER_HOME_PAGE_WIDGET + offset
		const variables = {
			id: props.id,
			cursor: nextcursor,
			limit: 3,
		}
		savedSearch.variables = variables
		navigate(location.pathname, { state: savedSearch })
		return savedSearch
	}


	// Search on page load
	useEffect(() => {
		handleSubmit(cursor, checkbox)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<HomePageCard
			title={title}
			paginate
			handleSubmit={handleSubmit}
			cursor={cursor}
			setCursor={setCursor}
			steps={steps}
			setSteps={setSteps}
			activeStep={activeStep}
			setActiveStep={setActiveStep}
			totalItems={totalItems}
			checkBox={true}
			checkBoxDescription={`Only show ${title.toLowerCase()} that you are the gallery contact for.`}
			checkbox={checkbox}
			setCheckbox={setCheckbox}
		>
			<GlobalTable
				name="consignment"
				expirationField="end_at"
				setCurrentArts={setConsignments}
				currentArts={consignments}
				loading={searchLoading || props.userLoading || !props.authState?.user?.id}
				user={props.authState?.user} // Will be used later for salespersons mapping
				saveAndGo={saveAndGo}
			/>
		</HomePageCard>
	)
}

export default withApollo(ConsignmentsParent)
