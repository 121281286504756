import EnhancedTableHead from '../../table/EnhancedTableHead'
import { Skeleton } from '@mui/material'
import { TableContainer, Table, TableRow, TableCell, TableBody } from '@mui/material'
import {  } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import React, { useCallback, useContext } from 'react'
import { returnDate, formatDate, getArtistThumbnailDetail } from '../../common/helpers'
import Thumbnail from '../../Thumbnail/Thumbnail'
import { differenceInDays, differenceInHours } from 'date-fns'
import compareAsc from 'date-fns/compareAsc'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import orderBy from 'lodash/orderBy'

import { severity } from '../../Snackbar/CustomizedSnackbar'
import { DispatchContext } from '../../store'
import GalleryContactList from '../../common/components/GalleryContactList'

function GlobalReserves(props) {

	// Cells for table
	const headCells = [
		{ id: 'artpiece', numeric: false, disablePadding: false, label: 'Artwork', noSort: true },
		{ id: 'salesperson', numeric: false, disablePadding: false, label: 'Gallery Contacts', noSort: true },
		{ id: 'expiration', numeric: false, disablePadding: false, label: 'Expiration', noSort: true },
		{ id: 'action', numeric: false, disablePadding: false, label: 'Actions', noSort: true },
	]

	const [currentRow, setCurrentRow] = React.useState(null)

	const noNav = (currentRow?.deal?.is_private || currentRow?.deal?.is_gallery_private) && !currentRow?.deal?.created_at
	const noNavArt = currentRow?.art?.is_private && !currentRow?.art?.created_at

	// Menu
	const [anchorEl, setAnchorEl] = React.useState(null)
	const handleClick = (event, row) => {
		setAnchorEl(event.currentTarget)
		setCurrentRow(row)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}
	
	const dispatch = useContext(DispatchContext)

	//Snackbar
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])
	
	return <>
		<div>
			<TableContainer style={{ overflowX: "hidden", paddingBottom: '2em' }}>
				<Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
					<EnhancedTableHead
						headCells={headCells}
						rowCount={props.currentArts?.length}
					/>
					<TableBody>

						{!props.loading && props.currentArts?.length === 0 ? 
							<TableRow>
								<TableCell></TableCell>
								<TableCell style={{ padding: '1em' }}>
                                        No global reserves found.
								</TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
							</TableRow>
							: null
						}

						{props.currentArts?.map((row, index) => {
                        
							let expiration
							let expired = false
                            
							// Get distance from today to reserve_end_date of each art piece.
							// If its expired return formatedDate, if it will expire en 3 days or less, show formatDistanceToNowStrict

							if (row?.reserve_end_at) {
								if (compareAsc(returnDate(row.reserve_end_at), new Date()) === -1) {
									expiration = formatDate(row.reserve_end_at, "MMMM do',' yyyy 'at' h':'mma")
									expired = true
								} else {
									if (differenceInDays(returnDate(row.reserve_end_at), new Date()) >= 3) {
										expiration = formatDate(row.reserve_end_at, "MMMM do',' yyyy 'at' h':'mma")
										expired = false
									} else {
										expiration = differenceInHours(returnDate(row.reserve_end_at), new Date()) + ' hours'
										expired = false
									}
                                    
								}
							}

							const hidden = row.art?.is_private && !row.art?.created_at

							const currentSalespersons = orderBy(
								row.deal?.salesperson,
								['is_lead'],
								['desc'],
							)
                            
							return (
								<TableRow key={row.id} selected={row === props.selectedRow}>
									<TableCell style={{ width: '15em' }}>
										<Thumbnail
											formatted_name={row.art?.formatted_title}
											name={row.art?.code_name}
											artist={getArtistThumbnailDetail(
												row.art
											)}
											date={row.art?.year}
											style={{ paddingLeft: '1em' }}
											avatar={row.art?.imgUrl}
											type="art"
											animation={
												props.loading
													? 'wave'
													: false
											}
											largeText	
											hasAccess={!hidden}
										></Thumbnail>
									</TableCell>

									<TableCell
										className="home-page-tasklist"
										component="th"
										scope="row"
										data-testid="contact-thumbnail"
										style={{ width: '10em' }}
									>
										<GalleryContactList
											salespersons={currentSalespersons}
											findMode={false}
											max={5}
										/>
									</TableCell>

									<TableCell style={{ width: '15em' }}>
										{row.reserve_end_at ? (
											<div style={expired ? { color: 'red' } : null}>
												{expiration}
											</div>
										) : (
											<Skeleton
												variant="text"
												width={props.totalItems ? '100%' : 'auto'}
												animation={props.loading ? 'wave' : false}
											/>
										)}
									</TableCell>

									<TableCell>
										{!row?.disabled ? (
											<IconButton
												aria-label="More"
												style={{ padding: '6px', marginRight: '-9px' }}
												onClick={(e) => {
													e.preventDefault()
													e.stopPropagation()
													handleClick(e, row)
												}}
												size="large">
												<MoreHorizIcon />
											</IconButton>
										) : null}
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem
						onClick={(e) => {

							if (noNav) {
								openSnackbar(severity.WARNING, "You do not have access to this Private Deal.")
							} else {
								props.saveAndGo(`/deals/${currentRow.deal_id}/details`, currentRow.id)
							}
							handleClose()
						}}>{currentRow?.is_private ? "Go to Private Deal" : "Go to Deal"}
					</MenuItem>

					<MenuItem
						onClick={(e) => {
                        
							if (noNavArt) {
								openSnackbar(severity.WARNING, "You do not have access to this Private Art.")
							} else {
								props.saveAndGo(`/art/${currentRow.art_id}/details`, currentRow.id)
							}

							handleClose()
						}}>{currentRow?.art?.is_private ? "Go to Private Art": "Go to Art"}
					</MenuItem>

				</Menu>
			</>
		</div>
	</>
}

export default GlobalReserves
