/* eslint-disable eqeqeq */
import React, { useContext, useMemo } from 'react'
import {
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Avatar,
	FormControl,
	Menu,
	MenuItem,
	CircularProgress,
	TextField,
	Tooltip,
} from '@mui/material'
import Thumbnail from '../Thumbnail/Thumbnail'
import { Skeleton, AvatarGroup, Autocomplete } from '@mui/material'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { CancelButton, SubmitButton } from '../input/Buttons'
import { GET_POSSIBLE_ACCESS_USERS } from './Queries'
import { withApollo } from '@apollo/client/react/hoc'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { autoCompleteStyles } from '../styles/makesStyles'
import Label from '../input/Label'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import {permissionValues, permissions} from '../constants/permissions'
import { AuthStateContext, DispatchContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import EditOff from '@mui/icons-material/EditOff'
import { useRecoilValue } from 'recoil'
import { findModeAtom } from '../navigation/Tabs/TabbedPage'

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false,
}

const PrivateAccessUsers = (props) => {

	const entity = useMemo(() => props.input || {}, [props.input])
	const [state, setState] = React.useState(initialState)
	const [userAccessModal, setUserAccessModal] = React.useState(false)

	const [open, setOpen] = React.useState(false)
	const [options, setOptions] = React.useState([])
	const [selection, setSelection] = React.useState([])
	const [loading, setLoading] = React.useState(false)

	const editableClasses = autoCompleteStyles()

	const findMode = useRecoilValue(findModeAtom)

	const authState = useContext(AuthStateContext)
	const userPermissions  = authState?.user?.permissions

	const adminConsoleAccess = userPermissions?.find(e => e.permission_id == permissions.ADMIN_CONSOLE)?.permission_value_id == permissionValues.YES
	const privateTogglePermission = userPermissions?.find(e => e.permission_id == permissions.PRIVATE_OBJECT_TOGGLE)?.permission_value_id == permissionValues.YES
	const privateObjectAccess = userPermissions?.find(e => e.permission_id == permissions.PRIVATE_OBJECT_ACCESS)?.permission_value_id == permissionValues.YES

	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({type: 'openSnackBar', payload: {severity, text}})
	}

	React.useEffect(() => {
		if (entity?.restrictedToUsersReplace) {
			setSelection(entity.restrictedToUsersReplace)
			setOptions(entity.restrictedToUsersReplace)
		}
	}, [entity])

	React.useEffect(() => {

		let active = true

		if (open == true) {
			setLoading(true)
			props.client
				.query({
					query: GET_POSSIBLE_ACCESS_USERS,
					variables: {
						id: entity.id,
						entity: props.entityType,
						galleries: props.entityType == 'deal' ? props.input?.gallery_access?.map(e => e.id) : []
					}
				})
				.then((result) => {
					if (active) {
						setLoading(false)
						setOptions(result.data.getPossibleUserAccess.filter(
							e => !props.users.find(user => user.id == e.id)))
					}
				})
		}

		return () => {
			active = false
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entity.id, open, props.entityType])

	React.useEffect(() => {
		let active = true
		let objectTypeId = 0

		if (active && entity.id && props.entityType) {

			switch (props.entityType) {
			case 'art':
				objectTypeId = 1
				break
			case 'contact':
				objectTypeId = 2
				break
			case 'deal':
				objectTypeId = 3
				break
			default:
				break
			}

			props.getUsers(entity.id, objectTypeId)
		}
		return () => {
			active = false
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entity.id])

	let noPermissions = false
	// Users without private toggle permission and admin console access cannot limit deal access
	if (props.entityType == "deal" && (!privateObjectAccess || (!privateTogglePermission && !adminConsoleAccess))) {
		noPermissions = true
	}

	// Users without private toggle permission and admin console access cannot limit deal access
	if (props.entityType == "art" && (!privateObjectAccess || !privateTogglePermission)) {
		noPermissions = true
	}

	// Users without private toggle permission and admin console access cannot limit deal access
	if (props.entityType == "contact" && (!privateObjectAccess || (!privateTogglePermission && !props.publicAccess))) {
		noPermissions = true
	}



	const handleClick = (event) => {
		if (state.mouseX || state.editable || findMode || !props.input?.id) return

		// Users without private toggle permission and admin console access cannot limit deal access
		if (noPermissions) {
			openSnackbar(severity.INFO, `You do not have permission to adjust ${props.entityType} access.`)
			event.preventDefault()
			event.stopPropagation()
			return
		}

		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const handleClose = (option) => {
		if (option === 'edit') {
			setUserAccessModal(true)
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	const assistantSet = new Set(props.users.filter(u => u.assistants).flatMap(u => u.assistants.map(a => a.id)))

	return <Paper
		className="qv-margin" 
		onContextMenu={(e) => !state.mouseX ? handleClick(e) : null}
		data-testid="card-users"
	>
		<h1 className="card-title">
			<span>Users

				{!findMode && noPermissions ?
					<Tooltip
						enterDelay={500}
						title={'You do not have sufficient permissions.'} arrow placement="bottom">
						<EditOff style={{
							color: 'grey',
							marginLeft: '0.3em',
							marginBottom: '-0.1em'
						}}/>
					</Tooltip>
					: null}
			</span>
		</h1>

		<TableContainer id="user-access-table">
			<Table aria-label="relationships table" size="small">
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>Private Object Admin</TableCell>
						<TableCell>Gallery</TableCell>
						<TableCell>Department</TableCell>
						<TableCell>Title</TableCell>
						<TableCell>Assistants With Access</TableCell>
					</TableRow>
				</TableHead>
				{entity ? (
					<TableBody>
						{/* entitys, or empty table row */}
						{props.users.length ? (
							<>
								{props.users.filter(u => !assistantSet.has(u.id)).map(
									user => {
										return (
											<TableRow
												key={`user-${user.id}`}
											>
												<TableCell>
													<Thumbnail
														name={`${user.first_name} ${user.last_name}`}
														avatar={user.imgUrl}
														hideDetails
													/>
												</TableCell>
												<TableCell>

													{user.permissions?.find(e => {
														if (e.permission_id == permissions.PRIVATE_OBJECT_ADMIN
														&& e.permission_value_id == permissionValues.YES) return true
														else return false
													}) ?
														<GroupAddIcon fontSize="large" style={{color: 'grey'}}/>
														: (<span> - </span>)}

												</TableCell>
												<TableCell>
													{ user.gallery ? <span>{user.gallery}</span> : <span>-</span> }
												</TableCell>
												<TableCell>
													{ user.department ? <span>{ user.department }</span> : <span>-</span> }
												</TableCell>
												<TableCell>
													{ user.title ? <span>{user.title}</span> : <span>-</span> }
												</TableCell>
												<TableCell>
													{user.assistants?.length ?
														<AvatarGroup max={4}>
															{user.assistants.filter(user => {
																const PrivateObjectAccess = '6'
																const accessPerm = user.permissions
																	?.find(p => p.permission_id === PrivateObjectAccess)
																return accessPerm?.permission_value_id === '1' // YES
															}).map(assistant => (
																<Tooltip
																	key={assistant.id}
																	title={`${assistant.first_name} ${assistant.last_name}`}
																	arrow
																>
																	<Avatar
																		alt={`${assistant.first_name} ${assistant.last_name}`}
																		src={assistant.imgUrl}
																	>
																		{!assistant.image && assistant.first_name && assistant.last_name
																			? assistant.first_name[0] + assistant.last_name[0]
																			: null}
																	</Avatar>
																</Tooltip>
															))}
														</AvatarGroup>
														: <span>-</span>}

												</TableCell>
											</TableRow>
										)
									}
								)}
							</>
						) : (
							<TableRow>
								<TableCell></TableCell>
								<TableCell style={{padding: '1em'}}>No Users found</TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
							</TableRow>
						)}
					</TableBody>
				) : (
					<TableBody>
						<TableRow>
							<TableCell>
								<Thumbnail largeText animation="wave" />
							</TableCell>
							<TableCell>
								<Skeleton
									animation={'wave'}
									variant="text"
									width="75%"
								/>
							</TableCell>
							<TableCell>
								<Skeleton
									animation={'wave'}
									variant="text"
									width="75%"
								/>
							</TableCell>
							<TableCell>
								<Skeleton
									animation={'wave'}
									variant="text"
									width="75%"
								/>
							</TableCell>
							<TableCell>
								<Skeleton
									animation={'wave'}
									variant="text"
									width="75%"
								/>
							</TableCell>
							<TableCell>
								<Skeleton
									animation={'wave'}
									variant="text"
									width="75%"
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				)}
			</Table>
		</TableContainer>

		<TransitionsModal
			className="user-access-modal"
			open={userAccessModal}
			close={() => {
				setUserAccessModal(false)
				setState(initialState)
			}}
		>
			<form
				style={{ width: '30em' }}
				onSubmit={(e) => {
					e.preventDefault()
					props.update({
						id: entity.id,
						restrictedToUsersReplace: selection.map(e => e.id)
					})
					setUserAccessModal(false)
					setState(initialState)
				}}
			>
				<h1 className="card-title" style={props.input?.is_private ? {color: '#FFFFFF'} : null }>Add Users</h1>
				<FormControl className="user-access-formcontrol fullWidth">
					<Label disableAnimation shrink htmlFor="user-access-autocomplete">
						User Access
					</Label>
					<Autocomplete
						multiple
						id="user-access-autocomplete"
						open={open}
						classes={editableClasses}
						onOpen={() => {
							setOpen(true)
						}}
						onClose={() => {
							setOpen(false)
						}}
						isOptionEqualToValue={(option, value) => 
							option.id == value?.id
						}
						filterSelectedOptions
						getOptionLabel={(option) => {
							return !option.first_name ? 'No Options' : `${option.first_name} ${option.last_name}`
						}}
						options={options}
						value={selection}
						forcePopupIcon
						getOptionDisabled={(option) => {
							return option.disabled
						}}
						popupIcon={<ExpandMoreRoundedIcon />}
						loading={loading}
						onChange={(event, value) => {
							setSelection(value)
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								id="user-access-autocomplete"
								variant="outlined"
								fullWidth
								style={{ paddingTop: '1.8em' }}
								classes={{ notchedOutline: null }}
								InputLabelProps={{ shrink: true }}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{ loading ? <CircularProgress color="inherit" size={20} /> : null }
											{ params.InputProps.endAdornment }
										</React.Fragment>
									),
									style: {
										paddingTop: '3px',
										paddingBottom: '3px',
									},
								}}
							/>
						)}
					/>
				</FormControl>
				<div
					style={{
						width: '100%',
						paddingBottom: '1em',
						paddingTop: '1em',
					}}
				>
					<CancelButton
						variant="contained"
						style={{ float: 'left' }}
						onClick={(e) => {
							setSelection(entity.restrictedToUsersReplace)
							setUserAccessModal(false)
							setState(initialState)
						}}
					>
						Cancel
					</CancelButton>

					<SubmitButton
						variant="contained"
						style={{ float: 'right' }}
						type="submit"
					>
						Submit
					</SubmitButton>
				</div>
			</form>
		</TransitionsModal>

		<Menu
			keepMounted
			open={state.mouseY !== null}
			onClose={handleClose}
			anchorReference="anchorPosition"
			anchorPosition={
				state.mouseY !== null && state.mouseX !== null
					? { top: state.mouseY, left: state.mouseX }
					: undefined
			}
		>
			<MenuItem onClick={() => handleClose('edit')}>Edit</MenuItem>
		</Menu>
	</Paper>
}

export default withApollo(PrivateAccessUsers)
