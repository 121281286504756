/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react"
import {
	Paper,
	FormControl,
	Menu,
	MenuItem,
	Select,
	TextField,
	CircularProgress,
} from "@mui/material"
import Dropdown from '../../input/Dropdown/ThinDropdown'
import Label from '../../input/Label'
import TextBox from '../../input/Text/TextBox'
import { Autocomplete } from '@mui/material'
import { CancelButton, SaveCardButton } from '../../input/Buttons'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { AuthStateContext, LookupContext } from '../../store'
import Thumbnail from "../../Thumbnail/Thumbnail"
import { formatDate, getGalleryContactLabel } from "../../common/helpers"
import { autoCompleteErrorStyles } from "../../styles/makesStyles"
import { GET_USERS } from "../../User/Queries"
import { withApollo } from '@apollo/client/react/hoc'
import GalleryContactEditMode from "../../common/GalleryContactEditMode"
import { Skelly } from "../../common/components/Skelly"
import { FindModeInput } from "../../navigation/Tabs/TabbedPage"
import { dealTypes } from "../../constants/values"
import { permissions, permissionValues } from '../../constants/permissions'

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false
}

function DealDetailsCard(props) {
	
	const classes2 = autoCompleteErrorStyles()

	const lookup = useContext(LookupContext)
	const types = lookup.data?.getDealTypes 

	const [state, setState] = useState(initialState)
	const [dealInput, setDealInput] = useState({})
	const [attempt, setAttempt] = useState(false)

	const authState = useContext(AuthStateContext)
	const userPermissions = authState?.user?.permissions || []

	const galleryContactPermission = userPermissions.find(p => p.permission_id == permissions.DEALS_SALESPEOPLE)
	const canUpdateGalleryContacts = (galleryContactPermission?.permission_value_id == permissionValues.CREATE_AND_EDIT) || 
		(galleryContactPermission?.permission_value_id == permissionValues.CREATE_AND_EDIT_OWN && 
			props.deal?.created_by == authState?.user?.id)


	// Gallery Contacts/Salespersons
	const [users, setUsers] = React.useState([])
	const [optionsDropdown, setOptionsDropdown] = React.useState(false)
	const optionsListLoading = optionsDropdown && users?.length === 0
	React.useEffect(() => {
		let active = true
		if (!optionsListLoading || props.findMode) {
			return undefined
		}
		props.client
			.query({ 
				query: GET_USERS,
				variables: {
					includeDeleted: true
				}
			})
			.then(result => {
				if (active) {
					setUsers(result.data.getUsers.items)	
				}
			})
		return () => active = false
	}, [optionsListLoading, props.client, props.findMode])


	const resetDealInput = (deal) => {
		const salespersons = deal.salesperson
			?.map((sp) => ({
				id: sp.id,
				first_name: sp.first_name,
				last_name: sp.last_name,
				is_lead: sp.is_lead,
				is_deleted: sp.is_deleted,
				imgUrl: sp.imgUrl,
				user_handle: sp.user_handle
			}))
		
		setDealInput({
			id: deal.id,
			type_id: deal.type_id,
			salespersons,
			code_name: deal.code_name
		})
	}

	useEffect(() => {
		resetDealInput(props.deal)
	}, [props.deal])

	const handleClick = event => {
		if (state.mouseX || state.editable || props.findMode) return

		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
			editable: false,
		})
	}

	const handleClose = option => {
		if (option === "edit") {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	return <>
		<Paper
			className="qv-margin"
			onContextMenu={(e) => (!state.mouseX && !props.findMode) ? handleClick(e) : null}
			id="contact-details"
			data-testid="card-details"
		>
			<h1 className="card-title">
				<span>Details</span>
				{(state.editable && !props.findMode) && (
					<>
						<div className="spacer"></div>
						<CancelButton
							variant="contained"
							size="small"
							onClick={() => {
								resetDealInput(props.deal)
								handleClose()
								setAttempt(false)
							}}
						>
                        Cancel
						</CancelButton>
						<SaveCardButton
							variant="contained"
							color="secondary"
							size="small"
							disabled={props.disabled}
							onClick={() => {
								const { salespersons, ...newDeal} = dealInput
								if (canUpdateGalleryContacts) {
									newDeal.salespersons_ids = salespersons.map(s => s.id)
								}
								props.onSave(newDeal)
								setState({
									...state,
									editable: false
								})
							}}
						>
                        Save
						</SaveCardButton>
					</>
				)}
			</h1>
			<div className="column-body">
				<div className="column">

					<FormControl>
						<Label id="type-label" disableAnimation shrink 
							error={attempt && state.editable && dealInput?.type_id === undefined}>
                        Type
						</Label>
						{props.findMode ? <FindModeInput field="deal_type" />
							: (props.loading || props.error) ? <Skelly error={props.error} /> :
								<Select
									IconComponent={ExpandMoreRoundedIcon}
									name="type"
									
									labelId="type-label"
									input={<Dropdown />}
									value={types ? (dealInput?.type_id ?? '' ) : ''}
									className={'readonly'}
									readOnly={true}
									data-testid="deal-type"
								>
									{types && types.map(type => (
										<MenuItem 
											key={type.id} 
											value={type.id}
											data-testid="deal-type-item"
										>
											{type.value}
										</MenuItem>
									))}
								</Select>
						}
                    
					</FormControl>

					<FormControl>
						<Label disableAnimation shrink>
                            Created By
						</Label>
						{props.findMode ? <FindModeInput field="creator_name" />
							: (props.loading || props.error) ? <Skelly error={props.error} /> :
								<Thumbnail style={{marginTop: '2em'}}
									type="contact"
									name={
										props.deal?.created_user?.first_name + ' ' + props.deal?.created_user?.last_name
									}
									detail={props.deal?.created_user?.gallery}
									avatar={props.deal?.created_user?.imgUrl}
									data-testid="date-created-by"
								></Thumbnail> }
					</FormControl>

					<FormControl>
						<Label disableAnimation shrink 
							htmlFor="deal-created-date">
                        Created Date
						</Label>
						{props.findMode ? <FindModeInput field="created_at" type="date-picker" />
							: (props.loading || props.error) ? <Skelly error={props.error} /> :
								<TextBox
									id="deal-created-date"
									value={props.deal?.created_at ? formatDate(props.deal?.created_at, 'MMM d, yyyy') : '-'}
									readOnly={ true }
									placeholder={'-'}
									data-testid="deal-created-date"
								/>
						}
					</FormControl>

				</div>
				<div className="column">
					<FormControl>
						<Label
							disableAnimation
							shrink
							htmlFor="contact-salesperson"
						>
                            Gallery Contacts
						</Label>
						{props.findMode ? <FindModeInput field="gallery_contacts" />
							: (props.loading || props.error) ? <Skelly error={props.error} /> :
								( (state.editable && canUpdateGalleryContacts) ?
									<Autocomplete
										multiple
										readOnly={!state.editable}
										classes={classes2}
										style={{marginTop: "1.75em"}}
										open={optionsDropdown}
										isOptionEqualToValue={(option, value) => {
											return option.id == value.id
										}}
										forcePopupIcon
										filterSelectedOptions
										popupIcon={<ExpandMoreRoundedIcon />}
										size="small"
										value={dealInput.salespersons ?? []}
										onOpen={() => { setOptionsDropdown(true) }}
										onClose={() => { setOptionsDropdown(false)}}
										disabled={props.deal.type_id == dealTypes.known_ownership}
										getOptionLabel={option => {
											try {
												return getGalleryContactLabel(option)
											} catch {
												return "Loading..."
											}
										}}
										options={users || []}
										loading={optionsListLoading}
										onChange={(event, value) => {
											setDealInput({
												...dealInput,
												salespersons: value
											})
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												fullWidth
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<React.Fragment>
															{optionsListLoading ?
																<CircularProgress color="inherit" size={20} /> :
																null}
															{params.InputProps.endAdornment}
														</React.Fragment>
													),
												}}
											/>
										)}
										data-testid="deal-gallery-contacts"
									/> : 
									<GalleryContactEditMode
										setSalespersonModal={() => {}}
										salespersons={dealInput.salespersons}
										canViewSP={true}
										editable={state.editable && canUpdateGalleryContacts}
									/>
								)}
					</FormControl>

					<FormControl style={{marginTop: '.2em'}}>
						<Label disableAnimation shrink htmlFor="deal-code-name" >
                            Code Name
						</Label>
						{props.findMode ? <FindModeInput field="code_name" />
							: (props.loading || props.error) ? <Skelly error={props.error} /> :
								<TextBox
									required								
									id="deal-code-name"
									value={dealInput.code_name || ''}
									readOnly={!state.editable}
									placeholder={'-'}
									onChange={(e) => {
										setDealInput({
											...dealInput,
											code_name: e.target.value
										})
									}}
									data-testid="deal_code_name"
								/>
						}
					</FormControl>
				</div>
			</div>

			<Menu
				keepMounted
				open={state.mouseY !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					state.mouseY !== null && state.mouseX !== null
						? { top: state.mouseY, left: state.mouseX }
						: undefined
				}
			>
				<MenuItem onClick={() => handleClose("edit")}>Edit</MenuItem>
			</Menu>
		</Paper>
	</>
}

export default withApollo(DealDetailsCard)
