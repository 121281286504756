import withStyles from '@mui/styles/withStyles'
import InputLabel from '@mui/material/InputLabel'

const Label = withStyles(theme => ({
	root: {
		fontSize: "18px",
		paddingTop: "1em",
		marginLeft: '-0.75em'
	}
}))(InputLabel)

export default Label
