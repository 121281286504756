const { GET_UPLOAD_LINKS } = require("./queries")

/**
 * This callback is displayed as a global member.
 * @callback uploadCallback
 * @param {*} newImage The image object that has been uploaded
 */

/**
 * Event handler to upload 1 or more images to s3.
 * @param {*} query withApollo _this.props.query_
 * @param {React.ChangeEvent<HTMLInputElement>} event
 * @param {uploadCallback=} callback Optional function called for each successful upload.
 * @returns {Promise}
 */
export async function uploadImages(query, event, callback, oldProfileLinks) {
	
	const files = event.target.files || event.dataTransfer.files

	const newImages = await Promise.all([...files].map(file => ({
		file,
		newImage: {
			key: null,
			filename: file.name,
			filesize: file.size,
			filetype: file.type,
			dimension: '',

			type_id: file.type_id || null,
			ordinal: file.ordinal || null,
			credit: file.credit || null,
			caption: file.caption || null,
			photographer: file.photographer || null,
			alt_text: file.alt_text || null,
			usage_right_id: file.usage_right_id || null,

			delete: false
		}
	})).map(({file, newImage}, i) =>
		new Promise((resolve, reject) => {
			const img = new Image()
			img.onload = function() {
				resolve({
					file,
					newImage,
					headers: {
						contentType: file.type,
						metadata: {
							'width': `${this.width}`,
							'height': `${this.height}`,
							'filename': encodeURI(newImage.filename)
						},
						profile_link: oldProfileLinks?.[i]
					}
				})
			}
			if (FileReader && file) {
				const fr = new FileReader()
				fr.onload = function () {
					img.src = fr.result
					newImage.src = fr.result
				}
				fr.readAsDataURL(file)
			} else {
				reject('no file.')
			}
		})
	))

	const uploadLinks = query({
		query: GET_UPLOAD_LINKS,
		variables: { headers: newImages.map(n => n.headers) },
	})

	return uploadLinks.then(({data: { getUploadLink }}) => {
		return Promise.all(getUploadLink.map((link, i) => {
			let { file, newImage, headers } = newImages[i]
			newImage.key = link.key
			newImage.dimension = `${headers.metadata.width} × ${headers.metadata.height}`
			return fetch(link.url, {
				method: 'PUT',
				body: file,
				headers: {
					'Content-type': headers.contentType,
					'x-amz-meta-width': headers.metadata.width,
					'x-amz-meta-height': headers.metadata.height,
					'x-amz-meta-filename': headers.metadata.filename
				}
			}).then(() => {
				let image = { ...newImage }
				callback && callback(image)
				return image
			}).catch((error) => {
				console.error(error)
			})
		}))
	})
}

/**
 * This callback is displayed as a global member.
 * @callback uploadCallback
 * @param {*} newFile The file object that has been uploaded
 */

/**
 * Event handler to upload 1 or more files to s3.
 * @param {*} query withApollo _this.props.query_
 * @param {React.ChangeEvent<HTMLInputElement>} event
 * @param {uploadCallback=} callback Optional function called for each successful upload.
 * @returns {Promise}
 */
export async function uploadFiles(query, event, callback) {

	const files = event.files || event.target.files || event.dataTransfer.files

	const uploadLinks = query({
		query: GET_UPLOAD_LINKS,
		variables: { headers: [...files].map(f =>
			({
				contentType: f.type,
				metadata: { 'filename': encodeURI(f.name) }
			})
		)},
	})

	const newFiles = [...files].map(file => ({
		file,
		newFile: {
			key: null,
			filename: file.name,
			filesize: file.size,
			filetype: file.type,
			file_category_id: file.file_category_id || null,
			version: file.version || null,
			parent_file_id: file.parent_file_id || null,
			description: file.description || null,
			delete: false
		}
	}))

	return uploadLinks.then(({data: { getUploadLink }}) => {
		return Promise.all(getUploadLink.map((link, i) => {
			let {file, newFile } = newFiles[i]
			newFile.key = link.key
			return fetch(link.url, {
				method: 'PUT',
				body: file,
				headers: {
					'Content-Type': file.type,
					'x-amz-meta-filename': encodeURI(newFile.filename)
				}
			}).then(() => {
				let file = { ...newFile }
				callback && callback(file)
				return file
			})
		}))
	})
}
