/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Undo } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Button, Chip, IconButton, Menu, MenuItem, Paper } from '@mui/material'
import {
	DataGridPro, GridToolbarColumnsButton,
	GridToolbarContainer, GridToolbarDensitySelector
} from '@mui/x-data-grid-pro'
import Thumbnail from '../Thumbnail/Thumbnail'
import clsx from 'clsx'
import { elementReady, formatDate, getListHeader, restoreColumnSettings } from '../common/helpers'
import { DispatchContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { TagModal } from './TagModal'
import { useMutation } from '@apollo/client'
import { DELETE_TAG } from './Queries'
import ConfirmationDialog from '../navigation/ConfirmationDialog/ConfirmationDialog'
import { computeTextColorFromBGColor } from '../common/helpers'

function arraymove(arr, fromIndex, toIndex) {
	const element = arr[fromIndex]
	arr.splice(fromIndex, 1)
	arr.splice(toIndex, 0, element)
}

function getCols({
	loading,
	actionMenu,
	density
}) {
	/** @type import('@mui/x-data-grid-pro').GridColDef[] */
	const columns = [
		{
			field: 'Actions',
			headerName: '',
			minWidth: 50,
			width: 50,
			resizable: false,
			sortable: false,
			renderCell: actionMenu
	  	},
		{
			field: 'label',
			headerName: 'Tag',
			width: 200,
			renderCell: params => params.value ?
				<Chip label={params.value} style={{ 
					backgroundColor: '#' + params.row.color_hex, 
					color: `${computeTextColorFromBGColor('#' + params.row.color_hex)}` 
				}} /> : 
				<span>-</span>
		},
		{
			field: 'description',
			headerName: 'Description',
			width: 300,
			renderCell: params => params.value ? params.value : <span>-</span>
		},
		{
			field: 'record_count',
			headerName: "Record Count",
			width: 75,
			renderCell: params => params.value ? params.value : <span>0</span>
		},
		{
			field: 'user_first_name',
			headerName: 'Created User',
			width: 230,
			renderCell: params =>
				<Thumbnail
					name={[params.row.created_user_entity.first_name,params.row.created_user_entity.last_name]
						.filter(a => a).join(' ')}
					detail={params.row.created_user_entity.gallery}
					avatar={params.row.created_user_entity.imgUrl}
					type='tag'
					animation={loading ? 'wave' : false}
					largeText
				></Thumbnail>
		},
		{
			field: "modified_at",
			headerName: "Modified At",
			sortable: true,
			width: 130,
			renderCell: params => {
				const fDate = params.row?.modified_at ? formatDate( params.row?.modified_at, "MMM' 'dd', 'yyyy" ) : "-"
				return <span>{fDate}</span>
			}
		},
		{
			field: 'created_at',
			headerName: 'Created Date',
			width: 225,
			renderCell: params => params.value ?
				formatDate(params.value, "MMM d, yyyy") :
				<span>-</span>
		},
	]
	return columns
}

/**
 * @typedef TagsDataGridProps
 * @property {Object[]} rows All rows of {Tags} to be displayed
 * @property {function} setRows
 * @property {boolean} loading If a loading is in progress
 * @property {function} saveAndGo Function called on row doubleClick
 * @property {string} mainSearchPage
 * @property {filterComparator} userId
 * @property {Object} selectedRow
 * @property {callback} onSelect
 * @property {filterComparator} totalItems
 * @property {number} limit
 * @property {Object} variables
 * @property {function} setLimit
 * @property {number} activeStep
 * @property {function} setActiveStep
 * @property {Object} sortModel
 * @property {function} onSortModelChange
 * @property {boolean} noScrollToTop do not scroll to top of page on pagination
 */

/**
 * @param {TagsDataGridProps} props
 */
export function TagsDataGrid (props) {

	const columnSettingsKey = 'TagsDataGrid'

	// handle null response.
	const rows = props.rows || []

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	const [currentTag, setCurrentTag] = React.useState(null)

	// Modal
	const [toggleUserDialog, setToggleUserDialog] = React.useState(false)
	const [openTagModal, setOpenTagModal] = React.useState(false)

	const handleEditTag = (tag) => {
		var existingTag = props.rows.find(e => e.id === tag.id)
		existingTag.label = tag.label
		existingTag.description = tag.description
		existingTag.color_hex = tag.color_hex
		props.setRows([...props.rows])
	}

	const [density, setDensity] = useState(
		localStorage.getItem(`${columnSettingsKey}.density`) || 'comfortable')
	useEffect(() => localStorage.setItem(`${columnSettingsKey}.density`, density),
		[density])

	const [columnVisibilityModel, setColumnVisibilityModel] = useState(
		JSON.parse(localStorage.getItem(`${columnSettingsKey}.visibility`) || null)
	)
	useEffect(() => localStorage.setItem(`${columnSettingsKey}.visibility`, JSON.stringify(columnVisibilityModel)),
		[columnVisibilityModel])

	const openContextMenu = (field) => (e) => {
		e.preventDefault()
		e.stopPropagation()
		setAnchorEl(e[field])
	}


	const actionMenu = params => {
		if (params.row.is_private && !params.row.created_at) {
			return null
		}

		return <IconButton
			aria-label="More"
			style={{
				padding: '6px',
				marginRight: '-9px',
			}}
			onClick={e => {
				if (params.row.disabled || params.row.hidden) return
				setCurrentTag(params.row)
				openContextMenu('currentTarget')(e)
			}}
		>
			<MoreVertIcon
				style={ params.row.isPrivate ? { color: 'white' } : null }
			/>
		</IconButton>
	}

	const [anchorEl, setAnchorEl] = useState(null)
	const [columns, setColumns] = useState([])

	// Set up grid with loaded dependencies, and saved data.
	const updateColumns = () => {

		const cols = getCols({
			loading: props.loading,
			actionMenu,
			density
		})

		// restore on load.
		const columnSettings = JSON.parse(localStorage.getItem(columnSettingsKey))
		if (columnSettings?.length) restoreColumnSettings(cols, columnSettings)

		if (!columnVisibilityModel) {
			const obj = cols.filter(c => c.hide)
				.reduce((acc, el) => {
					acc[el.field] = false
					return acc
				}, {})
			setColumnVisibilityModel(obj)
		}

		setColumns([...cols])
	}

	// on Load, load column settings, sort, merge in (once everything is loaded.)
	useEffect(() => {
		if (props.userId) updateColumns()
	}, [props.userId, density])

	// update the flag menu/toggle any time the props change
	// to prevent "stale closures"
	useEffect(() => {
		setColumns(cols => {
			return [...cols]
		})
	}, [props.variables, props.totalItems, props.userId, density])

	// on unmount, stash columns back in storage. (eventually in DB)
	useEffect(() => {

		// stash on nav. (`columns` needs to be in the deps in order to be accessed.)
		return () => {
			if (columns.length) {
				const columnSettings = columns.map(col => ({
					field: col.field,
					width: col.width,
					minWidth: col.minWidth,
					hide: col.hide
				}))
				localStorage.setItem(columnSettingsKey, JSON.stringify(columnSettings))
			}
		}
	}, [columns])

	const handleClose = () => setAnchorEl(null)

	// Mutations
	const [deleteTag] = useMutation(DELETE_TAG)

	// Closes the deactivate user confirmation dialog
	const closeDialog = () => {
		setToggleUserDialog(false)
	}

	const resetGrid = () => {
		localStorage.removeItem(columnSettingsKey)
		localStorage.removeItem(`${columnSettingsKey}.visibility`)
		setColumnVisibilityModel(null)
		updateColumns()
	}

	const CustomToolBar =  () => <GridToolbarContainer>
		<Button
			size="small"
			startIcon={<Undo />}
			onClick={resetGrid}
		>
			Reset
		</Button>
		<GridToolbarColumnsButton />
		<GridToolbarDensitySelector />
	</GridToolbarContainer>

	// Scroll to the top of current data grid component when paginating
	useEffect(() => {

		if (props.loading === false && !props.scrollIntoView) {

			elementReady(`.${columnSettingsKey}`)
				.then(searchList =>
					searchList.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					})
				)
		}

	}, [props.activeStep])

	return <>
		<Paper
			className={clsx({
				'data-grid-padding': true,
				[columnSettingsKey]: true
			})}
			data-testid={`card-${props.listHeader ? props.listHeader : "Results "}`}
			style={{
				...props.style,
				flexGrow: 1
			}}
		>
			<h1 className="card-title" style={{
				paddingTop: '1em',
				paddingLeft: '2em'
			}}>
				{getListHeader(props.listHeader, props.totalItems, props.canSee)}
			</h1>

			<div style={{ height: 'calc(100% - 4em)', width: '100%' }}>
				<DataGridPro
					components={{ Toolbar: CustomToolBar }}
					rowCount={props.totalItems || 0}
					rows={rows}
					columns={columns}
					onRowDoubleClick={({row}) => {
						props.saveAndGo(`/tags/${row.id}`, row.id)
					}}
					density={density}
					disableColumnMenu
					disableMultipleSelection
					pagination
					paginationMode="server"
					sortingMode="server"
					pageSize={props.limit}
					onPageSizeChange={props.setLimit}
					rowsPerPageOptions={[10, 25, 50]}
					onRowClick={(params, event) => {
						let row = params.row
						if (event.metaKey) {
							window.open(`/tags/${row.id}`, "_blank")
						} else {
							props.onSelect(row)
						}
					}}
					selectionModel={props.selectedRow?.id ? [props.selectedRow?.id] : []}
					page={props.activeStep}
					onPageChange={props.setActiveStep}
					onSortModelChange={props.onSortModelChange}
					loading={props.loading}
					onColumnVisibilityModelChange={setColumnVisibilityModel}
					columnVisibilityModel={columnVisibilityModel || {}}
					onColumnOrderChange={({ oldIndex, targetIndex }) => {
						arraymove(columns, oldIndex, targetIndex)
						setColumns([...columns])
					}}
					onColumnWidthChange={({ colDef, width}) => {
						columns.find(col => col.field === colDef.field).width = width
						setColumns([...columns])
					}}
					onStateChange={(args) => setDensity(args.density?.value ?? 'comfortable')}
				/>
			</div>

			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={!!anchorEl}
				onClose={handleClose}
			>
				<MenuItem onClick={(e, o) => {
					setOpenTagModal(true)
					handleClose()
				}}>Edit</MenuItem>
				<MenuItem onClick={(e) => {
					setToggleUserDialog(true)
					handleClose()
				}}>Delete</MenuItem>
			</Menu>
		</Paper>

		<TagModal
			editMode={true}
			tag={currentTag}
			open={openTagModal}
			setOpen={setOpenTagModal}
			handleResult={handleEditTag}
		/>

		<ConfirmationDialog
			open={toggleUserDialog}
			handleClose={closeDialog}
			title={'Are you sure?'}
			acceptText="DELETE"
			text={currentTag ? (
				<>
					<span>This will remove the "{currentTag.label}" tag <strong><u>permanently</u></strong> from The Platform.</span>
				</>
			) : null}
			onYes={() => {
				deleteTag({
					variables: {
						id: currentTag.id
					}
				}).then(response => {

					if (response.data.deleteTag?.success === true) {
						openSnackbar(severity[response.data.deleteTag.severity], response.data.deleteTag.message)
						props.setRows(props.rows.filter(tag => tag.id !== currentTag.id))
					} else if (response.errors[0].message) {
						openSnackbar(severity.ERROR, response.errors[0].message)
					} else {
						openSnackbar(severity.ERROR, "Could not delete tag.")
					}

				}).catch(error => {
					console.log(error)
					openSnackbar(severity.ERROR, "There was an error deleting this tag.")
				})
			}}
		/>
	</>
}
