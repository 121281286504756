/* eslint-disable eqeqeq */
import { useMutation } from '@apollo/client'
import { Menu, MenuItem, StyledEngineProvider, ThemeProvider, Tooltip } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import Grow from '@mui/material/Grow'
import IconButton from "@mui/material/IconButton"
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { useCallback, useContext, useEffect } from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import adminConsoleTheme from '../../../src/styles/muiThemes/adminConsoleTheme'
import mainTheme from '../../../src/styles/muiThemes/mainTheme'
import { permissions, permissionValues } from '../../constants/permissions'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { AuthStateContext, DispatchContext, OpenContext } from '../../store'
import * as Colors from '../../styles/colors/Colors'
import { UPDATE_SELF } from '../../User/Queries'
import { filteredPages, supportPage } from '../routes'
import useNavigation from '../useNavigation'
import './SideDrawer.css'
import UserButton from './UserButton'
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog'
import routes from '../routes'

const drawerWidth = 170
const smallScreenSize = 1000
const animationTime = 300
const sizeOfTopBar = 56

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false,
}

const useStyles = makeStyles(theme => ({
	sideDrawerRoot: {
		display: 'flex',
		height: `calc(100vh - ${sizeOfTopBar}px)`
	},
	menuButton: {
		marginRight: 36,
	},
	hide: {
		display: 'none',
	},
	paper: {
		backgroundColor: Colors.nav,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflow: "hidden",
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9),
		},
		overflow: "hidden",
	},
	toolbarPlaceholder: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
	},
	drawerItems: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		padding: "0"
	},
	drawerItem: {
		'&:hover': {
			backgroundColor: Colors.navHighlight
		},
	},
	drawerIcon: {
		margin: "0 auto 0",
		maxHeight: "26px",
		maxWidth: "25px",

	},
	drawerIconContainer: {
		minWidth: "73px"
	},
	navLink: {
		textDecoration: 'none',
		color: 'white',
	},
	content: {
		flexGrow: 1
	},
	bottomButton: {
		textDecoration: 'none',
		color: 'white',
	},
	chevronContainer: {
		color: "white",
		width: "2em",
		height: "2em",
		marginTop: "0",
		marginLeft: "12px",
		'&:hover': {
			backgroundColor: "transparent"
		},
	},
}))

const adminIconStyle = {
	margin: "0 auto 0",
	color: "white",
	marginBottom: "-5px"
}

const SideDrawer = (props) => {

	const { setSearchNav } = useNavigation()

	const { user } = props

	const location = useLocation()
	const navigate = useNavigate()

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const userName = ((user?.first_name || "") + " " + (user?.last_name || "")).trim()
	const title = user?.title || null

	const classes = useStyles()
	const [animate, setAnimate] = React.useState(false)
	const [smallScreen, setSmallScreen] = React.useState(window.innerWidth <= smallScreenSize)
	const [tooltipOpen, setTooltipOpen] = React.useState(null)
	const [showcaseRouteConfirmation, setShowcaseRouteConfirmation] = React.useState(false)

	const atAdminConsole = location.pathname.includes('admin')
	const atShowcaseConsole = location.pathname.includes('showcase')

	const authState = useContext(AuthStateContext)
	const userPermissions  = authState?.user?.permissions

	const [state, setState] = React.useState(initialState)

	const pathArray = location.pathname.split("/")
	const onProfile = pathArray.join("/") === "/user/profile"

	const isOpen = useContext(OpenContext)?.drawer || false
	const closeDrawer = () => dispatch({ type: 'closeDrawer' })
	const openDrawer = () => dispatch({ type: 'openDrawer' })

	// Function to call when screen size is changes
	const resize = () => {
		if (window.innerWidth <= smallScreenSize) {
			closeDrawer()
			setSmallScreen(true)
		} else {
			setSmallScreen(false)
		}
	}

	const handleClick = (event) => {
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const [updateSelf] = useMutation(UPDATE_SELF)
	
	function handleUpdateError(error) {
		console.error(error)
		openSnackbar(severity.ERROR, 'There was a problem updating showcase preference')
	}

	function handleUpdateSuccess({ data }) {
		if (data.updateSelf?.success !== true) {
			openSnackbar(severity.ERROR, data.updateSelf.message)
		}
	}


	const handleClose = (option) => {
		setState(initialState)

		if (option === 'profile') {
			navigate('/user')
		} else if (option === 'admin') {
			navigate('/admin')
		} else if (option === 'showcase') {
			navigate('/showcase/artists')
			updateSelf({ variables: { SelfInput: {
				id: user.id,
				web_app_showcase_mode: true
			}}}).then(handleUpdateSuccess)
				.catch(handleUpdateError)
		} else if (option === 'platform') {

			if (atShowcaseConsole) {
				setShowcaseRouteConfirmation(true)

			}
			else {

				navigate('/home')
				updateSelf({ variables: { SelfInput: {
					id: user.id,
					web_app_showcase_mode: false
				}}}).then(handleUpdateSuccess)
					.catch(handleUpdateError)

			}
			
		} else if (option === 'logout') {
			props.setShowcaseSelectMode ({
				selectMode: false,
				artIds: []
			})

			navigate('/logout', { replace: true })
		}
	}


	useEffect(() => {
		// Check if the screen size is below a certain width
		window.addEventListener("resize", resize.bind(this))

		return function cleanup() {
			window.removeEventListener('resize', resize.bind(this))
		}
	})

	// Routes filtered by their required user permission key, value pair
	const filteredRoutes = filteredPages(props.pageName, userPermissions)

	const hasArtPerms = authState.user?.permissions
		?.find(e => e.permission_id == permissions.ART)
		?.permission_value_id >= permissionValues.VIEW_ONLY

	const hasAdminPerms = authState.user?.permissions
		?.find(e => e.permission_id == permissions.ADMIN_CONSOLE)
		?.permission_value_id == permissionValues.YES

	return (location.pathname !== "/" && location.pathname !== "/admin/login") ?
		(<div className={classes.sideDrawerRoot}>
			<Drawer
				variant="permanent"
				data-testid="drawer"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: isOpen,
					[classes.drawerClose]: !isOpen,
				})}
				classes={{
					paper: clsx(classes.paper, {
						[classes.drawerOpen]: isOpen,
						[classes.drawerClose]: !isOpen,
					}),
				}}
			>
				<div className={classes.toolbarPlaceholder} />
				<List className={classes.drawerItems}>
					{filteredRoutes.filter(page => page.visible && ((atAdminConsole && page.admin) || (!atAdminConsole && !page.admin)))
						.map((page) => {

							let { route } = page
							const { name, icon, navigateToFirst } = page
							
							const navLinkRoute = navigateToFirst ? route.replace('/*', '') : route

							route = navigateToFirst ? route.replace('*', navigateToFirst) : route

							return (
								<NavLink
									key={name}
									onClickCapture={() => {

										props.setTotalResultCount(null)
										setSearchNav({
											prev: null,
											current: null,
											total: null,
											next: null,
											path: null
										})
									}}

									className={({ isActive }) => classes.navLink + (isActive ? " activeItem" : "")}

									data-testid={name+"-NavLink"}
									to={navLinkRoute}
								>
									<ListItem button key={name} className={classes.drawerItem} onMouseOver={() => setTooltipOpen(route)} onMouseLeave={() => setTooltipOpen(null)}>
										<Tooltip arrow open={!isOpen && tooltipOpen == route} title={name} placement="right">
											<ListItemIcon className={classes.drawerIconContainer}>
												{atAdminConsole ?
													<div style={adminIconStyle} className="admin-drawer-icon">
														{icon}
													</div>
													: (
														icon ? <div style={adminIconStyle} className="admin-drawer-icon">
															{icon}
														</div> :
															<img alt={route[0]} className={classes.drawerIcon}
																src={`/images/icons/White/${name}.svg`} />
													)
												}
											</ListItemIcon>
										</Tooltip>
										<ListItemText primary={name} style={{ whiteSpace: 'pre-wrap', height: '2em', display: 'flex', alignItems: 'center'}}/>
									</ListItem>
								</NavLink>
							)
						})}

					{/* Support */}
					<NavLink key={supportPage.name} 
						
						className={({ isActive }) => classes.navLink + (isActive ? " activeItem" : "")}

						data-testid={supportPage.name+"-NavLink"}
						to={supportPage.route.replace("*", routes.supportPages.pages[0].route)}
						target={"_blank"}
						style={{ marginTop: 'auto' }}
					>
						<ListItem button key={supportPage.name} className={classes.drawerItem} onMouseOver={() => setTooltipOpen(supportPage.route)} onMouseLeave={() => setTooltipOpen(null)}>
							<Tooltip arrow open={!isOpen && tooltipOpen == supportPage.route} title={supportPage.name} placement="right">
								<ListItemIcon className={classes.drawerIconContainer}>

									<img alt='support' className={classes.drawerIcon}
										src={`/images/icons/White/Support.svg`} />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary={supportPage.name} />
						</ListItem>
					</NavLink>

					{/* User Profile */}
					{(animate) ?
						<Grow in={animate} timeout={animationTime}>
							<div onClick={handleClick} data-testid="user-page-link" style={state.mouseX || onProfile ? {backgroundColor: 'rgb(59, 59, 59)'} : null }
								className={classes.bottomButton}>
								<ListItem button className={classes.drawerItem}>
									<UserButton animate={animate}
										open={isOpen}
										name={ userName }
										avatar= { user?.imgUrl }
										role={ title }
									></UserButton>
								</ListItem>
							</div>
						</Grow>
						:
						<div data-testid="user-page-link" onClick={handleClick} style={state.mouseX || onProfile ? {backgroundColor: 'rgb(59, 59, 59)'} : null }
							className={classes.bottomButton}>
							<Tooltip arrow disableFocusListener={isOpen} disableHoverListener={isOpen} disableTouchListener={isOpen} title="Profile" placement="right">
								<ListItem button className={classes.drawerItem}>
									<UserButton open={isOpen}
										name={ userName }
										avatar= { user?.imgUrl }
										role={ title }>
									</UserButton>
								</ListItem>
							</Tooltip>
						</div>
					}

					{(!smallScreen) ?
						<Grow in>
							<IconButton
								className={classes.chevronContainer}
								data-testid="chevron"
								onClick={() => {
									setAnimate(true)

									if (isOpen)  closeDrawer()
									else openDrawer()

									setTimeout(function () {
										window.dispatchEvent(new CustomEvent('resize'))
										setAnimate(false)
									}, animationTime)
								}}
								size="large">
								<img height="20" width="14" alt="Open Drawer"
									src="/images/icons/White/Next.svg"
									className={isOpen ? "chevronReversed" : "chevronForward"} />
							</IconButton>
						</Grow>
						:
						null
					}
				</List>

				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={pathArray.includes("admin") ? adminConsoleTheme : mainTheme}>
						<Menu
							keepMounted
							open={state.mouseY !== null}
							onClose={handleClose}
							anchorReference="anchorPosition"
							anchorPosition={
								state.mouseY !== null && state.mouseX !== null
									? { top: state.mouseY, left: isOpen ? drawerWidth : 73 }
									: undefined
							}
						>
							<MenuItem data-testid="profile-link"
								onClick={() => handleClose('profile')}
							>
								My Profile
							</MenuItem>
							{hasAdminPerms && props.pageName != 'adminPages' && props.pageName != 'showcasePages' ?
								<MenuItem data-testid="admin-console"
									onClick={() => handleClose('admin')}
								>
									Admin Console
								</MenuItem>
								: null
							}
							{(props.pageName != 'mainPages') &&
								<MenuItem data-testid="thePlatform"
									onClick={() => handleClose('platform')}
								>
									The Platform
								</MenuItem>
							}

							{(props.pageName != 'showcasePages' && hasArtPerms) &&
								<MenuItem data-testid="showcase"
									onClick={() => handleClose('showcase')}
								>
									Showcase
								</MenuItem>
							}
							<MenuItem data-testid="logout"
								onClick={() => handleClose('logout')}
							>
								Log Out
							</MenuItem>
						</Menu>
					</ThemeProvider>
				</StyledEngineProvider>
			</Drawer>

			<ConfirmationDialog
				open={showcaseRouteConfirmation}
				handleClose={() => setShowcaseRouteConfirmation(false)}
				title={'Are you sure you want to leave showcase mode?'}
				buttonColor={'#cc3333'}
				acceptText={'Leave'}
				cancelText={'Stay'}
				onYes={() => {
					navigate('/home')
					
					props.setShowcaseSelectMode ({
						selectMode: false,
						artIds: []
					})

					updateSelf({ variables: { SelfInput: {
						id: user.id,
						web_app_showcase_mode: false
					}}}).then(handleUpdateSuccess)
						.catch(handleUpdateError)
				}}
			/>

			{props.children}
		</div>) :
		<>
			{props.children}
		</>
}

export default SideDrawer
