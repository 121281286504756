/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Undo } from '@mui/icons-material'
import { Button, IconButton, Menu, MenuItem, Paper } from '@mui/material'
import {
	DataGridPro,  GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector
} from '@mui/x-data-grid-pro'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { AuthStateContext, DispatchContext, LookupContext } from '../../store'
import { arraymove, restoreColumnSettings, returnDate } from '../../common/helpers'
import { GET_TRANSACTION_HISTORY, GET_TRANSACTION_HISTORY_NAV, UPSERT_DEAL_ENTRY } from '../../DealEntry/Queries'
import { withApollo } from '@apollo/client/react/hoc'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import PriceModal from '../../Deals/Deal/PriceModal'
import { useMutation } from '@apollo/client'
import { ChooseImageButton } from '../../input/Buttons'
import CreateDealQuickView from '../../Deals/CreateDealQuickView'
import { dealTypes, ES_SEARCH_MAX_RESULT_COUNT } from '../../constants/values'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import UserAccessQuickView from '../../QuickView/UserAccessQuickView'
import DealQuickView from '../../Deals/DealQuickView'
import useNavigation from '../../navigation/useNavigation'
import InfoCard from '../../InfoCard/InfoCard'
import ConfirmationDialog from '../../navigation/ConfirmationDialog/ConfirmationDialog'
import { initialPriceModalState, initialStatusModalState } from '../../DealEntry/DealEntryList'
import { useLocation, useNavigate } from 'react-router-dom'
import DealEntryStatusModal from '../../DealEntry/DealEntryStatusModal'
import clsx from 'clsx'
import { currentSources, dateCell, dealStatus, galleryContacts, price, sourceType } from '../../common/DealEntryCells'
import { permissions } from '../../constants/permissions'
import { getPermissionCanSee } from '../../Tags/TagQuickView'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { LightTooltip } from '../../styles/makesStyles'

const TransactionHistory = (props) => {

	const columnSettingsKey = 'TransactionHistoryDataGrid'

	const navigate = useNavigate()
	const location = useLocation()
	const lookup = useContext(LookupContext)
	const { push: pushNav } = useNavigation()

	const statusTypes = lookup?.data?.getDealEntryStatuses

	const authState = useContext(AuthStateContext)
	const userPermissions  = authState?.user?.permissions

	const canViewDeals = getPermissionCanSee(userPermissions, permissions.DEALS)

	// actions menu
	const [anchorEl, setAnchorEl] = useState(null)
	const [currentRow, setCurrentRow] = useState(null)
	const [index, setIndex] = useState(null)

	const handleClick = (event) => setAnchorEl(event.currentTarget)
	const handleClose = () => setAnchorEl(null)

	const [order, setOrder] = React.useState("desc")
	const [orderBy, setOrderBy] = React.useState('start_at')
	const [rows, setRows] = React.useState([])
	const [activeStep, setActiveStep] = React.useState(0)
	const [totalItems, setTotalItems] = React.useState(null)
	const [selectedRow, setSelectedRow] = React.useState(null)
	const [loading, setLoading] = React.useState(false)

	const [columnSettings, setColumnSettings] = useState( JSON.parse(localStorage.getItem(columnSettingsKey) || null ) )
	const [columnVisibilityModel, setColumnVisibilityModel] = useState(JSON.parse(localStorage.getItem(`${columnSettingsKey}.visibility`) || '{}' ))
	const [limit, setLimit] = React.useState(Number(localStorage.getItem(`${columnSettingsKey}.limit`) || 10))
	const [density, setDensity] = useState(localStorage.getItem(`${columnSettingsKey}.density`) || 'comfortable')

	const [currentChange, setCurrentChange] = useState(null)

	const [priceModal, setPriceModal] = useState(initialPriceModalState)
	const [infoModal, setInfoModal] = useState({ open: false })
	const [statusModal, setStatusModal] = useState(initialStatusModalState)

	const [toggleRemoveArt, setRemoveArt] = useState(false)

	const privateEntity = props.art?.is_private || false

	// Tooltips
	const [open, setOpen] = React.useState({})
	const handleTTClose = useCallback((row) =>
		setOpen({
			...open,
			[row.id]: false,
		}), [open])
		
	const handleTTOpen = useCallback((row) =>
		setOpen({
			...open,
			[row.id]: true,
		}), [open])

	// Mutations
	const [updateRelation, { loading: loadingUpdateRelation }] = useMutation(UPSERT_DEAL_ENTRY)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	
	const handleSubmit = useCallback(
		(cursor = 0) => {

			if (!props.art?.id || !canViewDeals) return 

			setLoading(true)

			const input = {
				field: orderBy,
				direction: (order || 'asc').toUpperCase(),
				cursor,
				limit,
				id: Number(props.art.id)
			}

			props.client
				.query({
					query: GET_TRANSACTION_HISTORY,
					variables: { CommonRequest: input },
				})
				.then((result) => {
					const { data } = result

					setLoading(false)
					setRows(data.getTransactionHistory.items?.filter(f => f.id))
					setTotalItems(data.getTransactionHistory.totalItems)
				})
				.catch((error) => {
					setLoading(false)
					console.error(error)
				})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.art?.id, orderBy, order]
	)

	const updateRow = useCallback((row, notify) => {
		const { id, is_interested } = row

		const deal_entry_status_id = row.statusId || row.deal_entry_status?.id

		const variables = {
			id,
			deal_entry_status_id,
			status_note: row.statusNote,
			is_interested,
			project_code_id: row.project_code?.id
		}

		if (row.reserve_begin_at) {
			variables.reserve_begin_at = returnDate(row.reserve_begin_at)
		}
		if (row.reserve_end_at) {
			variables.reserve_end_at = returnDate(row.reserve_end_at)
		}

		if (row.start_at) variables.start_at = returnDate(row.start_at)
		if (row.end_at) variables.end_at = returnDate(row.end_at)

		if (row.offer_amount) {
			variables.offer_amount = Number(row.offer_amount)
			variables.offer_currency_id = Number(row.offer_currency?.id || row.offer_currency || '1')
		}
		if (row.sale_amount) {
			variables.sale_amount = Number(row.sale_amount)
			variables.sale_currency_id = Number(row.sale_currency?.id || row.sale_currency || '1')
		}
		if (row.counter_offer_amount) {
			variables.counter_offer_amount = Number(row.counter_offer_amount)
			variables.counter_offer_currency_id = Number(row.counter_offer_currency?.id || row.counter_offer_currency || '1')
		}

		// Send to server
		return updateRelation({
			variables: {
				DealEntryRequest: variables,
				notify
			},
		})
			.then((response) => {
				if (response && response.data?.upsertDealEntryV2?.success) {
					openSnackbar(severity.SUCCESS, 'Successfully updated entry.')

					// Requery for data
					handleSubmit(activeStep * limit)

				} else if (response?.data && !response.data.success) {
					openSnackbar(
						severity[response.data.upsertDealEntryV2.severity] ?? severity.ERROR,
						response.data.upsertDealEntryV2.message ?? 'There was an error updating this entry.'
					)
				} else throw response.errors
			})
			.catch((error) => {
				console.error(error)
				const message = error[0]?.message
				openSnackbar(severity.ERROR, message ?? 'There was an error updating this entry.')
			})
	}, [activeStep, handleSubmit, limit, openSnackbar, updateRelation])

	// Submit on page load, order
	useEffect(() => {
		handleSubmit()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.art?.id, order, orderBy, activeStep, limit])

	const resetGrid = () => {
		localStorage.removeItem(columnSettingsKey)
		localStorage.removeItem(`${columnSettingsKey}.density`)
		localStorage.removeItem(`${columnSettingsKey}.visibility`)
		localStorage.removeItem(`${columnSettingsKey}.limit`)

		setColumnVisibilityModel({})
		setColumnSettings(null)
		setLimit(10)
		setDensity('comfortable')
	}

	useEffect(() => {
		return () => {
			localStorage.setItem(columnSettingsKey, JSON.stringify(columnSettings))
			localStorage.setItem(`${columnSettingsKey}.density`, density)
			localStorage.setItem(`${columnSettingsKey}.visibility`, JSON.stringify(columnVisibilityModel))
			localStorage.setItem(`${columnSettingsKey}.limit`, limit)
		}
	}, [columnSettings, density, columnVisibilityModel, limit])


	const CustomToolBar = () => (
		<GridToolbarContainer>
			<Button size='small' startIcon={<Undo />} onClick={resetGrid}>
				Reset
			</Button>
			<GridToolbarColumnsButton />
			<GridToolbarDensitySelector />
		</GridToolbarContainer>
	)

	const actionMenu = useCallback((params) => {

		if (params.row.is_private && !params.row.created_at)
			return <></>

		return (
			<IconButton
				aria-label="More"
				style={{
					padding: '6px',
					marginRight: '-9px',
				}}
				onClick={e => {
					if (params.row.disabled || params.row.hidden) return
					e.preventDefault()
					e.stopPropagation()
					setCurrentRow(params.row)
					handleClick(e)
				}}
			>
				<MoreVertIcon
					sx={ params.row.is_private ? { color: 'white' } : null }
				/>
			</IconButton>
		)
	}, [])

	
	React.useEffect(() => {

		if ((selectedRow?.deal?.is_private || selectedRow?.deal?.is_gallery_private) && !selectedRow?.deal?.created_at) {
			props.setQVOverride(() => <UserAccessQuickView entity={selectedRow.deal} onClose={() => setSelectedRow(null)}/>)
		} else if (selectedRow) {
			props.setQVOverride(() => <DealQuickView id={selectedRow.deal.id} onClose={() => setSelectedRow(null)}/>)
		} else {
			props.setQVOverride(null)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRow])

	const hidden = (currentRow?.deal.is_private || currentRow?.deal?.is_gallery_private) && !currentRow?.deal?.created_at

	/**
	 * Queues quick navigation after execution of special query for ids of
	 * owner history list.
	 */
	const quickNavigate = (row) => {
		const hidden = (row?.deal.is_private || row?.deal?.is_gallery_private) && !row?.deal?.created_at

		// Check if you have access to navigate
		if (hidden) {
			return
		}

		if (row.deal?.publicAccess === null) {
			return
		}

		const input = {
			field: orderBy,
			direction: (order || 'asc').toUpperCase(),
			cursor: null,
			limit: null,
			id: Number(props.art.id)
		}

		props.client
			.query({
				query: GET_TRANSACTION_HISTORY_NAV,
				variables: { CommonRequest: input },
			})
			.then((result) => {
				const { data } = result

				const ids = data.getTransactionHistory.items.map(e => e.deal_id)

				const cursor = ids.findIndex((e) => e === row.deal_id)
				const state = { variables: { cursor: index } }
				
				pushNav({
					url: location.pathname,
					state,
					query: ids,
				}, cursor)

				navigate(`/deals/${row.deal_id}/details`, { state })
			})
			.catch((error) => {
				console.error(error)
				openSnackbar(severity.ERROR, "There was an error attempting to quick navigate.")
			})
	}

	const updateColumnSettings = () => {
		if (gridCols.length) {
			const colSettings = gridCols.map(col => {
				const setting = { field: col.field }
				if (col.width) setting.width = col.width
				if (col.minWidth) setting.minWidth = col.minWidth
				return setting
			})
			setColumnSettings(colSettings)
		}
	}

	// This prevents stale closures; anything that might change (contextually)
	// that needs to stay up to date must be listed in the useMemo dependencies.

	/** @type import('@mui/x-data-grid-pro').GridColDef[] */
	const gridCols = useMemo(() => {

		const cols = ([
			{
				field: 'Actions',
				headerName: '',
				minWidth: 50,
				width: 50,
				resizable: false,
				sortable: false,
				renderCell: actionMenu
			  },
			{
				field: 'current_sources',
				headerName: 'Sources',
				sortable: false,
				width: 300,
				renderCell: (params) => currentSources(params)
			},
			{
				field: 'source_type',
				headerName: '',
				width: 150,
				sortable: false,
				renderCell: (params) => sourceType(params)
			},
			{
				field: 'deal_entry_status',
				headerName: 'Deal Status',
				width: 200,
				renderCell: (params) => dealStatus(params, open, handleTTClose, handleTTOpen, statusTypes, setStatusModal, statusModal, setCurrentRow, privateEntity)
			},
	
			{
				field: 'gallery_contacts',
				headerName: 'Gallery Contacts',
				width: 150,
				sortable: false,
				renderCell: (params) => galleryContacts(params)
			},
			{
				field: 'price',
				headerName: 'Price',
				width: 200,
				renderCell: (params) => price(params, setPriceModal, priceModal, updateRow, setCurrentChange)
			},
			{
				field: 'start_at',
				headerName: 'Date',
				width: 400,
				renderCell: (params) => dateCell(params)
			},
			
		])
		if (columnSettings?.length) restoreColumnSettings(cols, columnSettings)
		return cols
	}, [actionMenu, columnSettings, open, statusTypes, privateEntity, handleTTClose, handleTTOpen, statusModal, updateRow, priceModal])

	return (
		<>
			<Paper
				className={clsx({
					'data-grid-padding': true,
					[columnSettingsKey]: true
				})}
				style={{
					...props.style,
					flexGrow: 1,
				}}
			>
				<h1 className='card-title'
					style={{
						paddingTop: '1em',
						paddingLeft: '2em'
					}}
				>
					Transaction History

					
					{totalItems && totalItems !== ES_SEARCH_MAX_RESULT_COUNT ? 
						' (' + totalItems + ')' : ''}
					{totalItems === ES_SEARCH_MAX_RESULT_COUNT ?
						` (${ES_SEARCH_MAX_RESULT_COUNT}+)` : ''}

					{canViewDeals ?
						<ChooseImageButton
							variant="contained"
							color="secondary"
							size="medium"
							style={{
								zIndex: 2
							}}
							onClick={() => {

								const createDealQVProps = {
									typeId: dealTypes.consignment_in,
									contacts: [],
									art: props.art ? [{ ...props.art }] : [],
									setCreateDeal: () => props.setQVOverride(null),
									darkTheme: props.art?.is_private || false,
								}

								props.setQVOverride(() => (
									<CreateDealQuickView
										{...createDealQVProps}
									></CreateDealQuickView>
								))
							}}
						>
						Create New Deal
						</ChooseImageButton> : <LightTooltip
							style={{
								zIndex: 500
							}}
							title={'You do not have permission to view this information, please see an admin for access.'} arrow placement="bottom">
							<div>
								<VisibilityOffIcon style={{
									color: 'grey',
									marginLeft: '0.4em',
									marginTop: '0.3em',
								}}/>
							</div>
						</LightTooltip>  }
				</h1>



				<div style={{ width: '100%' }}>
					<DataGridPro
						components={{ Toolbar: CustomToolBar }}
						rowCount={totalItems || 0}
						rows={rows}
						columns={gridCols}
						onRowDoubleClick={({row}) => {

							const hidden = (row?.deal.is_private || row?.deal?.is_gallery_private) && !row?.deal?.created_at

							if (hidden) {
								openSnackbar(severity.WARNING, 'You do not have access to this Private Deal.')
							} if (row.deal?.publicAccess === null) {
								openSnackbar(severity.WARNING, 'You do not have access to this Public Deal.')
							}
							else {
								quickNavigate(row)
							}

						}}
						autoHeight={true}
						density={density}
						disableColumnMenu
						disableMultipleSelection
						pagination
						paginationMode="server"
						sortingMode="server"
						pageSize={limit}
						onPageSizeChange={setLimit}
						rowsPerPageOptions={[10, 25, 50]}
						onRowClick={(params, event) => {
							if (currentChange) return
							let row = params.row
							if (event.metaKey) {
								const hidden = (row?.deal.is_private || row?.deal?.is_gallery_private) && !row?.deal?.created_at

								if (hidden) {
									openSnackbar(severity.WARNING, 'You do not have access to this Private Deal.')
								} if (row.deal?.publicAccess === null) {
									openSnackbar(severity.WARNING, 'You do not have access to this Public Deal.')
								} else {
									window.open(`/deals/${row.deal_id}/details`, '_blank')
								}

								return
							}

							setSelectedRow(row)
						}}
						selectionModel={selectedRow?.id ? [selectedRow?.id] : []}
						page={activeStep}
						onPageChange={setActiveStep}
						sortModel={orderBy ? [{ field: orderBy, sort: order }] : []}
						onSortModelChange={(newSort) => {
							if (newSort.length) {
								setOrderBy(newSort[0].field)
								setOrder(newSort[0].sort)
							} else if (!loading && !props.loading) {
								setOrder('asc')
							}
						}}
						loading={props.loading || loading}
						onColumnVisibilityModelChange={setColumnVisibilityModel}
						columnVisibilityModel={columnVisibilityModel || {}}
						onColumnOrderChange={({ oldIndex, targetIndex }) => {
							arraymove(gridCols, oldIndex, targetIndex)
							updateColumnSettings()
						}}
						onColumnWidthChange={({ colDef, width}) => {
							gridCols.find(col => col.field === colDef.field).width = width
							updateColumnSettings()
						}}
						onStateChange={({density}) => setDensity(density?.value ?? 'comfortable')}
						getRowClassName={({row}) => row.is_private ? 'Private-DataGrid-Row' : ''}
					/>
				</div>

				<Menu id="ownership-history-menu" anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}>
					<MenuItem
						onClick={() => {
							if (hidden) {
								openSnackbar(severity.WARNING, 'You do not have access to this Private Deal.')
							} if (currentRow.deal?.publicAccess === null) {
								openSnackbar(severity.WARNING, 'You do not have access to this Public Deal.')
							}
							else {
								quickNavigate(currentRow)
							}

							handleClose()
						}}
					>
						{hidden ? 'Go to Private Deal' : 'Go to Deal'}
					</MenuItem>
					<MenuItem
						onClick={() => {
							setInfoModal({ open: true })
							handleClose('information')
						}}
					>
					Information
					</MenuItem>
					<MenuItem
						onClick={() => {
							handleClose()
							setRemoveArt(true)
						}}
					>
					Remove from Deal
					</MenuItem>
				</Menu>
			</Paper>

			{/* Modals */}
			<TransitionsModal
				open={priceModal.open}
				close={() => setPriceModal({ ...priceModal, ...initialPriceModalState })}
			>
				<div className={props.art?.is_private ? 'dark-theme' : undefined}>
					<PriceModal
						name={priceModal.name}
						close={() => setPriceModal(initialPriceModalState)}
						amount={priceModal.amount}
						currency={priceModal.currency}
						save={priceModal.save}
						disabled={loadingUpdateRelation }
					></PriceModal>
				</div>
			</TransitionsModal>

			<InfoCard open={infoModal.open} object={currentRow} setInfoModal={setInfoModal} allAttributes />

			<ConfirmationDialog
				open={toggleRemoveArt || false}
				handleClose={setRemoveArt}
				title={'Remove from Deal?'}
				acceptText={'Remove'}
				text={`This will remove this artwork from the current deal.`}
				onYes={() => {
					const variables = {
						id: currentRow.id,
						delete: true,
					}

					// Send to server
					updateRelation({
						variables: {
							DealEntryRequest: variables,
						},
					})
						.then((response) => {
							if (response && !response.errors) {
								openSnackbar(severity.SUCCESS, 'Successfully deleted relation.')
								setCurrentRow(null)
								setIndex(null)
								handleSubmit(0)
							} else throw response.errors
						})
						.catch((error) => {
							console.error(error)
							openSnackbar(severity.ERROR, 'There was an error deleting this relation.')
						})
				}}
			/>

			<DealEntryStatusModal
				statusModal={statusModal}
				setStatusModal={setStatusModal}
				hasNoReserve={true}
				lookup={lookup}
				disabled={loadingUpdateRelation}
				updateRow={(row, notification) => updateRow(row, notification)}
				currentRow={currentRow}
				setCurrentRow={setCurrentRow}
				page={'art'}
				entityId={currentRow?.art?.id}
				order={order}
				orderBy={orderBy}
				privateEntity={privateEntity}
			/>
			
		</>
	)
}

export default withApollo(TransactionHistory)
