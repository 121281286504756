/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { Add, ExpandMoreRounded, Remove } from '@mui/icons-material'
import {
	Checkbox, Divider, FormControl, MenuItem,
	Select, ToggleButton, ToggleButtonGroup
} from '@mui/material'
import NumberFormat from 'react-number-format'
import { CancelButton, SubmitButton } from '../input/Buttons'
import Dropdown from '../input/Dropdown/Dropdown'
import Label from '../input/Label'
import TextBox from '../input/Text/TextBox'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { DispatchContext, LookupContext } from '../store'
import { typeStyle } from '../styles/makesStyles'
import { UPDATE_ART_PRICES } from './Queries'

const AdjustPriceModal = (props) => {
	const { open, close, rows } = props

	const initialInputState = {
		art_financial_type_id: 3,
		currency_id: 1,
		value: null,
	}

	const [input, setInput] = React.useState(initialInputState)

	const [action, setAction] = React.useState('exact')
	const [percentDir, setPercentDir] = React.useState('pos')
	const [attempt, setAttempt] = React.useState(false)
	const [primary, setPrimary] = React.useState(false)
	const [rounding, setRounding] = React.useState(0.01)
	const [roundUp, setRoundUp] = React.useState(false)

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const [loading, setLoading] = React.useState(false)

	useEffect(() => {
		if (loading) openSnackbar(severity.INFO, 'Loading')
	}, [loading, openSnackbar])

	const handleClose = () => {
		setPercentDir('pos')
		setAction('exact')
		setPrimary(false)
		setInput(initialInputState)
		setRounding(0.01)
		setRoundUp(false)
		setAttempt(false)
		close()
	}

	const [updateArtPrices] = useMutation(UPDATE_ART_PRICES)

	const handleSubmit = () => {
		setAttempt(true)

		if (attempt && !input.value) {
			openSnackbar(severity.WARNING, 'Please correct the fields in red.')
		} else if (input.value) {

			setLoading(true)

			const { value, art_financial_type_id, currency_id } = input

			const inputVariables = {
				art_financial_type_id,
				currency_id,
				value: parseFloat(value.replace(/%/g, '')),
				percentDir,
				primary,
				action,
				art_ids: rows?.map((e) => e.id),
				rounding,
				roundUp
			}

			updateArtPrices({
				variables: {
					ArtPricesInput: inputVariables,
				},
			})
				.then((response) => {
					if (response.data?.updateArtPrices?.success) {
						openSnackbar(severity.SUCCESS, response.data.updateArtPrices.message)
					} else if (!response.data?.updateArtPrices && response.errors?.[0]?.message?.includes('Admin Console')) {
						openSnackbar(severity.ERROR, 'Error: Requires admin privileges.')
					} else {
						openSnackbar(severity.ERROR, response.data.updateArtPrices.message)
					}

					setLoading(false)
					handleClose()
				})
				.catch((error) => {
					console.log(error)
					openSnackbar(severity.ERROR, error)
					setAttempt(false)
					setLoading(false)
				})
		}
	}

	const lookup = useContext(LookupContext)

	const types = lookup.data?.getArtFinancialTypes
	const currencies = lookup.data?.getCurrencies

	const isExact = action === 'exact'

	return (
		<TransitionsModal className="price-modal"
			open={open}
			close={handleClose}
		>
			<div style={{ width: '35em' }}>
				<h2
					className="card-title"
					style={{
						paddingBottom: '0.5em',
						margin: 0,
					}}
				>
					Adjust Price
				</h2>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<FormControl className="fullWidth">
						<Label id="type-label" style={typeStyle} disableAnimation shrink>
							Art Financial Type
						</Label>
						<Select
							IconComponent={ExpandMoreRounded}
							name="type"
							className="padded-select"

							labelId="type-label"
							input={<Dropdown />}
							value={(types && input.art_financial_type_id) || ''}
							onChange={(event) => {
								setInput({
									...input,
									art_financial_type_id: event.target.value,
								})
							}}
						>
							{types?.map((type) => (
								<MenuItem key={type.id} value={type.id}>
									{type.value}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl className="fullWidth" style={{ marginLeft: '1em' }}>
						<Label id="action-label" style={typeStyle} disableAnimation shrink>
							Action
						</Label>
						<Select
							IconComponent={ExpandMoreRounded}
							name="action"
							className="padded-select"

							labelId="action-label"
							input={<Dropdown />}
							value={action}
							onChange={(event) => {
								setAttempt(false)
								setInput({
									...input,
									value: '',
								})
								setAction(event.target.value)
							}}
						>
							<MenuItem key="Exact Value" value="exact">
								Exact Value
							</MenuItem>
							<MenuItem key="Percentage" value="percentage">
								Percentage
							</MenuItem>
						</Select>
					</FormControl>
				</div>

				<div
					style={{
						display: 'flex',
						justifyContent: 'space-evenly',
						marginTop: '0.5em'
					}}
				>
					<FormControl
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'baseline',
							paddingTop: '1.6em',
							flexBasis: '10em'
						}}
					>
						<Label id="primary-label" style={typeStyle} disableAnimation shrink>
							Primary
						</Label>
						<Checkbox color="primary" title="Toggle Primary" value={primary} onChange={(_, val) => setPrimary(val)} />
					</FormControl>
					<FormControl className="padded-select"
						sx={{ flexBasis: '15em', flexGrow: 0 }}
					>
						<Label id="currency-label" disableAnimation shrink>
							Currency
						</Label>

						<Select
							IconComponent={ExpandMoreRounded}
							name="currency"

							labelId="currency-label"
							input={<Dropdown />}
							style={{ width: '77px' }}
							value={(currencies && input.currency_id) || ''}
							renderValue={(value) => currencies?.find((e) => e.id == value)?.symbol}
							onChange={(e) => {
								setInput({
									...input,
									currency_id: e.target.value,
								})
							}}
						>
							{currencies?.map((currency) => (
								<MenuItem key={currency.id} value={currency.id}>
									{`${currency.symbol} - ${currency.name}`}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<div style={{
						display: 'flex',
						flexBasis: '100%',
						flexGrow: 2
					}}>

						{!isExact ? <FormControl sx={{ flexShrink: 0 }}>
							<Label disableAnimation shrink
								sx={{ overflow: 'visible' }}
							>
								Percentage Change
							</Label>
							<ToggleButtonGroup
								value={percentDir}
								exclusive
								onChange={(_, value) => setPercentDir(value)}
								style={{
									height: 45,
									marginTop: 24,
								}}
							>
								<ToggleButton value="pos">
									<Add fontSize="small" />
								</ToggleButton>
								<ToggleButton value="neg" style={{
									borderRadius: 0,
									borderRightWidth: 0
								}}>
									<Remove fontSize="small" />
								</ToggleButton>
							</ToggleButtonGroup>
						</FormControl> : null}


						<FormControl
							sx={{ width: '100%', flexShrink: 1 }}
							error={attempt && !input.value}
						>
							<Label id="price-label" disableAnimation shrink>
								{isExact ? 'Price' : null}
							</Label>

							<NumberFormat
								id={!isExact ? "percent-art-price" : null}
								customInput={TextBox}
								thousandSeparator={isExact}
								suffix={action === 'percentage' ? '%' : ''}
								allowNegative={false}
								decimalScale={2}
								value={input.value}
								error={attempt && !input.value}
								isAllowed={(values) => {
									const value = values?.floatValue

									if (!value) return true

									if (!isExact) {
										if (percentDir === 'pos' && value < 200) {
											return true
										}

										// Negative
										else if (percentDir === 'neg' && value < 100) {
											return true
										}
									} else return true
								}}
								onChange={(e) => {
									setInput({
										...input,
										value: e.target.value.replace(/,/g, ''),
									})
								}}
								allowLeadingZeros={false}
							/>
						</FormControl>

					</div>
				</div>

				{!isExact ? (
					<>
						<Divider direction="horizontal" 
							style={{ 
								marginTop: '1.5em',
								marginBottom: '0.5em'
							}}
						/>

						<div
							style={{
								display: 'flex',
								justifyContent: 'space-evenly',
							}}
						>
							<FormControl>
								<Label disableAnimation shrink	>
									Rounding
								</Label>

								<ToggleButtonGroup
									style={{
										marginTop: '2em',
									}}
									exclusive
									value={rounding}
									onChange={(_, value) => setRounding(value)}
									size="medium"
								>
									<ToggleButton value={10000}>10,000</ToggleButton>
									<ToggleButton value={1000}>1000</ToggleButton>
									<ToggleButton value={100}>100</ToggleButton>
									<ToggleButton value={10}>10</ToggleButton>
									<ToggleButton value={1}>1</ToggleButton>
									<ToggleButton value={0.1}>.1</ToggleButton>
									<ToggleButton value={0.01}>.01</ToggleButton>
								</ToggleButtonGroup>
							</FormControl>

							<FormControl
								style={{
									width: 'fit-content',
									paddingTop: '1.6em',
								}}
							>
								<Label id="round-up-label" sx={{
									...typeStyle,
									paddingTop: '0.7em',
									marginLeft: '-0.4em',
									overflow: 'visible'
								}} disableAnimation shrink>
									Round Up
								</Label>
								<Checkbox color="primary" title="Force Round Up" style={{
									marginTop: '0.3em'
								}} value={roundUp} onChange={(_, val) => setRoundUp(val)} />
							</FormControl>
						</div>
					</>
				) : null}

				<div
					style={{
						width: '100%',
						paddingBottom: '1em',
						marginTop: '1.5em',
					}}
				>
					<CancelButton variant="contained" style={{ float: 'left' }} onClick={() => handleClose()}>
						Cancel
					</CancelButton>

					<SubmitButton disabled={loading}
						variant="contained"
						style={{ float: 'right' }}
						type="submit"
						onClick={() => handleSubmit()}
					>
						Submit
					</SubmitButton>
				</div>
			</div>
		</TransitionsModal>
	)
}

export default AdjustPriceModal
