import React from 'react'
import { Avatar } from '@mui/material'
import { Skeleton } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import AssignmentIcon from '@mui/icons-material/Assignment'
import './Thumbnail.css'
import QuillText from '../input/QuillText'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import LockIcon from '@mui/icons-material/Lock'
import BusinessIcon from '@mui/icons-material/Business'
import { ReactComponent as ContactFilledSvg } from '../images/icons/White/ContactFilled.svg'

const variantMap = {
	art: 'square',
	contact: 'circular',
	listing: 'rounded',
	task: 'circular',
	artist: 'circular'
}

export const StyledAvatar = withStyles({
	root: {
		fontSize: '0.9rem',
	},
})(Avatar)

/**
 * Gets the avatar image, inititals, or placeholder image.
 * @param {*} props
 */
export const getIconOrLabel = (props) => {
	const style={
		height: props.heightOverride ?? '1.8em'
	}

	if (props.avatar)
		return (
			<img
				src={props.avatar}
				alt={props.name ? props.name : 'Thumbnail Image'}
			/>
		)

	switch (props.type) {
	case 'listing':
		return <AssignmentIcon style={{
			fontSize: 30
		}}></AssignmentIcon>

	case 'art':
		return <img alt={'art-icon'} style={style}
			src={`/images/icons/White/ArtImage.svg`} />

	case 'artist':
		return <img alt={'artist-icon'} style={style}
			src={`/images/icons/White/ArtistFilled.svg`} />

	default:{
		if (!props.isCompany)
			return <ContactFilledSvg alt={'contact-icon'} style={style} />
			// return <img alt={'contact-icon'}
			// 	src={`/images/icons/White/ContactFilled.svg`} />

		else return <BusinessIcon style={{ fontSize: 25 }}/>
	}}
}

/**
 * @typedef {Object} UserProps
 * @property {string=} name - The full display name.
 * @property {string=} avatar - the URL to the avatar image.
 * @property {string=} detail - the detail.
 * @property {string=} date - a date that may appear in the upper right.
 * @property {boolean=} isPrivate - If the Thumbnail is private. A type and name should still be included.
 * @property {'art' | 'contact' | 'listing'} type - Art (square), Contact (round), Listing (rounded-square)
 * @property {function=} onClick
 * @property {false | 'pulse' | 'wave'=} animation - animation is enabled if true
 * @property {boolean=} largeText - text is 10em if true
 * @property {boolean=} hideDetails - hides the details text/skeleton
 * @property {boolean=} hasAccess - shows an unlocked private icon
 * @property {boolean=} darkText - if private, toggles dark codename
 * @property {boolean=} isCompany - whether or not contact is a company
 * @property {boolean=} isDeceased - whether or not contact is a dead
 */

/**
 * A representation of a User
 * @param {UserProps} props
 * @returns {JSX.Element}
 */
export default function Thumbnail(props) {
	let {
		name,
		formatted_name,
		avatar,
		detail,
		isPrivate,
		type,
		artist,
		onClick,
		hasAccess,
		style,
		date,
	} = props

	const variant = variantMap[type]
	const thumbnailTextComponent = () => (
		<div className="thumbnail-component-text">
			<header style={{ paddingBottom: '0' }}>
				<span className={`${(props.darkText === false) ? "paragraph-2-light" : "paragraph-2-black"} font-normal`} style={{ color: props?.type === "contact" && props?.isDeceased ? "#A91D23" : null }} >
					{formatted_name || (!formatted_name && artist) ? (
						<>
							{artist ? (
								<span
									style={{
										color: (props.darkText === false) ? 'white' : null
									}}
								>
									{ artist }
								</span>
							) : (
								<Skeleton
									variant="text"
									width={props.largeText ? '10em' : '5em'}
									animation={
										props.animation
											? props.animation
											: false
									}
								/>
							)}
						</>
					) : (
						<>
							{name ? (
								name
							) : (
								<Skeleton
									variant="text"
									width={props.largeText ? '10em' : '5em'}
									style={{
										backgroundColor: props.privateTheme ? 'hsl(0deg 0% 100% / 13%)' : null,
									}}
									animation={
										props.animation
											? props.animation
											: false
									}
								/>
							)}
						</>
					)}
				</span>
			</header>
			{!props.hideDetails ? (
				<span
					className="paragraph-2-grey italic"
					style={props.hideDetail ? { display: 'none', color: (props.darkText === false) ? 'white' : null } : { color: (props.darkText === false) ? 'white' : null}}
				>
					{formatted_name ? (
						<>
							<QuillText noWrap name={name}>
								{typeof formatted_name === 'object'
									? formatted_name
									: JSON.parse(formatted_name)}
							</QuillText>
						</>
					) : (
						<>
							{(detail != null) ? (
								detail
							) : (
								<>
									{!artist ? (
										<Skeleton
											variant="text"
											width={
												props.largeText
													? '10em'
													: '5em'
											}
											animation={
												props.animation
													? props.animation
													: false
											}
											style={{
												backgroundColor: props.privateTheme ? 'hsl(0deg 0% 100% / 13%)' : null
											}}
										/>
									) : null}
								</>
							)}
						</>
					)}
					{(date && formatted_name) ? `, ${date}` : null}
					{(date && !formatted_name) ? `${date}` : null}
				</span>
			) : null}
		</div>
	)

	return isPrivate ? (
		<div
			className="thumbnail-component"
			style={style ?? null}
			onClick={onClick}
		>
			<Avatar
				alt={name || 'Private Entity'}
				variant={variant ? variant : 'circular'}
			>
				{!hasAccess ? <LockIcon /> : <LockOpenIcon />}
			</Avatar>
			{formatted_name ? thumbnailTextComponent() : <div className="thumbnail-component-text">
				{!props.noText ? (
					<span
						className="paragraph-1"
						style={{ color: !props.darkText ? 'white' : null }}
					>
						{name ? (
							name
						) : (
							<Skeleton
								variant="text"
								width="5em"
								animation={
									props.animation ? props.animation : false
								}
							/>
						)}
					</span>
				) : null}
			</div>}
			
		</div>
	) : (
		<div
			className="thumbnail-component"
			onClick={onClick}
			style={style ?? null}
		>
			<StyledAvatar
				alt={name || 'Private Entity'}
				src={avatar}
				variant={variant ? variant : 'circular'}
			>
				{getIconOrLabel(props)}
			</StyledAvatar>
			{thumbnailTextComponent()}
		</div>
	)
}
