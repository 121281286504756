import withStyles from '@mui/styles/withStyles'
import InputBase from '@mui/material/InputBase'

const DealEntryDateTimePicker = withStyles(theme => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(2),
		},
	},
	input: {
		border: '1px solid transparent',
		position: 'relative',
		fontSize: 16,
		padding: '10px 12px 10px 12px',
		borderRadius: 4,
		minWidth: '7em',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: 'Graphik',
	},
}))(InputBase)

const DealEntryWhiteDateTimePicker = withStyles(theme => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(2),
		},
	},
	input: {
		border: '1px solid transparent',
		position: 'relative',
		fontSize: 16,
		padding: '10px 12px 10px 12px',
		borderRadius: 4,
		minWidth: '7em',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: 'Graphik',
		'&:hover': {
			borderRadius: 4,
			border: '1px solid transparent',

		},
		'&:focus': {
			borderRadius: 4,
			border: '1px solid transparent',
		},
	},
}))(InputBase)

export { DealEntryDateTimePicker, DealEntryWhiteDateTimePicker }
