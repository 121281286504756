/* eslint-disable eqeqeq */
import React from 'react'
import FormControl from '@mui/material/FormControl'
import {autoCompleteStyles} from '../styles/makesStyles'
import { withApollo } from '@apollo/client/react/hoc'	
import { SIMPLE_SEARCH_DEALS } from './Queries'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { SaveCardButton, CancelButton } from '../input/Buttons'
import { searchStyles } from '../styles/makesStyles'
import LimitedAutocomplete from '../common/LimitedAutocomplete'
import { CircularProgress } from '@mui/material'
import { getNonNullArray } from '../common/helpers'
import TextBoxThinForAutocomplete from '../input/Text/TextBoxThinForAutocomplete'

function DealDropdown(props) {
	const classes = searchStyles()
	const autoCompleteStyle = autoCompleteStyles()
	
	// User
	const [optionsDropdown, setOptionsDropdown] = React.useState(false)
	const [options, setOptions] = React.useState([])
	const [loadDeal, setLoadDeal] = React.useState(false)
	const dealLoading = optionsDropdown && !!loadDeal

	const [selection, setSelection] = React.useState(null)
	const [dealId, setDealId] = React.useState(null)

	React.useEffect(() => {
		let active = true

		if (!dealLoading) {
			return undefined
		}


		props.client
			.query({
				query: SIMPLE_SEARCH_DEALS,
				variables: { query: loadDeal },
			})
			.then((result) => {
				if (active) {
					setLoadDeal(false)
					setOptions(getNonNullArray(result.data.simpleSearchDeals))
				}
			})
		

		return () => {
			active = false
		}
	}, [dealLoading, loadDeal, props.client])
	
	return (
		<div>
			<FormControl className={classes.selection} style={{ width: "100%"}}>
				
				<div style={{ display: 'flex', justifyContent:'space-between', alignItems: 'center'}}>
					<h1 className="card-title" >Deals</h1>
				</div>
				<LimitedAutocomplete

					query={loadDeal}
					setQuery={setLoadDeal}
					minimum={2}

					classes={autoCompleteStyle}
					open={optionsDropdown && !!options?.length}
					isOptionEqualToValue={(option, value) => { return option.id == value.id }}
					forcePopupIcon
					filterSelectedOptions
					popupIcon={<ExpandMoreRoundedIcon />}
					value={selection}
					onOpen={() => { setOptionsDropdown(true) }}
					onClose={() => { setOptionsDropdown(false)}}
					getOptionLabel={option => {
						return (option && (option.code_name || option.id)) || ''
						
					}}
					options={options || []}
					onChange={(event, value) => {
						setSelection(value)
						const id = value && Number(value.id)
						setDealId( id )
					}}
					renderInput={(params) => (
						<TextBoxThinForAutocomplete
							{...params}
							variant="outlined"
							fullWidth
							placeholder={!selection ? "-" : ""}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<React.Fragment>
										{dealLoading ? (
											<CircularProgress
												color="inherit"
												size={20}
											/>
										) : null}
										{params.InputProps.endAdornment}
									</React.Fragment>
								),
							}}
						/>
					)}
				/>
			</FormControl>

			<div style={{ 
				display: 'flex',
				justifyContent: 'space-between',
				marginTop: '1em',
			}}>
				<CancelButton
					variant="contained"
					size="medium"
					onClick={props.close}
				>
					Cancel
				</CancelButton>
				<SaveCardButton
					variant="contained"
					size="medium"
					disabled={!dealId || props.loading}
					onClick={() =>{
						props.onSave(dealId)
					}}
				>
					Save
				</SaveCardButton>
			</div>
		</div>
	)
}


export default withApollo(DealDropdown)
