/* eslint-disable eqeqeq */
import {
	DELETE_ART_NOTE,
	DELETE_ARTIST_NOTE,
	DELETE_CONTACT_NOTE,
	DELETE_DEAL_NOTE,
	DELETE_LISTING_NOTE
} from '../Queries'
import { AuthStateContext, DispatchContext } from '../../store'
import {
	Divider, FormControl, MenuItem, Menu,
	Paper, Select, Skeleton
} from '@mui/material'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { useMutation } from "@apollo/client"
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import {isSameDay} from 'date-fns'
import Dropdown from '../../input/Dropdown/Dropdown'
import InfoCard from '../../InfoCard/InfoCard'
import Label from '../../input/Label'
import NoteListItem from './ObjectNoteListItem'
import React, { useContext, useCallback } from "react"
import { SearchButton, CancelButton } from '../../input/Buttons'
import EditNotes from '../EditNotes'
import { LookupContext } from '../../store'
import { searchStyles } from '../../styles/makesStyles'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { GET_USERS } from '../../AdminConsole/Queries'
import { withApollo } from '@apollo/client/react/hoc'
import sortBy from 'lodash/sortBy'
import TextBox from '../../input/Text/TextBox'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from "@mui/material/IconButton"
import { permissions, permissionValues } from '../../constants/permissions'
import SingleUser from '../../input/SingleUser'

function ObjectNotesList(props) {

	const lookup = useContext(LookupContext)
	const noteTypes= lookup.data?.getNoteTypes
	const sortedTypes = sortBy(noteTypes, [function(o) { return o.value }])

	const classes = searchStyles()

	const typeStyle = {
		fontWeight: 500,
	}

	// Filter
	const [filter, setFilter] = React.useState({
		type: "All",
		date: null,
		user: null
	})

	// Initial filters
	const [noteObject, setNoteObject] = React.useState({
		type: "All",
		date: null,
		user: null
	})

	// Menu
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [currentNote, setCurrentNote] = React.useState(null)

	const handleClick = (event, note)=> {
		setAnchorEl(event.currentTarget)
		setCurrentNote(note)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	// Mutations
	const [deleteArtNote] = useMutation(DELETE_ART_NOTE)
	const [deleteArtistNote] = useMutation(DELETE_ARTIST_NOTE)
	const [deleteContactNote] = useMutation(DELETE_CONTACT_NOTE)
	const [deleteDealNote] = useMutation(DELETE_DEAL_NOTE)
	const [deleteListingNote] = useMutation(DELETE_LISTING_NOTE)

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])


	// Modal
	const [editNoteModal, setEditNoteModal] = React.useState({open: false, note: null})
	const [infoModal, setInfoModal] = React.useState({open: false})

	const handleDeleteResponse = (response) => {

		let mutationResponse

		switch (props.linkField) {
		case "art_id":
			mutationResponse = 'deleteArtNote'
			break

		case "artist_id":
			mutationResponse = 'deleteArtistNote'
			break

		case "contact_id":
			mutationResponse = 'deleteContactNote'
			break

		case "deal_id":
			mutationResponse = 'deleteDealNote'
			break

		case "listing_id":
			mutationResponse = 'deleteListingNote'
			break

		default:
			break
		}

		if (response?.data[mutationResponse]?.success === false) {

			console.error(response?.data[mutationResponse])
			openSnackbar(
				severity.ERROR,
				response?.data[mutationResponse]?.message
					|| 'There was an error deleting this note.'
			)
		} else if (response?.data[mutationResponse]?.success == true) {

			props.setNotes(props.notes?.filter(note => note.id != currentNote.id))
			openSnackbar(severity.SUCCESS, "Successfully deleted note.")
		} else {

			console.error(response?.data[mutationResponse])
			openSnackbar(
				severity.ERROR,
				response?.data[mutationResponse]?.message
					|| 'Could not delete note.'
			)
		}
	}

	const handleDeleteError = (error) => {

		openSnackbar(severity.ERROR, "There was an error deleting this note.")
	}

	// Autocomplete
	const [selection, setSelection] = React.useState({})

	// Add and remove critical notes from qv on mutation
	React.useEffect(() => {

		const criticalQVNotes = props.state[props.criticalNotes]
		const criticalObjectNotes = props.notes?.filter(e => e.is_critical)

		if (!props.showcaseMode && criticalQVNotes?.length != criticalObjectNotes.length) {
			props.setState({
				...props.state,
				[props.criticalNotes]: criticalObjectNotes
			})
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.notes])

	const authState = useContext(AuthStateContext)
	const userPermissions  = authState?.user?.permissions
	const privateTogglePermission = userPermissions?.find(e => e.permission_id == permissions.PRIVATE_OBJECT_TOGGLE)?.permission_value_id == permissionValues.YES

	const noPrivate = props.linkField == 'artist_id' || props.linkField == 'listing_id' || !privateTogglePermission

	return (
		<React.Fragment>
			<Paper
				className={props.showcaseMode ? "qv-margin" : "padding-margin-scrollbar"}
				id="notes-list"
				data-testid="card-notes"
			>
				<h1 className="card-title">{props.alias ?? "Notes"} {`(${props.notes?.length || 0})`}

					{props.addButton ? <>
						<div className='spacer'></div>
						<IconButton
							onClick={() => {
								props.setNewNoteModal(true)
							}}
						>
							<EditIcon />
						</IconButton>
					</> : null}

				</h1>

				<form onSubmit={(e) => {
					e.preventDefault()
				}}>
					<div className="row" style={{justifyContent: "flex-start"}}>
						{!props.disableTypeFilter ? <FormControl className={classes.searchSelection} style={{maxWidth: "13em"}}>
							<Label id="type-label" style={typeStyle} disableAnimation shrink>
								Type
							</Label>
							<Select
								name="type"

								labelId="type-label"
								className="padded-select"
								input={<Dropdown />}
								IconComponent={ExpandMoreRoundedIcon}
								value={noteObject.type}
								onChange={(e) => {
									setNoteObject({
										...noteObject,
										type: e.target.value
									})
								}}
							>
								<MenuItem key="All" value="All">All</MenuItem>
								{sortedTypes && sortedTypes.map(type => (
									<MenuItem key={type.id} value={type.id}>{type.value}</MenuItem>
								))}
							</Select>
						</FormControl> : null}

						<FormControl className={classes.searchDate} style={{maxWidth: "18em"}}>
							<Label id="date-label" style={typeStyle} disableAnimation shrink>
							Date
							</Label>

							<MobileDatePicker
								inputFormat="MMM do, yyyy"
								disableMaskedInput={true}
								componentsProps={{
									actionBar: {
										actions: ['today', 'clear', 'accept']
									}
								}}
								todayLabel="Today"
								name="last_modified"
								inputVariant="outlined"
								style={{marginTop: "17px"}}
								className="MUIDatePicker"
								variant="dialog"
								value={noteObject.date}
								renderInput={({ inputRef, inputProps, InputProps }) => {

									const newProps = { ...inputProps}

									newProps.readOnly = false

									return (
										<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
									)}}
								onChange={(date) => {
									setNoteObject({
										...noteObject,
										date: date
									})
								}}
							/>
						</FormControl>

						<FormControl style={{maxWidth: "18em"}}>
							<Label
								id="user-label"
								style={typeStyle}
								disableAnimation
								shrink
							>
								User
							</Label>
							<SingleUser
								style={{ marginTop: '1.5em'}}
								query={GET_USERS}
								value={selection || {}}
								onChange={(event, value) => {
									setSelection(value)
									setNoteObject(oldNode => ({
										...oldNode,
										user: value
									}))
								}}
							/>
						</FormControl>

						<div >
							<CancelButton
								data-testid="search-button"
								variant="contained"
								type="submit"
								onClick={() =>
								{
									setFilter({
										type: "All",
										date: null,
										user: null
									})
									setNoteObject({
										type: "All",
										date: null,
										user: null
									})
									setSelection([])
								}}
								style={{
									backgroundColor: '#cc3333',
									color: '#FFFFFF',
									marginTop: '1.9em',
									marginLeft: '1em',
								}}
							>
									Reset
							</CancelButton>
							<SearchButton
								data-testid="search-button"
								variant="contained"
								type="submit"
								onClick={() => setFilter(noteObject)}
								style={{
									backgroundColor: 'rgb(68, 101, 209)',
									marginTop: '1.9em',
									marginLeft: '1em',
								}}
							>
									Search
							</SearchButton>
						</div>

					</div>
				</form>

				<div id="notes-list">
					<div id="notes-list-header" style={{paddingTop: "1em"}}>
						Type, User &amp; Note
						<Divider></Divider>
					</div>


					{!props.notesLoading && props.notes?.length == 0 ?
						<div style={{
							display: 'flex',
							height: 230,
							borderBottom: '1px solid #e0e0e0'
						}}>
							<p>No notes found.</p>
						</div>
						: null}

					{props.notesLoading ? (
						<div style={{
							borderBottom: '1px solid #e0e0e0'
						}}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									marginTop: 18
								}}
							>
								<div>
									<Skeleton animation='wave' width='7em'/>
									<Skeleton animation='wave' width='5em' style={{ marginTop: 4, marginBottom: 14}}/>
								</div>
								<div style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-end',
								}}>
									<Skeleton animation='wave' width='10em'/>
								</div>
							</div>
							<Skeleton variant='rectangular' animation='wave' height='7em' style={{ marginTop: 16, marginBottom: 16}}/>
						</div>
					) : null}


					{props.notes?.map((note, i) => {

						if (filter.type !== "All" && parseInt(note.type_id) !== parseInt(filter.type)) return null
						if (filter.user && note.applied_user_id !== filter.user.id) return null
						if (filter.date && !isSameDay(new Date(filter.date), new Date(Number(note.applied_at)))) return null

						return (
							<div key={"note-" + i}>
								<NoteListItem
									note={note}
									users={props.users}
									count={i}
									handleClick={handleClick}
									handleClose={handleClose}
									disableEdits={props.disableEdits}
								/>
								<Divider></Divider>
							</div>
						)
					})}

				</div>

				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={(e) => {
						setInfoModal({open: true})
						handleClose()
					}}>Info</MenuItem>
					<MenuItem onClick={(e) => {
						setEditNoteModal({open: true, note: currentNote})
						handleClose()
					}}>Edit</MenuItem>
					<MenuItem onClick={(e) => {

						const variables = {
							id: currentNote.id
						}

						switch (props.linkField) {
						case "art_id":
							deleteArtNote({
								variables
							})
								.then(handleDeleteResponse)
								.catch(handleDeleteError)
							break

						case "artist_id":
							deleteArtistNote({
								variables
							})
								.then(handleDeleteResponse)
								.catch(handleDeleteError)
							break

						case "contact_id":
							deleteContactNote({
								variables
							})
								.then(handleDeleteResponse)
								.catch(handleDeleteError)
							break

						case "deal_id":
							deleteDealNote({
								variables
							})
								.then(handleDeleteResponse)
								.catch(handleDeleteError)
							break

						case "listing_id":
							deleteListingNote({
								variables
							})
								.then(handleDeleteResponse)
								.catch(handleDeleteError)
							break

						default:
							break
						}

						handleClose()

					}}>Delete</MenuItem>
				</Menu>

			</Paper>

			<EditNotes
				editNoteModal={editNoteModal}
				setEditNoteModal={setEditNoteModal}
				notes={props.notes}
				setNotes={props.setNotes}
				mutationResponse={props.mutationResponse}
				updateMutationName={props.updateMutationName}
				privateEntity={props.privateEntity || false}
				disablePrivate={noPrivate}
			/>

			<InfoCard open={infoModal.open} object={currentNote} setInfoModal={setInfoModal} allAttributes ignoredAttributes={[
				'created_by',
				'modified_by',
				'type_id',
				'applied_at',
				'applied_user_id',
				'note_text'
			]}/>

		</React.Fragment>
	)
}

export default withApollo(ObjectNotesList)
