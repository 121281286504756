export const permissionValues = {
	YES: 1,
	NO: 2,
	CANNOT_SEE: 3,
	VIEW_ONLY: 4,
	CREATE_AND_EDIT_OWN: 5,
	CREATE_AND_EDIT: 6
}

export const permissions = {
	ART_TO_EXCEL: 1,
	ART_TO_PDF: 2,
	CONTACTS_TO_EXCEL: 3,
	CONTACTS_TO_PDF: 4,
	IOS_SHOWCASE: 5,
	PRIVATE_OBJECT_ACCESS: 6,
	ADMIN_CONSOLE: 7,
	CONTACT_INFORMATION: 42,
	SOURCE: 44,

	ART: 8,
	ART_NOTES: 9,
	ART_TASKS: 10,
	ART_TAGS: 11,
	ART_SALESPEOPLE: 12,
	SHOWCASE: 13,
	RETAIL: 14,
	COST: 15,
	NET: 16,
	PRODUCTION: 45,
	INSURED: 17,

	ART_LISTINGS: 8,
	ART_DEALS: 8,

	CONTACTS: 18,
	CONTACTS_NOTES: 19,
	CONTACTS_TASKS: 20,
	CONTACTS_TAGS: 21,
	CONTACTS_SALESPEOPLE: 22,

	CONTACTS_LISTINGS: 18,
	CONTACTS_DEALS: 18,

	ARTISTS: 23,
	ARTISTS_NOTES: 24,
	ARTISTS_TASKS: 25,
	ARTISTS_TAGS: 26,

	LISTINGS: 27,
	LISTINGS_NOTES: 28,
	LISTINGS_TASKS: 29,
	LISTINGS_TAGS: 30,

	DEALS: 31,
	DEALS_NOTES: 32,
	DEALS_TASKS: 33,
	DEALS_TAGS: 34,

	VIEW_COMPLIANCE_FILES: 35,
	VIEW_FINANCIAL_FILES: 36,
	VIEW_SENSITIVE_IMAGES: 37,
	PRIVATE_OBJECT_ADMIN: 38,

	ARTISTS_SALESPEOPLE: 39,
	LISTINGS_SALESPEOPLE: 40,
	DEALS_SALESPEOPLE: 41,
	CATALOGUE_RAISONNE: 43,
	BULK_EDIT: 51,

	ACCESS_ALL_PUBLIC_DEALS: 53,
	ACCESS_ALL_PUBLIC_CONTACTS: 54,

	ADMIN_USERS: 55,
	ADMIN_IMPORT_TOOL: 56,
	PRIVATE_OBJECT_TOGGLE: 57,
	SILENT_TRANSACTIONS: 58,

	LGDR_ART: 59,
	LEVY_GORVY_ART: 60,
	SALON_94_ART: 61,
	DAYAN_ROHATYN_ART: 62, 
	LUXEMBOURG_AND_DAYAN_ART: 63,
	LEVY_GORVY_DAYAN_ART: 64,
}
