/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useEffect } from 'react'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import {
	Checkbox,
	Collapse,
	FormControl,
	FormControlLabel,
	FormGroup
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { CancelButton, SubmitButton } from '../input/Buttons'
import chunk from 'lodash/chunk'
import { getPdf } from '../Exports/PdfExport'
import { DispatchContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import Toggle from '../input/Toggle/UserToggle'

const FactSheetModal = (props) => {
	const exports = {

		// caption
		formatted_title: true,
		year: true,
		medium: true,
		dimension: true,
		signature: true,
		edition: true,

		// details
		inventory_number: false,
		retail_price: false,
		has_coa: false,

		// research
		provenance: false,
		exhibition: false,
		literature: false,
		other: false,

		// images
		imagesToggle: false,
		imagesArray: []
	}

	const exportArrangement = {
		Caption: [
			"formatted_title",
			"year",
			"medium",
			"dimension",
			"signature",
			"edition",
		],
		Details: [
			"inventory_number",
			"retail_price",
			"has_coa",
		],
		Research: [
			"provenance",
			"exhibition",
			"literature",
			"other"
		]
	}

	const [exportState, setExportState] = React.useState(exports)
	const [page, setPage] = React.useState(1)

	const getLabelOverride = (input) => {
		switch (input) {
		case 'other':
			return 'Text'

		case 'dimension':
			return 'Dimensions'

		case 'formatted_title':
			return 'Title'

		case 'year':
			return 'Date'

		case 'has_coa':
			return 'Certificate of Authenticity'

		default:
			return startCase(toLower(input))
		}
	}

	const getFactSheetInputs = (chunk) => {
		return chunk?.map((root, i) => {
			return (
				<FormControlLabel
					style={{width: '15em'}}
					key={`${root}-${i}`}
					control={
						<Checkbox
							checked={exportState[root]}
							name={root}
							onChange={(event) => {
								setExportState({
									...exportState,
									[event.target.name]: event.target.checked,
								})
							}}
						/>
					}
					label={getLabelOverride(root)}
				/>
			)
		})
	}

	// If every value is false
	const error = Object.values(exportState).every((e) => e === false)

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const useStyles = makeStyles((theme) => ({
		toggleContainer: {
			marginTop: '-1.5em',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			marginRight: '1em'
		},
		toggle: {},
	}))

	const classes = useStyles()

	useEffect(() => {
		setExportState({
			...exportState,
			imagesArray: props.entity?.images?.filter((_, index) => index !== 0)?.map(e => ({id: e.id, detail: true}))
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.entity?.images])

	return (
		<TransitionsModal open={props.open} close={props.close} scroll={true}>
			<div className={props.entity?.is_private ? 'private-object' : null} style={{ width: '32em' }}>
				<h1 className="card-title">Create Fact Sheet</h1>


				{page !== 1 ? <p style={{
					color: props.entity?.is_private ? 'white' : null
				}}>  Do you want to include additional images? If so, mark which images are detail shots. </p> : null }
				

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>

					{page === 1 ? <>
						<FormControl
							required
							error={error}
							component="fieldset"
						>
							{
								Object.entries(exportArrangement).map(([heading, group], i)=>(
									<div key={heading}>
										<h3 style={{fontWeight:'500'}}>{heading}</h3>
										<FormGroup 
											style={{
												flexDirection: 'row',
												justifyContent: 'space-evenly',
											}}
											key={`group-${i}`}
										>
											{chunk(group, Math.ceil(group.length/2)).map((side, i) => {
												return (
													<div
														key={`side-${i}`}
														style={{
															display: 'flex',
															flexDirection: 'column',
															width: 'fit-content'
														}}
													>
														{getFactSheetInputs(side)}
													</div>
												)
											})}
										</FormGroup>
									</div>
								))
							
							}
						</FormControl>
					</> : 

						<>
							<div style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between'
							}}>
								<div>
									<h3 style={{fontWeight:'500'}}>Other Images</h3>
								</div>
								<div>
									<Toggle checked={exportState.imagesToggle} onClick={() => {

										// Check if the current artwork has no other images
										if (props.entity.images.filter((_, index) => index !== 0)?.length === 0) {
											openSnackbar(severity.WARNING, "There are no other images to export.")
											return
										}

										setExportState({
											...exportState,
											imagesToggle: !exportState.imagesToggle
										})

									}}/>
								</div>
							</div>				
							
							<Collapse in={exportState.imagesToggle} > 

								<div style={{
									display: 'flex',
									flexDirection: 'column',
   									overflow: 'scroll',
									maxHeight: '40em'

								}}>
									{props.entity.images
										.filter((_, index) => index !== 0)
										?.map((image) => 
											<div 
												key={image.id} 
												style={{
													padding: '1em'
												}}
											>
												<img
													style={{
														maxWidth: '-webkit-fill-available',
														marginBottom: '-2em'
													}}
													src={image.src || image.imgUrl}
													alt={image.alt_text || image.filename}
												/>
												<div className={classes.toggleContainer}>										
													<FormControlLabel
														className={classes.toggle}
														control={
															<Toggle
																checked={exportState.imagesArray.find(el => el.id == image.id)?.detail}
																onClick={() => {
																	setExportState({
																		...exportState,
																		imagesArray: exportState.imagesArray.map(e => {
																			return e.id == image.id ? {
																				...e,
																				detail: !e.detail
																			} : e
																		})
																	})
					
																}}
															/>
														}
														labelPlacement="start"
														label={<span style={{color: 'white', textShadow: '1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000'}}>Detail Image</span>}
													/>
												</div>
											</div>
										)}
								</div>
							</Collapse>
						</>
					}

					<div style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						marginTop: '1em'
					}}>
						<CancelButton
							variant="contained"
							onClick={() => {
								if (page === 1) {
									setExportState(exports)
									props.close()
								} else {
									setPage(1)
								}
							}}
						>
							{page === 1 ? 'Cancel' : "Back"}
						</CancelButton>

						<SubmitButton
							type="submit"
							variant="contained"
							onClick={(e) => {
								if (error) {
									openSnackbar(severity.WARNING, "Please select at least one attribute.")
								} 
								
								if (page  === 1) {
									// Move to image Page
									setPage(2)
								} else {
								
									const artPiece = {
										id: props.entity.id,
										artist: props.entity.artist,
										title: props.entity.title,
										year: props.entity.year,
									}

									getPdf('Art Fact Sheet', null, null, [artPiece], openSnackbar, exportState, {width: 1024, height: 1024})

									setExportState({
										...exports,
										imagesArray: props.entity?.images
											?.filter((_, index) => index !== 0)?.map(e => ({id: e.id, detail: true}))
									})

									setPage(1)
									props.close()
								}
							}}
						>
							{page === 1 ? 'Next' : "Submit"}
						</SubmitButton>
					</div>
				</div>
			</div>
		</TransitionsModal>
	)
}

export default FactSheetModal
