/* eslint-disable eqeqeq */
import { avatarStyles } from '../../styles/makesStyles'
import { DispatchContext } from '../../store'
import { GET_ARTIST_QV_DETAILS, UPDATE_ARTIST } from '../Queries.js'
import { LookupContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { IconButton, Skeleton } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import QuickView from '../../QuickView/QuickView'
import React, { useEffect, useContext, useCallback } from 'react'
import QuickViewNotes from '../../QuickView/QuickViewNotes'
import AvatarInput from '../../common/AvatarInput'
import QuickViewContacts from '../../QuickView/QuickViewContacts'
import Thumbnail from '../../Thumbnail/Thumbnail'
import { ButtonBase, FormControl } from '@mui/material'
import { findModeFieldsAtom, findModeFieldSelector, FindModeInput } from '../../navigation/Tabs/TabbedPage'
import Label from '../../input/Label'
import { useRecoilCallback, useRecoilState } from 'recoil'
import { Close } from '@mui/icons-material'
import { getArtistThumbnailDetail } from '../../common/helpers'
import GroupsIcon from '@mui/icons-material/Groups'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import { LightTooltip } from '../../styles/makesStyles'

export const AVAILABLE_ART_STATUS = 6


const getAffiliatedIcon = (artist, loading = false) => {
	if (!artist) return null

	const affiliated = <GroupsIcon fontSize='medium' style={{ color: '#33BF5C' }} />

	const unAffiliated = <GroupsOutlinedIcon
		sx={{
			background: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 50 50'><path d='M50 0 L0 50 ' stroke='black' stroke-width='3'/><path d='M0 0 L50 50 ' stroke='black' stroke-width='3'/></svg>")`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center center',
			backgroundSize: '100% 100%, auto'
		}}
		fontSize='medium'
	/>

	const nullAffiliated = <GroupsOutlinedIcon fontSize='medium' />

	if ((artist.is_affiliated) && !loading) return affiliated
	if (artist.is_affiliated == null) return nullAffiliated
	return unAffiliated
}


function ArtistQuickView(props) {

	const lookup = useContext(LookupContext)
	const artistGenres = lookup.data?.getArtistGenres

	const [artist, setArtist] = React.useState(props.state?.getArtist)
	const [notes, setNotes] = React.useState([])
	const [findModeAffiliated, setFindModeAffiliated] = useRecoilState(findModeFieldSelector('is_affiliated'))

	const params = useParams()
	const navigate = useNavigate()
	const classes = avatarStyles()

	const salespersons = artist?.salesperson

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	let active = false

	useEffect(() => {
		if (params.id !== undefined && !props.id && props.state) {
			setArtist(props.state?.getArtist || {})
			setNotes(props.state?.getCriticalArtistNotes)
		}
	}, [params.id, props.id, props.state])

	// Loading Logic
	const [loading, setLoading] = React.useState(true)
	useEffect(() => {
		if (Object.keys(props?.state?.getArtist || {}).length) {
			setLoading(false)
		} else setLoading(true)
	}, [props])

	const [updateArtist, {res}] = useMutation(UPDATE_ARTIST)

	// Loading
	useEffect(() => {
		if (res && res.errors) {
			openSnackbar(severity.ERROR, "Could not update artist.")
		} else if (res) {
			openSnackbar(severity.SUCCESS, "Successfully updated artist.")
		}
	}, [res, openSnackbar])

	useEffect(() => {
		let active = true
		if (props.id && !props.findMode) {

			setArtist(null)
			setNotes([])

			props.client
				.query({
					query: GET_ARTIST_QV_DETAILS,
					variables: { id: props.id },
				})
				.then((result) => {
					if (!active) return
					// Snackbar responses
					if (!result.data.getArtist && result.errors) {
						openSnackbar(severity.ERROR, result.errors[0].message)
						return
					} else {
						setArtist(result.data.getArtist)
						setNotes(result.data.getCriticalArtistNotes)
					}

					setLoading(false)
				})
				.catch((e) => {
					console.error(e)
					openSnackbar(
						severity.ERROR,
						'Could not retrieve artist Quick View.'
					)

					setLoading(false)
				})
		}
		return () => active = false
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.id])

	const canEdit = Object.keys(props?.state?.getArtist || {}).length && true

	// handle FindMode search
	const handleKeyDown = useRecoilCallback(({ snapshot }) => async (e) => {
		if (props.findMode && e.key === 'Escape') {
			props.setFindMode(false)
			return
		}
		if (!props.findMode || e.key !== 'Enter') return
		e.stopPropagation()
		let fields = await snapshot.getPromise(findModeFieldsAtom)
		fields = Object.fromEntries(Object.entries(fields).filter(([_, v]) => v != null && v !== ''))
		navigate('/artists', { state: { fields }})
	})

	const avatarArtist = !props.findMode ? artist : { created_at: true }

	return (
		<QuickView className="quick-view-main">

			<div className="quick-view-inner" onKeyDown={handleKeyDown}>
				<header>
					<h2
						className="quick-view-title"
						style={{ textAlign: 'left', display: 'flex', justifyContent: 'space-between' }}
						data-testid="search-qv-header"
					>
						{ props.findMode ?
							<FindModeInput
								field="full_name"
								placeholder="Artist Name"
								style={{ width: '100%' }}
							/> : (artist?.first_name ?
								`${artist.first_name} ${artist.last_name}`
						 		: (
									loading ? <Skeleton
										animation={!artist?.created_at ? "wave" : false}
										variant="text"
										width="8em"
									/> : <span>-</span>
								)
							)
						}

						<LightTooltip

							arrow
							title={ props.findMode ? "" :
								(!artist?.is_affiliated ?
									`Is Affiliated\nEnabling this shows this artist on the Showcase portion of The Platform.` :
									`Is Affiliated\nDisabling this hides this artist on the Showcase portion of The Platform.`)
							}
						>
							<IconButton
								size='medium'
								onClick={() => {
									if (props.findMode) {
										let next = null
										if (findModeAffiliated) next = false
										if (findModeAffiliated == null) next = true
										setFindModeAffiliated(next)
										return
									}
									updateArtist({
										variables: {
											ArtistInput: {
												id: artist.id,
												is_affiliated: !artist.is_affiliated,
											},
										},
									})
										.then(({ data }) => {
											if (data.updateArtist.success) {
												setArtist({
													...artist,
													...data.updateArtist.artist,
												})
												openSnackbar(severity.SUCCESS, 'Successfully updated afilliation.')
											} else {
												openSnackbar(severity.ERROR, 'Could not update affiliation.')
											}
										})
										.catch((error) => {
											console.error(error)
											openSnackbar(severity.ERROR, 'Could not update affiliation.')
										})
								}}
							>
								{ props.findMode ?
									getAffiliatedIcon({
										is_affiliated: findModeAffiliated
									})
									: (artist?.is_affiliated ?
										<GroupsIcon fontSize='medium' style={{ color: '#33BF5C' }} /> :
										<GroupsOutlinedIcon fontSize='medium' />)
								}
							</IconButton>
						</LightTooltip>

						{props.onClose && artist?.id && <span>
							<IconButton size="large"
								sx={{ height: '35px', width: '35px' }}
								onClick={props.onClose}
							>
								<Close />
							</IconButton>
						</span>}
					</h2>

					<h3
						className="paragraph-1"
						style={{ fontWeight: 400 }}
					>
						{props.findMode ? <FindModeInput
							field="dates"
							placeholder="Dates"
							style={{ width: '100%', marginTop: '.5em' }}
						/> : artist?.dates || ( loading ? <Skeleton
							animation={!artist?.created_at ? "wave" : false}
							variant="text"
							width="8em"
						/> : <span>-</span>
						)}
					</h3>
					<div style={{ display: 'flex' }}>
						<h3
							className="paragraph-1"
							style={{
								fontWeight: 400,
								fontSize: '14px',
								marginRight: props.findMode ? '0' : '1em',
								width: '100%'
							}}
						>
							<p style={{margin: 0, fontStyle: 'italic'}}>
								{artist?.nationality || null}
							</p>

							<p style={{marginTop: 4, marginBottom: 4, fontSize: 14}}>
								{ (!props.findMode && artist?.genre?.map(e => e.artist_genre).join(", ")) || ''}
							</p>

							{props.findMode ? (
								<FindModeInput field='artist_genre' placeholder='Type' style={{ width: '100%', marginTop: '.5em' }} />
							) : !artist || !artistGenres ? (
								<>{loading ? <Skeleton animation={!artist?.created_at ? 'wave' : false} variant='text' width='8em' /> : <span>-</span>}</>
							) : null}

						</h3>
					</div>
				</header>
				<AvatarInput
					title={`${avatarArtist?.first_name} ${avatarArtist?.last_name}`}
					className={classes.large}
					alt={`${avatarArtist?.first_name} ${avatarArtist?.last_name}`}
					src={avatarArtist?.imgUrl}
					editable={canEdit && !props.findMode}
					oldProfileLink={[avatarArtist?.profile_link]}
					inputName="ArtistInput"
					entityId={avatarArtist?.id}
					mutation={updateArtist}
					setEntity={artist => {
						setArtist(artist)
						props.setState({getArtist: artist })
					}}
					mutationName="updateArtist"
					entity={avatarArtist}
					entityName="artist"
				/>

				{props.findMode ?
					<FormControl style={{ width: '100%' }}>
						<Label disableAnimation shrink>
							Gallery Contacts
						</Label>
						<FindModeInput field="gallery_contacts" />
					</FormControl> :
					<QuickViewContacts
						loading={loading}
						salespersons={salespersons}
					/>
				}

				{!props.findMode && <QuickViewNotes
					notes={notes}
					entity={artist}
					privateObject={false}
					loading={loading}
				/>}


				{!props.findMode && <>
					<h3 className="paragraph-2">Available Artworks</h3>
					{loading ? (
						<div
							className="shadow-group"
							style={{
								display: 'flex',
								justifyContent: 'flex-start',
								borderRadius: 8,
								marginTop: '0.5em',
								textAlign: 'left',
							}}
						>
							<div className="thumbnail-component" style={{
								marginLeft: '1em',
								marginRight: '0.5em'
							}}
							>
								<Thumbnail
									animation="wave"
									type="art"
								/>
							</div>
						</div>
					) : <>
						{artist?.available_art?.filter((art) => !!art && art.status_id == AVAILABLE_ART_STATUS)?.map((art, i) => {


							// Private only if no data comes
							const hidden = art.is_private && !art.created_at
							const isPrivate = art.is_private

							return (
								<ButtonBase
									key={art.id}
									className="shadow-group"
									style={{
										display: 'flex',
										justifyContent: 'flex-start',
										borderRadius: 8,
										marginTop: '0.5em',
										backgroundColor: isPrivate ? '#2E3134' : null,
										cursor: 'pointer',
										textAlign: 'left',
									}}
									onClick={(event) => {
										if (hidden) return

										if (event.metaKey) {
											window.open(`/art/${art?.id}`, '_blank')
										} else {
											navigate(`/art/${art?.id}`)
										}
									}}
								>
									<div className="thumbnail-component" style={{
										marginLeft: '1em',
										marginRight: '0.5em'
									}}>
										<Thumbnail
											formatted_name={!isPrivate ? art.formatted_title : null}
											name={art?.code_name}
											artist={!isPrivate ? getArtistThumbnailDetail(
												art
											) : null}
											detail={isPrivate ? " " : null}
											date={!isPrivate ? art.year : null}
											avatar={art.imgUrl}
											type="art"
											animation={
												props.loading
													? 'wave'
													: false
											}
											largeText
											hasAccess={!hidden}
											darkText={!isPrivate}
										></Thumbnail>
									</div>
									{active = true}
								</ButtonBase>
							)
						}
						)}
						{!active ?
							<table id="artist-table" className="shadow-group">
								<tbody>
									<tr>
										<th>Nothing to display here.</th>
									</tr>
								</tbody>
							</table> : null
						}
					</>
					}
				</>
				}
			</div>
		</QuickView>
	)
}

export default withApollo(ArtistQuickView)
