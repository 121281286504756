import React, { useState, useEffect, useContext, useCallback } from 'react'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { MobileStepper, Button, FormControl, Select, MenuItem } from '@mui/material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import Label from '../input/Label'
import Dropdown from '../input/Dropdown/Dropdown'


import { DispatchContext, LookupContext } from '../store'

import { CancelButton, SubmitButton } from '../input/Buttons'

import { withApollo } from '@apollo/client/react/hoc'
import { severity } from '../Snackbar/CustomizedSnackbar'

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

import { getPdf } from './PdfExport'
import ShippingAddressInput from './ShippingAddressInput'
import { useLazyQuery } from '@apollo/client'
import { GET_DEAL_WITH_ENTRIES } from '../Deals/Queries'
import { getFullName } from '../common/helpers'
import { getFormattedAddress } from '../Contacts/Contact/ContactInformation'

const ShippingFormModal = props => {

	const lookup = useContext(LookupContext)
	const countries = lookup.data?.getCountryTypes

	const [shipTo, setShipTo] = useState("")
	const [shipToName, setShipToName] = useState("")
	const [shipFrom, setShipFrom] = useState("Lévy Gorvy Dayan, LLC\n150 East 72nd Street\nNew York, NY 10021")
	const [shipFromName, setShipFromName] = useState("")
	const [header, setHeader] = useState('Lévy Gorvy Dayan')
	const [footer, setFooter] = useState('')

	const [attempt, setAttempt] = useState(false)

	const [activeStep, setActiveStep] = useState(0)
	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	const [dealEntries, setDealEntries] = React.useState(null)


	const [getDealEntries] = useLazyQuery(GET_DEAL_WITH_ENTRIES, {
		onCompleted: result => {
			// default values
			// If the parent deal is of type
			if (['Consignment In',  'Loan In'].includes(props.extraData?.dealType)) {
				const contact = result.getDeal?.contacts?.[0]
				if (contact) {
					const address = contact?.address?.[0]
					setShipTo(getFormattedAddress(countries, address))
					setShipToName(getFullName(contact))
				}
			}
			if (['Consignment Out',  'Loan Out'].includes(props.extraData?.dealType)) {
				const contact = result.getDeal?.contacts?.[0]
				if (contact) {
					const address = contact?.address?.[0]
					setShipFrom(getFormattedAddress(countries, address))
					setShipFromName(getFullName(contact))
				}
			}

			// 	If the user picks “gallery” for address type,
			//  default to New York in the gallery address input

			// 	 Recipient/sender address -
			// Expand this view if it’s too many lines, don’t let it be scrollable
			setDealEntries(
				result.getDeal.deal_entry
					.filter(de => de.art)
					.map(de => {
						const art = de.art
						return {
							id: art?.id,
							title: art?.title,
							profile_link: art?.profile_link,
							artist: art?.artist,
							__typename: "Art"
						}
					})
			)
		},
		onError: error => {
			console.error(error)
			openSnackbar(severity.ERROR, `There was a problem loading this Deal's art.`)
		}
	})

	useEffect(() => {
		if (props.extraData?.dealId && props.open && !dealEntries) {
			getDealEntries({ variables: {
				id: props.extraData.dealId,
				limit: 20
			}})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dealEntries, getDealEntries, props.extraData?.dealId, props.open])

	const headers = [
		'Lévy Gorvy Dayan',
		"Lévy Gorvy",
		"Lévy Gorvy HK",
		"None"
	]

	const footers = []

	const next = () => setActiveStep(activeStep + 1)
	const prev = () => setActiveStep(activeStep - 1)

	useEffect(()=>{
		setActiveStep(0)
	}, [props.open])

	const addressPages = [(
		<ShippingAddressInput
			key={1}
			addressTitle="Recipient"
			setAddress={setShipTo}
			setName={setShipToName}
			shipName={shipToName}
			address={shipTo}
			attempt={attempt}
			private={props.private}
		/>
	),
	(
		<ShippingAddressInput
			key={2}
			addressTitle="Sender"
			setAddress={setShipFrom}
			setName={setShipFromName}
			shipName={shipFromName}
			address={shipFrom}
			attempt={attempt}
			private={props.private}
		/>
	)]

	const headerFooterPage = (
		<div style={{
			display: "flex",
			flexDirection: "column"
		}}>
			<h3>Select Header/Footer</h3>

			{props.includeHeader ? (
				<FormControl className="fullWidth">
					<Label disableAnimation shrink id="header-select">
						Header
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						
						input={<Dropdown/>}
						name="header-select"
						value={header}
						onChange={(e) => { setHeader(e.target.value) }}
					>
						{headers.map( (h, i) => (
							<MenuItem key={i} value={h}>{h}</MenuItem>
						))}
					</Select>
				</FormControl>
			) : null}

			{props.includeFooter ? (
				<FormControl className="fullWidth">
					<Label disableAnimation shrink id="header-select">
						Footer
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						
						input={<Dropdown/>}
						name="header-select"
						value={footer}
						onChange={(e) => { setFooter(e.target.value) }}
					>
						{footers.map( (h, i) => (
							<MenuItem key={i} value={h}>{h}</MenuItem>
						))}
					</Select>
				</FormControl>
			) : null}
		</div>
	)

	const steps = [...addressPages, ...(props.extraPages || []), headerFooterPage].map(p=>p)

	return (
		<TransitionsModal  open={props.open} close={props.close}>
			<div className={props?.private ? 'private-object': ''}
				style={{ minWidth: '800px', overflow: 'scroll' }}
			>
				<h1 className="card-title">
					<span>{props.title}</span>
					<div className="spacer"></div>
					<CancelButton
						variant="contained"
						onClick={(e) => {
							setAttempt(false)
							props.close()
						}}
						style={{
							marginLeft: "2em"
						}}
					>
						Cancel
					</CancelButton>
					<SubmitButton
						type="submit"
						variant="contained"
						onClick={(e) => {
							setAttempt(true)

							if(!shipTo || !shipToName){
								openSnackbar(severity.ERROR, "Missing recipient information.")
								setActiveStep(0)
							} else if (!shipFrom || !shipFromName){
								openSnackbar(severity.ERROR, "Missing sender information.")
								setActiveStep(1)
							} else if (!header){
								openSnackbar(severity.ERROR, "Missing header.")
								setActiveStep(2)
							} else {
								const compression = {width: 768, height: 768}
								const agreement = props?.extraData?.agreement
									?.replace(/<\/p><p>/g, '\n\n')
									?.replace(/<p>/g, '')?.replace(/<\/p>/g, '')
								getPdf(
									props.template,
									props.includeHeader ? header : null,
									props.includeFooter ? footer : null,
									props.selectedRows || dealEntries,
									openSnackbar,
									{ shipTo, shipFrom, shipToName, shipFromName, ...{
										...props?.extraData,
										agreement
									}},
									compression
								)
								setAttempt(false)
								props.close()
							}
						}
						}
					>
					Submit
					</SubmitButton>
				</h1>


				{steps.map((step, i) => <div key={`page-${i}`} hidden={i!==activeStep}>{step}</div>)}

				<MobileStepper
					activeStep={activeStep}
					variant="dots"
					position="static"
					steps={steps.length}
					style={{
						flexGrow: 1,
						backgroundColor: "rgba(1,1,1,0)",
					}}
					nextButton={
						<Button size="small" onClick={next} disabled={activeStep === steps.length - 1}>
							<KeyboardArrowRight />
						</Button>
					}
					backButton={
						<Button size="small" onClick={prev} disabled={activeStep === 0}>
							<KeyboardArrowLeft />
						</Button>
					}
				>

				</MobileStepper>
			</div>
		</TransitionsModal>
	)

}

export default withApollo(ShippingFormModal)
