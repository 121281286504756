import React, { useContext, useEffect, useCallback, useState } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import { FormControl, Menu, MenuItem, Paper, Select, IconButton, Button, ListItemIcon, ListItemText, InputAdornment, Tooltip } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'
import { Skeleton, ToggleButton } from '@mui/material'
import { DispatchContext, LookupContext } from '../../store'
import { readableBytes } from '../../common/helpers'
import { SaveCardButton, CancelButton, ChooseImageButton } from '../../input/Buttons'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import * as Colors from '../../styles/colors/Colors'
import Dropdown from '../Dropdown/Dropdown'
import ImageInput from '../Image/ImageInput'
import Label from '../Label'
import TextBox from '../Text/TextBoxThin'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { uploadImages } from '../../common/upload'
import DeleteIcon from '@mui/icons-material/Delete'
import startCase from 'lodash/startCase'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import MenuIcon from '@mui/icons-material/Menu'
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { GET_IMAGE_DOWNLOAD_LINK, GET_IMAGE_PREVIEW_LINK, DOWNLOAD_ALL_IMAGES, PREVIEW_ALL_IMAGES } from './Queries'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import ConfirmationDialog from '../../navigation/ConfirmationDialog/ConfirmationDialog'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { DEFAULT_IMAGE_USAGE_RIGHT } from '../../Notes/ObjectNotes/constants'
import ReactImageGallery from 'react-image-gallery'

const useStyles = makeStyles((theme) => ({
	image: {
		borderWidth: 'small',
		borderStyle: 'solid',
		borderColor: 'transparent',
	},
	selected: {
		borderWidth: 'small',
		borderStyle: 'solid',
		borderColor: Colors.editAdd,
	},
}))

/**
 * @typedef ImagesProps
 * @property {Object} entity
 * @property {function} onSave
 * @property {boolean} loading
 * @property {Object} client // apollo client
 */

/**
 * @param {ImagesProps} props
 */
function ImagesCard(props) {
	const classes = useStyles()

	const [entityInput, setEntityInput] = useState()
	const [profileInput, setProfileInput] = useState()
	const [selectedImage, setSelectedImage] = useState({})
	const [uploading, setUploading] = useState(false)
	const [downloadAllConfirmation, setDownloadAllConfirmation] = useState(false)

	const [multiSelect, setMultiSelect] = useState(false)
	const [selectedImages, setSelectedImages] = useState([])

	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)

	const [slideShowOpen, setOpenSlideshow] = React.useState(false)


	const [copied, setCopied] = React.useState(false)

	const handleActionClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleActionClose = () => {
		setAnchorEl(null)
	}

	const lookup = useContext(LookupContext)

	const imageTypes = lookup.data?.getImageTypes
	const usageRights = lookup.data?.getUsageRights

	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const getBackgroundColor = () => {
		if (!props.private) return editable ? 'rgb(250, 250, 250)' : 'transparent'
		else return editable ? '#292c2e' : '#3E4245'
	}

	const resetEntityInput = useCallback(
		(entity) => {
			setEntityInput(entity?.[props.imgName || 'images']?.map((i) => ({ ...i })))

			if (!props.noPrimaryUpdate) setProfileInput(entity?.profile_link)
		},
		[props.imgName, props.noPrimaryUpdate]
	)

	useEffect(() => {
		resetEntityInput(props.entity)
	}, [props.entity, resetEntityInput])

	const [contextMenu, setContextMenu] = React.useState(null)
	const [editable, setEditable] = useState(false)
	const [origin, setOrigin] = useState('card')

	const handleContextMenu = (event, origin) => {
		event.preventDefault()
		setOrigin(origin)
		setContextMenu(
			contextMenu === null
				? {
					mouseX: event.clientX - 2,
					mouseY: event.clientY - 4,
				}
				: // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
			// Other native context menus might behave different.
			// With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
				null,
		)
	}

	const handleClose = (option) => {


		if (option === 'edit')
			setEditable(true)

		else if (option === 'download_all')
			setDownloadAllConfirmation(true)


		 else if (option === 'open_all') {

			const ids = props.entity?.[props.imgName || 'images']?.filter((i) => !i.delete && !i.moved)?.map(e => e.id)

			if (ids.some(el => !el)) {
				openSnackbar(severity.ERROR, "Image(s) not uploaded yet.")
				handleActionClose()
				return
			}

			props.client.query({
				query: PREVIEW_ALL_IMAGES,
				variables: { ids }
			}).then(result => {

				if (result.data.getMultiImagePreviews) {
					result.data.getMultiImagePreviews.forEach(async (link, i) => {
						window.open(link, '_blank')
					})
				}
				else
					openSnackbar(severity.ERROR, "Error - Unauthorized")

			}).catch(error => {
				console.error(error)
				openSnackbar(severity.ERROR, "Could not preview multiple files.")
			})

		} else if (option === 'download_one') {

			if (!selectedImage.id) {
				openSnackbar(severity.ERROR, "Image not uploaded yet.")
				handleActionClose()
				return
			}

			props.client.query({
				query: GET_IMAGE_DOWNLOAD_LINK,
				variables: {
					id: selectedImage.id
				}
			}).then(result => {

				if (result.data.getImageDownloadLink)
					window.location.href = result.data.getImageDownloadLink
				else
					openSnackbar(severity.ERROR, "Error - Unauthorized")


			}).catch(error => {
				console.error(error)
				openSnackbar(severity.ERROR, "Could not download file.")
			})

		} else if (option === 'open_one') {

			if (!selectedImage.id) {
				openSnackbar(severity.ERROR, "Image not uploaded yet.")
				handleActionClose()
				return
			}

			props.client.query({
				query: GET_IMAGE_PREVIEW_LINK,
				variables: {
					id: selectedImage.id
				}
			}).then(result => {

				if (result.data.getImagePreviewLink)
					window.open(result.data.getImagePreviewLink, '_blank')
				else
					openSnackbar(severity.ERROR, "Error - Unauthorized")

			}).catch(error => {
				console.error(error)
				openSnackbar(severity.ERROR, "Could not preview file.")
			})

		}


		setContextMenu(null)
	}


	// Set selected image to last added
	useEffect(() => {
		if (entityInput?.length && !multiSelect) {
			setSelectedImage(entityInput[entityInput.length - 1])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entityInput?.length])

	// Upload image.
	let handleUploadClick = (event) => {

		setUploading(true)

		return uploadImages(props.client.query, event, (newImage) => {
			setEntityInput((oldEntity) => {
				if (oldEntity.length === 0) {
					return [
						{
							...newImage,
							type_id: props.defaultImageType,
							usage_right_id: DEFAULT_IMAGE_USAGE_RIGHT,
							ordinal: oldEntity.length + 1,
						},
					]
				} else {
					let deleteIndex = oldEntity.findIndex((e) => e.delete)

					if (deleteIndex === -1) deleteIndex = oldEntity.length

					return [
						...oldEntity.slice(0, deleteIndex),
						{
							...newImage,
							type_id: props.defaultImageType,
							usage_right_id: DEFAULT_IMAGE_USAGE_RIGHT,
							ordinal: deleteIndex + 1,
						},
						...oldEntity.slice(deleteIndex).map((item) => ({
							...item,
							ordinal: item.ordinal + 1,
						})),
					]
				}
			})
		}).catch((error) => {
			setUploading(false)
			console.error(error)
			openSnackbar(severity.ERROR, 'Error during upload.')
		}).finally(() => {
			setUploading(false)
		})
	}

	const handleChange = (e) => {
		let temp = entityInput.find((e) => e.key === selectedImage.key)
		temp[e.target.name] = e.target.value

		let tempList = entityInput.map((e) => (e.key === temp.key ? temp : e))
		setEntityInput(tempList)
	}

	const disabled = !editable || !selectedImage.key

	const imageActionsDisabled = !editable || (!selectedImage.key && !selectedImages.length)

	const noImages = !props.loading && !editable && !props.entity?.[props.imgName || 'images']?.length && !entityInput?.length

	// Set profile pic to first image
	useEffect(() => {
		if (editable && !props.noPrimaryUpdate && entityInput && entityInput.filter((e) => !e.delete && !e.moved).length) {
			setProfileInput(entityInput.find((e) => !e.delete && !e.moved).key || null)
		}
	}, [entityInput, props.noPrimaryUpdate, editable])

	const getImageSelected = (image) =>
		(editable && !multiSelect && image.key === selectedImage.key)
		|| (editable && multiSelect && !!selectedImages.find(el => el.key === image.key))


	// This useEffect enables multiSelect single image editing
	useEffect(() => {
		if (multiSelect && selectedImages.length === 1) {
			setSelectedImage(selectedImages[0])
		}
		else if (multiSelect && selectedImages.length !== 1) {
			setSelectedImage({})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [multiSelect, selectedImages.length])


	const galleryImages = props.entity[props.imgName || 'images']?.map(image => ({
		original: image.src || image.imgUrl,
		thumbnail: image.src || image.imgUrl,
		originalAlt: image.alt_text,
	}))

	return <>
		<Paper className="qv-margin" id="listing-images"
			onContextMenu={(e) => (handleContextMenu(e, 'card'))}
			data-testid="card-images"
		>
			<header
				style={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<h1 className="card-title fullWidth">
					<span>{`${props.titleOverride || 'Images'} ${entityInput && entityInput?.filter((e) => !e.delete && !e.moved)?.length ? `(${entityInput?.filter((e) => !e.delete && !e.moved)?.length})` : ''}`}</span>

					{editable && (
						<>
							<div
								style={{
									marginTop: -3,
								}}
							>

								<Tooltip arrow placement="top" title="When enabled, the multi select button allows you to select one-to-many images for download, opening, moving, or removal.">
									<ToggleButton
										value="check"
										size="small"
										selected={multiSelect}
										onChange={() => {

											if (multiSelect) {
												setMultiSelect(false)
												setSelectedImage(entityInput[entityInput.length - 1])
												return
											}

											setMultiSelect(true)
											setSelectedImage({})

										}}
										style={{
											border: 'none'
										}}
									>
										<DoneAllIcon />
									</ToggleButton>
								</Tooltip>

								<IconButton
									disabled={disabled || multiSelect || entityInput?.findIndex((e) => e.key === selectedImage.key) === 0}
									size="small"
									onClick={() => {
										const selectedIndex = entityInput.findIndex((e) => e.key === selectedImage.key)

										if (selectedImage != null && selectedIndex !== 0) {
											let tempArray = [...entityInput].map((o) => ({ ...o }))

											tempArray[selectedIndex] = {
												...entityInput[selectedIndex - 1],
												ordinal: selectedIndex + 1,
											}

											tempArray[selectedIndex - 1] = {
												...entityInput[selectedIndex],
												ordinal: selectedIndex,
											}

											setEntityInput(tempArray)
										}
									}}
								>
									<ArrowBackIcon />
								</IconButton>
								<IconButton
									disabled={disabled || multiSelect || entityInput?.findIndex((e) => e.key === selectedImage.key) === entityInput.filter((e) => !e.delete && !e.moved).length - 1}
									size="small"
									onClick={() => {
										const selectedIndex = entityInput.findIndex((e) => e.key === selectedImage.key)

										if (selectedIndex != null && selectedIndex !== entityInput.filter((e) => !e.delete && !e.moved).length - 1) {
											let tempArray = [...entityInput].map((o) => ({ ...o }))

											tempArray[selectedIndex] = {
												...entityInput[selectedIndex + 1],
												ordinal: selectedIndex + 1,
											}

											tempArray[selectedIndex + 1] = {
												...entityInput[selectedIndex],
												ordinal: selectedIndex + 2,
											}

											setEntityInput(tempArray)
										}
									}}
								>
									<ArrowForwardIcon />
								</IconButton>
							</div>

							<div className="spacer"></div>

							<CancelButton
								variant="contained"
								size="small"
								onClick={() => {
									resetEntityInput(props.entity)
									setSelectedImage({})
									setSelectedImages([])
									setMultiSelect(false)
									handleClose()
									setEditable(false)
								}}
							>
							Cancel
							</CancelButton>
							<SaveCardButton
								variant="contained"
								color="secondary"
								size="small"
								disabled={uploading}
								onClick={() => {
									const images = entityInput.map(({ src, imgUrl, ...rest }) => rest)

									// Compute image ordinals at save time, based on array order
									var j = 1
									for(var i = 0; i < images.length; i++){
										if (!(images[i].delete || images[i].moved)){
											images[i].ordinal = j++
										} else images[i].ordinal = images.length
									}

									setSelectedImage({})
									if (props.noPrimaryUpdate) {
										props.onSave({
											[props.imgName]: images,
										})
									} else {
										props.onSave({
											images,
											profile_link: profileInput,
										})
									}

									setSelectedImage({})
									setSelectedImages([])
									setMultiSelect(false)
									handleClose()
									setEditable(false)
								}}
							>
							Save
							</SaveCardButton>
						</>
					)}
				</h1>
			</header>
			<div
				style={{
					display: 'flex',
					backgroundColor: getBackgroundColor(),
					marginLeft: editable ? '-2em' : null,
					marginRight: editable ? '-2em' : null,
					marginBottom: '0.5em',
					padding: editable ? '0.5em 2em' : '0',
				}}
			>
				<div
					style={{
						display: 'flex',
						overflowX: 'auto',
						marginRight: !noImages ? '1em' : 0,
						alignItems: 'center',
					}}
				>
					{props.loading ? (
						<Skeleton animation="wave" variant="rectangular" width="310px" height="210px" style={{ marginLeft: '2em' }} />
					) : (
						(editable ? entityInput?.filter((i) => !i.delete && !i.moved) : props.entity[props.imgName || 'images'])
							?.sort((a, b) => a.ordinal - b.ordinal)
							?.map((image) => {

								const imageStyles = {
									maxHeight: '15em',
									marginRight: '.7em',
									cursor: 'pointer',
								}

								return (
									<div key={image.id || image.key}>
										{!editable ?

											<img
												style={imageStyles}
												className={classes.image}
												src={image.src || image.imgUrl}
												alt={image.alt_text || image.filename}
												onClick={() => setOpenSlideshow(image)}
												onContextMenu={(e) => {
													e.stopPropagation()
													setSelectedImage(image)
													return handleContextMenu(e, 'image')
												}}
											/>

											:
											<img
												style={imageStyles}
												className={getImageSelected(image) ? classes.selected : classes.image}
												src={image.src || image.imgUrl}
												alt={image.alt_text || image.filename}
												onClick={() => {
													if (editable && !multiSelect) {
														setSelectedImage(entityInput.find((e) => e.key === image.key) ?? {})
													}

													if (editable && multiSelect) {

														const exists = selectedImages.find(el => el.key === image.key)

														// If already in selected images, remove
														if (exists) setSelectedImages(selectedImages.filter(el =>
															el.key !== image.key
														))

														// If not already in selected images, add
														if (!exists) setSelectedImages(selectedImages.concat(image))
													}
												}}
											/>
										}
									</div>
								)})
					)}
				</div>

				{/* No Images warning */}
				{noImages ? <span style={{ paddingLeft: '0' }}>No Images</span> : null}

				{editable ?
					<ImageInput
						onChange={handleUploadClick}
						loading={uploading}
						setLoading={setUploading}
					/> :
					null
				}
			</div>
			{editable && (
				<>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div
							style={{
								display: 'flex',
								flexGrow: 2,
								marginRight: '1em',
							}}
						>
							<div
								style={{
									flex: '196px 0 1',
									marginRight: '1em',
								}}
							>
								<Label disableAnimation shrink htmlFor="image-id" className="no-label-margin">
								ID
								</Label>
								{selectedImage.id ? selectedImage.id : '-'}
							</div>

							<div
								style={{
									flex: '196px 0 1',
									marginRight: '1em',
								}}
							>
								<Label id="Resolution-label" disableAnimation shrink htmlFor="image-resolution" className="no-label-margin">
								Resolution
								</Label>
								{selectedImage.dimension || '-'}
							</div>

							<div
								style={{
									flex: '196px 0 1',
									marginRight: '1em',
								}}
							>
								<Label id="listing-start-time-label" disableAnimation shrink htmlFor="image-file-size" className="no-label-margin">
								File size
								</Label>
								{selectedImage.filesize ? readableBytes(Number(selectedImage.filesize)) : '-'}
							</div>

							<div
								style={{
									flex: '196px 1 0',
								}}
							>
								<Label disableAnimation shrink htmlFor="image-file-name" className="no-label-margin">
								File name
								</Label>
								{selectedImage.filename}
							</div>
						</div>

						<div style={{ marginLeft: 'auto', marginTop: '10px'}}>
							<Button
								variant="contained"
								className={classes.button}
								startIcon={<MenuIcon style={{color: 'black'}}/>}
								style={{
									backgroundColor: 'hsl(0deg 0% 88%)',
									visibility: imageActionsDisabled ? 'hidden' : 'visible',
									color: 'black'
								}}
								onClick={handleActionClick}
								disabled={uploading}
							>
							Image Actions
							</Button>
							<Menu
								id="long-menu"
								className="image-card-actions"
								anchorEl={anchorEl}
								keepMounted
								open={open}
								onClose={handleActionClose}
								anchorOrigin={{
									vertical: 'center',
									horizontal: 'center',
								}}
								transformOrigin={{
									vertical: 'center',
									horizontal: 'center',
								}}
							>
								<MenuItem
									onClick={() => {
										if (!multiSelect) {
											if (!selectedImage.id) {
												openSnackbar(severity.ERROR, "Image not uploaded yet.")
												handleActionClose()
												return
											}

											props.client.query({
												query: GET_IMAGE_DOWNLOAD_LINK,
												variables: {
													id: selectedImage.id
												}
											}).then(result => {

												if (result.data.getImageDownloadLink)
													window.location.href = result.data.getImageDownloadLink
												else
													openSnackbar(severity.ERROR, "Error - Unauthorized")


											}).catch(error => {
												console.error(error)
												openSnackbar(severity.ERROR, "Could not download file.")
											})
										}

										if (multiSelect) {

											const ids = selectedImages?.filter((i) => !i.delete && !i.moved)?.map(e => e.id)

											if (ids.some(el => !el)) {
												openSnackbar(severity.ERROR, "Image(s) not uploaded yet.")
												handleActionClose()
												return
											}

											props.client.query({
												query: DOWNLOAD_ALL_IMAGES,
												variables: { ids }
											}).then(result => {

												if (result.data.getMultiImageDownloads) {
													result.data.getMultiImageDownloads.forEach(async (link, i) => {
														window.open(link, '_blank')
													})
												}
												else
													openSnackbar(severity.ERROR, "Error - Unauthorized")


											}).catch(error => {
												console.error(error)
												openSnackbar(severity.ERROR, "Could not download multiple files.")
											})
										}

										handleActionClose()
									}}
								>
									<ListItemIcon>
										<img
											style={{
												width: '1.1em',
												margin: 1,
											}}
											alt="Download Icon"
											src={props.private ? '/images/icons/White/Download.svg'
												: '/images/icons/Grey/Download.svg'}
										/>
									</ListItemIcon>
									<ListItemText primary="Download" />
								</MenuItem>
								<MenuItem
									onClick={() => {

										if (!multiSelect) {
											if (!selectedImage.id) {
												openSnackbar(severity.ERROR, "Image not uploaded yet.")
												handleActionClose()
												return
											}

											props.client.query({
												query: GET_IMAGE_PREVIEW_LINK,
												variables: {
													id: selectedImage.id
												}
											}).then(result => {

												if (result.data.getImagePreviewLink)
													window.open(result.data.getImagePreviewLink, '_blank')
												else
													openSnackbar(severity.ERROR, "Error - Unauthorized")

											}).catch(error => {
												console.error(error)
												openSnackbar(severity.ERROR, "Could not preview file.")
											})

										}

										if (multiSelect) {
											const ids = selectedImages?.filter((i) => !i.delete && !i.moved)?.map(e => e.id)

											if (ids.some(el => !el)) {
												openSnackbar(severity.ERROR, "Image(s) not uploaded yet.")
												handleActionClose()
												return
											}

											props.client.query({
												query: PREVIEW_ALL_IMAGES,
												variables: { ids }
											}).then(result => {

												if (result.data.getMultiImagePreviews) {
													result.data.getMultiImagePreviews.forEach(async (link, i) => {
														window.open(link, '_blank')
													})
												}
												else
													openSnackbar(severity.ERROR, "Error - Unauthorized")

											}).catch(error => {
												console.error(error)
												openSnackbar(severity.ERROR, "Could not preview multiple files.")
											})
										}

										handleActionClose()
									}}
								>
									<ListItemIcon>
										<OpenInNewIcon fontSize="small"/>
									</ListItemIcon>
									<ListItemText primary="Open" />
								</MenuItem>

								{(props.page === "Art" || props.page === "Operations") ? <MenuItem
									onClick={() => {

										if (!multiSelect) {
											let movedIndex = Number.MAX_SAFE_INTEGER

											// Set the selected image to moved
											// Decrement ordinal, of all others
											let tempList = entityInput.map((image, index) => {

												if (image.key === selectedImage.key) {
													movedIndex = index

													if (image.id) {
														return {
															...image,
															moved: props.page === "Art" ? 'art_operation_id' : 'art_id',
															ordinal: entityInput.length,
														}
													}

													else {
														return null
													}

												} else {
													return {
														...image,
														ordinal: index < movedIndex ? image.ordinal : image.ordinal - 1,
													}
												}
											}).filter(e => e)

											if (tempList.filter(e => !e.delete && !e.moved).length === 0) setProfileInput(null)

											setEntityInput(tempList)
											setSelectedImage({})
										}

										if (multiSelect) {

											let ordinalCount = 0
											let movedCount = 0

											let tempList = entityInput.map((image) => {

												if (selectedImages.find(el => el.key === image.key)) {

													if (image.id) {
														return {
															...image,
															moved: props.page === "Art" ? 'art_operation_id' : 'art_id',
															ordinal: entityInput.length - (movedCount++)
														}
													}

													else {
														return null
													}
												}

												else {
													ordinalCount += 1

													return {
														...image,
														ordinal: ordinalCount
													}

												}

											}).filter(e => e)?.sort((a, b) => a.ordinal - b.ordinal)

											if (tempList.filter(e => !e.delete && !e.moved).length === 0) setProfileInput(null)

											setEntityInput(tempList)
											setSelectedImages([])

										}

										handleActionClose()
									}}
								>
									<ListItemIcon>
										<ExitToAppIcon fontSize="small"/>
									</ListItemIcon>
									<ListItemText primary={`Move to ${props.page === 'Art' ? 'Operations' : 'Details'}`} />
								</MenuItem> : null}
								<MenuItem
									onClick={() => {

										if (!multiSelect) {
											let deletedIndex = Number.MAX_SAFE_INTEGER

											// Set the selected image to deleted
											// Decrement ordinal, of all others
											let tempList = entityInput.map((image, index) => {

												if (image.key === selectedImage.key) {
													deletedIndex = index

													if (image.id) {
														return {
															...image,
															delete: true,
															ordinal: entityInput.length,
														}
													}

													else {
														return null
													}

												} else {
													return {
														...image,
														ordinal: index < deletedIndex ? image.ordinal : image.ordinal - 1,
													}
												}
											}).filter(e => e)

											if (tempList.filter(e => !e.delete && !e.moved).length === 0) setProfileInput(null)

											setEntityInput(tempList)
											setSelectedImage({})
										}

										if (multiSelect) {

											let ordinalCount = 0
											let deletedCount = 0

											let tempList = entityInput.map((image) => {

												if (selectedImages.find(el => el.key === image.key)) {

													if (image.id) {
														return {
															...image,
															delete: true,
															ordinal: entityInput.length - (deletedCount++)
														}
													}

													else {
														return null
													}
												}

												else {
													ordinalCount += 1

													return {
														...image,
														ordinal: ordinalCount
													}

												}

											}).filter(e => e)?.sort((a, b) => a.ordinal - b.ordinal)

											if (tempList.filter(e => !e.delete && !e.moved).length === 0) setProfileInput(null)

											setEntityInput(tempList)
											setSelectedImages([])

										}

										handleActionClose()
									}}
								>
									<ListItemIcon>
										<DeleteIcon fontSize="small"/>
									</ListItemIcon>
									<ListItemText primary="Delete" />
								</MenuItem>
							</Menu>
							<ChooseImageButton
								style={{
									marginLeft: '1em',
									padding: '5px 15px'
								}}
								startIcon={<MoveToInboxIcon />}
								onClick={() => {
									setDownloadAllConfirmation(true)
								}}
							>
							Download All
							</ChooseImageButton>
						</div>
					</div>

					<div className="column-body" style={{ paddingBottom: '1em', paddingTop: '1em' }}>
						<div className="column">
							<FormControl className="padded-select">
								<Label id="type-label" disableAnimation shrink>
								Image Type*
								</Label>

								<Select
									IconComponent={ExpandMoreRoundedIcon}
									name="type_id"
									labelId="type-label"
									input={<Dropdown />}
									disabled={disabled}
									value={imageTypes ? selectedImage.type_id || '' : ''}
									className={!editable ? 'readonly' : null}
									readOnly={!editable}
									onChange={handleChange}
								>
									{imageTypes &&
									imageTypes.map((type) => (
										<MenuItem key={type.id} value={type.id}>
											{type.value}
										</MenuItem>
									))}
								</Select>
							</FormControl>

							<FormControl className="padded-select">
								<Label id="usage-rights-label" disableAnimation shrink>
								Usage Rights*
								</Label>
								<Select
									IconComponent={ExpandMoreRoundedIcon}
									name="usage_right_id"
									labelId="usage-rights-label"
									disabled={disabled}
									input={<Dropdown />}
									value={usageRights ? selectedImage?.usage_right_id || '' : ''}
									className={!editable ? 'readonly' : null}
									readOnly={!editable}
									onChange={handleChange}
								>
									{usageRights &&
									usageRights.map((right) => (
										<MenuItem key={right.id} value={right.id}>
											{startCase(right.value)}
										</MenuItem>
									))}
								</Select>
							</FormControl>

							<FormControl>
								<Label disableAnimation shrink htmlFor="img-alt-text">
								Alt Text
								</Label>
								<TextBox endAdornment={
									<Tooltip id="padded-tooltip" title={!copied ? "Copy to Clipboard" : "Copied!"} placement="top" arrow>
										<InputAdornment position="end">
											<IconButton
												aria-label="copy alt text"
												style={{ padding: 7 }}
												onClick={() => {

													try {
														navigator.clipboard.writeText(selectedImage.alt_text)

														setCopied(true)

														setTimeout(() => {
															setCopied(false)
														}, 5000)

													} catch (error) {
														console.error(error)
													}
												}}
												size="large">
												<FileCopyIcon />
											</IconButton>
										</InputAdornment>
									</Tooltip>
								} id="img-alt-text" name="alt_text" value={selectedImage.alt_text || ''} disabled={disabled} onChange={handleChange} />
							</FormControl>

							<FormControl>
								<Label disableAnimation shrink htmlFor="image-photographer">
								Photographer
								</Label>
								<TextBox placeholder={props.entity?.photographer} id="image-photographer" name="photographer" value={selectedImage.photographer || ''} disabled={disabled} onChange={handleChange} />
							</FormControl>
						</div>
						<div className="column">
							<FormControl style={{
								height: '-webkit-fill-available'
							}}>
								<Label disableAnimation shrink htmlFor="image-caption">
								Caption
								</Label>
								<TextBox
									inputProps={{ maxLength: 512 }}
									id="image-caption"
									name="caption" disabled={disabled}
									value={selectedImage.caption || ''}
									onChange={handleChange}
									multiline
									rows="4"
								/>
							</FormControl>

							<FormControl style={{ marginTop: 3, height: '-webkit-fill-available' }}>
								<Label disableAnimation shrink htmlFor="image-credit">
								Copyright
								</Label>
								<TextBox placeholder={props.entity?.artist?.copyright} id="image-credit" name="credit" disabled={disabled} value={selectedImage.credit || ''} onChange={handleChange} multiline rows="4" />
							</FormControl>
						</div>
					</div>
				</>
			)}

			<Menu
				keepMounted
				open={contextMenu !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					contextMenu !== null
					  ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
					  : undefined
				  }
			>

				{origin === 'card' || origin === 'image' ?
					<MenuItem
						onClick={() => {
							handleClose('edit')
						}}
					>
					Edit
					</MenuItem>
					: null}

				{origin === 'card' ?
					<MenuItem
						onClick={() => {
							handleClose('download_all')
						}}
					>
					   Download All
					</MenuItem>
					: null}

				{origin === 'card' ?
					<MenuItem
						onClick={() => {
							handleClose('open_all')
						}}
					>
					  Open All
					</MenuItem>
					: null}

				{origin === 'image' ?
					<MenuItem
						onClick={() => {
							handleClose('download_one')
						}}
					>
					Download Image
					</MenuItem>
					: null}

				{origin === 'image' ?
					<MenuItem
						onClick={() => {
							handleClose('open_one')
						}}
					>
					Open Image
					</MenuItem>
					: null}

			</Menu>
		</Paper>

		<ConfirmationDialog
			open={downloadAllConfirmation}
			handleClose={() => setDownloadAllConfirmation(false)}
			title='Download All'
			acceptText="Download"
			text='This may take some time.'
			buttonColor='#4465D1'
			onYes={() => {
				if (entityInput.length === 0) {
					openSnackbar(severity.WARNING, "No images to download.")
				}

				else if (entityInput.length > 1) {
					openSnackbar(severity.INFO, "Check your popup blocker.")
				}

				const ids = entityInput?.filter((i) => !i.delete && !i.moved)?.map(e => e.id)

				if (ids.some(el => !el)) {
					openSnackbar(severity.ERROR, "Image(s) not uploaded yet.")
					return
				}

				props.client.query({
					query: DOWNLOAD_ALL_IMAGES,
					variables: { ids }
				}).then(result => {

					if (result.data.getMultiImageDownloads) {
						result.data.getMultiImageDownloads.forEach(async (link, i) => {
							window.open(link, '_blank')
						})
					}
					else
						openSnackbar(severity.ERROR, "Error - Unauthorized")


				}).catch(error => {
					console.error(error)
					openSnackbar(severity.ERROR, "Could not download all files.")
				})
			}}
		/>

		{galleryImages?.length && slideShowOpen ?
			// @ts-ignore
			<ReactImageGallery
				infinite={false}
				items={galleryImages}
				showPlayButton={false}
				onScreenChange={(bool) => {
					if (!bool) setOpenSlideshow(false)
					return bool
				}}
				ref={(ref) => {
					if (ref) {
						if (slideShowOpen) {
							ref.slideToIndex(props.entity[props.imgName || 'images'].findIndex(e => e.id === slideShowOpen.id))
							ref.fullScreen()
						}
					}
				}}
			/>
			: null}

	</>
}

export default withApollo(ImagesCard)
