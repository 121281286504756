import React from 'react'
import DoneIcon from '@mui/icons-material/Done'
import { Chip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface ShowcaseSelectChipProps {
	showcaseSelectMode: any
	setShowcaseSelectMode: (newShowcaseSelectMode: any) => void
}

const ShowcaseSelectChips = (props: ShowcaseSelectChipProps) => {

	
	const chipStyle = {
		marginRight: '1em',
		marginBottom: '0.5em'
	}
	
	const { showcaseSelectMode, setShowcaseSelectMode } = props

	const handleClick = () => {

		if (showcaseSelectMode.selectMode) {
			setShowcaseSelectMode?.({
				selectMode: false,
				artIds: []
			})
		}

		else {
			setShowcaseSelectMode?.({
				selectMode: true,
				artIds: []
			})
		}
	}

	return (
		<>
			{showcaseSelectMode ? (
				<Chip
					label={showcaseSelectMode.selectMode ? 'Cancel' : 'Select'}
					color={showcaseSelectMode.selectMode ? 'error' : 'info'}
					variant={showcaseSelectMode.selectMode ? 'filled' : 'outlined'}
					onClick={() => handleClick()}
					onDelete={() => handleClick()}
					sx={chipStyle}
					deleteIcon={showcaseSelectMode.selectMode ? <CloseIcon /> : <DoneIcon />}
				/>
			) : null}
		</>
	)
}

export default ShowcaseSelectChips
