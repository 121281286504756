import React from 'react'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { SubmitButton, CancelButton } from '../input/Buttons'

const DuplicateInventoryNumberModal = (props) => {
	const incNum = String(Number(props.number) + 1)
	return (
		<TransitionsModal
			className="phone-modal"
			open={props.isOpen}
			close={props.close}
		>
			<form
				className={"modal-small-content"}
				onSubmit={e => {
					e.preventDefault()
					e.stopPropagation()
				}}
			>
				<h1 className="card-title">
					<span>Duplicate Inventory Number</span>
				</h1>
				<div>
					The inventory number {props.prefix}-{props.number} already exists.
					Try again with {props.prefix}-{incNum}?
				</div>
				<CancelButton
					variant="contained"
					style={{ float: 'left', marginTop: '1em' }}
					onClick={e => {
						e.preventDefault()
						props.close()
					}}
				>
				Cancel
				</CancelButton>

				<SubmitButton
					style={{ float: 'right', marginTop: '1em' }}
					type="submit"
					variant="contained"
					onClick={e => {
						props.close()
						props.onSave(incNum)
					}}
				>
					Submit
				</SubmitButton>
			</form>
		</TransitionsModal>
	)
}

export default DuplicateInventoryNumberModal
