import { FormControl, MenuItem, Select } from '@mui/material'
import Dropdown from '../../input/Dropdown/Dropdown'
import Label from '../../input/Label'
import React, { useContext } from "react"
import TextBox from '../../input/Text/TextBoxThin'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import {SubmitButton, CancelButton} from '../../input/Buttons'
import { isPrimary, isValidEmail } from '../../common/helpers'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { useMutation } from '@apollo/client'
import { CREATE_EMAIL } from '../Contact/Queries'

export default function NewEmailModal(props) {

	const [attempt, setAttempt] = React.useState(false)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}

	const [createEmail] = useMutation(CREATE_EMAIL, {
		onError: (error) => {
			openSnackbar(severity.ERROR, error.createEmail.message)
		},
		onCompleted: (data) => {
			if (data?.createEmail?.success) {
				openSnackbar(severity.SUCCESS, data.createEmail.message)
				props.setContact({
					...props.contact,
					emailCount: props.contact.emailCount + 1
				})
			} else {
				openSnackbar(severity.ERROR, "Could not create email.")
			}
		}
	})

	const handleKeyPress = (e) => {
		if(e.key === 'Enter'){
			handleSubmit(e)
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		e.stopPropagation()
		setAttempt(true)

		if (attempt && (!isValidEmail(props.newEmailModal?.email?.email) || props.newEmailModal?.email?.email?.length === 0)) {
			openSnackbar(severity.WARNING, "Please fill out the fields in red.")
		} else if (!(!isValidEmail(props.newEmailModal?.email?.email) || props.newEmailModal?.email?.email?.length === 0)) {
			let newEmail = {
				label: props.newEmailModal.email.label,
				type_id: parseInt(
					props.newEmailModal.email.type_id
				),
				email: props.newEmailModal.email.email,
				type:
					props.emailTypes[
						parseInt(
							props.newEmailModal.email.type_id
						) - 1
					].value,
				contact_id: props.contactInput.id,
				is_primary: isPrimary(props, "email")
			}

			if (!props.hasContactInfoPermission && props.onContactDetailsPage) {

				delete newEmail.type

				createEmail({
					variables: {
						EmailInput: newEmail
					}
				})

			} else {
				props.addToContactInput('email', newEmail)
			}

			props.setNewEmailModal({
				...props.newEmailModal,
				open: false
			})

			setAttempt(false)
		}
	}

	return (
		<TransitionsModal
			className="new-email-modal"
			open={props.newEmailModal?.open}
			close={() =>
				props.setNewEmailModal({ ...props.newEmailModal, open: false })
			}
		>
			<form
				className={props.isPrivate ? "private-object modal-small-content" : "modal-small-content"}
				onSubmit={e => handleSubmit(e) }
			>
				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Label
					</Label>
					<TextBox
						autoFocus
						placeholder="-"
						value={props.newEmailModal?.email?.label || ''}
						onChange={e => {
							props.setNewEmailModal({
								...props.newEmailModal,
								email: {
									...props.newEmailModal.email,
									label: e.target.value
								}
							})
						}}
						data-testid="email-title"
					/>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Type*
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						
						input={<Dropdown />}
						className="medium-selection padded-select"
						value={props.newEmailModal?.email?.type_id}
						onChange={e => {
							props.setNewEmailModal({
								...props.newEmailModal,
								email: {
									...props.newEmailModal.email,
									type_id: e.target.value
								}
							})
						}}
						data-testid="email-type"
					>
						{props.emailTypes && props.emailTypes.map(x => (
							<MenuItem key={x.id} value={x.id} data-testid="email-type-item">
								{x.value.charAt(0).toUpperCase() + x.value.substring(1)}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink error={attempt && !isValidEmail(props.newEmailModal?.email?.email)}>
						New Email*
					</Label>
					<TextBox
						onKeyPress={(e) => handleKeyPress(e)}
						type='email' 
						name='email'
						error={attempt && !isValidEmail(props.newEmailModal?.email?.email)}
						value={props.newEmailModal?.email?.email || ''}
						multiline
						onChange={e => {
							props.setNewEmailModal({
								...props.newEmailModal,
								email: {
									...props.newEmailModal.email,
									email: e.target.value
								}
							})
						}}
						data-testid="email"
						inputProps={{ maxLength: 255 }}
					/>
				</FormControl>

				{props.contactInput ? 
					<>
						<CancelButton
							variant="contained"
							style={{ float: 'left', marginTop: '1em' }}
							onClick={e => {
								e.preventDefault()
								props.setNewEmailModal({
									...props.newEmailModal,
									open: false
								})
								setAttempt(false)
							}}
							data-testid="email-cancel"
						>
						Cancel
						</CancelButton>

						<SubmitButton
							style={{ float: 'right', marginTop: '1em' }}
							type="submit"
							variant="contained"
							data-testid="email-submit"
						>
							Submit
						</SubmitButton>
					</>
					:
					null
				}
			</form>
		</TransitionsModal>
	)
}
