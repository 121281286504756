import React from 'react'
import { Paper, FormControl } from '@mui/material'
import Label from '../input/Label'
import { FindModeInput } from '../navigation/Tabs/TabbedPage'

const ArtCollectionSearch = (props) => {

	return (
		<Paper className="qv-margin" id="location-search">
			<h1 className="card-title">
				<span>Known Works</span>
			</h1>

			<div className="column-body">
				<FormControl style={{
					flexGrow: 1,
					marginRight: '1em'
				}}>
					<Label disableAnimation shrink htmlFor="source_title">
						Source Title
					</Label>
					<FindModeInput field="source_title" />
				</FormControl>
				<FormControl style={{
					flexGrow: 1,
					marginRight: '1em'
				}}>
					<Label disableAnimation shrink htmlFor="source_artist">
						Source Artist
					</Label>
					<FindModeInput field="source_artist" />
				</FormControl>
			</div>
		</Paper>
	)
}

export default ArtCollectionSearch
