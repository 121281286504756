/* eslint-disable eqeqeq */
import { autoCompleteStyles, LightTooltip, chipStyles, tagChipShadow } from '../styles/makesStyles'
import { FormControl, Chip } from '@mui/material'
import { SaveCardButton, CancelButton, ChooseImageButton } from '../input/Buttons'
import { Skeleton } from '@mui/material'
import { withApollo } from '@apollo/client/react/hoc'
import React, { useEffect } from 'react'
import TextField from '@mui/material/TextField'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import CircularProgress from '@mui/material/CircularProgress'
import { stripMeta } from '../common/helpers'
import { SIMPLE_SEARCH_TAGS } from './Queries'
import { TagModal } from './TagModal'
import LimitedAutocomplete from '../common/LimitedAutocomplete'
import { computeTextColorFromBGColor } from '../common/helpers'

function GlobalSearchTags(props) {

	const handleClose = () => props.setTagModal?.(false)

	// Style state
	const classes = autoCompleteStyles()

	// Autocomplete State
	const [open, setOpen] = React.useState(false)
	const [options, setOptions] = React.useState([])
	const [selection, setSelection] = React.useState([])
	const [loadTags, setLoadTags] = React.useState(false)
	const loading = !!open && !!loadTags

	// Tag Modal states
	const [openTagModal, setOpenTagModal] = React.useState(false)
	
	// Handle the creation of a new tag
	const handleNewTag = (tag) => {
		setOptions(options.concat(tag))
		setSelection(selection.concat(tag))
	}

	useEffect(() => {
		let active = true

		if (loading) {
			props.client
				.query({
					query: SIMPLE_SEARCH_TAGS,
					variables: { query: loadTags },
				})
				.then((result) => {
					if (active) {
						setLoadTags(false)
						setOptions(result.data.simpleSearchTags)
					}
				})
		}

		return () => active = false
	}, [loadTags, loading, props.client])

	// Tooltips
	const [ttOpen, setTTOpen] = React.useState({})
	const handleTTClose = (row) => {
		setTTOpen({
			[row.id]: false,
		}) 
	}
	const handleTTOpen = (row) => {
		setTTOpen({
			[row.id]: true,
		}) 
	}

	return (
		<div className="qv-margin" id={`${props.entityName}-tags`} style={{ margin: '1em', padding: '0px'}}>
			<h1 className="card-title">
				<span>Tags</span>
			</h1>
			<div>
				{props.loading ? (
					<Skeleton
						variant="rectangular"
						width={120}
						height={25}
						animation="wave"
						style={{
							paddingLeft: '0.5em',
							borderRadius: '10em',
							marginTop: '0.5em',
						}}
					/>
				) : (
					<>
						<FormControl fullWidth={true} style={{ paddingTop: '0.2em' }}>
							<LimitedAutocomplete
								query={loadTags}
								setQuery={setLoadTags}
								minimum={1}

								multiple
								size="small"
								forcePopupIcon

								classes={classes}

								filterSelectedOptions
								popupIcon={<ExpandMoreRoundedIcon />}
								open={open && !!options?.length}
								onOpen={() => setOpen(true)}
								onClose={() => setOpen(false)}
								getOptionLabel={(option) => option.label}
								options={options}
								loading={loading}
								disabled={false}
								value={selection}
								isOptionEqualToValue={(option, value) =>
									option.id == value?.id
								}
								onChange={(event, value) => {
									setSelection(value)
								}}
								renderOption={(params, option) =>
									<LightTooltip
										title={option.description || ''}
										key={option.id}
										label={option.label} 
										arrow
										open={ttOpen[option.id] || false}
										disableFocusListener
										disableTouchListener
										disableHoverListener
										onClose={() => handleTTClose(option)}
										onOpen={() => handleTTOpen(option)}
										placement="bottom"
										enterDelay={1000}
									>
										<Chip
											{...params}
											onMouseEnter={() => handleTTOpen(option)}
											onMouseLeave={() => handleTTClose(option)}
											style={{
												...tagChipShadow,
												width: "min-content",
												backgroundColor: '#' + option.color_hex,
												color: computeTextColorFromBGColor('#' + option.color_hex) 
											}}
											size="small"
											key={option.id}
											label={option.label} />
									</LightTooltip>
								}
								renderTags={(value, getTagProps) =>
									value.map((option, index) => (
										<LightTooltip
											title={option.description || ''}
											key={option.id}
											label={option.label}
											open={ttOpen[option.id] || false}>
											<Chip
												{...getTagProps({index})}
												onMouseEnter={() => handleTTOpen(option)}
												onMouseLeave={() => handleTTClose(option)}						
												style={{ 
													...chipStyles,
													...tagChipShadow,
													backgroundColor: '#' + option.color_hex,
													color: computeTextColorFromBGColor('#' + option.color_hex) 
												}}
												size="small"
												key={option.id}
												label={option.label}/>
										</LightTooltip>
									))
								}
								renderInput={(params) => (
									<TextField
										{...params}
										id="owner-autocomplete"
										variant="outlined"
										fullWidth
										autoFocus
										focused={false}
										style={{ paddingTop: '0.5' }}
										classes={{ notchedOutline: null }}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{loading ? (
														<CircularProgress
															color="inherit"
															size={20}
														/>
													) : null}
													{
														params.InputProps
															.endAdornment
													}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</FormControl>
					</>
				)}

				<div style={{
					display: 'flex',
					justifyContent: 'space-between',
					marginTop: '1em',
				}}>
					<CancelButton
						variant="contained"
						size="medium"
						onClick={handleClose}
					>
						Cancel
					</CancelButton>
					<ChooseImageButton
						variant="contained"
						size="medium"
						style={{
							marginLeft: 'auto',
							marginRight: '1em'
						}}
						onClick={() => {
							setOpenTagModal(true)
						}}
					>
						New
					</ChooseImageButton>
					<SaveCardButton
						variant="contained"
						color="secondary"
						size="medium"
						disabled={props.loading}
						onClick={() => {
							let tempEntityInput = {
								tags: stripMeta(selection)
							}
							props.onSave(tempEntityInput)
							handleClose()
						}}
					>
						Save
					</SaveCardButton>
				</div>
			</div>

			<TagModal
				editMode={false}
				loading={loading}
				open={openTagModal}
				setOpen={setOpenTagModal}
				handleResult={handleNewTag}
			/>
			
		</div>
	)
}

export default withApollo(GlobalSearchTags)
