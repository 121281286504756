import { useApolloClient, useMutation, useQuery } from "@apollo/client"
import { ExpandMoreRounded } from "@mui/icons-material"
import { Box, Button, FormControl, Menu, MenuItem, Paper, Select } from "@mui/material"
import { MobileDatePicker } from "@mui/x-date-pickers-pro"
import { formatISO9075, parseISO } from "date-fns"
import { cloneDeep, isEmpty } from "lodash"
import { useCallback, useContext, useEffect, useState } from "react"
import ReactQuill from "react-quill"
import { Skelly } from "../../../common/components/Skelly"
import { getFullName } from "../../../common/helpers"
import { getFormattedAddress } from "../../../Contacts/Contact/ContactInformation"
import { GET_CONTACT_ADDRESSES } from "../../../Contacts/Queries"
import { headers } from "../../../Exports/PdfExport"
import { CancelButton, SaveCardButton } from "../../../input/Buttons"
import Dropdown from "../../../input/Dropdown/Dropdown"
import Label from "../../../input/Label"
import SingleContact from "../../../input/SingleContact"
import TextBox from "../../../input/Text/TextBox"
import { StyledBadge } from "../../../styles/makesStyles"
import ContactThumbnail from "../../../Thumbnail/ContactThumbnail"
import { GET_INVOICE, UPSERT_INVOICE } from "./queries"
import { getPdf } from '../../../Exports/PdfExport'
import { DispatchContext } from "../../../store"
import VerifyModal from "./VerifyModal"
import { severity } from "../../../Snackbar/CustomizedSnackbar"

interface contextMenuState {
	mouseX?: number;
	mouseY?: number;
	editable: boolean;
}
const initialState: contextMenuState = {
	mouseX: undefined,
	mouseY: undefined,
	editable: false,
}

interface IInvoiceInformation {
	id?: any
	header?: string | null
	purchaser?: any
	purchaserName?: string | null
	purchaserAddress?: string
	invoiceNumber?: string
	agreementDate?: any | null
	paymentTerms?: any | null
	termsOfSale?: any | null
	isDeleted?: boolean | null
}

// options to appear in the toolbar
export const toolbar = [
	['bold', 'italic', 'underline'],
	[
		{'list': 'ordered'},
		{'list': 'bullet'}, 
		{'indent': '-1'},
		{'indent': '+1'}
	],
	['clean']
]
// options to be "accepted" into the quill formatting
export const formats = [
	'bold', 'italic', 'underline',
	'list', 'ordered', 'bullet', 'indent'
]

export default function InvoiceInformation(props: any) {

	const client = useApolloClient()

	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity: string, text: string) => {
		dispatch({type: 'openSnackBar', payload: {severity, text}})
	}, [dispatch])

	const [attempt, setAttempt] = useState(false)
	const [state, setState] = useState(initialState)
	const [contactAddresses, setContactAddresses] = useState([])
	const [selectedAddress, setSelectedAddress] = useState("")

	const [generateActive, setGenerateActive] = useState(false)

	// eslint-disable-next-line eqeqeq
	const [purchaser, setPurchaser] = useState(props.deal.contacts.find((c: any) => c.contactRole?.id == 1))

	const [verifyModal, setVerifyModal] = useState<any|undefined>()

	const [invoiceInformationInput, setInvoiceInformationInput] = useState<IInvoiceInformation>({
		header: headers[0],
		purchaser: purchaser,
		purchaserName: purchaser ? getFullName(purchaser) : null,
		purchaserAddress: undefined,
		invoiceNumber: undefined,
		agreementDate: new Date(),
		paymentTerms: null,
		termsOfSale: null,
	})

	const { error, loading: initialLoading, data } = useQuery(GET_INVOICE, {
		skip: !props.deal?.id,
		variables: {
			id: props.deal.id
		}
	})
	if (error) {
		console.error(error)
		openSnackbar(severity.ERROR, 'There was an error loading the current Invoice')
	}
	const [upsertInvoice, { loading: updateLoading }] = useMutation(UPSERT_INVOICE)

	const loading = updateLoading || initialLoading
	const loadedInvoice = data?.getInvoice
	useEffect(() => {
		if (loadedInvoice) {
			setInvoiceInformationInput({
				id: loadedInvoice.id,
				header: loadedInvoice.header,
				purchaser: loadedInvoice.purchaser,
				purchaserName: loadedInvoice.purchaser_name,
				purchaserAddress: loadedInvoice.purchaser_address,
				invoiceNumber: loadedInvoice.invoice_number,
				agreementDate: parseISO(loadedInvoice.agreement_date),
				paymentTerms: loadedInvoice.payment_terms,
				termsOfSale: loadedInvoice.terms_of_sale,
				isDeleted: loadedInvoice.is_deleted
			})
			setGenerateActive(true)
		}
	}, [loadedInvoice])

	const [invoiceInformationCopy, setInvoiceInformationCopy] = useState<IInvoiceInformation>()

	const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (state.mouseX || state.editable || props.findMode || !props.deal?.id) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
			editable: false
		})
	}

	const handleClose = (option?: string) => {
		if (option === 'edit') {
			setInvoiceInformationCopy(cloneDeep(invoiceInformationInput))
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	const handleChange = (e: any) => updateField(e.target.name, e.target.value)

	const updateField = (field: string, value: any) => {
		setInvoiceInformationInput(old => ({
			...old,
			[field]: value
		}))
	}

	// Update Address List
	useEffect(() => {
		let contact = purchaser
		if (!contact?.id) {
			return undefined
		}

		client.query({
			query: GET_CONTACT_ADDRESSES,
			variables: { id: contact.id }
		}).then(result => {
			const addr = result.data.getContact.address
			if (addr.length > 0) {
				setContactAddresses(addr)
				setSelectedAddress('free')
			}
		}).catch(e => {
			console.error(e)
			openSnackbar(severity.ERROR, "There was a problem loading Contact's Addresses")
		})
	}, [client, purchaser, openSnackbar])

	const saveInvoiceInformation = () => {
		setAttempt(true)

		if (attempt &&
			!(invoiceInformationInput.purchaserName && invoiceInformationInput.purchaserAddress &&
				invoiceInformationInput.invoiceNumber && invoiceInformationInput.paymentTerms && 
				invoiceInformationInput.termsOfSale && invoiceInformationInput.purchaser?.id)
		) {
			openSnackbar(severity.WARNING, 'Please correct fields in red.')
		} else if (invoiceInformationInput.purchaserName && invoiceInformationInput.purchaserAddress &&
			invoiceInformationInput.invoiceNumber && invoiceInformationInput.paymentTerms && 
			invoiceInformationInput.termsOfSale && invoiceInformationInput.purchaser?.id) {
			const input: any = {
				id: invoiceInformationInput.id,
				deal_id: props.deal.id,
				purchaser_id: invoiceInformationInput.purchaser?.id,
				purchaser_name: invoiceInformationInput.purchaserName,
				purchaser_address: invoiceInformationInput.purchaserAddress,
				invoice_number: invoiceInformationInput.invoiceNumber,
				agreement_date: formatISO9075(invoiceInformationInput.agreementDate, { representation: 'date' }),
				payment_terms: invoiceInformationInput.paymentTerms,
				terms_of_sale: invoiceInformationInput.termsOfSale,
				header: invoiceInformationInput.header,
				is_deleted: invoiceInformationInput.isDeleted
			}


			upsertInvoice({ variables: { input } })
				.then(resp => {
					if (!resp?.data?.upsertInvoice?.success) {
						console.error(resp.errors)
						openSnackbar(severity.ERROR, resp?.data?.upsertInvoice?.message ||
							`There was a problem updating the Invoice.`)
					} else {
						openSnackbar(severity.SUCCESS, resp?.data?.upsertInvoice?.message ||
							`Invoice successfully updated.`)
						setGenerateActive(true)
						setState(oldState => ({
							...oldState,
							editable: false
						}))
						setAttempt(false)
					}
				}).catch(error => {
					console.error(error)
					openSnackbar(severity.ERROR, `There was a problem updating the Invoice.`)
				})	
		}
	}

	return <Paper
		className="qv-margin"
		onContextMenu={(e) => !state.mouseX ? handleClick(e) : null}
	>

		<h1 className="card-title">
			<span>Invoice Information</span>
			{state.editable && <>
				<div className="spacer"></div>
				<CancelButton
					variant="contained"
					size="small"
					onClick={() => {
						if (invoiceInformationCopy !== undefined) {
							setInvoiceInformationInput(invoiceInformationCopy)
						}
						setAttempt(false)
						handleClose()
					}}
				>
					Cancel
				</CancelButton>
				<SaveCardButton
					variant="contained"
					color="secondary"
					size="small"
					disabled={props.disabled}
					onClick={saveInvoiceInformation}
				>
					Save
				</SaveCardButton>
			</>}
		</h1>

		{/* Purchaser row */}
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center'
			}}
		>
			<FormControl sx={{ flex: 1 }}>
				<Label id="purchaser-label" disableAnimation shrink
					error={attempt && !invoiceInformationInput.purchaser}
				>
					Purchaser
				</Label>
				{ state.editable ?
					<SingleContact
						error={attempt && !invoiceInformationInput.purchaser}
						elasticSearch="searchContacts"
						value={isEmpty(invoiceInformationInput.purchaser) ? null : invoiceInformationInput.purchaser}
						onChange={(event: any, value: any) => {
							setPurchaser(value)
							handleChange({ target: {
								name: 'purchaser',
								value
							}})
							if (value) {
								setVerifyModal({
									name: 'Purchaser Name',
									field: 'purchaserName',
									value: getFullName(value)
								})
							}
						}}
						private={props.private}
					/> :
					(loading ? <Skelly /> :
						<ContactThumbnail
							style={{ marginTop: '2em' }}
							contact={invoiceInformationInput.purchaser}
						/>)

				}
			</FormControl>
			<div style={{ width: '1em' }}>{/* spacer */}</div>
			{ (state.editable && !isEmpty(invoiceInformationInput.purchaser)) ?
				<FormControl
					sx={{ flex: 1 }}
					className="padded-select"
				>
					<Label id="contact-address-label" disableAnimation shrink>
						Select Address
					</Label>
					<Select
						IconComponent={ExpandMoreRounded}
						name="contact-address"

						labelId="contact-address-label"
						input={<Dropdown />}
						value={selectedAddress}
						onChange={(e) => {
							const addr = e.target.value
							setSelectedAddress(addr)
							if (addr !== 'free') {
								setVerifyModal({
									name: 'Purchaser Address',
									field: 'purchaserAddress',
									value: getFormattedAddress([], addr)
								})
							}
						}}
					>
						{contactAddresses.map((addr: any) => (
							<MenuItem key={addr.id} value={addr}>
								{`${ addr.label||'' } (${addr.type})`}
								{addr?.is_primary ?
									<StyledBadge
										style={{marginLeft: '.5em'}}
										overlap="circular"
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'right',
										}}
										variant="dot"
									></StyledBadge>
									: null
								}
							</MenuItem>
						))}
						<MenuItem key="fe" value="free">Free Entry</MenuItem>
					</Select>
				</FormControl> :
				null
			}
		</Box>

		<hr />

		<Box> {/* Invoice Header Row */}
			<FormControl className="padded-select"
				style={{ width: '100%' }}
			>
				<Label id="header-label" disableAnimation shrink>
					Header
				</Label>
				{ loading ? <Skelly /> :
					<Select
						readOnly={!state.editable}
						className={ !state.editable ? 'readonly' : undefined }
						IconComponent={ExpandMoreRounded}
						name="header"

						labelId="header-label"
						input={<Dropdown />}
						value={invoiceInformationInput.header}
						onChange={handleChange}
					>
						{ headers.map(h => <MenuItem key={h} value={h}>{h}</MenuItem>) }
					</Select> }
			</FormControl>
		</Box>

		{/* Name, Address; Inv. no, date row*/}
		<Box sx={{
			display: 'flex',
			flexDirection: 'row'
		}}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flex: 1
				}}
			>

				<FormControl>
					<Label id="purchaser-label" 
						disableAnimation shrink
						error={attempt && !invoiceInformationInput.purchaserName}
					>
						Purchaser Name
					</Label>
					{ loading ? <Skelly /> :
						<TextBox
							readOnly={!state.editable}

							className="user-input"
							name="purchaserName"
							value={invoiceInformationInput.purchaserName}
							error={attempt && !invoiceInformationInput.purchaserName}
							onChange={handleChange}
						/>}
				</FormControl>
				<FormControl>
					<Label id="address-label"
						disableAnimation shrink
						error={attempt && !invoiceInformationInput.purchaserAddress}
					>
						Address
					</Label>
					{ loading ? <Skelly /> :
						<TextBox
							readOnly={!state.editable}
							multiline
							name="purchaserAddress"
							value={invoiceInformationInput.purchaserAddress ?? ''}
							error={attempt && !invoiceInformationInput.purchaserAddress}
							onChange={handleChange}
							rows="4"
						/>}
				</FormControl>

			</Box>
			<div style={{ width: '1em' }}>{/* spacer */}</div>
			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				flex: 1
			}}>
				<FormControl>
					<Label id="invoice-label"
						disableAnimation shrink
						error={attempt && !invoiceInformationInput.invoiceNumber}
					>
						Invoice
					</Label>
					{ loading ? <Skelly /> :
						<TextBox
							readOnly={!state.editable}
							className="user-input"
							name="invoiceNumber"
							value={invoiceInformationInput.invoiceNumber ?? ''}
							error={attempt && !invoiceInformationInput.invoiceNumber}
							onChange={handleChange}
						/>}
				</FormControl>
				<FormControl /* sx={{paddingRight: '1em'}} */
				>
					<Label id="date-label"
						disableAnimation shrink
					>
						Date
					</Label>
					{ loading ? <Skelly /> :
						<MobileDatePicker
							readOnly={!state.editable}
							inputFormat="MMM do, yyyy"
							disableMaskedInput={true}
							className="MUIDatePicker"
							showToolbar
							componentsProps={{
								actionBar: {
									actions: ['today']
								}
							}}
							value={invoiceInformationInput.agreementDate}
							renderInput={({ inputRef, inputProps, InputProps }) => {

								const newProps = { ...inputProps}
								newProps.readOnly = !state.editable

								// @ts-ignore
								return <TextBox
									ref={inputRef}
									endAdornment={InputProps?.endAdornment}
									{...newProps}
								/>
							}}
							onChange={(date) => {
								let _date = new Date(date || Date())
								let newDate = new Date()

								newDate.setDate(_date.getDate())
								newDate.setFullYear(_date.getFullYear())
								newDate.setMonth(_date.getMonth())

								handleChange({ target: {
									name: 'agreementDate',
									value: newDate
								}})
							}}
						/>}
				</FormControl>
			</Box>
		</Box>

		<Box sx={{
			display: 'flex',
			flexDirection: "column"
		}}>
			<FormControl>
				<Label shrink
					error={attempt && !invoiceInformationInput.paymentTerms}
				>Payment Terms</Label>
				<div style={{ height: '1.5em' }}></div>
			</FormControl>
			{ loading ? <Skelly /> :
				<ReactQuill
					className={state.editable ? '' : 'not-editable'}
					readOnly={!state.editable}
					modules={{ toolbar: state.editable ? toolbar : false }}
					formats={formats}
					theme="snow"
					value={ invoiceInformationInput.paymentTerms || { ops: [] }}
					onChange={(content, delta, source, editor) => {
						setInvoiceInformationInput( old => ({
							...old,
							paymentTerms: editor.getContents(),
						}))
					}}
				/> }

			<FormControl>
				<Label shrink
					error={attempt && !invoiceInformationInput.termsOfSale}
				>Terms of Sale</Label>
				<div style={{ height: '1.5em' }}></div>
			</FormControl>
			{ loading ? <Skelly /> :
				<ReactQuill
					className={state.editable ? '' : 'not-editable'}
					theme="snow"
					readOnly={!state.editable}
					modules={{ toolbar: state.editable ? toolbar : false }}
					formats={formats}
					value={invoiceInformationInput.termsOfSale || { ops: [] }}
					onChange={(content, delta, source, editor) => {
						setInvoiceInformationInput( old => ({
							...old,
							termsOfSale: editor.getContents(),
						}))
					}}
				/> }
		</Box>
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'end',
				marginTop: '1em'
			}}
		>
			{!state.editable &&
				<Button
					variant="contained"
					color="success"
					disabled={!generateActive || loading}
					onClick={() => {
						const { purchaser, ...info } = invoiceInformationInput
						info.agreementDate = formatISO9075(
							info.agreementDate, { representation: 'date' }
						)
						// @ts-ignore
						info.deal_id = props.deal.id
						getPdf('Invoice', info.header, null,
							[purchaser], openSnackbar, info, null,
							(bool: boolean) => setGenerateActive(!bool)
						)
					}}
				>
					Generate
				</Button>
			}
		</Box>

		<VerifyModal
			open={verifyModal}
			onClose={() => setVerifyModal(undefined)}
			field={verifyModal?.field}
			name={verifyModal?.name}
			value={verifyModal?.value}
			onChange={handleChange}
		/>

		<Menu
			keepMounted
			open={state.mouseY != null}
			onClose={() => handleClose()}
			anchorReference="anchorPosition"
			anchorPosition={
				state.mouseY != null && state.mouseX != null
					? { top: state.mouseY, left: state.mouseX }
					: undefined
			}
		>
			<MenuItem onClick={() => handleClose('edit')}>Edit</MenuItem>
		</Menu>
	</Paper>


}
