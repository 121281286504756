import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import * as Colors from './../styles/colors/Colors'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import RevvedCircularProgress from './RevvedCircularProgress'



export default function OutreachGoal(props) {

	const outreach = props.user?.goals.find(x=> x.goal_type_id === '2')

	const useStyles = makeStyles(theme => ({
		outreachGoalRoot: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-evenly',
			height: outreach ? 'calc(100% - 3em)' : '8em'
		},
		bottom: {
			 color: 'grey',
		},
		top: {
			color:  Colors.error,
			animationDuration: "550ms",
			position: "absolute",
			left: 0,
		},
		circle: {
			  strokeLinecap: "round",
			  transition: 'all 800ms ease-in',
		}
	}))

	const classes = useStyles()

	if (!outreach?.target) {
		return (
			<div className={classes.outreachGoalRoot}>
				<Typography 
					variant="subtitle1"
					component="div"
					color="textSecondary"
					align="center"
				>
					You currently don’t have an outreach goal, you can disable this widget in your profile or 
					see a system administrator to record an annual goal.
				</Typography>
			</div>
		)
	}
	return (
		<div className={classes.outreachGoalRoot}>
			<Box position="relative" display="inline-flex" style={{
				padding: '3em 0'
			}}>
				<RevvedCircularProgress
					current={Math.min(
						outreach?.progress,
						outreach?.target
					)}
					target={outreach?.target}
					textStyle={{ fontSize: 20 }}
					topColor="#A91D23"
					bottomColor="lightgrey"
					size={170}
					thickness={5}
				></RevvedCircularProgress>
			</Box>

			<Box>
				<Typography 
					variant="subtitle1"
					component="div"
					color="textSecondary"
				>
					<table style={{width: '20em'}}>
						<tbody>
							<tr>
								<th></th>
								<th></th>
							</tr>
							<tr>
					    	<td style={{textAlign:'right'}}>Year to Date:</td>
								<td style={{paddingLeft:'2em'}}>{outreach?.progress}</td>
							</tr>
							<tr>
								<td style={{textAlign:'right'}}>Goal:</td>
								<td style={{paddingLeft:'2em'}}>{outreach?.target}</td>
							</tr>
						</tbody>
					</table>
					
				</Typography>
					
			</Box>
		</div>
	)

}
