import { Field } from "./BulkEdit"

// The following fields are intentionally omitted from below
// 'title', 'inventory_number_prefix', 'inventory_number',
// 'inventory_number_suffix', 'cost_split',	'code_name', 'images',
// 'profile_link', 'operation_images', 'files'


// the following fields are in Selected Actions
// 'salespersons_ids', 'primary_salesperson_ids', 'isFavorite'

const bulkArtfields: Field[] = [
	{
		field: 'formatted_title',
		name: 'Formatted Title',
		type: 'jsonb'
	},
	{
		field: 'year',
		name: 'Date',
		type: 'string'
	},
	{
		field: 'medium',
		name: 'Medium',
		type: 'string',
	},
	{
		field: 'dimension',
		name: 'Dimension',
		type: 'dimensions',
	},
	{
		field: 'signature',
		name: 'Signature',
		type: 'jsonb',
	},
	{
		field: 'edition_copy_number',
		name: 'Edition Copy #',
		type: 'string',
	},
	{
		field: 'edition_copy_total',
		name: 'Edition Copy Total',
		type: 'string', // 50
	},
	{
		field: 'edition_ap',
		name: 'Edition AP',
		type: 'string', // 255
	},
	{
		field: 'edition_ap_total',
		name: 'Edition AP Total',
		type: 'string', // 255
	},
	{
		field: 'category_id',
		name: 'Category',
		type: 'ID',
		lookup: 'getArtCategories'
	},
	{
		field: 'status_id',
		name: 'Status',
		type: 'ID',
		lookup: 'getArtStatuses'
	},
	{
		field: 'is_active',
		name: 'Is Active',
		type: 'boolean',
	},
	{
		field: 'other_inventory_number',
		name: 'Other Inv. No.',
		type: 'string',
	},
	{
		field: 'insurance_id',
		name: 'Insurance',
		type: 'ID',
		lookup: 'getInsurance'
	},
	{
		field: 'crate_description',
		name: 'Crate Description',
		type: 'string',
	},
	{
		field: 'frame_description',
		name: 'Frame Description',
		type: 'string',
	},
	{
		field: 'mount_description',
		name: 'Mount Description',
		type: 'string',
	},
	{
		field: 'installation_description',
		name: 'Installation Description',
		type: 'string',
	},
	{
		field: 'condition_description',
		name: 'Condition Description',
		type: 'string',
	},
	{
		field: 'is_private',
		name: 'Is Private',
		type: 'boolean',
	},
	{
		field: 'is_verified',
		name: 'Is Verified',
		type: 'boolean',
	},
	{
		field: 'verified_at',
		name: 'Verified At',
		type: 'date',
	},
	{
		field: 'verified_by',
		name: 'Verified By',
		type: 'galleryContact',
	},
	{
		field: 'verified_text',
		name: 'Verified Text',
		type: 'string',
	},
	{
		field: 'provenance',
		name: 'Provenance',
		type: 'ArtResearchInput',
	},
	{
		field: 'exhibition',
		name: 'Exhibition',
		type: 'ArtResearchInput',
	},
	{
		field: 'literature',
		name: 'Literature',
		type: 'ArtResearchInput',
	},
	{
		field: 'other',
		name: 'Text',
		type: 'ArtResearchInput',
	},
	{
		field: 'restrictedToUsers',
		name: 'Restricted To',
		type: 'restrictedToUsers',
	},
	{
		field: 'catalogue_raisonne',
		name: 'Catalogue Raisonne',
		type: 'string',
	},
	{
		field: 'photographer',
		name: 'Photographer',
		type: 'string',
	},
	{
		field: 'additional_credit_lines',
		name: 'Additional Credit Lines',
		type: 'string',
	},
	{
		field: 'customs_status',
		name: 'Customs Status',
		type: 'string',
	},
]

export default bulkArtfields
