import { gql } from '@apollo/client'
import { userData } from '../../common/fragments'

export const GET_USERS = gql`
	query getUsers(
		$field: String
		$direction: SortDirection
		$cursor: Int,
		$includeDeleted: Boolean
	)
	{
		getUsers(
			input: {
			  	field: $field
				direction: $direction
				cursor: $cursor
				includeDeleted: $includeDeleted
			}
		)
		{
			items {
				...userData
				title
				prefix_id
				is_deleted
			}
			cursor
			totalItems
		}
	}
	${userData}
`

export const GET_CONTACT_DATALOG = gql`
	query getContactDataLog(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$id: ID!
		$date: String
		$items: [String]
		$item_ids: [ID]
		$actions: [Item]
		$user_ids: [ID]
	)
	{
		getContactDataLog(
			input: {
				field: $field
				direction: $direction
				limit: 10
				cursor: $cursor
				filters: {
					id: $id
					date: $date
					items: $items
					item_ids: $item_ids
					actions: $actions
					user_ids: $user_ids
				}
			}
		)
		{
			cursor
			totalItems

			items {
				id
				contact_id
				item
				item_id
				action
				diff
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				user {
					...userData
					title
				}
			}
		}
	}
	${userData}
`

export const CREATE_PHONE = gql`
	 mutation createPhone($PhoneInput: PhoneInput) {
        createPhone(input: $PhoneInput) {
			code
			success
			message
        }
    }
`

export const CREATE_EMAIL = gql`
	 mutation createEmail($EmailInput: EmailInput) {
        createEmail(input: $EmailInput) {
			code
			success
			message
        }
    }
`

export const CREATE_WEBSITE = gql`
	 mutation createWebsite($WebsiteInput: WebsiteInput) {
        createWebsite(input: $WebsiteInput) {
			code
			success
			message
        }
    }
`

export const CREATE_ADDRESS = gql`
	 mutation createAddress($AddressInput: AddressInput) {
        createAddress(input: $AddressInput) {
			code
			success
			message
        }
    }
`

export const SEARCH_CONTACTS_FAST = gql`
	query SearchContacts(
		$field: String
		$direction: SortDirection
		$searchString: String
		$cursor: Int
		$limit: Int
	) {
		searchContacts(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: {
					searchString: $searchString
				}
			}
		) {
			items {
				id
				first_name
				last_name
				company_name
				is_company
				is_private
				created_at
				code_name
				imgUrl
				is_deceased
				dynamic_generated_codename
			}
			cursor
			totalItems
		}
	}
`
