import { gql } from '@apollo/client'
import { userDataCompressed } from '../../../common/fragments'

export const GET_TASKS = gql`
	query getDealTasks(
		$deal_id: ID
		$thumbnailResolution: String = "128x128"

	) {
		getDealTasks(input: { deal_id: $deal_id }) {
			id
			type_id
			description
			assigned_by_user_id
			approval_required
			approved_by_user_id
			approval_at
			due_at
			is_completed
			created_by
			created_at
			modified_by
			modified_at
			is_deleted
			task_status_id

			# task_x_assigned_to
			assigned_to_type
			assigned_to_users {
				...userDataCompressed
				department
				is_deleted
			}
			assigned_to_department {
				id
				value
			}
		}
	}
	${userDataCompressed}
`

export const CREATE_TASK = gql`
	mutation createDealTask(
		$CreateTaskInput: CreateTaskInput!
		$thumbnailResolution: String = "128x128"
	) {
		createDealTask(input: $CreateTaskInput) {
			code
			success
			message
			task {
				id
				type_id
				description
				assigned_by_user_id
				approval_required
				approved_by_user_id
				approval_at
				due_at
				is_completed
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				task_status_id
				# task_x_assigned_to
				assigned_to_type
				assigned_to_users {
					...userDataCompressed
					department
					is_deleted
				}
				assigned_to_department {
					id
					value
				}
			}
		}
	}
	${userDataCompressed}
`
export const DELETE_TASK = gql`
	mutation deleteDealTask($id: ID) {
		deleteDealTask(id: $id) {
			code
			success
			message
		}
	}
`

export const UPDATE_TASK = gql`
	mutation updateDealTask(
		$UpdateTaskInput: UpdateTaskInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateDealTask(input: $UpdateTaskInput) {
			code
			success
			message
			task {
				id
				type_id
				description
				assigned_by_user_id
				approval_required
				approved_by_user_id
				approval_at
				due_at
				is_completed
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				task_status_id

				# task_x_assigned_to
				assigned_to_type
				assigned_to_users {
					...userDataCompressed
					department
					is_deleted
				}
				assigned_to_department {
					id
					value
				}
			}
		}
	}
	${userDataCompressed}
`
