import Paper from '@mui/material/Paper'
import { Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'

export default function PageNotFound(props) {
	
	const location = useLocation()

	return (location.pathname !== "/") ? 
		<Paper className="padding-margin">
			<Typography variant="h2">
				404 Not Found <span style={{fontSize: "0.5em"}} role="img" aria-label="Slightly Frowning Face">🙁</span> 
			</Typography> 
			<br/>
			<Typography component="p">
				We cannot find the page your are looking for. <br/>
				Please use the navigation on this page to get back on track.
			</Typography>
		</Paper> : null
}
