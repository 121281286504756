import { TextField, TextFieldProps } from "@mui/material"
import { styled } from "@mui/system"

const styledTextField = styled(TextField)
const TextBoxForAutocomplete = styledTextField<TextFieldProps>(({ theme }) => ({
	"& .MuiOutlinedInput-root": {
		padding: '6px 12px',
	},
}))

export default TextBoxForAutocomplete
