/* eslint-disable eqeqeq */
import { useMutation, useQuery } from "@apollo/client"
import { withApollo } from "@apollo/client/react/hoc"
import React, { useCallback, useContext, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import CollapsibleSearchCard from "../common/components/CollapsibleSearchCard"
import { SavedSearch } from "../common/SavedSearch"
import { DEAL_SEARCH_OBJECT, DEFAULT_SEARCH_LIMIT } from "../constants/values"
import { DYNAMIC_DEAL_NAV } from "../navigation/Queries"
import useNavigation from "../navigation/useNavigation"
import UserAccessQuickView from "../QuickView/UserAccessQuickView"
import { SELECT_ALL_DEALS } from "../Search/ActionButtons/Queries"
import GlobalSearchFilters, { getOperatorValue, initialFilterState } from "../Search/GlobalSearchFilters"
import { DEAL_FLAG_QUERY, DYNAMIC_DEAL_SEARCH, GET_DEAL_FILTER_FIELDS, GET_SEARCHES, UPDATE_SEARCH } from "../Search/Queries"
import SearchQuickView from "../Search/SearchQuickView"
import { filterMap, filterSearchTerms, handleDeleteSearch, updateLabel } from "../Search/unifiedSearchHelpers"
import { severity } from "../Snackbar/CustomizedSnackbar"
import { AuthStateContext, DispatchContext } from "../store"
import CreateDealQuickView from "./CreateDealQuickView"
import DealQuickView from "./DealQuickView"
import { DealsDataGrid } from "./DealsDataGrid"
import SearchDeals from "./SearchDeals"

const Deals = (props) => {

	const { push: pushNav } = useNavigation()
	const navigate = useNavigate()
	const location = useLocation()
	const userAuthentication = useContext(AuthStateContext)

	const prevSearch = location.state

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const closeSnackbar = () => {
		dispatch({ type: 'closeSnackBar'})
	}

	// Mutations
	const [updateSearch] = useMutation(UPDATE_SEARCH)

	// State that comes from prevSearch
	const [order, setOrder] = React.useState(prevSearch?.order || "asc")
	const [orderBy, setOrderBy] = React.useState(prevSearch?.orderBy || null)
	const [deals, setDeals] = React.useState(prevSearch?.deals ?? [])
	const [activeStep, setActiveStep] = React.useState(prevSearch?.activeStep ?? 0)
	const [totalItems, setTotalItems] = React.useState(prevSearch?.totalItems ?? null)
	const [steps, setSteps] = React.useState(prevSearch?.steps ?? 1)
	const [selectedDeal, setSelectedDeal] = React.useState(null)
	const [advancedSearch, setAdvancedSearch] = React.useState(prevSearch?.advancedSearch ?? false)
	const [currentSearch, setCurrentSearch] = React.useState(prevSearch?.currentSearch ?? new SavedSearch({
		object_id: DEAL_SEARCH_OBJECT,
		search_terms: [],
		is_global: true,
		user_id: userAuthentication.user?.id
	}))
	const [limit, setLimit] = React.useState(prevSearch?.limit ?? DEFAULT_SEARCH_LIMIT)


	// Normal State
	const [value, setValue] = React.useState(0)
	const [flaggedCount, setFlaggedCount] = React.useState(null)
	const [firstRender, setFirstRender] = React.useState(true)
	const [selection, setSelection] = React.useState([])
	const [dealAutoValue, setDealAutoValue] = React.useState(null)
	const [savedSearches, setSavedSearches] = React.useState([])
	const [searchLoading, setSearchLoading] = React.useState(null)
	const [createDeal, setCreateDeal] = React.useState(null)

	const setFilters = (filters) => {
		setCurrentSearch({
			...currentSearch,
			search_terms: filters
		})
	}

	const currentFilters = [
		'searchable_deal_type',
		'deal_entry_status_category',
		'deal_entry_status_id',
		'created_at',
		'gallery_contacts',
		'isFavorite',
		'query'
	]

	const advancedFilters = filterSearchTerms(currentSearch.search_terms)?.filter(term => {
		if (currentFilters.includes(term.field)) return false
		return true
	})

	useEffect(() => {

		if (currentSearch.search_terms.length == 0 && advancedSearch) {
			setCurrentSearch(new SavedSearch({
				object_id: DEAL_SEARCH_OBJECT,
				search_terms: [initialFilterState()],
				is_global: true,
				user_id: userAuthentication.user?.id
			}))
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSearch.search_terms, userAuthentication.user?.id, advancedSearch])

	// Search on change of order
	React.useEffect(() => {
		setFirstRender(false)
		if (deals)
			if (deals.length !== 0 && deals[0].disabled !== true && !firstRender) {
				handleSubmit()
			}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order, orderBy, limit, activeStep])

	/**
	 * Save current page state for if one returns to it through
	 * back arrow.
	 *
	 * @returns current page state
	 */
	function updateHistory (index) {
		const currentPageState = {
			currentSearch,
			orderBy,
			order,
			deals,
			totalItems,
			limit,
			steps,
			activeStep,
			advancedSearch,
			searchPage: location.pathname
		}
		const offset = index ? deals.findIndex(a => a.id == index) : deals.indexOf(selectedDeal)
		const cursor = activeStep * limit + offset
		const variables = makeVariables(cursor, currentSearch, orderBy, order, currentSearch?.search_terms ?? [])
		variables.limit = 3
		currentPageState.variables = variables
		navigate(location.pathname, { state: currentPageState })
		return currentPageState
	}

	function saveAndGo(path, row) {
		// persist current page state in history
		const state = updateHistory(row)
		pushNav({
			url: location.pathname,
			state,
			query: DYNAMIC_DEAL_NAV
		}, state.variables?.cursor)
		// navigate to new page (with this page's query and `variables`)
		navigate(path, { state })
	}

	// Save search to state on selecting a Deal
	React.useEffect(() => {
		if (selectedDeal) updateHistory()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDeal])


	// Get saved searches
	const {loading, error } = useQuery(GET_SEARCHES, {
		skip:  !userAuthentication.user?.id,
		variables: {
			userId: userAuthentication.user?.id,
			objectId: DEAL_SEARCH_OBJECT,
			global: true
		},
		onCompleted: (data) => {
			if (data && data.getSearches && data.getSearches?.length !== 0) {
				setSavedSearches(data.getSearches
					.map(search => new SavedSearch(search)))
			} else {
				// console.log("No saved searches were found.")
			}
		}
	})

	if (error) {
		console.log(error)
		openSnackbar(severity.ERROR, "Error retrieving your saved searches.")
	}


	const makeVariables = (cursor, currentSearch, orderBy, order, filters, limitOverride) => {
		return ({
			cursor,
			limit: limitOverride ?? limit,
			field: orderBy,
			direction: order.toUpperCase(),
			filters: filters ? filterMap(filters) : filterMap(currentSearch.search_terms)
		})
	}

	const handleSubmit = ({cursor} = {}, filters = null, searchId) => {

		setSearchLoading(true)
		let submitCursor
		if (cursor == undefined) {
			submitCursor = activeStep * limit
		} else {
			submitCursor = cursor
		}
		openSnackbar(severity.INFO, "Loading search results...")

		const variables = makeVariables(
			submitCursor, currentSearch, orderBy, order, filters
		)
		setValue(1)

		// Set filters when handle submit is not coming from saved search list
		if (filters?.length && !searchId) setFilters(filters)

		props.client
			.query({ query: DYNAMIC_DEAL_SEARCH, variables })
			.then((result) => {

				const {data} = result

				if (data.searchDynamicDeals?.items) {
					setDeals(data.searchDynamicDeals.items)

					if (data.searchDynamicDeals.items < 1) setSteps(1)
					else setSteps((Math.ceil(data.searchDynamicDeals.totalItems / limit)))

					setTotalItems(data.searchDynamicDeals.totalItems || 0)
					setSearchLoading(false)

					if (!data.searchDynamicDeals.totalItems) {
						openSnackbar(severity.WARNING, "There were no results.")
					} else {
						closeSnackbar()
					}

				} else {
					console.error(data)
					setSearchLoading(false)
					openSnackbar(severity.ERROR, "There was an error searching deals.")
				}
			})
			.catch((error) => {
				console.error(error)
				setSearchLoading(false)
				openSnackbar(severity.ERROR, "Could not search deals.")
			})

	}

	const handleReset = (page) => {

		setCurrentSearch(new SavedSearch({
			object_id: DEAL_SEARCH_OBJECT,
			search_terms: [],
			is_global: true,
			user_id: userAuthentication.user?.id
		}))
		setDeals([])
		setCreateDeal(false)
		setSteps(1)
		setActiveStep(0)
		setTotalItems(null)
		setOrder("asc")
		setOrderBy(null)
		setSelectedDeal(null)
		setSearchLoading(false)
		setDealAutoValue(null)
		setValue(0)
	}

	const updateDealSavedSearchLabel = (search, label) =>
		updateLabel(
			search,
			label,
			updateSearch,
			savedSearches,
			setSavedSearches,
			openSnackbar
		)

	const handleDeleteDealSavedSearch = (search) =>
		handleDeleteSearch(
			search,
			updateSearch,
			savedSearches,
			setSavedSearches,
			openSnackbar
		)

	// Get the dynamic deal filter fields
	const { data: dealFilterFields, error: dealFilterError } = useQuery(GET_DEAL_FILTER_FIELDS, {
		fetchPolicy: 'cache-first',
		onCompleted: ({searchDynamicDealsFilterFields}) => {
			const storedFields = location?.state?.fields
			if (storedFields) {
				setAdvancedSearch(true)
				const fieldFilters = Object.entries(storedFields)
					.map(([field, value], i) => {
						const filterField = searchDynamicDealsFilterFields
							.find(f => f.name == field)
						let type = getOperatorValue(filterField?.type)
						return {
							field,
							value,
							type,
							id: new Date().getTime() + i
						}
					})
				
				setFilters(fieldFilters)
				handleSubmit({}, fieldFilters)
			}
		}
	})

	if (dealFilterError) {
		openSnackbar(severity.ERROR, 'There was an error retrieving filterable fields for this search.')
		console.error(dealFilterError)
	}

	const renderQuickView = () => {
		if (createDeal) {
			return (
				<CreateDealQuickView
					setCreateDeal={setCreateDeal}
				></CreateDealQuickView>
			)
		}

		else if (selectedDeal) {

			if ((selectedDeal.is_private || selectedDeal.is_gallery_private) && !selectedDeal.created_at)
				return (
					<UserAccessQuickView
						entity={selectedDeal}
						onClose={() => setSelectedDeal(null)}
					></UserAccessQuickView>
				)

			return (
				<DealQuickView
					id={selectedDeal.id}
					onClose={() => setSelectedDeal(null)}
				></DealQuickView>
			)
		}

		else {
			return (
				<SearchQuickView
					savedSearches={savedSearches}
					setSavedSearches={setSavedSearches}
					setCurrentSearch={setCurrentSearch}
					setFilters={setFilters}
					handleSubmit={handleSubmit}
					currentSearch={currentSearch}
					setAdvancedSearch={setAdvancedSearch}
					savedSearchesLoading={loading}
					updateLabel={updateDealSavedSearchLabel}
					handleDeleteSearch={handleDeleteDealSavedSearch}
					setSelection={setSelection}
					selection={selection}
					dealAutoValue={dealAutoValue}
					setDealAutoValue={setDealAutoValue}
					totalItems={totalItems}
					flaggedCount={flaggedCount}
					setFlaggedCount={setFlaggedCount}
					makeVariables={makeVariables}
					orderBy={orderBy}
					order={order}
					searchQuery={DYNAMIC_DEAL_SEARCH}
					entity="deal"
					bulkActionQuery={SELECT_ALL_DEALS}
					flagQuery={DEAL_FLAG_QUERY}
					setValue={setValue}
					value={value}
					filterFields={dealFilterFields?.searchDynamicDealsFilterFields}
					requery={handleSubmit}
				/>
			)
		}
	}

	const variables = makeVariables(0, currentSearch, orderBy, order)

	return (
		<>

			<section className="main-page">

				{ renderQuickView() }

				<div style={{
					display: 'flex',
					flexDirection: 'column'
				}}>
					<CollapsibleSearchCard>
						{advancedSearch ?
							<GlobalSearchFilters
								loading={searchLoading}
								currentSearch={currentSearch}
								setCurrentSearch={setCurrentSearch}
								onReset={handleReset}
								onSearch={handleSubmit}
								object_id={DEAL_SEARCH_OBJECT}
								advancedSearch={advancedSearch}
								setAdvancedSearch={setAdvancedSearch}
								setCreateEntity={setCreateDeal}
								filterFields={dealFilterFields?.searchDynamicDealsFilterFields}
								typeName="Deal"
								onFindMode={() => navigate('/deals/findmode/details')}
								savedSearches={savedSearches}
								setSavedSearches={setSavedSearches}
								prevSearch={prevSearch?.currentSearch}
							/> :
							<SearchDeals
								loading={searchLoading}
								currentFilters={currentSearch.search_terms}
								resetSearch={handleReset}
								currentSearch={currentSearch}
								setCurrentSearch={setCurrentSearch}
								handleSubmit={handleSubmit}
								setActiveStep={setActiveStep}
								setTotalItems={setTotalItems}	
								advancedSearch={advancedSearch}
								setAdvancedSearch={setAdvancedSearch}
								setCreateDeal={setCreateDeal}
								advancedFilters={advancedFilters}
								selection={selection}
								setSelection={setSelection}
								dealAutoValue={dealAutoValue}
								setDealAutoValue={setDealAutoValue}
								onFindMode={() => navigate('/deals/findmode/details')}
								prevSearch={prevSearch?.currentSearch}
								savedSearches={savedSearches}
								setSavedSearches={setSavedSearches}
							></SearchDeals>}
					</CollapsibleSearchCard>
					<DealsDataGrid 
					
						rows={deals}
						loading={searchLoading}
						saveAndGo={saveAndGo}
						setRows={setDeals}

						setFlaggedCount={setFlaggedCount}
						userId={userAuthentication.user?.id}
						mainSearchPage={true}
						totalItems={totalItems}
						onSelect={setSelectedDeal}
						selectedRow={selectedDeal}
						limit={limit}
						setLimit={setLimit}
						activeStep={activeStep}
						setActiveStep={setActiveStep}

						sortModel={ orderBy ? [{ field: orderBy, sort: order }] : []}
						onSortModelChange={newSort => {
							if (newSort.length) {
								setOrderBy(newSort[0].field)
								setOrder(newSort[0].sort)
							} else {
								setOrderBy(null)
								setOrder('asc')
							}
						}}
						// flagmenu
						filterTo={newFilter => {
							setSelection([])
							setFilters(newFilter)
							handleSubmit({cursor: 0}, newFilter)
							setValue(1)
							setSelectedDeal(null)
						}}
						type="Deal"
						variables={variables}
					/>
				</div>
			</section>
		</>
	)
}

export default withApollo(Deals)
