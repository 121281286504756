import { gql } from '@apollo/client'

export const GET_INVOICE = gql`
	query GetInvoice($id: ID!) {
		getInvoice(id: $id) {
			id
			purchaser {
				id
				first_name
				last_name
				company_name
				is_company
				is_private
				created_at
				code_name
				imgUrl (thumbnailResolution: "128x128")
				is_deceased
				dynamic_generated_codename
				created_at
			}
			purchaser_id
			purchaser_name
			purchaser_address
			invoice_number
			agreement_date
			payment_terms
			terms_of_sale
			header
			created_by
			created_at
			modified_by
			modified_at
			is_deleted
		}
	}
`

export const UPSERT_INVOICE = gql`
	mutation UpsertInvoice($input: InvoiceInput!) {
		upsertInvoice(input: $input) {
			code
			success
			message
			invoice {
				id
				purchaser_id
				purchaser_name
				purchaser_address
				invoice_number
				agreement_date
				payment_terms
				terms_of_sale
				header
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
			}
		}
	}
`


export const GET_CONSIGNMENT = gql`
	query GetConsignment($id: ID!) {
		getConsignment(id: $id) {
			id
			recipient {
				id
				first_name
				last_name
				company_name
				is_company
				is_private
				created_at
				code_name
				imgUrl (thumbnailResolution: "128x128")
				is_deceased
				dynamic_generated_codename
				created_at
			}
			recipient_id
			recipient_name
			recipient_address
			sender {
				id
				first_name
				last_name
				company_name
				is_company
				is_private
				created_at
				code_name
				imgUrl (thumbnailResolution: "128x128")
				is_deceased
				dynamic_generated_codename
				created_at
			}
			sender_id
			sender_name
			sender_address
			consignment_period
			pronoun
			consignment_agreement
			header
			created_by
			created_at
			modified_by
			modified_at
			is_deleted
		}
	}
`


export const UPSERT_CONSIGNMENT = gql`
	mutation UpsertConsignment($input: ConsignmentInput!) {
		upsertConsignment(input: $input) {
			code
			success
			message
			consignmentInformation {
				id
				recipient_id
				recipient_name
				recipient_address
				sender_id
				sender_name
				sender_address
				consignment_period
				pronoun
				consignment_agreement
				header
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
			}
		}
	}
`
