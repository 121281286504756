/* eslint-disable eqeqeq */
import { CircularProgress, List, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useContext } from 'react'
import StarIcon from '@mui/icons-material/Star'
import { capitalizeFirstLetter } from '../common/helpers'
import { format } from 'date-fns'
import { LookupContext } from '../store'
import SaveIcon from '@mui/icons-material/Save'
import QuickViewItem from '../SearchQuickView/QuickViewItem'
import { withApollo } from '@apollo/client/react/hoc'
import { FAVORITES_DEFAULT_SEARCH, RECENTLY_CREATED_DEFAULT_SEARCH, VERIFIED_ARTWORK_DEFAULT_SEARCH, COMPLETED_IMPORT_DEFAULT_SEARCH, INVALID_IMPORT_DEFAULT_SEARCH } from '../constants/values'
import { getFilterLabel } from './GlobalSearchFilters'
import DoneIcon from '@mui/icons-material/Done'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'

/* styles for the below component */
const useStyles = makeStyles(theme => ({
	newContactContainer: {
		marginLeft: "1em",
		marginBottom: "1em"
	},
	item: {
		cursor: "pointer",
		marginBottom: "1em"
	},
}))


const SavedSearchesList = (props) => {

	// for special filters counts

	const lookup = useContext(LookupContext)

	const contactTypes= lookup.data?.getContactTypes
	const listingTypes= lookup.data?.getListingTypes
	const regionsList= lookup.data?.getRegionTypes
	const activities= lookup.data?.getActivityTypes
	const grades= lookup.data?.getGradeTypes
	const artStatus = lookup.data?.getArtStatuses
	const artCategory = lookup.data?.getArtCategories

	const classes = useStyles()

	/**
	 * This function uses the context and passed key to determine the correct
	 * display value for a subtitle of a saved search.
	 * @param {*} key
	 * @param {*} value
	 */

	 const getSavedSearchValue = (key, value) => {
		switch (key) {
		case 'search':
			return `“${value}”`
		case 'type':
			return capitalizeFirstLetter(lookup(contactTypes)(value))
		case 'listing_type':
			return lookupFunction(listingTypes)(value)
		case 'grade':
			return lookupFunction(grades)(value)
		case 'activity':
			return lookupFunction(activities)(value)
		case 'region':
			return lookupFunction(regionsList)(value)
		case 'status':
			return lookupFunction(artStatus)(value)
		case 'category':
			return lookupFunction(artCategory)(value)
		case 'startDate':
		case 'endDate':
		case 'start_at':
		case 'end_at':
		case 'last_modified_start':
		case 'last_modified_end':
			return format(new Date(value), 'MMMM do, yyyy').toString()
		default:
			return value
		}
	}

	const selectedStyle = {
		boxShadow: 'none',
		border: "1.5px solid hsl(220, 100%, 77%)",
	}

	/**
	 * A helper function to aid in the looking up of types of saved search items
	 * from context.
	 * @param {*} dict
	 */
	const lookupFunction = (dict) => (val) => {
		return dict ? dict[val - 1]?.value : null
	}

	/**
	 * This function creates a subtitle given a contacts search object
	 * @param {*} search the search result to create a subtitle for
	 */
	const getSubtitle = (search, fieldsOnly=false) => {

		if (fieldsOnly) {
			let subtitle = search.search_terms
				.filter(
					({ value }) =>
						value !== "" &&
						value !== "All" &&
						value &&
						!(Array.isArray(value) && !value.every((e) => e))
				)
				.map((st) => getFilterLabel(st.field, props.filterFields))
				.join(", ")
			return subtitle.length ? subtitle : 'All'
		}

		let subtitle = search.search_terms
			.filter(({ value }) => {
				return value !== '' && value !== 'All' && value && 
				!(Array.isArray(value) && !value.every(e => e))
			})
			.map(st => {
				let fieldOverride = null
				if (st.field === "startDate") fieldOverride = "Last Modified Start"
				if (st.field === "endDate") fieldOverride = "Last Modified End"
				return (fieldOverride || getFilterLabel(st.field, props.filterFields)) + ": " + (getSavedSearchValue(st.field, st.value) || st.value) + "\n"
			})

		return subtitle.length ? subtitle : 'All'
	}
	
	const oneWeekAgo = new Date()
	oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)

	return (
		<List className='entity-quick-view-main'>
			{!props.disableFavoriteSavedSearch && <Paper className={classes.item} style={props.currentSearch?.id == FAVORITES_DEFAULT_SEARCH ? selectedStyle : null} onClick={() => {

				const search_terms = [{
					id: new Date().getTime(),
					field: "isFavorite",
					type: "eq",
					value: "true"
				}]

				props.setCurrentSearch({
					...props.currentSearch,
					id: FAVORITES_DEFAULT_SEARCH,
					search_terms
				})

				props.setSelection([])
				if (props.setDealAutoValue) props.setDealAutoValue(null)

				props.handleSubmit({cursor: 0}, search_terms, FAVORITES_DEFAULT_SEARCH)
			}}>
				<ListItem
					style={{
						borderLeft: "1em solid #F8D937",
						borderRadius: "6px",
						zIndex: "1",
					}}
				>
					<ListItemIcon>
						<StarIcon style={{ fill: "#F8D937", fontSize: 30 }} />
					</ListItemIcon>
					<ListItemText
						primary='Favorites'
						secondary={"Favorite " + props.entity}
					/>
				</ListItem>
			</Paper>}

			<Paper className={classes.item} style={props.currentSearch?.id == RECENTLY_CREATED_DEFAULT_SEARCH ? selectedStyle : null} onClick={() => {

				const search_terms = [{
					id: new Date().getTime(),
					field: "created_at",
					type: "gte",
					value: oneWeekAgo
				}]

				props.setCurrentSearch({
					...props.currentSearch,
					id: RECENTLY_CREATED_DEFAULT_SEARCH,
					search_terms,
				})

				props.setSelection([])
				if (props.setDealAutoValue) props.setDealAutoValue(null)

				props.handleSubmit({cursor: 0}, search_terms, RECENTLY_CREATED_DEFAULT_SEARCH)
			}}>
				<ListItem
					style={{
						borderLeft: "1em solid #cc3333",
						borderRadius: "6px",
					}}
				>
					<ListItemIcon>
						<img
							height='28'
							width='28'
							alt='Recently Created'
							src='../../images/icons/Red/Add.svg'
						/>
					</ListItemIcon>
					<ListItemText
						primary='Recently Created'
						secondary='This Week'
					/>
				</ListItem>
			</Paper>

			{props.entity === "art" ? <Paper className={classes.item} style={props.currentSearch?.id == VERIFIED_ARTWORK_DEFAULT_SEARCH ? selectedStyle : null} onClick={() => {

				const search_terms = [{
					id: new Date().getTime(),
					field: "isVerified",
					type: "eq",
					value: "true"
				}]

				props.setCurrentSearch({
					...props.currentSearch,
					id: VERIFIED_ARTWORK_DEFAULT_SEARCH,
					search_terms
				})

				props.setSelection([])
				if (props.setDealAutoValue) props.setDealAutoValue(null)

				props.handleSubmit({cursor: 0}, search_terms, VERIFIED_ARTWORK_DEFAULT_SEARCH)
			}}>
				<ListItem
					style={{
						borderLeft: "1em solid hsl(226, 61%, 54%)",
						borderRadius: "6px",
					}}
				>
					<ListItemIcon>
						<img
							height='28'
							width='28'
							alt='Verified Artwork'
							src='../../images/icons/VerifiedIcons/VerifiedBlueCheck.svg'
						/>
					</ListItemIcon>
					<ListItemText
						primary='Verified Artwork'
						secondary='All Time'
					/>
				</ListItem>
			</Paper> : null}

			{/* Completed import default search */}
			{props.entity === "import" ? <Paper className={classes.item} style={props.currentSearch?.id == COMPLETED_IMPORT_DEFAULT_SEARCH ? selectedStyle : null} onClick={() => {

				const search_terms = [{
					id: new Date().getTime(),
					field: "import_status",
					type: "eq",
					value: "Completed"
				}]

				props.setCurrentSearch({
					...props.currentSearch,
					id: COMPLETED_IMPORT_DEFAULT_SEARCH,
					search_terms
				})

				props.setSelection([])
				if (props.setDealAutoValue) props.setDealAutoValue(null)

				props.handleSubmit({cursor: 0}, search_terms, COMPLETED_IMPORT_DEFAULT_SEARCH)
			}}>
				<ListItem
					style={{
						borderLeft: "1em solid hsl(122.82, 38%, 57%)",
						borderRadius: "6px",
					}}
				>
					<ListItemIcon>
						<DoneIcon style={{height: '28px', width: '28px'}} color="success"/>
					</ListItemIcon>
					<ListItemText
						primary='Completed Imports'
						secondary='All Time'
					/>
				</ListItem>
			</Paper> : null}

			{/* Invalid import default search */}
			{props.entity === "import" ? <Paper className={classes.item} style={props.currentSearch?.id == INVALID_IMPORT_DEFAULT_SEARCH ? selectedStyle : null} onClick={() => {

				const search_terms = [{
					id: new Date().getTime(),
					field: "import_status",
					type: "eq",
					value: "Invalid"
				}]

				props.setCurrentSearch({
					...props.currentSearch,
					id: INVALID_IMPORT_DEFAULT_SEARCH,
					search_terms
				})

				props.setSelection([])
				if (props.setDealAutoValue) props.setDealAutoValue(null)

				props.handleSubmit({cursor: 0}, search_terms, INVALID_IMPORT_DEFAULT_SEARCH)
			}}>
				<ListItem
					style={{
						borderLeft: "1em solid hsl(35.67, 100%, 57%)",
						borderRadius: "6px",
					}}
				>
					<ListItemIcon>
						<WarningAmberOutlinedIcon style={{height: '28px', width: '28px'}} color="warning"/>
					</ListItemIcon>
					<ListItemText
						primary='Invalid Imports'
						secondary='All Time'
					/>
				</ListItem>
			</Paper> : null}

			{props.savedSearches?.map((search, i) => {
				return (
					<div key={"search " + i}>
						<QuickViewItem
							selected={props.currentSearch?.id == search.id}
							search={search}
							updateLabel={props.updateLabel}
							handleDeleteSearch={props.handleDeleteSearch}
							handleLeftClick={() => {

								props.setSelection([])
								if (props.setDealAutoValue) props.setDealAutoValue(null)
								
								const mappedValues = search.search_terms.map(el => ({
									field: el.field,
									type: el.comparator,
									value: el.value
								}))

								props.setCurrentSearch({
									...search,
									search_terms: mappedValues
								})

								props.handleSubmit({cursor: 0}, mappedValues, search.id)
				
							}}
							color={search.color}
							title={search.label}
							subtitle={getSubtitle(search, true)}
							tooltip={getSubtitle(search)}
							result_count={search.result_count}
							count={i + 1}
						/>
					</div>
				)
			})}

			{
				// Display notice if no saved searches found
				props.savedSearches?.length === 0 ? (
					<Paper
						className={classes.item}
						style={{
							borderLeft: "1em solid grey",
							maxHeight: "5em",
							cursor: "default",
							borderRadius: "6px",
						}}
					>
						<ListItem>
							<ListItemIcon>
								<SaveIcon
									style={{ fill: "grey", fontSize: 30 }}
								/>
							</ListItemIcon>
							<ListItemText
								primary='Saved Searches'
								secondary='None Found'
							/>
						</ListItem>
					</Paper>
				) : null
			}

			{props.loading ? (
				<CircularProgress
					style={{ marginLeft: "auto", marginRight: "auto" }}
				/>
			) : null}
		</List>
	)
}

export default withApollo(SavedSearchesList)
