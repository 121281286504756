import withStyles from '@mui/styles/withStyles'
import * as Colors from '../../styles/colors/Colors'
import React, { ChangeEventHandler, FormEventHandler } from "react"
import { InputBase, InputBaseProps } from '@mui/material'

const TextBoxStyled = withStyles(theme => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(3),
		},
	},
	input: {
		borderRadius: 4,
		position: 'relative',
		border: '1px solid #ced4da',
		fontSize: 16,
		padding: '10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: 'Graphik',
		'&:focus': {
			borderRadius: 4,
			borderColor: Colors.editAdd,
		},
	},
	error: {
		border: '1px solid red',
		borderRadius: 4,
	}
}))(InputBase)

export interface TextBoxProps extends InputBaseProps {
	InputProps?: any
	focused?: boolean
	helperText?: string
	value?: string | null
	onChange?: ChangeEventHandler<HTMLTextAreaElement>
	multiline?: boolean
	placeholder?: string
	onClick?: FormEventHandler
}

const TextBox = React.forwardRef<any, TextBoxProps>((props, ref) => {

	const divProps: TextBoxProps = Object.assign({}, props)
	
	delete divProps.helperText
	delete divProps.InputProps
	delete divProps.focused
	
	return <TextBoxStyled ref={ref} {...divProps} />
})

export default TextBox
