/* eslint-disable eqeqeq */
import { useMutation, useApolloClient } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { ControlPoint, Download, OpenInNew } from '@mui/icons-material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { Button, FormControl, MenuItem, Select } from '@mui/material'
import sortBy from 'lodash/sortBy'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { randomHSL } from '../../common/helpers'
import { SavedSearch } from '../../common/SavedSearch'
import { IMPORT_SEARCH_OBJECT } from '../../constants/values'
import { GET_CONTACT_TEMPLATE, GET_ART_TEMPLATE } from '../Queries'
import { AdvancedToggleButton, ChooseImageButton, ResetButton, SaveButton, SearchButton } from '../../input/Buttons'
import Dropdown from '../../input/Dropdown/Dropdown'
import Label from '../../input/Label'
import TextBox from '../../input/Text/TextBoxDark'
import ConfirmationInput from '../../navigation/ConfirmationDialog/ConfirmationInput'
import AdvancedFilterCount from '../../Search/AdvancedFilterCount'
import { CREATE_SEARCH } from '../../Search/Queries'
import SearchInfo from '../../Search/SearchInfo'
import { handleSearchChange, saveMap, saveSearch, searchMap } from '../../Search/unifiedSearchHelpers'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { AuthStateContext, DispatchContext, LookupContext } from '../../store'
import { searchStyles, typeStyle } from '../../styles/makesStyles'

const SearchImports = (props) => {

	const userAuthentication = useContext(AuthStateContext)

	// Lookup data required for dropdowns
	const lookup = useContext(LookupContext)
	// Import Status
	const ImportStatus = lookup.data?.getImportStatuses
	const sortedImportStatus = sortBy(ImportStatus, [function (o) { return o.value }])
	// Import Type
	const ImportType = lookup.data?.getImportTypes
	const sortedImportType = sortBy(ImportType, [function (o) { return o.value }])

	const [artTemplate, setArtTemplate] = useState(null)
	const [contactTemplate, setContactTemplate] = useState(null)
	const client = useApolloClient()

	const dispatch = useContext(DispatchContext)
	const openQuickView = useCallback((severity, text) => {
		dispatch({ type: 'openQuickView' })
	}, [dispatch])

	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	/* =================================================================
	DOWNLOAD LINKS
	================================================================= */

	// Get the template links from the server
	useEffect(() => {
		client.query({
			query: GET_ART_TEMPLATE
		}).then(({ data }) => {
			setArtTemplate(data?.getArtTemplate?.imgUrl)
		})

		client.query({
			query: GET_CONTACT_TEMPLATE
		}).then(({ data }) => {
			setContactTemplate(data?.getContactTemplate?.imgUrl)
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	/* =================================================================
	SEARCH CONFIG
	================================================================= */

	const [currentSearch, setCurrentSearch] = React.useState(props.prevSearch ?? new SavedSearch({
		object_id: IMPORT_SEARCH_OBJECT,
		search_terms: [{
			field: 'is_active',
			type: 'eq',
			value: 'true'
		}],
		is_global: true,
		user_id: userAuthentication.user?.id
	}))
	const [savedSearchDialog, setSavedSearchDialog] = React.useState({
		open: false,
		search: null
	})

	const handleImportSavedSearchChange = (event, type = 'eq') => {

		const search_terms = handleSearchChange(
			event,
			currentSearch,
			setCurrentSearch,
			type,
			true
		)

		if (props.currentSearch.id) {
			props.setCurrentSearch({
				...currentSearch,
				search_terms,
				id: null
			})
		}
	}

	// Mutations
	const [createSearch] = useMutation(CREATE_SEARCH)

	const [firstLoad, setFirstLoad] = React.useState(true)
	React.useEffect(() => setFirstLoad(false), [])


	useEffect(() => {
		if (props.currentSearch) setCurrentSearch(props.currentSearch)
	}, [props.currentSearch])

	const getSearchValue = (fieldName) =>
		currentSearch.search_terms
			?.find(el => el.field == fieldName)?.value

	/* =================================================================
	Styling
	================================================================= */

	const classes = searchStyles()

	return (
		<>
			<h1 className="card-title">

				Admin Search Imports

				<ChooseImageButton
					data-testid="new-deal-button"
					variant="contained"
					size="small"
					style={{ backgroundColor: !props.loading ? '#4465D1' : null }}
					onClick={() => {
						openQuickView()
						props.setCreateEntity(true)
					}}
					endIcon={<ControlPoint />}
				>
					Create
				</ChooseImageButton>


				<Button variant="text" endIcon={<OpenInNew />} onClick={() => {
					window.open('/admin/imports/help#uploading-data', '_blank')
				}}>
					Instructions
				</Button>

				<Button variant="text" endIcon={<Download />} onClick={() => {
					if( artTemplate ) window.open(artTemplate)
					else openSnackbar(severity.ERROR, "Error opening the template, please refresh and try again.")
				}}>
					Art Template
				</Button>

				<Button variant="text" endIcon={<Download />} onClick={() => {
					if( contactTemplate ) window.open(contactTemplate)
					else openSnackbar(severity.ERROR, "Error opening the template, please refresh and try again.")
				}}>
					Contact Template
				</Button>

				<AdvancedFilterCount
					advancedFilters={props.advancedFilters}
					setAdvancedSearch={props.setAdvancedSearch}
				/>

				<SearchInfo
					advancedFilters={!!props.advancedFilters?.length}
					style={{ marginRight: 0 }}
					recordName="import"
					unified
					darkMode
				/>
			</h1>

			{/* IMPORT SIMPLE SEARCH FORM */}
			<form
				onSubmit={(e) => {
					e.preventDefault()
					props.setActiveStep(0)
					props.handleSubmit(
						{ cursor: 0 },
						currentSearch.search_terms,
						currentSearch.id
					)
				}}
			>
				<div className="row">
					{/* Import Type Selection */}
					<FormControl className={classes.searchSelection}>
						<Label
							id="grade-label"
							style={typeStyle}
							disableAnimation
							shrink
						>
							Type
						</Label>
						<Select
							IconComponent={ExpandMoreRoundedIcon}
							name="import_type" // String
							className="padded-select"

							data-testid="search-type"
							labelId="type-label"
							input={<Dropdown />}
							value={
								getSearchValue('import_type') || ''
							}
							onChange={(event) =>
								handleImportSavedSearchChange(event)
							}
						>
							<MenuItem value='' data-testid="search-type-item">All</MenuItem>
							{sortedImportType &&
								sortedImportType.map((type) => (
									<MenuItem
										key={type.id}
										value={type.value}
										data-testid="search-type-item"
									>
										{type.value}
									</MenuItem>
								))}
						</Select>
					</FormControl>


					{/* Import Status Selection */}
					<FormControl className={classes.searchSelection}>
						<Label
							id="grade-label"
							style={typeStyle}
							disableAnimation
							shrink
						>
							Status
						</Label>
						<Select
							IconComponent={ExpandMoreRoundedIcon}
							name="import_status" // String
							className="padded-select"

							data-testid="search-status"
							labelId="status-label"
							input={<Dropdown />}
							value={
								getSearchValue('import_status') || ''
							}
							onChange={(event) =>
								handleImportSavedSearchChange(event)
							}
						>
							<MenuItem value='' data-testid="search-status-item">All</MenuItem>
							{sortedImportStatus &&
								sortedImportStatus.map((type) => (
									<MenuItem
										key={type.id}
										value={type.value}
										data-testid="search-status-item"
									>
										{type.value}
									</MenuItem>
								))}
						</Select>
					</FormControl>

				</div>
				<div className="row">
					<FormControl style={{ flexGrow: '2' }}>
						<Label style={typeStyle} disableAnimation shrink>
							Search
						</Label>
						<TextBox
							name="query"
							inputRef={input => input && firstLoad && input.focus()}
							value={getSearchValue('query') || ''}
							onChange={(event) => handleImportSavedSearchChange(event)}
							data-testid="searchbar"
						/>
					</FormControl>
				</div>

				<div className="row" style={{ paddingTop: '1em' }}>
					<div>
						<SaveButton
							data-testid="save-button"
							variant="contained"
							size="small"
							onClick={() => {
								saveSearch(
									currentSearch,
									openSnackbar,
									severity,
									setSavedSearchDialog
								)
							}}
						>
							Save Search
						</SaveButton>
					</div>
					<AdvancedToggleButton
						data-testid="advanced-toggle-button"
						size="small"
						onClick={() => {
							props.setCurrentSearch(currentSearch)
							props.setAdvancedSearch(true)
						}}
						style={{ marginRight: '1em' }}
					>
						Advanced
					</AdvancedToggleButton>
					<ResetButton
						data-testid="reset-button"
						variant="contained"
						size="small"
						onClick={() => {
							props.setTotalItems(null)
							props.setSelection([])
							props.resetSearch('simple')
							setCurrentSearch(new SavedSearch({
								object_id: IMPORT_SEARCH_OBJECT,
								search_terms: [],
								is_global: true,
								user_id: userAuthentication.user?.id
							}))
						}}
						style={{ marginRight: '1em' }}
					>
						Reset
					</ResetButton>
					<SearchButton data-testid="search-button" variant="contained" size="small" type="submit" style={{ backgroundColor: !props.loading ? '#4465D1' : null }}
						disabled={props.loading}>
						Search
					</SearchButton>
				</div>
			</form>

			<ConfirmationInput
				open={savedSearchDialog.open}
				handleClose={() => setSavedSearchDialog({
					open: false,
					savedSearch: null
				})}
				title={'Save search?'}
				acceptText={'Save'}
				buttonColor="#33BF5C"
				inputLabel="Search Label"
				placeholder={'Saved Search ' + (props.savedSearches?.length + 1)}
				onYes={(text) => {

					createSearch({
						variables: {
							CreateSearchInput: {
								label: text ? text : 'Saved Search ' + (props.savedSearches?.length + 1),
								user_id: userAuthentication.user.id,
								object_id: IMPORT_SEARCH_OBJECT,
								color: randomHSL(),
								search_terms: searchMap(currentSearch.search_terms),
								result_count: null,
								is_global: true,
							},
						},
					}).then((response) => {

						setCurrentSearch({
							...response.data.createSavedSearch.search,
							search_terms: saveMap(response.data.createSavedSearch.search.search_terms)
						})

						props.setSavedSearches(props.savedSearches.concat(
							new SavedSearch(response.data.createSavedSearch.search))
						)
					})
				}}
			/>
		</>
	)
}

export default withApollo(SearchImports)
