/* eslint-disable eqeqeq */
import React, { useContext } from 'react'
import QuickView from '../../QuickView/QuickView'

import TextBox from '../../input/Text/TextBox'
import Label from '../../input/Label'
import Dropdown from '../../input/Dropdown/Dropdown'
import { FormControl, Select, MenuItem } from '@mui/material'
import { MobileDateTimePicker } from "@mui/x-date-pickers-pro"
import { SubmitButton, CancelButton } from '../../input/Buttons'

import { severity } from '../../Snackbar/CustomizedSnackbar'
import { DispatchContext, AuthStateContext } from '../../store'
import CircularProgress from '@mui/material/CircularProgress'
import { useMutation } from '@apollo/client'
import Autocomplete from '@mui/material/Autocomplete'

import TextField from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'

import { CREATE_ENTITY_TASK, } from '../Queries.js'
import { LookupContext } from '../../store'

import { GET_USERS } from '../../User/Queries'
import { withApollo } from '@apollo/client/react/hoc'

import './CreateTaskQuickView.css'
import getDefaultDueDate from '../EntityTasks/NewEntityTask'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { autoCompleteErrorStyles } from '../../styles/makesStyles'

const useStyles = makeStyles(() => ({
	searchDate: {
		marginTop: "0.5em",
	}
}))


function CreateTaskQuickView(props) {

	const userAuthentication = useContext(AuthStateContext)
	const lookup = useContext(LookupContext)
	const taskTypes= lookup.data?.getTaskTypes
	// const departments= lookup.data?.getDepartmentTypes
	
	const taskStatusTypes = lookup.data?.getTaskStatusTypes

	// User options
	const [options, setOptions] = React.useState(userAuthentication.user ? [userAuthentication.user] : [])
	const [selection, setSelection] = React.useState(userAuthentication.user ? [userAuthentication.user] : [])
	const [optionsDropdown, setOptionsDropdown] = React.useState(false)
	const optionsListLoading = optionsDropdown && options?.length === 1
		


	const typeStyle = {
		fontWeight: 500,
	}

	const classes = useStyles()
	const classes2 = autoCompleteErrorStyles()

	// Other useState hooks
	const [attempt, setAttempt] = React.useState(false)

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}
	const [createEntityTask, { loading }] = useMutation(CREATE_ENTITY_TASK)
	
	// Task
	const [task, setTask] = React.useState({
		assigned_to_type: "User",	
		assignees: [userAuthentication.user],
		due_at: null,
		type: 2,
		department: "None",
		status: 1,
		approval: false,
		description: "",
		
	})

	// Async Autocomplete
	React.useEffect(() => {
		if (!selection) {
			setSelection(options?.find(item => item.id == userAuthentication.user?.id))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options])

	React.useEffect(() => {
		if (options.length <= 1) {
			setOptions([userAuthentication.user])
			setSelection(userAuthentication.user ? [userAuthentication.user] : [])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userAuthentication.user])
	
	React.useEffect(() => {
		let active = true
		if (!optionsListLoading) {
			return undefined
		}
		props.client
			.query({ query: GET_USERS})
			.then(result => {
				if (active) {
					setOptions(result.data.getUsers.items)	
				}
			})
		return () => {
			active = false
		}
	}, [optionsListLoading, props.client])

	React.useEffect(() => {
		if (!optionsDropdown && options.length <= 1) {
			setOptions(userAuthentication.user ? [userAuthentication.user] : [])
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.user, optionsDropdown])


	React.useEffect(() => {
		let active = true

		if (!loading) {
			return undefined
		}
		props.client
			.query({
				query: GET_USERS,
			})
			.then((result) => {
				if (active) {
					setOptions(result.data.getUsers.items)
				}
			})

		return () => {
			active = false
		}
	}, [loading, props.client])


	return (
		<QuickView id="create-task-qv" className="quick-view-main" style={{
			overflow: 'auto'
		}}>
			<form
				className="create-task-form"
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
				}}
				onSubmit={(e) => {
					e.preventDefault()
					setAttempt(true)

					if ( attempt && (!task.description || !task.due_at || !task.assignees.length)) {
						openSnackbar(severity.WARNING,'Please fill fields in red.')

					} else if (!(!task.description || !task.due_at || !task.assignees.length)) {
						let variables = {
							type_id: task.type,
							description: task.description,
							assigned_by_user_id: userAuthentication.user.id,
							approval_required: false,
							due_at: task.due_at,
							is_deleted: false,
							task_status_id: task.status,
							is_completed: false,
							assigned_to_type: task.department === "None" ? "User" : "Department",
							assigned_to_user_ids: (task.department === "None") ? task.assignees.map(assignee => assignee.id) : null,
							assigned_to_department_id: (task.department !== "None") ? task.department : null,
							
							entity_id: 7,
							entity_name: "task_id"
						}
							
						createEntityTask({
							variables: {
								CreateTaskInput: variables,
							},
						})
							.then((res) => {

								setAttempt(false)
								if (res.data.createEntityTask.success) {
									setAttempt(false)
									openSnackbar(severity.SUCCESS, 'Task created successfully.')
									props.setCreateTask(null)
								} else {
									setAttempt(false)
									openSnackbar( severity.ERROR, 'Could not create task.')
								}							
							})
							.catch((error) => {
								console.log(error)
								openSnackbar( severity.ERROR, 'There was an error creating the task.')
							})
					}
				}}
			>
				<div className="quick-view-inner">
					<h2 className="card-title" style={{
						alignSelf: 'flex-start',
					}}>Create New Task</h2>

					<FormControl fullWidth>
						<Label id="type-label" style={typeStyle} disableAnimation shrink>
						Type
						</Label>
						<Select
							IconComponent={ExpandMoreRoundedIcon}
							name="type"
							
							labelId="type-label"
							className="padded-select"
							input={<Dropdown />}
							value={task.type}
							onChange={(e) => {

								if (e.target.value === 3) {
								// Immediate
									setTask({
										...task,
										type: e.target.value,
										due_at: getDefaultDueDate()
									})
								} else {
									setTask({
										...task,
										type: e.target.value
									})
								}	
							}}
							data-testid="task-type"
						>
							{taskTypes && taskTypes.map(type => (
								<MenuItem 
									key={type.id} 
									value={type.id}
									data-testid="task-type-item"
								>
									{type.value}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					{/* TODO Phase II
				<FormControl>
					<Label id="department-label" style={typeStyle} disableAnimation shrink>
							Department
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						disabled
						className="padded-select"
						name="department"
						
						labelId="department-label"
						input={<Dropdown />}
						value={task.department}
						onChange={(e) => {

							setTask({
								...task,
								department: e.target.value,
								assignees: []
							})
							
						}}
					>
						<MenuItem key={"None"} value={"None"}>None</MenuItem>
						{departments && departments.map(type => (
							<MenuItem key={type.id} value={type.id}>{type.value}</MenuItem>
						))}
					</Select>
				</FormControl>

				*/}

					<FormControl fullWidth>
						<Label id="status-label" style={typeStyle} disableAnimation shrink>
						Status
						</Label>
						<Select
							IconComponent={ExpandMoreRoundedIcon}
							name="status"
							
							className="padded-select"
							labelId="status-label"
							input={<Dropdown />}
							value={task.status}
							onChange={(e) => {
								setTask({
									...task,
									status: e.target.value
								})
							}}
							data-testid="task-status"
						>

							{taskStatusTypes && taskStatusTypes.map(type => (
								<MenuItem 
									key={type.id} 
									value={type.id}
									data-testid="task-status-item"
								>
									{type.value}
								</MenuItem>
							))}
						</Select>
					</FormControl>

		
					<FormControl fullWidth>
						<Label
							id="date-label"
							style={typeStyle}
							disableAnimation
							shrink
							error={attempt && !task.due_at}
						>Due Date
						</Label>

						<MobileDateTimePicker
							inputFormat="MMMM do, yyyy 'at' h:mm aaa"
							disableMaskedInput={true}
							cancelText={null}
							disablePast
							componentsProps={{
								actionBar: {
									actions: ['today', 'clear', 'accept']
								}
							}}
							todayLabel="Now"
							error={attempt && !task.due_at}
							name="date"
							inputVariant="outlined"
							style={{ marginTop: '17px'}}
							className="MuiSelect-select-date"
							variant="dialog"
							value={task.due_at}
							renderInput={({ inputRef, inputProps, InputProps }) => {
								
								const newProps = { ...inputProps}
	
								newProps.readOnly = false
	
								return (
									<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
								)}}
							onChange={date => {
								setTask({
									...task,
									due_at: date
								})
							}}
							data-testid="task-duedate"
						/>
					</FormControl>

					<FormControl fullWidth>
						<Label
							id="user-label"
							style={typeStyle}
							disableAnimation
							shrink
							error={attempt && task.assignees.length === 0 && task.department === "None"}
						>
						Assignees
						</Label>
						<Autocomplete
							multiple
							classes={classes2}
							disabled={task.department !== "None"}
							style={{marginTop: "1.75em"}}
							open={optionsDropdown}
							isOptionEqualToValue={(option, value) => {
								return option.id == value.id
							}}
							forcePopupIcon
							filterSelectedOptions
							popupIcon={<ExpandMoreRoundedIcon />}
							size="small"
							value={selection ?? []}
							onOpen={() => { setOptionsDropdown(true) }}
							onClose={() => { setOptionsDropdown(false)}}
							getOptionLabel={option => {
								try {
									return option.first_name + ' ' + option.last_name
								} catch {
									return "Loading..."
								}
							}}
							options={options || []}
							loading={optionsListLoading}
							onChange={(event, value) => {
								setSelection(value)
								setTask({
									...task,
									assignees: value
								})
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									error={attempt && task.assignees.length === 0 && task.department === "None"}
									variant="outlined"
									fullWidth
									disabled={task.department !== "None"}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{optionsListLoading ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>
							)}
							data-testid="task-assignees"
						/>
					</FormControl>


					<FormControl className={classes.searchBar} fullWidth>
						<Label style={typeStyle} disableAnimation shrink 
							error={attempt && task.description.length === 0}
						>
						Task
						</Label>
						<TextBox id="new-task-text" multiline 
							error={attempt && task.description.length === 0}
							value={task.description}
							onChange={(e) => {
								setTask({
									...task, 
									description: e.target.value
								})
							}}
							data-testid="task-text"
						/>
					</FormControl>
				
				</div>

				<div
					style={{
						paddingTop: '1em',
						marginBottom: '1em',
						marginLeft: '1em',
						marginRight: '1em',
						marginTop: 'auto',
					}}
				>
					<CancelButton
						variant="contained"
						style={{ float: 'left' }}
						onClick={(e) => {
							props.setCreateTask(null)
							setAttempt(false)
						}}
						data-testid="task-cancel"
					>
						Cancel
					</CancelButton>

					<SubmitButton
						variant="contained"
						style={{ float: 'right' }}
						type="submit"
						date-testid="task-submit"
						disabled={loading}
					>
						Create
					</SubmitButton>
				</div>
			</form>
		</QuickView>
	)
}

export default withApollo(CreateTaskQuickView)
