import { gql } from '@apollo/client'
import { fileData } from '../common/fragments'

export const GET_FILE_GROUPS = gql`
	query getFileGroups($id: ID!) {
		getFileGroups(id: $id) {
			id
			value
		}
	}
`

export const GET_FILE_CATEGORIES = gql`
	query getFileCategories($id: ID!) {
		getFileCategories(id: $id) {
			id
			value
		}
	}
`

export const UPDATE_CONTACT_FILE = gql`
	mutation updateContactFile(
		$FileInput: FileInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateContactFile(input: $FileInput) {
			code
			success
			message
			file {
				...fileData
			}
		}
	}
	${fileData}
`

export const UPDATE_ART_FILE = gql`
	mutation updateArtFile(
		$FileInput: FileInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateArtFile(input: $FileInput) {
			code
			success
			message
			file {
				...fileData
			}
		}
	}
	${fileData}
`

export const UPDATE_ARTIST_FILE = gql`
	mutation updateArtistFile(
		$FileInput: FileInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateArtistFile(input: $FileInput) {
			code
			success
			message
			file {
				...fileData
			}
		}
	}
	${fileData}
`

export const UPDATE_DEAL_FILE = gql`
	mutation updateDealFile(
		$FileInput: FileInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateDealFile(input: $FileInput) {
			code
			success
			message
			file {
				...fileData
			}
		}
	}
	${fileData}
`

export const UPDATE_LISTING_FILE = gql`
	mutation updateListingFile(
		$FileInput: FileInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateListingFile(input: $FileInput) {
			code
			success
			message
			file {
				...fileData
			}
		}
	}
	${fileData}
`

export const GET_DOWNLOAD_LINK = gql`
	query getDownloadLink($id: ID!) {
		getDownloadLink(id: $id)
	}
`

export const GET_PREVIEW_LINK = gql`
	query getPreviewLink($id: ID!) {
		getPreviewLink(id: $id)
	}
`
