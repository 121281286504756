import { gql } from '@apollo/client'
import { userDataCompressed } from '../../../common/fragments'

export const GET_LISTING_CONTACTS = gql`
	query GetListingContacts(
		$field: String
		$id: ID
		$direction: SortDirection
		$searchString: String
		$limit: Int
		$listingContactStatus: String
		$galleryContacts: [ID]
		$cursor: Int
		$thumbnailResolution: String = "128x128"
	) {
		getListingContacts(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: {
					id: $id
					searchString: $searchString
					listingContactStatus: $listingContactStatus
					galleryContacts: $galleryContacts
				}
			}
		) {
			items {
				id
				status_id
				note
				# Custom resolvers
				interaction_salespersons {
					...userDataCompressed
					is_deleted
					is_lead
				}
				contact {
					id
					preferred_name
					company_name
					first_name
					last_name
					is_company
					code_name
					is_private
					is_deceased
					isFavorite
					isFlagged
					status {
						id
						value
					}
					phone {
						id
						type_id
						number
						is_deleted
						is_primary
						label
						extension
					}
					regions {
						id
						value
					}
					imgUrl (thumbnailResolution: "128x128")
					dynamic_generated_codename
					created_at
				}
				status_val
			}
			cursor
			totalItems
		}
	}
	${userDataCompressed}
`

export const GET_ALL_LISTING_CONTACTS = gql`
	query GetListingContacts( $id: ID ) {
		getListingContacts(
			input: {
				field: "id"
				direction: ASC
				limit: 0
				cursor: 0
				filters: { 
					id: $id
					listingContactStatus: ""
					searchString: ""
				}
			}
		) {
			items { 
				contact { id } 
			}
		}
	}
`

export const CREATE_LISTING_CONTACT = gql`
	mutation createListingContact(
		$contact_id: ID
		$listing_id: ID
		$thumbnailResolution: String = "128x128"
	) {
		createListingContact(
			input: {
				contact_id: $contact_id
				listing_id: $listing_id
			}
		) {
			id
			status_id
			note
			# Custom resolvers
			interaction_salespersons {
				...userDataCompressed
				is_deleted
				is_lead
			}
			contact {
				id
				preferred_name
				first_name
				last_name
				is_company
				company_name
				is_private
				code_name
				is_deceased
				status {
					id
					value
				}
				phone {
					id
					type_id
					number
					is_deleted
					is_primary
					label
					extension
				}
				regions {
					id
					value
				}
				dynamic_generated_codename
				created_at
			}
			status_val
		}
	}
	${userDataCompressed}
`

export const UPDATE_LISTING_CONTACT = gql`
	mutation updateListingContact(
		$id: ID
		$contact_id: ID
		$listing_id: ID
		$status_id: ID
		$note: JSONB
		$thumbnailResolution: String = "128x128"
	) {
		updateListingContact(
			input: {
				id: $id
				contact_id: $contact_id
				listing_id: $listing_id
				status_id: $status_id
				note: $note
			}
		) {
			id
			status_id
			note
			# Custom resolvers
			interaction_salespersons {
				...userDataCompressed
				is_deleted
				is_lead
			}
			contact {
				id
				preferred_name
				company_name
				first_name
				last_name
				is_company
				code_name
				is_private
				is_deceased
				status {
					id
					value
				}
				phone {
					id
					type_id
					number
					is_deleted
					is_primary
					label
					extension
				}
				regions {
					id
					value
				}
				dynamic_generated_codename
				created_at
			}
			status_val
		}
	}
	${userDataCompressed}
`

export const DELETE_LISTING_CONTACT = gql`
	mutation deleteListingContact($id: ID) {
		deleteListingContact(id: $id) {
			code
			success
			message
		}
	}
`

export const GET_POSSIBLE_LISTING_CONTACTS = gql`
	query getContacts(
		$listing_id: ID
		$query: String
		$thumbnailResolution: String = "128x128"
	) {
		getPossibleListingContacts(listing_id: $listing_id, query: $query) {
			id
			company_name
			first_name
			last_name
			is_company
			is_private
			created_at
			code_name
			imgUrl (thumbnailResolution: $thumbnailResolution)
			disabled
			is_deceased
			dynamic_generated_codename
		}
	}
`
