import { ButtonBase } from '@mui/material'
import { GalleryContactList } from './components/GalleryContactList'

const GalleryContactEditMode = (props) => {
	const { setSalespersonModal, salespersons, canViewSP, editable } = props

	return (
		<ButtonBase
			disableRipple={!editable}
			style={{
				marginTop: '2em',
				border: editable ? '1px solid hsl(210deg 14% 83%)' : null,
				padding: editable ? '5px 20px' : '0px 15px',
				borderRadius: editable ? '4px' : null,
				cursor: editable ? 'pointer' : 'default',
				display: 'flex',
   				justifyContent: 'flex-start',
			}}
			onClick={() => {
				if (editable && canViewSP) {
					setSalespersonModal(true)
				}
			}}
		>
			<GalleryContactList
				salespersons={salespersons}
				findMode={false}
				max={5}
			/>
		</ButtonBase>
	)
}

export default GalleryContactEditMode
