import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import { AvatarGroup } from '@mui/material'

/**
 * CustomAvatarGroup
 * This is a reproduction of the AvatarGroup component, adding a tooltip to the
 * "+x" at the end, if it overflows max.
 */
export default function CustomAvatarGroup (props) {

	const { children, max, ...otherProps } = props
	const clampedMax = max < 2 ? 2 : max

	const extraAvatars = children?.length > clampedMax ? children?.length - clampedMax + 1 : 0
	const newChildren = children?.slice(0, children?.length - extraAvatars)

	return <AvatarGroup max={max} {...otherProps} >
		{newChildren}
		{extraAvatars ?
			<Tooltip title={props.extraavatarstooltiptitle} arrow>
				<Avatar>+{extraAvatars}</Avatar>
			</Tooltip> : null}
	</AvatarGroup>
}
