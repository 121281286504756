import React, { useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { CancelButton, SubmitButton } from '../../input/Buttons'
import { FormControl } from '@mui/material'
import TextBox from '../../input/Text/TextBox'
import Label from '../../input/Label'

export default function ConfirmationInput(props) {

	const [open, setOpen] = React.useState(props.open)
	const [text, setText] = React.useState('')

	useEffect(() => {
		setOpen(props.open)
	}, [props.open])

	const handleClose = () => {
		props.handleClose()
		setText('')
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<form onSubmit={(e) => {
				e.preventDefault()
				e.stopPropagation()
			}}>
				<DialogTitle id="alert-dialog-title" style={{paddingBottom: '4px'}}>{props.title}</DialogTitle>
				<DialogContent style={{minWidth: "25em", maxWidth: "30em"}}>
					{props.text ? <p style={{fontSize: 16}}>
						{props.text}
					</p> : null}
					<FormControl className="fullWidth">
						{!props.noLabel ? 
							<Label disableAnimation shrink >
								{props.inputLabel || 'Input'}
							</Label>
							: null}
						<TextBox
							disabled={props.disabled}
							autoFocus
							value={text}
							onChange={(e) => {
								setText(e.target.value)
							}}
							placeholder={props.placeholder || null}
						/>		
					</FormControl>
				</DialogContent>
				<DialogActions style={{justifyContent: "space-between", margin: "0 1em", paddingBottom: "1.5em"}}>
					<CancelButton
						onClick={handleClose}
						variant="contained"
						disabled={props.disabled}
					>
						Cancel
					</CancelButton>
					<SubmitButton type="submit" 
						style={props.buttonColor ? {backgroundColor: props.buttonColor} : null}
						disabled={props.disabled} 
						onClick={() => {
							if (props.disableOnNonMatch && text !== props.match) 
								return
							props.onYes(text)
							handleClose()
						}}
						variant="contained">
						{props.acceptText ? props.acceptText : "Yes"}
					</SubmitButton>
				</DialogActions>
			</form>
		</Dialog>
	)
}
