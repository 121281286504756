import React from "react"
import TransitionsModal from "../navigation/TransitionsModal/TransitionsModal"

const GlobalSearchHelpModal = (props) => {

	const codeStyle = props.adminConsole ? {
		backgroundColor: 'lightgray',
		borderRadius: 4,
		padding: 2,
		color: 'black'
	} : {
		backgroundColor: 'lightgray',
		borderRadius: 4,
		padding: 2,
	}

	const headerStyle = {
		margin: 0,
		fontWeight: 500
	}

	return (
		<TransitionsModal open={props.open} close={props.close}>
			<div className={props.adminConsole ? 'private-object' : null}
				style={{
					display: 'flex',
					flexDirection: 'column',
					overflow: 'hidden'
				}}
			>
				<h1 className="card-title"
					style={{
						marginTop: 0,
						backgroundColor: 'white'
					}}
				>
					<span style={{ marginTop: 4 }}>Global Search Help</span>
				</h1>

				<div style={{
					display: 'flex',
					flexDirection: 'row',
					overflow: 'hidden'
				}}>

					<div style={{
						paddingRight: '1em',
						borderRight: '1px solid #d3d3d3',
						maxWidth: '35em',
						overflowY: 'scroll',
					}}>
						<h3 style={headerStyle}>
							Operators
						</h3>

						<p>
						Operators accept input from the user in designated formats:
						</p>

						<ul style={{
							color: props.adminConsole ? 'white' : null
						}}>
							<li>
								Contains (Open Text Field)
							</li>
							<li>
								Contains Any of These Words (Open Text Field)
							</li>
							<li>
								Does Not Contain (Open Text Field)
							</li>
							<li>
								Doesn't Contain Any of These Words (Open Text Field)
							</li>
							<li>
								Equals (Open Text Field/Selection)
							</li>
							<li>
								Does Not Equal (Open Text Field)
							</li>
							<li>
								Starts With (Open Text Field)
							</li>
							<li>
								Doesn't Start With (Open Text Field)
							</li>
							<li>
								Ends With (Open Text Field)
							</li>
							<li>
								Doesn't End Start With (Open Text Field)
							</li>
							<li>
								Like (Open Text Field)
							</li>
							<li>
								Not Like (Open Text Field)
							</li>
							<li>
								Is Blank (No Input)
							</li>
							<li>
								Not Blank (No Input)
							</li>
							<li>
								Less Than or Equal To (Number)
							</li>
							<li>
								Greater Than or Equal To (Number)
							</li>
						</ul>

						<h3 style={headerStyle}>
							Field Types
						</h3>

						<p>
						The type of field will determine the UI/UX of a global search input. That is, what term can be sent to the server for a specific filter:
						</p>

						<ul style={{
							color: props.adminConsole ? 'white' : null
						}}>
							<li>
								String (Open Text Field)
							</li>
							<li>
								Integer (Number)
							</li>
							<li>
								Boolean (True/False)
							</li>
							<li>
								Range (Decimal)
							</li>
							<li>
								Metric (Decimal)
							</li>
							<li>
								Note (Open Text Field)
							</li>
						</ul>

						<h3 style={headerStyle}>
							Search Types & Special Characters
						</h3>

						<p>
						The <code style={codeStyle}>Contains</code> and <code style={codeStyle}>Does Not Contain</code> operators can be supplied with search types and special characters similar to quick search:
						</p>

						<ul className="search-help-list" style={{
							color: props.adminConsole ? 'white' : null
						}}>
							<li>
								<code style={codeStyle}>AND</code> - <i>default</i> - The sum of all fields must contain <i>all</i> supplied terms. 
							</li>
							<li>
								<code style={codeStyle}>OR</code>  - One of the fields must contain <i>one</i> of the supplied terms. 
							</li>
							<li>
								<code style={codeStyle}>NOT</code> - All of the fields must not contain <i>all</i> negated terms. 
							</li>
						</ul>

						<ul className="search-help-list" style={{
							color: props.adminConsole ? 'white' : null
						}}>
							<li>
								<code style={codeStyle}>*</code>, <code style={codeStyle}>?</code> <b>Wildcard</b> searches can be run on individual terms, using ? to replace a single character, and * to replace zero or more characters.
							</li>
				
							<li>
								<code style={codeStyle}>~</code> <b>Fuzziness</b>, we can search for terms that are similar to, but not exactly like our search terms.
							</li>
							<li>
								<code style={codeStyle}>""</code> <b>Double Quotes</b> can query for all the words in the search in the same order.
							</li>
						</ul>
					</div>
					<div style={{
						paddingLeft: '1em',
						overflow: 'scroll'
					}}>
						<h3 style={headerStyle}>
							Searchable Fields
						</h3>
						<table style={{marginTop: '1em'}}>
							<thead style={{
								color: props.adminConsole ? 'white' : null
							}}>
								<tr>
									<th style={{ fontWeight: 500 }}>
										Field Name
									</th>
									<th style={{
										marginRight: '2.4em',
										fontWeight: 500
									}}>Type</th>
								</tr>
							</thead>
							<tbody  style={{
								color: props.adminConsole ? 'white' : null
							}}>
								{[...(props.fields || [])]?.map((field, index) => {
									return (
										<tr key={`${field.name}-${index}`}>
											<td>
												{`${field.label}`}
											</td>
											<td>
												{`${field.type}`}
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</TransitionsModal>
	)
}

export default GlobalSearchHelpModal
