import React, { useContext, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { AuthStateContext, DispatchContext } from '../../store'
import ObjectTags from '../../Tags/ObjectTags'
import { UPDATE_ART } from '../Queries'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import ArtCaption from './ArtCaption'
import ArtDetail from './ArtDetail'
import ResearchCard from '../../common/components/ResearchCard'
import ImagesCard from '../../input/Image/ImagesCard'
import { stripMeta } from '../../common/helpers'
import { permissions, permissionValues } from '../../constants/permissions'

function ArtDetailPage(props) {
	let { loading, error } = props
	let art = props.state.getArtPiece || {}

	let setArt = (art) => props.setState({ getArtPiece: art })

	const authState = useContext(AuthStateContext)
	const userPermissions = authState?.user?.permissions
	// eslint-disable-next-line eqeqeq
	const tagPermission = userPermissions?.find((e) => e.permission_id == permissions.ART_TAGS)
		.permission_value_id

	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const [updateArt, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_ART, {
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, 'Could not update.')
		},
		onCompleted: (response) => {
			if (response.updateArtPiece?.success === true) {
				openSnackbar(severity[response.updateArtPiece.severity] || severity.SUCCESS, response.updateArtPiece.message)
				setArt(response.updateArtPiece.art)
			} else {
				console.error(response)
				// On failure, reset artInput state, don't touch art
				// state and show error
				openSnackbar(severity.ERROR, response.updateArtPiece?.message || 'Could not update.')
				if (response.updateArtPiece?.art)
					setArt(response.updateArtPiece.art)
			}
		},
	})

	const update = (fields) => {
		let UpdateArtInput = stripMeta(fields)
		delete UpdateArtInput.location
		delete UpdateArtInput.sub_location 
		delete UpdateArtInput.location_date
		return updateArt({
			variables: {
				UpdateArtInput: {
					id: art.id,
					...UpdateArtInput
				}
			},
		})
	}
	return (
		<section className="main-page">
			<div className="tabbed-content">
				<div className="page-row">
					<ArtCaption
						loading={loading}
						error={error || updateError}
						art={art}
						disabled={updateLoading}
						onSave={update}
						private={art?.is_private || false}

						findMode={props.findMode}
					></ArtCaption>
					<ArtDetail
						loading={loading}
						error={error || updateError}
						art={art}
						disabled={updateLoading}
						onSave={update}
						findMode={props.findMode}
					></ArtDetail>
				</div>

				{ !props.findMode && <ImagesCard
					loading={loading}
					error={error || updateError}
					entity={art}
					setEntity={setArt}
					onSave={update}
					defaultImageType={1}
					private={art?.is_private || false}
					page="Art"
				></ImagesCard>}
				<div className="page-row" style={{marginLeft: '1em'}}>
					<ResearchCard 
						title="Provenance"
						entityKey="provenance"
						linkField="art_id"
						researchTypeId={1}
						researchTypeField="art_research_type_id"
						loading={loading}
						error={error}
						entity={art}
						update={update}
						disabled={updateLoading}
						data-testid="card-provenance"
						hangingIndent
						showcase={!props.findMode}
						private={art?.is_private || false}
						findMode={props.findMode}
					/>
					<ResearchCard
						title="Exhibition"
						entityKey="exhibition"
						linkField="art_id"
						researchTypeId={2}
						researchTypeField="art_research_type_id"
						loading={loading}
						error={error}
						entity={art}
						update={update}
						disabled={updateLoading}
						data-testid="card-exhibition"
						hangingIndent
						showcase={!props.findMode}
						private={art?.is_private || false}
						findMode={props.findMode}
					/>
				</div>
				<div className="page-row" style={{marginLeft: '1em'}}>
					<ResearchCard 
						title="Literature"
						entityKey="literature"
						linkField="art_id"
						researchTypeId={3}
						researchTypeField="art_research_type_id"
						loading={loading}
						error={error}
						entity={art}
						update={update}
						disabled={updateLoading}
						data-testid="card-literature"
						hangingIndent
						showcase={!props.findMode}
						private={art?.is_private || false}
						findMode={props.findMode}
					/>
					<ResearchCard 
						title="Text"
						entityKey="other"
						linkField="art_id"
						researchTypeId={4}
						researchTypeField="art_research_type_id"
						loading={loading}
						error={error}
						entity={art}
						update={update}
						disabled={updateLoading}
						data-testid="card-other"
						showcase={!props.findMode}
						private={art?.is_private || false}
						findMode={props.findMode}
					/>
				</div>
				<ObjectTags
					loading={loading}
					error={error || updateError}
					entity={art}
					setEntity={setArt}
					tagState={props.tagState}
					setTagState={props.setTagState}
					newTagModal={props.newTagModal}
					setNewTagModal={props.setNewTagModal}
					setQVOverride={ props.setQVOverride }
					entityType="Art"
					tagPermission={permissions.ART_TAGS}
					readOnly={tagPermission <= permissionValues.VIEW_ONLY}
					findMode={props.findMode}
				></ObjectTags>
			</div>
		</section>
	)
}

export default ArtDetailPage
