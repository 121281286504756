import GalleryContactList from '../common/components/GalleryContactList'

/**
 * A component to show salespersons in an entity qv.
 * Used on all entity quickviews except deals and contacts.
 * @param {*} props
 */
const QuickViewContacts = (props) => {
	const { salespersons, loading, findMode } = props

	return (
		<div className='w-full flex flex-col gap-2'>
			<div className='text-sm text-grey'>Gallery Contacts</div>
			<div className='flex flex-wrap ml-[8px]'>
				<GalleryContactList
					salespersons={salespersons}
					loading={loading}
					findMode={findMode}
					chunk={5}
				/>
			</div>
		</div>
	)
}

export default QuickViewContacts
