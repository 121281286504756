import React, { useContext, useState } from 'react'
import { OfferPrice } from './OfferPrice'
import { CancelButton, SaveButton } from '../../input/Buttons'
import { LookupContext } from '../../store'

export default function PriceModal (props) {

	const lookup = useContext(LookupContext)

	const [values, setValues] = useState({
		currency: props.currency || '1',
		amount: props.amount || 0
	})

	return (<>
		<h1 className="card-title" style={{marginBottom: '1em'}}>
			{ props.name }
		</h1>
			
		<OfferPrice
			name={' '}
			hideLabel
			currency={`${values.currency}`}
			price={values.amount}
			onChange={(field, value) => {
				field = field === 'offer_currency' ? 'currency' : 'amount'
				setValues({
					...values,
					[field]: value
				})
			}}
			currencies={lookup.data.getCurrencies}
		/>


		<div style={{ 
			display:'flex', 
			justifyContent: 'space-between',
			marginTop: '2em'
		}}>
			<CancelButton variant="contained" size="small"
				onClick={props.close}
			>
					Cancel
			</CancelButton>
			<SaveButton variant="contained" 
				disabled={props.disabled}
				color="secondary" 
				size="small"
				onClick={() => {
					props.close()
					props.save({
						amount: values.amount === "" ? "0" : values.amount,
						currency: values.currency,
					})
				}}
			>
                Save
			</SaveButton>
		</div>
		
	</>
	)
}
