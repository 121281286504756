/* eslint-disable eqeqeq */
import React, { useEffect } from 'react'
import { FormControl, Select, MenuItem, Button, TextField } from '@mui/material'
import TransitionsModal from './../navigation/TransitionsModal/TransitionsModal'
import { DispatchContext } from './../store'
import { red } from '@mui/material/colors'
import {
	SearchButton,
	CancelButton,
	ChooseImageButton,
} from './../input/Buttons'
import { severity } from './../Snackbar/CustomizedSnackbar'
import { useMutation } from '@apollo/client'
import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers'
import withStyles from '@mui/styles/withStyles'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import Dropdown from './../input/Dropdown/Dropdown'
import FormControlLabel from '@mui/material/FormControlLabel'
import Label from './../input/Label'
import * as Colors from './../styles/colors/Colors'
import TextBox from './../input/Text/TextBox'
import makeStyles from '@mui/styles/makeStyles'
import { LookupContext } from './../store'
import { GET_USERS } from '../User/Queries'
import CircularProgress from '@mui/material/CircularProgress'
import { withApollo } from '@apollo/client/react/hoc'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { stripMeta, returnDate } from '../common/helpers'
import { uploadImages } from '../common/upload'
import DeleteIcon from '@mui/icons-material/Delete'
import sortBy from 'lodash/sortBy'
import { DEFAULT_NOTE_IMAGE_TYPE, DEFAULT_IMAGE_USAGE_RIGHT } from './ObjectNotes/constants'

const useStyles = makeStyles(() => ({
	image: {
		borderWidth: 'small',
		borderStyle: 'solid',
		borderColor: 'transparent',
	},
	selected: {
		borderWidth: 'small',
		borderStyle: 'solid',
		borderColor: Colors.editAdd,
	},
}))

const useStyles2 = makeStyles((theme) => ({
	inputRoot: {
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#ced4da',
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: '#ced4da',
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: Colors.editAdd,
			borderWidth: '1px',
		},
	},
}))

const CriticalCheckbox = withStyles({
	root: {
		'&$checked': {
			color: red[500],
		},
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />)

const EditNotes = (props) => {
	const lookup = React.useContext(LookupContext)
	const noteTypes = lookup.data?.getNoteTypes

	// Snackbar
	const dispatch = React.useContext(DispatchContext)
	const openSnackbar = React.useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch],
	)

	const closeSnackbar = () => {
		dispatch({ type: 'closeSnackBar' })
	}

	const typeStyle = {
		fontWeight: 500,
	}


	const classes = useStyles()
	const classes2 = useStyles2()

	const [updateNote] = useMutation(props.updateMutationName)
	const [selectedImage, setSelectedImage] = React.useState()
	// Modal
	const [attempt, setAttempt] = React.useState(false)

	// Deactivated User
	useEffect(() => {
		if (props.editNoteModal.open && props.editNoteModal?.note?.user?.is_deleted)
			openSnackbar(
				severity.WARNING,
				'This note is assigned to a deactivated user.',
			)
	}, [openSnackbar, props.editNoteModal])

	// Note edit options
	const [options, setOptions] = React.useState([])

	const [
		noteListAutocompleteOpen,
		setNoteListAutocompleteOpen,
	] = React.useState(false)

	const noteListLoading = noteListAutocompleteOpen && options?.length === 0

	React.useEffect(() => {
		let active = true
		if (!noteListLoading) {
			return undefined
		}
		if (active) {
			props.client.query({ query: GET_USERS }).then((result) => {
				if (active) {
					setOptions(result.data.getUsers.items)
				}
			})
		}
		return () => {
			active = false
		}
	}, [noteListLoading, props.client])

	// Upload image.
	let handleUploadClick = (event) =>
		uploadImages(props.client.query, event, (newImage) => {
			props.setEditNoteModal((editNoteModal) => ({
				...editNoteModal,
				note: {
					...editNoteModal.note,
					images: (editNoteModal.note.images || []).concat({
						...newImage,
						type_id: DEFAULT_NOTE_IMAGE_TYPE,
						usage_right_id: DEFAULT_IMAGE_USAGE_RIGHT,
					}),
				},
			}))
		}).catch((error) => {
			console.error(error)
			openSnackbar(severity.ERROR, 'Error during upload.')
		})

	const noImages =
		!props.loading &&
		!props?.editNoteModal?.note?.images?.filter((i) => !i.delete).length

	return <>
		<TransitionsModal
			className="edit-note-modal"
			open={props.editNoteModal.open}
			close={() =>
				props.setEditNoteModal({ ...props.editNoteModal, open: false })
			}
		>
			<form
				style={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					overflowY: 'scroll',
					overflowX: 'hidden'
				}}
				onSubmit={(e) => {
					e.preventDefault()
					setAttempt(true)

					if (
						attempt &&
                        (!props.editNoteModal.note?.note_text ||
                            props.editNoteModal.note?.note_text?.length === 0 ||
                            !props.editNoteModal.note?.note_text?.replace(/\s/g, '').length)
					) {
						openSnackbar(severity.WARNING, 'Please complete required fields.')
					} else if (
						!(
							!props.editNoteModal.note?.note_text ||
                            props.editNoteModal.note?.note_text?.length === 0 ||
                            !props.editNoteModal.note?.note_text?.replace(/\s/g, '').length
						)
					) {
						let variables = stripMeta(props.editNoteModal.note)

						const updatedVariables = {
							applied_at: returnDate(variables.applied_at),
							applied_user_id: variables.applied_user_id,
							id: variables.id,
							is_critical: variables.is_critical,
							note_text: variables.note_text,
							type_id: variables.type_id,
							images: variables.images.map((i) => {
								const { src, imgUrl, ...image } = i
								return image
							}),
							is_private: variables.is_private
						}
						updateNote({
							variables: {
								UpdateNoteInput: updatedVariables,
							},
						}).then((response) => {

							if (response?.data[props.mutationResponse]?.success === false) {

								console.error(response?.data[props.mutationResponse])
								openSnackbar(
									severity.ERROR,
									response?.data[props.mutationResponse]?.message
                                        || 'There was an error updating this note.'
								)
							} else if (response?.data[props.mutationResponse]?.success == true) {
                                
								openSnackbar(severity.SUCCESS, 'Successfully updated note.')
								props.setEditNoteModal({
									...props.editNoteModal,
									open: false,
								})

								props.setNotes(
									props.notes.map((note) => {
										if (
											note.id == response.data[props.mutationResponse].note.id
										)
											return {
												...response.data[props.mutationResponse].note,
												entity_img: variables?.entity_img,
											}
										return note
									}),
								)
							} else {
                                
								console.error(response?.data[props.mutationResponse])
								openSnackbar(
									severity.ERROR,
									response?.data[props.mutationResponse]?.message
                                        || 'Could not update note.'
								)
							}
						})
					}
				}}
			>
				<div className="row">
					<FormControl sx={{paddingRight: '1em'}}>
						<Label id="type-label" style={typeStyle} disableAnimation shrink>
                            Type
						</Label>
						<Select
							name="type"
							
							labelId="type-label"
							input={<Dropdown />}
							value={props.editNoteModal.note?.type_id}
							onChange={(e) => {
								props.setEditNoteModal({
									...props.editNoteModal,
									note: {
										...props.editNoteModal.note,
										type_id: e.target.value,
									},
								})
							}}
						>
							{noteTypes &&
                                sortBy(noteTypes, ['value']).map(type => (
                                	<MenuItem key={type.id} value={type.id}>
                                		{type.value}
                                	</MenuItem>
                                ))}
						</Select>
					</FormControl>

					<FormControl sx={{paddingRight: '1em'}}>
						<Label
							id="date-label"
							style={typeStyle}
							disableAnimation
							shrink
						>
                            Date
						</Label>

						<MobileDatePicker
							inputFormat="MMM do, yyyy"
							disableMaskedInput={true}
							cancelText={null}
							name="date"
							inputVariant="outlined"
							className="MUIDatePicker"
							variant="dialog"
							value={Number(props.editNoteModal.note?.applied_at)}
							renderInput={({ inputRef, inputProps, InputProps }) => {
								
								const newProps = { ...inputProps}
	
								newProps.readOnly = false
	
								return (
									<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
								)}}
							onChange={(date) => {
								let _date = new Date(date)
								let newDate = new Date()

								newDate.setDate(_date.getDate())
								newDate.setFullYear(_date.getFullYear())
								newDate.setMonth(_date.getMonth())

								props.setEditNoteModal({
									...props.editNoteModal,
									note: {
										...props.editNoteModal.note,
										applied_at: newDate,
									},
								})
							}}
						/>
					</FormControl>

					<FormControl sx={{paddingRight: '1em'}}>
						<Label
							id="time-label"
							style={typeStyle}
							disableAnimation
							shrink
						>
                            Time
						</Label>

						<MobileTimePicker
							disableMaskedInput={true}
							cancelText={null}
							name="time"
							showTodayButton
							todayLabel="now"
							renderInput={({ inputRef, inputProps, InputProps }) => {
								
								const newProps = { ...inputProps}
	
								newProps.readOnly = false
	
								return (
									<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
								)}}
							value={Number(props.editNoteModal.note?.applied_at)}
							onChange={(time) => {
								let newDate = new Date(props.editNoteModal.note?.applied_at)
								newDate.setTime(time)

								props.setEditNoteModal({
									...props.editNoteModal,
									note: {
										...props.editNoteModal.note,
										applied_at: newDate,
									},
								})
							}}
						/>
					</FormControl>

					<FormControl
						sx={{ width: '25em', paddingRight: '1em' }}
					>
						<Label id="user-label" style={typeStyle} disableAnimation shrink>
                            User
						</Label>

						<Autocomplete
							disableClearable
							classes={classes2}
							style={{ marginTop: '1.5em' }}
							open={noteListAutocompleteOpen}
							forcePopupIcon
							filterSelectedOptions
							popupIcon={<ExpandMoreRoundedIcon />}
							isOptionEqualToValue={(option, value) => option.id == value.id}
							onOpen={() => {
								setNoteListAutocompleteOpen(true)
							}}
							onClose={() => {
								setNoteListAutocompleteOpen(false)
							}}
							getOptionLabel={(option) => {
								return option?.first_name + ' ' + option?.last_name
							}}
							options={options || [props.editNoteModal.note?.user]}
							defaultValue={props.editNoteModal.note?.user}
							loading={noteListLoading}
							onChange={(event, value) => {
								props.setEditNoteModal({
									...props.editNoteModal,
									note: {
										...props.editNoteModal.note,
										applied_user_id: value.id,
									},
								})
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									placeholder="-"
									fullWidth
									InputProps={{
										...params.InputProps,
										style: {
											paddingTop: 3.5,
											paddingBottom: 3.5,
										},
										endAdornment: (
											<React.Fragment>
												{noteListLoading ? (
													<CircularProgress color="inherit" size={20} />
												) : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>
							)}
						/>
					</FormControl>

					<FormControl style={{ maxWidth: '9em' }}>
						<Label id="user-label" style={typeStyle} disableAnimation shrink>
                            Image
						</Label>

						<input
							accept="image/*"
							style={{ display: 'none' }}
							id="icon-button-file-modal"
							type="file"
							multiple={true}
							onChange={handleUploadClick}
						/>

						<label
							htmlFor="icon-button-file-modal"
							style={{ paddingTop: '1.75em' }}
						>
							<ChooseImageButton
								style={{ height: '2.8em', width: '100%' }}
								color="primary"
								variant="contained"
								aria-label="upload picture"
								component="span"
							>
                                Choose a File
							</ChooseImageButton>
						</label>
					</FormControl>
				</div>

				<div
					style={{
						display: 'flex',
						backgroundColor: props.privateEntity ? '#292c2e' : 'rgb(250, 250, 250)',
						marginLeft: !noImages ? '-2em' : null,
						marginRight: !noImages ? '-2em' : null,
						marginBottom: !noImages ? '0.5em' : null,
						marginTop: !noImages ? '1em' : null,
						padding: !noImages ? '0.5em 2em' : '0',
					}}
				>
					<div
						style={{
							display: 'flex',
							overflowX: 'auto',
							marginRight: !noImages ? '1em' : 0,
							alignItems: 'center',
						}}
					>
						{props.editNoteModal?.note?.images
							?.filter((i) => !i.delete)
							.map((image) => (
								<div key={image.id || image.key}>
									<img
										style={{
											maxHeight: '10em',
											marginRight: '.7em',
										}}
										className={
											image.key === selectedImage?.key
												? classes.selected
												: classes.image
										}
										src={image.src || image.imgUrl}
										alt={image.alt_text || image.filename}
										onClick={() => {
											setSelectedImage(image)
										}}
									/>
								</div>
							))}
					</div>
				</div>

				<div
					className={
						attempt &&
                        (!props.editNoteModal.note?.note_text ||
                            props.editNoteModal.note?.note_text?.length === 0 ||
                            !props.editNoteModal.note?.note_text?.replace(/\s/g, '').length)
							? 'error'
							: null
					}
				>
					<FormControl
						className={classes.searchBar}
						style={{ width: '100%' }}
					>
						<Label
							style={typeStyle}
							disableAnimation
							shrink
							error={
								attempt &&
                                (!props.editNoteModal.note?.note_text ||
                                    props.editNoteModal.note?.note_text?.length === 0 ||
                                    !props.editNoteModal.note?.note_text?.replace(/\s/g, '')
                                    	.length)
							}
						>
                            Note
						</Label>
						<TextBox
							multiline
							minRows={5}
							maxRows={15}
							value={props.editNoteModal.note?.note_text}
							onChange={(e) => {
								props.setEditNoteModal({
									...props.editNoteModal,
									note: {
										...props.editNoteModal.note,
										note_text: e.target.value,
									},
								})
							}}
						/>
					</FormControl>
				</div>

				<div style={{marginTop: '1em'}}>
					<FormControlLabel
						control={
							<CriticalCheckbox
								checked={props.editNoteModal.note?.is_critical}
								color="secondary"
								inputProps={{ 'aria-label': 'critical checkbox' }}
								onChange={(event, value) => {
									props.setEditNoteModal({
										...props.editNoteModal,
										note: {
											...props.editNoteModal.note,
											is_critical: value,
										},
									})
								}}
							/>
						}
						label="Critical"
					/>

					{!props.disablePrivate && <FormControlLabel
						control={
							<Checkbox
								checked={props.editNoteModal.note?.is_private}
								color="secondary"
								inputProps={{ 'aria-label': 'private checkbox' }}
								onChange={(event, value) => {
									props.setEditNoteModal({
										...props.editNoteModal,
										note: {
											...props.editNoteModal.note,
											is_private: value,
										},
									})
								}}
							/>
						}
						label="Private"
					/>}

					{selectedImage ? (
						<Button
							variant="contained"
							className={classes.button}
							startIcon={<DeleteIcon />}
							style={{
								backgroundColor: '#CC3333',
								color: 'white',
								marginRight: '1em',
							}}
							onClick={() => {
								let temp = props.editNoteModal?.note?.images?.find(
									(e) => e.key === selectedImage.key,
								)
								temp.delete = true
								let tempList = props.editNoteModal?.note?.images?.map((e) =>
									e.key === temp.key ? temp : e,
								)
								props.setEditNoteModal((editNoteModal) => ({
									...editNoteModal,
									note: {
										...editNoteModal.note,
										images: tempList,
									},
								}))
								setSelectedImage()
							}}
						>
                            Remove Image
						</Button>
					) : null}

					<SearchButton
						variant="contained"
						size="small"
						type="submit"
						style={{ float: 'right' }}
					>
                        Update
					</SearchButton>
					<CancelButton
						variant="contained"
						size="small"
						type="reset"
						style={{ float: 'right', marginRight: '1em' }}
						onClick={() => {
							setAttempt(false)
							props.setEditNoteModal({
								...props.editNoteModal,
								open: false,
							})
							closeSnackbar()
						}}
					>
                        Cancel
					</CancelButton>
				</div>
			</form>
		</TransitionsModal>
	</>
}

export default withApollo(EditNotes)
