import withStyles from '@mui/styles/withStyles'
import InputBase from '@mui/material/InputBase'
import * as Colors from '../../styles/colors/Colors'

const DealEntryStyle = withStyles(theme => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(2),
		},
	},
	input: {
		border: '1px solid transparent',
		position: 'relative',
		fontSize: 16,
		padding: '10px 12px 10px 12px',
		borderRadius: 4,
		minWidth: '10em',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: 'Graphik',
		'&:hover': {
			borderRadius: 4,
			border: '1px solid #ced4da',
		},
		'&:focus': {
			backgroundColor: theme.palette.background.paper,
			borderRadius: 4,
			border: '1px solid #ced4da',
			borderColor: Colors.editAdd,
		},
	},
}))(InputBase)

const DealEntryWhiteStyle = withStyles(theme => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(2),
		},
	},
	input: {
		border: '1px solid transparent',
		position: 'relative',
		fontSize: 16,
		padding: '10px 12px 10px 12px',
		borderRadius: 4,
		minWidth: '10em',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: 'Graphik',
		color: 'white',
		'&:hover': {
			borderRadius: 4,
			border: '1px solid #ced4da',
		},
		'&:focus': {
			borderRadius: 4,
			border: '1px solid #ced4da',
			borderColor: Colors.editAdd,
		},
	},
}))(InputBase)

export { DealEntryStyle, DealEntryWhiteStyle }
