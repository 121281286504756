import React, { useState } from 'react'

import { CancelButton } from './../input/Buttons'
import IconButton from '@mui/material/IconButton'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { ThemeProvider } from '@mui/private-theming'
import { StyledEngineProvider } from '@mui/styled-engine'
import adminConsoleTheme from '../styles/muiThemes/adminConsoleTheme'
import theme from '../styles/muiThemes/mainTheme'


const SearchInfo = (props) => {
	const [open, setOpen] = useState(false)

	const defaultStyle = {
		height: 'fit-content',
		marginLeft: !props.advancedFilters ? 'auto' : 0,
		padding: 4,
	}

	const codeStyle = {
		backgroundColor: 'lightgray',
		borderRadius: 4,
		padding: 2,
	}

	const headerStyle = {
		margin: 0,
		fontWeight: 500
	}

	return (
	
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={props.darkMode ? adminConsoleTheme : theme}>
				<IconButton
					size="small"
					aria-label="More"
					style={{
						...defaultStyle,
						...props.style
					}}
					onClick={() => {
						setOpen(true)
					}}
				>
					<InfoOutlinedIcon />
				</IconButton>

				<TransitionsModal
					open={open}
					close={() => {
						setOpen(false)
					}}
				>
					<div style={{
						overflow: 'scroll',
						height: '75vh',
						marginRight: '-1em',
    					paddingRight: '1em',
						width: '55em'
					}} className={props.darkMode ? 'dark-theme' : null}>
						<h1 className="card-title">
							<div>Search Help</div>
							<div className="spacer"></div>
							<CancelButton variant="contained" onClick={() => setOpen(false)} style={{ display: 'flex', marginLeft: 'auto', padding: '.2em' }}>
							Close
							</CancelButton>
						</h1>

						<div>
							<h4 style={headerStyle}> The supplied terms will look through the following fields by priority: </h4>
							<ol>
								{props.fields?.map((field, i) => (
									<li key={`field-${i}`}> {field} </li>
								))}
							</ol>
						</div>

						<div style={{paddingBottom: '1em'}}>
							<h4 style={headerStyle}>Search Type - You can supply special terms to provide more control:</h4>
							<ul className="search-help-list">

								<li>
									<code style={codeStyle}>AND</code> - <i>default</i> - The sum of all fields must contain <i>all</i> supplied terms. 
								</li>
								<ul>
									<li>
									Supplied Term: <code style={codeStyle}>Term1 Term2</code>
									</li>
									<li>
									If all of the fields contain both <code style={codeStyle}>Term1 AND Term2</code>, the {props.recordName} will return.
									</li>
								</ul>

								<li>
									<code style={codeStyle}>OR</code> - One of the fields must contain <i>one</i> of the supplied terms. 
								</li>
								<ul>
									<li>
									Supplied Term: <code style={codeStyle}>Term1 OR Term2</code>
									</li>
									<li>
									If any of the fields contain either <code style={codeStyle}>Term1 OR Term2</code>, the {props.recordName} will return.
									</li>
								</ul>
							
								<li>
									<code style={codeStyle}>NOT</code> - All of the fields must not contain <i>all</i> negated terms. 
								</li>
								<ul>
									<li style={{lineHeight: 2}}>
									Supplied Term: <code style={codeStyle}>Term1 NOT Term2</code>
									</li>
									<li>
									If any of the fields contain <code style={codeStyle}>Term2</code>, the {props.recordName} will not return.
									</li>
								</ul>

								{!props.unified ?
									<>  
										<h4 style={{
											...headerStyle,
											paddingTop: '1em'
										}}>
										NOTE: <code style={codeStyle}>(</code>, <code style={codeStyle}>)</code>'s are required if multiple search types are used. Ex:
										</h4>
										<ul>
											<li>
												<code style={codeStyle}>quick brown OR fox</code> = <code style={codeStyle}>(quick brown) OR fox</code> - Search types are evaluated left to right, so the parenthesis are unused here.
											</li>
											<li>
												<code style={codeStyle}>quick (brown OR fox)</code> - The parenthesis here make sure to evaluate brown OR fox before quick.
											</li>
										</ul>
									</>
									: null}
							</ul>
						</div>


						<div>
							<h4 style={headerStyle}>In addition to the type of search, you can use the following characters within a term to help find the results:</h4>
							<ul className="search-help-list">
								<li>
									<code style={codeStyle}>*</code>, <code style={codeStyle}>?</code> <b>Wildcard</b> searches can be run on individual terms, using ? to replace a single character, and * to replace zero or more characters:
								</li>
								<ul>
									<li style={{lineHeight: 2}}>
										<code style={codeStyle}>qu?ck bro*</code>
									</li>
								</ul>
								<li>
									<code style={codeStyle}>""</code> - <b>Double Quotes</b> query for all the words in the search in the same order.
								</li>
								<ul>
									<li style={{lineHeight: 2}}>
										<code style={codeStyle}>"quick brown"</code>
									</li>
								</ul>
								<li>
									<code style={codeStyle}>~</code> <b>Fuzziness</b>, we can search for terms that are similar to, but not exactly like our search terms.
								</li>
								<ul>
									<li style={{lineHeight: 2}}>
										<code style={codeStyle}>quikc~ brwn~ foks~</code>
									</li>
								</ul>

								{!props.unified ?
									<> 
										<li>
											<code style={codeStyle}>+</code>, <code style={codeStyle}>-</code> <b>Boolean operator</b>, + (this term must be present) and - (this term must not be present). All other terms are optional.
										</li>
										<ul>
											<li style={{lineHeight: 2}}>
												<code style={codeStyle}>quick brown +fox -hound</code>
											</li>
										</ul>

									</>
									: null }
							</ul>
						</div>

						<div>
							<h4 style={headerStyle}>Advanced usage:</h4>
							<ul className="search-help-list">
								<li>
								To include the special characters like the following in your search  {' '} 
									<code style={codeStyle}>*</code>{', '}
									<code style={codeStyle}>?</code>{', '}
									<code style={codeStyle}>"</code>{', '}
									<code style={codeStyle}>~</code>{', '}
									<code style={codeStyle}>+</code> you can add a backslash character to escape them.
								</li>
								<ul>
									<li>
										<code style={codeStyle}>\"1234\"</code>, <code style={codeStyle}>Hello\?</code>, <code style={codeStyle}>\*</code>
									</li>
								</ul>
							</ul>
						</div>
					</div>
				</TransitionsModal>
			</ThemeProvider>
		</StyledEngineProvider>
		
	)
}

export default SearchInfo
