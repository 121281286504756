import { withApollo } from '@apollo/client/react/hoc'
import { FormControl, TextField } from '@mui/material'
import { Autocomplete } from '@mui/material'
import React, { useCallback, useContext, useEffect } from 'react'
import { GET_USERS } from '../Contacts/Contact/Queries'
import Label from '../input/Label'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { autoCompleteStyles, typeStyle } from '../styles/makesStyles'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { CancelButton, SubmitButton } from '../input/Buttons'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { DispatchContext } from '../store'
import { useMutation } from '@apollo/client'
import { UPDATE_GALLERY_CONTACTS } from './Queries'

const EditGalleryContactsModal = (props) => {
	const { open, onClose, selectedRows } = props

	const classes = autoCompleteStyles()

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const [loading, setLoading] = React.useState(false)

	useEffect(() => {
		if (loading) openSnackbar(severity.INFO, "Loading")
	}, [loading, openSnackbar])


	const [users, setUsers] = React.useState([])
	const [input, setInput] = React.useState({
		add: [],
		addPrimary: [],
		remove: [],
		removePrimary: [],
	})

	useEffect(() => {
		let active = true

		if (users.length) return

		props.client
			.query({
				query: GET_USERS,
				variables: { includeDeleted: true },
			})
			.then((result) => {
				if (active) {
					setUsers(result.data.getUsers.items)
				}
			})
			.catch(console.error)

		return () => {
			active = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleChange = (key, value) => {
		setInput({
			...input,
			[key]: value,
		})
	}

	const [updateGalleryContacts] = useMutation(UPDATE_GALLERY_CONTACTS)

	const handleClose = () => {
		setInput({
			add: [],
			addPrimary: [],
			remove: [],
			removePrimary: [],
		})
		onClose(false)
		setLoading(false)
	}

	const handleSubmit = () => {

		if (Object.values(input).every((el) => el.length === 0)) {
			openSnackbar(severity.WARNING, 'Please enter a valid input')
			return
		}

		setLoading(true)

		const inputVariables = {

			art_ids: selectedRows.filter(e => e.__typename === 'Art').map(e => e.id),
			contact_ids: selectedRows.filter(e => e.__typename === 'Contact').map(e => e.id),
			deal_ids: selectedRows.filter(e => e.__typename === 'Deal').map(e => e.id),
			artist_ids: selectedRows.filter(e => e.__typename === 'Artist').map(e => e.id),
			listing_ids: selectedRows.filter(e => e.__typename === 'Listing').map(e => e.id),

			add: input.add.map(e => e.id),
			addPrimary: input.addPrimary.map(e => e.id),
			remove: input.remove.map(e => e.id),
			removePrimary: input.removePrimary.map(e => e.id),
		}


		updateGalleryContacts({
			variables: {
				GalleryContactInput: inputVariables,
			},
		}).then((response) => {
			
			if (response.data?.updateGalleryContacts?.success) {
				openSnackbar(severity.SUCCESS, response.data.updateGalleryContacts.message)
			}

			else if (!response.data?.updateGalleryContacts && response.errors?.[0]?.message?.includes("Admin Console")) {
				openSnackbar(severity.ERROR, "Error: Requires admin privileges.")
			}

			else {
				openSnackbar(severity.ERROR, response.data.updateGalleryContacts.message)
			}

			setLoading(false)
			handleClose()
			
		}).catch(error => {
			openSnackbar(
				severity.ERROR,
				error
			)
			setLoading(false)
		} )
	}

	return (
		<TransitionsModal
			className="new-tag-modal"
			open={open}
			close={() => handleClose()}
		>
			<div style={{ width: '30em' }}>
				<h2
					className="card-title"
					style={{
						paddingBottom: '0.25',
						margin: 0,
					}}
				>
					Add
				</h2>

				<FormControl style={{ width: '100%' }}>
					<Label id="user-assistant" style={typeStyle} disableAnimation shrink>
						Gallery Contacts
					</Label>

					<Autocomplete
						multiple
						classes={classes}
						filterSelectedOptions
						style={{ marginTop: '1.75em' }}
						getOptionDisabled={(option) => {
							return !!input.remove?.find((el) => el.id === option.id)
						}}
						isOptionEqualToValue={(option, value) => {
							return option.id === value.id
						}}
						forcePopupIcon
						popupIcon={<ExpandMoreRoundedIcon />}
						size="small"
						getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
						options={users}
						value={input.add}
						onChange={(_, value) => {
							handleChange('add', value)
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								fullWidth
								classes={{ notchedOutline: null }}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
					/>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label id="user-assistant" style={typeStyle} disableAnimation shrink>
						Primary Gallery Contact Status
					</Label>

					<Autocomplete
						multiple
						classes={classes}
						filterSelectedOptions
						style={{ marginTop: '1.75em' }}
						getOptionDisabled={(option) => {
							return !!input.removePrimary?.find((el) => el.id === option.id)
						}}
						isOptionEqualToValue={(option, value) => {
							return option.id === value.id
						}}
						forcePopupIcon
						popupIcon={<ExpandMoreRoundedIcon />}
						size="small"
						getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
						options={input.add}
						value={input.addPrimary}
						onChange={(_, value) => {
							handleChange('addPrimary', value)
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								fullWidth
								classes={{ notchedOutline: null }}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
					/>
				</FormControl>

				<h2
					className="card-title"
					style={{
						paddingTop: '0.5em',
						paddingBottom: '0.25',
						margin: 0,
					}}
				>
					Remove
				</h2>
				<FormControl style={{ width: '100%' }}>
					<Label id="user-assistant" style={typeStyle} disableAnimation shrink>
						Gallery Contacts
					</Label>

					<Autocomplete
						multiple
						classes={classes}
						filterSelectedOptions
						getOptionDisabled={(option) => {
							return !!input.add?.find((el) => el.id === option.id) || !!input.removePrimary?.find((el) => el.id === option.id)
						}}
						style={{ marginTop: '1.75em' }}
						isOptionEqualToValue={(option, value) => {
							return option.id === value.id
						}}
						forcePopupIcon
						popupIcon={<ExpandMoreRoundedIcon />}
						size="small"
						getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
						options={users}
						value={input.remove}
						onChange={(_, value) => {
							handleChange('remove', value)
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								fullWidth
								classes={{ notchedOutline: null }}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
					/>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label id="user-assistant" style={typeStyle} disableAnimation shrink>
						Primary Gallery Contact Status
					</Label>

					<Autocomplete
						multiple
						classes={classes}
						filterSelectedOptions
						getOptionDisabled={(option) => {
							return !!input.addPrimary?.find((el) => el.id === option.id) || !!input.remove?.find((el) => el.id === option.id)
						}}
						style={{ marginTop: '1.75em' }}
						isOptionEqualToValue={(option, value) => {
							return option.id === value.id
						}}
						forcePopupIcon
						popupIcon={<ExpandMoreRoundedIcon />}
						size="small"
						getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
						options={users}
						value={input.removePrimary}
						onChange={(_, value) => {
							handleChange('removePrimary', value)
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								fullWidth
								classes={{ notchedOutline: null }}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
					/>
				</FormControl>

				<div
					style={{
						width: '100%',
						paddingBottom: '1em',
						marginTop: '1.5em',
					}}
				>
					<CancelButton
						variant="contained"
						style={{ float: 'left' }}
						onClick={() => handleClose()}
					>
						Cancel
					</CancelButton>

					<SubmitButton
						variant="contained"
						style={{ float: 'right' }}
						type="submit"
						disabled={loading}
						onClick={() => handleSubmit()}
					>
						Submit
					</SubmitButton>
				</div>
			</div>
		</TransitionsModal>
	)
}

export default withApollo(EditGalleryContactsModal)
