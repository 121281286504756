import routes from '../../navigation/routes'
import TabbedPage from '../../navigation/Tabs/TabbedPage'
import ArtQuickView from './ArtQuickView'
import { GET_ART_DETAILS } from '../Queries'
import { useParams } from 'react-router-dom'
import { DYNAMIC_ART_SEARCH } from '../../Search/Queries'

export default function ArtPiece(props) {
	const params = useParams()
	
	return TabbedPage({
		...props,
		routes: routes.artPages.pages,
		Quickview: ArtQuickView,
		query: GET_ART_DETAILS,
		searchGlobalQuery: DYNAMIC_ART_SEARCH,
		options: routes.artPages.options,
		params,
	})
}
