import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import { SnackbarStateContext } from '../store'
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CloseIcon from "@mui/icons-material/Close"
import clsx from "clsx"
import ErrorIcon from "@mui/icons-material/Error"
import {green} from "@mui/material/colors"
import IconButton from "@mui/material/IconButton"
import InfoIcon from "@mui/icons-material/Info"
import PropTypes from "prop-types"
import React, { useContext, useEffect } from 'react'
import Slide from "@mui/material/Slide"
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from "@mui/material/SnackbarContent"
import WarningIcon from "@mui/icons-material/Warning"
import CircularProgress from '@mui/material/CircularProgress'

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon
}

const useStyles1 = makeStyles(theme => ({
	success: {
		backgroundColor: green[600]
	},
	error: {
		backgroundColor: "#cc3333"
	},
	info: {
		backgroundColor: "#1976d2"
	},
	warning: {
		backgroundColor: "orange"
	},
	icon: {
		fontSize: 20
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1)
	},
	message: {
		display: "flex",
		alignItems: "center",
		paddingBottom: "1em"
	}
}))

const getMessageOverride = (message, variant) => {

	if (!message) {
		return "An unexpected error occured."
	}

	else if (typeof message == 'object' || Array.isArray(message))
		return "An unexpected error occured."

	else if (message.includes("DataError")) {
		let newMessage = "Data Error"

		if (message.includes("value too long for type character varying")) {
			newMessage += `: ${message.substring(message.search(' - ') + 3)}`
		}

		return newMessage
	}

	else if (message.includes("Not Authorised!")) {
		return "Not Authorised!"
	}

	else if (message.includes("DBError")) {
		return "Database Error"
	}
		
	return message
}

const MySnackbarContentWrapper = React.forwardRef((props, ref) => {
	const classes = useStyles1()
	const { className, message, onClose, variant, loading, ...other } = props
	const Icon = variantIcon[variant]

	return (
		<SnackbarContent
			ref={ref}
			className={clsx(classes[variant], className)}
			aria-describedby="client-snackbar"
			message={
				<div style={{ display: 'flex', width: 'fit-content' }}>
					<div style={props.loading ? { paddingTop: '3px' } : null}>
						{props.loading ? (
							<CircularProgress color="inherit" size={18} style={{marginRight: '1em'}}/>
						) : (
							<>
								{Icon ? (
									<Icon
										style={{ marginBottom: '-4px' }}
										className={clsx(
											classes.icon,
											classes.iconVariant
										)}
									/>
								) : null}
							</>
						)}
					</div>
					<div
						style={{
							margin: 'auto',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						{getMessageOverride(message, variant)}
					</div>
				</div>
			}
			action={[
				<IconButton
					key="close"
					aria-label="Close"
					color="inherit"
					onClick={onClose}
					size="large">
					<CloseIcon className={classes.icon} />
				</IconButton>,
			]}
			{...other}
		/>
	)
})

MySnackbarContentWrapper.propTypes = {
	className: PropTypes.string,
	message: PropTypes.node,
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
	loading: PropTypes.bool
}

const StyledSnackbar = withStyles({
	root: {
		top: "0px",
		borderRadius: "0 0 5px 5px"
	}
})(Snackbar)

const SlideTransition = React.forwardRef((props, ref) => {
	return <Slide {...props} ref={ref} direction="down" />
})

/**
 * A customized snackbar functional component that can display a supplied message, in a supplied style.
 * 
 */
export default function CustomizedSnackbar(props) {

	const state = useContext(SnackbarStateContext)
	const loading = state?.text?.toString()?.toLowerCase()?.includes("loading")

	useEffect(() => {

		switch(state.severity) {
		case "warning":
			console.warn(state.text)
			break
		case "error":
			console.error(state.text)
			break
		case "info":
			if (!loading) console.info(state.text)
			break
		case "success":
			console.log(state.text)
			break
		default:
			break
		}
	// eslint-disable-next-line 
	}, [state.severity]) // we only want logs once per snackbar

	
	return (
		<StyledSnackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
			TransitionComponent={SlideTransition}
			open={state.open}
			autoHideDuration={!loading ? 3000 : 600000}
			onClose={(event, reason) => {
				if (reason === 'clickaway') {
					return
				}
				props.handleClose(event, reason)
			}}
		>
			<MySnackbarContentWrapper
				loading={loading}
				onClose={props.handleClose}
				variant={state.severity || "info"}
				message={state.text}
			/>
		</StyledSnackbar>
	)
}

/**
 * Enum for severity values.
 * @readonly
 * @enum { string }
 */
const severity = {
	WARNING: 'warning',
	ERROR: 'error',
	INFO: 'info',
	SUCCESS: 'success'
}

export { severity }
