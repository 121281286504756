import { CREATE_USER } from './Queries'
import { DispatchContext } from '../store'
import { FormControl, Select, MenuItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { isValidPhone, isValidEmail } from '../common/helpers'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { SubmitButton, CancelButton } from '../input/Buttons'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router'
import Dropdown from '../input/Dropdown/Dropdown'
import Label from '../input/Label'
import PhoneInput from 'react-phone-input-2'
import QuickView from '../QuickView/QuickView'
import React, { useContext, useEffect, useCallback } from 'react'
import TextBox from '../input/Text/TextBoxThin'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

import { LookupContext } from '../store'
import { withApollo } from '@apollo/client/react/hoc'
import AvatarInput from '../common/AvatarInput'
import sortBy from 'lodash/sortBy'


const useStyles = makeStyles(theme => ({
	large: {
		width: 210,
		height: 210
	},
}))

function CreateUserQuickView(props) {

	const classes = useStyles()

	const lookup = useContext(LookupContext)
	const navigate = useNavigate()

	const prefixes = lookup.data?.getPrefixTypes
	const sortedPrefixes = sortBy(prefixes, [function(o) { return o.value }])

	const galleries = lookup.data?.getGalleryTypes
	const departments = lookup.data?.getDepartmentTypes

	const [createUser, {loading, error}] = useMutation(CREATE_USER)

	const [attempt, setAttempt] = React.useState(false)
	const [user, setUser] = React.useState({
		prefix_id: '',
		suffix: '',
		first_name: '',
		last_name: '',
		title: '',
		department_id: '',
		gallery_id: '',
		phone_number1: '',
		email: '',
		profile_link: null
	})

	const [validPhone, setValidPhone] = React.useState(false)
	const [validEmail, setValidEmail] = React.useState(false)

	useEffect(() => {
		setValidPhone(isValidPhone(user.phone_number1))
	}, [user.phone_number1])

	useEffect(() => {
		setValidEmail(isValidEmail(user.email))
	}, [user.email])

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	useEffect( () => {
		if(error?.graphQLErrors){
			const gqlErrors = error.graphQLErrors
			gqlErrors.forEach( (e) => {
				if(e.message === "autherror-admin"){
					openSnackbar(severity.ERROR, "Error: user creation requires admin login")
					navigate("/admin/login")
				}
			} )
		}
		else if (error) {
			console.log(error)
			openSnackbar(severity.ERROR, "Error creating user")
		}
	}, [navigate, error, openSnackbar])



	return (
		<QuickView className="quick-view-main create-user-quick-view">

			<div className="quick-view-inner">
			
				<form
					style={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						width: '90%',
					}}
					onSubmit={async (event) => {
						event.preventDefault()
						setAttempt(true)
						if(user.phone_number1 && !validPhone){
							openSnackbar(
								severity.WARNING,
								'Phone is not valid.'
							)
						}				
						else if (
							attempt &&
						(	!user.first_name ||
							!user.last_name ||
							!user.email ||
							!validEmail || 
							!user.title || 
							!user.gallery_id
						)
						) {
							openSnackbar(
								severity.WARNING,
								'Please fill fields in red.'
							)
						} else if (
							!(
								!user.first_name ||
							!user.last_name ||
							!user.email ||
							!validEmail || 
							!user.title || 
							!user.gallery_id 
							)
						) {
							let userInput = { ...user }

							Object.keys(userInput).forEach(
								(key) => !userInput[key] && delete userInput[key]
							)
							delete userInput.imgUrl

							await createUser({
								variables: {
									UserInput: userInput,
								},
							}).then((response) => {
							// Redirect to user detail
								const createdUser = response.data.createUser.user

								setAttempt(false)
								navigate(`/admin/users/${createdUser.id}/profile`)
							}).catch( (error) => {
								console.log(error)
								openSnackbar(
									severity.ERROR,
									'There was an error while creating the user.'
								)
							} )

						}
					}}
				>
					<div style={{ width: '100%' }}>

						<h2 className="card-title">Create New User</h2>


						<FormControl style={{ float: 'left', width: '47%' }}>
							<Label
								id="prefix-label"
								disableAnimation
								shrink
							>
							Prefix
							</Label>

							<Select
								IconComponent={ExpandMoreRoundedIcon}
								name="prefix"
								
								labelId="prefix-label"
								className="medium-selection"
								input={<Dropdown />}
								value={user.prefix_id}
								onChange={(e) => {
									setUser({
										...user,
										prefix_id: e.target.value,
									})
								}}
							>
								{sortedPrefixes.map((prefix) => (
									<MenuItem key={prefix.id} value={prefix.id}>
										{prefix.value}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl style={{ float: 'right', width: '47%' }}>
							<Label disableAnimation shrink htmlFor="user-suffix">
							Suffix
							</Label>

							<TextBox
								className="user-input"
								id="userQV-suffix"
								value={user.suffix}
								onChange={(e) => {
									setUser({
										...user,
										suffix: e.target.value,
									})
								}}
							/>
						</FormControl>
					</div>

					<FormControl fullWidth>
						<Label
							disableAnimation
							shrink
							htmlFor="user-first-name"
							error={attempt && !user?.first_name}
						>
						First Name*
						</Label>

						<TextBox
							className="user-input"
							id="userQV-first-name"
							value={user.first_name}
							error={attempt && !user?.first_name}
							onChange={(e) => {
								setUser({
									...user,
									first_name: e.target.value,
								})
							}}
						/>
					</FormControl>

					<FormControl fullWidth>
						<Label
							disableAnimation
							shrink
							htmlFor="userInputQV-last-name"
							error={attempt && !user?.last_name}
						>
						Last Name*
						</Label>

						<TextBox
							className="user-input"
							id="userQV-last-name"
							value={user.last_name}
							error={attempt && !user?.last_name}
							onChange={(e) => {
								setUser({
									...user,
									last_name: e.target.value,
								})
							}}
						/>
					</FormControl>

					<AvatarInput
						style={{
							textAlign: 'center',
							marginTop: '1em',
						}}
						newEntity={true}
						className={classes.large}
						alt={user?.first_name ? `${user.first_name} ${user.last_name}` : "New User"}
						src={user?.imgUrl}
						editable={true}
						oldProfileLink={[]}
						inputName="UserInput"
						entityId={null}
						setEntity={setUser}
						entity={user}
						entityName="user"
						onAdminConsole={true}
						creation={true}
					/>

					<FormControl fullWidth>
						<Label
							disableAnimation
							shrink
							htmlFor="userQV-title"
							error={attempt && !user?.title}
						>
						Title*
						</Label>

						<TextBox
							className="user-input"
							id="userQV-title"
							value={user.title}
							error={attempt && !user?.title}
							onChange={(e) => {
								setUser({
									...user,
									title: e.target.value,
								})
							}}
						/>
					</FormControl>

					<FormControl
						fullWidth
						className="padded-select"
					>
						<Label id="department-label" disableAnimation shrink>
						Department
						</Label>

						<Select
							className="medium-selection"
							IconComponent={ExpandMoreRoundedIcon}
							name="field"
							
							labelId="department-label"
							input={<Dropdown />}
							value={user.department_id}
							onChange={(e) => {
								setUser({
									...user,
									department_id: e.target.value,
								})
							}}
						>
							{departments.map((department) => (
								<MenuItem key={department.id} value={department.id}>
									{department.value}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl fullWidth className="padded-select">
						<Label
							id="gallery-label"
							disableAnimation
							shrink
							error={attempt && !user?.gallery_id}
						>
						Gallery*
						</Label>

						<Select
							className="medium-selection"
							IconComponent={ExpandMoreRoundedIcon}
							name="gallery"
							error={attempt && !user?.gallery_id}
							labelId="gallery-label"
							input={<Dropdown />}
							value={user.gallery_id}
							onChange={(e) => {
								setUser({
									...user,
									gallery_id: e.target.value,
								})
							}}
						>
							{galleries.map((gallery) => (
								<MenuItem key={gallery.id} value={gallery.id}>
									{gallery.value}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<div className={attempt && (user.phone_number1 && !validPhone) ? 'error' : null}>
						<Label disableAnimation shrink style={{
							paddingTop: '0.4em',
							marginLeft: 0,
							marginBottom: '-0.4em',
						}}>
						Phone
						</Label>

						<PhoneInput
							disableSearchIcon
							enableSearch
							placeholder=""
							dropdownStyle={{ borderRadius: '4px' }}
							inputStyle={{
								width: '100%',
								height: '37px',
								fontFamily: 'graphik',
							}}
							value={user.phone_number1}
							onChange={(value, data, event) => {
								setUser({
									...user,
									phone_number1: value.replace(/[^0-9]+/g, ''),
								})
							}}
						/>
					</div>

					<FormControl fullWidth>
						<Label
							disableAnimation
							shrink
							htmlFor="usertQV-email"
							error={attempt && (!validEmail || !user?.email)}
						>
						Email*
						</Label>

						<TextBox
							error={attempt && (!validEmail || !user?.email)}
							type="email"
							name="email"
							className="user-input"
							id="userQV-email"
							value={user.email}
							onChange={(e) => {
								setUser({
									...user,
									email: e.target.value,
								})
							}}
						/>
					</FormControl>

					<div
						style={{
							width: '100%',
							paddingBottom: '1em',
							marginTop: '1em',
						}}
					>
						<CancelButton
							variant="contained"
							style={{ float: 'left' }}
							onClick={(e) => {
								props.setCreateUser(false)
								setAttempt(false)
							}}
						>
						Cancel
						</CancelButton>

						<SubmitButton
							variant="contained"
							style={{ float: 'right' }}
							type="submit"
							disabled={loading}
						>
						Submit
						</SubmitButton>
					</div>
				</form>

			</div>
		</QuickView>
	)
}

export default withApollo(CreateUserQuickView)
