
import { gql } from '@apollo/client'

export const UPDATE_HOME_CARDS = gql`
	mutation updateHomeCards($HomePageWidgetRequest: HomePageWidgetRequest!) {
		updateHomeCards(input: $HomePageWidgetRequest) {
			code
			success
			message
		}
	}
`
