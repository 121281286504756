/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import ArtFinancials from './ArtFinancials'
import ArtFinancialHistory from './ArtFinancialHistory'
import { useLazyQuery } from '@apollo/client'
import { GET_ART_FINANCIAL_HISTORIES } from './Queries'
import { useParams } from 'react-router-dom'
import TransactionHistory from '../TransactionHistory'

const FINANCIAL_HISTORY_LIMIT = 3


export default function FinancialsAndTransactions (props) {

	const [history, setHistory] = useState([])

	const [cursor, setCursor] = React.useState(0)
	const [steps, setSteps] = React.useState(1)
	const [activeStep, setActiveStep] = React.useState(0)
	const [totalItems, setTotalItems] = React.useState(0)

	const params = useParams()

	const [refetch, { data, loading: historyLoading }] = useLazyQuery(GET_ART_FINANCIAL_HISTORIES, {
		skip: props.id === 'findmode' || !params?.id,
		variables: {
			id: params?.id,
			cursor,
			limit: FINANCIAL_HISTORY_LIMIT
		},
		onCompleted: (data) => {

			setHistory(data?.getArtFinancialHistories?.items)
			setCursor(data?.getArtFinancialHistories?.cursor)
			setTotalItems(data?.getArtFinancialHistories?.totalItems)

			if (data.getArtFinancialHistories.items?.length < 1) setSteps(1)
			else setSteps(Math.ceil(data.getArtFinancialHistories.totalItems / 3))
		},
		onError: (error) => {

			console.error(error)

			if (data?.getArtFinancialHistories?.items?.length) {

				setHistory(data?.getArtFinancialHistories?.items)
				setCursor(data?.getArtFinancialHistories?.cursor)
				setTotalItems(data?.getArtFinancialHistories?.totalItems)

				if (data.getArtFinancialHistories.items.length < 1) setSteps(1)
				else setSteps(Math.ceil(data.getArtFinancialHistories.totalItems / 3))
			}
		}
	})


	// Query for financial history on page load
	React.useEffect(() => {
		if (params?.id) {

			setHistory([])
			setCursor(0)
			setTotalItems(0)
			setSteps(1)
			
			refetch({variables: {
				id: params?.id,
				cursor,
				limit: FINANCIAL_HISTORY_LIMIT
			}})
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params?.id])

	return <>
		<div className="page-row">
			<ArtFinancials
				artPiece={props.state.getArtPiece}
				state={props.state}
				setState={props.setState}
				history={history}
				setHistory={setHistory}
				refetch={refetch}
				error={props.error}
				findMode={props.findMode}
				loading={props.loading}
			/>
			{!props.findMode && <ArtFinancialHistory
				artPiece={props.state.getArtPiece}
				history={history}
				setHistory={setHistory}
				refetch={refetch}
				limit={FINANCIAL_HISTORY_LIMIT}
				cursor={cursor}
				setCursor={setCursor}
				steps={steps}
				setSteps={setSteps}
				activeStep={activeStep}
				setActiveStep={setActiveStep}
				totalItems={totalItems}
				setTotalItems={setTotalItems}
				private={props.state?.getArtPiece?.is_private || false}
				loading={historyLoading}
			/>}
		</div>

		{!props.findMode && <TransactionHistory
			art={props.state?.getArtPiece}
			setQVOverride={props.setQVOverride}
			loading={props.loading}
			findMode={props.findMode}
			style={{
				marginRight: '1em'
			}}
		/>}
	</>
}

