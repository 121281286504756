import { gql } from "@apollo/client"

export const DYNAMIC_TASK_NAV = gql`
	query searchDynamicTasks(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$filters: [SearchFilter]
		$limit: Int
	) {
		searchDynamicTasks(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: $filters
			}
		) {
			cursor
			totalItems
			items {
				id
			}
		}
	}
`

export const DYNAMIC_USER_NAV = gql`
	query searchDynamicUsers(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$filters: [SearchFilter]
		$limit: Int
	) {
		searchDynamicUsers(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: $filters
			}
		) {
			cursor
			totalItems
			items {
				id
			}
		}
	}
`

export const DYNAMIC_IMPORT_NAV = gql`
	query searchDynamicImport(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$filters: [SearchFilter]
		$limit: Int
	) {
		searchDynamicImport(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: $filters
			}
		) {
			cursor
			totalItems
			items {
				id
			}
		}
	}
`

export const DYNAMIC_TAG_NAV = gql`
	query searchDynamicTags(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$filters: [SearchFilter]
		$limit: Int
	) {
		searchDynamicTags(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: $filters
			}
		) {
			cursor
			totalItems
			items {
				id
			}
		}
	}
`

export const DYNAMIC_ART_NAV = gql`
	query searchDynamicArt(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$filters: [SearchFilter]
		$limit: Int
	) {
		searchDynamicArt(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: $filters
			}
		) {
			cursor
			totalItems
			items {
				id
			}
		}
	}
`

export const DYNAMIC_ARTISTS_NAV = gql`
	query searchDynamicArtists(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$filters: [SearchFilter]
		$limit: Int
	) {
		searchDynamicArtists(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: $filters
			}
		) {
			cursor
			totalItems
			items {
				id
			}
		}
	}
`

export const GET_ART_LISTINGS_NAV = gql`
	query GetArtListings(
		$field: String
		$art_id: ID
		$artist_id: ID
		$direction: SortDirection
		$searchString: String
		$listingTypeId: String
		$cursor: Int
	) {
		getArtListings(
			input: {
				field: $field
				direction: $direction
				limit: 10
				cursor: $cursor
				filters: {
					art_id: $art_id
					artist_id: $artist_id
					searchString: $searchString
					listingTypeId: $listingTypeId
				}
			}
		) {
			items {
				id
				listing {
					id
				}
			}
			cursor
			totalItems
		}
	}
`

export const DYNAMIC_CONTACT_NAV = gql`
	query searchDynamicContacts(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$filters: [SearchFilter]
		$limit: Int
	) {
		searchDynamicContacts(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: $filters
			}
		) {
			cursor
			totalItems
			items {
				id
			}
		}
	}
`

export const GET_CONTACT_LISTINGS_NAV = gql`
	query GetContactListings(
		$field: String
		$id: ID
		$direction: SortDirection
		$searchString: String
		$listingTypeId: String
		$cursor: Int
	) {
		getContactListings(
			input: {
				field: $field
				direction: $direction
				limit: 10
				cursor: $cursor
				filters: {
					id: $id
					searchString: $searchString
					listingTypeId: $listingTypeId
				}
			}
		) {
			items {
				id
				listing {
					id
				}
			}
			cursor
			totalItems
		}
	}
`

export const DYNAMIC_DEAL_NAV = gql`
	query searchDynamicDeals(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$filters: [SearchFilter]
		$limit: Int
	) {
		searchDynamicDeals(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: $filters
			}
		) {
			cursor
			totalItems
			items {
				id
			}
		}
	}
`

export const GET_ACTIVE_DEALS_NAV = gql`
	query getMyActiveDeals($cursor: Int) {
		getMyActiveDeals(cursor: $cursor) {
			items {
				art {
					id
				}
				deal {
					id
				}
			}
			cursor
			totalItems
		}
	}
`

export const GET_GLOBAL_CONSIGNMENTS_NAV = gql`
	query getGlobalConsignments(
		$cursor: Int
		$limit: Int
		$isSalesperson: Boolean
	) {
		getGlobalConsignments(
			cursor: $cursor
			limit: $limit
			isSalesperson: $isSalesperson
		) {
			items {
				id
				art_id
				deal_id
			}
			cursor
			totalItems
		}
	}
`

export const GET_GLOBAL_RESERVES_NAV = gql`
	query getGlobalReserves($cursor: Int, $limit: Int) {
		getGlobalReserves(cursor: $cursor, limit: $limit) {
			items {
				id
				art_id
				deal_id
			}
			cursor
			totalItems
		}
	}
`

export const DYNAMIC_LISTING_NAV = gql`
	query searchDynamicListings(
		$field: String
		$direction: SortDirection
		$cursor: Int
		$filters: [SearchFilter]
		$limit: Int
	) {
		searchDynamicListings(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: $filters
			}
		) {
			cursor
			totalItems
			items {
				id
			}
		}
	}
`

export const GET_LISTING_CONTACTS_NAV = gql`
	query GetListingContacts(
		$field: String
		$id: ID
		$direction: SortDirection
		$searchString: String
		$limit: Int
		$listingContactStatus: String
		$cursor: Int
	) {
		getListingContacts(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: {
					id: $id
					searchString: $searchString
					listingContactStatus: $listingContactStatus
				}
			}
		) {
			items {
				id
				contact {
					id
				}
			}
			cursor
			totalItems
		}
	}
`

export const TOGGLE_NOTIFICATION_AS_READ = gql`
	mutation toggleNotificationAsRead($id: ID!, $read: Boolean!) {
		toggleNotificationAsRead(id: $id, read: $read) {
			code
			success
			message
		}
	}
`

export const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
	 mutation markAllNotificationAsRead($userId: ID!) {
        markAllNotificationAsRead(userId: $userId) {
			code
			success
			message
        }
    }
`
