/* eslint-disable eqeqeq */
import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers-pro'
import { DispatchContext, AuthStateContext } from '../../store'
import { GET_USERS } from '../../User/Queries'
import { LookupContext } from '../../store'
import makeStyles from '@mui/styles/makeStyles'
import {
	Autocomplete, Button, Checkbox, CircularProgress, FormControl,
	FormControlLabel, MenuItem, Paper, Select, TextField
} from '@mui/material'
import { red } from '@mui/material/colors'
import { SearchButton, CancelButton, ChooseImageButton } from '../../input/Buttons'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { uploadImages } from '../../common/upload'
import { useMutation } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import withStyles from '@mui/styles/withStyles'
import * as Colors from '../../styles/colors/Colors'
import Dropdown from '../../input/Dropdown/Dropdown'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import Label from '../../input/Label'
import React, { useContext, useCallback, useEffect, useState } from 'react'
import TextBox from '../../input/Text/TextBox'
import DeleteIcon from '@mui/icons-material/Delete'
import sortBy from 'lodash/sortBy'
import { autoCompleteStyles } from '../../styles/makesStyles'
import { DEFAULT_NOTE_IMAGE_TYPE, DEFAULT_IMAGE_USAGE_RIGHT, DEFAULT_NOTE_TYPE } from './constants'
import { permissions, permissionValues } from '../../constants/permissions'

const useStyles = makeStyles(() => ({
	image: {
		borderWidth: 'small',
		borderStyle: 'solid',
		borderColor: 'transparent',
	},
	selected: {
		borderWidth: 'small',
		borderStyle: 'solid',
		borderColor: Colors.editAdd,
	},
}))

const CriticalCheckbox = withStyles({
	root: {
		'&$checked': {
			color: red[500],
		},
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />)

function NewObjectNote(props) {
	const lookup = useContext(LookupContext)
	const noteTypes = lookup.data?.getNoteTypes
	const sortedTypes = sortBy(noteTypes, [function(o) { return o.value }])

	const classes = useStyles()
	const classes2 = autoCompleteStyles()

	const [selectedImage, setSelectedImage] = React.useState()



	const typeStyle = {
		fontWeight: 500,
	}

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const closeSnackbar = () => {
		dispatch({ type: 'closeSnackBar' })
	}

	// User
	const userAuthentication = useContext(AuthStateContext)

	const [attempt, setAttempt] = useState(false)
	const [note, setNote] = useState({
		type: props.noteType || DEFAULT_NOTE_TYPE,
		date_time: new Date(),
		user: userAuthentication?.user?.id,
		is_critical: false,
		images: [],
	})

	// User options
	const [options, setOptions] = useState([])
	const [selection, setSelection] = useState(null)

	useEffect(() => {
		setNote({
			...note,
			user: userAuthentication?.user?.id,
		})

		if (!options.length && userAuthentication.user) {
			setOptions([userAuthentication.user])
			setSelection(userAuthentication.user)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userAuthentication])

	const [optionsDropdown, setOptionsDropdown] = useState(false)
	const optionsListLoading = optionsDropdown && options?.length === 1

	useEffect(() => {
		let active = true
		if (!optionsListLoading) {
			return undefined
		}
		props.client.query({ query: GET_USERS }).then((result) => {
			if (active) {
				setOptions(result.data.getUsers.items)
			}
		})
		return () => {
			active = false
		}
	}, [optionsListLoading, props.client])

	// Create Note
	const [createNote] = useMutation(props.mutation, {
		onError: (error) => {
			// On failure, reset state, don't touch state and show error
			openSnackbar(severity.ERROR, 'Could not create note.')
		},

		onCompleted: (response) => {
			if (response[props.mutationName].success === true) {
				// Success
				openSnackbar(severity.SUCCESS, response[props.mutationName].message)

				// On success change state
				setNote({
					type: props.noteType || DEFAULT_NOTE_TYPE,
					date_time: new Date(),
					user: userAuthentication.user?.id,
					is_critical: false,
					text: '',
					images: [],
				})

				setAttempt(false)
				setSelection(options.find((item) => item.id === userAuthentication.user?.id))
				props.setNotes([response[props.mutationName].note, ...props.notes])

				// Add note to quickview, if critical
				if (response[props.mutationName].note.is_critical)
					props.setState({
						...props.state,
						[props.criticalNotes]: props.state[props.criticalNotes]
							.concat(response[props.mutationName].note)
					})

			} else {
				// On failure, reset state and show error
				openSnackbar(severity.ERROR, response[props.mutationName].message)
			}
		},
	})

	// Upload image.
	let handleUploadClick = (event) =>
		uploadImages(props.client.query, event, (newImage) => {
			setNote((note) => ({
				...note,
				images: note.images.concat(newImage),
			}))
		}).catch((error) => {
			console.error(error)
			openSnackbar(severity.ERROR, 'Error during upload.')
		})

	const noImages = !props.loading && !note.images?.filter((i) => !i.delete).length

	const getBackgroundColor = () =>
	 	props.privateEntity ? '#292c2e' : 'rgb(250, 250, 250)'


	const className = !props.bulk && 'padding-margin-scrollbar'
	const sx = props.bulk && {
		padding: '1em 2em'
	}

	const authState = useContext(AuthStateContext)
	const userPermissions  = authState?.user?.permissions
	const privateTogglePermission = userPermissions?.find(e => e.permission_id == permissions.PRIVATE_OBJECT_TOGGLE)?.permission_value_id == permissionValues.YES

	const noPrivate = props.linkField == 'artist_id' || props.linkField == 'listing_id' || !privateTogglePermission

	return (
		<React.Fragment>
			<Paper
				className={className}
				id="new-note"
				data-testid="card-new-note"
				sx={sx}
			>
				<h1 className="card-title">New Note</h1>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						setAttempt(true)

						if (
							attempt &&
							(!note.text || note.text?.length === 0 || !note.text.replace(/\s/g, '').length)
						) {
							openSnackbar(severity.WARNING, 'Please complete required fields.')
						} else if (!(!note.text || note.text?.length === 0 || !note.text.replace(/\s/g, '').length)) {
							let variables = {
								type_id: note.type,
								applied_at: new Date(note.date_time),
								applied_user_id: note.user.id || userAuthentication.user.id,
								note_text: note.text,
								is_critical: note.is_critical,
								entity_name: props.linkField,
								images: note.images.map((i) => {
									const { src, ...image } = i
									return {
										...image,
										type_id: DEFAULT_NOTE_IMAGE_TYPE,
										usage_right_id: DEFAULT_IMAGE_USAGE_RIGHT,
									}
								}),
								is_private: note.is_private
							}
							if (Array.isArray(props.entityId)) {
								variables.id_list = props.entityId
							} else {
								variables[props.linkField] = props.entityId
							}

							// Submit to server
							createNote({
								variables: {
									CreateNoteInput: variables,
								},
							})
							setSelectedImage({})
						}
					}}
				>
					<div className="row">
						<FormControl sx={{paddingRight: '1em'}}>
							<Label id="type-label" style={typeStyle} disableAnimation shrink>
								Type
							</Label>
							<Select
								IconComponent={ExpandMoreRoundedIcon}
								name="type"

								className="padded-select"
								labelId="type-label"
								input={<Dropdown />}
								value={noteTypes ? note.type || '' : ''}
								onChange={(e) => {
									setNote({
										...note,
										type: e.target.value,
									})
								}}
							>
								{sortedTypes &&
									sortedTypes.map((type) => (
										<MenuItem key={type.id} value={type.id}>
											{type.value}
										</MenuItem>
									))}
							</Select>
						</FormControl>

						<FormControl sx={{paddingRight: '1em'}}>
							<Label id="date-label" style={typeStyle} disableAnimation shrink>
								Date
							</Label>

							<MobileDatePicker
								inputFormat="MMM do, yyyy"
								disableMaskedInput={true}
								cancelText={null}
								name="date"
								inputVariant="outlined"
								style={{ marginTop: '17px' }}
								className="MUIDatePicker"
								variant="dialog"
								showTodayButton
								todayLabel="Today"
								value={note.date_time}
								renderInput={({ inputRef, inputProps, InputProps }) => {

									const newProps = { ...inputProps}

									newProps.readOnly = false

									return (
										<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
									)}}
								onChange={(date) => {
									let _date = new Date(date)
									let newDate = new Date()

									newDate.setDate(_date.getDate())
									newDate.setFullYear(_date.getFullYear())
									newDate.setMonth(_date.getMonth())

									setNote({
										...note,
										date_time: newDate,
									})
								}}
							/>
						</FormControl>

						<FormControl sx={{paddingRight: '1em'}}>
							<Label id="time-label" style={typeStyle} disableAnimation shrink>
								Time
							</Label>

							<MobileTimePicker
								disableMaskedInput={true}
								cancelText={null}
								name="time"
								showTodayButton
								style={{ marginTop: '17px' }}
								todayLabel="now"
								renderInput={({ inputRef, inputProps, InputProps }) => {

									const newProps = { ...inputProps}

									newProps.readOnly = false

									return (
										<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
									)}}
								value={note.date_time}
								onChange={(time) => {
									let newDate = new Date(note.date_time)
									newDate.setTime(time)

									setNote({
										...note,
										date_time: newDate,
									})
								}}
							/>
						</FormControl>

						<FormControl sx={{paddingRight: '1em'}}>
							<Label id="user-label" style={typeStyle} disableAnimation shrink>
								User
							</Label>
							<Autocomplete
								disableClearable
								classes={classes2}
								style={{ marginTop: '1.5em' }}
								open={optionsDropdown}
								isOptionEqualToValue={(option, value) => {
									return option.id == value.id
								}}
								forcePopupIcon
								filterSelectedOptions
								popupIcon={<ExpandMoreRoundedIcon />}
								value={selection ?? null}
								onOpen={() => {
									setOptionsDropdown(true)
								}}
								onClose={() => {
									setOptionsDropdown(false)
								}}
								getOptionLabel={(option) => {
									return option?.first_name + ' ' + option?.last_name
								}}
								options={options || []}
								loading={optionsListLoading}
								onChange={(event, value) => {
									setSelection(value)
									setNote({
										...note,
										user: value,
									})
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										fullWidth
										placeholder="-"
										InputProps={{
											...params.InputProps,
											style: {
												paddingTop: 3.5,
												paddingBottom: 3.5,
											},
											endAdornment: (
												<React.Fragment>
													{optionsListLoading ? (
														<CircularProgress color="inherit" size={20} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</FormControl>

						{ !props.bulk && <FormControl style={{ maxWidth: '9em' }}>
							<Label id="user-label" style={typeStyle} disableAnimation shrink>
								Image
							</Label>

							<input
								accept="image/*"
								style={{ display: 'none' }}
								id="icon-button-file"
								type="file"
								multiple={true}
								onChange={handleUploadClick}
							/>

							<label htmlFor="icon-button-file" style={{ paddingTop: '1.75em' }}>
								<ChooseImageButton
									style={{ height: '2.8em', width: '100%' }}
									color="primary"
									variant="contained"
									aria-label="upload picture"
									component="span"
								>
									Choose a File
								</ChooseImageButton>
							</label>
						</FormControl> }
					</div>

					<div
						style={{
							display: 'flex',
							backgroundColor: getBackgroundColor(),
							marginLeft: !noImages ? '-2em' : null,
							marginRight: !noImages ? '-2em' : null,
							marginBottom: !noImages ? '0.5em' : null,
							marginTop: !noImages ? '1em' : null,
							padding: !noImages ? '0.5em 2em' : '0',
						}}
					>
						<div
							style={{
								display: 'flex',
								overflowX: 'auto',
								marginRight: !noImages ? '1em' : 0,
								alignItems: 'center',
							}}
						>
							{note.images
								?.filter((i) => !i.delete)
								.map((image) => (
									<div key={image.id || image.key}>
										<img
											style={{
												maxHeight: '10em',
												marginRight: '.7em',
											}}
											className={image.key === selectedImage?.key ? classes.selected : classes.image}
											src={image.src || image.imgUrl}
											alt={image.alt_text || image.filename}
											onClick={() => {
												setSelectedImage(image)
											}}
										/>
									</div>
								))}
						</div>
					</div>

					<div
						className={
							attempt && (!note.text || note.text?.length === 0 || !note.text.replace(/\s/g, '').length)
								? 'error'
								: null
						}
					>
						<FormControl className={classes.searchBar} style={{ width: '100%' }}>
							<Label style={typeStyle} disableAnimation shrink>
								Note
							</Label>
							<TextBox
								name="search"
								multiline
								value={note.text}
								rows="4"
								onChange={(e) => {
									setNote({
										...note,
										text: e.target.value,
									})
								}}
							/>
						</FormControl>
					</div>

					<div style={{marginTop: '1em'}}>
						<FormControlLabel
							control={
								<CriticalCheckbox
									checked={note.is_critical || false}
									color="secondary"
									onChange={(event, value) => {
										setNote({
											...note,
											is_critical: value,
										})
									}}
									inputProps={{ 'aria-label': 'critical checkbox' }}
								/>
							}
							label="Critical"
						/>

						{!noPrivate ? <FormControlLabel
							control={
								<Checkbox
									checked={note.is_private || false}
									color="secondary"
									onChange={(event, value) => {
										setNote({
											...note,
											is_private: value,
										})
									}}
									inputProps={{ 'aria-label': 'private checkbox' }}
								/>
							}
							label="Private"
						/> : null}
						{selectedImage?.key ?
							<Button
								variant="contained"
								className={classes.button}
								startIcon={<DeleteIcon />}
								style={{
									backgroundColor: '#CC3333',
									color: 'white',
									marginRight: '1em'
								}}
								onClick={() => {
									let temp = note.images.find(e => e.key === selectedImage.key)
									temp.delete = true
									let tempList = note.images.map(e => e.key === temp.key ? temp : e)
									setNote(note => ({
										...note,
										images: tempList
									}))
									setSelectedImage({})
								}}
							>
							Remove Image
							</Button>
							: null}

						<SearchButton variant="contained" size="small" type="submit" style={{ float: 'right', backgroundColor: '#4465D1' }}>
							Save
						</SearchButton>
						<CancelButton
							variant="contained"
							size="small"
							type="reset"
							style={{ float: 'right', marginRight: '1em',color: '#FFFFFF', backgroundColor: '#cc3333' }}
							onClick={() => {
								setSelection(options.find((item) => item.id == userAuthentication.user?.id))
								setAttempt(false)
								setNote({
									type: props.noteType || DEFAULT_NOTE_TYPE,
									date_time: new Date(),
									user: userAuthentication.user?.id,
									is_critical: false,
									text: '',
									images: [],
								})
								closeSnackbar()
								setSelectedImage({})
							}}
						>
							Reset
						</CancelButton>
					</div>
				</form>
			</Paper>
		</React.Fragment>
	)
}

export default withApollo(NewObjectNote)
