import React, { useCallback, useContext } from 'react'
import { CREATE_NOTE as CREATE_ART_NOTE } from '../../Art/ArtPiece/Notes/Queries'
import { CREATE_NOTE as CREATE_ARTIST_NOTE } from '../../Artists/Artist/Notes/Queries'
import { CREATE_NOTE as CREATE_CONTACT_NOTE } from '../../Contacts/Contact/Notes/Queries'
import { CREATE_NOTE as CREATE_DEAL_NOTE } from '../../Deals/Deal/Notes/Queries'
import { CREATE_NOTE as CREATE_LISTING_NOTE } from '../../Listings/Listing/Notes/Queries'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { DispatchContext } from '../../store'
import { ART_NOTE_TYPE, DEAL_NOTE_TYPE, DEFAULT_NOTE_TYPE } from './constants'
import NewObjectNote from './NewObjectNote'

const types = {
	Art: {
		mutation: CREATE_ART_NOTE,
		mutationName: 'createArtNote',
		linkField: 'art_id',
		noteType: ART_NOTE_TYPE
	},
	Artist: {
		mutation: CREATE_ARTIST_NOTE,
		mutationName: 'createArtistNote',
		linkField: 'artist_id',
		noteType: DEFAULT_NOTE_TYPE
	},
	Contact: {
		mutation: CREATE_CONTACT_NOTE,
		mutationName: 'createContactNote',
		linkField: 'contact_id',
		noteType: DEFAULT_NOTE_TYPE
	},
	Deal: {
		mutation: CREATE_DEAL_NOTE,
		mutationName: 'createDealNote',
		linkField: 'deal_id',
		noteType: DEAL_NOTE_TYPE	
	},
	Listing: {
		mutation: CREATE_LISTING_NOTE,
		mutationName: 'createListingNote',
		linkField: 'listing_id',
		noteType: DEFAULT_NOTE_TYPE	
	}
}

/**
 * @typedef {Object} BulkObjectNoteProps
 * @property {boolean} open 
 * @property {function} close
 * @property {Object[]} rows
 */

/**
 * BulkObjectNote
 * @param {BulkObjectNoteProps} props 
 * @returns 
 */
export default function BulkObjectNote (props) {

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	if (!props?.rows?.length) return null

	const ids = props.rows?.map(r => r.id)

	const typeName = props.rows[0].__typename
	if (!props.rows.every(r => r.__typename === typeName)) {
		openSnackbar(severity.ERROR, 'Please only include one type of row.')
	}
	const type = types[typeName] || {}


	return <TransitionsModal
		open={props.open}
		close={props.close}
		noPadding={true}
	>
		<NewObjectNote 
			entityId={ids}
			notes={[]}
			setNotes={() => props.close()}
			notesLoading={false}
			mutation={type.mutation}
			mutationName={type.mutationName}
			linkField={type.linkField}
			state={{}}
			setState={() => {}}
			criticalNotes=""
			privateEntity={false}
			noteType={type.noteType}
			bulk={true}
		/>
	</TransitionsModal>
}
