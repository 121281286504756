/* eslint-disable eqeqeq */
import React, { useEffect, useContext } from 'react'
import {
	ThemeProvider,
	StyledEngineProvider,
	TableContainer,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Avatar,
	Tooltip,
	Table,
} from '@mui/material'

import privateObjectTheme from '../styles/muiThemes/privateObjectTheme'
import QuickView from './QuickView'
import { Skeleton, AvatarGroup } from '@mui/material'
import { withApollo } from '@apollo/client/react/hoc'
import { GET_ART_GALLERY_ACCESS_AND_USERS, GET_DEAL_GALLERY_ACCESS_AND_USERS, GET_USERS_WITH_ACCESS, GET_USERS_WITH_PUBLIC_ACCESS_PERMISSION } from './Queries'
import { permissionValues, permissions } from '../constants/permissions'
import {StyledBadge} from '../styles/makesStyles'
import { DispatchContext } from "../store"
import { severity } from "../Snackbar/CustomizedSnackbar"
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Collapse from '@mui/material/Collapse'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useApolloClient } from '@apollo/client'
import { Close } from '@mui/icons-material'


export const UserTable = ({rows, heading, lazyLoad, entity, objectTypeId, setRows, hideAssistants = false}) => {

	const client = useApolloClient()

	if (!rows.length && !lazyLoad) return <></>

	return (
		<>

			<div style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center'
			}}>
				<h3 style={{ paddingBottom: '0.7em', paddingTop: '0.5em' }}>{heading}</h3>

				{lazyLoad ? <IconButton 
					onClick={() => {
						if (entity.publicAccess === null && !rows.length) 
							client
								.query({ query: GET_USERS_WITH_PUBLIC_ACCESS_PERMISSION, variables: { objectTypeId }})
								.then(({ data }) => {
									if (entity.publicAccess === null) {
										setRows(data?.getUsersWithPublicAccessPermission?.users || [])
									}
								})
						else setRows([])
					}}
					color="primary" aria-label="expand" component="span" style={{
						height: 'min-content',
					}}>
					{rows.length ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
				</IconButton> : null}

			</div>

			{rows.length ? <TableContainer style={{ width: '100%'}}>
				<Table style={{margin: '0'}}>
					<TableHead>
						<TableRow>
							<TableCell>User</TableCell>
							{!hideAssistants ? <TableCell>Assistants</TableCell> : null}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((user) => {
								
							return (
								<TableRow key={`user-${user.id}`}>
									<TableCell>
										<div style={{ display: 'flex' }}>
											{user.permissions?.find(e => {
												if (e.permission_id == permissions.PRIVATE_OBJECT_ADMIN
														&& e.permission_value_id == permissionValues.YES) return true
												else return false
											}) ?
												<StyledBadge
													className="qv-sp-badge"
													overlap="circular"
													anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
													variant="dot"
												>
													<Avatar
														style={{ width: '40px', height: '40px' }}
														alt={`${user.first_name} ${user.last_name}`}
														src={user.imgUrl}
													>
													</Avatar>
												</StyledBadge>
												:
												<Avatar
													style={{ width: '40px', height: '40px' }}
													alt={`${user.first_name} ${user.last_name}`}
													src={user.imgUrl}
												>
												</Avatar>}
					
											<div style={{marginLeft: '1em'}}>
												<div style={{width:'100%'}}>{user.first_name} {user.last_name}</div>
												<div style={{width:'100%'}}>{user.gallery}</div>
											</div>
										</div>

									</TableCell>
									{!hideAssistants ? 
										<TableCell style={{textAlign: "left"}}>
											{user.assistants.filter((_user) => {
												const PrivateObjectAccess = '6'
												const accessPerm = _user.permissions.find(
													(p) => p.permission_id === PrivateObjectAccess
												)
												return accessPerm?.permission_value_id === '1' // YES
											})?.length === 0 ? (
													<span style={{paddingLeft: '16px'}}>-</span>
												) : (
													<AvatarGroup max={2} style={{justifyContent: 'flex-start'}}>
														{user.assistants
															.filter((_user) => {
																const PrivateObjectAccess = '6'
																const accessPerm = _user.permissions.find(
																	(p) => p.permission_id === PrivateObjectAccess
																)
																return accessPerm?.permission_value_id === '1' // YES
															})
															.map((assistant) => (
																<Tooltip
																	key={assistant.id}
																	title={`${assistant.first_name} ${assistant.last_name}`}
																	arrow
																>
																	<Avatar
																		alt={`${assistant.first_name} ${assistant.last_name}`}
																		src={assistant.imgUrl}
																	>
																		{!assistant.image &&
																			assistant.first_name &&
																			assistant.last_name
																			? assistant.first_name[0] + assistant.last_name[0]
																			: null}
																	</Avatar>
																</Tooltip>
															))}
													</AvatarGroup>
												)}
										</TableCell>
										: null}
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer> : null }
		</>
	)
}

const UserAccessQuickView = (props) => {
	const [entity, setEntity] = React.useState({})
	const [loading, setLoading] = React.useState(true)
	const [rows, setRows] = React.useState([])
	const [objectTypeId, setObjectTypeId] = React.useState(false)
	const [galleryAccess, setGalleryAccess] = React.useState([])
	const [open, setOpen] = React.useState(null)

	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({type: 'openSnackBar', payload: {severity, text}})
	}

	React.useEffect(() => {
		if (Object.keys(props.entity || {}).length) {
			setEntity(props.entity)
			setLoading(false)

			const typeName = props.entity?.__typename

			if (typeName === 'Art') setObjectTypeId(1)
			else if (typeName === 'Contact' ||
				typeName === 'DealContact') setObjectTypeId(2)
			else if (typeName === 'Deal' || typeName === 'DealEntry') setObjectTypeId(3)
		} else setLoading(true)
	}, [props.entity])

	React.useEffect(() => {
		if (loading || !entity.id || !objectTypeId) return

		if (props.entity.publicAccess === true || objectTypeId == 1) 
			props.client
				.query({ query: GET_USERS_WITH_ACCESS, variables: { objectId: props.idOverride ? entity[props.idOverride] : entity.id, objectTypeId } })
				.then(({ data }) => {
					if (props.entity.publicAccess === true || objectTypeId == 1) 
						setRows(data?.getUsersWithAccess?.users || [])
				})
	}, [entity, entity.id, loading, objectTypeId, props.client, props.entity?.publicAccess, props.idOverride])

	useEffect(() => {
		if (props.entity?.__typename === "Deal" || props.entity?.__typename === "DealEntry") {

			props.client.query({ 
				query: GET_DEAL_GALLERY_ACCESS_AND_USERS,
				variables: { id: props.entity?.__typename === "DealEntry" ? props.entity.deal_id : props.entity.id }
			}).then(({ data }) => {

				if (data?.getDealGalleriesAndUsers?.success) {
					setGalleryAccess(data?.getDealGalleriesAndUsers?.galleriesAndUsers)
				} else {
					openSnackbar(severity.ERROR, "Could not retrieve deal galleries and users.")
				}

			}).catch(error => {
				console.error(error)
				openSnackbar(severity.ERROR, "Could not retrieve deal galleries and users.")
			})
		} else if (props.entity?.__typename === 'Art') {
			props.client.query({
				query:  GET_ART_GALLERY_ACCESS_AND_USERS,
				variables: { id: props.entity.id }
			}).then(({ data }) => {
				if (data?.getArtGalleriesAndUsers?.success) {
					setGalleryAccess(data?.getArtGalleriesAndUsers?.galleriesAndUsers)
				} else {
					openSnackbar(severity.ERROR, "Could not retrieve art galleries and users.")
				}
			}).catch(error => {
				console.error(error)
				openSnackbar(severity.ERROR, "Could not retrieve art galleries and users.")
			})
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.entity?.__typename, props.entity?.deal_id, props.entity?.id])


	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={privateObjectTheme}>
				<QuickView className={'quick-view-main dark-theme'}>
					<div className="quick-view-inner">
						<header style={{ display: 'flex', justifyContent: 'space-between' }}>
							<h2 className={'quick-view-title-light'} style={{ textAlign: 'left' }}>
								{entity?.code_name || entity?.dynamic_generated_codename || entity?.title || [entity.first_name, entity.last_name].filter(e => e).join(" ") || ((entity?.__typename === "Deal" || entity?.__typename === "DealEntry") && `Deal ${props.idOverride ? entity[props.idOverride] : entity.id}`)  || (
									<>
										{loading ? (
											<Skeleton animation={!entity ? 'wave' : false} variant="text" width="8em" />
										) : (
											<span>-</span>
										)}
									</>
								)}
							</h2>
							{props.onClose && <span>
								<IconButton size="large" 
									sx={{ height: '35px', width: '35px' }}
									onClick={props.onClose}
								>
									<Close />
								</IconButton>
							</span>}
						</header>

						<div style={{
							display: 'flex',
							flexDirection: 'column',
							overflow: 'scroll',
							width: '100%'
						}}>

							{props.entity.is_gallery_private && 
								['Deal', 'DealEntry', 'Art'].includes(props.entity?.__typename) ? (
									<div>

										<span>
											Users must have access to the following galleries{props.entity?.is_private ? <>&nbsp;<i>and</i> be on the restricted access list</> : null}.
										</span>

										<h3 style={{ paddingBottom: '0.7em', paddingTop: '1em' }}>Galleries with Access</h3>

										<TableContainer style={{ width: '100%', overflow: 'hidden'}}>
											<Table size="small" style={{margin: '0'}}>
												<TableHead>
													<TableRow>
														<TableCell style={{width: '1em'}}></TableCell>
														<TableCell></TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{galleryAccess.map((galleryAndUsers, index) => {
														const currentId = galleryAndUsers.gallery.id

														return (
															<>
																<TableRow key={`gallery-and-users-${index}`}>
																	<TableCell>
																		<IconButton
																			aria-label="expand row"
																			size="small"
																			disabled={galleryAndUsers.users?.length == 0}
																			onClick={() => setOpen(galleryAndUsers.gallery.id == open ? null : currentId)}
																		>
																			{open == currentId ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
																		</IconButton>
																	</TableCell>
																	<TableCell>{galleryAndUsers.gallery.value}</TableCell>
																</TableRow>

																<TableRow>
																	<TableCell style={{ padding: 0 }} colSpan={2}>
																		<Collapse in={open == currentId} timeout="auto" unmountOnExit>
																			<Table size="small">
																				<TableBody>
																					{galleryAndUsers.users.map((user) => {
																						return (
																							<TableRow key={`user-${user.id}`}>
																								<TableCell>
																									<div style={{ display: 'flex' }}>
																										<Avatar
																											style={{ width: '40px', height: '40px' }}
																											alt={`${user.first_name} ${user.last_name}`}
																											src={user.imgUrl}
																										>
																										</Avatar>
						
																										<div style={{marginLeft: '1em'}}>
																											<div style={{width:'100%'}}>{user.first_name} {user.last_name}</div>
																											<div style={{width:'100%'}}>{user.gallery}</div>
																										</div>
																									</div>

																								</TableCell>
																							</TableRow>
																						)
																					})}
																				</TableBody>
																			</Table>
																		</Collapse>
																	</TableCell>
																</TableRow>
															</>
														)
													})}
												</TableBody>
											</Table>
										</TableContainer>
									</div>
								) : null }

							{props.entity.is_private ? 				
								<>
									<UserTable 
										rows={rows}
										heading='Users With Access'
										lazyLoad={false}
										entity={props.entity}
										objectTypeId={props.objectTypeId}
										setRows={setRows}
									/>

								</>
								: null}					
								
						</div>
					</div>
				</QuickView>
			</ThemeProvider>
		</StyledEngineProvider>
	)
}

export default withApollo(UserAccessQuickView)
