import React from 'react'
import Paper from '@mui/material/Paper'
import makeStyles from '@mui/styles/makeStyles'
import { Pagination, ToggleButton } from '@mui/material'
import { ITEMS_PER_HOME_PAGE_WIDGET } from '../constants/values'
import { Tooltip } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'

/** styles for this component */
export const useStyles = makeStyles((theme) => ({
	padding: {
		padding: '0 2em 0 2em',
		height: '100%',
	},
	paper: {
		padding: '1em 0',
		margin: '1em 0.5em 1em 0.5em',
		overflow: 'hidden',
	},
	cardContent: {
		overflow: 'auto',
		height: '100%',
	},
}))

export default function HomePageCard(props) {
	// custom styles
	const classes = useStyles()

	return (
		<Paper className={classes.paper} elevation={3}>
			{props.title ? (
				<h1
					className="card-title"
					style={{ paddingLeft: '16px', marginBottom: '0px' }}
				>
					{props.title + `${props.totalItems ? ` (${props.totalItems})` : ''}`}

					{props.checkBox ? <Tooltip arrow placement="top" title={props.checkBoxDescription}>
						<ToggleButton
							value="check"
							size="small"
							selected={props.checkbox}
							onChange={() => {
								props.setCheckbox(!props.checkbox)
								props.handleSubmit(0, !props.checkbox)
								props.setActiveStep(0)
							}}
							style={{
								border: 'none'
							}}
						>
							<PersonIcon />
						</ToggleButton>
					</Tooltip> : null}

					{props.paginate && props.steps !== 1 ? (
						<>
							<div className="spacer"></div>
							<Pagination
								style={{
									paddingRight: '1em',
								}}
								size="small"
								count={props.steps}
								siblingCount={0}
								page={props.activeStep + 1}
								onChange={(event, page) => {
									props.setActiveStep(page - 1)
									props.handleSubmit((page - 1) * ITEMS_PER_HOME_PAGE_WIDGET, props.checkbox)
								}}
							/>
						</>
					) : null}
				</h1>
			) : null}

			<div className={classes.cardContent}>
				<div className={classes.padding}>{props.children}</div>
			</div>
		</Paper>
	)
}
