/* eslint-disable eqeqeq */
import React, { useState, useContext, useCallback } from 'react'
import {
	ChooseImageButton,
	SubmitButton,
	CancelButton,
} from '../../../input/Buttons'
import UserAvatar from '../../../common/components/UserAvatar'
import {
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	IconButton,
	FormControl,
	Select,
	MenuItem,
	Skeleton,
} from '@mui/material'
import { useMutation } from '@apollo/client'
import { DELETE_ART_FINANCIAL_HISTORY, ADD_ART_FINANCIAL_HISTORY } from './Queries'
import { LookupContext, DispatchContext } from '../../../store'
import DeleteIcon from '@mui/icons-material/Delete'
import { formatter, formatDate } from '../../../common/helpers'
import ConfirmationDialog from '../../../navigation/ConfirmationDialog/ConfirmationDialog'
import { severity } from '../../../Snackbar/CustomizedSnackbar'
import TransitionsModal from '../../../navigation/TransitionsModal/TransitionsModal'
import Label from '../../../input/Label'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import Dropdown from '../../../input/Dropdown/Dropdown'
import NumberFormat from 'react-number-format'
import TextBox from '../../../input/Text/TextBox'
import { MobileDatePicker } from '@mui/x-date-pickers-pro'
import { Pagination } from '@mui/material'
import clsx from 'clsx'
import TextBoxThinForDatePicker from '../../../input/Text/TextBoxThinForDatePicker'


export default function ArtFinancialHistory(props) {
	const [confirmation, setConfirmation] = useState(false)
	const [currentHistory, setCurrentHistory] = useState(null)
	const [open, setOpen] = useState(false)
	const [attempt, setAttempt] = useState(false)
	const [input, setInput] = useState({
		art_id: props.artPiece?.id,
		currency_id: 1,
		art_financial_type_id: 1,
		value: '',
		value_changed_at: new Date()
	})

	React.useEffect(() => {
		setInput({
			...input,
			art_id: props.artPiece?.id
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.artPiece])
 
	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const lookup = useContext(LookupContext)

	const currencies = lookup.data?.getCurrencies

	const allTypes = lookup.data?.getArtFinancialTypes

	const types = lookup.data?.getArtFinancialTypes

	const canAddHistory = types?.length >= 1

	const [deleteHistory] = useMutation(DELETE_ART_FINANCIAL_HISTORY)
	const [addHistory] = useMutation(ADD_ART_FINANCIAL_HISTORY)
	return <>
		<Paper
			id="art-caption"
			style={{ overflow: 'auto' }}
			data-testid="card-financial-history"
			className="qv-margin"
		>

			<header
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					marginBottom: '-1em'
				}}
			>
				<h1 className="card-title hide-page-numbers" style={{ width: '100%' }}>
					<span>Financial History {(props.totalItems) ? "("+props.totalItems+")" : ""}</span>
					<div className="spacer"></div>

					<Pagination
						size="small"
						count={props.steps}
						siblingCount={0}
						page={props.activeStep + 1}
						onChange={(_, page) => {
							props.setActiveStep(page - 1)
							props.refetch({variables: {
								id: props.artPiece?.id,
								cursor: (page - 1) * props.limit,
								limit: props.limit
							}})
						}}
					/>

					{canAddHistory ? 
						<ChooseImageButton
							variant="contained"
							color="secondary"
							size="small"
							onClick={() => {
								setOpen(true)
							}}
						>
							Add
						</ChooseImageButton>
						: null}
				</h1>
			</header>
			<TableContainer>
				<Table aria-label="simple table" size="medium">
					<TableHead>
						<TableRow>
							<TableCell>User</TableCell>
							<TableCell>Date</TableCell>
							<TableCell>Type</TableCell>
							<TableCell>Price</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{props?.history?.length  ?
							props.history?.filter(f => f.id)?.map((row) => {
								return (
									<TableRow key={row.id}>
										<TableCell>
											<UserAvatar sp={row.modified_by_user} />
										</TableCell>
										<TableCell>
											{formatDate(row.value_changed_at, "MMM do yyyy")}
										</TableCell>
										<TableCell>{allTypes?.find(e => e.id == row.art_financial_type_id)?.value}</TableCell>
										<TableCell>
											{formatter(
												currencies?.find(
													(e) => e.id == row.currency_id
												).label || 'USD'
											).format(row.value)}
										</TableCell>
										<TableCell className="no-padding">
											<IconButton
												onClick={() => {
													setConfirmation(true)
													setCurrentHistory(row.id)
												}}
												title="Delete"
												size="large">
												<DeleteIcon
													style={{ color: 'grey' }}
												/>
											</IconButton>
										</TableCell>
									</TableRow>
								)
							}) :
							
							<>
								{ props.loading ?
									<TableRow>
										<TableCell> <Skeleton
											variant="circular"
											width={40}
											height={40}
											animation="wave"
										/> </TableCell>
										<TableCell><Skeleton animation="wave"/></TableCell>
										<TableCell><Skeleton animation="wave"/></TableCell>
										<TableCell><Skeleton animation="wave"/></TableCell>
										<TableCell></TableCell>

									</TableRow> : 
									<TableRow>
										<TableCell>
											 No financial records on file
										</TableCell>
										<TableCell></TableCell>
										<TableCell></TableCell>
										<TableCell></TableCell>
										<TableCell></TableCell>
									</TableRow>
								}

							</>
						}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>

		<ConfirmationDialog
			open={confirmation}
			handleClose={() => setConfirmation(false)}
			title={'Are you sure?'}
			acceptText={'Delete'}
			text={'This will delete this financial history.'}
			onYes={() => {
				deleteHistory({
					variables: {
						id: currentHistory,
					},
				})
					.then((response) => {
						if (response && response.errors) {
							openSnackbar(
								severity.ERROR,
								'Error - Unauthorized'
							)
						} else if (
							response &&
							response.data?.deleteArtFinancialHistory?.success === false
						) {
							openSnackbar(
								severity.ERROR,
								'There was an error deleting this history.'
							)
						} else {
							openSnackbar(
								severity.SUCCESS,
								'Successfully deleted history.'
							)
							props.refetch({variables: {
								id: props.artPiece?.id,
								cursor: null
							}})
						}
					})
					.catch((error) => {
						console.log(error)
						openSnackbar(
							severity.ERROR,
							'There was an error deleting this history.'
						)
					})
			}}
		/>

		<TransitionsModal
			className="phone-modal"
			open={open}
			close={() => setOpen(false)}
		>
			<div className={clsx({
				'private-object': props.private, 
			})}>
				<h1 className="card-title">
					<span>Add Financial History</span>
				</h1>

				<div
					style={{
						width: '100%',
						paddingBottom: '1em',
						marginTop: '1em',
					}}
				>
					<div>
						<FormControl style={{ width: '16.75em', marginRight: '1em'}} className="padded-select">
							<Label id="currency-label" disableAnimation shrink>
							Type
							</Label>

							<Select
								IconComponent={ExpandMoreRoundedIcon}
								name="currency"
								
								labelId="currency-label"
								input={<Dropdown />}
								value={input.art_financial_type_id}
								onChange={(e) => {
									setInput({
										...input,
										art_financial_type_id: e.target.value
									})
								}}
							>
								{types?.map((type) => (
									<MenuItem key={type.id} value={type.id}>
										{type.value}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl style={{ width: '16.75em' }} className="padded-select">
							<Label id="currency-label" disableAnimation shrink>
							Currency
							</Label>

							<Select
								IconComponent={ExpandMoreRoundedIcon}
								name="currency"
								
								labelId="currency-label"
								input={<Dropdown />}
								value={input.currency_id}
								onChange={(e) => {
									setInput({
										...input,
										currency_id: e.target.value
									})
								}}
							>
								{currencies?.map((currency) => (
									<MenuItem key={currency.id} value={currency.id}>
										{`${currency.symbol} - ${currency.name}`}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>

					<div>
						<FormControl style={{ width: '16.75em' }}>
							<Label id="currency-label" disableAnimation shrink error={attempt && !input.value}>
							Price
							</Label>

							<NumberFormat
								id="new-art-value"
								customInput={TextBox}
								thousandSeparator
								allowNegative={false}
								value={input.value}
								error={attempt && !input.value}
								onChange={(e) => {
									setInput({
										...input,
										value: e.target.value.replace(/,/g, '')
									})
								}}
								allowLeadingZeros={false}
							/>
						</FormControl>


						<FormControl style={{ marginLeft: '1em' , width: '16.75em'}}>
							<Label
								id="date-label"
								style={{
									fontWeight: 500,
								}}
								disableAnimation
								shrink
							>
							Date
							</Label>

							<MobileDatePicker
								inputFormat="MMM do, yyyy"
								disableMaskedInput={true}
								cancelText={null}
								showTodayButton
								name="date"
								inputVariant="outlined"
								style={{ marginTop: '17px' }}
								className="MUIDatePicker"
								variant="dialog"
								renderInput={({ inputRef, inputProps, InputProps }) => {
								
									const newProps = { ...inputProps}
		
									newProps.readOnly = false
		
									return (
										<TextBoxThinForDatePicker ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
									)}}
								value={input.value_changed_at}
								onChange={(date) => {
									setInput({
										...input,
										value_changed_at: date
									})
								}}
							/>
						</FormControl>
					</div>
					<div style={{marginTop: '2em'}}>

						<SubmitButton
							variant="contained"
							style={{ float: 'right' }}
							type="submit"
							onClick={() => {
								setAttempt(true)

								if (attempt && !input.value) {
									openSnackbar(severity.WARNING, "Please correct field in red.")			

								} else if (!(!input.value)){

									addHistory({
										variables: {
											ArtFinancialHistoryInput: {
												...input,
												value: parseFloat(input.value)
											},
										},
									})
										.then((response) => {
											if (response && response.errors) {
												openSnackbar(
													severity.ERROR,
													'Error - Unauthorized'
												)
											} else if (
												response &&
											response.data?.addArtFinancialHistory
												?.success === false
											) {
												openSnackbar(
													severity.ERROR,
													response.data?.addArtFinancialHistory?.message || 
													'There was an error adding this history.'
												)
											} else {
												openSnackbar(
													severity.SUCCESS,
													'Successfully added history.'
												)

												// Add to card
												props.refetch({variables: {
													id: props.artPiece?.id,
													cursor: null,
													limit: props.limit
												}})
											}
										})
										.catch((error) => {
											console.log(error)
											openSnackbar(
												severity.ERROR,
												'There was an error adding this history.'
											)
										})

									setOpen(false)
									setAttempt(false)
									setInput({
										art_id: props.artPiece?.id,
										currency_id: 1,
										art_financial_type_id: 1,
										value: '',
										value_changed_at: new Date()
									})
								}	
							}}
						>
						Submit
						</SubmitButton>

						<CancelButton
							variant="contained"
							style={{ float: 'right', marginRight: '1em'}}
							onClick={(e) => {
								setOpen(false)
								setAttempt(false)
								setInput({
									art_id: props.artPiece?.id,
									currency_id: 1,
									art_financial_type_id: 1,
									value: '',
									value_changed_at: new Date()
								})
							}}
						>
						Cancel
						</CancelButton>
					</div>
				</div>
			</div>
		</TransitionsModal>
	</>
}
