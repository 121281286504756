/* eslint-disable eqeqeq */
import { GET_GLOBAL_RESERVES } from '../Queries'
import React, { useCallback, useEffect, useRef } from 'react'
import HomePageCard from '../HomePageCard'
import GlobalReserves from './GlobalReserves'
import { withApollo } from '@apollo/client/react/hoc'
import { permissions, permissionValues } from '../../constants/permissions'
import { ITEMS_PER_HOME_PAGE_WIDGET } from '../../constants/values'
import useNavigation from '../../navigation/useNavigation'
import { useLocation, useNavigate } from 'react-router-dom'
import { GET_GLOBAL_RESERVES_NAV } from '../../navigation/Queries'

const GlobalReservesParent = (props) => {
	
	// like the "active" - but we're using it inside a callback instead of a effect...
	const mounted = useRef(false)
	useEffect(() => {
		mounted.current = true 
		return () => mounted.current = false
	}, [])

	const { push: pushNav } = useNavigation()
	const navigate = useNavigate()
	const location = useLocation()
	let prevSearch = location.state
	if (prevSearch?.card != 'Global Reserves') {
		prevSearch = null
	}

	// Order of table
	const [cursor, setCursor] = React.useState(prevSearch?.cursor ?? null)
	const [steps, setSteps] = React.useState(prevSearch?.steps ?? 1)
	const [activeStep, setActiveStep] = React.useState(prevSearch?.activeStep ?? 0)
	const [searchLoading, setSearchLoading] = React.useState(false)
	const [totalItems, setTotalItems] = React.useState(prevSearch?.totalItems ?? 0)

	const [reserves, setReserves] = React.useState(prevSearch?.reserves ?? [])

	const user = props.authState?.user
	const userPermissions = user?.permissions


	function saveAndGo(path, row) {
		const state = updateHistory(row)
		const idField = path.includes('art') ? 'art_id' : 'deal_id'
		pushNav({ 
			url: location.pathname, 
			state,
			query: GET_GLOBAL_RESERVES_NAV,
			getId: (r => r?.[idField])
		}, state.variables.cursor)
		navigate(path, { state })
	}
	// Save Search
	function updateHistory (id) {
		const savedSearch = {
			cursor, reserves,
			steps, activeStep, totalItems,
			searchPage: location.pathname,
			card: 'Global Reserves'
		}
		const offset = reserves.findIndex(a => a.id == id)
		const nextcursor = activeStep * ITEMS_PER_HOME_PAGE_WIDGET + offset
		const variables = {
			id: props.id,
			cursor: nextcursor,
			limit: 3,
		}
		savedSearch.variables = variables
		navigate(location.pathname, { state: savedSearch })
		return savedSearch
	}

	const handleSubmit = useCallback((cursor = 0) => {

		setSearchLoading(true)

		const variables = {
			cursor,
			limit: ITEMS_PER_HOME_PAGE_WIDGET,
		}

		const dealPermission = userPermissions && userPermissions.find((element) => element.permission_id == permissions.DEALS)
		const artPermission = userPermissions && userPermissions.find((element) => element.permission_id == permissions.ART)

		// Check if current user has valid permissions for viewing global reserves
		if (dealPermission?.permission_value_id >= permissionValues.VIEW_ONLY &&
			artPermission?.permission_value_id >= permissionValues.VIEW_ONLY) {
			props.client
				.query({
					query: GET_GLOBAL_RESERVES,
					variables
				})
				.then(result => {
					if (!mounted.current) return 				
					setReserves(result.data.getGlobalReserves?.items)
					setCursor(result.data.getGlobalReserves?.cursor)
					setTotalItems(result.data.getGlobalReserves?.totalItems)

					if (result.data.getGlobalReserves.items.length < 1) setSteps(1)
					else setSteps(Math.ceil(result.data.getGlobalReserves.totalItems / ITEMS_PER_HOME_PAGE_WIDGET))

					setSearchLoading(false)
				})
				.catch(console.error)
		} else {
			setReserves([])
			setCursor(null)
			setSteps(1)
			setSearchLoading(false)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.client, userPermissions])


	// Search on page load
	useEffect(() => {
		handleSubmit(cursor)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<HomePageCard
			title="Global Reserves"
			paginate
			handleSubmit={handleSubmit}
			cursor={cursor}
			setCursor={setCursor}
			steps={steps}
			setSteps={setSteps}
			activeStep={activeStep}
			setActiveStep={setActiveStep}
			totalItems={totalItems}
		>
			<GlobalReserves
				setCurrentArts={setReserves}
				currentArts={reserves}
				loading={searchLoading || props.userLoading || !props.authState?.user?.id}
				user={props.authState?.user} // Will be used later for salespersons mapping
				saveAndGo={saveAndGo}
			/>
		</HomePageCard>
	)
}

export default withApollo(GlobalReservesParent)
