import React, { useCallback, useContext, useEffect, useState } from 'react'
import { gql } from '@apollo/client'
import { useLazyQuery } from '@apollo/client'
import { stripMeta } from '../../../common/helpers'
import FileGroups from '../../../Files/FileGroups'
import { DispatchContext } from '../../../store'
import { severity } from '../../../Snackbar/CustomizedSnackbar'
import { UPDATE_DEAL } from '../../Queries'
import { useMutation } from '@apollo/client'
import { fileData } from '../../../common/fragments'
import { useParams } from 'react-router-dom'

const fileQuery = gql`
	query getDeal(
		$id: ID!
		$thumbnailResolution: String = "128x128"
	) {
		getDeal(id: $id) {
			id
			files {
				...fileData
			}
		}
	}
	${fileData}
`

export default function DealFilesPage(props) {

	const [files, setFiles] = useState([])

	const params = useParams()

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	let deal = props.state?.getDeal || {}
	let setDeal = (deal) => props.setState({ getDeal: deal })

	const [refetch, { loading, error }] = useLazyQuery(fileQuery, {
		onCompleted: (data) => {
			setFiles(data?.getDeal?.files || [])
		},
		onError: error => {
			console.log(error)
			openSnackbar(severity.ERROR, "Error retrieving files.")
		}
	})


	const [
		updateDeal, { error: updateError }
	] = useMutation(UPDATE_DEAL, {
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, "Could not update.")
		},
		onCompleted: (response) => {
			if (response.updateDeal.success === true) {
				refetch({ variables: { id: props.id } })
				openSnackbar(severity.SUCCESS, response.updateDeal.message)
				setDeal(response.updateDeal.deal)
			} else {
				// On failure, reset listingInput state, don't touch listing
				// state and show error
				openSnackbar(severity.ERROR, response.updateDeal.message)
			}
		}
	})

	// update function
	const update = (fields) => {
		let variables = stripMeta(fields)
		variables.id = deal.id

		updateDeal({
			variables: {
				DealInput: variables
			}
		})
	}

	useEffect(() => {
		setFiles([])
		if (params.id && !props.findMode)
			refetch({ variables: { id: params.id } })
	}, [params.id, refetch, props.tabbedPageFileRefetch, props.findMode])

	return (!props.findMode && props.id > 0) && (
		<FileGroups
			objectId={deal?.id}
			loading={props.loading || loading}
			error={props.error || error || updateError}
			files={files}
			setFiles={setFiles}
			onSave={update}
			fileObjectId={props.fileObjectId}
			options={props.options}
			refetch={refetch}

			tabbedPageFileRefetch={props.tabbedPageFileRefetch}
			setTabbedPageFileRefetch={props.setTabbedPageFileRefetch}
			
			atPrivateObject={deal.is_private}
		/>
	)
}
