/* eslint-disable eqeqeq */
import React, { useContext } from 'react'
import QuickView from '../QuickView/QuickView'
import { withApollo } from '@apollo/client/react/hoc'

import TextBox from '../input/Text/TextBoxThin'
import Label from '../input/Label'
import Dropdown from '../input/Dropdown/Dropdown'
import { FormControl, Select, MenuItem } from '@mui/material'
import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers'
import { format } from 'date-fns'
import { SubmitButton, CancelButton } from '../input/Buttons'

import { severity } from '../Snackbar/CustomizedSnackbar'
import { DispatchContext } from '../store'
import { CREATE_LISTING } from './Queries.js'

import { useMutation } from '@apollo/client'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

import { LookupContext } from '../store'
import { autoCompleteStyles } from '../styles/makesStyles'
import AvatarInput from '../common/AvatarInput'
import { DEFAULT_IMAGE_USAGE_RIGHT } from '../Notes/ObjectNotes/constants'
import { useNavigate } from 'react-router-dom'
import ProjectCodeInput from '../common/ProjectCodeInput'

const LISTING_IMAGE_TYPE_ID = 2


const getOptionLabel = (option) => option.tzoffset + " " + option.value + " ("+option.abbr+")"

export const filterOptions = createFilterOptions({
	ignoreCase: true,
	trim: true,
	stringify: option => getOptionLabel(option)
})


/**
 * @typedef listingQuickViewProps
 * @property {import('./Contacts').Contact} contact
 */

/**
 * @param {listingQuickViewProps} props
 */
function ListingQuickView(props) {

	const lookup = useContext(LookupContext)
	const listingTypes= lookup.data?.getListingTypes
	const timezones = lookup.data?.getTimezones
	
	const navigate = useNavigate()

	const typeStyle = {
		fontWeight: 500,
	}

	// Other useState hooks
	const [attempt, setAttempt] = React.useState(false)

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}
	const [createListing, {loading}] = useMutation(props.createListingMutation || CREATE_LISTING)
	const [listingInput, setListingInput] = React.useState({
		type_id: '', // required
		title: '', // required
		subtitle: '',
		about: '',
		location: '',
		is_active: true,
		start_at: new Date(),
		end_at: null,
		start_time: null,
		end_time: null,
		time_zone: null,
		project_code: null,
		images: []
	})


	const projectCodeValue = listingInput.project_code

	const requiresDate = (listingInput.type_id == 1 // Exhibition 
		|| listingInput.type_id == 2) // Art Fair

	return (
		<QuickView id="create-listing-qv" className="quick-view-main">

			<form
				className="create-listing-form"
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					height: '100%',
				}}
				onSubmit={(e) => {
					e.preventDefault()
					setAttempt(true)

					if (attempt && (!listingInput.type_id || !listingInput.title || 
						(requiresDate && (!listingInput?.start_at || !listingInput?.end_at)) ||
						(['1', '2'].includes(listingInput?.type_id) && !listingInput?.project_code)
					)) {
						openSnackbar(
							severity.WARNING,
							'Please fill fields in red.'
						)
					} else if (!(!listingInput.type_id || !listingInput.title)) {

						let variables = {...listingInput}
						delete variables.time_zone

						if (listingInput.start_at)
							variables.start_at = format(
								new Date(listingInput.start_at),
								'yyyy-MM-dd'
							)
						if (listingInput.end_at)
							variables.end_at = format(
								new Date(listingInput.end_at),
								'yyyy-MM-dd'
							)


						if (listingInput.start_time)
							variables.start_time = format(
								new Date(listingInput.start_time),
								'HH:mm:00'
							)
						if (listingInput.end_time)
							variables.end_time = format(
								new Date(listingInput.end_time),
								'HH:mm:00'
							)

						if (listingInput.time_zone) {
							if (listingInput.start_time)
								variables.timezone_id = listingInput.time_zone.id
						} 

						if (listingInput.images?.[0]) {
							delete listingInput.images[0].src
						}
						if (listingInput.project_code) {
							delete listingInput.project_code.__typename
						}

						if (props.entities) {
							let artIds = []
							let contactIds = []
							let rows = []

							props.entities.forEach(row => { // rows selected, items all list
								rows.push(row)
								if (row.__typename.toLowerCase() === 'art') artIds.push(Number(row.id))
								if (row.__typename.toLowerCase() === 'contact') contactIds.push(Number(row.id))
								variables.artIds = artIds
								variables.contactIds = contactIds
							})
						} 
						
						createListing({
							variables: {
								CreateListingInput: variables,
							},
						})
							.then((res) => {

								setAttempt(false)
								if (res.data.createListing?.success || res.data.createListingAndInsertRelation?.success) {
									setAttempt(false)
									openSnackbar(severity.SUCCESS, 'Listing created successfully.')
									props.setCreateListing(null)
									if(res.data?.createListing?.success)
										navigate(`/listings/${res.data.createListing.listing.id}/details`)
									else
										navigate(`/listings/${res.data.createListingAndInsertRelation.listing.id}/details`)
								} else {
									setAttempt(false)
									openSnackbar( severity.ERROR, 'Could not create listing.')
								}							
							})
							.catch((error) => {
								console.log(error)
								openSnackbar( severity.ERROR, 'There was an error creating the listing.')
							})
					}
				}}
			>
				<div className="quick-view-inner">
					<h2 className="card-title" style={{
						alignSelf: 'flex-start',
					}}>Create New Listing</h2>

					<FormControl fullWidth>
						<Label
							disableAnimation
							shrink
							htmlFor="listingInputQV-title"
							error={attempt && !listingInput?.title}
						>
							Title*
						</Label>

						<TextBox
							error={attempt && !listingInput?.title}
							className="listing-input"
							id="listingQV-title"
							value={listingInput?.title}
							onChange={(e) => {
								setListingInput({
									...listingInput,
									title: e.target.value,
								})
							}}
							data-testid="listing-title"
						/>
					</FormControl>

					<FormControl fullWidth>
						<Label
							disableAnimation
							shrink
							htmlFor="listingInputQV-subtitle"
						>
						Subtitle
						</Label>

						<TextBox
							className="listing-input"
							id="listingQV-subtitle"
							value={listingInput.subtitle}
							onChange={(e) => {
								setListingInput({
									...listingInput,
									subtitle: e.target.value,
								})
							}}
							data-testid="listing-subtitle"
						/>
					</FormControl>

					<FormControl fullWidth>
						<Label id="type-id-label" disableAnimation shrink error={attempt && !listingInput?.type_id}>
							Type*
						</Label>

						<Select
							IconComponent={ExpandMoreRoundedIcon}
							name="type_id"
							
							className="padded-select medium-selection"
							labelId="type-id-label"
							input={<Dropdown />}
							value={listingInput?.type_id}
							error={attempt && !listingInput?.type_id}
							onChange={(e) => {
								setListingInput({
									...listingInput,
									type_id: e.target.value,
								})
							}}
							data-testid="listing-type"
						>
							{listingTypes && listingTypes.map((listingType) => (
								<MenuItem
									key={listingType.id}
									value={listingType.id}
									data-testid="listing-type-item"
								>
									{listingType.value}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					{['1', '2'].includes(listingInput?.type_id) && <FormControl fullWidth>
						<Label id="type-id-label" disableAnimation shrink error={attempt && !listingInput?.project_code}>
							Project Code*
						</Label>

						<ProjectCodeInput
							attempt={attempt}
							value={projectCodeValue || null}
							onChange={(event, value) => {
								setListingInput({
									...listingInput,
									project_code: value,
								})
							}}
						/>
					</FormControl>}

					<AvatarInput
						style={{
							textAlign: 'center',
							marginTop: '1em',
						}}
						newEntity={true}
						alt={listingInput?.title || "New Art Piece"}
						src={listingInput?.imgUrl}
						editable={true}
						oldProfileLink={[]}
						inputName="CreateListingInput"
						entityId={null}
						setEntity={setListingInput}
						entity={listingInput}
						entityName="Listing"
						includeImages={true}
						extraFields={{
							type_id: LISTING_IMAGE_TYPE_ID,
							usage_right_id: DEFAULT_IMAGE_USAGE_RIGHT
						}}
						creation={true}
					/>

					<FormControl fullWidth>
						<Label
							id="start-at-label"
							style={typeStyle}
							disableAnimation
							shrink
							error={attempt && requiresDate && !listingInput?.start_at}
						>
							Start Date
						</Label>

						<MobileDatePicker
							inputFormat="MMM do, yyyy"
							disableMaskedInput={true}
							cancelText={null}
							name="start_at"
							value={listingInput.start_at}
							inputVariant="outlined"
							fullWidth
							className="MUIDatePicker"
							variant="dialog"
							style={{ marginTop: '25px' }}
							renderInput={({ inputRef, inputProps, InputProps }) => {
								
								console.log(listingInput)
								const newProps = { ...inputProps}
	
								newProps.readOnly = false
	
								return <TextBox 
									error={attempt && requiresDate && !listingInput?.start_at}
									ref={inputRef} 
									endAdornment={InputProps?.endAdornment} 
									{...newProps}
								/>
							}}
							labelFunc={(date) => {
								if (date)
									return format(
										new Date(date),
										'iiii, MMMM do, yyyy'
									)
								else return ''
							}}
							onChange={(date) => {
								setListingInput({
									...listingInput,
									start_at: date,
								})
							}}
							data-testid="listing-start_date"
						/>
					</FormControl>

					<FormControl fullWidth>
						<Label
							id="end-at-label"
							style={typeStyle}
							disableAnimation
							shrink
							error={attempt && requiresDate && !listingInput?.end_at}
						>
							End Date
						</Label>

						<MobileDatePicker
							inputFormat="MMM do, yyyy"
							disableMaskedInput={true}
							componentsProps={{
								actionBar: {
									actions: ['clear', 'accept']
								}
							}}
							name="end_at"
							value={listingInput.end_at}
							inputVariant="outlined"
							style={{ marginTop: '25px' }}
							className="MUIDatePicker"
							variant="dialog"
							renderInput={({ inputRef, inputProps, InputProps }) => {
								
								const newProps = { ...inputProps}
	
								newProps.readOnly = false
	
								return (
									<TextBox 
										ref={inputRef} 
										endAdornment={InputProps?.endAdornment} 
										error={attempt && requiresDate && !listingInput?.end_at}
										{...newProps}
									/>
								)}}
							labelFunc={(date) => {
								if (date)
									return format(
										new Date(date),
										'iiii, MMMM do, yyyy'
									)
								else return ''
							}}
							onChange={(date) => {
								setListingInput({
									...listingInput,
									end_at: date,
								})
							}}
							data-testid="listing-end_date"
						/>
					</FormControl>

					<FormControl className="start-time" fullWidth>
						<Label
							id="start-time-label"
							style={{ fontWeight: 500 }}
							disableAnimation
							shrink
						>
						Start Time
						</Label>

						<MobileTimePicker
							componentsProps={{
								actionBar: {
									actions: ['clear', 'accept']
								}
							}}
							disableMaskedInput={true}
							name="time"
							renderInput={({ inputRef, inputProps, InputProps }) => {
								
								const newProps = { ...inputProps}
	
								newProps.readOnly = false
	
								return (
									<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
								)}}
							value={listingInput.start_time}
							onChange={(time) => {
								setListingInput({
									...listingInput,
									start_time: time
								})
							}}
							data-testid="listing-start_time"
						/>

					</FormControl>

					<FormControl className="end-time" fullWidth>
						<Label
							id="end-time-label"
							style={{ fontWeight: 500 }}
							disableAnimation
							shrink
						>
						End Time
						</Label>


						<MobileTimePicker
							disableMaskedInput={true}
							componentsProps={{
								actionBar: {
									actions: ['clear', 'accept']
								}
							}}
							name="time"
							renderInput={({ inputRef, inputProps, InputProps }) => {
								
								const newProps = { ...inputProps}
	
								newProps.readOnly = false
	
								return (
									<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
								)}}
							value={listingInput.end_time}
							onChange={(time) => {
								setListingInput({
									...listingInput,
									end_time: time
								})
							}}
							data-testid="listing-end_time"
						/>

					</FormControl>



					<FormControl fullWidth>
						<Label id="timezone-label" style={{fontWeight: 500}} disableAnimation shrink>
						Timezone
						</Label>
						<Autocomplete
							size="small"
							autoHighlight
							style={{marginTop: "1.75em"}}
							popupIcon={<ExpandMoreRoundedIcon />}
							options={timezones}
							classes={autoCompleteStyles()}
							filterOptions={filterOptions}
							value={listingInput.time_zone}
							getOptionLabel={(option) => getOptionLabel(option)}
							renderOption={(props, option) => (
								<li {...props}>
									{getOptionLabel(option)}
								</li>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									fullWidth
									inputProps={{
										...params.inputProps,
									}}
								/>
							)}
							onChange={(event, value) => setListingInput({
								...listingInput,
								time_zone: value
							})}
							data-testid="listing-timezone"
						/>
					</FormControl>

					<FormControl fullWidth>
						<Label id="location-label" disableAnimation shrink>
						Location
						</Label>

						<TextBox
							className="listing-input"
							id="listingQV-location"
							value={listingInput?.location}
							onChange={(e) => {
								setListingInput({
									...listingInput,
									location: e.target.value,
								})
							}}
							data-testid="listing-location"
						/>
					</FormControl>
				</div>
				<div
					style={{
						paddingTop: '1em',
						marginBottom: '1em',
						marginLeft: '1em',
						marginRight: '1em',
						marginTop: 'auto'
					}}
				>
					<CancelButton
						variant="contained"
						style={{ float: 'left' }}
						onClick={(e) => {
							props.setCreateListing(null)
							setAttempt(false)
						}}
						data-testid="listing-cancel"
					>
						Cancel
					</CancelButton>

					<SubmitButton
						variant="contained"
						style={{ float: 'right' }}
						type="submit"
						data-testid="listing-submit"
						disabled={loading}
					>
						Submit
					</SubmitButton>
				</div>
			</form>
		</QuickView>
	)
}

export default withApollo(ListingQuickView)
