import './ContactDetails.css'
import Advanced from './Advanced'
import ContactInformation from './ContactInformation'
import Details from './Details'
import Interests from './Interests'
import React from 'react'
import Relationships from './Relationships'
import ObjectTags from '../../Tags/ObjectTags'
import ContactQuickView from '../ContactQuickView'
import UserAccessQuickView from '../../QuickView/UserAccessQuickView'
import clsx from 'clsx'
import { permissions } from '../../constants/permissions'
// import WishList from './WishList'

function ContactDetail(props) {

	let { loading, error } = props
	let contact = props.state.getContact || {}
	let setContact = (contact) => props.setState({ getContact: contact })
	const isPrivate = contact && contact.is_private

	const [selected, setSelected] = React.useState(null)
	const [privateAccess, setPrivateAccess] = React.useState(null)
	const [selectedTag, setSelectedTag] = React.useState(null)

	React.useEffect(() => {
		if (selected && selected?.contact_1?.id) props.setQVOverride(() => 
			<ContactQuickView 
				id={selected?.contact_1?.id}
				onClose={() => setSelected(null)}
			/>)
		else {
			props.setQVOverride(null)
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected])

	React.useEffect(() => {
		if (privateAccess) props.setQVOverride(() => 
			<UserAccessQuickView entity={privateAccess?.contact_1}/>)
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [privateAccess])

	return (
		<section className="main-page">
			<div className={clsx({
				'private-object': isPrivate,
				'tabbed-content': true
			})}>
				<div className="page-row">
					<Details 
						loading={loading} 
						error={error}
						contact={contact}
						setContact={setContact}
						private={contact?.is_private || false}
						findMode={props.findMode}
					></Details>
					<Advanced 
						loading={loading} 
						error={error}
						contact={contact}
						setContact={setContact}
						isPrivate={isPrivate}
						findMode={props.findMode}
					></Advanced>
				</div>

				<ContactInformation
					loading={loading}
					error={error}
					contact={contact}
					setContact={setContact}
					isPrivate={isPrivate}
					findMode={props.findMode}
				></ContactInformation>

				<Relationships
					loading={loading}
					error={error}
					contact={contact}
					setContact={setContact}

					selectedRow={selected}
					onSelect={setSelected}

					setPrivateAccess={setPrivateAccess}
					privateAccess={privateAccess}

					setQVOverride={props.setQVOverride}
					findMode={props.findMode}
				></Relationships>

				<div className="page-row">
					<Interests
						loading={loading}
						error={error}
						contact={contact}
						setContact={setContact}
						setQVOverride={props.setQVOverride}
						setSelectedTag={setSelectedTag}
						selectedTag={selectedTag}
						findMode={props.findMode}
					></Interests>
					{/*
						<WishList
							loading={loading}
							error={error}
							contact={contact}
							setContact={setContact}
						></WishList>	
						*/}
				</div>

				<ObjectTags
					isPrivate={isPrivate}
					loading={loading}
					error={error}
					entity={contact}
					setEntity={setContact}
					tagState={props.tagState}
					setTagState={props.setTagState}
					newTagModal={props.newTagModal}
					setNewTagModal={props.setNewTagModal}
					setQVOverride={ props.setQVOverride }
					entityType="Contact"
					tagPermission={permissions.CONTACTS_TAGS}
					findMode={props.findMode}
				></ObjectTags>
			</div>
		</section>
	)
}

export default ContactDetail
