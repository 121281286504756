/* eslint-disable eqeqeq */
import React, { useContext, useCallback } from "react"
import { TableContainer,Table,TableRow,TableCell,TableBody} from "@mui/material"
import Thumbnail from "../../Thumbnail/Thumbnail"
import { Skeleton } from '@mui/material'
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { DispatchContext } from "../../store"
import { severity } from "../../Snackbar/CustomizedSnackbar"
import EnhancedTableHead from "../../table/EnhancedTableHead"
import InfoCard from "../../InfoCard/InfoCard"
import { useNavigate } from "react-router-dom"
import EditNotes from './../../Notes/EditNotes'
import {UPDATE_LISTING_NOTE, UPDATE_CONTACT_NOTE, UPDATE_DEAL_NOTE, UPDATE_ART_NOTE, UPDATE_ARTIST_NOTE} from './../../Notes/Queries'
import { formatDate, getContactName, getDealName, shorten, getArtistThumbnailDetail } from "../../common/helpers"
import DealThumbnail from "../../Deals/DealThumbnail"

function Notes(props) {
	
	const navigate = useNavigate()
	const headCells = [
		{ id: "entity_title",noSort: true, numeric: false, disablePadding: false, label: "Object" },
		{ id: "note_text",noSort: true, numeric: false, disablePadding: false, label: "Description" },
		{ id: "applied_at",noSort: true, numeric: false, disablePadding: false, label: "Date"},
		{ id: "action",noSort: true, numeric: false, disablePadding: false, label: "Actions" },
	]
	
	// Modal
	const [editNoteModal, setEditNoteModal] = React.useState({open: false, note: null})
	const [infoModal, setInfoModal] = React.useState({open: false})

	// Menu
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [currentNote, setCurrentNote] = React.useState(null)

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: "openSnackBar", payload: { severity, text } })
		},
		[dispatch]
	)

	const handleClick = (event, note) => {
		setAnchorEl(event.currentTarget)
		setCurrentNote(note)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}
	
	/**
	 * To get the query name, and query for the currentNote's relevant note_link
	 * type.
	 */
	const getMutation = () => {
		if (currentNote?.entity_name){
			switch(currentNote?.entity_name) {
			case 'contact': {
				return ['updateContactNote', UPDATE_CONTACT_NOTE]
			}
			case 'listing': {
				return ['updateListingNote', UPDATE_LISTING_NOTE]
			}
			case 'art': {
				return ['updateArtNote', UPDATE_ART_NOTE]
			}
			case 'deal': {
				return ['updateDealNote', UPDATE_DEAL_NOTE]
			}
			case 'artist': {
				return ['updateArtistNote', UPDATE_ARTIST_NOTE]
			}
			default: {
				break
			}
			}
		}
	}

	/**
	 * Get the differing ignored attributes for each note's info card display.
	 */
	const getIgnoredAttributes = () => {
		if (!currentNote) return []

		const common = [
			"type_id",
			"note_status_id",
			"assigned_to_type",
			"assigned_by_user_id",
			"due_at",
			"created_by",
			"modified_by",
			"description",
		]

		if (currentNote.contact_id) {
			return [...common, "is_completed", "artist_name"]

		} else if (currentNote.listing_id) {
			return [...common, "is_completed", "contact_name", "artist_name"]
		}

		return [...common, "is_completed", "contact_name", "artist_name"]
	}


	return <>
		<div>
			<TableContainer style={{ overflowX: "hidden", paddingBottom: '2em' }}>
				<Table
					aria-labelledby="tableTitle"
					size="medium"
					aria-label="enhanced table"
				>
					<EnhancedTableHead
						headCells={headCells}
						rowCount={props?.currentNotes?.length}
					/>
					<TableBody>
						{!props.loading && props.currentNotes.length === 0 ? 
							<TableRow>
								<TableCell></TableCell>
								<TableCell style={{ padding: "1em" }}>
                                    No Notes Found.
								</TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
							</TableRow>	 : null}
                            
						{(!props.loading ? props.currentNotes : [{id: 1, disabled: true}]).map((row, index) => {
                            
							let subTitle = row.entity_subtitle

							if (row.entity_name === 'artist') {
								subTitle = row.artist?.genre?.map(e => e.artist_genre).join(", ") || '-'
							}

							let isPrivate = false
							let hidden = false

							switch (row.entity_name) {

							case "contact":
								isPrivate = row.contact?.is_private
								hidden = row.contact?.is_private && !row.contact?.created_at
								break

							case "deal":
								isPrivate = row.deal?.is_private
								hidden = row.deal?.is_private && !row.deal?.created_at
								break

							case "art":
								isPrivate = row.art?.is_private
								hidden = row.art?.is_private && !row.art?.created_at
								break
                        
							default:
								break
							}
                            
							const getThumbnail = () => {

								if (row?.entity_title?.startsWith('Deal'))
									return (
										<div style={{paddingLeft: '1em'}}>
											<DealThumbnail
												is_private={row.deal.is_private}
												is_gallery_private={row.deal.is_gallery_private}
												created_at={row.deal.created_at}
												deal_name={getDealName(row.deal)}
											/>
										</div>
									)

								if (row.art?.id) return (
									<Thumbnail
										formatted_name={row.art?.formatted_title}
										artist={getArtistThumbnailDetail(
											row.art
										)}
										isCompany={row.contact?.is_company}
										date={row.art?.year}
										name={row.art?.code_name}
										detail={!props.loading ? subTitle : null}
										avatar={row.entity_img}
										type={row.entity_name}
										animation={props.loading ? 'wave' : false}
										largeText
										hasAccess={!hidden}
										darkText
									></Thumbnail>
								)

								else return (
									<Thumbnail
										isCompany={row.contact?.is_company}
										name={row?.entity_title}
										detail={!props.loading ? subTitle : null}
										avatar={row.entity_img}
										type={row.entity_name}
										animation={props.loading ? 'wave' : false}
										largeText
										isPrivate={isPrivate}
										hasAccess={!hidden}
										darkText
										isDeceased={row.contact?.is_deceased}
									></Thumbnail>
								)

							}

							return (
								<TableRow key={index} selected={row === props.selectedRow}>
									<TableCell
										className="home-page-notelist"
										component="th"
										scope="row"
										data-testid="contact-thumbnail"
										style={{ width: '15em' }}
									>
										{getThumbnail()}
									</TableCell>

									<TableCell>
										<div
											style={{
												maxHeight: '10em',
												overflow: 'hidden',
											}}
										>
											{row?.note_text ? (
												shorten(row.note_text, 240)
											) : (
												<Skeleton
													variant="text"
													width={props.totalItems ? '100%' : 'auto'}
													animation={props.loading ? 'wave' : false}
												/>
											)}
										</div>
									</TableCell>

									<TableCell>
										<div
											style={{
												maxHeight: '10em',
												overflow: 'auto',
											}}
										>
											{row?.applied_at ? (
												formatDate(
													row.applied_at,
													"MMMM do, yyyy 'at' h':'mma",
												)
											) : (
												<Skeleton
													variant="text"
													width={props.totalItems ? '100%' : 'auto'}
													animation={props.loading ? 'wave' : false}
												/>
											)}
										</div>
									</TableCell>

									<TableCell>
										{!row?.disabled ? (
											<IconButton
												aria-label="More"
												style={{ padding: '6px', marginRight: '-9px' }}
												onClick={(e) => {
													e.preventDefault()
													e.stopPropagation()
													handleClick(e, row)
												}}
												size="large">
												<MoreHorizIcon />
											</IconButton>
										) : null}
									</TableCell>
								</TableRow>
							)
						})
						}
					</TableBody>
				</Table>
			</TableContainer>

			<>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem
						onClick={(e) => {
							switch(currentNote.entity_name) {
							case 'contact': {

								if (currentNote.contact?.is_deleted) {
									openSnackbar(severity.WARNING, `${getContactName(currentNote.contact)} was deleted.`)
									break
								}

								if (currentNote.contact?.is_private && !currentNote.contact.created_at) {
									openSnackbar(severity.ERROR, `You do not have access to this Private Contact.`)
									break
								}

								navigate(`/contacts/${currentNote.entity_id}/notes`)
								break
							}
							case 'listing':

								if (currentNote.listing?.is_deleted) {
									openSnackbar(severity.WARNING, `Listing ${currentNote.listing?.id} was deleted.`)
									break
								}

								navigate(`/listings/${currentNote.entity_id}/notes`)
								break
							case 'deal':

								if (currentNote.deal?.is_deleted) {
									openSnackbar(severity.WARNING, `Deal ${currentNote.deal?.id} was deleted.`)
									break
								}

								if ((currentNote.deal?.is_private || currentNote.deal?.is_gallery_private) && !currentNote.contact.created_at) {
									openSnackbar(severity.ERROR, `You do not have access to this Private Deal.`)
									break
								}

								navigate(`/deals/${currentNote.entity_id}/notes`)
								break
							case 'art':

								if (currentNote.art?.is_deleted) {
									openSnackbar(severity.WARNING, `Art Piece ${currentNote.art?.id} was deleted.`)
									break
								}

								if (currentNote.art?.is_private && !currentNote.art?.created_at) {
									openSnackbar(severity.ERROR, `You do not have access to this Private Artwork.`)
									break
								}

								navigate(`/art/${currentNote.entity_id}/notes`)
								break
							case 'artist':

								if (currentNote.artist?.is_deleted) {
									openSnackbar(severity.WARNING, `Artist ${currentNote.artist?.id} was deleted.`)
									break
								}


								navigate(`/artists/${currentNote.entity_id}/notes`)
								break
							default:
								openSnackbar(severity.WARNING,"This task is not linked to an object.")
							}
							handleClose()
						}}
					>
                    Go to
					</MenuItem>
					<MenuItem
						onClick={(e) => {
							setEditNoteModal({
								open: true,
								note: {
									...currentNote,
									assigned_to_department:
                                        currentNote.assigned_to_department?.id || null,
								},
							})
							handleClose()
						}}
					>
                        Edit
					</MenuItem>
					<MenuItem onClick={e => {
						setInfoModal({open: true})
						handleClose()
					}}>
                    Info
					</MenuItem>
                    
				</Menu>
			</>
		</div>
		{currentNote ?
			<EditNotes
				editNoteModal={editNoteModal}
				setEditNoteModal={setEditNoteModal}
				notes={props.currentNotes}
				setNotes={props.setCurrentNotes}
				mutationResponse={getMutation()[0]}
				updateMutationName={getMutation()[1]}
			/>
			: null}

		<InfoCard
			open={infoModal.open}
			object={currentNote}
			setInfoModal={setInfoModal}
			ignoredAttributes={getIgnoredAttributes()}
			allAttributes
		/>
	</>
}

export default Notes
