import React from 'react'
import { GET_USER_DATALOG } from '../Queries'
import DataLog from '../../DataLog/DataLog'
import { AuthStateContext } from './../../store'

export default function AdminDataLog (props) {
	const authState = React.useContext(AuthStateContext)

	return (
		<DataLog 
			query={GET_USER_DATALOG}
			id_field={"user_id"} 
			queryName={"getUserDataLog"}
			parentQueryName={"getUser"}
			entity_id={authState.user?.id}
			{...props}
		/>
	)
}
