/* eslint-disable eqeqeq */
import React, { useContext, useCallback, useState } from 'react'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useStyles } from './StyledTab'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import * as Fonts from '../../styles/fonts/Fonts'
import '../../App.css'
import {
	ThemeProvider,
	StyledEngineProvider,
	IconButton,
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
} from '@mui/material'
import privateObjectTheme from '../../styles/muiThemes/privateObjectTheme'
import clsx from 'clsx'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import StarIcon from '@mui/icons-material/Star'
import FlagIcon from '@mui/icons-material/Flag'
import LabelIcon from '@mui/icons-material/Label'
import SearchIcon from '@mui/icons-material/Search'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SettingsIcon from '@mui/icons-material/Settings'
import { permissionValues, permissions } from '../../constants/permissions'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { useApolloClient, useMutation } from '@apollo/client'
import { TOGGLE_FAVORITE, TOGGLE_FLAG } from '../../common/queries'
import { DispatchContext } from '../../store'
import UploadModal from '../../Files/UploadModal'
import CreateDealQuickView from '../../Deals/CreateDealQuickView'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog'
import { DELETE_CONTACT } from '../../Contacts/Queries'
import { DELETE_ART } from '../../Art/Queries'
import ShowcaseModal from '../../Showcase/ShowcaseModal'
import { DELETE_ARTIST } from '../../Artists/Queries'
import { DELETE_LISTING } from '../../Listings/Queries'
import { DELETE_DEAL } from '../../Deals/Queries'
import DescriptionIcon from '@mui/icons-material/Description'
import LocalShipping from '@mui/icons-material/LocalShipping'
import FactSheetModal from '../../FactSheet/FactSheetModal'
import ShippingFormModal from '../../Exports/ShippingFormModal'
import ConsignmentTerms from '../../Exports/ConsignmentTerms'
import InfoCard from '../../InfoCard/InfoCard'
import ListingTypeChangeModal from '../../Contacts/Modals/ListingTypeChangeModal'
import TransitionModal from '../../navigation/TransitionsModal/TransitionsModal'
import ArtLabelModal from '../../Exports/ArtLabelSheet'
import { CancelButton, SubmitButton } from '../../input/Buttons'
import PdfExport, { getPdf } from '../../Exports/PdfExport'
import { Box } from '@mui/system'
import ShowcaseToggle from '../../Showcase/ShowcaseToggle'
import ViewCompactIcon from '@mui/icons-material/ViewCompact'
import CuratedListModal from '../../Showcase/CuratedLists/CuratedListModal'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { GET_LISTING_ART_IDS } from '../../Listings/Listing/Queries'

const TabBar = (props) => {

	let getEntity

	// Modal state
	const [fileModal, setFileModal] = React.useState(false)
	const [infoModal, setInfoModal] = React.useState(false)
	const [listingTypeModal, setListingTypeModal] = React.useState(false)
	const [deletionConfirmation, setDeletionConfirmation] = React.useState(false)
	const [showcaseModal, setShowcaseModal] = React.useState(false)
	const [factSheetModal, setFactSheetModal] = React.useState(false)
	const [shipFormModal, setShipFormModal] = React.useState(false)
	const [labelFormModal, setLabelFormModal] = React.useState(false)
	const [consignmentData, setConsignmentData] = React.useState({})
	const [favoriteLoading, setFavoriteLoading] = React.useState(false)
	const [flagLoading, setFlagLoading] = React.useState(false)
	const [curatedListModal, setCuratedListModal] = React.useState(false)
	const [pdfModal, setPdfModal] = React.useState(false)
	const [listingArtIds, setListingArtIds] = React.useState([])
	const [loading, setLoading] = React.useState(false)

	const client = useApolloClient()

	const location = useLocation()
	const navigate = useNavigate()

	const handleError = (error, entity) => {
		console.error(error)
		openSnackbar(severity.ERROR, `Could not delete ${entity}.`)
	}


	const handleCompletion = (response, path, mutationName) => {

		if (response[mutationName]?.success === true) {
			// Success
			openSnackbar(severity.SUCCESS, response[mutationName]?.message)
			window.location.pathname=path

		} else {
			// On failure, reset userInput state, don't touch user state and show error
			openSnackbar(severity.ERROR, response[mutationName]?.message)
		}
	}

	// Delete Mutations
	const [deleteContact] = useMutation(DELETE_CONTACT, {
		onError: (error) => handleError(error, 'Contact'),
		onCompleted: (response) => handleCompletion(response, 'contacts', 'deleteContact')
	})

	const [deleteArt] = useMutation(DELETE_ART, {
		onError: (error) => handleError(error, 'Art Piece'),
		onCompleted: (response) => handleCompletion(response, 'art', 'deleteArt')
	})

	const [deleteArtist] = useMutation(DELETE_ARTIST, {
		onError: (error) => handleError(error, 'Artist'),
		onCompleted: (response) => handleCompletion(response, 'artists', 'deleteArtist')
	})

	const [deleteListing] = useMutation(DELETE_LISTING, {
		onError: (error) => handleError(error, 'Listing'),
		onCompleted: (response) => handleCompletion(response, 'listings', 'deleteListing')
	})

	const [deleteDeal] = useMutation(DELETE_DEAL, {
		onError: (error) => handleError(error, 'Deal'),
		onCompleted: (response) => handleCompletion(response, 'deals', 'deleteDeal')
	})


	// Menu
	const [anchorEl, setAnchorEl] = React.useState(null)
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	// custom styles
	const classes = useStyles()

	const [toggleFavorite] = useMutation(TOGGLE_FAVORITE)
	const [toggleFlag] = useMutation(TOGGLE_FLAG)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	// The "current route" won't match one of the options if you go to the
	// root level (i.e, "/user" instead of "/user/profile"). This is
	// prevent the console from throwing an error until it redirects.
	const val = props.tabs.find((t) => props.value.endsWith(t.route))?.route ||
		props.tabs[0]?.route

	const commonPermission = props.permissions?.find(e => e.permission_id == props.options?.permissionVal)
	const dealPermission = props.permissions?.find(e => e.permission_id == permissions.DEALS)
	const showcasePermission = props.permissions?.find(e => e.permission_id == permissions.SHOWCASE)

	switch (props.options?.object) {
	case 'art':
		getEntity = 'getArtPiece'
		break

	case 'contact':
		getEntity = 'getContact'
		break
	case 'artist':
		getEntity = 'getArtist'
		break
	case 'deal':
		getEntity = 'getDeal'
		break
	case 'listing':
		getEntity = 'getListing'
		break

	default:
		break
	}

	const currentPath = location.pathname

	const content = (

		<>
			<Paper
				style={{
					justifyContent: 'center',
					display: 'flex',
					zIndex: 2,
					backgroundColor: props.atPrivateObject ? '#6A6A72' : null
				}}
				className={clsx(classes.paperTab, {
					'private-object': props.atPrivateObject,
				})}
				elevation={3}
			>
				{!props.hideTabBar ? (
					<>
						{props.children}
						<Tabs
							value={val}
							className={clsx(classes.tabs, 'Top-Tabs', 'fullWidth')}
							variant="scrollable"
							scrollButtons
						>
							{props.tabs.map((page) => {
								return (
									<Tab
										style={Fonts.subNav}
										component={Link}
										to={page.route}
										state={{
											...location.state,
											pathTemplate: (page.route)
										}}
										key={page.name}
										data-testid={page.name + '-id'}
										label={page.name}
										className={classes.tab}
										value={page.route}
										onClick={() => {
											props.setQVOverride(null)
										}}
									/>
								)
							})}
						</Tabs>

						{(!currentPath.includes("user") || currentPath.includes("admin")) && !currentPath.includes("support") ? <> 
						
							{props.options?.findMode !== false ?
							
								<IconButton 
									onClick={props.toggleFindMode} 
									disabled={!props.options || !Object.keys(props.options).includes('findMode')} size="large"
									style={currentPath.includes("admin") ? {
										background: '#6a6a72',
										borderRadius: 0,
									} : null}
								>
									<SearchIcon />
								</IconButton>

								: null}
						
							{!currentPath.includes("admin") ? <> 

								{props.options?.toggleFlag !== false ? 		
									<IconButton
										disabled={flagLoading || props.findMode || !props.options || !Object.keys(props.options).includes('toggleFlag') || Number(commonPermission?.permission_value_id) < permissionValues.VIEW_ONLY}
										onClick={() => {
											setFlagLoading(true)
											toggleFlag({
												variables: {
													ToggleFlagInput: {
														userId: props.userId,
														objectType: props.options.object,
														objectId: props.objectId,
														isFlagged: !props.atFlaggedObject,
													}
												}
											}).then(response => {
												setFlagLoading(false)
												if (response && response.errors) {
													openSnackbar(severity.ERROR, "Could not toggle this flag.")
												} else if (response && response.data?.toggleFlagged?.success === false) {
													openSnackbar(severity.ERROR, 
														response.data?.toggleFlagged.message || 
														"There was an error toggling this flag.")
												} else {
													props.setAtFlaggedObject(!props.atFlaggedObject)
													if (!props.atFlaggedObject)
														openSnackbar(severity.SUCCESS, "Successfully flagged.")
													else
														openSnackbar(severity.SUCCESS, "Successfully unflagged.")
												}
											}).catch(error => {
												setFlagLoading(false)
												openSnackbar(severity.ERROR, "There was an error toggling this flag.")
											})
											handleClose()
										}}
										size="large">
										<FlagIcon style={ props.atFlaggedObject ?
											{color: '#cc3333'} : {color: '#919191'}
										} />
									</IconButton>

									: null}


								{props.options?.showcaseInformationToggle === true ? 
								
									<Box sx={{
										display: 'flex',
    									alignItems: 'center',
									}}>
										<ShowcaseToggle showcaseInformation={props.showcaseInformation} setShowcaseInformation={props.setShowcaseInformation}/>
									</Box>
								
									: null}

								{props.options?.menu !== false ? 
									<IconButton 
										disabled={props.findMode || !props.options || Number(commonPermission?.permission_value_id) < permissionValues.VIEW_ONLY || props.state?.[getEntity] === null } 
										onClick={e => handleClick(e)} 
										size="large"
									>
										<MoreVertIcon />
									</IconButton>
									: null}

							</> : null}
						 </> : null}
					</>
				) : null}

				<Menu
					PaperProps={{ style: { maxHeight: '100%' } }}
					id="top-tab-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					{
						props.options &&
						(props.options.extraMenuOptions || [])
							.map(option =>
								option.predicate(props) &&
								<MenuItem key={option.title}
									onClick={() => {
										option.action(props)
										handleClose()
									}}>
									<ListItemIcon>
										<img alt=""
											style={{
												maxHeight: '20px',
												maxWidth: '24px',
											}}
											src={option.image}
										/>
									</ListItemIcon>
									<ListItemText style={{paddingRight: '1em'}}>
										{option.title}
									</ListItemText>
								</MenuItem>
							)
					}
					{props.options && Object.keys(props.options).map((option) => {
						if (option === 'object' || option === 'permissionVal' || option === 'fileObjectId') return null
						else if (props.options[option] === false) return null

						switch (option) {
						case 'uploadFile':
							return Number(commonPermission?.permission_value_id) >= permissionValues.VIEW_ONLY && (
								<MenuItem key={option} onClick={() => {
									setFileModal(true)
									handleClose()
								}}>
									<ListItemIcon>
										<img
											alt="share"
											style={{
												maxHeight: '20px',
												maxWidth: '24px',
											}}
											src="../../images/icons/Grey/Upload.svg"
										/>
									</ListItemIcon>
									<ListItemText style={{paddingRight: '1em'}}>
									Upload File
									</ListItemText>
								</MenuItem>
							)

						case 'toggleFavorite':
							return Number(commonPermission?.permission_value_id) >= permissionValues.VIEW_ONLY && (
								<MenuItem key={option} disabled={favoriteLoading} onClick={() => {

									setFavoriteLoading(true)

									toggleFavorite({
										variables: {
											ToggleFavoriteInput: {
												userId: props.userId,
												objectType: props.options.object,
												objectId: props.objectId,
												isFavorite: !props.atFavoriteObject,
											}
										}
									}).then(response => {

										setFavoriteLoading(false)

										if (response && response.errors) {

											openSnackbar(severity.ERROR, "Could not toggle this favorite.")
										} else if (response && response.data?.toggleFavorite?.success === false) {
											openSnackbar(severity.ERROR, 
												response.data?.toggleFlagged.message || 
												"There was an error toggling this favorite.")
										} else {

											props.setAtFavoriteObject(!props.atFavoriteObject)

											if (!props.atFavoriteObject)
												openSnackbar(severity.SUCCESS, "Successfully favorited.")
											else
												openSnackbar(severity.SUCCESS, "Successfully unfavorited.")
										}

									}).catch(error => {

										setFavoriteLoading(false)

										openSnackbar(severity.ERROR, "There was an error toggling this favorite.")
									})
									handleClose()
								}}>
									<ListItemIcon>
										<StarIcon style={props.atFavoriteObject ? {color: 'hsl(50, 93%, 59%)'} : {color: '#919191'}}/>
									</ListItemIcon>
									<ListItemText style={{paddingRight: '1em'}}>
										{props.atFavoriteObject ? "Unfavorite" : "Favorite"}
									</ListItemText>
								</MenuItem>
							)


						case 'showcase':
							return Number(commonPermission?.permission_value_id) > permissionValues.VIEW_ONLY && (
								<MenuItem key={option} onClick={() => {

									// If current user has relative create and edit own permissions
									// and current object was not created by them

									if (Number(showcasePermission?.permission_value_id) < permissionValues.VIEW_ONLY ) {
										openSnackbar(severity.WARNING, "You do not have necessary permissions.")
									} else {
										// Open showcase modal
										setShowcaseModal(true)
									}

									handleClose()
								}}>

									<ListItemIcon>
										<img
											alt="showcase-preview"
											style={{
												maxHeight: '20px',
												maxWidth: '24px',
											}}
											src={props.entity?.showcase?.ready || props.entity?.showcase_ready
												? "../../images/icons/Green/Showcase.svg"
												: "../../images/icons/Grey/Showcase.svg" }
										/>
									</ListItemIcon>
									<ListItemText style={{paddingRight: '1em'}}>
										Showcase
									</ListItemText>
								</MenuItem>
							)

						case 'delete':
							return Number(commonPermission?.permission_value_id) > permissionValues.VIEW_ONLY && (
								<MenuItem key={option} onClick={() => {
									setDeletionConfirmation(true)
									handleClose()
								}}>
									<ListItemIcon>
										<DeleteOutlineIcon style={{color: 'rgb(145, 145, 145)'}}/>
									</ListItemIcon>
									<ListItemText style={{paddingRight: '1em'}}>
										Delete
									</ListItemText>
								</MenuItem>
							)

						case 'createFactSheet':
							return (Number(commonPermission?.permission_value_id) >= permissionValues.VIEW_ONLY) ?
								(
									<MenuItem key={option} onClick={() => {
										setFactSheetModal(true)
										handleClose()
									}}>
										<ListItemIcon>
											<DescriptionIcon style={{
												maxHeight: '24px',
												maxWidth: '24px',
												color: '#919191'
											}}/>
										</ListItemIcon>
										<ListItemText style={{paddingRight: '1em'}}>
										Create Fact Sheet
										</ListItemText>
									</MenuItem>
								) : null

						case 'createShippingForm':
							return (Number(commonPermission?.permission_value_id) >= permissionValues.VIEW_ONLY) ?
								(
									<MenuItem key={option} onClick={()=>{
										setShipFormModal(true)
										handleClose()
									}}>
										<ListItemIcon>
											<LocalShipping style={{
												maxHeight: '24px',
												maxWidth: '24px',
												color: '#919191'
											}}/>
										</ListItemIcon>
										<ListItemText style={{paddingRight: '1em'}}>
											Shipping Form
										</ListItemText>
									</MenuItem>
								) : null

						case 'createLabelSheet': 
							return (Number(commonPermission?.permission_value_id) >= permissionValues.VIEW_ONLY) ?
								(
									<MenuItem key={option} onClick={()=>{
										setLabelFormModal(true)
										handleClose()
									}}>
										<ListItemIcon>
											<LabelIcon style={{
												maxHeight: '24px',
												maxWidth: '24px',
												color: '#919191'
											}}/>
										</ListItemIcon>
										<ListItemText style={{paddingRight: '1em'}}>
											Art Label Sheet
										</ListItemText>
									</MenuItem>
								) : null

						case 'createConsignmentForm':
							return (Number(commonPermission?.permission_value_id) >= permissionValues.VIEW_ONLY) ?
								(
									<MenuItem key={option} onClick={()=>{
										setShipFormModal(true)
										handleClose()
									}}>
										<ListItemIcon>
											<LocalShipping style={{
												maxHeight: '24px',
												maxWidth: '24px',
												color: '#919191'
											}}/>
										</ListItemIcon>
										<ListItemText style={{paddingRight: '1em'}}>
											Consignment Form
										</ListItemText>
									</MenuItem>
								) : null

						case 'tag':
							return Number(commonPermission?.permission_value_id) > permissionValues.VIEW_ONLY && (
								<MenuItem key={option} onClick={() => {

									// Create tag
									if (!location.pathname.includes("details")) navigate('details')

									props.setTagState(Object.assign({}, {
										mouseX: null,
										mouseY: null,
										editable: false,
									}, { editable: true, focus: true }))

									handleClose()

								}}>
									<ListItemIcon>
										<img
											alt="Tag"
											style={{
												maxHeight: '24px',
												maxWidth: '24px',
											}}
											src="../../images/icons/Grey/Tags.svg"
										/>
									</ListItemIcon>
									<ListItemText style={{paddingRight: '1em'}}>
									Add Tag
									</ListItemText>
								</MenuItem>
							)

						case 'createDeal':
							return (Number(commonPermission?.permission_value_id) >= permissionValues.VIEW_ONLY  && // TODO
							Number(dealPermission?.permission_value_id) >= permissionValues.CREATE_AND_EDIT_OWN ) ?
								(
									<MenuItem key={option} onClick={() => {

										const type = props?.options?.object

										const contacts = type == 'contact' ?
											[props.entity] : []

										const art = type == 'art' ?
											[props.entity] : []

										props.setQVOverride(
											<CreateDealQuickView
												contacts={contacts}
												art={art}
												setCreateDeal={() => props.setQVOverride(null)}
												darkTheme={props.entity?.is_private}
											></CreateDealQuickView>
										)

										handleClose()
									}}>
										<ListItemIcon>
											<img
												alt="Create Deal"
												style={{
													maxHeight: '24px',
													maxWidth: '24px',
												}}
												src="../../images/icons/Grey/Deals.svg"
											/>
										</ListItemIcon>
										<ListItemText>
										Create Deal
										</ListItemText>
									</MenuItem>
								) : null
						case 'changeListingType':
							return <MenuItem key={option} 
								onClick={() => {
									setListingTypeModal(true)
									handleClose()
								}}
							>
								<ListItemIcon>
									<SettingsIcon 
										sx={{ color: '#919191' }}
									/>
								</ListItemIcon>
								<ListItemText>Change Listing Type</ListItemText>
							</MenuItem>
						case 'info':
							return <MenuItem key={option} 
								onClick={() => {
									setInfoModal(true)
									handleClose()
								}}
							>
								<ListItemIcon>
									<InfoOutlinedIcon 
										sx={{
											color: '#919191'
										}}
									/>
								</ListItemIcon>
								<ListItemText>Info</ListItemText>
							</MenuItem>

						case 'curatedLists': 
							return <MenuItem key={option} onClick={()=> {

								if (!props.showcaseSelectMode?.artIds?.length) {
									openSnackbar(severity.WARNING, "There is nothing selected.")
								}

								else setCuratedListModal(true)	

								handleClose()
							}}>
								<ListItemIcon>
									<ViewCompactIcon style={{
										maxHeight: '24px',
										maxWidth: '24px',
										color: '#919191'
									}}/>
								</ListItemIcon>
								<ListItemText style={{paddingRight: '1em'}}>
									Create Curated List
								</ListItemText>
							</MenuItem>
						case 'showcasePdf': 
							return <MenuItem key={option} onClick={()=>{

								// Get art ids of listing to export
								setLoading(true)
								setPdfModal(true)
								client.query({ query: GET_LISTING_ART_IDS, variables: { id: props.objectId} })
									.then(({ data }) => {
										setListingArtIds(data.getListingArtIds)
										setLoading(false)
									})
									.catch(error => {
										setLoading(false)
										console.error(error)
										openSnackbar(severity.ERROR, "There was an error retrieving the art for export.")
									})

								handleClose()
							}}>
								<ListItemIcon>
									<PictureAsPdfIcon style={{
										maxHeight: '24px',
										maxWidth: '24px',
										color: '#919191'
									}}/>
								</ListItemIcon>
								<ListItemText style={{paddingRight: '1em'}}>
									Export to PDF
								</ListItemText>
							</MenuItem>
						default:
							return null
						}
					})}
				</Menu>
			</Paper>

			<UploadModal
				open={fileModal}
				setOpen={() => setFileModal(false)}
				object={props.options?.object}
				objectId={props.objectId}
				atPrivateObject={props.atPrivateObject}
				fileObjectId={props.options?.fileObjectId}
				updateQuery={props.options?.updateQuery}
				updateFileQuery={props.options?.updateFileQuery}
				tabbedPageFileRefetch={props.tabbedPageFileRefetch}
				setTabbedPageFileRefetch={props.setTabbedPageFileRefetch}
			/>

			{props.options?.object === "art" ?
				<FactSheetModal
					open={factSheetModal}
					close={() => setFactSheetModal(false)}
					entity={props.entity}
					state={props.state}
					setState={props.setState}
					private={props.entity?.is_private}
				/> : null
			}

			{props.options?.object === "art" ?
				<ShippingFormModal
					title="Create Shipping Form"
					template="Art Shipping Form"
					includeHeader={true}
					includeFooter={false}
					open={shipFormModal}
					close={() => {setShipFormModal(false)}}
					// entity={props.entity}
					private={props.entity?.is_private}
					selectedRows={
						[{
							id: props.entity?.id,
							title: props.entity?.title,
							profile_link: props.entity?.profile_link,
							artist: props.entity?.artist,
							__typename: "Art"
						}]
					}
				/> : null
			}
			{props.options?.object === "deal" ?
				<ShippingFormModal
					title="Create Consignment Form"
					template="Art Consignment Form"
					includeHeader={true}
					includeFooter={false}
					open={shipFormModal}
					close={() => {setShipFormModal(false)}}
					private={props.entity?.is_private}
					extraData={{
						dealId: props.entity?.id,
						dealType: props.entity?.deal_type?.value,
						...consignmentData
					}}
					extraPages={[
						<ConsignmentTerms
							data={consignmentData}
							setData={(data)=>{setConsignmentData(data)}}
						/>
					]}
				/> : null
			}

			{ props.options?.object === "art" ?
				<UniqueArtLabelModal 
					open={labelFormModal}
					close={() => setLabelFormModal(false)}
					entity={props.entity}
				/>
				: null
			}

			<ConfirmationDialog
				open={deletionConfirmation}
				handleClose={() => setDeletionConfirmation(false)}
				title={'Are you sure?'}
				acceptText={'Delete'}
				buttonColor="#cc3333"
				text={`This will permanently delete this ${props.options?.object}. Are you sure?`}
				onYes={() => {

					switch (props.options?.object) {
					case 'contact':
						deleteContact({ variables: { id: props.objectId } })
						break

					case 'art':
						deleteArt({ variables: { id: props.objectId } })
						break

					case 'listing':
						deleteListing({ variables: { id: props.objectId } })
						break

					case 'deal':
						deleteDeal({ variables: { id: props.objectId } })
						break

					case 'artist':
						deleteArtist({ variables: { id: props.objectId } })
						break

					default:
						openSnackbar(severity.ERROR, "Unknown entity.")
						break
					}
				}}
			/>

			{props.options?.object === "listing" ?
				<ShowcaseModal
					open={showcaseModal}
					close={() => setShowcaseModal(false)}
					entity={props.entity}
					state={props.state}
					setState={props.setState}
					private={props.entity?.is_private}
					entityName={props.options?.object}
					listingMode={props.options?.object === "listing"}
				/> : null}

			<InfoCard
				open={infoModal}
				close={() => setInfoModal(false)}
				object={props.entity}
				ignoredAttributes={[
					'imgUrl',
					'phoneCount', 'emailCount', 'websiteCount', 'addressCount',
					'education', 'selected_awards', 'selected_monographs',
					'selected_solo_exhibitions',
					
				]}
				allAttributes
			/>

			{props.entity && listingTypeModal && <ListingTypeChangeModal
				open={listingTypeModal}
				close={() => setListingTypeModal(false)}
				listing={props.entity}
				setState={props.setState}
			/>}

			<CuratedListModal
				open={curatedListModal}
				setOpen={setCuratedListModal}
				showcaseSelectMode={props.showcaseSelectMode}
				setShowcaseSelectMode={props.setShowcaseSelectMode}
			/>

			<PdfExport
				open={pdfModal}
				loading={loading}
				close={() => {
					setPdfModal(false)
					setListingArtIds([])
				}}
				selectedRows={listingArtIds?.map(e => ({
					id: e,
					__typename: 'Art'
				}))}
			/>
		</>
	)

	return props.atPrivateObject ? (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={privateObjectTheme}>{content}</ThemeProvider>
		</StyledEngineProvider>
	) : (
		content
	)
}

export default TabBar

function UniqueArtLabelModal(props) {

	const [artLabelFields, setArtLabelFields] = useState({})
	const [loading, setLoading] = useState(false)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	return <TransitionModal
		open={props.open}
		close={props.close}
	>
		<h1 className="card-title">
			<span>Art Label Sheet</span>
		</h1>
		<ArtLabelModal
			onChange = {val => setArtLabelFields(val)}
			fields = {artLabelFields}
	 />
	 <div style={{
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
			paddingTop: '1em',
			marginBottom: '0.1em'
		}}>
			<CancelButton
				variant="contained"
				onClick={props.close}
			>
				Cancel
			</CancelButton>
			<SubmitButton
				style={{ marginLeft: '1em' }}
				variant="contained"
				disabled={loading}
				onClick={() => {
					props.close()
					getPdf(
						'Art Label Sheet',
						null,
						null,
						[props.entity],
						openSnackbar,
						{
							...artLabelFields,
							entityId: props.entity?.id,
						},
						null,
						setLoading
					)
				}}
			>
				Download
			</SubmitButton>
		</div>
	</TransitionModal> 
}
