import React, { useEffect, useCallback, useState } from "react"
import { Paper, MenuItem, Menu, FormControl, Skeleton } from '@mui/material'
import { SaveCardButton, CancelButton } from './Buttons'
import TextBox from './Text/TextBox'
import { FindModeInput } from "../navigation/Tabs/TabbedPage"

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false
}

/**
 * @typedef AboutProps
 * @property {Object} entity 
 * @property {function} onSave
 */

/**
 * @param {AboutProps} props
 */
function AboutCard(props) {

	const [state, setState] = useState(initialState)

	const [entityInput, setEntityInput] = React.useState('')

	const handleClick = event => {
		if (state.mouseX || state.editable || props.findMode || !props.entity?.id) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const resetEntityInput = useCallback((entity) => {
		setEntityInput(entity?.about)
	}, [])

	useEffect(() => {
		resetEntityInput(props.entity)
	}, [props.entity, resetEntityInput])

	const handleClose = (option) => {
		if (option === 'edit') {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	return (
		<Paper className="qv-margin" 
			id="about"
			onContextMenu={handleClick}
			data-testid="card-about"
		>
			<h1 className="card-title">
				<span>About</span>
				{state.editable && !props.findMode && (<>
					<div className="spacer"></div>
					<CancelButton variant="contained" size="small"
						onClick={() => {
							resetEntityInput()
							handleClose()
						}}>Cancel</CancelButton>
					<SaveCardButton 
						variant="contained" 
						color="secondary"
						disabled={props.disabled}
						size="small" 
						onClick={() => {
							props.onSave({
								id: props.entity.id,
								about: entityInput
							})
							handleClose()
						}}
					>
						Save
					</SaveCardButton>
				</>)}
			</h1>
			<div>
				{props.findMode ? <FindModeInput 
					field="about" 
					style={{ width: '100%' }} 
				/> :
					(!state.editable ?
						<>{props.loading ? <Skeleton variant="rectangle" animation="wave" height='5em'/> : <div>{props.entity.about || '-'}</div>}</>
						:
						<FormControl fullWidth={true} style={{ paddingBottom: "0.5em" }}>
							<TextBox
								placeholder="-"
								id="filled-multiline-flexible"
								label="Multiline"
								multiline
								rowsMax="15"
								value={entityInput}
								variant="filled"
								onChange={e => setEntityInput(e.target.value)}
								maxLength={32768}
							/>
						</FormControl>
					)}
			</div>

			<Menu
				keepMounted
				open={state.mouseY !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					state.mouseY !== null && state.mouseX !== null
						? { top: state.mouseY, left: state.mouseX }
						: undefined
				}
			>
				<MenuItem onClick={() => {
					handleClose('edit')
				}}>Edit</MenuItem>
			</Menu>
		</Paper>
	)
}

export default AboutCard
