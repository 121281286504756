import withStyles from '@mui/styles/withStyles'
import InputBase from '@mui/material/InputBase'
import React from "react"

const TextBoxStyled = withStyles(theme => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(3),
		},
	},
	input: {
		borderRadius: 4,
		position: 'relative',
		border: '1px solid #ced4da',
		fontSize: 16,
		padding: '10px 12px',
		borderColor: "white",
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: 'Graphik',
		'&:focus': {
			borderRadius: 4,
			borderColor: "white",
			backgroundColor: "rgba(255, 255, 255, 0.05)"
		},
	},
	error: {
		border: '1px solid red',
		borderRadius: 4,
	}
}))(InputBase)

function TextBox(props) {

	const divProps = Object.assign({}, props)
	delete divProps.helperText
	delete divProps.InputProps
	
	return <TextBoxStyled {...divProps} />
}

export default TextBox
