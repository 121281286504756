/* eslint-disable eqeqeq */
import React, { useContext, useState } from 'react'
import { LookupContext } from '../../store'
import DealQuickView from '../../Deals/DealQuickView'
import { permissions, permissionValues } from '../../constants/permissions'
import UserAccessQuickView from '../../QuickView/UserAccessQuickView'
import DealEntryList from '../../DealEntry/DealEntryList'
import { deal_entry_status_categories } from '../../DealEntry/constants'
import ContactDealEntrySearch from '../Contact/ContactDealEntrySearch'

export default function OffersAndReserves(props) {

	const lookup = useContext(LookupContext)

	const [selectedRow, setSelectedRow] = useState(null)
	const [requeryDealEntry, setRequeryDealEntry] = useState({})

	const dealEntryStatusCategories = lookup.data?.getDealEntryStatusCategories

	React.useEffect(() => {

		if ((selectedRow?.deal?.is_private || selectedRow?.deal?.is_gallery_private) && !selectedRow?.deal?.created_at) {
			props.setQVOverride(() => <UserAccessQuickView
				entity={selectedRow} 
				onClose={() => setSelectedRow(null)}
				idOverride="deal_id" 
			/>)
		} else if (selectedRow) {
			props.setQVOverride(() => <DealQuickView 
				id={selectedRow.deal_id} 
				onClose={() => setSelectedRow(null)}
			/>)
		} else {
			props.setQVOverride(null)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRow])

	return (
		<>
			{props.findMode ? 
				<ContactDealEntrySearch 
					category={dealEntryStatusCategories.find(e => e.id == deal_entry_status_categories.offers)}
				/> : 
				<DealEntryList
					page='contact'
					category={dealEntryStatusCategories
						?.find(e => e.id == deal_entry_status_categories.offers)} 
					initialSort='start_at'
					privateEntity={props.state?.getContact?.is_private || false}
					selectedRow={selectedRow}
					setSelectedRow={setSelectedRow}
					setQVOverride={props.setQVOverride}
					contact={props.state?.getContact}
					creation={props.id > 0}
					otherPermissionPair={[permissions.DEALS, permissionValues.CREATE_AND_EDIT_OWN]}
					creationButtonName="Create New Deal"
					requeryDealEntry={requeryDealEntry}
					setRequeryDealEntry={setRequeryDealEntry}
				/> }
			{props.findMode ? 
				<ContactDealEntrySearch 
					category={dealEntryStatusCategories?.find(e => e.id == deal_entry_status_categories.reserves)} 
				/> : 
				<DealEntryList
					page='contact'
					category={dealEntryStatusCategories
						?.find(e => e.id == deal_entry_status_categories.reserves)} 
					privateEntity={props.state?.getContact?.is_private || false}
					selectedRow={selectedRow}
					setSelectedRow={setSelectedRow}
					setQVOverride={props.setQVOverride}
					contact={props.state?.getContact}
					requeryDealEntry={requeryDealEntry}
					setRequeryDealEntry={setRequeryDealEntry}
				/> }
		</>
	)
}
