import React, { useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import Thumbnail from '../Thumbnail/Thumbnail'
import { CircularProgress } from '@mui/material'
import { autoCompleteErrorStyles } from '../styles/makesStyles'
import { useApolloClient } from '@apollo/client'
import TextBoxForAutocomplete from './Text/TextBoxForAutocomplete'
import { GET_POSSIBLE_ACCESS_USERS } from '../PrivateAccess/Queries'

/**
 * @typedef MultiplePrivateAccessUsers
 * @property {boolean=} error - if the value isn't valie
 * @property {DocumentNode} query - the query to call for the autocomplete
 * @property {function} onChange - a callback when the contact value changes
 * @property {boolean=} private - if the ... context? is private
 * @property {*} value - currently selected 'User' object
 * @property {String} entityType - type of entity we're searching for users for
*/

/**
 * @param {MultiplePrivateAccessUsers} props
 */
export default function MultiplePrivateAccessUsers (props) {

	// pass down styling/id props that are passed in
	const passedProps = {}
	if (props.className) passedProps.className = props.className
	if (props.style) passedProps.style = props.style
	if (props['data-testid']) passedProps['data-testid'] = props['data-testid']
	if (props.onChange) passedProps.onChange = props.onChange

	const [userOpen, setUserOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const [options, setOptions] = useState([])

	const client = useApolloClient()

	React.useEffect(() => {
		
		let active = true
		
		if (userOpen === true) {
			setLoading(true)
			client.query({
				query: GET_POSSIBLE_ACCESS_USERS,
				variables: {
					// id: props.entity.id,
					entity: props.entityType,
					galleries: props.entityType === 'deal' ? 
						props.input?.gallery_access?.map(e => e.id) : 
						[]
				}
			}).then((result) => {
				if (active) {
					setLoading(false)
					setOptions(result.data.getPossibleUserAccess)
				}
			})
		}
		
		return () => active = false
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userOpen, props.entityType])



	return <Autocomplete
		multiple
		isOptionEqualToValue={(a, b) => a?.id === b?.id}
		className="fullWidth"
		open={userOpen}
		forcePopupIcon
		style={{...props.style}}
		popupIcon={<ExpandMoreRoundedIcon />}
		onOpen={() => setUserOpen(true)}
		onClose={() => setUserOpen(false)}
		loading={loading}
		options={options}
		value={props.value}
		getOptionLabel={val => val.id ? [
			val.first_name,
			val.last_name
		].filter(a => a).join(' ') : '' }
		classes={autoCompleteErrorStyles()}
		getOptionDisabled={option => option.disabled}
		filterSelectedOptions
		renderOption={(optionProps, option) => {
			// eslint-disable-next-line eqeqeq
			const user = options?.find(e => e.id == option || e.id == option.id) || {}

			const name = [user.first_name, user.last_name].filter(a => a).join(' ')

			const thumbnail = <Thumbnail
				name={name}
				detail={user?.department ?? "-"}
				avatar={user?.imgUrl}
				type="contact"
				animation={loading ? 'wave' : false}
				darkText={!props.private}
				largeText
			/>

			if (option.disabled) {
				return (
					<li {...optionProps} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
						{thumbnail}
						<i>Already Included</i>
					</li>
				)
			}
			return <li {...optionProps}>{thumbnail}</li>
		}}

		renderInput={(params) => {
			return (
				<TextBoxForAutocomplete
					autoFocus
					{...params}
					error={props.error}
					variant="outlined"
					InputProps={{
						...params.InputProps,
						endAdornment: <>
							{loading ? (
								<CircularProgress
									color="inherit"
									size={20}
								/>
							) : null}
							{params.InputProps.endAdornment}
						</>,
						style: {
							paddingTop: '3px',
							paddingBottom: '3px',
						},
					}}
				/>
			)	
		}}
		{...passedProps}
	/>
}
