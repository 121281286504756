import React from 'react'
import { Avatar } from "@mui/material"
import clsx from 'clsx'
import { format } from 'date-fns'
import { avatarStyles } from '../styles/makesStyles'
import { Skeleton } from '@mui/material'
import { shorten, isNullArray } from '../common/helpers'
import { useLocation, useNavigate } from 'react-router-dom'

const QuickViewNotes = (props) => {

	const { notes, privateObject } = props
	const classes = avatarStyles()
	const location = useLocation()
	const navigate = useNavigate()

	const qvPath = location.pathname.substring(0, location.pathname.lastIndexOf("/"))

	return (
		<div className='flex flex-col w-full gap-2'>
			<div style={props.style} className={`${privateObject ? "text-black" : "text-grey"} text-sm font-normal w-full`}>Critical Notes</div>
			<>
				{notes && !isNullArray(notes) && notes.sort((a,b) => b.modified_at - a.modified_at ).map((note, i) => {
					return (
						<div key={"note-"+note.id+"critical"}
							style={{ margin: 0 }}
							className={clsx({
								'shadow-group': !privateObject,
								'shadow-group-light': privateObject,
								'private-object': note.is_private,
								'private': note.is_private,
							})}
						>
							<div style={{
								cursor: 'pointer'
							}} onClick={(event) => {
								var path
								if(qvPath === ''){
									path = `${location.pathname}/${props.entity.id}/notes`
								} else {
									switch(props.entity.__typename){
									case 'Contact':
										path = `/contacts/${props.entity.id}/notes`
										break
									case 'Art':
										path = `/art/${props.entity.id}/notes`
										break
									case 'Deal':
										path = `/deals/${props.entity.id}/notes`
										break
									case 'Artist':
										path = `/artists/${props.entity.id}/notes`
										break
									default:
										path = `${qvPath}/notes`
										break
									}
								}
								if (event.metaKey) {
									window.open(path, '_blank')
								} else {
									navigate(path)
								}
							}
							}>

								<div>
									<div style={{
										padding: "1em",
										color: note.is_private ? 'white' : 'inherit'
									}}>
										<div style={{
											display:"flex",
											alignItems:"center",
											justifyContent:"space-between"
										}}>
											<div style={{display: "flex"}}>
												<Avatar className={classes.small}
													src={note.user?.imgUrl}
												></Avatar>
												<span style={{fontSize: 'small', marginTop: 3, marginLeft: 3}}>{note.user?.first_name +" "+ note.user?.last_name}</span>
											</div>
											<div style={{fontSize: "smaller"}}>
												{format(
													new Date(
														Number(note.applied_at)
													),
													"MMMM do, yyyy"
												)}
											</div>

										</div>
										<div style={{
											whiteSpace: "pre-line",
											maxHeight: "15em",
											overflow: "auto",
											paddingTop: "1em"
										}}>
											{shorten(note.note_text?.trim() ?? 'Private Note', 200)}
										</div>
									</div>
								</div>
							</div>
						</div>
					)

				})}

				{!notes || isNullArray(notes) || notes.length === 0 ?
					<table id="contact-table" className={clsx({
						'shadow-group': !privateObject,
						'shadow-group-light': privateObject
					})}>
						<tbody>
							<tr>
								{props.loading ? <th>
									<Skeleton animation={'wave'} style={{marginTop: '-0.5em'}} variant="text" width="100%" height="2em"/>
									<Skeleton animation={'wave'} style={{marginTop: '-0.5em', marginBottom: '-0.4em'}} variant="text" width="100%" height="3em"/>
								</th> : <th>Nothing to display here.</th>}
							</tr>
						</tbody>
					</table>
					: null}
			</>
		</div>
	)
}

export default QuickViewNotes
