/* eslint-disable eqeqeq */
import React, { useContext } from 'react'
import QuickView from '../QuickView/QuickView'
import { withApollo } from '@apollo/client/react/hoc'
import TextBox from '../input/Text/TextBoxThin'
import Label from '../input/Label'
import Dropdown from '../input/Dropdown/Dropdown'
import { FormControl, Select, MenuItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import sortBy from 'lodash/sortBy'
import SalespersonSelection from './Contact/Salesperson/SalespersonSelection'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { useMutation } from '@apollo/client'
import { CREATE_CONTACT } from './Queries.js'

import { SubmitButton, CancelButton } from '../input/Buttons'
import { capitalizeFirstLetter } from '../common/helpers'
import PhoneInput from 'react-phone-input-2'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { DispatchContext } from '../store'

import { LookupContext } from '../store'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

import NewPhoneModal from './Modals/NewPhoneModal'
import NewEmailModal from './Modals/NewEmailModal'
import NewAddressModal from './Modals/NewAddressModal'
import NewWebsiteModal from './Modals/NewWebsiteModal'
import AvatarInput from '../common/AvatarInput'

import { getFormattedAddress } from '../Contacts/Contact/ContactInformation'
import MultiTypeModal from '../common/MultiTypeModal'
import { contactStatusConstants, contactGradeConstants, contactTypeConstants } from '../constants/values'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	large: {
		width: 210,
		height: 210,
	},
}))



// Get phone label
export const getPhoneLabel = (phone) => {
	if (!phone) return 'Primary Phone (Mobile)'
	else {
		if (!phone.type) phone.type = 'Mobile'

		if (phone.label) {
			return phone.label + ' (' + capitalizeFirstLetter(phone.type) + ')'
		} else {
			return 'Primary Phone (' + capitalizeFirstLetter(phone.type) + ')'
		}
	}
}

/**
 * @typedef ContactQuickViewProps
 * @property {import('./Contacts').Contact} contact
 */

/**
 * @param {ContactQuickViewProps} props
 */
function ContactQuickView(props) {
	const classes = useStyles()

	const lookup = useContext(LookupContext)
	const navigate = useNavigate()

	const prefixes = lookup.data?.getPrefixTypes
	const sortedPrefixes = sortBy(prefixes, [
		function (o) {
			return o.value
		},
	])
	const grades = lookup.data?.getGradeTypes
	const contactStatus = lookup.data?.getContactStatus

	const activities = lookup.data?.getActivityTypes
	const emailTypes = lookup.data?.getEmailTypes
	const phoneTypes = lookup.data?.getPhoneTypes
	const websiteTypes = lookup.data?.getWebsiteTypes
	const addressTypes = lookup.data?.getAddressTypes
	const countries = lookup.data?.getCountryTypes
	const types = lookup.data?.getContactTypes

	// Other useState hooks
	const [attempt, setAttempt] = React.useState(false)

	// Modals for more complex additions
	const [phoneModal, setPhoneModal] = React.useState({ open: false })
	const [emailModal, setEmailModal] = React.useState({ open: false })
	const [websiteModal, setWebsiteModal] = React.useState({ open: false })
	const [addressModal, setAddressModal] = React.useState({ open: false })
	const [salespersonModal, setSalespersonModal] = React.useState(false)
	const [typeModal, setTypeModal] = React.useState(false)

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}

	const [createContact, { loading }] = useMutation(CREATE_CONTACT)
	const [contactInput, setContactInput] = React.useState({
		first_name: '',
		last_name: '',
		salespersons: [],
		primary_salespersons: [],
		profile_link: null,
		website: [],
		address: [],
		email: [],
		phone: [],
		types: [
			{contact_type_id: contactTypeConstants.UNASSIGNED, contact_type: 'UNASSIGNED', ordinal: 1}
		],
		grade_id: contactGradeConstants.NA,
		status_id: contactStatusConstants.TBD
	})

	const typeDropdownValues = types?.filter(type =>
		!contactInput.types?.find(it => it.contact_type_id == type.id)
	)
	const sortedTypeDropdownValues = sortBy(typeDropdownValues, ['value'])

	const addToContactInput = (type, obj) => {
		setContactInput({
			...contactInput,
			[type]: obj,
		})
	}

	const { isCompany } = props

	const showcaseStyles = {
		width: '30em',
		maxHeight: '80vh',
		overflow: 'auto'
	}

	const submit = () => {
		// Filter falsy values
		let tempContactInput = contactInput
		delete tempContactInput.imgUrl
		Object.entries(tempContactInput)
			.filter(([k, v]) => !v || v.length === 0)
			.forEach(([k, v]) => delete tempContactInput[k])

		// Map to salespersons ID
		if (tempContactInput.salespersons) {
			tempContactInput.salespersons_ids = tempContactInput.salespersons.map((salesperson) => salesperson.id)
			delete tempContactInput.salespersons
		}

		if (tempContactInput.primary_salespersons) {
			tempContactInput.primary_salesperson_ids = tempContactInput.primary_salespersons.map((salesperson) => salesperson.id)
			delete tempContactInput.primary_salespersons
		}

		// Map to primary salesperson ID
		if (tempContactInput.primary_salesperson && tempContactInput.primary_salesperson.id) {
			tempContactInput.primary_salesperson_id = tempContactInput.primary_salesperson.id
			delete tempContactInput.primary_salesperson
		}

		if (tempContactInput.image) {
			delete tempContactInput.image.__typename
		}

		if (!isCompany) {
			let prefixSalutation = prefixes.find((item) => item.id === tempContactInput.prefix_id)?.value
			tempContactInput.salutation = prefixSalutation
				? `${prefixSalutation} ${tempContactInput.first_name} ${tempContactInput.last_name}`
				: `${tempContactInput.first_name} ${tempContactInput.last_name}`
		}

		if (tempContactInput.phone?.type) {
			delete tempContactInput.phone.type
		}

		// Delete types
		if (tempContactInput.address?.type) {
			delete tempContactInput.address.type
		}

		if (tempContactInput.website?.type) {
			delete tempContactInput.website.type
		}

		if (tempContactInput.email?.type) {
			delete tempContactInput.email.type
		}

		if (tempContactInput.address?.country) {
			delete tempContactInput.address.country
		}

		if (isCompany) {
			tempContactInput = {
				...tempContactInput,
				is_company: true,
				first_name: '-',
				last_name: '-'
			}
		}

		createContact({
			variables: {
				CreateContactInput: tempContactInput,
			},
		})
			.then((res) => {
				setAttempt(false)
				if (res.data.createContact.success) {
					setAttempt(false)
					openSnackbar(severity.SUCCESS, 'Contact created successfully.')

					if (props.showcaseMode) {		
						navigate(`/showcase/contacts/${res.data.createContact.contact.id}`)
					}

					else {
						props.setCreateContact(null)
						navigate(`/contacts/${res.data.createContact.contact.id}/details`)
					}


				} else {
					setAttempt(false)
					console.error(res)
					openSnackbar(severity.ERROR, 'Could not create contact.')
				}
			})
			.catch((error) => {
				console.log(error)
				openSnackbar(severity.ERROR, 'There was an error creating the contact.')
			})
	}


	const inner = <form
		style={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%' }}
		onSubmit={(event) => {
			event.preventDefault()
			setAttempt(true)

			if (isCompany) {
				if (attempt && (!contactInput.status_id || !contactInput.company_name || !contactInput.grade_id || Object.keys(contactInput.address || {}).length === 0)) {
					openSnackbar(severity.WARNING, 'Please fill fields in red.')
				} else if (!(!contactInput.status_id || !contactInput.company_name || !contactInput.grade_id || Object.keys(contactInput.address || {}).length === 0)) {
					submit()
				}
			} else {
				if (
					attempt &&
				(!contactInput.status_id || !contactInput.first_name ||
					!contactInput.last_name || !contactInput.grade_id ||
					Object.keys(contactInput.address || {}).length === 0 ||
					!contactInput.types?.length
				)
				) {
					openSnackbar(severity.WARNING, 'Please fill fields in red.')
				} else if (
					!(!contactInput.status_id || !contactInput.first_name ||
						!contactInput.last_name || !contactInput.grade_id ||
						Object.keys(contactInput.address || {}).length === 0 ||
					!contactInput.types?.length
					)
				) {
					submit()
				}
			}
		}}
	>
		<div className={!props.showcaseMode ? "quick-view-inner" : null} style={props.showcaseMode ? showcaseStyles : null }>
			<div style={{ width: '100%' }}>
				<h2 className="card-title">Create New {isCompany ? 'Company' : 'Contact'}</h2>

				{!isCompany ? (
					<FormControl style={{ float: 'left', width: '47%' }}>
						<Label id="prefix-label" disableAnimation shrink>
							Prefix
						</Label>

						<Select
							IconComponent={ExpandMoreRoundedIcon}
							name="prefix"

							labelId="prefix-label"
							className="medium-selection"
							input={<Dropdown />}
							value={contactInput?.prefix_id ? parseInt(contactInput?.prefix_id) : 'All'}
							onChange={(e) => {
								setContactInput({
									...contactInput,
									prefix_id: e.target.value === 'All' ? null : e.target.value,
								})
							}}
							data-testid="contact-prefix"
						>
							<MenuItem value={'All'}>-</MenuItem>
							{sortedPrefixes?.map((prefix) => (
								<MenuItem key={prefix.id} value={prefix.id} data-testid="contact-prefix-item">
									{prefix.value}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				) : null}

				<FormControl style={!isCompany ? { float: 'right', width: '47%' } : { width: '100%' }}>
					<Label id="status-label" disableAnimation shrink error={attempt && !contactInput?.status_id}>
						Status*
					</Label>

					<Select
						IconComponent={ExpandMoreRoundedIcon}
						name="status"

						labelId="status-label"
						className="medium-selection"
						error={attempt && !contactInput?.status_id}
						input={<Dropdown />}
						value={contactInput?.status_id || ''}
						onChange={(e) => {
							setContactInput({
								...contactInput,
								status_id: e.target.value,
							})
						}}
						data-testid="contact-status"
					>
						{contactStatus?.map((type) => (
							<MenuItem key={type.id} value={type.id} data-testid="contact-status-item">
								{type.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>

			{isCompany ? (
				<FormControl fullWidth>
					<Label disableAnimation shrink 
						htmlFor="contact-company-name" 
						error={attempt && !contactInput?.company_name}
					>
						Company Name*
					</Label>

					<TextBox
						error={attempt && !contactInput?.company_name}
						id="contactQV-company-name"
						onChange={(e) => {
							setContactInput({
								...contactInput,
								company_name: e.target.value,
							})
						}}
						data-testid="contact-company_name"
					/>
				</FormControl>
			) : (
				<>
					<FormControl fullWidth>
						<Label disableAnimation shrink 
							htmlFor="contact-first-name" 
							error={attempt && !contactInput?.first_name}
						>
							First Name*
						</Label>

						<TextBox
							error={attempt && !contactInput?.first_name}
							id="contactQV-first-name"
							onChange={(e) => {
								setContactInput({
									...contactInput,
									first_name: e.target.value,
								})
							}}
							data-testid="contact-first_name"
						/>
					</FormControl>

					<FormControl fullWidth>
						<Label disableAnimation shrink 
							htmlFor="contactInputQV-last-name" 
							error={attempt && !contactInput?.last_name}
						>
							Last Name*
						</Label>

						<TextBox
							error={attempt && !contactInput?.last_name}
							id="contactQV-last-name"
							onChange={(e) => {
								setContactInput({
									...contactInput,
									last_name: e.target.value,
								})
							}}
							data-testid="contact-last_name"
						/>
					</FormControl>
				</>
			)}

			<AvatarInput
				style={{
					textAlign: 'center',
					marginTop: '1em',
				}}
				newEntity={true}
				className={classes.large}
				alt={contactInput?.preferred_name || 'New Contact'}
				src={contactInput?.imgUrl}
				editable={true}
				oldProfileLink={[]}
				inputName="CreateContactInput"
				entityId={null}
				setEntity={setContactInput}
				entity={contactInput}
				entityName="contact"
				creation={true}
				isCompany={isCompany}
			/>
			<FormControl style={{ width: '100%' }}>
				<Label disableAnimation shrink
					htmlFor="contactQV-type"
					error={attempt && !contactInput?.types?.length}
				>
					Type*
				</Label>

				<TextBox
					error={attempt && !contactInput?.types?.length}
					inputProps={{ spellCheck: 'false' }}
					id="contact-types"
					value={contactInput.types
						?.map(item => item.contact_type)
						?.join(", ") || ""
					}
					placeholder={'-'}
					onClick={(e) => setTypeModal(true) }
					data-testid="contact-type"
				/>
			</FormControl>


			<div style={{ width: '100%' }}>
				<FormControl style={{ float: 'left', width: '47%' }}>
					<Label id="gradeQV-label" disableAnimation shrink 
						error={attempt && !contactInput?.grade_id}
					>
						Grade*
					</Label>

					<Select
						IconComponent={ExpandMoreRoundedIcon}
						name="grade"

						labelId="gradeQV-label"
						className="medium-selection"
						error={attempt && !contactInput?.grade_id}
						input={<Dropdown />}
						value={contactInput?.grade_id || ''}
						onChange={(e) => {
							setContactInput({
								...contactInput,
								grade_id: e.target.value,
							})
						}}
						data-testid="contact-grade"
					>
						{grades?.map((grade) => (
							<MenuItem key={grade.id} value={grade.id} data-testid="contact-grade-item">
								{grade.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl style={{ float: 'right', width: '47%' }}>
					<Label id="activityQV-label" disableAnimation shrink>
						Activity
					</Label>

					<Select
						IconComponent={ExpandMoreRoundedIcon}
						name="activity"

						labelId="activityQV-label"
						className="medium-selection"
						input={<Dropdown />}
						value={contactInput?.activity_id || ''}
						onChange={(e) => {
							setContactInput({
								...contactInput,
								activity_id: e.target.value,
							})
						}}
						data-testid="contact-activity"
					>
						<MenuItem key={'No Activity'} value={''} data-testid="contact-activity-item">
							{'-'}
						</MenuItem>
						{activities?.map((activity) => (
							<MenuItem key={activity.id} value={activity.id} data-testid="contact-activity-item">
								{activity.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>

			<FormControl style={{ width: '100%' }}>
				<Label disableAnimation shrink htmlFor="contactQV-gallery-contacts">
					Gallery Contacts
				</Label>

				<TextBox
					multiline
					readOnly
					inputProps={{ spellCheck: 'false' }}
					className="contactQV-input force-border"
					id="contactQV-gallery-contacts"
					value={contactInput.salespersons?.map((salesperson) => salesperson.first_name + ' ' + salesperson.last_name).join(', ') || ''}
					onClick={(e) => {
						setSalespersonModal(true)
					}}
					data-testid="contact-gallery-contacts"
				/>
			</FormControl>

			<FormControl fullWidth style={contactInput?.phone?.number ? { marginTop: '-2px' } : null}>
				<Label disableAnimation shrink htmlFor="contact-primary-phone" className="flag-label">
					{/* This is just the flag of the phone number.  */}
					{contactInput?.phone?.number ? (
						<PhoneInput
							placeholder=""
							disabled
							dropdownStyle={{ borderRadius: '4px' }}
							inputStyle={{ width: '90%', height: '33px', fontFamily: 'graphik' }}
							value={contactInput?.phone?.number}
						/>
					) : null}
					{getPhoneLabel(contactInput?.phone)}
				</Label>

				<div className="information" style={{ display: 'flex' }} data-testid="contact-primary_phone">
					<PhoneInput
						placeholder=""
						className="contactQV-input"
						id="contact-primary-phone"
						inputStyle={{ fontFamily: 'graphik' }}
						style={contactInput?.phone?.number ? { paddingTop: '0.6em', width: '100%' } : { paddingTop: '0.3em' }}
						value={contactInput?.phone?.number}
						onChange={() => {}}
						onClick={(e) => {
							setPhoneModal({
								open: true,
								count: 1,
								phone: {
									...phoneModal.phone,
									type_id: contactInput.phone?.type_id || 2,
								},
							})
						}}
					/>
					<span style={{ alignSelf: 'center' }}>{contactInput?.phone?.extension ? `${contactInput?.phone?.extension}` : null}</span>
				</div>
			</FormControl>

			<FormControl fullWidth>
				<Label disableAnimation shrink htmlFor="contact-primary_email">
					{contactInput?.email?.label || 'Primary Email'} {contactInput?.email?.type_id !== undefined ? '(' + capitalizeFirstLetter(contactInput?.email?.type) + ')' : '(Work)'}
				</Label>

				<TextBox
					readOnly
					className="contactQV-input force-border"
					id="contact-primary-email"
					value={contactInput?.email?.email}
					onClick={() => {
						setEmailModal({
							open: true,
							count: 1,
							email: {
								...emailModal.email,
								type_id: contactInput.email?.type_id || 2,
							},
						})
					}}
					data-testid="contact-primary_email"
				/>
			</FormControl>

			<FormControl fullWidth>
				<Label disableAnimation shrink htmlFor="contact-primary-website">
					{contactInput?.website?.label || 'Primary Website'}{' '}
					{contactInput?.website?.type_id !== undefined
						? '(' + capitalizeFirstLetter(contactInput?.website?.type || websiteTypes.find((e) => e.id == contactInput?.website?.type_id).value) + ')'
						: '(Personal)'}
				</Label>

				<TextBox
					readOnly
					className="contactQV-input force-border"
					id="contact-primary-website"
					value={contactInput?.website?.website}
					onClick={() => {
						setWebsiteModal({
							open: true,
							count: 1,
							website: {
								...websiteModal.website,
								type_id: contactInput.website?.type_id || 1,
							},
						})
					}}
					data-testid="contact-primary_website"
				/>
			</FormControl>

			<FormControl fullWidth>
				<Label disableAnimation shrink htmlFor="contact-primary-address" error={attempt && Object.keys(contactInput.address || {}).length === 0}>
					{contactInput?.address?.label || 'Primary Address'}{' '}
					{contactInput?.address?.type_id !== undefined
						? '(' + capitalizeFirstLetter(contactInput?.address?.type || addressTypes.find((e) => e.id == contactInput?.address?.type_id).value) + ')'
						: '(Home)'}
						*
				</Label>

				<TextBox
					readOnly
					multiline
					className="contactQV-input force-border"
					id="contact-primary-address"
					value={!Array.isArray(contactInput?.address) ? getFormattedAddress(countries, contactInput?.address) : ''}
					error={attempt && Object.keys(contactInput.address || {}).length === 0}
					onClick={() => {
						setAddressModal({
							open: true,
							address: {
								label: '',
								type_id: 1,
								street_1: '',
								street_2: '',
								street_3: '',
								country_id: null,
								country: '',
								region: '',
								town: '',
								postcode: '',
								...addressModal.address,
							},
							count: 1,
						})
					}}
					data-testid="contact-primary_address"
				/>
			</FormControl>

			<TransitionsModal className="salesperson-modal" open={salespersonModal} close={() => setSalespersonModal(false)}>
				<SalespersonSelection
					input={contactInput}
					setInput={setContactInput}
					salespersons={contactInput.salespersons || []}
					primary_salespersons={contactInput.primary_salespersons || []}
					close={() => setSalespersonModal(false)}
				/>
			</TransitionsModal>

			<NewPhoneModal
				newPhoneModal={phoneModal}
				setNewPhoneModal={setPhoneModal}
				phoneTypes={phoneTypes}

				contactInput={contactInput}
				setContactInput={setContactInput}
				addToContactInput={addToContactInput}
			/>

			<NewEmailModal
				newEmailModal={emailModal}
				setNewEmailModal={setEmailModal}
				emailTypes={emailTypes}

				contactInput={contactInput}
				setContactInput={setContactInput}
				addToContactInput={addToContactInput}
			/>

			<NewWebsiteModal
				newWebsiteModal={websiteModal}
				setNewWebsiteModal={setWebsiteModal}
				websiteTypes={websiteTypes}

				contactInput={contactInput}
				setContactInput={setContactInput}
				addToContactInput={addToContactInput}
			/>

			<NewAddressModal
				newAddressModal={addressModal}
				setNewAddressModal={setAddressModal}
				addressTypes={addressTypes}

				contactInput={contactInput}
				setContactInput={setContactInput}
				addToContactInput={addToContactInput}
			/>

			<MultiTypeModal
				sortedTypeDropdownValues={sortedTypeDropdownValues}
				attempt={attempt}
				setAttempt={setAttempt}
				typeModal={typeModal}
				setTypeModal={setTypeModal}
				loading={props.loading}
				error={props.error}
				isPrivate={false}
				entityInput={contactInput}
				setEntityInput={setContactInput}
				type="contact_type"
				typeId="contact_type_id"
				keyName="types"
				title="Type"
			/>
		</div>

		<div
			style={{
				paddingTop: '1em',
				margin: !props.showcaseMode ? 'auto 1em 1em' : null
			}}
		>
			<CancelButton
				variant="contained"
				style={{ float: 'left' }}
				onClick={(e) => {
					props.setCreateContact(null)
					setAttempt(false)
				}}
				data-testid="contact-cancel"
			>
			Cancel
			</CancelButton>

			<SubmitButton variant="contained"
				style={{ float: 'right' }}
				type="submit" data-testid="contact-submit"
				disabled={loading}
			>
			Submit
			</SubmitButton>
		</div>
	</form>

	if (props.showcaseMode) return inner

	return (
		<QuickView
			className="quick-view-main"
		>
			{inner}
		</QuickView>
	)
}

export default withApollo(ContactQuickView)
