import React, { useContext, useEffect, useState } from 'react'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { SaveCardButton, CancelButton } from '../input/Buttons'
import { withApollo } from '@apollo/client/react/hoc'
import { groupBy } from 'lodash'
import { Checkbox, FormControl, FormControlLabel, MenuItem, Select } from '@mui/material'
import { GET_EXPORT, GET_EXPORTABLE_FIELDS } from '../Search/Queries'
import { useLazyQuery, useQuery } from '@apollo/client'
import { DispatchContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import Dropdown from '../input/Dropdown/Dropdown'
import Label from '../input/Label'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'


const orders = {
	'Art': [
		'Artist Last Name', 'Status', 
		'Location', 'Retail Price', 
		'Title',
		'Catalogue Raisonné',
		'Edition Number',
		'Height',
		'Date',
	],

	'Contact': ['Name', 'City', 'Country'],
	'Deal': ['Name', 'Type', 'Date Created']
}

function ExcelExport (props) {

	const listingContact = props?.options?.listingId

	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({type: 'openSnackBar', payload: {severity, text}})
	}

	let pages = Object.entries(
		groupBy(props.selectedRows, row => row.__typename)
	).map(([key, value]) => ({
		type: key,
		columns: [],
		reason: {
			required: value.map(row => row.id)
		}
	}))

	const [columns, setColumns] =
		useState(JSON.parse(localStorage.getItem('ExcelExport.columns')) || {})

	useEffect(() =>
		localStorage.setItem('ExcelExport.columns', JSON.stringify(columns)),
	[columns])

	const [pageOrders, setPageOrders] = useState(
		Object.entries(orders).reduce((acc, [k, v]) => {
			acc[k] = v[0]
			return acc
		}, {}))

	const [pageOptions, setPageOptions] = useState(
		Object.entries(orders).reduce((acc, [k, v]) => {
			acc[k] = {}
			return acc
		}, {}))

	const { loading: exportLoading, data: exportData } =
		useQuery(GET_EXPORTABLE_FIELDS, {
			fetchPolicy: 'cache-first',
			// handle removed fields that may be saved in localstorage
			onCompleted: ({getExportableFields}) => {
				Object.keys(columns).forEach(entry => {
					const [key, value] = entry.split('.')
					if (!getExportableFields[key]?.includes(value)) {
						delete columns[entry]
					}
					setColumns({...columns})
				})
			}
		})

	const [getExport, { loading }] = useLazyQuery(GET_EXPORT, {
		onCompleted: data => {
			if (data?.getExport?.length) {
				const a = document.createElement("a")
				document.body.appendChild(a)
				a.style = "display: none"
				const buf = new ArrayBuffer(data.getExport.length) //convert to arrayBuffer
				const view = new Uint8Array(buf)  //create uint8array as viewer
				for (let i = 0; i < data.getExport.length; i++)
					view[i] = data.getExport.charCodeAt(i) & 0xFF //convert to octet
				const blob = new Blob([buf], { type: "application/octet-stream" })
				const url = window.URL.createObjectURL(blob)
				a.href = url
				a.download = 'export.xlsx'
				a.click()
				window.URL.revokeObjectURL(url)
				openSnackbar(severity.SUCCESS, `Download complete.`)
				props.onClose()
			} else {
				console.error(data?.errors)
				openSnackbar(severity.ERROR,
					'There was an error generating the Export.')
				props.onClose()
			}
		}
	})

	const getExportClicked = () => {
		const selectedColumns = Object.keys(columns)
			.filter(key => columns[key])
			.map(col => col.split('.'))
			.reduce((acc, el) => {
				acc[el[0]] = (acc[el[0]] || []).concat(el[1])
				return acc
			}, {})
		pages.forEach(page => {
			page.columns = selectedColumns[page.type] || []
			page.order = pageOrders[page.type]
			page.options = {
				...pageOptions[page.type],
				...props.options
			}
		})
		openSnackbar(severity.INFO, `Downloading. Please wait.`)
		getExport({
			variables: {args: pages}
		})
	}

	return (
		<TransitionsModal
			className="new-listing-modal"
			open={props.open}
			close={props.onClose}
			scroll={true}
		>
			<div style={{
				display: 'flex',
				justifyContent: 'flex-end',
				marginBottom: '-2em'
			}}>
				<CancelButton
					variant="contained"
					size="medium"
					onClick={props.onClose}
				>
					Cancel
				</CancelButton>
				<SaveCardButton
					disabled={loading}
					style={{ marginLeft: '1em' }}
					variant="contained"
					color="secondary"
					size="medium"
					onClick={getExportClicked}
				>
					Export
				</SaveCardButton>
			</div>
			{ pages.map(page => <div key={page.type}>
				<h1 style={{margin: 0}}>{page.type}</h1>

				{orders[page.type]?.length ? 
					<div style={{ display: 'flex', alignItems: 'end' }}>
						<FormControl className="padded-select">
							<Label id="order-by" disableAnimation shrink>Order by</Label>
							<Select
								name="order-by"
								labelId="order-by"
								IconComponent={ExpandMoreRoundedIcon}
								input={<Dropdown />}
								value={pageOrders[page.type]}
								onChange={e => setPageOrders({
									...pageOrders,
									[page.type]: e.target.value
								})}
							>
								{orders[page.type]?.map(t =>
									<MenuItem key={t} value={t}>{ t }</MenuItem>
								)}
							</Select>
						</FormControl>
						{page.type === 'Art' && 
							<FormControlLabel
								sx={{ paddingLeft: '1em' }}
								label="Anonymize"
								control={
									<Checkbox
										checked={pageOptions[page.type].anonymize || false}
										name="anonymize"
										onChange={(e)=>{
											setPageOptions({
												...pageOptions,
												[page.type]: {
													...pageOptions[page.type],
													anonymize: e.target.checked
												}
											})
										}}
									/>
								}
							/>}
					</div>
					: <br/>}
				
				{exportLoading ? <span>Loading...</span> :
					<div style={{
						display: 'flex',
						flexFlow: 'column wrap',
						height: '17em',
						width: '75vw',
						overflowX: 'auto'
					}}>
						{[...(exportData?.getExportableFields?.[page.type]
							?.filter(f => listingContact || f !== 'Listing Status') || [])]
							.sort()
							.map(column => <FormControlLabel
								key={column}
								control={<Checkbox
									checked={columns[page.type+'.'+column] || false}
									name={page+column}
									value={column}
									onClick={() => {
										const checked = columns[page.type+'.'+column]
										setColumns({
											...columns,
											[page.type+'.'+column]: !checked
										})
									}}
								/>}
								label={column}
							/>)}
					</div>
				}
			</div>) }
		</TransitionsModal>
	)
}

export default withApollo(ExcelExport)
