/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useEffect } from 'react'
import { FormControl, Select, MenuItem, IconButton, FormControlLabel, TextField } from '@mui/material'
import Label from '../input/Label'
import Dropdown from '../input/Dropdown/Dropdown'
import makeStyles from '@mui/styles/makeStyles'
import TextBox from '../input/Text/TextBox'
import { MobileDatePicker, MobileDateRangePicker } from '@mui/x-date-pickers-pro'
import { SaveButton, SearchButton, ResetButton, ChooseImageButton, AdvancedToggleButton } from '../input/Buttons'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import { AuthStateContext, DispatchContext, LookupContext } from '../store'
import sortBy from 'lodash/sortBy'
import { Autocomplete } from '@mui/material'
import { autoCompleteStyles } from '../styles/makesStyles'
import { GET_USERS } from '../User/Queries'
import { withApollo } from '@apollo/client/react/hoc'
import SearchInfo from "../Search/SearchInfo"
import { ControlPoint } from '@mui/icons-material'
import AdvancedFilterCount from '../Search/AdvancedFilterCount'
import { FindModeButton } from '../Search/GlobalSearchFilters'
import StartTextBox from '../input/Text/StartTextBox'
import EndTextBox from '../input/Text/EndTextBox'
import { LISTING_SEARCH_OBJECT } from '../constants/values'
import { useMutation } from '@apollo/client'
import { CREATE_SEARCH } from '../Search/Queries'
import ConfirmationInput from '../navigation/ConfirmationDialog/ConfirmationInput'
import { getGalleryContactLabel, randomHSL } from '../common/helpers'
import { saveMap, searchMap, handleSearchChange, handleLastModifiedChange, saveSearch } from '../Search/unifiedSearchHelpers'
import { SavedSearch } from '../common/SavedSearch'
import { severity } from '../Snackbar/CustomizedSnackbar'

const useStyles = makeStyles(() => ({
	searchSelection: {
		width: '100%',
		marginRight: '1em'
	},
	searchBar: {
		paddingBottom: "1em"
	},
	searchDatePadding: {
		paddingRight: "1em",
	},
}))

const SearchListings = (props) => {

	const classes = useStyles()
	const lookup = useContext(LookupContext)
	const listingTypes= lookup.data?.getListingTypes
	const sortedListingTypes = sortBy(listingTypes, [function(o) { return o.value }])

	const typeStyle = {
		fontWeight: 500,
	}

	const [firstLoad, setFirstLoad] = React.useState(true)
	React.useEffect(() => setFirstLoad(false), [])

	const userAuthentication = useContext(AuthStateContext)
	const [currentSearch, setCurrentSearch] = React.useState(props.prevSearch ?? new SavedSearch({
		object_id: LISTING_SEARCH_OBJECT,
		search_terms: [],
		is_global: true,
		user_id: userAuthentication.user?.id
	}))
	const [savedSearchDialog, setSavedSearchDialog] = React.useState({
		open: false,
		search: null
	})

	// Mutations
	const [createSearch, { loading }] = useMutation(CREATE_SEARCH)

	useEffect(() => {
		if (props.currentSearch) setCurrentSearch(props.currentSearch)
	}, [props.currentSearch])

	const getSearchValue = useCallback((fieldName) =>
		currentSearch.search_terms
			?.find(el => el.field == fieldName)?.value,
	[currentSearch.search_terms])

	const [options, setOptions] = React.useState([])

	const dispatch = useContext(DispatchContext)
	const openQuickView = useCallback((severity, text) => {
		dispatch({ type: 'openQuickView' })
	}, [dispatch])

	// Snackbar
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const handleListingSavedSearchChange = (event, type = 'eq') => {

		const search_terms = handleSearchChange(
			event,
			currentSearch,
			setCurrentSearch,
			type,
			true
		)

		if (props.currentSearch.id) { 
			props.setCurrentSearch({ 
				...currentSearch,
				search_terms,
				id: null 
			})
		}
	}

	const handleListingLastModifiedChange = (value) => {
		
		const search_terms = handleLastModifiedChange(
			value,
			currentSearch,
			setCurrentSearch
		)
		
		if (props.currentSearch.id) { 
			props.setCurrentSearch({ 
				...currentSearch,
				search_terms,
				id: null 
			})
		}
	}

	React.useEffect(() => {
		let active = true

		if (active && !options.length) {
			props.client
				.query({
					query: GET_USERS,
					variables: {
						includeDeleted: true
					}
				})
				.then(result => {
					if (active)
						setOptions(result.data.getUsers.items)
				})
		}

		return () => {
			active = false
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// This populate the gallery contacts autocomplete from saved searches 
	React.useEffect(() => {

		const names = getSearchValue('gallery_contacts')?.split(' AND ')

		if (options.length && getSearchValue('gallery_contacts')) {
			props.setSelection(options.filter(user => 
				 names.includes([user.first_name, user.last_name].join(" "))
			))
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getSearchValue('gallery_contacts')])

	return <>
		<h1 className="card-title">
            
            Search Listings
        
			<ChooseImageButton
				data-testid="new-deal-button"
				variant="contained"
				size="small"
				style={{backgroundColor: !props.loading ? '#4465D1' : null}}
				onClick={() => {
					openQuickView()
					props.setCreateListing(true)
				}}
				endIcon={<ControlPoint />}
			>
                Create
			</ChooseImageButton>

			<AdvancedFilterCount 
				advancedFilters={props.advancedFilters}
				setAdvancedSearch={props.setAdvancedSearch}
			/>

			<SearchInfo 
				advancedFilters={!!props.advancedFilters?.length}
				fields={["Title", "Subtitle", "About Description", "ID"]}
				recordName="listing"
			/> 
        
		</h1>
		<form onSubmit={(e) => {
			e.preventDefault() 
			props.setActiveStep(0)
			props.handleSubmit(
				{ cursor: 0 }, 
				currentSearch.search_terms, 
				currentSearch.id
			)
		}}>
			<div className="row">
				<FormControl className={classes.searchSelection}>
					<Label id="type-label" style={typeStyle}
						disableAnimation shrink>
                            Type
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						name="listing_type"
							
						data-testid="search-type"
						labelId="type-label"
						className="padded-select"
						input={<Dropdown />}
						value={getSearchValue('listing_type') || ''}
						onChange={(event) => handleListingSavedSearchChange(event)}
					>
						<MenuItem value=''>All</MenuItem>
						{sortedListingTypes && sortedListingTypes.map((listingType) => (
							<MenuItem
								key={listingType.id}
								value={listingType.value}
								data-testid="search-type-item"
							>
								{listingType.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl className={classes.searchDatePadding} style={{width: '100%'}}>
					<Label id="start-date-label" style={typeStyle} disableAnimation shrink>
                        Start Date
					</Label>
                    
					<MobileDatePicker
						inputFormat="MMM do, yyyy"
						disableMaskedInput={true}
						componentsProps={{
							actionBar: {
								actions: ['today', 'clear', 'accept']
							}
						}}
						name="start_at"
						value={getSearchValue('start_at') || null}
						inputVariant="outlined"
						className="MUIDatePicker"
						variant="dialog" 
						renderInput={({ inputRef, inputProps, InputProps }) => {
								
							const newProps = { ...inputProps}

							newProps.readOnly = false

							return (
								<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
							)}}
						onChange={(date) => handleListingSavedSearchChange({
							target: {
								name: 'start_at',
								value: date ? date : ''
							}
						})}
						data-testid="search-startdate"
					/> 
				</FormControl>

				<FormControl className={classes.searchDatePadding} style={{width: '100%'}}>
					<Label id="end-date-label" style={typeStyle} disableAnimation shrink>
                        End Date
					</Label>
                    
					<MobileDatePicker
						inputFormat="MMM do, yyyy"
						disableMaskedInput={true}
						componentsProps={{
							actionBar: {
								actions: ['today', 'clear', 'accept']
							}
						}}
						name="end_at"
						value={getSearchValue('end_at') || null}
						inputVariant="outlined"
						className="MUIDatePicker"
						variant="dialog" 
						renderInput={({ inputRef, inputProps, InputProps }) => {
								
							const newProps = { ...inputProps}
							newProps.readOnly = false

							return (
								<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
							)}}
						onChange={(date) => handleListingSavedSearchChange({
							target: {
								name: 'end_at',
								value: date ? date : null
							}
						})}
						data-testid="search-enddate"
					/> 
				</FormControl>

				<MobileDateRangePicker
					inputFormat="MMM do, yyyy"
					disableMaskedInput={true}
					componentsProps={{
						actionBar: {
							actions: ['clear', 'accept']
						}
					}}
					value={[
						currentSearch.search_terms
							.find(el => el.field == 'modified_at' && el.type == 'gte')?.value || null,
						currentSearch.search_terms
							.find(el => el.field == 'modified_at' && el.type == 'lte')?.value || null
					]}
					onChange={handleListingLastModifiedChange}
					renderInput={(startProps, endProps) => {
							
						const newStartProps = { ...startProps}
						const newEndProps = { ...endProps}
	
						newStartProps.inputProps.placeholder = "Start"
						newEndProps.inputProps.placeholder = "End"
							
						return (
							<div style={{
								width: '-webkit-fill-available'
							}}>
								<Label
									id="date-label"
									style={{
										...typeStyle,
										fontSize: 18,
										paddingTop: '0.45em',
										marginLeft: '0'
									}}
									disableAnimation
									shrink
								>
									Last Modified
								</Label>
								<div style={{
									marginTop: '-0.6em',
									display: 'flex',
									width: '-webkit-fill-available'
								}}>
								
									<StartTextBox sx={{width: '100%'}} {...startProps}/>	
									<EndTextBox sx={{width: '100%'}} {...endProps} />
								</div>
							</div>
						)}}
				/>
			</div>

			<div className="flex-row">
				<div className="flex-row search-booleans">
					<FormControlLabel
						value="top"
						control={
							<>
								{getSearchValue('isFavorite') == null ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleListingSavedSearchChange({
												target: {
													name: "isFavorite",
													value: false
												}
											})
										}}
										title="All"
										size="large">
										<StarBorderIcon style={{ color: 'grey' }} />
									</IconButton>
									: null}

								{getSearchValue('isFavorite') == 'false' ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleListingSavedSearchChange({
												target: {
													name: "isFavorite",
													value: true
												}
											})
										}}
										title="Non-Favorite"
										size="large">
										<StarBorderIcon style={{color: 'hsl(50, 93%, 59%)'}} />
									</IconButton>
									: null}

								{getSearchValue('isFavorite') == 'true' ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleListingSavedSearchChange({
												target: {
													name: "isFavorite",
													value: null
												}
											})
										}}
										title="Favorite"
										size="large">
										<StarIcon style={{color: 'hsl(50, 93%, 59%)'}} />
									</IconButton>
									: null}
							</>
						}
						label="Favorite"
						labelPlacement="top"
					/>

					<FormControlLabel
						value="top"
						control={
							<>
								{getSearchValue('showcase_ready') == null ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleListingSavedSearchChange({
												target: {
													name: "showcase_ready",
													value: false
												}
											})
										}}
										title="All Art"
										size="large">
										<img
											alt="art"
											style={{
												width: '24px',
												height: '24px',
											}}
											src="/images/icons/Grey/ShowcaseOutline.svg"
										/>
									</IconButton>
									: null}

								{getSearchValue('showcase_ready') == 'false' ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleListingSavedSearchChange({
												target: {
													name: "showcase_ready",
													value: true
												}
											})
										}}
										title="Non-Showcased Art"
										size="large">
										<img
											alt="un-showcased-art"
											style={{
												width: '24px',
												height: '24px',
											}}
											src="/images/icons/Grey/Showcase.svg"
										/>
									</IconButton>
									: null}

								{getSearchValue('showcase_ready') == 'true' ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleListingSavedSearchChange({
												target: {
													name: "showcase_ready",
													value: null
												}
											})
										}}
										title="Showcased Art"
										size="large">
										<img
											alt="showcased-art"
											style={{
												width: '24px',
												height: '24px',
											}}
											src="/images/icons/Green/Showcase.svg"
										/>
									</IconButton>
									: null}
							</>
						}
						label="Showcase"
						labelPlacement="top"
					/>
				</div>

				<FormControl className={classes.searchBar} style={{ width: "100%", marginRight: '1em' }}>
					<Label style={typeStyle} disableAnimation shrink>
                        Search
					</Label>
					<TextBox 
						name="query" 
						inputRef={input => input && firstLoad && input.focus()}
						value={getSearchValue('query') || ''}
						onChange={(event) => handleListingSavedSearchChange(event)} 
						data-testid="searchbar"
					/>
				</FormControl>

				<FormControl className={classes.selection} style={{width: "100%"}}>
					<Label
						id="user-label"
						style={typeStyle}
						disableAnimation
						shrink
					>
                        Gallery Contact
					</Label>

					<Autocomplete
						multiple
						classes={autoCompleteStyles()}
						style={{marginTop: "1.5em"}}
						isOptionEqualToValue={(option, value) => {
							return option.id == value.id
						}}
						forcePopupIcon
						filterSelectedOptions
						popupIcon={<ExpandMoreRoundedIcon />}
						size="small"
						value={props.selection ?? []}
						getOptionLabel={(option) =>getGalleryContactLabel(option)}

						options={options || []}
						onChange={(event, value) => {
							props.setSelection(value)
							const names = value.map(x=> [x.first_name, x.last_name].join(' ')).join(" AND ")
							handleListingSavedSearchChange({
								target: {
									name: "gallery_contacts",
									value: names
								}
							})
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								fullWidth
								InputProps={{
									...params.InputProps,
									style: {'padding': '9px'}
								}}
							/>
						)}
						data-testid="search-gallery_contact"
					/>
				</FormControl>
			</div>
            
			<div className="row">
				<div>
					<SaveButton
						data-testid="save-button"
						variant="contained"
						size="small"
						onClick={() => {
							saveSearch(
								currentSearch,
								openSnackbar,
								severity,
								setSavedSearchDialog
							)
						}}					
					>
                        Save Search
					</SaveButton>
				</div>
				<FindModeButton onFindMode={props.onFindMode} />
				<AdvancedToggleButton
					data-testid="advanced-toggle-button"
					size="small"
					onClick={() => {
						props.setCurrentSearch(currentSearch)
						props.setAdvancedSearch(true)
					}}
					style={{ marginRight: '1em' }}
				>
                    Advanced
				</AdvancedToggleButton>
				<ResetButton
					data-testid="reset-button"
					variant="contained"
					size="small"
					onClick={() => {
						props.setTotalItems(null)
						props.setSelection([])
						props.resetSearch('simple')
						setCurrentSearch(new SavedSearch({
							object_id: LISTING_SEARCH_OBJECT,
							search_terms: [],
							is_global: true,
							user_id: userAuthentication.user?.id
						}))
					}}
					style={{ marginRight: '1em' }}
				>
                    Reset
				</ResetButton>
				<SearchButton
					data-testid="search-button"
					variant="contained"
					size="small"
					type="submit"
					style={{backgroundColor: !props.loading ? '#4465D1' : null}}
					disabled={props.loading}
				>
                    Search
				</SearchButton>
			</div>
		</form>

		<ConfirmationInput
			open={savedSearchDialog.open}
			handleClose={() => setSavedSearchDialog({
				open: false,
				savedSearch: null
			})}
			disabled={loading}
			title={'Save search?'}
			acceptText={'Save'}
			buttonColor="#33BF5C"
			inputLabel="Search Label"
			placeholder={'Saved Search ' + (props.savedSearches?.length + 1)}
			onYes={(text) => {
				if (!loading) {
					createSearch({
						variables: {
							CreateSearchInput: {
								label: text ? text : 'Saved Search ' + (props.savedSearches?.length + 1),
								user_id: userAuthentication.user.id,
								object_id: LISTING_SEARCH_OBJECT,
								color: randomHSL(),
								search_terms: searchMap(currentSearch.search_terms),
								result_count: null,
								is_global: true,
							},
						},
					}).then((response) => {

						setCurrentSearch({
							...response.data.createSavedSearch.search,
							search_terms: saveMap(response.data.createSavedSearch.search.search_terms)
						})

						props.setSavedSearches(props.savedSearches.concat(
							new SavedSearch(response.data.createSavedSearch.search))
						)
					})
				}
			}}
		/>
	</>
}

export default withApollo(SearchListings)
