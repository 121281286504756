import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { Collapse, IconButton, Paper } from "@mui/material"
import { useState } from "react"


export default function CollapsibleSearchCard(props: any) {

	const [searchOpen, setSearchOpen] = useState(true)

	const collapsedClass = searchOpen ? '' : 'collapsed'

	return <>
		<Paper 
			className={`padding-margin-scrollbar search-card ${collapsedClass}`}
			sx={{ position: 'relative' }}
		>
			<Collapse in={searchOpen} >
				{ props.children }
			</Collapse>
			<IconButton
				style={{
					position: 'absolute',
					right: '1em',
					bottom: '0',
					transform: 'translateY(100%)',
					backgroundColor: 'hsl(0deg 0% 50% / 32%)',
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
					padding: 6,
					zIndex: 3,
					transition: '225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
				}}
				onClick={() => setSearchOpen(so => !so)}
				size="large"
			>
				{ searchOpen ? <ExpandLess /> : <ExpandMore /> }
			</IconButton>
		</Paper>
	</>
}
