const AuthButton = (props) => {
	return(
		<a 
			href={props.url} 
			style={{ 
				color: "inherit",
				textDecoration: 'none'
			}}
		>
			<div
				style = {{
					height: "52px",
					width: props.width || "358px",
					display: "flex",
					backgroundColor: "#fff",
					alignItems: "center",
					marginBottom: "1em",
					borderRadius: "0.5em"
				}}
				onClick={()=>{
					window.localStorage.setItem('idp', props.identityProvider)
				}}
			>
				<img 
					src={props.logo}
					alt="microsoft logo"
					style={{
						height: "60%",
						marginLeft: "12px",
						flexGrow: "0",
					}}
				/>
				<span 
					style={{
						fontSize: "18px",
						marginTop: "-2px",
						flexGrow: "1",
						textAlign: "center"
					}}
				> 
					{props.name}
				</span>
			</div>
		</a>
	)
}

export default AuthButton
