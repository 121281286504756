/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import {
	Button, IconButton, Menu, MenuItem, Paper, TableSortLabel, Tooltip
} from '@mui/material'
import clsx from 'clsx'
import {
	DataGridPro, GridToolbarColumnsButton,
	GridToolbarContainer, GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro'
import {
	elementReady,
	getContactName,
	getListHeader, restoreColumnSettings, toTitleCase, formatDate, arraymove
} from '../common/helpers'
import { AuthStateContext } from '../store'
import ContactThumbnail from '../Thumbnail/ContactThumbnail'
import FavoriteAndFlag from '../common/FavoriteAndFlag'
import { filteredPages } from '../navigation/routes'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DataGridFlagMenu from '../common/components/DataGridFlagMenu'
import { Undo } from '@mui/icons-material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import PhoneInput from 'react-phone-input-2'
import GalleryContactList from '../common/components/GalleryContactList'

function hidden(params) {
	return params.row.is_private && !params.row.created_at
}

function getCols({
	favoriteAndFlag, actionMenu, flagMenu, contactMenu
}) {
	/** @type import('@mui/x-data-grid-pro').GridColDef[] */
	const columns = [
		{
			field: 'Actions',
			headerName: '',
			minWidth: 50,
			width: 50,
			resizable: false,
			sortable: false,
			renderCell: actionMenu
	  	},
		{
			field: 'isFlagged',
			headerName: '',
			renderHeader: flagMenu,
			width: 50,
			renderCell: favoriteAndFlag,
			sortable: false,
			resizable: false,
			headerClassName: 'flag-header-data-grid'
		},
		{
			field: 'contact',
			headerName: 'Contact',
			renderHeader: contactMenu,
			width: 300,
			sortable: false,
			renderCell: params => <>
				{ params?.api?.state?.density?.value === 'compact' ?
					<span>{getContactName(params.row)}</span> :
					<ContactThumbnail
						contact={params.row}
						darkText={!params.row.is_private}
						style={{ lineHeight: 'initial' }}
					/>
				}
			</>
		},
		{
			field: 'primary_phone',
			headerName: 'Primary Phone',
			width: 200,
			renderCell: params => {
				const hidden = params.row.is_private && !params.row.first_name
				const primaryPhone = params.row.phone
					?.find(phone => phone.is_primary)
				return !hidden && primaryPhone?.number ?
					<div className="plain-text">
						<PhoneInput
							style={{
								width: '10em',
								display: 'inline-block',
							}}
							inputStyle={{
								color: params.row.is_private ?
									'white' :'rgba(0, 0, 0, 0.87)',
								fontFamily: 'graphik',
								cursor: 'pointer',
							}}
							placeholder=""
							value={primaryPhone?.number}
							disabled
							onChange={() => {}}
						/>
						<div
							style={{
								width: 'min-content',
								display: 'inline-block',
								color: params.row.isPrivate ? 'white' : 'inherit'
							}}
						>
							{primaryPhone?.extension
								? 'x' + primaryPhone?.extension
								: null}
						</div>
					</div> : <span>-</span>
			}
		},
		{
			field: 'primary_email',
			headerName: 'Primary Email',
			width: 200,
			renderCell: params => {
				const hidden = params.row.is_private && !params.row.first_name
				const primaryEmail = params.row.email
					?.find(email => email.is_primary)
				return !hidden && primaryEmail?.email ?
					<span>{primaryEmail?.email || '-'}</span> : <span>-</span>
			}
		},
		{
			field: "title",
			headerName: "Job Title",
			width: 160,
			renderCell: params => {
				if(hidden(params)) return <span>-</span>
				return <span>{params.row?.title || '-'}</span>
			}
		},
		{
			field: "company_name",
			headerName: "Company Name",
			width: 160,
			renderCell: params => {
				if(hidden(params)) return <span>-</span>
				return <span>{params.row?.company_name || "-"}</span>
			}
		},
		{
			field: "primary_town",
			headerName: "Primary City",
			width: 130,
			renderCell: params => {
				if(hidden(params)) return <span>-</span>
				let primaryAddress = params.row?.address
					?.find(address => address.is_primary)
				return <span>{primaryAddress?.town || "-"}</span>
			}
		},
		{
			field: 'primary_region',
			headerName: 'Primary State/Region',
			width: 160,
			sortable: false,
			renderCell: params => {
				if(hidden(params)) return <span>-</span>

				let primaryAddress = params.row?.address
					?.find(address => address.is_primary)
				return <span>{primaryAddress?.region || '-'}</span>
			}
		},
		{
			field: 'primary_country',
			headerName: 'Primary Country',
			width: 160,
			renderCell: params => {
				if(hidden(params)) return <span>-</span>

				let primaryAddress = params.row?.address
					?.find(address => address.is_primary)
				return <span>{primaryAddress?.country || '-'}</span>
			}
		},
		{
			field: 'contact_status',
			headerName: 'Status',
			renderCell: params => {
				const hidden = params.row.is_private && !params.first_name
				if (!hidden && params.row?.status?.value) {
					return toTitleCase(params.row.status.value)
				}
				return <span>-</span>
			}
		},
		{
			field: "gallery_contacts",
			headerName: "Gallery Contacts",
			width: 200,
			sortable: false,
			renderCell: params => {
				if(hidden(params)) return <span>-</span>
				return <>
					<GalleryContactList
						salespersons={params.row?.salesperson}
						findMode={false}
						max={2}
					/>
				</>
			}
		},
		{
			field: "contact_types",
			headerName: "Type",
			width: 160,
			sortable: false,
			renderCell: params => {
				if(hidden(params)) return <span>-</span>
				const types = params.row?.type?.map(el => el.contact_type)?.filter(el=>el)?.join(", ")
				return(
					<Tooltip title={types || '-'} arrow>
						<span>{types || '-'}</span>
					</Tooltip>
				)
			}
		},
		{
			field: "contactGrade",
			headerName: "Grade",
			sortable: false,
			width: 80,
			renderCell: params => {
				if(hidden(params)) return <span>-</span>
				return <span>{params.row?.grade?.value || "-"}</span>
			}
		},
		{
			field: "contactActivity",
			headerName: "Activity",
			sortable: false,
			width: 80,
			renderCell: params => {
				if(hidden(params)) return <span>-</span>
				return <span>{params.row?.activity?.value || "-"}</span>
			}
		},
		{
			field: "modified_at",
			headerName: "Modified At",
			sortable: true,
			width: 130,
			renderCell: params => {
				if(hidden(params)) return <span>-</span>
				const fDate = params.row?.modified_at ? formatDate( params.row?.modified_at, "MMM' 'dd', 'yyyy" ) : "-"
				return <span>{fDate}</span>
			}
		},
		{
			field: "created_at",
			headerName: "Created At",
			sortable: true,
			width: 130,
			renderCell: params => {
				if(hidden(params)) return <span>-</span>
				const fDate = params.row?.created_at ? formatDate( params.row?.created_at, "MMM' 'dd', 'yyyy" ) : "-"
				return <span>{fDate}</span>
			}
		},
	]
	return columns
}

/**
 * @typedef ContactDataGridProps
 * @property {Object[]} rows All rows of {Contact} to be displayed
 * @property {function} setRows
 * @property {boolean} loading If a loading is in progress
 * @property {function} saveAndGo Function called on row doubleClick
 * @property {string} mainSearchPage
 * @property {filterComparator} userId
 * @property {Object} selectedRow
 * @property {callback} onSelect
 * @property {filterComparator} totalItems
 * @property {number} limit
 * @property {Object} variables
 * @property {function} setLimit
 * @property {number} activeStep
 * @property {function} setActiveStep
 * @property {Object} sortModel
 * @property {function} onSortModelChange
 * @property {boolean} noScrollToTop do not scroll to top of page on pagination
 */

/**
 * @param {ContactDataGridProps} props
 */
export function ContactDataGrid (props) {

	const columnSettingsKey = 'ContactDataGrid'
	const columnVisibilityKey = `${columnSettingsKey}-visible`
	
	const authState = useContext(AuthStateContext)

	// await these three, make the function async, and wrap it in a suspense?
	const userPermissions  = authState?.user?.permissions

	// handle null response
	const rows = props.rows || []

	const [columnVisibilityModel, setColumnVisibilityModel] = useState({})

	const [density, setDensity] = useState(
		localStorage.getItem(`${columnSettingsKey}.density`) || 'comfortable')
	useEffect(() => localStorage.setItem(`${columnSettingsKey}.density`, density),
		[density])

	const favoriteAndFlag = params => {

		if (params.row.is_private && !params.row.created_at)
			return <></>
			
		return (
			<FavoriteAndFlag
				entityName="contact"
				row={params.row}
				mainSearchPage={props.mainSearchPage}
				userId={props?.userId}
				setRows={props.setRows}
				setEntity={props.setContacts}
				listName={props.listName}
				setFlaggedCount={props.setFlaggedCount}
				compact={density === 'compact'}
				hidden={params.row.is_private && !params.row.created_at}
			/>
		)
	}

	const flagMenu = params => {

		if (props.disableFlagMenu) return null

		return (
			<DataGridFlagMenu
				currentFilters
				filterTo={props.filterTo}
				variables={props.variables}
				setFlaggedCount={props.setFlaggedCount}
				setRows={props.setRows}
				rows={props.rows}
				totalItems={props.totalItems}
				type={props.type}
			/>
		)
	}

	const contactMenu = (args) =>
		<Button
			size="small"
			endIcon={<KeyboardArrowDownIcon />}
			onClick={openContactSortMenu}
			sx={{
				fontWeight: 500,
				color: '#808080',
				fontSize: 14
			}}
		>
			{ args.colDef.headerName }
		</Button>

	const openContextMenu = (field) => (e) => {
		e.preventDefault()
		e.stopPropagation()
		setAnchorEl(e[field])
	}
	const openContactSortMenu = (e) => {
		e.preventDefault()
		e.stopPropagation()
		setCtcAnchorEl(e.target)
	}

	const actionMenu = params => {

		if (params.row.is_private && !params.row.created_at)
			return <></>

		return (
			<IconButton
				aria-label="More"
				style={{
					padding: '6px',
					marginRight: '-9px',
				}}
				onClick={e => {
					if (params.row.disabled || params.row.hidden) return
					setMenuRowId(params.id)
					openContextMenu('currentTarget')(e)
				}}
			>
				<MoreVertIcon
					style={ params.row.is_private ? { color: 'white' } : null }
				/>
			</IconButton>
		)
	}

	const [menuRowId, setMenuRowId] = useState(null)
	const [anchorEl, setAnchorEl] = useState(null)
	const [ctcAnchorEl, setCtcAnchorEl] = useState(null)
	const [columns, setColumns] = useState([])

	// Set up grid with loaded dependencies, and saved data.
	const updateColumns = () => {

		const cols = getCols({
			favoriteAndFlag, actionMenu,
			flagMenu, contactMenu
		})

		// restore on load.
		const columnSettings = JSON.parse(localStorage.getItem(columnSettingsKey))
		if (columnSettings?.length) restoreColumnSettings(cols, columnSettings)
		const columnVisiblitySettings = JSON.parse(localStorage.getItem(columnVisibilityKey))
		if (columnVisiblitySettings) setColumnVisibilityModel(columnVisiblitySettings)
		setColumns([...cols])
	}

	// on Load, load column settings, sort, merge in (once everything is loaded.)
	useEffect(() => {
		if (props.userId) updateColumns()
	}, [props.userId, density])

	// update the flag menu/toggle any time the props change
	// to prevent "stale closures"
	useEffect(() => {
		setColumns(cols => {
			const flagCol = cols.find(c => c.field === 'isFlagged')
			if (flagCol) {
				flagCol.renderHeader = flagMenu
				flagCol.renderCell = favoriteAndFlag
				flagCol.width = (density === 'compact') ? 80 : 50
			}
			return [...cols]
		})
	}, [props.variables, props.totalItems, props.userId, density])

	// on unmount, stash columns back in storage. (eventually in DB)
	useEffect(() => {

		// stash on nav. (`columns` needs to be in the deps in order to be accessed.)
		return () => {
			if (columns.length) {
				const columnSettings = columns.map(col => ({
					field: col.field,
					width: col.width,
					minWidth: col.minWidth,
				}))
				localStorage.setItem(columnSettingsKey, JSON.stringify(columnSettings))
				localStorage.setItem(columnVisibilityKey, JSON.stringify(columnVisibilityModel))
			}
		}
	}, [columns])

	const handleClose = () => setAnchorEl(null)
	const handleCtcAnchorClose = () => setCtcAnchorEl(null)

	const resetGrid = () => {
		localStorage.removeItem(columnSettingsKey)
		localStorage.removeItem(columnVisibilityKey)
		setColumnVisibilityModel({})
		updateColumns()
	}

	const CustomToolBar =  () => <GridToolbarContainer>
		<Button
			size="small"
			startIcon={<Undo />}
			onClick={resetGrid}
		>
			Reset
		</Button>
		<GridToolbarColumnsButton />
		<GridToolbarDensitySelector />
	</GridToolbarContainer>

	const contactSorts = [
		{
			id: 'first_name',
			label: 'First Name'
		},{
			id: 'last_name',
			label: "Last Name"
		},{
			id: 'company_name',
			label: "Company Name"
		}]

	const filterSorts = sorts => {
		const fields = contactSorts.map(d => d.id)
		sorts.filter(s => !fields.includes(s.field))
	}

	// Scroll to the top of current data grid component when paginating
	useEffect(() => {

		if (props.loading === false && !props.scrollIntoView) {
			elementReady(`.${columnSettingsKey}`)
				.then(searchList =>
					searchList.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					})
				)
		}

	}, [props.activeStep])

	return <>
		<Paper
			className={clsx({
				'data-grid-padding': true,
				[columnSettingsKey]: true
			})}
			data-testid={`card-${props.listHeader ? props.listHeader : "Results "}`}
			style={{
				...props.style,
				flexGrow: 1
			}}
		>
			<h1 className="card-title" style={{
				paddingTop: '1em',
				paddingLeft: '2em'
			}}>
				{getListHeader(props.listHeader, props.totalItems, props.canSee)}
			</h1>

			<div style={{ height: 'calc(100% - 4em)', width: '100%' }}>	
				<DataGridPro
					components={{ Toolbar: CustomToolBar }}
					rowCount={props.totalItems || 0}
					rows={rows}
					columns={columns}
					onRowDoubleClick={({row}) => {
						if (row.is_private && !row.created_at) return
						props.saveAndGo(`/contacts/${row.id}`, row.id)
					}}
					autoHeight={props.alwaysAutoHeight ?? false}
					density={density}
					disableColumnMenu
					disableMultipleSelection
					pagination
					paginationMode="server"
					sortingMode="server"
					pageSize={props.limit}
					onPageSizeChange={props.setLimit}
					rowsPerPageOptions={[10, 25, 50]}
					onRowClick={(params, event) => {
						let row = params.row
						if (event.metaKey) {
							if (row.is_private && !row.created_at) return
							window.open(`/contacts/${row.id}`, "_blank")
						} else {
							props.onSelect(row)
						}
					}}
					selectionModel={props.selectedRow?.id ? [props.selectedRow?.id] : []}
					page={props.activeStep}
					onPageChange={props.setActiveStep}
					sortModel={filterSorts(props.sortModel)}
					onSortModelChange={props.onSortModelChange}
					loading={props.loading}
					onColumnOrderChange={({ oldIndex, targetIndex }) => {
						arraymove(columns, oldIndex, targetIndex)
						setColumns([...columns])
					}}
					onColumnWidthChange={({ colDef, width}) => {
						columns.find(col => col.field === colDef.field).width = width
						setColumns([...columns])
					}}
					onStateChange={({density}) => setDensity(density?.value ?? 'comfortable')}
					getRowClassName={({row}) => row.is_private ? 'Private-DataGrid-Row' : null}
					onColumnVisibilityModelChange={setColumnVisibilityModel}
					columnVisibilityModel={columnVisibilityModel}
					columnBuffer={columns.length}
				/>
			</div>

			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={!!anchorEl}
				onClose={handleClose}
			>
				{
					filteredPages('contactPages', userPermissions)
						.map(page =>
							<MenuItem
								key={ page.name }
								onClick={() => {
									props.saveAndGo(`/contacts/${menuRowId}/${page.route}`, menuRowId)
									handleClose()
								}}
							>
								{ page.name }
							</MenuItem>
						)
				}
			</Menu>
		</Paper>
		<Menu
			id="simple-menu"
			anchorEl={ctcAnchorEl}
			keepMounted
			open={!!ctcAnchorEl}
			onClose={handleCtcAnchorClose}
			anchorOrigin={{
				vertical: 'bottom',
    			horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
    			horizontal: 'right',
			}}
		> { contactSorts.map(x =>
				<MenuItem key={`inner-menu-${x.id}`} value={x.id}
					onClick={() => {
						if (!x.noSort) {
							const field = x.id
							const oldSort = props.sortModel.find(sm => sm.field === field)?.sort ?? 'desc'
							const sort = oldSort === 'asc' ? 'desc' : 'asc'
							props.onSortModelChange([{ field, sort }])
						} else {
							props.onSortModelChange([])
						}
						handleCtcAnchorClose()
					}}
				>
					<TableSortLabel
						active={!!props.sortModel.find(sm => sm.field === x.id)}
						direction={props.sortModel.find(sm => sm.field === x.id)?.sort ?? "asc"}
						hideSortIcon={x.noSort}
						style={x.noSort ? {cursor: "default", fontWeight: 400} : {fontWeight: 400}}
					>
						{ x.label }
					</TableSortLabel>
				</MenuItem>
			)}
		</Menu>
	</>

}
