import routes from '../navigation/routes'
import TabbedPage from '../navigation/Tabs/TabbedPage'

export default function Listing(props) {

	return TabbedPage({
		...props,
		routes: routes.supportPages.pages,
		Quickview: null,
		query: null,
		options: null,
	})
}
