import * as Colors from '../colors/Colors'

/* ==========================================================================
	These fonts are meant to be used anywhere in the Levy Gorvy CRM
	========================================================================== */
	
export const navTitle = {
	fontSize: "28px",
	fontWeight: "normal",
	letterSpacing: "0",
	color: Colors.white,
}

export const cardTitle = {
	fontSize: "22px",
	fontWeight: 600,
	letterSpacing: "0",
	color: Colors.text,
	textTransform: "uppercase",
}

export const navigationText = {
	fontSize: "20px",
	fontWeight: "regular",
	letterSpacing: "0",
	color: Colors.white,
}

export const subNav = {
	fontSize: "18px",
	fontWeight: 400,
	letterSpacing: "0",
	/* color conflicts with material-ui here */ 
	/* color: Colors.text */
}

export const paragraph1 = {
	fontSize: "18px",
	fontWeight: "medium",
	letterSpacing: "0",
	color: Colors.text,
}

export const paragraph2 = {
	fontSize: "14px",
	fontWeight: "medium",
	letterSpacing: "0",
	color: Colors.cancel,
}

export const button = {
	fontSize: "16px",
	fontWeight: "bold",
	letterSpacing: "0",
	color: Colors.white,
}
