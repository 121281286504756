/* eslint-disable eqeqeq */
import { useMutation, useQuery } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { Paper, ThemeProvider, StyledEngineProvider } from '@mui/material'
import React, { useCallback, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { SavedSearch } from "../../common/SavedSearch"
import { DEFAULT_SEARCH_LIMIT, USER_SEARCH_OBJECT } from "../../constants/values"
import { DYNAMIC_USER_NAV } from "../../navigation/Queries"
import useNavigation from "../../navigation/useNavigation"
import GlobalSearchFilters, { getOperatorValue, initialFilterState } from "../../Search/GlobalSearchFilters"
import { DYNAMIC_USER_SEARCH, GET_SEARCHES, GET_USER_FILTER_FIELDS, UPDATE_SEARCH } from "../../Search/Queries"
import SearchQuickView from "../../Search/SearchQuickView"
import { filterSearchTerms, handleDeleteSearch, updateLabel } from "../../Search/unifiedSearchHelpers"
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { AuthStateContext, DispatchContext } from '../../store'
import SearchUsers from './SearchUsers'
import UserQuickView from '../../User/UserQuickView'
import { UsersDataGrid } from './UsersDataGrid'
import theme from '../../styles/muiThemes/adminConsoleTheme'
import '../DarkTheme.css'
import CircularLoading from '../../navigation/CircularLoading'
import { fetchWithAuth } from '../../common/helpers'
import { permissions } from '../../constants/permissions'
import {  } from 'react-router-dom'
import CreateUserQuickView from '../CreateUserQuickView'

const AdminUsers = (props) => {

	const { push: pushNav } = useNavigation()
	const navigate = useNavigate()
	const location = useLocation()
	const userAuthentication = useContext(AuthStateContext)

	const prevSearch = location.state

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const closeSnackbar = () => {
		dispatch({ type: 'closeSnackBar'})
	}

	// Mutations
	const [updateSearch] = useMutation(UPDATE_SEARCH)

	


	// State that comes from prevSearch
	const [value, setValue] = React.useState(0)
	const [order, setOrder] = React.useState(prevSearch?.order || "asc")
	const [orderBy, setOrderBy] = React.useState(prevSearch?.orderBy || null)
	const [users, setUsers] = React.useState(prevSearch?.users ?? [])
	const [limit, setLimit] = React.useState(prevSearch?.limit ?? DEFAULT_SEARCH_LIMIT)
	const [activeStep, setActiveStep] = React.useState(prevSearch?.activeStep ?? 0)
	const [totalItems, setTotalItems] = React.useState(prevSearch?.totalItems ?? null)
	const [steps, setSteps] = React.useState(prevSearch?.steps ?? 1)
	const [selectedUser, setSelectedUser] = React.useState(null)
	const [advancedSearch, setAdvancedSearch] = React.useState(prevSearch?.advancedSearch ?? false)

	const [currentSearch, setCurrentSearch] = React.useState(prevSearch?.currentSearch ?? new SavedSearch({
		object_id: USER_SEARCH_OBJECT,
		search_terms: [{
			field: 'is_active',
			type: 'eq',
			value: 'true'
		}],
		is_global: true,
		user_id: userAuthentication.user?.id
	}))

	// Normal State
	const [flaggedCount, setFlaggedCount] = React.useState(null)
	const [firstRender, setFirstRender] = React.useState(true)
	const [selection, setSelection] = React.useState(null)
	const [savedSearches, setSavedSearches] = React.useState([])
	const [searchLoading, setSearchLoading] = React.useState(null)
	const [createUser, setCreateUser] = React.useState(null)

	const setFilters = (filters) => {
		setCurrentSearch({
			...currentSearch,
			search_terms: filters
		})
	}

	const currentFilters = [
		'query',
		'is_active'
	]

	const advancedFilters = filterSearchTerms(currentSearch.search_terms)?.filter(term => {
		if (currentFilters.includes(term.field)) return false
		return true
	})

	
	React.useEffect(() => {

		if (currentSearch.search_terms.length == 0 && advancedSearch) {
			setCurrentSearch(new SavedSearch({
				object_id: USER_SEARCH_OBJECT,
				search_terms: [initialFilterState()],
				is_global: true,
				user_id: userAuthentication.user?.id
			}))
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSearch.search_terms, userAuthentication.user?.id, advancedSearch])

	// Search on change of order
	React.useEffect(() => {
		setFirstRender(false)
		if (users)
			if (users.length !== 0 && users[0].disabled !== true && !firstRender) {
				handleSubmit()
			}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order, orderBy, limit, activeStep])

	/**
	 * Save current page state for if one returns to it through
	 * back arrow.
	 *
	 * @returns current page state
	 */
	function updateHistory (index) {
		const currentPageState = {
			currentSearch,
			orderBy,
			order,
			users,
			limit,
			totalItems,
			steps,
			activeStep,
			advancedSearch,
			searchPage: location.pathname
		}
		const offset = index ? users.findIndex(a => a.id == index) : users.indexOf(selectedUser)
		const cursor = activeStep * limit + offset
		const variables = makeVariables(cursor, currentSearch, orderBy, order, currentSearch?.search_terms ?? [])
		variables.limit = 3
		currentPageState.variables = variables
		navigate(location.pathname, { state: currentPageState })
		return currentPageState
	}

	function saveAndGo(path, row) {
		// persist current page state in history
		const state = updateHistory(row)
		pushNav({
			url: location.pathname,
			state,
			query: DYNAMIC_USER_NAV
		}, state.variables?.cursor)
		// navigate to new page (with this page's query and `variables`)
		navigate(path, state)
	}

	// Save search to state on selecting a User
	React.useEffect(() => {
		if (selectedUser) updateHistory()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUser])

	// Get saved searches
	const {loading, error } = useQuery(GET_SEARCHES, {
		skip:  !userAuthentication.user?.id,
		variables: {
			userId: userAuthentication.user?.id,
			objectId: USER_SEARCH_OBJECT,
			global: true
		},
		onCompleted: (data) => {
			if (data && data.getSearches && data.getSearches?.length !== 0) {
				setSavedSearches(data.getSearches
					.map(search => new SavedSearch(search)))
			} else {
				// console.log("No saved searches were found.")
			}
		}
	})

	if (error) {
		console.log(error)
		openSnackbar(severity.ERROR, "Error retrieving your saved searches.")
	}


	// get dynamic user filter fields
	const { data: userFilterFields, error: userFilterError } = useQuery(GET_USER_FILTER_FIELDS, {
		fetchPolicy: 'cache-first'
	})
	
	if (userFilterError) {
		openSnackbar(severity.ERROR, 'There was an error retrieving filterable fields for this search.')
		console.error(userFilterError)
	}



	const userFiltersMap = (filters) => filters.filter(e => e.field != "").map(e => {

		const foundPermission = userFilterFields?.searchDynamicUsersFilterFields.find((filter) => 
			filter.name == e.field && 
			(filter.type == 'PermissionYesNo' || filter.type == 'PermissionFourValue')
		)

		if (foundPermission) return {
			field: `permission_id.${foundPermission.permission_id}`,
			type: e.type,
			value: e.value?.toString()
		}
	
		return {
			field: e.field,
			type: e.type,
			value: e.value?.toString()
		}
	})

	const makeVariables = (cursor, currentSearch, orderBy, order, filters, limitOverride) => {
		return ({
			cursor,
			limit: limitOverride ?? limit,
			field: orderBy,
			direction: order.toUpperCase(),
			filters: filters ? userFiltersMap(filters) : userFiltersMap(currentSearch.search_terms),
			thumbnailResolution: "128x128"
		})
	}

	const handleSubmit = ({cursor} = {}, filters = null, searchId) => {
		setSearchLoading(true)
		let submitCursor
		if (cursor == undefined) {
			submitCursor = activeStep * limit
		} else {
			submitCursor = cursor
		}
		openSnackbar(severity.INFO, "Loading search results...")

		const variables = makeVariables(
			submitCursor, currentSearch, orderBy, order, filters
		)
		setValue(1)

		// Set filters when handle submit is not coming from saved search list
		if (filters?.length && !searchId) setFilters(filters)

		props.client
			.query({ query: DYNAMIC_USER_SEARCH, variables })
			.then((result) => {

				const {data} = result

				if (data.searchDynamicUsers?.items) {
					setUsers(data.searchDynamicUsers.items)

					if (data.searchDynamicUsers.items < 1) setSteps(1)
					else setSteps((Math.ceil(data.searchDynamicUsers.totalItems / limit)))

					setTotalItems(data.searchDynamicUsers.totalItems || 0)
					setSearchLoading(false)
					if (!data.searchDynamicUsers.totalItems) {
						openSnackbar(severity.WARNING, "There were no results.")
					} else {
						closeSnackbar()
					}

				} else {
					console.error(data)
					setSearchLoading(false)
					openSnackbar(severity.ERROR, "There was an error searching users.")
				}
			})
			.catch((error) => {
				console.error(error)
				setSearchLoading(false)
				openSnackbar(severity.ERROR, "Could not search users.")
			})

	}

	const handleReset = (page) => {

		setCurrentSearch(new SavedSearch({
			object_id: USER_SEARCH_OBJECT,
			search_terms: [{
				field: 'is_active',
				type: 'eq',
				value: 'true'
			}],
			is_global: true,
			user_id: userAuthentication.user?.id
		}))
		setUsers([])
		setSteps(1)
		setCreateUser(false)
		setActiveStep(0)
		setTotalItems(null)
		setOrder("asc")
		setOrderBy(null)
		setSelectedUser(null)
		setSearchLoading(false)
		setValue(0)
	}

	const updateUserSavedSearchLabel = (search, label) =>
		updateLabel(
			search,
			label,
			updateSearch,
			savedSearches,
			setSavedSearches,
			openSnackbar
		)

	const handleDeleteUserSavedSearch = (search) =>
		handleDeleteSearch(
			search,
			updateSearch,
			savedSearches,
			setSavedSearches,
			openSnackbar
		)


	React.useEffect(() => {
		const storedFields = location?.state?.fields
		if (storedFields) {
			setAdvancedSearch(true)
			const fieldFilters = Object.entries(storedFields)
				.map(([field, value], i) => {
					const filterField = userFilterFields.searchDynamicUsersFilterFields
						.find(f => f.name == field)
					let type = getOperatorValue(filterField?.type)
					return {
						field,
						value,
						type,
						id: new Date().getTime() + i
					}
				})
			setFilters(fieldFilters)
			handleSubmit({}, fieldFilters)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userFilterFields])

	


	const renderQuickView = () => {

		if (createUser) {
			return <CreateUserQuickView setCreateUser={setCreateUser} />
		}

		else if (selectedUser) {
			return (
				<UserQuickView
					user={selectedUser}
					findMode={props.findMode}
					onClose={() => setSelectedUser(null)}
				></UserQuickView>
			)
		}

		else {
			return (
				<SearchQuickView

					disableBulkActions
					disableFlagCount
					disableFavoriteSavedSearch

					savedSearches={savedSearches}
					setSavedSearches={setSavedSearches}
					setCurrentSearch={setCurrentSearch}
					setFilters={setFilters}
					handleSubmit={handleSubmit}
					currentSearch={currentSearch}
					savedSearchesLoading={loading}
					updateLabel={updateUserSavedSearchLabel}
					handleDeleteSearch={handleDeleteUserSavedSearch}
					setSelection={setSelection}
					selection={selection}
					totalItems={totalItems}
					flaggedCount={flaggedCount}
					setFlaggedCount={setFlaggedCount}
					makeVariables={makeVariables}
					orderBy={orderBy}
					order={order}
					searchQuery={DYNAMIC_USER_SEARCH}
					entity="user"
					setValue={setValue}
					value={value}
					filterFields={userFilterFields?.searchDynamicUsersFilterFields}
					requery={handleSubmit}
				/>
			)
		}
	}

	const variables = makeVariables(0, currentSearch, orderBy, order)


	// Admin Authorization Logic

	const authState = useContext(AuthStateContext)

	useEffect(() => {
		// redirect if the user is not logged in as admin. 
		const url = new URL("/api/admin-auth-status", window.location.origin)
		fetchWithAuth(url)
			.then( resp => resp.json() )
			.then( data => {
				if (!data.isAdminAuthenticated){
					navigate('/admin/login')
				}
			} )

	}, [navigate])

	
	if (authState && authState.user) {
		if (authState.user.permissions.length === 0 || authState.user.permissions.find(e => e.permission_id == permissions.ADMIN_CONSOLE).permission_value_id !== "1") {
			openSnackbar(severity.ERROR, "Non-admin privileges.")
			navigate('/home')
		}

		return (
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<section className="main-page dark-theme">
						{renderQuickView()}

						<div style={{
							display: 'flex',
							flexDirection: 'column'
						}}>
							<Paper className="padding-margin-scrollbar search-card">
								{advancedSearch ?
									<GlobalSearchFilters
										adminConsole
										loading={searchLoading}
										currentSearch={currentSearch}
										setCurrentSearch={setCurrentSearch}
										onReset={handleReset}
										onSearch={handleSubmit}
										object_id={USER_SEARCH_OBJECT}
										advancedSearch={advancedSearch}
										setAdvancedSearch={setAdvancedSearch}
										setCreateEntity={setCreateUser}
										filterFields={userFilterFields?.searchDynamicUsersFilterFields}
										typeName="User"
										onFindMode={() => navigate('/admin/users/findmode/profile')}
										savedSearches={savedSearches}
										setSavedSearches={setSavedSearches}
										prevSearch={prevSearch?.currentSearch}
									/> :
									<SearchUsers
										loading={searchLoading}
										currentFilters={currentSearch.search_terms}
										resetSearch={handleReset}
										currentSearch={currentSearch}
										setCurrentSearch={setCurrentSearch}
										handleSubmit={handleSubmit}
										setActiveStep={setActiveStep}
										setTotalItems={setTotalItems}
										advancedSearch={advancedSearch}
										setAdvancedSearch={setAdvancedSearch}
										advancedFilters={advancedFilters}
										selection={selection}
										setSelection={setSelection}
										onFindMode={() => navigate('/admin/users/findmode/profile')}
										prevSearch={prevSearch?.currentSearch}
										savedSearches={savedSearches}
										setSavedSearches={setSavedSearches}
										setCreateEntity={setCreateUser}
									></SearchUsers>}
							</Paper>

							<UsersDataGrid
								rows={users}
								loading={searchLoading}
								saveAndGo={saveAndGo}
								setRows={setUsers}

								userId={userAuthentication.user?.id}
								mainSearchPage={true}
								totalItems={totalItems}
								onSelect={setSelectedUser}
								selectedRow={selectedUser}
								limit={limit}
								setLimit={setLimit}
								activeStep={activeStep}
								setActiveStep={setActiveStep}

								sortModel={ orderBy ? [{ field: orderBy, sort: order }] : []}
								onSortModelChange={newSort => {
									if (newSort.length) {
										setOrderBy(newSort[0].field)
										setOrder(newSort[0].sort)
									} else {
										setOrderBy(null)
										setOrder('asc')
									}
								}}
								variables={variables}
							/>
						</div>
					</section>
				</ThemeProvider>
			</StyledEngineProvider>
		)	} return (
		<CircularLoading/>
	) 	
}

export default withApollo(AdminUsers)
