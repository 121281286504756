import React, {useContext, useCallback} from "react"
import NewNote from '../../../Notes/ObjectNotes/NewObjectNote'
import NoteList from '../../../Notes/ObjectNotes/ObjectNotesList'
import { GET_CONTACT_NOTES, CREATE_NOTE, CONTACT_UPDATE_NOTE } from './Queries'
import { DispatchContext } from "../../../store"
import { isNullArray } from "../../../common/helpers"
import { severity } from "../../../Snackbar/CustomizedSnackbar"
import { withApollo } from "@apollo/client/react/hoc"
import NoteSearch from "../../../Search/NoteSearch"

function ContactNotes(props) {

	const [notes, setNotes] = React.useState([])
	const [notesLoading, setNotesLoading] = React.useState(true)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	// Modal
	const [editNoteModal, setEditNoteModal] = React.useState({open: false, note: null})

	React.useEffect(() => {
		let active = true
		if (props.id && !props.findMode) {
			setNotesLoading(true)
			setNotes([])
			
			props.client
				.query({ query: GET_CONTACT_NOTES, variables: { contact_id: props.id}})
				.then(notesData => {
					if (active) {
						setNotesLoading(false)
						if (notesData?.data && !isNullArray(notesData?.data?.getContactNotes)) {
							setNotes(notesData?.data.getContactNotes || [])
						} else if (isNullArray(notesData?.data?.getContactNotes)) {
							setNotes([])
							openSnackbar(severity.ERROR, "Error - Unauthorized")
						}
					}
				})
		}
		return () => active = false
	}, [openSnackbar, props.client, props.findMode, props.id])
	
	return (
		<section className="main-page">
			{props.findMode ? <NoteSearch type="contact" /> :
				(props.id > 0 && 
				<div>
					<NewNote
						entityId={props.id}
						notes={notes}
						setNotes={setNotes}
						notesLoading={notesLoading}
						mutation={CREATE_NOTE}
						mutationName="createContactNote"
						linkField="contact_id"
						state={props.state}
						setState={props.setState}
						criticalNotes="getCriticalContactNotes"
						privateEntity={props.state?.getContact?.is_private || false}
					/>
					<NoteList
						editNoteModal={editNoteModal}
						setEditNoteModal={setEditNoteModal}
						state={props.state}
						setState={props.setState}
						notes={notes}
						setNotes={setNotes}
						notesLoading={notesLoading}
						mutationResponse="updateContactNote"
						updateMutationName={CONTACT_UPDATE_NOTE}
						criticalNotes="getCriticalContactNotes"
						linkField="contact_id"
						privateEntity={props.state?.getContact?.is_private || false}
					/>
				
				</div>
				)}
		</section>
	)
}

export default withApollo(ContactNotes)
