/* eslint-disable eqeqeq */
import { useMutation, useQuery } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import React, { useCallback, useContext } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import CollapsibleSearchCard from '../common/components/CollapsibleSearchCard'
import { SavedSearch } from "../common/SavedSearch"
import { DEFAULT_SEARCH_LIMIT, TAG_SEARCH_OBJECT } from "../constants/values"
import { DYNAMIC_TAG_NAV } from "../navigation/Queries"
import useNavigation from "../navigation/useNavigation"
import GlobalSearchFilters, { getOperatorValue, initialFilterState } from "../Search/GlobalSearchFilters"
import { DYNAMIC_TAG_SEARCH, GET_SEARCHES, GET_TAG_FILTER_FIELDS, UPDATE_SEARCH } from "../Search/Queries"
import SearchQuickView from "../Search/SearchQuickView"
import { filterMap, filterSearchTerms, handleDeleteSearch, updateLabel } from "../Search/unifiedSearchHelpers"
import { severity } from '../Snackbar/CustomizedSnackbar'
import { AuthStateContext, DispatchContext } from '../store'
import SearchTags from './SearchTags'
import TagQuickView from './TagQuickView'
import { TagsDataGrid } from './TagsDataGrid'

const Tags = (props) => {

	const { push: pushNav } = useNavigation()
	const navigate = useNavigate()
	const location = useLocation()
	const userAuthentication = useContext(AuthStateContext)

	const prevSearch = location.state

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const closeSnackbar = () => {
		dispatch({ type: 'closeSnackBar'})
	}

	// Mutations
	const [updateSearch] = useMutation(UPDATE_SEARCH)

	// State that comes from prevSearch
	const [value, setValue] = React.useState(0)
	const [order, setOrder] = React.useState(prevSearch?.order || "asc")
	const [orderBy, setOrderBy] = React.useState(prevSearch?.orderBy || null)
	const [tags, setTags] = React.useState(prevSearch?.tags ?? [])
	const [limit, setLimit] = React.useState(prevSearch?.limit ?? DEFAULT_SEARCH_LIMIT)
	const [activeStep, setActiveStep] = React.useState(prevSearch?.activeStep ?? 0)
	const [totalItems, setTotalItems] = React.useState(prevSearch?.totalItems ?? null)
	const [steps, setSteps] = React.useState(prevSearch?.steps ?? 1)
	const [selectedTag, setSelectedTag] = React.useState(null)
	const [advancedSearch, setAdvancedSearch] = React.useState(prevSearch?.advancedSearch ?? false)

	const [currentSearch, setCurrentSearch] = React.useState(prevSearch?.currentSearch ?? new SavedSearch({
		object_id: TAG_SEARCH_OBJECT,
		search_terms: [],
		is_global: true,
		user_id: userAuthentication.user?.id
	}))

	// Normal State
	const [flaggedCount, setFlaggedCount] = React.useState(null)
	const [firstRender, setFirstRender] = React.useState(true)
	const [selection, setSelection] = React.useState(null)
	const [savedSearches, setSavedSearches] = React.useState([])
	const [searchLoading, setSearchLoading] = React.useState(null)

	const setFilters = (filters) => {
		setCurrentSearch({
			...currentSearch,
			search_terms: filters
		})
	}

	const currentFilters = [
		'query',
		'created_at',
		'created_user'
	]

	const advancedFilters = filterSearchTerms(currentSearch.search_terms)?.filter(term => {
		if (currentFilters.includes(term.field)) return false
		return true
	})

	
	React.useEffect(() => {

		if (currentSearch.search_terms.length == 0 && advancedSearch) {
			setCurrentSearch(new SavedSearch({
				object_id: TAG_SEARCH_OBJECT,
				search_terms: [initialFilterState()],
				is_global: true,
				user_id: userAuthentication.user?.id
			}))
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSearch.search_terms, userAuthentication.user?.id, advancedSearch])

	// Search on change of order
	React.useEffect(() => {
		setFirstRender(false)
		if (tags)
			if (tags.length !== 0 && tags[0].disabled !== true && !firstRender) {
				handleSubmit()
			}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order, orderBy, limit, activeStep])

	/**
	 * Save current page state for if one returns to it through
	 * back arrow.
	 *
	 * @returns current page state
	 */
	function updateHistory (index) {
		const currentPageState = {
			currentSearch,
			orderBy,
			order,
			tags,
			limit,
			totalItems,
			steps,
			activeStep,
			searchPage: location.pathname
		}
		const offset = index ? tags.findIndex(a => a.id == index) : tags.indexOf(selectedTag)
		const cursor = activeStep * limit + offset
		const variables = makeVariables(cursor, currentSearch, orderBy, order, currentSearch?.search_terms ?? [])
		variables.limit = 3
		currentPageState.variables = variables
		navigate(location.pathname, { state: currentPageState })
		return currentPageState
	}

	function saveAndGo(path, row) {
		// persist current page state in history
		const state = updateHistory(row)
		pushNav({
			url: location.pathname,
			state,
			query: DYNAMIC_TAG_NAV
		}, state.variables?.cursor)
		// navigate to new page (with this page's query and `variables`)
		navigate(path, { state })
	}

	// Save search to state on selecting a Tag
	React.useEffect(() => {
		if (selectedTag) updateHistory()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTag])

	// Get saved searches
	const {loading, error } = useQuery(GET_SEARCHES, {
		skip:  !userAuthentication.user?.id,
		variables: {
			userId: userAuthentication.user?.id,
			objectId: TAG_SEARCH_OBJECT,
			global: true
		},
		onCompleted: (data) => {
			if (data && data.getSearches && data.getSearches?.length !== 0) {
				setSavedSearches(data.getSearches
					.map(search => new SavedSearch(search)))
			} else {
				// console.log("No saved searches were found.")
			}
		}
	})

	if (error) {
		console.log(error)
		openSnackbar(severity.ERROR, "Error retrieving your saved searches.")
	}


	const makeVariables = (cursor, currentSearch, orderBy, order, filters, limitOverride) => {
		return ({
			cursor,
			limit: limitOverride ?? limit,
			field: orderBy,
			direction: order.toUpperCase(),
			filters: filters ? filterMap(filters) : filterMap(currentSearch.search_terms),
			thumbnailResolution: "128x128"
		})
	}

	const handleSubmit = ({cursor} = {}, filters = null, searchId) => {
		setSearchLoading(true)
		let submitCursor
		if (cursor == undefined) {
			submitCursor = activeStep * limit
		} else {
			submitCursor = cursor
		}
		openSnackbar(severity.INFO, "Loading search results...")

		// Apply * to all search terms for tags only. https://jahnelgroup.atlassian.net/browse/LGWEBAPP-4873
		const searchTerms = filters?.map(f => ({
			...f,
			value: f.value.split(' ').map(t => `*${t}*`).join(' ')
		}))

		const variables = makeVariables(
			submitCursor, currentSearch, orderBy, order, searchTerms
		)
		setValue(1)

		// Set filters when handle submit is not coming from saved search list
		if (filters?.length && !searchId) setFilters(filters)

		props.client
			.query({ query: DYNAMIC_TAG_SEARCH, variables })
			.then((result) => {

				const {data} = result

				if (data.searchDynamicTags?.items) {
					setTags(data.searchDynamicTags.items)

					if (data.searchDynamicTags.items < 1) setSteps(1)
					else setSteps((Math.ceil(data.searchDynamicTags.totalItems / limit)))

					setTotalItems(data.searchDynamicTags.totalItems || 0)
					setSearchLoading(false)
					if (!data.searchDynamicTags.totalItems) {
						openSnackbar(severity.WARNING, "There were no results.")
					} else {
						closeSnackbar()
					}

				} else {
					console.error(data)
					setSearchLoading(false)
					openSnackbar(severity.ERROR, "There was an error searching tags.")
				}
			})
			.catch((error) => {
				console.error(error)
				setSearchLoading(false)
				openSnackbar(severity.ERROR, "Could not search tags.")
			})

	}

	const handleReset = (page) => {

		setCurrentSearch(new SavedSearch({
			object_id: TAG_SEARCH_OBJECT,
			search_terms: [],
			is_global: true,
			user_id: userAuthentication.user?.id
		}))
		setTags([])
		setSteps(1)
		setActiveStep(0)
		setTotalItems(null)
		setOrder("asc")
		setOrderBy(null)
		setSelectedTag(null)
		setSearchLoading(false)
		setValue(0)
	}

	const updateTagSavedSearchLabel = (search, label) =>
		updateLabel(
			search,
			label,
			updateSearch,
			savedSearches,
			setSavedSearches,
			openSnackbar
		)

	const handleDeleteTagSavedSearch = (search) =>
		handleDeleteSearch(
			search,
			updateSearch,
			savedSearches,
			setSavedSearches,
			openSnackbar
		)

	// Get dynamic tag filter fields
	const { data: tagFilterFields, error: tagFilterError } = useQuery(GET_TAG_FILTER_FIELDS, {
		fetchPolicy: 'cache-first',
		onCompleted: ({searchDynamicTagsFilterFields}) => {
			const storedFields = location?.state?.fields
			if (storedFields) {
				setAdvancedSearch(true)
				const fieldFilters = Object.entries(storedFields)
					.map(([field, value], i) => {
						const filterField = searchDynamicTagsFilterFields
							.find(f => f.name == field)
						let type = getOperatorValue(filterField?.type)
						return {
							field,
							value,
							type,
							id: new Date().getTime() + i
						}
					})
				setFilters(fieldFilters)
				handleSubmit({}, fieldFilters)
			}
		}
	})

	if (tagFilterError) {
		openSnackbar(severity.ERROR, 'There was an error retrieving filterable fields for this search.')
		console.error(tagFilterError)
	}

	const renderQuickView = () => {
		if (selectedTag) {
			return (
				<TagQuickView
					tag={selectedTag}
					onClose={() => setSelectedTag(null)}
				></TagQuickView>
			)
		}

		else {
			return (
				<SearchQuickView

					disableBulkActions
					disableFlagCount
					disableFavoriteSavedSearch

					savedSearches={savedSearches}
					setSavedSearches={setSavedSearches}
					setCurrentSearch={setCurrentSearch}
					setFilters={setFilters}
					handleSubmit={handleSubmit}
					currentSearch={currentSearch}
					savedSearchesLoading={loading}
					updateLabel={updateTagSavedSearchLabel}
					handleDeleteSearch={handleDeleteTagSavedSearch}
					setSelection={setSelection}
					selection={selection}
					totalItems={totalItems}
					flaggedCount={flaggedCount}
					setFlaggedCount={setFlaggedCount}
					makeVariables={makeVariables}
					orderBy={orderBy}
					order={order}
					searchQuery={DYNAMIC_TAG_SEARCH}
					entity="tag"
					setValue={setValue}
					value={value}
					filterFields={tagFilterFields?.searchDynamicTagsFilterFields}
					requery={handleSubmit}
				/>
			)
		}
	}

	const variables = makeVariables(0, currentSearch, orderBy, order)

	return (
		<section className="main-page">
			{renderQuickView()}

			<div style={{
				display: 'flex',
				flexDirection: 'column'
			}}>
				<CollapsibleSearchCard>
					{advancedSearch ?
						<GlobalSearchFilters
							disableCreation
							loading={searchLoading}
							currentSearch={currentSearch}
							setCurrentSearch={setCurrentSearch}
							onReset={handleReset}
							onSearch={handleSubmit}
							object_id={TAG_SEARCH_OBJECT}
							advancedSearch={advancedSearch}
							setAdvancedSearch={setAdvancedSearch}
							filterFields={tagFilterFields?.searchDynamicTagsFilterFields}
							typeName="Tag"
							savedSearches={savedSearches}
							setSavedSearches={setSavedSearches}
							prevSearch={prevSearch?.currentSearch}
						/> :
						<SearchTags
							loading={searchLoading}
							currentFilters={currentSearch.search_terms}
							resetSearch={handleReset}
							currentSearch={currentSearch}
							setCurrentSearch={setCurrentSearch}
							handleSubmit={handleSubmit}
							setActiveStep={setActiveStep}
							setTotalItems={setTotalItems}
							advancedSearch={advancedSearch}
							setAdvancedSearch={setAdvancedSearch}
							advancedFilters={advancedFilters}
							selection={selection}
							setSelection={setSelection}
							prevSearch={prevSearch?.currentSearch}
							savedSearches={savedSearches}
							setSavedSearches={setSavedSearches}
						></SearchTags>}
				</CollapsibleSearchCard>

				<TagsDataGrid
					rows={tags}
					loading={searchLoading}
					saveAndGo={saveAndGo}
					setRows={setTags}

					userId={userAuthentication.user?.id}
					mainSearchPage={true}
					totalItems={totalItems}
					onSelect={setSelectedTag}
					selectedRow={selectedTag}
					limit={limit}
					setLimit={setLimit}
					activeStep={activeStep}
					setActiveStep={setActiveStep}

					sortModel={ orderBy ? [{ field: orderBy, sort: order }] : []}
					onSortModelChange={newSort => {
						if (newSort.length) {
							setOrderBy(newSort[0].field)
							setOrder(newSort[0].sort)
						} else {
							setOrderBy(null)
							setOrder('asc')
						}
					}}
					variables={variables}
				/>
			</div>
		</section>
	)
}

export default withApollo(Tags)
