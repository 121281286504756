import React from 'react'

/**
 * A small component to render a quill delta obj in html
 *
 * @param {*} props.children - A supplied quill delta obj: https://quilljs.com/docs/delta/
 */
export default function QuillText(props) {
	const delta = props.children?.ops

	const outerStyle = !props.noWrap ?
		{ wordWrap: 'break-word', ...props.style} :
		{...props.style}

	let className = "quill-text"


	if (props.inputStyle) {
		outerStyle.marginTop = '1.6em'
		outerStyle.minHeight = '33px'

		className += " quill-text-input"
	}

	if (props.editableStyle) {
		outerStyle.border = props.error ? "1px solid red" : "1px solid hsl(210, 14%, 83%)"
		outerStyle.borderRadius = '4px'
		outerStyle.padding = '4.5px 12px'
		outerStyle.cursor = 'text'

		className += " quill-text-editable"
	}

	if (props.fontSize) {
		outerStyle.fontSize = props.fontSize
	}

	if (props.ellipsis) {
		outerStyle.whiteSpace = 'nowrap'
		outerStyle.overflow = 'hidden'
		outerStyle.textOverflow = 'ellipsis'
	}

	if (props.large) {
		outerStyle.paddingTop = '10px'
		outerStyle.paddingBottom = '10px'
		outerStyle.minHeight = '44px'
		outerStyle.margin = 0

	}
	return (
		<span style={outerStyle} onClick={props.onClick}
			className={className}
			data-testid={props["data-testid"]}
		>
			{delta?.map((text, index) => {

				const style={}

				// Loading
				if (!text) return null

				if (text.attributes?.italic)
					style.fontStyle = 'italic'

				if (text.attributes?.bold)
					style.fontWeight = '600'

				if (text.attributes?.underline)
					style.textDecoration = 'underline'

				if (text.attributes?.color)
					style.color = text.attributes.color

				// Return nothing if extra newline at end of text
				if (index === delta.length - 1 && text.insert === '\n') return null
				return <span key={`${props.name}-${index}`} style={style}>
					{text.insert}
				</span>
			})}
		</span>
	)
}
