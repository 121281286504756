import React, { useContext, useCallback } from "react"
import { DispatchContext } from '../../store'
import {
	Paper,
	FormControl,
	Menu,
	MenuItem,
	Select,
	IconButton,
	Tooltip,
	Fade
} from "@mui/material"
import makeStyles from '@mui/styles/makeStyles'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import Dropdown from '../../input/Dropdown/ThinDropdown'
import Label from '../../input/Label'
import TextBox from '../../input/Text/TextBoxThin'
import { CancelButton, SaveCardButton } from '../../input/Buttons'
import { useMutation } from '@apollo/client'
import { UPDATE_CONTACT } from "../Queries.js"
import { LookupContext } from '../../store'
import ReactQuill from 'react-quill'
import Delta from 'quill-delta'
import PhoneInput from 'react-phone-input-2'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import "./ContactDetails.css"
import sortBy from 'lodash/sortBy'
import { Skelly } from "../../common/components/Skelly"
import { FindModeInput } from "../../navigation/Tabs/TabbedPage"

const useStyles = makeStyles(theme => ({
	selection: {
		width: "100%",
	},
	input: {
		padding: '6px 12px',
	}
}))

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false
}

export default function Details(props) {

	const lookup = useContext(LookupContext)
	const prefixes= lookup.data?.getPrefixTypes
	const sortedPrefixes = sortBy(prefixes, [function(o) { return o.value }])

	const classes = useStyles()

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const [state, setState] = React.useState(initialState)
	const [contactInput, setContactInput] = React.useState({})
	const [attempt, setAttempt] = React.useState(false)

	const [ttOpen, setTTOpen] = React.useState(false)
	const [showCopy, setShowCopy] = React.useState(false)
	const [copyText, setCopyText] = React.useState(null)
	const [copyElement, setCopyElement] = React.useState(null)

	const handleTooltipClose = () => setTTOpen(false)
	const handleTooltipOpen = () => setTTOpen(true)

	const phoneRef = React.useRef([])

	const [updateContact, { loading }] = useMutation(UPDATE_CONTACT, {

		onError: (error) => {

			// On failure, reset contactInput state, don't touch contact state and show error
			resetContactInput(props.contact)
			openSnackbar(severity.ERROR, "Could not update details card.")
			handleClose()
		},

		onCompleted: (response) => {
			if (response.updateContact.success === true) {

				// Success
				openSnackbar(severity.SUCCESS, response.updateContact.message)

				// On success change contact state
				props.setContact({
					...props.contact,
					prefix: (contactInput.prefix_id) ? {
						id: contactInput.prefix_id,
						value: prefixes[parseInt(contactInput.prefix_id)-1].value
					} : null,
					first_name: contactInput.first_name,
					last_name: contactInput.last_name,
					suffix: contactInput.suffix,
					salutation: contactInput.salutation,
					preferred_name: contactInput.preferred_name,
					company_name: contactInput.company_name,
					title: contactInput.title
				})

			} else {

				// On failure, reset contactInput state, don't touch contact state and show error
				resetContactInput(props.contact)
				openSnackbar(severity.ERROR, response.updateContact.message)

			}

		}
	})



	const resetContactInput = (contact) => {
		setContactInput({
			id: contact.id,
			prefix_id: contact.prefix?.id,
			first_name: contact.first_name,
			last_name: contact.last_name,
			suffix: contact.suffix,
			salutation: contact.salutation,
			preferred_name: contact.preferred_name,
			company_name: contact.company_name,
			title: contact.title
		})
	}

	const sortByPrimary = (a, b) => {
		if (a?.is_primary) return -1
		else if (b?.is_primary) return 1
		else return 0
	}

	React.useEffect( () => {
		const address = props.contact?.address?.find(address => address?.is_primary === true)
		const countries = lookup.data?.getCountryTypes
		const primaryCountry = countries?.find(e => e.id === address?.country_id)?.name
		const prefix = (prefixes && props.contact?.prefix_id) ? prefixes[parseInt(props.contact?.prefix_id)-1]?.value : null

		const contactCopyDelta = new Delta()
			.insert( prefix ? `${prefix} ` : "",  {})
			.insert( props.contact?.first_name ? `${props.contact?.first_name} ` : "",  {})
			.insert( props.contact?.last_name ? `${props.contact?.last_name}` : "",  {})
			.insert( props.contact?.suffix ? `${props.contact?.suffix}` : "",  {})
			.insert( "\n",  {})
			.insert( props.contact?.title ? `${props.contact?.title}\n` : "",  {})
			.insert( props.contact?.company_name ? `${props.contact?.company_name}\n` : "",  {})
			.insert( address?.street_1 ? `${address.street_1}\n` : "", {} )
			.insert( address?.street_2 ? `${address.street_2}\n` : "", {} )
			.insert( address?.street_3 ? `${address.street_3}\n` : "", {} )
			.insert( address?.town ? `${address.town}, ` : "", {} )
			.insert( address?.region ? `${address.region} ` : "", {} )
			.insert( address?.postcode ? `${address.postcode}` : "", {} )
			.insert( address ? "\n" : "", {} )
			.insert( primaryCountry ? `${primaryCountry}\n` : "", {} )

		const typeUppercase = type => type.charAt(0).toUpperCase() + type.slice(1)

		contactCopyDelta.insert( props.contact?.email?.length > 0 ? 'Emails:\n' : "", {bold: true} )
		const sortedEmails = [...(props.contact?.email || [])].sort( sortByPrimary )

		// eslint-disable-next-line no-unused-expressions
		sortedEmails?.forEach( email => {
			contactCopyDelta
				.insert( email.label ? `${email.label} ` : "", {} )
				.insert( email.type ? `(${typeUppercase(email.type)})` : "", {} )
				.insert( email.email ? `: ${email.email}` : "", {} )
				.insert("\n")
		} )

		contactCopyDelta.insert( props.contact?.address?.length > 1 ? 'Other Addresses:\n' : "", {bold: true} )
		// eslint-disable-next-line no-unused-expressions
		props.contact?.address?.forEach( address => {
			const country = countries?.find(c => c.id === address?.country_id)?.name

			if(!address.is_primary){
				contactCopyDelta
					.insert( address.label ? `${address.label} ` : "", {} )
					.insert( address.type ? `(${typeUppercase(address.type)})\n` : "", {} )
					.insert( address.street_1 ? `  ${address.street_1}\n` : "", {} )
					.insert( address.street_2 ? `  ${address.street_2}\n` : "", {} )
					.insert( address.street_3 ? `  ${address.street_3}\n` : "", {} )
					.insert( "  ", {} )
					.insert( address?.town ? `${address.town}, ` : "", {} )
					.insert( address?.region ? `${address.region} ` : "", {} )
					.insert( address?.postcode ? `${address.postcode}` : "", {} )
					.insert("\n")
					.insert( country ? `  ${country}\n` : "", {} )

			}
		} )

		contactCopyDelta.insert( props.contact?.phone?.length > 0 ? 'Phone Numbers:\n' : "", {bold: true})
		const sortedPhones = [...(props.contact?.phone || [])].sort( sortByPrimary )

		// eslint-disable-next-line no-unused-expressions
		sortedPhones?.forEach( phone => {
			const fnumber = phoneRef?.current?.find( pref => pref.props.value === phone.number )?.state?.formattedNumber
			contactCopyDelta
				.insert( phone.label ? `${phone.label} ` : "", {} )
				.insert( phone.type ? `(${typeUppercase(phone.type)})` : "" )
				.insert( fnumber ? `: ${fnumber}` : "" )
				.insert( phone.extension ? ` x${phone.extension}` : "" )
				.insert("\n")
		} )

		contactCopyDelta.insert( props.contact?.website?.length > 0 ? 'Websites:\n' : "", {bold: true})
		const sortedWebsites = props.contact?.website?.sort( sortByPrimary )

		// eslint-disable-next-line no-unused-expressions
		sortedWebsites?.forEach( site => {
			contactCopyDelta
				.insert( site.label ? `${site.label} ` : "", {} )
				.insert( site.type ? `(${typeUppercase(site.type)})` : "" )
				.insert( site.website ? `: ${site.website}` : "" )
				.insert("\n")
		} )

		setCopyText(contactCopyDelta)

	}, [props.contact, lookup, prefixes])

	React.useEffect(() => {
		resetContactInput(props.contact)
	}, [props.contact])

	const handleClick = event => {
		if (state.mouseX || state.editable || props.findMode || !props.contact?.id) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4
		})
	}

	const handleClose = option => {
		if (option === "edit") {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	return (
		<Paper
			className="qv-margin"
			onMouseEnter={() => setShowCopy(true)}
			onMouseLeave={() => setShowCopy(false)}
			onContextMenu={handleClick}
			id="contact-details"
			data-testid="card-details"
		>
			<h1 className="card-title">
				<span>Details</span>
				<Tooltip open={ttOpen} onClose={handleTooltipClose} onOpen={handleTooltipOpen} title={"Copy formatted Details"} arrow placement="bottom">
					<Fade in={showCopy || state.editable} {...{timeout: {
						enter: 500,
						exit: 100
					} }}>
						<IconButton
							size="small"
							onClick={()=>{
								try{
									const editor = copyElement.getEditor()

									if(editor.getLength() === 0){
										openSnackbar(
											severity.WARNING,
											'No content to copy.'
										)
										return
									}

									editor.focus()
									editor.setSelection(0, editor.getLength())
									document.execCommand("copy")
									editor.setSelection(null)
									openSnackbar( 	severity.SUCCESS, 'Contact Details copied to Clipboard!' )

								} catch (e) {
									openSnackbar( 	severity.ERROR, 'Error copying Contact Details' )
								}
							}}
						>
							<FileCopyIcon style={{ color: props.private ? 'white' : 'grey', height: '20px' }}> </FileCopyIcon>
						</IconButton>
					</Fade>
				</Tooltip>
				{state.editable && !props.findMode && (
					<>
						<div className="spacer"></div>
						<CancelButton
							variant="contained"
							size="small"
							onClick={() => {
								resetContactInput(props.contact)
								handleClose()
								setAttempt(false)
							}}
						>
							Cancel
						</CancelButton>
						<SaveCardButton
							variant="contained"
							color="secondary"
							size="small"
							disabled={loading}
							onClick={() => {

								setAttempt(true)
								const isDeceased = /deceased/i

								if (!attempt &
										(isDeceased.test(contactInput?.first_name) ||
										isDeceased.test(contactInput?.last_name))
								) {
									openSnackbar(severity.WARNING, "Deceased contacts should be indicated in their status.")
								} else if (attempt && (
									contactInput?.first_name?.length === 0 ||
										contactInput?.last_name?.length === 0 ||
										contactInput?.preferred_name?.length === 0
								)) {

									openSnackbar(severity.WARNING, "Please complete required fields.")

								} else if (!(
									contactInput?.first_name?.length === 0 ||
									contactInput?.last_name?.length === 0 ||
									contactInput?.preferred_name?.length === 0
								)) {
									updateContact({ variables: { ContactInput: contactInput } })
									setAttempt(false)
									setState({
										...state,
										editable: false
									})
								}
							}}
						>
							Save
						</SaveCardButton>
					</>
				)}
			</h1>
			<div className="column-body">
				<div className="column">

					<FormControl className={classes.selection}>
						<Label id="prefix-label" disableAnimation shrink htmlFor="prefix-label">
							Prefix
						</Label>
						{(props.loading || props.error) ? <Skelly error={props.error} /> :
							(props.findMode ? <FindModeInput field="prefix_value" /> :
								<Select
									IconComponent={ExpandMoreRoundedIcon}
									name="prefix"

									labelId="prefix-label"
									input={<Dropdown />}
									value={(contactInput?.prefix_id) ? parseInt(contactInput?.prefix_id) : 'All'}
									className={(!state.editable) ? "readonly padded-select" : "padded-select"}
									readOnly={!state.editable}
									onChange={(e) => {
										setContactInput({
											...contactInput,
											prefix_id:  (e.target.value === 'All') ? null : e.target.value
										})
									}}
									data-testid="contact-prefix"
								>
									<MenuItem
										value={'All'}
										data-testid="contact-prefix-item"
									>-</MenuItem>
									{sortedPrefixes && sortedPrefixes.map(prefix => (
										<MenuItem
											key={prefix.id}
											value={prefix.id}
											data-testid="contact-prefix-item"
										>
											{prefix.value}
										</MenuItem>
									))}
								</Select>)
						}

					</FormControl>

					<FormControl>
						<Label disableAnimation shrink htmlFor="contact-first-name" error={attempt && state.editable && contactInput?.first_name?.length === 0}>
							First Name{!props.findMode && '*'}
						</Label>
						{(props.loading || props.error) ? <Skelly error={props.error} /> :
							(props.findMode ? <FindModeInput field="first_name" /> :
								<TextBox
									required
									error={attempt && state.editable && contactInput?.first_name?.length === 0}

									id="contact-first-name"
									value={contactInput.first_name || ''}
									readOnly={!state.editable}
									placeholder={'-'}
									onChange={(e) => {
										setContactInput({
											...contactInput,
											first_name: e.target.value
										})
									}}
									data-testid="contact-first_name"
								/>
							)
						}
					</FormControl>

					<FormControl>
						<Label disableAnimation shrink htmlFor="contact-last-name" error={attempt && state.editable && contactInput?.last_name?.length === 0}>
							Last Name{!props.findMode && '*'}
						</Label>
						{(props.loading || props.error) ? <Skelly error={props.error} /> :
							(props.findMode ? <FindModeInput field="last_name" /> :
								<TextBox
									required
									error={attempt && state.editable && contactInput?.last_name?.length === 0}
									id="contact-last-name"
									value={contactInput.last_name || ''}
									readOnly={!state.editable}
									placeholder={'-'}
									onChange={(e) => {
										setContactInput({
											...contactInput,
											last_name: e.target.value
										})
									}}
									data-testid="contact-last_name"
								/>
							)
						}
					</FormControl>

					<FormControl>
						<Label disableAnimation shrink htmlFor="contact-suffix">
							Suffix
						</Label>
						{(props.loading || props.error) ? <Skelly error={props.error} /> :
							(props.findMode ? <FindModeInput field="suffix" /> :
								<TextBox
									id="contact-suffix"
									value={contactInput.suffix || ''}
									readOnly={!state.editable}
									placeholder={'-'}
									onChange={(e) => {
										setContactInput({
											...contactInput,
											suffix: e.target.value
										})
									}}
									data-testid="contact-suffix"
								/>
							)
						}
					</FormControl>
				</div>
				<div className="column">
					<FormControl>
						<Label disableAnimation shrink htmlFor="contact-preferred-name" error={attempt && state.editable && contactInput?.preferred_name?.length === 0}>
							Preferred Name
						</Label>
						{(props.loading || props.error) ? <Skelly error={props.error} /> :
							(props.findMode ? <FindModeInput field="preferredName" /> :
								<TextBox
									required
									error={attempt && state.editable && contactInput?.preferred_name?.length === 0}
									id="contact-preferred-name"
									value={contactInput.preferred_name || ''}
									readOnly={!state.editable}
									placeholder={'-'}
									onChange={(e) => {
										setContactInput({
											...contactInput,
											preferred_name: e.target.value
										})
									}}
									data-testid="contact-preferred_name"
								/>
							)
						}
					</FormControl>

					<FormControl>
						<Label disableAnimation shrink htmlFor="contact-salutation">
							Salutation
						</Label>
						{(props.loading || props.error) ? <Skelly error={props.error} /> :
							(props.findMode ? <FindModeInput field="salutation" /> :
								<TextBox
									id="contact-salutation"
									value={contactInput.salutation || ''}
									readOnly={!state.editable}
									placeholder={'-'}
									onChange={(e) => {
										setContactInput({
											...contactInput,
											salutation: e.target.value
										})
									}}
									data-testid="contact-salutation"
								/>
							)
						}
					</FormControl>

					<FormControl>
						<Label disableAnimation shrink htmlFor="contact-company-name">
							Company Name
						</Label>
						{(props.loading || props.error) ? <Skelly error={props.error} /> :
							(props.findMode ? <FindModeInput field="company_name" /> :
								<TextBox
									id="contact-company-name"
									value={contactInput.company_name || ''}
									readOnly={!state.editable}
									placeholder={'-'}
									onChange={(e) => {
										setContactInput({
											...contactInput,
											company_name: e.target.value
										})
									}}
									data-testid="contact-company_name"
								/>
							)
						}
					</FormControl>

					<FormControl>
						<Label disableAnimation shrink htmlFor="contact-title">Job Title</Label>
						{(props.loading || props.error) ? <Skelly error={props.error} /> :
							(props.findMode ? <FindModeInput field="title" /> :
								<TextBox
									id="contact-title"
									value={contactInput.title || ''}
									readOnly={!state.editable}
									placeholder={'-'}
									onChange={(e) => {
										setContactInput({
											...contactInput,
											title: e.target.value
										})
									}}
									data-testid="contact-title"
								/>
							)
						}
					</FormControl>
				</div>
			</div>

			<Menu
				keepMounted
				open={state.mouseY !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					state.mouseY !== null && state.mouseX !== null
						? { top: state.mouseY, left: state.mouseX }
						: undefined
				}
			>
				<MenuItem onClick={() => handleClose("edit")}>Edit</MenuItem>
			</Menu>

			<div style={{position: 'absolute', opacity: 0, zIndex: -1}}>
				<ReactQuill
					value={copyText || { ops: [] }}
					ref={(el) => {
						if (el) setCopyElement(el)
					}}
				>
				</ReactQuill>
			</div>
			{
				props.contact?.phone?.map( (phone, i) => {
					return phoneRef ? (<div key={i} style={{display: "none"}}>
						<PhoneInput
							value={phone?.number || ""}
							ref={ el => phoneRef.current[i] = el }
						/>
					</div>) : null
				} )
			}
		</Paper>
	)
}
