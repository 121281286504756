import React, { useContext, useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { DispatchContext } from '../../store'
import ObjectTags from '../../Tags/ObjectTags'
import { UPDATE_LISTING } from '../Queries'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { stripMeta } from '../../common/helpers'
import ListingDetail from './ListingDetail'
import ListingAdvanced from './ListingAdvanced'
import { format } from 'date-fns'
import ImagesCard from '../../input/Image/ImagesCard'
import AboutCard from '../../input/AboutCard'
import { permissions } from '../../constants/permissions'

function ListingDetailPage(props) {

	let { loading, error } = props
	let listing = props.state?.getListing || {}
	let setListing = (listing) => props.setState({ getListing: listing })

	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const [
		updateListing, { loading: updateLoading, error: updateError }
	] = useMutation(UPDATE_LISTING, {
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, "Could not update.")
		},
		onCompleted: (response) => {
			if (response?.updateListing?.success === true) {
				openSnackbar(severity[response.updateListing.severity] || severity.SUCCESS, response.updateListing.message)
				setListing(response.updateListing.listing)
			} else {
				// On failure, reset listingInput state, don't touch listing
				// state and show error
				openSnackbar(
					severity[response?.updateListing?.severity] || severity.ERROR, 
					response?.updateListing?.message
				)
			}
		}
	})

	const update = (fields) => {
		let variables = stripMeta(fields)

		if (variables.start_at) 
			variables.start_at = format(new Date(variables.start_at), "yyyy-MM-dd")
		if (variables.end_at) 
			variables.end_at = format(new Date(variables.end_at), "yyyy-MM-dd")

		if (variables.start_time) 
			variables.start_time = format(new Date(variables.start_time), "HH:mm:ss")
		if (variables.end_time) 
			variables.end_time = format(new Date(variables.end_time), "HH:mm:ss")

		if (variables.time_zone && Object.keys(variables.time_zone).length) {
			variables.timezone_id = variables.time_zone.id
		} else {
			variables.timezone_id = null
		}

		delete variables.time_zone
		delete variables.project_code
		if (!variables.id) variables.id = listing.id

		updateListing({ variables: { 
			UpdateListingInput: variables
		}})
	}

	return (
		<section className="main-page">
			<div className="tabbed-content">
				<div className="page-row">
					<ListingDetail 
						loading={loading}
						error={error || updateError}
						listing={listing}
						setListing={setListing}
						onSave={update}
						disabled={updateLoading}
						findMode={props.findMode}
					></ListingDetail>
					<ListingAdvanced
						loading={loading}
						error={error || updateError}
						listing={listing}
						setListing={setListing}
						onSave={update}
						disabled={updateLoading}
						findMode={props.findMode}
					></ListingAdvanced>
				</div>


				{/* Images */}
				{!props.findMode && <ImagesCard
					loading={loading}
					error={error || updateError}
					entity={listing}
					setEntity={setListing}
					onSave={update}
					defaultImageType={2}
					private={false}
					page="Listings"
				></ImagesCard> }

				<AboutCard
					loading={loading}
					disabled={updateLoading}
					error={error || updateError}
					entity={listing}
					setEntity={setListing}
					onSave={update}
					findMode={props.findMode}
				></AboutCard>

				{!props.findMode && <ObjectTags
					loading={loading}
					error={error || updateError}
					entity={listing}
					setEntity={setListing}
					tagState={props.tagState}
					setTagState={props.setTagState}
					newTagModal={props.newTagModal}
					setNewTagModal={props.setNewTagModal}
					setQVOverride={ props.setQVOverride }
					entityType="Listing"
					tagPermission={permissions.LISTINGS_TAGS}
				></ObjectTags>}
			</div>
		</section>
	)
}

export default ListingDetailPage
