import React from 'react'
import { GET_DEAL_DATALOG } from '../Queries'
import DataLog from '../../DataLog/DataLog'

export default function DealDataLog(props) {

	return (!props.findMode && props.id > 0) && (
		<DataLog
			query={GET_DEAL_DATALOG}
			id_field={"deal_id"}
			queryName={"getDealDataLog"}
			parentQueryName={"getDeal"}
			{...props}
		/>
	)
}
