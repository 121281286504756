/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useRef } from 'react'
import { GET_ACTIVE_DEALS } from './../Queries'
import Deals from './Deals'
import HomePageCard from '../HomePageCard'
import { withApollo } from '@apollo/client/react/hoc'
import { permissions, permissionValues } from '../../constants/permissions'
import { ITEMS_PER_HOME_PAGE_WIDGET } from '../../constants/values'
import { useNavigate, useLocation } from 'react-router-dom'
import useNavigation from '../../navigation/useNavigation'
import { GET_ACTIVE_DEALS_NAV } from '../../navigation/Queries'

const DealsParent = (props) => {

	// like the "active" - but we're using it inside a callback instead of a effect...
	const mounted = useRef(false)
	useEffect(() => {
		mounted.current = true 
		return () => mounted.current = false
	}, [])


	const { push: pushNav } = useNavigation()
	const navigate = useNavigate()
	const location = useLocation()
	let prevSearch = location.state
	if (prevSearch?.card != 'My Active Deals') {
		prevSearch = null
	}

	// Order of table
	const [cursor, setCursor] = React.useState(prevSearch?.cursor ?? null)
	const [steps, setSteps] = React.useState(prevSearch?.steps ?? 1)
	const [activeStep, setActiveStep] = React.useState(prevSearch?.activeStep ?? 0)
	const [searchLoading, setSearchLoading] = React.useState(false)
	const [totalItems, setTotalItems] = React.useState(prevSearch?.totalItems ?? 0)

	const [deals, setDeals] = React.useState(prevSearch?.deals ?? [])

	const user = props.authState?.user
	const userPermissions = user?.permissions

	const handleSubmit = useCallback((cursor = 0) => {

		// If the user id falsy, don't execute the query yet
		if (!props.authState?.user?.id) return 

		setSearchLoading(true)

		const variables = {
			id: props.authState.user.id,
			cursor,
			limit: ITEMS_PER_HOME_PAGE_WIDGET,
			field: 'start_at',
			direction: 'ASC',
		}

		const dealPermission = userPermissions && userPermissions.find((element) => element.permission_id == permissions.DEALS)
		const artPermission = userPermissions && userPermissions.find((element) => element.permission_id == permissions.ART)

		// Check if current user has valid permissions for viewing global reserves
		if (dealPermission?.permission_value_id >= permissionValues.VIEW_ONLY && 
			artPermission?.permission_value_id >= permissionValues.VIEW_ONLY) {
			props.client
				.query({
					query: GET_ACTIVE_DEALS,
					variables
				})
				.then(result => {
					if (!mounted.current) return	
					setDeals(result.data.getMyActiveDeals?.items)
					setCursor(result.data.getMyActiveDeals?.cursor)
					setTotalItems(result.data.getMyActiveDeals?.totalItems)
					
					if (result.data.getMyActiveDeals.items.length < 1) setSteps(1)
					else setSteps(Math.ceil(result.data.getMyActiveDeals.totalItems / ITEMS_PER_HOME_PAGE_WIDGET))

					setSearchLoading(false)

				})
				.catch(error => {
					console.log(error)
				})
		} else {
			setDeals([])
			setCursor(null)
			setSteps(1)
			setSearchLoading(false)
		}
	}, [props.authState, props.client, userPermissions])


	function saveAndGo(path, row) {
		const state = updateHistory(row)
		pushNav({ 
			url: location.pathname, 
			state,
			query: GET_ACTIVE_DEALS_NAV,
			getId: (r => path.includes('art') 
				? r?.art?.id 
				: r?.deal?.id || r?.deal_id
			)
		}, state.variables.cursor)
		navigate(path, { state })
	}
	// Save Search
	function updateHistory (row) {
		const savedSearch = {
			cursor, deals,
			steps, activeStep, totalItems,
			searchPage: location.pathname,
			card: 'My Active Deals'
		}
		const offset = deals.indexOf(row)
		const nextcursor = activeStep * ITEMS_PER_HOME_PAGE_WIDGET + offset
		const variables = {
			id: props.id,
			cursor: nextcursor,
			limit: 3,
		}
		savedSearch.variables = variables
		navigate(location.pathname, { state: savedSearch })
		return savedSearch
	}

	// Search on page load
	useEffect(() => {
		handleSubmit(cursor)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<HomePageCard
			title="My Active Deals"
			paginate
			handleSubmit={handleSubmit}
			cursor={cursor}
			setCursor={setCursor}
			steps={steps}
			setSteps={setSteps}
			activeStep={activeStep}
			setActiveStep={setActiveStep}
			totalItems={totalItems}
		>
			<Deals
				setCurrentDeals={setDeals}
				currentDeals={deals}
				loading={searchLoading || props.userLoading || !props.authState?.user?.id}
				saveAndGo={saveAndGo}
			/>
		</HomePageCard>
	)
}

export default withApollo(DealsParent)
