/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useEffect } from 'react'
import ListingQuickView from '../../Listings/Listing/ListingQuickView'
import ContactQuickView from '../../Contacts/ContactQuickView'
import ArtQuickView from '../../Art/ArtPiece/ArtQuickView'
import ArtistQuickView from '../../Artists/Artist/ArtistQuickView'
import DealQuickView from '../../Deals/DealQuickView'
import UserAccessQuickView from '../../QuickView/UserAccessQuickView'
import TagQuickView from '../TagQuickView'
import { ArtDataGrid } from '../../Art/ArtDataGrid'
import useNavigation from '../../navigation/useNavigation'
import { DYNAMIC_ARTISTS_SEARCH, DYNAMIC_ART_SEARCH, DYNAMIC_CONTACT_SEARCH, DYNAMIC_DEAL_SEARCH, DYNAMIC_LISTING_SEARCH } from '../../Search/Queries'
import { ART_SEARCH_OBJECT } from '../../constants/values'
import { AuthStateContext, DispatchContext } from '../../store'
import { SavedSearch } from '../../common/SavedSearch'
import { filterMap } from '../../Search/unifiedSearchHelpers'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { withApollo } from '@apollo/client/react/hoc'
import { useLocation, useNavigate } from 'react-router-dom'
import { DYNAMIC_ART_NAV, DYNAMIC_CONTACT_NAV, DYNAMIC_DEAL_NAV, DYNAMIC_LISTING_NAV } from '../../navigation/Queries'
import { ArtistsDataGrid } from '../../Artists/ArtistsDataGrid'
import { ContactDataGrid } from '../../Contacts/ContactDataGrid'
import { DealsDataGrid } from '../../Deals/DealsDataGrid'
import { ListingsDataGrid } from '../../Listings/ListingDataGrid'
import { getPermissionCanSee } from '../TagQuickView'
import { permissions } from '../../constants/permissions'

function TagDetail(props) {
	// Hooks
	const { push: pushNav } = useNavigation()
	const navigate = useNavigate()
	const location = useLocation()
	const userAuthentication = useContext(AuthStateContext)
	const userPermissions  = userAuthentication?.user?.permissions


	const canSeeContacts= getPermissionCanSee(userPermissions, permissions.CONTACTS)
	const canSeeArt = getPermissionCanSee(userPermissions, permissions.ART)
	const canSeeArtists = getPermissionCanSee(userPermissions, permissions.ARTISTS)
	const canSeeDeals = getPermissionCanSee(userPermissions, permissions.DEALS)
	const canSeeListings = getPermissionCanSee(userPermissions, permissions.LISTINGS)
	const artTagPermission = getPermissionCanSee(userPermissions, permissions.ART_TAGS)
	const artistTagPermission = getPermissionCanSee(userPermissions, permissions.ARTISTS_TAGS)
	const contactTagPermission = getPermissionCanSee(userPermissions, permissions.CONTACTS_TAGS)
	const dealTagPermission = getPermissionCanSee(userPermissions, permissions.DEALS_TAGS)
	const listingTagPermission = getPermissionCanSee(userPermissions, permissions.LISTINGS_TAGS)


	// Common State
	const [selectedRow, setSelectedRow] = React.useState(null)
	const [privateAccess] = React.useState(false)
	const [firstRender, setFirstRender] = React.useState(true)

	/**
	 * Save current page state for if one returns to it through
	 * back arrow.
	 *
	 * @returns current page state
	 */
	function updateHistory(index, type) {
		const currentPageState = {
			// art
			currentArtSearch,
			artOrderBy,
			artOrder,
			art,
			artTotalItems,
			artLimit,
			artSteps,
			artActiveStep,

			// artists
			currentArtistsSearch,
			artistsOrderBy,
			artistsOrder,
			artists,
			artistsTotalItems,
			artistsLimit,
			artistsSteps,
			artistsActiveStep,

			// contacts
			currentContactsSearch,
			contactsOrderBy,
			contactsOrder,
			contacts,
			contactsTotalItems,
			contactsLimit,
			contactsSteps,
			contactsActiveStep,

			// deals
			currentDealsSearch,
			dealsOrderBy,
			dealsOrder,
			deals,
			dealsTotalItems,
			dealsLimit,
			dealsSteps,
			dealsActiveStep,

			// listings
			currentListingsSearch,
			listingsOrderBy,
			listingsOrder,
			listings,
			listingsTotalItems,
			listingsLimit,
			listingsSteps,
			listingsActiveStep,

			searchPage: location.pathname,
		}

		const artOffset = index ? art.findIndex((a) => a.id == index) : art.indexOf(selectedRow)
		const artCursor = artActiveStep * artLimit + artOffset
		const artVariables = makeArtVariables(artCursor, currentArtSearch, artOrderBy, artOrder, currentArtSearch?.search_terms ?? [])

		const artistsOffset = index ? artists.findIndex((a) => a.id == index) : artists.indexOf(selectedRow)
		const artistsCursor = artistsActiveStep * artistsLimit + artistsOffset
		const artistsVariables = makeArtistsVariables(artistsCursor, currentArtistsSearch, artistsOrderBy, artistsOrder, currentArtistsSearch?.search_terms ?? [])

		const contactsOffset = index ? contacts.findIndex((a) => a.id == index) : contacts.indexOf(selectedRow)
		const contactsCursor = contactsActiveStep * contactsLimit + contactsOffset
		const contactsVariables = makeContactsVariables(contactsCursor, currentContactsSearch, contactsOrderBy, contactsOrder, currentContactsSearch?.search_terms ?? [])

		const dealsOffset = index ? deals.findIndex((a) => a.id == index) : deals.indexOf(selectedRow)
		const dealsCursor = dealsActiveStep * dealsLimit + dealsOffset
		const dealsVariables = makeDealsVariables(dealsCursor, currentDealsSearch, dealsOrderBy, dealsOrder, currentDealsSearch?.search_terms ?? [])

		const listingsOffset = index ? listings.findIndex((a) => a.id == index) : listings.indexOf(selectedRow)
		const listingsCursor = listingsActiveStep * listingsLimit + listingsOffset
		const listingsVariables = makeListingsVariables(listingsCursor, currentListingsSearch, listingsOrderBy, listingsOrder, currentListingsSearch?.search_terms ?? [])

		if (type == 'art') currentPageState.variables = artVariables
		if (type == 'artist') currentPageState.variables = artistsVariables
		if (type == 'contact') currentPageState.variables = contactsVariables
		if (type == 'deal') currentPageState.variables = dealsVariables
		if (type == 'listing') currentPageState.variables = listingsVariables

		artVariables.limit = 3
		artistsVariables.limit = 3
		contactsVariables.limit = 3
		dealsVariables.limit = 3
		listingsVariables.limit = 3

		navigate(location.pathname, { state: currentPageState })
		return currentPageState
	}

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	// Art
	const [art, setArt] = React.useState([])
	const [artLoading, setArtLoading] = React.useState(false)
	const [artOrder, setArtOrder] = React.useState('asc')
	const [artOrderBy, setArtOrderBy] = React.useState(null)
	const [artActiveStep, setArtActiveStep] = React.useState(0)
	const [artTotalItems, setArtTotalItems] = React.useState(null)
	const [artSteps, setArtSteps] = React.useState(1)
	const [currentArtSearch, setCurrentArtSearch] = React.useState(
		new SavedSearch({
			object_id: ART_SEARCH_OBJECT,
			search_terms: [],
			is_global: true,
			user_id: userAuthentication.user?.id,
		})
	)
	const [artLimit, setArtLimit] = React.useState(10)

	const makeArtVariables = (cursor, currentSearch, orderBy, order, filters, limitOverride) => {
		return {
			cursor,
			limit: limitOverride ?? artLimit,
			field: orderBy,
			direction: order.toUpperCase(),
			filters: filters ? filterMap(filters) : filterMap(currentSearch.search_terms),
			thumbnailResolution: '128x128',
		}
	}

	const setArtFilters = (filters) => {
		setCurrentArtSearch({
			...currentArtSearch,
			search_terms: filters,
		})
	}

	function saveArtAndGo(path, row) {
		// persist current page state in history
		const state = updateHistory(row, 'art')
		pushNav(
			{
				url: location.pathname,
				state,
				query: DYNAMIC_ART_NAV,
			},
			state.variables?.cursor
		)
		// navigate to new page (with this page's query and `variables`)
		navigate(path, { state })
	}

	const handleArtSubmit = ({ cursor } = {}, filters = null, searchId) => {
		if (!artTagPermission) return
		setArtLoading(true)
		let submitCursor
		if (cursor == undefined) {
			submitCursor = artActiveStep * artLimit
		} else {
			submitCursor = cursor
		}

		const variables = makeArtVariables(submitCursor, currentArtSearch, artOrderBy, artOrder, filters)

		// Set filters when handle submit is not coming from saved search list
		if (filters?.length && !searchId) setArtFilters(filters)

		props.client
			.query({ query: DYNAMIC_ART_SEARCH, variables })
			.then((result) => {
				const { data } = result

				if (data.searchDynamicArt?.items) {
					setArt(data.searchDynamicArt.items)

					if (data.searchDynamicArt.items < 1) setArtSteps(1)
					else setArtSteps(Math.ceil(data.searchDynamicArt.totalItems / artLimit))

					setArtTotalItems(data.searchDynamicArt.totalItems || 0)
					setArtLoading(false)
				} else {
					console.error(data)
					setArtLoading(false)
					openSnackbar(severity.ERROR, 'There was an error searching art.')
				}
			})
			.catch((error) => {
				console.error(error)
				setArtLoading(false)
				openSnackbar(severity.ERROR, 'Could not search art.')
			})
	}

	// Artists
	const [artists, setArtists] = React.useState([])
	const [artistsLoading, setArtistsLoading] = React.useState(false)
	const [artistsOrder, setArtistsOrder] = React.useState('asc')
	const [artistsOrderBy, setArtistsOrderBy] = React.useState(null)
	const [artistsActiveStep, setArtistsActiveStep] = React.useState(0)
	const [artistsTotalItems, setArtistsTotalItems] = React.useState(null)
	const [artistsSteps, setArtistsSteps] = React.useState(1)
	const [currentArtistsSearch, setCurrentArtistsSearch] = React.useState(
		new SavedSearch({
			object_id: ART_SEARCH_OBJECT,
			search_terms: [],
			is_global: true,
			user_id: userAuthentication.user?.id,
		})
	)
	const [artistsLimit, setArtistsLimit] = React.useState(10)

	const makeArtistsVariables = (cursor, currentSearch, orderBy, order, filters, limitOverride) => {
		return {
			cursor,
			limit: limitOverride ?? artistsLimit,
			field: orderBy,
			direction: order.toUpperCase(),
			filters: filters ? filterMap(filters) : filterMap(currentSearch.search_terms),
			thumbnailResolution: '128x128',
		}
	}

	const setArtistsFilters = (filters) => {
		setCurrentArtistsSearch({
			...currentArtistsSearch,
			search_terms: filters,
		})
	}

	function saveArtistsAndGo(path, row) {
		// persist current page state in history
		const state = updateHistory(row, 'artist')
		pushNav(
			{
				url: location.pathname,
				state,
				query: DYNAMIC_ARTISTS_SEARCH,
			},
			state.variables?.cursor
		)
		// navigate to new page (with this page's query and `variables`)
		navigate(path, { state })
	}

	const handleArtistsSubmit = ({ cursor } = {}, filters = null, searchId) => {
		if (!artistTagPermission) return
		setArtistsLoading(true)
		let submitCursor
		if (cursor == undefined) {
			submitCursor = artistsActiveStep * artistsLimit
		} else {
			submitCursor = cursor
		}

		const variables = makeArtistsVariables(submitCursor, currentArtistsSearch, artistsOrderBy, artistsOrder, filters)

		// Set filters when handle submit is not coming from saved search list
		if (filters?.length && !searchId) setArtistsFilters(filters)

		props.client
			.query({ query: DYNAMIC_ARTISTS_SEARCH, variables })
			.then((result) => {
				const { data } = result

				if (data.searchDynamicArtists?.items) {
					setArtists(data.searchDynamicArtists.items)

					if (data.searchDynamicArtists.items < 1) setArtistsSteps(1)
					else setArtistsSteps(Math.ceil(data.searchDynamicArtists.totalItems / artistsLimit))

					setArtistsTotalItems(data.searchDynamicArtists.totalItems || 0)
					setArtistsLoading(false)
				} else {
					console.error(data)
					setArtistsLoading(false)
					openSnackbar(severity.ERROR, 'There was an error searching artists.')
				}
			})
			.catch((error) => {
				console.error(error)
				setArtistsLoading(false)
				openSnackbar(severity.ERROR, 'Could not search artists.')
			})
	}

	// Contacts
	const [contacts, setContacts] = React.useState([])
	const [contactsLoading, setContactsLoading] = React.useState(false)
	const [contactsOrder, setContactsOrder] = React.useState('asc')
	const [contactsOrderBy, setContactsOrderBy] = React.useState(null)
	const [contactsActiveStep, setContactsActiveStep] = React.useState(0)
	const [contactsTotalItems, setContactsTotalItems] = React.useState(null)
	const [contactsSteps, setContactsSteps] = React.useState(1)
	const [currentContactsSearch, setCurrentContactsSearch] = React.useState(
		new SavedSearch({
			object_id: ART_SEARCH_OBJECT,
			search_terms: [],
			is_global: true,
			user_id: userAuthentication.user?.id,
		})
	)
	const [contactsLimit, setContactsLimit] = React.useState(10)

	const makeContactsVariables = (cursor, currentSearch, orderBy, order, filters, limitOverride) => {
		return {
			cursor,
			limit: limitOverride ?? contactsLimit,
			field: orderBy,
			direction: order.toUpperCase(),
			filters: filters ? filterMap(filters) : filterMap(currentSearch.search_terms),
			thumbnailResolution: '128x128',
		}
	}

	const setContactsFilters = (filters) => {
		setCurrentContactsSearch({
			...currentContactsSearch,
			search_terms: filters,
		})
	}

	function saveContactsAndGo(path, row) {
		// persist current page state in history
		const state = updateHistory(row, 'contact')
		pushNav(
			{
				url: location.pathname,
				state,
				query: DYNAMIC_CONTACT_NAV,
			},
			state.variables?.cursor
		)
		// navigate to new page (with this page's query and `variables`)
		navigate(path, { state })
	}

	const handleContactsSubmit = ({ cursor } = {}, filters = null, searchId) => {
		if (!contactTagPermission) return
		setContactsLoading(true)
		let submitCursor
		if (cursor == undefined) {
			submitCursor = contactsActiveStep * contactsLimit
		} else {
			submitCursor = cursor
		}

		const variables = makeContactsVariables(submitCursor, currentContactsSearch, contactsOrderBy, contactsOrder, filters)

		// Set filters when handle submit is not coming from saved search list
		if (filters?.length && !searchId) setContactsFilters(filters)

		props.client
			.query({ query: DYNAMIC_CONTACT_SEARCH, variables })
			.then((result) => {
				const { data } = result

				if (data.searchDynamicContacts?.items) {
					setContacts(data.searchDynamicContacts.items)

					if (data.searchDynamicContacts.items < 1) setContactsSteps(1)
					else setContactsSteps(Math.ceil(data.searchDynamicContacts.totalItems / contactsLimit))

					setContactsTotalItems(data.searchDynamicContacts.totalItems || 0)
					setContactsLoading(false)
				} else {
					console.error(data)
					setContactsLoading(false)
					openSnackbar(severity.ERROR, 'There was an error searching contacts.')
				}
			})
			.catch((error) => {
				console.error(error)
				setContactsLoading(false)
				openSnackbar(severity.ERROR, 'Could not search contacts.')
			})
	}

	// Deals
	const [deals, setDeals] = React.useState([])
	const [dealsLoading, setDealsLoading] = React.useState(false)
	const [dealsOrder, setDealsOrder] = React.useState('asc')
	const [dealsOrderBy, setDealsOrderBy] = React.useState(null)
	const [dealsActiveStep, setDealsActiveStep] = React.useState(0)
	const [dealsTotalItems, setDealsTotalItems] = React.useState(null)
	const [dealsSteps, setDealsSteps] = React.useState(1)
	const [currentDealsSearch, setCurrentDealsSearch] = React.useState(
		new SavedSearch({
			object_id: ART_SEARCH_OBJECT,
			search_terms: [],
			is_global: true,
			user_id: userAuthentication.user?.id,
		})
	)
	const [dealsLimit, setDealsLimit] = React.useState(10)

	const makeDealsVariables = (cursor, currentSearch, orderBy, order, filters, limitOverride) => {
		return {
			cursor,
			limit: limitOverride ?? dealsLimit,
			field: orderBy,
			direction: order.toUpperCase(),
			filters: filters ? filterMap(filters) : filterMap(currentSearch.search_terms),
			thumbnailResolution: '128x128',
		}
	}

	const setDealsFilters = (filters) => {
		setCurrentDealsSearch({
			...currentDealsSearch,
			search_terms: filters,
		})
	}

	function saveDealsAndGo(path, row) {
		// persist current page state in history
		const state = updateHistory(row, 'deal')
		pushNav(
			{
				url: location.pathname,
				state,
				query: DYNAMIC_DEAL_NAV,
			},
			state.variables?.cursor
		)
		// navigate to new page (with this page's query and `variables`)
		navigate(path, { state })
	}

	const handleDealsSubmit = ({ cursor } = {}, filters = null, searchId) => {
		if (!dealTagPermission) return
		setDealsLoading(true)
		let submitCursor
		if (cursor == undefined) {
			submitCursor = dealsActiveStep * dealsLimit
		} else {
			submitCursor = cursor
		}

		const variables = makeDealsVariables(submitCursor, currentDealsSearch, dealsOrderBy, dealsOrder, filters)

		// Set filters when handle submit is not coming from saved search list
		if (filters?.length && !searchId) setDealsFilters(filters)

		props.client
			.query({ query: DYNAMIC_DEAL_SEARCH, variables })
			.then((result) => {
				const { data } = result

				if (data.searchDynamicDeals?.items) {
					setDeals(data.searchDynamicDeals.items)

					if (data.searchDynamicDeals.items < 1) setDealsSteps(1)
					else setDealsSteps(Math.ceil(data.searchDynamicDeals.totalItems / dealsLimit))

					setDealsTotalItems(data.searchDynamicDeals.totalItems || 0)
					setDealsLoading(false)
				} else {
					console.error(data)
					setDealsLoading(false)
					openSnackbar(severity.ERROR, 'There was an error searching deals.')
				}
			})
			.catch((error) => {
				console.error(error)
				setDealsLoading(false)
				openSnackbar(severity.ERROR, 'Could not search deals.')
			})
	}

	// Listing
	const [listings, setListings] = React.useState([])
	const [listingsLoading, setListingsLoading] = React.useState(false)
	const [listingsOrder, setListingsOrder] = React.useState('asc')
	const [listingsOrderBy, setListingsOrderBy] = React.useState(null)
	const [listingsActiveStep, setListingsActiveStep] = React.useState(0)
	const [listingsTotalItems, setListingsTotalItems] = React.useState(null)
	const [listingsSteps, setListingsSteps] = React.useState(1)
	const [currentListingsSearch, setCurrentListingsSearch] = React.useState(
		new SavedSearch({
			object_id: ART_SEARCH_OBJECT,
			search_terms: [],
			is_global: true,
			user_id: userAuthentication.user?.id,
		})
	)
	const [listingsLimit, setListingsLimit] = React.useState(10)

	const makeListingsVariables = (cursor, currentSearch, orderBy, order, filters, limitOverride) => {
		return {
			cursor,
			limit: limitOverride ?? listingsLimit,
			field: orderBy,
			direction: order.toUpperCase(),
			filters: filters ? filterMap(filters) : filterMap(currentSearch.search_terms),
			thumbnailResolution: '128x128',
		}
	}

	const setListingsFilters = (filters) => {
		setCurrentListingsSearch({
			...currentListingsSearch,
			search_terms: filters,
		})
	}

	function saveListingsAndGo(path, row) {
		// persist current page state in history
		const state = updateHistory(row, 'listing')
		pushNav(
			{
				url: location.pathname,
				state,
				query: DYNAMIC_LISTING_NAV,
			},
			state.variables?.cursor
		)
		// navigate to new page (with this page's query and `variables`)
		navigate(path, { state })
	}

	const handleListingsSubmit = ({ cursor } = {}, filters = null, searchId) => {
		if (!listingTagPermission) return
		setListingsLoading(true)
		let submitCursor
		if (cursor == undefined) {
			submitCursor = listingsActiveStep * listingsLimit
		} else {
			submitCursor = cursor
		}

		const variables = makeListingsVariables(submitCursor, currentListingsSearch, listingsOrderBy, listingsOrder, filters)

		// Set filters when handle submit is not coming from saved search list
		if (filters?.length && !searchId) setListingsFilters(filters)

		props.client
			.query({ query: DYNAMIC_LISTING_SEARCH, variables })
			.then((result) => {
				const { data } = result

				if (data.searchDynamicListings?.items) {
					setListings(data.searchDynamicListings.items)

					if (data.searchDynamicListings.items < 1) setListingsSteps(1)
					else setListingsSteps(Math.ceil(data.searchDynamicListings.totalItems / listingsLimit))

					setListingsTotalItems(data.searchDynamicListings.totalItems || 0)
					setListingsLoading(false)
				} else {
					console.error(data)
					setListingsLoading(false)
					openSnackbar(severity.ERROR, 'There was an error searching listings.')
				}
			})
			.catch((error) => {
				console.error(error)
				setListingsLoading(false)
				openSnackbar(severity.ERROR, 'Could not search listings.')
			})
	}

	/*
		On Load, search for all entities
	*/

	// Search Art
	useEffect(() => {
		if (props.state?.getTag?.art?.length) {
			const tagId = props.state?.getTag.id

			const search_terms = [
				{
					id: new Date().getTime(),
					field: 'tag_id',
					type: 'eq',
					value: tagId,
				},
			]

			handleArtSubmit({ cursor: 0 }, search_terms)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.state?.getTag])

	// Search Artists
	useEffect(() => {
		if (props.state?.getTag?.artist?.length) {
			const tagId = props.state?.getTag.id

			const search_terms = [
				{
					id: new Date().getTime(),
					field: 'tag_id',
					type: 'eq',
					value: tagId,
				},
			]

			handleArtistsSubmit({ cursor: 0 }, search_terms)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.state?.getTag])

	// Search Contacts
	useEffect(() => {
		if (props.state?.getTag?.contact?.length) {
			const tagId = props.state?.getTag.id

			const search_terms = [
				{
					id: new Date().getTime(),
					field: 'tag_id',
					type: 'eq',
					value: tagId,
				},
			]

			handleContactsSubmit({ cursor: 0 }, search_terms)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.state?.getTag])

	// Search Deals
	useEffect(() => {
		if (props.state?.getTag?.deal?.length) {
			const tagId = props.state?.getTag.id

			const search_terms = [
				{
					id: new Date().getTime(),
					field: 'tag_id',
					type: 'eq',
					value: tagId,
				},
			]

			handleDealsSubmit({ cursor: 0 }, search_terms)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.state?.getTag])

	// Search Listings
	useEffect(() => {
		if (props.state?.getTag?.listing?.length) {
			const tagId = props.state?.getTag.id

			const search_terms = [
				{
					id: new Date().getTime(),
					field: 'tag_id',
					type: 'eq',
					value: tagId,
				},
			]

			handleListingsSubmit({ cursor: 0 }, search_terms)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.state?.getTag])

	/*
		Effect change for sorting, pagination, etc
	*/

	// Art
	React.useEffect(() => {
		setFirstRender(false)
		if (art && art.length !== 0 && !firstRender) {
			handleArtSubmit()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [artOrder, artOrderBy, artActiveStep, artLimit])

	// Artists
	React.useEffect(() => {
		setFirstRender(false)
		if (artists && artists.length !== 0 && !firstRender) {
			handleArtistsSubmit()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [artistsOrder, artistsOrderBy, artistsActiveStep, artistsLimit])

	// Contacts
	React.useEffect(() => {
		setFirstRender(false)
		if (contacts && contacts.length !== 0 && !firstRender) {
			handleContactsSubmit()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contactsOrder, contactsOrderBy, contactsActiveStep, contactsLimit])

	// Deals
	React.useEffect(() => {
		setFirstRender(false)
		if (deals && deals.length !== 0 && !firstRender) {
			handleDealsSubmit()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dealsOrder, dealsOrderBy, dealsActiveStep, dealsLimit])

	// Listings
	React.useEffect(() => {
		setFirstRender(false)
		if (listings && listings.length !== 0 && !firstRender) {
			handleListingsSubmit()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listingsOrder, listingsOrderBy, listingsActiveStep, listingsLimit])

	useEffect(() => {
		if (privateAccess) {
			setSelectedRow(null)
			props.setQVOverride(() => <UserAccessQuickView entity={privateAccess}></UserAccessQuickView>)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [privateAccess])

	const isTypeName = (row, type) => row && type && row.__typename === type

	useEffect(() => {
		// Getting the typename of the query, then validating what QV need to show on selectedRow.
		if (!selectedRow) {
			props.setQVOverride(() => <TagQuickView tag={props.id} parentState={props.state} />)
		} else if (selectedRow.is_private && !selectedRow.created_at) {
			props.setQVOverride(() => <UserAccessQuickView entity={selectedRow} onClose={() => setSelectedRow(null)} />)
		} else if (isTypeName(selectedRow, 'Contact')) {
			props.setQVOverride(() => <ContactQuickView id={selectedRow.id} onClose={() => setSelectedRow(null)} />)
		} else if (isTypeName(selectedRow, 'Listing')) {
			props.setQVOverride(() => <ListingQuickView id={selectedRow.id} onClose={() => setSelectedRow(null)} />)
		} else if (isTypeName(selectedRow, 'Art')) {
			props.setQVOverride(() => <ArtQuickView id={selectedRow.id} onClose={() => setSelectedRow(null)} />)
		} else if (isTypeName(selectedRow, 'Artist')) {
			props.setQVOverride(() => <ArtistQuickView id={selectedRow.id} onClose={() => setSelectedRow(null)} />)
		} else if (isTypeName(selectedRow, 'Deal')) {
			props.setQVOverride(() => <DealQuickView id={selectedRow.id} onClose={() => setSelectedRow(null)} />)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRow, props.state])

	return (
		<div
			style={{
				margin: '1em 1em 0 0',
			}}
		>
			{props.state?.getTag?.art?.length && canSeeArt ? (
				<ArtDataGrid
					disableFlagMenu
					alwaysAutoHeight
					listHeader="Art"
					rows={art}
					loading={artLoading}
					saveAndGo={saveArtAndGo}
					setRows={setArt}
					userId={userAuthentication.user?.id}
					totalItems={artTotalItems}
					onSelect={setSelectedRow}
					selectedRow={isTypeName(selectedRow, 'Art') ? selectedRow : null}
					limit={artLimit}
					setLimit={setArtLimit}
					activeStep={artActiveStep}
					setActiveStep={setArtActiveStep}
					sortModel={artOrderBy ? [{ field: artOrderBy, sort: artOrder }] : []}
					onSortModelChange={(newSort) => {
						if (newSort.length) {
							setArtOrderBy(newSort[0].field)
							setArtOrder(newSort[0].sort)
						} else {
							setArtOrderBy(null)
							setArtOrder('asc')
						}
					}}
					// flagmenu
					filterTo={(newFilters) => {
						setSelectedRow(null)
					}}
					type='Art'
					variables={null}
				/>
			) : null}
			{props.state?.getTag?.contact?.length && canSeeContacts ? (
				<ContactDataGrid
					disableFlagMenu
					alwaysAutoHeight
					listHeader="Contacts"
					rows={contacts}
					loading={contactsLoading}
					saveAndGo={saveContactsAndGo}
					setRows={setContacts}
					userId={userAuthentication.user?.id}
					totalItems={contactsTotalItems}
					onSelect={setSelectedRow}
					selectedRow={isTypeName(selectedRow, 'Contact') ? selectedRow : null}
					limit={contactsLimit}
					setLimit={setContactsLimit}
					activeStep={contactsActiveStep}
					setActiveStep={setContactsActiveStep}
					sortModel={contactsOrderBy ? [{ field: contactsOrderBy, sort: contactsOrder }] : []}
					onSortModelChange={(newSort) => {
						if (newSort.length) {
							setContactsOrderBy(newSort[0].field)
							setContactsOrder(newSort[0].sort)
						} else {
							setContactsOrderBy(null)
							setContactsOrder('asc')
						}
					}}
					// flagmenu
					filterTo={(newFilters) => {
						setSelectedRow(null)
					}}
					type='Contact'
					variables={null}
				/>
			) : null}

			{props.state?.getTag?.listing?.length && canSeeListings ? (
				<ListingsDataGrid
					disableFlagMenu
					alwaysAutoHeight
					listHeader="Listings"
					rows={listings}
					loading={listingsLoading}
					saveAndGo={saveListingsAndGo}
					setRows={setListings}
					userId={userAuthentication.user?.id}
					totalItems={listingsTotalItems}
					onSelect={setSelectedRow}
					selectedRow={isTypeName(selectedRow, 'Listing') ? selectedRow : null}
					limit={listingsLimit}
					setLimit={setListingsLimit}
					activeStep={listingsActiveStep}
					setActiveStep={setListingsActiveStep}
					sortModel={listingsOrderBy ? [{ field: listingsOrderBy, sort: listingsOrder }] : []}
					onSortModelChange={(newSort) => {
						if (newSort.length) {
							setListingsOrderBy(newSort[0].field)
							setListingsOrder(newSort[0].sort)
						} else {
							setListingsOrderBy(null)
							setListingsOrder('asc')
						}
					}}
					// flagmenu
					filterTo={(newFilters) => {
						setSelectedRow(null)
					}}
					type='Artist'
					variables={null}
				/>
			) : null}

			{props.state?.getTag?.artist?.length && canSeeArtists ? (
				<ArtistsDataGrid
					disableFlagMenu
					alwaysAutoHeight
					listHeader="Artists"
					rows={artists}
					loading={artistsLoading}
					saveAndGo={saveArtistsAndGo}
					setRows={setArtists}
					userId={userAuthentication.user?.id}
					totalItems={artistsTotalItems}
					onSelect={setSelectedRow}
					selectedRow={isTypeName(selectedRow, 'Artist') ? selectedRow : null}
					limit={artistsLimit}
					setLimit={setArtistsLimit}
					activeStep={artistsActiveStep}
					setActiveStep={setArtistsActiveStep}
					sortModel={artistsOrderBy ? [{ field: artistsOrderBy, sort: artistsOrder }] : []}
					onSortModelChange={(newSort) => {
						if (newSort.length) {
							setArtistsOrderBy(newSort[0].field)
							setArtistsOrder(newSort[0].sort)
						} else {
							setArtistsOrderBy(null)
							setArtistsOrder('asc')
						}
					}}
					// flagmenu
					filterTo={(newFilters) => {
						setSelectedRow(null)
					}}
					type='Artist'
					variables={null}
				/>
			) : null}

			{props.state?.getTag?.deal?.length && canSeeDeals ? (
				<DealsDataGrid
					disableFlagMenu
					alwaysAutoHeight
					listHeader="Deals"
					rows={deals}
					loading={dealsLoading}
					saveAndGo={saveDealsAndGo}
					setRows={setDeals}
					userId={userAuthentication.user?.id}
					totalItems={dealsTotalItems}
					onSelect={setSelectedRow}
					selectedRow={isTypeName(selectedRow, 'Deal') ? selectedRow : null}
					limit={dealsLimit}
					setLimit={setDealsLimit}
					activeStep={dealsActiveStep}
					setActiveStep={setDealsActiveStep}
					sortModel={dealsOrderBy ? [{ field: dealsOrderBy, sort: dealsOrder }] : []}
					onSortModelChange={(newSort) => {
						if (newSort.length) {
							setDealsOrderBy(newSort[0].field)
							setDealsOrder(newSort[0].sort)
						} else {
							setDealsOrderBy(null)
							setDealsOrder('asc')
						}
					}}
					// flagmenu
					filterTo={(newFilters) => {
						setSelectedRow(null)
					}}
					type='Deal'
					variables={null}
				/>
			) : null}
		</div>
	)
}

export default withApollo(TagDetail)
