import React from 'react'
import { FormControl, Select, MenuItem, Tooltip } from '@mui/material'
import TextBox from '../../input/Text/TextBox'
import Dropdown from '../../input/Dropdown/Dropdown'
import NumberFormat from 'react-number-format'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import Label from '../../input/Label'

function OfferPrice (props) {
	const testidPrefix = !(props.testidPrefix===undefined) ? `${props.testidPrefix}-` : ""
	const testidOrdinal = !(props.testidOrdinal===undefined) ? `-${props.testidOrdinal}` : ""
	return (
		<div style={{
			display: 'flex',
			alignItems: 'end',
			...props.style
		}}>
			<FormControl style={{
				marginRight: '1em',
			}}>
				{!props.hideLabel === true ? 
					<Label error={props.error} className="currency-label" disableAnimation shrink 
						sx={{overflow: 'visible'}}
						focused={false}>
						{props.labelOverride || 'Offer Price'}
					</Label>
					: null }

				<Select
					error={props.error}
					input={<Dropdown />}
					value={props.currency}
					data-testid={`deal-artwork-offercurrency${testidOrdinal}`}
					IconComponent={
						ExpandMoreRoundedIcon
					}
					style={{
						marginTop: '1.5em',
						width: '5em',
						marginRight: '1em',
						...props.selectStyles
					}}
					onChange={e => 
						props.onChange('offer_currency', e.target.value)
					}
				>	
					{ props.currencies.map(cur => {
						return <MenuItem key={cur.id} value={cur.id} data-testid={`${testidPrefix}offercurrency${testidOrdinal}-item`}>
							<Tooltip
								title={cur.name}
								arrow
								placement="right"
								enterDelay={1000}
							>
								<span>{ cur.symbol }</span>
							</Tooltip> 
						</MenuItem>}
					)}
				</Select>
			</FormControl>
        
			<FormControl style={{
				width: '-webkit-fill-available'
			}}>
				<NumberFormat
					name="offer-price"
					customInput={TextBox}
					thousandSeparator
					data-testid={`deal-artwork-offerprice${testidOrdinal}`}
					style={{
						marginTop: '1em',
					}}
					error={props.error}
					allowNegative={false}
					value={props.price === 0 || !props.price ? null : props.price}
					placeholder="-"
					onChange={e => props.onChange(
						'offer_amount', e.target.value.replace(/,/g, '')
					)}
					allowLeadingZeros={false}
				/>
			</FormControl>
		</div>
	)

}

export { OfferPrice }
