import React from 'react'
import ArtListingsTable from '../../common/components/ArtListingsTable'

const ArtistListings = (props) => {

	let artist = props.state.getArtist || {}

	return !props.findMode && (
		<ArtListingsTable 
			artist_id={artist.id}
			setQVOverride={props.setQVOverride}
			hideAddButton={true}
			showArt
		/>
	)
}

export default ArtistListings
