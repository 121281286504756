import { createTheme } from "@mui/material/styles"
import * as Colors from "../colors/Colors"

const theme = createTheme({
	palette: {
		background: {
			default: Colors.background
		},
		primary: { main: Colors.primary },
		secondary: { main: Colors.secondary },
		error: { main: "#cc3333" },
		warning: { main: Colors.warning },
		info: { main: Colors.info },
		success: { 
			main: Colors.success,
			contrastText: "#fff",
			
		},
	},
	typography: {
		fontFamily: ["Graphik"].join(","),
		fontSize: 14,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		button: {
			textTransform: "none",
		},
	},


	// MuiDataGrid-toolbarContainer
	components: {
		MuiDataGrid: {
			styleOverrides: {
				root: {
					border: 'none'
				},
				main: {
					overflow: 'initial',
				},
				columnHeaders: {
					position: 'sticky',
					zIndex: 1,
					backgroundColor: 'white',
				},
				toolbarContainer: {
					justifyContent: 'end',
					marginRight: '1em',
					marginTop: '-3em',
				},
				virtualScroller: {
					marginTop: '0px !important',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				rounded: {
					borderRadius: "8px",
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				indicator: {
					backgroundColor: "blue",
				},
				scroller: {
					height: "100%",
				},
				flexContainer: {
					justifyContent: "center",
					minWidth: "max-content",
				},
			},
		},
		MuiBadge: {
			styleOverrides: {
				root: {
					borderRadius: "100%",
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					"&.Mui-selected": {
						color: Colors.editAdd,
					},
				},
			},
		},
		MuiSkeleton: {
			styleOverrides: {
				root: {
					backgroundColor: "#F8F8F8",
				},
				wave: {
					backgroundColor: "white",
				},
			},
		},
		MuiPopover: {
			styleOverrides: {
				root: {
					maxHeight: "35em",
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					fontFamily: "Graphik",
				},
			},
		},
		MuiButtonBase: {
			styleOverrides: {
				root: {
					fontFamily: "Graphik",
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: "normal"
				},
			},
		},
		MuiAvatarGroup: {
			styleOverrides: {
				avatar: {
					border: "none",
				},
				root: {
					justifyContent: "flex-end",
				},
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					border: "none !important",
				},
			}
		}
	},
})

export default theme
