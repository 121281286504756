import { gql } from '@apollo/client'

export const CREATE_TAG = gql`
	mutation createTag($TagInput: TagInput!) {
		createTag(input: $TagInput) {
			code
			success
			message
			tag {
				id
				label
				description
				color_hex
			}
		}
	}
`

export const UPLOAD_FILE = gql`
	mutation uploadFile($file: Upload!) {
		singleUpload(file: $file) {
			filename
			Location
			mimetype
			encoding
		}
	}
`

export const DELETE_TAG = gql`
	mutation deleteTag($id: ID) {
		deleteTag(id: $id) {
			code
			success
			message
			severity
		}
	}
`

export const UPDATE_TAG = gql`
	mutation updateEntityTag($UpdateTagInput: UpdateTagInput) {
		updateEntityTag(input: $UpdateTagInput) {
			code
			success
			message
			tag {
				id
				label
				description
				color_hex
			}
		}
	}
`

export const GET_TAG_DETAILS = gql`
	query getTag($id: ID) {
		getTag(id: $id) {
			id
			label
			description
			color_hex
			deal {
				id
			}
			artist {
				id
			}
			art {
				id
			}
			contact {
				id
			}
			listing {
				id
			}
		}
		getTagCount
	}
`

export const UPDATE_ART_TAGS = gql`
	mutation updateArtTags($EntityTagInput: EntityTagInput!) {
		updateArtTags(input: $EntityTagInput) {
			code
			success
			message
			tags {
				id
				label
				description
				color_hex
			}
		}
	}
`

export const UPDATE_ARTIST_TAGS = gql`
	mutation updateArtistTags($EntityTagInput: EntityTagInput!) {
		updateArtistTags(input: $EntityTagInput) {
			code
			success
			message
			tags {
				id
				label
				description
				color_hex
			}
		}
	}
`

export const UPDATE_CONTACT_TAGS = gql`
	mutation updateContactTags($EntityTagInput: EntityTagInput!) {
		updateContactTags(input: $EntityTagInput) {
			code
			success
			message
			tags {
				id
				label
				description
				color_hex
			}
		}
	}
`

export const UPDATE_DEAL_TAGS = gql`
	mutation updateDealTags($EntityTagInput: EntityTagInput!) {
		updateDealTags(input: $EntityTagInput) {
			code
			success
			message
			tags {
				id
				label
				description
				color_hex
			}
		}
	}
`

export const UPDATE_LISTING_TAGS = gql`
	mutation updateListingTags($EntityTagInput: EntityTagInput!) {
		updateListingTags(input: $EntityTagInput) {
			code
			success
			message
			tags {
				id
				label
				description
				color_hex
			}
		}
	}
`

export const SIMPLE_SEARCH_TAGS = gql`
	query simpleSearchTags($query: String) {
		simpleSearchTags(query: $query) {
			id
			label
			description
			color_hex
		}
	}
`

export const SEARCH_TAGS_INCL_SAME_ARTIST = gql`
	query searchTagsIncludingRelatedByArtist($id: String, $query: String) {
		simpleSearchTags(query: $query) {
			id
			label
			description
			color_hex
		}
		
		searchDynamicArt(input: {
			field: "title",
			direction: ASC,
			cursor: 0,
			limit: 25,
			filters: [
				{
					field: "artist_id",
					type: "eq"
					value: $id
				},
				{
					field: "art_tag"
					type: "$isNotNull",
					value: ""
				}
			]
		}) {
			items {
				tags {
					id
					label
					description
					color_hex
				}
			}
		}
	}
`
