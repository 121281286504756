/* eslint-disable eqeqeq */
import React, { useContext, useCallback, useEffect } from 'react'
import EnhancedTableHead from '../../table/EnhancedTableHead'
import {
	Paper,
	TableContainer,
	Table,
	TableRow,
	TableCell,
	TableBody,
	FormControl,
	Select,
	InputAdornment,
	Skeleton
} from '@mui/material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import IconButton from '@mui/material/IconButton'
import isEqual from 'lodash/isEqual'
import { useLocation, useNavigate } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import PhoneInput from 'react-phone-input-2'
import { LookupContext } from './../../store'
import { ChooseImageButton, SubmitButton, CancelButton } from '../../input/Buttons'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import { GET_DEAL_CONTACTS, GET_POSSIBLE_DEAL_CONTACTS, UPSERT_DEAL_CONTACT } from '../Queries'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { typeStyle } from '../../styles/makesStyles'
import { useApolloClient, useMutation } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import Dropdown from '../../input/Dropdown/Dropdown'
import Label from '../../input/Label'
import ConfirmationDialog from '../../navigation/ConfirmationDialog/ConfirmationDialog'
import clsx from 'clsx'
import useNavigation from '../../navigation/useNavigation'
import SingleContact from '../../input/SingleContact'
import ContactThumbnail from '../../Thumbnail/ContactThumbnail'
import { dealContactRoles, dealTypes } from '../../constants/values'
import { DealEntryStyle, DealEntryWhiteStyle } from '../../input/DealEntry/TextEntry'
import Thumbnail from '../../Thumbnail/Thumbnail'
import { OWNER_CONTACT_ROLE, PRIMARY_CONTACT_ROLE } from '../../DealEntry/DealEntryList'

const ContactRow = (props) => {

	// Menu
	const [anchorEl, setAnchorEl] = React.useState(null)
	const handleClick = event => setAnchorEl(event.currentTarget)
	const handleClose = () => setAnchorEl(null)

	const [currentChange, setCurrentChange] = React.useState(null)
	const [percentageFocus, setPercentageFocus] = React.useState(null)
	const [currentPercentage, setCurrentPercentage] = React.useState(props?.row?.ownership_percentage)
	const [upsertDealContact] = useMutation(UPSERT_DEAL_CONTACT)

	const primaryPhone = props.row.phone?.find(phone => phone.is_primary == true)

	const hidden = props.row.is_private && !props.row.first_name
	const isPrivate = props.row.is_private

	const updateOwnershipPercentage = () => {

		// No op
		if (Number(currentPercentage) == props.row.ownership_percentage) return

		upsertDealContact({
			variables: {
				contact_id: props.row.id,
				deal_id: props.dealId,
				ownership_percentage: Number(currentPercentage)
			}
		}).then(({data}) => {
			if (data.upsertDealContact) {
				props.openSnackbar(severity.SUCCESS, "Successfully updated Contact.")
				props.handleSubmit(false)
			} else {
				props.openSnackbar(severity.ERROR, "Could not update Contact.")
			}


		}).catch((error) =>{
			console.error(error)
			props.openSnackbar(severity.ERROR, "Could not update Contact.")
		})
	}
	
	return <>
		<TableRow
			selected={isEqual(props.row, props.selectedRow)}
			className={clsx({
				'private-object': isPrivate,
				'private': isPrivate && !props.privateDeal
			})}
			style={{ cursor: 'pointer' }}
			hover={!hidden && !isPrivate}
			onClick={(event) => {
				if (currentChange || percentageFocus) return

				if (event.metaKey) {
					if ((props.row?.is_private && !props.row?.created_at) || (props.row?.publicAccess == null && props.row?.isPermittedContactType == null)) {
						props.openSnackbar(severity.WARNING, "You do not have access to this contact.")
						return
					}

					window.open(`/contacts/${props.row?.id}/details`, '_blank')
					return
				}

				props.setSelectedRow(props.row)
				if (props.row?.id === props.selectedRow?.id) {
					props.saveAndGo(props.row?.id)
				}
			}}
			data-contact-name={props.row.code_name || props.row.preferred_name}
		>
			<TableCell component="th" scope="row" style={{ width: '20em' }}>
				<ContactThumbnail contact={props.row} />
			</TableCell>
			<TableCell className="plain-text" style={{ width: '20em' }}>
				{(!isPrivate && props.row && props.row.phone &&
                        props.row.phone?.length !== 0 ) ?
					<>
						<PhoneInput
							style={{width: "10em", display: "inline-block"}}
							inputStyle={{
								color: 'inherit',
								fontFamily: "graphik",
								cursor: "pointer"
							}}
							placeholder=""
							value={primaryPhone?.number}
							disabled
							onChange={() => {}} />
						<div style={{width: "min-content", display: "inline-block"}}>{(primaryPhone?.extension) ? "x" + primaryPhone?.extension : null}</div>
					</>
					:
					<span>-</span>
				}
			</TableCell>
			<TableCell>
				{(!isPrivate && (props?.row?.primary_town?.length || 0) > 0) ?
					<span>
						{props.row.primary_town}
					</span>
					:
					<span>-</span>
				}
			</TableCell>
			<TableCell>
				<RoleSelect
					isPrivate={isPrivate}
					roles={props.contactRoles}
					value={props.row.contactRole?.id}
					onChange={props.onRoleChange}
					noBorder
				>
				</RoleSelect>
			</TableCell>
			{props.dealType == dealTypes.known_ownership || props.dealType == dealTypes.offer ?
				<TableCell 
					onMouseEnter={() => setCurrentChange(true)} 
					onMouseLeave={() => setCurrentChange(false)}
				>
					{props?.row?.is_private ? (
						<DealEntryWhiteStyle
							value={currentPercentage}
							endAdornment={<InputAdornment position="end">%</InputAdornment>}
							placeholder="0"
							onFocus={(e) => {
								setPercentageFocus(true)
							}}
							onBlur={(e) => {
								updateOwnershipPercentage()
								setPercentageFocus(false)
							}}
							onKeyUp={(e) => {
								if (e.key == 'Enter') {
									updateOwnershipPercentage()
								}
							}}
							onChange={(e) => {
								const value = Number(e.target.value)
								if (value <= 100 && value >= 0) setCurrentPercentage(e.target.value)
							}}
						/>
					) : (
						<DealEntryStyle
							value={currentPercentage}
							endAdornment={<InputAdornment position="end">%</InputAdornment>}
							placeholder="0"
							onFocus={(e) => {
								setPercentageFocus(true)
							}}
							onBlur={(e) => {
								updateOwnershipPercentage()
								setPercentageFocus(false)
							}}
							onKeyUp={(e) => {
								if (e.key == 'Enter') {
									updateOwnershipPercentage()
								}
							}}
							onChange={(e) => {
								const value = Number(e.target.value)
								if (value <= 100 && value >= 0) setCurrentPercentage(e.target.value)
							}}
						/>
					)}
						
					
				</TableCell>
			 : null}
			<TableCell>
				<IconButton
					aria-label="More"
					style={{
						padding: '6px',
						marginRight: '-9px'
					}}
					onClick={(e) => {
						if (props.row.disabled) return
						e.preventDefault()
						e.stopPropagation()
						props.setCurrentID(props.row?.id)
						handleClick(e)
					}}
					size="large">
					<MoreHorizIcon />
				</IconButton>
			</TableCell>
		</TableRow>
		<Menu
			id="simple-menu"
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={handleClose}
		>
			<MenuItem onClick={(e) => {
				if ((props.row?.is_private && !props.row?.created_at) || props.row?.publicAccess == null) {
					props.openSnackbar(severity.WARNING, "You do not have access to this contact.")
				}

				props.saveAndGo(props.currentID)
				handleClose()
			}}>Go to Contact</MenuItem>

			<MenuItem onClick={(e) => {
				props.setRemoveContact(true)
				handleClose()
			}}>Remove</MenuItem>
		</Menu>
	</>
}


const RoleSelect = (props) =>
	<Select
		style={props.isPrivate ? {color: 'white'} : null}
		IconComponent={ExpandMoreRoundedIcon}
		
		className={props.noBorder ? "hideborder padded-select" : "padded-select"}
		input={<Dropdown />}
		value={(props.roles.length && props.value) || ''}
		onClick={e => {
			e.preventDefault()
			e.stopPropagation()
		}}
		onChange={props.onChange}
	>
		{props.roles
			.map(role => (
				<MenuItem key={role.id} value={role.id}>
					{ role.value }
				</MenuItem>
			))
		}
	</Select>

function DealContactTable(props) {

	const contactHeadcells = [
		{
			id: 'preferred_name',
			numeric: false,
			disablePadding: false,
			label: 'Name & Company',
			noSort: true
		},
		{
			id: 'primary_phone',
			numeric: false,
			disablePadding: false,
			label: 'Primary Phone',
			noSort: true
		},
		{ id: 'town', numeric: false, disablePadding: false, label: 'City', noSort: true},
		{ id: 'type', numeric: false, disablePadding: false, label: 'Type', noSort: true },
		props.deal.type_id == dealTypes.known_ownership || props.deal.type_id == dealTypes.offer ?  
			{ id: 'ownership_percentage', numeric: false, disablePadding: false, label: 'Ownership Percentage', noSort: true} 
			: null,
		{
			id: 'ContactActions',
			numeric: false,
			disablePadding: false,
			label: 'Actions',
			noSort: true
		},
	].filter(e => e)

	const navigate = useNavigate()
	const location = useLocation()
	const { push: pushNav } = useNavigation()

	const [toggleRemoveContact, setRemoveContact] = React.useState(false)

	// Order of table
	const [order, setOrder] = React.useState('asc')
	const [orderBy, setOrderBy] = React.useState('id')

	const lookup = useContext(LookupContext)
	const contactRoles = lookup.data?.getContactRoles || []

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	// Contact Autocomplete
	const [modal, setModal] = React.useState({
		open: false,
		contact_id: null,
		contact_role_id: '2'
	})

	const [attempt, setAttempt] = React.useState(false)
	const [currentID, setCurrentID] = React.useState(null)

	const handleError = (error) => {
		console.error(error)
		openSnackbar(severity.ERROR, "Could not update deal.")
		props.refetch({variables: { id: props.dealId }})
	}

	const [upsertDealContact] = useMutation(UPSERT_DEAL_CONTACT)

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const [rows, setRows] = React.useState([])
	const [contactsLoading, setContactsLoading] = React.useState(false)
	const client = useApolloClient()

	const loading = props.loading || contactsLoading

	const handleSubmit = (loading = true) => {

		if (loading) {
			setRows([])
			setContactsLoading(true)
		}
		client.query({
			query: GET_DEAL_CONTACTS,
			variables: {
				id: props.dealId
			}
		})
			.then(({data}) => {
				setContactsLoading(false)
				setRows(data?.getDealContacts || [])
			})
			.catch((error) => {	
				setContactsLoading(false)
				openSnackbar(severity.ERROR, "There was an error retreiving this deals contacts.")
				console.error(error)
			})
	}

	useEffect(() => {
		if (props?.dealId) handleSubmit()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.dealId])

	const tableRows = rows?.map((item) => ({
		...item,
		type: item.type ? item.type?.map(e => e.contact_type).join("|") : item.listing_type?.value,
		primary_phone: item.phone?.find(phone => phone.is_primary == true)?.number,
		town: item.address?.find(address => address.is_primary == true)?.town,
	}))

	return <>
		<Paper
			className={props.deal?.is_private ? "search-list padding-margin dark-theme" : "search-list padding-margin"}
			data-testid="card-contacts"
		>
			<h1 className="card-title" style={{justifyContent: 'space-between'}}>
				<span>
					Contacts
					{rows?.length ? ' (' + rows?.length + ')' : ''}
				</span>
					
				<span>			
					<ChooseImageButton
						variant="contained"
						color="secondary"
						size="medium"
						onClick={() => {
							setModal({
								open:true,
								contact_id: null,
								contact_role_id: '2',
							})
						}}
					>
						Add Contact
					</ChooseImageButton>
				</span>
			</h1>


			<TableContainer>
				<Table
					aria-labelledby="tableTitle"
					size="medium"
					aria-label="enhanced table"
				>
					<EnhancedTableHead
						headCells={contactHeadcells}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={rows?.length}
					/>

					<TableBody>

						{loading == false && tableRows?.length == 0 ? 
							<TableRow>
								<TableCell></TableCell>
								<TableCell>
									<div style={{
										height: 62,
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}>
										No Contacts Found
									</div>
								</TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
							</TableRow>
							: null }

						{loading == true ? 
							<TableRow>
								<TableCell>
									<Thumbnail animation="wave" type="contact"/>
								</TableCell>
								<TableCell> <Skeleton type="text" animation="wave" /> </TableCell>
								<TableCell> <Skeleton type="text" animation="wave" /> </TableCell>
								<TableCell> <Skeleton type="text" animation="wave" /> </TableCell>
								<TableCell> <Skeleton type="text" animation="wave" /> </TableCell>
								<TableCell></TableCell>
							</TableRow>
							: null }

						{tableRows?.map((row, index) => <ContactRow
							handleSubmit={handleSubmit}
							dealType={props.deal?.type_id}
							key={`contact-${index}`}
							openSnackbar={openSnackbar}
							dealId={props.deal.id}
							row={row}
							index={index}
							currentID={currentID}
							setCurrentID={setCurrentID}
							setRemoveContact={setRemoveContact}
							selectedRow={props.selectedRow}
							setSelectedRow={props.setSelectedRow}
							contactRoles={contactRoles}
							privateDeal={props.privateDeal}
							onRoleChange={event => {
								const variables = {
									contact_id: row.id,
									deal_id: props.deal.id,
									contact_role_id: event.target.value
								}

								// Deal is Known Purchase
								if (props.deal.type_id == dealTypes.known_ownership && event.target.value == PRIMARY_CONTACT_ROLE) {
									openSnackbar(severity.WARNING,
										"There are no Primary Contacts on Known Purchase deals.")
									return
								}

								// Deal is not Known Purchase
								else if (props.deal.type_id != dealTypes.known_ownership && row.contactRole?.id == PRIMARY_CONTACT_ROLE) {
									openSnackbar(severity.WARNING,
										"There must always be one Primary Contact. You must first designate a new Primary Contact.")
									return
								}

								else if (props.deal.type_id != dealTypes.known_ownership && event.target.value == OWNER_CONTACT_ROLE) {
									openSnackbar(severity.WARNING,
										"You cannot have owners on non-Known Purchase deal types.")
									return
								}
								
								// Send to server
								upsertDealContact({
									variables
								}).then(({data}) => {
									if (data.upsertDealContact) {
										handleSubmit(false)
										props.requeryDeal()
										openSnackbar(severity.SUCCESS, "Successfully updated Deal.")
									} else {
										openSnackbar(severity.ERROR, "Could not update Deal.")
									}
								}).catch((error) => handleError(error))
							}}
							saveAndGo={(id) => {

								if ((row?.is_private && !row?.created_at) || (row?.publicAccess == null && row?.isPermittedContactType == null)) {
									openSnackbar(severity.WARNING, "You do not have access to this contact.")
									return
								}

								const ids = tableRows?.map(r => r.id)
								const state = { variables: { cursor: index } }
								pushNav({
									url: location.pathname,
									state,
									query: ids,
								}, state.variables.cursor)
								navigate(`/contacts/${id}/details`, { state })
							}}
							data-testid="deal-contact"
						/>
						)}
					</TableBody>
				</Table>
			</TableContainer>

			<TransitionsModal
				open={modal.open}
				close={() => setModal({open: false, contact_id: null})}
			>
				<div className={props.privateDeal ? "dark-theme deal-contact-modal" : "deal-contact-modal"}>
					<h2 style={{fontWeight: 500}} className="card-title">Add a Contact</h2>
					<div style={{minWidth: '20em'}}>
						<FormControl
							className={(attempt && !modal.contact_id) ? "error" : null}
							style={{width: "100%", paddingRight: "0px"}}
						>
							<Label
								id="contact-label"
								style={typeStyle}
								disableAnimation
								shrink
							>
								Contact
							</Label>

							<SingleContact
								query={GET_POSSIBLE_DEAL_CONTACTS}
								variables={{ deal_id: props.deal?.id}}
								private={props.deal?.is_private}
								onChange={(event, value) => {
									setModal({
										...modal,
										contact_id: value ? value.id : null
									})
								}}
							></SingleContact>
						</FormControl>


						<FormControl className="fullWidth">
							<Label id="role-label" disableAnimation shrink>
								Role
							</Label>
							<RoleSelect
								roles={contactRoles
									.filter(cr => {

										if (props.deal?.type_id != dealTypes.known_ownership)
											return cr.id != dealContactRoles.primary &&
												cr.id != dealContactRoles.owner

										else return cr.id != dealContactRoles.primary
									})
								}
								value={modal.contact_role_id}
								onChange={event => {
									setModal({
										...modal,
										contact_role_id: event.target.value
									})
								}}
							>
							</RoleSelect>
						</FormControl>

					</div>

					<>
						<CancelButton
							variant="contained"
							style={{ float: 'left', marginTop: '2em' }}
							onClick={e => {
								e.preventDefault()
								setModal({open: false, contact_id: null})
								setAttempt(false)
							}}
						>
							Cancel
						</CancelButton>

						<SubmitButton
							style={{ float: 'right', marginTop: '2em' }}
							variant="contained"
							onClick={(e) => {
								setAttempt(true)

								if (attempt && !modal.contact_id) {
									openSnackbar(severity.WARNING,
										"Please complete the fields in red.")

								} else if (modal.contact_id) {
									const variables = {
										contact_id: modal.contact_id,
										deal_id: props.deal.id,
										contact_role_id: modal.contact_role_id
									}

									// Send to server
									upsertDealContact({
										variables
									}).then(({data}) => {
										if (data.upsertDealContact) {
											setModal({open: false, contact_id: null})
											setAttempt(false)
											handleSubmit()
											props.requeryDeal()
											openSnackbar(severity.SUCCESS, "Successfully updated Deal.")
										} else {
											openSnackbar(severity.ERROR, "Could not update Deal.")
										}
									}).catch((error) => handleError(error))
								}
							}}
						>
							Submit
						</SubmitButton>
					</>
				</div>
			</TransitionsModal>
		</Paper>


		<ConfirmationDialog
			open={toggleRemoveContact || false}
			handleClose={setRemoveContact}
			title={'Remove from Deal?'}
			acceptText={"Remove"}
			text={`This action cannot be undone.`}
			onYes={() => {
				const variables = {
					contact_id: currentID,
					deal_id: props.deal.id,
					delete: true,
					contact_role_id: null
				}

				if (rows?.length <= 1) {
					dispatch({ type: 'openSnackBar', payload: {
						severity: severity.ERROR,
						text: "Contact needed, add another contact before deleting."
					}})
				} else {
					// Send to server
					upsertDealContact({
						variables
					}).then(({data}) => {
						if (data.upsertDealContact || data.upsertDealContact===null) {
							handleSubmit()
							props.requeryDeal()
							openSnackbar(severity.SUCCESS, "Successfully updated Deal.")
						} else {
							openSnackbar(severity.ERROR, "Could not update Deal.")
						}
					}).catch((error) => handleError(error))
				}
			}}
		/>
	</>
}

export default withApollo(DealContactTable)
