/* eslint-disable eqeqeq */
import React, { useContext, useEffect } from "react"
import {
	Paper,
	FormControl,
	Menu,
	MenuItem
} from "@mui/material"
import Label from '../../input/Label'
import TextBox from '../../input/Text/TextBoxThin'
import { CancelButton, SaveCardButton } from '../../input/Buttons'
import { parse, isValid } from 'date-fns'
import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import {filterOptions} from '../CreateListingQuickView'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { autoCompleteStyles } from "../../styles/makesStyles"
import { LookupContext } from "../../store"
import { FindModeInput } from "../../navigation/Tabs/TabbedPage"
import { Skelly } from "../../common/components/Skelly"
import TextBoxThinForDatePicker from "../../input/Text/TextBoxThinForDatePicker"

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false
}

export default function ListingAdvanced(props) {

	const classes = autoCompleteStyles()
	
	const [state, setState] = React.useState(initialState)
	const [listingInput, setListingInput] = React.useState({
		id: props.listing.id,
		start_at: null,
		end_at: null,
		start_time: null,
		end_time: null,
	})
	const [selection, setSelection] = React.useState(null)

	const resetListingInput = (listing) => {

		if (!listing || !Object.entries(listing).length) {
			return
		}

		const start_time = parse(listing.start_time, "HH:mm:ss", new Date())
		const end_time = parse(listing.end_time, "HH:mm:ss", new Date())

		setListingInput({
			id: listing.id,
			start_at: parse(listing.start_at, 'yyyy-MM-dd', new Date()),
			end_at: parse(listing.end_at, 'yyyy-MM-dd', new Date()),
			start_time: isValid(start_time) ? start_time : null,
			end_time: isValid(end_time) ? end_time : null,
			time_zone: listing.timezone_id,
			project_code: listing.project_code
		})

	}

	const lookup = useContext(LookupContext)
	const timezones = lookup.data?.getTimezones

	useEffect(() => {
		if (timezones) timezones.forEach(timezone => {
			if (timezone.id == listingInput.time_zone) {
				setSelection(timezone)
			}
			if (!listingInput.time_zone) setSelection(null)
		}
		)
	}, [listingInput.time_zone, props.listing, timezones])

	useEffect(() => {
		resetListingInput(props.listing)
	}, [props.listing])

	const handleClick = event => {
		if (state.mouseX || state.editable || props.findMode || !props.listing?.id) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4
		})
	}

	const handleClose = option => {
		if (option === "edit") {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	return (
		<React.Fragment>
			<Paper
				className="qv-margin"
				onContextMenu={handleClick}
				id="listing-advanced"
			>
				<h1 className="card-title">
					<span>Advanced</span>
					{state.editable && !props.findMode && (
						<>
							<div className="spacer"></div>
							<CancelButton
								variant="contained"
								size="small"
								onClick={() => {
									resetListingInput(props.listing)
									handleClose()
								}}
							>
							Cancel
							</CancelButton>
							<SaveCardButton
								variant="contained"
								color="secondary"
								size="small"
								onClick={() => {
									// Submit update
									props.onSave(listingInput)	
									handleClose()
								}}
							>
							Save
							</SaveCardButton>
						</>
					)}
				</h1>
				<div className="column-body">
					<div className="column">

						<FormControl>
							<Label id="start-label" style={{fontSize: "18px"}} disableAnimation shrink>
								Start Date
							</Label>
							{props.findMode ? <FindModeInput field="start_at" type="date-picker"/> :
								(props.loading ? <Skelly error={props.error} /> :
									<MobileDatePicker
										inputFormat="MMM do, yyyy"
										disableMaskedInput={true}
										componentsProps={{
											actionBar: {
												actions: ['clear', 'accept']
											}
										}}
										name="start_at"
										value={listingInput.start_at != "Invalid Date" ? listingInput.start_at : null}
										inputVariant="outlined"
										style={{marginTop: "17px"}}
										className="MUIDatePicker"
										variant="dialog" 
										readOnly={!state.editable}
										renderInput={({ inputRef, inputProps, InputProps }) => {
								
											const newProps = { ...inputProps}
				
											newProps.readOnly = !state.editable
											if (!state.editable) newProps.onClick = () => {}
				
											return (
												<TextBoxThinForDatePicker ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
											)}}
										onChange={(date) => {
											setListingInput({
												...listingInput,
												start_at: date
											})
										}}
										data-testid="listing-start-date"
									/>
								)}
						</FormControl>

						<FormControl>
							<Label id="end-label" style={{fontSize: "18px"}} disableAnimation shrink>
								End Date
							</Label>
							{props.findMode ? <FindModeInput field="end_at" type="date-picker"/> :
								(props.loading ? <Skelly error={props.error} /> :
									<MobileDatePicker
										inputFormat="MMM do, yyyy"
										disableMaskedInput={true}
										componentsProps={{
											actionBar: {
												actions: ['clear', 'accept']
											}
										}}
										name="end_at"
										value={listingInput.end_at != "Invalid Date" ? listingInput.end_at : null}
										inputVariant="outlined"
										style={{marginTop: "17px"}}
										className="MUIDatePicker"
										variant="dialog" 
										readOnly={!state.editable}
										renderInput={({ inputRef, inputProps, InputProps }) => {
								
											const newProps = { ...inputProps}
				
											newProps.readOnly = !state.editable
											if (!state.editable) newProps.onClick = () => {}
				
											return (
												<TextBoxThinForDatePicker ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
											)}}
										onChange={(date) => {
											setListingInput({
												...listingInput,
												end_at: date
											})
										}}
										data-testid="listing-end-date"
									/>
								)}
						</FormControl>
						{listingInput.project_code && <FormControl>
							<Label id="end-label" style={{fontSize: "18px"}} disableAnimation shrink>
								Project Code
							</Label>
							{listingInput.project_code && props.findMode ? <FindModeInput field="project_code" /> :
								(props.loading ? <Skelly error={props.error} /> :
									<TextBox
										id="listing-project-code"
										value={listingInput.project_code?.project_code}
										readOnly={true}
										data-testid="listing-project-code"
									/>
								)}
						</FormControl>}
					</div>
					<div className="column">

						{!props.findMode ? <> 

							<FormControl className="start-time">
								<Label
									id="start-time-label"
									style={{ fontWeight: 500 }}
									disableAnimation
									shrink
								>
								Start Time
								</Label>
								{props.loading ? <Skelly error={props.error} /> :
									<MobileTimePicker
										disableMaskedInput={true}
										cancelText={null}
										placeholder="-"
										componentsProps={{
											actionBar: {
												actions: ['clear', 'accept']
											}
										}}
										name="time"
										style={{marginTop: "10px"}}
										readOnly={!state.editable}
										renderInput={({ inputRef, inputProps, InputProps }) => {
							
											const newProps = { ...inputProps}
			
											newProps.readOnly = !state.editable
											if (!state.editable) newProps.onClick = () => {}

											return (
												<TextBoxThinForDatePicker ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
											)}}
										value={listingInput.start_time}
										onChange={(time) => {
											setListingInput({
												...listingInput,
												start_time: time
											})
										}}
										data-testid="listing-start-time"
									/>
								}
							</FormControl>

							<FormControl className="end-time">
								<Label
									id="end-time-label"
									style={{ fontWeight: 500}}
									disableAnimation
									shrink
								>
								End Time
								</Label>
								{props.loading ? <Skelly error={props.error} /> :
									<MobileTimePicker
										disableMaskedInput={true}
										placeholder="-"
										componentsProps={{
											actionBar: {
												actions: ['clear', 'accept']
											}
										}}										name="time"
										readOnly={!state.editable}
										renderInput={({ inputRef, inputProps, InputProps }) => {
							
											const newProps = { ...inputProps}
			
											newProps.readOnly = !state.editable
											if (!state.editable) newProps.onClick = () => {}

											return (
												<TextBoxThinForDatePicker ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
											)}}
										value={listingInput.end_time}
										onChange={(time) => {
											setListingInput({
												...listingInput,
												end_time: time
											})
										}}
										data-testid="listing-end-time"
									/>
								}
							</FormControl>

						</> : null}

						{!props.findMode && <FormControl>
							<Label id="timezone-label" style={{fontWeight: 500}} disableAnimation shrink>
								Timezone
							</Label>

							{(props.loading || props.error) ?
								<Skelly error={props.error} /> : <>
									{!state.editable ?
										<TextBox
											placeholder="-"
											variant="outlined"
											readOnly={!state.editable}
											value={selection ? selection.tzoffset + " ("+selection.abbr+")" : ''}
											fullWidth
										/>
										:
										<Autocomplete
											size="small"
											style={{marginTop: "1.75em"}}
											filterOptions={filterOptions}
											popupIcon={<ExpandMoreRoundedIcon />}
											disabled={!listingInput.start_time && !listingInput.end_time}
											options={timezones}
											classes={classes}
											value={selection}
											getOptionLabel={(option) => option.tzoffset + " ("+option.abbr+")"}
											renderOption={(props, option) => (
												<li {...props}>
													{option.tzoffset + " " + option.value + " ("+option.abbr+")"}
												</li>
											)}
											renderInput={(params) => (
												<TextField
													{...params}
													placeholder="-"
													variant="outlined"
													fullWidth
													inputProps={{
														...params.inputProps,
													}}
												/>
											)}
											onChange={(event, value) => setListingInput({
												...listingInput,
												time_zone: value
											})}
											data-testid="listing-timezone"
										/>
									}
								</>
							}
						</FormControl> }
					</div>
				</div>

				<Menu
					keepMounted
					open={state.mouseY !== null}
					onClose={handleClose}
					anchorReference="anchorPosition"
					anchorPosition={
						state.mouseY !== null && state.mouseX !== null
							? { top: state.mouseY, left: state.mouseX }
							: undefined
					}
				>
					<MenuItem onClick={() => handleClose("edit")}>Edit</MenuItem>
				</Menu>
			</Paper>
		</React.Fragment>
	)
}
