import React, { useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { CancelButton, ResetButton } from '../../input/Buttons'

export default function AlertDialog(props) {

	const [open, setOpen] = React.useState(props.open)

	useEffect(() => {
		setOpen(props.open)
	}, [props.open])

	const handleClose = () => {
		props.handleClose()
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
			<DialogContent style={{minWidth: "25em"}}>
				<DialogContentText id="alert-dialog-description">
					{props.text}
				</DialogContentText>
			</DialogContent>
			<DialogActions style={{justifyContent: "space-between", margin: "0 1em", paddingBottom: "1.5em"}}>
				<CancelButton
					onClick={handleClose}
					variant="contained"
					autoFocus
					style={{
						minWidth: '5em'
					}}
				>
					{props.cancelText ? props.cancelText : "Cancel"}
				</CancelButton>
				<ResetButton style={{
					backgroundColor: props.buttonColor ? props.buttonColor : null,
					minWidth: '5em'
				}} onClick={() => {
					props.onYes()
					handleClose()
				}}variant="contained">
					{props.acceptText ? props.acceptText : "Yes"}
				</ResetButton>

			</DialogActions>
		</Dialog>
	)
}
