import React, { useState, useEffect } from 'react'
import { FormControl } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import Label from '../input/Label'
import TextBox from '../input/Text/TextBox'
import ReactQuill from 'react-quill'

const defaultAgreement =  `Consignor hereby grants to Consignee the exclusive right to offer the Work for sale to potential buyers at a gross sales price for which Consignor shall receive, after payment of any and all commissions to Consignee, the agreed Net to Consignor ("Net Price").
The Consignment Period and the Net Price may be changed from time to time by written mutual consent of Consignor and Consignee.
Consignor hereby represents that %pronoun% has the full legal authority, without any action or any other party's consent, to enter into and perform this agreement, and that %pronoun% is the sole and absolute owner of the Work, which is free and clear of any and all liens, claims, security interests or other encumbrances held by any person or entity.
Consignor hereby represents the Work is authentic, that is, the Work was created by the artist indicated on this agreement and that %pronoun% has no knowledge of any information that casts doubt on the authenticity of the Work; the Work has been lawfully exported and imported, as required by the laws of any country in which it was located or transported to; required declarations upon the export and import of the Work have been properly made and any duties and taxes on the export and import of the Work have been paid.
Consignor hereby represents that %pronoun% has provided Consignee with all information in Consignor's possession concerning the attribution, authenticity, description, condition and provenance of the Work, including information regarding any damage, alterations, repairs or restorations to the Work.
Consignee will pay all costs and expenses of crating, packing, insuring, and shipping the Work from its current location to Consignee's premises. If the Work is not sold prior to the end of the Consignment Period, Consignee shall pay all costs and expenses of crating, packing, insuring, and shipping the Work back to the same location or to such other location as mutually agreed upon by Consignor and Consignee within fifteen days after the end of the Consignment Period. Consignee shall be responsible for all expenses related to selling the Work.
Consignee shall arrange and pay for all-risk fine art wall-to-wall insurance for the Work against any loss or damage. Such insurance shall be provided from the moment the Work is released to Consignee's shippers, and until the Work is returned to Consignor or its designate, or title is transferred to Buyer. Consignee will provide Consignor with a certificate of insurance evidencing such insurance coverage.
In the event that the Work is sold during the Consignment Period, Consignee shall be entitled to receive any gross sale proceeds for the Work above the Net Price as a commission in consideration of its services. Consignee shall retain this commission and forward the balance of the gross sale proceeds for the Work to Consignor within five (5) business days of the receipt by Consignee of the entire gross sales proceeds for the Work in good and clear funds.
Title to the Work shall pass to Buyer from Consignor through Consignee upon Consignee's receipt of the full payment of the gross sale proceeds for the Work, at which time Consignor specifically authorizes Consignee to effectuate the transfer of ownership and title to the Work, to provide such documentation as is reasonably required upon sale, including a bill of sale for the Work, and to release the Work to Buyer.
During the term of this Agreement and after the closing of title, neither Consignee nor Consignor will disclose the existence of this Agreement, the identity of the parties hereto, the sale proceeds for the Work, the commission payable or any other term of this Agreement, or the nature of the transaction contemplated hereby, to any third party without the other’s written consent, except to potential buyers and their agents and representatives insofar as necessary to carry out the terms of this Agreement, or as may be required by law.  The terms of this paragraph shall survive the closing of title or termination of this Agreement for any reason.
This agreement contains the entire understanding between Consignor and Consignee. It may not be changed except by a writing signed by Consignor and Consignee. This agreement shall inure to the benefit of and shall bind the Consignor and Consignee, and any successors permitted assigns. This Agreement and all matters relating to it shall be governed by the laws of the State of New York without regard to conflict of laws principles. Any and all disputes, claims or controversies arising out of or relating to this Agreement shall be submitted to JAMS, or its successor, for mediation, and if the matter is not resolved through mediation, then it shall be submitted to JAMS, or its successor, for final and binding arbitration in New York City pursuant to its Streamlined Arbitration Rules and Procedures. Judgment on any award may be entered in any court having jurisdiction. The arbitrator shall, in any award, allocate all or part of the costs of the arbitration, including the fees of the arbitrator and the reasonable attorneys’ fees of the prevailing party. The Consignor and Consignee also agree that they shall first try to resolve any disputes amicably and in good faith.
`.split('\n').map(p => `<p>${p}</p>\n`).join('')

const ConsignmentTerms = (props) => {

	const [period, setPeriod] = useState(props.data?.period || "")
	const [pronoun, setPronoun] = useState(props.data?.pronoun || "he")
	const [agreement, setAgreement] = useState(props.data?.agreement || defaultAgreement)

	useEffect(()=>{
		props.setData({
			period,
			pronoun,
			agreement
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [period, pronoun, agreement])

	return (
		<div
			style = {{
				display: "flex",
				flexDirection: "column"
			}}
		>
			<h3>Terms of Consignment</h3>
			<FormControl className="fullWidth">
				<Label disableAnimation shrink id="consignment-period">
					Consignment Period
				</Label>
				
				<TextBox
					name="consignment-period"
					value={period}
					onChange={(e) => { setPeriod(e.target.value) }}
				/>
			</FormControl>

			<FormControl 
				className="fullWidth"
				style={{	
					paddingTop: '1.8em',
				}}
			>
				<Label disableAnimation shrink id="consignor-pronoun">
					Consignor Pronoun
				</Label>
				<ToggleButtonGroup 
					value={pronoun} 
					exclusive
					onChange={(_, nval)=> { setPronoun(nval) }} 
					name="consignor-pronoun"
					style= {{
						height: '3em'
					}}
				>
					{["He", "She", "It"].map( pronoun => 
						<ToggleButton 
							value={pronoun.toLowerCase()}
							style={{width: "4em"}}
							key={pronoun}
						>
							{pronoun}
						</ToggleButton>
					)}
			
				</ToggleButtonGroup>

			</FormControl>
			<FormControl className="fullWidth">
				<Label disableAnimation shrink id="agreement">
						Consignment Agreement
				</Label>
			</FormControl>
			<ReactQuill
				theme="snow"
				className="quill-consignment"
				value={agreement}
				onChange={(content, delta, source, editor) => {
					setAgreement(content)
				}}
				modules={{ toolbar: false }}
			/>
		</div>
	)
}

export default ConsignmentTerms
