/* eslint-disable eqeqeq */
import '../SearchQuickView/SearchQuickView.css'
import { IconButton, ListItemSecondaryAction, Skeleton } from '@mui/material'
import { withApollo } from '@apollo/client/react/hoc'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import QuickView from '../QuickView/QuickView'
import React, { useContext, useEffect } from 'react'
import {GET_TAG_COUNTS} from './Tag/Queries'
import { AuthStateContext } from '../store'
import { permissions, permissionValues } from '../constants/permissions'
import { Close } from '@mui/icons-material'
import BulkActions from '../Search/BulkActions'
import { Box } from '@mui/system'
import { computeTextColorFromBGColor } from '../common/helpers'

export 	const getPermissionCanSee = (userPermissions, permission) => {
	const currentPermission = userPermissions?.find(e => e.permission_id == permission)
		?.permission_value_id

	return userPermissions && currentPermission != undefined
		&& currentPermission >= permissionValues.VIEW_ONLY
}

function TagQuickView(props) {

	const [tag, setTag] = React.useState(props.tag || props.state.getTag)

	const [artCount, setArtCount] = React.useState(0)
	const [listingCount, setListingCount] = React.useState(0)
	const [contactCount, setContactCount] = React.useState(0)
	const [artistCount, setArtistCount] = React.useState(0)
	const [dealCount, setDealCount] = React.useState(0)


	const authState = useContext(AuthStateContext)
	const userPermissions  = authState?.user?.permissions


	const artTagPermission = getPermissionCanSee(userPermissions, permissions.ART_TAGS)
	const artistTagPermission = getPermissionCanSee(userPermissions, permissions.ARTISTS_TAGS)
	const contactTagPermission = getPermissionCanSee(userPermissions, permissions.CONTACTS_TAGS)
	const dealTagPermission = getPermissionCanSee(userPermissions, permissions.DEALS_TAGS)
	const listingTagPermission = getPermissionCanSee(userPermissions, permissions.LISTINGS_TAGS)

	useEffect(() => {

		setTag(props.tag || props.state?.getTag)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.tag, props.state?.getTag])

	useEffect(() => {

		if (!props.tag) return

		if (typeof props.tag !== "object") {
			setTag(props.parentState?.getTag)

			setArtistCount(props.parentState?.getTag?.artist?.length || 0)
			setArtCount(props.parentState?.getTag?.art?.length || 0)
			setDealCount(props.parentState?.getTag?.deal?.length || 0)
			setListingCount(props.parentState?.getTag?.listing?.length || 0)
			setContactCount(props.parentState?.getTag?.contact?.length || 0)
		}
	    else {
			const tagId = props.tag.id
			props.client
				.query({query: GET_TAG_COUNTS, variables: {id: tagId}})
				.then(result => {
					setArtistCount(result.data.getTagCounts.artistCount)
					setArtCount(result.data.getTagCounts.artCount)
					setDealCount(result.data.getTagCounts.dealCount)
					setListingCount(result.data.getTagCounts.listingCount)
					setContactCount(result.data.getTagCounts.contactCount)

				})
				.catch(error => {
					// Error
					console.log(error)
				})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.client, props.id, props.parentState?.getTag, props.state, props.tag])

	const title = tag?.label || props.state?.getTag?.label
	const description = tag?.description || props.state?.getTag?.description
	const bgColor = '#' + (tag?.color_hex || 'var(--tag-background)')
	const txtColor = computeTextColorFromBGColor(bgColor)

	return (
		<QuickView style={{
			display: 'flex',
			flexDirection: 'column'
		}}>
			<h2 className="navigation-text"
				data-testid="search-qv-header"
				style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: bgColor, color: txtColor }}
			>
				{title ? title :
					!props.state?.getTag ?
						<Skeleton
							animation="wave"
							variant="text"
							width='10em'
							style={{
								backgroundColor: '#2f2f31'
							}}
						/> : <span>-</span>
				}

				{props.onClose && <span>
					<IconButton size="large"
						sx={{ height: '35px', width: '35px' }}
						onClick={props.onClose}
					>
						<Close sx={{ color: 'white' }} />
					</IconButton>
				</span>}
			</h2>

			<span style={{ display: 'flex', justifyContent: 'space-between', padding: '1em' }}>
				{description ? description :
					!(props.state?.getTag || tag) ?
						<Skeleton
							animation="wave"
							variant="text"
							width='10em'
							style={{
								backgroundColor: '#2f2f31'
							}}
						/> : <span>-</span>
				}
			</span>

			<List>
				<ListItem>
					<ListItemText
						primaryTypographyProps={{ style: { fontSize: '20px' } }}
						primary="ARTWORK"
					/>
					<ListItemSecondaryAction style={{ paddingRight: '1em' }}>
						{artTagPermission ? artCount : 0}
					</ListItemSecondaryAction>
				</ListItem>
				<Divider variant="middle" style={{ margin: '0.5em 0'}}/>
				<ListItem>
					<ListItemText
						primaryTypographyProps={{ style: { fontSize: '20px' } }}
						primary="CONTACTS"
					/>
					<ListItemSecondaryAction style={{ paddingRight: '1em' }}>
						{contactTagPermission ? contactCount : 0}
					</ListItemSecondaryAction>
				</ListItem>
				<Divider variant="middle" style={{ margin: '0.5em 0'}}/>
				<ListItem>
					<ListItemText
						primaryTypographyProps={{ style: { fontSize: '20px' } }}
						primary="LISTINGS"
					/>
					<ListItemSecondaryAction style={{ paddingRight: '1em' }}>
						{listingTagPermission ? listingCount : 0}
					</ListItemSecondaryAction>
				</ListItem>
				<Divider variant="middle" style={{ margin: '0.5em 0'}}/>
				<ListItem>
					<ListItemText
						primaryTypographyProps={{ style: { fontSize: '20px' } }}
						primary="ARTISTS"
					/>
					<ListItemSecondaryAction style={{ paddingRight: '1em' }}>
						{artistTagPermission ? artistCount : 0}
					</ListItemSecondaryAction>
				</ListItem>
				<Divider variant="middle" style={{ margin: '0.5em 0'}}/>
				<ListItem>
					<ListItemText
						primaryTypographyProps={{ style: { fontSize: '20px' } }}
						primary="DEALS"
					/>
					<ListItemSecondaryAction style={{ paddingRight: '1em' }}>
						{dealTagPermission ? dealCount : 0}
					</ListItemSecondaryAction>
				</ListItem>
				<Divider variant="middle" style={{ margin: '0.5em 0'}}/>
			</List>
			{tag?.art?.length ? 
				<Box
					sx={{
						margin: '1em',
						width: 'auto',
						display: 'flex',
						flexFlow: 'column',
						marginTop: 'auto'
					}}
				>
					<BulkActions
						selectedEntities={tag?.art}
						entityType='art'
					/>
				</Box> : null}

		</QuickView>
	)
}

export default withApollo(TagQuickView)
