/* eslint-disable eqeqeq */
import { Autocomplete, CircularProgress, FormControl } from "@mui/material"
import React, { useCallback, useContext } from "react"
import { CancelButton, SubmitButton } from "../input/Buttons"
import Label from "../input/Label"
import TransitionsModal from "../navigation/TransitionsModal/TransitionsModal"
import { autoCompleteErrorStyles, typeStyle } from "../styles/makesStyles"
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded"
import { GET_NON_DELETED_USERS_WITH_IMAGE } from "../User/Queries"
import { withApollo } from "@apollo/client/react/hoc"
import TextBoxForAutocomplete from '../input/Text/TextBoxForAutocomplete'
import Thumbnail from "../Thumbnail/Thumbnail"
import { useMutation } from "@apollo/client"
import { DispatchContext } from "../store"
import { severity } from "../Snackbar/CustomizedSnackbar"
import { UPDATE_TASK_ASSIGNEES } from "./Queries"

const TaskAssigneesModal = (props) => {
	const { open: modalOpen, close, rows } = props

	const [loading, setLoading] = React.useState(false)
	const [open, setOpen] = React.useState(false)
	const [options, setOptions] = React.useState([])
	const [selection, setSelection] = React.useState([])
	const [attempt, setAttempt] = React.useState(false)

	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const autoCompleteLoading = open && options?.length === 0
	const error = attempt && selection.length == 0

	React.useEffect(() => {
		let active = true

		if (!autoCompleteLoading) {
			return undefined
		}


		props.client
			.query({
				query: GET_NON_DELETED_USERS_WITH_IMAGE,
			})
			.then((result) => {
				if (active){
					setOptions(result.data.getUsers.items)
				}
			})

		return () => {
			active = false
		}
	}, [autoCompleteLoading, props.client])

	const handleClose = () => {
		setAttempt(false)
		setOptions([])
		setSelection([])
		setLoading(false)
		close()
	}

	const [updateTaskAssignees] = useMutation(UPDATE_TASK_ASSIGNEES)


	const handleSubmit = () => {

		setAttempt(true)

		if (error) openSnackbar(severity.WARNING, 'Please complete required fields.')
		else if (selection.length != 0) {

			setLoading(true)
			
			updateTaskAssignees({
				variables: {
					assigneeIds: selection.map(e => e.id),
					taskIds: rows.map(e => e.id)
				},
			})
				.then((response) => {

					if (response.data?.updateTaskAssignees?.success) {
						openSnackbar(severity.SUCCESS, response.data.updateTaskAssignees.message)
					} else {
						openSnackbar(severity.ERROR, response.data.updateTaskAssignees.message)
					}

					setLoading(false)
					handleClose()
				})
				.catch((error) => {
					console.log(error)
					openSnackbar(severity.ERROR, error)
					setAttempt(false)
					setLoading(false)
				})
		}
	}

	const autoCompleteClasses = autoCompleteErrorStyles()

	return (
		<TransitionsModal className='total-price-modal' open={modalOpen} close={() => handleClose()}>
			<div style={{ width: "25em" }}>
				<h2
					className='card-title'
					style={{
						paddingBottom: "0.5em",
						margin: 0,
					}}
				>
					Update Task Assignees
				</h2>

				<FormControl
					sx={{ width: '-webkit-fill-available' }}
					error={error}
				>
					<Label id='assignee-label' style={typeStyle} disableAnimation shrink error={error}>
						Assignees
					</Label>
					<Autocomplete
						open={open}
						onOpen={() => {
							setOpen(true)
						}}
						onClose={() => {
							setOpen(false)
						}}
						multiple
						isOptionEqualToValue={(a, b) => a.id == b.id}
						popupIcon={<ExpandMoreRoundedIcon />}
						style={{ marginTop: "1.75em" }}
						options={options}
						loading={autoCompleteLoading}
						getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
						value={selection}
						filterSelectedOptions
						classes={autoCompleteClasses}
						renderInput={(params) => (
							<TextBoxForAutocomplete
								{...params}
								variant='outlined'
								error={error}
								fullWidth
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{autoCompleteLoading ? <CircularProgress color='inherit' size={20} /> : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
						renderOption={(optionProps, option) => {
				
							const thumbnail = (
								<Thumbnail 
									name={`${option.first_name} ${option.last_name}`}
									detail={option?.department ?? ''}
									avatar={option.imgUrl}
									type='contact'
									animation={loading ? 'wave' : false}
								/>
							)
				
							return <li {...optionProps}>{thumbnail}</li>
						}}
						onChange={(event, value) => {
							setSelection(value)
						}}
					/>
				</FormControl>

				<div
					style={{
						display: "flex",
						marginTop: "1em",
					}}
				>
					<CancelButton variant='contained' style={{ float: "left" }} onClick={() => handleClose()}>
						Cancel
					</CancelButton>

					<SubmitButton variant='contained' type='submit' sx={{ marginLeft: "auto" }} disabled={loading} onClick={() => handleSubmit()}>
						Submit
					</SubmitButton>
				</div>
			</div>
		</TransitionsModal>
	)
}

export default withApollo(TaskAssigneesModal)
