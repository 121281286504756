import { gql } from "@apollo/client"
import { imageData, userDataCompressed } from '../common/fragments'

export const GET_HOME_NOTES = gql`
	query searchHomeNotes(
		$id: ID
		$field: String
		$direction: SortDirection
		$cursor: Int
		$limit: Int
		$thumbnailResolution: String = "128x128"
	) {
		searchHomeNotes(
			input: {
				id: $id
				field: $field
				direction: $direction
				cursor: $cursor
				limit: $limit
			}
		) {
			items {
				id
				note_text
				applied_user_id
				modified_at
				is_critical
				type_id
				applied_at
				entity_name
				entity_subtitle
				entity_id
				entity_title
				entity_img (thumbnailResolution: $thumbnailResolution)
				user {
					...userDataCompressed
					gallery
					is_deleted
				}
				images {
					...imageData
				}

				contact {
					id
					is_deleted
					is_private
					created_at
					code_name
					is_company
					is_deceased
					dynamic_generated_codename
				}

				deal {
					id
					is_private
					is_gallery_private
					created_at
					code_name
					is_deleted
				}

				artist {
					id
					is_deleted
				}

				listing {
					id
					is_deleted
				}

				art {
					id
					imgUrl
					title
					formatted_title 
					year
					is_private
					code_name
					created_at
					artist {
						id
						first_name
						last_name
					}
				}
			}
			cursor
			totalItems
		}
	}
	${imageData}
	${userDataCompressed}
`

export const DELETE_ART_NOTE = gql`
	mutation deleteArtNote($id: ID) {
		deleteArtNote(id: $id) {
			code
			success
			message
		}
	}
`

export const DELETE_ARTIST_NOTE = gql`
	mutation deleteArtistNote($id: ID) {
		deleteArtistNote(id: $id) {
			code
			success
			message
		}
	}
`

export const DELETE_CONTACT_NOTE = gql`
	mutation deleteContactNote($id: ID) {
		deleteContactNote(id: $id) {
			code
			success
			message
		}
	}
`

export const DELETE_DEAL_NOTE = gql`
	mutation deleteDealNote($id: ID) {
		deleteDealNote(id: $id) {
			code
			success
			message
		}
	}
`

export const DELETE_LISTING_NOTE = gql`
	mutation deleteListingNote($id: ID) {
		deleteListingNote(id: $id) {
			code
			success
			message
		}
	}
`

export const UPDATE_LISTING_NOTE = gql`
	mutation updateListingNote(
		$UpdateNoteInput: UpdateNoteInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateListingNote(input: $UpdateNoteInput) {
			code
			success
			message
			note {
				id
				type_id
				applied_at
				applied_user_id
				note_text
				is_critical
				is_private
				is_deleted
				created_by
				created_at
				modified_by
				modified_at
				entity_id
				entity_name
				entity_title
				entity_subtitle
				user {
					...userDataCompressed
					is_deleted
				}
				images {
					...imageData
				}
			}
		}
	}
	${imageData}
	${userDataCompressed}
`

export const UPDATE_CONTACT_NOTE = gql`
	mutation updateContactNote(
		$UpdateNoteInput: UpdateNoteInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateContactNote(input: $UpdateNoteInput) {
			code
			success
			message
			note {
				id
				type_id
				applied_at
				applied_user_id
				note_text
				is_critical
				is_private
				is_deleted
				created_by
				created_at
				modified_by
				modified_at
				entity_id
				entity_name
				entity_title
				entity_subtitle
				user {
					...userDataCompressed
					is_deleted
				}
				images {
					...imageData
				}
			}
		}
	}
	${imageData}
	${userDataCompressed}
`
export const UPDATE_ART_NOTE = gql`
	mutation updateArtNote(
		$UpdateNoteInput: UpdateNoteInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateArtNote(input: $UpdateNoteInput) {
			code
			success
			message
			note {
				id
				type_id
				applied_at
				applied_user_id
				note_text
				is_critical
				is_private
				is_deleted
				created_by
				created_at
				modified_by
				modified_at
				entity_id
				entity_name
				entity_title
				entity_subtitle
				user {
					...userDataCompressed
					is_deleted
				}
				images {
					...imageData
				}
			}
		}
	}
	${imageData}
	${userDataCompressed}
`

export const UPDATE_DEAL_NOTE = gql`
	mutation updateDealNote(
		$UpdateNoteInput: UpdateNoteInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateDealNote(input: $UpdateNoteInput ) {
			code
			success
			message
			note {
				id
				type_id
				applied_at
				applied_user_id
				note_text
				is_critical
				is_private
				is_deleted
				created_by
				created_at
				modified_by
				modified_at
				user {
					...userDataCompressed
					is_deleted
				}
				images {
					...imageData
				}
			}
		}
	}
	${imageData}
	${userDataCompressed}
`
export const UPDATE_ARTIST_NOTE = gql`
	mutation updateArtistNote(
		$UpdateNoteInput: UpdateNoteInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateArtistNote(input: $UpdateNoteInput) {
			code
			success
			message
			note {
				id
				type_id
				applied_at
				applied_user_id
				note_text
				is_critical
				is_private
				is_deleted
				created_by
				created_at
				modified_by
				modified_at
				entity_id
				entity_name
				entity_title
				entity_subtitle
				user {
					...userDataCompressed
					is_deleted
				}
				images {
					...imageData
				}
			}
		}
	}
	${imageData}
	${userDataCompressed}
`
