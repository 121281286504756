import { useParams } from 'react-router-dom'
import TabbedPage from '../navigation/Tabs/TabbedPage'
import routes from '../navigation/routes'
import { gql } from '@apollo/client'

interface ShowcaseArtistProps extends React.FC {}

export const GET_ARTIST = gql`
	query getArtist($id: ID!) {
		getArtist(id: $id) {
			id
			first_name
			last_name
		}
	}
`

export default function ShowcaseArtist(props: ShowcaseArtistProps) {
	const params = useParams()

	// @ts-ignore
	return TabbedPage({
		...props,
		routes: routes.showcaseArtistPages.pages,
		query: GET_ARTIST,
		options: routes.showcaseArtistPages.options,
		params,
	})
}
