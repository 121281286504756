import React, {useContext, useCallback} from "react"
import NewNote from '../../../Notes/ObjectNotes/NewObjectNote'
import NoteList from '../../../Notes/ObjectNotes/ObjectNotesList'
import { GET_ARTIST_NOTES, CREATE_NOTE, ARTIST_UPDATE_NOTE } from './Queries'
import { isNullArray } from "../../../common/helpers"
import { DispatchContext } from "../../../store"
import { severity } from "../../../Snackbar/CustomizedSnackbar"
import { withApollo } from "@apollo/client/react/hoc"

function ArtistNotes(props) {

	const [notes, setNotes] = React.useState([])
	const [notesLoading, setNotesLoading] = React.useState(true)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	// Modal
	const [editNoteModal, setEditNoteModal] = React.useState({open: false, note: null})

	React.useEffect(() => {

		let active = true
		if (!props.findMode && props.id > 0) {
			setNotesLoading(true)
			setNotes([])
			
			props.client
				.query({ query: GET_ARTIST_NOTES, variables: { artist_id: props.id}})
				.then(notesData => {
					if (active) {
						setNotesLoading(false)
						if (notesData?.data && !isNullArray(notesData?.data?.getArtistNotes)) {
							setNotes(notesData?.data.getArtistNotes || [])
						} else if (isNullArray(notesData?.data?.getArtistNotes)) {
							setNotes([])
							openSnackbar(severity.ERROR, "Error - Unauthorized")
						}
					}
				})
		}

		return () => active = false
	}, [openSnackbar, props.client, props.findMode, props.id])

	return !props.findMode && (
		<section className="main-page">
			<div>
				{props.id > 0 && <NewNote
					entityId={props.id}
					notes={notes}
					setNotes={setNotes}
					notesLoading={notesLoading}
					mutation={CREATE_NOTE}
					mutationName="createArtistNote"
					linkField="artist_id"
					state={props.state}
					setState={props.setState}
					criticalNotes="getCriticalArtistNotes"
					privateEntity={false}
				/>}
				<NoteList
					editNoteModal={editNoteModal}
					setEditNoteModal={setEditNoteModal}
					state={props.state}
					setState={props.setState}
					notes={notes}
					setNotes={setNotes}
					notesLoading={notesLoading}
					mutationResponse="updateArtistNote"
					updateMutationName={ARTIST_UPDATE_NOTE}
					criticalNotes="getCriticalArtistNotes"
					linkField="artist_id"
					privateEntity={false}
				/>
				
			</div>
		</section>
	)
}

export default withApollo(ArtistNotes)
