import { ExpandMoreRounded } from "@mui/icons-material"
import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import Dropdown from "../input/Dropdown/Dropdown"
import Label from "../input/Label"

export default function ImageReportOptions (props: any) {
	
	const [fields, setFields] = useState<any>(
		JSON.parse(localStorage.getItem('ImageReportOptions.fields') || '{}'))

	const fullFields = useMemo(() => ({
		includeRetailPrice: 'true',
		includeLocations: 'true',
		includeOwnership: 'true',
		includeInventoryNumber: 'true',
		layout: 'vertical'
	}), [])

	// update stored values if a new one is added.
	useEffect(() => {
		if (!fields || Object.keys(fullFields).length !== Object.keys(fields).length) {
			
			const tempFields = {...fields}
			Object.keys(fullFields).forEach((key) => {
				if (tempFields[key] === undefined) tempFields[key] = true
			})
			setFields(tempFields)
		}
		
	}, [fields, fullFields])


	const { onChange } = props

	useEffect(() => {
		localStorage.setItem('ImageReportOptions.fields', JSON.stringify(fields))
		onChange(fields)
	}, [onChange, fields] )
	
	const handleChange = (e: SelectChangeEvent<any>) => {
		setFields((oldFields: any) => ({
			...oldFields,
			[e.target.name]:  e.target.value === 'true' ? true : e.target.value
		}))
	}

	return <>
		<Box sx={{ display: 'flex' }}>
			<FormControl 
				className="fullWidth padded-select" 
				sx={{ paddingRight: '1em' }}
			>
				<Label
					id="includeRetailPrice"
					disableAnimation
					shrink
				>
					Include Retail Price?
				</Label>
				<Select
					IconComponent={ExpandMoreRounded}
					name="includeRetailPrice"
					className="padded-select"
					data-testid="includeRetailPrice"
					labelId="includeRetailPrice"
					input={<Dropdown />}
					value={fields.includeRetailPrice || 'no' }
					onChange={handleChange}
				>
					<MenuItem value='true'>Yes</MenuItem>
					<MenuItem value='no'>No</MenuItem>
				</Select>
			</FormControl>

			<FormControl className="fullWidth padded-select">
				<Label
					id="includeLocations"
					disableAnimation
					shrink
				>
					Include Locations?
				</Label>
				<Select
					IconComponent={ExpandMoreRounded}
					name="includeLocations"
					className="padded-select"
					data-testid="includeLocations"
					labelId="includeLocations"
					input={<Dropdown />}
					value={fields.includeLocations || 'no' }
					onChange={handleChange}
				>
					<MenuItem value='true'>Yes</MenuItem>
					<MenuItem value='no'>No</MenuItem>
				</Select>
			</FormControl>
		</Box>
		<Box sx={{ display: 'flex' }}>
			<FormControl
				className="fullWidth padded-select" 
			>
				<Label
					id="includeOwnership"
					disableAnimation
					shrink
				>
					Include Inventory Number?
				</Label>
				<Select
					IconComponent={ExpandMoreRounded}
					name="includeInventoryNumber"
					className="padded-select"
					data-testid="includeInventoryNumber"
					labelId="includeInventoryNumber"
					input={<Dropdown />}
					value={fields.includeInventoryNumber || 'no' }
					onChange={handleChange}
				>
					<MenuItem value='true'>Yes</MenuItem>
					<MenuItem value='no'>No</MenuItem>
				</Select>
			</FormControl>
		</Box>
		<Box sx={{ display: 'flex' }}>
			<FormControl
				className="fullWidth padded-select" 
				sx={{ paddingRight: '1em' }}
			>
				<Label
					id="includeOwnership"
					disableAnimation
					shrink
				>
					Include Ownership?
				</Label>
				<Select
					IconComponent={ExpandMoreRounded}
					name="includeOwnership"
					className="padded-select"
					data-testid="includeOwnership"
					labelId="includeOwnership"
					input={<Dropdown />}
					value={fields.includeOwnership || 'no' }
					onChange={handleChange}
				>
					<MenuItem value='true'>Yes</MenuItem>
					<MenuItem value='no'>No</MenuItem>
				</Select>
			</FormControl>

			<FormControl className="fullWidth padded-select" >
				<Label
					id="layout"
					disableAnimation
					shrink
				>
					Layout
				</Label>
				<Select
					IconComponent={ExpandMoreRounded}
					name="layout"
					className="padded-select"
					data-testid="layout"
					labelId="layout"
					input={<Dropdown />}
					value={fields.layout || 'vertical' }
					onChange={handleChange}
				>
					<MenuItem value='horizontal'>Horizontal</MenuItem>
					<MenuItem value='vertical'>Vertical</MenuItem>
				</Select>
			</FormControl>
		</Box>
	</>
}
