/* eslint-disable eqeqeq */

import { handleDeleteSearchResponse, handleUpdateSearchLabel } from "../common/searchHelpers"

export const filterEmptySearch = searchTerms => 
	searchTerms.filter(e => !!e && !!e.type && !!e.field)


export const filterSearchTerms = (searchTerms) => 
	searchTerms.filter(e => 
		e.value != 'All' && 
		e.value != '' && 
		e.value != null && 
		e.value != 'null')


export const filterMap = (filters) => filters.filter(e => e.field && e.field != "").map(e => {

	const nestedQueryFields = [
		'compound_notes',
		'deal_entry_artist',
		'deal_entry_art_title',
		'deal_entry_inventory_number',
		'deal_entry_gallery_contact',
		'deal_entry_start_date',
	]

	return {
		field: e.field,
		type: e.type,
		value: nestedQueryFields.includes(e.field) ? JSON.stringify(e.value) : e.value?.toString(),
		operator: e.operator,
		isOptional: e.isOptional,
		nestedQueryType: e.nestedQueryType
	}
})

export const searchMap = (filters) => filters.map((e, index) => ({
	field: e.field,
	comparator: e.type,
	value: e.value?.toString(),
	ordinal: index+1
}))


export const saveMap = (filters) => filters.map((e, index) => ({
	field: e.field,
	type: e.comparator,
	value: e.value,
}))

export const emptySearch = {
	id: null,
	color: null,
	created_at: null,
	created_by: null,
	label: null,
	last_run_at: null,
	modified_at: null,
	modified_by: null,
	result_count: null
}


/**
 * A function to handle all search changes for searching
 * 
 * @param {*} event - the even passed with new data
 * @param {*} currentSearch - the current search passed with previous data
 * @param {*} setCurrentSearch - the function to change the current search
 * @param {*} type - the operator of the current search
 * @param {*} isSimpleSearch - if true, we return null when the passed value is null
 */
export const handleSearchChange = (event, currentSearch, setCurrentSearch, type = 'eq', isSimpleSearch = false) => {

	const name = event.target.name
	let value = event.target.value

	value = (value == null) ? null : value.toString()

	let search_terms

	if (currentSearch.search_terms?.find(el => el.field == name)) {
		search_terms = filterEmptySearch(currentSearch.search_terms.map(term => {
			if (term.field == name) {

				if (!value && isSimpleSearch) return null

				return {
					...term,
					type,
					value
				} 
			}
			return term
		}))
	} else {
		search_terms = filterEmptySearch(currentSearch.search_terms.concat([{
			id: new Date().getTime(),
			field: name,
			type,
			value,
		}]))
	}

	setCurrentSearch({
		...emptySearch,
		search_terms 
	})

	return search_terms
}

// Week picker filter change
export const handleLastModifiedChange = (values, currentSearch, setCurrentSearch) => {
	const start = values[0]?.toDateString()
	const end = values[1]?.toDateString()

	const new_terms = [{
		id: new Date().getTime(),
		field: 'modified_at',
		type: 'gte',
		value: start,
	}, {
		id: new Date().getTime()+1,
		field: 'modified_at',
		type: 'lte',
		value: end,
	}]

	let search_terms

	if (currentSearch.search_terms.find(el => el.field == 'modified_at')) {
		search_terms = filterSearchTerms(currentSearch.search_terms
			.filter(e => e.field != 'modified_at')
			.concat(new_terms))
	} else {
		search_terms = filterSearchTerms(currentSearch.search_terms.concat(new_terms))
	}

	setCurrentSearch({
		...emptySearch,
		search_terms
	})
}

// Save a search
export const saveSearch = (currentSearch, openSnackbar, severity, setSavedSearchDialog) => {

	if (currentSearch.search_terms?.length == 0) {
		openSnackbar(severity.WARNING, "Cannot save empty search.")
		return
	}

	else {
		setSavedSearchDialog({
			open: true,
			savedSearch: currentSearch
		})
	}
	
}

// Update a saved searches label
export const updateLabel = (search, label, updateSearch, savedSearches, setSavedSearches, openSnackbar) => {

	// return if no change
	if (search.label === label) return

	const variables = {
		id: search.id,
		label,
	}

	updateSearch({ variables: { UpdateSearchInput: variables } })
		.then((response) =>
			handleUpdateSearchLabel(
				response,
				label,
				search,
				savedSearches,
				setSavedSearches,
				openSnackbar
			)
		)

}

// Delete a search
export const handleDeleteSearch = (search, updateSearch, savedSearches, setSavedSearches, openSnackbar) => {

	const variables = {
		id: search.id,
		is_deleted: true,
	}

	updateSearch({ variables: { UpdateSearchInput: variables } }).then(
		(response) =>
			handleDeleteSearchResponse(
				response,
				savedSearches,
				search,
				setSavedSearches,
				openSnackbar
			)
	)
}


export const formatForFuzzySearch = (value) => value.trim().split(' ').map(w => `${w}~1`).join(' ')
