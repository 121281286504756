import { Paper, Menu, MenuItem } from '@mui/material'
import { CancelButton, SaveCardButton } from '../input/Buttons'
import { DispatchContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { UPDATE_USER } from './Queries.js'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router'
import React, { useContext, useEffect, useCallback } from 'react'
import TextBox from '../input/Text/TextBoxDark'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { getYear } from 'date-fns'
import NumberFormat from 'react-number-format'
import makeStyles from '@mui/styles/makeStyles'
import { FindModeInput } from '../navigation/Tabs/TabbedPage'

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false,
}

/* eslint-disable eqeqeq */
export default function UserDetails(props) {

	const useStyles = makeStyles(theme => ({
		userGoalsRoot: {
		  "& > *": {
				color: !props.readOnly ? "#fff" : "black",
		  }
		}
	}))
	
	const classes = useStyles()
	const navigate = useNavigate()
	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const [state, setState] = React.useState(initialState)
	const [goalsInput, setGoalsInput] = React.useState([])

	const [updateUser, { loading, error }] = useMutation(UPDATE_USER, {
		onError: (error) => {
			// On failure, reset userInput state, don't touch user state and show error
			resetGoalsInput(props.user)
			openSnackbar(severity.ERROR, 'Could not update Goals card.')
			handleClose()
		},
		onCompleted: (response) => {
			if (response.updateUser?.success === true) {
				// Success
				openSnackbar(severity.SUCCESS, response.updateUser.message)
				// On success change user state
				props.onChange({
					...props.user,
					goals: response.updateUser.user.goals,
				})
			} else {
				// On failure, reset userInput state, don't touch user state and show error
				resetGoalsInput(props.user)
				openSnackbar(severity.ERROR, response.updateUser.message)
			}
		},
	})


	useEffect( () => {
		if(error?.graphQLErrors){
			const gqlErrors = error.graphQLErrors
			gqlErrors.forEach( (e) => {
				if(e.message === "autherror-admin"){
					openSnackbar(severity.ERROR, "Error: Editing user details requires admin login")
					navigate("/admin/login")
				}
			} )
		}

	}, [navigate, error, openSnackbar])
	// Loading, Error
	useEffect(() => {
		if (loading) openSnackbar(severity.INFO, 'Saving goals card...')
	}, [loading, openSnackbar])

	const resetGoalsInput = (user) => {
		if (!user) return
		setGoalsInput(user.goals)
	}

	React.useEffect(() => {
		resetGoalsInput(props.user)
	}, [props.user])

	const handleClick = (event) => {
		if (props.findMode) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
			editable: false,
		})
	}

	const handleClose = (option) => {
		if (option === 'edit') {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	/**
	 * Updates the correct column and type of a goal with the passed new value
	 * @param {*} column
	 * @param {*} type
	 * @param {*} newValue
	 */
	const updateGoalsInput = (column, type, newValue) => {
		if (newValue < 0) return

		let tempGoalsInput = JSON.parse(JSON.stringify(goalsInput))
		let index = tempGoalsInput.findIndex(
			(goal) => goal.goal_type_id == type
		)

		if (index !== -1) {
			
			tempGoalsInput[index][column] = newValue
			setGoalsInput(tempGoalsInput)		
			
		} else {
			tempGoalsInput.push({
				goal_type_id: type,
				user_id: props.user.id,
				start_at: getYear(new Date()) + '-01-01',
				end_at: getYear(new Date()) + '-12-30',
				target: column === 'target' ? newValue : null,
				progress: column === 'progress' ? newValue : null,
			})
			
			setGoalsInput(tempGoalsInput)
		}
	}

	return (
		<Paper
			className="qv-margin-no-top"
			onContextMenu={(e) => (!state.mouseX && !props.readOnly ? handleClick(e) : null)}
			id="user-details"
			data-testid="card-annual-goals"
		>
			<h1 className="card-title">
				<span>Annual Goals</span>
				{state.editable && (
					<>
						<div className="spacer"></div>
						<CancelButton
							variant="contained"
							size="small"
							onClick={() => {
								resetGoalsInput(props.user)
								handleClose()
							}}
						>
							Cancel
						</CancelButton>
						<SaveCardButton
							variant="contained"
							color="secondary"
							size="small"
							disabled={loading}
							onClick={() => {

								// Delete unused values
								goalsInput.forEach(goal => {
									delete goal.__typename
									delete goal.type
									goal.progress = goal.progress ? parseInt(goal.progress) : 0
									goal.target = goal.target ? parseInt(goal.target) : 0
								})				
								
								updateUser({
									variables: {
										UserInput: {
											id: props.user.id,
											goals: goalsInput
										}
									},
								})

								handleClose()
							}}
						>
							Save
						</SaveCardButton>
					</>
				)}
			</h1>
			<div className="user-goals-body">
				<TableContainer>
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell
									style={{ borderBottom: '1px solid white' }}
								></TableCell>
								<TableCell
									style={{ borderBottom: '1px solid white' }}
									align="left"
								>
									Year To Date
								</TableCell>
								<TableCell
									style={{ borderBottom: '1px solid white' }}
									align="left"
								>
									Goal
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow key="Sales-Row">
								<TableCell component="th" scope="row">
									Sales
								</TableCell>
								<TableCell align="left">
									{!props.findMode ?
										<NumberFormat
											style={{ width: '100%' }}
											className={classes.userGoalsRoot}
											customInput={TextBox} 
											value={goalsInput?.find((item) => item.goal_type_id == 3)?.progress || ""}
											disabled={!state.editable}
											readOnly={!state.editable}
											prefix="$"
											placeholder="-"
											thousandSeparator
											allowLeadingZeros={true} /* This prop is intentionally reversed due to a bug in the library. */
											onChange={(e)=>{
												updateGoalsInput('progress',3, parseInt(e.target.value
													.replace('$', '')
													.replace(/,/g, ''), 10))
											}}
										/> 
										: <FindModeInput field="sales_ytd" largeInput/>}
								</TableCell>
								<TableCell align="left">
									{!props.findMode ?
										<NumberFormat 
											style={{ width: '100%' }}
											className={classes.userGoalsRoot}
											customInput={TextBox} 
											value={goalsInput?.find((item) => item.goal_type_id == 3)?.target || ""}
											disabled={!state.editable}
											readOnly={!state.editable}
											prefix="$"
											placeholder="-"
											thousandSeparator
											allowLeadingZeros={true} /* This prop is intentionally reversed due to a bug in the library. */
											onChange={(e)=>{
												updateGoalsInput('target',3, parseInt(e.target.value
													.replace('$', '')
													.replace(/,/g, ''), 10))
											}}
										/>
										: <FindModeInput field="sales_goal" largeInput/>}
								</TableCell>
							</TableRow>
							<TableRow key="Consignments-Row">
								<TableCell component="th" scope="row">
									Consignments
								</TableCell>
								<TableCell align="left">
									{!props.findMode ?
										<NumberFormat
											style={{ width: '100%' }}
											value={
												goalsInput?.find(
													(item) => item.goal_type_id == 1
												)?.progress || ''
											}
											customInput={TextBox} 
											disabled={!state.editable}
											readOnly={!state.editable}
											className={classes.userGoalsRoot}
											allowLeadingZeros={true} /* This prop is intentionally reversed due to a bug in the library. */
											decimalSeparator=''
											placeholder="-"
											onChange={(e) => {
												updateGoalsInput(
													'progress',
													1,
													parseInt(e.target.value, 10)
												)
											}}
										/>
										: <FindModeInput field="consignments_ytd" largeInput/>}

								</TableCell>
								<TableCell align="left">
									{!props.findMode ?
										<NumberFormat
											style={{ width: '100%' }}
											value={
												goalsInput?.find(
													(item) => item.goal_type_id == 1
												)?.target || ''
											}
											customInput={TextBox} 
											readOnly={!state.editable}
											disabled={!state.editable}
											className={classes.userGoalsRoot}
											decimalSeparator=''
											placeholder="-"
											allowLeadingZeros={true} /* This prop is intentionally reversed due to a bug in the library. */
											onChange={(e) => {
												updateGoalsInput(
													'target',
													1,
													parseInt(e.target.value, 10)
												)
											}}
										/>
										: <FindModeInput field="consignments_goal" largeInput/>}
								</TableCell>
							</TableRow>
							<TableRow key="Outreach-Row">
								<TableCell component="th" scope="row">
									Outreach
								</TableCell>
								<TableCell align="left">
									{!props.findMode ?
										<NumberFormat
											style={{ width: '100%' }}
											value={
												goalsInput?.find(
													(item) => item.goal_type_id == 2
												)?.progress || ''
											}
											customInput={TextBox} 
											readOnly={!state.editable}
											disabled={!state.editable}
											className={classes.userGoalsRoot}
											decimalSeparator=''
											placeholder="-"
											allowLeadingZeros={true} /* This prop is intentionally reversed due to a bug in the library. */
											onChange={(e) => {
												updateGoalsInput(
													'progress',
													2,
													parseInt(e.target.value, 10)
												)
											}}
										/>
										: <FindModeInput field="outreach_ytd" largeInput/>}
								</TableCell>
								<TableCell align="left">
									{!props.findMode ?
										<NumberFormat
											style={{ width: '100%' }}
											value={
												goalsInput?.find(
													(item) => item.goal_type_id == 2
												)?.target || ''
											}
											customInput={TextBox} 
											readOnly={!state.editable}
											disabled={!state.editable}
											className={classes.userGoalsRoot}
											decimalSeparator=''
											placeholder="-"
											allowLeadingZeros={true} /* This prop is intentionally reversed due to a bug in the library. */
											onChange={(e) => {
												updateGoalsInput(
													'target',
													2,
													parseInt(e.target.value, 10)
												)
											}}
										/>
										: <FindModeInput field="outreach_goal" largeInput/>}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</div>

			<Menu
				keepMounted
				open={state.mouseY !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					state.mouseY !== null && state.mouseX !== null
						? { top: state.mouseY, left: state.mouseX }
						: undefined
				}
			>
				<MenuItem onClick={() => handleClose('edit')}>Edit</MenuItem>
			</Menu>
		</Paper>
	)
}
