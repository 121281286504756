import * as Colors from '../styles/colors/Colors'
import makeStyles from '@mui/styles/makeStyles'
import { Badge, Tooltip } from "@mui/material"

import withStyles from '@mui/styles/withStyles'

export const chipStyles = {
	marginRight: 4, 
	marginBottom: 4
}

export const tagChipShadow = {
	boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'
}

export const LightTooltip = withStyles((theme) => ({
	tooltip: {
	  backgroundColor: theme.palette.common.white,
	  color: 'rgba(0, 0, 0, 0.87)',
	  boxShadow: theme.shadows[1],
	},
}))(Tooltip)

export const StyledBadge = withStyles((theme) => ({
	badge: {
	  backgroundColor: '#44b700',
	  color: '#44b700',
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		borderRadius: '100%',
	  '&::after': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			border: '1px solid currentColor',
			content: '""',
	  },
	},
}))(Badge)

export const skeletonStyle = {
	height: '3.88em',
	marginTop: '0.8em',
	marginBottom: '-0.6em',
}

export const searchStyles = makeStyles(() => ({
	searchSelection: {
		paddingRight: "1em",
		"&:last-child": {
			paddingRight: "0em",
		}
	},
	searchBar: {
		paddingBottom: "1em"
	},
	searchDate: {
		paddingRight: "1em",
		"&:last-child": {
			paddingRight: "0em",
		}
	}
}))


export const avatarStyles = makeStyles(theme => ({
	large: {
		width: 210,
		height: 210
	},
	small: {
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
	medium: {
		width: theme.spacing(4.5),
		height: theme.spacing(4.5),
	},
	bigger: {
		width: 40,
		height: 40,
	}
}))

export const autoCompleteStyles = makeStyles((theme) => ({
	inputRoot: {
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#ced4da',
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: '#ced4da',
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: Colors.editAdd,
			borderWidth: '1px',
		},
	},
}))

export const limitedAutocompleteStyles = makeStyles(theme => ({
	inputRoot: {
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#ced4da"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#ced4da"
		},
		"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: Colors.editAdd,
			borderWidth: "1px"
		},
		"&.Mui-error .MuiOutlinedInput-notchedOutline": {
			borderColor: "red"
		},
		"&.Mui-error:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "red"
		},
	},
	popupIndicatorOpen: {
		transform: 'none !important'
	},
}))

export const autoCompleteErrorStyles = makeStyles(theme => ({
	inputRoot: {
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#ced4da"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#ced4da"
		},
		"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: Colors.editAdd,
			borderWidth: "1px"
		},
		"&.Mui-error .MuiOutlinedInput-notchedOutline": {
			borderColor: "red"
		},
		"&.Mui-error:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "red"
		},
	},
}))

export const autoCompleteErrorStylesNoBorder = makeStyles(theme => ({
	inputRoot: {
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "white"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "white"
		},
		"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: Colors.editAdd,
			borderWidth: "1px"
		},
		"&.Mui-error .MuiOutlinedInput-notchedOutline": {
			borderColor: "red"
		},
		"&.Mui-error:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "red"
		},
	},
}))

export const typeStyle = {
	fontWeight: 500,
}
