/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Undo } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import TimelapseOutlinedIcon from '@mui/icons-material/TimelapseOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Button, IconButton, Menu, MenuItem, Paper, Tooltip } from '@mui/material'
import {
	DataGridPro, GridToolbarColumnsButton,
	GridToolbarContainer, GridToolbarDensitySelector
} from '@mui/x-data-grid-pro'
import clsx from 'clsx'
import { useCallback, useContext, useEffect, useState } from 'react'
import { elementReady, formatDate, getListHeader, restoreColumnSettings } from '../../common/helpers'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { DispatchContext } from '../../store'


function arraymove(arr, fromIndex, toIndex) {
	const element = arr[fromIndex]
	arr.splice(fromIndex, 1)
	arr.splice(toIndex, 0, element)
}

function hidden(params) {
	return params.row.is_private && !params.row.created_at
}

function getCols({
	loading,
	actionMenu,
	density
}) {
	/** @type import('@mui/x-data-grid-pro').GridColDef[] */

	const columns = [
		{
			field: 'Actions',
			headerName: '',
			minWidth: 50,
			width: 50,
			resizable: false,
			sortable: false,
			renderCell: actionMenu
		},
		{
			field: 'id',
			headerName: 'ID',
			sortable: true,
			type: 'String',
			width: 100,
		},
		{
			field: 'type',
			headerName: 'Type',
			sortable: false,
			renderCell: (params) => {
				if (params.row?.type?.value && params.row.type.value == 'Art')
					return <>
						<Tooltip arrow placement="right-start" title={params.row.type.value}>
							<img alt='art' style={{ maxHeight: '24px', maxWidth: '24px' }}
								src={`/images/icons/White/Art.svg`} />
						</Tooltip>
					</>
				else if (params.row?.type?.value && params.row.type.value == 'Contact')
					return <>
						<Tooltip arrow placement="right-start" title={params.row.type.value}>
							<img alt='contact' style={{ maxHeight: '24px', maxWidth: '24px' }}
								src={`/images/icons/White/Contacts.svg`} />
						</Tooltip>
					</>
				else return <DoneIcon />
			}
		},
		{
			field: 'inserted_row',
			headerName: 'Count',
			sortable: false,
			valueGetter: params => {
				const count = params.api.getRow(params.id).inserted_row
				return count == 0 ? '-' : count
			},
		},
		{
			field: 'status',
			headerName: 'Status',
			sortable: false,
			renderCell: (params) => {
				if (params.row?.status?.value && params.row.status.value == 'Completed')
					return <>
						<Tooltip arrow placement="right-start" title={params.row.status.value}>
							<DoneIcon color="success" />
						</Tooltip>
					</>
				else if (params.row?.status?.value && params.row.status.value == 'Failed')
					return <>
						<Tooltip arrow placement="right-start" title={params.row.status.value}>
							<CloseIcon color="error" />
						</Tooltip>
					</>
				else if (params.row?.status?.value && params.row.status.value == 'Invalid')
					return <>
						<Tooltip arrow placement="right-start" title={params.row.status.value}>
							<WarningAmberOutlinedIcon color="warning" />
						</Tooltip>
					</>
				else if (params.row?.status?.value && params.row.status.value == 'Processing')
					return <>
						<Tooltip arrow placement="right-start" title={params.row.status.value}>
							<TimelapseOutlinedIcon color="primary" />
						</Tooltip>
					</>
				else if (params.row?.status?.value && params.row.status.value == 'Uploading')
					return <>
						<Tooltip arrow placement="right-start" title={params.row.status.value}>
							<FileUploadOutlinedIcon color="primary" />
						</Tooltip>
					</>
				else return <p>Unknown Status</p>
			}
		},
		{
			field: 'name',
			headerName: 'Name',
			sortable: true,
			type: 'String',
			minWidth: 250,
			resizable: true,
		},
		{
			field: "created_at",
			headerName: "Created At",
			sortable: true,
			minWidth: 130,
			renderCell: params => {
				if (hidden(params)) return <span>-</span>
				const fDate = params.row?.modified_at ? formatDate(params.row?.created_at, "MM'-'dd'-'yyyy") : "-"
				return <span>{fDate}</span>
			}
		},

	]
	return columns
}


/**
 * @typedef ImportDataGridProps
 * @property {Object[]} rows All rows of {Useres} to be displayed
 * @property {function} setRows
 * @property {boolean} loading If a loading is in progress
 * @property {function} saveAndGo Function called on row doubleClick
 * @property {string} mainSearchPage
 * @property {filterComparator} userId
 * @property {Object} selectedRow
 * @property {callback} onSelect
 * @property {filterComparator} totalItems
 * @property {number} limit
 * @property {Object} variables
 * @property {function} setLimit
 * @property {number} activeStep
 * @property {function} setActiveStep
 * @property {Object} sortModel
 * @property {function} onSortModelChange
 * @property {boolean} noScrollToTop do not scroll to top of page on pagination
 */

/**
 * @param {ImportDataGridProps} props
 */
export function ImportDataGrid(props) {
	const columnSettingsKey = 'ImportDataGrid'

	// handle null response.
	const rows = props.rows || []

	const [density, setDensity] = useState(
		localStorage.getItem(`${columnSettingsKey}.density`) || 'comfortable')
	useEffect(() => localStorage.setItem(`${columnSettingsKey}.density`, density),
		[density])

	const [columnVisibilityModel, setColumnVisibilityModel] = useState(
		JSON.parse(localStorage.getItem(`${columnSettingsKey}.visibility`) || null)
	)
	useEffect(() => localStorage.setItem(`${columnSettingsKey}.visibility`, JSON.stringify(columnVisibilityModel)),
		[columnVisibilityModel])

	const [currentImport, setCurrentImport] = useState(null)
	const [anchorEl, setAnchorEl] = useState(null)
	const [columns, setColumns] = useState([])

	const openContextMenu = (field) => (e) => {
		e.preventDefault()
		e.stopPropagation()
		setAnchorEl(e[field])
	}

	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	const actionMenu = params => {
		if (params.row.is_private && !params.row.created_at) {
			return null
		}

		return <IconButton
			aria-label="More"
			style={{
				padding: '6px',
				marginRight: '-9px',
			}}
			onClick={e => {
				if (params.row.disabled || params.row.hidden) return
				setCurrentImport(params.row)
				openContextMenu('currentTarget')(e)
			}}
		>
			<MoreVertIcon
				style={params.row.isPrivate ? { color: 'white' } : null}
			/>
		</IconButton>
	}

	const updateColumns = () => {

		const cols = getCols({
			loading: props.loading,
			actionMenu,
			density
		})

		// restore on load.
		const columnSettings = JSON.parse(localStorage.getItem(columnSettingsKey))
		if (columnSettings?.length) restoreColumnSettings(cols, columnSettings)

		if (!columnVisibilityModel) {
			const obj = cols.filter(c => c.hide)
				.reduce((acc, el) => {
					acc[el] = false
					return acc
				}, {})
			setColumnVisibilityModel(obj)
		}

		setColumns([...cols])
	}

	// on Load, load column settings, sort, merge in (once everything is loaded.)
	useEffect(() => {
		if (props.userId) updateColumns()
	}, [props.userId, density])

	// update the flag menu/toggle any time the props change
	// to prevent "stale closures"
	useEffect(() => {
		setColumns(cols => {
			return [...cols]
		})
	}, [props.variables, props.totalItems, props.userId, density])


	// on unmount, stash columns back in storage. (eventually in DB)
	useEffect(() => {

		// stash on nav. (`columns` needs to be in the deps in order to be accessed.)
		return () => {
			if (columns.length) {
				const columnSettings = columns.map(col => ({
					field: col.field,
					width: col.width,
					minWidth: col.minWidth,
					hide: col.hide
				}))
				localStorage.setItem(columnSettingsKey, JSON.stringify(columnSettings))
			}
		}
	}, [columns])


	const handleClose = () => setAnchorEl(null)

	const resetGrid = () => {
		localStorage.removeItem(columnSettingsKey)
		localStorage.removeItem(`${columnSettingsKey}.visibility`)
		setColumnVisibilityModel(null)
		updateColumns()
	}

	const CustomToolBar = () => <GridToolbarContainer>
		<Button
			size="small"
			startIcon={<Undo />}
			onClick={resetGrid}
		>
			Reset
		</Button>
		<GridToolbarColumnsButton />
		<GridToolbarDensitySelector />
	</GridToolbarContainer>


	// Scroll to the top of current data grid component when paginating
	useEffect(() => {

		if (props.loading === false && !props.scrollIntoView) {

			elementReady(`.${columnSettingsKey}`)
				.then(searchList =>
					searchList.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					})
				)
		}

	}, [props.activeStep])


	return <>
		<Paper
			className={clsx({
				'data-grid-padding': true,
				[columnSettingsKey]: true
			})}
			data-testid={`card-${props.listHeader ? props.listHeader : "Results "}`}
			style={{
				...props.style,
				flexGrow: 1
			}}
		>
			<h1 className="card-title" style={{
				paddingTop: '1em',
				paddingLeft: '2em',
				display: "flex"
			}}>
				{getListHeader(props.listHeader, props.totalItems, props.canSee)}
			</h1>

			<div style={{ height: 'calc(100% - 4em)', width: '100%' }}>
				<DataGridPro
					components={{ Toolbar: CustomToolBar }}
					rowCount={props.totalItems || 0}
					rows={rows}
					columns={columns}
					onRowDoubleClick={() => openSnackbar(severity.WARNING, 'Double-clicks not supported on imports')}
					density={density}
					disableColumnMenu
					pagination
					paginationMode="server"
					sortingMode="server"
					pageSize={props.limit}
					onPageSizeChange={props.setLimit}
					rowsPerPageOptions={[10, 25, 50]}
					onSelectionModelChange={(newSelectionModel) => {
						// eslint-disable-next-line eqeqeq
						props.onSelect(rows.find(row => row.id == newSelectionModel[0]))
					}}
					selectionModel={props.selectedRow?.id ? [props.selectedRow?.id] : []}
					page={props.activeStep}
					onPageChange={props.setActiveStep}
					onSortModelChange={props.onSortModelChange}
					loading={props.loading}
					onColumnVisibilityModelChange={setColumnVisibilityModel}
					columnVisibilityModel={columnVisibilityModel || {}}
					onColumnOrderChange={({ oldIndex, targetIndex }) => {
						arraymove(columns, oldIndex, targetIndex)
						setColumns([...columns])
					}}
					onColumnWidthChange={({ colDef, width }) => {
						columns.find(col => col.field === colDef.field).width = width
						setColumns([...columns])
					}}
					onStateChange={(args) => setDensity(args.density?.value ?? 'comfortable')}
				/>
			</div>

			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={!!anchorEl}
				onClose={handleClose}
			>
				<MenuItem
					onClick={() => {
						props.onSelect(currentImport)
					}}
				>Details</MenuItem>
			</Menu>
		</Paper>

	</>
}
