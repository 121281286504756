/* eslint-disable eqeqeq */
import React from 'react'
import { format, isValid } from 'date-fns'
import cloneDeep from 'lodash/cloneDeep'
import { addDays } from 'date-fns'
import Chip from '@mui/material/Chip'
import * as Colors from '../styles/colors/Colors'
import { alpha } from '@mui/material/styles'
import { Skeleton } from '@mui/material'
import { parseISO } from 'date-fns'
import { ES_SEARCH_MAX_RESULT_COUNT } from '../constants/values'
import { severity } from '../Snackbar/CustomizedSnackbar'
import Thumbnail from '../Thumbnail/Thumbnail'
import { Tooltip } from '@mui/material'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

/**
 * Determines the subtitle of the supplied art pieces thumbnail.
 * @param {*} row - the Art Piece supplied
 */
export const getArtistThumbnailDetail = (row) => {
	if (!row) return null
	else if (row?.artist?.first_name && row?.artist?.last_name) {
		return `${row?.artist?.first_name} ${row?.artist?.last_name}`
	} else if (row?.artist?.first_name && !row?.artist?.last_name) {
		return `${row?.artist?.first_name}`
	} else if (!row?.artist?.first_name && row?.artist?.last_name) {
		return `${row?.artist?.last_name}`
	} else {
		return null
	}
}


/**
 * Determines the activity displayed for the current row.
 * @param {*} row  - the Art Piece supplied
 * @param {*} loading - loading sets the skeleton animation to true
 */
export const getArtActivity = (row, loading) => {
	if (row?.disabled) {
		return (
			<span>-</span>
		)
	}

	if (row?.is_active) return <div style={{ color: 'var(--available)', fontWeight: '500'}}>ACTIVE</div>
	return <div style={{ color: 'var(--sold)', fontWeight: '500' }}>INACTIVE</div>
}


export const getArtInventoryNumber = (root, includeCopy = false) => {

	let returnable = ""
	if (!root) return "-"

	if (root.inventory_number_prefix || root.inventory_number || root.inventory_number_suffix) {
		returnable += [root.inventory_number_prefix, root.inventory_number, root.inventory_number_suffix].filter(a=>a).join('-')
	} 

	if (returnable && includeCopy) {
		returnable += root?.edition_copy_number ? `.${root?.edition_copy_number}` : ''
		return returnable
	}

	else if (returnable && !includeCopy) {
		return returnable
	}

	else return "-"
}

export const getArtEditionText = (artInput) => {
	const {
		edition_copy_number, edition_copy_total, edition_ap, edition_ap_total
	} = artInput

	if (!edition_ap_total) {
		if (!edition_copy_total && !edition_ap)
			return edition_copy_number || ''

		if (!edition_copy_number && edition_copy_total && !edition_ap)
			return `${edition_copy_total} Editions`

		if (!edition_copy_number && !edition_copy_total && edition_ap)
			return `${edition_ap}`

		if (!edition_copy_number && edition_copy_total && edition_ap)
			return `${edition_copy_total}, ${edition_ap}`

		if (edition_copy_number && !edition_copy_total && edition_ap)
			return `${edition_copy_number}, ${edition_ap}`

		if (!edition_ap)
			return `${edition_copy_number} of ${edition_copy_total}`

		return `${edition_copy_number} of ${edition_copy_total}, ${edition_ap}`
	} else {
		if (!edition_copy_total && !edition_ap)
			return `${(edition_copy_number && ', ' )|| ''}${edition_ap_total}`

		if (!edition_copy_number && edition_copy_total && !edition_ap)
			return `${edition_copy_total} Editions, ${edition_ap_total}`

		if (!edition_copy_number && !edition_copy_total && edition_ap)
			return `${edition_ap} of ${edition_ap_total}`

		if (!edition_copy_number && edition_copy_total && edition_ap && edition_ap_total)
			return `${edition_copy_total}, ${edition_ap} of ${edition_ap_total}`

		if (!edition_copy_number && edition_copy_total && edition_ap && !edition_ap_total)
			return `${edition_copy_total}, ${edition_ap}`

		if (edition_copy_number && !edition_copy_total && edition_ap)
			return `${edition_copy_number}, ${edition_ap} of ${edition_ap_total}`

		if (!edition_ap)
			return `${edition_copy_number} of ${edition_copy_total}, ${edition_ap_total}`

		return `${edition_copy_number} of ${edition_copy_total}, ${edition_ap} of ${edition_ap_total}`
	}
}

export const renewAccessToken = async () => {
	const response = await fetch('/api/renew')
	const {access_token} = await response.json()
	
	if(!access_token || response.status===400){
		window.location.pathname = '/'
	}
	
	window.token = access_token
	window.token_exp = Date.now() + 3600000 // 1 hour from now
}

/**
 * Wrapper on the fetch API, this function will add the token and handle renewal! 
 */
export const fetchWithAuth = async (url, options={}) => {
	//if token is expired or about to expire, redirect to login page
	const fiveMinutes = 5 * 60000
	if(!window.token || !window.token_exp || Date.now() > window.token_exp - fiveMinutes){
		try {
			await renewAccessToken()
		} catch(e) {
			console.error(e)
			window.location.pathname = '/'
			return
		}
	} 

	const {headers} = options
	const token = window.token
	const headersWithAuth = {
		...headers,
		authorization: token ? `Bearer ${token}` : 'Bearer none',
		'X-Client-Version': window.SERVER_DATA?.VERSION?.Client
	}

	return fetch(url, {
		...options,
		headers: headersWithAuth
	})
}

export const addQuillTitles = () => {

	const isMac = navigator.platform.toUpperCase().indexOf('MAC')>=0

	// Add Tooltips to Keyboard Shortcuts
	setTimeout(() => {
		var bold = document.querySelector('button.ql-bold')
		if (bold) bold.title = isMac ? "⌘ B" : "Ctrl B"

		var italic = document.querySelector('button.ql-italic')
		if (italic) italic.title = isMac ? "⌘ I" : "Ctrl I"

		var underline = document.querySelector('button.ql-underline')
		if (underline) underline.title = isMac ? "⌘ U" : "Ctrl U"
	}, 0)
}

export const getFullName = (contact, lastNameFirst) => {

	if (!contact?.last_name && !contact?.first_name && contact.code_name ) {
		return contact.code_name
	}

	if (lastNameFirst) return [
		contact?.last_name, 
		contact?.first_name
	].filter(a => a).join(', ')

	return [
		contact?.first_name,
		contact?.last_name, 
		contact?.is_deceased ? '- Deceased' : null
	].filter(a => a).join(' ') || null
}

export const getContactLabel = (contact) => {
	if (!contact) return 'Loading...'

	const getNonCodename = () => (contact.is_company ? 
		(contact.company_name || null) :
		getFullName(contact)) || null


	if (contact.is_private && !contact.created_at) {

		return contact.code_name ? contact.code_name || getNonCodename()
			: contact.dynamic_generated_codename || getNonCodename()

	}

	if (contact.is_company && contact.company_name) return contact.company_name

	return `${getFullName(contact)}${contact.company_name ? ` (${contact.company_name})` : ''}`

}

export const getContactName = (contact, lastNameFirst) => {

	const getNonCodename = () => (contact.is_company ? 
		(contact.company_name || null) :
		getFullName(contact, lastNameFirst)) || null

	if (!contact) return null

	if (contact.is_private && !contact.created_at) {

		return contact.code_name ? contact.code_name || getNonCodename()
			: contact.dynamic_generated_codename || getNonCodename()

	}
	
	return getNonCodename()
}

export const getArtistName = (artist) => {
	if (!artist) return null
	return `${artist.first_name ? artist.first_name + " " : null}${artist.last_name || ''}`
}


export const getDealName = (deal) => {
	if (!deal) return null
	return deal.code_name || `Deal ${deal.id}`
}

// Generates a random pastel color
export function randomHSL() {
	// eslint-disable-next-line no-useless-concat
	return 'hsla(' + ~~(360 * Math.random()) + ',' + '70%,' + '50%,1)'
}

/**
 * Returns true when the passed array is empty from a permissions rule.
 *
 * @param {*} array - An array that has been nullified by graphql-shield,
 * 						only returns true when appropriate.
 */
export const isNullArray = (array) => array == null
	|| !array.length ? false : array.every(e => e === null || e.id === null)

export const getNonNullArray = (array) => {
	if (array == null) return []
	else return array.filter(e => e !== null && e.id !== null) || []
}

export const getInitialism = (string) => string?.split(' ').map((n) => n[0])


/**
 * Returns the passed string shortened by an ellipse to the specified length.
 * @param {*} string
 * @param {*} length
 */
export const shorten = (string, length) => {
	if (!string) return string
	return string.length < length ?
		string : `${string.substring(0, length)}…`
}

/**
 * USD Number Format
 */
export const formatter = (currency) => new Intl.NumberFormat(undefined, {
	style: 'currency',
	currency,
})

/**
 * Gets only the truthy attributes of an object.
 * @param {*} obj
 */
export const truthyObj = (obj) => {
	let truthy = cloneDeep(obj)
	for (var i in truthy) {
		if (!truthy[i]) { //if falsy
			delete truthy[i]
		}
	}
	return truthy
}

/**
 * This functions is capitalizes the passed string's first letter.
 * @param {*} string
 */
export function capitalizeFirstLetter(string) {
	if (!string) return string
	return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * This function formats a passed date no matter the type using date-fns.
 *
 * @param {*} date - the date to be formatted
 * @param {string} formatString - the formatting string passed
 * @return {string}
 */
export const formatDate = (date, formatString) => {

	if (date == null) return '-'

	if (typeof date === "number") {

		return format(
			new Date(date),
			formatString
		)
	} else if (typeof date === "string") {
		if (isValid(Number(date))) {
			return format( new Date(Number(date)), formatString )
		}
		try {
			const formattedDate = format(parseISO(date), formatString)
			return formattedDate
		} catch(e) {
			return date
		}
	} else if (typeof date === "object") {
		try{
			return format(
				date,
				formatString
			)
		} catch (e){
 				return JSON.stringify(date)
		}
	}

	return "-"
}


/**
 * This function returns a date object from a timestamp.
 *
 * @param {*} timestamp
 * @return {date}
 */
export const returnDate = (date) => {
	
	if (date == null) return null

	else if (typeof date === "number") {

		return new Date(date)

	} else if (typeof date === "string") {

		if (isValid(Number(date))) {
			return new Date(Number(date))
		}

		else {
			try {
				return parseISO(date)
			} catch (e) {
				return new Date(date)
			}
		}

	} else if (typeof date === "object") {
		return date
	}

	else return null
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 */
export function getRandomInt(min, max) {
	min = Math.ceil(min)
	max = Math.floor(max)
	return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * returns the inputString in proper case
 * @param {=String} inputString
 */
export const toProperCase = (inputString) => {
	return inputString.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
	})
}
/**
 * Returns a copy of the object with all fields starting with `__` removed
 * @param {=Object} obj
 */
export const stripMeta = obj =>
	JSON.parse(JSON.stringify(obj, (k,v) => (k.startsWith('__')) ? undefined : v))

/**
* Converts a long string of bytes into a readable format e.g KB, MB, GB, TB, YB
*  (from https://ourcodeworld.com/articles/read/713/converting-bytes-to-human-readable-values-kb-mb-gb-tb-pb-eb-zb-yb-with-javascript)
* @param {number} bytes The number of bytes.
*/
export function readableBytes(bytes) {
	const i = Math.floor(Math.log(bytes) / Math.log(1024))
	const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i]
}

/**
 * Returns a phrase with each word capatilized.
 * @param {*} phrase
 */
export const toTitleCase = (phrase) => {

	if (!phrase) return ''

	const returnable = phrase
		.toLowerCase()
		.replace(/_/g, ' ')
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')

	return returnable
}


/**
 * Returns true if a new communication object should be set to primary,
 * false if not.
 *
 * @param {*} type The type of the comm object.
 */
export const isPrimary = (props, type) => {
	let allDeleted = true

	if (props.contactInput[type].length === 0) return true
	for (let i = 0; i < props.contactInput[type].length; i++) {
		if (props.contactInput[type][i].is_deleted !== true) {
			allDeleted = false
		}
	}
	if (allDeleted === true) return true
	else return false
}


export const isValidEmail = email => {
	// eslint-disable-next-line no-useless-escape
	let regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
	return regex.test(String(email).toLowerCase())
}

export const isValidWebsite = (website, type = {}) => {

	const { is_social = false, value } = type

	if (!is_social) {
		let regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
		return regex.test(String(website).toLowerCase())
	}

	switch (value) {
	case 'LinkedIn':
	case 'Facebook':
	case 'Instagram':
	case 'WeChat':
		let regex1 = /^\S+$/
		return regex1.test(String(website).toLowerCase())
	case 'Twitter':
		let regex2 = /^@?(\w){1,15}$/
		return regex2.test(String(website).toLowerCase())	
	default:
		return false
	}
}

export const isValidPhone = phone => {
	// eslint-disable-next-line no-useless-escape
	return (/^\d{7,}$/).test(phone?.replace(/[\s()+\-\.]|ext/gi, ''))
}


/**
 * This function returns true if its a date
 */

export const isDate = (input) => Object.prototype.toString.call(input) === "[object Date]"


/**
 * This returns a date-fns date.
 *
 * 5pm today if it is before 5pm, else 5pm tomorrow.
 */
export const getDefaultDueDate = () => {

	let due_at

	if(new Date().getHours() >= 17) {

		// After 5pm
		due_at = addDays(new Date(), 1)
		due_at.setHours(17)
		due_at.setMinutes(0)
	} else {
		// Before 5pm
		due_at = new Date()
		due_at.setHours(17)
		due_at.setMinutes(0)
	}

	return due_at
}

/**
 * Get object details given the object.
 * @param {*} row
 */
export const getObjectDetails = (row) => {

	// Null Check
	if (!row)
		return {
			type: 'contact',
			title: null,
			subTitle: null,
			isPrivate: false,
			imgUrl: null,
			hidden: false,
		}

	let type = ''
	let title = ''
	let subTitle = ''
	let isPrivate = row.contact?.is_private || row.deal?.is_private || row.art?.is_private || false
	let imgUrl = null
	let hidden = false

	if (row.contact_id) {

		type = 'contact'
		title = getContactName(row.contact)
		subTitle = row.contact?.is_company ? ' ' : row.contact?.company_name || ' '
		imgUrl = row.contact?.imgUrl || null
		hidden = (row.contact?.is_private && !row.contact?.created_at) || false

	} else if (row.listing_id) {

		type = 'listing'
		title = row.listing?.title
		subTitle = row.listing?.subtitle
		imgUrl = row.listing?.imgUrl || null
		hidden = false

	} else if (row.art_id) {

		hidden = (row.art?.is_private && !row.art?.created_at) || false
		type = 'art'
		title = row.art?.code_name
		imgUrl = row.art?.imgUrl || null
		subTitle = null

	} else if (row.artist_id) {

		type = 'artist'
		title = `${row.artist?.first_name} ${row.artist?.last_name}`
		subTitle = row.artist?.genre?.map(e => e.artist_genre)?.join(", ") || ' '
		imgUrl = row.artist?.imgUrl || null
		hidden = false

	} else if (row.deal_id) {

		type = 'deal'
		title = `Deal ${row.deal_id}`
		subTitle = " "
		hidden = (row.deal?.is_private && !row.deal?.created_at) || false
	}

	return { type, title, subTitle, isPrivate, hidden, imgUrl }
}

// Color of action
export const getActionColor = (action) => {
	switch (action) {
	case "Edited":
		return "orange"
	case "Created":
		return "green"
	case "Deleted":
		return "red"
	default:
		return null
	}
}


/**
 *
 * @param {*} selectedIndex
 * @param {*} setSelectedIndex
 * @param {*} array
 * @param {*} setArray
 */
export const moveUp = (selectedIndex, setSelectedIndex, array, setArray) => {
	if (selectedIndex != null && selectedIndex !== 0) {
		let tempArray = [...array]
			.map((o) => ({...o}))

		tempArray[selectedIndex] = {
			...array[selectedIndex - 1],
			ordinal: selectedIndex + 1
		}

		tempArray[selectedIndex - 1] = {
			...array[selectedIndex],
			ordinal: selectedIndex
		}

		setSelectedIndex(selectedIndex - 1)
		setArray(tempArray)
	}
}

/**
 *
 * @param {*} selectedIndex
 * @param {*} setSelectedIndex
 * @param {*} array
 * @param {*} setArray
 */
export const moveDown = (selectedIndex, setSelectedIndex, array, setArray) => {
	if (selectedIndex != null && selectedIndex !== array.length - 1) {
		let tempArray = [...array]
			.map((o) => ({...o}))

		tempArray[selectedIndex] = {
			...array[selectedIndex + 1],
			ordinal: selectedIndex + 1
		}

		tempArray[selectedIndex + 1] = {
			...array[selectedIndex],
			ordinal: selectedIndex + 2
		}

		setSelectedIndex(selectedIndex + 1)
		setArray(tempArray)
	}
}


/**
	 * This returns a correctly styled chip displaying the passed art's status
	 * @param {*} row - the passed piece of art
	 * @param {*} loading - is the status loading
	 * @param {*} artStatus - the list of art status, from lookup context
	 */
export const getArtStatusChip = (row, loading, artStatus, pointer = false) => {

	if (row.disabled || (!row.status_id && !row?.status?.id)) {
		return (
			<Skeleton
				width='4em'
				animation={loading ? 'wave' : false}
			/>
		)
	}

	const isPrivate = row?.is_private || false

	const statusId = row.status?.id || row.status_id
	const label = artStatus?.find((status) => status.id == statusId).value

	const color = {
		"SOLD": Colors.sold,
		"Available": Colors.available,
		"Reserved": Colors.reserved,
	}

	const getBackgroundColor = () => {
		
		if (isPrivate) return color[label] != null ? alpha(color[label], 0.2) : alpha('#d3d3d3', 0.2)
		return color[label] != null ? alpha(color[label], 0.2) : alpha('#373A52', 0.2)
	}

	const getColor = () =>{

		if (isPrivate) return color[label] ?? '#d3d3d3'
		return color[label] ?? 'var(--text)'
	}

	return (
		<Chip
			label={label?.toLowerCase()}
			style={{
				color: getColor(),
				backgroundColor: getBackgroundColor(),
				cursor: pointer ? "pointer" : null,
				fontWeight: 400,
				fontSize: '11px',
				className: 'w-full',
				height: '24px',
				textTransform: 'capitalize',
			}}
		/>
	)
}

/**
 * This function returns a color based on the passed listing status.
 * @param {*} id
 * @param {*} list
 */
export const getListingStatusColor = (id, list) => {

	const status = list?.find(e => e.id == id)?.value

	switch (status) {
	case "Exhibited":
	case "Attended":
	case "Visited":
	case "Sent":
		return "var(--accept-changes)"

	case "Invited":
		return "var(--link)"

	case "Responded":
		return "var(--returned)"

	case "Added":
	case "Accepted":
		return "var(--edit-add)"

	case "Denied":
		return "var(--decline)"

	case "No Show":
		return "var(--reserved)"

	case "Confirmed":
		return "var(--consigned-in)" // green

	case "Pending":
		return "var(--edit-add)" // blue

	default:
		return "black"
	}
}

/**
	 * This returns a correctly styled chip displaying the passed deal's status
	 * @param {*} row - the passed deal
	 * @param {*} loading - is the status loading
	 */
export const getMyActiveDealsStatus = (row, loading, pointer = false) => {
	if (row.disabled || !row.deal_entry_status?.value) {
		return (
			<Skeleton
				variant="text"
				width={'70%'}
				animation={loading ? 'wave' : false}
			/>
		)
	}
	const statusId = row.deal_entry_status.id
	const label = row.deal_entry_status.value

	const color = {
		1: Colors.offerSent,
		2: Colors.noResponse,
		3: Colors.rejected,
		4: Colors.reserved,
		5: Colors.offerRejected,
		6: Colors.belowAsking,
		7: Colors.sold,
		13: Colors.sold,
		17: Colors.sold,
		21: Colors.sold,
		25: Colors.sold,

		10: Colors.offerSent,
		14: Colors.offerSent,
		18: Colors.offerSent,
		22: Colors.offerSent,

		11: Colors.reserved,
		15: Colors.reserved,
		19: Colors.reserved,
		23: Colors.reserved,

		12: Colors.rejected,
		16: Colors.rejected,
		20: Colors.rejected,
		24: Colors.rejected,

		26: Colors.offerSent,
		27: Colors.reserved,
		28: Colors.sold,

		29: Colors.offerSent,
		30: Colors.reserved,
		31: Colors.offerSent,
		32: Colors.reserved,
		33: Colors.sold
	}

	const theColor = color[statusId] || Colors.offerSent
	return (
		<Chip
			size="small"
			label={label}
			style={{
				color: theColor,
				backgroundColor: `#fff`,
				cursor: pointer ? "pointer" : null,
				border: `1px solid ${alpha(theColor, 0.2)}`
			}}
		/>
	)
}

export const removeTypenames = (searchTerms) => {
	return searchTerms.map(term => {
		delete term.__typename
		return term
	})	
}

/**
 * Waits for an element satisfying selector to exist, then resolves promise with the element.
 * Useful for resolving race conditions.
 *
 * @param selector
 * @returns {Promise}
 */
export function elementReady(selector) {
	return new Promise((resolve, reject) => {
		let el = document.querySelector(selector)
		if (el) {
			resolve(el)
		}
		new MutationObserver((mutationRecords, observer) => {
			// Query for elements matching the specified selector
			Array.from(document.querySelectorAll(selector)).forEach((element) => {
				resolve(element)
				//Once we have resolved we don't need the observer anymore.
				observer.disconnect()
			})
		}).observe(document.documentElement, {
			childList: true,
			subtree: true,
		})
	})
}

export const getListHeader = (listHeader = null, totalItems = null, canSee = true) => (
	<>
		{listHeader ? listHeader : 'Results '}
		{totalItems && totalItems !== ES_SEARCH_MAX_RESULT_COUNT && canSee !== false ? 
			' (' + totalItems + ')' : ''}
		{totalItems === ES_SEARCH_MAX_RESULT_COUNT ?
			` (${ES_SEARCH_MAX_RESULT_COUNT}+)` : ''}
		{canSee === false ? 
			<Tooltip
				enterDelay={500}
				title={'You do not have permission to view these results.'} arrow placement="top">
				<VisibilityOffIcon style={{
					color: 'grey',
					marginLeft: '0.4em',
					marginTop: '0.20em',
				}}/>
			</Tooltip> : null}
	</>
)

export const handleWebsitePaste = (e, currentWebsiteType, setModal, modal, openSnackbar) => {
	const input = e.clipboardData?.getData('Text')
	
	if (currentWebsiteType?.is_social && isValidWebsite(input, {})) {

		openSnackbar(severity.INFO, "Username parsed from url.")

		e.preventDefault()
		e.stopPropagation()

		const { value } = currentWebsiteType

		const paste = input.split('/')

		let handle = ''

		if (value === 'Twitter') handle += '@'
		
		if (paste[paste.length-1].length) {
			handle += paste[paste.length-1]
		} else {
			handle += paste[paste.length-2]
		}
		
		setModal({
			...modal,
			website: {
				...modal.website,
				website: handle
			}
		})
	}
}

export const getArtistThumbnail = (artist) => (
	<Thumbnail
		name={getArtistName(artist)}
		detail={artist.genre?.map(e => e.artist_genre).join(", ")?? " "}
		avatar={artist.imgUrl}
		type="artist"
		hideDetails={!artist.genre?.length}
		largeText
	/>
)

/**
 * 
 * @param {*} event 
 * @param {*} disable 
 * @param {*} row 
 * @param {*} toggle 
 * @param {*} setToggle 
 * @param {*} toggleFavorite 
 * @param {*} userId 
 * @param {*} objectType 
 * @param {*} openSnackbar 
 * @param {*} handleSuccess 
 * @returns 
 */
export const favoriteEntityRow = (event, row, toggle, setToggle, toggleFavorite, userId, objectType, openSnackbar, handleSuccess) => {
	event.stopPropagation()
	event.preventDefault()
	if (row.disabled) return
	else if (!toggle) {

		setToggle(row.id)
		toggleFavorite({
			variables: {
				ToggleFavoriteInput: {
					userId,
					objectType,
					objectId: row.id,
					isFavorite: !row.isFavorite,
				},
			},
		})
			.then((response) => {
				if (response && response.errors) {
					openSnackbar(
						severity.ERROR,
						'Could not toggle this favorite.'
					)
				} else if (
					response && !response.data?.toggleFavorite?.success === true
				) {
					openSnackbar(
						severity.ERROR,
						response.data?.toggleFavorite?.message || 
						'There was an error toggling this favorite.'
					)
				} else {
					handleSuccess()
				}
			})
			.catch((error) => {
				console.error(error)
				openSnackbar(
					severity.ERROR,
					'There was an error toggling this favorite.'
				)
			})
			.finally(() => setToggle(null))
	}
}

/**
 * 
 * @param {*} event 
 * @param {*} row 
 * @param {*} toggle 
 * @param {*} setToggle 
 * @param {*} toggleFavorite 
 * @param {*} userId 
 * @param {*} objectType 
 * @param {*} openSnackbar 
 * @param {*} handleSuccess 
 * @returns 
 */
export const flagEntityRow = (event, row, toggle, setToggle, toggleFlagged, userId, objectType, openSnackbar, handleSuccess) => {

	event.stopPropagation()
	event.preventDefault()
	if (row.disabled) return
	else if (!toggle) {

		setToggle(row.id)
		toggleFlagged({
			variables: {
				ToggleFlagInput: {
					userId,
					objectType,
					objectId: row.id,
					isFlagged: !row.isFlagged,
				},
			},
		})
			.then((response) => {
				if (response && response.errors) {
					openSnackbar(
						severity.ERROR,
						'Could not toggle this flag.'
					)
				} else if (
					response && response.data?.toggleFlagged?.success === false
				) {
					openSnackbar(
						severity.ERROR,
						'There was an error toggling this flag.'
					)
				} else {
					handleSuccess()
				}
			})
			.catch((error) => {
				console.error(error)
				openSnackbar(
					severity.ERROR,
					'There was an error toggling this flag.'
				)
			})
			.finally(() => setToggle(null))
	}
}

export const noteTypeComparator = (a, b) => {
	if (a.value < b.value) return -1
	else if (a.value > b.value) return 1
	else return 0
}

/**
 * Restores DataGrid cols from columnSettings stored in Application Local Storage.
 * 
 * @param {*} cols 
 * @param {*} columnSettings 
 */
export const restoreColumnSettings = (cols, columnSettings) => {
	// sort the columns, such that a new column will go to the end
	cols.sort((a, b) => {

		const ai = columnSettings.findIndex(c => c.field === a.field)
		const bi = columnSettings.findIndex(c => c.field === b.field)

		// New column should be put at the end of the row
		if (ai === -1 && bi >= 0) return 1
		else return ai - bi
	})

	cols.forEach(col => {
		const savedCol = columnSettings.find(c => c.field === col.field)
		Object.assign(col, savedCol)
	})
}

/**
 * Function to get the option label for gallery contact selections.
 */
export const getGalleryContactLabel = (option) => {
	
	if (option.is_deleted) {
		return [option.first_name, option.last_name].filter(e => e).join(" ") + ` (Deleted ID: ${option.id})`
	}

	return [option.first_name, option.last_name].filter(e => e).join(" ")
}

export function arraymove(arr, fromIndex, toIndex) {
	const element = arr[fromIndex]
	arr.splice(fromIndex, 1)
	arr.splice(toIndex, 0, element)
}

export const contactSorts = [
	{
		id: 'first_name',
		label: 'First Name'
	}, {
		id: 'last_name',
		label: "Last Name"
	}, {
		id: 'company_name',
		label: "Company Name"
	}
]

export const artSorts = [
	{
		id: "art.title",
		label: "Title",
	}, {
		id: "artist.first_name",
		label: "Artist First Name",
	}, {
		id: "artist.last_name",
		label: "Artist Last Name",
	}, {
		id: "art.year",
		label: "Date",
	}
]

export const getShowcaseIcon = (art, loading = false) => {
	if (!art) return null

	const showcase = (
		<img
			alt="showcase-enabled"
			style={{
				width: '24px',
				height: '24px',
				margin: '5px',
			}}
			src="/images/icons/Green/Showcase.svg"
		/>
	)

	const noShowcase = (
		<img
			alt="un-showcased-art"
			style={{
				width: '24px',
				height: '24px',
				margin: '5px',
			}}
			src="/images/icons/Grey/Showcase.svg"
		/>
	)

	const nullShowcase = <img
		alt="art"
		style={{
			margin: '5px',
			width: '24px',
			height: '24px',
		}}
		src="/images/icons/Grey/ShowcaseOutline.svg"
	/>

	if ((art.ready || art.showcase?.ready) && !loading) return showcase
	if (art.ready == null) return nullShowcase
	return noShowcase
}

export const listingTypes = {
	CLIENT_DEV: 8
}

export const groupByArt = (art) => {
	if (art?.status?.value?.includes('Available'))
		return 'Available'
	else if (art?.status?.value?.includes('SOLD'))
		return 'SOLD'
	else if (art?.status?.value?.includes('Reserved'))
		return 'Reserved'
	else
		return 'Other'
}

export const artComparator = (a, b) => {
	const order = ['Available', 'Reserved', 'SOLD', 'Other']
	return order.indexOf(b) - order.indexOf(a)
}

export const hexToHSL = (H, withLightness) => {
	// Convert hex to RGB first
	let r = 0, g = 0, b = 0
	if (H.length == 4) {
	  r = "0x" + H[1] + H[1]
	  g = "0x" + H[2] + H[2]
	  b = "0x" + H[3] + H[3]
	} else if (H.length == 7) {
	  r = "0x" + H[1] + H[2]
	  g = "0x" + H[3] + H[4]
	  b = "0x" + H[5] + H[6]
	}
	// Then to HSL
	r /= 255
	g /= 255
	b /= 255
	let cmin = Math.min(r,g,b),
		cmax = Math.max(r,g,b),
		delta = cmax - cmin,
		h = 0,
		s = 0,
		l = 0
  
	if (delta == 0)
	  h = 0
	else if (cmax == r)
	  h = ((g - b) / delta) % 6
	else if (cmax == g)
	  h = (b - r) / delta + 2
	else
	  h = (r - g) / delta + 4
  
	h = Math.round(h * 60)
  
	if (h < 0)
	  h += 360
  
	l = (cmax + cmin) / 2
	s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
	s = +(s * 100).toFixed(1)
	l = +(l * 100).toFixed(1)

	let hslStr = 'hsl(' + h + ',' + s + '%,' + l + '%)'
  
	return withLightness ? [hslStr, l] : hslStr
}

export const LIGHTNESS_THRESHOLD = 60

export const computeTextColorFromBGColor = (bgColor) => {
	const lightness = hexToHSL(bgColor, true)[1]
	return (lightness - LIGHTNESS_THRESHOLD > 0) ? 'var(--text)' : 'var(--white)'
}

/**
 * Return a "safe" interval for future date-fns update (v. 3)
 * @param {import('date-fns').Interval} interval 
 * @returns {import('date-fns').Interval}
 */
export const safeInterval = (interval) => {
	let { start, end } = interval
	if (start > end) {
		;[ start, end ] = [ end, start ]
	}
	return { start, end }
}
