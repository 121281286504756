/* eslint-disable eqeqeq */
import { withApollo } from "@apollo/client/react/hoc"
import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { permissions, permissionValues } from '../constants/permissions'
import CircularLoading from "../navigation/CircularLoading"
import { AuthStateContext } from "../store"

function hasPermission(userPermissions, permission_id) {
	if (!userPermissions) return false

	for (var j = 0; j < userPermissions.length; j++) {
		if (userPermissions[j].permission_id == permission_id) {
			if (userPermissions[j].permission_value_id == permissionValues.YES) {
				return true
			}
		}
	}
	return false
}


const AdminRouter = (props) => {
	const navigate = useNavigate()
	const authState = useContext(AuthStateContext)
	const userPermissions = authState?.user?.permissions

	// Ordered list of redirects when a user hits the admin console.
	const redirects = [
		['/admin/users', permissions.ADMIN_USERS],
		['/admin/imports/help', permissions.ADMIN_IMPORT_TOOL],
		['/admin/imports', permissions.ADMIN_IMPORT_TOOL],
		['/admin/groups', permissions.ADMIN_USERS],
		['/home', null] // Default redirect
	]

	// On mount, redirect to the first permissioned admin page
	useEffect(() => {
		for (var i = 0; i < redirects.length; i++) {
			// Default redirect handler
			if (redirects[i][1] == null) {
				navigate(redirects[i][0])
				break
			}

			// Check non-default redirects
			if (hasPermission(userPermissions, redirects[i][1])) {
				navigate(redirects[i][0])
				break
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <CircularLoading />
}

export default withApollo(AdminRouter)
