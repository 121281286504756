/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { Button, IconButton, Menu, MenuItem, Paper, TableSortLabel } from '@mui/material'
import clsx from 'clsx'
import {
	DataGridPro,
	GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro'
import {
	elementReady,
	formatDate, formatter, getArtStatusChip, getListHeader, restoreColumnSettings,
	getArtEditionText
} from '../common/helpers'
import { getIconOrLabel, StyledAvatar } from '../Thumbnail/Thumbnail'
import { AuthStateContext, LookupContext } from '../store'
import { getVerifiedIcon } from './ArtPiece/ArtQuickView'
import QuillText from '../input/QuillText'
import FavoriteAndFlag from '../common/FavoriteAndFlag'
import { filteredPages } from '../navigation/routes'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DataGridFlagMenu from '../common/components/DataGridFlagMenu'
import { Undo } from '@mui/icons-material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { getArtActivity } from '../common/helpers'
import Source from './ArtPiece/Source'
import DealThumbnail from '../Deals/DealThumbnail'


function arraymove(arr, fromIndex, toIndex) {
	const element = arr[fromIndex]
	arr.splice(fromIndex, 1)
	arr.splice(toIndex, 0, element)
}

const getCurrentLocation = (params) => {
	const currentLocation = params.row?.art_location?.find((art) => art.is_current)

	let locationString =  [
		currentLocation?.location?.name,
		currentLocation?.sub_location?.name,
		formatDate(currentLocation?.location_change_at, 'MMM do, yyyy')
	].filter(e => e && e !== '-').join(" / ")

	return !params.row.is_private ? locationString || '-' : '-'
}

const artProfileImage = params => {

	return (
		<StyledAvatar
			alt={params.row.title || 'Private Entity'}
			src={params.row.imgUrl}
			variant={'square'}
		>

			{getIconOrLabel({
				avatar: params.row.imgUrl,
				name: params.row.code_name,
				type: 'art',
			})}
		</StyledAvatar>
	)
}


const privateField = field => params =>
	(params.row?.[field] && !params.row.is_private) ?
		params.row[field] : '-'

function getCols({
	artStatus,
	currencies,
	loading,
	favoriteAndFlag,
	actionMenu,
	flagMenu,
	dimensionsMenu,
	density
}) {
	/** @type GridColDef[] */
	const columns = [
		{
			field: 'Actions',
			headerName: '',
			minWidth: 50,
			width: 50,
			resizable: false,
			sortable: false,
			renderCell: actionMenu
	  	},
		{
			field: 'isFlagged',
			headerName: '',
			renderHeader: flagMenu,
			width: 50,
			renderCell: favoriteAndFlag,
			sortable: false,
			resizable: false,
			headerClassName: 'flag-header-data-grid'
		}, {
			field: 'art_profile_link',
			headerName: 'Art Preview',
			renderHeader: () => <></>,
			description: '',
			minWidth: 50,
			width: 50,
			sortable: false,
			resizable: false,
			renderCell: artProfileImage
		}, {
			field: 'artist_first_name',
			headerName: 'Artist First Name',
			width: 120,
			valueGetter: params => params.row.artist?.first_name || "-"
		},
		{
			field: 'artist_last_name',
			headerName: 'Artist Last Name',
			width: 120,
			valueGetter: params =>  params.row.artist?.last_name || "-"
		}, {
			field: 'title',
			headerName: 'Title',
			minWidth: 150,
			width: 150,
			renderCell: params => {
				return <DealThumbnail
					created_at={params.row.created_at}
					is_private={params.row.is_private}
					is_gallery_private={params.row.is_gallery_private}
					deal_name={
						<QuillText noWrap ellipsis name={params.row.id}>
							{typeof params.row.formatted_title === 'object'
								? params.row.formatted_title
								: JSON.parse(params.row.formatted_title)}
						</QuillText>
					}
				/>
			}
		}, {
			field: 'year',
			headerName: 'Date',
			valueGetter: params => params.row?.year || "-"
		}, {
			field: 'searchable_inventory_number',
			headerName: 'Inventory No.',
			minWidth: 125,
			width: 140,
			valueGetter: params => [
				params.row.inventory_number_prefix,
				params.row.inventory_number,
				params.row.inventory_number_suffix,
			].filter(a => a).join('-'),
		}, {
			field: 'medium',
			headerName: 'Medium',
			valueGetter: privateField('medium')
		}, {
			field: 'dimensions',
			headerName: 'Dimensions',
			renderHeader: dimensionsMenu,
			width: 150,
			valueGetter: privateField('all_dimensions'),
			sortable: false
		},
		{
			field: 'edition',
			headerName: 'Edition',
			valueGetter: params => {
				const artInput = params.api.getRow(params.id)
				return getArtEditionText(artInput)
			},
			width: 150,
			sortable: false,
		},
		{
			field: 'retail',
			width: 125,
			valueGetter: params => {
				const price = params.api.getRow(params.id).primaryRetailPrice
				const currency = params.api.getRow(params.id).primary_currency_id
				return price && !params.row.is_private ?
					formatter(currencies
						?.find(e => e.id === currency)
						?.label || 'USD'
					).format(price) : "-"
			},
			headerName: 'Retail Price',
		}, {
			field: 'catalogue_raisonne',
			headerName: 'Catalogue Raisonné',
			width: 120,
			valueGetter: privateField('catalogue_raisonne'),
		}, {
			field: 'location',
			headerName: "Current Location",
			sortable: false,
			valueGetter: getCurrentLocation
		}, {
			field: 'source',
			headerName: 'Source',
			minWidth: 250,
			sortable: false,
			width: 250,
			renderCell: params => (params.row?.current_sources?.length && !params.row.is_private) ?
				<>
					<Source
						loading={false} 
						canViewOwnerContact={params.row?.canViewOwnerContact} 
						sources={params.row?.current_sources} 
						thumbnailStyle={{
							margin: '8px auto 8px 8px',
						}}
						compact={params?.api?.state?.density?.value === 'compact'}
						avatarGroupStyles={{
							marginLeft: '1em'
						}}
					/>
				</> : <div>-</div>
		}, {
			field: 'verified',
			type: 'boolean',
			headerName: 'Verified',
			label: ' ',
			sortable: false,
			description: '',
			minWidth: 30,
			width: 30,
			headerClassName: 'small-header-data-grid',
			renderCell: params => !params.row.is_private ?
				getVerifiedIcon(params.row, loading) : <span>-</span>
		}, {
			field: 'is_active',
			headerName: 'Active',
			type: 'boolean',
			minWidth: 125,
			width: 125,
			renderCell: params => !params.row.is_private ?
				getArtActivity(params.row, loading) : <span>-</span>
		}, {
			field: 'art_status_value',
			headerName: 'Status',
			renderCell: artStatus,
			minWidth: 125,
			width: 125,
		}, {
			field: 'customs_status',
			headerName: 'Customs Status',
			valueGetter: privateField('customs_status')
		},
		{
			field: "modified_at",
			headerName: "Modified At",
			sortable: true,
			width: 130,
			renderCell: params => {
				const fDate = params.row?.modified_at ? formatDate( params.row?.modified_at, "MMM' 'dd', 'yyyy" ) : "-"
				return <span>{fDate}</span>
			}
		},
		{
			field: "created_at",
			headerName: "Created At",
			sortable: true,
			width: 130,
			renderCell: params => {
				const fDate = params.row?.created_at ? formatDate( params.row?.created_at, "MMM' 'dd', 'yyyy" ) : "-"
				return <span>{fDate}</span>
			}
		},
	]
	return columns
}

/**
 * @typedef ArtDataGridProps
 * @property {Object[]} rows All rows of {Art} to be displayed
 * @property {function} setRows
 * @property {boolean} loading If a loading is in progress
 * @property {function} saveAndGo Function called on row doubleClick
 * @property {string=} mainSearchPage
 * @property {filterComparator} userId
 * @property {Object} selectedRow
 * @property {callback} onSelect
 * @property {filterComparator} totalItems
 * @property {number} limit
 * @property {Object} variables
 * @property {function} setLimit
 * @property {number} activeStep
 * @property {function} setActiveStep
 * @property {Object} sortModel
 * @property {function} onSortModelChange
 * @property {boolean=} noScrollToTop do not scroll to top of page on pagination
 * @property {function} setFlaggedCount
 * @property {function} filterTo
 * @property {string} type
 */

/**
 * @param {ArtDataGridProps} props
 */
export function ArtDataGrid (props) {

	const columnSettingsKey = 'ArtDataGrid'

	const authState = useContext(AuthStateContext)
	const lookup = useContext(LookupContext)

	// await these three, make the function async, and wrap it in a suspense?
	const userPermissions  = authState?.user?.permissions
	const artStatus = lookup.data?.getArtStatuses
	const currencies = lookup.data?.getCurrencies

	// handle null response.
	const rows = props.rows || []

	const [density, setDensity] = useState(
		localStorage.getItem(`${columnSettingsKey}.density`) || 'comfortable')
	useEffect(() => localStorage.setItem(`${columnSettingsKey}.density`, density),
		[density])

	const [columnVisibilityModel, setColumnVisibilityModel] = useState(
		JSON.parse(localStorage.getItem(`${columnSettingsKey}.visibility`) || null)
	)
	useEffect(() => localStorage.setItem(`${columnSettingsKey}.visibility`, JSON.stringify(columnVisibilityModel)),
		[columnVisibilityModel])

	const favoriteAndFlag = params => {

		if (params.row.is_private && !params.row.created_at)
			return <></>

		return (
			<FavoriteAndFlag
				entityName="art"
				row={params.row}
				userId={props?.userId}
				setRows={props.setRows}
				setEntity={props.setArt}
				listName={props.listName}
				setFlaggedCount={props.setFlaggedCount}
				compact={density === 'compact'}
				hidden={params.row.is_private && !params.row.created_at}
			/>
		)
	}

	const flagMenu = params => {

		if (props.disableFlagMenu) return null

		return (
			<DataGridFlagMenu
				currentFilters
				filterTo={props.filterTo}
				variables={props.variables}
				setFlaggedCount={props.setFlaggedCount}
				setRows={props.setRows}
				rows={props.rows}
				totalItems={props.totalItems}
				type={props.type}
			/>
		)
	}

	const dimensionsMenu = (args) =>
		<Button
			size="small"
			endIcon={<KeyboardArrowDownIcon />}
			onClick={openDimensionSortMenu}
			sx={{
				fontWeight: 500,
				color: '#808080',
				fontSize: 14
			}}
		>
			{ args.colDef.headerName }
		</Button>

	const artStatusFormatter = params => !params.row.is_private ? getArtStatusChip(
		params.row,
		props.loading,
		artStatus
	) : <span>-</span>

	const openContextMenu = (field) => (e) => {
		e.preventDefault()
		e.stopPropagation()
		setAnchorEl(e[field])
	}
	const openDimensionSortMenu = (e) => {
		e.preventDefault()
		e.stopPropagation()
		setDimAnchorEl(e.target)
	}

	const actionMenu = params => {

		if ((params.row.is_private || params.row.is_gallery_private) && !params.row.created_at)
			return <></>

		return (
			<IconButton
				aria-label="More"
				style={{
					padding: '6px',
					marginRight: '-9px',
				}}
				onClick={e => {
					if (params.row.disabled || params.row.hidden) return
					setMenuRowId(params.id)
					openContextMenu('currentTarget')(e)
				}}
			>
				<MoreVertIcon
					style={ params.row.is_private ? { color: 'white' } : null }
				/>
			</IconButton>
		)
	}

	const [menuRowId, setMenuRowId] = useState(null)
	const [anchorEl, setAnchorEl] = useState(null)
	const [dimAnchorEl, setDimAnchorEl] = useState(null)
	const [columns, setColumns] = useState([])

	// Set up grid with loaded dependencies, and saved data.
	const updateColumns = () => {

		const cols = getCols({
			artStatus: artStatusFormatter,
			currencies,
			favoriteAndFlag,
			actionMenu,
			loading: props.loading,
			flagMenu,
			dimensionsMenu,
			density
		})

		// restore on load.
		const columnSettings = JSON.parse(localStorage.getItem(columnSettingsKey))
		if (columnSettings?.length) restoreColumnSettings(cols, columnSettings)

		if (!columnVisibilityModel) {
			const obj = cols.filter(c => c.hide)
				.reduce((acc, el) => {
					acc[el.field] = false
					return acc
				}, {})
			setColumnVisibilityModel(obj)
		}

		setColumns([...cols])
	}

	// on Load, load column settings, sort, merge in (once everything is loaded.)
	useEffect(() => {
		if (currencies && artStatus && props.userId) updateColumns()
	}, [props.userId, currencies, artStatus, density])

	// update the flag menu/toggle any time the props change
	// to prevent "stale closures"
	useEffect(() => {
		setColumns(cols => {
			const flagCol = cols.find(c => c.field === 'isFlagged')
			if (flagCol) {
				flagCol.renderHeader = flagMenu
				flagCol.renderCell = favoriteAndFlag
				flagCol.width = (density === 'compact') ? 80 : 50
			}
			return [...cols]
		})
	}, [props.variables, props.totalItems, props.userId, currencies, artStatus, density])

	// on unmount, stash columns back in storage. (eventually in DB)
	useEffect(() => {

		// stash on nav. (`columns` needs to be in the deps in order to be accessed.)
		return () => {
			if (columns.length) {
				const columnSettings = columns.map(col => ({
					field: col.field,
					width: col.width,
					minWidth: col.minWidth,
					hide: col.hide
				}))
				localStorage.setItem(columnSettingsKey, JSON.stringify(columnSettings))
			}
		}
	}, [columns])

	const handleClose = () => setAnchorEl(null)
	const handleDimAnchorClose = () => setDimAnchorEl(null)

	const resetGrid = () => {
		localStorage.removeItem(columnSettingsKey)
		localStorage.removeItem(`${columnSettingsKey}.visibility`)
		setColumnVisibilityModel(null)
		updateColumns()
	}

	const CustomToolBar =  () => <GridToolbarContainer>
		<Button
			size="small"
			startIcon={<Undo />}
			onClick={resetGrid}
		>
			Reset
		</Button>
		<GridToolbarColumnsButton />
		<GridToolbarDensitySelector />
	</GridToolbarContainer>

	const dimensionSorts = [{
		id: 'size',
		label: 'Dimension',
		noSort: true
	}, {
		id: 'height_metric',
		label: 'Height'
	}, {
		id: 'width_metric',
		label: 'Width',
	}, {
		id: 'depth_metric',
		label: 'Depth'
	}]
	const filterSorts = sorts => {
		const fields = dimensionSorts.map(d => d.id)
		sorts.filter(s => !fields.includes(s.field))
	}

	// Scroll to the top of current data grid component when paginating
	useEffect(() => {

		if (props.loading === false && !props.scrollIntoView) {

			elementReady(`.${columnSettingsKey}`)
				.then(searchList =>
					searchList?.scrollIntoView?.({
						behavior: 'smooth',
						block: 'start',
					})
				)
		}

	}, [props.activeStep])

	return <>
		<Paper
			className={clsx({
				'data-grid-padding': true,
				[columnSettingsKey]: true
			})}
			data-testid={`card-${props.listHeader ? props.listHeader : "Results "}`}
			style={{
				...props.style,
				flexGrow: 1
			}}
		>
			<h1 className="card-title" style={{
				paddingTop: '1em',
				paddingLeft: '2em'
			}}>
				{getListHeader(props.listHeader, props.totalItems, props.canSee)}
			</h1>

			<div style={{ height: 'calc(100% - 4em)', width: '100%' }}>
				<DataGridPro
					components={{ Toolbar: CustomToolBar }}
					rowCount={props.totalItems || 0}
					rows={rows}
					columns={columns}
					onRowDoubleClick={({row}) => {
						if ((row.is_private || row.is_gallery_private) && !row.created_at) return
						props.saveAndGo(`/art/${row.id}`, row.id)
					}}
					autoHeight={props.alwaysAutoHeight ?? false}
					density={density}
					disableColumnMenu
					disableMultipleSelection
					pagination
					paginationMode="server"
					sortingMode="server"
					pageSize={props.limit}
					onPageSizeChange={props.setLimit}
					rowsPerPageOptions={[10, 25, 50]}
					onRowClick={(params, event) => {
						let row = params.row
						if (event.metaKey) {
							if ((row.is_private || row.is_gallery_private) && !row.created_at) return
							window.open(`/art/${row.id}`, "_blank")
						} else {
							props.onSelect(row)
						}
					}}
					selectionModel={props.selectedRow?.id ? [props.selectedRow?.id] : []}
					page={props.activeStep}
					onPageChange={props.setActiveStep}
					sortModel={filterSorts(props.sortModel)}
					onSortModelChange={props.onSortModelChange}
					loading={props.loading}
					onColumnVisibilityModelChange={setColumnVisibilityModel}
					columnVisibilityModel={columnVisibilityModel || {}}
					onColumnOrderChange={({ oldIndex, targetIndex }) => {
						arraymove(columns, oldIndex, targetIndex)
						setColumns([...columns])
					}}
					onColumnWidthChange={({ colDef, width}) => {
						columns.find(col => col.field === colDef.field).width = width
						setColumns([...columns])
					}}
					onStateChange={({density}) => setDensity(density?.value ?? 'comfortable')}
					getRowClassName={({row}) => row.is_private ? 'Private-DataGrid-Row' : null}
				/>
			</div>

			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={!!anchorEl}
				onClose={handleClose}
			>
				{
					filteredPages('artPages', userPermissions)
						.map(page =>
							<MenuItem
								key={ page.name }
								onClick={() => {
									props.saveAndGo(`/art/${menuRowId}/${page.route}`, menuRowId)
									handleClose()
								}}
							>
								{ page.name }
							</MenuItem>
						)
				}
			</Menu>
		</Paper>
		<Menu
			id="simple-menu"
			anchorEl={dimAnchorEl}
			keepMounted
			open={!!dimAnchorEl}
			onClose={handleDimAnchorClose}
			anchorOrigin={{
				vertical: 'bottom',
    			horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
    			horizontal: 'right',
			}}
		> { dimensionSorts.map(x =>
				<MenuItem key={`inner-menu-${x.id}`} value={x.id}
					onClick={() => {
						if (!x.noSort) {
							const field = x.id
							const oldSort = props.sortModel.find(sm => sm.field === field)?.sort ?? 'desc'
							const sort = oldSort === 'asc' ? 'desc' : 'asc'
							props.onSortModelChange([{ field, sort }])
						} else {
							props.onSortModelChange([])
						}
						handleDimAnchorClose()
					}}
				>
					<TableSortLabel
						active={!!props.sortModel.find(sm => sm.field === x.id)}
						direction={props.sortModel.find(sm => sm.field === x.id)?.sort ?? "asc"}
						hideSortIcon={x.noSort}
						style={x.noSort ? {cursor: "default", fontWeight: 400} : {fontWeight: 400}}
					>
						{ x.label }
					</TableSortLabel>
				</MenuItem>
			)}
		</Menu>
	</>

}
