import { gql } from '@apollo/client'

export const GET_TAG_COUNTS = gql `
	query getTagCounts($id: ID) {
		getTagCounts(id: $id) {
			artCount
			contactCount
			listingCount
			artistCount
			dealCount
		}
	}
`
