/* eslint-disable eqeqeq */
import { AuthStateContext, DispatchContext } from './../store'
import { GET_HOME_PAGE_DETAILS } from './Queries'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { useQuery } from '@apollo/client'
import ActivitiesParent from './Activity/ActivitiesParent'
import ConsignmentGoal from './ConsignmentGoal'
import DealsParent from './Deals/DealsParent'
import GlobalReservesParent from './GlobalReserves/GlobalReservesParent'
import Grid from '@mui/material/Grid'
import NotesParent from './Notes/NotesParent'
import OutreachGoal from './OutreachGoal'
import React, { useContext, useCallback } from 'react'
import SalesGoal from './SalesGoal'
import HomePageCard from './HomePageCard'
import TasksParent from './Tasks/TasksParent'
import { Skeleton } from '@mui/material'
import chunk from 'lodash/chunk'
import ConsignmentsParent from './GlobalConsignments/ConsignmentsParent'
import ArtFairsAndExhibitions from './ArtFairsAndExhibitions'

export default function Home(props) {
	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const authState = useContext(AuthStateContext)

	const { data, loading: userLoading, error: userError } = useQuery(GET_HOME_PAGE_DETAILS)

	const user = data?.getUser
	const userWidgets = data?.getHomePageWidgetsXUser

	if (userError) openSnackbar(severity.ERROR, 'Could not load user data.')

	const skeletonStyle = {
		transform: 'none',
		backgroundColor: 'white',
		marginTop: '1em',
		boxShadow:
			'0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
		borderRadius: '8px',
	}

	const boxShadowStyle = {
		marginLeft: '1em',
		boxShadow:
			'0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
		borderRadius: '8px',
	}

	const loadingCards = Array.from({ length: 8 }, () => (
		<Grid item key={Math.random().toString(36)} sm={12} md={6}>
			<div style={boxShadowStyle}>
				<Skeleton height="400px" style={skeletonStyle} animation="wave" />
			</div>
		</Grid>
	))

	const cards = [
		{
			id: 1,
			component: () => (
				<TasksParent authState={authState} userLoading={userLoading} />
			),
		},
		{
			id: 2,
			component: () => (
				<DealsParent authState={authState} userLoading={userLoading} />
			),
		},
		{
			id: 3,
			component: () => (
				<GlobalReservesParent
					authState={authState}
					userLoading={userLoading}
				/>
			),
		},
		{
			id: 4,
			component: () => (
				<ActivitiesParent
					authState={authState}
					userLoading={userLoading}
				/>
			),
		},
		{
			id: 5,
			component: () => (
				<NotesParent authState={authState} userLoading={userLoading} />
			),
		},
		{
			id: 6,
			component: () => (
				<HomePageCard title="Consignment Goal">
					<ConsignmentGoal user={user} userLoading={userLoading} />
				</HomePageCard>
			),
		},
		{
			id: 7,
			component: () => (
				<HomePageCard title="Outreach Goal">
					<OutreachGoal user={user} userLoading={userLoading} />
				</HomePageCard>
			),
		},
		{
			id: 8,
			component: () => (
				<HomePageCard title="Sales Goal">
					<SalesGoal user={user} userLoading={userLoading} />
				</HomePageCard>
			),
		},
		{
			id: 9,
			component: () => (
				<ConsignmentsParent authState={authState} userLoading={userLoading} />
			)
		},
		{
			id: 10,
			component: () => <ArtFairsAndExhibitions />
		}
	]

	const filteredWidgets = userWidgets
		?.filter((widget) => !widget.is_deleted)
		.sort((a, b) => Number(a.ordinal) - Number(b.ordinal))

	const [leftColumn, rightColumn, remainder] = chunk(
		filteredWidgets, 
		(filteredWidgets?.length) / 2
	)
	if(remainder && remainder.length===1) rightColumn.push(remainder[0])

	if (filteredWidgets?.length === 0) return (
		<div style={{
			height: 200,
			margin: 'auto',
			textAlign: 'center',
			fontSize: 16
		}}>
			You have disabled all the widgets for the home page. To revert these changes, <br/>
			navigate to <a href="/user/profile">your profile</a> and edit the “Home Page” settings there.
		</div> 
	)

	return (
		<>
			{!user && !userWidgets ? (
				<Grid container style={{ marginBottom: '15px', paddingRight: '1em' }}>
					{loadingCards.map((card) => card)}
				</Grid>
			) : (
				<div id="home-column-container" className="flex" style={{
					margin: '0 0 0 0.5em',
					paddingRight: '3.5px',
				}}>
					<div className="leftColumn">
						{leftColumn
							?.map((widget) => {
								const CurrentWidget = cards.find(
									(component) =>
										component.id ==
										widget.home_page_widget_id
								).component
								return <CurrentWidget key={widget.id} />
							})}
					</div>
					<div className="rightColumn">
						{rightColumn
							?.map((widget) => {
								const CurrentWidget = cards.find(
									(component) =>
										component.id ==
										widget.home_page_widget_id
								).component
								return <CurrentWidget key={widget.id} />
							})}
					</div>
				</div>
			)}
		</>
	)
}
