type SearchTerm = {
	field: string
	type: string // This field was "comparator" 
	comparator?: string // @deprecated, still exists in saved searches.
	value: string | boolean | undefined
	ordinal?: number
	isOptional: boolean // If a field is optional, it will be passed into the should boolean query
}

export type { SearchTerm }

export class SavedSearch {
	id?: string | null = null
	label?: string | null = null
	user_id?: number | null = null
	object_id?: number | null = null
	color?: string | null = null
	search_terms?: SearchTerm[] | null = null
	result_count?: number | null = null
	last_run_at?: string | null = null
	is_global?: boolean | null = null
	created_by?: string | null  = null
	created_at?: string | null  = null
	modified_by?: string | null  = null
	modified_at?: string | null  = null

	constructor ({
		id,
		label,
		user_id,
		object_id,
		color,
		search_terms,
		result_count,
		last_run_at,
		is_global,
		created_by,
		created_at,
		modified_by,
		modified_at
	}: SavedSearch) {
		this.id = id
		this.label = label
		this.user_id = user_id
		this.object_id = object_id
		this.color = color
		this.search_terms = search_terms
		this.result_count = result_count
		this.last_run_at = last_run_at
		this.is_global = is_global
		this.created_by = created_by
		this.created_at = created_at
		this.modified_by = modified_by
		this.modified_at = modified_at
	}
}
