import React from 'react'
import withStyles from '@mui/styles/withStyles'
import Switch from '@mui/material/Switch'
import * as Colors from '../../styles/colors/Colors'

const ActiveToggle = withStyles(theme => ({
	root: {
		width: 28,
		height: 16,
		padding: 0,
		display: 'inline-flex',
		margin: theme.spacing(1),
	},
	switchBase: {
		padding: 2,
		color: Colors.white,
		'&$checked': {
			transform: 'translateX(12px)',
			color: Colors.nav,
			'& + $track': {
				opacity: 1,
				backgroundColor: '#52d869',
				borderColor: '#52d869',
			},
		},
	},
	thumb: {
		width: 12,
		height: 12,
		boxShadow: 'none',
		color: '#FFF',
	},
	track: {
		border: `1px solid #E4E4E5`,
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor: '#E4E4E5',
		height: "auto"

	},
	checked: {},
}))(({ classes, ...props }) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked,
			}}
			{...props}
		/>
	)
})

export default ActiveToggle
