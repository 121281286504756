import React from 'react'
import { GET_LISTING_DATALOG } from './Queries'
import DataLog from '../../DataLog/DataLog'

export default function ListingDataLog (props) {

	return (!props.findMode && props.id > 0) && (
		<DataLog 
			query={GET_LISTING_DATALOG} 
			id_field={"listing_id"} 
			queryName={"getListingDataLog"}
			parentQueryName={"getListing"}
			{...props}
		/>
	)
}
