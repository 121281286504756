/* eslint-disable eqeqeq */
import { groupBy } from 'lodash'
import { permissions, permissionValues } from '../constants/permissions'
import { severity } from '../Snackbar/CustomizedSnackbar'

export const PDF = Symbol('PDF')
export const EXCEL = Symbol('Excel')
export const BULK_EDIT = Symbol('Bulk Edit')


export const validatePermissions = (permissionType, userPermissions, rows, openSnackbar) => {
	if (!userPermissions) return false

	const canBulkEdit = userPermissions
		.find((element) => element.permission_id == permissions.BULK_EDIT &&
			element.permission_value_id == permissionValues.YES)

	const canArtExport = userPermissions
		.find((element) => element.permission_id == permissions.ART_TO_EXCEL &&
			element.permission_value_id == permissionValues.YES)
	const canContactExport = userPermissions
		.find((element) => element.permission_id == permissions.CONTACTS_TO_EXCEL &&
			element.permission_value_id == permissionValues.YES)

	const canArtPdf = userPermissions
		.find((element) => element.permission_id == permissions.ART_TO_PDF &&
			element.permission_value_id == permissionValues.YES)
	const canContactPdf = userPermissions
		.find((element) => element.permission_id == permissions.CONTACTS_TO_PDF &&
			element.permission_value_id == permissionValues.YES)

	const canViewArt = userPermissions
		.find(element => element.permission_id == permissions.ART &&
			element.permission_value_id >= permissionValues.VIEW_ONLY)
	const canViewContacts = userPermissions
		.find(element => element.permission_id == permissions.CONTACTS &&
			element.permission_value_id >= permissionValues.VIEW_ONLY)

	let pages = groupBy(rows, row => row.__typename)
	if (!Object.keys(pages).length) return true

	let errorMessage = null
	if (permissionType === BULK_EDIT && !canBulkEdit) {
		errorMessage = "You do not have permission to Bulk Update."
	} else if (permissionType === PDF) {
		if (pages.Art && !(canViewArt && canArtPdf)) {
			errorMessage = "You do not have permission to export Art to PDF."
		}
		if (pages.Contact && !(canViewContacts && canContactPdf)) {
			errorMessage = "You do not have permission to export Contacts to PDF."
		}
	} else {
		if (pages.Art && !(canViewArt && canArtExport)) {
			errorMessage = "You do not have permission to export Art to Excel."
		}
		if (pages.Contact && !(canViewContacts && canContactExport)) {
			errorMessage = "You do not have permission to export Contacts to Excel."
		}
	}

	if (errorMessage) {
		openSnackbar(severity.WARNING, errorMessage)
		return false
	}
	return true
}
