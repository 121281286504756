import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import Dropdown from '../../input/Dropdown/Dropdown'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { useCallback, useContext, useEffect, useState } from 'react'
import { DispatchContext, LookupContext } from '../../store'
import { Entity } from '../../Search/BulkActions'
import { useMutation, useQuery } from '@apollo/client'
import { GET_LISTING_STATUSES, UPDATE_LISTING_TYPE } from '../../Listings/Listing/Queries'
import { CancelButton, SearchButton } from '../../input/Buttons'
import { severity } from '../../Snackbar/CustomizedSnackbar'

const ListingTypeChangeModal = (props: any) => {

	const lookup = useContext<any>(LookupContext)?.data
	const listingTypes: Entity[]  = lookup?.getListingTypes
	const listingArtStatuses = lookup?.getListingArtStatus
	const listingContactStatuses = lookup?.getListingContactStatus

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity: severity, text: string) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const [listingType, setListingType] = useState(props?.listing?.type_id)
	const [newContactStatuses, setNewContactStatuses] = 
		useState<{[index: number]: number}>({})
	const [newArtStatuses, setNewArtStatuses] = 
		useState<{[index: number]: number}>({})

	const originalListingTypeId = props?.listing.type_id
	const currentListingType = listingTypes?.find(lt => lt.id === originalListingTypeId)?.value

	const { data } = useQuery(GET_LISTING_STATUSES, { variables: { id: props.listing.id}})

	const artTypes = data?.getListingParticipantStatuses?.statuses?.artTypes || []
	const contactTypes = data?.getListingParticipantStatuses?.statuses?.contactTypes || []

	useEffect(() => {
		const artStats = artTypes.reduce((acc: {[index: number]: number}, el: number) => {
			acc[el] = el
			return acc
		}, {})
		setNewArtStatuses(artStats)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	const availableContactStatuses = listingContactStatuses
		?.filter((cs: any) => cs.listing_type_id === listingType)
	
	const changeListingType = (e: SelectChangeEvent) => {
		const newListingType = e.target.value
		setListingType(newListingType)
		const newNewContactStatuses = { ...newContactStatuses }
		const newAvailableContactStatuses = listingContactStatuses
			?.filter((cs: any) => cs.listing_type_id === newListingType)
	
		contactTypes.forEach((cs: number) => {
			newNewContactStatuses[cs] = newAvailableContactStatuses[0].id
		})
		setNewContactStatuses(newNewContactStatuses)
	}

	const [updateListing, {loading}] = useMutation(UPDATE_LISTING_TYPE, {
		onError: (error) => {
			openSnackbar(severity.ERROR, "Could not update details card.")
			props.close()
		},
		onCompleted: (response) => {
			if (response.updateListingType?.success === true) {
				openSnackbar(severity.SUCCESS, response.updateListingType.message)
				// On success change user state
				const newListing = {
					...props.listing,
					listing_type: listingTypes.find(lt => lt.id === listingType),
					type_id: listingType
				}
				props.setState({ getListing: newListing })
			} else {
				// On failure, reset userInput state, don't touch user state and show error
				openSnackbar(severity.ERROR, response.updateListingType.message)
			}
			props.close()
		}
	})

	const handleSave = () => {
		let updateListingType = {
			listingId: props.listing.id,
			newTypeId: listingType,
			newStatusIds: {
				newContactStatuses,
				newArtStatuses
			}
		}
		updateListing({ variables: { updateListingType } })
	}

	const Arrow = () => <span style={{ marginRight: '1em' }}>⭢</span>

	const rowStyle = { 
		display: 'flex', 
		justifyContent: 'space-between',
		alignItems: 'baseline',
		paddingBottom: '.5em',
		minWidth: '20em'
	}

	const innerStyle = {
		display: 'flex',
		justifyContent: 'space-between',
		flex: 1
	}

	return (
		<TransitionsModal open={props.open} close={props.close}>
			<div>
				<h1 className="card-title">
					<span style={{ marginTop: 4 }}>
						Change Listing Type
					</span>
				</h1>
				
				<div>
					<h4>Type</h4>
					<div style={rowStyle} >
						<div style={innerStyle}>
							<span>{ currentListingType }</span>
							<Arrow />
						</div>
						<Select
							style={{ flex: 1 }}
							IconComponent={ExpandMoreRoundedIcon}
							name="listing_type"
							
							data-testid="listing-type"
							labelId="type-label"
							className="padded-select"
							input={<Dropdown />}
							value={listingType}
							onChange={changeListingType}
						>
							{listingTypes && listingTypes.map((listingType) => (
								<MenuItem
									key={listingType.id}
									value={listingType.id}
									data-testid="listing-type-item"
								>
									{listingType.value}
								</MenuItem>
							))}
						</Select>
					</div>
				</div>
				<div style={{ display: 'flex' }}>
					<div style={{ marginRight: '1em'}}>
						<h4>Art Statuses</h4>
						{ artTypes.map((id: number) => 
							<div style={rowStyle} key={`art-${id}`}>
								<div style={innerStyle} >
									<span>
										{ listingArtStatuses.find((a: any) => a.id === id)?.value }
									</span>
									<Arrow />
								</div>
								<Select
									style={{ flex: 1 }}
									IconComponent={ExpandMoreRoundedIcon}
									labelId="type-label"
									className="padded-select"
									input={<Dropdown />}
									value={newArtStatuses[id] || ''}
									onChange={(e) => {
										const newVal = e.target.value
										const newStatuses = {
											...newArtStatuses,
											[id]: newVal
										}
										// @ts-ignore
										setNewArtStatuses(newStatuses)
									}}
								>
									{listingArtStatuses
										.map((s:any) => (
											<MenuItem
												key={s.id}
												value={s.id}
											>
												{s.value}
											</MenuItem>
										))}
								</Select>
							</div>)}
					</div>
					<div>
						<h4>Contact Statuses</h4>	
						{ contactTypes.map((id: number) => 
							<div style={rowStyle} key={`contact-${id}`} >
								<div style={innerStyle}>
									<span>
										{ listingContactStatuses.find((a: any) => a.id === id)?.value }
									</span>
									<Arrow />
								</div>
								<Select
									style={{ flex: 1 }}
									IconComponent={ExpandMoreRoundedIcon}
									labelId="type-label"
									className="padded-select"
									input={<Dropdown />}
									value={newContactStatuses[id] || ''}
									onChange={(e) => {
										const newVal = e.target.value
										const newStatuses = {
											...newContactStatuses,
											[id]: newVal
										}
										// @ts-ignore
										setNewContactStatuses(newStatuses)
									}}
								>
									{availableContactStatuses
										.map((s:any) => (
											<MenuItem
												key={s.id}
												value={s.id}
											>
												{s.value}
											</MenuItem>
										))}
								</Select>
							</div>
						)}
					</div>
				</div>

				<div style={{ paddingTop: '1em' }}>
					<SearchButton
						variant="contained"
						size="medium"
						type="submit"
						style={{ float: 'right' }}
						onClick={handleSave}
						disabled={loading}
					>
						Save
					</SearchButton>
					<CancelButton
						variant="contained"
						size="medium"
						type="reset"
						style={{ float: 'right', marginRight: '1em' }}
						onClick={() => {
							props.close()
						}}
					>
						Cancel
					</CancelButton>
				</div>
				
			</div>
		</TransitionsModal>
	)
}

export default ListingTypeChangeModal
