import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { stripMeta } from '../common/helpers'
import FileGroups from '../Files/FileGroups'
import { DispatchContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { useMutation } from '@apollo/client'
import { fileQuery } from '../Listings/Listing/Files/FilesPage'
import { UPDATE_LISTING } from '../Listings/Queries'


export default function ShowcaseFilesPage(props) {

	const [files, setFiles] = useState([])


	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text } })
	}, [dispatch])

	let listing = props.state?.getListing || {}
	let setListing = (listing) => props.setState({ getListing: listing })

	const [refetch, { loading, error }] = useLazyQuery(fileQuery, {
		onCompleted: (data) => {
			setFiles(data?.getListing?.files || [])
		},
		onError: error => {
			console.log(error)
			openSnackbar(severity.ERROR, "Error retrieving files.")
		}
	})


	const [
		updateListing, { error: updateError }
	] = useMutation(UPDATE_LISTING, {
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, "Could not update.")
		},
		onCompleted: (response) => {
			if (response.updateListing.success === true) {
				refetch({ variables: { id: props.id } })
				openSnackbar(severity.SUCCESS, response.updateListing.message)
				setListing(response.updateListing.listing)
			} else {
				// On failure, reset listingInput state, don't touch listing
				// state and show error
				openSnackbar(severity.ERROR, response.updateListing.message)
			}
		}
	})

	// update function
	const update = (fields) => {
		let variables = stripMeta(fields)
		variables.id = listing.id

		updateListing({
			variables: {
				UpdateListingInput: variables
			}
		})
	}

	useEffect(() => {
		if (!props.findMode && props.id > 0) {
			refetch({ variables: { id: props.id } })
		}
	}, [props.id, refetch, props.tabbedPageFileRefetch, props.findMode])

	return (!props.findMode && props.id > 0) && (
		<FileGroups
			fileGroups={[{
				id: '9',
				value: 'General',
				alias: 'Public Facing Documents',
				hideAddButton: true,
				hideEditButtons: true,
				__typename: 'FileGroup'
			}]}
			objectId={listing?.id}
			loading={props.loading || loading}
			error={props.error || error || updateError}
			files={files}
			setFiles={setFiles}
			onSave={update}
			fileObjectId={props.fileObjectId}
			options={props.options}

			tabbedPageFileRefetch={props.tabbedPageFileRefetch}
			setTabbedPageFileRefetch={props.setTabbedPageFileRefetch}
		/>
	)
}
