import { Field } from "./BulkEdit"

// The following fields are intentionally omitted from below
// regions_ids, birthdate, phone, website, email, address, relationships

// the following fields are in Selected Actions
// 'salespersons_ids', 'primary_salesperson_ids', 'isFavorite'

const bulkContactFields: Field[] = [
	{
		field: 'prefix_id',
		name: 'Prefix',
		type: 'ID',
		lookup: 'getPrefixTypes'
	},
	{
		field: 'first_name',
		name: 'First Name',
		type: 'string',
	},
	{
		field: 'last_name',
		name: 'Last Name',
		type: 'string',
	},
	{
		field: 'suffix',
		name: 'Suffix',
		type: 'string',
	},
	{
		field: 'preferred_name',
		name: 'Preferred Name',
		type: 'string',
	},
	{
		field: 'company_name',
		name: 'Company Name',
		type: 'string',
	},
	{
		field: 'title',
		name: 'Title',
		type: 'string',
	},
	{
		field: 'salutation',
		name: 'Salutation',
		type: 'string',
	},
	{
		field: 'types',
		name: 'Type',
		type: '[ID]',
		lookup: 'getContactTypes'
	},
	{
		field: 'grade_id',
		name: 'Grade',
		type: 'ID',
		lookup: 'getGradeTypes'
	},
	{
		field: 'activity_id',
		name: 'Activity',
		type: 'ID',
		lookup: 'getActivityTypes'
	},
	{
		field: 'foreign_preferred_name',
		name: 'Foreign Preferred Name',
		type: 'string',
	},

	// # Interests
	// artists_ids: [ID]
	{
		field: 'artists_ids',
		name: 'Artists',
		type: 'artists',
		// lookup: 'getArtCategories'
	},
	{
		field: 'art_category_ids',
		name: 'Categories',
		type: '[ID]',
		lookup: 'getArtCategories'
	},
	{
		field: 'genre_ids',
		name: 'Genre',
		type: '[ID]',
		lookup: 'getArtistGenres'
	},
	{
		field: 'status_id',
		name: 'Status',
		type: 'ID',
		lookup: 'getContactStatus'
	},
	{
		field: 'restrictedToUsers',
		name: 'Restricted To',
		type: 'restrictedToUsers',
	},
	{
		field: 'is_private',
		name: 'Is Private',
		type: 'boolean',
	},
	{
		field: 'code_name',
		name: 'Code Name',
		type: 'string',
	},
	{
		field: 'is_deceased',
		name: 'Is Deceased',
		type: 'boolean',
	},
	{
		field: 'is_company',
		name: 'Is Company',
		type: 'boolean',
	},
]

export default bulkContactFields
