import { gql } from '@apollo/client'
import { userData } from '../../../common/fragments'

export const GET_ART_FINANCIALS = gql`
	query getArtFinancials($id: ID!) {
		getArtFinancials(id: $id) {
			id
			art_id
			currency_id
			art_financial_type_id
			value
			created_by
			created_at
			modified_by
			modified_at
			is_deleted
		}
	}
`

export const UPDATE_ART_FINANCIALS = gql`
	mutation updateArtFinancials($ArtFinancialUpdate: ArtFinancialUpdate!) {
		updateArtFinancials(input: $ArtFinancialUpdate) {
			code
			success
			message
			severity
			financials {
				id
				art_id
				currency_id
				art_financial_type_id
				value
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
			}
		}
	}
`

export const GET_ART_FINANCIAL_HISTORIES = gql`
	query getArtFinancialHistories($id: ID!, $cursor: Int, $limit: Int) {
		getArtFinancialHistories(id: $id, cursor: $cursor, limit: $limit) {
			items {
				id
				art_id
				currency_id
				art_financial_type_id
				value
				value_changed_at
				created_by
				created_at
				modified_by
				modified_by_user {
					...userData
					department
				}
				modified_at
				is_deleted
			}
			cursor
			totalItems
		}
	}
	${userData}
`

export const DELETE_ART_FINANCIAL_HISTORY = gql`
	mutation deleteArtFinancialHistory($id: ID!) {
		deleteArtFinancialHistory(id: $id) {
			code
			success
			message
		}
	}
`

export const ADD_ART_FINANCIAL_HISTORY = gql`
	mutation addArtFinancialHistory($ArtFinancialHistoryInput: ArtFinancialHistoryInput!) {
		addArtFinancialHistory(input: $ArtFinancialHistoryInput) {
			code
			success
			message
		}
	}
`

