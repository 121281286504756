import { FormControl, MenuItem, Select } from '@mui/material'
import React, { useCallback, useContext } from 'react'
import { CancelButton, SubmitButton } from '../input/Buttons'
import Dropdown from '../input/Dropdown/Dropdown'
import Label from '../input/Label'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { typeStyle } from '../styles/makesStyles'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { DispatchContext, LookupContext } from '../store'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { useMutation } from '@apollo/client'
import { UPDATE_TASK_STATUSES } from './Queries'

const TaskStatusModal = (props) => {
	const { open, close, rows } = props

	const lookup = useContext(LookupContext)
	const taskStatusTypes = lookup.data?.getTaskStatusTypes

	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const [loading, setLoading] = React.useState(false)
	const [attempt, setAttempt] = React.useState(false)
	const [status, setStatus] = React.useState(null)

	const error = attempt && !status
	
	const handleClose = () => {
		setStatus(null)
		setLoading(false)
		setAttempt(false)
		close()
	}

	const [updateTaskStatuses] = useMutation(UPDATE_TASK_STATUSES)

	const handleSubmit = () => {

		setAttempt(true)

		if (error) openSnackbar(severity.WARNING, 'Please complete required fields.')
		else if (status) {

			setLoading(true)
			
			updateTaskStatuses({
				variables: {
					task_status_id: status,
					taskIds: rows.map(e => e.id)
				},
			})
				.then((response) => {

					if (response.data?.updateTaskStatuses?.success) {
						openSnackbar(severity.SUCCESS, response.data.updateTaskStatuses.message)
					} else {
						openSnackbar(severity.ERROR, response.data.updateTaskStatuses.message)
					}

					setLoading(false)
					handleClose()
				})
				.catch((error) => {
					console.log(error)
					openSnackbar(severity.ERROR, error)
					setAttempt(false)
					setLoading(false)
				})
		}

	}

	return (
		<TransitionsModal className='task-statuses-modal' open={open} close={() => handleClose()}>
			<div style={{ width: "20em" }}>
				<h2
					className='card-title'
					style={{
						paddingBottom: "0.5em",
						margin: 0,
					}}
				>
					Update Task Statuses
				</h2>

				<FormControl
					sx={{ width: '-webkit-fill-available' }}
					error={error}
				>
					<Label
						id="status-label"
						style={typeStyle}
						disableAnimation
						shrink
						error={error}
					>
						Status
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						className="padded-select"
						name="status"
						value={status}
						labelId="status-label"
						input={<Dropdown />}
						error={error}
						onChange={(e) => {
							setStatus(e.target.value)
						}}
					>
						{taskStatusTypes?.map((type) => (
							<MenuItem key={type.id} value={type.id}>
								{type.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<div
					style={{
						display: 'flex',
						marginTop: '1em'
					}}
				>
					<CancelButton
						variant='contained'
						style={{ float: "left" }}
						onClick={() => handleClose()}
					>
						Cancel
					</CancelButton>

					<SubmitButton
						variant='contained'
						type='submit'
						sx={{marginLeft: 'auto'}}
						disabled={loading}
						onClick={() => handleSubmit()}
					>
						Submit
					</SubmitButton>
				</div>
			</div>
		</TransitionsModal>
	)
}

export default TaskStatusModal
