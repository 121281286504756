import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { Button, CircularProgress, FormControl, Grid, List, ListItemButton, Paper, Typography } from '@mui/material'
import React, { useCallback, useContext, useEffect } from 'react'
import LimitedAutocomplete from '../common/LimitedAutocomplete'
import TransitionsModal from '../navigation/TransitionsModal/TransitionsModal'
import { DispatchContext, LookupContext } from '../store'
import { autoCompleteStyles, LightTooltip } from '../styles/makesStyles'
import { GET_USERS, GET_USER_GROUP_BY_GALLERY, ADD_USER_TO_GROUP, REMOVE_USER_GROUP, UPDATE_USER_GROUP } from './Queries'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import Label from '../input/Label'
import TextBoxThinForAutocomplete from '../input/Text/TextBoxThinForAutocomplete'
import { getGalleryContactLabel } from '../common/helpers'
import Thumbnail from '../Thumbnail/Thumbnail'
import { severity } from '../Snackbar/CustomizedSnackbar'
import groupBy from 'lodash/groupBy'
import UserQuickView from '../User/UserQuickView'
import TextBoxThin from '../input/Text/TextBoxThin'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'

const AdminGallery = (props: any) => {
	const [galleryId, setGalleryId] = React.useState(null)
	const [groupModal, setGroupModal] = React.useState<any>({})
	const [modalOpen, setModalOpen] = React.useState(false)
	const [userGroups, setUserGroups] = React.useState<any>({})
	const [selectedGroupType, setSelectedGroupType] = React.useState(null)
	const [query, setQuery] = React.useState<string>('')
	const [selectedUserGroup, setSelectedUserGroup] = React.useState<any>(null)

	const lookup = useContext(LookupContext)

	// @ts-ignore
	const galleries = lookup.data?.getGalleryTypes

	// @ts-ignore
	const groupTypes = lookup.data?.getUserGroupTypes

	/* =================================================================
	Snackbar
	================================================================= */
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity: any, text: string) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	// When the page Changes
	useEffect(() => {

		setGalleryId(galleries?.find((e: any) => e.value === props.pageName)?.id)
		setQuery('')
		closeModal()
		setSelectedGroupType(null)
		setSelectedUserGroup(null)

	}, [galleries, props.pageName])

	const { loading } = useQuery(GET_USER_GROUP_BY_GALLERY, {
		skip: !galleryId,
		variables: {
			id: galleryId
		},
		onCompleted: (data) => {
			const { getUserGroupsByGallery } = data
			setUserGroups(groupBy(getUserGroupsByGallery, 'type.value'))
		},
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, 'Could not retrieve users.')
		}
	})

	const [addUserToGroup] = useMutation(ADD_USER_TO_GROUP)
	const [removeUserGroup] = useMutation(REMOVE_USER_GROUP)
	const [updateUserGroup] = useMutation(UPDATE_USER_GROUP)

	// Autocomplete

	const [loadUser, setLoadUser] = React.useState(false)
	const [userOptions, setUserOptions] = React.useState([])
	const [userOpen, setUserOpen] = React.useState(false)
	const [userInput, setUserInput] = React.useState<any>(null)
	const userLoading = userOpen && !!loadUser

	const client = useApolloClient()

	useEffect(() => {
		let active = true

		if (!userLoading) {
			return undefined
		}

		client
			.query({
				query: GET_USERS,
				variables: {
					query: loadUser,
					active: true,
				},
			})
			.then((result: any) => {
				if (active) {
					setLoadUser(false)
					setUserOptions(result.data.getUsers.items)
				}
			})

		return () => {
			active = false
		}
	}, [client, loadUser, userLoading])

	const closeModal = () => {
		setModalOpen(false)
		setUserInput(null)
		setLoadUser(false)
		setUserOptions([])
		setUserOpen(false)
	}

	useEffect(() => {
		if (!selectedUserGroup) props.setQVOverride(null)
		else {
			props.setQVOverride(<UserQuickView user={selectedUserGroup.user} findMode={false} onClose={() => setSelectedUserGroup(null)}></UserQuickView>)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUserGroup])

	const containerStyles = {
		minHeight: '15em',
		maxHeight: '15em',
		overflow: 'auto',
	}

	const [firstLoad, setFirstLoad] = React.useState(true)
	React.useEffect(() => setFirstLoad(false), [])

	/*
	 * Filters the users depending on input from the query.
	 */
	const queryFilter = (userGroup: any) => {
		const { user } = userGroup
		const { first_name, last_name, department} = user

		const terms = [first_name, last_name, department]
			.filter(e => e)
			.map(e => e.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))

		return terms.some(e => e.includes(query) || query.includes(e))
	}

	return (
		<>
			<Paper style={{ 
				margin: '1em 1em 0 1em',
				padding: '1em'
			}}>
				<h1 className="card-title">
					Admin User Groups
				</h1>

				<div style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'flex-end',
					justifyContent: 'space-between',
				}}>
					<FormControl style={{ width: '100%' }}>
						<Label style={{ fontWeight: 500}} disableAnimation shrink>
							Search
						</Label>
						<TextBoxThin
							inputRef={(input: any) => input && firstLoad && input.focus()}
							value={query} 
							onChange={(e: any) => setQuery(e.target.value.toLowerCase().trim())} 
						/>
					</FormControl>

					<Button 
						style={{
							marginLeft: '1em'
						}}
						variant="outlined"
						color="error"
						onClick={() => {
							setQuery('')
						}}
					>
						Reset
					</Button>
				</div>
			</Paper>
			<Grid style={{ padding: '0 1em 1em 1em' }} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 8, md: 12  }}>
				{groupTypes?.map((groupType: any, index: number) => (
					<Grid item xs={2} sm={4} md={4} key={index}>
						<Paper style={{ padding: '1em' }}>
							<Typography variant="h4" component="h5">
								{groupType.value}
							</Typography>

							<div style={loading ? { ...containerStyles, display: 'flex', justifyContent: 'center', alignItems: 'center' } : containerStyles}>
								{!loading ? (
									<List className="admin-gallery-content">
										{userGroups[groupType.value]?.filter(queryFilter)?.sort((a: any, b: any) => b.is_lead - a.is_lead )?.map((userGroup: any) => {
											const { user } = userGroup

											const name = [user.first_name, user.last_name].filter((a) => a).join(' ')

											return (
												<ListItemButton
													key={`${groupType.value}-${user.id}`}
													selected={selectedUserGroup?.user?.id === user.id && selectedGroupType === groupType.id}
													onClick={(event) => {
														setSelectedGroupType(groupType.id)
														setSelectedUserGroup(userGroup)
													}}
													style={{
														borderRadius: '4px',
														margin: '4px 0',
													}}
												>
													<Thumbnail name={name} detail={user?.department ?? '-'} avatar={user?.imgUrl} type="contact" darkText={false} largeText />
													{userGroup.is_lead ? 
														<LightTooltip arrow title="Lead"> 
															<SupervisorAccountIcon style={{ marginLeft: 'auto'}} />
														</LightTooltip> : null}
												</ListItemButton>
											)
										})}
									</List>
								) : (
									<CircularProgress />
								)}
							</div>

							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									width: '100%',
								}}
							>
								<Button
									onClick={() => {
										setGroupModal(groupType)
										setModalOpen(true)
									}}
									style={{
										marginTop: '1em',
									}}
									variant="outlined"
								>
									Add User
								</Button>

								<div>

									{selectedUserGroup && selectedGroupType === groupType.id ? (
										<Button
											onClick={() => {
												updateUserGroup({
													variables: {
														id: selectedUserGroup.id,
														is_lead: !selectedUserGroup.is_lead
													},
												})
													.then(async ({ data }) => {
														if (data.updateUserGroup.success) {
															setUserGroups({
																...userGroups,
																[groupType.value]: userGroups[groupType.value].map((e: any) => {
																	if (e.id === selectedUserGroup.id) {
																		return ({
																			...e,
																			is_lead: !selectedUserGroup.is_lead
																		})
																	}
																	return e
																}),
															})

															props.setQVOverride(null)
															setSelectedUserGroup(null)
															setSelectedGroupType(null)

															openSnackbar(severity.SUCCESS, 'Successfully updated user.')

														} else {
															console.error(data)
															openSnackbar(severity.ERROR, 'There was an error while updating this user.')
														}
													})
													.catch((error: any) => {
													// Handle error updating DB record
														console.error(error)
														openSnackbar(severity.ERROR, 'There was an error while updating this user.')
													})
											}}
											style={{
												marginTop: '1em',
											}}
											color="success"
											variant="outlined"
										>
											{selectedUserGroup.is_lead ? "Mark non-lead" : "Mark lead"}
										</Button>
									) : null}

									{selectedUserGroup && selectedGroupType === groupType.id ? (
										<Button
											onClick={() => {
												removeUserGroup({
													variables: {
														id: selectedUserGroup.id,
													},
												})
													.then(async ({ data }) => {
														if (data.removeUserGroup.success) {
															setUserGroups({
																...userGroups,
																[groupType.value]: userGroups[groupType.value].filter((e: any) => e.id !== selectedUserGroup.id),
															})
															props.setQVOverride(null)
															setSelectedUserGroup(null)
															setSelectedGroupType(null)

															openSnackbar(severity.SUCCESS, 'Successfully removed user from group.')
														} else {
															console.error(data)
															openSnackbar(severity.ERROR, 'There was an error while removing this user from the group.')
														}
													})
													.catch((error: any) => {
														// Handle error removing DB record
														console.error(error)
														openSnackbar(severity.ERROR, 'There was an error while removing this user from the group.')
													})
											}}
											style={{
												marginTop: '1em',
												marginLeft: '1em'
											}}
											color="error"
											variant="outlined"
										>
										Remove User
										</Button>
									) : null}

								</div>
							</div>
						</Paper>
					</Grid>
				))}
			</Grid>

			<TransitionsModal open={!!Object.keys(groupModal)?.length && modalOpen} close={() => closeModal()}>
				<div
					className="dark-theme"
					style={{
						width: '28em',
					}}
				>
					<Typography variant="h5">Add user to {groupModal?.value || ''} user group:</Typography>

					<div>{props.pageName}</div>

					<LimitedAutocomplete
						style={{
							marginTop: '1em',
						}}
						query={loadUser}
						setQuery={setLoadUser}
						minimum={2}
						autoHighlight
						options={userOptions}
						loading={userLoading}
						value={userInput}
						isOptionEqualToValue={(option: any, value: any) => {
							return option === value
						}}
						open={userOpen && !!userOptions?.length}
						onOpen={() => setUserOpen(true)}
						onClose={() => setUserOpen(false)}
						classes={autoCompleteStyles()}
						popupIcon={<ExpandMoreRoundedIcon />}
						getOptionLabel={(option: any) => getGalleryContactLabel(option)}
						renderOption={(optionProps: any, option: any) => {
							// eslint-disable-next-line eqeqeq
							const user: any = userOptions?.find((e: any) => e.id == option || e.id == option.id)

							const name = [user.first_name, user.last_name].filter((a) => a).join(' ')

							const thumbnail = <Thumbnail name={name} detail={user?.department ?? '-'} avatar={user?.imgUrl} type="contact" darkText={false} largeText />

							if (option.disabled) {
								return (
									<li {...optionProps} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
										{thumbnail}
										<i>Already Included</i>
									</li>
								)
							}
							return <li {...optionProps}>{thumbnail}</li>
						}}
						onChange={(event: Event, value: any) => setUserInput(value)}
						renderInput={(params: any) => (
							<FormControl style={{ display: 'flex' }}>
								<Label shrink htmlFor="user-autocomplete" style={{ display: 'block' }}>
									Name
								</Label>
								<TextBoxThinForAutocomplete
									{...params}
									id="user-autocomplete"
									variant="outlined"
									fullWidth
									style={{ paddingTop: '1.8em' }}
									InputLabelProps={{
										shrink: true,
									}}
									autoFocus
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{userLoading ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
									data-testid="permissioncopy-user"
								>
									{' '}
								</TextBoxThinForAutocomplete>
							</FormControl>
						)}
					></LimitedAutocomplete>
				</div>

				<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1.5em' }}>
					<Button variant="outlined" color="error" onClick={() => closeModal()}>
						Cancel
					</Button>
					<Button
						style={{ marginLeft: '1em' }}
						variant="contained"
						color="success"
						onClick={() => {
							addUserToGroup({
								variables: {
									UserGroupInput: {
										user_id: userInput.id,
										gallery_id: galleryId,
										user_group_type_id: groupModal.id,
									},
								},
							})
								.then(async ({ data }) => {
									if (data.addUserToGroup.success) {
										setUserGroups({
											...userGroups,
											...groupBy(data.addUserToGroup.user_groups, 'type.value')
										})
										closeModal()

										openSnackbar(severity.SUCCESS, 'Successfully updated user groups.')
									} else {
										console.error(data)
										openSnackbar(severity.ERROR, 'There was an error while updating user groups.')
									}
								})
								.catch((error) => {
									// Handle error creating DB record
									console.error(error)
									openSnackbar(severity.ERROR, 'There was an error while updating user groups.')
								})
						}}
					>
						Add
					</Button>
				</div>
			</TransitionsModal>
		</>
	)
}

export default AdminGallery
