import React from 'react'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import LockIcon from '@mui/icons-material/Lock'
import { Avatar } from '@mui/material'

const DealThumbnail = ({
	is_private, 
	is_gallery_private, 
	created_at,
	deal_name
}) => {

	if ((is_private || is_gallery_private) && !created_at) {

		return <div className="thumbnail-component" >
			<Avatar variant="circular"> <LockIcon/> </Avatar>
			<span style={{paddingLeft: '1em'}}>{deal_name}</span>
		</div>
	}


	else if ((is_private || is_gallery_private) && created_at) {

		if (is_gallery_private && !is_private) {
			return <span>{deal_name}</span>
		}

		return <div className="thumbnail-component" >
			<Avatar variant="circular"> <LockOpenIcon/> </Avatar>
			<span style={{paddingLeft: '1em'}}>{deal_name}</span>
		</div>
	}

	return <span>{deal_name}</span>
}

export default DealThumbnail
