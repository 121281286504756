/* eslint-disable eqeqeq */
import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchWithAuth } from '../common/helpers'
import routes from '../navigation/routes'
import TabbedPage from '../navigation/Tabs/TabbedPage'
import { AuthStateContext } from '../store'
import theme from '../styles/muiThemes/adminConsoleTheme'
import UserQuickView from '../User/UserQuickView'
import { ADMIN_GET_USER } from './Queries'

const AdminUserGroups = (props: any) => {

	const params = useParams()
	const authState = useContext(AuthStateContext)

	const navigate = useNavigate()

	const adminRedirect = (url: any) => {
		fetchWithAuth(url)
			.then( (resp: any) => resp.json() )
			.then( data => {
				if (!data.isAdminAuthenticated){
					navigate('/admin/login')
				}
			} )	
	}

	useEffect(()=>{
		// redirect if the user is not logged in as admin. 
		const url = new URL("/api/admin-auth-status", window.location.origin)
		adminRedirect(url)
	})

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme} >
				{ TabbedPage({
					...props,
					routes: routes.adminGalleryPages.pages,
					options: routes.adminGalleryPages.options,
					Quickview: UserQuickView,
					query: ADMIN_GET_USER,
					params: {
						id: authState.user?.id || 0,
						...params
					},
				}) }
			</ThemeProvider>
		</StyledEngineProvider>
	)
}

export default AdminUserGroups
