import { FormControl, MenuItem, Select } from '@mui/material'
import Dropdown from '../../input/Dropdown/Dropdown'
import Label from '../../input/Label'
import React, { useContext } from "react"
import TextBox from '../../input/Text/TextBoxThin'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import {SubmitButton, CancelButton} from '../../input/Buttons'
import { isValidEmail } from '../../common/helpers'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

export default function EditEmailModal(props) {

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}

	return (
		<TransitionsModal
			className="email-modal"
			open={props.emailModal.open}
			close={() =>
				props.setEmailModal({ ...props.emailModal, open: false })
			}
		>
			<form
				className={props.isPrivate ? "private-object modal-small-content" : "modal-small-content"}
				onSubmit={e => {
					e.preventDefault()
					e.stopPropagation()

					if (!isValidEmail(props.emailModal?.email?.email) || props.emailModal?.email?.email?.length === 0 || props.emailModal?.email?.email === undefined) {
						openSnackbar(severity.WARNING, "Please fill out the fields in red.")
					} else {
						let temp = props.contactInput.email

						if (Array.isArray(temp)) {
							temp[props.emailModal.count - 1] =
							props.emailModal.email
							props.setContactInput({
								...props.contactInput,
								email: temp
							})
							props.setEmailModal({
								...props.emailModal,
								open: false
							})
						} else {
							props.setContactInput({
								...props.contactInput,
								email: props.emailModal.email
							})
							props.setEmailModal({
								...props.emailModal,
								open: false
							})
						}

					}
				}}
			>
				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Label
					</Label>
					<TextBox
						autoFocus
						placeholder="-"
						value={props.emailModal?.email?.label || ''}
						onChange={e => {
							props.setEmailModal({
								...props.emailModal,
								email: {
									...props.emailModal.email,
									label: e.target.value
								}
							})
						}}
					/>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Type*
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						
						input={<Dropdown />}
						className="medium-selection padded-select"
						value={props.emailModal?.email?.type_id || ''}
						onChange={e => {
							props.setEmailModal({
								...props.emailModal,
								email: {
									...props.emailModal.email,
									type_id: e.target.value,
									type:
										props.emailTypes[e.target.value - 1]
											.value
								}
							})
						}}
					>
						{props.emailTypes && props.emailTypes.map(x => (
							<MenuItem key={x.id} value={x.id}>
								{x.value.charAt(0).toUpperCase() + x.value.substring(1)}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink error={!isValidEmail(props.emailModal?.email?.email) || props.emailModal?.email?.email === undefined}
						defaultValue={props.emailModal?.email?.email}>
						Email*
					</Label>
					<TextBox
						error={!isValidEmail(props.emailModal?.email?.email) || 
							props.emailModal?.email?.email === undefined}
						defaultValue={props.emailModal?.email?.email}
						multiline
						onChange={e => {
							props.setEmailModal({
								...props.emailModal,
								email: {
									...props.emailModal.email,
									email: e.target.value
								}
							})
						}}
						inputProps={{ maxLength: 255 }}
					/>
				</FormControl>

				<CancelButton
					variant="contained"
					style={{ float: 'left', marginTop: '1em' }}
					onClick={e => {
						e.preventDefault()

						if (Array.isArray(props.contactInput.email)) {
							props.setEmailModal({
								...props.emailModal,
								open: false
							})
						} else {
							props.setEmailModal({
								...props.emailModal,
								email: props.contactInput.email,
								open: false
							})
						}
					}}
				>
				Cancel
				</CancelButton>

				<SubmitButton
					style={{ float: 'right', marginTop: '1em' }}
					type="submit"
					variant="contained"
				>
					Submit
				</SubmitButton>

			</form>
		</TransitionsModal>
	)
}
