import { useApolloClient, useMutation, useQuery } from "@apollo/client"
import { ExpandMoreRounded } from "@mui/icons-material"
import {
	Box, Button, FormControl, Menu, MenuItem, Paper, Select,
	ToggleButton, ToggleButtonGroup
} from "@mui/material"
import { capitalize, cloneDeep, isEmpty } from "lodash"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import ReactQuill from "react-quill"
import { Skelly } from "../../../common/components/Skelly"
import { formatDate, getFullName } from "../../../common/helpers"
import { getFormattedAddress } from "../../../Contacts/Contact/ContactInformation"
import { GET_CONTACT_ADDRESSES } from "../../../Contacts/Queries"
import { getPdf, headers } from "../../../Exports/PdfExport"
import { galleries, galleryNames } from "../../../Exports/ShippingAddressInput"
import { CancelButton, SaveCardButton } from "../../../input/Buttons"
import Dropdown from "../../../input/Dropdown/Dropdown"
import Label from "../../../input/Label"
import SingleContact from "../../../input/SingleContact"
import TextBox from "../../../input/Text/TextBox"
import { severity } from "../../../Snackbar/CustomizedSnackbar"
import { DispatchContext } from "../../../store"
import { StyledBadge } from "../../../styles/makesStyles"
import ContactThumbnail from "../../../Thumbnail/ContactThumbnail"
import { GET_DEAL_ART } from "../../Queries"
import { GET_CONSIGNMENT, UPSERT_CONSIGNMENT } from "./queries"
import VerifyModal from "./VerifyModal"

const initialState = {
	mouseX: undefined,
	mouseY: undefined,
	editable: false,
}

const defaultConsignmentAgreements = [
	{
		name: 'Agreement 1',
		contents: `<ol>Consignor hereby grants to Consignee the exclusive right to offer the Work for sale to potential buyers at a gross sales price for which Consignor shall receive, after payment of any and all commissions to Consignee, the agreed Net to Consignor ("Net Price").
		The Consignment Period and the Net Price may be changed from time to time by written mutual consent of Consignor and Consignee.
		Consignor hereby represents that %pronoun% has the full legal authority, without any action or any other party's consent, to enter into and perform this agreement, and that %pronoun% is the sole and absolute owner of the Work, which is free and clear of any and all liens, claims, security interests or other encumbrances held by any person or entity.
		Consignor hereby represents the Work is authentic, that is, the Work was created by the artist indicated on this agreement and that %pronoun% has no knowledge of any information that casts doubt on the authenticity of the Work; the Work has been lawfully exported and imported, as required by the laws of any country in which it was located or transported to; required declarations upon the export and import of the Work have been properly made and any duties and taxes on the export and import of the Work have been paid.
		Consignor hereby represents that %pronoun% has provided Consignee with all information in Consignor's possession concerning the attribution, authenticity, description, condition and provenance of the Work, including information regarding any damage, alterations, repairs or restorations to the Work.
		Consignee will pay all costs and expenses of crating, packing, insuring, and shipping the Work from its current location to Consignee's premises. If the Work is not sold prior to the end of the Consignment Period, Consignee shall pay all costs and expenses of crating, packing, insuring, and shipping the Work back to the same location or to such other location as mutually agreed upon by Consignor and Consignee within fifteen days after the end of the Consignment Period. Consignee shall be responsible for all expenses related to selling the Work.
		Consignee shall arrange and pay for all-risk fine art wall-to-wall insurance for the Work against any loss or damage. Such insurance shall be provided from the moment the Work is released to Consignee's shippers, and until the Work is returned to Consignor or its designate, or title is transferred to Buyer. Consignee will provide Consignor with a certificate of insurance evidencing such insurance coverage.
		In the event that the Work is sold during the Consignment Period, Consignee shall be entitled to receive any gross sale proceeds for the Work above the Net Price as a commission in consideration of its services. Consignee shall retain this commission and forward the balance of the gross sale proceeds for the Work to Consignor within five (5) business days of the receipt by Consignee of the entire gross sales proceeds for the Work in good and clear funds.
		Title to the Work shall pass to Buyer from Consignor through Consignee upon Consignee's receipt of the full payment of the gross sale proceeds for the Work, at which time Consignor specifically authorizes Consignee to effectuate the transfer of ownership and title to the Work, to provide such documentation as is reasonably required upon sale, including a bill of sale for the Work, and to release the Work to Buyer.
		During the term of this Agreement and after the closing of title, neither Consignee nor Consignor will disclose the existence of this Agreement, the identity of the parties hereto, the sale proceeds for the Work, the commission payable or any other term of this Agreement, or the nature of the transaction contemplated hereby, to any third party without the other’s written consent, except to potential buyers and their agents and representatives insofar as necessary to carry out the terms of this Agreement, or as may be required by law.  The terms of this paragraph shall survive the closing of title or termination of this Agreement for any reason.
		This agreement contains the entire understanding between Consignor and Consignee. It may not be changed except by a writing signed by Consignor and Consignee. This agreement shall inure to the benefit of and shall bind the Consignor and Consignee, and any successors permitted assigns. This Agreement and all matters relating to it shall be governed by the laws of the State of New York without regard to conflict of laws principles. Any and all disputes, claims or controversies arising out of or relating to this Agreement shall be submitted to JAMS, or its successor, for mediation, and if the matter is not resolved through mediation, then it shall be submitted to JAMS, or its successor, for final and binding arbitration in New York City pursuant to its Streamlined Arbitration Rules and Procedures. Judgment on any award may be entered in any court having jurisdiction. The arbitrator shall, in any award, allocate all or part of the costs of the arbitration, including the fees of the arbitrator and the reasonable attorneys’ fees of the prevailing party. The Consignor and Consignee also agree that they shall first try to resolve any disputes amicably and in good faith.</ol>
		`.split('\n').map(p => `<li>${p}</li>\n`).join('')
	}
]
export default function ConsignmentInformation(props) {

	const client = useApolloClient()
	const quillRef = useRef(null)

	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({type: 'openSnackBar', payload: {severity, text}})
	}, [dispatch])

	const [dealEntries, setDealEntries] = useState()
	const [attempt, setAttempt] = useState(false)
	const [state, setState] = useState(initialState)
	const [consignmentInformation, setConsignmentInformation] = useState({
		recipient: null,
		pronoun: 'he',
		header: 'Lévy Gorvy Dayan',
		consignmentPeriod: [
			formatDate(props.deal.start_at, 'MMM do, yyyy'),
			formatDate(props.deal.end_at, 'MMM do, yyyy')
		].filter(a => a)
		 .join('—')
	})

	const [consignmentInformationCopy, setConsignmentInformationCopy] = useState()

	const [recipient, setRecipient] = useState()
	const [sender, setSender] = useState()
	const [verifyModal, setVerifyModal] = useState()
	const [generateActive, setGenerateActive] = useState(false)

	const [recipientAddresses, setRecipientAddresses] = useState([])
	const [senderAddresses, setSenderAddresses] = useState([])
	const [recipientAddressesLoading, setRecipientAddressesLoading] = useState(false)
	const [senderAddressesLoading, setSenderAddressesLoading] = useState(false)

	const [selectedAddress, setSelectedAddress] = useState("")
	const [senderAddress, setSenderAddress] = useState("")

	const [recipientType, setRecipientType] = useState('contact')
	const [senderType, setSenderType] = useState('contact')
	const [template, setTemplate] = useState('0')

	// eslint-disable-next-line no-unused-vars
	const { error, loading: initialLoading, data } = useQuery(GET_CONSIGNMENT, {
		skip: !props.deal?.id,
		variables: {
			id: props.deal.id
		}
	})
	if (error) {
		console.error(error)
		openSnackbar(severity.ERROR, 'There was an error loading the current Invoice')
	}
	const { loading: dealEntryLoading } = useQuery(GET_DEAL_ART, {
		variables: {
			id: props.deal?.id,
			limit: 20
		},
		skip: !props.deal?.id,
		onCompleted: result => {

			setDealEntries(
				result.getDeal.deal_entry
					.filter(de => de.art)
					.map(de => {
						const art = de.art
						return {
							id: art?.id,
							title: art?.title,
							profile_link: art?.profile_link,
							artist: art?.artist,
							__typename: "Art"
						}
					})
			)
		},
		onError: error => {
			console.error(error)
			openSnackbar(severity.ERROR, `There was a problem loading this Deal's art.`)
		}
	})

	// Update Address List
	const updateAddress = useCallback((type, contact) => {

		if (!contact?.id) {
			return undefined
		}
		if (type === 'sender') {
			if (senderAddressesLoading) return
			setSenderAddressesLoading(true)
		} else {
			if (recipientAddressesLoading) return
			setRecipientAddressesLoading(true)
		}

		client.query({
			query: GET_CONTACT_ADDRESSES,
			variables: { id: contact.id }
		}).then(result => {
			const addr = result.data.getContact.address

			if (type === 'sender') {
				setSenderAddresses(addr || [])
				setSenderAddress('free')
				setSenderAddressesLoading(false)
			} else {
				setRecipientAddresses(addr || [])
				setSelectedAddress('free')
				setRecipientAddressesLoading(false)
			}

		}).catch(e => {
			console.error(e)
			openSnackbar(severity.ERROR, "There was a problem loading Contact's Addresses")
		})
	}, [ client, recipientAddressesLoading, senderAddressesLoading, openSnackbar ])

	const [upsertConsignmentInformation, { loading: updateLoading }] = useMutation(UPSERT_CONSIGNMENT)

	const loading = updateLoading || initialLoading || dealEntryLoading
	const loadedConsignment = data?.getConsignment
	useEffect(() => {
		if (loadedConsignment) {
			setConsignmentInformation({
				id: loadedConsignment.id,
				header: loadedConsignment.header,
				pronoun: loadedConsignment.pronoun,
				recipientName: loadedConsignment.recipient_name,
				recipientAddress: loadedConsignment.recipient_address,
				senderName: loadedConsignment.sender_name,
				senderAddress: loadedConsignment.sender_address,
				consignmentPeriod: loadedConsignment.consignment_period,
				consignmentAgreement: loadedConsignment.consignment_agreement,
				isDeleted: loadedConsignment.is_deleted
			})
			const recipientGallery = Object.entries(galleryNames)
				.find(([key, val]) => val === loadedConsignment.recipient_name)
				?.[0]
			const senderGallery = Object.entries(galleryNames)
				.find(([key, val]) => val === loadedConsignment.sender_name)
				?.[0]

			if (loadedConsignment.recipient) {
				setRecipient(loadedConsignment.recipient)
				setRecipientType('contact')
				updateAddress('recipient', loadedConsignment.recipient)
			} else if (recipientGallery) {
				setRecipient(recipientGallery)
				setRecipientType('gallery')
			} else {
				setRecipientType('free entry')
			}
			if (loadedConsignment.sender) {
				setSender(loadedConsignment.sender)
				setSenderType('contact')
				updateAddress('sender', loadedConsignment.sender)
			} else if (senderGallery) {
				setSender(senderGallery)
				setSenderType('gallery')
			} else {
				setSenderType('free entry')
			}
			setGenerateActive(true)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadedConsignment])

	const handleClick = (event) => {
		if (state.mouseX || state.editable || props.findMode || !props.deal?.id) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
			editable: false
		})
	}

	const handleClose = (option) => {
		if (option === 'edit') {
			setConsignmentInformationCopy(cloneDeep(consignmentInformation))
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	const handleChange = (e) => updateField(e.target.name, e.target.value)

	const updateField = (field, value) => {
		setConsignmentInformation(old => ({
			...old,
			[field]: value
		}))
	}

	const saveConsignmentInformation = () => {
		setAttempt(true)

		if (attempt && !(
			consignmentInformation.recipientName &&
			consignmentInformation.recipientAddress &&
			consignmentInformation.senderName &&
			consignmentInformation.senderAddress &&
			consignmentInformation.consignmentAgreement
		)) {
			openSnackbar(severity.WARNING, 'Please correct fields in red.')
		} else if (consignmentInformation.recipientName && consignmentInformation.recipientAddress &&
			consignmentInformation.senderName && consignmentInformation.senderAddress) {

			const input = {
				id: consignmentInformation.id,
				deal_id: props.deal.id,
				recipient_id: recipient?.id || null,
				recipient_name: consignmentInformation.recipientName,
				recipient_address: consignmentInformation.recipientAddress,
				sender_id: sender?.id || null,
				sender_name: consignmentInformation.senderName,
				sender_address: consignmentInformation.senderAddress,
				consignment_period: consignmentInformation.consignmentPeriod,
				pronoun: consignmentInformation.pronoun,
				consignment_agreement: quillRef.current.editor.getContents(),
				header: consignmentInformation.header,
				is_deleted: consignmentInformation.isDeleted
			}

			upsertConsignmentInformation({ variables: { input } })
				.then(resp => {
					if (!resp?.data?.upsertConsignment?.success) {
						console.error(resp.errors)
						openSnackbar(severity.ERROR, resp?.data?.upsertConsignment?.message ||
							`There was a problem updating the Consignment.`)
					} else {
						openSnackbar(severity.SUCCESS, resp?.data?.upsertConsignment?.message ||
							`Consignment successfully updated.`)
						setGenerateActive(true)
						setGenerateActive(true)
						setState(oldState => ({
							...oldState,
							editable: false
						}))
						setAttempt(false)
					}
				}).catch(error => {
					console.error(error)
					openSnackbar(severity.ERROR, `There was a problem updating the Consignment.`)
				})


		}
	}

	return <Paper
		className="qv-margin"
		onContextMenu={(e) => !state.mouseX ? handleClick(e) : null}
	>
		<h1 className="card-title">
			<span>Consignment Terms</span>
			{state.editable && <>
				<div className="spacer"></div>
				<CancelButton
					variant="contained"
					size="small"
					onClick={() => {
						if (consignmentInformationCopy !== undefined) {
							setConsignmentInformation(consignmentInformationCopy)
						}
						handleClose()
					}}
				>
					Cancel
				</CancelButton>
				<SaveCardButton
					variant="contained"
					color="secondary"
					size="small"
					disabled={props.disabled}
					onClick={saveConsignmentInformation}
				>
					Save
				</SaveCardButton>
			</>}
		</h1>


		<Box sx={{ display: 'flex', flexDirection: 'row' }}> {/* Invoice Header Row */}
			<FormControl className="padded-select"
				style={{ width: '100%' }}
			>
				<Label id="header-label" disableAnimation shrink>
					Header
				</Label>
				{ loading ? <Skelly /> :
					<Select
						readOnly={!state.editable}
						className={ !state.editable ? 'readonly' : undefined }
						IconComponent={ExpandMoreRounded}
						name="header"

						labelId="header-label"
						input={<Dropdown />}
						value={consignmentInformation.header}
						onChange={handleChange}
					>
						{ headers.map(h => <MenuItem key={h} value={h}>{h}</MenuItem>) }
					</Select> }
			</FormControl>
		</Box>

		<Box sx={{
			display: 'flex',
			flexDirection: 'row'
		}}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flex: 1,
					marginRight: '1em'
				}}
			>

				<FormControl className="fullWidth padded-select">
					<Label id="recipient-label" disableAnimation shrink>
						Consignee Type
					</Label>
					<Select
						IconComponent={ExpandMoreRounded}
						input={<Dropdown/>}
						name="recipientType"
						className={ !state.editable ? 'readonly' : undefined }
						readOnly={!state.editable}
						value={recipientType}
						onChange={e => {
							setRecipientType(e.target.value)
							if (e.target.value === 'gallery') {
								setRecipient('')
							}
						}}
					>
						{ ["Contact", "Gallery", "Free Entry"].map((st, i) =>
							<MenuItem key={i} value={st.toLowerCase()}>{st}</MenuItem>
						)}
					</Select>
				</FormControl>
				{ recipientType === "gallery" ?
					<FormControl className="padded-select fullWidth" style={{
						display: 'flex'
					}}>
						<Label id="gallery-label" disableAnimation shrink>
							Gallery Address
						</Label>
						<Select
							IconComponent={ExpandMoreRounded}
							name="gallery"
							className={ !state.editable ? 'readonly' : undefined }
							readOnly={!state.editable}
							labelId="gallery-label"
							input={<Dropdown />}
							value={recipient}
							onChange={(e) => {
								const value = e.target.value
								setRecipient(value)
								handleChange({ target: {
									name: 'recipientName',
									value: galleryNames[value] || ''
								}})
								handleChange({ target: {
									name: 'recipientAddress',
									value: galleries[value] || ""
								}})
							}}
						>
							{Object.keys(galleries).map(g => (
								<MenuItem key={g} value={g}>{ g }</MenuItem>
							))}
						</Select>
					</FormControl> : null}
				{ recipientType === "contact" ? <>

					<FormControl sx={{ flex: 1 }}>
						<Label id="recipient-label" disableAnimation shrink>
							Consignee
						</Label>
						{ state.editable ?
							<SingleContact
								elasticSearch="searchContacts"
								value={recipient}
								onChange={(event, value) => {
									setRecipient(value)
									if (value) {
										updateAddress('recipient', value)
										setVerifyModal({
											name: 'Consignee Name',
											field: 'recipientName',
											value: getFullName(value)
										})
									}
								}}
								private={props.private}
							/> :
							(loading ? <Skelly /> :
								<ContactThumbnail
									style={{ marginTop: '2em' }}
									contact={recipient}
								/>
							)

						}
					</FormControl>
					{ (state.editable && !isEmpty(recipient)) ?
						<FormControl
							sx={{ flex: 1 }}
							className="padded-select"
						>
							<Label id="contact-address-label" disableAnimation shrink>
								Select Address
							</Label>
							<Select
								IconComponent={ExpandMoreRounded}
								name="contact-address"

								labelId="contact-address-label"
								input={<Dropdown />}
								value={selectedAddress}
								onChange={(e) => {
									const addr = e.target.value
									setSelectedAddress(addr)
									if (addr !== 'free') {
										setVerifyModal({
											name: 'Consignee Address',
											field: 'recipientAddress',
											value: getFormattedAddress([], addr)
										})
									}
								}}
							>
								{recipientAddresses.map((addr) => (
									<MenuItem key={addr.id} value={addr}>
										{`${ addr.label||'' } (${addr.type})`}
										{addr?.is_primary ?
											<StyledBadge
												style={{marginLeft: '.5em'}}
												overlap="circular"
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'right',
												}}
												variant="dot"
											></StyledBadge>
											: null
										}
									</MenuItem>
								))}
								<MenuItem key="fe" value="free">Free Entry</MenuItem>
							</Select>
						</FormControl> :
						null
					}</> : null
				}
				<div style={{ height: '1em' }}>{/* spacer */}</div>

				<FormControl sx={{ borderTop: '1px solid grey' }}>
					<Label id="recipient-label"
						disableAnimation
						shrink
						error={attempt && !consignmentInformation.recipientName}
					>
						Consignee Name
					</Label>
					{ loading ? <Skelly /> :
						<TextBox
							error={attempt && !consignmentInformation.recipientName}
							readOnly={!state.editable}
							className="user-input"
							name="recipientName"
							value={consignmentInformation.recipientName}
							onChange={handleChange}
						/>}
				</FormControl>
				<FormControl>
					<Label  id="recipient-address"
						disableAnimation
						shrink
						error={attempt && !consignmentInformation.recipientAddress}
					>
						Consignee Address
					</Label>
					{ loading ? <Skelly /> :
						<TextBox
							readOnly={!state.editable}
							className="user-input"
							name="recipientAddress"
							value={consignmentInformation.recipientAddress}
							error={attempt && !consignmentInformation.recipientAddress}
							onChange={handleChange}
							multiline
							rows="4"
						/>}
				</FormControl>

			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flex: 1
				}}
			>
				<FormControl className="fullWidth padded-select">
					<Label id="recipient-label" disableAnimation shrink>
						Consignor Type
					</Label>
					<Select
						IconComponent={ExpandMoreRounded}
						input={<Dropdown/>}
						name="recipientType"
						className={ !state.editable ? 'readonly' : undefined }
						readOnly={!state.editable}
						value={senderType}
						onChange={e =>{
							setSenderType(e.target.value)
							if (e.target.value === 'gallery') {
								setSender('')
							}
						}}
					>
						{ ["Contact", "Gallery", "Free Entry"].map((st, i) =>
							<MenuItem key={i} value={st.toLowerCase()}>{st}</MenuItem>
						)}
					</Select>
				</FormControl>
				{ senderType === "gallery" ?
					<FormControl className="padded-select fullWidth" style={{
						display: 'flex'
					}}>
						<Label id="gallery-label" disableAnimation shrink>
							Gallery Address
						</Label>
						<Select
							IconComponent={ExpandMoreRounded}
							name="gallery"
							className={ !state.editable ? 'readonly' : undefined }
							readOnly={!state.editable}
							labelId="gallery-label"
							input={<Dropdown />}
							value={sender}
							onChange={(e) => {
								const value = e.target.value
								setSender(value)
								handleChange({ target: {
									name: 'senderName',
									value: galleryNames[value] || ''
								}})
								handleChange({ target: {
									name: 'senderAddress',
									value: galleries[value] || ""
								}})
							}}
						>
							{Object.keys(galleries).map(g => (
								<MenuItem key={g} value={g}>{ g }</MenuItem>
							))}
						</Select>
					</FormControl> : null}
				{ senderType === "contact" ? <>
					<FormControl sx={{ flex: 1 }}>
						<Label id="sender-label" disableAnimation shrink>
							Consignor
						</Label>
						{ state.editable ?
							<SingleContact
								elasticSearch="searchContacts"
								value={sender}
								onChange={(event, value) => {
									setSender(value)
									if (value) {
										updateAddress('sender', value)
										setVerifyModal({
											name: 'Consignor Name',
											field: 'senderName',
											value: getFullName(value)
										})
									}
								}}
								private={props.private}
							/> :
							(loading ? <Skelly /> :
								<ContactThumbnail
									style={{ marginTop: '2em' }}
									contact={sender}
								/>
							)

						}
					</FormControl>
					<div style={{ width: '1em' }}>{/* spacer */}</div>
					{ (state.editable && !isEmpty(sender)) ?
						<FormControl
							sx={{ flex: 1 }}
							className="padded-select"
						>
							<Label id="sender-address-label" disableAnimation shrink>
								Select Address
							</Label>
							<Select
								IconComponent={ExpandMoreRounded}
								name="sender-address"

								labelId="sender-address-label"
								input={<Dropdown />}
								value={senderAddress}
								onChange={(e) => {
									const addr = e.target.value
									setSenderAddress(addr)
									if (addr !== 'free') {
										setVerifyModal({
											name: 'Consignor Address',
											field: 'senderAddress',
											value: getFormattedAddress([], addr)
										})
									}
								}}
							>
								{senderAddresses.map((addr) => (
									<MenuItem key={addr.id} value={addr}>
										{`${ addr.label||'' } (${addr.type})`}
										{addr?.is_primary ?
											<StyledBadge
												style={{marginLeft: '.5em'}}
												overlap="circular"
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'right',
												}}
												variant="dot"
											></StyledBadge>
											: null
										}
									</MenuItem>
								))}
								<MenuItem key="fe" value="free">Free Entry</MenuItem>
							</Select>
						</FormControl> :
						null
					}
				</> : null}
				<div style={{ height: '1em' }}>{/* spacer */}</div>

				<FormControl sx={{ borderTop: '1px solid grey' }}>
					<Label id="recipient-label"
						disableAnimation
						shrink
						error={attempt && !consignmentInformation.senderName}
					>
						Consignor Name
					</Label>
					{ loading ? <Skelly /> :
						<TextBox
							readOnly={!state.editable}
							className="user-input"
							name="senderName"
							value={consignmentInformation.senderName}
							error={attempt && !consignmentInformation.senderName}
							onChange={handleChange}
						/>}
				</FormControl>
				<FormControl>
					<Label id="sender-address"
						disableAnimation
						shrink
						error={attempt && !consignmentInformation.senderAddress}
					>
						Consignor Address
					</Label>
					{ loading ? <Skelly /> :
						<TextBox
							readOnly={!state.editable}
							className="user-input"
							name="senderAddress"
							value={consignmentInformation.senderAddress}
							error={attempt && !consignmentInformation.senderAddress}
							onChange={handleChange}
							multiline
							rows="4"
						/>}
				</FormControl>
			</Box>
		</Box>

		<Box sx={{ display: 'flex', flexDirection: 'row' }}> {/* Invoice Header Row */}
			<FormControl className="padded-select"
				style={{ width: '100%', marginRight: '1em' }}
			>
				<Label id="header-label" disableAnimation shrink>
					Consignment Period
				</Label>
				{ loading ? <Skelly /> :
					<TextBox
						readOnly={!state.editable}
						name="consignmentPeriod"
						value={consignmentInformation.consignmentPeriod}
						onChange={handleChange}
					/> }
			</FormControl>
			<FormControl
				className="fullWidth"
				style={{
					paddingTop:  state.editable ? '1.5em' : null,
				}}
			>
				<Label disableAnimation shrink id="consignor-pronoun">
					Consignor Pronoun
				</Label>
				{ state.editable ?
					<ToggleButtonGroup
						value={consignmentInformation.pronoun}
						exclusive
						onChange={(_, value)=> { handleChange({ target: {
							name: 'pronoun',
							value
						}})}}
						name="consignor-pronoun"
						style= {{ height: '2.8em' }}
					>
						{["He", "She", "It"].map( pronoun =>
							<ToggleButton
								value={pronoun.toLowerCase()}
								style={{width: "4em"}}
								key={pronoun}
							>
								{ pronoun }
							</ToggleButton>
						)}

					</ToggleButtonGroup> :
					<TextBox
						className="user-input"
						value={capitalize(consignmentInformation.pronoun)}
						readOnly={true}
					/>
				}

			</FormControl>
		</Box>

		<Box sx={{
			display: 'flex',
			flexDirection: "column"
		}}>
			<FormControl>
				<Label shrink
					error={attempt && !consignmentInformation.consignmentAgreement}
				>Consignment Agreement</Label>
				<div style={{ height: '1.5em' }}></div>
			</FormControl>
			{ loading ? <Skelly /> : <>
				{ state.editable ?
					<FormControl className="padded-select"
						style={{ width: '100%', marginBottom: '1em' }}
					>
						<Label id="header-label" disableAnimation shrink>
							Template
						</Label>

						<Select
							readOnly={!state.editable}
							className={ !state.editable ? 'readonly' : undefined }
							IconComponent={ExpandMoreRounded}
							name="template"
							labelId="template-label"
							input={<Dropdown />}
							value={template}
							onChange={event => {
								const value = event.target.value
								setTemplate(value)
								if (value !== '0') {
									setConsignmentInformation( old => ({
										...old,
										consignmentAgreement: defaultConsignmentAgreements[value-1]?.contents,
									}))
								}
							}}
						>
							{
								defaultConsignmentAgreements.map((a, i) =>
									<MenuItem key={i+1} value={i+1}>
										{ a.name }
									</MenuItem>
								)
							}
							<MenuItem key={0} value="0">Free Entry</MenuItem>
						</Select>
					</FormControl> : null
				}

				<ReactQuill
					className={state.editable ? '' : 'not-editable'}
					ref={quillRef}
					readOnly={!state.editable}
					modules={{ toolbar: false }}
					theme="snow"
					value={ consignmentInformation.consignmentAgreement || { ops: [] }}
					onChange={(content, delta, source, editor) => {
						setConsignmentInformation( old => ({
							...old,
							consignmentAgreement: editor.getContents(),
						}))
						setTemplate("0")
					}}
				/>
			</>}
		</Box>

		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'end',
				marginTop: '1em'
			}}
		>
			{!state.editable &&
				<Button
					variant="contained"
					color="success"
					disabled={!generateActive || loading}
					onClick={async () => {
						setGenerateActive(false)

						const compression = {width: 768, height: 768}
						const agreement = quillRef.current.getEditor().getText()
							?.replace(/\n/g, '\n\n')

						await getPdf(
							"Art Consignment Form",
							consignmentInformation.header,
							null,
							dealEntries,
							openSnackbar,
							{
								shipTo: consignmentInformation.recipientAddress,
								shipFrom: consignmentInformation.senderAddress,
								shipToName: consignmentInformation.recipientName,
								shipFromName: consignmentInformation.senderName,
								dealId: props.deal?.id,
								dealType: props.deal?.deal_type?.value,
								period: consignmentInformation.consignmentPeriod,
								agreement
							},
							compression
						)
						setGenerateActive(true)
					}}
				>
					Generate Agreement
				</Button>
			}
		</Box>

		<VerifyModal
			open={verifyModal}
			onClose={() => setVerifyModal(undefined)}
			field={verifyModal?.field}
			name={verifyModal?.name}
			value={verifyModal?.value}
			onChange={handleChange}
		/>

		<Menu
			keepMounted
			open={state.mouseY != null}
			onClose={() => handleClose()}
			anchorReference="anchorPosition"
			anchorPosition={
				state.mouseY != null && state.mouseX != null
					? { top: state.mouseY, left: state.mouseX }
					: undefined
			}
		>
			<MenuItem onClick={() => handleClose('edit')}>Edit</MenuItem>
		</Menu>

	</Paper>
}
