const ArtDetail = (props) => {
    const { art } = props
    const { artist, imgUrl, inventory_number, inventory_number_prefix, inventory_number_suffix, title: artTitle, year } = art || {}

    const title = artist?.first_name + ' ' + artist?.last_name
    const subTitle = artTitle + ', ' + year
    const inventoryNo = `${inventory_number_prefix}-${inventory_number}-${inventory_number_suffix}`

    return (
        <div className="flex flex-col w-[310px]">
            <div className="flex bg-grey p-4 shadow rounded h-[310px]">
                <img src={imgUrl || `/images/icons/White/ArtImage.svg`} alt={subTitle} className="w-full h-full object-cover" />
            </div>
            <div className="flex flex-col text-sm">
                <div className="text-black">{title}</div>
                <div className="text-grey italics">{subTitle}</div>
                <div className="text-black">{inventoryNo}</div>
            </div>
        </div>
    )
}

export { ArtDetail }
