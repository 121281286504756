/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useEffect } from "react"
import { FormControl, IconButton, FormControlLabel, MenuItem, Select } from '@mui/material'
import Label from '../input/Label'
import makeStyles from '@mui/styles/makeStyles'
import TextBox from '../input/Text/TextBox'
import { SaveButton, SearchButton, ResetButton, AdvancedToggleButton, ChooseImageButton } from '../input/Buttons'
import { DispatchContext, LookupContext } from '../store'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { GET_USERS } from '../User/Queries'
import {autoCompleteStyles} from '../styles/makesStyles'
import { AuthStateContext } from '../store'
import { withApollo } from '@apollo/client/react/hoc'
import SearchInfo from "../Search/SearchInfo"
import { ControlPoint } from "@mui/icons-material"
import AdvancedFilterCount from '../Search/AdvancedFilterCount'
import Dropdown from "../input/Dropdown/Dropdown"
import { FindModeButton } from "../Search/GlobalSearchFilters"
import { DEAL_SEARCH_OBJECT } from "../constants/values"
import { SavedSearch } from "../common/SavedSearch"
import ConfirmationInput from "../navigation/ConfirmationDialog/ConfirmationInput"
import { CREATE_SEARCH } from '../Search/Queries'
import { emptySearch, filterSearchTerms, saveMap, searchMap , saveSearch} from "../Search/unifiedSearchHelpers"
import { getGalleryContactLabel, randomHSL } from "../common/helpers"
import { useMutation } from "@apollo/client"
import { severity } from "../Snackbar/CustomizedSnackbar"

const useStyles = makeStyles(() => ({
	searchSelection: {
		width: "100%",
		paddingRight: "1em",
		"&:last-child": {
			paddingRight: "0em",
		}
	},
	searchDate: {
		marginTop: "0.5em"
	}
}))

const SearchDeals = (props) => {
	const lookup = useContext(LookupContext)
	const types = lookup.data?.getDealTypes
	const statusTypes = lookup?.data?.getDealEntryStatuses
	const statusCategories = lookup?.data?.getDealEntryStatusCategories

	const classes = useStyles()
	const autoCompleteStyle = autoCompleteStyles()

	// User
	const userAuthentication = useContext(AuthStateContext)
	const [currentSearch, setCurrentSearch] = React.useState(props.prevSearch ?? new SavedSearch({
		object_id: DEAL_SEARCH_OBJECT,
		search_terms: [],
		is_global: true,
		user_id: userAuthentication.user?.id
	}))
	const [savedSearchDialog, setSavedSearchDialog] = React.useState({
		open: false,
		search: null
	})

	// Mutations
	const [createSearch, { loading }] = useMutation(CREATE_SEARCH)

	useEffect(() => {
		if (props.currentSearch) setCurrentSearch(props.currentSearch)
	}, [props.currentSearch])

	const getSearchValue = useCallback((fieldName) =>
		currentSearch.search_terms
			?.find(el => el.field == fieldName)?.value,
	[currentSearch.search_terms])

	const handleDealSavedSearchChange = (event, type = 'eq', cardType) => {

		const name = event.target.name
		const value = event.target.value

		let filterName = null
		if ((name == 'deal_entry_status_category' || name == 'deal_entry_status_id') && cardType == 'simple') {
			if (name == 'deal_entry_status_category') filterName = 'deal_entry_status_id'
			else if (name == 'deal_entry_status_id') filterName = 'deal_entry_status_category'
		}

		let search_terms

		if (currentSearch.search_terms.find(el => el.field == name)) {
			search_terms = filterSearchTerms(currentSearch.search_terms.map(term => {
				if (term.field == name) {
					return {
						...term,
						type,
						value: `${value}`
					}
				} return term
			}))
		} else {
			search_terms = filterSearchTerms(currentSearch.search_terms.concat([{
				id: new Date().getTime(),
				field: name,
				type,
				value: `${value}`,
			}])).filter(el => el.field != filterName)
		}

		setCurrentSearch({
			...emptySearch,
			search_terms
		})

		if (props.currentSearch.id) {
			props.setCurrentSearch({
				...props.currentSearch,
				search_terms,
				id: null
			})
		}
	}


	const [options, setOptions] = React.useState([])

	const [firstLoad, setFirstLoad] = React.useState(true)
	React.useEffect(() => setFirstLoad(false), [])

	const dispatch = useContext(DispatchContext)
	const openQuickView = useCallback((severity, text) => {
		dispatch({ type: 'openQuickView' })
	}, [dispatch])

	// Snackbar
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	React.useEffect(() => {
		let active = true

		if (active && !options.length) {
			props.client
				.query({
					query: GET_USERS,
					variables: {
						includeDeleted: true
					}
				})
				.then(result => {
					if (active)
						setOptions(result.data.getUsers.items)
				})
		}

		return () => {
			active = false
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// This populate the gallery contacts autocomplete from saved searches
	React.useEffect(() => {

		const names = getSearchValue('gallery_contacts')?.split(' AND ')

		if (options.length && getSearchValue('gallery_contacts')) {
			props.setSelection(options.filter(user =>
				 names.includes([user.first_name, user.last_name].join(" "))
			))
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getSearchValue('gallery_contacts')])

	// This populate the deal entry & category autocomplete from saved searches
	React.useEffect(() => {

		let active = true

		const category = getSearchValue('deal_entry_status_category')

		const status = getSearchValue('deal_entry_status_id')

		if (active && category) {
			props.setDealAutoValue(dealOptions.find(option => {
				return option.value == category
			}))
		}

		if (active && !category && status) {
			props.setDealAutoValue(dealOptions.find(option => {
				return option.type == 'deal_entry_status_id' &&
					option.id == status
			}))
		}

		return () => {
			active = false
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getSearchValue])

	const typeStyle = {
		fontWeight: 500,
	}

	const byId = (a, b) => a.id - b.id

	const getDealStatusItems = (categoryId) => {
		return !!statusTypes ? statusTypes.filter(el =>
			el.deal_entry_status_category.id == categoryId
		).map(type => ({
			id: type.id,
			value: type.value,
			type: "deal_entry_status_id",
			dataType: 'Integer',
			categoryId
		})) : []
	}

	const dealOptions = [
		{id: 0, type: "deal_entry_status_category", dataType: 'String', value: ""},
		...(statusCategories?.sort(byId)?.map(sc => ([
			{
				id: sc.id,
				value: sc.value,
				type: 'deal_entry_status_category',
				dataType: 'String'
			},
			...getDealStatusItems(sc.id)?.sort(byId)
		]))?.flat() || [])
	]



	return <>
		<h1 className="card-title">

            Search Deals

			<ChooseImageButton
				data-testid="new-deal-button"
				variant="contained"
				size="small"
				style={{backgroundColor: !props.loading ? '#4465D1' : null}}
				onClick={() => {
					openQuickView()
					props.setCreateDeal(true)
				}}
				endIcon={<ControlPoint />}
			>
                Create
			</ChooseImageButton>

			<AdvancedFilterCount
				advancedFilters={props.advancedFilters}
				setAdvancedSearch={props.setAdvancedSearch}
			/>

			<SearchInfo
				advancedFilters={!!props.advancedFilters?.length}
				fields={["Artwork", "Contact", "ID"]}
				recordName="deal"
				unified
			/>

		</h1>
		<form onSubmit={(e) => {
			e.preventDefault()
			props.setActiveStep(0)
			props.handleSubmit(
				{ cursor: 0 },
				currentSearch.search_terms,
				currentSearch.id
			)
		}}>
			<div className="flex-row" style={{justifyContent: 'space-between'}}>

				<FormControl className={classes.searchSelection}>
					<Label id="type-label" style={typeStyle} disableAnimation shrink>
                        Type
					</Label>
					<Select
						data-testid="search-type"
						IconComponent={ExpandMoreRoundedIcon}
						name="searchable_deal_type"
						labelId="type-label"
						className="padded-select"
						input={<Dropdown />}
						value={ getSearchValue('searchable_deal_type') || '' }
						onChange={(event) => handleDealSavedSearchChange(event)}
					>
						<MenuItem value="" data-testid="search-type-item">All</MenuItem>
						{types && types.map(type => (
							<MenuItem key={type.id} value={type.value} data-testid="search-type-item">{type.value}</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl className={classes.selection} style={{width: "100%", marginRight: '1em'}}>

					<Label
						id="deal-type-status-label"
						style={typeStyle}
						disableAnimation
						shrink
					>
                        Deal Entry Status & Category
					</Label>

					<Autocomplete
						id="deal-type-status-autocomplete"
						options={dealOptions}
						classes={autoCompleteStyle}
						value={props.dealAutoValue ?? dealOptions[0]}
						popupIcon={<ExpandMoreRoundedIcon />}
						size="small"
						style={{marginTop: "1.5em"}}
						isOptionEqualToValue={(option, value) => {
							return option.id == value.id
                                && option.type == value.type
						}}
						getOptionLabel={(option) => option.value}
						filterOptions={(options, state) => {

							/*
                            This custom filterOptions function shows the headers
                            on the deal entry status and category autocomplete
                            even when they do not match the users search.
                            */

							let { inputValue } = state
							inputValue = inputValue.toLowerCase().trim()

							const nestedOptions = options
								?.filter((option) => {
									return option.id && !option.categoryId
								})
								.map((option) => {
									return {
										...option,
										deal_entry_status: options?.filter(
											(innerOption) => {
												return (
													innerOption.categoryId ==
                                                    option.id
												)
											}
										),
									}
								})

							return nestedOptions.flatMap((el) => {
								const subArray =
                                    el.deal_entry_status.filter(
                                    	(option) => {
                                    		return option.value
                                    			.toLowerCase()
                                    			.includes(inputValue)
                                    	}
                                    )

								const hasSubItems = subArray.length

								return [
									hasSubItems ||
                                    el.value
                                    	.toLowerCase()
                                    	.includes(inputValue)
										? el
										: null,
									...subArray,
								].filter(Boolean)
							})
						}}
						onChange={(_, value) => {

							props.setDealAutoValue(value)

							if (value == null) {

								props.setCurrentSearch({
									...currentSearch,
									search_terms: currentSearch.search_terms.filter(el => {

										return el.field !== "deal_entry_status_category" &&
											el.field !== "deal_entry_status_id"
									})
								})

							}

							else {
								handleDealSavedSearchChange({
									target: {
										name: value.type,
										value: value.dataType == 'String' ?
											value.value : value.id
									}
								}, 'eq', 'simple')
							}



						}}
						renderInput={params => (
							<TextField
								{...params}
								variant="outlined"
								fullWidth
								InputProps={{
									...params.InputProps,
									style: {'padding': '9px'},
								}}
							/>
						)}
						renderOption={(optionProps, option) => {

							const style = {
								marginLeft: option.type != "deal_entry_status_category" ? '2em' : null
							}

							return (
								<li {...optionProps} key={`${option.id}-${option.value}`} style={style}>{option.value}</li>
							)
						}}
					/>

				</FormControl>


				<FormControl style={{ width: '100%' }}>
					<Label
						id="created-at-label"
						style={{fontSize: 17}}
						disableAnimation
						shrink
					> Date Created
					</Label>
					<MobileDatePicker
						inputFormat="MMM do, yyyy"
						disableMaskedInput={true}
						componentsProps={{
							actionBar: {
								actions: ['clear', 'accept']
							}
						}}
						data-testid="search-date_created"
						name="created_at"
						value={getSearchValue('created_at') || null}
						inputVariant="outlined"
						className="MUIDatePicker"
						variant="dialog"
						placeholder="-"
						renderInput={({ inputRef, inputProps, InputProps }) => {

							const newProps = { ...inputProps}

							newProps.readOnly = false

							return (
								<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
							)}}
						onChange={(date) => handleDealSavedSearchChange({
							target: {
								name: 'created_at',
								value: date ? date.toDateString() : null
							}
						})}
					/>
				</FormControl>

				<FormControl className={classes.selection} style={{width: "100%", marginLeft: "1em"}}>
					<Label
						id="user-label"
						style={typeStyle}
						disableAnimation
						shrink
					>
                        Gallery Contact
					</Label>

					<Autocomplete
						multiple
						classes={autoCompleteStyle}
						style={{marginTop: "1.5em"}}
						isOptionEqualToValue={(option, value) => {
							return option.id == value.id
						}}
						forcePopupIcon
						filterSelectedOptions
						popupIcon={<ExpandMoreRoundedIcon />}
						size="small"
						value={props.selection ?? []}
						getOptionLabel={(option) => getGalleryContactLabel(option)}
						options={options || []}
						onChange={(_, value) => {
							props.setSelection(value)
							const names = value.map(x=> [x.first_name, x.last_name].join(' ')).join(" AND ")
							handleDealSavedSearchChange({
								target: {
									name: "gallery_contacts",
									value: names
								}
							})
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								fullWidth
								InputProps={{
									...params.InputProps,
									style: {'padding': '9px'},
								}}
							/>
						)}
						data-testid="search-gallery_contact"
					/>
				</FormControl>
			</div>
			<div className="row">
				<div className="flex-row search-booleans" style={{flexGrow: '0'}}>
					<FormControlLabel
						value="top"
						style={{
							marginTop: '0.4em'
						}}
						control={
							<>
								{getSearchValue('isFavorite') == null ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleDealSavedSearchChange({
												target: {
													name: "isFavorite",
													value: false
												}
											})
										}}
										title="All"
										size="large">
										<StarBorderIcon style={{ color: 'grey' }} />
									</IconButton>
									: null}

								{getSearchValue('isFavorite') == 'false' ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleDealSavedSearchChange({
												target: {
													name: "isFavorite",
													value: true
												}
											})
										}}
										title="Non-Favorite"
										size="large">
										<StarBorderIcon style={{color: 'hsl(50, 93%, 59%)'}} />
									</IconButton>
									: null}

								{getSearchValue('isFavorite') == 'true' ?
									<IconButton
										style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
										onClick={() => {
											handleDealSavedSearchChange({
												target: {
													name: "isFavorite",
													value: null
												}
											})
										}}
										title="Favorite"
										size="large">
										<StarIcon style={{color: 'hsl(50, 93%, 59%)'}} />
									</IconButton>
									: null}
							</>
						}
						label="Favorite"
						labelPlacement="top"
					/>

				</div>
				<FormControl className={classes.searchBar} style={{ flexGrow: '2' }}>
					<Label style={typeStyle} disableAnimation shrink>
                        Art/Contact
					</Label>
					<TextBox
						data-testid="search-contact"
						name="query"
						inputRef={input => input && firstLoad && input.focus()}
						value={getSearchValue('query') || ''}
						onChange={(event) => handleDealSavedSearchChange(event)} />
				</FormControl>
			</div>

			<div className="row">
				<div>
					<SaveButton
						data-testid="save-button"
						variant="contained"
						size="small"
						onClick={() => {
							saveSearch(
								currentSearch,
								openSnackbar,
								severity,
								setSavedSearchDialog
							)
						}}
					>
                        Save Search
					</SaveButton>
				</div>
				<FindModeButton onFindMode={props.onFindMode} />
				<AdvancedToggleButton
					data-testid="advanced-toggle-button"
					size="small"
					onClick={() => {
						props.setCurrentSearch(currentSearch)
						props.setAdvancedSearch(true)
					}}
					style={{ marginRight: '1em' }}
				>
                    Advanced
				</AdvancedToggleButton>
				<ResetButton
					data-testid="reset-button"
					variant="contained"
					size="small"
					onClick={() => {
						props.setTotalItems(null)
						props.setSelection([])
						props.resetSearch('simple')
						setCurrentSearch(new SavedSearch({
							object_id: DEAL_SEARCH_OBJECT,
							search_terms: [],
							is_global: true,
							user_id: userAuthentication.user?.id
						}))
					}}
					style={{ marginRight: '1em' }}
				>
                    Reset
				</ResetButton>
				<SearchButton
					data-testid="search-button"
					variant="contained"
					size="small"
					type="submit"
					style={{backgroundColor: !props.loading ? '#4465D1' : null}}
					disabled={props.loading}
				>
                    Search
				</SearchButton>
			</div>
		</form>

		<ConfirmationInput
			open={savedSearchDialog.open}
			handleClose={() => setSavedSearchDialog({
				open: false,
				savedSearch: null
			})}
			title={'Save search?'}
			disabled={loading}
			acceptText={'Save'}
			buttonColor="#33BF5C"
			inputLabel="Search Label"
			placeholder={'Saved Search ' + (props.savedSearches?.length + 1)}
			onYes={(text) => {
				if (!loading) {
					createSearch({
						variables: {
							CreateSearchInput: {
								label: text ? text : 'Saved Search ' + (props.savedSearches?.length + 1),
								user_id: userAuthentication.user.id,
								object_id: DEAL_SEARCH_OBJECT,
								color: randomHSL(),
								search_terms: searchMap(currentSearch.search_terms),
								result_count: null,
								is_global: true,
							},
						},
					}).then((response) => {

						setCurrentSearch({
							...response.data.createSavedSearch.search,
							search_terms: saveMap(response.data.createSavedSearch.search.search_terms)
						})

						props.setSavedSearches(props.savedSearches.concat(
							new SavedSearch(response.data.createSavedSearch.search))
						)
					})
				}
			}}
		/>
	</>
}

export default withApollo(SearchDeals)
