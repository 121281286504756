import withStyles from '@mui/styles/withStyles'
import InputBase from '@mui/material/InputBase'
import * as Colors from '../../styles/colors/Colors'

const Dropdown = withStyles(theme => ({
	root: {
		'label + &': {
		  marginTop: theme.spacing(3),
		},
	},
	input: {
		borderRadius: 4,
		position: 'relative',
		border: 'none',
		fontSize: 16,
		padding: '10px 30px 10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: 'Graphik',
		'&:focus': {
			borderRadius: 4,
			borderColor: Colors.editAdd,
		},
	},
	error: {
		border: '1px solid red',
		borderRadius: 4,
	}
}))(InputBase)

export default Dropdown
