import React, { useEffect } from 'react'
import NewEntityTask from '../../../Tasks/EntityTasks/NewEntityTask'
import { GET_TASKS, CREATE_TASK, UPDATE_TASK } from './Queries'
import TasksList from '../../../Tasks/EntityTasks/TasksList'
import { DispatchContext } from '../../../store'
import { severity } from '../../../Snackbar/CustomizedSnackbar'
import { isNullArray } from '../../../common/helpers'
import { withApollo } from '@apollo/client/react/hoc'
import { TASK_STATUSES } from '../../../constants/values'
import { useQuery } from '@apollo/client'

const ContactTasks = (props) => {
	const [tasks, setTasks] = React.useState([])

	//Snackbar
	const dispatch = React.useContext(DispatchContext)
	const openSnackbar = React.useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const { loading: tasksLoading, data, refetch } = useQuery(GET_TASKS, {
		variables: { contact_id: props.id}
	})


	useEffect(() => {
		
		if (data && !isNullArray(data.getContactTasks)) {
			setTasks(data.getContactTasks || [])

		} else if (data && isNullArray(data.getContactTasks)) {
			setTasks([])
			openSnackbar(severity.ERROR, "Error - Unauthorized")
		}
		
	}, [data, openSnackbar])


	return (!props.findMode && props.id > 0) && (
		<>
			<NewEntityTask
				entityId={props.id}
				tasks={tasks}
				setTasks={setTasks}
				mutation={CREATE_TASK}
				mutationResponse="createContactTask"
				linkField="contact_id"
			></NewEntityTask>
			<TasksList
				tasksLoading={tasksLoading}
				title="Ready to Start"
				status={TASK_STATUSES.readyToStart}
				tasks={tasks}
				setTasks={setTasks}
				mutation={UPDATE_TASK}
				mutationResponse="updateContactTask"
				deleteResponse="deleteContactTask"
				linkField="contact_id"
				entityId={props.id}
				refetch={refetch}
			></TasksList>
			<TasksList
				tasksLoading={tasksLoading}
				title="In Progress"
				status={TASK_STATUSES.inProgress}
				tasks={tasks}
				setTasks={setTasks}
				mutation={UPDATE_TASK}
				mutationResponse="updateContactTask"
				deleteResponse="deleteContactTask"
				linkField="contact_id"
				entityId={props.id}
				refetch={refetch}
			></TasksList>
			<TasksList
				tasksLoading={tasksLoading}
				title="Review"
				status={TASK_STATUSES.review}
				tasks={tasks}
				setTasks={setTasks}
				mutation={UPDATE_TASK}
				mutationResponse="updateContactTask"
				deleteResponse="deleteContactTask"
				linkField="contact_id"
				entityId={props.id}
				refetch={refetch}
			></TasksList>
			<TasksList
				tasksLoading={tasksLoading}
				title="Completed"
				status={TASK_STATUSES.completed}
				tasks={tasks}
				setTasks={setTasks}
				mutation={UPDATE_TASK}
				mutationResponse="updateContactTask"
				deleteResponse="deleteContactTask"
				linkField="contact_id"
				entityId={props.id}
				refetch={refetch}
			></TasksList>
		</>
	)
}

export default withApollo(ContactTasks)
