import React from 'react'
import { Paper, FormControl } from '@mui/material'
import Label from '../../input/Label'
import { FindModeInput } from '../../navigation/Tabs/TabbedPage'


function DealContactSearch(props) {

	return <>
		<Paper className="qv-margin" id="location-search">
			<h1 className="card-title">
				<span>Contacts</span>
			</h1>

			<div className="column-body">
				<FormControl style={{
					flexGrow: 1,
					marginRight: '1em'
				}}>
					<Label disableAnimation shrink htmlFor="contact_first_name">
						First Name
					</Label>
					<FindModeInput field="contact_first_name" />
				</FormControl>
				<FormControl style={{
					flexGrow: 1,
					marginRight: '1em'
				}}>
					<Label disableAnimation shrink htmlFor="contact_last_name">
						Last Name
					</Label>
					<FindModeInput field="contact_last_name" />
				</FormControl>
				<FormControl style={{ flexGrow: 1 }}>
					<Label disableAnimation shrink htmlFor="contact_name">
						Contact Name
					</Label>
					<FindModeInput field="contact_name" />
				</FormControl>
			</div>
		</Paper>
	</>
}

export default DealContactSearch
