import React, { useEffect } from "react"
import makeStyles from '@mui/styles/makeStyles'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'

export default function TransitionsModal(props) {

	const useStyles = makeStyles(theme => ({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			overflow: props.noOverflow ? 'hidden' : null
		},
		paper: {
			backgroundColor: !props.noBackground ? theme.palette.background.paper : 'transparent',
			boxShadow: !props.noBackground ? theme.shadows[5] : 'none',
			padding: !props.noPadding ? theme.spacing(2, 4, 3) : null,
			borderRadius: "8px",
			maxHeight: '90%',
			maxWidth: '100%',
			margin: '3em',
			overflow: props.scroll ? 'scroll' : 'inherit',
			display: 'flex',
			flexDirection: 'column'
		},
	}))

	const classes = useStyles()
	const [open, setOpen] = React.useState(props.open)
    
	useEffect(() => {
		setOpen(props.open)
	}, [props.open])

	const handleClose = () => {
		props.close()
	}

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={handleClose}
			disableEscapeKeyDown={props.disableEscapeKeyDown || false}
		>
			<Fade in={open} timeout={300}>
				<div style={{outline: "0"}} className={classes.paper}>
					{props.children}
				</div>
			</Fade>
		</Modal>
	)
}
