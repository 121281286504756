import React, { useContext, useEffect, useCallback } from "react"
import { DispatchContext } from '../store'
import {
	Paper,
	FormControl,
	Menu,
	MenuItem,
	Select
} from "@mui/material"
import Dropdown from '../input/Dropdown/Dropdown'
import Label from '../input/Label'
import TextBox from '../input/Text/TextBoxDark'
import { useMutation } from '@apollo/client'
import { UPDATE_USER } from "./Queries.js"
import { Skeleton } from '@mui/material'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { CancelButton, SaveCardButton } from '../input/Buttons'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { LookupContext } from '../store'
import { useNavigate } from 'react-router'
import sortBy from 'lodash/sortBy'
import { format } from "date-fns"
import { FindModeInput } from "../navigation/Tabs/TabbedPage"

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false
}

export default function UserDetails(props) {
	const lookup = useContext(LookupContext)
	const navigate = useNavigate()

	const prefixes = lookup.data?.getPrefixTypes
	const sortedPrefixes = sortBy(prefixes, [function(o) { return o.value }])

	const departments = lookup.data?.getDepartmentTypes

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const [state, setState] = React.useState(initialState)
	const [userInput, setUserInput] = React.useState({})
	const [attempt, setAttempt] = React.useState(false)

	const [updateUser, {loading, error}] = useMutation(UPDATE_USER, {
		onError: (error) => {
			// On failure, reset userInput state, don't touch user state and show error
			resetUserInput(props.user)
			// openSnackbar(severity.ERROR, "Could not update details card.")
			handleClose()
		},
		onCompleted: (response) => {
			if (response.updateUser?.success === true) {
				// Success
				openSnackbar(severity.SUCCESS, response.updateUser.message)
				// On success change user state
				props.setUser({
					...props.user,
					prefix: userInput.prefix,
					prefix_id: userInput.prefix_id,
					first_name: userInput.first_name,
					last_name: userInput.last_name,
					department: userInput.department,
					department_id: userInput.department_id,
					title: userInput.title,
					user_handle: userInput.user_handle
				})

				setAttempt(false)
			} else {
				// On failure, reset userInput state, don't touch user state and show error
				resetUserInput(props.user)
				openSnackbar(severity.ERROR, response.updateUser.message)
			}
		}
	})

	useEffect( () => {
		if(error?.graphQLErrors){
			const gqlErrors = error.graphQLErrors
			gqlErrors.forEach( (e) => {
				if(e.message === "autherror-admin"){
					openSnackbar(severity.ERROR, "Error: Editing user details requires admin login")
					navigate("/admin/login")
				}
			} )
		}
	}, [navigate, error, openSnackbar])

	// Loading, Error
	useEffect(() => {
		if (loading) openSnackbar(severity.INFO, "Saving details card...")
	}, [loading, openSnackbar])


	const resetUserInput = useCallback((user) => {
		if (!user) return
		setUserInput({
			id: user.id,
			prefix_id: user.prefix_id,
			prefix: user.prefix_id ? prefixes?.find(item => item.id === user.prefix_id)?.value : null,
			first_name: user.first_name,
			last_name: user.last_name,
			department_id: user.department_id,
			department: user.department_id ? departments?.find(item => item.id === user.department_id)?.value : null,
			title: user.title,
			user_handle: user.user_handle
		})
	}, [departments, prefixes])

	React.useEffect(() => {
		resetUserInput(props.user)
	}, [props.user, resetUserInput])

	const handleClick = event => {
		if (props.findMode) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
			editable: false
		})
	}

	const handleClose = option => {
		if (option === "edit") {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	const skeletonStyle = {
		height: "4.5em",
		marginTop: "0.8em",
		marginBottom: "-0.6em",
	}

	return (
		<Paper
			className="qv-margin"
			onContextMenu={e => (!state.mouseX && !props.readOnly) ? handleClick(e) : null}
			id="user-details"
			data-testid="card-details"
		>
			<h1 className="card-title">
				<span>Details</span>
				{state.editable && (
					<>
						<div className="spacer"></div>
						<CancelButton
							variant="contained"
							size="small"
							onClick={() => {
								resetUserInput(props.user)
								handleClose()
								setAttempt(false)
							}}
						>
							Cancel
						</CancelButton>
						<SaveCardButton
							variant="contained"
							color="secondary"
							size="small"
							disabled={loading}
							onClick={() => {

								setAttempt(true)

								if (attempt && ( !userInput?.first_name || !userInput?.last_name)) {
									openSnackbar(severity.WARNING, "Please complete required fields.")

								} else if (!(!userInput?.first_name || !userInput?.last_name)) {

									delete userInput.prefix
									delete userInput.department

									updateUser({ variables: { UserInput: userInput } })
									handleClose()
								}
							}}
						>
							Save
						</SaveCardButton>
					</>
				)}
			</h1>
			<div className="user-details-body">
				<div className="user-details-column">

					<FormControl className="user-prefix">
						<Label id="prefix-label" disableAnimation shrink>
							Prefix
						</Label>

						{!props.findMode ? <> 

							{(props.loading || props.error) ? <Skeleton
								animation={props.error ? false : "wave"}
								variant="text"
								width="auto"
								style={skeletonStyle}
							/> :
								<Select
									IconComponent={ExpandMoreRoundedIcon}
									name="prefix"
									labelId="prefix-label"
									input={<Dropdown/>}
									value={(userInput?.prefix_id) ? parseInt(userInput?.prefix_id) : ''}
									className={(!state.editable) ? "readonly padded-select" : null}
									readOnly={!state.editable}
									onChange={(e) => {
										setUserInput({
											...userInput,
											prefix_id: e.target.value,
											prefix: sortedPrefixes[e.target?.value-1]?.value
										})
									}}
								>
									<MenuItem key={0} value={null}>—</MenuItem>
									{sortedPrefixes?.map(prefix => (
										<MenuItem key={prefix.id} value={prefix.id}>{prefix.value}</MenuItem>
									))}
								</Select>
							}

						</> : <FindModeInput field="prefix" largeInput/>}

					</FormControl>

					<FormControl>
						<Label disableAnimation shrink htmlFor="user-first-name" error={state.editable && attempt && !userInput?.first_name}>
							First Name{!props.findMode && <span>*</span>}
						</Label>

						{!props.findMode ? <> 
							{(props.loading || props.error) ? <Skeleton
								animation={props.error ? false : "wave"}
								variant="text"
								width="auto"
								style={skeletonStyle}
							/> :
								<TextBox
									required
									error={state.editable && attempt && !userInput?.first_name}
									className="user-input"
									id="user-first-name"
									value={userInput.first_name || ''}
									readOnly={!state.editable}
									placeholder={'-'}
									onChange={(e) => {
										setUserInput({
											...userInput,
											first_name: e.target.value
										})
									}}
								/>
							}
						</> : <FindModeInput field="first_name" largeInput/>}
					</FormControl>

					<FormControl>
						<Label disableAnimation shrink htmlFor="user-last-name" error={state.editable && attempt && !userInput?.last_name}>
							Last Name{!props.findMode && <span>*</span>}
						</Label>
						{!props.findMode ? <> 
							{(props.loading || props.error) ? <Skeleton
								animation={props.error ? false : "wave"}
								variant="text"
								width="auto"
								style={skeletonStyle}
							/> :
								<TextBox
									required
									error={state.editable && attempt && !userInput?.last_name}
									className="user-input"
									id="user-last-name"
									value={userInput.last_name || ''}
									readOnly={!state.editable}
									placeholder={'-'}
									onChange={(e) => {
										setUserInput({
											...userInput,
											last_name: e.target.value
										})
									}}
								/>
							}
						</> : <FindModeInput field="last_name" largeInput/>}
					</FormControl>
				</div>

				<div className="user-details-column">
					<FormControl >
						<Label disableAnimation shrink htmlFor="user-title">
							Title{!props.findMode && <span>*</span>}
						</Label>
						{!props.findMode ? <> 
							{(props.loading || props.error) ? <Skeleton
								animation={props.error ? false : "wave"}
								variant="text"
								width="auto"
								style={skeletonStyle}
							/> :
								<TextBox
									className="user-input"
									id="user-title"
									value={userInput.title || ''}
									readOnly={!state.editable}
									placeholder={'-'}
									onChange={(e) => {
										setUserInput({
											...userInput,
											title: e.target.value
										})
									}}
								/>
							}
						</> : <FindModeInput field="title" largeInput/>}
					</FormControl>

					<FormControl className="user-department">
						<Label id="field-label" disableAnimation shrink>
							Department
						</Label>

						{!props.findMode ? <> 
							{(props.loading || props.error) ? <Skeleton
								animation={props.error ? false : "wave"}
								variant="text"
								width="auto"
								style={skeletonStyle}
							/> : ( state.editable ?
								<Select
									className="medium-selection"
									IconComponent={ExpandMoreRoundedIcon}
									name="field"
									
									labelId="department-label"
									input={<Dropdown />}
									value={userInput.department_id}
									onChange={(e) => {
										setUserInput({
											...userInput,
											department_id: e.target.value,
										})
									}}
								>
									{departments.map((department) => (
										<MenuItem key={department.id} value={department.id}>
											{department.value}
										</MenuItem>
									))}
								</Select>:
								<TextBox
									className="user-input"
									id="user-department"
									value={departments?.find(item => item.id === userInput.department_id)?.value || ''}
									readOnly={!state.editable}
									placeholder={'-'}
								/>)
							}
						</> : <FindModeInput field="department" largeInput/>}

					</FormControl>


					<FormControl >
						<Label disableAnimation shrink htmlFor="user-handle">
							User Handle{!props.findMode && <span>*</span>}
						</Label>
						{!props.findMode ? <> 
							{(props.loading || props.error) ? <Skeleton
								animation={props.error ? false : "wave"}
								variant="text"
								width="auto"
								style={skeletonStyle}
							/> :
								<TextBox
									className="user-input"
									id="user-handle"
									value={userInput.user_handle || ''}
									readOnly={!state.editable}
									placeholder={'-'}
									onChange={(e) => {
										setUserInput({
											...userInput,
											user_handle: e.target.value
										})
									}}
								/>
							}
						</> : <FindModeInput field="user_handle" largeInput/>}
					</FormControl>


					<FormControl className="user-department">
						<Label id="field-label" disableAnimation shrink>
							Last Login
						</Label>

						{!props.findMode ? <> 
							{(props.loading || props.error) ? <Skeleton
								animation={props.error ? false : "wave"}
								variant="text"
								width="auto"
								style={skeletonStyle}
							/> :
								<TextBox
									className="user-input"
									id="user-last-login"
									value={(props.user.last_login && format(
										new Date(props.user.last_login),
										"MMMM do, yyyy 'at' h':'mma"
									)) || ''}
									readOnly={true}
									placeholder={'-'}
								/>
							}
						</> : <FindModeInput field="last_login" type='date-picker' largeInput/>}
					</FormControl>
				</div>
			</div>

			<Menu
				keepMounted
				open={state.mouseY !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					state.mouseY !== null && state.mouseX !== null
						? { top: state.mouseY, left: state.mouseX }
						: undefined
				}
			>
				<MenuItem onClick={() => handleClose("edit")}>Edit</MenuItem>

			</Menu>
		</Paper>
	)
}
