/* eslint-disable eqeqeq */
import React, { useEffect, useContext, useCallback } from 'react'

import {
	FormControl,
	Select,
	MenuItem,
	IconButton,
	ToggleButton,
	Tooltip,
	TextField,
	Divider,
} from '@mui/material'
import Label from '../input/Label'
import Dropdown from '../input/Dropdown/Dropdown'
import TextBox from '../input/Text/TextBox'
import { SaveButton, SearchButton, ResetButton, AdvancedToggleButton, ChooseImageButton } from '../input/Buttons'
import ClearIcon from '@mui/icons-material/HighlightOff'
import AltRouteIcon from '@mui/icons-material/AltRoute'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { Autocomplete } from '@mui/material'
import {
	autoCompleteStyles,
	searchStyles,
} from '../styles/makesStyles'
import { elementReady, noteTypeComparator, randomHSL, toTitleCase } from '../common/helpers'
import NumberFormat from 'react-number-format'

import { selectionOptions, getInchRoot, getInchFractional} from '../Art/ArtPiece/DimensionSelection'
import { AuthStateContext, DispatchContext, LookupContext } from '../store'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import GlobalSearchHelpModal from './GlobalSearchHelpModal'
import { ControlPoint } from '@mui/icons-material'
import { useSetRecoilState } from 'recoil'
import { findModeFieldsAtom } from '../navigation/Tabs/TabbedPage'
import startCase from 'lodash/startCase'
import TextBoxForAutocomplete from '../input/Text/TextBoxForAutocomplete'
import { MobileDatePicker } from '@mui/x-date-pickers-pro'
import ConfirmationInput from '../navigation/ConfirmationDialog/ConfirmationInput'
import { SavedSearch } from '../common/SavedSearch'
import { saveMap, saveSearch, searchMap } from './unifiedSearchHelpers'
import { useMutation } from '@apollo/client'
import { CREATE_SEARCH } from './Queries'
import { severity } from '../Snackbar/CustomizedSnackbar'
import { IMPORT_SEARCH_OBJECT } from '../constants/values'
import SingleUser from '../input/SingleUser'
import { GET_USERS } from '../AdminConsole/Queries'


export const initialFilterState = () => ({
	id: new Date().getTime(),
	field: '',
	type: '',
	value: '',
	isOptional: false
})

export const FindModeButton = (props) => {
	const setFindModeFields = useSetRecoilState(findModeFieldsAtom)

	return <AdvancedToggleButton
		data-testid="find-mode-button"
		size="small"
		onClick={() => {
			setFindModeFields({})
			props.onFindMode()
		}}
		style={{ marginRight: '1em' }}
	>
		Find Mode
	</AdvancedToggleButton>
}

export const getFilterLabel = (fieldName, filterFields) => {
	if (!fieldName) return ''

	if (fieldName == 'label')	// They found "Label" in the tag search confusing
		return 'Tag'			// So we're changing it to _appear as_ "Tag"

	if (fieldName && !filterFields?.length)
		return startCase(fieldName)

	return filterFields.find(el => el.name === fieldName)?.label || ''
}

const returnMap = {
	undefined: '',
	Boolean: 'eq',
	Integer: 'eq',
	String: 'contains',
	StringArray: 'contains',
	IntArray: 'contains',
	DateRange: 'eq',
	Note: 'contains',
	Metric: 'lte',
	NumRange: 'lte',
	PermissionYesNo: 'eq',
	PermissionFourValue: 'eq',
	Search: 'eq',
	ContactDealEntry: 'contains',
	ContactDealEntryDate: 'eq',
	SingleUser: 'contains',
	Continent: 'eq',
	ContactSource: 'contains'
}

const operators = [
	{ // 0
		value: 'contains',
		key: 'contains',
		label: 'Contains',
		showOptionalToggle: true,
		order: 1,
		group: 'Contains'
	},
	{ // 1
		value: 'ncontains',
		key: 'ncontains',
		label: 'Does Not Contain',
		showOptionalToggle: false,
		order: 3,
		group: 'Contains'
	},
	{ // 2
		value: 'eq',
		key: 'eq',
		label: 'Equals',
		showOptionalToggle: true,
		order: 5,
		group: 'Equals'
	},
	{ // 3
		value: 'neq',
		key: 'neq',
		label: 'Does Not Equal',
		showOptionalToggle: false,
		order: 6,
		group: 'Equals'
	},
	{ // 4
		value: '$isNotNull',
		key: '$isNotNull',
		label: 'Not Blank',
		showOptionalToggle: true,
		order: 14,
		group: 'Other'
	},
	{ // 5
		value: '$isNull',
		key: '$isNull',
		label: 'Is Blank',
		showOptionalToggle: false,
		order: 13,
		group: 'Other'
	},
	{ // 6
		value: 'lte',
		key: 'lte',
		label: 'Less Than or Equal To',
		showOptionalToggle: true,
		group: "Comparator"
	},
	{ // 7
		value: 'gte',
		key: 'gte',
		label: 'Greater Than or Equal To',
		showOptionalToggle: true,
		group: "Comparator"
	},
	{ // 8
		value: 'lt',
		key: 'lt',
		label: 'Less Than',
		showOptionalToggle: true,
		group: "Comparator"
	},
	{ // 9
		value: 'gt',
		key: 'gt',
		label: 'Greater Than',
		showOptionalToggle: true,
		group: "Comparator"
	},
	{ // 10
		value: 'startsWith',
		key: 'startsWith',
		label: 'Starts With',
		showOptionalToggle: true,
		order: 7,
		group: "Affixes"
	},
	{ // 11
		value: 'endsWith',
		key: 'endsWith',
		label: 'Ends With',
		showOptionalToggle: true,
		order: 9,
		group: "Affixes"
	},
	{ // 12
		value: 'notStartsWith',
		key: 'notStartsWith',
		label: 'Doesn’t Start With',
		showOptionalToggle: false,
		order: 8,
		group: "Affixes"
	},
	{ // 13
		value: 'notEndsWith',
		key: 'notEndsWith',
		label: 'Doesn’t End With',
		showOptionalToggle: false,
		order: 10,
		group: "Affixes"
	},
	{ // 14
		value: 'containsAny',
		key: 'containsAny',
		label: 'Contains Any of These Words',
		showOptionalToggle: true,
		order: 2,
		group: 'Contains'
	},
	{ // 15
		value: 'notContainsAny',
		key: 'notContainsAny',
		label: 'Doesn’t Contains Any of These Words',
		showOptionalToggle: false,
		order: 4,
		group: 'Contains'
	},
	{ // 16
		value: 'like',
		key: 'like',
		label: 'Like',
		showOptionalToggle: true,
		order: 11,
		group: 'Other'
	},
	{ // 17
		value: 'notLike',
		key: 'notLike',
		label: 'Not Like',
		showOptionalToggle: false,
		order: 12,
		group: 'Other'
	},
	{ // 18
		value: 'startsWith',
		key: 'startsWith',
		label: 'Starts With',
		group: "Affixes"
	},
	{ // 19
		value: 'endsWith',
		key: 'endsWith',
		label: 'Ends With',
		group: "Affixes"
	},
]

const opsByTypes = {
	undefined: [],
	Boolean: [2],
	Integer: [2, 3, 4, 5],
	String: [0, 1, 2, 3, 4, 5, 10, 11, 12, 13, 14, 15, 16, 17],
	StringArray: [0, 1, 2, 3, 4, 5],
	IntArray: [0, 1, 14, 15],
	Metric: [2, 6, 7],
	NumRange: [2, 6, 7],
	DateRange: [2, 6, 7],
	Note: [0 , 1],
	Search: [2],
	PermissionYesNo: [2],
	PermissionFourValue: [2, 6, 7, 8, 9],
	ContactDealEntry: [0, 1],
	ContactDealEntryDate: [2, 3, 6, 7, 8, 9],
	SingleUser: [0, 1],
	Continent: [2, 3],
	ContactSource: [0, 1]
}

const defaultNestedMap = {
	Source: 'current_sources'
}

const nestedFilterOps = {
	source_id: [2, 3],
	source_name:  [0, 1],
	source_is_company: [2],
	source_type: [0, 1],
	source_country: [2, 3],
	source_continent: [2, 3],
	source_gallery_contacts: [0, 1]
}

// Filter Operators
export const getOperatorValue = function (filterType, nestedFilterType = null) {
	return nestedFilterType ? operators.find((e, i) => i == nestedFilterOps[nestedFilterType]?.[0])?.value || returnMap[filterType] : returnMap[filterType]
}

export default function GlobalSearchFilters(props) {

	const [globalSearchHelp, setGlobalSearchHelp] = React.useState(false)

	const classes = searchStyles()
	const autocompleteClasses = autoCompleteStyles()

	const typeStyle = {
		fontWeight: 500,
	}
	const dispatch = useContext(DispatchContext)
	const openQuickView = useCallback((severity, text) => {
		dispatch({ type: 'openQuickView' })
	}, [dispatch])

	// Snackbar
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])


	const byId = (a, b) => a.id - b.id

	const lookup = useContext(LookupContext)

	const statusCategories = lookup?.data?.getDealEntryStatusCategories
	const statusTypes = lookup?.data?.getDealEntryStatuses
	const continents = lookup?.data?.getContinents

	const getDealStatusItems = (categoryId) => {
		return !!statusTypes ? statusTypes.filter(el =>
			el.deal_entry_status_category.id == categoryId
		).map(type => ({
			id: type.id,
			value: type.value,
			type: "deal_entry_status_id",
			dataType: 'Integer',
			categoryId
		})) : []
	}

	const dealOptions = [
		{id: 0, type: "deal_entry_status_category", dataType: 'String', value: ""},
		...(statusCategories?.sort(byId)?.map(sc => ([
			{
				id: sc.id,
				value: sc.value,
				type: 'deal_entry_status_category',
				dataType: 'String'
			},
			...getDealStatusItems(sc.id)?.sort(byId)
		]))?.flat() || [])
	]

	const autoCompleteStyle = autoCompleteStyles()

	const [activeFilter, setActiveFilter] = React.useState(null)

	const noteTypes = lookup.data?.getNoteTypes || []

	const userAuthentication = useContext(AuthStateContext)
	const [savedSearchDialog, setSavedSearchDialog] = React.useState({
		open: false,
		search: null
	})
	const [currentSearch, setCurrentSearch] = React.useState(props.prevSearch ?? new SavedSearch({
		object_id: props.object_id,
		search_terms: [],
		is_global: true,
		user_id: userAuthentication.user?.id
	}))

	useEffect(() => {
		if (props.currentSearch) setCurrentSearch(props.currentSearch)
	}, [props.currentSearch])


	const filters = currentSearch.search_terms
	const setFilters = (filters) => {
		setCurrentSearch({
			...currentSearch,
			search_terms: filters
		})
	}

	// Mutations
	const [createSearch, { loading }] = useMutation(CREATE_SEARCH)

	useEffect(() => {
		if (props.currentFilters) {
			setFilters(props.currentFilters)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.currentFilters])

	const buttonStyle = {
		padding: '8px',
		marginRight: '0.25em',
		height: 'fit-content',
	}


	/**
	 * Handle all search changes for global search
	 */
	const handleSearchChange = (item, key, event, nestedQuery = null) => {

		let { value } = event.target

		// I think these only apply if the "key" is "field"
		// String, Integer, Boolean, DateRange, NumRange, StringArray, Note, Metric, Search
		const oldFilterType = (props.filterFields || [])
			.find(f => f.name === item.field)?.type

		const newFilterType = (props.filterFields || [])
			.find(f => f.name === String(value).split('.')[0])?.type

		let newItem = {}

		// Value or Operator Change
		if (key === 'value' || key === 'type') {
			newItem = {
				...item,
				[key]: value
			}
		}

		// Field Change
		else if (key === 'field') {

			const nestedQueryType = nestedQuery || (props.filterFields || [])
				.find(f => f.name === value.split('.')[0])?.nestedQueryType

			if (nestedQueryType && !nestedQuery) {
				value = `${value}.${defaultNestedMap[nestedQueryType]}`
			}	

			// valid new operators for new filter type
			const validNewOperators = operators.filter((_, i) => opsByTypes[newFilterType].includes(i)).sort((a,b) => a.order - b.order)

			// default operator for new filter type
			const defaultNewOperator = getOperatorValue(newFilterType, event.target?.value)

			// If it is a nested change, we use the first index
			const newValue = !!nestedQuery ? `${item.field.split('.')[0]}.${value}` : value

			// Filter type did not change
			if (oldFilterType == newFilterType) {

				newItem = {
					...item,
					[key]: newValue,
					type: item.type ?? defaultNewOperator, // Check for no operator,
					nestedQueryType
				}
			}

			// Filter type changed
			else if (oldFilterType != newFilterType) {

				newItem = {
					...item,
					value: newFilterType === 'Boolean' ? 'true' : '',
					[key]: newValue,
					// Change to new default operator, unless current operator is valid
					type: validNewOperators.includes(item.type) ? item.type : defaultNewOperator,
					nestedQueryType
				}
			}
		}


		setFilters(filters.map(f => (f === item ? newItem : f)))
		return newFilterType
	}

	const handleNoteChange = (filter, event) => {
		const {value, name} = event.target

		let	newValue = (filter.value?.type || filter.value?.text) ? filter.value : {}
		switch(name){
		case "noteType":
			newValue.type = value
			break
		case "noteText":
			newValue.text = value
			break
		default:
			break
		}

		const newFilter = {
			...filter,
			value: newValue
		}

		setFilters(filters.map(f => (f === filter ? newFilter : f)))

	}

	// Filter Operators
	const getOperators = function (filterType, isNestedFilter = false, field) {

		return operators.filter((_, i) => isNestedFilter ? nestedFilterOps[field.split('.')[0]].includes(i) 
			: opsByTypes[filterType].includes(i)).sort((a,b) => a.order - b.order)
	}

	const focusValue = (filterType) => {

		if (filterType === 'Boolean') {
			elementReady('.active-search-value > div')
				.then(input => input.focus())
		} else {
			elementReady('.active-search-value > .MuiInputBase-input')
				.then(input => input.focus())
		}
	}

	const getNestedFilterDropdown = function (filter, isActive = false) {

		let filterCategory = (props.filterFields || [])
			.find(f => f.name === filter.field.split('.')[0])?.nestedQueryType

		const [, value] = filter.field.split(".")

		if (filterCategory === "Source") {
			return (
				<FormControl
					style={{flexGrow: 1}}
					className={classes.searchSelection}
				>
					<Label
						focused={false}
						style={typeStyle}
						disableAnimation
						shrink
					>
						Category
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						name="source_type"
						input={<Dropdown />}
						value={value}
						onChange={event => {
							handleSearchChange(filter, 'field', event, "Source")
						}}
					>
						<MenuItem value="current_sources">Current</MenuItem>
						<MenuItem value="purchaser">Purchaser</MenuItem>
						<MenuItem value="consignor">Consignor</MenuItem>
						<MenuItem value="owners">Owner</MenuItem>
					</Select>
				</FormControl>
			)
		}

		if (filter.field.includes("deal_entry")) {
			return (
				<FormControl className={classes.selection} style={{
					flexGrow: 1,
					flexBasis: 0,
					marginRight: '1em'
				}}>

					<Label
						id="deal-type-status-label"
						style={typeStyle}
						disableAnimation
						shrink
					>
						Deal Entry Status & Category
					</Label>

					<Autocomplete
						id="deal-type-status-autocomplete"
						options={dealOptions}
						classes={autoCompleteStyle}
						value={findDealEntryValue(filter, dealOptions)}
						popupIcon={<ExpandMoreRoundedIcon />}
						size="small"
						style={{marginTop: "1.5em"}}
						isOptionEqualToValue={(option, value) => {
							return option.id == value.id
								&& option.type == value.type
						}}
						getOptionLabel={(option) => option.value}
						filterOptions={(options, state) => {

							/*
							This custom filterOptions function shows the headers
							on the deal entry status and category autocomplete
							even when they do not match the users search.
							*/

							let { inputValue } = state
							inputValue = inputValue.toLowerCase().trim()

							const nestedOptions = options
								?.filter((option) => {
									return option.id && !option.categoryId
								})
								.map((option) => {
									return {
										...option,
										deal_entry_status: options?.filter(
											(innerOption) => {
												return (
													innerOption.categoryId ==
													option.id
												)
											}
										),
									}
								})

							return nestedOptions.flatMap((el) => {
								const subArray =
									el.deal_entry_status.filter(
										(option) => {
											return option.value
												.toLowerCase()
												.includes(inputValue)
										}
									)

								const hasSubItems = subArray.length

								return [
									hasSubItems ||
									el.value
										.toLowerCase()
										.includes(inputValue)
										? el
										: null,
									...subArray,
								].filter(Boolean)
							})
						}}
						onChange={(_, value) => {

							if (value == null) {
								setFilters(filters.map(f => (f === filter ? {
									...filter,
									value: {
										...filter.value,
										deal_entry_status_category_id: null,
										deal_entry_status_id: null
									}
								} : f)))
							}

							else if (value.type === 'deal_entry_status_category') {
								setFilters(filters.map(f => (f === filter ? {
									...filter,
									value: {
										...filter.value,
										deal_entry_status_category_id: value.id,
										deal_entry_status_id: null
									}
								} : f)))
							}

							else if (value.type === 'deal_entry_status_id') {
								setFilters(filters.map(f => (f === filter ? {
									...filter,
									value: {
										...filter.value,
										deal_entry_status_category_id: value.categoryId,
										deal_entry_status_id: value.id
									}
								} : f)))
							}
						}}
						renderInput={params => (
							<TextField
								{...params}
								variant="outlined"
								fullWidth
								placeholder='Any'
								InputProps={{
									...params.InputProps,
									style: {'padding': '9px'},
								}}
							/>
						)}
						renderOption={(optionProps, option) => {

							const style = {
								marginLeft: option.type != "deal_entry_status_category" ? '2em' : null
							}

							return (
								<li {...optionProps} key={`${option.id}-${option.value}`} style={style}>{option.value}</li>
							)
						}}
					/>

				</FormControl>
			)
		}

		return null
	}

	// Filter Inputs
	const getInputByType = function (filter, isActive = false) {
		let filterType = (props.filterFields || [])
			.find(f => f.name === filter.field.split(".")[0])?.type

		if (filterType === 'Boolean') {
			return (
				<FormControl
					style={{flexGrow: 2}}
					className={classes.searchSelection}
				>
					<Select
						className={
							isActive
								? 'active-search-value padded-select'
								: 'padded-select'
						}
						IconComponent={ExpandMoreRoundedIcon}
						name={`value-${filter.id}-label`}

						input={<Dropdown />}
						value={filter.value}
						onChange={event =>
							handleSearchChange(filter, 'value', event)
						}
					>
						<MenuItem value="true">True</MenuItem>
						<MenuItem value="false">False</MenuItem>
					</Select>
				</FormControl>
			)
		} else if (filterType === 'Integer' || (filterType === 'NumRange')) {
			return (
				<FormControl
					style={{flexGrow: 2}}
					className={classes.searchSelection}
				>
					<NumberFormat
						className={isActive ? 'active-search-value' : null}
						name="value"
						value={filter.value}
						autoFocus={isActive}
						allowLeadingZeros={true}
						disabled={['$isNull', '$isNotNull'].includes(filter.type)}
						onChange={event =>
							handleSearchChange(filter, 'value', event)
						}
						customInput={TextBox}
						decimalSeparator={filterType === 'NumRange' ? '.' : ''}
					/>
				</FormControl>
			)
		} else if (filterType === "Metric") {
			return (
				getDimensionInput(filter, isActive)
			)
		} else if (filterType === "Note"){

			return getNoteInput(filter, isActive)

		} else if (filterType === "ContactDealEntry" || filterType === "ContactDealEntryDate"){

			return getContactDealEntryInput(filter, isActive, filterType)

		} else if (filterType === "DateRange") {

			const { value, field } = filter

			return (
				<FormControl
					style={{flexGrow: 2}}
					className={classes.searchSelection}
				>
					<MobileDatePicker
						inputFormat="MMM do, yyyy"
						disableMaskedInput={true}
						componentsProps={{
							actionBar: {
								actions: ['today', 'clear', 'accept']
							}
						}}
						inputVariant="outlined"
						renderInput={({ inputRef, inputProps, InputProps }) => {

							const newProps = { ...inputProps}

							newProps.readOnly = false

							return (
								<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
							)}}
						value={value}
						onChange={(date) => {
							handleSearchChange(filter, 'value', {
								target: {
									name: field,
									value: date ? date?.toDateString() : null
								}
							})
						}}
					/>
				</FormControl>
			)

		} else if (filterType === "PermissionYesNo" || filterType === "PermissionFourValue") {

			return (
				<FormControl
					style={{flexGrow: 2}}
					className={classes.searchSelection}
				>
					<Select
						className={
							isActive
								? 'active-search-value padded-select'
								: 'padded-select'
						}
						IconComponent={ExpandMoreRoundedIcon}
						name={`value-${filter.id}-label`}

						input={<Dropdown />}
						value={filter.value}
						onChange={event => {
							const value = event.target.value

							handleSearchChange(filter, 'value', {
								target: {
									name: `field-${filter.id}-${filterType}`,
									value,
								},
							})
						}}
					>
						{filterType === "PermissionYesNo" && (<MenuItem value="1">Yes</MenuItem>)}
						{filterType === "PermissionYesNo" && (<MenuItem value="2">No</MenuItem>)}
						{filterType === "PermissionFourValue" && (<MenuItem value="3">Cannot See</MenuItem>)}
						{filterType === "PermissionFourValue" && (<MenuItem value="4">View Only</MenuItem>)}
						{filterType === "PermissionFourValue" && (<MenuItem value="5">Create and Edit Own</MenuItem>)}
						{filterType === "PermissionFourValue" && (<MenuItem value="6">Create and Edit All</MenuItem>)}
					</Select>
				</FormControl>
			)
		} else if (filterType === "SingleUser") {
			return <FormControl
				style={{flexGrow: 2}}
				className={classes.searchSelection}
			>
				<SingleUser
					query={GET_USERS}
					value={filter.value || null}
					onChange={(event, value) => {
						handleSearchChange(filter, 'value', {
							target: {
								name: `field-${filter.id}-${filterType}`,
								value: value.id,
							},
						})
					}}
				/>
			</FormControl>
		} else if (filterType === "Continent") {
			return (
				<FormControl
					style={{flexGrow: 2}}
					className={classes.searchSelection}
				>
					<Select
						className={
							isActive
								? 'active-search-value padded-select'
								: 'padded-select'
						}
						IconComponent={ExpandMoreRoundedIcon}
						name={`value-${filter.id}-label`}

						input={<Dropdown />}
						value={filter.value}
						autoFocus={isActive}
						onChange={event => {
							const value = event.target.value

							handleSearchChange(filter, 'value', {
								target: {
									name: `field-${filter.id}-${filterType}`,
									value,
								},
							})
						}}
					>

						{continents.map((continent) => (
							<MenuItem key={continent.code} value={continent.code}>{toTitleCase(continent.name)}</MenuItem>
						))}
						
					</Select>
				</FormControl>
			)
		} else {
			let { value } = filter

			if (filter.type == "$isNull" || filter.type == "$isNotNull")
				value = ''

			return (
				<FormControl
					style={{flexGrow: 2}}
					className={classes.searchSelection}
				>
					<TextBox
						className={isActive ? 'active-search-value' : null}
						name="value"
						value={value}
						disabled={['$isNull', '$isNotNull'].includes(filter.type)}
						onChange={event =>
							handleSearchChange(filter, 'value', event)
						}
					/>
				</FormControl>
			)
		}
	}
	const getDimensionInput = function (filter, isActive = false) {

		const { value } = filter

		return (
			<div style={{
				flexGrow: 2,
				display: 'flex',
				alignItems: 'flex-end',
			}}>
				<FormControl className={classes.searchSelection} style={{flexGrow: 1}}>
					<Label
						focused={false}
						style={typeStyle}
						disableAnimation
						shrink
					>
						Metric (cm)
					</Label>

					<NumberFormat
						style={{width: '100%'}}
						step="any"
						name="metric"
						onChange={(event) => {

							const value = event.target.value

							handleSearchChange(filter, 'value', {
								target: {
									name: `field-${filter.id}-metric`,
									value,
								},
							})
						}}
						autoFocus={isActive}
						value={value}
						customInput={TextBox}
						allowLeadingZeros={false}
					/>
				</FormControl>

				<FormControl className={classes.searchSelection} style={{flexGrow: 1}}>
					<Label
						focused={false}
						style={typeStyle}
						disableAnimation
						shrink
					>
						Imperial (in)
					</Label>

					<NumberFormat
						style={{width: '100%'}}
						step="any"
						name="inch"
						value={getInchRoot(value)}
						onChange={(event) => {

							const inches = event.target.value
							const fraction = getInchFractional(value)
							const decimal = fraction.decimal

							let newMetric

							if (decimal) newMetric = (Number(inches) + decimal) * 2.54
							else newMetric = (Number(inches)) * 2.54

							handleSearchChange(filter, 'value', {
								target: {
									name: `field-${filter.id}-imperial`,
									value: newMetric,
								},
							})
						}}
						customInput={TextBox}
						decimalSeparator=''
						allowLeadingZeros={false}
					/>
				</FormControl>

				<Select
					style={{
						width: '5em'
					}}
					input={<Dropdown />}
					name="fraction"
					value={getInchFractional(value)}
					IconComponent={
						ExpandMoreRoundedIcon
					}
					onChange={(event) => {

						const fraction = event.target.value
						const decimal = fraction.decimal
						const inch = getInchRoot(value)

						let newMetric = 0

						if (decimal) newMetric = (Number(inch) + decimal) * 2.54
						else newMetric = (Number(inch)) * 2.54

						handleSearchChange(filter, 'value', {
							target: {
								name: `field-${filter.id}-fraction`,
								value: newMetric,
							},
						})
					}}
				>
					{selectionOptions.map(
						(fraction, index) => (
							<MenuItem
								key={`fraction-${index}`}
								value={fraction}
							>
								{fraction.fraction}
							</MenuItem>
						)
					)}
				</Select>
			</div>
		)
	}

	const getNoteInput = function(filter, isActive = false){
		return(
			<div style={{
				flexGrow: 2,
				display: 'flex',
				alignItems: 'flex-end',
				marginRight: '1em'
			}}>
				<FormControl
					className={classes.searchSelection}
					style={{
						flexGrow: 1,
						flexBasis: 0
					}}
				>
					<Label
						focused={false}
						style={typeStyle}
						disableAnimation
						shrink
					>
					Note Text
					</Label>
					<TextBox
						style={{width:"100%"}}
						name="noteText"
						value={filter.value?.text || ''}
						onChange={(e) => {
							handleNoteChange(filter, e)
						}}
					/>
				</FormControl>

				<FormControl
					className={classes.searchSelection}
					style={{
						flexGrow: 1,
						flexBasis: 0
					}}
				>
					<Label
						focused={false}
						style={typeStyle}
						disableAnimation
						shrink
					>
						Note Type
					</Label>

					<Select
						style={{
							width: '100%'
						}}
						displayEmpty
						input={<Dropdown />}
						name="noteType"

						value={filter.value?.type || ''}
						IconComponent={	ExpandMoreRoundedIcon }
						onChange={(e) => {
							handleNoteChange(filter, e)
						}}
					>
						<MenuItem key="nt-any" value=''> Any Type </MenuItem>
						{noteTypes.sort( noteTypeComparator )?.map(
							(noteType, index) => (
								<MenuItem
									key={`nt-${index}`}
									value={noteType.value}
								>
									{noteType.value}
								</MenuItem>
							)
						)}
					</Select>
				</FormControl>
			</div>
		)
	}

	const findDealEntryValue = (filter, dealOptions) => {

		const { value } = filter

		if (value.deal_entry_status_id) 
			return dealOptions.find(el => el.id == value.deal_entry_status_id && el.type == "deal_entry_status_id")
		
		return dealOptions.find(el => el.id == value.deal_entry_status_category_id && el.type == "deal_entry_status_category")

	}

	const getContactDealEntryInput = function(filter, isActive = false, filterType){
		return(
			<div style={{
				flexGrow: 2,
				display: 'flex',
				alignItems: 'flex-end',
				marginRight: '1em'
			}}>

				{filterType === 'ContactDealEntry' ? 
					<FormControl
						className={classes.searchSelection}
						style={{
							flexGrow: 1,
							flexBasis: 0
						}}
					>
						<Label
							focused={false}
							style={typeStyle}
							disableAnimation
							shrink
						>
							Text
						</Label>

						
						<TextBox
							name="value"
							className={isActive ? 'active-search-value' : null}
							autoFocus={isActive}
							value={filter.value?.text || ''}
							onChange={event => {
								setFilters(filters.map(f => (f === filter ? {
									...filter,
									value: {
										...filter.value,
										text: event.target.value
									}
								} : f)))
							}}
						/>
					</FormControl> 
					: 
					<FormControl
						style={{
							flexGrow: 1,
							flexBasis: 0
						}}
						className={classes.searchSelection}
					>
						<Label
							focused={false}
							style={typeStyle}
							disableAnimation
							shrink
						>
							Date
						</Label>
						<MobileDatePicker
							inputFormat="MMM do, yyyy"
							disableMaskedInput={true}
							componentsProps={{
								actionBar: {
									actions: ['today', 'clear', 'accept']
								}
							}}
							inputVariant="outlined"
							renderInput={({ inputRef, inputProps, InputProps }) => {

								const newProps = { ...inputProps}

								newProps.readOnly = false

								return (
									<TextBox autoFocus={isActive} ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
								)}}
							value={filter.value?.date ?? null}
							onChange={(date) => {
								setFilters(filters.map(f => (f === filter ? {
									...filter,
									value: {
										...filter.value,
										date: date ? date?.toDateString() : null
									}
								} : f)))
							}}
						/>
					</FormControl>}

			</div>
		)
	}

	const CreateMenu = props.createMenu

	return <>
		<h1 className="card-title">Advanced Search

			{!props.disableCreation ? (
				<ChooseImageButton
					data-testid="new-entity-button"
					variant="contained"
					size="small"
					style={{backgroundColor: !props.loading ? '#4465D1' : null}}
					onClick={(e) => {
						if (props.createMenu) props.handleActionClick(e)

						else {
							props.setCreateEntity(true)
							openQuickView()
						}

					}}
					endIcon={<ControlPoint />}
				>
				Create
				</ChooseImageButton>
			)
				: null}

			{CreateMenu ? <CreateMenu /> : null}

			<IconButton
				size="small"
				onClick={() => setGlobalSearchHelp(true)}
				style={{
					marginLeft: 'auto',
					marginTop: '0.1em'
				}}
			>
				<InfoOutlinedIcon />
			</IconButton>
		</h1>
		<form
			onSubmit={e => {
				// fix filter for source contacts
				let submittedFilters = filters.map(filter => ({
					...filter,
					value: filter.value?.__typename === 'User' ?
						filter.value.id :
						filter.value
				}))
				e.preventDefault()
				props.onSearch({cursor: 0}, submittedFilters)
			}}
		>
			{filters.map((filter, i) => {
				const filterType = (props.filterFields || [])
					.find(f => f.name === filter.field.split(".")[0])?.type
	
				const shouldShowOptionalToggle = 
					operators.find(el => el.value == filter.type)?.showOptionalToggle
					
				const isNestedFilter = filter.field?.includes('.')

				const [outterField, ] = filter.field.split(".")

				return (
					<div
						className="row"
						style={{
							alignItems: 'flex-end',
							minHeight: '65px',
						}}
						key={filter.id || i}
					>
						<IconButton
							aria-label="delete"
							onClick={e => {
								e.preventDefault()

								if (filters.length === 1)
									setFilters([initialFilterState()])

								else setFilters(
									filters.filter(f => f !== filter)
								)
							}}
							style={buttonStyle}
							size="large">
							<ClearIcon />
						</IconButton>

						<FormControl className={classes.searchSelection}>
							<Label
								id={`field-${filter.id}-label`}
								style={typeStyle}
								disableAnimation
								shrink
							>
                                Field
							</Label>
							<Autocomplete
								popupIcon={<ExpandMoreRoundedIcon />}
								classes={autocompleteClasses}
								name={`field-${filter.id}-select`}
								style={{ marginTop: '1.5em' }}
								onMouseOver={() => setActiveFilter(i)}
								onChange={(_, value) => {
									const newFilterType = handleSearchChange(filter, 'field', {
										target: {
											name: `field-${filter.id}-select`,
											value,
										},
									})
									focusValue(
										newFilterType || props.filterFields
											.find(f => f.name === value)?.type
									)
								}}
								value={isNestedFilter ? outterField : filter.field || null}
								getOptionLabel={(option) => getFilterLabel(option, props.filterFields)}
								options={(props.filterFields ?? [{name: ''}])
									.map(f => f.name)
								}
								renderInput={(params) => (
									<TextBoxForAutocomplete
										{...params}
										variant="outlined"
										InputProps={{
											...params.InputProps,
											style: {
												paddingTop: 3.5,
												paddingBottom: 3.5
											}
										}}
									/>
								)}

							/>
						</FormControl>

						{getNestedFilterDropdown(filter, activeFilter === i)}

						<FormControl className={classes.searchSelection}>
							<Label
								id={`operator-${filter.id}-label`}
								focused={false}
								style={typeStyle}
								disableAnimation
								shrink
							>
                                Operator
							</Label>
							<Autocomplete
								popupIcon={<ExpandMoreRoundedIcon />}
								classes={autocompleteClasses}
								name={`operator-${filter.id}-label`}
								labelId={`operator-${filter.id}-label`}
								ListboxProps={{ style: { maxHeight: "80rem" }, position: "bottom-start" }}
								style={{ marginTop: '1.5em' }}
								disableClearable
								onMouseOver={() => setActiveFilter(i)}
								onChange={(_, value) => {

									handleSearchChange(
										filter,
										'type',
										{
											target: {
												name: `field-${filter.id}-select`,
												value: value.key,
											}
										}
									)
									focusValue(filterType)
									
								}}
								value={filter.type || ''}
								disabled={!filter.field}
								getOptionLabel={(option) => {

									if (typeof option === 'string') {
										return operators.find(el => el.key == option)?.label || ''
									}

									else return option.label || ''
								}}
								options={getOperators(filterType, isNestedFilter, filter.field)}
								renderOption={(props, option) => {

									const tempIndex = getOperators(filterType).findIndex((el) => el == option) 

									const hasAfterDivider = tempIndex + 1 !== getOperators(filterType).length && 
										getOperators(filterType)[tempIndex + 1]?.group != option?.group
										
									return (
										<>
											<li {...props} key={option.label}>{option.label}</li>
											{hasAfterDivider ? <Divider /> : null}
										</>
									)
								}}
								isOptionEqualToValue={(option, value) => option.value == value}
								renderInput={(params) => (
									<TextBoxForAutocomplete
										{...params}
										variant="outlined"
										InputProps={{
											...params.InputProps,
											style: {
												paddingTop: 3.5,
												paddingBottom: 3.5
											}
										}}
									/>
								)}

							/>
						</FormControl>

						<div style={{
							display: 'flex',
							flexGrow: 2,
							alignItems: 'end'
						}}>

							{getInputByType(filter, activeFilter === i)}

							{shouldShowOptionalToggle == true  ? 
								<Tooltip title="Mark this filter as optional.">
									<ToggleButton value="optional" selected={filter.isOptional} onChange={() => {
										setFilters(filters.map(f => (f === filter ? {
											...filter,
											isOptional: !filter.isOptional
										} : f)))
									}} sx={{height: 45}}>
										<AltRouteIcon />
									</ToggleButton>
								</Tooltip>
								: null}

						</div>

					</div>
				)
			})}

			<div
				className="row"
				style={{ minHeight: '65px', alignItems: 'center' }}
			>
				<IconButton
					aria-label="add"
					onClick={(e) => {
						e.preventDefault()
						setFilters(filters.concat(initialFilterState()))
					}}
					style={buttonStyle}
					disabled={
						(filters[filters.length - 1]?.field == null ||
                            filters[filters.length - 1]?.field == '') &&
                        (filters[filters.length - 1]?.type == null ||
                            filters[filters.length - 1]?.type == '')
					}
					size="large">
					<AddCircleOutlineIcon />
				</IconButton>
			</div>

			<div className="row" style={{marginTop: '1em'}}>
				<div>
					<SaveButton
						data-testid="save-button"
						variant="contained"
						size="small"
						onClick={() => {
							saveSearch(
								currentSearch,
								openSnackbar,
								severity,
								setSavedSearchDialog
							)
						}}
					>
                        Save Search
					</SaveButton>
				</div>
				{ (props.onFindMode && props.object_id != IMPORT_SEARCH_OBJECT) && <FindModeButton onFindMode={props.onFindMode}/> }
				<AdvancedToggleButton
					data-testid="advanced-toggle-button"
					size="small"
					onClick={() => {
						props.setCurrentSearch(currentSearch)
						props.setAdvancedSearch(false)
					}}
					style={{ marginRight: '1em' }}
				>
                    Simple
				</AdvancedToggleButton>
				<ResetButton
					data-testid="reset-button"
					variant="contained"
					size="small"
					onClick={() => {
						setFilters([initialFilterState()])
						props.onReset('advanced')
					}}
					style={{ marginRight: '1em' }}
				>
                    Reset
				</ResetButton>
				<SearchButton
					data-testid="search-button"
					variant="contained"
					size="small"
					type="submit"
					style={{backgroundColor: !props.loading ? '#4465D1' : null}}
					disabled={props.loading}
				>
                    Search
				</SearchButton>
			</div>
		</form>

		<GlobalSearchHelpModal
			open={globalSearchHelp}
			close={() => setGlobalSearchHelp(false)}
			fields={props.filterFields}
			objectId={props.object_id}
			adminConsole={props.adminConsole}
		/>

		<ConfirmationInput
			open={savedSearchDialog.open}
			handleClose={() => setSavedSearchDialog({
				open: false,
				savedSearch: null
			})}
			disabled={loading}
			title={'Save search?'}
			acceptText={'Save'}
			buttonColor="#33BF5C"
			inputLabel="Search Label"
			placeholder={'Saved Search ' + (props.savedSearches?.length + 1)}
			onYes={(text) => {
				if (!loading) {
					createSearch({
						variables: {
							CreateSearchInput: {
								label: text ? text : 'Saved Search ' + (props.savedSearches?.length + 1),
								user_id: userAuthentication.user.id,
								object_id: props.object_id,
								color: randomHSL(),
								search_terms: searchMap(currentSearch.search_terms),
								result_count: null,
								is_global: true,
							},
						},
					}).then((response) => {

						setCurrentSearch({
							...response.data.createSavedSearch.search,
							search_terms: saveMap(response.data.createSavedSearch.search.search_terms)
						})

						props.setSavedSearches(props.savedSearches.concat(
							new SavedSearch(response.data.createSavedSearch.search))
						)
					})
				}
			}}
		/>
	</>
}
