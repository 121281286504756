/* eslint-disable eqeqeq */
import React, { useCallback, useContext } from 'react'
import parse from 'html-react-parser'
import CircleIcon from '@mui/icons-material/Circle'
import { LightTooltip } from '../../styles/makesStyles'
import Toggle from '../../input/Toggle/UserToggleSmall'
import { formatDistance } from 'date-fns'
import { formatDate } from '../../common/helpers'
import { Avatar, Button, ClickAwayListener, IconButton, Paper, Popper, Slide, StyledEngineProvider, ThemeProvider, Typography } from '@mui/material'
import { useMutation } from '@apollo/client'
import { MARK_ALL_NOTIFICATIONS_AS_READ, TOGGLE_NOTIFICATION_AS_READ } from '../Queries'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { makeStyles } from '@mui/styles'
import adminConsoleTheme from '../../styles/muiThemes/adminConsoleTheme'
import lightTheme from '../../styles/muiThemes/mainTheme'

/** styles for this component */
const useStyles = makeStyles((theme) => ({
	small: {
		width: theme.spacing(5),
		height: theme.spacing(5),
		marginBottom: 'auto',
		marginTop: '0.3em',
		marginRight: '0.5em',
	},
}))


const NotificationPane = ({
	open,
	anchorEl,
	handleClickAway,
	showUnread,
	setShowUnread,
	userNotifications,
	authState,
	setUserNotification,
	setUnreadNotificationCount,
	onAdminConsole
}) => {

	const classes = useStyles()

	const [toggleRead] = useMutation(TOGGLE_NOTIFICATION_AS_READ)
	const [toggleAllRead] = useMutation(MARK_ALL_NOTIFICATIONS_AS_READ)

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text, loading: true } })
		},
		[dispatch]
	)
	

	return <Popper
		open={open}
		anchorEl={anchorEl}
		placement="bottom"
		transition
		style={{
			zIndex: 5
		}}
	>
		{({ TransitionProps }) => (
			<Slide {...TransitionProps} timeout={350}>	
				<div>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={onAdminConsole ? adminConsoleTheme : lightTheme}>
							<Paper>
								<ClickAwayListener onClickAway={handleClickAway}>
									<div
										style={{
											maxWidth: '35em',
											minWidth: '35em',
										}}
									>
										<div style={{
											padding: '1.5em 1.5em 0.5em 1.5em',
										}}>
											<div style={{
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
											}}>
												<Typography variant="h6">Notifications</Typography>

												<LightTooltip arrow placement="left" title="Only show unread">
													<div>
														<Toggle
															checked={showUnread}
															onChange={() => setShowUnread(!showUnread)}
														/>
													</div>

												</LightTooltip>
											</div>

											<div style={{
												display: 'flex',
												justifyContent: 'right',
											}}>
												<Button onClick={(e) => {
													toggleAllRead({
														variables: {
															userId: authState?.user?.id
														}
													}).then(async (response) => {
							
														if (response.data.markAllNotificationAsRead?.success) {

															const newUserNotifications = userNotifications.map(e => ({
																...e,
																read: true
															}))
												
															setUserNotification(newUserNotifications)

															const unreadNotificationLength = newUserNotifications?.filter((e) => !e.read)?.length || 0

															setUnreadNotificationCount(
																unreadNotificationLength > 9
																	? '9+'
																	: unreadNotificationLength.toString()
															)

														}
														else {
															openSnackbar(
																severity.ERROR,
																'There was an error while toggling all notifications as read.'
															)

														}


													}).catch((error) => {
													// Handle error creating DB record
														console.error(error)
														openSnackbar(
															severity.ERROR,
															'There was an error while toggling all notifications as read.'
														)
													})
												}}>
													<u>Mark all as read</u>
												</Button>
											</div>
										</div>

										<hr style={{
											borderColor: 'rgb(88 88 88 / 11%)',
											marginBottom: 0
										}}/>

										{userNotifications.filter((notification) => {
											return showUnread ? !notification.read : true
										})?.length == 0 ? 
											<div style={{
												textAlign: 'center',
												padding: '0 1.5em 1.5em 1.5em',
											}}>
												<Typography variant="span">Nothing here</Typography>
											</div> : null}
				
										<div style={{
											maxHeight: '70vh',
											overflowY: 'auto',
											overflowX: 'hidden'
										}}>
											{userNotifications.filter((notification) => {
												return showUnread ? !notification.read : true
											}).map((notification, index) => {

												const body = parse(notification.body)

												let adjustedBody = {...body }

												adjustedBody = Object.values(adjustedBody).map((el) => {

													if (el.type == 'a') {

														return {
															...el,
															props: {
																...el.props,
																href: '#',
																onClick: () => {

																	if (!notification.read) toggleRead({
																		variables: {
																			id: notification.id,
																			read: true
																		}
																	}).then(() => window.location.replace(el.props.href))

																	else window.location.replace(el.props.href)
																}
															}
														}

													}

													return el

												})
					
												const createdUser = notification.user 

												const timeAgo = formatDistance(
													new Date(notification.created_at),
													new Date(),
													{ addSuffix: true }
												)

												return (
													<div key={`notification-${notification.id}`}>
														<div
															style={{
																padding: '1em 1.5em',
															}}
														>
															<div
																style={{
																	display: 'flex',
																	alignItems: 'center',
																	padding: '0.5em 0',
																}}
															>
																<span
																	style={{
																		fontSize: 'small',
																		display: 'flex',
																		alignItems: 'center',
																		paddingLeft: 5,
																	}}
																></span>

																<Avatar className={classes.small} src={createdUser.imgUrl}></Avatar>

																<div>
																	{createdUser.id != 1 ? (
																		<>
																			<b style={{fontWeight: 600}}>
																				{[createdUser.first_name, createdUser.last_name]
																					.filter((e) => e)
																					.join(' ')}
																			</b>{' '}
																		</>
																	) : null}
																	{adjustedBody} <br />
																	<LightTooltip
																		arrow
																		placement="right"
																		title={formatDate(
																			notification.created_at,
																			"iiii, MMMM do, yyyy 'at' h':'mma"
																		)}
																	>
																		<span style={{ color: 'grey', fontSize: 'smaller' }}>
																			{timeAgo}
																		</span>
																	</LightTooltip>
																</div>

																<LightTooltip
																	arrow
																	placement="left"
																	title={!notification.read ? 'Mark as read' : 'Mark as unread'}
																>
																	<IconButton
																		size='large'
																		aria-label={
																			!notification.read ? 'Mark as read' : 'Mark as unread'
																		}
																		onClick={(e) => {
																			toggleRead({
																				variables: {
																					id: notification.id,
																					read: !notification.read,
																				},
																			})
																				.then(async (response) => {
																					if (
																						response.data.toggleNotificationAsRead
																							?.success
																					) {
																						const newUserNotifications =
																				userNotifications.map((e) =>
																					e.id == notification.id
																						? {
																							...e,
																							read: !notification.read,
																						  }
																						: e
																				)

																						setUserNotification(newUserNotifications)

																						const unreadNotificationLength =
																				newUserNotifications?.filter(
																					(e) => !e.read
																				)?.length || 0

																						setUnreadNotificationCount(
																							unreadNotificationLength > 9
																								? '9+'
																								: unreadNotificationLength.toString()
																						)
																					} else {
																						openSnackbar(
																							severity.ERROR,
																							'There was an error while toggling this notification.'
																						)
																					}
																				})
																				.catch((error) => {
																				// Handle error creating DB record
																					console.error(error)
																					openSnackbar(
																						severity.ERROR,
																						'There was an error while toggling this notification.'
																					)
																				})
																		}}
																		color={notification.read ? 'default' : 'info'}
																		style={{
																			height: 'fit-content',
																			marginLeft: 'auto',
																		}}
																	>
																		<CircleIcon
																			style={{
																				fontSize: 'small',
																			}}
																		/>
																	</IconButton>
																</LightTooltip>
															</div>
														</div>

														{index !==
											userNotifications.filter((notification) => {
												return showUnread ? !notification.read : true
											}).length -
												1 ? (
																<hr
																	style={{
																		margin: 0,
																		borderColor: 'rgb(88 88 88 / 11%)',
																		maxHeight: '50em',
																	}}
																/>
															) : null}
													</div>
												)
											})}
										</div>
									</div>
								</ClickAwayListener>
							</Paper>
						</ThemeProvider>
					</StyledEngineProvider>
				</div>
			</Slide>
		)}
	</Popper>
}

export default NotificationPane
