import React, {useContext, useCallback} from 'react'
import ObjectTags from '../../../Tags/ObjectTags'
import { stripMeta } from '../../../common/helpers'
import ArtistDetail from './ArtistDetail'
import ArtistAdvanced from './ArtistAdvanced'
import AboutCard from '../../../input/AboutCard'
import { useMutation } from '@apollo/client'
import { DispatchContext } from '../../../store'
import { UPDATE_ARTIST } from '../../Queries'
import { severity } from '../../../Snackbar/CustomizedSnackbar'
import { permissions } from '../../../constants/permissions'
	
function ArtistDetailPage(props) {

	let { loading, error } = props
	let artist = props.state?.getArtist || {}
	let setArtist = (artist) => props.setState({ getArtist: artist })
	const [selectedTag, setSelectedTag] = React.useState(null)

	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const [updateArtist, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_ARTIST, {
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, 'Could not update.')
		},
		onCompleted: (response) => {
			if (response.updateArtist.success === true) {
				openSnackbar(severity[response.updateArtist.severity] || severity.SUCCESS, response.updateArtist.message)
				setArtist(response.updateArtist.artist)
			} else {
				// On failure, reset artistInput state, don't touch artist
				// state and show error
				openSnackbar(severity[response.updateArtist.severity] || severity.ERROR, response.updateArtist.message)
			}
		},
	})

	const update = (fields) => {
		let variables = stripMeta(fields)

		if (!variables.id) variables.id = artist.id

		updateArtist({
			variables: {
				ArtistInput: variables,
			},
		})
	}

	return (
		<section className="main-page">
			<div className="tabbed-content">
				<div className="page-row">
					<ArtistDetail
						loading={loading}
						disabled={updateLoading}
						error={error || updateError}
						artist={artist}
						setArtist={setArtist}
						onSave={update}
						findMode={props.findMode}
					></ArtistDetail>
					<ArtistAdvanced
						loading={loading}
						disabled={updateLoading}
						error={error}
						artist={artist}
						setArtist={setArtist}
						onSave={update}
						findMode={props.findMode}
					></ArtistAdvanced>
				</div>

				<AboutCard
					loading={loading}
					disabled={updateLoading}
					error={error || updateError}
					entity={artist}
					setEntity={setArtist}
					onSave={update}
					findMode={props.findMode}
				></AboutCard>

				{!props.findMode && <ObjectTags
					loading={loading}
					error={error || updateError}
					entity={artist}	
					setEntity={setArtist}
					tagState={props.tagState}
					setTagState={props.setTagState}
					newTagModal={props.newTagModal}
					setNewTagModal={props.setNewTagModal}
					setQVOverride={ props.setQVOverride }
					entityType="Artist"
					setSelectedTag={setSelectedTag}
					selectedTag={selectedTag}
					tagPermission={permissions.ARTISTS_TAGS}
					findMode={props.findMode}
				></ObjectTags>}
			</div>
		</section>
	)
}

export default ArtistDetailPage
