import { gql } from '@apollo/client'

export const GET_USER_NOTIFICATIONS_SETTINGS = gql`
	query getUserNotificationSettings($user_id: ID) {
		getUserNotificationSettings(user_id: $user_id) {
			id
			user_id
			notifications_on
			notifications
			created_by
			created_at
			modified_by
			is_deleted
			modified_at
		}
	}
`

export const GET_USER_NOTIFICATIONS = gql`
	query getUserNotifications($id: ID) {
		getUserNotifications(id: $id) {
			id
			notification_key
			user_id
			read
			body
			body2
			body3
			created_by
			created_at
			modified_by
			modified_at
			is_deleted
		}
	}
`

export const UPDATE_USER_NOTIFICATIONS = gql`
	mutation updateUserNotifications($UserNotificationsInput: UserNotificationsInput) {
		updateUserNotifications(input: $UserNotificationsInput) {
			code
			success
			message
			severity
			user_notifications {
				id
				user_id
				notifications_on
				notifications
				created_by
				created_at
				modified_by
				is_deleted
				modified_at
			}
		}
	}
`
