/* eslint-disable eqeqeq */
import React, { useContext, useState } from 'react'
import { LookupContext } from '../../../store'
import DealQuickView from '../../../Deals/DealQuickView'
import UserAccessQuickView from '../../../QuickView/UserAccessQuickView'
import { permissions, permissionValues } from '../../../constants/permissions'
import DealEntryList from '../../../DealEntry/DealEntryList'
import { deal_entry_status_categories } from '../../../DealEntry/constants'

export default function ArtOffersAndReserves(props) {

	const lookup = useContext(LookupContext)
	const dealEntryStatusCategories = lookup.data?.getDealEntryStatusCategories

	const [selectedRow, setSelectedRow] = useState(null)
	const [requeryDealEntry, setRequeryDealEntry] = useState({})

	React.useEffect(() => {

		if ((selectedRow?.deal?.is_private || selectedRow?.deal?.is_gallery_private) && !selectedRow?.deal?.created_at) {
			props.setQVOverride(() => <UserAccessQuickView entity={selectedRow.deal} />)
		} else if (selectedRow) {
			props.setQVOverride(() => <DealQuickView id={selectedRow.deal.id} />)
		} else {
			props.setQVOverride(null)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRow])


	const onUpdateArtStatus = (artStatusId) => {
		props.setState({
			...props.state,
			getArtPiece: {
				...props.state.getArtPiece,
				status_id: artStatusId
			}
		})
	}

	return !props.findMode && <>
		<DealEntryList
			page='art'
			category={dealEntryStatusCategories
				?.find(e => e.id == deal_entry_status_categories.offers)}
			initialSort='start_at'
			privateEntity={props.state?.getArtPiece?.is_private || false}
			selectedRow={selectedRow}
			setSelectedRow={setSelectedRow}
			setQVOverride={props.setQVOverride}
			art={props.state?.getArtPiece}
			creation={true}
			otherPermissionPair={[permissions.DEALS, permissionValues.CREATE_AND_EDIT_OWN]}
			creationButtonName="Create New Deal"
			requeryDealEntry={requeryDealEntry}
			setRequeryDealEntry={setRequeryDealEntry}
			onUpdateArtStatus={onUpdateArtStatus}
		/>
		<DealEntryList
			page='art'
			category={dealEntryStatusCategories
				?.find(e => e.id == deal_entry_status_categories.reserves)}
			privateEntity={props.state?.getArtPiece?.is_private || false}
			selectedRow={selectedRow}
			setSelectedRow={setSelectedRow}
			setQVOverride={props.setQVOverride}
			art={props.state?.getArtPiece}
			requeryDealEntry={requeryDealEntry}
			setRequeryDealEntry={setRequeryDealEntry}
			onUpdateArtStatus={onUpdateArtStatus}
		/>
	</>
}
