import routes from '../../navigation/routes'
import TabbedPage from '../../navigation/Tabs/TabbedPage'
import { useParams } from 'react-router-dom'
import { GET_CONTACT_DETAILS } from '../../Contacts/Queries'


export default function ShowcaseContactTabbedPage(props: any) {
	const params = useParams()

	// @ts-ignore
	return TabbedPage({
		...props,
		routes: routes.showcaseContact.pages,
		query: GET_CONTACT_DETAILS,
		options: routes.showcaseContact.options,
		params,
	})
}
