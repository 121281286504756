/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useCallback } from 'react'
import { LookupContext, DispatchContext } from '../../../store'
import { GET_CONTACT_LISTINGS, DELETE_LISTING_CONTACT, UPDATE_LISTING_CONTACT } from './Queries'
import { useMutation } from '@apollo/client'
import {
	Paper,
	TableContainer,
	Table,
	TableRow,
	TableCell,
	TableBody
} from '@mui/material'
import EnhancedTableHead from '../../../table/EnhancedTableHead'
import Thumbnail from '../../../Thumbnail/Thumbnail'
import { Skeleton } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { severity } from '../../../Snackbar/CustomizedSnackbar'
import { Menu, MenuItem, Select } from "@mui/material"
import Dropdown from "../../../input/Dropdown/Dropdown"
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ListingQuickView from '../../../Listings/Listing/ListingQuickView'
import { ChooseImageButton } from '../../../input/Buttons'
import TextBox from '../../../input/Text/TextBox'
import Label from '../../../input/Label'
import { SearchButton, CancelButton } from '../../../input/Buttons'
import { FormControl } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import {formatDate} from '../../../common/helpers'
import ListingRemoveModal from '../../../common/components/ListingRemoveModal'
import {getContactName} from '../../../common/helpers'
import { withApollo } from '@apollo/client/react/hoc'
import useNavigation from '../../../navigation/useNavigation'
import ListingAddModal from '../../../common/components/ListingAddModal'
import { GET_CONTACT_LISTINGS_NAV } from '../../../navigation/Queries'

const headCells = [
	{
		id: "title",
		numeric: false,
		disablePadding: true,
		label: "Title"
	},
	{
		id: "listing:listing_type.value",
		numeric: false,
		disablePadding: true,
		label: "Type"
	},
	{ id: "location", numeric: false, disablePadding: false, label: "Gallery" },
	{ id: "start_at", numeric: false, disablePadding: false, label: "Start Date" },
	{ id: "status.value", numeric: false, disablePadding: false, label: "Status" },
	{ id: "actions", numeric: false, disablePadding: false, label: "Actions" }
]

const pageSize = 10

const Listings = (props) => {

	const { push: pushNav } = useNavigation()
	const navigate = useNavigate()
	const location = useLocation()
	const params = useParams()
	let prevSearch = location.state
	if (prevSearch?.card !== 'listings') {
		prevSearch = null
	}


	// Actions
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [currentID, setCurrentID] = React.useState(null)
	const handleClick = event => setAnchorEl(event.currentTarget)
	const handleClose = () => setAnchorEl(null)
	const [selectedRow, setSelectedRow] = React.useState(null)
	const [removeListingModal, setRemoveListingModal] = React.useState(false)
	const [listingsLoading, setListingsLoading] = React.useState(false)

	// Pagination	
	const [steps, setSteps] = React.useState(prevSearch?.steps ?? 1)
	const [activeStep, setActiveStep] = React.useState(prevSearch?.activeStep ?? 0)
	const [cursor, setCursor] = React.useState(prevSearch?.cursor ?? 0)
	const [totalItems, setTotalItems] = React.useState(prevSearch?.totalItems ?? 0)

	// Filters
	const [searchString, setSearchString] = React.useState(prevSearch?.searchString ?? "")
	const [listingTypeId, setListingTypeId] = React.useState(prevSearch?.listingTypeId ?? "")

	// Sorting
	const [order, setOrder] = React.useState(prevSearch?.order || 'desc')
	const [orderBy, setOrderBy] = React.useState(prevSearch?.orderBy || 'start_at')

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const lookup = useContext(LookupContext)
	
	const [addListingModal, setAddListingModal] = React.useState(false)

	// Get Status and Listing options
	const [statusTypes, setStatusTypes] = React.useState([])
	useEffect(() => {
		if (lookup.data?.getListingContactStatus) 
			setStatusTypes(lookup.data.getListingContactStatus)
	}, [lookup.data, props.state])

	const [listingTypes, setListingTypes] = React.useState([])
	useEffect(() => {
		if (lookup.data?.getListingTypes) 
			setListingTypes(lookup.data.getListingTypes)
	}, [lookup.data, props.state])

	// Data
	const [rows, setRows] = React.useState(prevSearch?.rows ?? [])

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	// Delete
	const [deleteRelation, { loading }] = useMutation(DELETE_LISTING_CONTACT)

	// Update
	const [updateRelation] = useMutation(UPDATE_LISTING_CONTACT)


	function saveAndGo(path, row) {
		const state = updateHistory(row)
		pushNav({ 
			url: location.pathname, 
			state,
			query: GET_CONTACT_LISTINGS_NAV,
			getId: r => r?.listing?.id
		}, state.variables.cursor)
		navigate(path, { state })
	}
	// Save Search
	function updateHistory (index) {
		const savedSearch = {
			selectedRow, rows,
			searchString, listingTypeId,
			orderBy, order,
			steps, activeStep, totalItems,
			searchPage: location.pathname,
			card: 'listings'
		}
		const offset = index ?? rows.indexOf(selectedRow)
		const cursor = activeStep * pageSize + offset
		const variables = {
			id: params.id,
			cursor,
			searchString,
			listingTypeId,
			limit: 3,
			field: orderBy,
			direction: order.toUpperCase(),
		}
		savedSearch.variables = variables
		navigate(location.pathname, { state: savedSearch })
		return savedSearch
	}

	const handleResponse = (data) => {

		if (data.getContactListings === null) {
			openSnackbar(severity.ERROR, "Error - Unauthorized")
			setListingsLoading(false)
			return
		}

		setListingsLoading(false)
		setRows(data.getContactListings.items)

		if (data.getContactListings.items.length < 1) setSteps(1)
		else setSteps((Math.ceil(data.getContactListings.totalItems / pageSize)))

		setTotalItems(data.getContactListings.totalItems || 0)
	}

	const handleError = (error) => {

		setListingsLoading(false)
		console.error(error)
		openSnackbar(severity.ERROR, "There was an error retrieving this contact's listings.")

	}

	const searchListings = (cursorOverride = null, searchStringOverride = null, listingTypeIdOverride = null) => {
		setListingsLoading(true)
		return props.client
			.query({
				query: GET_CONTACT_LISTINGS,
				variables: {
					id: params.id,
					cursor: cursorOverride ?? cursor,
					searchString: searchStringOverride ?? searchString,
					listingTypeId: listingTypeIdOverride ?? listingTypeId,
					field: orderBy,
					direction: order.toUpperCase(),
					limit: 10
				},
			})
	}

	useEffect(() => {
		let active = true

		if (!props.findMode && params.id > 0) {
			searchListings()
				.then((data) => {
					if (active) {
						handleResponse(data.data)
					}
				})
				.catch((error) => {
					if (active) {
						handleError(error)
					}
				})
		}
		return () => active = false
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id, orderBy, order])

	// Deselect Row on change of Main Entity, also reset Rows
	useEffect(() => {
		setRows([])
		setSelectedRow(null)
	}, [params.id])

	const handleDeleteListing =  () =>{
		deleteRelation({
			variables: { 
				id: currentID
			}
		}).then(response => {

			if (response && response.errors) {

				openSnackbar(severity.ERROR, "Could not delete relation.")
			} else if (response && response.data.deleteListingContact.success === false) {
			
				openSnackbar(severity.ERROR, "There was an error deleting this relation.")
			} else {
				
				openSnackbar(severity.SUCCESS, "Successfully deleted relation.")
				searchListings()
					.then((data) => handleResponse(data.data))
					.catch((error) => handleError(error))
			}
			setRemoveListingModal(false)

		}).catch(error => {
			console.log(error)
			openSnackbar(severity.ERROR, "There was an error deleting this relation.")
		})
	}

	const handleKeyPress = (e) => {
		if(e.key === 'Enter'){
			setSearchString(e.target.value)
			searchListings()
				.then((data) => handleResponse(data.data))
				.catch((error) => handleError(error))
		}
	}

	return (
		<Paper 
			className={props.state?.getContact?.is_private ? "padding-margin search-list dark-theme" : "padding-margin search-list"}
			data-testid="card-listings"
		>

			<h1 className='card-title' style={{justifyContent: 'space-between'}}>
				<div>
					<span>Listings {"("+totalItems+")"} </span>
				</div>

				{params.id > 0 && <ChooseImageButton
					variant="contained"
					color="secondary"
					size="small"
					onClick={() => {
						setAddListingModal(true)
						setSelectedRow(null)
						props.setQVOverride(null)
					}}
				>
					Add Listing
				</ChooseImageButton>}
			</h1>

			<div>
				<FormControl style={{width: '20em', paddingRight: '1em'}}>
					<Label style={{
						fontWeight: 500
					}} disableAnimation shrink>
						Search
					</Label>
					<TextBox 
						name="search" value={searchString} 
						onKeyPress={(e) => handleKeyPress(e)}
						onChange={(e)=> {
							setSearchString(e.target.value)
						}}/>
				</FormControl>
				<FormControl style={{width: '20em', paddingRight: '1em'}}>
					<Label id="type-label" style={{
						fontWeight: 500
					}} disableAnimation shrink>
						Type
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						name="type"
						
						labelId="type-label"
						className="padded-select"
						input={<Dropdown />}
						value={listingTypes.length ? listingTypeId : ''}
						onChange={(event) => {
							setListingTypeId(event.target.value)
						}}
					>
						<MenuItem value={""}>All</MenuItem>
						{listingTypes && listingTypes.map(type => (
							<MenuItem key={type.id} value={type.id}>{type.value}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl style={{paddingRight: '1em'}}>
					<CancelButton variant="contained" size="medium" type="submit" 
						style={{marginTop: '1.9em', color: '#FFFFFF', backgroundColor: '#cc3333'}} 
						onClick={() => {
							setActiveStep(0)
							setSteps(1)
							setListingTypeId('')
							setSearchString('')
							searchListings(0, '', '')
								.then((data) => handleResponse(data.data))
								.catch((error) => handleError(error))
						}}>
						Reset
					</CancelButton>
				</FormControl>
				<FormControl>
					<SearchButton variant="contained" size="medium" type="submit" 
						style={{marginTop: '1.9em', backgroundColor: '#4465D1'}} 
						onClick={() => {
							setActiveStep(0)
							setSteps(1)
							searchListings(0, searchString, listingTypeId)
								.then((data) => handleResponse(data.data))
								.catch((error) => handleError(error))
						}}
						disabled={props.id === 'findmode'}
					>
						Search
					</SearchButton>
				</FormControl>
			</div>	
				
			<TableContainer>
				<Table
					aria-labelledby='tableTitle'
					size='medium'
					aria-label='enhanced table'
				>
					<EnhancedTableHead
						headCells={headCells}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={rows.length}
					/>

					<TableBody>
						
						{listingsLoading ? 
							<TableRow>
								<TableCell>
									<Thumbnail 
										type='listing'
										animation="wave"
									/>
								</TableCell>
								<TableCell><Skeleton animation="wave"/></TableCell>
								<TableCell><Skeleton animation="wave"/></TableCell>
								<TableCell><Skeleton animation="wave"/></TableCell>
								<TableCell><Skeleton animation="wave"/></TableCell>
								<TableCell></TableCell>
							</TableRow>
						 : null}

						{!listingsLoading && rows?.length == 0 ? 
							<TableRow>
								<TableCell></TableCell>
								<TableCell> 
									<div style={{
										height: 62,
										display: 'flex',
										alignItems: 'center'
									}}>
										No Listings Found
									</div>
								</TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
							</TableRow>
						 : null}



						{rows?.map((row, index) => {
							if (row && row.hidden === true) return null

							return (
								<TableRow
									key={row.id}
									selected={row === selectedRow}
									style={{cursor: 'pointer'}}
									onClick={event => {
										// Check if row is a placeholder, or private
										if (row.disabled) return

										if (event.metaKey) {
											window.open(`/listings/${row.listing.id}`, '_blank')
											return
										}

										if (row === selectedRow) {
											saveAndGo(
												`/listings/${row.listing.id}`,
												index
											)
										}

										setSelectedRow(row)
										props.setQVOverride(() => <ListingQuickView 
											id={row.listing.id}
											onclose={() => {
												setSelectedRow(null)
												props.setQVOverride(null)
											}}
										/>)
									}}
								>
									<TableCell
										component='th'
										scope='row'
										data-testid='listing-thumbnail'
										style={{ width: '20em' }}
									>
										<Thumbnail
											name={row.listing?.title}
											detail={row.listing?.subtitle || "-"}
											avatar={row.listing?.imgUrl}
											type='listing'
											largeText
										></Thumbnail>

									</TableCell>
									<TableCell>
										{ row?.listing?.listing_type?.value ? row.listing.listing_type.value :
											<span>-</span>
										}	
									</TableCell>
									<TableCell>
										{ row?.listing?.location ? row.listing.location :
											<span>-</span>
										}	
										
									</TableCell>
									<TableCell>
										{ row?.listing?.start_at ? formatDate(row.listing.start_at, "MMMM do, yyyy") :
											<span>-</span>
										}	
									</TableCell>
									<TableCell>
										<Select
											IconComponent={ExpandMoreRoundedIcon}
											id="contact-listings-status"
											
											input={<Dropdown />}
											className="hideborder"
											value={statusTypes.length ? row.status_id  : ''}
											onClick={e => {
												e.preventDefault()
												e.stopPropagation()
											}}
											onChange={(event, value) => {
												
												const variables = {
													id: row.id,
													status_id: event.target.value,
												}			

												// Send to server
												updateRelation({
													variables
												}).then(response => {
								
													if (response && !response.data.updateListingContact.id) {
											
														openSnackbar(severity.ERROR, "There was an error updating this status.")
													} else {
												
														openSnackbar(severity.SUCCESS, "Successfully updated status.")
														setRows(rows.map(row => {
															if (row.id == response.data.updateListingContact.id) 
																return response.data.updateListingContact
															else return row
														}))
													}
				
												}).catch(error => {
				
													console.log(error)
													openSnackbar(severity.ERROR, "There was an error updating this status.")
												})

											}}
										>
											{statusTypes && statusTypes.filter(status => status.listing_type_id == row.listing.type_id).map(type => (
												<MenuItem key={type.id} value={type.id}>{type.value}</MenuItem>
											))}
										</Select>
									</TableCell>
									<TableCell>
										<IconButton
											aria-label="More"
											style={{padding: "6px", marginRight: "-9px"}}
											onClick={(e) => {
												if(row.disabled) return
												e.preventDefault()
												e.stopPropagation()
												setCurrentID(row.id)
												handleClick(e)
											}}
											size="large">
											<MoreHorizIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>

			<Menu
				id="simple-menu"
				anchorEl={ anchorEl }
				keepMounted
				open={ !!anchorEl }
				onClose={ handleClose }
			>
				<MenuItem onClick={() => {
					const id = rows.find(row => row.id == currentID).listing.id
					saveAndGo(`/listings/${id}/details`)
					handleClose()
				}}>Go to Listing</MenuItem>
				<MenuItem onClick={() => {
					handleClose()					
					setRemoveListingModal(true)
				}}>Remove from Listing</MenuItem>
			</Menu>

			<ListingRemoveModal
				disabled={loading}
				removeListingModal={removeListingModal}
				setRemoveListingModal={setRemoveListingModal}
				itemToRemove={getContactName(props.state.getContact)}
				handleDeleteListing={handleDeleteListing}
				isPrivate={props.state.getContact?.is_private}
			/>

			<ListingAddModal
				addListingModal={addListingModal}
				setAddListingModal={setAddListingModal}
				isPrivate={props.state.getContact?.is_private}
				id={props.state.getContact?.id}
				type="contact"
				requery={()=>searchListings()
					.then((data) => handleResponse(data.data))
					.catch((error) => handleError(error))}
			/>

			<div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
				<Pagination style={{
					paddingTop: '2em',
					paddingBottom: '1em',
					margin: 'auto',
					background: 'transparent'
				}} count={steps} page={activeStep+1} onChange={(event, page) => {
					
					setActiveStep(page-1)
					setCursor((page - 1) * pageSize)
					
					searchListings((page - 1) * pageSize)
						.then((data) => handleResponse(data.data))
						.catch((error) => handleError(error))

					// Scroll to top of page
					var elmnt = document.querySelector('.search-list')
					elmnt.scrollIntoView({behavior: "smooth", block: "start"})
				}}/>
			</div>
				
		</Paper>
	)
}

export default withApollo(Listings)
