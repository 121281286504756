import { gql } from '@apollo/client'
import { taskData, userDataCompressed } from '../common/fragments'

export const CREATE_ENTITY_TASK = gql`
	mutation createEntityTask(
		$CreateTaskInput: CreateTaskInput!
		$thumbnailResolution: String = "128x128"
	) {
		createEntityTask(input: $CreateTaskInput) {
			code
			success
			message
			task {
				...taskData
			}
		}
	}
	${taskData}
`

export const SEARCH_TASKS = gql`
	query searchTasks(
		$searchString: String
		$statusId: String
		$typeId: String
		$dueDate: String
		$dateCreated: String
		$assigneeIds: [String]
		$specialFilter: String
		$entityTypes: [String]
		$field: String
		$direction: SortDirection
		$cursor: Int
		$limit: Int
		$thumbnailResolution: String = "128x128"

	) {
		searchTasks(
			input: {
				field: $field
				direction: $direction
				limit: $limit
				cursor: $cursor
				filters: {
					searchString: $searchString
					specialFilter: $specialFilter
					statusId: $statusId
					typeId: $typeId
					dueDate: $dueDate
					dateCreated: $dateCreated
					assigneeIds: $assigneeIds
					entityTypes: $entityTypes
				}
			}
		) {
			cursor
			totalItems
			items {
				id
				artist_type_id
				art_sub_first_name
				art_sub_last_name
				art_sub_dates
				listing_profile_link
				art_profile_link
				artist_profile_link
				contact_profile_link
				imgUrl  (thumbnailResolution: $thumbnailResolution)
				type_id
				description
				assigned_by_user_id
				approval_required
				approved_by_user_id
				approval_at
				due_at
				is_completed
				created_by
				created_at
				modified_by
				modified_at
				is_deleted
				contact_company_name
				listing_subtitle
				task_status_id
				user_ids
				contact_id
				contact_name
				art_id
				art_title
				artist_id
				artist_name
				deal_id
				listing_id
				listing_title
				listing_subtitle
				entity_type
				contact_company_name
				# task_x_assigned_to
				assigned_to_type
				assigned_to_users {
					...userDataCompressed
					department
					is_deleted
				}
				assigned_to_department {
					id
					value
				}

				art {
					id
					title
					imgUrl (thumbnailResolution: $thumbnailResolution)
					formatted_title 
					year
					is_private
					code_name
					created_at
					artist {
						id
						first_name
						last_name
					}
				}

				deal {
					id
					is_private
					created_at
					code_name
				}

				contact {
					id
					is_private
					created_at
					code_name
					first_name
					last_name
					company_name
					is_company
					is_deleted
					is_deceased
					dynamic_generated_codename
				}
			}
		}
	}
	${userDataCompressed}
`

export const DELETE_ENTITY_TASK = gql`
	mutation deleteEntityTask($id: ID) {
		deleteEntityTask(id: $id) {
			code
			success
			message
		}
	}
`

export const UPDATE_ENTITY_TASK = gql`
	mutation updateEntityTask(
		$UpdateTaskInput: UpdateTaskInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateEntityTask(input: $UpdateTaskInput) {
			code
			success
			message
			task {
				...taskData
			}
		}
	}
	${taskData}
`

export const DELETE_ART_TASK = gql`
	mutation deleteArtTask($id: ID) {
		deleteArtTask(id: $id) {
			code
			success
			message
		}
	}
`

export const UPDATE_ART_TASK = gql`
	mutation updateArtTask(
		$UpdateTaskInput: UpdateTaskInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateArtTask(input: $UpdateTaskInput) {
			code
			success
			message
			task {
				...taskData
			}
		}
	}
	${taskData}
`

export const DELETE_ARTIST_TASK = gql`
	mutation deleteArtistTask($id: ID) {
		deleteArtistTask(id: $id) {
			code
			success
			message
		}
	}
`

export const UPDATE_ARTIST_TASK = gql`
	mutation updateArtistTask(
		$UpdateTaskInput: UpdateTaskInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateArtistTask(input: $UpdateTaskInput) {
			code
			success
			message
			task {
				...taskData
			}
		}
	}
	${taskData}
`

export const DELETE_CONTACT_TASK = gql`
	mutation deleteContactTask($id: ID) {
		deleteContactTask(id: $id) {
			code
			success
			message
		}
	}
`

export const UPDATE_CONTACT_TASK = gql`
	mutation updateContactTask(
		$UpdateTaskInput: UpdateTaskInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateContactTask(input: $UpdateTaskInput) {
			code
			success
			message
			task {
				...taskData
			}
		}
	}
	${taskData}
`

export const DELETE_DEAL_TASK = gql`
	mutation deleteDealTask($id: ID) {
		deleteDealTask(id: $id) {
			code
			success
			message
		}
	}
`

export const UPDATE_DEAL_TASK = gql`
	mutation updateDealTask(
		$UpdateTaskInput: UpdateTaskInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateDealTask(input: $UpdateTaskInput) {
			code
			success
			message
			task {
				...taskData
			}
		}
	}
	${taskData}
`

export const DELETE_LISTING_TASK = gql`
	mutation deleteListingTask($id: ID) {
		deleteListingTask(id: $id) {
			code
			success
			message
		}
	}
`

export const UPDATE_LISTING_TASK = gql`
	mutation updateListingTask(
		$UpdateTaskInput: UpdateTaskInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateListingTask(input: $UpdateTaskInput) {
			code
			success
			message
			task {
				...taskData
			}
		}
	}
	${taskData}
`
