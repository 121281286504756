/* eslint-disable eqeqeq */
import { FormControl, MenuItem, Select } from '@mui/material'
import Dropdown from '../../input/Dropdown/Dropdown'
import Label from '../../input/Label'
import React, { useContext } from "react"
import TextBox from '../../input/Text/TextBoxThin'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import {SubmitButton, CancelButton} from '../../input/Buttons'
import { handleWebsitePaste, isPrimary, isValidWebsite } from '../../common/helpers'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { CREATE_WEBSITE } from '../Contact/Queries'
import { useMutation } from '@apollo/client'

export default function NewWebsiteModal(props) {

	const [attempt, setAttempt] = React.useState(false)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}
	
	const currentWebsiteType = props.websiteTypes?.find(e => 
		e.id == props?.newWebsiteModal.website?.type_id
	)

	const [createWebsite] = useMutation(CREATE_WEBSITE, {
		onError: (error) => {
			openSnackbar(severity.ERROR, error.createWebsite.message)
		},
		onCompleted: (data) => {
			if (data?.createWebsite?.success) {
				openSnackbar(severity.SUCCESS, data.createWebsite.message)
				props.setContact({
					...props.contact,
					websiteCount: props.contact.websiteCount + 1
				})
			} else {
				openSnackbar(severity.ERROR, "Could not create website.")
			}
		}
	})

	return (
		<TransitionsModal
			className="new-website-modal"
			open={props.newWebsiteModal?.open}
			close={() =>
				props.setNewWebsiteModal({
					...props.newWebsiteModal,
					open: false
				})
			}
		>
			<form
				className={props.isPrivate ? "private-object modal-small-content" : "modal-small-content"}
				onSubmit={e => {
					e.preventDefault()
					e.stopPropagation()
					setAttempt(true)

					if (attempt && (!isValidWebsite(props.newWebsiteModal?.website?.website, currentWebsiteType) || props.newWebsiteModal?.website?.website?.length === 0)) {
						openSnackbar(severity.WARNING, "Please correct the fields in red.")
					} else if (!(!isValidWebsite(props.newWebsiteModal?.website?.website, currentWebsiteType) || props.newWebsiteModal?.website?.website?.length === 0)) {
						
						let newWebsite = {
							label: props.newWebsiteModal.website.label,
							type_id: parseInt(
								props.newWebsiteModal.website.type_id
							),
							website: props.newWebsiteModal.website.website,
							type:
								props.websiteTypes[
									parseInt(
										props.newWebsiteModal.website.type_id
									) - 1
								].type,
							is_primary: isPrimary(props, "phone"),
							contact_id: props.contactInput.id,
						}

						
						if (!props.hasContactInfoPermission && props.onContactDetailsPage) {

							createWebsite({
								variables: {
									WebsiteInput: newWebsite
								}
							})
			
						} else {
							props.addToContactInput('website', newWebsite)
						}

						props.setNewWebsiteModal({
							...props.newWebsiteModal,
							open: false
						})

						setAttempt(false)
					}
				}}
			>
				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Label
					</Label>
					<TextBox
						autoFocus
						placeholder="-"
						onChange={e => {
							props.setNewWebsiteModal({
								...props.newWebsiteModal,
								website: {
									...props.newWebsiteModal.website,
									label: e.target.value
								}
							})
						}}
						data-testid="website-title"
					/>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Type*
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						
						input={<Dropdown />}
						className="medium-selection padded-select"
						value={props.newWebsiteModal?.website?.type_id}
						multiline
						onChange={e => {
							props.setNewWebsiteModal({
								...props.newWebsiteModal,
								website: {
									...props.newWebsiteModal.website,
									type_id: e.target.value
								}
							})
						}}
						data-testid="website-type"
					>
						{props.websiteTypes && props.websiteTypes.map(x => (
							<MenuItem key={x.id} value={x.id} data-testid="website-type-item"> 
								{x.value.charAt(0).toUpperCase() + x.value.substring(1)}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink error={attempt && !isValidWebsite(props.newWebsiteModal?.website?.website, currentWebsiteType)}>
						New {currentWebsiteType?.is_social ? 'Social' : 'Website'}*
					</Label>
					<TextBox
						name='url'
						error={attempt && !isValidWebsite(props.newWebsiteModal?.website?.website, currentWebsiteType)}
						value={props.newWebsiteModal?.website?.website}
						onPaste={e => handleWebsitePaste(e, currentWebsiteType, props.setNewWebsiteModal, props.newWebsiteModal, openSnackbar)}
						onChange={e => {
							props.setNewWebsiteModal({
								...props.newWebsiteModal,
								website: {
									...props.newWebsiteModal.website,
									website: e.target.value
								}
							})
						}}
						data-testid="website"
						multiline
						inputProps={{ maxLength: 255 }}
					/>
				</FormControl>

				{props.contactInput ? 
					<>
						<CancelButton
							variant="contained"
							style={{ float: 'left', marginTop: '1em' }}
							onClick={e => {
								e.preventDefault()
								props.setNewWebsiteModal({
									...props.newWebsiteModal,
									open: false
								})
								setAttempt(false)
							}}
							data-testid="website-cancel"
						>
						Cancel
						</CancelButton>

						<SubmitButton
							style={{ float: 'right', marginTop: '1em' }}
							type="submit"
							variant="contained"
							data-testid="website-submit"
						>
							Submit
						</SubmitButton>
					</>
					:
					null
				}
			</form>
		</TransitionsModal>
	)
}
