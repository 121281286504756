import React from 'react'
import { Avatar } from '@mui/material'
import { Skeleton } from '@mui/material'

export default function UserButtonOpen(props) {
	let { name, avatar, role, open } = props

	return (
		<div className="user-component" >
			<Avatar className="user-avatar" alt={name || 'Unknown User'} 
				src={avatar} 
			> 
				{(props.name) ? props.name.split(" ").map((n)=>n[0]) : null}
			</Avatar>

			<div className={open ? "user-component-text open" : "user-component-text closed"} >
				<span className="user-component-text-name">
					{name ? name :
						<Skeleton variant="text" width="7.65em" animation={false} />
					}
				</span>
				<span className="user-component-text-role">
					{role ? role :
						<Skeleton variant="text" width="8em" animation={false} />
					}
				</span>
			</div>
		</div>
	)
}
