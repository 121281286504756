/* eslint-disable eqeqeq */
import { MenuItem, Select } from "@mui/material"
import React, { useCallback, useContext } from "react"
import { DispatchContext, LookupContext } from "../store"
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded"
import { useMutation } from "@apollo/client"
import {
	UPDATE_ENTITY_TASK,
	UPDATE_ART_TASK,
	UPDATE_ARTIST_TASK,
	UPDATE_CONTACT_TASK,
	UPDATE_DEAL_TASK,
	UPDATE_LISTING_TASK,
} from '../Tasks/Queries'
import { severity } from "../Snackbar/CustomizedSnackbar"

const TaskStatusSelectField = (props) => {
	const { id, value, api, field, row } = props
	const lookup = React.useContext(LookupContext)
	const taskStatusTypes = lookup.data?.getTaskStatusTypes

	// Mutations
	const [updateEntityTask] = useMutation(UPDATE_ENTITY_TASK)
	const [updateArtTask] = useMutation(UPDATE_ART_TASK)
	const [updateArtistTask] = useMutation(UPDATE_ARTIST_TASK)
	const [updateContactTask] = useMutation(UPDATE_CONTACT_TASK)
	const [updateDealTask] = useMutation(UPDATE_DEAL_TASK)
	const [updateListingTask] = useMutation(UPDATE_LISTING_TASK)

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)
	

	const handleResponse = (response, name, event) => {

		if (response && response.data[name].success === false) {
			console.error(response.data[name])
			openSnackbar(severity.ERROR, 'There was an error updating this task.')
			
		} else if (response && response.data[name].success === true) {
			openSnackbar(severity.SUCCESS, 'Successfully updated task.')
			api.setEditCellValue({ id, field, value: event.target.value }, event)
		} else {
			console.error(response.data[name])
			openSnackbar(severity.ERROR, 'Could not update task.')
		}
	}

	const handleError = (error) => {
		console.error(error)
		openSnackbar(severity.ERROR, 'There was an error updating this task.')
	}


	const handleChange = (event) => {
		const value = event.target.value

		const updateVariables = {
			variables: {
				UpdateTaskInput: {
					id,
					task_status_id: taskStatusTypes.find(el => el.value == value).id,
				},
			},
		}

		if (!row.created_at) {
			openSnackbar(severity.ERROR, 'You do not have permission to update this task.')
			return
		}

		if (row.art_id)
			updateArtTask(updateVariables)
				.then((response) => handleResponse(response, 'updateArtTask', event))
				.catch(handleError)
		else if (row.artist_id)
			updateArtistTask(updateVariables)
				.then((response) => handleResponse(response, 'updateArtistTask', event))
				.catch(handleError)
		else if (row.contact_id)
			updateContactTask(updateVariables)
				.then((response) => handleResponse(response, 'updateContactTask', event))
				.catch(handleError)
		else if (row.deal_id)
			updateDealTask(updateVariables)
				.then((response) => handleResponse(response, 'updateDealTask', event))
				.catch(handleError)
		else if (row.listing_id)
			updateListingTask(updateVariables)
				.then((response) => handleResponse(response, 'updateListingTask', event))
				.catch(handleError)
		else
			updateEntityTask(updateVariables)
				.then((response) => handleResponse(response, 'updateEntityTask', event))
				.catch(handleError)

	}
	
	return (
		<Select className='hideBorder-data-grid fullWidth' value={value} onChange={handleChange} IconComponent={ExpandMoreRoundedIcon}>
			{taskStatusTypes?.map((menuItem) => (
				<MenuItem key={menuItem.id} value={menuItem.value}>
					{menuItem.value}
				</MenuItem>
			))}
		</Select>
	)
}

export function renderSelectEditCell(params) {
	return <TaskStatusSelectField {...params} />
}
