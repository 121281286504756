/* eslint-disable eqeqeq */
import { useMutation } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { ControlPoint } from '@mui/icons-material'
import { Checkbox, FormControl, FormControlLabel, IconButton } from '@mui/material'
import { withStyles } from '@mui/styles'
import React, { useCallback, useContext, useEffect } from 'react'
import { randomHSL } from '../../common/helpers'
import { SavedSearch } from '../../common/SavedSearch'
import { USER_SEARCH_OBJECT } from '../../constants/values'
import { AdvancedToggleButton, ChooseImageButton, ResetButton, SaveButton, SearchButton } from '../../input/Buttons'
import Label from '../../input/Label'
import TextBox from '../../input/Text/TextBoxDark'
import ConfirmationInput from '../../navigation/ConfirmationDialog/ConfirmationInput'
import AdvancedFilterCount from '../../Search/AdvancedFilterCount'
import { FindModeButton } from '../../Search/GlobalSearchFilters'
import { CREATE_SEARCH } from '../../Search/Queries'
import SearchInfo from '../../Search/SearchInfo'
import { handleSearchChange, saveMap, saveSearch, searchMap } from '../../Search/unifiedSearchHelpers'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { AuthStateContext, DispatchContext } from '../../store'
import { typeStyle } from '../../styles/makesStyles'

const SearchUsers = (props) => {
	const userAuthentication = useContext(AuthStateContext)
	const [currentSearch, setCurrentSearch] = React.useState(props.prevSearch ?? new SavedSearch({
		object_id: USER_SEARCH_OBJECT,
		search_terms: [{
			field: 'is_active',
			type: 'eq',
			value: 'true'
		}],
		is_global: true,
		user_id: userAuthentication.user?.id
	}))
	const [savedSearchDialog, setSavedSearchDialog] = React.useState({
		open: false,
		search: null
	})

	const handleUserSavedSearchChange = (event, type = 'eq') => {

		const search_terms = handleSearchChange(
			event,
			currentSearch,
			setCurrentSearch,
			type,
			true
		)

		if (props.currentSearch.id) { 
			props.setCurrentSearch({ 
				...currentSearch,
				search_terms,
				id: null 
			})
		}
	}

	const dispatch = useContext(DispatchContext)
	const openQuickView = useCallback((severity, text) => {
		dispatch({ type: 'openQuickView' })
	}, [dispatch])


	// Snackbar
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])


	// Mutations
	const [createSearch, { loading }] = useMutation(CREATE_SEARCH)

	const [firstLoad, setFirstLoad] = React.useState(true)
	React.useEffect(() => setFirstLoad(false), [])


	useEffect(() => {
		if (props.currentSearch) setCurrentSearch(props.currentSearch)
	}, [props.currentSearch])

	const getSearchValue = (fieldName) =>
		currentSearch.search_terms
			?.find(el => el.field == fieldName)?.value


	const AdminFormControlLabel = withStyles({
		label: {
			fontSize: '14px',
			fontWeight: '500',
			paddingTop: '.35em',
			color: 'hsl(0deg 0% 100% / 70%)',
		},
		root: {
			marginLeft: 0
		}
	})(FormControlLabel)

	const AdminCheckbox = withStyles({
		root: {
			'&$checked': {
				color: 'grey',
			},		
		},
		checked: {},
	})((props) => <Checkbox color="default" {...props} />)

	return (
		<>
			<h1 className="card-title">
				
				Admin Search Users

				<ChooseImageButton
					data-testid="new-user-button"
					variant="contained"
					size="small"
					style={{backgroundColor: !props.loading ? '#4465D1' : null}}
					onClick={() => {
						openQuickView()
						props.setCreateEntity(true)
					}}
					endIcon={<ControlPoint />}
				>
					Create
				</ChooseImageButton>

				<AdvancedFilterCount
					advancedFilters={props.advancedFilters}
					setAdvancedSearch={props.setAdvancedSearch}
				/>
			
				<SearchInfo
					advancedFilters={!!props.advancedFilters?.length}
					style={{marginRight: 0}}
					fields={["User Name (First, Last, Full)", "Gallery", "Department", "ID"]}
					recordName="user"
					unified
					darkMode
				/> 
			</h1>
			<form
				onSubmit={(e) => {
					e.preventDefault()
					props.setActiveStep(0)
					props.handleSubmit(
						{ cursor: 0 },
						currentSearch.search_terms,
						currentSearch.id
					)
				}}
			>
				<div className="row">
					<div style={{flexGrow: 0}}>
						<AdminFormControlLabel
							value="top"
							control={
								<>
									{getSearchValue('is_active') == null ?
										<IconButton
											style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
											onClick={() => handleUserSavedSearchChange({
												target: {
													name: 'is_active',
													value: 'true'
												},
											})}
											title="All"
											size="large"> <AdminCheckbox style={{ color: 'grey' }} />
										</IconButton> : null}
                                                        
									{getSearchValue('is_active') == 'true' ?
										<IconButton
											style={{marginTop: '2px', height: '1.6em', width: '1.6em'}}
											onClick={() => handleUserSavedSearchChange({
												target: {
													name: 'is_active',
													value: 'false'
												},
											})}
											title="Non-Active"
											size="large"> <AdminCheckbox checked={true} style={{color: 'hsl(226deg 61% 54%)'}} />
										</IconButton>
										: null}

									{getSearchValue('is_active') == 'false' ?
										<IconButton
											style={{marginTop: '2px', height: '1.6em', width: '1.6em', color: 'white'}}
											onClick={() => handleUserSavedSearchChange({
												target: {
													name: 'is_active',
													value: null
												},
											})}
											title="Active"
											size="large"> <AdminCheckbox style={{color: 'hsl(226deg 61% 54%)'}} />
										</IconButton>
										: null}
								</>
							}
							label="Active"
							labelPlacement="top"
						/>
					</div>
					<FormControl style={{ flexGrow: '2' }}>
						<Label style={typeStyle} disableAnimation shrink>
							Search
						</Label>
						<TextBox
							name="query" 
							inputRef={input => input && firstLoad && input.focus()}
							value={getSearchValue('query') || ''} 
							onChange={(event) => handleUserSavedSearchChange(event)} 
							data-testid="searchbar"
						/>
					</FormControl>
				</div>

				<div className="row" style={{ paddingTop: '1em' }}>
					<div>
						<SaveButton
							data-testid="save-button"
							variant="contained"
							size="small"
							onClick={() => {
								saveSearch(
									currentSearch,
									openSnackbar,
									severity,
									setSavedSearchDialog
								)
							}}
						>
							Save Search
						</SaveButton>
					</div>
					<FindModeButton onFindMode={props.onFindMode} />
					<AdvancedToggleButton
						data-testid="advanced-toggle-button"
						size="small"
						onClick={() => {
							props.setCurrentSearch(currentSearch)
							props.setAdvancedSearch(true)
						}}
						style={{ marginRight: '1em' }}
					>
                    Advanced
					</AdvancedToggleButton>
					<ResetButton
						data-testid="reset-button"
						variant="contained"
						size="small"
						onClick={() => {
							props.setTotalItems(null)
							props.setSelection([])
							props.resetSearch('simple')
							setCurrentSearch(new SavedSearch({
								object_id: USER_SEARCH_OBJECT,
								search_terms: [{
									field: 'is_active',
									type: 'eq',
									value: 'true'
								}],
								is_global: true,
								user_id: userAuthentication.user?.id
							}))
						}}
						style={{ marginRight: '1em' }}
					>
						Reset
					</ResetButton>
					<SearchButton data-testid="search-button" variant="contained" size="small" type="submit" style={{backgroundColor: !props.loading ? '#4465D1' : null}}
						disabled={props.loading}>
						Search
					</SearchButton>
				</div>
			</form>

			<ConfirmationInput
				open={savedSearchDialog.open}
				handleClose={() => setSavedSearchDialog({
					open: false,
					savedSearch: null
				})}
				disabled={loading}
				title={'Save search?'}
				acceptText={'Save'}
				buttonColor="#33BF5C"
				inputLabel="Search Label"
				placeholder={'Saved Search ' + (props.savedSearches?.length + 1)}
				onYes={(text) => {
					if (!loading) {
						createSearch({
							variables: {
								CreateSearchInput: {
									label: text ? text : 'Saved Search ' + (props.savedSearches?.length + 1),
									user_id: userAuthentication.user.id,
									object_id: USER_SEARCH_OBJECT,
									color: randomHSL(),
									search_terms: searchMap(currentSearch.search_terms),
									result_count: null,
									is_global: true,
								},
							},
						}).then((response) => {

							setCurrentSearch({
								...response.data.createSavedSearch.search,
								search_terms: saveMap(response.data.createSavedSearch.search.search_terms)
							})

							props.setSavedSearches(props.savedSearches.concat(
								new SavedSearch(response.data.createSavedSearch.search))
							)
						})
					}
				}}
			/>
		</>
	)
}

export default withApollo(SearchUsers)
