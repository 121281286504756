import { FormControl, MenuItem, Select } from '@mui/material'
import Dropdown from '../../input/Dropdown/ThinDropdown'
import Label from '../../input/Label'
import React, { useContext } from "react"
import TextBox from '../../input/Text/TextBoxThin'
import TransitionsModal from '../../navigation/TransitionsModal/TransitionsModal'
import {SubmitButton, CancelButton} from '../../input/Buttons'
import PhoneInput from 'react-phone-input-2'
import { isValidPhone } from '../../common/helpers'
import { useEffect } from 'react'
import { DispatchContext } from '../../store'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

export default function EditPhoneModal(props) {
	
	const [validPhone, setValidPhone] = React.useState(true)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}

	useEffect(() => {
		setValidPhone(isValidPhone(props.phoneModal?.phone?.number))
	}, [props.phoneModal])

	return (
		<TransitionsModal
			className="phone-modal"
			open={props.phoneModal.open}
			close={() =>
				props.setPhoneModal({ ...props.phoneModal, open: false })
			}
		>
			<form
				className={props.isPrivate ? "private-object modal-small-content" : "modal-small-content"}
				onSubmit={e => {
					e.preventDefault()
					e.stopPropagation()

					if (props.phoneModal?.phone?.number === undefined || !validPhone) {
						openSnackbar(severity.WARNING, "Please fill out the fields in red.")
					} else {
						let temp = props.contactInput.phone

						if (Array.isArray(temp)) {
							temp[props.phoneModal.count - 1] =
							props.phoneModal.phone

							props.setContactInput({
								...props.contactInput,
								phone: temp
							})
							props.setPhoneModal({
								...props.phoneModal,
								open: false
							})
						} else {
							props.setContactInput({
								...props.contactInput,
								phone: props.phoneModal.phone
							})
							props.setPhoneModal({
								...props.phoneModal,
								open: false
							})
						}
					}
				}}
			>
				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Label
					</Label>
					<TextBox
						autoFocus
						placeholder="-"
						value={props.phoneModal?.phone?.label || ''}
						onChange={e => {
							props.setPhoneModal({
								...props.phoneModal,
								phone: {
									...props.phoneModal.phone,
									label: e.target.value
								}
							})
						}}
					/>
				</FormControl>

				<FormControl style={{ width: '100%' }}>
					<Label disableAnimation shrink>
						Type*
					</Label>
					<Select
						IconComponent={ExpandMoreRoundedIcon}			
						input={<Dropdown />}
						className="padded-select"
						value={props.phoneModal?.phone?.type_id || ''}
						onChange={e => {
							props.setPhoneModal({
								...props.phoneModal,
								phone: {
									...props.phoneModal.phone,
									type_id: e.target.value,
									type:
										props.phoneTypes[e.target.value - 1]
											.type
								}
							})
						}}
					>
						{props.phoneTypes && props.phoneTypes.map(x => (
							<MenuItem key={x.id} value={x.id}>
								{x.value}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<div style={{display: "flex"}}>
					
					<div className={!validPhone ? "error" : null} style={{
						marginRight: '1em',
						marginTop: '-12px'
					}}>

						<Label disableAnimation shrink style={{marginBottom: '-12px', marginTop: 5, marginLeft: 2}}>
							Phone*
						</Label>
						<PhoneInput
							disableSearchIcon
							enableSearch
							placeholder=""
							dropdownStyle={{borderRadius: "4px"}}
							inputStyle={{width: "100%", fontFamily: "graphik", height: '37px'}}
							value={props.phoneModal?.phone?.number}
							onChange={(value, data, event) => {
								props.setPhoneModal({
									...props.phoneModal,
									phone: {
										...props.phoneModal.phone,
										number: value.replace(/[^0-9]+/g,'')
									}
								})
							}}
						/>

					</div>

					<FormControl style={{ width: '30%' }}>
						<Label disableAnimation shrink>
							Extension
						</Label>
						<TextBox
							value={props.phoneModal?.phone?.extension || ''}
							onChange={e => {
								props.setPhoneModal({
									...props.phoneModal,
									phone: {
										...props.phoneModal.phone,
										extension: e.target.value
									}
								})
							}}
						/>
					</FormControl>

				</div>

				<CancelButton
					variant="contained"
					style={{ float: 'left', marginTop: '1em' }}
					onClick={e => {
						e.preventDefault()

						if (Array.isArray(props.contactInput.phone)) {
							props.setPhoneModal({
								...props.phoneModal,
								open: false
							})
						} else {
							props.setPhoneModal({
								...props.phoneModal,
								phone: props.contactInput.phone,
								open: false
							})
						}
					}}
				>
				Cancel
				</CancelButton>

				<SubmitButton
					style={{ float: 'right', marginTop: '1em' }}
					type="submit"
					variant="contained"
				>
					Submit
				</SubmitButton>
		
			</form>
		</TransitionsModal>
	)
}
