import { gql } from '@apollo/client'

export const GET_ART_LISTINGS = gql`
	query GetArtListings($field: String, $art_id: ID, $artist_id: ID, $limit: Int, $direction: SortDirection, $searchString: String, $listingTypeId: String, $cursor: Int) {
		getArtListings(
			input: { field: $field, direction: $direction, limit: $limit, cursor: $cursor, filters: { art_id: $art_id, artist_id: $artist_id, searchString: $searchString, listingTypeId: $listingTypeId } }
		) {
			items {
				id
				status_id
				art {
					title
					formatted_title
					is_private
					created_at
					imgUrl  (thumbnailResolution: "128x128")
					year
					artist {
						first_name
						last_name
					}
				}
				listing {
					id
					title
					subtitle
					type_id
					location
					start_at
					listing_type {
						value
					}
					imgUrl (thumbnailResolution: "128x128")
				}
			}
			cursor
			totalItems
		}
	}
`

export const UPDATE_LISTING_ART = gql`
	mutation updateListingArt($id: ID, $art_id: ID, $listing_id: ID, $status_id: ID) {
		updateListingArt(input: { id: $id, art_id: $art_id, listing_id: $listing_id, status_id: $status_id }) {
			id
			status_id
			art {
				title
				formatted_title
				imgUrl
				year
				artist {
					first_name
					last_name
				}
			}
			listing {
				id
				title
				subtitle
				type_id
				location
				start_at
				listing_type {
					value
				}
				imgUrl
			}
		}
	}
`

export const DELETE_LISTING_ART = gql`
	mutation deleteListingArt($id: ID) {
		deleteListingArt(id: $id) {
			code
			success
			message
		}
	}
`

export const SEARCH_POSSIBLE_ART_LISTINGS = gql`
	query searchPossibleArtListings($art_id: ID, $query: String) {
		searchPossibleArtListings(art_id: $art_id, query: $query) {
			id
			title
			subtitle
			disabled
			imgUrl
		}
	}
`

export const SEARCH_POSSIBLE_CONTACT_LISTINGS = gql`
	query searchPossibleContactListings($contact_id: ID, $query: String) {
		searchPossibleContactListings(contact_id: $contact_id, query: $query) {
			id
			title
			subtitle
			disabled
			imgUrl
		}
	}
`


export const GET_SHARED_WORKS_CONTACTS = gql`
    query getLinks($input: LinkQueryRequest!) {
        getLinks(input: $input) {
            items {
                id
                art {
                    id
                }
                contact_id
                owner_user_id
                created_at

                user {
                    first_name
                    last_name
                    department
                    imgUrl
                }

                contact {
                    first_name
                    last_name
                    code_name
                    dynamic_generated_codename
                    company_name
                    imgUrl
                    is_private
                    is_company
                    is_deceased
                    created_at
                }
                
            }
            cursor
            totalItems
        }
    }
`

export const GET_SHARED_WORKS_ART = gql`
    query getLinks($input: LinkQueryRequest!) {
        getLinks(input: $input) {
            items {
                id
                contact_id
                owner_user_id
                created_at

                user {
                    first_name
                    last_name
                    department
                    imgUrl
                }

                art {
                    id
                    title
                    formatted_title
                    code_name
                    year
                    imgUrl
                    artist {
                        first_name
                        last_name
                    }
                    is_private
                }
                
            }
            cursor
            totalItems
        }
    }
`

