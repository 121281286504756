/* eslint-disable eqeqeq */
import { useMutation, useQuery } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import React, { useCallback, useContext, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { SavedSearch } from "../common/SavedSearch"
import { DEFAULT_SEARCH_LIMIT, TASK_SEARCH_OBJECT } from "../constants/values"
import { SELECT_ALL_TASKS } from "../Search/ActionButtons/Queries"
import GlobalSearchFilters, { getOperatorValue, initialFilterState } from "../Search/GlobalSearchFilters"
import { DYNAMIC_TASK_SEARCH, GET_SEARCHES, GET_TASK_FILTER_FIELDS, UPDATE_SEARCH, TASK_FLAG_QUERY } from "../Search/Queries"
import { filterMap, filterSearchTerms, handleDeleteSearch, updateLabel } from "../Search/unifiedSearchHelpers"
import SearchQuickView from "../Search/SearchQuickView"
import { severity } from '../Snackbar/CustomizedSnackbar'
import { AuthStateContext, DispatchContext } from '../store'
import SearchTasks from "./SearchTasks"
import CreateTaskQuickView from "./Task/CreateTaskQuickView"
import TasksDataGrid from './TasksDataGrid'
import { DYNAMIC_TASK_NAV } from '../navigation/Queries'
import useNavigation from '../navigation/useNavigation'
import UserAccessQuickView from '../QuickView/UserAccessQuickView'
import ArtQuickView from '../Art/ArtPiece/ArtQuickView'
import ArtistQuickView from '../Artists/Artist/ArtistQuickView'
import ContactQuickView from '../Contacts/ContactQuickView'
import DealQuickView from '../Deals/DealQuickView'
import ListingQuickView from '../Listings/Listing/ListingQuickView'
import { permissions, permissionValues } from '../constants/permissions'
import CollapsibleSearchCard from '../common/components/CollapsibleSearchCard'


const Tasks = (props) => {

	const { push: pushNav } = useNavigation()
	const navigate = useNavigate()
	const location = useLocation()
	const userAuthentication = useContext(AuthStateContext)

	const prevSearch = location.state

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const closeSnackbar = () => {
		dispatch({ type: 'closeSnackBar'})
	}

	// Mutations
	const [updateSearch] = useMutation(UPDATE_SEARCH)

	// State that comes from prevSearch
	const [order, setOrder] = React.useState(prevSearch?.order || "asc")
	const [orderBy, setOrderBy] = React.useState(prevSearch?.orderBy || null)
	const [tasks, setTasks] = React.useState(prevSearch?.tasks ?? [])
	const [limit, setLimit] = React.useState(prevSearch?.limit ?? DEFAULT_SEARCH_LIMIT)
	const [activeStep, setActiveStep] = React.useState(prevSearch?.activeStep ?? 0)
	const [totalItems, setTotalItems] = React.useState(prevSearch?.totalItems ?? null)
	const [steps, setSteps] = React.useState(prevSearch?.steps ?? 1)
	const [selectedTask, setSelectedTask] = React.useState(null)
	const [advancedSearch, setAdvancedSearch] = React.useState(prevSearch?.advancedSearch ?? false)
	const [defaultSelection, setDefaultSelection] = React.useState([])
	const [currentSearch, setCurrentSearch] = React.useState(prevSearch?.currentSearch ?? new SavedSearch({
		object_id: TASK_SEARCH_OBJECT,
		search_terms: defaultSelection,
		is_global: true,
		user_id: userAuthentication.user?.id
	}))


	// Normal State
	const [value, setValue] = React.useState(0)
	const [flaggedCount, setFlaggedCount] = React.useState(null)
	const [firstRender, setFirstRender] = React.useState(true)
	const [selection, setSelection] = React.useState([])
	const [savedSearches, setSavedSearches] = React.useState([])
	const [searchLoading, setSearchLoading] = React.useState(null)
	const [createTask, setCreateTask] = React.useState(null)
	const [entitySelection, setEntitySelection] = React.useState([])

	const setFilters = (filters) => {
		setCurrentSearch({
			...currentSearch,
			search_terms: filters
		})
	}

	React.useEffect(() => {
		if (!userAuthentication) return null

		let temp = ['unlinked']

		const userPermissions = userAuthentication?.user?.permissions

		if (userPermissions) {
			const artPermission = userPermissions
				.find(e => e.permission_id == permissions.ART_TASKS)
				?.permission_value_id
			if (artPermission >= permissionValues.VIEW_ONLY) 
				temp.push('art')

			const artistPermission = userPermissions
				.find(e => e.permission_id == permissions.ARTISTS_TASKS)
				?.permission_value_id
			if (artistPermission >= permissionValues.VIEW_ONLY)
				temp.push('artist')

			const contactPermission = userPermissions
				.find(e => e.permission_id == permissions.CONTACTS_TASKS)
				?.permission_value_id
			if (contactPermission >= permissionValues.VIEW_ONLY)
				temp.push('contact')

			const dealPermission = userPermissions
				.find(e => e.permission_id == permissions.DEALS_TASKS)
				?.permission_value_id
			if (dealPermission >= permissionValues.VIEW_ONLY) 
				temp.push('deal')

			const listingPermission = userPermissions
				.find(e => e.permission_id == permissions.LISTINGS_TASKS)
				?.permission_value_id
			if (listingPermission >= permissionValues.VIEW_ONLY)
				temp.push('listing')
		}

		const objectFilters = [{
			id: new Date().getTime(),
			field: 'linkEntity',
			type: 'eq',
			value: temp.join(" OR ")
		}]

		setDefaultSelection(objectFilters)
		setFilters(objectFilters)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.authState])

	const currentFilters = [
		'query',
		'due_at',
		'created_at',
		'taskStatus',
		'taskType',
		'assignees',
		'linkEntity'
	]

	const advancedFilters = filterSearchTerms(currentSearch.search_terms)?.filter(term => {
		if (currentFilters.includes(term.field)) return false
		return true
	})

	useEffect(() => {
		if (currentSearch.search_terms.length == 0 && advancedSearch) {
			setCurrentSearch(new SavedSearch({
				object_id: TASK_SEARCH_OBJECT,
				search_terms: [initialFilterState()],
				is_global: true,
				user_id: userAuthentication.user?.id
			}))
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSearch.search_terms, userAuthentication.user?.id, advancedSearch])

	// Search on change of order
	React.useEffect(() => {
		setFirstRender(false)
		if (tasks)
			if (tasks.length !== 0 && tasks[0].disabled !== true && !firstRender) {
				handleSubmit()
			}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order, orderBy, limit, activeStep])

	React.useEffect(() => {
		setFirstRender(false)
		if (tasks)
			if (tasks.length !== 0 && tasks[0].disabled !== true && !firstRender) {
				handleSubmit()
			}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order, orderBy, limit, activeStep])

	function saveAndGo(path, row) {
		// persist current page state in history
		const state = updateHistory(row)
		pushNav({
			url: location.pathname,
			state,
			query: DYNAMIC_TASK_NAV
		}, state.variables?.cursor)
		// navigate to new page (with this page's query and `variables`)
		navigate(path, { state })
	}

	/**
	 * Save current page state for if one returns to it through
	 * back arrow.
	 *
	 * @returns current page state
	 */
	 function updateHistory (index) {
		const currentPageState = {
			currentSearch,
			orderBy,
			order,
			tasks,
			totalItems,
			steps,
			activeStep,
			advancedSearch,
			limit,
			searchPage: location.pathname
		}
		const offset = index ? tasks.findIndex(a => a.id == index) : tasks.indexOf(selectedTask)
		const cursor = activeStep * limit + offset
		const variables = makeVariables(cursor, currentSearch, orderBy, order, currentSearch?.search_terms ?? [])
		variables.limit = 3
		currentPageState.variables = variables
		navigate(location.pathname, { state: currentPageState })
		return currentPageState
	}

	// Save search to state on selecting a Task
	React.useEffect(() => {
		if (selectedTask) updateHistory()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTask])


	// Get saved searches
	const {loading, error } = useQuery(GET_SEARCHES, {
		skip:  !userAuthentication.user?.id,
		variables: {
			userId: userAuthentication.user?.id,
			objectId: TASK_SEARCH_OBJECT,
			global: true
		},
		onCompleted: (data) => {
			if (data && data.getSearches && data.getSearches?.length !== 0) {
				setSavedSearches(data.getSearches
					.map(search => new SavedSearch(search)))
			} else {
				// console.log("No saved searches were found.")
			}
		}
	})

	if (error) {
		console.log(error)
		openSnackbar(severity.ERROR, "Error retrieving your saved searches.")
	}


	const makeVariables = (cursor, currentSearch, orderBy, order, filters, limitOverride) => {
		return ({
			cursor,
			limit: limitOverride ?? limit,
			field: orderBy,
			direction: order.toUpperCase(),
			filters: filters ? filterMap(filters) : filterMap(currentSearch.search_terms)
		})
	}

	const handleSubmit = ({ cursor } = {}, filters = null, searchId) => {

		let submitCursor
		if (cursor == undefined) {
			submitCursor = activeStep * limit
		} else {
			submitCursor = cursor
		}
		setSearchLoading(true)
		openSnackbar(severity.INFO, "Loading search results...")

		const variables = makeVariables(
			submitCursor, currentSearch, orderBy, order, filters
		)
		setValue(1)

		// Set filters when handle submit is not coming from saved search list
		if (filters?.length && !searchId) setFilters(filters)

		props.client
			.query({ query: DYNAMIC_TASK_SEARCH, variables })
			.then((result) => {

				const {data} = result

				if (data.searchDynamicTasks?.items) {
					setTasks(data.searchDynamicTasks.items)

					if (data.searchDynamicTasks.items < 1) setSteps(1)
					else setSteps((Math.ceil(data.searchDynamicTasks.totalItems / limit)))

					setTotalItems(data.searchDynamicTasks.totalItems || 0)
					setSearchLoading(false)
					if (!data.searchDynamicTasks.totalItems) {
						openSnackbar(severity.WARNING, "There were no results.")
					} else {
						closeSnackbar()
					}

				} else {
					console.error(data)
					setSearchLoading(false)
					openSnackbar(severity.ERROR, "There was an error searching tasks.")
				}
			})
			.catch((error) => {
				console.error(error)
				setSearchLoading(false)
				openSnackbar(severity.ERROR, "Could not search tasks.")
			})

	}

	const handleReset = (page) => {

		setCurrentSearch(new SavedSearch({
			object_id: TASK_SEARCH_OBJECT,
			search_terms: defaultSelection,
			is_global: true,
			user_id: userAuthentication.user?.id
		}))
		setTasks([])
		setCreateTask(false)
		setSteps(1)
		setActiveStep(0)
		setTotalItems(null)
		setOrder("asc")
		setOrderBy(null)
		setSelectedTask(null)
		setSearchLoading(false)
		setValue(0)
	}

	const updateTaskSavedSearchLabel = (search, label) =>
		updateLabel(
			search,
			label,
			updateSearch,
			savedSearches,
			setSavedSearches,
			openSnackbar
		)

	const handleDeleteTaskSavedSearch = (search) =>
		handleDeleteSearch(
			search,
			updateSearch,
			savedSearches,
			setSavedSearches,
			openSnackbar
		)

	
	// Get dynamic task filter fields
	const { data: taskFilterFields, error: taskFilterError } = useQuery(GET_TASK_FILTER_FIELDS, {
		fetchPolicy: 'cache-first',
		onCompleted: ({searchDynamicTasksFilterFields}) => {
			const storedFields = location?.state?.fields
			if (storedFields) {
				setAdvancedSearch(true)
				const fieldFilters = Object.entries(storedFields)
					.map(([field, value], i) => {
						const filterField = searchDynamicTasksFilterFields
							.find(f => f.name == field)
						let type = getOperatorValue(filterField?.type)
						return {
							field,
							value,
							type,
							id: new Date().getTime() + i
						}
					})

				setFilters(fieldFilters)
				handleSubmit({}, fieldFilters)
			}
		}
	})

	if (taskFilterError) {
		openSnackbar(severity.ERROR, 'There was an error retrieving filterable fields for this search.')
		console.error(taskFilterError)
	}


	const renderQuickView = () => {

		let selectedEntity
		let entityType

		if (selectedTask?.art_id) {
			selectedEntity = selectedTask.art
			entityType = 'art'
		} else if (selectedTask?.artist_id) {
			selectedEntity = selectedTask.artist
			entityType = 'artist'
		} else if (selectedTask?.contact_id) {
			selectedEntity = selectedTask.contact
			entityType = 'contact'
		} else if (selectedTask?.deal_id) {
			selectedEntity = selectedTask.deal
			entityType = 'deal'
		} else if (selectedTask?.listing_id) {
			selectedEntity = selectedTask.listing
			entityType = 'listing'
		}
		
		if (createTask) {
			return (
				<CreateTaskQuickView
					setCreateTask={setCreateTask}
					currentSearch={currentSearch}
					setCurrentSearch={setCurrentSearch}
				></CreateTaskQuickView>
			)
		}

		else if (selectedEntity) {

			if ((selectedEntity.is_private || selectedEntity.is_gallery_private) && !selectedEntity.created_at)
				return (
					<UserAccessQuickView
						entity={selectedEntity}
						onClose={() => setSelectedTask(null)}
					></UserAccessQuickView>
				)

			if (entityType == 'art') return (
				<ArtQuickView
					id={selectedEntity.id}
					onClose={() => setSelectedTask(null)}
				></ArtQuickView>
			)

			if (entityType == 'artist') return (
				<ArtistQuickView
					id={selectedEntity.id}
					onClose={() => setSelectedTask(null)}
				></ArtistQuickView>
			)

			if (entityType == 'contact') return (
				<ContactQuickView
					id={selectedEntity.id}
					onClose={() => setSelectedTask(null)}
				></ContactQuickView>
			)

			if (entityType == 'deal') return (
				<DealQuickView
					id={selectedEntity.id}
					onClose={() => setSelectedTask(null)}
				></DealQuickView>
			)

			if (entityType == 'listing') return (
				<ListingQuickView
					id={selectedEntity.id}
					onClose={() => setSelectedTask(null)}
				></ListingQuickView>
			)
		}

		else {
			return (
				<SearchQuickView
					savedSearches={savedSearches}
					setSavedSearches={setSavedSearches}
					setCurrentSearch={setCurrentSearch}
					setFilters={setFilters}
					handleSubmit={handleSubmit}
					currentSearch={currentSearch}
					setAdvancedSearch={setAdvancedSearch}
					savedSearchesLoading={loading}
					updateLabel={updateTaskSavedSearchLabel}
					handleDeleteSearch={handleDeleteTaskSavedSearch}
					setSelection={setSelection}
					selection={selection}
					totalItems={totalItems}
					flaggedCount={flaggedCount}
					setFlaggedCount={setFlaggedCount}
					makeVariables={makeVariables}
					orderBy={orderBy}
					order={order}
					searchQuery={DYNAMIC_TASK_SEARCH}
					flagQuery={TASK_FLAG_QUERY}
					entity="task"
					bulkActionQuery={SELECT_ALL_TASKS}
					setValue={setValue}
					value={value}
					filterFields={taskFilterFields?.searchDynamicTasksFilterFields}
					requery={handleSubmit}
				/>
			)
		}
	}

	const variables = makeVariables(0, currentSearch, orderBy, order)

	return (
		<section className="main-page">

			{ renderQuickView() }

			<div style={{
				display: 'flex',
				flexDirection: 'column'
			}}>
				<CollapsibleSearchCard>
					{advancedSearch ?
						<GlobalSearchFilters
							loading={searchLoading}
							currentSearch={currentSearch}
							setCurrentSearch={setCurrentSearch}
							onReset={handleReset}
							onSearch={handleSubmit}
							object_id={TASK_SEARCH_OBJECT}
							advancedSearch={advancedSearch}
							setAdvancedSearch={setAdvancedSearch}
							setCreateEntity={setCreateTask}
							filterFields={taskFilterFields?.searchDynamicTasksFilterFields}
							typeName="Task"
							savedSearches={savedSearches}
							setSavedSearches={setSavedSearches}
							prevSearch={prevSearch?.currentSearch}

						/> :
						<SearchTasks
							loading={searchLoading}
							currentFilters={currentSearch.search_terms}
							resetSearch={handleReset}
							currentSearch={currentSearch}
							setCurrentSearch={setCurrentSearch}
							handleSubmit={handleSubmit}
							setActiveStep={setActiveStep}
							setTotalItems={setTotalItems}
							advancedSearch={advancedSearch}
							setAdvancedSearch={setAdvancedSearch}
							setCreateTask={setCreateTask}
							advancedFilters={advancedFilters}
							selection={selection}
							setSelection={setSelection}
							entitySelection={entitySelection}
							setEntitySelection={setEntitySelection}
							prevSearch={prevSearch?.currentSearch}
							savedSearches={savedSearches}
							setSavedSearches={setSavedSearches}
						></SearchTasks>}
				</CollapsibleSearchCard>
				<TasksDataGrid
					rows={tasks}
					loading={searchLoading}
					saveAndGo={saveAndGo}
					setRows={setTasks}
					setFlaggedCount={setFlaggedCount}
					userId={userAuthentication.user?.id}
					mainSearchPage={true}
					totalItems={totalItems}
					onSelect={setSelectedTask}
					selectedRow={selectedTask}
					limit={limit}
					setLimit={setLimit}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleSubmit={handleSubmit}
					currentSearch={currentSearch}
					sortModel={ orderBy ? [{ field: orderBy, sort: order }] : []}
					onSortModelChange={newSort => {
						if (newSort.length) {
							setOrderBy(newSort[0].field)
							setOrder(newSort[0].sort)
						} else {
							setOrderBy(null)
							setOrder('asc')
						}
					}}
					// flagmenu
					filterTo={newFilter => {
						setSelection([])
						setFilters(newFilter)
						handleSubmit({cursor: 0}, newFilter)
						setValue(1)
						setSelectedTask(null)
					}}
					type="Task"
					variables={variables}
				/>
			</div>
		</section>
	)
}

export default withApollo(Tasks)
