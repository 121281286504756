import React, { useContext, useState } from 'react'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import Flag from '@mui/icons-material/Flag'
import { Divider, IconButton, Menu, MenuItem } from '@mui/material'
import { GLOBAL_SEARCH_MAX_SELECTION } from '../../constants/values'
import { SELECT_ALL_ART, SELECT_ALL_ARTISTS, SELECT_ALL_CONTACTS, SELECT_ALL_DEALS, SELECT_ALL_LISTINGS, SELECT_ALL_TASKS } from '../../Search/ActionButtons/Queries'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { AuthStateContext, DispatchContext } from '../../store'
import { CLEAR_FLAGS, TOGGLE_FLAGGED_ENTITIES } from '../../table/Queries'

const getQuery = (type) => {
	const queries = {
		'Art': SELECT_ALL_ART,
		'Contact': SELECT_ALL_CONTACTS,
		'Deal':  SELECT_ALL_DEALS,
		'Artist':  SELECT_ALL_ARTISTS,
		'Listing': SELECT_ALL_LISTINGS,
		'Task': SELECT_ALL_TASKS
	}
	
	if (queries[type]) {
		return queries[type]
	} else {
		console.error(`${type} has no associated query.`)
		return gql``
	}
}

const searchResponses = {
	'Art': 'searchDynamicArt',
	'Contact': 'searchDynamicContacts',
	'Deal':  'searchDynamicDeals',
	'Artist':  'searchDynamicArtists',
	'Listing': 'searchDynamicListings',
	'Task': 'searchDynamicTasks'
}

/**
 * @typedef {Object} FlagMenuProps
 * @property {function} filterTo
 * @property {'Art' | 'Contact' | 'Deal' | 'Artist' | 'Listing'} type
 * @property {number} totalItems
 * @property {function} setFlaggedCount
 * @property {function} setRows
 * @property {function} rows
 * @property {function} getVariables
 * 
 * @property {Object[]} currentFilters
 */

/**
 * DataGridFlagMenu
 * @param {FlagMenuProps} props 
 * @returns 
 */
export default function DataGridFlagMenu (props) {

	const userAuthentication = useContext(AuthStateContext)
	const userId = userAuthentication.user?.id

	const dispatch = useContext(DispatchContext)
	const openSnackbar = (severity, text) => {
		dispatch({type: 'openSnackBar', payload: {severity, text}})
	}

	const [flagAnchorEl, setFlagAnchorEl] = useState()

	const handleClick = (event) => {
		const target = event.currentTarget
		;(props.onClick || Promise.reject.bind(Promise))()
			.then(() => clearAllFlags())
			.then(() => setFlagAnchorEl(target))
			.catch(() => setFlagAnchorEl(target))
	}
	const handleClose = () => setFlagAnchorEl(null)


	/*
	 * Queries
	 */
	const [toggleFlaggedEntities] = useMutation(TOGGLE_FLAGGED_ENTITIES)

	const flagAll = (items, setFlag = true) => {

		let contacts = []
		let deals = []
		let art = []
		let artists = []
		let listings = []
		let tasks = []

		let ids = items.map(e => Number(e.id))

		if (props.type === "Art") { art = ids }
		else if (props.type === "Contact") { contacts = ids }
		else if (props.type === "Deal") { deals = ids }
		else if (props.type === "Artist") { artists = ids }
		else if (props.type === "Listing") { listings = ids }
		else if (props.type === "Task") { tasks = ids}
		else {
			console.error(`${props.type} is not valid.`)
			return
		}
		toggleFlaggedEntities({
			variables: {
				contacts, deals, listings,
				artists, art, tasks,
				setFlag
			},
		}).then((res) => {
			if (res?.data?.toggleFlaggedEntities?.errors) {
				openSnackbar(severity.ERROR, 'Could not toggle these flags.')
			} else if (res?.data?.toggleFlaggedEntities?.success === false) {
				openSnackbar(severity.ERROR, 'There was an error toggling these flags.')
			} else if (res?.data?.toggleFlaggedEntities?.success) {
				openSnackbar(severity.SUCCESS, 'Successfully flagged entities.')

				props.setFlaggedCount?.(cur => cur + res.data.toggleFlaggedEntities.count)

				props.setRows(rows => {
					return rows.map(el => ({
						...el,
						isFlagged: setFlag
					}))})
			}
		}).catch((e) => {
			console.error(e)
			openSnackbar(severity.ERROR, 'There was an error toggling these flags.')
		})
	}


	const [clearFlags] = useMutation(CLEAR_FLAGS, {
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, "Could not clear flags.")
		},
		onCompleted: (response) => {
			if (response.clearFlags?.success === true) {
				// Success
				openSnackbar(severity.SUCCESS, response.clearFlags.message)

				props.setRows(rows => rows.map(el => ({
					...el,
					isFlagged: false
				})))
			} else {
				openSnackbar(severity.ERROR, response.clearFlags.message)
			}
		}
	})


	const findFlaggedOption = (e) => {
		const searchTerms = [
			{ field: 'isFlagged', type: 'eq', value: 'true' },
		]
		props.filterTo(searchTerms)
		handleClose()
	}

	const [ selectAllQueryToFlag ] = useLazyQuery(getQuery(props.type), {
		notifyOnNetworkStatusChange: true,
		onCompleted: (data) => {
			const respName = searchResponses[props.type]
			if (data[respName]) {
				const items = data[respName].items
				if (items.length) {
					flagAll(items)
				}
				else openSnackbar(severity.WARNING, 'No items to flag.')
			} else {
				console.error(data)
				openSnackbar(severity.ERROR, 'Unable to select results.')
			}
		},
		onError: error => {
			console.error(error.message)
			openSnackbar(severity.ERROR, 'There was an error selecting results.')
		}
	})

	const [ selectAllQueryToUnFlag ] = useLazyQuery(getQuery(props.type), {
		notifyOnNetworkStatusChange: true,
		onCompleted: (data) => {
			const respName = searchResponses[props.type]
			if (data[respName]) {
				const items = data[respName].items
				if (items.length) {
					flagAll(items, false)
				}
				else openSnackbar(severity.WARNING, 'No items to unflag.')
			} else {
				console.error(data)
				openSnackbar(severity.ERROR, 'Unable to select results.')
			}
		},
		onError: error => {
			console.error(error.message)
			openSnackbar(severity.ERROR, 'There was an error selecting results.')
		}
	})

	const flagAllOption = (e) => {
		if (props.totalItems > GLOBAL_SEARCH_MAX_SELECTION) {
			openSnackbar(
				severity.WARNING,
				`Cannot flag more than ${GLOBAL_SEARCH_MAX_SELECTION} entities.`
			)
			return
		}

		openSnackbar(severity.INFO, "Loading...")
		selectAllQueryToFlag({
			variables: {
				...props.variables,
				limit: GLOBAL_SEARCH_MAX_SELECTION
			},
		})
		handleClose()
	}

	const flagPageOption = (e) => {
		openSnackbar(severity.INFO, "Loading...")
		if (props.rows?.length) {
			flagAll(props.rows)
		} else {
			// Should not happen.
			openSnackbar(severity.WARNING, 'No items to flag.')
		}
		handleClose()
	}

	const unFlagResult = (e) => {
		openSnackbar(severity.INFO, "Loading...")
		selectAllQueryToUnFlag({
			variables: {
				...props.variables,
				limit: GLOBAL_SEARCH_MAX_SELECTION
			},
		})
		handleClose()
	}

	const unFlagPage = (e) => {
		openSnackbar(severity.INFO, "Loading...")
		if (props.rows?.length) {
			flagAll(props.rows, false)
		} else {
			// Should not happen.
			openSnackbar(severity.WARNING, 'No items to flag.')
		}
		handleClose()
	}

	const clearAllFlags = () => {
		let object = props.type === 'Deal' ? 'deal' : props.type.toLowerCase()

		props.setFlaggedCount?.(0)
	
		return clearFlags({
			variables: {
				user_id: userId,
				object
			}
		})
	}

	const clearFlagsOption = () => {
		handleClose()
		return clearAllFlags()
	}

	return <>
		<IconButton onClick={handleClick} aria-label="flagged">
			<Flag sx={{ color: '#919191', width: 18 }} />
		</IconButton>
		<Menu
			id="flag-menu"
			anchorEl={flagAnchorEl}
			keepMounted
			open={!!flagAnchorEl}
			onClose={handleClose}
		>
			{props.hideFindFlagged ? null :  
				<MenuItem onClick={findFlaggedOption}>
					Find Flagged
				</MenuItem>
			}
			<Divider />
			<MenuItem
				disabled={!props.totalItems}
				onClick={flagAllOption}
			>
				Flag All
			</MenuItem>
			<MenuItem
				disabled={!props.totalItems}
				onClick={flagPageOption}
			>
				Flag Page
			</MenuItem>
			<Divider />
			<MenuItem
				disabled={!props.totalItems}
				onClick={unFlagResult}
			>
				Unflag All
			</MenuItem>
			<MenuItem 
				disabled={!props.totalItems}
				onClick={unFlagPage}>
				Unflag Page
			</MenuItem>
			<Divider />
			<MenuItem onClick={clearFlagsOption}>
				Clear Flags
			</MenuItem>
		</Menu>
	</>
}
