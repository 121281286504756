/* eslint-disable eqeqeq */
import { severity } from "../Snackbar/CustomizedSnackbar"

export const handleUpdateSearchLabel = (response, label, selectedSearch, savedSearches, setSavedSearches, openSnackbar) => {
	// Success
	if (response.data.updateSavedSearch.success) {
        
		// Update saved search result count
		let searches = [...savedSearches]
		const index = savedSearches
			.findIndex(_search => 
				_search.id === selectedSearch.id 
			)		
		if (index !== -1) {
			searches[index].label = label
			setSavedSearches(searches)
		}
		// Error
	} else {
		openSnackbar(severity.ERROR, "There was an error updating your saved search.")
	}
}

export const handleDeleteSearchResponse = (response, savedSearches, search, setSavedSearches, openSnackbar) => {
	// Success
	if (response.data.updateSavedSearch.success) {
		let searches = [...savedSearches]
		const index = savedSearches
			.findIndex(_search => _search.id === search.id)
		if (index !== -1) {
			searches.splice(index, 1)
			setSavedSearches(searches)
		}
		// Error
	} else {
		openSnackbar(severity.ERROR, "There was an error deleting your saved search.")
	}
}

/**
 * This function returns a regular expression using the reservedCharacter array param, 
 * filtering out either global or quick search reserved characters. 
 * 
 * @param {*} reservedCharacters 
 * @param {*} filter 
 * @returns 
 */
export const reservedCharactersRegex = (reservedCharacters, filter) => new RegExp(`[${reservedCharacters
	.filter(e => !e[filter])
	.map(e => `\\${e.char}`).join('')}]`, 'g')

